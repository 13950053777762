import {ColorPaletteItem} from "./ColorPaletteItem";

export class ColorPaletteGroup {
  public normal: ColorPaletteItem;
  public light: ColorPaletteItem;
  public dark: ColorPaletteItem;

  constructor( obj ) {
    if ( obj ) {
      this.normal = new ColorPaletteItem( obj.normal );
      this.light = new ColorPaletteItem( obj.light );
      this.dark = new ColorPaletteItem( obj.dark );
    } else {
      this.normal = new ColorPaletteItem( undefined );
      this.normal.color = '#b71c1c';
      this.light = new ColorPaletteItem( undefined );
      this.light.color = '#e53935';
      this.dark = new ColorPaletteItem( undefined );
      this.dark.color = '#b71c1c';

    }
  }
}
