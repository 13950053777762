export class ManufacturingResourceType {
    name: string;

    constructor ( obj ) {
        this.name = '';
        if (obj) {
            for (const prop of Object.keys(obj)) {
                // if ( this[prop] ) {
                this[prop] = obj[prop];
                // }
            }
        }
    }

}
