<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Add User">Add User</h2>
  </div>
  <div>
    <form [formGroup]="addForm" (change)="formChanged()">
      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <input matInput placeholder="Name" i18n-placeholder="@@Name" value={{name}} formControlName="name" required>
              <mat-error *ngIf="addForm.controls.name.hasError('required')" i18n="@@Please enter name">Please enter name</mat-error>
              <mat-error *ngIf="addForm.controls.name.hasError('pattern')" i18n="@@Please enter a valid name">Please enter a valid name</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput placeholder="Email Address" i18n-placeholder="@@Email Address" formControlName="email" required>
              <mat-error *ngIf="addForm.controls.email.hasError('required')" i18n="@@Please enter an email">Please enter an email</mat-error>
              <mat-error *ngIf="addForm.controls.email.hasError('email')" i18n="@@Please enter a valid email">Please enter a valid email</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <mat-slide-toggle formControlName="makeUserAnAdmin" i18n="@@Make User an Admin">Make User an Admin</mat-slide-toggle>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->


      </mat-grid-list>
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn" mat-dialog-close="" [disabled]="addForm.invalid" (click)="onAdd()" i18n="@@Add User">Add User</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
