export class TableFilter {
  static classMap = {};

  public type: string;
  public fieldName: string;
  public fieldNames: string[];
  public fieldObjects: string[] = [];


  public static create(obj: any): TableFilter { // context is window in most contexts but not in Node
    if ( obj && obj.type && obj.type !== 'Empty' && TableFilter.classMap[obj.type]) {
      const className = obj.type;
      const classInstance = TableFilter.classMap[obj.type];
      // const classObject = window[className];

      if ( classInstance ) {
        const newObj = new classInstance(obj);
        // newObj.constructor(obj);
        if ( newObj instanceof TableFilter ) {
          return newObj;
        }
      }

      // if (classObject && classObject.prototype) {
      return Object.create(classInstance)(obj);
         // return new (<any>window)[className](obj); // window[className];

        // return new window[className](obj); // window[className];
        //  if (instance) {
        //   instance.constructor.apply(instance, obj);
        //   if ( instance instanceof TableFilter ) {
        //     return instance;
        //   }
        // }
      // }
    }
    return new TableFilter(undefined );
  }

  public constructor (obj: any) {
    if ( obj ) {
        if ( !obj.type ) {
        this.type = 'Empty';
      } else {
        this.type = obj.type;
      }
      if ( !obj.fieldName ) {
        this.setField('');
      } else {
        this.setField(obj.fieldName);
      }
      if ( !obj.fieldNames ) {
        this.fieldNames = [];
      } else {
        this.fieldNames = obj.fieldNames;
      }
    } else {
      this.type = 'Empty';
      this.setField('');
    }
  }

  public setField(field: string) {
    const parts = field.split('.');
    if ( parts.length > 1 ) {
      this.fieldName = parts[parts.length - 1];
      this.fieldObjects = parts.splice(0, parts.length - 1);
    } else {
      this.fieldName = field;
    }
  }

  public match(data: any, filter: string): boolean {
    return true;
  }

  public getStringValue(data: any): string {
    return this.getStringValueWithFieldName(data, this.fieldName);
  }

  public getStringValueWithFieldName(data: any, fieldName: string): string {
    data = this.getLeafObject(data);
    try {
      return String(data[fieldName]);
    } catch ( e ) {
      return '';
    }
  }

  public getCompositeStringValue(data: any): string {
    let value = '';
    const _this = this;
    if ( this.fieldNames && this.fieldNames.length && this.fieldNames.length > 0) {
      this.fieldNames.forEach(function (fieldName) {
        value += ' ' + _this.getStringValueWithFieldName(data, fieldName);
      });
    }
    return value;
  }
  public getNumericValue(data: any): number {
    data = this.getLeafObject(data);
    try {
      return Number(data[this.fieldName]);
    } catch ( e ) {
      return 0;
    }
  }
  public getDateValue(data: any): Date {
    data = this.getLeafObject(data);
    try {
      return new Date(data[this.fieldName]);
    } catch ( e ) {
      return new Date();
    }
  }

  public getLeafObject(data: any): any {
    if ( this.fieldObjects.length === 0 ) {
      return data;
    } else {
      try {
        let next = data;
        for ( const nextObj of this.fieldObjects ) {
          next = next[nextObj];
          if ( !next ) {
            return undefined;
          }
        }
        return next;
      } catch ( error ) {
        return undefined;
      }
    }
  }
}
