import { Injectable } from '@angular/core';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import {CognitoUtil} from "../service-auth/cognito.service";
import {Subscription, SubscriptionLevel, SubscriptionStatus, SubscriptionPeriod} from "../../model/Subscription";
import {User} from "../../model/User";
import {CognitoUserAttribute} from "amazon-cognito-identity-js";
import {environment} from "../../environments/environment";
import {S3Service} from "../service-auth/s3.service";
import {DataService} from "./data-service";

@Injectable()
export class SubscriptionService extends DataService {

  constructor(private cognitoUtil: CognitoUtil, private messageService: MessageService, private startupService: StartupService, private s3Service: S3Service) {
    super(startupService);
    // this.startupService.setSubscriptionService(this);
  }

    reset (filter: string = '') {}

  changeSubscription(subscription: Subscription) {
    this.updateUserAttribute('custom:subscriptionToken', subscription.toToken());
  }

  updateUserAttribute(name: string, value: string) {
    const __this = this;
    const cognitoUser = this.cognitoUtil.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
        } else {
          this.cognitoUtil.getCurrentUser().refreshSession( session.getRefreshToken(), (error, refreshedSession) => {
            if (error) {
              console.log("CognitoUtil: Can't refresh the refreshedSession:" + error);
            } else {
              console.log("UserLoginService: Session is " + refreshedSession.isValid());
              cognitoUser.getUserAttributes((err2, result2) => {
                if (err2) {

                } else {
                  const attributeList = [];
                  const attributeX = {
                    Name : name,
                    Value : value
                  };
                  const attribute = new CognitoUserAttribute(attributeX);
                  attributeList.push(attribute);

                  cognitoUser.updateAttributes(attributeList, function(err3, result) {
                    if (err3) {
                      alert(err3);
                      return;
                    }
                    console.log('Updated the Current Users Subscription Level: ' + result);
                    // __this.saveUser().then( user => {
                    //   console.log('Saved User: ' + JSON.stringify(user));
                    // });
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  // saveUser (): Promise<User> {
  //   const user: User = this.startupService.getUser();
  //   if ( !user ) {
  //     return new Promise((resolve, reject) => {
  //       reject('User is not logged in');
  //     });
  //   }
  //
  //   return new Promise((resolve, reject) => {
  //     const params = {
  //       Bucket: environment.dataBucket, Key: new UserKey(this.startupService.getUserId()).toString(), Body: JSON.stringify(user), ContentType: 'text/json'
  //     };
  //     this.s3Service.getS3().putObject(params, (err, data) => {
  //       if (err) {
  //         reject(err);
  //       } else {
  //         const ret = user as User;
  //         resolve(ret);
  //       }
  //     });
  //   });
  // }


}
export class UserKey {
  private static regexp = new RegExp(/[0-9a-z]+(-[0-9a-z]+)+\/config.user$/);
  private userId: string;

  public static isValid(input: string) {
    return this.regexp.test(input);
  }
  public constructor(userId: string) {
    this.userId = userId;
  }

  public toString(): string {
    return `${this.userId}/config.user`;
  }
}

