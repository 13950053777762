import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Umbrella } from '../../model/Umbrella';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import {DataService} from "./data-service";
import {environment} from "../../environments/environment";
import {AbstractDataService} from "./abstract-data.service";
// https://coryrylan.com/blog/angular-observable-data-services
@Injectable()
export class UmbrellaService extends AbstractDataService<Umbrella> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService) {
    super(httpa, messageServicea, startupServicea);
  }

  makeObjectInstance(args: any): Umbrella {
    const object = new Umbrella(args);
    return object;
  }

  supportsParallel(): boolean {
    return false;
  }

  getLoggingObjectTypeName (): string {
    return "umbrella";
  }

  getURLEndPoint (): string {
    return "umbrella";
  }
}
