import {Organization} from "./Organization";
import {ServiceProvider} from "./ServiceProvider";
import {DataObject} from "./DataObject";

export class Umbrella implements DataObject {

  public ID: number;
  public serviceprovider_id: number;
  public name: string;
  public marginShare: number;
  public fixedShare: number;
  public lastUpdate: Date;
  public org_id: number;
  catalogs: string;

  public serviceProvider: ServiceProvider;
  public organizations: Organization[];
  public administrationOrganization: Organization;

  constructor ( obj ) {
    this.organizations = [];

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    //
    // if ( obj ) {
    //   for ( const prop of Object.keys(obj) ) {
    //     if ( prop === 'administrationOrganization') {
    //       this.administrationOrganization = new Organization(obj[prop]);
    //     }
    //     if ( prop === 'serviceProvider') {
    //       this.serviceProvider = new ServiceProvider(obj[prop]);
    //     } else {
    //       if ( prop === 'organizations') {
    //         for ( const o of obj[prop] ) {
    //           this.organizations.push(new Organization(o));
    //         }
    //       } else {
    //         this[prop] = obj[prop];
    //       }
    //     }
    //   }
    // }
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}

