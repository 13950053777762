<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Order Items">Order Items</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Order Items">Order Items</span>
        </button>
      </span>
    </div>
    <div>
      <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>
    </div>
    <span class="app-toolbar-filler"></span>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="orderItems"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <div class="app-content app-table-content">
        <app-table #appTable *ngIf="columnsWithOutProductionData && eventHandler" [columns]="startupService.selectedRole === 'organization' || startupService.selectedRole === 'serviceProvider' ? columnsWithOutProductionData : columnsWithProductionData" [eventHandler]="eventHandler" [data]="renderedData" [expandedTableRowTemplate]="expandedTableRowTemplate" [dataServiceStatus]="printNodeOrderService.loadingState" [showFilters]="true">
          <ng-template #expandedTableRowTemplate let-element>
            <div class="columnArea">
              <div class="packages card-list-content">
                <h7 i18n="@@Packages">Packages</h7>
                <button mat-icon-button i18n-title="@@Change Packages" title="Change Packages" class="floatingButton" (click)="changePackages(element.orderItem && element.orderItem.orderItemToPackages && element.orderItem.orderItemToPackages.length > 0 ? element.orderItem.orderItemToPackages[0] : undefined, element.printNodeOrder)" color="primary"><mat-icon>change_history</mat-icon></button>
                <ng-container *ngIf="element.orderItem && element.orderItem.orderItemToPackages && element.orderItem.orderItemToPackages.length > 0">
                  <mat-card appearance="outlined" *ngFor="let orderItemToPackage of element.orderItem.orderItemToPackages">
                    <mat-card-content style="padding: 2px 2px 4px 6px">
                      <app-facility-package *ngIf="orderItemToPackage && orderItemToPackage.package" [pkg]="orderItemToPackage.package" [showPrintNodeOrderItems]="true" [expandPrintNodeOrderPackages]="{}"  showExpandPrintNodeOrderPackages="false"  numberOfNotShippedPackagesInOrder="0"></app-facility-package>
                    </mat-card-content>
                  </mat-card>
                </ng-container>
              </div>
            </div>
          </ng-template>
        </app-table>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






