import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import {Cart} from "../../../model/cart/Cart";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {ProductService} from "../../service-data/product.service";
import {Order} from "../../../model/Order";
import {OrderService} from "../../service-data/order.service";
import {SimpleOrder} from "../csv/csv-page/SimpleOrder";
import {OrderDeliverTo} from "../../../model/OrderDeliverTo";
import {OrderItem} from "../../../model/OrderItem";
import {OrderDeliverToService} from "../../service-data/order-deliver-to.service";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import {SimpleItem} from "../csv/csv-page/SimpleItem";
import {ValidationAddress, AddressValidationService} from "../../service-data/address-validation.service";
import {takeUntil} from "rxjs/operators";
import {DraftService} from "../../service-data/draft.service";
import {Subject} from "rxjs";
import {AddressService} from "../../service-data/address-service";
import {Address} from "../../../model/Address";
import {ChargeProfileService} from "../../service-data/charge-profile-service";
import {ChargeProfile} from "../../../model/ChargeProfile";

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.css', './cart-items.css']
})
export class CartPageComponent implements OnDestroy, OnInit, LoggedInCallback, RoleChangeListener {
  @Input() narrow: boolean;

  protected readonly Number = Number;

  mode: string = 'CART';
  addressMode = 'DISPLAY';
  shippingMode = 'DISPLAY';
  paymentMode = 'DISPLAY';

  invoice: boolean = false;

  addresses: Address[] = [];

  onDestroySubject: Subject<boolean> = new Subject();
  state: string = 'ITEMS';
  itemsReady: boolean = false;
  addressEditable: boolean = false;
  addressReady: boolean = false;
  shippingEditable: boolean = false;
  shippingReady: boolean = false;
  paymentEditable: boolean = false;
  paymentReady: boolean = false;

  editDeliveryAddress: boolean = false;
  enterCreditCard: boolean = false;

  deliveryAddressChanged: boolean = false;

  cart: Cart;
  // products: Product[] = [];
  addressIsComplete: boolean = false;
  shippingVerified: boolean = false;
  paymentVerified: boolean = false;
  costsAndShippingVerified: boolean = false;
  billingAddressTheSameAsShipping: boolean = true;
  savePaymentInfoAsChargeProfile: boolean = false;
  savePaymentInfoAsChargeProfileName: string = '';
  expeditedManufacturing: boolean = false;
  placingOrderMessage: string = '';
  placingOrderStatus: string = '';
  createdOrderDeliverTo: OrderDeliverTo;
  validatedAddress: ValidationAddress;

  totalQuantity: number;
  totalWeight: number;
  totalCost: number;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  messages: {
    validDate: 'GOOD\nTHRU',
    monthYear: 'mm/aaaa'
  };
  placeholders: {
    number: '•••• •••• •••• ••••',
    name: 'Nome Completo',
    expiry: '••/••',
    cvc: '•••'
  };
  masks: {
    cardNumber: '•' // optional - mask card number
  };

  showDetails: boolean = true;
  showCoverImage: boolean = true;
  showOnlyRecent: boolean = false;
  showNonZero: boolean = true;
  showProducts: boolean = true;
  showDrafts: boolean = true;
  showTable: boolean = true;

  facilityFriendlyNames = {
    '1': 'Phoenix, AZ',
    '7': 'Houston, Tx'
  };
  constructor(public router: Router, public startupService: StartupService, private productService: ProductService,
              private orderService: OrderService, private orderDeliverToService: OrderDeliverToService,
              private printNodeOrderService: PrintNodeOrderService, private draftService: DraftService,
              private addressValidationService: AddressValidationService, private addressService: AddressService,
              protected chargeProfileService: ChargeProfileService, private formBuilder: FormBuilder) {
    this.startupService.addRoleChangeListener(this);
  }


  ngOnInit() {
    console.log("AppComponent: Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.totalCost = 0;
    this.totalWeight = 0;
    this.totalQuantity = 0;
    this.mode = 'CART';
    this.addressMode = 'DISPLAY';
    this.shippingMode = 'DISPLAY';
    this.paymentMode = 'DISPLAY';
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.cart = this.startupService.getCart();
      this.getData();
      this.verifyCostsAndShipping();
      this.verifyPaymentInfo();
      this.checkAddressIsComplete();
    }
  }
  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  getData() {
    this.cart = this.startupService.getCart();
    this.draftService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.cart.addDraftItem(o, 0);
    });
    this.draftService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.cart.addDraftItem(o, 0);
    });
    // this.draftService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
    //   this.cart.addDraftItem(o, 0);
    // });
    this.draftService.objects.forEach( o => {
      this.cart.addDraftItem(o, 0);
    });

    this.productService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      if (o.productType === 'BOOK') {
        this.cart.addProductItem(o, 0);
      }
    });
    this.productService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      if (o.productType === 'BOOK') {
        this.cart.addProductItem(o, 0);
      }
    });
    // this.productService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
    // });
    this.productService.objects.forEach( o => {
      if (o.productType === 'BOOK') {
        this.cart.addProductItem(o, 0);
      }
    });
    this.cart.items.sort(( a, b): number => {
      if ( (a.tagId - 1) % 24 > (b.tagId - 1) % 24 ) {
        return 1;
      } else if ( (a.tagId - 1) % 24 === (b.tagId - 1) % 24 ) {
        if ( a.tagId > b.tagId ) {
          return 1;
        }
        if ( a.tagId < b.tagId ) {
          return -1;
        }
        return 0;
      } else {
        return -1;
      }
    });
    this.chargeProfileService.objects.forEach( o => {
      if ( o.default ) {
        if ( !this.cart.chargeProfile || this.cart.chargeProfile.ID === 0 ) {
          this.cart.setChargeProfile(o);
        }
      }
    });
    this.addresses = [];
    this.addressService.objects.forEach( o => {
      this.addresses.push(o);
      if ( o.default ) {
        if ( !this.cart.address || this.cart.address.ID === 0 ) {
          this.cart.setAddress(o);
        }
      }
    });
  }

  proceedToCheckout() {
    if ( this.totalQuantity > 0 ) {
      this.mode = 'CHECKOUT';
      if ( this.cart.isAddressReady ) {
        this.addressMode = 'DISPLAY';
      }
      if ( this.cart.isShippingReady ) {
        this.shippingMode = 'DISPLAY';
      }
      if ( this.cart.isPaymentInfoReady ) {
        this.paymentMode = 'DISPLAY';
      }
      this.itemsReady = true;
      this.addressEditable = true;
      this.cart.setNonZeroItems();
    } else {
      this.mode = 'CART';
      this.itemsReady = false;
      this.addressEditable = false;
    }
    if ( this.cart.address.validated || this.cart.address.override ) {
      this.addressReady = true;
    }
    if ( this.addressReady && this.itemsReady ) {
      this.shippingEditable = true;
    }
    this.shippingEditable = false;
    this.shippingReady = false;
    this.paymentEditable = false;
    this.paymentReady = false;
  }

  makeAddressDefault(address: Address) {
    for ( const currentAddress of this.addressService.objects ) {
      if ( currentAddress.default ) {
        currentAddress['updatingState'] = 'UPDATING';
        this.addressService.updateItem(address).subscribe( o => {
          delete address['updatingState'];
        });
      }
    }
    address.default = true;
    address['updatingState'] = 'UPDATING';
    if ( address.ID > 0 ) {
      this.addressService.updateItem(address).subscribe( o => {
        delete address['updatingState'];
      });
    }
  }

  makeChargeProfileDefault(chargeProfile: ChargeProfile) {
    for ( const currentChargeProfile of this.chargeProfileService.objects ) {
      if ( currentChargeProfile.default ) {
        currentChargeProfile['updatingState'] = 'UPDATING';
        this.chargeProfileService.updateItem(chargeProfile).subscribe( o => {
          delete chargeProfile['updatingState'];
        });
      }
    }
    chargeProfile.default = true;
    chargeProfile['updatingState'] = 'UPDATING';
    if ( chargeProfile.ID > 0 ) {
      this.chargeProfileService.updateItem(chargeProfile).subscribe( o => {
        delete chargeProfile['updatingState'];
      });
    }
  }

  deleteChargeProfile(chargeProfile: ChargeProfile) {
    if ( chargeProfile.ID > 0 ) {
      this.chargeProfileService.deleteItem(chargeProfile).subscribe( o => {
        delete chargeProfile['updatingState'];
      });
    }
  }

  deleteSavedDeliveryAddress(address: Address) {
    if ( address.ID > 0 ) {
      address['updatingState'] = 'UPDATING';
      this.addressService.deleteItem(address).subscribe( o => {
        const index = this.addresses.indexOf(address);
        this.addresses.splice(index, 1);
        delete address['updatingState'];
      });
    }
  }

  saveChangesToDeliveryAddress(address: Address) {
    if ( address.ID > 0 ) {
      address['updatingState'] = 'UPDATING';
      this.addressService.updateItem(address).subscribe( o => {
        delete address['updatingState'];
      });
    } else {
      address.org_ID = Number(this.startupService.selectedRoleId);
      this.addressService.addItem(address).subscribe( o => {
        this.addresses.push(o);
        address['updatingState'] = 'UPDATING';
        address.ID = o.ID;
      });
    }

  }

  toggleBillingAddressTheSameAsShipping() {
    this.verifyPaymentInfo();
  }
  toggleSavePaymentInfoAsChargeProfile() {
    this.savePaymentInfoAsChargeProfile = !this.savePaymentInfoAsChargeProfile;
  }

  checkAddressIsComplete() {
    if ( this.billingAddressTheSameAsShipping ) {
      this.cart.setBillingAddressTheSameAsShipping();
      this.paymentVerified = this.cart.isPaymentInfoReady();
    }
    // else {
      // this.cart.setShippingAddressTheSameAsBilling();
    // }
    this.addressIsComplete = this.cart.isAddressReady();
  }

  // verifyShipping() {
  //   this.addressIsComplete = this.cart.isShippingReady();
  // }

  verifyPaymentInfo() {
    if ( this.billingAddressTheSameAsShipping ) {
      this.cart.setBillingAddressTheSameAsShipping();
    }
    // else {
    //   this.cart.setShippingAddressTheSameAsBilling();
    // }
    this.paymentVerified = this.cart.isPaymentInfoReady();
    this.checkAddressIsComplete();
  }

  verifyCostsAndShipping () {
    this.costsAndShippingVerified = this.cart.verifyCostsAndShipping();
    this.totalQuantity = 0;
    this.totalWeight = 0;
    this.totalCost = 0;
    for ( const item of this.cart.items ) {
      this.totalCost += item.getCost();
      this.totalWeight += ( item.getWeight() * item.quantity );
      this.totalQuantity += item.quantity;
    }
    if ( this.totalQuantity === 0 ) {
      this.mode = 'CART';
    }

    //
    // this.totalQuantity = this.cart.totalQuantity;
    // this.totalWeight = this.cart.totalWeight;
    // this.totalCost = this.cart.totalCost;

  }

  getPlaceOrderButtonClass(): string {
    if ( this.placingOrderStatus === 'Success') {
      return 'processing btn-success';
    }
    if ( this.placingOrderStatus === 'PleaseWait') {
      return 'processing btn-light';
    }
    if ( this.placingOrderStatus !== '') {
      return 'processingProblem btn-danger';
    }
    if ( this.addressIsComplete && this.paymentVerified && this.costsAndShippingVerified ) {
      return 'ready btn-primary';
    } else {
      return 'notReady btn-warning';
    }
  }

  makePurchase() {
    // if ( this.billingAddressTheSameAsShipping ) {
    //   this.cart.setBillingAddressTheSameAsShipping();
    // } else {
    //   if ( this.cart.transcriptionItems && this.cart.transcriptionItems.length > 0) {
    //     this.cart.setShippingAddressTheSameAsBilling();
    //   }
    // }
    //
    // this.placingOrderStatus = 'PleaseWait';
    // this.placingOrderMessage = 'PleaseWait';
    // this.orderService.placeOrder(this.cart).then(lambdaResponse => {
    //   this.placingOrderStatus = lambdaResponse.response;
    //   this.placingOrderMessage = '';
    //   switch ( lambdaResponse.response ) {
    //     case 'Success': {
    //       this.router.navigate(['/secure/orders']);
    //       break;
    //     }
    //     case 'CreditCardChargedButNoOrder': {
    //       break;
    //     }
    //     case 'CreditCardDidNotCharge': {
    //       this.placingOrderMessage = lambdaResponse.responseMessage;
    //       this.activeTab = 'payment';
    //       break;
    //     }
    //     case 'ConnectionError': {
    //       break;
    //     }
    //     case 'GeneralError': {
    //       break;
    //     }
    //     default: {
    //       this.placingOrderStatus = 'Undefined';
    //       this.placingOrderMessage = lambdaResponse.responseMessage;
    //       break;
    //     }
    //   }
    // }).catch( error => {
    //   this.placingOrderStatus = 'Error';
    //   this.placingOrderMessage = 'Error completing the order. ' + error;
    // });
  }
  makeTranscriptionPurchase() {
    // if ( this.billingAddressTheSameAsShipping ) {
    //   this.cart.setBillingAddressTheSameAsShipping();
    // } else {
    //   if ( this.cart.transcriptionItems && this.cart.transcriptionItems.length > 0) {
    //     this.cart.setShippingAddressTheSameAsBilling();
    //   }
    // }
    //
    // this.placingOrderStatus = 'PleaseWait';
    // this.placingOrderMessage = 'PleaseWait';
    // this.orderService.placeTranscriptionOrder(this.cart).then(lambdaResponse => {
    //   this.placingOrderStatus = lambdaResponse.response;
    //   this.placingOrderMessage = '';
    //   switch ( lambdaResponse.response ) {
    //     case 'Success': {
    //       // Close the charging window and show the added files
    //       // walk through all of the items that are in the cart and pick the items in the uploader that match and upload them.
    //       for ( const transcriptionItem of this.cart.transcriptionItems ) {
    //         for ( const item of this.startupService.uploader.getNotUploadedItems() ) {
    //           if ( transcriptionItem.name === item._file.name ) {
    //             item.upload();
    //           }
    //         }
    //       }
    //       this.startupService.uploader.uploadAll();
    //       break;
    //     }
    //     case 'CreditCardChargedButNoOrder': {
    //       break;
    //     }
    //     case 'CreditCardDidNotCharge': {
    //       this.placingOrderMessage = lambdaResponse.responseMessage;
    //       this.activeTab = 'payment';
    //       break;
    //     }
    //     case 'ConnectionError': {
    //       break;
    //     }
    //     case 'GeneralError': {
    //       break;
    //     }
    //     default: {
    //       this.placingOrderStatus = 'Undefined';
    //       this.placingOrderMessage = lambdaResponse.responseMessage;
    //       break;
    //     }
    //   }
    // }).catch( error => {
    //   this.placingOrderStatus = 'Error';
    //   this.placingOrderMessage = 'Error completing the order. ' + error;
    // });
  }

  ngOnDestroy(): void {
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  async validateCartAddress() {
    const simpleOrder = this.makeSimpleOrderFromCart();
    simpleOrder.addressValidationResult = undefined;
    const address = new ValidationAddress(undefined);
    address.title = '';
    address.firstname = this.cart.address.firstname;
    address.lastname = this.cart.address.lastname;
    address.company = this.cart.address.company;
    address.address1 = this.cart.address.address1;
    address.address2 = this.cart.address.address2;
    address.address3 = '';
    address.address4 = '';
    address.city = this.cart.address.city;
    address.state = this.cart.address.state;
    address.country = this.cart.address.country;
    address.postalcode = this.cart.address.postalcode;
    if ( address.country === 'US' ) {
      address.country = 'USA';
    }

    this.addressValidationService.validate(address).subscribe(addressValidationResult => {
      if ( addressValidationResult ) {
        if ( addressValidationResult.recommendedAddresses.length > 0) {
          this.validatedAddress = new ValidationAddress(address);
          this.validatedAddress.company = addressValidationResult.recommendedAddresses[0].company ? addressValidationResult.recommendedAddresses[0].company : address.company;
          this.validatedAddress.address1 = addressValidationResult.recommendedAddresses[0].address1 ? addressValidationResult.recommendedAddresses[0].address1 : '';
          this.validatedAddress.address2 = addressValidationResult.recommendedAddresses[0].address2 ? addressValidationResult.recommendedAddresses[0].address2 : '';
          this.validatedAddress.city = addressValidationResult.recommendedAddresses[0].city;
          this.validatedAddress.state = addressValidationResult.recommendedAddresses[0].state;
          this.validatedAddress.postalcode = addressValidationResult.recommendedAddresses[0].postalcode;
          this.validatedAddress.country = addressValidationResult.recommendedAddresses[0].country;
          this.cart.addressValidationStatus = 'SUCCESS';
        } else {
          this.cart.addressValidationStatus = 'NO VALID SUGGESTIONS';
        }
      } else {
        console.log(`Error validating address`);
      }
    });
  }

//////////// CREATING Order, OrderDeliverTo, OrderItems, PrintNodeOrder and PrintNodeOrderItems
  // This is the Order that contains multiple orderDeliverTo orders -- each with its own delivery address
  placeInvoicedOrder() {
    const order = new Order(undefined);
    order.payer = this.cart.payer; // 'ORGANIZATION'; should be the default
    order.org_ID = Number(this.startupService.selectedRoleId);
    const customerOrderIdDate = new Date();
    order.customerorderid = 'Order-' + ( customerOrderIdDate.getMonth() + 1 ) + '.' + ( customerOrderIdDate.getDate() + 1 ) + '.' + customerOrderIdDate.getFullYear() + '.' + Math.floor(Math.random() * 1000); // this.orderStrategyFormGroup.controls.firstPartOfOrderId.value; // this.firstPartOfOrderId; // Add the current date and/or the org_id and or the umbrella to the id?
    order.inventoryType = this.cart.inventoryType; // 'FULFILL_FROM_INVENTORY'; should be the default
    this.orderService.addOrder(order).subscribe( createdOrder => {
      if ( createdOrder ) {
        const simpleOrder = this.makeSimpleOrderFromCart();
        simpleOrder.orderId = '' + createdOrder.ID;
        simpleOrder.inventoryType = createdOrder.inventoryType;
        this.createOrderDeliverTo(simpleOrder, createdOrder);
      } else {
        // TODO: put out a message
      }
    });
  }

  makeSimpleOrderFromCart(): SimpleOrder {
    const simpleOrder = new SimpleOrder();
    simpleOrder.firstName = this.cart.address.firstname;
    simpleOrder.lastName = this.cart.address.lastname;
    simpleOrder.company = this.cart.address.company;
    simpleOrder.address1 = this.cart.address.address1;
    simpleOrder.address2 = this.cart.address.address2;
    simpleOrder.city = this.cart.address.city;
    simpleOrder.region = this.cart.address.state;
    simpleOrder.country = this.cart.address.country;
    simpleOrder.postalCode = this.cart.address.postalcode;
    simpleOrder.email = this.cart.address.email;
    simpleOrder.inventoryType = this.cart.inventoryType;
    simpleOrder.shippingInfo = this.cart.shippingMethod;
    simpleOrder.submissionMethod = '';
    simpleOrder.telephone = this.cart.phone;
    // simpleOrder.type = ??

    for ( const cartItem of this.cart.items ) {
      const item = new SimpleItem();
      item.setQuantity(cartItem.quantity);
      item.setId(cartItem.getId());
      if ( item.quantity > 0 ) {
        item.setQuantity(cartItem.quantity);
        simpleOrder.addItem(item);
      }
    }
    return simpleOrder;
  }

  createOrderDeliverTo(simpleOrder: SimpleOrder, order: Order) {
    const orderDeliverTo = new OrderDeliverTo(undefined);
    orderDeliverTo.inventoryType = simpleOrder.inventoryType;
    orderDeliverTo.order_ID = order.ID;

    orderDeliverTo.title = simpleOrder.title ? simpleOrder.title.toUpperCase() : '';
    orderDeliverTo.firstname = simpleOrder.firstName ? simpleOrder.firstName.toUpperCase() : '';
    orderDeliverTo.lastname = simpleOrder.lastName ? simpleOrder.lastName.toUpperCase() : '';
    orderDeliverTo.company = simpleOrder.company ? simpleOrder.company.toUpperCase() : '';
    orderDeliverTo.address1 = simpleOrder.address1 ? simpleOrder.address1 : '';
    orderDeliverTo.address2 = simpleOrder.address2 ? simpleOrder.address2 : '';
    orderDeliverTo.city = simpleOrder.city ? simpleOrder.city : '';
    orderDeliverTo.state = simpleOrder.region ? simpleOrder.region : '';
    orderDeliverTo.postalcode = simpleOrder.postalCode ? simpleOrder.postalCode : '';
    orderDeliverTo.country = simpleOrder.country ? simpleOrder.country : '';

    orderDeliverTo.addressValidationStatus = 'VALID';

    const carrierService = 'USPS_PRIORITY_FLAT';
    let shippingInfoParts = simpleOrder.shippingInfo.split('_');
    if ( !shippingInfoParts || shippingInfoParts.length < 2 ) {
      shippingInfoParts = carrierService.split('_');
    }
    if ( shippingInfoParts && shippingInfoParts.length >= 2) {
      orderDeliverTo.carrier = shippingInfoParts[0];
      orderDeliverTo.servicelevel = shippingInfoParts[1];
      if ( shippingInfoParts && shippingInfoParts.length >= 3) {
        orderDeliverTo.servicelevel += "_" + shippingInfoParts[2];
      }
    }
    // orderId;
    // type;
    // ignored: boolean;
    // confirmed: boolean;

    for ( const item of simpleOrder.items) {
        let orderItem: OrderItem;
        for ( const alreadyCreatedOrderItem of orderDeliverTo.orderItems ) {
          if ( alreadyCreatedOrderItem.product_ID === Number(item.id) ) {
            orderItem = alreadyCreatedOrderItem;
          }
        }
        if ( orderItem ) {
          orderItem.quantity += item.quantity;
        } else {
          orderItem = new OrderItem(undefined);
          orderItem.product_ID = Number(item.id);
          orderItem.quantity = item.quantity;
          orderDeliverTo.orderItems.push(orderItem);
      }
    }

    this.orderDeliverToService.addOrderDeliverTo(orderDeliverTo).subscribe( createdOrderDeliverTo => {
      if ( createdOrderDeliverTo ) {
        this.createdOrderDeliverTo = createdOrderDeliverTo;
        console.log(`Success making OrderDeliverTo`);
        this.cart.clear();
      } else {
        this.createdOrderDeliverTo = undefined;
        console.log(`Trouble saving an OrderDeliverTo ${simpleOrder.firstName}`);
      }
    });
  }

  // async createPrintNodeOrder(simpleOrder: SimpleOrder, orderDeliverTo: OrderDeliverTo) {
  //   const printNodeOrder = new PrintNodeOrder(undefined);
  //   printNodeOrder.inventoryType = orderDeliverTo.inventoryType;
  //   printNodeOrder.org_ID = Number(this.startupService.selectedRoleId);
  //   printNodeOrder.order_ID = orderDeliverTo.order_ID;
  //   printNodeOrder.printnode_ID = 1;
  //   printNodeOrder.orderdeliverto_ID = orderDeliverTo.ID;
  //   printNodeOrder.address1 = orderDeliverTo.address1;
  //   printNodeOrder.address2 = orderDeliverTo.address2;
  //   printNodeOrder.city = orderDeliverTo.city;
  //   printNodeOrder.state = orderDeliverTo.state;
  //   printNodeOrder.postalcode = orderDeliverTo.postalcode;
  //   printNodeOrder.country = orderDeliverTo.country;
  //   printNodeOrder.title = orderDeliverTo.title;
  //   printNodeOrder.firstname = orderDeliverTo.firstname;
  //   printNodeOrder.lastname = orderDeliverTo.lastname;
  //   printNodeOrder.carrier = orderDeliverTo.carrier;
  //   printNodeOrder.servicelevel = orderDeliverTo.servicelevel;
  //   const dueDate = new Date();
  //   dueDate.setDate(dueDate.getDate() + 1);
  //   printNodeOrder.dueDate = dueDate;
  //
  //   printNodeOrder.printNodeOrderItems = [];
  //   for ( const orderItem of orderDeliverTo.orderItems ) {
  //     const printNodeOrderItem = new PrintNodeOrderItem(undefined, printNodeOrder);
  //     printNodeOrderItem.printNodeOrder = undefined;
  //     printNodeOrderItem.book_ID = this.getBookIdFromProductId(orderItem.product_ID);
  //     printNodeOrderItem.orderitem_ID = orderItem.ID;
  //     printNodeOrderItem.quantity = orderItem.quantity;
  //     printNodeOrder.printNodeOrderItems.push(printNodeOrderItem);
  //   }
  //   // Move this part to createPrintNodeOrder in the same patter as Validate and CreateOrderDeliverTo
  //   this.printNodeOrderService.addItem(printNodeOrder).subscribe( createdPrintNodeOrder => {
  //     if ( createdPrintNodeOrder ) {
  //       this.printNodeOrderCreated = createdPrintNodeOrder;
  //       console.log(`Success making PrintNodeOrder`);
  //     } else {
  //       this.printNodeOrderCreated = undefined;
  //       console.log(`Error making PrintNodeOrder`);
  //     }
  //   });
  // }

  // getBookIdFromProductId(productId: number): number {
  //   for ( const product of this.products) {
  //     if ( product.ID === productId) {
  //       return product.book_ID;
  //     }
  //   }
  //   return 0;
  // }

  isInstanceOf(obj: any, className: any): boolean {
    return obj && obj.constructor.name === className;
  }
}

