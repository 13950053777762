import { Component, OnInit } from '@angular/core';
import {Subscription, SubscriptionStatus} from "../../../../model/Subscription";
import {LoggedInCallback} from "../../../service-auth/cognito.service";
import {Recurring} from "../../../../model/cart/Recurring";
import {Router} from "@angular/router";
import {StartupService} from "../../../service-ui/startup.service";
import {NavbarService} from "../../../service-ui/navbar.service";
import {SubscriptionService} from "../../../service-data/subscription.service";

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit, LoggedInCallback {
  subscription: Subscription;
  recurring: Recurring;
  paymentVerified: boolean = false;
  updateRecurringMessage: string = '';
  updateRecurringStatus: string = '';


  constructor(public router: Router, public startupService: StartupService, private navbarService: NavbarService, public subscriptionService: SubscriptionService) {
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Account");
      // this.router.navigate(['/home/login']);
    } else {
      this.navbarService.setNavBarState('account', "");
      this.startupService.showUpdateRecurring = false;
      this.subscription = this.startupService.getSubscription();
      this.recurring = new Recurring( undefined );
    }
  }

  onSubmit() {

  }

  save() {
    this.subscriptionService.changeSubscription(this.subscription); // TODO: Make this a Promise or an Observable
  }

  verifyPaymentInfo() {
    this.paymentVerified = this.recurring.isPaymentInfoReady();
  }
  getUpdateRecurringButtonClass (): string {
    if ( this.updateRecurringStatus === 'Success') {
      return 'processing btn-success';
    }
    if ( this.updateRecurringStatus === 'PleaseWait') {
      return 'processing btn-light';
    }
    if ( this.updateRecurringStatus !== '') {
      return 'processingProblem btn-danger';
    }
    if ( this.paymentVerified ) {
      return 'ready btn-primary';
    } else {
      return 'notReady btn-warning';
    }
  }

  updateRecurring() {

  //   this.updateRecurringStatus = 'PleaseWait';
  //   this.updateRecurringMessage = 'PleaseWait';
  //   this.orderService.updateRecurring(this.recurring).then(lambdaResponse => {
  //     this.updateRecurringStatus = lambdaResponse.response;
  //     this.updateRecurringMessage = '';
  //     switch ( lambdaResponse.response ) {
  //       case 'Success': {
  //         this.router.navigate(['/secure/orders']);
  //         break;
  //       }
  //       case 'CreditCardChargedButNoOrder': {
  //         break;
  //       }
  //       case 'CreditCardDidNotCharge': {
  //         this.updateRecurringMessage = lambdaResponse.responseMessage;
  //         break;
  //       }
  //       case 'ConnectionError': {
  //         break;
  //       }
  //       case 'GeneralError': {
  //         break;
  //       }
  //       default: {
  //         this.updateRecurringStatus = 'Undefined';
  //         this.updateRecurringMessage = lambdaResponse.responseMessage;
  //         break;
  //       }
  //     }
  //   }).catch( error => {
  //     this.updateRecurringStatus = 'Error';
  //     this.updateRecurringMessage = 'Error completing the order. ' + error;
  //   });
  }

}
