import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {PrintNodeOrderItem} from "../../../model/PrintNodeOrderItem";
import {ManufacturingSetService} from "../../service-data/manufacturing-set.service";
import {ManufacturingSet} from "../../../model/ManufacturingSet";
import {ManufacturingTask} from "../../../model/ManufacturingTask";
import {ManufacturingTaskService} from "../../service-data/manufacturing-task.service";
import {ManufacturingSetsComponent} from "../manufacturing-sets/manufacturing-sets.component";
import {Subject} from "rxjs";
import {ManufacturingBucketsComponent} from "../manufacturing-buckets/manufacturing-buckets.component";

@Component({
  selector: 'app-manufacturing-tasks',
  templateUrl: './manufacturing-tasks.component.html',
  styleUrls: ['./manufacturing-tasks.component.css']
})

export class ManufacturingTasksComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, RoleChangeListener {
  // public static types = ['interior', 'binding', 'lamination', 'impositionLayout', 'trimSize', 'paperType', 'quantityGroup'];
  public static types = ['interior', 'binding', 'lamination', 'impositionLayout', 'trimSize', 'paperType', 'quantityGroup', 'oneDigitZip'];

  public manufacturingTaskStatusColors = {
    'INITIAL': 'white',
    'STARTED': 'yellow',
    'PAUSED': 'orange',
    'COMPLETED': 'green',
    'ERROR': 'red'
  };

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;

  packagesByManufacturingSets = {};

  useTypeIcons = {
  'BodyPrint': 'library_books',
  'CutApart': 'content_cut',
  'CoverPrint': 'menu_book',
  'Laminate': 'density_large',
  'Bind': 'view_day',
  'Trim': 'select_all',
  'Pack': 'local_post_office',
  'Ship': 'local_shipping'
  };

  useTypeColors = {
    'BodyPrint': 'lightcyan',
    'CutApart': 'cyan',
    'CoverPrint': 'lightskyblue',
    'Laminate': 'skyblue',
    'Bind': 'lightgreen',
    'Trim': 'limegreen',
    'Pack': 'cornsilk',
    'Ship': 'wheat'
  };

  manufacturingSets: ManufacturingSet[];
  selectedManufacturingSet: ManufacturingSet;
  selectedPrintNodeOrderItem: PrintNodeOrderItem;

  // Display flags and filters
  showEmptySets: boolean = false;
  showClosedSets: boolean = true;
  showLockedSets: boolean = true;
  showOpenSets: boolean = true;
  showPrintNodeOrderItems: boolean = true;
  showDates: boolean = true;
  showStats: boolean = true;

  hideUseType: any = {};

  expandSets: any = {};
  expandSetsPackages: any = {};

  dataIndex = 0; // 0 for Quantity, 1 for feetOrSheets, 2 for number of line items
  Math = Math;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor(public router: Router, public startupService: StartupService, private manufacturingTaskService: ManufacturingTaskService, private manufacturingSetService: ManufacturingSetService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.addRoleChangeListener(this);
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }


  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  ngAfterViewInit(): void {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  onSelect(printNodeOrderItem: PrintNodeOrderItem): void {
    this.selectedPrintNodeOrderItem = printNodeOrderItem;
    if ( this.selectedPrintNodeOrderItem ) {
      this.selectedManufacturingSet = this.selectedPrintNodeOrderItem.manufacturingSet;
    }
  }

  getData(reset: boolean = true): void {
    this.startupService.touchLastUserActivity();
    const manufacturingSetsFilter = {
      eq: {
        ' date_add(date(substring(manufacturingTaskBucketPeriod.period, 2, 10)), INTERVAL 8 DAY) > now() ': 1,
        ' date_add(date(substring(manufacturingTaskBucketPeriod.period, 2, 10)), INTERVAL -7 DAY) < now() ': 1,
        ' manufacturingSet.openStatus': 'CLOSED'
      }
    };
    this.manufacturingSetService.getFilteredItems(manufacturingSetsFilter, reset, 10)
      .subscribe(manufacturingSets => {
        this.packagesByManufacturingSets = {};
        this.manufacturingSets = []; // manufacturingSets;
        for ( const manufacturingSet of manufacturingSets ) {
          this.computeProperties(manufacturingSet, manufacturingSet.printNodeOrderItems);
          manufacturingSet.getNextManufacturingTasksToExecuteFromRoots();
          this.manufacturingSets.push(manufacturingSet);
        }
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  computeProperties(wrapper: any, printNodeOrderItems: PrintNodeOrderItem[]) {
    if ( !wrapper.filter ) {
      wrapper.filter = {};
    }

    wrapper.lineItems = 0;
    wrapper.quantity = 0;
    wrapper.feetOrSheets = 0;
    wrapper.totals = [0, 0, 0];
    wrapper.binding = [{}, {}, {}];
    wrapper.lamination = [{}, {}, {}];
    wrapper.paperType = [{}, {}, {}];
    wrapper.createdDate = [{}, {}, {}];
    wrapper.trimSize = [{}, {}, {}];
    wrapper.interior = [{}, {}, {}];
    wrapper.impositionLayout = [{}, {}, {}];
    wrapper.quantityGroup = [{}, {}, {}];
    wrapper.serviceProvider = [{}, {}, {}];
    wrapper.oneDigitZip = [{}, {}, {}];
    for ( const printNodeOrderItem of printNodeOrderItems ) {
      try {
        const quantity = printNodeOrderItem.quantity;
        const width = printNodeOrderItem.book.body.width;
        const height = printNodeOrderItem.book.body.height;
        const thickness = printNodeOrderItem.book.body.spine;
        const pages = printNodeOrderItem.book.body.numPages;
        const interior = printNodeOrderItem.book.interiorType;
        const paperType = String(printNodeOrderItem.book.body.paperType_ID);
        const lamination = printNodeOrderItem.book.cover.laminateType;

        let createdDate = '';
        let oneDigitZip = '';
        let serviceProvider = 0;
        if ( printNodeOrderItem.printNodeOrder ) {
          createdDate = new Date(printNodeOrderItem.printNodeOrder.created).toISOString().split('T')[0];
          oneDigitZip = ManufacturingBucketsComponent.getFacilityBasedOnCountryStateAndOneDigitZip(printNodeOrderItem.printNodeOrder.country, printNodeOrderItem.printNodeOrder.state, printNodeOrderItem.printNodeOrder.postalcode ? printNodeOrderItem.printNodeOrder.postalcode.substring(0, 1) : '', printNodeOrderItem.printNodeOrder.printnode_ID);
          if ( printNodeOrderItem.printNodeOrder.organization ) {
            serviceProvider = printNodeOrderItem.printNodeOrder.organization.serviceProvider_ID;
          }
        }
        // const createdDate = new Date(printNodeOrderItem.printNodeOrder.created).toISOString().split('T')[0];

        const binding = printNodeOrderItem.book.bindingType;
        let trimSize = '';
        if ( width / 72 === 5.5 && height / 72 === 8.5 ) {
          trimSize = '_5.5X8.5';
        } else {
          if ( width / 72 === 6.0 && height / 72 === 9.0 ) {
            trimSize = '_6.0X9.0';
          } else {
            if ( width / 72 === 8.5 && height / 72 === 11 ) {
              trimSize = '_8.5X11.0';
            } else {
              trimSize = 'Other';
            }
          }
        }

        let impositionLayout = width / 72 > 6 || height / 72 > 9 ? '_2UP' : '_4UP';
        let feetOrSheets = width / 72 > 6 || height / 72 > 9 ? quantity * pages / 4 : quantity * pages / 8;
        // Get a criteria to determine that it is comming off of a roll to make it feet
        if ( false ) {
          feetOrSheets = width / 72 > 6 || height / 72 > 9 ? quantity * pages / 4 : ( quantity * pages / 6 ) * 10 / 12;
          impositionLayout = width / 72 > 6 || height / 72 > 9 ? '_2UP' : '_3UP';
        }

        let quantityGroup = '_001-019';
        if ( quantity < 20 ) {
          quantityGroup = '_001-019';
        } else {
          if ( quantity < 50 ) {
            quantityGroup = '_020-049';
          } else {
            if ( quantity < 100 ) {
              quantityGroup = '_050-099';
            } else {
              if ( quantity < 250 ) {
                quantityGroup = '_100-249';
              } else {
                if ( quantity < 500 ) {
                  quantityGroup = '_250-499';
                } else {
                  quantityGroup = '_500+';
                }
              }
            }
          }
        }

        wrapper.lineItems += 1;
        wrapper.quantity += quantity;
        wrapper.feetOrSheets += feetOrSheets;
        wrapper.totals[0] += quantity;
        wrapper.totals[1] += feetOrSheets;
        wrapper.totals[2] += 1;
        if ( !wrapper.quantityGroup[0][quantityGroup] ) {
          wrapper.quantityGroup[0][quantityGroup] = 0;
        }
        if ( !wrapper.quantityGroup[1][quantityGroup] ) {
          wrapper.quantityGroup[1][quantityGroup] = 0;
        }
        if ( !wrapper.quantityGroup[2][quantityGroup] ) {
          wrapper.quantityGroup[2][quantityGroup] = 0;
        }
        wrapper.quantityGroup[0][quantityGroup] += quantity;
        wrapper.quantityGroup[1][quantityGroup] += feetOrSheets;
        wrapper.quantityGroup[2][quantityGroup] += 1;

        if ( !wrapper.binding[0][binding] ) {
          wrapper.binding[0][binding] = 0;
        }
        if ( !wrapper.binding[1][binding] ) {
          wrapper.binding[1][binding] = 0;
        }
        if ( !wrapper.binding[2][binding] ) {
          wrapper.binding[2][binding] = 0;
        }
        wrapper.binding[0][binding] += quantity;
        wrapper.binding[1][binding] += feetOrSheets;
        wrapper.binding[2][binding] += 1;

        if ( !wrapper.paperType[0][paperType] ) {
          wrapper.paperType[0][paperType] = 0;
        }
        if ( !wrapper.paperType[1][paperType] ) {
          wrapper.paperType[1][paperType] = 0;
        }
        if ( !wrapper.paperType[2][paperType] ) {
          wrapper.paperType[2][paperType] = 0;
        }
        wrapper.paperType[0][paperType] += quantity;
        wrapper.paperType[1][paperType] += feetOrSheets;
        wrapper.paperType[2][paperType] += 1;

        if ( !wrapper.trimSize[0][trimSize] ) {
          wrapper.trimSize[0][trimSize] = 0;
        }
        if ( !wrapper.trimSize[1][trimSize] ) {
          wrapper.trimSize[1][trimSize] = 0;
        }
        if ( !wrapper.trimSize[2][trimSize] ) {
          wrapper.trimSize[2][trimSize] = 0;
        }
        wrapper.trimSize[0][trimSize] += quantity;
        wrapper.trimSize[1][trimSize] += feetOrSheets;
        wrapper.trimSize[2][trimSize] += 1;

        if ( !wrapper.createdDate[0][createdDate] ) {
          wrapper.createdDate[0][createdDate] = 0;
        }
        if ( !wrapper.createdDate[1][createdDate] ) {
          wrapper.createdDate[1][createdDate] = 0;
        }
        if ( !wrapper.createdDate[2][createdDate] ) {
          wrapper.createdDate[2][createdDate] = 0;
        }
        wrapper.createdDate[0][createdDate] += quantity;
        wrapper.createdDate[1][createdDate] += feetOrSheets;
        wrapper.createdDate[2][createdDate] += 1;

        if ( !wrapper.lamination[0][lamination] ) {
          wrapper.lamination[0][lamination] = 0;
        }
        if ( !wrapper.lamination[1][lamination] ) {
          wrapper.lamination[1][lamination] = 0;
        }
        if ( !wrapper.lamination[2][lamination] ) {
          wrapper.lamination[2][lamination] = 0;
        }
        wrapper.lamination[0][lamination] += quantity;
        wrapper.lamination[1][lamination] += feetOrSheets;
        wrapper.lamination[2][lamination] += 1;

        if ( !wrapper.interior[0][interior] ) {
          wrapper.interior[0][interior] = 0;
        }
        if ( !wrapper.interior[1][interior] ) {
          wrapper.interior[1][interior] = 0;
        }
        if ( !wrapper.interior[2][interior] ) {
          wrapper.interior[2][interior] = 0;
        }
        wrapper.interior[0][interior] += quantity;
        wrapper.interior[1][interior] += feetOrSheets;
        wrapper.interior[2][interior] += 1;

        if ( !wrapper.impositionLayout[0][impositionLayout] ) {
          wrapper.impositionLayout[0][impositionLayout] = 0;
        }
        if ( !wrapper.impositionLayout[1][impositionLayout] ) {
          wrapper.impositionLayout[1][impositionLayout] = 0;
        }
        if ( !wrapper.impositionLayout[2][impositionLayout] ) {
          wrapper.impositionLayout[2][impositionLayout] = 0;
        }
        wrapper.impositionLayout[0][impositionLayout] += quantity;
        wrapper.impositionLayout[1][impositionLayout] += feetOrSheets;
        wrapper.impositionLayout[2][impositionLayout] += 1;

        if ( !wrapper.oneDigitZip[0][oneDigitZip] ) {
          wrapper.oneDigitZip[0][oneDigitZip] = 0;
        }
        if ( !wrapper.oneDigitZip[1][oneDigitZip] ) {
          wrapper.oneDigitZip[1][oneDigitZip] = 0;
        }
        if ( !wrapper.oneDigitZip[2][oneDigitZip] ) {
          wrapper.oneDigitZip[2][oneDigitZip] = 0;
        }
        wrapper.oneDigitZip[0][oneDigitZip] += quantity;
        wrapper.oneDigitZip[1][oneDigitZip] += feetOrSheets;
        wrapper.oneDigitZip[2][oneDigitZip] += 1;

        if ( !wrapper.serviceProvider[0][serviceProvider] ) {
          wrapper.serviceProvider[0][serviceProvider] = 0;
        }
        if ( !wrapper.serviceProvider[1][serviceProvider] ) {
          wrapper.serviceProvider[1][serviceProvider] = 0;
        }
        if ( !wrapper.serviceProvider[2][serviceProvider] ) {
          wrapper.serviceProvider[2][serviceProvider] = 0;
        }
        wrapper.serviceProvider[0][serviceProvider] += quantity;
        wrapper.serviceProvider[1][serviceProvider] += feetOrSheets;
        wrapper.serviceProvider[2][serviceProvider] += 1;
      } catch ( e ) {
        console.log(`Error setting up Wrapper Properties for a ManufacturingSet ${e.message}`);
      }

    }
  }

  getTypes(): string[] {
    return ManufacturingSetsComponent.types;
  }
  getCategories (list: any, type: any): string[] {
    return Object.keys(list[type][0]);
  }

  getCategoryData (list: any, type: any, dataIndex: any): number[] {
    return list[type][dataIndex];
  }

  getColor (type: any): any {
    return ManufacturingSetsComponent.colors[type];
  }

  getIcons (type: any): any {
    return ManufacturingSetsComponent.icons[type];
  }

  show(list: any): boolean {
    if ( list instanceof ManufacturingSet ) {
      const manufacturingSet: ManufacturingSet = list;
      if ( manufacturingSet.openStatus === 'OPEN' && !this.showOpenSets) {
        return false;
      }
      if ( manufacturingSet.openStatus === 'LOCKED' && !this.showLockedSets) {
        return false;
      }
      if ( manufacturingSet.openStatus === 'CLOSED' && !this.showClosedSets) {
        return false;
      }
      if ( manufacturingSet.printNodeOrderItems.length === 0 && !this.showEmptySets) {
        return false;
      }
    }
    return true;
  }

  getManufacturingTask(manufacturingSet: ManufacturingSet, useType: string): ManufacturingTask {
    if ( manufacturingSet && manufacturingSet.manufacturingTasks ) {
      for ( const manufacturingTask of manufacturingSet.manufacturingTasks ) {
        if ( manufacturingTask.useType === useType) {
          return manufacturingTask;
        }
      }
    }
    return undefined;
  }

  canStart (manufacturingTask: ManufacturingTask ): boolean {
    if ( manufacturingTask.useType === 'BodyPrint' ||  manufacturingTask.useType === 'CoverPrint' ||  manufacturingTask.useType === 'Pack') {
      if ( manufacturingTask.inputStatus === 'COMPLETE' ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  canCompleteWithoutStarting (manufacturingTask: ManufacturingTask ): boolean {
    if ( manufacturingTask.useType === 'BodyPrint' ||  manufacturingTask.useType === 'CoverPrint' ||  manufacturingTask.useType === 'Pack') {
      return false;
    }
    return true;
  }

  canReprint (manufacturingTask: ManufacturingTask ): boolean {
    if ( manufacturingTask.useType === 'BodyPrint' ||  manufacturingTask.useType === 'CoverPrint' ||  manufacturingTask.useType === 'Pack') {
      if ( manufacturingTask.inputStatus === 'COMPLETE' ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  start (manufacturingTask: ManufacturingTask, printer: string) {
    if ( manufacturingTask.useType === 'BodyPrint' ||  manufacturingTask.useType === 'CoverPrint' ||  manufacturingTask.useType === 'Pack') {
      if ( manufacturingTask.inputStatus === 'COMPLETE' ) {
        this.print(manufacturingTask, printer); // TODO: We could put to start buttons for the case where there are two printers
      }
    }
  }

  pause (manufacturingTask: ManufacturingTask ) {
    manufacturingTask.status = 'PAUSED';
    this.manufacturingTaskService.updateItem(manufacturingTask).subscribe( changedManufacturingTask => {
      manufacturingTask.status = changedManufacturingTask.status;
    });
  }

  continue (manufacturingTask: ManufacturingTask ) {
    manufacturingTask.status = 'STARTED';
    this.manufacturingTaskService.updateItem(manufacturingTask).subscribe( changedManufacturingTask => {
      manufacturingTask.status = changedManufacturingTask.status;
    });
  }

  reset (manufacturingTask: ManufacturingTask ) {
    manufacturingTask.status = 'INITIAL';
    this.manufacturingTaskService.updateItem(manufacturingTask).subscribe( changedManufacturingTask => {
      manufacturingTask.status = changedManufacturingTask.status;
    });
  }

  complete (manufacturingTask: ManufacturingTask ) {
    manufacturingTask.status = 'COMPLETED';
    this.manufacturingTaskService.updateItem(manufacturingTask).subscribe( changedManufacturingTask => {
      manufacturingTask.status = changedManufacturingTask.status;
      manufacturingTask.manufacturingSet.recomputeNextManufacturingTasks();
    });
  }

  print (manufacturingTask: ManufacturingTask, printer: string) {
    manufacturingTask.status = 'SENT';
    this.manufacturingTaskService.print(manufacturingTask, printer).subscribe( changedManufacturingTask => {
      manufacturingTask.status = changedManufacturingTask.status;
      console.log(`Perpare Response for ManufacturingSet : ${manufacturingTask.ID} ${manufacturingTask.inputStatus}`);
    });
  }

  reprint (manufacturingTask: ManufacturingTask, printer: string) {
    manufacturingTask.status = 'STARTED';
    this.manufacturingTaskService.print(manufacturingTask, printer).subscribe( changedManufacturingTask => {
      manufacturingTask.status = changedManufacturingTask.status;
      console.log(`Perpare Response for ManufacturingSet : ${manufacturingTask.ID} ${manufacturingTask.inputStatus}`);
    });
  }

  refresh () {
    this.getData();
  }

  getManufacturingTaskStatusBackgroundColor (manufacturingTask: ManufacturingTask) {
    switch ( manufacturingTask.status ) {
      case 'INITIAL': {

      }
    }
  }
}
