<div class="accountSection">
  <h2  i18n="@@Contact Information">Contact Information</h2>
  <p>Contact paragraph here.</p>
<!--  <p><button mat-button color="accent" (click)="showCreditCardForm = !showCreditCardForm" i18n="@@Setup your subscription payment information">Setup your subscription payment information</button></p>-->
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title i18n="@@Contact Information">Contact Information</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>AAA</div>
    </mat-card-content>
    <mat-card-actions>
<!--      <button *ngIf="recurring && !showCreditCardForm" mat-flat-button color="primary" (click)="showCreditCardForm = !showCreditCardForm" i18n="@@Add">Add</button>-->
<!--      <button *ngIf="recurring && recurring.cardNumber && !showCreditCardForm" mat-stroked-button color="primary" (click)="showCreditCardForm = !showCreditCardForm" i18n="@@Edit">Edit</button>-->
    </mat-card-actions>
  </mat-card>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title i18n="@@Return Address">Return Address</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>BBB</div>
    </mat-card-content>
    <mat-card-actions>
      <!--      <button *ngIf="recurring && !showCreditCardForm" mat-flat-button color="primary" (click)="showCreditCardForm = !showCreditCardForm" i18n="@@Add">Add</button>-->
      <!--      <button *ngIf="recurring && recurring.cardNumber && !showCreditCardForm" mat-stroked-button color="primary" (click)="showCreditCardForm = !showCreditCardForm" i18n="@@Edit">Edit</button>-->
    </mat-card-actions>
  </mat-card>
</div>
