import {Location} from '@angular/common';
import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { NavbarService } from '../../service-ui/navbar.service';
import { ProjectNavbarService } from '../../service-ui/project.navbar.service';
import {ActivatedRoute, Router} from "@angular/router";
import {UserLoginService} from "../../service-auth/user-login.service";
import {StartupService} from "../../service-ui/startup.service";
import {InteriorTheme} from "../../../model/editor/interior-theme/InteriorTheme";
import {ThemeService} from "../../service-data/theme.service";
import {EditorProjectService} from "../../service-data/editor/editor-project.service";
import {CoverTheme} from "../../../model/editor/CoverTheme";
import {Project} from "../../../model/editor/Project";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit, OnChanges {

  state: string;

  showLanguagesMenu = false;

  showInteriorToolsContainer: boolean = false;

  constructor(public navbarService: NavbarService, public projectNavbarService: ProjectNavbarService, public startupService: StartupService, public userService: UserLoginService, public router: Router, private route: ActivatedRoute, private themeService: ThemeService, private projectService: EditorProjectService, public location: Location) {
    // Listen for changes in the state of the NavbarService -- values are set in each component
    navbarService.navState.subscribe( (state) => this.state = state );
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("navigation changes");
  }

  toggleFilterState() {
    this.projectNavbarService.toggleFilterState();
  }

  logout() {
    this.startupService.ready = 'NOT_READY';
    this.userService.logout();
    this.router.navigate(['/home']);
  }
}
