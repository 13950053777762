import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {IssueService} from "../../service-data/issue.service";
import {Issue} from "../../../model/Issue";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {IssueComponent} from "../issue/issue.component";
import {LanguagePipe} from "../../pipe/language.pipe";

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.css']
})

export class IssuesComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [
    { property: 'ID',                    columnDef: 'Id',            header: 'Id',              sort: true,  type: 'number',  filterType: 'NumericRange', style: 'text-align: right',  cell: (element: any) => `${element.ID}`},
    { property: 'status',                columnDef: 'status',        header: 'Status',          sort: true,  type: 'string',  filterType: 'String',       style: 'text-align: right',  cell: (element: any) => `${LanguagePipe.get(element.status, 'compressSpaces')}`},
    { property: 'created',               columnDef: 'created',       header: 'Created',         sort: true,  type: 'date',    filterType: 'NumericRange', style: 'text-align: right',  cell: (element: any) => `${element.created}`, dateFormat: 'MM/dd'},
    { property: 'assignedTo',            columnDef: 'assignedTo',    header: 'Assigned To',     sort: true,  type: 'string',  filterType: 'String',       style: 'text-align: right',  cell: (element: any) => `${LanguagePipe.get(element.assignedTo, 'compressSpaces')}`},
    { property: 'userRole',              columnDef: 'userRole',      header: 'User Type',       sort: true,  type: 'string',  filterType: 'String',       style: 'text-align: right',  cell: (element: any) => `${element.userRole}`},
    { property: 'userId',                columnDef: 'userId',        header: 'User Id',         sort: true,  type: 'number',  filterType: 'NumericRange', style: 'text-align: right',  cell: (element: any) => `${element.userId}`},
    { property: 'objectType',            columnDef: 'objectType',    header: 'Data Type',       sort: true,  type: 'string',  filterType: 'String',       style: 'text-align: right',  cell: (element: any) => `${LanguagePipe.get(element.objectType, 'compressSpaces')}`},
    { property: 'objectId',              columnDef: 'objectId',      header: 'Data Id',         sort: true,  type: 'number',  filterType: 'NumericRange', style: 'text-align: right',  cell: (element: any) => `${element.objectId}`},
    { property: 'supportStatus',         columnDef: 'supportStatus', header: 'Support Status',  sort: true,  type: 'string',  filterType: 'String',                                    cell: (element: any) => `${LanguagePipe.get(element.supportStatus, 'compressSpaces')}`, styleX: (element: any) => `${"background-color: " + this.supportStatusColors[element.supportStatus] }` },
    { property: 'supportPerson',         columnDef: 'supportPerson', header: 'Support Person',  sort: true,  type: 'string',  filterType: 'String',       style: 'text-align: right',  cell: (element: any) => `${element.supportPerson}`},
    { property: 'supportNote',           columnDef: 'supportNote',   header: 'Support Note',    sort: true,  type: 'string',  filterType: 'String',       style: 'text-align: right',  cell: (element: any) => `${element.supportNote}`},
    { property: 'userStatus',            columnDef: 'userStatus',    header: 'User Status',     sort: true,  type: 'string',  filterType: 'String',                                    cell: (element: any) => `${LanguagePipe.get(element.userStatus, 'compressSpaces')}`, styleX: (element: any) => `${"background-color: " + this.userStatusColors[element.userStatus] }` },
    { property: 'userPerson',            columnDef: 'userPerson',    header: 'User Person',     sort: true,  type: 'string',  filterType: 'String',       style: 'text-align: right',  cell: (element: any) => `${element.userPerson}`},
    { property: 'userNote',              columnDef: 'userNote',      header: 'User Note',       sort: true,  type: 'string',  filterType: 'String',       style: 'text-align: right',  cell: (element: any) => `${element.userNote}`}
  ];

  supportStatusColors: any = {
    'INITIAL': '',
    'ASSIGNED': 'lightcoral',
    'ACKNOWLEDGED': StartupService.colorAliases['errorBackground'],
    'WORKING': 'yellow',
    'RESOLVED': 'lightgreen',
    'BLOCKED': 'orange',
    'CANCELED': 'lightgrey',
    'WAITINGRESPONSE': 'lightsalmon'
  };

  userStatusColors: any = {
    'INITIAL': '',
    'ASSIGNED': 'lightcoral',
    'ACKNOWLEDGED': StartupService.colorAliases['errorBackground'],
    'WORKING': 'yellow',
    'RESOLVED': 'lightgreen',
    'BLOCKED': 'orange',
    'CANCELED': 'lightgrey',
    'WAITINGRESPONSE': 'lightsalmon'
  };
  eventHandler: EventHandler;

  public issues: Issue[];
  public dataSource = new MatTableDataSource<Issue>();
  public renderedData: Issue[];
  tableFilterManager: TableFilterManager<Issue> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav') mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor(public router: Router, public startupService: StartupService, private issueService: IssueService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.addRoleChangeListener(this);
    this.startupService.touchLastUserActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => this.renderedData = d);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: Issue, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
      };
    // this.dataSource.paginator = this.paginator;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  match(o: Issue ): boolean {
    if ( o ) {
      return true;
    }
    return false;
  }

  addUpdate(o: Issue) {
    if ( this.match(o)) {
      if ( this.dataSource.data.findIndex(item => item.getId() === o.getId()) === -1) {
        this.dataSource.data.push(o);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      } else {
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
    } else {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
    }
  }

  getData(): void {
    this.startupService.touchLastUserActivity();
    this.issueService.getAddNotifier(true).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.issueService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.issueService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
    });
    this.issueService.objects.forEach( o => {
      this.addUpdate(o);
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public event(action: string, data: any, event) {

  }

  refresh () {
    this.getData();
  }
}
