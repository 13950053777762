import {Component, Inject, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../../pipe/language.pipe";
import {UserService} from "../../../../service-data/user.service";
import {Product} from "../../../../../model/Product";
import {FacilityInventoryProduct} from "../../../../../model/FacilityInventoryProduct";
import {FacilityInventoryProductService} from "../../../../service-data/facility-inventory-product.service";
import {Organization} from "../../../../../model/Organization";
import {OrganizationService} from "../../../../service-data/organization.service";
import {ProductService} from "../../../../service-data/product.service";
import {MatTableDataSource} from "@angular/material/table";
import {Order} from "../../../../../model/Order";
import {OrderDeliverTo} from "../../../../../model/OrderDeliverTo";
import {OrderItem} from "../../../../../model/OrderItem";
import {OrderDeliverToService} from "../../../../service-data/order-deliver-to.service";
import {OrderService} from "../../../../service-data/order.service";
import {FacilityService} from "../../../../service-data/facility.service";

@Component({
  selector: 'app-place-replenishment-order',
  templateUrl: './place-replenishment-order-component.html',
  styleUrls: ['./place-replenishment-order-component.css']
})
export class PlaceReplenishmentOrderComponent implements OnInit {
  public products: number[]; // Product[];
  public organizations: Organization[];
  public facilityInventoryProduct: FacilityInventoryProduct;
  public breakpoint: number; // Breakpoint observer code
  public quantity: number = 0;
  public batch: string;
  // public facilityId: number;
  public product_ID: number = 0;
  public formGroup: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public facilityService: FacilityService, public orderService: OrderService, public orderDeliverToService: OrderDeliverToService, public productService: ProductService, public facilityInventoryProductService: FacilityInventoryProductService, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<PlaceReplenishmentOrderComponent>) {
    this.facilityInventoryProduct = data.facilityInventoryProduct;
    this.products =  [];
    this.facilityInventoryProduct.productIds.split(',').forEach( product_ID => {
      this.products.push(Number(product_ID));
    });
    this.quantity = Math.max(this.facilityInventoryProduct.reorderQuantity, Math.ceil((this.facilityInventoryProduct.reorderQuantity + this.facilityInventoryProduct.quantityCommited - this.facilityInventoryProduct.quantityOnOrder - this.facilityInventoryProduct.quantityOnHand) / 4 ) * 4);
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      // facilityId: [this.facilityId, [Validators.required]],
      quantity: [this.quantity, [Validators.required]],
      batch: [this.batch, [Validators.required]],
      product_ID: [this.product_ID, [Validators.required]]
    });

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onSave(): void {
    this.facilityService.getItem(Number(this.facilityInventoryProduct.printnode_ID)).subscribe(facility => {
      const orderx = new Order(undefined);
      if (this.facilityInventoryProduct.product) {
        orderx.org_ID = this.facilityInventoryProduct.product.org_ID;
        const customerOrderIdDate = new Date();
        orderx.customerorderid = 'Replenishment-' + (customerOrderIdDate.getMonth() + 1) + '.' + (customerOrderIdDate.getDate() + 1) + '.' + customerOrderIdDate.getFullYear() + '.' + Math.floor(Math.random() * 1000); // this.orderStrategyFormGroup.controls.firstPartOfOrderId.value; // this.firstPartOfOrderId; // Add the current date and/or the org_id and or the umbrella to the id?
        orderx.inventoryType = 'REPLENISH_INVENTORY';
        orderx.replenishmentFacility_ID = this.facilityInventoryProduct.printnode_ID;
        this.orderService.addOrder(orderx).subscribe(order => {
          if (order && order.ID ) {
            const orderDeliverTo = new OrderDeliverTo(undefined);
            orderDeliverTo.inventoryType = order.inventoryType;
            orderDeliverTo.order_ID = order.ID;

            orderDeliverTo.title = '';
            orderDeliverTo.firstname = this.facilityInventoryProduct.title;
            orderDeliverTo.lastname = this.formGroup.controls.batch.value;
            // orderDeliverTo.company = this.facility;
            orderDeliverTo.address1 = facility.toAddress1;
            orderDeliverTo.address2 = facility.toAddress2;
            orderDeliverTo.address3 = this.facilityInventoryProduct.location + ' ' + this.facilityInventoryProduct.shelf;
            orderDeliverTo.city = facility.toCity;
            orderDeliverTo.state = facility.toState;
            orderDeliverTo.postalcode = facility.toPostalCode;
            orderDeliverTo.country = facility.toCountry;
            orderDeliverTo.carrier = 'INPLANT';
            orderDeliverTo.servicelevel = 'PICKUP';
            // orderDeliverTo.requestedPrintNodeName = '' + this.facilityId;
            orderDeliverTo.replenishmentFacility_ID = this.facilityInventoryProduct.printnode_ID;
            const orderItem = new OrderItem( undefined );
            orderItem.product_ID = this.formGroup.controls.product_ID.value; //  facilityInventoryProduct.product_ID;
            orderItem.quantity = this.formGroup.controls.quantity.value;
            orderDeliverTo.orderItems.push(orderItem);
            this.orderDeliverToService.addOrderDeliverTo(orderDeliverTo).subscribe(createdOrderDeliverTo => {
              if (createdOrderDeliverTo) {
                console.log(`Saved an OrderDeliverTo ${JSON.stringify(order)}`);
              } else {
                console.log(`Trouble saving an OrderDeliverTo ${JSON.stringify(order)}`);
              }
            });
          }
        });
      }
    });
    this.markAsDirty(this.formGroup);
    this.dialogRef.close();
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
