import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {UserRegistrationService} from "../../service-auth/user-registration.service";
import {UserLoginService} from "../../service-auth/user-login.service";
import {CognitoCallback} from "../../service-auth/cognito.service";
import {environment as env} from "../../../environments/environment";

export class NewPasswordUser {
    username: string;
    existingPassword: string;
    password: string;
}
/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
    selector: 'app-account',
    templateUrl: './newpassword.html',
    styleUrls: ['../component-account.scss']

})
export class NewPasswordComponent implements CognitoCallback {
    environment = env;
    registrationUser: NewPasswordUser;
    router: Router;
    errorMessage: string;

    constructor(public userRegistration: UserRegistrationService, public userService: UserLoginService, router: Router) {
        this.router = router;
        this.onInit();
    }

    onInit() {
        this.registrationUser = new NewPasswordUser();
        this.errorMessage = null;
    }

    onRegister() {
        console.log(this.registrationUser);
        this.errorMessage = null;
        this.userRegistration.newPassword(this.registrationUser, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { // error
            this.errorMessage = message;
            console.log("result: " + this.errorMessage);
        } else { // success
            // move to the next step
            console.log("redirecting");
            this.router.navigate(['/secure']);
        }
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
          this.router.navigate(['/secure']);
        }
    }
}
