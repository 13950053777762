import {Product} from "./Product";
import {SimplePackage} from "./SimplePackage";
import {DataObject} from "./DataObject";
import {Package} from "./Package";
import {OrderItem} from "./OrderItem";

export class OrderItemToPackage implements DataObject {
  orderitem_ID: number;
  printnode_ID: number;
  package_ID: number;
  quantity: number;
  lastUpdate: Date;
  package: Package;
  orderItem: OrderItem;

  constructor( obj ) {
    this.orderitem_ID = 0;
    this.printnode_ID = 0;
    this.package_ID = 0;
    this.quantity = 0;
    this.lastUpdate = new Date();

    this.update(obj);
    // if ( obj ) {
    //   for ( const prop of Object.keys(obj) ) {
    //     this[prop] = obj[prop];
    //   }
    //   // if ( obj.product ) {
    //   //   this.xpackage = new SimplePackage( obj.xpackage );
    //   // } else {
    //   //   this.xpackage = new SimplePackage( undefined );
    //   // }
    // } else {
    //   // this.xpackage = new SimplePackage( undefined );
    // }
  }

  getId() {
    return this.package_ID + 100000 * this.orderitem_ID;
  }

  getOrderValue() {
    return this.package_ID + 100000 * this.orderitem_ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
