import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableFilterManager} from "../../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../../table/EventHandler";
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../../service-data/print-node-order.service";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {ProductService} from "../../../service-data/product.service";
import {Product} from "../../../../model/Product";
import {Order} from "../../../../model/Order";

@Component({
  selector: 'app-print-node-orders-admin-contained',
  templateUrl: './print-node-orders-admin-contained.component.html',
  styleUrls: ['./print-node-orders-admin-contained.component.css']
})

export class PrintNodeOrdersAdminContainedComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {
  @Input() order: Order;

  columns = [
    { columnDef: 'ID',               header: 'ID',               sort: true,  type: 'number',    filterType: 'String',          style: 'text-align: right',  property: 'ID',                                                                 cell: (element: any) => `${element.ID}`  },
    // { columnDef: 'orderId',          header: 'Order ID',         sort: true,  type: 'number',    filterType: 'String',          style: 'text-align: right',  property: 'order_ID',                                                        cell: (element: any) => `${element.order_ID}`  },
    // { columnDef: 'created',          header: 'Created',          sort: true,  type: 'date',      filterType: 'String',          style: 'text-align: center', property: 'created',                                                         cell: (element: any) => `${element.created ? element.created : new Date() }`, dateFormat: 'MM/dd/yy' },
    // { columnDef: 'tag',            header: 'Tag',              sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'tag',                                                               cell: (element: any) => `${element.tag}`  },
    // { columnDef: 'organizationId',   header: 'Org ID',           sort: true,  type: 'number',    filterType: 'String',          style: 'text-align: left',   property: 'ID',                                                              cell: (element: any) => `${ element.organization ? element.organization.ID : ''}`  },
    // { columnDef: 'organizationName', header: 'Organization',     sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'name',                                                            cell: (element: any) => `${ element.organization ? element.organization.name : ''}`  },
    { columnDef: 'name',             header: 'Name',             sort: true,  type: 'string',    filterType: 'CompositeString', style: 'text-align: left',   properties: ['firstname', 'lastname', 'company'],                               cell: (element: any) => `${element.firstname.toUpperCase()} ${element.lastname.toUpperCase()}`,                        cellRow2: (element: any) => `${element.company}`  },
    { columnDef: 'address',          header: 'Address',          sort: true,  type: 'string',    filterType: 'CompositeString', style: 'text-align: left',   properties: ['address1', 'address2', 'state', 'city', 'postalcode', 'country'], cell: (element: any) => `${element.address1.toUpperCase()} ${element.address2 ? element.address2.toUpperCase() : ''}`, cellRow2: (element: any) => `${element.city.toUpperCase()} ${element.state.toUpperCase()} ${element.postalcode ? element.postalcode : ''} ${element.country ? element.country.toUpperCase() : ''}`  },
    { columnDef: 'weight',           header: 'Weight',           sort: true,  type: 'number',    filterType: 'NumericRange',    style: 'text-align: right',  property: 'weight',                                                             cell: (element: any) => `${Math.round(element.weight * 10) / 10}` },
    // { columnDef: 'thickness',        header: 'Thickness',        sort: true,  type: 'number',    filterType: 'NumericRange',    style: 'text-align: right',  property: 'thickness',                                                       cell: (element: any) => `${Math.round(element.thickness * 10) / 10}` },
    { columnDef: 'quantity',         header: 'Quantity',         sort: true,  type: 'number',    filterType: 'NumericRange',    style: 'text-align: right',  property: 'quantity',                                                           cell: (element: any) => `${element.quantity}` },
    { columnDef: 'flags',            header: 'Flags',            sort: true,  type: 'iconToggleButton', filterType: 'Empty',   style: 'text-align: right',  property: 'tag4',                                                                cell: (element: any) => `${element.tag4 === 'DONTSHIP' ? 1 : (element.hold === 'CUSTOMER_REVIEW' ? 2 : ( element.printnode_ID === 0 ? 3 : (element.printnode_ID === 10000 ? 4 : 0 ))) }`,  buttonColor: ['warn', 'warn', 'warn', 'warn'], icon: ['content_copy','preview','cancel','question_answer'], clickAction: 'toggleX'  },
    { columnDef: 'invoiceId',        header: 'Invoice Id',       sort: true,  type: 'string',    filterType: 'String',         style: 'text-align: right',  property: 'invoiceId',                                                           cell: (element: any) => `${element.invoice_ID ? '' : element.invoice_ID }` },
    { columnDef: 'packingCharge',    header: 'Packing',          sort: true,  type: 'currency',  filterType: 'NumericRange',   style: 'text-align: right',  property: 'packingCharge',                                                       cell: (element: any) => `${element.packingCost}`, displayClass: 'USCurrency' },
    { columnDef: 'shippingCharge',   header: 'Shipping',         sort: true,  type: 'currency',  filterType: 'NumericRange',   style: 'text-align: right',  property: 'shippingCharge',                                                      cell: (element: any) => `${element.shippingCharge}`, displayClass: 'USCurrency' },
    { columnDef: 'totalCharge',      header: 'Total',            sort: true,  type: 'currency',  filterType: 'NumericRange',   style: 'text-align: right',  property: 'shippingCharge',                                                     cell: (element: any) => `${element.packingCost + element.shippingCharge}`, displayClass: 'USCurrency' },
    // { columnDef: 'hold',             header: 'Hold',             sort: true,  type: 'iconToggleButton', filterType: 'Empty',          style: 'text-align: left',   property: 'hold',                                                               cell: (element: any) => `${element.hold === 'CUSTOMER_REVIEW' ? 'TRUE' : 'FALSE' }`,      trueButtonColor: 'warn',  falseButtonColor: 'accent', trueIcon: 'content_copy', falseIcon: '', clickAction: 'toggleX'  },
    // { columnDef: 'addressIssue',     header: 'Address',          sort: true,  type: 'iconToggleButton', filterType: 'Empty',          style: 'text-align: left',   property: 'printnode_ID',                                                       cell: (element: any) => `${element.printnode_ID === 0 ? 'TRUE' : 'FALSE' }`,      trueButtonColor: 'warn',  falseButtonColor: 'accent', trueIcon: 'cancel', falseIcon: '', clickAction: 'toggleX'  },
    // { columnDef: 'canceled',         header: 'Canceled',         sort: true,  type: 'iconToggleButton',  filterType: 'Empty',          style: 'text-align: left',   property: 'printnode_ID',                                                       cell: (element: any) => `${element.printnode_ID === 10000 ? 'TRUE' : 'FALSE' }`,  trueButtonColor: 'warn',  falseButtonColor: 'accent', trueIcon: 'question_answer', falseIcon: '', clickAction: 'toggleX'  },
    // { columnDef: 'shippingStatus',   header: 'Shipping Status',  sort: true,  type: 'iconToggleButton', filterType: 'Empty',          style: 'text-align: left',   property: 'shippingStatus',                                              cell: (element: any) => `${element.shippingStatus === 'SHIPPED' ? 'TRUE' : 'FALSE' }`,  trueButtonColor: 'primary',  falseButtonColor: 'warn', trueIcon: 'local_shipping', falseIcon: '', clickAction: 'toggleX'  },
    // {
    //   property: 'shippingStatus',
    //   columnDef: 'shippingStatus',
    //   header: 'Shipped',
    //   sort: true,
    //   type: 'iconToggleButton',
    //   filterType: 'Empty',
    //   style: 'text-align: right',
    //   cell: (element: any) =>
    //     `${element.shippingStatus === 'SHIPPED' ? 'TRUE' : 'FALSE' }`,
    //   trueButtonColor: 'accent',
    //   falseButtonColor: 'warn',
    //   trueIcon: 'group',
    //   falseIcon: 'not_interested',
    //   clickAction: 'toggleX'
    // },
    // { columnDef: 'canceled',         header: 'Canceled',         sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'printnode_ID',                                                       cell: (element: any) => `${element.printnode_ID === 0 ? 'Y' : ''}`},
    // { columnDef: 'fingerprint',      header: 'Fingerprint',      sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'tag2',                                                               cell: (element: any) => `${element.tag2}`},
    // { columnDef: 'addressIssue',     header: 'Address',          sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'printnode_ID',                                                       cell: (element: any) => `${element.printnode_ID === 10000 ? 'Y' : ''}`},
    // { columnDef: 'carrier',          header: 'Carrier',          sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'carrier',                                                            cell: (element: any) => `${element.servicelevel}` },
    // { columnDef: 'shippingStatus',   header: 'Shipping Status',  sort: true,  type: 'iconToggleButton', filterType: 'Empty',          style: 'text-align: left',   property: 'shippingStatus',                                              cell: (element: any) => `${element.shippingStatus === 'SHIPPED' ? true : false}`,  trueButtonColor: 'accent',  falseButtonColor: 'warn', trueIcon: 'local_shipping', falseIcon: '', clickAction: 'toggleX'  },
    // { columnDef: 'trackingNumbers', header: 'Tracking Numbers', sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'trackingNumbers',                                                    cell: (element: any) => `${element.trackingNumbers}`},
    { columnDef: 'trackingNumbers',  header: 'Tracking Numbers', sort: true,  type: 'link',      filterType: 'String',          style: 'text-align: left',   property: 'trackingNumbers',                                                    cell: (element: any) => `${element.shippingStatus === 'NONE' ? '' : element.trackingNumbers}`,   link: (element: any) => {
        const trackingNumbers = element.trackingNumbers; // .split(' ').join('_');
        if ( element.shippingStatus === 'NONE' ) {
          return '';
        }
        if (trackingNumbers.startsWith('94')) {
          return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + trackingNumbers.split(/_(.+)/)[0]; // trackingNumbers.split(',').join('%20');
        } else if (trackingNumbers.startsWith('1Z')) {
          return 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + trackingNumbers.split(/_(.+)/)[0]; // .split(',').join('%20');
        } else if (element.carrier === 'FEDEX') {
          return 'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=' + trackingNumbers.split(/_(.+)/)[0];
        } else {
          return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + trackingNumbers.split(/_(.+)/)[0]; // trackingNumbers.split(',').join('%20');
        }
      }
    }
  ];

  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<PrintNodeOrder>();
  public renderedData: PrintNodeOrder[];
  public products: Product[];
  tableFilterManager: TableFilterManager<PrintNodeOrder> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  // mobileQuery: MediaQueryList;
  // private _mobileQueryListener: () => void;

  role: string;
  shippedFilter: string = 'NONE';
  holdFilter: string = 'FLAGGED';
  duplicateFilter: string = 'NONE';
  minDate: Date;
  maxDate: Date;


  constructor(public router: Router, public startupService: StartupService, private printNodeOrderService: PrintNodeOrderService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public productService: ProductService) {
    this.startupService.addRoleChangeListener(this);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the printNodeOrder is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      this.renderedData = this.dataSource.data;
    });
  }

  ngAfterViewInit(): void {
    this.getData();
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: PrintNodeOrder, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
    // this.dataSource.paginator = this.paginator;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  getData(): void {
    const filter = { eq: {
      },
      ge: {
      },
      ne: {
      }
    };

    // switch ( this.shippedFilter ) {
    //   case 'SHIPPED': {
    //     filter['eq']['printNodeOrder.shippingStatus'] = 'SHIPPED';
    //     break;
    //   }
    //   case 'NONE': {
    //     filter['eq']['printNodeOrder.shippingStatus'] = 'NONE';
    //     break;
    //   }
    //   case 'ALL': {
    //     delete filter['eq']['printNodeOrder.shippingStatus'];
    //     break;
    //   }
    // }
    // switch ( this.holdFilter ) {
    //   case 'FLAGGED': {
    //     filter['eq']['printNodeOrder.hold'] = 'CUSTOMER_REVIEW';
    //     break;
    //   }
    //   case 'NONE': {
    //     filter['ne']['printNodeOrder.hold'] = 'CUSTOMER_REVIEW';
    //     break;
    //   }
    //   case 'ALL': {
    //     delete filter['eq']['printNodeOrder.hold'];
    //     delete filter['ne']['printNodeOrder.hold'];
    //     break;
    //   }
    // }
    // switch ( this.duplicateFilter ) {
    //   case 'DUPLICATE': {
    //     filter['eq']['printNodeOrder.tag4'] = 'DONTSHIP';
    //     break;
    //   }
    //   case 'NONE': {
    //     filter['ne']['printNodeOrder.tag4'] = 'DONTSHIP';
    //     break;
    //   }
    //   case 'ALL': {
    //     delete filter['eq']['printNodeOrder.tag4'];
    //     delete filter['ne']['printNodeOrder.tag4'];
    //     break;
    //   }
    // }
    // if ( this.holdFilter === 'ALL' && this.duplicateFilter === 'ALL' && this.shippedFilter === 'ALL' ) {
    filter['eq']['printNodeOrder.order_ID'] = this.order.ID;
    // this.role = this.printNodeOrderService.getRole(JSON.stringify(filter));
    this.printNodeOrderService.getFilteredItems(filter)
      .subscribe(printNodeOrders => {
        this.dataSource.data = printNodeOrders;
        this.dataSource.filter = '' + Math.random();
        // this.table.renderRows();
    });
    this.productService.getItems()
      .subscribe(products => {
        this.products = products.sort((a, b) => b.book.tagId - a.book.tagId);
      });
  }

  ngOnDestroy(): void {
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  refresh () {
    this.printNodeOrderService.reset();
    this.getData();
  }

  dateChangeEvent(event: MatDatepickerInputEvent<Date>) {
    console.log(`dateChangeEvent: ${event.value}`);
    this.minDate = event.value;
    this.getData();
  }

  toggleShippingFilter() {
    switch ( this.shippedFilter ) {
      case 'SHIPPED': {
        this.shippedFilter = 'NONE';
        break;
      }
      case 'NONE': {
        this.shippedFilter = 'ALL';
        break;
      }
      case 'ALL': {
        this.shippedFilter = 'SHIPPED';
        break;
      }
    }
  }

  toggleHoldsFilter() {
    switch ( this.holdFilter ) {
      case 'FLAGGED': {
        this.holdFilter = 'NONE';
        break;
      }
      case 'NONE': {
        this.holdFilter = 'ALL';
        break;
      }
      case 'ALL': {
        this.holdFilter = 'FLAGGED';
        break;
      }
    }
  }
  toggleDuplicatesFilter() {
    switch ( this.duplicateFilter ) {
      case 'DUPLICATE': {
        this.duplicateFilter = 'NONE';
        break;
      }
      case 'NONE': {
        this.duplicateFilter = 'ALL';
        break;
      }
      case 'ALL': {
        this.duplicateFilter = 'DUPLICATE';
        break;
      }
    }
  }

  public toggleX = (id: string) => {
    console.log("toggleAdmin: " + id);
    // this.organizationService.makeOrganizationAdmin(user.organization, user);
  }
}
