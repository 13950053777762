import {Criteria} from "./Criteria";

export class CriteriaSet {
    criteria: Criteria[] = [];
  constructor (obj) {
    this.criteria = [];
    if (obj && obj.criteria ) {
      for (const o of obj.criteria ) {
        this.criteria.push(new Criteria(o));
      }
    }
  }
}
