import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import {AbstractDataService} from "./abstract-data.service";
import {CsvImportSettings} from "../../model/CsvImportSettings";
// https://coryrylan.com/blog/angular-observable-data-services
@Injectable()
export class CsvImportSettingsService extends AbstractDataService<CsvImportSettings> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService) {
    super(httpa, messageServicea, startupServicea);
  }

  makeObjectInstance(args: any): CsvImportSettings {
    const object = new CsvImportSettings(args);
    return object;
  }

  supportsParallel(): boolean {
    return false;
  }

  getLoggingObjectTypeName (): string {
    return "CsvImportSettings";
  }

  getURLEndPoint (): string {
    return "csvimportsettings";
  }
}
