<div class="projectThumbnail">
<!--  <img src="{{project.getCoverImageUrl()}}" class="image" i18n-alt="@@Your book is being prepared and will be ready soon." alt="Your book is being prepared and will be ready soon.">-->
  <div class="hoveringDisplayCommands">
    <button mat-raised-button style="width:100%" color="primary" type="button" (click)="viewProject(project)" title="EditProjectToolTip" i18n-title="@@EditProjectToolTip"><mat-icon>edit</mat-icon><span> </span><ng-container i18n="@@Edit">Edit</ng-container></button>
    <button mat-raised-button style="width:100%" color="primary" type="button" (click)="addToCart(project)" title="AddToCartToolTip" i18n-title="@@AddToCartToolTip"><ng-container i18n="@@Add to Cart">Add to Cart</ng-container></button>
  </div>
  <div *ngIf="project.status !== 'READY' && project.status !== 'SAVE_AND_RERENDER'  && project.status !== 'ERROR'" class="progress" style="margin-bottom: 0;">
    <div *ngIf="!project.coverNeedsSavingWithNewSpineSize" class="progress-bar" role="progressbar" [style.background]="'repeating-linear-gradient( 45deg, transparent, transparent ' + ( (  project.progress / 11 ) * 10 ) + 'px, #0275d8 10px, transparent 5px)'"></div>
    <div *ngIf="project.coverNeedsSavingWithNewSpineSize" class="progress-bar" role="progressbar" [style.background]="'repeating-linear-gradient( 45deg, transparent, transparent ' + ( (  project.progress / 11 ) * 10 ) + 'px, #d80202 10px, transparent 5px)'"></div>
  </div>
  <div *ngIf="project.status === 'SAVE_AND_RERENDER'" class="progress" style="margin-bottom: 0;">
    <div class="progress-bar" role="progressbar" [style.background]="'repeating-linear-gradient( 45deg, transparent, transparent 0px, orange 10px, transparent 5px)'"></div>
  </div>
  <div *ngIf="project.status === 'ERROR'" class="progress" style="margin-bottom: 0;">
    <div class="progress-bar" role="progressbar" [style.background]="'repeating-linear-gradient( 45deg, transparent, transparent 0px, red 10px, transparent 5px)'"></div>
  </div>
  <div class="projectThumbnailBottom1">
    <!--    <h5 style="font-size: 0.4em; margin-top: 3px; margin-bottom: 3px;">{{project.ID}}</h5>-->
    <h5 style="font-size: 0.4em; margin-top: 3px; margin-bottom: 3px;"><span i18n="@@Last Saved">Last Saved</span> {{project.id}}</h5>
    <h5 style="font-size: 0.7em; margin-top: -6px; margin-bottom: 3px;">{{project.timestamp | date: 'MM/dd/yy hh:mm:ss'}} {{project.pages}}</h5>
    <ng-container *ngIf="project.timestamp !== project.renderedSaveTimestamp || Math.abs( project.pages - project.pagesRenderedCoverIsBasedOn ) > 4 ">
      <h5 style="font-size: 0.4em; margin-top: -2px; margin-bottom: 3px;" i18n="@@Most Recent Cover Based On - Saved Time and Pages">Most Recent Cover Based On - Saved Time and Pages</h5>
      <h5 style="font-size: 0.7em; margin-top: -6px; margin-bottom: 3px;">{{project.renderedSaveTimestamp | date: 'MM/dd/yy hh:mm:ss'}} {{project.pagesRenderedCoverIsBasedOn}}</h5>
    </ng-container>
    <div style="position: absolute; bottom: 0px; left: 5px; width: calc(100% - 10px)">
      <!--<span *ngIf="renameProject">-->
      <!--<textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="text" i18n-title="@@Rename the project." title="Rename the project." [ngModel]="project.name" #placeHolderProjectName maxlength="40"></textarea>-->
      <!--&lt;!&ndash;<input type="text" i18n-title="@@Rename the project." title="Rename the project." [ngModel]="project.name" #placeHolderProjectName maxlength="32" size="18">&ndash;&gt;-->
      <!--<span *ngIf="placeHolderProjectName.value.trim()!==project.name">-->
      <!--<span i18n-title="@@Save the new name of the project." title="Save the new name of the project." *ngIf="!isProjectNameUsed()" (click)="renameProject = !renameProject; saveProjectValue();"><mat-icon>check</mat-icon></span>-->
      <!--</span>-->
      <!--<span i18n-title="@@Cancel the new name of the project." title="Cancel the new name of the project." (click)="renameProject = !renameProject; cancelProjectValue();"><mat-icon>close</mat-icon></span>-->
      <!--</span>-->
      <!--<ng-container *ngIf="!renameProject">-->
      <span>{{ (project.name.length>60)? (project.name | slice:0:60)+'..':(project.name) }}</span>
      <!--<span style="position:absolute;" (click)="renameProject = !renameProject" i18n-title="@@Rename Project" title="Rename Project"><mat-icon>edit</mat-icon></span>-->
      <span i18n-style="@@floatEnd" style="float:right" (click)="deleteProject(project)" i18n-title="@@Delete Project" title="Delete Project"><mat-icon>delete</mat-icon></span>
      <!--</ng-container>-->
    </div>
  </div>
</div>
