import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {DraftBody} from "../../../../../model/DraftBody";
import {StartupService} from "../../../../service-ui/startup.service";
import {Draft} from "../../../../../model/Draft";


@Component({
  selector: 'app-project-draft-body',
  templateUrl: './project-draft-body.component.html',
  styleUrls: ['./project-draft-body.component.css']
})
export class ProjectDraftBodyComponent implements OnInit {
  @Input() draft: Draft;
  @Input() draftBody: DraftBody;

  constructor (public router: Router, public startupService: StartupService) {
  }

  ngOnInit() {
    console.log("Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
    }
  }

  pageNumbers(countBy: number) {
    const pageNumbers = [];
    for ( let i = 0; i <= this.draftBody.numPages; i += countBy ) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }
}


