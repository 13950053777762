import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatTable} from "@angular/material/table";
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {FacilityService} from "../../service-data/facility.service";
import {Facility} from "../../../model/Facility";
import {MatDialog} from "@angular/material/dialog";
import {AddFacilityComponent} from "./add-facility/add-facility.component";
import {Subject} from "rxjs";
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.css']
})

export class FacilitiesComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {
  columns = [
     { property: 'ID',                         columnDef: 'ID',                         header: 'ID',                 sort: true,  type: 'number', filterType: 'Numeric',  style: 'display: flex; justify-content: space-around; text-align: right',  cell: (element: any) => `${element.ID}` },
     { property: 'name',                       columnDef: 'name',                       header: 'Name',               sort: true,  type: 'string', filterType: 'String',        style: 'text-align: right',  cell: (element: any) => `${element.name}` },
     { property: 'friendlyName',               columnDef: 'friendlyName',               header: 'Friendly Name',      sort: true,  type: 'number', filterType: 'Numeric',  style: 'text-align: right',  cell: (element: any) => `${element.friendlyName}` },
     { property: 'admin_ID',                   columnDef: 'admin_ID',                   header: 'Admin ID',           sort: true,  type: 'string', filterType: 'String',        style: 'text-align: right',  cell: (element: any) => `${element.admin_ID}` },
     // { property: 'org_ID',                     columnDef: 'administrationOrganizationId',               header: 'Admin Organization Id',       sort: true,  type: 'number', filterType: 'NumericRange',  style: 'text-align: right',  cell: (element: any) => `${element.org_id}` },
     // { property: 'administrationOrganization.name', columnDef: 'administrationOrganization', header: 'Admin Organization', sort: true,  type: 'string', filterType: 'String',  style: 'text-align: right',  cell: (element: any) => `${element.administrationOrganization.name}` },
     // { property: 'facility.Name',       columnDef: 'facilityName',        header: 'Service Provider',   sort: true,  type: 'string', filterType: 'String',  style: 'text-align: right',  cell: (element: any) => `${element.facility.Name}` },
     // { property: 'marginShare',                columnDef: 'marginShare',                header: 'Margin Share',       sort: true,  type: 'number', filterType: 'NumericRange',  style: 'text-align: right',  cell: (element: any) => `${element.marginShare}` },
     // { property: 'fixedShare',                 columnDef: 'fixedShare',                 header: 'Fixed Share',        sort: true,  type: 'number', filterType: 'NumericRange',  style: 'text-align: right',  cell: (element: any) => `${element.fixedShare}` }
    // // { property: 'active',            columnDef: 'active',       header: 'Active',       sort: true,  type: 'boolean',    filterType: 'Boolean',      style: 'text-align: right',  cell: (element: any) => `${element.active}` },
    // // { property: 'book.ID',           columnDef: 'previewCover', header: 'Preview',      sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.book.ID}`,  buttonColor: 'primary', icon: 'system_update',  clickAction: 'redirectToDelete'},
    // // { property: 'printNodeOrder.ID', columnDef: 'packButton',   header: 'Pack',         sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'},
    // { property: 'ID',                columnDef: 'resendInvite', header: 'Invite',       sort: true,  type: 'iconButton', filterType: 'Empty',        tyle: 'text-align: right',  cell: (element: any) => `${element.ID}`,  buttonColor: 'primary', icon: 'system_update',  clickAction: 'invite'},
    // { property: 'ID',                columnDef: 'delete',       header: 'Delete',       sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'}
  ];

  eventHandler: EventHandler;

   public dataSource = new MatTableDataSource<Facility>();
  public renderedData: Facility[];
  tableFilterManager: TableFilterManager<Facility> = new TableFilterManager(this.dataSource);

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor(public router: Router, public startupService: StartupService, private facilityService: FacilityService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public dialog: MatDialog) {
    this.startupService.addRoleChangeListener(this);
    // this.startupService.touchLastFacilityActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the facility is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      this.renderedData = this.dataSource.data;
    });

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: Facility, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  match(o: Facility ): boolean {
    return true;
  }

  addUpdate(o: Facility) {
    if ( this.match(o)) {
      if ( this.dataSource.data.findIndex(item => item.getId() === o.getId()) === -1) {
        this.dataSource.data.push(o);
      } else {
      }
    } else {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
      }
    }
    this.dataSource.sort = this.sort;
    this.dataSource.filter = '' + Math.random();
  }

  getData(): void {
    this.startupService.touchLastUserActivity();
    this.dataSource.data = [];
    this.facilityService.getAddNotifier(true).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.facilityService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.facilityService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
    });
    this.facilityService.objects.forEach( o => {
      this.addUpdate(o);
    });
  }
  //
  //
  //
  // getData(): void {
  //   // this.dataSource.data = [];
  //   this.facilityService.getItems()
  //     .subscribe(facilities => {
  //       // for (const facility of facilities) {
  //       //   // this.dataSource.data.push(facility);
  //       // }
  //       this.dataSource.filter = '' + Math.random();
  //       this.dataSource.data = facilities;
  //       // this.table.renderRows();
  //     });
  // }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  public openAddDialog (): void {
    const dialogRef = this.dialog.open(AddFacilityComponent,   {
      width: '640px', disableClose: false
    });
  }

}
