import {Component, Inject, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../../pipe/language.pipe";
import {UserService} from "../../../../service-data/user.service";
import {Product} from "../../../../../model/Product";
import {FacilityInventoryProduct} from "../../../../../model/FacilityInventoryProduct";
import {FacilityInventoryProductService} from "../../../../service-data/facility-inventory-product.service";
import {Organization} from "../../../../../model/Organization";
import {OrganizationService} from "../../../../service-data/organization.service";
import {ProductService} from "../../../../service-data/product.service";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-edit-facility-inventory-product-note',
  templateUrl: './edit-facility-inventory-product-note-component.html',
  styleUrls: ['./edit-facility-inventory-product-note-component.css']
})
export class EditFacilityInventoryProductNoteComponent implements OnInit {
  public facilityInventoryProduct: FacilityInventoryProduct;
  public breakpoint: number; // Breakpoint observer code
  public reason: string = ``;
  public note: string = ``;
  public formGroup: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public organizationService: OrganizationService, public productService: ProductService, public facilityInventoryProductService: FacilityInventoryProductService, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<EditFacilityInventoryProductNoteComponent>) {
    this.facilityInventoryProduct = data.facilityInventoryProduct;
    if ( this.facilityInventoryProduct.note.indexOf(':') >= 0 ) {
      if ( this.facilityInventoryProduct.note.substring(0, this.facilityInventoryProduct.note.indexOf(':')) === 'Question' ) {
        this.reason = 'Question';
      } else if ( this.facilityInventoryProduct.note.substring(0, this.facilityInventoryProduct.note.indexOf(':')) === 'Problem' ) {
        this.reason = 'Problem';
      } else {
        this.reason = 'Question';
      }
      this.note = this.facilityInventoryProduct.note.substring(this.facilityInventoryProduct.note.indexOf(':') + 1);
    } else {
      this.reason = 'Question';
      this.note = '';
    }
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      reason: [this.reason, [Validators.required]],
      note: [this.note, [Validators.required]]
    });

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onSave(): void {
    let note = this.formGroup.controls.reason.value + ': ' + this.formGroup.controls.note.value;
    if ( note.length > 120 ) {
      note = note.substring(0, 119);
    }
    this.facilityInventoryProduct.note = note;
    this.facilityInventoryProductService.updateItem(this.facilityInventoryProduct).subscribe( object => {
      console.log(`Updated note of FacilityInventoryProduct productId: ${object.product_ID}`);
    });
    this.markAsDirty(this.formGroup);
    this.dialogRef.close();
  }


  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
