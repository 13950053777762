<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-sidenav-container class="app-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #sideNav mode="side" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" opened="true">
      <mat-nav-list>
        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'objects' ? 'selected': ''" (click)="activeSideArea === 'objects' ? activeSideArea = '' : activeSideArea = 'objects'">
          <div class="centeredIcon">
            <mat-icon>layers</mat-icon>
            <span mat-list-text i18n="@@Objects">Objects</span>
          </div>
        </a>
        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'designs' ? 'selected': ''" (click)="activeSideArea === 'designs' ? activeSideArea = '' : activeSideArea = 'designs'">
          <div class="centeredIcon">
            <mat-icon>developer_board</mat-icon>
            <span mat-list-text i18n="@@Designs">Designs</span>
          </div>
        </a>
        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'fonts' ? 'selected': ''" (click)="activeSideArea === 'fonts' ? activeSideArea = '' : activeSideArea = 'fonts'">
          <div class="centeredIcon">
            <mat-icon>font_download</mat-icon>
            <span i18n="@@Fonts">Fonts</span>
          </div>
        </a>
<!--        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'images' ? 'selected': ''" (click)="activeSideArea === 'images' ? activeSideArea = '' : activeSideArea = 'images'">-->
<!--          <div class="centeredIcon">-->
<!--            <mat-icon>landscape</mat-icon>-->
<!--            <span i18n="@@Images">Images</span>-->
<!--          </div>-->
<!--        </a>-->
        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'colors' ? 'selected': ''" (click)="activeSideArea === 'colors' ? activeSideArea = '' : activeSideArea = 'colors'">
          <div class="centeredIcon">
            <mat-icon>palette</mat-icon>
            <span i18n="@@Colors">Colors</span>
          </div>
        </a>
        <!--<a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'texts' ? 'selected': ''" (click)="activeSideArea === 'texts' ? activeSideArea = '' : activeSideArea = 'texts'">-->
          <!--<div class="centeredIcon">-->
            <!--<mat-icon>picture_in_picture_alt</mat-icon>-->
            <!--<span i18n="@@Texts">Texts</span>-->
          <!--</div>-->
        <!--</a>-->
        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'trim' ? 'selected': ''" (click)="activeSideArea === 'trim' ? activeSideArea = '' : activeSideArea = 'trim'">
          <div class="centeredIcon">
            <mat-icon>crop</mat-icon>
            <span i18n="@@Trim Size">Trim Size</span>
          </div>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <ng-container *ngIf="startupService.selectedCover">
        <mat-sidenav-container class="main-content-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
          <mat-sidenav class="middleToolArea" [style.width.px]="activeSideArea === '' ? 0 : 340" #middleSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" opened="true">
            <mat-card appearance="outlined" *ngIf="activeSideArea === 'objects'" class="sideAreaMatCard">
              <mat-card-content>
                <span>
                  <h3><ng-container i18n="@@Objects">Objects</ng-container>
                    <span i18n-style="@@floatEnd" style="float:right">
                      <span *ngIf="startupService.getSubscription().getSubscriptionLevel().toString() === 'DESIGNER'" style="color: white;" (click)="startupService.showInactiveCoverThemeItems = !startupService.showInactiveCoverThemeItems"><mat-icon *ngIf="startupService.showInactiveCoverThemeItems" title="Hide Objects Not Included In This Cover Design" i18n-title="@@Hide Objects Not Included In This Cover Design">airline_seat_recline_normal</mat-icon><mat-icon *ngIf="!startupService.showInactiveCoverThemeItems" title="Show Objects Not Included In This Cover Design" i18n-title="@@Show Objects Not Included In This Cover Design">airline_seat_flat</mat-icon></span>
                    </span>
                  </h3>
                </span>

                <ng-container *ngFor="let coverThemeItemSetting of startupService.project.cover.coverTheme.objects">
                  <div class="sideAreaItem coverItem" *ngIf="coverThemeItemSetting.isActive() && coverThemeItemSetting.getRole() !== 'whole-hidden-image'" (click)="startupService.canvasManager.selectObjectBasedOnRole(coverThemeItemSetting.getRole())" [ngClass]="coverThemeItemSetting.getRole() === startupService.canvasManager.getRole() ? 'selected' : ''" [class.inverse]="startupService.selectedCover.getImagePropertyBoolean(coverThemeItemSetting.getRole(), 'hidden', coverThemeItemSetting.isHidden())">
                    <span (click)="startupService.canvasManager.selectObjectBasedOnRole(coverThemeItemSetting.getRole()); saveProject();">{{coverThemeItemSetting.getRole() | languageString }}</span>
                    <span *ngIf="startupService.showInactiveCoverThemeItems" i18n-style="@@floatEnd" style="float:right" (click)="coverThemeItemSetting.setActive('inactive'); startupService.coverThemeChange = startupService.coverThemeChange + 1; saveProject();"><mat-icon>airline_seat_recline_normal</mat-icon></span>
                    <span *ngIf="startupService.showInactiveCoverThemeItems" i18n-style="@@floatEnd" style="float:right" (click)="coverThemeItemSetting.setHidden(!coverThemeItemSetting.isHidden()); startupService.canvasManager.refreshObjectBasedOnRole(coverThemeItemSetting.getRole()); startupService.canvasManager.canvas.calcOffset(); startupService.canvasManager.canvas.renderAll(); ; saveProject();"><mat-icon color="accent" *ngIf="coverThemeItemSetting.isHidden()">visibility_off</mat-icon><mat-icon color="accent" *ngIf="!coverThemeItemSetting.isHidden()">visibility_on</mat-icon></span>
                    <span i18n-style="@@floatEnd" style="float:right" (click)="startupService.selectedCover.setImagePropertyBoolean(coverThemeItemSetting.getRole(), 'hidden', !startupService.selectedCover.getImagePropertyBoolean(coverThemeItemSetting.getRole(), 'hidden', coverThemeItemSetting.isHidden())); startupService.canvasManager.refreshObjectBasedOnRole(coverThemeItemSetting.getRole()); startupService.canvasManager.canvas.calcOffset(); startupService.canvasManager.canvas.renderAll(); ; saveProject();"><mat-icon *ngIf="startupService.selectedCover.getImagePropertyBoolean(coverThemeItemSetting.getRole(), 'hidden', coverThemeItemSetting.isHidden())">visibility_off</mat-icon><mat-icon *ngIf="!(startupService.selectedCover.getImagePropertyBoolean(coverThemeItemSetting.getRole(), 'hidden', coverThemeItemSetting.isHidden()))">visibility_on</mat-icon></span>
                    <span *ngIf="startupService.showInactiveCoverThemeItems && coverThemeItemIndex(coverThemeItemSetting) > 10" i18n-style="@@floatEnd" style="float:right" (click)="moveCoverThemeItemSetting(coverThemeItemSetting, -10); startupService.coverThemeChange = startupService.coverThemeChange + 1; ; saveProject();" class="mdi mdi-chevron-triple-up"></span>
                    <span *ngIf="startupService.showInactiveCoverThemeItems && coverThemeItemIndex(coverThemeItemSetting) > 5" i18n-style="@@floatEnd" style="float:right" (click)="moveCoverThemeItemSetting(coverThemeItemSetting, -5); startupService.coverThemeChange = startupService.coverThemeChange + 1; ; saveProject();" class="mdi mdi-chevron-double-up"></span>
                    <span *ngIf="startupService.showInactiveCoverThemeItems && coverThemeItemIndex(coverThemeItemSetting) > 1" i18n-style="@@floatEnd" style="float:right" (click)="moveCoverThemeItemSetting(coverThemeItemSetting, -1); startupService.coverThemeChange = startupService.coverThemeChange + 1; ; saveProject();" class="mdi mdi-chevron-up"></span>
                    <span *ngIf="startupService.showInactiveCoverThemeItems && coverThemeItemIndex(coverThemeItemSetting) < startupService.project.cover.coverTheme.objects.length-1" i18n-style="@@floatEnd" style="float:right" (click)="moveCoverThemeItemSetting(coverThemeItemSetting, 1); startupService.coverThemeChange = startupService.coverThemeChange + 1; saveProject();" class="mdi mdi-chevron-down"></span>
                    <span *ngIf="startupService.showInactiveCoverThemeItems && coverThemeItemIndex(coverThemeItemSetting) < startupService.project.cover.coverTheme.objects.length-5" i18n-style="@@floatEnd" style="float:right" (click)="moveCoverThemeItemSetting(coverThemeItemSetting, 5); startupService.coverThemeChange = startupService.coverThemeChange + 1; saveProject();" class="mdi mdi-chevron-double-down"></span>
                    <span *ngIf="startupService.showInactiveCoverThemeItems && coverThemeItemIndex(coverThemeItemSetting) < startupService.project.cover.coverTheme.objects.length-10" i18n-style="@@floatEnd" style="float:right" (click)="moveCoverThemeItemSetting(coverThemeItemSetting, 10); startupService.coverThemeChange = startupService.coverThemeChange + 1; saveProject();" class="mdi mdi-chevron-triple-down"></span>
                  </div>
                  <div class="sideAreaItem coverItem inactive" *ngIf="startupService.showInactiveCoverThemeItems && !coverThemeItemSetting.isActive() && coverThemeItemSetting.getRole() !== 'whole-hidden-image'"  (click)="coverThemeItemSetting.setHidden(!coverThemeItemSetting.isHidden()); startupService.canvasManager.refreshObjectBasedOnRole(coverThemeItemSetting.getRole()); startupService.canvasManager.canvas.calcOffset(); startupService.canvasManager.canvas.renderAll(); saveProject();">
                    <span i18n-style="@@floatEnd" style="float:right" (click)="coverThemeItemSetting.setHidden(!coverThemeItemSetting.isHidden()); startupService.canvasManager.refreshObjectBasedOnRole(coverThemeItemSetting.getRole());startupService.canvasManager.canvas.calcOffset();startupService.canvasManager.canvas.renderAll(); saveProject();"><mat-icon *ngIf="coverThemeItemSetting.isHidden()">visibility_off</mat-icon><mat-icon *ngIf="!coverThemeItemSetting.isHidden()">visibility_on</mat-icon></span>
                    <span>{{coverThemeItemSetting.getRole() | languageString }}</span> <span i18n-style="@@floatEnd" style="float:right" (click)="coverThemeItemSetting.setActive('active'); startupService.coverThemeChange = startupService.coverThemeChange + 1;"><mat-icon>airline_seat_flat</mat-icon></span>
                  </div>
                </ng-container>
              </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined" *ngIf="activeSideArea === 'designs'" class="sideAreaMatCard">
              <mat-card-content>
                <h3 i18n="@@Designs">Designs</h3>
                <ng-container *ngIf="startupService.project && themeService.coverThemes">
                  <div  class="sideAreaItem sideAreaItemTwoUp coverTheme" (click)="setCoverTheme(coverTheme); saveProject();" [ngClass]="startupService.project.cover.coverTheme.id === coverTheme.id ? 'selected' : ''" *ngFor="let coverTheme of themeService.coverThemes">
                    <h4>{{coverTheme.name | languageString }}</h4>
                    <img [src]="themeService.getCoverThemePreviewImageURL(coverTheme)" style="max-width: 130px; margin-top: -10px; background: white;">
                  </div>
                </ng-container>
              </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined" *ngIf="activeSideArea === 'fonts'" class="sideAreaMatCard">
              <!-- start added into fontSettings -->
              <mat-card-content>
                <h3 i18n="@@Fonts">Fonts</h3>
                <mat-tab-group>
                  <mat-tab i18n-label="@@Current Fonts" label="Current Fonts">
                    <div class="sideAreaItem">
                      <button *ngIf="startupService.getSubscription().getSubscriptionLevel().toString() === 'DESIGNER'" mat-icon-button (click)="startupService.selectedCover.coverTheme.addFontSettings(startupService.selectedCover.fontSettings); saveProject();" title="AddFontCollectionToCoverDesign" i18n-title="@@AddFontCollectionToCoverDesign"><mat-icon>add</mat-icon></button>
                      <app-font-settings [fontSettings]="startupService.selectedCover.fontSettings"></app-font-settings>
                    </div>
                  </mat-tab>
                  <mat-tab i18n-label="@@Design Tested Fonts" label="Design Tested Fonts">
                    <ng-container *ngIf="startupService.project && startupService.project.cover.coverTheme.fontSettingsList">
                      <div  class="sideAreaItem" (click)="startupService.project.cover.setFontSettings(fontSettings); saveProject();" [ngClass]="startupService.project.cover.fontSettings.id === fontSettings.id ? 'selected' : ''" *ngFor="let fontSettings of startupService.project.cover.coverTheme.fontSettingsList">
<!--                        <h4>{{fontSettings.name | languageString }}</h4>-->
                        <button *ngIf="startupService.getSubscription().getSubscriptionLevel().toString() === 'DESIGNER'" mat-icon-button (click)="startupService.selectedCover.coverTheme.removeFontSettings(fontSettings)" title="RemoveFontCollectionFromCoverDesign" i18n-title="@@RemoveFontCollectionFromCoverDesign"><mat-icon>delete</mat-icon></button>
                        <app-font-settings [fontSettings]="fontSettings"></app-font-settings>
                      </div>
                    </ng-container>
                  </mat-tab>
                </mat-tab-group>
              </mat-card-content>
              <!-- end added into fontSettings -->
            </mat-card>
<!--            <mat-card *ngIf="activeSideArea === 'images'" class="sideAreaMatCard">-->
<!--              <mat-card-content>-->
<!--                <div class="sideAreaItem">-->
<!--                  <app-image-selector></app-image-selector>-->
<!--                </div>-->
<!--              </mat-card-content>-->
<!--            </mat-card>-->
            <mat-card appearance="outlined" *ngIf="activeSideArea === 'colors'" class="sideAreaMatCard">
              <mat-card-content>
                <mat-tab-group>
                  <mat-tab i18n-label="@@Current" label="Current">
                    <h3 i18n="@@Color Palettes">Color Palettes</h3>
                    <ng-container *ngIf="startupService.project && startupService.project.cover && startupService.project.cover.colorPalette">
                      <div  class="sideAreaItem">
                        <h4 i18n="@@Current Color Palette">Current Color Palette</h4>
<!--                        <h4>{{startupService.project.cover.colorPalette.name | languageString }}</h4>-->
                        <button *ngIf="startupService.getSubscription().getSubscriptionLevel().toString() === 'DESIGNER'" mat-icon-button (click)="startupService.project.cover.coverTheme.addColorPalette(startupService.project.cover.getCoverPalette())" title="AddColorPaletteToCoverDesign" i18n-title="@@AddColorPaletteToCoverDesign"><mat-icon>add</mat-icon></button>
                        <div class="colorSwatches">
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="startupService.project.cover.colorPalette.primary.normal.color"><span>{{startupService.project.cover.colorPalette.primary.normal.color }}</span></span>
                            <span class="light" [style.background]="startupService.project.cover.colorPalette.primary.light.color"><span>{{startupService.project.cover.colorPalette.primary.light.color }}</span></span>
                            <span class="dark" [style.background]="startupService.project.cover.colorPalette.primary.dark.color"><span>{{startupService.project.cover.colorPalette.primary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="startupService.project.cover.colorPalette.secondary.normal.color"><span>{{startupService.project.cover.colorPalette.secondary.normal.color }}</span></span>
                            <span class="light" [style.background]="startupService.project.cover.colorPalette.secondary.light.color"><span>{{startupService.project.cover.colorPalette.secondary.light.color }}</span></span>
                            <span class="dark" [style.background]="startupService.project.cover.colorPalette.secondary.dark.color"><span>{{startupService.project.cover.colorPalette.secondary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="startupService.project.cover.colorPalette.tertiary.normal.color"><span>{{startupService.project.cover.colorPalette.tertiary.normal.color  }}</span></span>
                            <span class="light" [style.background]="startupService.project.cover.colorPalette.tertiary.light.color"><span>{{startupService.project.cover.colorPalette.tertiary.light.color  }}</span></span>
                            <span class="dark" [style.background]="startupService.project.cover.colorPalette.tertiary.dark.color"><span>{{startupService.project.cover.colorPalette.tertiary.dark.color  }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="startupService.project.cover.colorPalette.quaternary.normal.color"><span>{{startupService.project.cover.colorPalette.quaternary.normal.color }}</span></span>
                            <span class="light" [style.background]="startupService.project.cover.colorPalette.quaternary.light.color"><span>{{startupService.project.cover.colorPalette.quaternary.light.color }}</span></span>
                            <span class="dark" [style.background]="startupService.project.cover.colorPalette.quaternary.dark.color"><span>{{startupService.project.cover.colorPalette.quaternary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="startupService.project.cover.colorPalette.quinary.normal.color"><span>{{startupService.project.cover.colorPalette.quinary.normal.color }}</span></span>
                            <span class="light" [style.background]="startupService.project.cover.colorPalette.quinary.light.color"><span>{{startupService.project.cover.colorPalette.quinary.light.color }}</span></span>
                            <span class="dark" [style.background]="startupService.project.cover.colorPalette.quinary.dark.color"><span>{{startupService.project.cover.colorPalette.quinary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="startupService.project.cover.colorPalette.senary.normal.color"><span>{{startupService.project.cover.colorPalette.senary.normal.color }}</span></span>
                            <span class="light" [style.background]="startupService.project.cover.colorPalette.senary.light.color"><span>{{startupService.project.cover.colorPalette.senary.light.color }}</span></span>
                            <span class="dark" [style.background]="startupService.project.cover.colorPalette.senary.dark.color"><span>{{startupService.project.cover.colorPalette.senary.dark.color }}</span></span>
                          </div>
                        </div>
                        <button mat-icon-button (click)="showColorPaletteEditor = !showColorPaletteEditor" title="Edit Color Palette" i18n-title="@@Edit Color Palette"><mat-icon>edit</mat-icon></button>
                        <button mat-icon-button (click)="generateRectangle(startupService.project.cover.colorPalette)" title="Generate a Color Wheel Rectangle Patern Color Palette" i18n-title="@@Generate a Color Wheel Rectangle Patern Color Palette"><mat-icon>crop_landscape</mat-icon></button>
                        <button mat-icon-button (click)="generateSquare(startupService.project.cover.colorPalette)" title="Generate a Color Wheel Square Patern Color Palette" i18n-title="@@Generate a Color Wheel Square Patern Color Palette"><mat-icon>crop_square</mat-icon></button>
                        <button mat-icon-button (click)="generateSplitComplementary(startupService.project.cover.colorPalette)" title="Generate a Color Wheel Split Patern Color Palette" i18n-title="@@Generate a Color Wheel Split Patern Color Palette"><mat-icon>merge_type</mat-icon></button>
                        <button mat-icon-button (click)="generateComplementary(startupService.project.cover.colorPalette)" title="Generate a Color Wheel Complementary Patern Color Palette" i18n-title="@@Generate a Color Wheel Complementary Patern Color Palette"><mat-icon>linear_scale</mat-icon></button>
                        <button mat-icon-button (click)="generateTriad(startupService.project.cover.colorPalette)" title="Generate a Color Wheel Triad Patern Color Palette" i18n-title="@@Generate a Color Wheel Triad Patern Color Palette"><mat-icon>scatter_plot</mat-icon></button>
                        <button mat-icon-button (click)="generateMonochromatic(startupService.project.cover.colorPalette)" title="Generate a Monochromatic Color Palette" i18n-title="@@Generate a Monochromatic Patern Color Palette"><mat-icon>monochrome_photos</mat-icon></button>
                      </div>
                      <div class="sideAreaItem" *ngIf="showColorPaletteEditor">
                        <app-color-palette [colorPalette]="startupService.selectedCover.colorPalette"></app-color-palette>
                      </div>
                    </ng-container>
                  </mat-tab>
                  <mat-tab i18n-label="@@Design Tested Palettes" label="Design Tested Palettes">
                    <ng-container *ngIf="startupService.project && startupService.project.cover.coverTheme.colorPalettes">
                      <div  class="sideAreaItem" (click)="startupService.project.cover.setColorPalette(colorPalette)" [ngClass]="startupService.project.cover.colorPalette.name === colorPalette.name ? 'selected' : ''" *ngFor="let colorPalette of startupService.project.cover.coverTheme.colorPalettes">
<!--                        <h4>{{colorPalette.name | languageString }}</h4>-->
                        <button *ngIf="startupService.getSubscription().getSubscriptionLevel().toString() === 'DESIGNER'" mat-icon-button (click)="startupService.project.cover.coverTheme.removeColorPalette(colorPalette)" title="RemoveColorPaletteFromCoverDesign" i18n-title="@@RemoveColorPaletteFromCoverDesign"><mat-icon>delete</mat-icon></button>
                        <div class="colorSwatches">
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.primary.normal.color"><span>{{colorPalette.primary.normal.color }}</span></span>
                            <span class="light" [style.background]="colorPalette.primary.light.color"><span>{{colorPalette.primary.light.color }}</span></span>
                            <span class="dark" [style.background]="colorPalette.primary.dark.color"><span>{{colorPalette.primary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.secondary.normal.color"><span>{{colorPalette.secondary.normal.color }}</span></span>
                            <span class="light" [style.background]="colorPalette.secondary.light.color"><span>{{colorPalette.secondary.light.color }}</span></span>
                            <span class="dark" [style.background]="colorPalette.secondary.dark.color"><span>{{colorPalette.secondary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.tertiary.normal.color"><span>{{colorPalette.tertiary.normal.color  }}</span></span>
                            <span class="light" [style.background]="colorPalette.tertiary.light.color"><span>{{colorPalette.tertiary.light.color  }}</span></span>
                            <span class="dark" [style.background]="colorPalette.tertiary.dark.color"><span>{{colorPalette.tertiary.dark.color  }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.quaternary.normal.color"><span>{{colorPalette.quaternary.normal.color }}</span></span>
                            <span class="light" [style.background]="colorPalette.quaternary.light.color"><span>{{colorPalette.quaternary.light.color }}</span></span>
                            <span class="dark" [style.background]="colorPalette.quaternary.dark.color"><span>{{colorPalette.quaternary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.quinary.normal.color"><span>{{colorPalette.quinary.normal.color }}</span></span>
                            <span class="light" [style.background]="colorPalette.quinary.light.color"><span>{{colorPalette.quinary.light.color }}</span></span>
                            <span class="dark" [style.background]="colorPalette.quinary.dark.color"><span>{{colorPalette.quinary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.senary.normal.color"><span>{{colorPalette.senary.normal.color }}</span></span>
                            <span class="light" [style.background]="colorPalette.senary.light.color"><span>{{colorPalette.senary.light.color }}</span></span>
                            <span class="dark" [style.background]="colorPalette.senary.dark.color"><span>{{colorPalette.senary.dark.color }}</span></span>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </mat-tab>
                  <mat-tab i18n-label="@@Designer" label="Designer">
                    <ng-container *ngIf="startupService.project && themeService.colorPalettes">
                      <div  class="sideAreaItem" (click)="startupService.project.cover.setColorPalette(colorPalette)" [ngClass]="startupService.project.cover.colorPalette.name === colorPalette.name ? 'selected' : ''" *ngFor="let colorPalette of themeService.colorPalettes">
<!--                        <h4>{{colorPalette.name | languageString }}</h4>-->
                        <button *ngIf="startupService.getSubscription().getSubscriptionLevel().toString() === 'DESIGNER'" mat-icon-button (click)="startupService.project.cover.coverTheme.addColorPalette(startupService.project.cover.getCoverPalette())" title="AddColorPaletteToCoverDesign" i18n-title="@@AddColorPaletteToCoverDesign"><mat-icon>add</mat-icon></button>
                        <div class="colorSwatches">
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.primary.normal.color"><span>{{colorPalette.primary.normal.color }}</span></span>
                            <span class="light" [style.background]="colorPalette.primary.light.color"><span>{{colorPalette.primary.light.color }}</span></span>
                            <span class="dark" [style.background]="colorPalette.primary.dark.color"><span>{{colorPalette.primary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.secondary.normal.color"><span>{{colorPalette.secondary.normal.color }}</span></span>
                            <span class="light" [style.background]="colorPalette.secondary.light.color"><span>{{colorPalette.secondary.light.color }}</span></span>
                            <span class="dark" [style.background]="colorPalette.secondary.dark.color"><span>{{colorPalette.secondary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.tertiary.normal.color"><span>{{colorPalette.tertiary.normal.color  }}</span></span>
                            <span class="light" [style.background]="colorPalette.tertiary.light.color"><span>{{colorPalette.tertiary.light.color  }}</span></span>
                            <span class="dark" [style.background]="colorPalette.tertiary.dark.color"><span>{{colorPalette.tertiary.dark.color  }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.quaternary.normal.color"><span>{{colorPalette.quaternary.normal.color }}</span></span>
                            <span class="light" [style.background]="colorPalette.quaternary.light.color"><span>{{colorPalette.quaternary.light.color }}</span></span>
                            <span class="dark" [style.background]="colorPalette.quaternary.dark.color"><span>{{colorPalette.quaternary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.quinary.normal.color"><span>{{colorPalette.quinary.normal.color }}</span></span>
                            <span class="light" [style.background]="colorPalette.quinary.light.color"><span>{{colorPalette.quinary.light.color }}</span></span>
                            <span class="dark" [style.background]="colorPalette.quinary.dark.color"><span>{{colorPalette.quinary.dark.color }}</span></span>
                          </div>
                          <div class="colorSwatch">
                            <span class="normal" [style.background]="colorPalette.senary.normal.color"><span>{{colorPalette.senary.normal.color }}</span></span>
                            <span class="light" [style.background]="colorPalette.senary.light.color"><span>{{colorPalette.senary.light.color }}</span></span>
                            <span class="dark" [style.background]="colorPalette.senary.dark.color"><span>{{colorPalette.senary.dark.color }}</span></span>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </mat-tab>
                </mat-tab-group>
              </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined" *ngIf="activeSideArea === 'texts'" class="sideAreaMatCard">
              <mat-card-content>
                <h3 i18n="@@Text Designs">Text Designs</h3>
                <div class="sideAreaItem">
                  <p><b i18n="@@Under construction.">Under construction.</b></p>
                  <p i18n="@@When ready this will allow for selecting premade fonts, borders and backgrounds for text elements.">When ready this will allow for selecting premade fonts, borders and backgrounds for text elements.</p>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined" *ngIf="activeSideArea === 'trim'" class="sideAreaMatCard">
              <mat-card-content >
                <h3 i18n="@@Trim Size">Trim Size</h3>
                <div  class="sideAreaItem" (click)="setTrimSize(trimSize)" [ngClass]="( startupService.project.width === trimSize.width && startupService.project.height === trimSize.height ) ? 'selected' : ''" *ngFor="let trimSize of trimSizes">
                  <h4>{{trimSize.name | languageString }}</h4>
                </div>
              </mat-card-content >
            </mat-card>
          </mat-sidenav>
          <mat-sidenav-content [style.marginLeft.px]="activeSideArea === '' ? 0 : 340" class="mainArea m2app-dark" style="overflow: hidden;">
            <mat-card appearance="outlined" style="overflow: auto; height: 100%; background: white">
              <mat-card-content >
                <!--<app-cover-editor-area *ngIf="startupService.selectedCover" class="coverArea" [project]="project" [cover]="project.cover" [activeSideArea]="activeSideArea"></app-cover-editor-area>-->
                <!--<app-cover-editor *ngIf="mainSideNav && startupService.selectedCover && project" [project]="project" [cover]="project.cover" [coverTheme]="project.cover.coverTheme" [colorPalette]="project.cover.colorPalette" [fontSettings]="project.cover.fontSettings" [colorPaletteChange]="startupService.colorPaletteChange" [fontSettingsChange]="startupService.fontSettingsChange" [coverThemeChange]="startupService.coverThemeChange" [trimSizeChange]="startupService.trimSizeChange" [activeSideArea]="activeSideArea" [mainSideNavIsOpen]="mainSideNav && mainSideNav.isOpen()"></app-cover-editor>-->
                <app-cover-editor *ngIf="startupService.selectedCover && project" [project]="project" [cover]="project.cover" [coverTheme]="project.cover.coverTheme" [colorPalette]="project.cover.colorPalette" [fontSettings]="project.cover.fontSettings" [colorPaletteChange]="startupService.colorPaletteChange" [fontSettingsChange]="startupService.fontSettingsChange" [coverThemeChange]="startupService.coverThemeChange" [trimSizeChange]="startupService.trimSizeChange" [activeSideArea]="activeSideArea" [mainSideNavIsOpen]="false"></app-cover-editor>
              </mat-card-content >
            </mat-card>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
