import {Component, Input, OnInit} from '@angular/core';
import {FontSettings} from "../../../../model/editor/FontSettings";
import {StartupService} from "../../../service-ui/startup.service";
import {ThemeService} from "../../../service-data/theme.service";

@Component({
  selector: 'app-font-settings',
  templateUrl: './font-settings.component.html',
  styleUrls: ['./font-settings.component.css']
})
export class FontSettingsComponent implements OnInit {
  @Input() fontSettings: FontSettings;

  constructor (public startupService: StartupService, public themeService: ThemeService) {
  }

  ngOnInit () {
  }

  changedFont() {
    this.startupService.fontSettingsChange++;
  }

}
