import {environment} from "../../environments/environment";
import * as AWS from "aws-sdk";
import { Inject, Injectable } from "@angular/core";
import {CognitoUtil} from "./cognito.service";

@Injectable()
export class LambdaService {

    constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil) {
    }

    public getLambda(): any {
      // AWS.config.update({region: environment.region});
      // AWS.config.credentials = new AWS.CognitoIdentityCredentials({IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID});

      const lambda = new AWS.Lambda({region:  environment.region, apiVersion: '2015-03-31'});
      return lambda;
    }
}
