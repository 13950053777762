import {Component} from '@angular/core';
import {StartupService} from "../../service-ui/startup.service";

@Component({
  selector: 'app-active-role',
  templateUrl: './active-role.component.html',
  styleUrls: ['./active-role.component.css']
})

export class ActiveRoleComponent {

  constructor (public startupService: StartupService) {
    this.startupService.touchLastUserActivity();
  }
}


