import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { Project } from '../../../model/editor/Project';
import { MessageService } from '../../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../../service-ui/startup.service';
import {environment} from "../../../environments/environment";
import {S3Service} from "../../service-auth/s3.service";
import {Chapter} from "../../../model/editor/Chapter";
import {CoverTheme} from "../../../model/editor/CoverTheme";
import {FontSettings} from "../../../model/editor/FontSettings";
import {ColorPalette} from "../../../model/color/ColorPalette";
import {ProjectProperties} from "../../../model/ProjectProperties";
import {InteriorTheme} from "../../../model/editor/interior-theme/InteriorTheme";
import {ProjectSaver} from "../../../model/ProjectSaver";
import {NotificationsService} from "../notifications.service";
import {InteriorThemeEditorSaver} from "../../../model/editor/interior-theme/InteriorThemeEditorSaver";
import * as Stream from "stream";
import {ThemeService} from "../theme.service";
import {AlertService} from "../../service-ui/alert.service";
import {LanguagePipe} from "../../pipe/language.pipe";
import {FileItem} from "../../file-upload/file-item.class";
import {WindowRef} from "../../WindowRef";
import {DataService} from "../data-service";

@Injectable()
export class EditorProjectService extends DataService implements ProjectSaver, InteriorThemeEditorSaver {
  private projects: Project[] = [];
  private projectsObservable = of(this.projects);
  public projectsLoadingState = 'INITIAL';

  private debounceProjectTimers = new Map<Project, number>();
  private debounceProjectTimersCanvas = new Map<Project, any>();

  constructor(private http: HttpClient, private messageService: MessageService, public startupService: StartupService, private s3Service: S3Service, private themeService: ThemeService, private notificationsService: NotificationsService, private alertService: AlertService, public windowRef: WindowRef) {
    super(startupService);
    // this.startupService.setProjectService(this);
  }

  reset (filter: string = '') {
    this.projectsLoadingState = 'RESETTING';
    while (this.projects.length > 0) {
      const project = this.projects.pop();
    }
    this.projectsLoadingState = 'INITIAL';
  }
  getProjects(): Observable<Project[]> {
    if ( this.projectsLoadingState === 'INITIAL') {
      this.projectsLoadingState = 'STARTING';
      this.fetchProjects().subscribe();
    }
    return this.projectsObservable;
  }


  fetchProjects(): Observable<Project[]> {
    this.reset();
    this.projectsLoadingState = 'FETCHING';
    this.listProjects().then( projs => {
      projs.map( project => {
        this.addProjectInAlphabeticalOrderByProjectName(project);
      });
      this.projectsLoadingState = 'LOADED';
    }).catch(err => {
      console.warn('Problem in getProjects() sending to logout():' + err);
      this.projectsLoadingState = 'INITIAL';
      // this.startupService.logout();
    });
    return this.projectsObservable;
  }

  addProject(project: Project): Promise<any> {
    const coverTheme = this.themeService.getCoverThemeFromId('/assets/cover-themes/Power/covertheme.json');
    if ( coverTheme ) {
      const copyOfCoverTheme = new CoverTheme(coverTheme);
      project.cover.setCoverTheme(coverTheme);
      project.cover.copyCoverThemeColorPalette();
      project.cover.copyCoverThemeFontSettings();
    }
    return this.addProjectWithSpecificThemes(project, environment.defaultInteriorThemeKey, coverTheme);
  }
  addProjectWithSpecificThemes(project: Project, interiorThemeKey: string, coverTheme: CoverTheme): Promise<any> {
    const promises: Promise<any>[] = [];
    if (!project.id) {
      project.id = Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16);
    }

    if (project) {
      const defaultCopyright = new Chapter( undefined );
      defaultCopyright.title = LanguagePipe.get('DefaultCopyrightTitle', 'compressSpaces');
      defaultCopyright.type = 'copyright';
      project.interior.chapters.push(defaultCopyright);
      promises.push(this.addOrUpdateChapter(project, defaultCopyright, LanguagePipe.get('DefaultCopyrightPageContents', 'compressSpaces') ) );

      const defaultChapter = new Chapter( undefined );
      defaultChapter.title = LanguagePipe.get('DefaultChapterTitle', 'compressSpaces');
      defaultChapter.type = 'standard';
      project.interior.chapters.push(defaultChapter);
      promises.push(this.addOrUpdateChapter(project, defaultChapter, LanguagePipe.get('DefaultChapterContents', 'compressSpaces') ) );

      // Save a copy of the selected CSS file
      promises.push(new Promise((resolve, reject) => {
        this.getPublicCSS(environment.publicBucket, interiorThemeKey).then( cssString => {
          this.saveInteriorTheme(project, cssString);
          resolve(project);
        }).catch(errCSS => {
          reject(errCSS);
        });
      }));
    }
    const projectKey = new ProjectKey(this.startupService.getUserId(), project.id + '/book.config');

    const params = {
      Bucket: environment.dataBucket,
      Key: new ProjectKey(this.startupService.getUserId(), project.id).toString(),
      Body: JSON.stringify(project),
      ContentType: 'text/json'
    };

    promises.push(new Promise((resolve, reject) => {
      this.s3Service.getS3().putObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const ret = project as Project;
          resolve(ret);
        }
      });
    }));

    return Promise.all(promises).then((d) => {
      this.addProjectInAlphabeticalOrderByProjectName(project);
    });

  }

  addProjectInAlphabeticalOrderByProjectName(project: Project) {
    let i = 0;
    for (; i < this.projects.length && this.projects[i].name && this.projects[i].name < project.name; i++) {
    }
    this.projects.splice(i, 0, project);
  }

  retreiveLocalProject(id: string): Project {
    for (const project of this.projects) {
      if (project.id === id) {
        return project;
      }
    }
    return null;
  }

  refresh(id: string) {
    const localProject = this.retreiveLocalProject(id);
    this.loadProject(id).then(project => {
      if (localProject) {
        for (const key in project) {
          if (project[key] !== localProject[key]) {
            localProject[key] = project[key];
          }
        }
      } else {
        this.addProjectInAlphabeticalOrderByProjectName(project);
      }
    });
  }

  getProjectProperties(project: Project): Promise<ProjectProperties> {
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    const params = {
      Bucket: environment.dataBucket,
      Key: new ProjectPropertiesKey(userName, project.id + '/bookProperties.json').toString(),
    };
    return new Promise((resolve, reject) => {
      this.s3Service.getS3().getObject(params, (err, data) => {
        if (err) {
          if ( project.timestamp + ( 1000 * 60 * 30 ) < Date.now()) { // If more than 30 minutes old
            project.status = "ERROR";
            project.progress = 0;
          }
          reject(err);
        } else {
          const json = data.Body.toString();
          const projectProperties = new ProjectProperties( JSON.parse(json) );
          if ( projectProperties &&  projectProperties.pages ) {
            project.updateStatusAndPages(projectProperties);
          }
          resolve(projectProperties);
        }
      });
    });
  }

  public loadProject(projectId: string): Promise<Project> {
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    const params = {
      Bucket: environment.dataBucket,
      Key: new ProjectKey(userName, projectId + '/book.config').toString(),
    };
    return new Promise((resolve, reject) => {
      this.s3Service.getS3().getObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const json = data.Body.toString();
          const project = new Project( JSON.parse(json) );
          if ( project) {
            this.getProjectProperties(project).then( projectProperties => {
              console.log('Got ProjectProperties for Project ' + project.id );
            }).catch( getProjectPropertiesError => {
              console.warn('Get ProjectProperties did not return an object:' + getProjectPropertiesError);
            });
          }
          // project.eTag = data.ETag;
          resolve(project);
        }
      });
    });
  }

  public listProjects(): Promise<Project[]> {
    this.projectsLoadingState = 'LOADING';
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      // this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      // this.startupService.logout();
    }


    const params = {
      Bucket: environment.dataBucket,
      Prefix: userName + '/'
    };
    const projectPromises: Promise<Project>[] = [];

    return new Promise((resolve, reject) => {
      console.log("About to call S3.listObjects in listProjects()");
      try {
        this.s3Service.getS3().listObjectsV2(params, (err, data) => {
          console.log(`Finished calling S3.listObjects in listProjects()=>(err:${JSON.stringify(err)}\ndata:${JSON.stringify(data)})`);
          if (err) {
            reject(err);
            return;
          }

          data.Contents.forEach(c => {
            if (ProjectKey.isValid(c.Key)) {
              console.log("In listProjects valid key for a project: " + c.Key);
              const objectRetrievedPromise = new Promise<Project>(((s3Resolve, s3Reject) => {
                this.s3Service.getS3().getObject({Bucket: environment.dataBucket, Key: c.Key}, (err2, data2) => {
                  if (err2) {
                    s3Reject(err2);
                    return;
                  }
                  const json = data2.Body.toString();
                  console.log(`project json: [${json}]`);
                  try {
                    const project = new Project(JSON.parse(json));
                    if ( project) {
                      this.getProjectProperties(project).then( projectProperties => {
                        console.log('Got ProjectProperties for Project ' + project.id );
                      }).catch( getProjectPropertiesError => {
                        console.warn('Get ProjectProperties did not return an object:' + getProjectPropertiesError);
                      });
                    }
                    this.getURLs(project);
                    console.log(`parsed project [${project}]`);
                    s3Resolve(project);

                  } catch ( errJSONParse ) {
                    s3Reject(errJSONParse);
                  }
                });
              }));
              projectPromises.push(objectRetrievedPromise);
            }
          });

          Promise.all(projectPromises).then((d) => {
            // this.notificationsService.setProjectService(this);
            this.notificationsService.startNotification();
            resolve(d);
          });
        });

      } catch (e) {
        reject(e);
      }
    });
  }

  // public saveProject(project: Project) {
  //   this.saveProjectAndCanvas(undefined, project, undefined);
  // }

  public makeHTMLSVG( project: Project, svg: string ): string {
    const fonts = project.cover.fontSettings.getFontsAsArray();
    let fontFamilies = "";
    fonts.forEach(function(font) {
      if ( fontFamilies.length > 0 ) {
        fontFamilies += ", ";
      }
      fontFamilies += "'" + font + "'";
    });

    const htmlSVG = "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/><script src='https://ajax.googleapis.com/ajax/libs/webfont/1.5.18/webfont.js'></script><style>body{ margin: 0px;}</style><script>WebFont.load({google: {families: [" + fontFamilies + "]}});</script></head><body>" + svg + "</body></html>";
    return htmlSVG;
  }


  public saveProjectAndCanvas (canvasManager: any, project: Project, canvas: any) {
    this.startupService.touchLastUserActivity();
    if ( !canvas && !project.skipCoverRendering ) {
      return;
    }
    project.progress = 0;
    project.status = 'INITIAL';
    console.log("Queueing to Save Project: " + project.id + "  " + project.name);
    project.savingStatus = 'Queued';
    let timeoutId = this.debounceProjectTimers.get(project);
    if (timeoutId) {
      this.windowRef.nativeWindow.clearTimeout(timeoutId);
    }
    timeoutId = this.windowRef.nativeWindow.setTimeout(() => {
      console.log("Saving Queued Project: " + project.id + "  " + project.name);
      let svg = '';
      if ( canvas ) {
        if (canvasManager) {
          const putBackGridLines = canvasManager.coverGridLines;
          const displayedScale = project.cover.scale;
          if ( displayedScale !== 1.0 ) {
            canvasManager.zoomToFullScale();
          }
          canvasManager.removeCoverGuideLines();
          svg = canvas.toSVG();
          if (putBackGridLines) {
            canvasManager.addCoverGuideLines();
          }
          if ( displayedScale !== 1.0 ) {
            canvasManager.zoomTo( displayedScale );
          }
        } else {
          svg = canvas.toSVG();
        }
        project.skipCoverRendering = false;
      }

      let htmlSVG = this.makeHTMLSVG(project, svg);
      htmlSVG = htmlSVG.replace(/&amp;|&/g, "&amp;");
      console.log(svg.toString());
      console.log(htmlSVG.toString());
      // const svgBase64 = Buffer.from(svg, 'binary').toString('base64');
      // console.log('svgBase64' + svgBase64);
      //
      // const svgUnBase64 = Buffer.from(svgBase64, 'base64').toString('binary');
      // console.log('svgUnBase64' + svgUnBase64);

      project.savingStatus = 'Saving';
      const retreivedCanvas = this.debounceProjectTimersCanvas.get(project);
      this.debounceProjectTimers.delete(project);
      this.debounceProjectTimersCanvas.delete(project);
      const projectKey = new ProjectKey(this.startupService.getUserId(), project.id + '/book.config');

      return new Promise((resolve, reject) => {
        project.timestamp = Date.now();
        const params = {
          Bucket: environment.dataBucket,
          Key: new SVGKey(this.startupService.getUserId(), project.id).toString(),
          Body: htmlSVG,
          ContentType: 'text/html'
        };
        console.log('Saving cover SVG:' + JSON.stringify(params) );
        console.log('Saving cover SVG JUST THE Body:' + params.Body );
        this.s3Service.getS3().putObject(params, (err, data) => {
          if (err) {
            project.savingStatus = 'Failed';
            reject(err);
          } else {
            const ret = project as Project;
            console.log("Saving Queued Project Complete: " + project.id + "  " + project.name);
            project.savingStatus = 'Saved';
            project.coverNeedsSavingWithNewSpineSize = false;
            const params2 = {
              Bucket: environment.dataBucket,
              Key: new ProjectKey(this.startupService.getUserId(), project.id).toString(),
              Body: JSON.stringify(project),
              ContentType: 'text/json'
            };
            console.log('Saving project:' + JSON.stringify(params) );
            console.log('Saving project JUST THE Body:' + params.Body );
            project.skipCoverRendering = false;
            this.s3Service.getS3().putObject(params2, (err2, data2) => {
              if (err2) {
                project.savingStatus = 'Failed';
                reject(err2);
              } else {
                const ret2 = project as Project;
                console.log("Saving Queued Project Complete: " + project.id + "  " + project.name);
                project.savingStatus = 'Saved';
                project.skipCoverRendering = true;
                resolve(ret2);
              }
            });
          }
        });
      });
    }, 5000); // Five seconds
    this.debounceProjectTimers.set(project, timeoutId);
    if ( canvas ) {
      this.debounceProjectTimersCanvas.set(project, canvas);
    }

  }


  // This saves immediatly
  public saveProjectImmediately(project: Project): Promise<Project> {

    if ( project === this.startupService.project ) {
      return new Promise((resolve, reject) => {
        try {

          this.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
          resolve(this.startupService.project);
        } catch (err) {
          reject(err);
        }
      });
    } else {
      project.progress = 0;
      project.timestamp = Date.now();
      project.status = 'INITIAL';
      console.log("Saving Project Immediately: " + project.id + "  " + project.name);

      const retreivedCanvas = this.debounceProjectTimersCanvas.get(project);
      this.debounceProjectTimers.delete(project); // Remove other queued saves because this will take care of it
      this.debounceProjectTimersCanvas.delete(project); // Remove other queued saves because this will take care of it
      project.savingStatus = 'Saving';

      const projectKey = new ProjectKey(this.startupService.getUserId(), project.id + '/book.config');

      return new Promise((resolve, reject) => {
        const params = {
          Bucket: environment.dataBucket,
          Key: new ProjectKey(this.startupService.getUserId(), project.id).toString(),
          Body: JSON.stringify(project),
          ContentType: 'text/json'
        };
        project.skipCoverRendering = true;
        this.s3Service.getS3().putObject(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            const ret = project as Project;
            project.savingStatus = 'Saved';
            resolve(ret);
          }
        });
      });

    }
  }

  public deleteProject(project: Project): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: environment.dataBucket,
        Key: new ProjectKey(this.startupService.getUserId(), project.id).toString()
      };
      console.log(`Deleting params[${JSON.stringify(params)}]`);
      this.s3Service.getS3().deleteObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const index = this.projects.indexOf(project);
          if ( index > -1 ) {
            this.projects.splice(index, 1);
          }
          resolve(true);
        }
      });
    });
  }

  private clearFromPrefix(prefix: string): Promise<any> {
    console.log(`Clearing data for bucket[${environment.dataBucket}] prefix[${prefix}]`);
    return new Promise<any>((resolve, reject) => {
      const params = {
        Bucket: environment.dataBucket,
        Prefix: prefix
      };

      this.s3Service.getS3().listObjectsV2(params, (err, data) => {
        console.log(`Listed objects err[${err}] data[${data}]`);
        if (err) {
          reject(err);
        } else {
          if (data.Contents && data.Contents.length > 0) {
            const keys = [];
            data.Contents.forEach(c => keys.push({Key: c.Key}));

            const params2 = {
              Bucket: environment.dataBucket,
              Delete: {
                Objects: keys
              }
            };
            console.log(`Deleting params[${JSON.stringify(params2)}]`);
            this.s3Service.getS3().deleteObjects(params2, (err2, data2) => {
              console.log(`Deleted err[${err2}] data[${JSON.stringify(data2)}]`);
              if (err2) {
                reject(err2);
              } else {
                resolve(data2);
              }
            });
          } else {
            resolve(true);
          }
        }
      });
    });
  }


  public async uploadFile(item: FileItem, extension: string): Promise<any> {
    const file = item._file;
    const chapter = new Chapter( undefined );
    chapter.title = file.name.substr(0, file.name.length - extension.length - 1 );

    chapter.mediaFile = item.chapterId + '.' + extension;
    chapter.source = 'Audio';
    chapter.status = 'INITIAL';
    chapter.type = 'standard';
    chapter.audioDuration = item.duration;
    chapter.audioCharge = item.charge;
    chapter.audioChargeStatus = item.chargeStatus;
    chapter.id = item.chapterId;
    this.startupService.project.interior.chapters.push(chapter);
    this.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
    let contentType = 'audio/mp3';
    switch ( extension.toLowerCase() ) {
      case 'acc': {
        contentType = 'audio/acc';
        break;
      }
      case 'aif': {
        contentType = 'audio/x-aiff';
        break;
      }
      case 'aifc': {
        contentType = 'audio/x-aiff';
        break;
      }
      case 'aiff': {
        contentType = 'audio/x-aiff';
        break;
      }
      case 'amr': {
        contentType = 'video/amr';
        break;
      }
      case 'avi': {
        contentType = 'video/avi';
        break;
      }
      case 'mp3': {
        contentType = 'audio/mp3';
        break;
      }
      case 'mp4': {
        contentType = 'video/mp4';
        break;
      }
      case 'm4a': {
        contentType = 'video/mp4';
        break;
      }
      case 'mov': {
        contentType = 'video/quicktime';
        break;
      }
      case 'ogg': {
        contentType = 'video/ogg';
        break;
      }
      case 'vob': {
        contentType = 'video/x-ms-vob';
        break;
      }
      case 'wav': {
        contentType = 'audio/wav';
        break;
      }
      case 'wma': {
        contentType = 'video/x-ms-wma';
        break;
      }
      case 'wmv': {
        contentType = 'video/x-ms-wmv';
        break;
      }
      // case 'flac': {
      //   contentType = 'audio/flac';
      //   break;
      // }
    }

    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    return this.s3Service.getS3().putObject({Bucket: environment.dataBucket, Key: userName + '/' + this.startupService.project.id + '/' + chapter.id + '.' + extension + 'transcribeSource', ContentType: contentType, Body: file}).promise();
  }

  public async uploadFileStream(readStream: Stream, chapterId: string, extension: string): Promise<any> {
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    return this.s3Service.getS3().upload({Bucket: environment.dataBucket, Key: userName + '/' + this.startupService.project.id + '/' + chapterId + '.' + extension, Body: readStream}).promise();
  }

  // Get the duration in minutes of a file that may be uploaded
  public setUploadFileItemDuration(fileItem: FileItem): Promise<FileItem> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      fileItem.chapterId = Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16);
      const _win = this.windowRef.nativeWindow;
      video.onloadedmetadata = function() {
        _win.URL.revokeObjectURL(video.src);
        fileItem.duration = Math.ceil(video.duration / 60.0);
        fileItem.charge = Math.ceil(video.duration / 60.0) * 1.2;
        fileItem.chargeStatus = 'INITIAL';

        console.log('Audio or Video of length: ' + fileItem.duration);
        resolve(fileItem);
      };
      video.onerror = function() {
        reject('error loading file to compute audio duration');
      };
      video.src = URL.createObjectURL(fileItem._file);
    });
  }

  public getUploadPresignedUrl(chapterId: string, extension: string): string {
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    return this.s3Service.getS3().getSignedUrl('getObject', {Bucket: environment.dataBucket, Key: userName + '/' + this.startupService.project.id + '/' + chapterId + '.' + extension, Expires: 6000000});
  }

  public addOrUpdateChapter(project: Project, chapter: Chapter, value: string): Promise<Chapter> {
    const chapterKey = new ChapterKey(this.startupService.getUserId(), `${project.id}/${chapter.id}`);

    const params = {
      Bucket: environment.dataBucket,
      Key: chapterKey.toString(),
      Body: value,
      ContentType: 'text/html'
    };

    return new Promise((resolve, reject) => {
      this.s3Service.getS3().putObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const ret = chapter as Chapter;
          resolve(ret);
        }
      });
    });
  }

  public getChapter(projectId: string, chapterId: string): Promise<string> {
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    const params = {
      Bucket: environment.dataBucket,
      Key: new ChapterKey(userName, `${projectId}/${chapterId}`).toString(),
    };
    return new Promise((resolve, reject) => {
      this.s3Service.getS3().getObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const text = data.Body.toString('utf8');
          resolve(text);
        }
      });
    });
  }
  public getCover(projectId: string): Promise<string> {
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    const params = {
      Bucket: environment.dataBucket,
      Key: new CoverKey(userName, projectId).toString(),
    };
    return new Promise((resolve, reject) => {
      this.s3Service.getS3().getObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const text = data.Body.toString('utf8');
          resolve(text);
        }
      });
    });
  }

  public getCSS(projectId: string): Promise<string> {
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    const params = {
      Bucket: environment.dataBucket,
      Key: new ProjectCSSKey(userName, projectId).toString(),
    };
    return new Promise((resolve, reject) => {
      this.s3Service.getS3().getObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const text = data.Body.toString('utf8');
          resolve(text);
        }
      });
    });
  }

  public getPublicCSS(bucket: string, key: string): Promise<string> {
    const params = {
      Bucket: bucket,
      Key: key,
    };
    return new Promise((resolve, reject) => {
      this.s3Service.getS3().getObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const text = data.Body.toString('utf8');
          resolve(text);
        }
      });
    });
  }

  saveCoverThemeImage(folder: string, project: Project): Promise<CoverTheme> {
    const coverTheme = new CoverTheme(project.cover.coverTheme);
    coverTheme.fontSettings = new FontSettings(project.cover.fontSettings);
    coverTheme.colorPalette = new ColorPalette(project.cover.colorPalette);

    const params = {
      Bucket: environment.dataBucket,
      CopySource: environment.dataBucket + '/' + new CoverThemeKey(this.startupService.getUserId(), project.id, 'front.png').toString(),
      Key: new CoverThemeKey(this.startupService.getUserId(), folder, coverTheme.name.replace(/\s+/g, '-') + '.png').toString()
    };

    return new Promise((resolve, reject) => {
      this.s3Service.getS3().copyObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const ret = coverTheme as CoverTheme;
          resolve(ret);
        }
      });
    });
  }


  saveCoverTheme(folder: string, project: Project): Promise<CoverTheme> {
    const coverTheme = new CoverTheme(project.cover.coverTheme);
    coverTheme.fontSettings = new FontSettings(project.cover.fontSettings);
    coverTheme.colorPalette = new ColorPalette(project.cover.colorPalette);

    const params = {
      Bucket: environment.dataBucket,
      Key: new CoverThemeKey(this.startupService.getUserId(), folder, coverTheme.name.replace(/\s+/g, '-') + '.json').toString(),
      Body: JSON.stringify(coverTheme),
      ContentType: 'text/json'
    };

    return new Promise((resolve, reject) => {
      this.s3Service.getS3().putObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const ret = coverTheme as CoverTheme;
          resolve(ret);
        }
      });
    });
  }

  saveInteriorTheme(project: Project, interiorThemeCSS: string): Promise<InteriorTheme> {
    console.log("Saving the interiorTheme.css for project:" +  project.id + " " + project.name);
    const params = {
      Bucket: environment.dataBucket,
      Key: new InteriorThemeKey(this.startupService.getUserId(), project.id, 'interiorTheme.css').toString(),
      Body: interiorThemeCSS,
      ContentType: 'text/css'
    };

    return new Promise((resolve, reject) => {
      this.s3Service.getS3().putObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const ret = project.interior.interiorTheme as InteriorTheme;
          resolve(ret);
        }
      });
    });
  }

  getURLs(project: Project) {
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    project.coverImageUrl = this.s3Service.getS3().getSignedUrl('getObject', {Bucket: environment.dataBucket, Key: userName + '/' + project.id + '/front.png', Expires: 6000000});
    project.coverPreviewUrl = this.s3Service.getS3().getSignedUrl('getObject', {Bucket: environment.dataBucket, Key: userName + '/' + project.id + '/cover.pdf', Expires: 6000000});
    project.bodyPreviewUrl = this.s3Service.getS3().getSignedUrl('getObject', {Bucket: environment.dataBucket, Key: userName + '/' + project.id + '/body.pdf', Expires: 6000000});
    project.ebookPreviewUrl = this.s3Service.getS3().getSignedUrl('getObject', {Bucket: environment.dataBucket, Key: userName + '/' + project.id + '/ebook.epub', Expires: 6000000});
    // project.interior.interiorThemeUrl = this.s3Service.getS3().getSignedUrl('getObject', {Bucket: environment.dataBucket, Key: userName + '/' + project.ID + '/interiorTheme.css', Expires: 6000000});
  }

  // getFreshImageUrl(image: Image): string {
  //   if ( image && image.key ) {
  //     if ( !image.url || Date.now() - image.timestamp > 500000 ) { // Can be much longer
  //       const parts = image.key.split(":");
  //       if ( parts.length === 2) {
  //         const bucket = parts[0];
  //         const key = parts[1];
  //         image.url = this.s3Service.getS3().getSignedUrl('getObject', {Bucket: bucket, Key: key, Expires: 6000000});
  //         image.timestamp = Date.now();
  //         return image.url;
  //       }
  //     } else {
  //       return image.url;
  //     }
  //   }
  //   return '';
  // }

  public getCachedProject(projectId: string): Project {
    for ( const project of this.projects ) {
      if ( project.id === projectId ) {
        return project;
      }
    }
    return undefined;
  }
}

export class InteriorThemeKey {
  private static regexp = new RegExp(/[0-9a-z]+(-[0-9a-z]+)+\/[0-9a-z]+(-[0-9a-z]+)/);
  private userId: string;
  private projectId: string;
  private interiorThemeId: string;

  public static isValid(input: string) {
    return this.regexp.test(input);
  }
  public constructor(userId: string, projectId: string, interiorThemeId: string) {
    this.userId = userId;
    this.projectId = projectId;
    this.interiorThemeId = interiorThemeId;
  }

  public toString(): string {
    return `${this.userId}/${this.projectId}/${this.interiorThemeId}`;
  }
}
export class CoverThemeKey {
  private static regexp = new RegExp(/[0-9a-z]+(-[0-9a-z]+)+\/[0-9a-z]+(-[0-9a-z]+)/);
  private userId: string;
  private folder: string;
  private coverThemeId: string;

  public static isValid(input: string) {
    return this.regexp.test(input);
  }
  public constructor(userId: string, folder: string, coverThemeId: string) {
    this.userId = userId;
    this.folder = folder;
    this.coverThemeId = coverThemeId;
  }

  public toString(): string {
    return `${this.userId}/${this.folder}/${this.coverThemeId}`;
  }
}

export class ProjectKey {
  private static regexp = new RegExp(/[0-9a-z]+(-[0-9a-z]+)+\/[0-9a-z]+(-[0-9a-z]+)+\/book.config$/);
  private userId: string;
  private projectId: string;

  public static isValid(input: string) {
    return this.regexp.test(input);
  }
  public constructor(userId: string, projectId: string) {
    this.userId = userId;
    this.projectId = projectId;
  }

  public toString(): string {
    return `${this.userId}/${this.projectId}/book.config`;
  }
}

export class SVGKey {
  private static regexp = new RegExp(/[0-9a-z]+(-[0-9a-z]+)+\/[0-9a-z]+(-[0-9a-z]+)+\/cover.html$/);
  private userId: string;
  private projectId: string;

  public static isValid(input: string) {
    return this.regexp.test(input);
  }
  public constructor(userId: string, projectId: string) {
    this.userId = userId;
    this.projectId = projectId;
  }

  public toString(): string {
    return `${this.userId}/${this.projectId}/cover.html`;
  }
}

export class ProjectCSSKey {
  private static regexp = new RegExp(/[0-9a-z]+(-[0-9a-z]+)+\/[0-9a-z]+(-[0-9a-z]+)+\/interiorTheme.css$/);
  private userId: string;
  private projectId: string;

  public static isValid(input: string) {
    return this.regexp.test(input);
  }
  public constructor(userId: string, projectId: string) {
    this.userId = userId;
    this.projectId = projectId;
  }

  public toString(): string {
    return `${this.userId}/${this.projectId}/interiorTheme.css`;
  }
}

export class ProjectPropertiesKey {
  private static regexp = new RegExp(/[0-9a-z]+(-[0-9a-z]+)+\/[0-9a-z]+(-[0-9a-z]+)+\/bookProperties.json$/);
  private userId: string;
  private projectId: string;

  public static isValid(input: string) {
    return this.regexp.test(input);
  }
  public constructor(userId: string, projectId: string) {
    this.userId = userId;
    this.projectId = projectId;
  }

  public toString(): string {
    return `${this.userId}/${this.projectId}`;
  }
}
export class CoverKey {
  private static regexp = new RegExp(/[0-9a-z]+(-[0-9a-z]+)+\/[0-9a-z]+(-[0-9a-z]+)+\/cover.json$/);
  private userId: string;
  private projectId: string;

  public static isValid(input: string) {
    return this.regexp.test(input);
  }
  public constructor(userId: string, projectId: string) {
    this.userId = userId;
    this.projectId = projectId;
  }

  public toString(): string {
    return `${this.userId}/${this.projectId}/cover.json`;
  }
}
export class ChapterKey {
  private static regexp = new RegExp(/[0-9a-z]+(-[0-9a-z]+)+\/[0-9a-z]+(-[0-9a-z]+)+\/[0-9a-z]+(-[0-9a-z]+)+/);
  private userId: string;
  private chapterId: string;

  public static isValid(input: string) {
    return this.regexp.test(input);
  }
  public constructor(userId: string, chapterId: string) {
    this.userId = userId;
    this.chapterId = chapterId;
  }

  public toString(): string {
    return `${this.userId}/${this.chapterId}`;
  }
}
