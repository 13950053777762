import { Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../pipe/language.pipe";
import {UserService} from "../../../service-data/user.service";
import {Umbrella} from "../../../../model/Umbrella";
import {User} from "../../../../model/User";
import {StartupService} from "../../../service-ui/startup.service";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  public breakpoint: number; // Breakpoint observer code
  public name: string = ``;
  // public phone: string = ``;
  // public phoneCountry: string = ``;
  public addForm: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public userService: UserService, private startupService: StartupService) {
  }

  public ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      name: [this.name, [Validators.required, Validators.pattern('[a-zA-Z]+([a-zA-Z ]+)*')]],
      email: [null, [Validators.required, Validators.email]],
      // makeUserAnAdmin: [null, []]
      // phone: [this.phone, [Validators.required]],
      // phone: [this.phone, [Validators.required, PhoneValidator.validCountryPhone(this.addForm.controls.phoneCountry)]],
      // phoneCountry: [this.phoneCountry, [Validators.required, Validators.pattern('^\\d*[0-9]\\d*$')]]
    });
    // this.addForm.controls.phoneCountry.validator = PhoneValidator.validCountryPhone(this.addForm.controls.phoneCountry);

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onAdd(): void {
    const objectToAdd = new User( undefined );
    if ( this.startupService.selectedRole === 'organization' ) {
      objectToAdd.focusOrg_ID = Number(this.startupService.selectedRoleId);
      objectToAdd.name = this.addForm.controls.name.value;
      objectToAdd.email = this.addForm.controls.email.value;
      objectToAdd.phoneNumber = '';

      objectToAdd.isAdmin = 'FALSE';
      // if ( this.addForm.controls.makeUserAnAdmin.value ) {
      //   objectToAdd.isAdmin = 'TRUE';
      // }

      objectToAdd.password_digest = '';
      objectToAdd.active = 'TRUE';
      objectToAdd.allowInPlant = 'FALSE';
      // objectToAdd.language: string; TODO: Put language in the form as a combo box and default to the current UI language or the language of the browser
      // objectToAdd.country: string; TODO: Put country in the form as a combo box

      this.userService.addUser(objectToAdd).subscribe( addResult => {
        console.log("Added user");
      });
      this.markAsDirty(this.addForm);
      //  close the dialog here?
    } else {
      // TODO: Throw up an error AND don't show the add plus button when not in role === 'organization'
    }
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
