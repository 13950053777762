<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Add Facility">Add Facility</h2>
  </div>
  <div>
    <form [formGroup]="addForm" (change)="formChanged()">
      <mat-grid-list cols="1" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-label i18n="@@Organization">Organization</mat-label>
              <mat-select  placeholder="Organization" i18n-placeholder="@@Organization" formControlName="org_ID" required>
                <mat-option *ngFor="let organization of organizations" [value]="organization.ID">{{organization.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="addForm.invalid" (click)="onAdd()" i18n="@@Add Facility">Add Facility</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
