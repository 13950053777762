<div class="draftThumbnail">
  <div>
    <app-project-draft-cover-image [draft]="draft"></app-project-draft-cover-image>
<!--    <img #draftCoverImage src=""  style="display: block; width: 436px; height: 304px; left: -230px; top: -1px; object-fit: cover; position: absolute; max-height: unset; min-height: unset; min-width: unset; max-width: unset;" class="image" i18n-alt="" alt="">-->
  </div>
  <div class="hoveringDisplayCommands">
    <button mat-raised-button style="width:100%" color="primary" type="button" (click)="viewDraft(draft)" title="EditDraftToolTip" i18n-title="@@EditDraftToolTip"><mat-icon>edit</mat-icon><span> </span><ng-container i18n="@@Edit">Edit</ng-container></button>
    <button mat-raised-button style="width:100%" color="primary" type="button" (click)="addToCart(draft)" title="AddToCartToolTip" i18n-title="@@AddToCartToolTip"><ng-container i18n="@@Add to Cart">Add to Cart</ng-container></button>
  </div>
  <div class="draftThumbnailBottom1" >
    <h5 style="font-size: 0.4em; margin-top: 3px; margin-bottom: 3px;"><span i18n="@@Last Saved">Last Saved</span> {{draft.ID}}</h5>
    <h5 style="font-size: 0.7em; margin-top: -6px; margin-bottom: 3px;">{{draft.lastUpdate | date: 'MM/dd/yy hh:mm:ss'}} {{draft.draftBody.numPages}}</h5>
    <div style="position: absolute; bottom: 0px; left: 5px; width: calc(100% - 10px)">
      <span *ngIf="draft.title">{{ (draft.title.length>60)? (draft.title | slice:0:60)+'..':(draft.title) }}</span>
      <span *ngIf="!draft.title">{{ 'No Title Set' | languageString }}</span>
      <span i18n-style="@@floatEnd" style="float:right" (click)="deleteDraft(draft)" i18n-title="@@Delete" title="Delete"><mat-icon>delete</mat-icon></span>
    </div>
  </div>
</div>
