import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {MediaMatcher} from "@angular/cdk/layout";
import {of} from 'rxjs';
import {ManufacturingSetService} from "../../service-data/manufacturing-set.service";
import {PackageService} from "../../service-data/package.service";
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {EventHandler} from "../../table/EventHandler";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import {IssueService} from "../../service-data/issue.service";
import {PackageTypeService} from "../../service-data/package-type.service";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import {ProductService} from "../../service-data/product.service";
import {FacilityInventoryProductService} from "../../service-data/facility-inventory-product.service";
import {ManufacturingTaskBucketPeriodService} from "../../service-data/manufacturing-task-bucket-period.service";
import {ManufacturingTaskBucketService} from "../../service-data/manufacturing-task-bucket.service";
import {PrintNodeOrderItemService} from "../../service-data/print-node-order-item.service";
import {OrderItemService} from "../../service-data/order-item.service";
import {NotificationsService} from "../../service-data/notifications.service";
import {ManufacturingResourceService} from "../../service-data/manufacturing-resource.service";
import {FacilityInvoiceService} from "../../service-data/facility-invoice.service";
import {Subject} from "rxjs";
import {FacilityService} from "../../service-data/facility.service";
import {OrganizationInvoiceService} from "../../service-data/organization-invoice.service";
import {OrganizationService} from "../../service-data/organization.service";
import {DraftService} from "../../service-data/draft.service";
import {AddressService} from "../../service-data/address-service";
import {ChargeProfileService} from "../../service-data/charge-profile-service";
import {ProjectService} from "../../service-data/project.service";

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.css']
})

export class StartupComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  eventHandler: EventHandler;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  expandAttachmentRequests: boolean = false;

  isLoaded: boolean = false;

  constructor(public router: Router,
              public startupService: StartupService,
              public notificationsService: NotificationsService,
              public addressService: AddressService,
              public chargeProfileService: ChargeProfileService,
              public manufacturingResourceService: ManufacturingResourceService,
              public manufacturingSetService: ManufacturingSetService,
              public packageTypeService: PackageTypeService,
              public packageService: PackageService,
              public issueService: IssueService,
              public printNodeOrderService: PrintNodeOrderService,
              public printNodeOrderItemService: PrintNodeOrderItemService,
              public orderItemService: OrderItemService,
              public facilityInventoryProductService: FacilityInventoryProductService,
              public productService: ProductService,
              public projectService: ProjectService,
              public draftService: DraftService,
              public manufacturingTaskBucketPeriodService: ManufacturingTaskBucketPeriodService,
              public manufacturingTaskBucketService: ManufacturingTaskBucketService,
              public organizationInvoiceService: OrganizationInvoiceService,
              public organizationService: OrganizationService,
              public facilityInvoiceService: FacilityInvoiceService,
              public facilityService: FacilityService,
              public changeDetectorRef: ChangeDetectorRef,
              public media: MediaMatcher) {
    this.startupService.addRoleChangeListener(this);
    this.startupService.touchLastUserActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  ngAfterViewInit(): void {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  checkIsLoaded() {
    for ( const serviceName of Object.keys(this.notificationsService.services)) {
      // if ( this.notificationsService.services[serviceName].loadingState !== 'LOADED' && !(this.notificationsService.services[serviceName].loadingState === 'INITIAL' && this.notificationsService.services[serviceName].objects.length > 0 ) ) {
      if ( this.notificationsService.services[serviceName].loadingState !== 'LOADED' && this.notificationsService.services[serviceName].loadingState !== 'INITIAL' ) {
        this.startupService.isDataLoaded = false;
        return;
      }
    }
    this.startupService.isDataLoaded = true;
    if ( this.startupService.isDataLoaded ) {
      switch (this.startupService.selectedRole) {
        case 'network': {
          this.router.navigate(['/secure/manufacturingbuckets']);
          break;
        }
        case 'facility': {
          this.router.navigate(['/secure/manufacturingsets']);
          break;
        }
        case 'organization': {
          this.router.navigate(['/secure/orderitems']);
          break;
        }
        case 'serviceprovider': {
          this.router.navigate(['/secure/orderitems']);
          break;
        }
        default: {
          this.router.navigate(['/secure/orderitems']);
          break;
        }
      }
    }
  }
  getData(): void {
    this.startupService.touchLastUserActivity();
    this.startupService.isDataLoaded = false;

    for ( const serviceName of Object.keys(this.notificationsService.services)) {
      this.notificationsService.services[serviceName].loadingState = 'INITIAL';
    }

    // Network and Facilities
    if ( this.startupService.selectedRole === "network" ||  this.startupService.selectedRole === "facility") {
      if ( this.startupService.selectedRole === "network") {
        const _role = this.startupService.selectedRole;
        const _roldId = this.startupService.selectedRoleId;
        this.facilityService.getItems(true).subscribe(facilities => {
          if ( _role === this.startupService.selectedRole && _roldId === this.startupService.selectedRoleId ) {
          }
          this.checkIsLoaded();
          console.log(`Done with Facilities length: ${facilities.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });
      }
      const manufacturingResourceFilter = {};
      this.manufacturingResourceService.getFilteredItems(manufacturingResourceFilter, true, 10).subscribe(manufacturingResources => {
        this.checkIsLoaded();
        console.log(`Done with ManufacturingResources length: ${manufacturingResources.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });
      const manufacturingTaskBucketFilter = {
        eq: {
          ' manufacturingTaskBucket.status ': 'OPEN',
          ' date_add(date(substring(manufacturingTaskBucketPeriod.period, 2, 10)), INTERVAL 6 DAY) > now() ': 1,
          ' date_add(date(substring(manufacturingTaskBucketPeriod.period, 2, 10)), INTERVAL -6 DAY) < now() ': 1
        }
      };
      this.manufacturingTaskBucketService.getFilteredItems(manufacturingTaskBucketFilter, true, 1).subscribe(manufacturingTaskBuckets => {
        this.checkIsLoaded();
        console.log(`Done with ManufacturingTaskBuckets length: ${manufacturingTaskBuckets.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });

      if ( this.startupService.selectedRole === "facility") {
        const manufacturingSetsFilter = {
          eq: {
            ' date_add(date(substring(manufacturingTaskBucketPeriod.period, 2, 10)), INTERVAL 18 DAY) > now() ': 1
          },
          gt: {
            'manufacturingSet.ID': 700
          }
        };
        this.manufacturingSetService.getFilteredItems(manufacturingSetsFilter, true, 25).subscribe(manufacturingSets => {
          this.checkIsLoaded();
          console.log(`Done with ManufacturingSets length: ${manufacturingSets.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });
      }
      if ( this.startupService.selectedRole === "network") {
        const manufacturingSetsFilter2 = {
          eq: {
            ' date_add(date(substring(manufacturingTaskBucketPeriod.period, 2, 10)), INTERVAL 6 DAY) > now() ': 1
          },
          gt: {
            'manufacturingSet.ID': 700
          }
        };
        this.manufacturingSetService.getFilteredItems(manufacturingSetsFilter2, true, 25).subscribe(manufacturingSets => {
          this.checkIsLoaded();
          console.log(`Done with ManufacturingSets length: ${manufacturingSets.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });
      }

      const issuesFilter = {
        eq: {
          'issue.status': 'OPEN'
        }
      };
      this.issueService.getFilteredItems(issuesFilter, true, 25).subscribe(issues => {
        this.checkIsLoaded();
        console.log(`Done with Issues length: ${issues.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });

      this.packageTypeService.getItems(true, 25).subscribe(packageTypes => {
        this.checkIsLoaded();
        console.log(`Done with PackageTypes length: ${packageTypes.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });

      // this.productService.getItems(true, 5).subscribe(products => {
      //   this.checkIsLoaded();
      //   console.log(`Done with Product length: ${products.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      // });

      this.facilityInventoryProductService.getItems(true, 25).subscribe(facilityInventoryProducts => {
        this.checkIsLoaded();
        console.log(`Done with FacilityInventoryProduct length: ${facilityInventoryProducts.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });

      if ( this.startupService.selectedRole === "facility") {
        const packageFilter = {eq: {'((printNodeOrder.shippingStatus = "NONE" AND date_add(printNodeOrder.created, INTERVAL 30 DAY) > now() ) OR date_add(printNodeOrder.created, INTERVAL 15 DAY) > now())': 1}};
        this.packageService.getFilteredItems(packageFilter, true, 25).subscribe(packages => {
          this.checkIsLoaded();
          console.log(`Done with Packages length: ${packages.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });
      }

      const printNodeOrderFilter = {
        eq: {'((printNodeOrder.shippingStatus = "NONE" AND date_add(printNodeOrder.created, INTERVAL 30 DAY) > now() ) OR date_add(printNodeOrder.created, INTERVAL 15 DAY) > now())': 1}
        // ne: { 'printNodeOrder.ID': 286249 }
      };
      this.printNodeOrderService.getFilteredItems(printNodeOrderFilter, true, 25).subscribe(printNodeOrders => {
        this.checkIsLoaded();
        console.log(`Done with PrintNodeOrders length: ${printNodeOrders.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });

      const facilityInvoiceFilter = {};
      this.facilityInvoiceService.getFilteredItems(facilityInvoiceFilter, true, 25).subscribe(facilityInvoices => {
        this.checkIsLoaded();
        console.log(`Done with FacilityInvoices length: ${facilityInvoices.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });
    }

    // Service Provider and Organizations
    if ( this.startupService.selectedRole === "organization" ||  this.startupService.selectedRole === "serviceProvider") {
      const issuesFilter = {
        eq: {
          'issue.status': 'OPEN'
        }
      };
      this.issueService.getFilteredItems(issuesFilter, true, 25).subscribe(issues => {
        this.checkIsLoaded();
        console.log(`Done with Issues length: ${issues.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });

      this.packageTypeService.getItems(true, 25).subscribe(packageTypes => {
        this.checkIsLoaded();
        console.log(`Done with PackageTypes length: ${packageTypes.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });

      if ( this.startupService.selectedRole === "organization") {
        this.projectService.getItems(true, 25).subscribe(products => {
          this.checkIsLoaded();
          console.log(`Done with Project length: ${products.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });
        this.productService.getItems(true, 25).subscribe(products => {
          this.checkIsLoaded();
          console.log(`Done with Product length: ${products.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });
        this.draftService.getItems(true, 25).subscribe(drafts => {
          this.checkIsLoaded();
          console.log(`Done with Draft length: ${drafts.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });
        this.addressService.getItems(true, 100).subscribe(address => {
          this.checkIsLoaded();
          console.log(`Done with Address length: ${address.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });
        this.chargeProfileService.getItems(true, 100).subscribe(address => {
          this.checkIsLoaded();
          console.log(`Done with ChargeProfiles length: ${address.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });
        const packageFilter = {eq: {'((printNodeOrder.shippingStatus = "NONE" AND date_add(printNodeOrder.created, INTERVAL 365*2 DAY) > now() ) OR date_add(printNodeOrder.created, INTERVAL 365*2 DAY) > now())': 1}};
        this.packageService.getFilteredItems(packageFilter, true, 25).subscribe(packages => {
          this.checkIsLoaded();
          console.log(`Done with Packages length: ${packages.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });

        const printNodeOrderFilter = {eq: {'((printNodeOrder.shippingStatus = "NONE" AND date_add(printNodeOrder.created, INTERVAL 365*2 DAY) > now() ) OR date_add(printNodeOrder.created, INTERVAL 365*2 DAY) > now())': 1}};
        this.printNodeOrderService.getFilteredItems(printNodeOrderFilter, true, 25).subscribe(printNodeOrders => {
          this.checkIsLoaded();
          console.log(`Done with PrintNodeOrders length: ${printNodeOrders.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });
      }
      if ( this.startupService.selectedRole === "serviceProvider") {
        const packageFilter = {eq: {'((printNodeOrder.shippingStatus = "NONE" AND date_add(printNodeOrder.created, INTERVAL 30 DAY) > now() ) OR date_add(printNodeOrder.created, INTERVAL 10 DAY) > now())': 1}};
        this.packageService.getFilteredItems(packageFilter, true, 25).subscribe(packages => {
          this.checkIsLoaded();
          console.log(`Done with Packages length: ${packages.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });

        const printNodeOrderFilter = {eq: {'((printNodeOrder.shippingStatus = "NONE" AND date_add(printNodeOrder.created, INTERVAL 30 DAY) > now() ) OR date_add(printNodeOrder.created, INTERVAL 10 DAY) > now())': 1}};
        this.printNodeOrderService.getFilteredItems(printNodeOrderFilter, true, 25).subscribe(printNodeOrders => {
          this.checkIsLoaded();
          console.log(`Done with PrintNodeOrders length: ${printNodeOrders.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
        });
      }

      this.facilityInventoryProductService.getItems(true, 25).subscribe(facilityInventoryProducts => {
        this.checkIsLoaded();
        console.log(`Done with FacilityInventoryProduct length: ${facilityInventoryProducts.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });


      const organizationFilter = {
        eq: {
          'state': 'ACTIVE'
        },
      };
      this.organizationService.getFilteredItems(organizationFilter, true, 50).subscribe(organizations => {
        this.checkIsLoaded();
        console.log(`Done with Organizations length: ${organizations.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });
      const organizationInvoiceFilter = {
        eq: {
          ' ( date_add(organizationInvoice.periodStart, INTERVAL 90 DAY) > now() ) ': 1
        },
        ne: {
          'organizationInvoice.amount': 0
        }
      };
      this.organizationInvoiceService.getFilteredItems(organizationInvoiceFilter, true, 50).subscribe(organizationInvoices => {
        this.checkIsLoaded();
        console.log(`Done with OrganizationInvoices length: ${organizationInvoices.length} role: ${this.startupService.selectedRoleName}-${this.startupService.selectedRoleId}`);
      });
    }
  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }


  //
  // public redirectToDetails = (id: string) => {
  //
  // }
  //
  // public redirectToUpdate = (id: string) => {
  //
  // }
  //
  // public redirectToDelete = (id: string) => {
  //
  // }

  public event(action: string, data: any, event) {

  }
  getKeys(object: any) {
    return Object.keys(object);
  }

  refresh () {
    this.getData();
  }
}
