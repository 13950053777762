import {DataObject} from "./DataObject";
import {PrintNodeOrderItem} from "./PrintNodeOrderItem";
import {ManufacturingTaskBucket} from "./ManufacturingTaskBucket";
import {ManufacturingResouceConsumer} from "./supporting/ManufacturingResourceConsumer";
import {CriteriaSet} from "./supporting/CriteriaSet";
import {ManufacturingSet} from "./ManufacturingSet";

export class ManufacturingTaskBucketPeriod implements DataObject {
  ID: number;
  manufacturingTaskBucket_id: number;
  status: string;
  period: string;
  manufacturingResourceBucketPeriodMapping: any;
  manufacturingTaskBucket: ManufacturingTaskBucket;
  manufacturingSets: ManufacturingSet[];
  printNodeOrderItems: PrintNodeOrderItem[];
  printnode_ID: number;

  constructor (obj) {
    this.ID = 0;
    this.manufacturingSets = [];

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if (obj) {
    //   for (const prop of Object.keys(obj)) {
    //     this[prop] = obj[prop];
    //     if ( prop === 'printNodeOrderItems') {
    //       this.printNodeOrderItems = [];
    //       for (const o of obj[prop]) {
    //         this.printNodeOrderItems.push(new PrintNodeOrderItem(o, o.printNodeOrder));
    //       }
    //     }
    //     if ( prop === 'manufacturingTaskBucket') {
    //       if ( obj[prop] ) {
    //         this.manufacturingTaskBucket = new ManufacturingTaskBucket(obj[prop]);
    //       }
    //     }
    //     if ( prop === 'manufacturingSets') {
    //       this.manufacturingSets = [];
    //       for (const o of obj[prop]) {
    //         this.manufacturingSets.push(new ManufacturingSet(o));
    //       }
    //     }
    //   }
    // }
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }

  consumeResources (role, manufacturingResouceConsumer: ManufacturingResouceConsumer) {
  }

  releaseResources (role, manufacturingResouceConsumer: ManufacturingResouceConsumer) {
  }

  areResourcesAvailable (manufacturingResouceConsumer: ManufacturingResouceConsumer): boolean {
    return false;
  }

  isCompatible (manufacturingResouceConsumer: ManufacturingResouceConsumer): boolean {
    return false;
  }

  // Move to UI Only
  getAllowedCharacteristics(role, manufacturingResouceConsumer: ManufacturingResouceConsumer): CriteriaSet {
    return this.manufacturingTaskBucket.criteriaSet; // ?? not sure this is correct
  }
  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
