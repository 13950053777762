import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatTable} from "@angular/material/table";
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import {PrintNodeOrderItem} from "../../../model/PrintNodeOrderItem";
import {Book} from "../../../model/Book";
import {ManufacturingSet} from "../../../model/ManufacturingSet";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {ProductService} from "../../service-data/product.service";
import {Product} from "../../../model/Product";
import {Subject} from "rxjs";

@Component({
  selector: 'app-print-node-orders-admin',
  templateUrl: './print-node-orders-admin.component.html',
  styleUrls: ['./print-node-orders-admin.component.css']
})

export class PrintNodeOrdersAdminComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [
    { columnDef: 'Order',            header: 'Order',            sort: true,  type: 'number',    filterType: 'String',          style: 'text-align: right',  property: 'order_ID',                                                           cell: (element: any) => `${element.order_ID}`  },
    { columnDef: 'Shipment',         header: 'Shipment',               sort: true,  type: 'number',    filterType: 'String',          style: 'text-align: right',  property: 'ID',                                                                 cell: (element: any) => `${element.ID}`  },
    { columnDef: 'created',          header: 'Created',          sort: true,  type: 'date',      filterType: 'String',          style: 'text-align: center', property: 'created',                                                            cell: (element: any) => `${element.created ? element.created : new Date() }`, dateFormat: 'MM/dd/yy' },
    // { columnDef: 'tag',            header: 'Tag',              sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'tag',                                                               cell: (element: any) => `${element.tag}`  },
    { columnDef: 'organizationId',   header: 'Org ID',           sort: true,  type: 'number',    filterType: 'String',          style: 'text-align: left',   property: 'ID',                                                                 cell: (element: any) => `${ element.organization ? element.organization.ID : ''}`  },
    { columnDef: 'organizationName', header: 'Organization',     sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'name',                                                               cell: (element: any) => `${ element.organization ? element.organization.name : ''}`  },
    { columnDef: 'name',             header: 'Name',             sort: true,  type: 'string',    filterType: 'CompositeString', style: 'text-align: left',   properties: ['firstname', 'lastname', 'company'],                               cell: (element: any) => `${element.firstname.toUpperCase()} ${element.lastname.toUpperCase()}`,                        cellRow2: (element: any) => `${element.company}`  },
    { columnDef: 'address',          header: 'Address',          sort: true,  type: 'string',    filterType: 'CompositeString', style: 'text-align: left',   properties: ['address1', 'address2', 'state', 'city', 'postalcode', 'country'], cell: (element: any) => `${element.address1.toUpperCase()} ${element.address2 ? element.address2.toUpperCase() : ''}`, cellRow2: (element: any) => `${element.city.toUpperCase()} ${element.state.toUpperCase()} ${element.postalcode ? element.postalcode : ''} ${element.country ? element.country.toUpperCase() : ''}`  },
    { columnDef: 'weight',           header: 'Weight',           sort: true,  type: 'number',    filterType: 'NumericRange',    style: 'text-align: right',  property: 'weight',                                                             cell: (element: any) => `${Math.round(element.weight * 10) / 10}` },
    { columnDef: 'thickness',        header: 'Thickness',        sort: true,  type: 'number',    filterType: 'NumericRange',    style: 'text-align: right',  property: 'thickness',                                                          cell: (element: any) => `${Math.round(element.thickness * 10) / 10}` },
    { columnDef: 'quantity',         header: 'Quantity',         sort: true,  type: 'number',    filterType: 'NumericRange',    style: 'text-align: right',  property: 'quantity',                                                           cell: (element: any) => `${element.quantity}` },
    { columnDef: 'duplicate',        header: 'Dup',              sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'tag4',                                                               cell: (element: any) => `${element.tag4 === 'DONTSHIP' ? 'Y' : ''}`},
    { columnDef: 'hold',             header: 'Hold',             sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'hold',                                                               cell: (element: any) => `${element.hold ? element.hold : ''}`},
    { columnDef: 'canceled',         header: 'Canceled',         sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'printnode_ID',                                                       cell: (element: any) => `${element.printnode_ID === 0 ? 'Y' : ''}`},
    { columnDef: 'fingerprint',      header: 'Fingerprint',      sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'tag2',                                                               cell: (element: any) => `${element.tag2}`},
    { columnDef: 'addressIssue',     header: 'Address',          sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'printnode_ID',                                                       cell: (element: any) => `${element.printnode_ID === 10000 ? 'Y' : ''}`},
    { columnDef: 'carrier',          header: 'Carrier',          sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'carrier',                                                            cell: (element: any) => `${element.servicelevel}` },
    { columnDef: 'shippingStatus',   header: 'Shipping Status',  sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'shippingStatus',                                                     cell: (element: any) => `${element.shippingStatus}`},
    // { columnDef: 'trackingNumbers', header: 'Tracking Numbers', sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'trackingNumbers',                                                    cell: (element: any) => `${element.trackingNumbers}`},
    { columnDef: 'trackingNumbers',  header: 'Tracking Numbers', sort: true,  type: 'link',      filterType: 'String',          style: 'text-align: left',   property: 'trackingNumbers',                                                    cell: (element: any) => `${element.shippingStatus === 'NONE' ? '' : element.trackingNumbers}`,   link: (element: any) => {
        const trackingNumbers = element.trackingNumbers; // .split(' ').join('_');
        if ( element.shippingStatus === 'NONE' ) {
          return '';
        }
        if (trackingNumbers.startsWith('94')) {
          return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + trackingNumbers.split(/_(.+)/)[0]; // trackingNumbers.split(',').join('%20');
        } else if (trackingNumbers.startsWith('1Z')) {
          return 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + trackingNumbers.split(/_(.+)/)[0]; // .split(',').join('%20');
        } else if (element.carrier === 'FEDEX') {
          return 'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=' + trackingNumbers.split(/_(.+)/)[0];
        } else {
          return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + trackingNumbers.split(/_(.+)/)[0]; // trackingNumbers.split(',').join('%20');
        }
      }
    }
  ];

  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<PrintNodeOrder>();
  public renderedData: PrintNodeOrder[];
  public products: Product[];
  tableFilterManager: TableFilterManager<PrintNodeOrder> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  role: string;
  shippedFilter: string = 'NONE';
  holdFilter: string = 'FLAGGED';
  duplicateFilter: string = 'NONE';
  minDate: Date;
  maxDate: Date;


  constructor(public router: Router, public startupService: StartupService, private printNodeOrderService: PrintNodeOrderService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public productService: ProductService) {
    this.startupService.addRoleChangeListener(this);
    // this.startupService.touchLastPrintNodeOrderActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 2, 0, 1);
    this.maxDate = new Date(currentYear + 2, 11, 31);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the printNodeOrder is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      // if ( d && d.length > 0 ) {
      //   this.renderedData = d;
      // }
      this.renderedData = this.dataSource.data;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: PrintNodeOrder, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
    // this.dataSource.paginator = this.paginator;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  // onSelect(printNodePrintNodeOrderItem: PrintNodePrintNodeOrderItem): void {
  //   this.selectedPrintNodePrintNodeOrderItem = printNodePrintNodeOrderItem;
  //   if ( this.selectedPrintNodePrintNodeOrderItem ) {
  //     this.selectedPrintNodePrintNodeOrder = this.selectedPrintNodePrintNodeOrderItem.printNodePrintNodeOrder;
  //   }
  // }

  getData(): void {
    const filter = { eq: {
      },
      ge: {
      },
      ne: {
      }
    };
    switch ( this.shippedFilter ) {
      case 'SHIPPED': {
        filter['eq']['printNodeOrder.shippingStatus'] = 'SHIPPED';
        break;
      }
      case 'NONE': {
        filter['eq']['printNodeOrder.shippingStatus'] = 'NONE';
        break;
      }
      case 'ALL': {
        delete filter['eq']['printNodeOrder.shippingStatus'];
        break;
      }
    }
    switch ( this.holdFilter ) {
      case 'FLAGGED': {
        filter['eq']['printNodeOrder.hold'] = 'CUSTOMER_REVIEW';
        break;
      }
      case 'NONE': {
        filter['ne']['printNodeOrder.hold'] = 'CUSTOMER_REVIEW';
        break;
      }
      case 'ALL': {
        delete filter['eq']['printNodeOrder.hold'];
        delete filter['ne']['printNodeOrder.hold'];
        break;
      }
    }
    switch ( this.duplicateFilter ) {
      case 'DUPLICATE': {
        filter['eq']['printNodeOrder.tag4'] = 'DONTSHIP';
        break;
      }
      case 'NONE': {
        filter['ne']['printNodeOrder.tag4'] = 'DONTSHIP';
        break;
      }
      case 'ALL': {
        delete filter['eq']['printNodeOrder.tag4'];
        delete filter['ne']['printNodeOrder.tag4'];
        break;
      }
    }
    if ( this.holdFilter === 'ALL' && this.duplicateFilter === 'ALL' && this.shippedFilter === 'ALL' ) {
      // this.role = this.printNodeOrderService.getRole('');
      this.printNodeOrderService.getItems()
        .subscribe(printNodeOrders => {
          this.dataSource.data = printNodeOrders;
          this.dataSource.filter = '' + Math.random();
        });
    } else {
      // this.role = this.printNodeOrderService.getRole(JSON.stringify(filter));
      this.printNodeOrderService.getFilteredItems(filter)
        .subscribe(printNodeOrders => {
          this.dataSource.data = printNodeOrders;
          this.dataSource.filter = '' + Math.random();
          // this.table.renderRows();
      });
    }
    this.productService.getItems()
      .subscribe(products => {
        this.products = products.sort((a, b) => b.book.tagId - a.book.tagId);
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  refresh () {
    this.printNodeOrderService.reset();
    this.getData();
  }

  dateChangeEvent(event: MatDatepickerInputEvent<Date>) {
    console.log(`dateChangeEvent: ${event.value}`);
    this.minDate = event.value;
    this.getData();
  }

  toggleShippingFilter() {
    switch ( this.shippedFilter ) {
      case 'SHIPPED': {
        this.shippedFilter = 'NONE';
        break;
      }
      case 'NONE': {
        this.shippedFilter = 'ALL';
        break;
      }
      case 'ALL': {
        this.shippedFilter = 'SHIPPED';
        break;
      }
    }
  }

  toggleHoldsFilter() {
    switch ( this.holdFilter ) {
      case 'FLAGGED': {
        this.holdFilter = 'NONE';
        break;
      }
      case 'NONE': {
        this.holdFilter = 'ALL';
        break;
      }
      case 'ALL': {
        this.holdFilter = 'FLAGGED';
        break;
      }
    }
  }
  toggleDuplicatesFilter() {
    switch ( this.duplicateFilter ) {
      case 'DUPLICATE': {
        this.duplicateFilter = 'NONE';
        break;
      }
      case 'NONE': {
        this.duplicateFilter = 'ALL';
        break;
      }
      case 'ALL': {
        this.duplicateFilter = 'DUPLICATE';
        break;
      }
    }
  }
}
