<mat-card appearance="outlined">
  <mat-card-content>
    <mat-form-field appearance="fill">
      <mat-label i18n="@@Contest Status">Contest Status</mat-label>
      <mat-select (selectionChange)="changeContestStatus($event.value)" [(value)]="organizationInvoice.contestStatus">
        <mat-option value="RESOLVED" i18n="@@RESOLVED">RESOLVED</mat-option>
        <mat-option value="ADJUSTED" i18n="@@ADJUSTED">ADJUSTED</mat-option>
        <mat-option value="UNCONTESTED" i18n="@@UNCONTESTED">UNCONTESTED</mat-option>
        <mat-option value="CONTESTED" i18n="@@CONTESTED">CONTESTED</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label i18n="@@Recurring">Recurring</mat-label>
      <mat-select (selectionChange)="changeRecurring($event.value)" [(value)]="organizationInvoice.recurring">
        <mat-option value="STOP" i18n="@@STOP">STOP</mat-option>
        <mat-option value="CONTINUE" i18n="@@CONTINUE">CONTINUE</mat-option>
        <mat-option value="ONETIME" i18n="@@ONETIME">ONETIME</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label i18n="@@Status">Status</mat-label>
      <mat-select (selectionChange)="changeStatus($event.value)" [(value)]="organizationInvoice.status">
        <mat-option value="INITIAL" i18n="@@Initial">Initial</mat-option>
        <mat-option value="SENT" i18n="@@SENT">SENT</mat-option>
        <mat-option value="PAID" i18n="@@PAID">PAID</mat-option>
        <mat-option value="PREPARING" i18n="@@Preparing">Preparing</mat-option>
        <mat-option value="FINALIZED" i18n="@@FINALIZED">FINALIZED</mat-option>
        <mat-option value="CONTESTED" i18n="@@CONTESTED">CONTESTED</mat-option>
        <mat-option value="EMPTY" i18n="@@EMPTY">EMPTY</mat-option>
        <mat-option value="IGNORE" i18n="@@IGNORE">IGNORE</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label i18n="@@Note To Customer">Note To Customer</mat-label>
      <textarea (change)="changeNoteToCustomer()" name="noteToCustomer" [(ngModel)]="organizationInvoice.noteToCustomer" matInput i18n-placeholder="@@Note To Customer" placeholder="Note To Customer"></textarea>
    </mat-form-field>
    <button disabled mat-icon-button (click)="sendInvoice()" i18n-matTooltip="@@Send Invoice" matTooltip="Send Invoice"><mat-icon>outgoing_mail</mat-icon></button>
    <button mat-raised-button color="primary" (click)="downloadInvoiceHTMLFile()" type="submit" i18n="@@Download Invoice HTML File">Download Invoice HTML File</button>
  </mat-card-content>
</mat-card>
