import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {AddressIssueOrderDeliverToService} from "../../service-data/address-issue-order-deliver-to.service";
import {OrderDeliverTo} from "../../../model/OrderDeliverTo";
import {Order} from "../../../model/Order";
import {OrderService} from "../../service-data/order.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-billing-orders',
  templateUrl: './billing-orders.component.html',
  styleUrls: ['./billing-orders.component.css']
})

export class BillingOrdersComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [
     { property: 'ID',                       columnDef: 'ID',                       header: 'ID',                   sort: true,  type: 'number',  filterType: 'String',       style: 'text-align: right',  cell: (element: any) => `${element.ID}` },
     { property: 'created',                  columnDef: 'created',                  header: 'Created',              sort: true,  type: 'date',    filterType: 'String',       style: 'text-align: right',                   cell: (element: any) => `${element.created}`, dateFormat: 'yyyy-MM-dd' },
     { property: 'umbrella',                 columnDef: 'umbrella',                 header: 'Umbrella',             sort: true,  type: 'string',  filterType: 'String',       style: 'text-align: right',                   cell: (element: any) => `${element.organization ? element.organization.agent_ID : '' }`  },
     { property: 'organizationName',         columnDef: 'organizationName',         header: 'Organization Name',    sort: true,  type: 'string',  filterType: 'String',       style: 'text-align: right',                   cell: (element: any) => `${element.organization ? element.organization.name : ''}`  },
     { property: 'shipmentsTotal',           columnDef: 'shipmentsTotal',           header: 'Shipments Total',      sort: true,  type: 'number',  filterType: 'NumericRange', style: 'text-align: right',                   cell: (element: any) => `${element.shipmentsTotal}` },
     { property: 'shipmentsShipped',         columnDef: 'shipmentsShipped',         header: 'Shipments Shipped',    sort: true,  type: 'number',  filterType: 'NumericRange', style: 'text-align: right',                   cell: (element: any) => `${element.shipmentsShipped}` },
     { property: 'shipmentsHold',            columnDef: 'shipmentsHold',            header: 'Shipments Hold',       sort: true,  type: 'number',  filterType: 'NumericRange', style: 'text-align: right',                   cell: (element: any) => `${element.shipmentsHold}` },
     { property: 'shipmentsBadAddress',      columnDef: 'shipmentsBadAddress',      header: 'Shipment Bad Address', sort: true,  type: 'number',  filterType: 'NumericRange', style: 'text-align: right',                   cell: (element: any) => `${element.shipmentsBadAddress}` },
     { property: 'shipmentsDuplicates',      columnDef: 'shipmentsDuplicates',      header: 'Shipment Duplicates',  sort: true,  type: 'number',  filterType: 'NumericRange', style: 'text-align: right',                   cell: (element: any) => `${element.shipmentsDuplicates}` },
     { property: 'quantity',                 columnDef: 'quantity',                 header: 'Quantity',             sort: true,  type: 'number',  filterType: 'NumericRange', style: 'text-align: right',                   cell: (element: any) => `${element.quantity}` },
     { property: 'addedToInvoiceStatus',     columnDef: 'addedToInvoiceStatus',     header: 'Invoiced',             sort: true,  type: 'string',  filterType: 'String',       style: 'text-align: right',                   cell: (element: any) => `${element.addedToInvoiceStatus}` },
     { property: 'packingCharge',            columnDef: 'packingCharge',            header: 'Packing',              sort: true,  type: 'currency',  filterType: 'NumericRange', style: 'text-align: right',                 cell: (element: any) => `${element.packingCharge}`, displayClass: 'USCurrency' },
     { property: 'shippingCharge',           columnDef: 'shippingCharge',           header: 'Shipping',             sort: true,  type: 'currency',  filterType: 'NumericRange', style: 'text-align: right',                 cell: (element: any) => `${element.shippingCharge}`, displayClass: 'USCurrency' },
     { property: 'shippingCharge',           columnDef: 'totalCharge',              header: 'Total',                sort: true,  type: 'currency',  filterType: 'NumericRange', style: 'text-align: right',                 cell: (element: any) => `${element.packingCharge + element.shippingCharge}`, displayClass: 'USCurrency' },
     { property: 'invoicedByCustomerStatus', columnDef: 'invoicedByCustomerStatus', header: 'Customer Invoiced',    sort: true,  type: 'string',  filterType: 'NumericRange', style: 'text-align: right',                   cell: (element: any) => `${element.invoicedByCustomerStatus}` },
     { property: 'customerNotes',            columnDef: 'customerNotes',            header: 'Notes',                sort: true,  type: 'string',  filterType: 'String', style: 'text-align: right',                         cell: (element: any) => `${element.customerNotes ? element.customerNotes : ''}` }
     // { property: 'inventoryType',          columnDef: 'inventoryType',       header: 'Inventory Type',       sort: true,  type: 'string',  filterType: 'String',       style: '',                   cell: (element: any) => `${element.inventoryType}`  }
  ];

  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<Order>();
  public renderedData: Order[];
  tableFilterManager: TableFilterManager<Order> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor(public router: Router, public startupService: StartupService, public orderService: OrderService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.addRoleChangeListener(this);
    // this.startupService.touchLastPrintNodeOrderActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the printNodeOrder is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      this.renderedData = this.dataSource.data;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: Order, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  // onSelect(printNodePrintNodeOrderItem: PrintNodePrintNodeOrderItem): void {
  //   this.selectedPrintNodePrintNodeOrderItem = printNodePrintNodeOrderItem;
  //   if ( this.selectedPrintNodePrintNodeOrderItem ) {
  //     this.selectedPrintNodePrintNodeOrder = this.selectedPrintNodePrintNodeOrderItem.printNodePrintNodeOrder;
  //   }
  // }

  getData(): void {
    // this.dataSource.data = [];
    this.orderService.getOrders()
      .subscribe(orders => {
        // for (const printNodeOrder of printNodeOrders) {
        //   console.log("In PrintNodeOrderComponent getting data from PrintNodeOrderService: " + JSON.stringify(printNodeOrder));
        //   this.dataSource.data.push(printNodeOrder);
        // }
        this.dataSource.data = orders;
        this.dataSource.filter = '' + Math.random();
        // this.table.renderRows();
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  refresh () {
    this.orderService.reset();
    this.getData();
  }
}
