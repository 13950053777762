<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Create Replenishment Order">Create Replenishment Order</h2>
    <h3 class="primary">{{facilityInventoryProduct.title}}</h3>
  </div>
  <div>
    <form [formGroup]="formGroup" (change)="formChanged()">
      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-select  placeholder="Product" i18n-placeholder="@@Product" formControlName="product_ID" required>
                <mat-option *ngFor="let product of products" [value]="product">{{product}}</mat-option>
<!--                <mat-option *ngFor="let product of products" [value]="product.ID">{{product.ID}} {{product.title}}</mat-option>-->
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <mat-select  placeholder="Facility" i18n-placeholder="@@Facility" formControlName="facilityId" required>-->
<!--                <mat-option value="6" i18n="@@Houston" selected>Houston</mat-option>-->
<!--                <mat-option value="7" i18n="@@California">California</mat-option>-->
<!--                <mat-option value="1" i18n="@@Phoenix">Phoenix</mat-option>-->
<!--                <mat-option value="8" i18n="@@South Carolina">South Carolina</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Quantity" placeholder="Quantity" id="quantity" required type="number" min="1"  max="1500" formControlName="quantity">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Batch" placeholder="Batch" id="batch" required type="text" formControlName="batch">
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="formGroup.invalid" (click)="onSave()" i18n="@@Create Replenishment Order">Create Replenishment Order</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
