import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../model/User";
import {OrganizationService} from "../../../service-data/organization.service";
import {UserService} from "../../../service-data/user.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @Input() user: User;

  constructor(public organizationService: OrganizationService, public userService: UserService) { }

  ngOnInit() {
  }

  revokeOrganizationAdmin (user: User) {
    this.organizationService.revokeOrganizationAdmin(user).subscribe( x => {
      user.isAdmin = 'FALSE';
      console.log("revokeOrganizationAdmin " + user.email);
    });

  }
  grantOrganizationAdmin (user: User) {
    this.organizationService.grantOrganizationAdmin(user).subscribe( x => {
      user.isAdmin = 'TRUE';
      console.log("revokeOrganizationAdmin " + user.email);
    });
  }

  resendEmailInvite (user: User) {
    this.userService.resendEmailInvite(user).subscribe( x => {
      console.log("resendEmailInvite " + user.email);
    });
  }

  disable (user: User) {
    this.userService.disable(user).subscribe( usr => {
      console.log("Disabled User");
    });
  }

  enable (user: User) {
    this.userService.enable(user).subscribe( usr => {
      console.log("Enabled User");
    });
  }
}


