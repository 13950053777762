import {DataObject} from "./DataObject";

export class Issue implements DataObject {
  ID: number;
  supportRole: string;
  supportId: number;
  userRole: string;
  userId: number;
  created: Date;
  status: string;
  level: number;
  assignedTo: string;
  objectType: string;
  objectId: number;
  supportPerson: string;
  supportStatus: string;
  supportNote: string;
  userPerson: string;
  userStatus: string;
  userNote: string;

  constructor (obj) {
    this.ID = 0;
    this.supportRole = '';
    this.userRole = '';
    this.created = new Date(0);
    this.status = 'OPEN';
    this.level = 0;
    this.assignedTo = 'SUPPORT';
    this.objectType = '';
    this.objectId = 0;
    this.supportPerson = '';
    this.supportStatus = 'ASSIGNED';
    this.supportNote = '';
    this.userPerson = '';
    this.userStatus = 'ASSIGNED';
    this.userNote = '';

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
  }

  getId() {
    return this.ID;
  }

  getOrderValue() {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
