import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {OrderDeliverTo} from "../../model/OrderDeliverTo";
import {DataService} from "./data-service";

@Injectable()
export class AddressIssueOrderDeliverToService extends DataService {
  private objects: OrderDeliverTo[] = [];
  private objectsObservable = of( this.objects );
  public loadingState = 'INITIAL';


  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
    super(startupService);
  }

    reset (filter: string = '') {
    this.loadingState = 'INITIAL';
    while ( this.objects.length > 0) {
      this.objects.pop();
    }
  }

  getOrderDeliverTos(): Observable<OrderDeliverTo[]> {
    if ( this.loadingState === 'INITIAL') {
      this.loadingState = 'LOADING';
      const url = `${environment.apiBaseURL}addressissue`;
      this.messageService.add('OrderDeliverToService: fetched orderDeliverTos');
      return this.http.get<OrderDeliverTo[]>(url, this.startupService.getHttpOptions())
        .pipe(
          map(orderDeliverTos => {
            while ( this.objects.length > 0) {
              this.objects.pop();
            }
            for ( const orderDeliverTo of orderDeliverTos ) {
              const o = new OrderDeliverTo(orderDeliverTo);
              this.objects.push(o);
            }
            this.loadingState = 'LOADED';
            return this.objects;
          }), catchError(this.handleError('getOrderDeliverTos', []))
        );
    } else {
      return this.objectsObservable;
    }
  }


  getOrderDeliverTo(id: string): Observable<OrderDeliverTo> {
    const url = `${environment.apiBaseURL}addressissue/${id}`;
    this.messageService.add(`OrderDeliverToService: fetched orderDeliverTo id=${id}`);
    return this.http.get<OrderDeliverTo>(url, this.startupService.getHttpOptions())
      .pipe(
        map(orderDeliverTo => {
          const p = new OrderDeliverTo(orderDeliverTo);
          return p;
        }),
        catchError(this.handleError<OrderDeliverTo>(`getOrderDeliverTo id=${id}`))
    );
  }

  /** PUT: update the orderDeliverTo on the server */
  updateOrderDeliverTo (orderDeliverTo: OrderDeliverTo): Observable<OrderDeliverTo> {
    const url = `${environment.apiBaseURL}addressissue/${orderDeliverTo.ID}`;
    this.messageService.add(`OrderDeliverToService: updated orderDeliverTo id=${orderDeliverTo.ID}`);
    return this.http.put(url, orderDeliverTo, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated orderDeliverTo id=${orderDeliverTo.ID}`)),
      catchError(this.handleError<any>('updateOrderDeliverTo'))
    );
  }

  addOrderDeliverTo (orderDeliverTo: OrderDeliverTo): Observable<OrderDeliverTo> {
    const url = `${environment.apiBaseURL}addressissue`;
    return this.http.post<OrderDeliverTo>(url, orderDeliverTo, this.startupService.getHttpOptions()).pipe(
      tap(o => {
        this.log(`added orderDeliverTo w/ id=${orderDeliverTo.ID}`);
        this.objects.push(new OrderDeliverTo(o));
     }),
      catchError(this.handleError<OrderDeliverTo>('addOrderDeliverTo'))
    );
  }

  /** DELETE: delete the orderDeliverTo from the server */
  deleteOrderDeliverTo (orderDeliverTo: OrderDeliverTo): Observable<OrderDeliverTo> {
    const url = `${environment.apiBaseURL}addressissue/${orderDeliverTo.ID}`;

    return this.http.delete<OrderDeliverTo>(url, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`deleted orderDeliverTo id=${orderDeliverTo.ID}`)),
      catchError(this.handleError<OrderDeliverTo>('deleteOrderDeliverTo'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add('OrderDeliverToService: ' + message);
  }

}
