import {Component, Input, OnInit} from '@angular/core';
import {IssueService} from "../../service-data/issue.service";
import {Issue} from "../../../model/Issue";
import {StartupService} from "../../service-ui/startup.service";

@Component({
  selector: 'app-issue',
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.css']
})

export class IssueComponent implements OnInit {
  @Input() issue: Issue;
  @Input() userRoleName: string;
  @Input() userRoleId: number;
  @Input() supportRoleName: string;
  @Input() supportRoleId: number;
  @Input() objectType: string;
  @Input() objectId: number;
  @Input() displayOnly: boolean;
  @Input() createOnly: boolean;
  @Input() title: string;

  edit: boolean = false;

  // issue: Issue;
  changedIssue: Issue;
  saving: boolean = false;
  changed: boolean = false;

  supportStatusColors: any = {
    'INITIAL': '',
    'ASSIGNED': 'lightcoral',
    'ACKNOWLEDGED': StartupService.colorAliases['errorBackground'],
    'WORKING': 'yellow',
    'RESOLVED': 'lightgreen',
    'BLOCKED': 'orange',
    'CANCELED': 'lightgrey',
    'WAITINGRESPONSE': 'lightsalmon'
  };

  userStatusColors: any = {
    'INITIAL': '',
    'ASSIGNED': 'lightcoral',
    'ACKNOWLEDGED': StartupService.colorAliases['errorBackground'],
    'WORKING': 'yellow',
    'RESOLVED': 'lightgreen',
    'BLOCKED': 'orange',
    'CANCELED': 'lightgrey',
    'WAITINGRESPONSE': 'lightsalmon'
  };


  constructor(public startupService: StartupService, public issueService: IssueService) {
  }

  ngOnInit() {
    if ( !this.issue ) {
      try {
        this.issueService.getIssue(this.objectType, this.objectId, this.userRoleName, this.userRoleId, this.supportRoleName, this.supportRoleId).subscribe( issue => {
          if ( issue ) {
            this.issue = issue;
          } else {
            console.log(`Trouble getting issue for objectType[${this.objectType}] objectId[${this.objectId}]]`);
          }
        });
      } catch ( e ) {
        console.log(`Error getting issue for objectType[${this.objectType}] objectId[${this.objectId}]]`);
      }
    }
  }

  // update(issue: Issue) {
  //   this.saving = true;
  //   this.changed = false;
  //   this.issueService.updateItem(issue).subscribe( responseIssue => {
  //     this.saving = false;
  //     this.issue = responseIssue;
  //   });
  // }
  //

  save() {
    this.saving = true;
    this.changed = false;
    if ( this.issue ) {
      this.issueService.updateItem(this.changedIssue).subscribe( responseIssue => {
        this.saving = false;
        this.issue = responseIssue;
      });
    } else {
      this.issueService.addItem(this.changedIssue).subscribe( responseIssue => {
        this.saving = false;
        this.issue = responseIssue;
      });
    }
  }

  cancel() {
    this.changed = false;
  }

  copyIssueForEdits(issue: Issue) {
    if ( issue ) {
      this.changedIssue = new Issue(issue);
    } else {
      this.changedIssue = new Issue(issue);
      this.changedIssue.userRole = this.userRoleName;
      this.changedIssue.userId = this.userRoleId;
      this.changedIssue.supportRole = this.supportRoleName;
      this.changedIssue.supportId = this.supportRoleId;
      this.changedIssue.objectType = this.objectType;
      this.changedIssue.objectId = this.objectId;
      this.changedIssue.created = new Date();
    }
  }
}
