import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UserRegistrationService} from "../../service-auth/user-registration.service";
import {UserLoginService} from "../../service-auth/user-login.service";
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {StartupService} from "../../service-ui/startup.service";
import {environment as env} from "../../../environments/environment";

@Component({
    selector: 'app-account',
    templateUrl: './confirmRegistration.html',
    styleUrls: ['../component-account.scss']

})

export class RegistrationConfirmationComponent implements OnInit, OnDestroy {
    environment = env;
    confirmationCode: string;
    email: string;
    errorMessage: string;
    private sub: any;

    constructor(public regService: UserRegistrationService, public router: Router, public route: ActivatedRoute) {
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.email = params['username'];

        });

        this.errorMessage = null;
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    onConfirmRegistration() {
        this.errorMessage = null;
        this.regService.confirmRegistration(this.email, this.confirmationCode, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { // error
            this.errorMessage = message;
            console.log("message: " + this.errorMessage);
        } else { // success
            // move to the next step
            console.log("Moving to secure");
            // this.configs.curUser = result.user;
            this.router.navigate(['/secure']);
        }
    }
}





