import {ManufacturingSetRule} from "./supporting/ManufacturingSetRule";
import {PrintNodeOrderItem} from "./PrintNodeOrderItem";
import {Criteria} from "./supporting/Criteria";
import {CriteriaSet} from "./supporting/CriteriaSet";
import {ManufacturingTask} from "./ManufacturingTask";
import {DataObject} from "./DataObject";
import {ManufacturingTaskBucketPeriod} from "./ManufacturingTaskBucketPeriod";

export class ManufacturingSet implements DataObject {
  ID: number;
  printnode_id: number;
  details: any;
  created: Date;
  duedate: Date;
  manufacturingProcess_id: number;
  totalcompleted: number;
  units: string;
  total: number;
  status = 'INITIAL'; //         enum: ["INITIAL", "STARTED", "PAUSED", "COMPLETED", "ERROR"],
  lastUpdate: Date;
  openStatus = 'OPEN'; //         enum: ["OPEN", "CLOSED"],
  manufacturingSetRule: ManufacturingSetRule;
  criteriaSet: CriteriaSet;
  manufacturingTaskBucketPeriod_id: number;

  printNodeOrderItems: PrintNodeOrderItem[];
  manufacturingTasks: ManufacturingTask[];
  manufacturingTasksById: any = {};
  rootManufacturingTasks: ManufacturingTask[] = [];
  nextManufacturingTasks: ManufacturingTask[] = [];
  manufacturingTaskBucketPeriod: ManufacturingTaskBucketPeriod;

  constructor (obj) {
    this.ID = 0;
    this.lastUpdate = new Date(0);
    this.printnode_id = 0;
    this.details = {};
    this.created = new Date(0);
    this.duedate = new Date(0);
    this.manufacturingProcess_id = 0;
    this.totalcompleted = 0;
    this.units = '';
    this.total = 0;
    this.status = 'INITIAL'; //         enum: ["INITIAL", "STARTED", "PAUSED", "COMPLETED", "ERROR"],
    this.openStatus = 'OPEN'; //         enum: ["OPEN", "CLOSED"],
    this.manufacturingSetRule = undefined;
    this.criteriaSet = undefined;
    this.manufacturingTaskBucketPeriod_id = 0;
    this.manufacturingTaskBucketPeriod = undefined;
    this.printNodeOrderItems = [];

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if (obj) {
    //   for (const prop of Object.keys(obj)) {
    //     this[prop] = obj[prop];
    //   }
    //   if ( obj.criteriaSet ) {
    //     this.criteriaSet = new CriteriaSet(obj.criteriaSet);
    //   } else {
    //     this.criteriaSet = new CriteriaSet(undefined);
    //   }
    //   if ( obj.manufacturingSetRule ) {
    //     this.manufacturingSetRule = new ManufacturingSetRule(obj.manufacturingSetRule);
    //   } else {
    //     this.manufacturingSetRule = undefined;
    //   }
    //   if ( obj.printNodeOrderItems ) {
    //     this.printNodeOrderItems = [];
    //     for ( const printNodeOrderItem of obj.printNodeOrderItems ) {
    //       if ( printNodeOrderItem instanceof PrintNodeOrderItem ) {
    //         this.printNodeOrderItems.push(printNodeOrderItem);
    //       } else {
    //         const pnoi = new PrintNodeOrderItem(printNodeOrderItem, printNodeOrderItem.printNodeOrder);
    //         pnoi.manufacturingSet = this;
    //         this.printNodeOrderItems.push(pnoi);
    //       }
    //     }
    //   }
    //   if ( obj.manufacturingTasks ) {
    //     this.manufacturingTasks = [];
    //     for ( const manufacturingTask of obj.manufacturingTasks ) {
    //       if ( manufacturingTask instanceof ManufacturingTask ) {
    //         this.manufacturingTasks.push(manufacturingTask);
    //       } else {
    //         const mt = new ManufacturingTask(manufacturingTask, new ManufacturingResource(obj['manufacturingResource']), this, new ManufacturingWork(obj['manufacturingWork']));
    //         this.manufacturingTasks.push(mt);
    //       }
    //     }
    //   }
    //   if ( obj.manufacturingTaskBucketPeriod ) {
    //     this.manufacturingTaskBucketPeriod = new ManufacturingTaskBucketPeriod(obj.manufacturingTaskBucketPeriod);
    //   }
    // }

  }

  public getId() {
        return this.ID;
  }
  public getOrderValue() {
      return this.ID;
  }



  // // Does this PrintNodeOrderItem match all of the criteria for this ManufacturingRuleSet
    matchesFilterValues(printNodeOrderItem: PrintNodeOrderItem): boolean {
        if (printNodeOrderItem && this.criteriaSet && this.criteriaSet.criteria) {
            for (let c of this.criteriaSet.criteria) {
                if ( !(c instanceof Criteria) ) {
                    c = new Criteria(c);
                }
                if ( !( c.matchesFilterValues(printNodeOrderItem) ) ) {
                    return false;
                }
            }
        }
        console.log('TRUE Testing ManufacturingSet.matchesFilterValues Criteria: ' + JSON.stringify(this.criteriaSet.criteria) );
        return true;
    }

    setDueDateTotalQuantity() {
        let quantity = 0;
        let duedate = new Date();
        for ( const printNodeOrderItem of this.printNodeOrderItems ) {
            quantity += printNodeOrderItem.quantity;
            if ( duedate > printNodeOrderItem.printNodeOrder.dueDate) {
                duedate = printNodeOrderItem.printNodeOrder.dueDate;
            }
        }
        this.total = quantity;
        this.duedate = duedate;
        const details = {};
        details['rule'] = this.manufacturingSetRule.name;
        details['ruleType'] = this.manufacturingSetRule.type;
        for ( let criteria of this.manufacturingSetRule.criteria) {
            if ( !(criteria instanceof Criteria) ) {
                criteria = new Criteria(criteria);
            }
            if ( criteria.type === 'ENUM' ) {
                details[criteria.property] = criteria.enumValues;
            }
            if ( criteria.type === 'NUMERIC' ) {
                details[criteria.property] = [ criteria.min, criteria.max ];
            }

        }
        for ( let criteria of this.criteriaSet.criteria ) {
            if ( !(criteria instanceof Criteria) ) {
                criteria = new Criteria(criteria);
            }
            details[criteria.property] = criteria.value;
        }
        this.details = details;
    }

  setupManufacturingSetsById() {
    if ( (Object.keys(this.manufacturingTasksById)).length === 0 ) {
      for ( const manufacturingTask of this.manufacturingTasks ) {
        this.manufacturingTasksById['' + manufacturingTask.ID] = manufacturingTask;
      }
    }
  }

  getRootManufacturingTasks(): ManufacturingTask[] {
    this.setupManufacturingSetsById();
    if ( this.rootManufacturingTasks.length === 0 ) {
      const list = [];
      for ( const manufacturingTask of this.manufacturingTasks ) {
        list.push(manufacturingTask);
      }
      // Start with a list of all tasks and remove all tasks that are mentioned in a next reference -- what is left are the roots
      for ( let i = list.length - 1; i >= 0; i--) {
        const currentManufacturingTask = list[i];
        let notFound = true;
        for ( let j = this.manufacturingTasks.length - 1; j >= 0 && notFound ; j--) {
          if ( this.manufacturingTasks[j].next.indexOf('' + currentManufacturingTask.ID) !== -1) {
            list.splice(i, 1);
            notFound = false;
          }
        }
      }
      this.rootManufacturingTasks = list;
    }
    return this.rootManufacturingTasks;
  }

  recomputeNextManufacturingTasks() {
    this.nextManufacturingTasks = [];
    this.getNextManufacturingTasksToExecuteFromRoots();
  }

  getNextManufacturingTasksToExecuteFromRoots(): ManufacturingTask[] {
    if ( this.nextManufacturingTasks.length === 0 ) {
      const roots = this.getRootManufacturingTasks();
      this.nextManufacturingTasks = this.getNextManufacturingTasksToExecute(roots);
    }
    return this.nextManufacturingTasks;
  }

  getNextManufacturingTasksToExecute(manufacturingTasks: ManufacturingTask[]): ManufacturingTask[] {
    const nextTasks: ManufacturingTask[] = [];
    for ( const manufacturingTask of manufacturingTasks ) {
      if ( manufacturingTask.status === 'COMPLETED' || this.status === 'COMPLETED' ) {
        const nexts = this.getNextManufacturingTasks(manufacturingTask);
        const nextsToExecute = this.getNextManufacturingTasksToExecute(nexts); // This will recursively call this function until it gets to the end
        for ( const next of nextsToExecute ) {
          nextTasks.push(next);
        }
      } else {
        // Since it is not COMPLETED it must still need to be done or is ERROR and is going to hold everything up
        nextTasks.push(manufacturingTask);
      }
    }
    return this.getListWithoutDuplicates(nextTasks);
  }

  getListWithoutDuplicates(manufacturingTasks: ManufacturingTask[]): ManufacturingTask[] {
    if ( manufacturingTasks.length < 2 ) {
      return manufacturingTasks;
    }
    // if ( manufacturingTasks.length === 2 ) {
    //   if ( manufacturingTasks[0].ID === manufacturingTasks[1].ID ) {
    //     console.log('Same');
    //   }
    // }
    for ( let i = manufacturingTasks.length - 1; i >= 0; i-- )  {
      for ( let j = 0; j < i; j++ )  {
        if ( manufacturingTasks[i].ID === manufacturingTasks[j].ID) {
          manufacturingTasks.splice(i, 1);
        }
      }
    }
    return manufacturingTasks;
  }
  getNextManufacturingTasks(manufacturingTask: ManufacturingTask): ManufacturingTask[] {
    const nextTasks: ManufacturingTask[] = [];
    if ( manufacturingTask.next && manufacturingTask.next.length > 2 ) {
      const nextList = JSON.parse(manufacturingTask.next);
      for ( const nextId of nextList ) {
        if ( this.manufacturingTasksById['' + nextId] ) {
          nextTasks.push(this.manufacturingTasksById['' + nextId]);
        }
      }
    }
    return nextTasks;
  }
  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
      if ( obj.details ) {
        this.details = obj.details;
      } else {
        this.details = {};
      }
      if ( obj.criteriaSet ) {
        this.criteriaSet = new CriteriaSet(obj.criteriaSet);
      } else {
        this.criteriaSet = new CriteriaSet(undefined);
      }
      if ( obj.manufacturingSetRule ) {
        this.manufacturingSetRule = new ManufacturingSetRule(obj.manufacturingSetRule);
      } else {
        this.manufacturingSetRule = undefined;
      }
    }
  }
}
