import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {Product} from "../../../model/Product";

export class PickSheetsGroup {
  public type: string;
  public packageType: string;
  public date: string;
  public tag: string;
  public tagType: string;
  public orders: number = 0;
  public orderIds: number[] = [];
  public books: number = 0;
  public titleQuantities: any = {};
  public selected: boolean = false;
  public group: string;

  constructor (obj: any) {
    this.type = '';
    this.packageType = '';
    this.date = '';
    this.tag = '';
    this.tagType = '';
    this.orders = 0;
    this.orderIds = [];
    this.books = 0;
    this.titleQuantities = {};
    this.selected = false;
    this.group = '';

    if ( obj ) {
      for (const prop of Object.keys(obj)) {
        this[prop] = obj[prop];
      }
    }
  }

  public getId() {
    return this.type + '-' + this.packageType + '-' + this.date + '-' + this.tag;
  }

  getIndex () {
    switch ( this.tag ) {
      case 'KA': return 0;
      case '1A': return 1;
      case '2A': return 2;
      case '3A': return 3;
      case '4A': return 4;
      case '5A': return 5;
      case 'KB': return 6;
      case '1B': return 7;
      case '2B': return 8;
      case '3B': return 9;
      case '4B': return 10;
      case '5B': return 11;
    }
    return 0;
  }
}
