import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {
    CognitoUserAttribute,
    CognitoUserPool
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as awsservice from 'aws-sdk/lib/service';
import * as CognitoIdentity from 'aws-sdk/clients/cognitoidentity';


export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;
}

export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface Callback {
    callback(): void;
    callbackWithParam(result: any): void;
}

@Injectable()
export class CognitoUtil {

    public static _REGION = environment.region;

    public static _IDENTITY_POOL_ID = environment.identityPoolId;
    public static _USER_POOL_ID = environment.userPoolId;
    public static _CLIENT_ID = environment.clientId;

    public static _POOL_DATA: any = {
        UserPoolId: CognitoUtil._USER_POOL_ID,
        ClientId: CognitoUtil._CLIENT_ID
    };

    public cognitoCreds: AWS.CognitoIdentityCredentials;

    getUserPool() {
        if (environment.cognito_idp_endpoint) {
            CognitoUtil._POOL_DATA.endpoint = environment.cognito_idp_endpoint;
        }
        return new CognitoUserPool(CognitoUtil._POOL_DATA);
    }

    getCurrentUser() {
        return this.getUserPool().getCurrentUser();
    }

    // AWS Stores Credentials in many ways, and with TypeScript this means that
    // getting the base credentials we authenticated with from the AWS globals gets really murky,
    // having to get around both class extension and unions. Therefore, we're going to give
    // developers direct access to the raw, unadulterated CognitoIdentityCredentials
    // object at all times.
    setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
        this.cognitoCreds = creds;
    }

    getCognitoCreds() {
        return this.cognitoCreds;
    }

    // This method takes in a raw jwtToken and uses the global AWS config options to build a
    // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
    // to avoid unnecessary calls to setCognitoCreds.

    buildCognitoCreds(idTokenJwt: string) {
        let url = 'cognito-idp.' + CognitoUtil._REGION.toLowerCase() + '.amazonaws.com/' + CognitoUtil._USER_POOL_ID;
        if (environment.cognito_idp_endpoint) {
            url = environment.cognito_idp_endpoint + '/' + CognitoUtil._USER_POOL_ID;
        }
        const logins: CognitoIdentity.LoginsMap = {};
        logins[url] = idTokenJwt;
        const params = {
            IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID, /* required */
            Logins: logins
        };

        // Added by Todd
        // AWSCognito.config.update({accessKeyId: 'anything', secretAccessKey: 'anything'}); // Added to deal with the every other time not being logged in problem

        AWS.config.region = 'us-east-1'; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID
        });
        // End added by Todd


        const serviceConfigs: awsservice.ServiceConfigurationOptions = {};
        if (environment.cognito_identity_endpoint) {
           serviceConfigs.endpoint = environment.cognito_identity_endpoint;
        }
        const creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
        this.setCognitoCreds(creds);
        return creds;
    }


    getCognitoIdentity(): string {
        return this.cognitoCreds.identityId;
    }

    getAccessToken(callback: Callback): void {
        if (callback == null) {
            throw(new Error("CognitoUtil: callback in getAccessToken is null...returning"));
        }
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    console.log("CognitoUtil: Can't set the credentials:" + err);
                    callback.callbackWithParam(null);
                } else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getAccessToken().getJwtToken());
                    }
                }
            });
        } else {
          callback.callbackWithParam(null);
        }
    }

    getIdToken(callback: Callback): void {
        if (callback == null) {
            throw(new Error("CognitoUtil: callback in getIdToken is null...returning"));
        }
        if (this.getCurrentUser() != null) {
          this.getCurrentUser().getSession(function (err, session) {
            if (err) {
              console.log("CognitoUtil: Can't set the credentials:" + err);
              callback.callbackWithParam(null);
            } else {
              if (session.isValid()) {
                callback.callbackWithParam(session.getIdToken().getJwtToken());
              } else {
                console.log("CognitoUtil: Got the ID token, but the session isn't valid");
              }
            }
          });
        } else {
          callback.callbackWithParam(null);
        }
    }

    getRefreshToken(callback: Callback): void {
        if (callback == null) {
            throw(new Error("CognitoUtil: callback in getRefreshToken is null...returning"));
        }
        if (this.getCurrentUser() != null) {
          this.getCurrentUser().getSession(function (err, session) {
            if (err) {
              console.log("CognitoUtil: Can't set the credentials:" + err);
              callback.callbackWithParam(null);
            } else {
              if (session.isValid()) {
                callback.callbackWithParam(session.getRefreshToken());
              }
            }
          });
        } else {
          callback.callbackWithParam(null);
        }
    }

    refresh(): void {
        this.getCurrentUser().getSession(function (err, session) {
            if (err) {
                console.log("CognitoUtil: Can't set the credentials:" + err);
            } else {
                if (session.isValid()) {
                    console.log("CognitoUtil: refreshed successfully");
                } else {
                    console.log("CognitoUtil: refreshed but session is still not valid");
                }
            }
        });
    }

    updateUserAttribute(name: string, value: string) {
      const cognitoUser = this.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
          } else {
            this.getCurrentUser().refreshSession( session.getRefreshToken(), (error, refreshedSession) => {
              if (error) {
                console.log("CognitoUtil: Can't refresh the refreshedSession:" + error);
              } else {
                console.log("UserLoginService: Session is " + refreshedSession.isValid());
                cognitoUser.getUserAttributes((err2, result2) => {
                  if (err2) {

                  } else {
                    const attributeList = [];
                    const attributeX = {
                      Name : name,
                      Value : value
                    };
                    const attribute = new CognitoUserAttribute(attributeX);
                    attributeList.push(attribute);

                    cognitoUser.updateAttributes(attributeList, function(err3, result) {
                      if (err3) {
                        alert(err3);
                        return;
                      }
                      console.log('Updated the Current Users Subscription Level: ' + result);
                    });
                  }
                });
              }
            });
          }
        });
      }
    }
}
