export class SimpleItem {
  id: string;
  quantity: number;
  ignored: boolean;
  confirmed: boolean;

  constructor () {
    this.id = '';
    this.quantity = 0;
  }
  setId (id: any) {
    this.id = id;
  }
  setQuantity (quantity: number) {
    this.quantity = quantity;
  }
}
