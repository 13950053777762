<div #coverPageWrapper style="margin-top: 20px; margin-left:20px; margin-right:20px;overflow-y: scroll;">
  <div #coverPage class="coverPage resource" style="margin-left: auto; margin-right: auto; margin-top: 50px; width: 1283.49px; height: 836.4px;">
    <div #coverClippingRect class="clippingRect">
      <img #image style="position: absolute; top: 21px; left: 650.58px; display: block; width: 612px; height: 792px;" src="">
    </div>
    <div #coverBounds style="position: absolute; top: 15px; left: 15px; height: 806.4px; width: 1253.49px;">
      <div class="boundsMarker" #backCoverBounds style="position: absolute; top: 0px; left: 0px; width: 597.6px; height: 777.6px; border: 14.4px solid red;"></div>
      <div class="boundsMarker" #spineCoverBounds style="position: relative; top: 0px; margin-left: auto; margin-right: auto; width: 0.6912px; height: 777.6px; border: 14.4px solid red;"></div>
      <div class="boundsMarker" #frontCoverBounds style="position: absolute; top: 0px; right: 0px; width: 597.6px; height: 777.6px; border: 14.4px solid red;"></div>
    </div>
    <div id="coverLines" style="position: absolute; top: 22.2px; left: 15px; height: 806.4px; width: 1253.49px;">
      <div class="sharpMarker" #backCoverLine style="position: absolute; top: 0px; left: 0px; width: 610px; margin-left: 7.2px; height: 790px;">
        <!--<img #eanImage src="assets/images/upc.png" style="position: absolute; background-color: white;" alt="EAN Placeholder" />-->
      </div>
      <div class="sharpMarker" #spineCoverLine style="position: relative; top: 0px; margin-left: auto; margin-right: auto; width: 13.0912px; height: 790px;"></div>
      <div class="sharpMarker" #frontCoverLine style="position: absolute; top: 0px; right: 0px; width: 610px; height: 790px; margin-right: 7.2px;"></div>
    </div>
    <button #coverPageShiftUp mat-icon-button (click)="draft.draftCover.verticalShift = draft.draftCover.verticalShift - 9"><mat-icon i18n-title="@@Shift page up" title="Shift page up" color="primary">arrow_upward</mat-icon></button>
    <button #coverPageShiftDown mat-icon-button (click)="draft.draftCover.verticalShift = draft.draftCover.verticalShift + 9"><mat-icon i18n-title="@@Shift page down" title="Shift page down" color="primary">arrow_downward</mat-icon></button>
    <button #coverPageShiftLeft mat-icon-button (click)="draft.draftCover.horizontalShift = draft.draftCover.horizontalShift - 9"><mat-icon i18n-title="@@Shift page left" title="Shift page left" color="primary">arrow_back</mat-icon></button>
    <button #coverPageShiftRight mat-icon-button (click)="draft.draftCover.horizontalShift = draft.draftCover.horizontalShift + 9"><mat-icon i18n-title="@@Shift page right" title="Shift page right" color="primary">arrow_forward</mat-icon></button>
  </div>
</div>
