import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {StartupService} from "../../../../service-ui/startup.service";
import {ProjectService} from "../../../../service-data/project.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../../pipe/language.pipe";
import {Project} from "../../../../../model/Project";

@Component({
  selector: 'app-classic-project-thumbnail',
  templateUrl: './classic-project-thumbnail.component.html',
  styleUrls: ['./classic-project-thumbnail.component.css']
})
export class ClassicProjectThumbnailComponent implements OnInit, OnChanges {
  @ViewChild('draftCoverImage', { static: true }) draftCoverImage;
  @Input() project: Project;
  @Input() projects: Project[];
  @Output() selectedItem = new EventEmitter<Project>();
  @Output() editingDetailsChange = new EventEmitter<string>();

  displayScale = 1.0;

  Math = Math;
  public renameDraft: boolean = false;

  constructor(public router: Router, public startupService: StartupService, private projectService: ProjectService, public dialog: MatDialog) {
  }

  ngOnInit() {
    // this.refreshImagePosition();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('Change ', changes);
    // this.refreshImagePosition();
  }

  viewProject(project: Project, viewType: string): void {
    this.selectedItem.emit(project);
    this.editingDetailsChange.emit(viewType);
  }

  deleteProject (project: Project): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        name: project.name,
        title: LanguagePipe.get('Delete', 'compressSpaces') + ' ' + project.name + LanguagePipe.get('?', 'compressSpaces'),
        body: '', // LanguagePipe.get('Delete', 'compressSpaces') + ' ' + draft.name + LanguagePipe.get('?', 'compressSpaces'),
        noButton: LanguagePipe.get('Cancel', 'compressSpaces'),
        yesButton: LanguagePipe.get('Delete', 'compressSpaces') + ' ' + project.name,
        returnValue: 'ok'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed. result[' + result + ']');
      if ( result && result === 'ok' ) {
        // this.draftService.deleteDraft(draft).then( proj => {
        //   console.log('Deleted a draft');
        // });
      }
    });

  }

  addToCart(project: Project) {
    // if ( project.primaryBook && project.primaryBook.product ) {
      // this.startupService.getCart().addProductItem(project.primaryBook.products, 1);
    // }
  }

}
