import {DataObject} from "./DataObject";

export class Catalog implements DataObject {

  public ID: number;
  public name: string;
  public org_id: number;
  public iconName: string;

  constructor (obj) {
    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if (obj) {
    //   for (const prop of Object.keys(obj)) {
    //     this[prop] = obj[prop];
    //   }
    // }
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }


  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
