import {Component, OnInit} from "@angular/core";
import {NavbarService} from "../service-ui/navbar.service";
import {StartupService} from "../service-ui/startup.service";
import {environment as env} from "../../environments/environment";

declare let AWS: any;
declare let AWSCognito: any;

@Component({
    selector: 'app-about',
    template: '<p>Hello and welcome!"</p>'
})
export class AboutComponent {

}

@Component({
    selector: 'app-landing',
    templateUrl: './landinghome.html',
    styleUrls: ['./home.css']

})
export class HomeLandingComponent implements OnInit {
  environment = env;

    constructor(public navbarService: NavbarService, public startupService: StartupService) {
        console.log("HomeLandingComponent constructor");
    }
  ngOnInit() {
    this.navbarService.setNavBarState('login', "");
  }
}

@Component({
    selector: 'app-home',
    templateUrl: './home.html',
    styleUrls: ['./home.css']
})
export class HomeComponent implements OnInit {

  constructor(public navbarService: NavbarService) {
        console.log("HomeComponent constructor");
  }

  ngOnInit() {
    this.navbarService.setNavBarState('login', "");
  }
}



