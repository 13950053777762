<div class="printNodeOrderItems" [style.background-color]="printNodeOrderItem.book.body.standardized !== 'COMPLETE' || printNodeOrderItem.book.cover.standardized !== 'COMPLETE' ? startupService.colorAliases['errorBackground'] : ''">
  <div class="printNodeOrderItemInfoSide">
    <div class="graphicDetails">
      <div class="graphicDetail" *ngFor="let type of types" [style.background-color]="colors[type][properties[type]]" [title]="type + ': ' + properties[type] + units">
        <mat-icon *ngIf="icons[type][properties[type]]">{{icons[type][properties[type]]}}</mat-icon>
        <!--      <br/>-->
        <!--      <span style="font-size: 8pt">{{properties[type]}}</span>-->
      </div>
    </div>
    <div class="basicInfo">
      <div class="clippedWidth" style="font-weight: bold">
        <span title="Quantity" i18n-title="@@Quantity" [style.color]="printNodeOrderItem.type==='REPRINT' ? 'RED': (printNodeOrderItem.type==='INITIAL' ? '': 'GREEN')" [style.font-size]="printNodeOrderItem.type==='REPRINT' ? 'larger': (printNodeOrderItem.type==='INITIAL' ? 'larger  ': 'x-larger')"  style="font-weight: bold">{{quantity}} - </span>
        <span title="Book Id" i18n-title="@@Book Id">{{printNodeOrderItem.book.ID}} </span>
        <span title="Title" i18n-title="@@Title">{{printNodeOrderItem.book.title}}</span>
      </div>
      <div class="details clippedWidth">
        <span *ngIf="printNodeOrderItem.printNodeOrder"  title="Organization" i18n-title="@@Organization" style="font-weight: bold">{{printNodeOrderItem.printNodeOrder.org_ID}}</span>
        <span *ngIf="printNodeOrderItem.printNodeOrder"  title="Service Provider" i18n-title="@@Service Provider" [style.color]="printNodeOrderItem.printNodeOrder.organization && printNodeOrderItem.printNodeOrder.organization.serviceProvider_ID === 4?'red':'black'">{{printNodeOrderItem.printNodeOrder.organization ? printNodeOrderItem.printNodeOrder.organization.serviceProvider_ID : 'X'}}</span>
        <span title="Pages" i18n-title="@@Pages">{{pages * quantity}}</span>
        <span title="Thickness" i18n-title="@@Thickness">{{thickness | number: '1.1-1' }}in</span>
        <span title="Weight" i18n-title="@@Weight">{{printNodeOrderItem.book.weight * quantity | number: '1.1-1' }}lbs</span>
        <span title="Created" i18n-title="@@Created">{{createdDate | date: 'yyyy-MM-dd' }}</span>
      </div>
    </div>
    <div class="basicInfo">
      <div class="clippedWidth">{{printNodeOrderItem.printnodeorder_ID}}/{{printNodeOrderItem.ID}}</div>
      <div class="clippedWidth">
        <span *ngIf="printNodeOrderItem.printNodeOrder" title="Shipping " i18n-title="@@Shipping">{{printNodeOrderItem.printNodeOrder.carrier}}_{{printNodeOrderItem.printNodeOrder.servicelevel}} </span>
        <span>{{printNodeOrderItem.book.body.width/72}}in X{{printNodeOrderItem.book.body.height/72}}in </span>
      </div>
      <div class="clippedWidth" [style.color]="isDateOld(printNodeOrderItem.printNodeOrder.created) ? 'red' : 'inherit'">{{printNodeOrderItem.printNodeOrder.created | date: 'yyyy-MM-dd' }}</div>
    </div>
    <div *ngIf="printNodeOrderItem.printNodeOrder" class="basicInfo">
      <div class="clippedWidth">
        <span title="Name" i18n-title="@@Name">{{printNodeOrderItem.printNodeOrder.firstname}} {{printNodeOrderItem.printNodeOrder.lastname}} </span>
        <span title="Address" i18n-title="@@Address">{{printNodeOrderItem.printNodeOrder.address1}}</span>
      </div>
      <div class="details clippedWidth">
        <span title="City" i18n-title="@@City">{{printNodeOrderItem.printNodeOrder.city}}, </span>
        <span title="State" i18n-title="@@State">{{printNodeOrderItem.printNodeOrder.state}} </span>
        <span title="Postal Code" i18n-title="@@Postal Code">{{printNodeOrderItem.printNodeOrder.postalcode}} </span>
        <span title="Country" i18n-title="@@Country">{{printNodeOrderItem.printNodeOrder.country}}</span>
      </div>
    </div>
<!--    <div class="basicInfo">-->
<!--      <div class="clippedWidth">-->
<!--        <span title="Name" i18n-title="@@Name">{{printNodeOrderItem.printNodeOrder.firstname}} {{printNodeOrderItem.printNodeOrder.lastname}} </span>-->
<!--        <span title="Address" i18n-title="@@Address">{{printNodeOrderItem.printNodeOrder.address1}}</span>-->
<!--      </div>-->
<!--      <div class="details clippedWidth">-->
<!--        <span title="City" i18n-title="@@City">{{printNodeOrderItem.printNodeOrder.city}}, </span>-->
<!--        <span title="State" i18n-title="@@State">{{printNodeOrderItem.printNodeOrder.state}} </span>-->
<!--        <span title="Postal Code" i18n-title="@@Postal Code">{{printNodeOrderItem.printNodeOrder.postalcode}} </span>-->
<!--        <span title="Country" i18n-title="@@Country">{{printNodeOrderItem.printNodeOrder.country}}</span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div class="printNodeOrderItemActionSide">
    <div class="manufacturingTask">
      <ng-container *ngIf="manufacturingSet['BodyPrintManufacturingTask'] && manufacturingSet['BodyPrintManufacturingTask'].inputStatus === 'COMPLETE'">
        <button mat-icon-button i18n-title="@@Print Body" title="Print Body" class="floatingButton" (click)="reprint('BodyPrint')" i18n-style="@@floatEnd" style="float:right" color="warn"><mat-icon>library_books</mat-icon></button>
      </ng-container>
      <ng-container *ngIf="manufacturingSet['CoverPrintManufacturingTask'] && manufacturingSet['CoverPrintManufacturingTask'].inputStatus === 'COMPLETE'">
        <button mat-icon-button i18n-title="@@Print Cover" title="Print Cover" class="floatingButton" (click)="reprint('CoverPrint')" i18n-style="@@floatEnd" style="float:right" color="warn"><mat-icon>menu_book</mat-icon></button>
      </ng-container>
    </div>
  </div>
</div>
