import {Draft} from "./Draft";

export class DraftBody {
  ID: number;
  resource_ID: number;
  leftVerticalShift: number = 0;
  leftHorizontalShift: number = 0;
  rightVerticalShift: number = 0;
  rightHorizontalShift: number = 0;
  documentScale: number = 1;
  numPages: number = 0;
  height: number = 648;
  width: number = 432;
  spine: number = 0;
  marginTop: number = 32;
  marginBottom: number = 32;
  marginOut: number = 32;
  marginIn: number = 32;
  paperType_ID: number = 1;
  correctBlackText: number = 0;
  useICC: string = 'FALSE';
  lastUpdate: Date = new Date();

  constructor( obj ) {
    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        if ( obj[prop]) {
          this[prop] = obj[prop];
        }
      }
    }
  }

}
