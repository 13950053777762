import {Component, Input, OnInit} from '@angular/core';
import {Recurring} from "../../../../model/cart/Recurring";
import {Subscription} from "../../../../model/Subscription";
import {Router} from "@angular/router";
import {StartupService} from "../../../service-ui/startup.service";
import {NavbarService} from "../../../service-ui/navbar.service";
import {SubscriptionService} from "../../../service-data/subscription.service";
import {LoggedInCallback} from "../../../service-auth/cognito.service";
import { FormsModule } from '@angular/forms';
import {Project} from "../../../../model/editor/Project";
import {EcommerceConnection} from "../../../../model/EcommerceConnection";

@Component({
  selector: 'app-ecommerce-connection',
  templateUrl: './ecommerce-connection.component.html',
  styleUrls: ['./ecommerce-connection.component.css']
})
export class EcommerceConnectionComponent implements OnInit, LoggedInCallback {
  @Input() ecommerceConnection: EcommerceConnection;

  constructor(public router: Router, public startupService: StartupService, private navbarService: NavbarService, public subscriptionService: SubscriptionService) {
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Account");
      // this.router.navigate(['/home/login']);
    } else {
    }
  }

}
