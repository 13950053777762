import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {ProductService} from "../../service-data/product.service";
import {Product} from "../../../model/Product";
import {FacilityInvoiceService} from "../../service-data/facility-invoice.service";
import {FacilityInvoice} from "../../../model/FacilityInvoice";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-facility-invoices',
  templateUrl: './facility-invoices.component.html',
  styleUrls: ['./facility-invoices.component.css']
})

export class FacilityInvoicesComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [
    { columnDef: 'ID',               header: 'ID',               sort: true,  type: 'number',     filterType: 'String',          style: 'text-align: right',  property: 'ID',             cell: (element: any) => `${element.ID}`  },
    { columnDef: 'organizationId',   header: 'Org ID',           sort: true,  type: 'number',     filterType: 'String',          style: 'text-align: left',   property: 'org_ID',         cell: (element: any) => `${ element.org_ID}`  },
    { columnDef: 'periodStart',      header: 'Period Start',     sort: true,  type: 'date',       filterType: 'DateRange',       style: 'text-align: center', property: 'periodStart',    cell: (element: any) => `${element.periodStart }`, dateFormat: 'MM/dd/yy' },
    { columnDef: 'periodEnd',        header: 'Period End',       sort: true,  type: 'date',       filterType: 'DateRange',       style: 'text-align: center', property: 'periodEnd',      cell: (element: any) => `${element.periodEnd }`,   dateFormat: 'MM/dd/yy' },
    { columnDef: 'submittedDate',    header: 'Submitted',        sort: true,  type: 'date',       filterType: 'DateRange',       style: 'text-align: center', property: 'submittedDate',  cell: (element: any) => `${element.submittedDate }`,   dateFormat: 'MM/dd/yy' },
    { columnDef: 'dueDate',          header: 'Due',              sort: true,  type: 'date',       filterType: 'DateRange',       style: 'text-align: center', property: 'dueDate',        cell: (element: any) => `${element.dueDate }`,   dateFormat: 'MM/dd/yy' },
    { columnDef: 'status',           header: 'Status',           sort: true,  type: 'string',     filterType: 'String',          style: 'text-align: left',   property: 'status',         cell: (element: any) => `${element.status }`},
    { columnDef: 'amount',           header: 'Amount',           sort: true,  type: 'currency',   filterType: 'NumericRange',    style: 'text-align: right',  property: 'amount',         cell: (element: any) => `${element.amount}`, displayClass: 'USCurrency' },
    { columnDef: 'contestStatus',    header: 'Contest Status',   sort: true,  type: 'string',     filterType: 'String',          style: 'text-align: left',   property: 'contestStatus',  cell: (element: any) => `${element.contestStatus }`},
    { columnDef: 'noteToCustomer',   header: 'Note',             sort: true,  type: 'string',     filterType: 'String',          style: 'text-align: left',   property: 'noteToCustomer', cell: (element: any) => `${element.noteToCustomer ? element.noteToCustomer : '' }`},
    { columnDef: 'downloadInvoice',  header: 'Download',         sort: false, type: 'iconButton', filterType: 'Empty',           style: 'text-align: right',  property: 'ID',             cell: (element: any) => element,  buttonColor: 'warn', icon: 'download',  clickAction: 'download'},

  ];

  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<FacilityInvoice>();
  public renderedData: FacilityInvoice[];
  public products: Product[];
  tableFilterManager: TableFilterManager<FacilityInvoice> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  role: string;
  // shippedFilter: string = 'NONE';
  // holdFilter: string = 'FLAGGED';
  // duplicateFilter: string = 'NONE';
  // minDate: Date;
  // maxDate: Date;


  constructor(public router: Router, public startupService: StartupService, public facilityInvoiceService: FacilityInvoiceService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public productService: ProductService) {
    this.startupService.addRoleChangeListener(this);
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      this.renderedData = this.dataSource.data;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: FacilityInvoice, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  match(o: FacilityInvoice ): boolean {
    if ( o ) {
      return true;
    }
    return false;
  }

  addUpdate(o: FacilityInvoice) {
    if ( this.match(o)) {
      if ( this.dataSource.data.findIndex(item => item.getId() === o.getId()) === -1) {
        this.dataSource.data.push(o);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      } else {
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
    } else {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
    }
  }

  getData(): void {
    this.startupService.touchLastUserActivity();
    this.facilityInvoiceService.getAddNotifier(true).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.facilityInvoiceService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.facilityInvoiceService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
    });
    this.facilityInvoiceService.objects.forEach( o => {
      this.addUpdate(o);
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public download(facilityInvoice: FacilityInvoice) {
    console.log(`Download FacilityInvoice: ${facilityInvoice.ID}`);
    this.facilityInvoiceService.getInvoice(facilityInvoice, 'en', 'detailed').subscribe( data => {
      const periodStart = new Date(facilityInvoice.periodStart);
      const periodEnd = new Date(facilityInvoice.periodEnd);
      const downloadFileName = facilityInvoice.invoiceNumber + '_' + facilityInvoice.printNode_ID + '_' + periodStart.getFullYear() + '_' + (periodStart.getMonth() + 1) + '_' + periodStart.getDate() + '_' + periodEnd.getFullYear() + '_' + (periodEnd.getMonth() + 1) + '_' + periodEnd.getDate() + '.html';
      const blob = new Blob([data.toString()], {type: 'text/html'});
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = downloadFileName;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/html', a.download, a.href].join(':');
      e.initEvent('click', true, false);
      a.dispatchEvent(e);
    });
  }

  public event(action: string, data: any, event) {
    switch ( action ) {
      case 'download': {
        this.download(new FacilityInvoice(data));
      }
    }
  }

  refresh () {
    this.facilityInvoiceService.reset();
    this.getData();
  }

  // dateChangeEvent(event: MatDatepickerInputEvent<Date>) {
  //   console.log(`dateChangeEvent: ${event.value}`);
  //   this.minDate = event.value;
  //   this.getData();
  // }
  //
  // toggleShippingFilter() {
  //   switch ( this.shippedFilter ) {
  //     case 'SHIPPED': {
  //       this.shippedFilter = 'NONE';
  //       break;
  //     }
  //     case 'NONE': {
  //       this.shippedFilter = 'ALL';
  //       break;
  //     }
  //     case 'ALL': {
  //       this.shippedFilter = 'SHIPPED';
  //       break;
  //     }
  //   }
  // }
  //
  // toggleHoldsFilter() {
  //   switch ( this.holdFilter ) {
  //     case 'FLAGGED': {
  //       this.holdFilter = 'NONE';
  //       break;
  //     }
  //     case 'NONE': {
  //       this.holdFilter = 'ALL';
  //       break;
  //     }
  //     case 'ALL': {
  //       this.holdFilter = 'FLAGGED';
  //       break;
  //     }
  //   }
  // }
  // toggleDuplicatesFilter() {
  //   switch ( this.duplicateFilter ) {
  //     case 'DUPLICATE': {
  //       this.duplicateFilter = 'NONE';
  //       break;
  //     }
  //     case 'NONE': {
  //       this.duplicateFilter = 'ALL';
  //       break;
  //     }
  //     case 'ALL': {
  //       this.duplicateFilter = 'DUPLICATE';
  //       break;
  //     }
  //   }
  // }
}
