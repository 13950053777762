import {
  AfterContentInit, AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { EditorProjectService } from '../../service-data/editor/editor-project.service';
import {Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import {Chapter} from "../../../model/editor/Chapter";
import { Project } from '../../../model/editor/Project';
import {NavbarService} from "../../service-ui/navbar.service";
import {InteriorTheme} from "../../../model/editor/interior-theme/InteriorTheme";
import {environment} from "../../../environments/environment";
import {ThemeService} from "../../service-data/theme.service";
import {LanguagePipe} from "../../pipe/language.pipe";
import {AlertService} from "../../service-ui/alert.service";

declare var CKEDITOR: any;

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.css']
})
export class ChapterComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('hostElement') hostElement: any;
  @Input() project: Project;
  @Input() chapter: Chapter;
  @Input() interiorTheme: InteriorTheme;
  @Input() config: any;
  @Input() debounce: any;
  @Input() trimSizeChange;
  @Input() showAudioCertantity;
  @Input() useBookWidthInEditor;
  @Input() sizeWidth;

  initializedContent = false;
  debounceTimeout: any;
  ckeditorInstance: any;

  matchesPrototypeFunction: any;

  constructor(public router: Router, public startupService: StartupService, private alertService: AlertService, private projectService: EditorProjectService, private navbarService: NavbarService, private themeService: ThemeService, public changeDetectorRef: ChangeDetectorRef, public zone: NgZone) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.interiorTheme && changes.interiorTheme.currentValue !== undefined) {
      console.log("The project InteriorTheme changed");
      this.removeProjectCSS();
      this.loadProjectCSS();
    }
    if (changes.showAudioCertantity) {
      console.log("showAudioCertantity changed");
      if ( this.project) {
        this.loadHighlightAudioConfienceCSS(this.showAudioCertantity);
      }
    }
    if (changes.useBookWidthInEditor) {
      console.log("useBookWidthInEditor changed");
      if ( this.project) {
        if ( this.useBookWidthInEditor ) {
          this.setEditorDocumentWidthAndMargins(this.project.width, '0.875in', '0.5in');
        } else {
          this.setEditorDocumentWidthAndMargins('calc( 100% - 150px)', '0.875in', '0.5in');
        }
      }
    }
    if (changes.trimSizeChange) {
      console.log("The project trim size changed");
      if ( this.project) {
        if ( this.useBookWidthInEditor ) {
          this.setEditorDocumentWidthAndMargins(this.project.width, '0.5in', '0.5in');
        } else {
          this.setEditorDocumentWidthAndMargins('calc( 100% - 27px - 36px - 0.36px)', '0.875in', '0.5in');
        }
      }
    }
    if ( changes.chapter && changes.chapter.currentValue !== changes.chapter.previousValue ) {
      this.initializedContent = false;
      this.init();
    }
    if ( changes.sizeWidth ) {
      this.setEditorHeight();
    }

    console.log(`onChanges ${changes.toString()}`);
  }

  ngOnDestroy() {
    console.log("onDestroy");
    if ( CKEDITOR.instances) {
      Object.keys(CKEDITOR.instances).forEach(key => {
        console.log(key);
        CKEDITOR.instances[key].destroy();
      });
    }
  }

  loadFonts() {
    const ckeditorDocument = document.getElementsByTagName("iframe");
    for ( let i = 0; i < ckeditorDocument.length; i++) {
      const doc = ckeditorDocument[i];
      if ( doc.className.indexOf('cke') > -1 ) {
        const interiorDocument = doc.contentWindow.document;
        const html = interiorDocument.getElementsByTagName('html')[0];
        this.startupService.editorDocument = interiorDocument;
        html.setAttribute("class", this.chapter.type);
        const headElement = interiorDocument.getElementsByTagName('head')[0];
        console.log(headElement);
        if ( headElement ) {
          this.themeService.fonts.forEach( fontItem => {
            const fileref = interiorDocument.createElement("link");
            fileref.setAttribute("rel", "stylesheet");
            // fileref.setAttribute("type", "text/css");
            fileref.setAttribute("href", fontItem.url);
            if (typeof fileref !== "undefined") {
              headElement.appendChild(fileref);
            }
          });
        }
      }
    }
  }
  // loadjscssfileoniframe(filename, filetype) {
  //   if ( filetype === "js" ) { // if filename is a external JavaScript file
  //     const fileref = document.createElement('script');
  //     fileref.setAttribute("type", "text/javascript" );
  //     fileref.setAttribute("src", filename);
  //     if (typeof fileref !== "undefined") {
  //       document.getElementsByTagName("head")[0].appendChild(fileref);
  //     }
  //   } else if ( filetype === "css" ) { // if filename is an external CSS file
  //     const ckeditorDocument = document.getElementsByTagName("iframe");
  //     for ( let i = 0; i < ckeditorDocument.length; i++) {
  //       const doc = ckeditorDocument[i];
  //       if ( doc.className.indexOf('cke') > -1 ) {
  //         const interiorDocument = doc.contentWindow.document;
  //         const html = interiorDocument.getElementsByTagName('html')[0];
  //         this.startupService.editorDocument = interiorDocument;
  //         html.setAttribute("class", this.chapter.type);
  //         const headElement = interiorDocument.getElementsByTagName('head')[0];
  //         console.log(headElement);
  //         if ( headElement ) {
  //           const fileref = interiorDocument.createElement("link");
  //           fileref.setAttribute("rel", "stylesheet");
  //           fileref.setAttribute("type", "text/css");
  //           fileref.setAttribute("href", filename);
  //           if (typeof fileref !== "undefined") {
  //             headElement.appendChild(fileref);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }



  loadProjectCSS() {
    this.projectService.getCSS(this.project.id).then( cssText => {
      const ckeditorDocument = document.getElementsByTagName("iframe");
      for ( let i = 0; i < ckeditorDocument.length; i++) {
        const doc = ckeditorDocument[i];
        if ( doc.className.indexOf('cke') > -1 ) {
          const interiorDocument = doc.contentWindow.document;
          const html = interiorDocument.getElementsByTagName('html')[0];
          this.startupService.editorDocument = interiorDocument;
          html.setAttribute("class", this.chapter.type);
          const headElement = interiorDocument.getElementsByTagName('head')[0];
          console.log(headElement);
          if ( headElement ) {
            const css = interiorDocument.createElement('style');
            css.setAttribute("id", "ProjectCSS");
            css.textContent = cssText;
            if (typeof css !== "undefined") {
              headElement.appendChild(css);
            }
          }
        }
      }
    });
  }

  setEditorDocumentWidthAndMargins(width: string, marginLeft: string, marginRight: string) {
    const ckeditorDocument = document.getElementsByTagName("iframe");
    for ( let i = 0; i < ckeditorDocument.length; i++) {
      const doc = ckeditorDocument[i];
      if (doc.className.indexOf('cke') > -1) {
        const interiorDocument = doc.contentWindow.document;
        const body = interiorDocument.getElementsByTagName('body')[0];
        if (body) {
          body.style.marginLeft = marginLeft;
          body.style.marginRight = marginRight;
          body.style.width = width;
        }
      }
    }
  }

  loadHighlightAudioConfienceCSS(showAudioCertantity: boolean) {
    const ckeditorDocument = document.getElementsByTagName("iframe");
    for ( let i = 0; i < ckeditorDocument.length; i++) {
      const doc = ckeditorDocument[i];
      if ( doc.className.indexOf('cke') > -1 ) {
        const interiorDocument = doc.contentWindow.document;
        const html = interiorDocument.getElementsByTagName('html')[0];
        this.startupService.editorDocument = interiorDocument;
        html.setAttribute("class", this.chapter.type);
        const headElement = interiorDocument.getElementsByTagName('head')[0];
        console.log(headElement);
        if ( headElement ) {
          const css = interiorDocument.createElement('style');
          css.setAttribute("id", "HightlightAudioConfidenceCSS");
          if ( showAudioCertantity ) {
            css.textContent = "span[confidence='0.95'] {  background: rgba( 255, 0, 0, 0.05 ); }" +
              " span[confidence='0.9'] {  background: rgba( 255, 0, 0, 0.1 ); }" +
              " span[confidence='0.85'] {  background: rgba( 255, 0, 0, 0.15 ); }" +
              " span[confidence='0.8'] {  background: rgba( 255, 0, 0, 0.2 ); }" +
              " span[confidence='0.75'] {  background: rgba( 255, 0, 0, 0.25 ); }" +
              " span[confidence='0.7'] {  background: rgba( 255, 0, 0, 0.3 ); }" +
              " span[confidence='0.65'] {  background: rgba( 255, 0, 0, 0.35 ); }" +
              " span[confidence='0.6'] {  background: rgba( 255, 0, 0, 0.4 ); }" +
              " span[confidence='0.55'] {  background: rgba( 255, 0, 0, 0.45 ); }" +
              " span[confidence='0.5'] {  background: rgba( 255, 0, 0, 0.5 ); }" +
              " span[confidence='0.45'] {  background: rgba( 255, 0, 0, 0.55 ); }" +
              " span[confidence='0.4'] {  background: rgba( 255, 0, 0, 0.6 ); }" +
              " span[confidence='0.35'] {  background: rgba( 255, 0, 0, 0.65 ); }" +
              " span[confidence='0.3'] {  background: rgba( 255, 0, 0, 0.7 ); }" +
              " span[confidence='0.25'] {  background: rgba( 255, 0, 0, 0.75 ); }" +
              " span[confidence='0.2'] {  background: rgba( 255, 0, 0, 0.8 ); }" +
              " span[confidence='0.15'] {  background: rgba( 255, 0, 0, 0.85 ); }" +
              " span[confidence='0.1'] {  background: rgba( 255, 0, 0, 0.9 ); }" +
              " span[confidence='0.05'] {  background: rgba( 255, 0, 0, 0.95 ); }" +
              " span[confidence='0.0'] {  background: rgba( 255, 0, 0, 1.0 ); }";
          } else {
            css.textContent = "span[confidence='0.95'] {  background: inherit; }" +
              " span[confidence='0.9'] {  background: inherit; }" +
              " span[confidence='0.85'] {  background: inherit; }" +
              " span[confidence='0.8'] {  background: inherit; }" +
              " span[confidence='0.75'] {  background: inherit; }" +
              " span[confidence='0.7'] {  background: inherit; }" +
              " span[confidence='0.65'] {  background: inherit; }" +
              " span[confidence='0.6'] {  background: inherit; }" +
              " span[confidence='0.55'] {  background: inherit; }" +
              " span[confidence='0.5'] {  background: inherit; }" +
              " span[confidence='0.45'] {  background: inherit; }" +
              " span[confidence='0.4'] {  background: inherit; }" +
              " span[confidence='0.35'] {  background: inherit; }" +
              " span[confidence='0.3'] {  background: inherit; }" +
              " span[confidence='0.25'] {  background: inherit; }" +
              " span[confidence='0.2'] {  background: inherit; }" +
              " span[confidence='0.15'] {  background: inherit; }" +
              " span[confidence='0.1'] {  background: inherit; }" +
              " span[confidence='0.05'] {  background: inherit; }" +
              " span[confidence='0.0'] {  background: inherit; }";
          }
          if (typeof css !== "undefined") {
            headElement.appendChild(css);
          }
        }
      }
    }
  }

  removejscssfilefromiframe(filename, filetype) {
    const targetelement = ( filetype === "js") ? "script" : ( filetype === "css") ? "link" : "none"; // determine element type to create nodelist from
    const targetattr = ( filetype === "js" ) ? "src" : (filetype === "css" ) ? "href" : "none"; // determine corresponding attribute to test for
    const ckeditorDocument = document.getElementsByTagName("iframe");
    if ( ckeditorDocument.length > 0 ) {
      const doc = ckeditorDocument[0];
      const interiorDocument = doc.contentWindow.document;
      const allsuspects = interiorDocument.getElementsByTagName(targetelement);
      for (let i = allsuspects.length; i >= 0; i--) { // search backwards within nodelist for matching elements to remove
        if ( allsuspects[i] && allsuspects[i].getAttribute(targetattr) !== null && allsuspects[i].getAttribute(targetattr).indexOf(filename) !== -1 ) {
          allsuspects[i].parentNode.removeChild(allsuspects[i]); // remove element by calling parentNode.removeChild()
        }
      }
    }
  }

  removeProjectCSS() {
    const ckeditorDocument = document.getElementsByTagName("iframe");
    if ( ckeditorDocument.length > 0 ) {
      const doc = ckeditorDocument[0];
      const interiorDocument = doc.contentWindow.document;
      const allsuspects = interiorDocument.getElementsByTagName("style");
      for (let i = allsuspects.length; i >= 0; i--) { // search backwards within nodelist for matching elements to remove
        if ( allsuspects[i] && allsuspects[i].getAttribute("id") === 'ProjectCSS' ) {
          allsuspects[i].parentNode.removeChild(allsuspects[i]); // remove element by calling parentNode.removeChild()
        }
      }
    }
  }

  removejscssfile(filename, filetype) {
    const targetelement = ( filetype === "js") ? "script" : ( filetype === "css") ? "link" : "none"; // determine element type to create nodelist from
    const targetattr = ( filetype === "js" ) ? "src" : (filetype === "css" ) ? "href" : "none"; // determine corresponding attribute to test for
    const allsuspects = document.getElementsByTagName(targetelement);
    for (let i = allsuspects.length; i >= 0; i--) { // search backwards within nodelist for matching elements to remove
      if ( allsuspects[i] && allsuspects[i].getAttribute(targetattr) !== null && allsuspects[i].getAttribute(targetattr).indexOf(filename) !== -1 ) {
        allsuspects[i].parentNode.removeChild(allsuspects[i]); // remove element by calling parentNode.removeChild()
      }
    }
  }

  // ngOnChanges() {
  // // ngAfterContentInit() {
  // // ngAfterViewInit() {
  //   console.log("onChages");
  //   // if ( this.initializedContent ) {
  //   //   this.onChange();
  //   // }
  // }
  ngOnInit() {
    console.log("onInit");
  }

  init() {
    this.navbarService.setNavBarState('chapter', " " + this.project.name);
    if ( CKEDITOR.instances) {
      Object.keys(CKEDITOR.instances).forEach(key => {
        console.log(key);
        CKEDITOR.instances[key].destroy();
      });
    }
    if ( this.chapter.source !== 'Audio' || (this.chapter.source === 'Audio' && this.chapter.status === 'COMPLETED')) {
      this.loadChapterContent();
    }
  }

  loadChapterContent() {
    this.projectService.getChapter(this.project.id, this.chapter.id).then( text => {
      this.hostElement.nativeElement.value = text;
      this.initializedContent = true;
      this.initCKEditor();
    }).catch( err => {
      console.log("Retrying loading a chapter: " + this.chapter.id + " -- " + this.chapter.title);
      this.projectService.getChapter(this.project.id, this.chapter.id).then( text2 => {
        this.hostElement.nativeElement.value = text2;
        this.initializedContent = true;
        this.initCKEditor();
      }).catch( err2 => {
        console.log("Retrying loading a chapter: " + this.chapter.id + " -- " + this.chapter.title);
        this.projectService.getChapter(this.project.id, this.chapter.id).then( text3 => {
          this.hostElement.nativeElement.value = text3;
          this.initializedContent = true;
          this.initCKEditor();
        }).catch( err3 => {
          console.log("Error loading a chapter: " + this.chapter.id + " -- " + this.chapter.title);
          this.hostElement.nativeElement.value = "<p>" + LanguagePipe.get('Text did not load for chapter ID', 'compressSpaces') + " " + this.chapter.id + "</p><p>" + this.chapter.title + "</p><p>" + LanguagePipe.get('No conent will be saved so your text will not be overwritten', 'compressSpaces') + "</p>";
          this.initializedContent = false;
          this.ckeditorInstance = CKEDITOR.replace(this.hostElement.nativeElement, this.config);
          this.ckeditorInstance.on('instanceReady', (evt: any) => {
            evt.editor.setReadOnly(true);
            this.router.navigate(['/home/login']);
          });
        });
      });
    });
  }

  initCKEditor() {
    this.ckeditorInstance = CKEDITOR.replace(this.hostElement.nativeElement, this.config);
    this.ckeditorInstance.enabled = true;

    // const currentHeight = this.ckeditorInstance.getStyle('height');
    // this.ckeditorInstance.setStyle( 'height', '900px');

    // this.ckeditorInstance.resize('100%', '100%');

    this.ckeditorInstance.on('instanceReady', (evt: any) => {
      this.loadHighlightAudioConfienceCSS(this.startupService.showAudioCertantity);
      if ( this.useBookWidthInEditor ) {
        this.setEditorDocumentWidthAndMargins(this.project.width, '0.875in', '0.5in');
      } else {
        this.setEditorDocumentWidthAndMargins('calc( 100% - 150px)', '0.875in', '0.5in');
      }

      this.loadProjectCSS();
      this.loadFonts();
      this.zone.run(() => {
        try {
          let nodeList = evt.editor.document.getElementsByTag('h2');
          if ( nodeList && nodeList.count() === 0 ) {
            nodeList = evt.editor.document.getElementsByTag('p');
          }
          if ( nodeList && nodeList.count() === 0 ) {
            nodeList = evt.editor.document.getElementsByTag('h2');
          }
          if ( nodeList && nodeList.count() === 0 ) {
            nodeList = evt.editor.document.getElementsByTag('div');
          }
          if ( nodeList && nodeList.count() === 0 ) {
            nodeList = evt.editor.document.getElementsByTag('h3');
          }
          if ( nodeList && nodeList.count() === 0 ) {
            nodeList = evt.editor.document.getElementsByTag('h4');
          }
          if ( nodeList && nodeList.count() > 0 ) {
            const array = [];
            array.push(nodeList.getItem(0));
            this.startupService.selectedElements = array;
          }
        } catch ( err ) {

        }
      });
    });


    // CKEditor change event
    this.ckeditorInstance.on('change', () => {
      console.log("CKEditor change event");

      const value = this.ckeditorInstance.getData();

      // Debounce update
      if (this.debounce) {
        if (this.debounceTimeout) {
          clearTimeout(this.debounceTimeout);
        }
        this.debounceTimeout = setTimeout(() => {
          this.saveChapterContent();
          this.debounceTimeout = null;
        }, Number(this.debounce));
      } else {
        console.log(`CKEditor change event -- saving chapter content ${this.project.id} - ${this.chapter.id} -- ${this.hostElement.nativeElement.value}`);
        this.saveChapterContent();
        // this.projectService.addOrUpdateChapter(this.project, this.chapter, this.hostElement.nativeElement.value);
      }
    });

    this.ckeditorInstance.on('paste', (evt: any) => {
      console.log("CKEditor paste event");
      if ( evt.data.dataValue.indexOf('<') !== -1 ) {
        evt.data.dataValue = this.ckeditorInstance.dataProcessor.toDataFormat(this.ckeditorInstance.dataProcessor.toHtml(evt.data.dataValue));
      }
    });

    this.ckeditorInstance.on('blur', (evt: any) => {
      console.log("CKEditor blur event");
    });

    // CKEditor focus event
    this.ckeditorInstance.on('focus', (evt: any) => {
      console.log("CKEditor focus event");
    });

    // Set the size of the editor
    this.ckeditorInstance.on( 'insertHtml', (evt: any) => {
      console.log("CKEditor insertHtml");
      this.setEditorHeight();
    });
    this.ckeditorInstance.on( 'insertElement', (evt: any) => {
      console.log("CKEditor insertElement");
      this.setEditorHeight();
    });
    this.ckeditorInstance.on( 'selectionChange', (evt: any) => {
      console.log("CKEditor selectionChange");
      this.zone.run(() => {
        this.startupService.selectedElements = evt.data.path.elements;
      });
    });
    this.ckeditorInstance.on( 'instanceReady', (evt: any) => {
      console.log("CKEditor instanceReady");
      this.setEditorHeight();
    });

    // // Add Toolbar Groups to Editor. This will also add Buttons within groups.
    // this.toolbarGroups.forEach(group => {
    //   group.initialize(this);
    // });
    // // Add Toolbar Buttons to Editor.
    // this.toolbarButtons.forEach(button => {
    //   button.initialize(this);
    // });

  }
  setEditorHeight() {
    console.log("Running setEditorHeight");
    const topElementHeightElement = document.getElementsByClassName("cke_top");
    if ( topElementHeightElement && topElementHeightElement.length > 0 ) {
      const topElementHeight = (topElementHeightElement[0] as any).offsetHeight;
      const bottomElementHeight = (document.getElementsByClassName("cke_bottom")[0] as any).offsetHeight;
      const contentsElement = document.getElementsByClassName("cke_contents")[0];
      // const wholeEditorHeight = (document.getElementsByClassName("chapterArea")[0] as any).offsetHeight;
      const wholeEditorHeight = (document.getElementsByClassName("mainArea")[0] as any).offsetHeight;
      console.log("Running setEditorHeight -- top:" + topElementHeight + " bottom:" + bottomElementHeight + " whole:" + wholeEditorHeight);
      (contentsElement as any).style.height = (wholeEditorHeight - topElementHeight - bottomElementHeight - 3 ) + 'px';
    }
  }
  saveChapterContent() {
    if ( this.initializedContent ) {
      const chapterContent = this.ckeditorInstance.getData();
      this.projectService.addOrUpdateChapter(this.project, this.chapter, chapterContent).then( _ => {
        this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
        // this.projectService.saveProjectImmediately(this.project).then( project => {
        // });
      }).catch( err => {
        console.log(`Failed to save chapter ${this.project.id} - ${this.chapter.id} -- ${err.toString()}`);
        this.projectService.addOrUpdateChapter(this.project, this.chapter, chapterContent).then( _ => {
          this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
          // this.projectService.saveProjectImmediately(this.project).then( project => {
          // });
        }).catch( err2 => {
          console.log(`Failed to save chapter ${this.project.id} - ${this.chapter.id} -- ${err2.toString()}`);
          this.projectService.addOrUpdateChapter(this.project, this.chapter, chapterContent).then( _ => {
            this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
            // this.projectService.saveProjectImmediately(this.project).then( project => {
            // });
          }).catch( err3 => {
            console.log(`Failed to save chapter ${this.project.id} - ${this.chapter.id} -- ${err3.toString()}`);
            this.alertService.error(LanguagePipe.get("Failed to save chapter", "compressSpaces") + " " + this.project.id + " - " + this.chapter.id + " -- " + err3.toString(), true);
          });
        });
      });
    }
  }
  onReady(event: any) {
    console.log("onready " + event);
  }
  onFocus(event: any) {
    console.log("onfocus " + event);
  }

  onBlur(event: any) {
    console.log("onblur " + event);
  }

  getFileStatus(): string {
    if ( !this.chapter ) {
      return 'WAITING';
    }
    if ( this.chapter.source === '') {
      return 'READY';
    }
    if ( this.chapter.source === 'Audio' && this.chapter.status === 'IN_PROGRESS') {
      return 'IN_PROGRESS';
    }
    if ( this.chapter.source === 'Audio' && this.chapter.status === 'FAILED') {
      return 'FAILED';
    }
    if ( this.chapter.source === 'Audio' && this.chapter.status === 'COMPLETED') {
      return 'READY';
    }
    return 'READY';
  }
}

