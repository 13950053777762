<style>
  .my-drop-zone { border: dotted 3px lightgray; height: 100px;}
  .nv-file-over { border: dotted 3px red; } /* Default class applied to drop zones on over */
  .another-file-over-class { border: dotted 3px green; }

  html, body { height: 100%; }
</style>

<div class="container">

  <div class="row">

    <div class="col-md-9">

      <h3>Select files</h3>

      <div style="min-height: 100px;" appNg2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" [uploader]="uploader" class="well my-drop-zone">

        <span style="font-size:1.2em" i18n="@@Drop Image Files Here">Drop Image Files Here</span><br>
        <span style="font-size:0.85em" i18n="@@Accepted file types: png, jpg, jpeg">Accepted file types: png, jpg, jpeg</span><br>
<!--        <span style="font-size:0.7em" i18n="@@xxx">xxx</span><br><br>-->
        <label for="audioFileToTranscribe">
          <span i18n="@@Or browse files">Or browse files</span>
          <input id="audioFileToTranscribe" type="file"  style="display:none" appNg2FileSelect [uploader]="uploader" multiple accept="image/png image/jpeg">
        </label>
      </div>





      <!--<div appNg2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" [uploader]="uploader" class="well my-drop-zone" i81n="@@Drop Image Files Here">Drop Image Files Here</div>-->
      <!--<br/>-->
      <!--<p>OR</p>-->
      <!--<input type="file" appNg2FileSelect [uploader]="uploader" multiple accept="application/pdf" />-->
      <!--<br/>-->
      <!--<br/>-->
    <!--</div>-->

    <div class="col-md-9" style="margin-bottom: 40px">

      <h3>Files to upload</h3>
      <!--<p>Queue length: {{ uploader?.queue?.length }}</p>-->

      <table class="table">
        <thead>
        <tr>
          <th width="50%">Name</th>
          <th>Size</th>
          <th>Progress</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of uploader.queue">
          <td><strong>{{ item?.file?.name }}</strong></td>
          <!--<td *ngIf="uploader.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
          <td *ngIf="uploader.isHTML5"> -->
          <td nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
          <td>
            <div class="progress" style="margin-bottom: 0;">
              <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
            </div>
          </td>
          <td class="text-center">
            <span *ngIf="item.isSuccess"><i class="fa fa-check"></i><!--<i class="glyphicon glyphicon-ok"></i>--></span>
            <span *ngIf="item.isCancel"><i class="fa fa-times"></i><!--<i class="glyphicon glyphicon-ban-circle"></i>--></span>
            <span *ngIf="item.isError"><i class="fa fa-trash"></i><!--<i class="glyphicon glyphicon-remove"></i>--></span>
          </td>
          <td nowrap>
            <!--<button type="button" class="btn btn-success btn-xs" (click)="item.upload()" [disabled]="!item.isReady || item.isUploading || item.isSuccess">-->
            <!--<span class="glyphicon glyphicon-upload"></span> Upload-->
            <!--</button>-->
            <button type="button" class="btn btn-warning btn-xs" (click)="item.cancel()" [disabled]="!item.isUploading">
              <span class="glyphicon glyphicon-ban-circle"></span> Cancel
            </button>
            <button type="button" class="btn btn-danger btn-xs" (click)="item.remove()">
              <span class="glyphicon glyphicon-trash"></span> Remove
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <div  class="col-md-9">
        <div>Upload progress:
          <div class="progress">
            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
          </div>
        </div>
        <button type="button" class="btn btn-success btn-s"
                (click)="uploader.uploadAll();" [disabled]="!uploader.getNotUploadedItems().length">
          <span class="glyphicon glyphicon-upload"></span> Upload all
        </button>
        <button type="button" class="btn btn-warning btn-s"
                (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
          <span class="glyphicon glyphicon-ban-circle"></span> Cancel all
        </button>
        <button type="button" class="btn btn-danger btn-s"
                (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
          <span class="glyphicon glyphicon-trash"></span> Remove all
        </button>
      </div>

    </div>

  </div>
</div>
