<div style="width: 100%">
  <div style="padding-top: 10px; margin-left: auto; margin-right: auto;">
<!--    <mat-grid-list cols="1" >-->
<!--      <mat-grid-tile i18n-class="@@dir">-->
<!--        <mat-card style="background: rgba(59, 56, 87, 0.94); height: 100%; width: 100%;">-->
<!--          <mat-card-content>-->
<!--            <div style="padding: 30px;">-->
<!--              <h3 style="padding-top: 150px;" i18n="@@WELCOME BACK!">WELCOME BACK!</h3>-->
<!--              <p i18n="@@....">..................................................................................</p>-->
<!--              <h2 style="padding-top: 40px" i18n="@@Main Organization Name">Main Organization Name</h2>-->
<!--              <p i18n="@@START YOUR PROJECT TODAY">START YOUR PROJECT TODAY</p>-->
<!--            </div>-->
<!--          </mat-card-content>-->
<!--        </mat-card>-->
<!--      </mat-grid-tile>-->
<!--      <mat-grid-tile i18n-class="@@dir">-->
        <mat-card appearance="outlined" class="formCard">
          <h1 i18n="@@Create Account">Create Account</h1>
          <img src="/logo.png" class="intro-logo">
          <mat-card-content>
            <form style="padding-top: 0px; padding-bottom: 0px;">
              <mat-form-field class="full-width">
                <input matInput i18n-placeholder="@@Name" placeholder="Name" id="name" required type="text" maxlength="100" [(ngModel)]="registrationUser.name" [ngModelOptions]="{standalone: true}">
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput i18n-placeholder="@@Email" placeholder="Email" id="signupEmail" required type="email" maxlength="100" [(ngModel)]="registrationUser.email" [ngModelOptions]="{standalone: true}">
              </mat-form-field>

              <mat-form-field class="full-width">
<!--                &lt;!&ndash;                       (countryChange)="onCountryChange($event)"&ndash;&gt;-->
<!--&lt;!&ndash;                <input matInput i18n-placeholder="@@Phone" placeholder="Phone" ID="phone" required type="phone_number" maxlength="100" [(ngModel)]="registrationUser.phone_number" [ngModelOptions]="{standalone: true}">&ndash;&gt;-->
                <input matInput i18n-placeholder="@@Phone" placeholder="Phone" id="phone" required type="text">
                <!--                       ng2TelInput-->
<!--                       [ng2TelInputOptions]="{initialCountry: 'us'}"-->
<!--                       (hasError)="hasError($event)"-->
<!--                       (ng2TelOutput)="telOutput($event)"-->
<!--                       (intlTelInputObject)="intlTelInputObject($event)"-->
<!--                       (countryChange)="onCountryChange($event)"-->
<!--                />-->
                <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput i18n-placeholder="@@Password" placeholder="Password" id="signupPassword" required type="password" maxlength="25" length="40" [(ngModel)]="registrationUser.password" [ngModelOptions]="{standalone: true}">
              </mat-form-field>
              <span style="font-size: 0.8em; text-align: center; display: block" i18n="@@Password must contain an UPPER CASE and lower case letter and a number.">Password must contain an UPPER CASE and lower case letter and a number.</span>
              <span>{{ errorMessage }}</span>
            </form>
          </mat-card-content>
          <mat-card-actions class="primaryAction">
            <button mat-raised-button color="primary" (click)="onRegister()" id="signupSubmit" type="submit" class="btn btn-info btn-block" i18n="@@Create your account">Create your account</button>
          </mat-card-actions>
          <mat-card-actions class="secondaryAction">
            <p>Already have an account? <button mat-button color="primary" [routerLink]="['/home/login']"><ng-container i18n="@@Login">Login</ng-container></button></p>
          </mat-card-actions>
        </mat-card>
<!--      </mat-grid-tile>-->
<!--    </mat-grid-list>-->
  </div>
</div>
