import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {S3Service} from "../service-auth/s3.service";
import {NotificationsService} from "./notifications.service";
import {AlertService} from "../service-ui/alert.service";
import {LanguagePipe} from "../pipe/language.pipe";
import {FileItem} from "../file-upload/file-item.class";
import {Image} from "../../model/Image";


@Injectable()
export class ImageService {
  private images = {};
  private imagesObservable = {};
  private imagesLoadingState = {};
  private useUserIds = {};
  private librariesPath = {};


  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService, private s3Service: S3Service, private notificationsService: NotificationsService, private alertService: AlertService) {
    this.librariesPath['backgrounds'] = 'images/backgrounds';
    this.librariesPath['transparent'] = 'images/transparent';
    this.librariesPath['userbackgrounds'] = 'images/backgrounds';
    this.librariesPath['usertransparent'] = 'images/transparent';
    this.librariesPath['userlogo'] = 'images/logo';
    this.useUserIds['backgrounds'] = false;
    this.useUserIds['transparent'] = false;
    this.useUserIds['userbackgrounds'] = true;
    this.useUserIds['usertransparent'] = true;
    this.useUserIds['userlogo'] = true;

    const libraries = ['backgrounds', 'transparent', 'userbackgrounds', 'usertransparent', 'userlogo'];

    for ( const libraryName of libraries ) {
      if ( !this.images[ libraryName ] ) {
        this.images[ libraryName ] = [];
      }
      if ( !this.imagesObservable[ libraryName ] ) {
        this.imagesObservable[ libraryName ] = of(this.images[ libraryName ]);
      }
      if ( !this.imagesLoadingState[ libraryName ]) {
        this.imagesLoadingState[ libraryName ] = 'INITIAL';
      }
    }
  }

  resetImages(libraryName: string) {
    this.imagesLoadingState[ libraryName ] = 'RESETTING';
    while (this.images[ libraryName ].length > 0) {
      const image = this.images[ libraryName ].pop();
    }
    this.imagesLoadingState[ libraryName ] = 'INITIAL';
  }

  getImages(libraryName: string): Observable<Image[]> {
    if ( this.imagesLoadingState[ libraryName ] === 'INITIAL') {
      this.imagesLoadingState[ libraryName ] = 'STARTING';
      this.fetchImages(libraryName).subscribe();
    }
    return this.imagesObservable[ libraryName ];
  }


  fetchImages(libraryName: string): Observable<Image[]> {
    this.resetImages(libraryName);
    this.imagesLoadingState[ libraryName ] = 'FETCHING';
    this.listImages(libraryName).then( imgs => {
      // imgs.map( image => this.images[ libraryName ].push(image));
      this.imagesLoadingState[ libraryName ] = 'LOADED';
    });
    return this.imagesObservable[ libraryName ];
  }


  // public async uploadFile(libraryName: string, item: FileItem, extension: string): Promise<any> {
  //   const file = item._file;
  //
  //
  // }

  public async addImage(libraryName: string, imageName: string, file: File): Promise<Image> {

    let imageKey = new ImageKey(this.startupService.getUserId() + "/" + this.librariesPath[ libraryName ] + '/' +  imageName);

    if ( this.useUserIds[ libraryName ] ) {
      imageKey = new ImageKey( this.librariesPath[ libraryName ] + '/' +  imageName);
    }

    let extension = 'png';
    if ( file.name.lastIndexOf('.') > 0 ) {
      extension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
    }
    let contentType = 'image/png';

    switch ( extension ) {
      case 'png': {
        contentType = 'image/png';
        break;
      }
      case 'jpg': {
        contentType = 'image/jpeg';
        break;
      }
      case 'jpeg': {
        contentType = 'image/jpeg';
        break;
      }
      case 'gif': {
        contentType = 'image/gif';
        break;
      }
      case 'tif': {
        contentType = 'image/tiff';
        break;
      }
      case 'tiff': {
        contentType = 'image/tiff';
        break;
      }
      case 'svg': {
        contentType = 'image/svg+xml';
        break;
      }
    }
    return this.s3Service.getS3().putObject({Bucket: environment.imageBucket, Key: imageKey.toString(), ContentType: contentType, Body: file}).promise();
  }

  retreiveLocalImage(libraryName: string, id: string): Image {
    for (const image of this.images[ libraryName ]) {
      if (image.id === id) {
        return image;
      }
    }
    return null;
  }

  public listImages(libraryName: string): Promise<Image[]> {
    this.imagesLoadingState[ libraryName ] = 'LOADING';
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    let imageKey = this.librariesPath[ libraryName ] + '/';

    if ( this.useUserIds[ libraryName ] ) {
      imageKey = this.startupService.getUserId() + "/" + this.librariesPath[ libraryName ] + '/';
    }


    const params = {
      Bucket: environment.imageBucket,
      Prefix: imageKey
    };

    return new Promise((resolve, reject) => {
      console.log("About to call S3.listObjects in listProjects()");
      try {
        this.s3Service.getS3().listObjectsV2(params, (err, data) => {
          console.log(`Finished calling S3.listObjects in listProjects()=>(err:${JSON.stringify(err)}\ndata:${JSON.stringify(data)})`);
          if (err) {
            reject(err);
            return;
          }

          data.Contents.forEach(c => {
            if (ImageKey.isValid(c.Key)) {
              console.log("In listProjects valid key for a project: " + c.Key);
              this.images[ libraryName ].push( new Image( { key: environment.imageBucket + ':' + c.Key }));
            }
          });

          resolve(this.images[ libraryName ]);
        });

      } catch (e) {
        reject(e);
      }
    });
  }

  public deleteImage(libraryName: string, image: Image): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: environment.imageBucket,
        Key: new ImageKey(this.startupService.getUserId() + '/' + image.key).toString()
      };
      console.log(`Deleting params[${JSON.stringify(params)}]`);
      this.s3Service.getS3().deleteObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const index = this.images[ libraryName ].indexOf(image);
          if ( index > -1 ) {
            this.images[ libraryName ].splice(index, 1);
          }
          resolve(true);
        }
      });
    });
  }


  // public async uploadFile(libraryName: string, item: FileItem, extension: string): Promise<any> {
  //   const file = item._file;
  //
  //   return this.s3Service.getS3().putObject({Bucket: environment.imageBucket, Key: userName + '/' + this.startupService.project.ID + '/' + chapter.ID + '.' + extension + 'transcribeSource', ContentType: contentType, Body: file}).promise();
  // }
  //
  // public async uploadFileStream(libraryName: string, readStream: Stream, chapterId: string, extension: string): Promise<any> {
  //   const userName = this.startupService.getUserId();
  //   if ( userName === 'no-current-user' ) {
  //     this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
  //     this.startupService.logout();
  //   }
  //
  //   return this.s3Service.getS3().upload({Bucket: environment.imageBucket, Key: userName + '/' + this.startupService.project.ID + '/' + chapterId + '.' + extension, Body: readStream}).promise();
  // }
  //
  // public getUploadPresignedUrl(libraryName: string, chapterId: string, extension: string): string {
  //   const userName = this.startupService.getUserId();
  //   if ( userName === 'no-current-user' ) {
  //     this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
  //     this.startupService.logout();
  //   }
  //
  //   return this.s3Service.getS3().getSignedUrl('getObject', {Bucket: environment.imageBucket, Key: userName + '/' + this.startupService.project.ID + '/' + chapterId + '.' + extension, Expires: 6000000});
  // }


  ///// ADD UPLOAD IMAGES ///////
  ///// ADD UPLOAD IMAGES ///////
  ///// ADD UPLOAD IMAGES ///////
  ///// ADD UPLOAD IMAGES ///////
  public newlyAddedImage(libraryName: string, fileName: string, extension: string) {
    const key = '' + this.startupService.getUserId() + '/images/' + libraryName + '/' + fileName.replace(/\s+/g, '-') + '.' + extension
    this.images[ 'user' + libraryName ].push( new Image( { key: environment.imageBucket + ':' + key }));
  }
  public async uploadFile(libraryName: string, item: FileItem, fileName: string, extension: string): Promise<any> {
    const file = item._file;
    let contentType = 'image/png';
    switch ( extension.toLowerCase() ) {
      case 'png': {
        contentType = 'image/png';
        break;
      }
      case 'gif': {
        contentType = 'image/gif';
        break;
      }
      case 'jpg': {
        contentType = 'image/jpeg';
        break;
      }
      case 'jpeg': {
        contentType = 'image/jpeg';
        break;
      }
    }

    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    return this.s3Service.getS3().putObject({Bucket: environment.imageBucket, Key: 'original/' + userName + '/images/' + libraryName + '/' + fileName.replace(/\s+/g, '-') + '.' + extension, ContentType: contentType, Body: file}).promise();
  }

  // public async uploadFileStream(readStream: Stream, chapterId: string, extension: string): Promise<any> {
  //   const userName = this.startupService.getUserId();
  //   if ( userName === 'no-current-user' ) {
  //     this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
  //     this.startupService.logout();
  //   }
  //
  //   return this.s3Service.getS3().upload({Bucket: environment.dataBucket, Key: userName + '/' + this.startupService.project.ID + '/' + chapterId + '.' + extension, Body: readStream}).promise();
  // }

  // // Get the duration in minutes of a file that may be uploaded
  // public setUploadFileItemDuration(fileItem: FileItem) {
  //   const video = document.createElement('video');
  //   video.preload = 'metadata';
  //
  //   const _win = this.windowRef.nativeWindow;
  //   video.onloadedmetadata = function() {
  //     _win.URL.revokeObjectURL(video.src);
  //     fileItem.duration = Math.ceil(video.duration / 60.0);
  //     fileItem.charge = Math.ceil(video.duration / 60.0) * 1.2;
  //     fileItem.chargeStatus = 'INITIAL';
  //     fileItem.chapterId = Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16);
  //
  //
  //     console.log('Audio or Video of length: ' + fileItem.duration);
  //   };
  //
  //   video.src = URL.createObjectURL(fileItem._file);
  // }
  public getUploadPresignedUrl(libraryName: string, fileName: string, extension: string): string {
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    return this.s3Service.getS3().getSignedUrl('getObject', {Bucket: environment.dataBucket, Key: 'original/' + userName + '/images/' + libraryName + '/' + fileName.replace(/\s+/g, '-') + '.' + extension, Expires: 6000000});
  }

  ///// END ADD UPLOAD IMAGES ///
  ///// END ADD UPLOAD IMAGES ///
  ///// END ADD UPLOAD IMAGES ///
  ///// END ADD UPLOAD IMAGES ///

  getFreshImageUrlFromImageKey(imageKey: string, thumbnail: boolean): string {
    const image = this.createImage(imageKey);
    return this.getFreshImageUrl(image, thumbnail);
  }

  getFreshImageUrl(image: Image, thumbnail: boolean): string {
    if ( image && image.key ) {
      if ( !image.thumbnailUrl || !image.url || Date.now() - image.timestamp > 500000 ) { // Can be much longer
        const parts = image.key.split(":");
        if ( parts.length === 2) {
          const bucket = parts[0];
          const key = parts[1];
          image.url = this.s3Service.getS3().getSignedUrl('getObject', {Bucket: bucket, Key: '' + key, Expires: 6000000});
          // image.thumbnailUrl = this.s3Service.getS3().getSignedUrl('getObject', {Bucket: bucket, Key: 'thumbnail/' + key, Expires: 6000000});
          image.thumbnailUrl = this.s3Service.getS3().getSignedUrl('getObject', {Bucket: bucket, Key: '' + key, Expires: 6000000});
          image.timestamp = Date.now();
          if ( thumbnail ) {
            return image.thumbnailUrl;
          } else {
            return image.url;
          }

        }
      } else {
        if ( thumbnail ) {
          return image.thumbnailUrl;
        } else {
          return image.url;
        }
      }
    }
    return '';
  }

  getImage(key: string): Image {
    for ( const imageLibraryName of Object.keys(this.images) ) {
      for ( const image of this.images[imageLibraryName]) {
        if ( image.key === key ) {
          return image;
        }
      }
    }
    return undefined;
  }
  createImage(key: string): Image {
    for ( const imageLibraryName of Object.keys(this.images) ) {
      for ( const image of this.images[imageLibraryName]) {
        if ( image.key === key ) {
          return image;
        }
      }
    }
    const image = new Image( undefined );
    image.key = key;
    return image;
  }



}

export class ImageKey {
  private static regexp = new RegExp(/(images\/[0-9A-Za-z\.]+\/[0-9A-Za-z\.]+(-[0-9A-Za-z\.]+)+)|(images\/[0-9A-Za-z\.]+(-[0-9A-Za-z\.]+)+\/images\/[0-9A-Za-z\.]+\/[0-9A-Za-z\.]+(-[0-9A-Za-z\.]+)+)/);
  private key: string;

  public static isValid(input: string) {
    return this.regexp.test(input);
  }
  public constructor(key: string) {
    this.key = key;
  }

  public toString(): string {
    return `${this.key}`;
  }
}
