<div class="colorPalette">

  <div>
    <div style="padding-bottom: 10px;">
      <div>
        <div class="colorSwatch" [class.selected]="colorPalette.primary.normal === selectedColorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.primary, colorPalette.primary.normal)"          [style.background-color]="colorPalette.primary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.primary.normal.color" (change)="setHexColorValue(colorPalette.primary, colorPalette.primary.normal, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button ></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.primary.dark === selectedColorPaletteItem"   (click)="onColorPaletteItemSelect(colorPalette.primary, colorPalette.primary.dark)"            [style.background-color]="colorPalette.primary.dark.color"  ><span i18n="@@Primary Dark">Primary Dark</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.primary.dark.color" (change)="setHexColorValue(colorPalette.primary, colorPalette.primary.dark, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button ></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.primary.light === selectedColorPaletteItem"  (click)="onColorPaletteItemSelect(colorPalette.primary, colorPalette.primary.light)"           [style.background-color]="colorPalette.primary.light.color" ><span i18n="@@Primary Light">Primary Light</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.primary.light.color" (change)="setHexColorValue(colorPalette.primary, colorPalette.primary.light, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
      </div>
      <div style="margin-top: -8px">
        <div class="colorSwatch" [class.selected]="colorPalette.secondary.normal === selectedColorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.secondary, colorPalette.secondary.normal)"    [style.background-color]="colorPalette.secondary.normal.color"><span i18n="@@Secondary Normal">Secondary Normal</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.secondary.normal.color" (change)="setHexColorValue(colorPalette.secondary, colorPalette.secondary.normal, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.secondary.dark === selectedColorPaletteItem"   (click)="onColorPaletteItemSelect(colorPalette.secondary, colorPalette.secondary.dark)"      [style.background-color]="colorPalette.secondary.dark.color"  ><span i18n="@@Secondary Dark">Secondary Dark</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.secondary.dark.color" (change)="setHexColorValue(colorPalette.secondary, colorPalette.secondary.dark, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.secondary.light === selectedColorPaletteItem"  (click)="onColorPaletteItemSelect(colorPalette.secondary, colorPalette.secondary.light)"     [style.background-color]="colorPalette.secondary.light.color" ><span i18n="@@Secondary Light">Secondary Light</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.secondary.light.color" (change)="setHexColorValue(colorPalette.secondary, colorPalette.secondary.light, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
      </div>
      <div style="margin-top: -8px">
        <div class="colorSwatch" [class.selected]="colorPalette.tertiary.normal === selectedColorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.tertiary, colorPalette.tertiary.normal)"       [style.background-color]="colorPalette.tertiary.normal.color"><span i18n="@@Tertiary Normal">Tertiary Normal</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.tertiary.normal.color" (change)="setHexColorValue(colorPalette.tertiary, colorPalette.tertiary.normal, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.tertiary.dark === selectedColorPaletteItem"   (click)="onColorPaletteItemSelect(colorPalette.tertiary, colorPalette.tertiary.dark)"         [style.background-color]="colorPalette.tertiary.dark.color"  ><span i18n="@@Tertiary Dark">Tertiary Dark</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.tertiary.dark.color" (change)="setHexColorValue(colorPalette.tertiary, colorPalette.tertiary.dark, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.tertiary.light === selectedColorPaletteItem"  (click)="onColorPaletteItemSelect(colorPalette.tertiary, colorPalette.tertiary.light)"        [style.background-color]="colorPalette.tertiary.light.color" ><span i18n="@@Tertiary Light">Tertiary Light</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.tertiary.light.color" (change)="setHexColorValue(colorPalette.tertiary, colorPalette.tertiary.light, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
      </div>
      <div style="margin-top: -8px">
        <div class="colorSwatch" [class.selected]="colorPalette.quaternary.normal === selectedColorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.quaternary, colorPalette.quaternary.normal)" [style.background-color]="colorPalette.quaternary.normal.color"><span i18n="@@Quaternary Normal">Quaternary Normal</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.quaternary.normal.color" (change)="setHexColorValue(colorPalette.quaternary, colorPalette.quaternary.normal, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.quaternary.dark === selectedColorPaletteItem"   (click)="onColorPaletteItemSelect(colorPalette.quaternary, colorPalette.quaternary.dark)"   [style.background-color]="colorPalette.quaternary.dark.color"  ><span i18n="@@Quaternary Dark">Quaternary Dark</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.quaternary.dark.color" (change)="setHexColorValue(colorPalette.quaternary, colorPalette.quaternary.dark, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.quaternary.light === selectedColorPaletteItem"  (click)="onColorPaletteItemSelect(colorPalette.quaternary, colorPalette.quaternary.light)"  [style.background-color]="colorPalette.quaternary.light.color" ><span i18n="@@Quaternary Light">Quaternary Light</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.quaternary.light.color" (change)="setHexColorValue(colorPalette.quaternary, colorPalette.quaternary.light, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
      </div>
      <div style="margin-top: -8px">
        <div class="colorSwatch" [class.selected]="colorPalette.quinary.normal === selectedColorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.quinary, colorPalette.quinary.normal)"          [style.background-color]="colorPalette.quinary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.quinary.normal.color" (change)="setHexColorValue(colorPalette.quinary, colorPalette.quinary.normal, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button ></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.quinary.dark === selectedColorPaletteItem"   (click)="onColorPaletteItemSelect(colorPalette.quinary, colorPalette.quinary.dark)"            [style.background-color]="colorPalette.quinary.dark.color"  ><span i18n="@@Primary Dark">Primary Dark</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.quinary.dark.color" (change)="setHexColorValue(colorPalette.quinary, colorPalette.quinary.dark, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button ></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.quinary.light === selectedColorPaletteItem"  (click)="onColorPaletteItemSelect(colorPalette.quinary, colorPalette.quinary.light)"           [style.background-color]="colorPalette.quinary.light.color" ><span i18n="@@Primary Light">Primary Light</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.quinary.light.color" (change)="setHexColorValue(colorPalette.quinary, colorPalette.quinary.light, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
      </div>
      <div style="margin-top: -8px">
        <div class="colorSwatch" [class.selected]="colorPalette.senary.normal === selectedColorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.senary, colorPalette.senary.normal)"             [style.background-color]="colorPalette.senary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.senary.normal.color" (change)="setHexColorValue(colorPalette.senary, colorPalette.senary.normal, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button ></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.senary.dark === selectedColorPaletteItem"   (click)="onColorPaletteItemSelect(colorPalette.senary, colorPalette.senary.dark)"               [style.background-color]="colorPalette.senary.dark.color"  ><span i18n="@@Primary Dark">Primary Dark</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.senary.dark.color" (change)="setHexColorValue(colorPalette.senary, colorPalette.senary.dark, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button ></button></div>
        <div class="colorSwatch" [class.selected]="colorPalette.senary.light === selectedColorPaletteItem"  (click)="onColorPaletteItemSelect(colorPalette.senary, colorPalette.senary.light)"              [style.background-color]="colorPalette.senary.light.color" ><span i18n="@@Primary Light">Primary Light</span><input class="hexColorInput" type='text' [ngModel]="colorPalette.senary.light.color" (change)="setHexColorValue(colorPalette.senary, colorPalette.senary.light, $event.target.value)" [ngModelOptions]="{standalone: true}"><button mat-button></button></div>
      </div>
    </div>
  </div>

  <!--<div class="colorPaletteDisplayed">-->
    <!---->
    <!---->
    <!--<div class="colorPaletteGroup" [ngClass]="colorPalette.primary === selectedColorPaletteItem ? 'selected' : ''">-->
      <!--<div class="colorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.primary)">-->
        <!--<div class="colorPalleteItemTitle" i18n="@@Primary">Primary</div>-->
        <!--<div class="normal" [style.background]="colorPalette.primary.normal.color"><span [style.color]="'' + colorPalette.primary.normal.contrast" >{{colorPalette.primary.normal.color}}</span></div>-->
        <!--<span class="light" [style.background]="colorPalette.primary.light.color" [style.color]="'' + colorPalette.primary.light.contrast"><span i18n="@@light">Light</span><br><span>{{colorPalette.primary.light.color}}</span></span>-->
        <!--<span class="dark" [style.background]="colorPalette.primary.dark.color" [style.color]="'' + colorPalette.primary.dark.contrast"><span i18n="@@Dark">Dark</span><br><span>{{colorPalette.primary.dark.color}}</span></span>-->
      <!--</div>-->
    <!--</div>-->
    <!--<div class="colorPaletteGroup" [ngClass]="colorPalette.secondary === selectedColorPaletteItem ? 'selected' : ''">-->
      <!--<div class="colorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.secondary)">-->
        <!--<div class="colorPalleteItemTitle" i18n="@@Secondary">Secondary</div>-->
        <!--<div class="normal" [style.background]="colorPalette.secondary.normal.color"><span [style.color]="'' + colorPalette.secondary.normal.contrast" >{{colorPalette.secondary.normal.color}}</span></div>-->
        <!--<div class="light" [style.background]="colorPalette.secondary.light.color" [style.color]="'' + colorPalette.secondary.light.contrast"><span i18n="@@light">Light</span><br><span>{{colorPalette.secondary.light.color}}</span></div>-->
        <!--<div class="dark" [style.background]="colorPalette.secondary.dark.color" [style.color]="'' + colorPalette.secondary.dark.contrast"><span i18n="@@Dark">Dark</span><br><span>{{colorPalette.secondary.dark.color}}</span></div>-->
      <!--</div>-->
    <!--</div>-->
    <!--<div class="colorPaletteGroup" [ngClass]="colorPalette.tertiary === selectedColorPaletteItem ? 'selected' : ''">-->
      <!--<div class="colorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.tertiary)">-->
        <!--<div class="colorPalleteItemTitle" i18n="@@Tertiary">Tertiary</div>-->
        <!--<div class="normal" [style.background]="colorPalette.tertiary.normal.color"><span [style.color]="'' + colorPalette.tertiary.normal.contrast" >{{colorPalette.tertiary.normal.color}}</span></div>-->
        <!--<span class="light" [style.background]="colorPalette.tertiary.light.color" [style.color]="'' + colorPalette.tertiary.light.contrast"><span i18n="@@light">Light</span><br><span>{{colorPalette.tertiary.light.color}}</span></span>-->
        <!--<span class="dark" [style.background]="colorPalette.tertiary.dark.color" [style.color]="'' + colorPalette.tertiary.dark.contrast"><span i18n="@@Dark">Dark</span><br><span>{{colorPalette.tertiary.dark.color}}</span></span>-->
      <!--</div>-->
    <!--</div>-->
    <!--<div class="colorPaletteGroup" [ngClass]="colorPalette.quaternary === selectedColorPaletteItem ? 'selected' : ''">-->
      <!--<div class="colorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.quaternary)">-->
        <!--<div class="colorPalleteItemTitle" i18n="@@Quaternary">Quaternary</div>-->
        <!--<div class="normal" [style.background]="colorPalette.quaternary.normal.color"><span [style.color]="'' + colorPalette.quaternary.normal.contrast" >{{colorPalette.quaternary.normal.color}}</span></div>-->
        <!--<div class="light" [style.background]="colorPalette.quaternary.light.color" [style.color]="'' + colorPalette.quaternary.light.contrast"><span i18n="@@light">Light</span><br><span>{{colorPalette.quaternary.light.color}}</span></div>-->
        <!--<div class="dark" [style.background]="colorPalette.quaternary.dark.color" [style.color]="'' + colorPalette.quaternary.dark.contrast"><span i18n="@@Dark">Dark</span><br><span>{{colorPalette.quaternary.dark.color}}</span></div>-->
      <!--</div>-->
    <!--</div>-->
    <!--<div class="colorPaletteGroup" [ngClass]="colorPalette.quinary === selectedColorPaletteItem ? 'selected' : ''">-->
      <!--<div class="colorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.quinary)">-->
        <!--<div class="colorPalleteItemTitle" i18n="@@Quinary">Quinary</div>-->
        <!--<div class="normal" [style.background]="colorPalette.quinary.normal.color"><span [style.color]="'' + colorPalette.quinary.normal.contrast" >{{colorPalette.quinary.normal.color}}</span></div>-->
        <!--<span class="light" [style.background]="colorPalette.quinary.light.color" [style.color]="'' + colorPalette.quinary.light.contrast"><span i18n="@@light">Light</span><br><span>{{colorPalette.quinary.light.color}}</span></span>-->
        <!--<span class="dark" [style.background]="colorPalette.quinary.dark.color" [style.color]="'' + colorPalette.quinary.dark.contrast"><span i18n="@@Dark">Dark</span><br><span>{{colorPalette.quinary.dark.color}}</span></span>-->
      <!--</div>-->
    <!--</div>-->
    <!--<div class="colorPaletteGroup" [ngClass]="colorPalette.senary === selectedColorPaletteItem ? 'selected' : ''">-->
      <!--<div class="colorPaletteItem" (click)="onColorPaletteItemSelect(colorPalette.senary)">-->
        <!--<div class="colorPalleteItemTitle" i18n="@@Senary">Senary</div>-->
        <!--<div class="normal" [style.background]="colorPalette.senary.normal.color"><span [style.color]="'' + colorPalette.senary.normal.contrast" >{{colorPalette.senary.normal.color}}</span></div>-->
        <!--<span class="light" [style.background]="colorPalette.senary.light.color" [style.color]="'' + colorPalette.senary.light.contrast"><span i18n="@@light">Light</span><br><span>{{colorPalette.senary.light.color}}</span></span>-->
        <!--<span class="dark" [style.background]="colorPalette.senary.dark.color" [style.color]="'' + colorPalette.senary.dark.contrast"><span i18n="@@Dark">Dark</span><br><span>{{colorPalette.senary.dark.color}}</span></span>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
  <div class="colorChooser">
    <div class="colorChooserRow" *ngFor="let color of getColors()">
      <!--<span class="leftHeader" >{{color.name}}<div class="divider"></div></span>-->
      <!--<span class="colorCell" *ngFor="let shade of getValues(color.shades)" [ngClass]="shade === selectedShade ? 'selected' : ''" [style.background]="shade.hex" (click)="onSelect(color, shade)"></span>-->
      <span class="colorCell" *ngFor="let shade of getValues(color.shades)" [style.background]="shade.hex" (click)="onSelect(color, shade)"></span>
      <!--<span class="colorCell" [ngClass]="color.shades['50'] === selectedShade ? 'selected' : ''" [style.background]="color.shades['50'].hex" (click)="onSelect(color, 0)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['100'].hex" (click)="onSelect(color, 1)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['200'].hex" (click)="onSelect(color, 2)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['300'].hex" (click)="onSelect(color, 3)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['400'].hex" (click)="onSelect(color, 4)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['500'].hex" (click)="onSelect(color, 5)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['600'].hex" (click)="onSelect(color, 6)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['700'].hex" (click)="onSelect(color, 7)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['800'].hex" (click)="onSelect(color, 8)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['900'].hex" (click)="onSelect(color, 9)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['A100'].hex" (click)="onSelect(color, 10)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['A200'].hex" (click)="onSelect(color, 11)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['A400'].hex" (click)="onSelect(color, 12)"></span>-->
      <!--<span class="colorCell" [ngClass]="color === selectedColor ? 'selected' : ''" [style.background]="color.shades['A700'].hex" (click)="onSelect(color, 13)"></span>-->
    </div>
  </div>
</div>


<!--<div data-react-class="Palette" >-->
  <!--<div class="palette-wrapper">-->
    <!--<div class="palette clearfix palette&#45;&#45;small" style="height: 913px;">-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-red" style="animation-delay: 0ms;">-->
          <!--<div style="background: rgb(244, 67, 54);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">red</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-pink" style="animation-delay: 25ms;">-->
          <!--<div style="background: rgb(233, 30, 99);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">pink</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper palette-color-wrapper&#45;&#45;double">-->
        <!--<div class="palette-color palette-color&#45;&#45;ad animated fadeIn">-->
          <!--<div ID="carbon_js">-->
            <!--<div ID="carbonads">-->
              <!--<span><span class="carbon-wrap"><a href="//srv.carbonads.net/ads/click/x/GTND42QJF6BI4KQWCY74YKQMC6AIVK77CESDTZ3JCWYIL2JLCEYDC23KC6BI45QMCTAIKK3EHJNCLSIZZRLCP7I35MNFV?segment=placement:materialpalettecom;&amp;encredirect=https%3A%2F%2Fad.doubleclick.net%2Fddm%2Ftrackclk%2FN5295.1676840CARBONADS.COM%2FB10444636.213266676%3Bdc_trk_aid%3D412396604%3Bdc_trk_cid%3D75142946%3Bdc_lat%3D%3Bdc_rdid%3D%3Btag_for_child_directed_treatment%3D" class="carbon-img" target="_blank" rel="noopener"><img src="https://assets.servedby-buysellads.com/p/manage/asset/id/61986" alt="" border="0" height="100" width="130" style="max-width: 130px;"></a><a href="//srv.carbonads.net/ads/click/x/GTND42QJF6BI4KQWCY74YKQMC6AIVK77CESDTZ3JCWYIL2JLCEYDC23KC6BI45QMCTAIKK3EHJNCLSIZZRLCP7I35MNFV?segment=placement:materialpalettecom;&amp;encredirect=https%3A%2F%2Fad.doubleclick.net%2Fddm%2Ftrackclk%2FN5295.1676840CARBONADS.COM%2FB10444636.213266676%3Bdc_trk_aid%3D412396604%3Bdc_trk_cid%3D75142946%3Bdc_lat%3D%3Bdc_rdid%3D%3Btag_for_child_directed_treatment%3D" class="carbon-text" target="_blank" rel="noopener">Kubernetes made easy with Google Container Engine. Try it free.</a></span><a href="http://carbonads.net/?utm_source=materialpalettecom&amp;utm_medium=ad_via_link&amp;utm_campaign=in_unit&amp;utm_term=carbon" class="carbon-poweredby" target="_blank" rel="noopener">ads via Carbon</a><img src="https://ad.doubleclick.net/ddm/trackimp/N5295.1676840CARBONADS.COM/B10444636.213266676;dc_trk_aid=412396604;dc_trk_cid=75142946;ord=1519511442;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=?" border="0" height="1" width="1" style="display: none;"></span>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-purple" style="animation-delay: 50ms;">-->
          <!--<div style="background: rgb(156, 39, 176);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">purple</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-deep-purple" style="animation-delay: 75ms;">-->
          <!--<div style="background: rgb(103, 58, 183);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">deep purple</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-indigo" style="animation-delay: 100ms;">-->
          <!--<div style="background: rgb(63, 81, 181);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">indigo</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-blue" style="animation-delay: 125ms;">-->
          <!--<div style="background: rgb(33, 150, 243);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">blue</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-light-blue" style="animation-delay: 150ms;">-->
          <!--<div style="background: rgb(3, 169, 244);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">light blue</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-cyan" style="animation-delay: 175ms;">-->
          <!--<div style="background: rgb(0, 188, 212);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">cyan</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-teal" style="animation-delay: 200ms;">-->
          <!--<div style="background: rgb(0, 150, 136);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">teal</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-green" style="animation-delay: 225ms;">-->
          <!--<div style="background: rgb(76, 175, 80);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">green</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-light-green" style="animation-delay: 250ms;">-->
          <!--<div style="background: rgb(139, 195, 74);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;black">light green</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-lime selected" style="animation-delay: 275ms;">-->
          <!--<div style="background: rgb(205, 220, 57);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;black">lime</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-yellow" style="animation-delay: 300ms;">-->
          <!--<div style="background: rgb(255, 235, 59);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;black">yellow</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-amber" style="animation-delay: 325ms;">-->
          <!--<div style="background: rgb(255, 193, 7);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;black">amber</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-orange" style="animation-delay: 350ms;">-->
          <!--<div style="background: rgb(255, 152, 0);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;black">orange</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-deep-orange" style="animation-delay: 375ms;">-->
          <!--<div style="background: rgb(255, 87, 34);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">deep orange</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-brown" style="animation-delay: 400ms;">-->
          <!--<div style="background: rgb(121, 85, 72);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">brown</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-grey selected" style="animation-delay: 425ms;">-->
          <!--<div style="background: rgb(158, 158, 158);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;black">grey</label></div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<div class="palette-color-wrapper">-->
        <!--<div class="palette-color palette-color&#45;&#45;color animated fadeIn palette-color&#45;&#45;color-blue-grey" style="animation-delay: 450ms;">-->
          <!--<div style="background: rgb(96, 125, 139);"><i class="material-icons">check</i><label class="palette-color-label palette-color-label&#45;&#45;white">blue grey</label></div>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
    <!--<div class="theme-wrapper animated fadeInRightBig" style="height: 913px;">-->
      <!--<div class="theme-palette">-->
        <!--<div class="theme-palette__header clearfix">-->
          <!--<span class="theme-palette__header-title">Your Palette<i class="material-icons">expand_less</i></span><span class="theme-palette__header-actions"><span><a href="javascript:;"><i class="material-icons">file_download</i>DOWNLOAD</a></span><a href="javascript:;"><i class="material-icons">share</i>TWEET</a></span>-->
        <!--</div>-->
        <!--<div class="animated">-->
          <!--<div class="theme-palette-colors clearfix">-->
            <!--<div class="theme-palette-color" data-clipboard-text="}">-->
              <!--<div class="theme-palette-color-inner theme-palette-color-inner&#45;&#45;white" style="background: rgb(97, 97, 97);"><span>Dark primary color</span><strong>#616161</strong></div>-->
            <!--</div>-->
            <!--<div class="theme-palette-color" data-clipboard-text="}">-->
              <!--<div class="theme-palette-color-inner theme-palette-color-inner&#45;&#45;black" style="background: rgb(245, 245, 245);"><span>Light primary color</span><strong>#f5f5f5</strong></div>-->
            <!--</div>-->
            <!--<div class="theme-palette-color" data-clipboard-text="}">-->
              <!--<div class="theme-palette-color-inner theme-palette-color-inner&#45;&#45;black" style="background: rgb(158, 158, 158);"><span>Primary color</span><strong>#9e9e9e</strong></div>-->
            <!--</div>-->
            <!--<div class="theme-palette-color" data-clipboard-text="}">-->
              <!--<div class="theme-palette-color-inner theme-palette-color-inner&#45;&#45;white" style="background: rgb(33, 33, 33);"><span>Text / Icons</span><strong>#212121</strong></div>-->
            <!--</div>-->
            <!--<div class="theme-palette-color" data-clipboard-text="}">-->
              <!--<div class="theme-palette-color-inner theme-palette-color-inner&#45;&#45;black" style="background: rgb(205, 220, 57);"><span>Accent color</span><strong>#cddc39</strong></div>-->
            <!--</div>-->
            <!--<div class="theme-palette-color" data-clipboard-text="}">-->
              <!--<div class="" style="background: rgb(33, 33, 33);"><span>Primary text</span><strong>#212121</strong></div>-->
            <!--</div>-->
            <!--<div class="theme-palette-color" data-clipboard-text="}">-->
              <!--<div class="" style="background: rgb(117, 117, 117);"><span>Secondary text</span><strong>#757575</strong></div>-->
            <!--</div>-->
            <!--<div class="theme-palette-color" data-clipboard-text="}">-->
              <!--<div class="" style="background: rgb(189, 189, 189);"><span>Divider color</span><strong>#BDBDBD</strong></div>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->
