import {Resource} from "./Resource";
import {DataObject} from "./DataObject";

export class Cover implements DataObject {
  ID: number;
  resource_ID: number;
  documentScale: number;
  verticalShift: number;
  horizontalShift: number;
  paperType_ID: number;
  barcodeSide: string;
  barcodeOrientationHorz: string;
  barcodeOrientationVert: string;
  barcodePaddingHorz: number;
  barcodePaddingVert: number;
  barcodePositionHorz: number;
  barcodePositionVert: number;
  isColorBoosted: string;
  laminateType: string;
  lastUpdate: Date;
  // book: Book;
  resource: Resource;
  standardized: string;
  problemStatus: string;
  problemNote: string;

  constructor (obj) {
    this.ID = 0;
    this.resource_ID = 0;
    this.documentScale = 1;
    this.verticalShift = 0;
    this.horizontalShift = 0;
    this.paperType_ID = 3;
    this.barcodeSide = 'NONE';
    this.barcodeOrientationHorz = 'LEFT';
    this.barcodeOrientationVert = 'BOTTOM';
    this.barcodePaddingHorz = 0;
    this.barcodePaddingVert = 0;
    this.barcodePositionHorz = 36;
    this.barcodePositionVert = 36;
    this.isColorBoosted = 'FALSE';
    this.laminateType = 'GLOSS';
    this.standardized = '';
    this.problemStatus = '';
    this.problemNote = '';

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    //
    // this.lastUpdate = new Date(0);
    // if (obj) {
    //   for (const prop of Object.keys(obj)) {
    //     this[prop] = obj[prop];
    //   }
    // }
    // if (obj) {
    //   this.resource = new Resource(obj.resource);
    // } else {
    //   this.resource = new Resource(undefined);
    // }
  }

  getId() {
    return this.ID;
  }

  getOrderValue() {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
