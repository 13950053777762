<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Add Facility Inventory Product">Add Facility Inventory Product</h2>
  </div>
  <div>
    <form [formGroup]="addForm" (change)="formChanged()">
      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-select  placeholder="Facility" i18n-placeholder="@@Facility" formControlName="printnode_ID" required>
<!--                <mat-option value="1" i18n='@@Phoneix, AZ'>Phoneix, AZ</mat-option>-->
                <mat-option value="7" i18n='@@Houston, TX' selected='selected'>Houston, TX</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <mat-select  placeholder="Inventory Management Type" i18n-placeholder="@@Inventory Management Type" formControlName="inventoryManagementType" required>-->
<!--                <mat-option value="CUSTOMER" i18n='@@Customer'>Customer</mat-option>-->
<!--                <mat-option value="LEVEL" i18n='@@Level'>Level</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Reorder Quantity" placeholder="Reorder Quantity" id="reorderQuantity" type="number" min="20"  max="1500" formControlName="reorderQuantity">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Reorder Level" placeholder="Reorder Level" id="reorderLevel" type="number" min="0"  max="1500" formControlName="reorderLevel">
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="addForm.invalid" (click)="onAdd()" i18n="@@Add Inventory Product">Add Inventory Product</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
