import {Component} from '@angular/core';
import {StartupService} from "../../service-ui/startup.service";

@Component({
  selector: 'app-shopping-cart-top-bar-button',
  templateUrl: './shopping-cart-top-bar-button.component.html',
  styleUrls: ['./shopping-cart-top-bar-button.component.css']
})

export class ShoppingCartTopBarButtonComponent {

  constructor (public startupService: StartupService) {
  }
}


