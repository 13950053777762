import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { User } from '../../model/User';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {DataService} from "./data-service";
import {Facility} from "../../model/Facility";
import {PrintNodeOrder} from "../../model/PrintNodeOrder";

@Injectable()
export class ShippingService extends DataService {

  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
    super(startupService);
    // this.startupService.setUserService(this);
  }

  /** PUT: createShippingLabels for a PrintNodeOrder */
  createShippingLabels(facility: Facility, printNodeOrder: PrintNodeOrder, shippingCarrierAndService, printer: string ): Observable<User> {
    const url = `${environment.apiBaseURL}shippinglabel/${facility.ID}/${printNodeOrder.ID}/${shippingCarrierAndService}/${printer}`;
    this.messageService.add(`ShippingService: createShippingLabel facility printNodeOrder id=${facility.ID} ${printNodeOrder.ID}`);
    return this.http.put(url, undefined, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`createShippingLabel facility printNodeOrder id=${facility.ID} ${printNodeOrder.ID}`)),
      catchError(this.handleError<any>(`createShippingLabel id=${facility.ID} ${printNodeOrder.ID}`))
    );
  }

  createFreightAddressSheets(facility: Facility, printNodeOrder: PrintNodeOrder, printer: string ): Observable<User> {
    const url = `${environment.apiBaseURL}shippinglabel/freightaddresssheets/${facility.ID}/${printNodeOrder.ID}/${printer}`;
    this.messageService.add(`ShippingService: createShippingLabel facility printNodeOrder id=${facility.ID} ${printNodeOrder.ID}`);
    return this.http.put(url, undefined, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`createShippingLabel facility printNodeOrder id=${facility.ID} ${printNodeOrder.ID}`)),
      catchError(this.handleError<any>(`createShippingLabel id=${facility.ID} ${printNodeOrder.ID}`))
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add('UserService: ' + message);
  }

  reset (filter: string = '') {
  }

}
