<mat-card appearance="outlined" style="max-width: 600px">
  <mat-card-content>
    <!--    <p>{{facilityInventoryProduct.ID}}</p>-->
    <!--    <p>{{facilityInventoryProduct.product.title}}</p>-->
    <!--    <p>{{facilityInventoryProduct.printNode.friendlyName}}</p>-->
    <!--    <p>{{facilityInventoryProduct.facilityInventoryProductEvents.length}}</p>-->

    <!--    <div id="chartdiv" style="width: 100%; height: 500px"></div>-->
    <div style="display: block; max-height: 500px;">
      <mat-card-header>
        <!--        <mat-card-title>{{facilityInventoryProduct.title}} <span i18n-style="@@floatEnd" style="float:right" [style.color]="( facilityInventoryProduct.quantityOnOrder + facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited ) <= 0 ? 3 : 1">{{facilityInventoryProduct.quantityOnOrder + facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited}}</span></mat-card-title>-->
        <!--        <mat-card-title>{{facilityInventoryProduct.title}}</mat-card-title>-->
        <mat-card-title>
          <span>{{facilityInventoryProduct.printnode_ID === 1 ? 'AZ' : 'TX' }} {{facilityInventoryProduct.title}}</span>
          <span i18n-style="@@floatEnd" style="float:right">
            <span style="font-size: 14px; color: gray;" title="Present Inventory Level/Net Inventory Level" i18n="@@Present/Net:">Present/Net:</span>
            <span [style.color]="( facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited ) <= 0 ? 'red' : 'green'">{{facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited}}</span><span>/</span>
            <span [style.color]="( facilityInventoryProduct.quantityOnOrder + facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited ) <= 0 ? 'red' : 'green'">{{facilityInventoryProduct.quantityOnOrder + facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited}}</span>
          </span>
        </mat-card-title>
        <mat-card-subtitle>
          <table>
            <tr>
              <td>Case Quantity:</td>
              <td>Replenishment On Order:</td>
              <td>Quantity On Hand:</td>
              <td>Needed for Current Orders:</td>
<!--              <td>Net Inventory Level:</td>-->
              <td *ngIf="startupService.selectedRole !== 'organization'">
                <button (click)="createReplenishmentOrder(facilityInventoryProduct, '' + Math.max ( facilityInventoryProduct.quantityCommited - facilityInventoryProduct.quantityOnOrder - facilityInventoryProduct.quantityOnHand + facilityInventoryProduct.reorderQuantity , facilityInventoryProduct.reorderQuantity ), '', '')" title="Reorder Quantity" i18n-title="@@Reorder Quantity">
                  <mat-icon [matBadge]="Math.max ( facilityInventoryProduct.quantityCommited - facilityInventoryProduct.quantityOnOrder - facilityInventoryProduct.quantityOnHand + facilityInventoryProduct.reorderQuantity , facilityInventoryProduct.reorderQuantity )" matBadgeColor="primary" >shopping_cart_checkout</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td>{{facilityInventoryProduct.caseQuantity}}</td>
              <td>{{facilityInventoryProduct.quantityOnOrder}}</td>
              <td>{{facilityInventoryProduct.quantityOnHand}}</td>
              <td>{{facilityInventoryProduct.quantityCommited}}</td>
<!--              <td>{{facilityInventoryProduct.quantityOnOrder + facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited}}</td>-->
              <td *ngIf="startupService.selectedRole !== 'organization'">
                <button (click)="placeReplenishmentOrder()" title="Reorder Manually" i18n-title="@@Reorder Manually">
                  <mat-icon matBadgeColor="secondary" >shopping_cart</mat-icon>
                </button>
              </td>
            </tr>
          </table>
        </mat-card-subtitle>
      </mat-card-header>

<!--      <canvas *ngIf="showCharts" style="max-height: 500px;" baseChart width="300" height="150"-->
<!--              [datasets]="lineChartData"-->
<!--              [labels]="lineChartLabels"-->
<!--              [options]="lineChartOptions"-->
<!--              [colors]="lineChartColors"-->
<!--              [legend]="lineChartLegend"-->
<!--              [chartType]="lineChartType"-->
<!--              [plugins]="lineChartPlugins">-->
<!--      </canvas>-->
      <div>
        <button mat-icon-button *ngIf="startupService.selectedRole === 'facility' || startupService.selectedRole === 'organization'" (click)="editFacilityInventoryProductSettings()" title="Setting" i18n-title="@@Settings">
          <mat-icon>settings</mat-icon>
        </button>
        <button mat-icon-button *ngIf="startupService.selectedRole === 'facility'" (click)="adjustFacilityInventoryProductInventory('RecordCurrentOnHandInventoryLevel')" title="Record Current On Hand Inventory Level" i18n-title="@@Record Current On Hand Inventory Level">
          <mat-icon>auto_fix_normal</mat-icon>
        </button>
        <button mat-icon-button *ngIf="startupService.selectedRole === 'facility'" (click)="adjustFacilityInventoryProductInventory('ReceiveExternalReplenishment')" title="Receive Manual External Replenishment" i18n-title="@@Receive Manual External Replenishment">
          <mat-icon>read_more</mat-icon>
        </button>
        <button mat-icon-button *ngIf="startupService.selectedRole === 'facility'" (click)="adjustFacilityInventoryProductInventory('ReceiveReturn')" title="Receive Return" i18n-title="@@Receive Return">
          <mat-icon>south_west</mat-icon>
        </button>
        <button mat-icon-button *ngIf="startupService.selectedRole === 'facility'" (click)="adjustFacilityInventoryProductInventory('CorrectInventoryLevel')" title="Correct Inventory Level" i18n-title="@@Correct Inventory Level">
          <mat-icon>change_history</mat-icon>
        </button>
<!--        <button mat-icon-button *ngIf="startupService.selectedRole !== 'organization'" (click)="transferFacilityInventoryProductInventory()" title="Transfer Inventory" i18n-title="@@Transfer Inventory">-->
<!--          <mat-icon>sync_alt</mat-icon>-->
<!--        </button>-->
        <button *ngIf="startupService.selectedRole !== 'organization'" mat-icon-button (click)="editFacilityInventoryProductNote()" title="Note" i18n-title="@@Note">
          <mat-icon>speaker_notes</mat-icon>
        </button>
        <button mat-icon-button (click)="showDetails=!showDetails; showFaciliityInventoryProductDetails();" title="Show Details" i18n-title="@@Show Details">
          <mat-icon>details</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="showDetails">
      <h3><span i18n="@@Details">Details</span>
        <button i18n-style="@@floatEnd" style="float:right" mat-icon-button (click)="refreshDetails()" title="Refresh Details" i18n-title="@@Refresh Details">
          <mat-icon>refresh</mat-icon>
        </button>
        <button i18n-style="@@floatEnd" style="float:right" mat-icon-button (click)="hideFulfillment = !hideFulfillment" title="Show/Hide Fulfillment Orders" i18n-title="@@Show/Hide Fulfillment Orders">
          <mat-icon>view_in_ar</mat-icon>
        </button>
      </h3>
      <ng-container *ngIf="!inventoryEvents">
        <h2 style="padding: 20px; text-align: center;" i18n="@@Loading Data">Loading Data</h2><mat-spinner style="margin:0 auto;"></mat-spinner>
      </ng-container>
      <ng-container *ngIf="inventoryEvents">
        <table class="details">
          <tr>
            <td class="end" i18n="@@Id">Id</td>
            <td class="center"><span i18n="@@Created">Created</span><br/><span i18n="@@Actual/Expected">Actual/Expected</span></td>
            <td class="center" i18n="@@Type">Type</td>
            <td class="end" i18n="@@Quantity">Quantity</td>
            <td class="end" i18n="@@Balance">Balance</td>
            <td class="end" i18n="@@Actions">Actions</td>
          </tr>
          <ng-container *ngFor="let event of inventoryEvents">
            <tr *ngIf="(event.source !== 'FULFILLMENT*' || !hideFulfillment)">
              <td class="end">{{event.ID > 0 ? event.ID : '-' }}</td>
              <td class="center"><span>{{event.createdTimestamp | date: 'yyyy-MM-dd' }}</span><br/><span>{{event.actualOrExpectedTimestamp | date: 'yyyy-MM-dd' }}</span><span style="display: block" *ngIf="event.source === 'REPLENISHMENT*' && event.printNodeOrder">{{event.printNodeOrder.tag}}<br/>{{event.printNodeOrder.lastname}}</span></td>
              <td class="center">
                <ng-container [ngSwitch]="event.source">
                  <ng-container *ngSwitchCase="'INITIAL'"><mat-icon style="color: green" title="Initial" i18n-title="@@Initial">flight_takeoff</mat-icon></ng-container>
                  <ng-container *ngSwitchCase="'TRANSFER'"><mat-icon title="Transfer" i18n-title="@@Transfer">sync_alt</mat-icon></ng-container>
                  <ng-container *ngSwitchCase="'REPLENISHMENT'"><mat-icon   [style.color]="event.status === 'VALID' ? 'green' : 'orange'" title="Manual Replenishment" i18n-title="@@Manual Replenishment">south_east</mat-icon>*</ng-container>
                  <ng-container *ngSwitchCase="'REPLENISHMENT*'"><mat-icon  [style.color]="event.status === 'RECEIVED' ? 'green' : 'orange'"  title="Replenishment" i18n-title="@@Replenishment">south_east</mat-icon></ng-container>
                  <ng-container *ngSwitchCase="'CORRECTION'"><mat-icon title="Correction" i18n-title="@@Correction">network_ping</mat-icon></ng-container>
                  <ng-container *ngSwitchCase="'FULFILLMENT'"><mat-icon [style.color]="event.status === 'VALID' ? 'green' : 'orange'" title="Manual Fulfillment" i18n-title="@@Manual Fulfillment">north_east</mat-icon>*</ng-container>
                  <ng-container *ngSwitchCase="'FULFILLMENT*'"><mat-icon [style.color]="event.status === 'SHIPPED' ? 'green' : 'orange'" title="Fulfilment" i18n-title="@@Fulfillment">north_east</mat-icon></ng-container>
  <!--                {{event.source}}-->
                </ng-container>
              </td>
              <td class="end">{{event.quantity}}</td>
              <td class="end">{{event.balance}}</td>
              <td class="center">
                <button *ngIf="event.source === 'REPLENISHMENT*' && event.printNodeOrderItem && event.printNodeOrder && event.printNodeOrder.shippingStatus === 'NONE'" mat-icon-button (click)="receiveReplenishmentPrintNodeOrder(event.printNodeOrder, event.printNodeOrderItem)" title="Receive" i18n-title="@@Receive">
                  <mat-icon>done_outline</mat-icon>
                </button>
                <button *ngIf="event.source === 'REPLENISHMENT*' && event.printNodeOrderItem && event.printNodeOrder && event.printNodeOrder.shippingStatus === 'SHIPPED'" mat-icon-button (click)="unReceiveReplenishmentPrintNodeOrder(event.printNodeOrder, event.printNodeOrderItem)" title="Unreceive" i18n-title="@@Unreceive">
                  <mat-icon>remove_done</mat-icon>
                </button>
                <button *ngIf="event.source === 'REPLENISHMENT*' && event.printNodeOrderItem" mat-icon-button (click)="editReplenishmentPrintNodeOrder(event.printNodeOrder, event.printNodeOrderItem)" title="Edit Replenishment" i18n-title="@@Edit Replenishment">
                  <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="(event.source === 'INITIAL' || event.source === 'TRANSFER' || event.source === 'REPLENISHMENT' || event.source === 'FULFILLMENT'  || event.source === 'CORRECTION' )  && event.facilityInventoryProductEvent" mat-icon-button (click)="editFacilityInventoryProductEvent(event.facilityInventoryProductEvent)" title="Edit Facility Inventory Product Event" i18n-title="@@Edit Facility Inventory Product Event">
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </tr>
          </ng-container>
        </table>
      </ng-container>
    </div>

    <div *ngIf="edit">
      <ng-container *ngIf="editFacilityInventoryProduct">
<!--        <form>-->
          <h3 i18n="@@Inventory Controls">Inventory Controls</h3>
<!--          <mat-form-field>-->
            <textarea name="reorderQuantity" [(ngModel)]="editableFacilityInventoryProduct.reorderQuantity" matInput i18n-placeholder="@@Reorder Quantity" placeholder="Reorder Quantity"></textarea>
<!--          </mat-form-field>-->
<!--          <mat-form-field>-->
            <textarea name="reorderLevel" [(ngModel)]="editableFacilityInventoryProduct.reorderLevel" matInput i18n-placeholder="@@Reorder Level" placeholder="Reorder Level"></textarea>
<!--          </mat-form-field>-->
          <br>
<!--          <mat-form-field>-->
            <textarea name="sequence" [(ngModel)]="editableFacilityInventoryProduct.sequence" matInput i18n-placeholder="@@Sequence" placeholder="Sequence"></textarea>
<!--          </mat-form-field>-->
<!--          <mat-form-field>-->
            <textarea name="caseQuantity" [(ngModel)]="editableFacilityInventoryProduct.caseQuantity" matInput i18n-placeholder="@@Case Quantity" placeholder="Case Quantity"></textarea>
<!--          </mat-form-field>-->
          <br>
          <h3 i18n="@@Settings">Settings</h3>
<!--          <mat-form-field>-->
            <mat-select name="state" [(ngModel)]="editableFacilityInventoryProduct.state" matInput i18n-placeholder="@@State" placeholder="State">
              <mat-option value="ACTIVE" i18n='@@Active'>Active</mat-option>
              <mat-option value="INACTIVE" i18n='@@Inactive'>Inactive</mat-option>
              <mat-option value="RETAIN" i18n='@@Retain'>Retain</mat-option>
            </mat-select>
<!--          </mat-form-field>-->
<!--          <mat-form-field>-->
<!--            <mat-select name="inventoryManagementType" [(ngModel)]="editableFacilityInventoryProduct.inventoryManagementType" matInput i18n-placeholder="@@Inventory Management Type" placeholder="Inventory Management Type">-->
<!--              <mat-option value="CUSTOMER" i18n='@@Customer'>Customer</mat-option>-->
<!--              <mat-option value="LEVEL" i18n='@@Level'>Level</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->
<!--          <br>-->
<!--          <mat-form-field>-->
            <mat-select name="productId" [(ngModel)]="editableFacilityInventoryProduct.product_ID" matInput i18n-placeholder="@@Product" placeholder="Product">
              <mat-option *ngFor="let product of products" [value]="product.ID">{{product.ID}} {{product.title}}</mat-option>
            </mat-select>
<!--          </mat-form-field>-->
<!--        </form>-->
      </ng-container>
<!--      <button *ngIf="!editFacilityInventoryProduct" mat-icon-button (click)="editObject()" title="Edit" i18n-title="@@Edit">-->
<!--        <mat-icon>edit</mat-icon>-->
<!--      </button>-->
<!--      <button *ngIf="editFacilityInventoryProduct" mat-icon-button (click)="save()" title="Save" i18n-title="@@Save">-->
<!--        <mat-icon>save</mat-icon>-->
<!--      </button>-->
<!--      <button *ngIf="editFacilityInventoryProduct" mat-icon-button (click)="cancel()" title="Cancel" i18n-title="@@Cancel">-->
<!--        <mat-icon>cancel</mat-icon>-->
<!--      </button>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Location"  title="Location" [ngModel]="facilityInventoryProduct.location" #placeholderLocation></textarea>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Shelf"  title="Shelf" [ngModel]="facilityInventoryProduct.shelf" #placeholderShelf></textarea>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Case Quantity"  title="Case Quantity" [ngModel]="facilityInventoryProduct.caseQuantity" #placeholderCaseQuantity min="1" max="300"></textarea>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Sequence"  title="Sequence" [ngModel]="facilityInventoryProduct.sequence" #placeholderSequence min="1" max="1500"></textarea>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Reorder Quantity"  title="Reorder Quantity" [ngModel]="facilityInventoryProduct.reorderQuantity" #placeholderReorderQuantity min="1" max="300"></textarea>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Reorder Level"  title="Reorder Level" [ngModel]="facilityInventoryProduct.reorderLevel" #placeholderReorderLevel min="1" max="300"></textarea>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Title"  title="Title" [ngModel]="facilityInventoryProduct.title" #placeholderTitle></textarea>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@State"  title="State" [ngModel]="facilityInventoryProduct.state" #placeholderState></textarea>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Inventory Management Type"  title="Inventory Management Type" [ngModel]="facilityInventoryProduct.inventoryManagementType" #placeholderManagementType></textarea>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Product Id"  title="Product Id" [ngModel]="facilityInventoryProduct.product_ID" #placeholderProductId></textarea>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Product Ids"  title="Product Ids" [ngModel]="facilityInventoryProduct.productIds" #placeholderProductIds></textarea>-->
      <!--          <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Quantity On Hand"  title="Quantity On Hand" [ngModel]="facilityInventoryProduct.quantityOnHand" #placeholderQuantityOnHand></textarea>-->
      <!--          <div>-->
      <!--            <ng-container *ngIf="placeholderLocation.value !== facilityInventoryProduct.location ||-->
      <!--                                  placeholderShelf.value !== facilityInventoryProduct.shelf ||-->
      <!--                                  placeholderSequence.value !== facilityInventoryProduct.sequence ||-->
      <!--                                  placeholderReorderQuantity.value !== facilityInventoryProduct.reorderQuantity ||-->
      <!--                                  placeholderReorderLevel.value !== facilityInventoryProduct.reorderLevel ||-->
      <!--                                  placeholderTitle.value !== facilityInventoryProduct.title ||-->
      <!--                                  placeholderState.value !== facilityInventoryProduct.state ||-->
      <!--                                  placeholderManagementType.value !== facilityInventoryProduct.inventoryManagementType ||-->
      <!--                                  placeholderProductId.value !== facilityInventoryProduct.product_ID ||-->
      <!--                                  placeholderProductIds.value !== facilityInventoryProduct.productIds ||-->
      <!--                                  placeholderQuantityOnHand.value !== facilityInventoryProduct.quantityOnHand ||-->
      <!--                                  placeholderCaseQuantity.value !== facilityInventoryProduct.caseQuantity">-->
      <!--              <span i18n-title="@@Save Case Quantity"  title="Save Case Quantity" (click)="setCaseQuantity(facilityInventoryProduct, placeholderCaseQuantity.value); editFacilityInventoryProduct = !editFacilityInventoryProduct; "><mat-icon>check</mat-icon></span>-->
      <!--            </ng-container>-->
      <!--            <span i18n-title="@@Cancel changes to case quantity" title="Cancel changes to case quantity" (click)="editFacilityInventoryProduct = !editFacilityInventoryProduct;"><mat-icon>close</mat-icon></span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <span *ngIf="!editFacilityInventoryProduct" (click)="editFacilityInventoryProduct = !editFacilityInventoryProduct" i18n-title="@@Edit Case Quantity" title="Edit Case Quantity"><mat-icon>edit</mat-icon></span>-->
    </div>
<!--    <div>-->
<!--      <span i18n="@@Replenish Inventory">Replenish Inventory</span>-->
<!--      <div *ngIf="replenish">-->
<!--        <mat-form-field appearance="fill">-->
<!--          <mat-label i18n="@@Select an option">Select an option</mat-label>-->
<!--          <mat-select [(value)]="replenishmentOrderProductId">-->
<!--            <ng-container *ngFor="let productId of facilityInventoryProduct.productIds.split(', ')">-->
<!--              <mat-option [value]="productId">{{productId}}{{ productId === facilityInventoryProduct.product_ID ? '*' : ''}}</mat-option>-->
<!--            </ng-container>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--        <textarea style="width: calc(30% - 10px); height: 45px; font-size:1em" type="number" i18n-title="@@Replenishment Quantity" title="Replenishment Quantity" [ngModel]="facilityInventoryProduct.caseQuantity"-->
<!--                  #placeholderReplenishmentQuantity min="1" max="10000"></textarea>-->
<!--        <textarea style="width: calc(30% - 10px); height: 45px; font-size:1em" type="text" i18n-title="@@Replenishment Tag" title="Replenishment Tag" #placeholderReplenishmentTag1></textarea>-->
<!--        <textarea style="width: calc(30% - 10px); height: 45px; font-size:1em" type="text" i18n-title="@@Batch" title="Batch" #placeholderReplenishmentTag2></textarea>-->
<!--        <div>-->
<!--          <span i18n-title="@@Commit Replenishment" title="Commit Replenishment"-->
<!--                (click)="createReplenishmentOrder(facilityInventoryProduct, placeholderReplenishmentQuantity.value, placeholderReplenishmentTag1.value, placeholderReplenishmentTag2.value); replenish = !replenish;"><mat-icon>check</mat-icon></span>-->
<!--          <span i18n-title="@@Cancel replenishment" title="Cancel replenishment" (click)="replenish = !replenish;"><mat-icon>close</mat-icon></span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <span *ngIf="!replenish" (click)="replenish = !replenish" i18n-title="@@Replenish Inventory" title="Replenish Inventory"><mat-icon>edit</mat-icon></span>-->
<!--    </div>-->
    <mat-card-title *ngIf="facilityInventoryProduct.note && startupService.selectedRole !== 'organization'">
      <mat-icon style="color: cornflowerblue" *ngIf="facilityInventoryProduct.note.indexOf(':') >= 0 && facilityInventoryProduct.note.substring(0, facilityInventoryProduct.note.indexOf(':')) === 'Question'">question_mark</mat-icon>
      <mat-icon style="color: red" *ngIf="facilityInventoryProduct.note.indexOf(':') >= 0 && facilityInventoryProduct.note.substring(0, facilityInventoryProduct.note.indexOf(':')) === 'Problem'">warning</mat-icon>
      <span style="color: cornflowerblue" *ngIf="facilityInventoryProduct.note.indexOf(':') >= 0 ? facilityInventoryProduct.note.substring(facilityInventoryProduct.note.indexOf(':')) : ''"></span><span>{{facilityInventoryProduct.note.indexOf(':') >= 0 ? facilityInventoryProduct.note.substring(facilityInventoryProduct.note.indexOf(':') + 1) : ''}}</span>
    </mat-card-title>
  </mat-card-content>
</mat-card>
