import {Component, Inject, Input, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {Product} from "../../../../../model/Product";
import {Organization} from "../../../../../model/Organization";
import {OrganizationService} from "../../../../service-data/organization.service";
import {ProductService} from "../../../../service-data/product.service";
import {FacilityInventoryProductService} from "../../../../service-data/facility-inventory-product.service";
import {StartupService} from "../../../../service-ui/startup.service";
import {FacilityInventoryProduct} from "../../../../../model/FacilityInventoryProduct";
import {ValidationAddress, AddressValidationResult, AddressValidationService} from "../../../../service-data/address-validation.service";
import {PrintNodeOrder} from "../../../../../model/PrintNodeOrder";
import {OrderDeliverTo} from "../../../../../model/OrderDeliverTo";
import {PrintNodeOrderService} from "../../../../service-data/print-node-order.service";
import {Package} from "../../../../../model/Package";

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.css']
})
export class EditAddressComponent implements OnInit {
  public printNodeOrder: PrintNodeOrder;
  // public pkg: Package;

  public initialProcessing: boolean;
  public breakpoint: number;

  public form: FormGroup;
  wasFormChanged = false;

  addressValidationStatus = ''; // Needs to be initilized
  addressValidationResult: AddressValidationResult;
  enterAnAlternativeAddress = false;
  alternativeAddress: ValidationAddress = new ValidationAddress(undefined);

  constructor(private printNodeOrderService: PrintNodeOrderService, private addressValidationService: AddressValidationService, private formBuilder: FormBuilder, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<EditAddressComponent>) {
    this.enterAnAlternativeAddress = false;
    if ( data.printNodeOrder ) {
      this.printNodeOrder = data.printNodeOrder;
    }
    // if ( data.pkg ) {
    //   this.pkg = data.pkg;
    // }
  }

  public ngOnInit(): void {
    this.enterAnAlternativeAddress = false;
    // this.form = this.formBuilder.group({
    //   printnode_ID: [this.printnode_ID, [Validators.required]],
    //   inventoryManagementType: [this.inventoryManagementType, [Validators.required]],
    //   reorderQuantity: [this.reorderQuantity, [Validators.required]],
    //   reorderLevel: [this.reorderLevel, [Validators.required]]
    // });

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  // From addressIsue
  validateAddress (orderDeliverTo: OrderDeliverTo | ValidationAddress) {
    const address = new ValidationAddress(orderDeliverTo);
    this.addressValidationService.validate(address).subscribe( addressValidationResult => {
      this.addressValidationResult = addressValidationResult;
    });
  }

  setEnterAnAlternativeAddress() {
    this.alternativeAddress = new ValidationAddress( this.printNodeOrder );
    this.enterAnAlternativeAddress = true;
  }
  setCancelAlternativeAddress () {
    this.enterAnAlternativeAddress = false;
  }


  useThisAddress (address: ValidationAddress) {
    this.printNodeOrder.address1 = address.address1;
    this.printNodeOrder.address2 = address.address2;
    this.printNodeOrder.address3 = address.address3;
    this.printNodeOrder.address4 = address.address4;
    this.printNodeOrder.city = address.city;
    this.printNodeOrder.state = address.state;
    this.printNodeOrder.postalcode = address.postalcode;
    this.printNodeOrder.country = address.country;
    // this.printNodeOrder.addressValidationStatus = 'VALID';
    this.printNodeOrderService.changeAddress(this.printNodeOrder).subscribe( printNodeOrder => {
      console.log("Saving a validated OrderDeliverTo");
      this.markAsDirty(this.form);
      this.dialogRef.close();
    });
  }

  // deleteFromOrder (orderDeliverTo: OrderDeliverTo) {
  //   console.log("Make this work");
  //   this.printNodeOrder.addressValidationStatus = 'VALID';
  //   this.printNodeOrderService.deleteOrderDeliverTo(this.printNodeOrder).subscribe( odt => {
  //     // TODO: Update the order weight etc.. and delete the order if there are no other OrderDeliverTos in the Order -- make sure to clean up any PrintNodeOrders if the address issue came about after the PrintNodeOrder was created
  //     console.log("Deleting an OrderDeliverTo from an order");
  //   });
  // }

  // processLater (orderDeliverTo: OrderDeliverTo) {
  //   orderDeliverTo.addressValidationStatus = 'INVALID';
  //   this.printNodeOrderService.updateOrderDeliverTo(this.printNodeOrder).subscribe( odt => {
  //     console.log("Saving a validated OrderDeliverTo");
  //   });
  // }

  useAddressAsShownInAlternativeAddress (printNodeOrder: PrintNodeOrder, alternativeAddress: ValidationAddress) {
    printNodeOrder.address1 = alternativeAddress.address1;
    printNodeOrder.address2 = alternativeAddress.address2;
    printNodeOrder.city = alternativeAddress.city;
    printNodeOrder.state = alternativeAddress.state;
    printNodeOrder.country = alternativeAddress.country;
    printNodeOrder.postalcode = alternativeAddress.postalcode;
    this.printNodeOrderService.changeAddress(this.printNodeOrder).subscribe( changedPrintNodeOrder => {
      console.log("Saving a validated Change Address in PrintNodeOrder");
    });
    this.markAsDirty(this.form);
    this.dialogRef.close();
  }
}
