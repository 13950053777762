import { Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../pipe/language.pipe";
import {UserService} from "../../../service-data/user.service";

@Component({
  selector: 'app-add-group-order',
  templateUrl: './add-group-order.component.html',
  styleUrls: ['./add-group-order.component.css']
})
export class AddGroupOrderComponent implements OnInit {
  public breakpoint: number; // Breakpoint observer code
  public name: string = ``;
  // public phone: string = ``;
  // public phoneCountry: string = ``;
  public addForm: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public userService: UserService) {
  }

  public ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      name: [this.name, [Validators.required, Validators.pattern('[a-zA-Z]+([a-zA-Z ]+)*')]],
      email: [null, [Validators.required, Validators.email]],
      // phone: [this.phone, [Validators.required]],
      // phone: [this.phone, [Validators.required, PhoneValidator.validCountryPhone(this.addForm.controls.phoneCountry)]],
      // phoneCountry: [this.phoneCountry, [Validators.required, Validators.pattern('^\\d*[0-9]\\d*$')]]
    });
    // this.addForm.controls.phoneCountry.validator = PhoneValidator.validCountryPhone(this.addForm.controls.phoneCountry);

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onAdd(): void {
    this.markAsDirty(this.addForm);
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
