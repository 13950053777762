<!--<button *ngIf="pickSheetsGroup" [ngClass]="pickSheetsGroup.selected ? 'selected': ''" (click)="toggleSelected(pickSheetsGroup);">-->
  <table *ngIf="pickSheetsGroup" (click)="toggleSelected(pickSheetsGroup);" style="padding: 0; margin: 0;">
    <tr style="padding: 0; margin: 0;">
      <td style="padding: 0; margin: 0;">{{pickSheetsGroup.orders}}</td>
    </tr>
    <tr style="padding: 0; margin: 0;">
      <td>{{pickSheetsGroup.books}}</td>
    </tr>
  </table>
<!--</button>-->
