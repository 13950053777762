import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import { AbstractDataService } from "./abstract-data.service";
import { Catalog } from "../../model/Catalog";

@Injectable()
export class CatalogService extends AbstractDataService<Catalog> {
  public static catalogCacheList: Catalog[] = [
    new Catalog( { ID: 1, name: 'Star', iconName: 'star_rate'}),
    new Catalog( { ID: 2, name: 'Circle', iconName: 'circle'}),
    new Catalog( { ID: 3, name: 'Anchor', iconName: 'anchor'})
  ];

  // TODO: Fix this so that one auto loads from the other and they can't get out of sync
  static catalogCache: any = {
    '1': new Catalog( { ID: 1, name: 'Star', iconName: 'star_rate'}),
    '2': new Catalog( { ID: 2, name: 'Circle', iconName: 'circle'}),
    '3': new Catalog( { ID: 3, name: 'Anchor', iconName: 'anchor'})
  };

  static getCatalogs(catalogsString: string): Catalog[] {
    const list = catalogsString.split(',');
    const catalogList: Catalog[] = [];
    for ( const catalogId in list ) {
      if ( CatalogService.catalogCache[ catalogId ]) {
        catalogList.push(CatalogService.catalogCache[ catalogId ]);
      }
    }
    return catalogList;
  }

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService) {
    super(httpa, messageServicea, startupServicea);
  }

  getCatalogs(): Catalog[] {
    return CatalogService.catalogCacheList;
  }


  makeObjectInstance(args: any): Catalog {
    const object = new Catalog(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "catalog";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "catalog";
  }
}
