import {Component} from "@angular/core";
import {UserRegistrationService} from "../../service-auth/user-registration.service";
import {CognitoCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {environment as env} from "../../../environments/environment";

@Component({
    selector: 'app-account',
    templateUrl: './resendCode.html',
    styleUrls: ['../component-account.scss']

})
export class ResendCodeComponent implements CognitoCallback {
    environment = env;
    email: string;
    errorMessage: string;

    constructor(public registrationService: UserRegistrationService, public router: Router) {

    }

    resendCode() {
        this.registrationService.resendCode(this.email, this);
    }

    cognitoCallback(error: any, result: any) {
        if (error != null) {
            this.errorMessage = "Something went wrong...please try again";
        } else {
            this.router.navigate(['/home/confirmRegistration', this.email]);
        }
    }
}
