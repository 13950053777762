
export class ShippingItem {
  height: number;
  width: number;
  depth: number;
  thickness: number;
  weight: number;
  quantity: number;
  caseQuantity: number;
  manufacturingSetId: number;

  constructor ( height: number, width: number, depth: number, thickness: number, weight: number, quantity: number, caseQuantity: number, manufacturingSetId: number = 0 ) {
    this.height = height;
    this.width = width;
    this.depth = depth;
    this.thickness = thickness;
    this.weight = weight;
    this.quantity = quantity;
    this.caseQuantity = caseQuantity;
    this.manufacturingSetId = manufacturingSetId;
  }
}

