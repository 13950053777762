<div class="audioFileToTranscribeArea">

<!--<div class="container">-->

  <!--<div class="row">-->

    <!--<div class="col-md-9">-->

      <!--<h4 i18n="@@Audio Files to Upload">Audio Files to Upload</h4>-->

  <div style="min-height: 100px;" appNg2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" [uploader]="uploader" class="well my-drop-zone">

    <span style="font-size:1.2em" i18n="@@Drop Audio and Video Files Here">Drop Audio and Video Files Here</span><br>
    <span style="font-size:0.85em" i18n="@@Accepted file types: acc, aif, aifc, aiff, amr, avi, mp3, mp4, m4a, mov, ogg, vob, wav, wma, wmv">Accepted file types: acc, aif, aifc, aiff, amr, avi, mp3, mp4, m4a, mov, ogg, vob, wav, wma, wmv</span><br>
    <span style="font-size:0.7em" i18n="@@Only English transcription is currently available.">Only English transcription is currently available.</span><br><br>
    <label for="audioFileToTranscribe">
      <span i18n="@@Or browse files">Or browse files</span>
      <input id="audioFileToTranscribe" type="file"  style="display:none" appNg2FileSelect [uploader]="uploader" multiple accept="audio/aac audio/x-aiff video/ogg audio/wav audio/mp4 video/mp4 audio/AMR video/quicktime video/x-ms-wmv video/x-ms-wma video/x-ms-vob">
    </label>
  </div>

<!--  <div class="filesToUplaod">-->
<!--    <div class="fileToUpload" *ngFor="let item of uploader.queue">-->
<!--      <div class="fileName" style="display: block">{{ item?.file?.name }}</div>-->
<!--      <div class="fileDuration" *ngIf="item.duration" style="display: inline-block">{{ item?.duration }} <ng-container i18n="@@Minutes">Minutes</ng-container></div>-->
<!--      <div class="fileCharge" *ngIf="item.duration" style="display: inline-block">{{ item?.charge | currency:'USD':'symbol':'1.2-2' }}</div>-->
<!--      <div class="fileSize" style="display: inline-block; float: right">{{ item?.file?.size/1024/1024 | number:'.1' }} MB</div>-->
<!--      <div class="progress" style="margin-bottom: 0;">-->
<!--          <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>-->
<!--      </div>-->

<!--      <div class="fileUploadControls">-->
<!--        <button type="button" class="btn btn-success btn-xs" (click)="startupService.transcriptionsCart.clear(); startupService.transcriptionsCart.addTranscription(startupService.project.name,  item.chapterId, item._file.name, item.duration, item.charge); startupService.showUploadCheckout = true" [disabled]="!item.isReady || item.isUploading || item.isSuccess" style="margin-top: 5px; margin-bottom: 5px; margin-left: auto; margin-right: auto; display: block;" >-->
<!--          <span class="glyphicon glyphicon-upload"></span><ng-container i18n="@@Approve Transcription">Approve Transcription</ng-container><br><ng-container i18n="@@Charge of">Charge of </ng-container>{{ item?.charge | currency:'USD':'symbol':'1.2-2' }} <br><ng-container i18n="@@and Upload">and Upload</ng-container>-->
<!--        </button>-->
<!--        <button type="button" class="btn btn-danger btn-xs" (click)="item.remove()" style="margin-top: 5px; margin-bottom: 5px; margin-left: auto; margin-right: auto; display: block;" >-->
<!--          <span class="glyphicon glyphicon-trash"></span> <ng-container i18n="@@Remove">Remove</ng-container>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  &lt;!&ndash;<button *ngIf="uploader.getNotUploadedItems().length > 1" type="button" class="btn btn-success btn-s" style="margin-top: 5px; margin-bottom: 5px; margin-left: auto; margin-right: auto; display: block;" (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">&ndash;&gt;-->
<!--  <button *ngIf="uploader.getNotUploadedItems().length > 1" type="button" class="btn btn-success btn-s" style="margin-top: 5px; margin-bottom: 5px; margin-left: auto; margin-right: auto; display: block;" (click)="addNotUploadedItemsToCart(); startupService.showUploadCheckout = true" [disabled]="!uploader.getNotUploadedItems().length">-->
<!--    <span class="glyphicon glyphicon-upload"></span><ng-container i18n="@@Approve Total">Approve Total</ng-container><br><ng-container i18n="@@Transcription">Transcription</ng-container><br><ng-container i18n="@@Charges of">Charges of </ng-container>{{ uploader.getTotalCharge() | currency:'USD':'symbol':'1.2-2' }} <br><ng-container i18n="@@and Upload">and Upload</ng-container>-->
<!--  </button>-->
<!--  <button *ngIf="uploader.queue.length > 1" type="button" class="btn btn-danger btn-s" style="margin-top: 5px; margin-bottom: 5px; margin-left: auto; margin-right: auto; display: block;" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">-->
<!--    <span class="glyphicon glyphicon-trash"></span> <ng-container i18n="@@Remove All">Remove All</ng-container>-->
<!--  </button>-->
</div>
