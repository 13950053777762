import {Component, Inject, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {Product} from "../../../../../model/Product";
import {Organization} from "../../../../../model/Organization";
import {OrganizationService} from "../../../../service-data/organization.service";
import {ProductService} from "../../../../service-data/product.service";
import {FacilityInventoryProductService} from "../../../../service-data/facility-inventory-product.service";
import {StartupService} from "../../../../service-ui/startup.service";
import {FacilityInventoryProduct} from "../../../../../model/FacilityInventoryProduct";

@Component({
  selector: 'app-add-facility-inventory-product-for-organization',
  templateUrl: './add-facility-inventory-product-for-organization.component.html',
  styleUrls: ['./add-facility-inventory-product-for-organization.component.css']
})
export class AddFacilityInventoryProductForOrganizationComponent implements OnInit {
  public products: Product[];
  public organizations: Organization[];
  public product: Product;

  public printnode_ID: number = 7;
  public inventoryManagementType: string = 'LEVEL';
  public reorderQuantity: number = 20;
  public reorderLevel: number = 10;

  public breakpoint: number; // Breakpoint observer code

  public addForm: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public organizationService: OrganizationService, public productService: ProductService, public facilityInventoryProductService: FacilityInventoryProductService, public startupService: StartupService, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<AddFacilityInventoryProductForOrganizationComponent>) {
    if ( data.organizations ) {
      this.organizations = data.organizations;
    }
    if ( data.products ) {
      this.products = data.products;
    }
    if ( data.product ) {
      this.product = data.product;
    }
  }

  public ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      printnode_ID: [this.printnode_ID, [Validators.required]],
      inventoryManagementType: [this.inventoryManagementType, [Validators.required]],
      reorderQuantity: [this.reorderQuantity, [Validators.required]],
      reorderLevel: [this.reorderLevel, [Validators.required]]
    });

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onAdd(): void {
    const objectToAdd = new FacilityInventoryProduct(undefined);
    objectToAdd.printnode_ID = this.addForm.controls.printnode_ID.value;
    objectToAdd.location = ''; // this.location;
    objectToAdd.shelf = ''; // this.shelf;
    objectToAdd.inventoryManagementType = this.addForm.controls.inventoryManagementType.value;
    objectToAdd.product_ID = this.product.ID;
    objectToAdd.title = this.product.title;
    objectToAdd.quantityOnHand = 0; // this.quantityOnHand;
    objectToAdd.quantityCommited = 0; // this.quantityCommited;
    objectToAdd.state = 'PREPARING'; // this.addForm.controls.state.value;
    objectToAdd.lastUpdate = new Date();
    objectToAdd.sequence = 1; // this.addForm.controls.sequence.value;
    objectToAdd.reorderQuantity = this.addForm.controls.reorderQuantity.value;
    objectToAdd.reorderLevel = this.addForm.controls.reorderLevel.value;
    objectToAdd.caseQuantity = 0; // this.addForm.controls.caseQuantity.value;

    this.facilityInventoryProductService.addItem(objectToAdd).subscribe( object => {
      console.log(`Added FacilityInventoryProduct productId: ${object.product_ID}`);
      this.product.facilityInventoryProducts.push(new FacilityInventoryProduct(object));
    });
    this.markAsDirty(this.addForm);
    this.dialogRef.close();
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
