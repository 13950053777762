import {DataObject} from "./DataObject";
import {ManufacturingTaskBucketPeriod} from "./ManufacturingTaskBucketPeriod";
import {ManufacturingResourceBucket} from "./ManufacturingResourceBucket";
import {ManufacturingProcess} from "./ManufacturingProcess";
import {CriteriaSet} from "./supporting/CriteriaSet";
import {ManufacturingResouceConsumer} from "./supporting/ManufacturingResourceConsumer";

export class ManufacturingTaskBucket implements DataObject {
  ID: number;
  name: string;
  manufacturingProcess_id: number;
  criteriaSet: CriteriaSet;
  manufacturingResourceBucketMapping: any;
  manufacturingResourceBucketOffsets: any;
  manufacturingProcess: ManufacturingProcess;
  manufacturingTaskBucketPeriods: ManufacturingTaskBucketPeriod[];
  printnode_ID: number;

  constructor (obj) {
    this.ID = 0;
    this.manufacturingTaskBucketPeriods = [];
    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if (obj) {
    //   for (const prop of Object.keys(obj)) {
    //     if ( prop === 'manufacturingTaskBucketPeriods') {
    //       for (const o of obj[prop]) {
    //         this.manufacturingTaskBucketPeriods.push(new ManufacturingTaskBucketPeriod(o));
    //       }
    //     }
    //     this[prop] = obj[prop];
    //   }
    // }
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }

  getUseTypes(): string[] {
    const list: string[] = [];
    Object.keys(this.manufacturingResourceBucketMapping).forEach(key => {
      list.push(key);
    });
    return list;
  }

  addManufacturingResourceBucket(useType: string, manufacturingResourceBucket: ManufacturingResourceBucket, periodsOffset: number) {
    if ( !this.manufacturingResourceBucketMapping ) {
      this.manufacturingResourceBucketMapping = {};
    }
    if ( !this.manufacturingResourceBucketMapping[useType] ) {
      this.manufacturingResourceBucketMapping[useType] = [];
    }
    if ( this.manufacturingResourceBucketMapping[useType].indexOf(manufacturingResourceBucket.ID) === -1 ) {
      this.manufacturingResourceBucketMapping[useType].push(manufacturingResourceBucket.ID);
    }

    if ( !this.manufacturingResourceBucketOffsets ) {
      this.manufacturingResourceBucketOffsets = {};
    }
    if ( this.manufacturingResourceBucketOffsets ) {
      this.manufacturingResourceBucketOffsets[useType + '_' + manufacturingResourceBucket.ID] = periodsOffset;
    }
  }

  removeManufacturingResourceBucket(useType: string, manufacturingResourceBucket: ManufacturingResourceBucket) {
    if ( !this.manufacturingResourceBucketMapping[useType] ) {
      this.manufacturingResourceBucketMapping[useType] = [];
    }
    if ( this.manufacturingResourceBucketMapping[useType].indexOf(manufacturingResourceBucket) === -1 ) {
      this.manufacturingResourceBucketMapping[useType].push(manufacturingResourceBucket);
    }
    if ( this.manufacturingResourceBucketOffsets ) {
      delete this.manufacturingResourceBucketOffsets[useType + '_' + manufacturingResourceBucket.ID];
    }
  }

  getManufacturingResourceBucketsByUseType(useType: string): ManufacturingResourceBucket[] {
    if ( this.manufacturingResourceBucketMapping[useType] ) {
      return this.manufacturingResourceBucketMapping[useType];
    }
    return [];
  }

  getManufacturingResourceBucketIds(): number[] {
    const list: number[] = [];
    for ( const key of Object.keys(this.manufacturingResourceBucketMapping)) {
      this.manufacturingResourceBucketMapping[key].forEach(manufacturingResourceBucket => {
//                if ( manufacturingResourceBucket instanceof ManufacturingResourceBucket ) {
        list.push(manufacturingResourceBucket);
        //              }
      });
    }
    return list;
  }

  getManufacturingResourceBucketPeriod (useType: string, manufacturingResourceBucketPeriodId: any) {
    return null;
  }
  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
      // TODO: Criteria??
    }
  }
}
