<router-outlet></router-outlet>
<!--<div i18n-dir="@@dir" dir="ltr" i18n-style="@@direction" style="direction: ltr">-->
  <!--<app-navigation></app-navigation>-->
  <!--<div class="login container">-->
    <!--<div class="row">-->
      <!--<div class="col-12" style="margin-top:15%">-->
        <!--<router-outlet></router-outlet>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->
