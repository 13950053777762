import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Draft} from "../../../model/Draft";

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.css']
})

export class ResourceComponent implements OnInit, OnChanges {
  @ViewChild('image', { static: true }) image;
  @ViewChild('coverPage') coverWrapper;
  @ViewChild('coverClippingRect') coverClipping;
  @ViewChild('coverBounds') coverMarkers;
  @ViewChild('coverLines') coverLines;
  @ViewChild('backCoverBounds') back;
  @ViewChild('spineCoverBounds') spine;
  @ViewChild('frontCoverBounds') front;
  @ViewChild('backCoverLine') backLine;
  @ViewChild('spineCoverLine') spineLine;
  @ViewChild('frontCoverLine') frontLine;

  @Input() draft: Draft;
  @Input() resourceId: number;
  @Input() verticalShift: number;
  @Input() horizontalShift: number;
  @Input() scale: number;
  @Input() pageNumber: number;
  @Input() dpi: number;
  @Input() orgId: number;
  @Input() bodyOrCover: string;
  @Input() bindingType: string;

  displayScale: number = 1.0;
  bleed: number = 15;
  trimWobble: number = 72 / 5;

  coverImageRipperURL: string = "/users/ripImage.jsp";

  Math = Math;

  constructor () {
  }

  ngOnInit() {
    this.refreshImagePosition();
    this.trimWobble = this.dpi / 5;
  }

  ngOnChanges() {
    this.refreshImagePosition();
  }

  refreshImagePositionDetails(cachedImage: any) {
    // START INITIAL HACK TO GET THINGS GOING
    this.image.nativeElement.style.display = "block";
    this.image.nativeElement.src = cachedImage.src;
    this.image.nativeElement.style.left = this.horizontalShift + 'px';
    this.image.nativeElement.style.top = this.verticalShift + 'px';
    // END INITIAL HACK TO GET THINGS GOING

    // START NEW COPY TO COMPUTE THINGS
    this.image.nativeElement.style.display = "block";

    let naturalWidth = cachedImage.naturalWidth;
    if ( !naturalWidth ) {
      naturalWidth = cachedImage.width;
    }
    let naturalHeight = cachedImage.naturalHeight;
    if ( !naturalHeight ) {
      naturalHeight = cachedImage.height;
    }

    // const clipWidth = ((this.draft.draftBody.pageSize.width)*2+(paperWeights[paperWeights.usedWeight].caliper*this.draft.draftBody.pages.numberOfPages/2*dpi) + this.trimWobble)*this.displayScale+2*this.bleed;
    // const clipHeight = ((this.draft.draftBody.pageSize.height + this.trimWobble)*this.displayScale.value)+2*this.bleed;
    const clipWidth = this.image.nativeElement.parentNode.clientWidth - 2; // - 2 for border thickness
    const clipHeight = this.image.nativeElement.parentNode.clientHeight - 2;

    const pageScreenWidth =  ( naturalWidth * this.displayScale * this.draft.draftCover.documentScale );
    if ( pageScreenWidth ) {
      this.image.nativeElement.style.width = pageScreenWidth + "px";
      const pageScreenHeight = ( naturalHeight * this.displayScale * this.draft.draftCover.documentScale );

      this.image.nativeElement.style.height = pageScreenHeight + "px";

      this.image.nativeElement.style.left = ( ( clipWidth - pageScreenWidth ) / 2 + this.draft.draftCover.horizontalShift * this.displayScale ) + "px";
      this.image.nativeElement.style.top = ( ( clipHeight - pageScreenHeight) / 2 - this.draft.draftCover.verticalShift * this.displayScale ) + "px";
    }
    this.refresh();
    // END NEW COPY TO COMPUTE THINGS

    // let naturalWidth = cachedImage.naturalWidth;
    // if ( !naturalWidth ) {
    //   naturalWidth = cachedImage.width;
    // }
    //
    // let naturalHeight = cachedImage.naturalHeight;
    // if ( !naturalHeight ) {
    //   naturalHeight = cachedImage.height;
    // }

    // var clipWidth = ((bookBody.pageSize.width)*2+(paperWeights[paperWeights.usedWeight].caliper*bookBody.pages.numberOfPages/2*dpi) + this.trimWobble)*bookBody.displayScale.value+2*this.bleed;
    // var clipHeight = ((bookBody.pageSize.height + this.trimWobble)*bookBody.displayScale.value)+2*this.bleed;
    // const clipWidth = this.image.nativeElement.parentNode.clientWidth - 2; // - 2 for border thickness
    // const clipHeight = this.image.nativeElement.parentNode.clientHeight - 2;

    // const pageScreenWidth = ( naturalWidth * this.displayScale * this.scale);
    // if ( pageScreenWidth ) {
    //   this.image.nativeElement.style.width = pageScreenWidth + 'px';
    //   const pageScreenHeight = ( naturalHeight * this.displayScale * this.scale);
    //
    //   this.image.nativeElement.style.height = pageScreenHeight + 'px';

      // this.image.nativeElement.style.left = ( ( clipWidth - pageScreenWidth ) / 2 + this.horizontalShift * this.displayScale ) + 'px';
      // this.image.nativeElement.style.top = ( ( clipHeight - pageScreenHeight ) / 2 - this.verticalShift * this.displayScale ) + 'px';

    // START INITIAL HACK TO GET THINGS GOING
    //   this.image.nativeElement.style.width = '436px';
    //   this.image.nativeElement.style.height = '297px';
    //   this.image.nativeElement.style.width = '100%';
    //   this.image.nativeElement.style.height = '100%';
    // END INITIAL HACK TO GET THINGS GOING
      // this.image.nativeElement.style.left = '0px';
      // this.image.nativeElement.style.top = '0px';
    //
    // }
  }

  refreshImagePosition() {
    if ( !this.resourceId ) {
      this.image.nativeElement.src = '';
      return;
    }

    const imageId = 'resource-' + this.resourceId + '-' + this.pageNumber + '-' + this.dpi;
    const imageUrl = 'http://app.snowfallpress.com/users/ripImage.jsp?color=true&resourceId=' + this.resourceId + '&dpi=' + this.dpi + '&pageNumbers=' + this.pageNumber + '&maxWidth=1584&maxHeight=936&authtoken=a339-5d3b-2192-4c04-b161-1dff-637b-44f2&source=' + this.orgId;

    let cachedImage: any = document.getElementById(imageId);
    if ( !cachedImage ) {
      const hiddenImagesElement: any = document.getElementById('hiddenImages');
      if ( hiddenImagesElement ) {
        const imageElement = document.createElement("img");
        imageElement.src = imageUrl;
        imageElement.id = imageId;
        hiddenImagesElement.appendChild(imageElement);
        cachedImage = imageElement;
      }
    }
    if ( cachedImage ) {
      if (cachedImage.complete ) {
        this.refreshImagePositionDetails(cachedImage);
      } else {
        cachedImage.addEventListener('load', _ => {
          this.refreshImagePositionDetails(cachedImage);
        });
        cachedImage.addEventListener('error', _ => {
          console.log(`Error loading image ${imageId}`);
        });
      }
    }
  }

  public refresh() {
    const cropHeight = this.draft.draftBody.height;
    const cropWidth = this.draft.draftBody.width;
    const spineWidth = this.draft.draftBody.spine;
    const borderWidth = this.trimWobble;


    this.coverMarkers.nativeElement.style.top = this.bleed + "px";
    this.coverMarkers.nativeElement.style.left = this.bleed + "px";
    this.coverMarkers.nativeElement.style.height = ( ( cropHeight + borderWidth ) * this.displayScale ) + "px";
    this.coverMarkers.nativeElement.style.width = ( ( cropWidth * 2 + spineWidth + borderWidth ) * this.displayScale ) + "px";

    this.coverLines.nativeElement.style.top = ( this.bleed + borderWidth * this.displayScale / 2 ) + "px";
    this.coverLines.nativeElement.style.left = this.bleed + "px";
    this.coverLines.nativeElement.style.height = ( ( cropHeight + borderWidth ) * this.displayScale ) + "px";
    this.coverLines.nativeElement.style.width = ( ( cropWidth * 2 + spineWidth + borderWidth ) * this.displayScale ) + "px";

    this.back.nativeElement.style.width = ( cropWidth - borderWidth ) * this.displayScale + "px";
    this.backLine.nativeElement.style.width = ( cropWidth * this.displayScale - 2 ) + "px";
    this.backLine.nativeElement.style.marginLeft = borderWidth * this.displayScale / 2 + "px";
    this.front.nativeElement.style.width = ( cropWidth - borderWidth ) * this.displayScale + "px";
    this.frontLine.nativeElement.style.width = ( cropWidth * this.displayScale - 2 ) + "px";
    this.back.nativeElement.style.height = ( cropHeight - borderWidth ) * this.displayScale + "px";
    this.backLine.nativeElement.style.height = ( cropHeight * this.displayScale - 2 ) + "px";
    this.front.nativeElement.style.height = ( cropHeight - borderWidth ) * this.displayScale + "px";
    this.frontLine.nativeElement.style.height = ( cropHeight * this.displayScale - 2 ) + "px";
    this.frontLine.nativeElement.style.marginRight = borderWidth * this.displayScale / 2 + "px";
    if (spineWidth - borderWidth < 0 ) {
      this.spine.nativeElement.style.width = "0px";
      this.spineLine.nativeElement.style.width = "0px";
    } else {
      this.spine.nativeElement.style.width = (spineWidth - borderWidth ) * this.displayScale + "px";
      this.spineLine.nativeElement.style.width = ( spineWidth * this.displayScale - 2 ) + "px";
    }
    this.spine.nativeElement.style.height = ( cropHeight - borderWidth ) * this.displayScale + "px";
    this.spineLine.nativeElement.style.height = ( cropHeight * this.displayScale - 2 ) + "px";

    /*this.back.nativeElement.style.top = -(borderWidth/2)*scale +"px";
          this.spine.nativeElement.style.top = -(borderWidth/2)*scale +"px";
          this.front.nativeElement.style.top = -(borderWidth/2)*scale +"px";

          this.back.nativeElement.style.left = -(borderWidth/2)*scale +"px";
          this.spine.nativeElement.style.left = (cropWidth)*scale+ "px";
          this.front.nativeElement.style.left = (cropWidth + spineWidth)*scale + "px";
          */

    const wrapWidth = ( cropWidth * 2 + spineWidth + borderWidth ) * this.displayScale + 2 * this.bleed;
    const wrapHeight = ( ( cropHeight + borderWidth ) * this.displayScale ) + 2 * this.bleed;
    this.coverWrapper.nativeElement.style.width = wrapWidth + "px";
    this.coverWrapper.nativeElement.style.height = wrapHeight + "px";
    // this.draft.draftCoverClipping.nativeElement.style.marginLeft = this.bleed+"px";
    // this.draft.draftCoverMarkers.nativeElement.style.marginLeft = this.bleed+"px";

    this.back.nativeElement.style.border = ( borderWidth * this.displayScale ) + "px" + " solid red";
    this.spine.nativeElement.style.border = ( borderWidth * this.displayScale ) + "px" + " solid red";
    this.front.nativeElement.style.border = ( borderWidth * this.displayScale ) + "px" + " solid red";
    /*this.backLine.nativeElement.style.border = (borderWidth*scale)+"px"+" solid red";
          this.spineLine.nativeElement.style.border = (borderWidth*scale)+"px"+" solid red";
          this.frontLine.nativeElement.style.border = (borderWidth*scale)+"px"+" solid red";*/
  }

}


