export class Subscription {
  public userId: string;
  public subscriptionLevel: SubscriptionLevel;
  public subscriptionStatus: SubscriptionStatus;
  public subscriptionPeriod: SubscriptionPeriod;
  public recurringStatus: string;
  public startDate: string;
  public paidUntilDate: string;
  private invalidToken: boolean = false;

  constructor( userId: string, token: string ) {
    this.subscriptionLevel = SubscriptionLevel.STARTER;
    this.subscriptionStatus = SubscriptionStatus.TRIAL;
    this.subscriptionPeriod = SubscriptionPeriod.ANNUAL;
    this.recurringStatus = 'INITIAL';
    this.userId = userId;
    const today = new Date();
    this.startDate = today.toISOString().substring(0, 10);
    this.paidUntilDate = this.addMonths(today, 1).toISOString().substring(0, 10);
    if ( token ) {
      token = atob(token);
      const tokenParts = token.split('_');

      if ( tokenParts.length === 7 ) {
        this.userId = tokenParts[0];
        if ( !this.userId.startsWith('cognito-') ) {
          this.invalidToken = true;
          this.userId = userId;
        }
        this.startDate = tokenParts[1];
        this.paidUntilDate = tokenParts[2];
        this.setSubscriptionLevel(tokenParts[3]);
        this.setSubscriptionPeriod(tokenParts[4]);
        this.setSubscriptionStatus(tokenParts[5]);
        this.setRecurringStatus(tokenParts[6]);
      }
    }
    // if (this.invalidToken ) {
    //   this.subscriptionLevel = SubscriptionLevel.STARTER;
    //   this.subscriptionStatus = SubscriptionStatus.TRIAL;
    //   this.subscriptionPeriod = SubscriptionPeriod.ANNUAL;
    //   this.recurringStatus = 'INITIAL';
    //   this.userId = userId;
    //   this.startDate = today.toISOString().substring(0, 10);
    //   this.paidUntilDate = this.addMonths(today, 1).toISOString().substring(0, 10);
    // }
  }
  init ( obj ) {
    if ( obj ) {
      if ( obj.userId ) {
        this.userId = this.userId;
      }
      if ( obj.recurringStatus ) {
        this.recurringStatus = this.recurringStatus;
      }
      if ( obj.startDate ) {
        this.startDate = this.startDate;
      }
      if ( obj.paidUntilDate ) {
        this.paidUntilDate = this.paidUntilDate;
      }
      if ( obj.subscriptionLevel ) {
        this.setSubscriptionLevel(obj.subscriptionLevel);
      }
      if ( obj.subscriptionStatus ) {
        this.setSubscriptionStatus(obj.subscriptionStatus);
      }
      if ( obj.subscriptionPeriod ) {
        this.setSubscriptionPeriod(obj.subscriptionPeriod);
      }
    }
  }

  setSubscriptionLevel(value: string) {
    switch ( value ) {
      case 'INITIAL': {
        this.subscriptionLevel = SubscriptionLevel.INITIAL;
        break;
      }
      case 'STARTER': {
        this.subscriptionLevel = SubscriptionLevel.STARTER;
        break;
      }
      case 'STANDARD': {
        this.subscriptionLevel = SubscriptionLevel.STANDARD;
        break;
      }
      case 'PLUS': {
        this.subscriptionLevel = SubscriptionLevel.PLUS;
        break;
      }
      case 'ECOMMERCE': {
        this.subscriptionLevel = SubscriptionLevel.ECOMMERCE;
        break;
      }
      case 'DESIGNER': {
        this.subscriptionLevel = SubscriptionLevel.DESIGNER;
        break;
      }
      default: {
        this.invalidToken = true;
      }
    }
  }

  setSubscriptionPeriod(value: string) {
    switch ( value) {
      case 'ANNUAL': {
        this.subscriptionPeriod = SubscriptionPeriod.ANNUAL;
        break;
      }
      case 'MONTHLY': {
        this.subscriptionPeriod = SubscriptionPeriod.MONTHLY;
        break;
      }
      default: {
        this.invalidToken = true;
      }
    }
  }

  setSubscriptionStatus(value: string) {
    switch ( value) {
      case 'CURRENT': {
        this.subscriptionStatus = SubscriptionStatus.CURRENT;
        break;
      }
      case 'EXPIRED': {
        this.subscriptionStatus = SubscriptionStatus.EXPIRED;
        break;
      }
      case 'TRIAL': {
        this.subscriptionStatus = SubscriptionStatus.TRIAL;
        break;
      }
      default: {
        this.invalidToken = true;
      }
    }
  }

  setRecurringStatus(value: string) {
    this.recurringStatus = value;
  }

  getRecurringStatus(): string {
    return this.recurringStatus;
  }

  getSubscriptionPeriod(): string {
    return this.subscriptionPeriod.toString();
  }
  getSubscriptionLevel(): string {
    return this.subscriptionLevel.toString();
  }

  getSubscriptionStatus(): string {
    return this.subscriptionStatus.toString();
  }


  setSubscriptionPeriodObject(subscriptionPeriod: SubscriptionPeriod) {
    this.subscriptionPeriod = subscriptionPeriod;
  }
  setSubscriptionLevelObject(subscriptionLevel: SubscriptionLevel) {
    this.subscriptionLevel = subscriptionLevel;
  }

  setSubscriptionStatusObject(subscriptionStatus: SubscriptionStatus) {
    this.subscriptionStatus = subscriptionStatus;
  }

  toToken(): string {
    return btoa(this.userId + '_' + this.startDate + '_' + this.paidUntilDate + '_' + this.subscriptionLevel.toString() + '_' + this.subscriptionPeriod.toString() + '_' + this.subscriptionStatus.toString() + '_' + this.recurringStatus);
  }

  isLeapYear(year) {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
  }

  getDaysInMonth(year, month) {
    return [31, (this.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  }

  addMonths(date, value) {
    const d = new Date(date),
    n = date.getDate();
    d.setDate(1);
    d.setMonth(d.getMonth() + value);
    d.setDate(Math.min(n, this.getDaysInMonth(d.getFullYear(), d.getMonth())));
    return d;
  }
}
export enum SubscriptionPeriod {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
}

export enum SubscriptionStatus {
  TRIAL = 'TRIAL',
  EXPIRED = 'EXPIRED',
  CURRENT = 'CURRENT',
}

export enum SubscriptionLevel {
  INITIAL = 'INITIAL',
  STARTER = 'STARTER',
  STANDARD = 'STANDARD',
  PLUS = 'PLUS',
  ECOMMERCE = 'ECOMMERCE',
  DESIGNER = 'DESIGNER'
}
