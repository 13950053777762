import { Component, OnInit } from '@angular/core';
import { User } from '../../../model/User';
import { NavbarService } from '../../service-ui/navbar.service';
import { LoggedInCallback} from "../../service-auth/cognito.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import {AdminService} from "../../service-data/admin.service";

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit, LoggedInCallback {

  selectedUser: User;
  users: User[];
  constructor(public router: Router, private route: ActivatedRoute, public startupService: StartupService, private adminService: AdminService, private navbarService: NavbarService) { }

  ngOnInit() {
    console.log("AppComponent: Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.navbarService.setNavBarState('admin-users', "");
      this.getUsers();
    }
  }

  onSelect(user: User): void {
    this.selectedUser = user;
  }

  createNewUser() {
    console.log('Clicked Create New Project');
  }
  getUsers(): void {
    const state = this.route.snapshot.paramMap.get('state');
    this.adminService.getUsers()
      .subscribe(users => this.users = users);
  }

  // add(name: string): void {
  //   name = name.trim();
  //   if (!name) { return; }
  //   this.userService.addUser({ name } as User)
  //     .subscribe(user => {
  //       this.users.push(user);
  //     });
  // }
  //
  // delete(user: User): void {
  //   this.users = this.users.filter(h => h !== user);
  //   this.userService.deleteUser(user).subscribe();
  // }

  goUserAccount(userId: string) {
    this.startupService.setUserId(userId);
    this.router.navigate([`/secure/user/${userId}`]);
  }
  goProjectUserAccount(id: string) {

  }
}
