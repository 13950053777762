import {Component, Input, OnInit} from '@angular/core';
import {StartupService} from "../../../service-ui/startup.service";
import {OrganizationInvoice} from "../../../../model/OrganizationInvoice";
import {OrganizationInvoiceService} from "../../../service-data/organization-invoice.service";
import {Facility} from "../../../../model/Facility";
import {catchError, map, tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {of} from "rxjs";

@Component({
  selector: 'app-organization-invoice-details',
  templateUrl: './organization-invoice-details.component.html',
  styleUrls: ['../organization-invoices.component.css'] // NOTE THIS IS FROM THE PARENT FOLDER
})
export class OrganizationInvoiceDetailsComponent implements OnInit {
  @Input() organizationInvoice: OrganizationInvoice;
  constructor(private http: HttpClient, private organizationInvoiceService: OrganizationInvoiceService, public startupService: StartupService) {

  }

  ngOnInit() {
    // Load the ManufacturingTaskBucketPeriod and the contained ManufacturingSets
    // this.printNodeOrderService.getItem('' + this.printNodeOrder.ID).subscribe(pno => {
    //   this.printNodeOrderWithItems = pno;
    //   this.changeItems['printNodeOrderId'] = this.printNodeOrderWithItems.ID;
    //   this.changeItems['orderDeliverToId'] = this.printNodeOrderWithItems.orderdeliverto_ID;
    //   this.changeItems.items = [];
    //   for ( const printNodeOrderItem of this.printNodeOrderWithItems.printNodeOrderItems ) {
    //     this.changeItems.items.push({
    //       type: 'NONE',
    //       printNodeOrderItemId: printNodeOrderItem.ID,
    //       orderItemId: printNodeOrderItem.orderitem_ID,
    //       quantity: printNodeOrderItem.quantity
    //     });
    //   }
    // });
  }

  changeNoteToCustomer () {
    this.organizationInvoiceService.updateItem(this.organizationInvoice).subscribe( changedOrganizationInvoice => {
      console.log(`Change Note To Customer ${this.organizationInvoice.noteToCustomer}`);
    });
  }

  changeStatus (value: string) {
    this.organizationInvoice.status = value;
    this.organizationInvoiceService.updateItem(this.organizationInvoice).subscribe( changedOrganizationInvoice => {
      console.log(`Change Status ${this.organizationInvoice.status}`);
    });
  }

  changeRecurring (value: string) {
    this.organizationInvoice.recurring = value;
    this.organizationInvoiceService.updateItem(this.organizationInvoice).subscribe( changedOrganizationInvoice => {
      console.log(`Change Status ${this.organizationInvoice.recurring}`);
    });
  }

  changeContestStatus (value: string) {
    this.organizationInvoice.contestStatus = value;
    this.organizationInvoiceService.updateItem(this.organizationInvoice).subscribe( changedOrganizationInvoice => {
      console.log(`Change Consest Status ${this.organizationInvoice.contestStatus}`);
    });
  }

  sendInvoice () {
    this.organizationInvoice.status = 'SENT';
    console.log(`SENT EMAIL TO CUSTOMER ${this.organizationInvoice.status}`);
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  downloadInvoiceHTMLFile () {
    this.organizationInvoiceService.getOrganizationInvoiceURL(String(this.organizationInvoice.invoiceNumber), 'en', 'detailed').subscribe( data => {
      const periodStart = new Date(this.organizationInvoice.periodStart);
      const periodEnd = new Date(this.organizationInvoice.periodEnd);
      const downloadFileName = this.organizationInvoice.invoiceNumber + '_' + this.organizationInvoice.org_ID + '_' + periodStart.getFullYear() + '_' + (periodStart.getMonth() + 1) + '_' + periodStart.getDate() + '_' + periodEnd.getFullYear() + '_' + (periodEnd.getMonth() + 1) + '_' + periodEnd.getDate() + '.html';
      // const downloadFileContents = this.getInvoiceHTMLFile();
      const blob = new Blob([data.toString()], {type: 'text/html'});
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = downloadFileName;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/html', a.download, a.href].join(':');
      e.initEvent('click', true, false);
      // e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    });
  }

  // downloadInvoiceHTMLFile () {
  //   this.organizationInvoiceService.getOrganizationInvoiceURL(String(this.organizationInvoice.invoiceNumber), 'en', 'detailed').subscribe( url => {
  //     this.getInvoiceHTMLFile(String(url)).subscribe( data => {
  //       const downloadFileName = this.organizationInvoice.invoiceNumber + '_' + this.organizationInvoice.periodStart.getFullYear() + '' + (this.organizationInvoice.periodStart.getMonth() +1 ) + '' + this.organizationInvoice.periodStart.getDate() + '_' + this.organizationInvoice.periodEnd.getFullYear() + '' + (this.organizationInvoice.periodEnd.getMonth() + 1 ) + '' + this.organizationInvoice.periodEnd.getDate() + '.html';
  //       // const downloadFileContents = this.getInvoiceHTMLFile();
  //       const blob = new Blob([data.toString()], {type: 'text/html'});
  //       if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //         window.navigator.msSaveOrOpenBlob(blob, downloadFileName);
  //       } else {
  //         const e = document.createEvent('MouseEvents');
  //         const a = document.createElement('a');
  //         a.download = downloadFileName;
  //         a.href = window.URL.createObjectURL(blob);
  //         a.dataset.downloadurl = ['text/html', a.download, a.href].join(':');
  //         e.initEvent('click', true, false);
  //         // e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  //         a.dispatchEvent(e);
  //         // window.URL.revokeObjectURL(url); // clean the url.createObjectURL resource
  //       }
  //     });
  //   });
  // }

  // getInvoiceHTMLFile(url: string): Observable<string> {
  //   return this.http.get<string>(url, this.startupService.getHttpOptions()).pipe(tap(data => {
  //     console.log("data from getInvoiceHTMLFile: " + data);
  //     return of(data);
  //   }), catchError((error: any): Observable<string> => {
  //     return of(error as string);
  //   }));
  // }
}
