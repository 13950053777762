import {TableFilter} from "./TableFilter";

export class DateTableFilter extends TableFilter {
  public value: Date = new Date();

  constructor (obj: any) {
    super(obj);
    if ( obj.value ) {
      this.value = new Date(obj.value);
    }
  }

  public match(data: any, filter: string): boolean {
    const value = this.getDateValue(data);
    if ( this.value >= value ) {
      return true;
    }
    return false;
  }
}
