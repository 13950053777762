import {ManufacturingStaticData} from "./supporting/ManufacturingStaticData";
import {Criteria} from "./supporting/Criteria";
import {DataObject} from "./DataObject";
import {Address} from "./Address";

export class Facility implements DataObject  {
  ID: number;
  name: string;
  friendlyName: string;
  network_ID: number;
  admin_ID: number;
  invoiceClientId: number;
  invoiceRecurringId: number;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  email: string;
  phone: string;
  toFirstName: string;
  toLastName: string;
  toAddress1: string;
  toAddress2: string;
  toCity: string;
  toState: string;
  toPostalCode: string;
  toCountry: string;
  toEmail: string;
  toPhone: string;
  currency: string;
  secret: string;
  resourceBuckets: string;
  engines: string;
  impositions: string;
  printLineData: string;
  shippingRateTables: string;
  carriers: string;
  shipping: string;
  tradabilityTariffCodes: string;
  tradabilityAnswers: string;
  handlesInventory: string;
  languageStrings: string;
  productionRate: string;
  lastUpdate: Date;
  invoiceCompany: string;
  manufacturingStaticData: ManufacturingStaticData;

  constructor ( obj ) {
    this.name = '';
    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    if (obj && obj.manufacturingStaticData ) {
      this.manufacturingStaticData = new ManufacturingStaticData(obj.manufacturingStaticData);
    }
  }
  public getId() {
    return this.ID;
  }

  public getOrderValue() {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }


  public getManufacturingStaticData(): ManufacturingStaticData {
        if ( ! ( this.manufacturingStaticData instanceof ManufacturingStaticData ) ) {
            this.manufacturingStaticData = new ManufacturingStaticData( this.manufacturingStaticData );
        }
        return this.manufacturingStaticData;
    }

    // stringifyManufacturingStaticData() {
    //     this.getManufacturingStaticData();
    //     this.manufacturingStaticData = JSON.stringify(this.manufacturingStaticDataObject);
    // }
  getAddress() {
    return new Address(this);
  }
}
