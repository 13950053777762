import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PrintNodeOrderItem} from "../../../../model/PrintNodeOrderItem";
import {FacilityInventoryProductEvent} from "../../../../model/FacilityInventoryProductEvent";

export class InventoryEvent {
  ID: number;
  createdTimestamp: Date;
  actualOrExpectedTimestamp: Date;
  source: string;
  type: string;
  status: string;
  quantity: number;
  balance: number;
  printNodeOrder: PrintNodeOrder;
  printNodeOrderItem: PrintNodeOrderItem;
  facilityInventoryProductEvent: FacilityInventoryProductEvent;

  constructor(ID: number, createdTimestamp: Date, actualOrExpectedTimestamp: Date, source: string, type: string, status: string, quantity: number, balance: number, printNodeOrder: PrintNodeOrder, printNodeOrderItem: PrintNodeOrderItem, facilityInventoryProductEvent: FacilityInventoryProductEvent) {
    this.ID = ID;
    this.actualOrExpectedTimestamp = actualOrExpectedTimestamp;
    this.createdTimestamp = createdTimestamp;
    this.source = source;
    this.type = type;
    this.status = status;
    this.quantity = quantity;
    this.balance = balance;
    this.printNodeOrder = printNodeOrder;
    this.printNodeOrderItem = printNodeOrderItem;
    this.facilityInventoryProductEvent = facilityInventoryProductEvent;
  }
}
