import {TableFilter} from "./TableFilter";

export class DateRangeTableFilter extends TableFilter {
  public startValue: Date = new Date();
  public endValue: Date = new Date();

  constructor (obj: any) {
    super(obj);
    if ( obj.startValue ) {
      this.startValue = new Date(obj.startValue);
    }
    if ( obj.endValue ) {
      this.endValue = new Date(obj.endValue);
    }
  }

  public match(data: any, filter: string): boolean {
    const value = this.getDateValue(data);
    if ( this.startValue <= value && this.endValue >= value ) {
      return true;
    }
    return false;
  }
}
