<mat-card appearance="outlined">
  <mat-card-content>
    <div>
      <h3></h3>
      <mat-form-field class="creditCardFullWidth">
        <mat-label i18n="@@User Id">User Id</mat-label>
        <input matInput type="text" name="expiry" [(ngModel)]="userId" required/>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="revokeProperty(userId, 'inventory')" i18n="@@Revoke Inventory Privileges">Revoke Inventory Privileges</button>
      <button mat-raised-button color="primary" (click)="grantProperty(userId, 'inventory')" i18n="@@Grant Inventory Privileges">Grant Inventory Privileges</button>
    </div>
    <div>
      <p>{{facility.ID}}</p>
      <p>{{facility.friendlyName}}</p>
      <p>{{facility.admin_ID}}</p>
      <p>{{facility.invoiceClientId}}</p>
      <p>{{facility.invoiceRecurringId}}</p>
      <p>{{facility.firstName}}</p>
      <p>{{facility.lastName}}</p>
      <p>{{facility.address1}}</p>
      <p>{{facility.address2}}</p>
      <p>{{facility.city}}</p>
      <p>{{facility.state}}</p>
      <p>{{facility.postalCode}}</p>
      <p>{{facility.country}}</p>
      <p>{{facility.email}}</p>
      <p>{{facility.phone}}</p>
      <p>{{facility.currency}}</p>
      <p>{{facility.secret}}</p>
      <p>{{facility.resourceBuckets}}</p>
      <p>{{facility.engines}}</p>
      <p>{{facility.impositions}}</p>
      <p>{{facility.printLineData}}</p>
      <p>{{facility.shippingRateTables}}</p>
      <p>{{facility.carriers}}</p>
      <p>{{facility.shipping}}</p>
      <p>{{facility.tradabilityTariffCodes}}</p>
      <p>{{facility.tradabilityAnswers}}</p>
      <p>{{facility.handlesInventory}}</p>
      <p>{{facility.languageStrings}}</p>
      <p>{{facility.productionRate}}</p>
      <p>{{facility.lastUpdate | date}}</p>
      <p>{{facility.invoiceCompany}}</p>
      <p>{{facility.manufacturingStaticData}}</p>
      <button mat-icon-button (click)="startupService.selectRole('facility', '' + facility.ID, facility.name)"><mat-icon>supervised_user_circle</mat-icon></button>
    </div>
  </mat-card-content>
</mat-card>
