import {Component, Inject, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../../pipe/language.pipe";
import {UserService} from "../../../../service-data/user.service";
import {Product} from "../../../../../model/Product";
import {FacilityInventoryProduct} from "../../../../../model/FacilityInventoryProduct";
import {FacilityInventoryProductService} from "../../../../service-data/facility-inventory-product.service";
import {Organization} from "../../../../../model/Organization";
import {OrganizationService} from "../../../../service-data/organization.service";
import {ProductService} from "../../../../service-data/product.service";
import {MatTableDataSource} from "@angular/material/table";
import {StartupService} from "../../../../service-ui/startup.service";

@Component({
  selector: 'app-edit-facility-inventory-product-settings',
  templateUrl: './edit-facility-inventory-product-settings-component.html',
  styleUrls: ['./edit-facility-inventory-product-settings-component.css']
})
export class EditFacilityInventoryProductSettingsComponent implements OnInit {
  public products: Product[];
  public organizations: Organization[];
  public facilityInventoryProduct: FacilityInventoryProduct;
  public breakpoint: number; // Breakpoint observer code
  public location: string = ``;
  public shelf: string = ``;
  public quantityOnHand: number = 0;
  public quantityCommited: number = 0;
  public state: string = 'PREPARING';
  public inventoryManagementType: string = 'LEVEL';
  public product_ID: number = 0;
  public formGroup: FormGroup;
  wasFormChanged = false;
  private sequence: string = '';
  private reorderQuantity: number = 20;
  private reorderLevel: number = 10;
  private caseQuantity: number = 0;
  private initialProductId: number;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public organizationService: OrganizationService, public productService: ProductService, public startupService: StartupService, public facilityInventoryProductService: FacilityInventoryProductService, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<EditFacilityInventoryProductSettingsComponent>) {
    this.facilityInventoryProduct = data.facilityInventoryProduct;
    this.products = data.products;
    for ( const product of this.products) {
      if ( product.ID === this.facilityInventoryProduct.product_ID ) {
        this.facilityInventoryProduct.product = product;
      }
    }
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      state: [this.facilityInventoryProduct.state, [Validators.required]],
      inventoryManagementType: [this.facilityInventoryProduct.inventoryManagementType, [Validators.required]],
      // product: [this.facilityInventoryProduct.product, [Validators.required]],
      sequence: [this.facilityInventoryProduct.sequence, [Validators.required]],
      reorderQuantity: [this.facilityInventoryProduct.reorderQuantity, [Validators.required]],
      reorderLevel: [this.facilityInventoryProduct.reorderLevel, [Validators.required]],
      location: [this.facilityInventoryProduct.location, [Validators.required]],
      shelf: [this.facilityInventoryProduct.shelf, [Validators.required]],
      caseQuantity: [this.facilityInventoryProduct.caseQuantity, [Validators.required]]
    });
    this.initialProductId = this.facilityInventoryProduct.product_ID;
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onSave(): void {
    if ( this.initialProductId !== this.facilityInventoryProduct.product_ID) {
      this.facilityInventoryProduct.productIds = this.facilityInventoryProduct.product_ID + ',' + this.facilityInventoryProduct.productIds;
    }

    const objectToUpdate = new FacilityInventoryProduct(undefined);
    objectToUpdate.ID = this.facilityInventoryProduct.ID;
    objectToUpdate.printnode_ID = this.facilityInventoryProduct.printnode_ID;
    objectToUpdate.product_ID = this.facilityInventoryProduct.product.ID;
    objectToUpdate.title = this.facilityInventoryProduct.product.title;
    objectToUpdate.quantityOnHand = this.facilityInventoryProduct.quantityOnHand;
    objectToUpdate.quantityCommited = this.facilityInventoryProduct.quantityCommited;
    objectToUpdate.lastUpdate = new Date();
    objectToUpdate.state = this.formGroup.controls.state.value;
    objectToUpdate.inventoryManagementType = this.formGroup.controls.inventoryManagementType.value;
    // objectToUpdate.product_ID = this.formGroup.controls.product.value.product_ID;
    objectToUpdate.sequence = this.formGroup.controls.sequence.value;
    objectToUpdate.reorderQuantity = this.formGroup.controls.reorderQuantity.value;
    objectToUpdate.reorderLevel = this.formGroup.controls.reorderLevel.value;
    objectToUpdate.caseQuantity = this.formGroup.controls.caseQuantity.value;
    objectToUpdate.location = this.formGroup.controls.location.value;
    objectToUpdate.shelf = this.formGroup.controls.shelf.value;

    this.facilityInventoryProductService.updateItem(objectToUpdate).subscribe( object => {
      console.log(`Updated FacilityInventoryProduct productId: ${object.product_ID}`);
    });
    this.markAsDirty(this.formGroup);
    this.dialogRef.close();
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
