import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../model/User";
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../../service-data/print-node-order.service";
import {StartupService} from "../../../service-ui/startup.service";
import {Product} from "../../../../model/Product";

@Component({
  selector: 'app-print-node-order-admin',
  templateUrl: './print-node-order-admin.component.html',
  styleUrls: ['../print-node-orders-admin.component.css'] // NOTE THIS IS FROM THE PARENT FOLDER
})
export class PrintNodeOrderAdminComponent implements OnInit {
  @Input() printNodeOrder: PrintNodeOrder;
  @Input() products: Product[];

  printNodeOrderWithItems: PrintNodeOrder;

  editItems: boolean = false;

  changeItems: any = {};

  constructor(private printNodeOrderService: PrintNodeOrderService, public startupService: StartupService) {

  }

  ngOnInit() {
    if ( this.printNodeOrder.printNodeOrderItems && this.printNodeOrder.printNodeOrderItems.length > 0 ) {
      this.printNodeOrderWithItems = this.printNodeOrder;
      this.changeItems['printNodeOrderId'] = this.printNodeOrderWithItems.ID;
      this.changeItems['orderDeliverToId'] = this.printNodeOrderWithItems.orderdeliverto_ID;
      this.changeItems.items = [];
      for ( const printNodeOrderItem of this.printNodeOrderWithItems.printNodeOrderItems ) {
        this.changeItems.items.push({
          type: 'NONE',
          printNodeOrderItemId: printNodeOrderItem.ID,
          orderItemId: printNodeOrderItem.orderitem_ID,
          quantity: printNodeOrderItem.quantity
        });
      }
    //     items : [
    //     {
    //       type: 'ADD',
    //       productId: 5443,
    //       bookId: 5273,
    //       quantity: 59
    //     },
    //     {
    //       type: 'UPDATE',
    //       printNodeOrderItemId: 2658,
    //       orderItemId: 2661,
    //       quantity: 60
    //     }
    //     ,
    //     {
    //       type: 'REMOVE',
    //       printNodeOrderItemId: 448502,
    //       orderItemId: 414899
    //     }
    //   ]
    // };
    } else {
      this.printNodeOrderService.getItem(this.printNodeOrder.ID).subscribe(pno => {
        this.printNodeOrderWithItems = pno;
        this.changeItems['printNodeOrderId'] = this.printNodeOrderWithItems.ID;
        this.changeItems['orderDeliverToId'] = this.printNodeOrderWithItems.orderdeliverto_ID;
        this.changeItems.items = [];
        for ( const printNodeOrderItem of this.printNodeOrderWithItems.printNodeOrderItems ) {
          this.changeItems.items.push({
            type: 'NONE',
            printNodeOrderItemId: printNodeOrderItem.ID,
            orderItemId: printNodeOrderItem.orderitem_ID,
            quantity: printNodeOrderItem.quantity
          });
        }
      });
    }
  }

  changeHoldValue(holdValue: string) {
    const printNodeOrderX = new PrintNodeOrder(undefined);
    for (const prop of Object.keys(this.printNodeOrder)) {
      if (this.printNodeOrder[prop] !== null && !(this.printNodeOrder[prop] instanceof Array) && (prop !== 'order_ID') && (typeof this.printNodeOrder[prop] === "string" || typeof this.printNodeOrder[prop] === "number")) {
        printNodeOrderX[prop] = this.printNodeOrder[prop];
      } else {
        console.log("Not updating PrintNodeOrder property [" + prop + "]");
      }
    }
    printNodeOrderX.hold = holdValue;
    printNodeOrderX.pickSheet = 'INITIAL';

    this.printNodeOrderService.updateItem(printNodeOrderX).subscribe( printNodeOrder => {
      console.log(`PrintNodeOrder hold value updated ${printNodeOrderX.ID} ${printNodeOrderX.hold}`);
    })
  }

  saveEdit() {
    for ( let i = this.changeItems.length - 1; i >= 0; i--) {
      if ( this.changeItems[i].added && ( this.changeItems[i].type != 'ADD' || !this.changeItems[i].quantity || this.changeItems[i].quantity <= 0 || !this.changeItems[i].bookId || !this.changeItems[i].productId ) ) {
        this.changeItems.splice(i, 1);
      } else {
        if (this.changeItems[i].type === 'NONE') {
          this.changeItems.splice(i, 1);
        } else {
          if (this.changeItems[i].type === 'UPDATE' && this.changeItems[i].quantity === this.printNodeOrderWithItems.printNodeOrderItems[i].quantity) {
            this.changeItems.splice(i, 1);
          }
        }
      }
    }

    this.printNodeOrderService.updatePrintNodeOrderItems(this.printNodeOrderWithItems).subscribe( printNodeOrder => {

    });
  }

  cancelEdit() {
    this.printNodeOrderWithItems = undefined;
    this.printNodeOrderService.getItem(this.printNodeOrder.ID).subscribe(pno => {
      this.printNodeOrderWithItems = pno;
    });

  }

  addChangeItem() {
    this.changeItems.items.push({type: 'ADD', quantity: 1, productId: 0, bookId: 0, added: true });
  }
}
