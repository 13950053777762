import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Project } from '../../../model/editor/Project';
import { EditorProjectService } from '../../service-data/editor/editor-project.service';
import { LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {ProductService} from "../../service-data/product.service";
import {Product} from "../../../model/Product";
import {Draft} from "../../../model/Draft";
import {DraftService} from "../../service-data/draft.service";
import {EventHandler} from "../../table/EventHandler";
import {Subject} from "rxjs";

@Component({
  selector: 'app-shelf',
  templateUrl: './shelf.component.html',
  styleUrls: ['./shelf.component.css']
})

export class ShelfComponent implements OnInit, OnDestroy, LoggedInCallback, RoleChangeListener {
  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  selectedProduct: Product;
  products: Product[];
  selectedDraft: Draft;
  drafts: Draft[];

  showLanguagesMenu = false;

  constructor(public router: Router, public startupService: StartupService, private productService: ProductService, private draftService: DraftService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.startupService.addRoleChangeListener(this);
    console.log("SecureHomeComponent: constructor");
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("AppComponent: Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Projects");
      this.startupService.logout();
    } else {
      this.getData();
    }
  }

  onSelectProduct(product: Product): void {
    this.selectedDraft = undefined;
    this.selectedProduct = product;
  }
  onSelectDraft(draft: Draft): void {
    this.selectedProduct = undefined;
    this.selectedDraft = draft;
  }

  createNewProduct(draft: Draft) {
    console.log('Clicked Create New Product');
  }

  getData(): void {
    this.productService.getItems()
      .subscribe(products => {
        this.products = products;
      });
    this.draftService.getItems()
      .subscribe(drafts => {
        this.drafts = drafts;
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

}
