import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NavbarService } from '../../service-ui/navbar.service';
import {CognitoUtil, LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import {Subscription, SubscriptionLevel, SubscriptionPeriod} from "../../../model/Subscription";
import {SubscriptionService} from "../../service-data/subscription.service";
import {Recurring} from "../../../model/cart/Recurring";
import { MatSidenav } from '@angular/material/sidenav';
import {Subject} from "rxjs";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnDestroy, OnInit, LoggedInCallback {
  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  onDestroySubject: Subject<boolean> = new Subject();

  subscription: Subscription;
  recurring: Recurring;
  paymentVerified: boolean = false;
  updateRecurringMessage: string = '';
  updateRecurringStatus: string = '';

  private _mobileQueryListener: () => void;

  constructor(public router: Router, public startupService: StartupService, private navbarService: NavbarService, public subscriptionService: SubscriptionService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    console.log("AppComponent: Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Account");
      // this.router.navigate(['/home/login']);
    } else {
      this.navbarService.setNavBarState('account', "");
      this.startupService.showUpdateRecurring = false;
      this.subscription = this.startupService.getSubscription();
      this.recurring = new Recurring( undefined );
    }
  }

  onSubmit() {

  }

  verifyPaymentInfo() {
    this.paymentVerified = this.recurring.isPaymentInfoReady();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }
}
