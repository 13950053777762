<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Issues">Issues</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Issues">Issues</span>
        </button>
      </span>
    </div>
<!--    <div>-->
<!--      <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
<!--    </div>-->
    <span class="app-toolbar-filler"></span>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="issues"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <div class="app-content board">
        <ng-container *ngFor="let service of notificationsService.services | keyvalue">
          <div *ngIf="service.value.loadingState !== 'INITIAL'" class="container" [style.background-color]="service.value.loadingState === 'INITIAL' ? 'yellow' : ( service.value.loadingState === 'LOADING' ? 'darkorange' : ( service.value.loadingState === 'LOADED' ? 'green': 'red' ) )">
            <h2>{{service.key.substring(0, service.key.length - 7) | languageString}}</h2>
            <div *ngIf="service.value.loadingState">{{service.value.loadingState | languageString}}&nbsp;</div>
            <div *ngIf="service.value.objects"><span i18n="@@Objects">Objects</span>&nbsp;{{service.value.objects.length}}&nbsp;<span style="font-size: x-small   " *ngIf="service.value.objectAttachRequests && getKeys(service.value.objectAttachRequests).length > 0">&nbsp;{{getKeys(service.value.objectAttachRequests).length}}</span></div>
            <div>
              <ng-container *ngFor="let objectAttachmentRequests of service.value.objectAttachRequests | keyvalue">
                <div *ngIf="expandAttachmentRequests">
                  Key of Attachment Requests: {{objectAttachmentRequests.key}}&nbsp;
                  <div *ngFor="let objectAttachmentRequest of objectAttachmentRequests.value">
                    {{objectAttachmentRequest.objectIdToAttachTo}}&nbsp;
                    {{objectAttachmentRequest.arrayPropertyToAddTo}}&nbsp;
                    {{objectAttachmentRequest.objectPropertyToSet}}&nbsp;
                    {{objectAttachmentRequest.objectToAttach.getId()}}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






