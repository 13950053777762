import {Item} from "./Item";
import {Project} from "../editor/Project";
import {ProjectProperties} from "../ProjectProperties";
import {TranscriptionItem} from "./TranscriptionItem";

export class Recurring {
  userId: string;
  accountId: number;
  verificationKey: string;
  email: string;
  emailConfirmation: string;
  phone: string;
  phoneCountry: string;
  cardNumber: string;
  cardNumberWithSpaces: string;
  csc: string;
  expMonth: number;
  expYear: number;
  expMonthYear: string;
  firstNamePayment: string;
  lastNamePayment: string;
  address1Payment: string;
  address2Payment: string;
  cityPayment: string;
  statePayment: string;
  postalCodePayment: string;
  countryPayment: string;
  language: string;


  constructor (obj) {
    this.userId = '';
    this.accountId = 0;
    this.verificationKey = '';
    this.email = '';
    this.emailConfirmation = '';
    this.phone = '';
    this.phoneCountry = '';
    this.cardNumber = '';
    this.csc = '';
    this.expMonth = 12;
    this.expYear = 2020;
    this.expMonthYear = '12 / 20';
    this.firstNamePayment = '';
    this.lastNamePayment = '';
    this.address1Payment = '';
    this.address2Payment = '';
    this.cityPayment = '';
    this.statePayment = '';
    this.postalCodePayment = '';
    this.countryPayment = 'US';
    this.language = 'en';
    if (obj) {
      for (const prop of Object.keys(obj)) {
        this[prop] = obj[prop];
      }
    }
  }

  isPaymentInfoReady(): boolean {
    if ( !this.expMonthYear || !( this.expMonthYear.length === 5 || this.expMonthYear.length === 7 || this.expMonthYear.length === 9 ) ) {
      return false;
    }
    this.cardNumber = this.cardNumberWithSpaces.split(' ').join('')
    this.expMonth = Number(this.expMonthYear.substring(0, 2));
    this.expYear = Number(this.expMonthYear.substring(5));
    if ( this.expYear < 100 ) {
      this.expYear += 2000;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.firstNamePayment, 1, 32)) {
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.lastNamePayment, 2, 32)) {
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.address1Payment, 1, 32)) {
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.address2Payment, 0, 32)) {
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.cityPayment, 2, 32)) {
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.statePayment, 2, 2)) {
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.postalCodePayment, 5, 16)) {
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.countryPayment, 2, 2)) {
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.csc, 3, 4)) {
      return false;
    }
    if (!this.isNotUndefinedAndValueRange(this.expMonth, 1, 12)) {
      return false;
    }
    if (!this.isNotUndefinedAndValueRange(this.expYear, 2018, 2030)) {
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.cardNumber, 15, 19)) {
      return false;
    }
    return this.validCardNumberWithCardNumber(this.cardNumber);
  }

  validCardNumber(): boolean {
    this.cardNumber = this.cardNumberWithSpaces.split(' ').join('');
    if ( this.validCardNumberWithCardNumber( this.cardNumber ) ) {
      return true;
    }
    return false;
  }

  validCardNumberWithCardNumber(number: string) {
    const regex = new RegExp("^[0-9]{16,19}$");
    if (!regex.test(number)) {
      return false;
    }
    return this.luhnCheck(number);
  }

  luhnCheck(val: string) {
    let sum = 0;
    for (let i = 0; i < val.length; i++) {
      let intVal = Number((val.substr(i, 1)));
      if (i % 2 === 0) {
        intVal *= 2;
        if (intVal > 9) {
          intVal = 1 + (intVal % 10);
        }
      }
      sum += intVal;
    }
    return (sum % 10) === 0;
  }

  isNotUndefinedAndValueRange(value: number, minValue: number, maxValue: number): boolean {
    if (value === undefined || value === null) {
      return false;
    }
    if ( value < minValue ) {
      return false;
    }
    if ( value > maxValue ) {
      return false;
    }
    return true;
  }
  isNotUndefinedAndMinimumLength(value: string, minLength: number, maxLength: number): boolean {
    if (value === undefined || value === null) {
      return false;
    }
    if ( value.length >= minLength ) {
      return true;
    }
    return false;
  }


  clear() {
    this.userId = '';
    this.accountId = 0;
    this.verificationKey = '';
    this.email = '';
    this.emailConfirmation = '';
    this.phone = '';
    this.phoneCountry = '';
    this.cardNumber = '';
    this.csc = '';
    this.expMonth = 12;
    this.expYear = 2020;
    this.firstNamePayment = '';
    this.lastNamePayment = '';
    this.address1Payment = '';
    this.address2Payment = '';
    this.cityPayment = '';
    this.statePayment = '';
    this.postalCodePayment = '';
    this.countryPayment = 'US';
  }
}

