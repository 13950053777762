import {Component, Input, OnInit} from '@angular/core';
import {OrderDeliverTo} from "../../../../model/OrderDeliverTo";
import {ValidationAddress, AddressValidationResult, AddressValidationService} from "../../../service-data/address-validation.service";
import {OrderDeliverToService} from "../../../service-data/order-deliver-to.service";

@Component({
  selector: 'app-address-issue',
  templateUrl: './address-issue.component.html',
  styleUrls: ['./address-issue.component.css']
})
export class AddressIssueComponent implements OnInit {
  @Input() orderDeliverTo: OrderDeliverTo;
  @Input() initialProcessing: boolean;
  addressValidationResult: AddressValidationResult;
  enterAnAlternativeAddress = false;
  alternativeAddress: ValidationAddress = new ValidationAddress(undefined);

  constructor(private addressValidationService: AddressValidationService, private orderDeliverToService: OrderDeliverToService) {
    this.enterAnAlternativeAddress = false;
  }

  ngOnInit() {
    this.enterAnAlternativeAddress = false;
  }
  validateAddress (orderDeliverTo: OrderDeliverTo | ValidationAddress) {
    const address = new ValidationAddress(orderDeliverTo);
    this.addressValidationService.validate(address).subscribe( addressValidationResult => {
      this.addressValidationResult = addressValidationResult;
    });
  }

  setEnterAnAlternativeAddress() {
    this.alternativeAddress = new ValidationAddress( this.orderDeliverTo );
    this.enterAnAlternativeAddress = true;
  }
  setCancelAlternativeAddress () {
    this.enterAnAlternativeAddress = false;
  }


  useThisAddress (address: ValidationAddress) {
    this.orderDeliverTo.address1 = address.address1;
    this.orderDeliverTo.address2 = address.address2;
    this.orderDeliverTo.address3 = address.address3;
    this.orderDeliverTo.address4 = address.address4;
    this.orderDeliverTo.city = address.city;
    this.orderDeliverTo.state = address.state;
    this.orderDeliverTo.postalcode = address.postalcode;
    this.orderDeliverTo.country = address.country;
    this.orderDeliverTo.addressValidationStatus = 'VALID';
    this.orderDeliverToService.updateOrderDeliverTo(this.orderDeliverTo).subscribe( orderDeliverTo => {
      // TODO: Make the PrintNodeOrder now that it is validated
      console.log("Saving a validated OrderDeliverTo");
    });
  }

  deleteFromOrder (orderDeliverTo: OrderDeliverTo) {
    console.log("Make this work");
    this.orderDeliverTo.addressValidationStatus = 'VALID';
    this.orderDeliverToService.deleteOrderDeliverTo(this.orderDeliverTo).subscribe( odt => {
      // TODO: Update the order weight etc.. and delete the order if there are no other OrderDeliverTos in the Order -- make sure to clean up any PrintNodeOrders if the address issue came about after the PrintNodeOrder was created
      console.log("Deleting an OrderDeliverTo from an order");
    });
  }

  processLater (orderDeliverTo: OrderDeliverTo) {
    orderDeliverTo.addressValidationStatus = 'INVALID';
    this.orderDeliverToService.updateOrderDeliverTo(this.orderDeliverTo).subscribe( odt => {
      console.log("Saving a validated OrderDeliverTo");
    });
  }

  useAddressAsShownInAlternativeAddress (orderDeliverTo: OrderDeliverTo, alternativeAddress: ValidationAddress) {
    orderDeliverTo.addressValidationStatus = 'VALID';
    orderDeliverTo.address1 = alternativeAddress.address1;
    orderDeliverTo.address2 = alternativeAddress.address2;
    orderDeliverTo.city = alternativeAddress.city;
    orderDeliverTo.state = alternativeAddress.state;
    orderDeliverTo.country = alternativeAddress.country;
    orderDeliverTo.postalcode = alternativeAddress.postalcode;
    this.orderDeliverToService.updateOrderDeliverTo(this.orderDeliverTo).subscribe( odt => {
      console.log("Saving a validated OrderDeliverTo");
    });
  }
}
