<div *ngIf="book" style="display:flex; height: calc( 100% - 65px ); width: 97%; flex-direction: row; padding: 25px;">
  <div style="flex-grow: 1; overflow-x: auto; overflow-y: auto;">
    <div *ngIf="displayCover">
      <div style="display: inline"><button (click)="editingDetails = ''; onDataChange();" mat-icon-button color="primary"><mat-icon i18n-title="@@Back to books" title="Back to books" color="primary">arrow_back_ios</mat-icon></button><h3 style="display: inline; padding-right: 40px;" i81n="@@Cover">Cover</h3><button style="display: inline" mat-icon-button color="primary" (click)="displayCover = !displayCover"><mat-icon>swap_horiz</mat-icon><span>&nbsp;</span><span *ngIf="!displayCover" i18n="@@Edit Cover">Edit Cover</span><span *ngIf="displayCover" i18n="@@Edit Interior">Edit Interior</span></button></div>
      <app-resource
        [book]="book"
        [resourceId]="book.cover.resource_ID"
        [verticalShift]="book.cover.verticalShift"
        [horizontalShift]="book.cover.horizontalShift"
        [scale]="book.cover.documentScale"
        [dpi]="72"
        [pageNumber]="1"
        [orgId]="book.org_ID"
        [bodyOrCover]="'COVER'"
        [bindingType]="book.bindingType">
      </app-resource>
    </div>
    <div *ngIf="!displayCover">
      <div style="display: inline"><button (click)="editingDetails = ''; onDataChange();" mat-icon-button color="primary"><mat-icon i18n-title="@@Back to books" title="Back to books" color="primary">arrow_back_ios</mat-icon></button><h3 style="display: inline; padding-right: 40px;" i81n="@@Interior Pages">Interior Pages</h3><button style="display: inline" mat-icon-button color="primary" (click)="displayCover = !displayCover"><mat-icon>swap_horiz</mat-icon><span>&nbsp;</span><span *ngIf="!displayCover" i18n="@@Edit Cover">Edit Cover</span><span *ngIf="displayCover" i18n="@@Edit Interior">Edit Interior</span></button></div>
      <app-project-book-body [book]="book" [body]="book.body"></app-project-book-body>
    </div>
  </div>
  <div style="flex-grow: 0; width: 400px;">
    <mat-form-field>
      <input matInput i18n-placeholder="@@Title" placeholder="Title" required maxlength="40" length="40" [(ngModel)]="book.title"  [ngModelOptions]="{standalone: true}">
    </mat-form-field>
    <mat-form-field>
      <input matInput i18n-placeholder="@@Sub Title" placeholder="Sub Title" required maxlength="40" length="40" [(ngModel)]="book.subtitle"  [ngModelOptions]="{standalone: true}">
    </mat-form-field>
    <mat-form-field>
      <input matInput i18n-placeholder="@@Author" placeholder="Author" required maxlength="40" length="40" [(ngModel)]="book.author"  [ngModelOptions]="{standalone: true}">
    </mat-form-field>
    <mat-form-field>
      <textarea matInput i18n-placeholder="@@Comment" placeholder="Comment" required [(ngModel)]="book.comment"  [ngModelOptions]="{standalone: true}"></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@Binding">Binding</mat-label>
      <mat-select [(ngModel)]="book.bindingType">
        <mat-option value="PERFECT" i18n="@@Perfect">Perfect</mat-option>
        <mat-option value="COIL" i18n="@@Coil">Coil</mat-option>
        <mat-option value="SADDLE_STITCH" i18n="@@Saddle Stitch">Saddle Stitch</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@Interior">Interior</mat-label>
      <mat-select [(ngModel)]="book.interiorType">
        <mat-option value="GRAY" i18n="@@Black and White">Black and White</mat-option>
        <mat-option value="COLOR" i18n="@@Color">Color</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input matInput i18n-placeholder="@@Width" placeholder="Width" required type="number" [ngModel]="book.body.width/72 | number:'.2'" (change)="book.body.width = $event.target.value * 72" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
    <mat-form-field>
      <input matInput i18n-placeholder="@@Height" placeholder="Height" required type="number" [ngModel]="book.body.height/72 | number:'.2'" (change)="book.body.height = $event.target.value * 72" [ngModelOptions]="{standalone: true}">
    </mat-form-field>

    <!-- COVER -->
    <ng-container *ngIf="displayCover">
      <mat-form-field>
        <input matInput i18n-placeholder="@@Scale" placeholder="Scale" required type="number" min="=0.1" max="1.5" [(ngModel)]="book.cover.documentScale"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-form-field>
        <input matInput i18n-placeholder="@@Vertical Shift" placeholder="Vertical Shift" required type="number" [(ngModel)]="book.cover.verticalShift"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-form-field>
        <input matInput i18n-placeholder="@@Horizontal Shift" placeholder="Horizontal Shift" required type="number" [(ngModel)]="book.cover.horizontalShift"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-form-field>
        <input matInput i18n-placeholder="@@Print Inside of Cover" placeholder="Print Inside of Cover" required type="number" [(ngModel)]="book.cover.barcodeSide"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
    </ng-container>

    <!-- BODY -->
    <ng-container *ngIf="!displayCover">
      <mat-form-field>
        <input matInput i18n-placeholder="@@Scale" placeholder="Scale" required type="number" min="=0.1" max="1.5" [(ngModel)]="book.body.documentScale"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-form-field>
        <input matInput i18n-placeholder="@@Left Vertical Shift" placeholder="Left Vertical Shift" required type="number" [(ngModel)]="book.body.leftVerticalShift"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-form-field>
        <input matInput i18n-placeholder="@@Left Horizontal Shift" placeholder="Left Horizontal Shift" required type="number" [(ngModel)]="book.body.leftHorizontalShift"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-form-field>
        <input matInput i18n-placeholder="@@Right Vertical Shift" placeholder="Right Vertical Shift" required type="number" [(ngModel)]="book.body.rightVerticalShift"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-form-field>
        <input matInput i18n-placeholder="@@Right Horizontal Shift" placeholder="Right Horizontal Shift" required type="number" [(ngModel)]="book.body.rightHorizontalShift"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
    </ng-container>
  </div>
</div>
