import {FacilityInventoryProduct} from "./FacilityInventoryProduct";

export class FacilityInventoryProductEvent {
  ID: number;
  facilityinventoryproduct_ID: number;
  printnode_ID: number;
  source: string; //     enum: ["ORDER", "CORRECTION", "REPLENISHMENT", "RETURN"],
  timestamp: Date;
  type: string; //     enum: ["ACTUAL", "PLAN", "PROJECTED"],
  printNodeOrderItem_ID: number | null;
  quantity: number;
  status: string;  //    enum: ["UNREALIZED", "VALID", "INITIAL"],
  lastUpdate: Date;
  facilityInventoryProduct: FacilityInventoryProduct;

  constructor( obj, facilityInventoryProduct: FacilityInventoryProduct ) {
    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        this[prop] = obj[prop];
      }
    }
    // this.facilityInventoryProduct =  facilityInventoryProduct;
  }

}
