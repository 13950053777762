<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Products">Products</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Products">Products</span>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
<!--    <div style="display:flex; width:100%">-->
<!--      <span>-->
<!--        <button mat-icon-button (click)="toggleCoverImages(); showCoverImages = !showCoverImages;" title="ShowAndHideCoverImagesToggleToolTip" i18n-title="@@ShowAndHideCoverImagesToggleToolTip"><mat-icon *ngIf="!showCoverImages">hide_image</mat-icon><mat-icon *ngIf="showCoverImages">image</mat-icon></button>-->
<!--      </span>-->
<!--    </div>-->
    <span class="app-toolbar-filler"></span>
<!--    <div>-->
<!--      <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
<!--    </div>-->
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="products"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <div class="app-content">
<!--          <button mat-icon-button (click)="search()" title="Search" i18n-title="@@Search"><mat-icon>search</mat-icon></button>-->
        <div class="results">
          <div class="container mat-elevation-z8">
<!--            <mat-table *ngIf="catalogs" #table [dataSource]="dataSource" matSort>-->
            <mat-table #table [dataSource]="dataSource" matSort>

              <!-- ProductId Column -->
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Product Id</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.ID}} </mat-cell>
              </ng-container>

              <!-- Title Column -->
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
              </ng-container>

              <!-- ShipmentId Column -->
              <ng-container matColumnDef="subtitle">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Subtitle</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.subtitle}} </mat-cell>
              </ng-container>

              <!-- Tag Column -->
              <ng-container matColumnDef="tag">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Tag</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.book ? element.book.tagId : ''}}</mat-cell>
              </ng-container>

              <!-- Cover Column -->
              <ng-container matColumnDef="coverImage">
                <mat-header-cell *matHeaderCellDef>Cover Image</mat-header-cell>
                <mat-cell *matCellDef="let element"><div [style.backgroundImage]="'url(' + getCoverImageURL(element, 1) + ')'" style="background-position: -160px -10px; min-width: 150px; min-height: 200px; background-size: cover;"></div></mat-cell>
              </ng-container>
              <!-- inventoryItems Column -->
              <ng-container matColumnDef="inventoryItems">
                <mat-header-cell *matHeaderCellDef>Inventory Items</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button (click)="manageMicroInventory(element)" title="Manage Micro Inventory Items" i18n-title="@@Manage Micro Inventory Items">
                    <mat-icon *ngIf="element.facilityInventoryProducts && element.facilityInventoryProducts.length > 0" [matBadge]="element.facilityInventoryProducts.length" matBadgeColor="primary" >layers</mat-icon>
                    <mat-icon *ngIf="!element.facilityInventoryProducts || element.facilityInventoryProducts.length === 0" [matBadge]="'+'" matBadgeColor="accent">layers</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <!-- Catalog Columns -->
              <ng-container matColumnDef="catalog0">
                <mat-header-cell *matHeaderCellDef><mat-icon>{{catalogs[0].iconName}}</mat-icon> Catalog</mat-header-cell>
                <mat-cell *matCellDef="let element"><mat-checkbox color="primary" [checked]="isInCatalog(0, element)" (change)="catalogChecked(0, element, $event.checked)"></mat-checkbox></mat-cell>
              </ng-container>

              <ng-container matColumnDef="catalog1">
                <mat-header-cell *matHeaderCellDef><mat-icon>{{catalogs[1].iconName}}</mat-icon> Catalog</mat-header-cell>
                <mat-cell *matCellDef="let element"><mat-checkbox color="primary" [checked]="isInCatalog(1, element)" (change)="catalogChecked(1, element, $event.checked)"></mat-checkbox></mat-cell>
              </ng-container>

              <ng-container matColumnDef="catalog2">
                <mat-header-cell *matHeaderCellDef><mat-icon>{{catalogs[2].iconName}}</mat-icon> Catalog</mat-header-cell>
                <mat-cell *matCellDef="let element"><mat-checkbox color="primary" [checked]="isInCatalog(2, element)" (change)="catalogChecked(2, element, $event.checked)"></mat-checkbox></mat-cell>
              </ng-container>

              <ng-container matColumnDef="catalog3">
                <mat-header-cell *matHeaderCellDef><mat-icon>{{catalogs[3].iconName}}</mat-icon> Catalog</mat-header-cell>
                <mat-cell *matCellDef="let element"><mat-checkbox color="primary" [checked]="isInCatalog(3, element)" (change)="catalogChecked(3, element, $event.checked)"></mat-checkbox></mat-cell>
              </ng-container>

              <ng-container matColumnDef="catalog4">
                <mat-header-cell *matHeaderCellDef><mat-icon>{{catalogs[4].iconName}}</mat-icon> Catalog</mat-header-cell>
                <mat-cell *matCellDef="let element"><mat-checkbox color="primary" [checked]="isInCatalog(4, element)" (change)="catalogChecked(4, element, $event.checked)"></mat-checkbox></mat-cell>
              </ng-container>

              <ng-container matColumnDef="catalog5">
                <mat-header-cell *matHeaderCellDef><mat-icon>{{catalogs[5].iconName}}</mat-icon> Catalog</mat-header-cell>
                <mat-cell *matCellDef="let element"><mat-checkbox color="primary" [checked]="isInCatalog(5, element)" (change)="catalogChecked(5, element, $event.checked)"></mat-checkbox></mat-cell>
              </ng-container>

              <ng-container matColumnDef="catalog6">
                <mat-header-cell *matHeaderCellDef><mat-icon>{{catalogs[6].iconName}}</mat-icon> Catalog</mat-header-cell>
                <mat-cell *matCellDef="let element"><mat-checkbox color="primary" [checked]="isInCatalog(6, element)" (change)="catalogChecked(6, element, $event.checked)"></mat-checkbox></mat-cell>
              </ng-container>

              <ng-container matColumnDef="catalog7">
                <mat-header-cell *matHeaderCellDef><mat-icon>{{catalogs[7].iconName}}</mat-icon> Catalog</mat-header-cell>
                <mat-cell *matCellDef="let element"><mat-checkbox color="primary" [checked]="isInCatalog(7, element)" (change)="catalogChecked(7, element, $event.checked)"></mat-checkbox></mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






