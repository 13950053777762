import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, Optional, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {NavbarService} from "../../service-ui/navbar.service";
import {StartupService} from "../../service-ui/startup.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {Subject} from "rxjs";

@Component({
    selector: 'app-secure',
    templateUrl: './secure-home.html',
    styleUrls: ['./secure-home.css']

})
export class SecureHomeComponent implements OnInit, OnDestroy /*, LoggedInCallback*/ {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor(private dialog: MatDialog, private _snackbar: MatSnackBar, public router: Router, public startupService: StartupService, private navbarService: NavbarService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    console.log("SecureHomeComponent: constructor");
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }
  //
  // openDialog() {
  //   const dialogRef = this.dialog.open(TestdialogComponent);
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.lastDialogResult = result;
  //   });
  // }
  //
  // showSnackbar() {
  //   this._snackbar.open('YUM SNACKS', 'CHEW');
  // }
}
