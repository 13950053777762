import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {Package} from "../../model/Package";
import {NotificationsService} from "./notifications.service";
import {AbstractLiveDataService} from "./abstract-live-data.service";

@Injectable()
export class PackageService extends AbstractLiveDataService<Package> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    notificationsService.setPackageService(this);
    notificationsService.startNotification();
  }

  makeObjectInstance(args: any): Package {
    const object = new Package(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "Package";
  }

  supportsParallel(): boolean {
    return true;
  }

  getURLEndPoint (): string {
    return "package";
  }

  /** PUT: print */
  print (pkg: Package, manufacturingSetId: number): Observable<Package> {
    const url = `${environment.apiBaseURL}package/print/${pkg.package_ID}/${manufacturingSetId}`;
    this.messageService.add(`Package print: pkg.package_ID=${pkg.package_ID}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(changedPackage => {
          const m = new Package(changedPackage);
          return m;
        }),
        catchError(this.handleErrorX<any>('setManufacturingProcess'))
      );
  }

  /** PUT: print */
  recordTrackingNumberShipped (pkg: Package): Observable<Package> {
    const url = `${environment.apiBaseURL}package/recordtrackingnumbershipped/${pkg.trackingnumber}`;
    this.messageService.add(`Package print: pkg.package_ID=${pkg.package_ID} pkg.trackingNumber=${pkg.trackingnumber}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(changedPackage => {
          const m = new Package(changedPackage);
          return m;
        }),
        catchError(this.handleErrorX<any>('markTrackingNumberShipped'))
      );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleErrorX<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getLinkData(): any[] {
    return [
      { action: 'Link', objectIdPropertyName: 'printNodeOrder_ID', serviceName: 'printNodeOrderService', arrayProperty: 'packages', objectProperty: 'printNodeOrder'},
      { action: 'AddUpdateArray', serviceName: 'orderItemToPackageService', arrayProperty: 'orderItemToPackages'},
    ];
  }

  // linkObject(newObject: Package, rawObject: Package) {
  //   if (newObject.printNodeOrder_ID) {
  //     this.notificationsService.addObjectAttachRequest('printNodeOrderService', new ObjectAttachRequest(newObject.printNodeOrder_ID, 'packages', 'printNodeOrder', newObject));
  //   }
  //   if (newObject.orderItemToPackages ) {
  //     for ( const orderItemToPackage of rawObject.orderItemToPackages ) {
  //       this.notificationsService.addUpdateLocalItem('orderItemToPackageService', orderItemToPackage);
  //     }
  //   }
  // }
  //
  // updateLinks(existingObject: Package, newObject: Package, rawObject: Package) {
  //
  // }

}
