import {Item} from "./Item";
import {Address} from "../Address";
import {Facility} from "../Facility";
import {ShippingItem} from "./ShippingItem";
import {ShippingPackage} from "./ShippingPackage";
import {PackageAndShippingComputationService} from "../../app/service-data/package-and-shipping-computation.service";
import {Cart} from "./Cart";
import {ShippingOption} from "./ShippingOption";

export class PrintNodeOrderOption {
  carrier: string;
  serviceLevel: string;
  packItemsInSeparateBoxes: boolean;
  useCaseQuantities: boolean;
  deliveryAddress: Address;
  facilityId: number;
  items: Item[] = [];
  shippingPackages: ShippingPackage[] = [];
  packageAndShippingComputationService: PackageAndShippingComputationService;
  computationStatus = 'DIRTY';
  computationVersion: number = 1;

  constructor (carrier: string, serviceLevel: string, deliveryAddress: Address, facilityId: number, packItemsInSeparateBoxes: boolean, useCaseQuantities: boolean, packageAndShippingComputationService: PackageAndShippingComputationService) {
    this.carrier = carrier;
    this.serviceLevel = serviceLevel;
    this.deliveryAddress = deliveryAddress;
    this.facilityId = facilityId;
    this.packItemsInSeparateBoxes = packItemsInSeparateBoxes;
    this.useCaseQuantities = useCaseQuantities;
    this.packageAndShippingComputationService = packageAndShippingComputationService;
  }

  addItem(item: Item) {
      this.items.push(item);
      this.computationStatus = 'DIRTY';
  }

  getShippingItems(): ShippingItem[] {
    const shippingItems: ShippingItem[] = [];
    for ( const item of this.items ) {
      shippingItems.push(new ShippingItem(item.getWidth(), item.getHeight(), item.getThickness(), item.getThickness() * item.quantity, item.getWeight(), item.quantity, item.getCaseQuantity()));
    }
    return shippingItems;
  }

  compute(cart: Cart) {
    if ( this.computationStatus  === 'DIRTY') {
      this.computationStatus = 'COMPUTING';
      this.computationVersion ++;
      this.shippingPackages = [];
      this.packageAndShippingComputationService.getPackages(this.carrier, this.serviceLevel, this.facilityId, this.getShippingItems(), this.packItemsInSeparateBoxes, this.useCaseQuantities, this.computationVersion).subscribe( shippingPackagesResponse => {
        if ( shippingPackagesResponse['status'] = 'SUCCESS' && shippingPackagesResponse && shippingPackagesResponse['packages'] && shippingPackagesResponse['packages'].length > 0 ) {
          if ( shippingPackagesResponse['computationVersion'] === this.computationVersion ) { // Only use the result if it is the current version
            this.shippingPackages = [];
            shippingPackagesResponse['packages'].forEach( incommingShippingPackage => {
              this.shippingPackages.push(new ShippingPackage(incommingShippingPackage.height, incommingShippingPackage.width, incommingShippingPackage.depth, incommingShippingPackage.weight, incommingShippingPackage.quantity, []));
            });
            cart.shippingStatus = 'READY'; // this is just here temporarily for debugging the results
            for ( const shippingPackage of this.shippingPackages ) {
              shippingPackage.computationStatus = 'COMPUTING';
              this.packageAndShippingComputationService.getShippingOptions(this.carrier, this.serviceLevel, this.facilityId, this.deliveryAddress, shippingPackage, this.computationVersion).subscribe( response => {
                if ( response['status'] === 'SUCCESS' ) {
                  const shippingOptions = [];
                  response['shippingOptions'].forEach( incommingShippingOption => {
                    shippingOptions.push(new ShippingOption(incommingShippingOption.carrier, incommingShippingOption.serviceLevel, incommingShippingOption.cost, incommingShippingOption.deliveryDays));
                  });
                  shippingPackage.setShippingOptions(shippingOptions);
                  shippingPackage.computationStatus = 'COMPLETE';
                  cart.checkComputationStatus();
                } else {
                  // Show that the response had an error rather than just waiting forever and doing nothing
                }
              });
            }
          } else {
            // Ignoring response because it is no longer the current set of items
          }
        } else {
          // Trouble
        }
      });
    } else {
      // Either COMPUTING and or COMPLETE
    }
  }

  checkComputationStatus(): string {
    if ( this.computationStatus === 'INITIAL') {
      return 'INITIAL';
    }
    if ( this.computationStatus === 'ERROR') {
      return 'ERROR';
    }
    let cs = '';
    this.shippingPackages.forEach( shippingPackage => {
      if ( shippingPackage.computationStatus === 'ERROR') {
        this.computationStatus = 'ERROR';
        return this.computationStatus;
      }
      if ( shippingPackage.computationStatus === 'COMPUTING' ) {
        cs = 'COMPUTING';
      }
    });
    if ( cs.length === 0) {
      this.computationStatus = 'COMPLETE';
    } else {
      this.computationStatus = 'COMPUTING';
    }
    return this.computationStatus;
  }


  getTotalPackages() {
    let totalPackages = 0;
    if ( this.computationStatus === 'COMPLETE') {
      for ( const shippingPackage of this.shippingPackages ) {
        totalPackages += shippingPackage.quantity;
      }
      return totalPackages;
    } else {
      return -1;
    }
  }

  getTotalPackingAndShippingCost(carrier: string, serviceLevel: string) {
    let totalCost = 0;
    if ( this.computationStatus === 'COMPLETE') {
      for ( const shippingPackage of this.shippingPackages ) {
        totalCost += shippingPackage.getCost(carrier, serviceLevel);
      }
      return totalCost;
    } else {
      return -1;
    }
  }
}

