import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-stacked-column',
  templateUrl: './stacked-column.component.html',
  styleUrls: ['./stacked-column.component.css']
})

export class StackedColumnComponent  {
  @Input() type: string;
  @Input() units: string;
  @Input() categories: any;
  @Input() data: any;
  @Input() colors: any;
  @Input() icons: any;
  @Input() total: number;
  @Input() totalHeight: number;
  @Input() filter: any;
  @Output() filterChange = new EventEmitter<any>();

  constructor() { }

  onDataChange() {
    this.filterChange.emit(this.filter);
  }

  filtered(category: string) {
    const key = this.type + '*' + category;
    if ( this.filter[key] ) {
      return true;
    }
    return false;
  }
  clicked(category: string) {
    // (click)=filter[category]="!filter[category]"
    const key = this.type + '*' + category;
    if ( this.filter[key] ) {
      delete this.filter[key];
    } else {
      this.filter[key] = true;
    }
  }
}
