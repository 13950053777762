import {Component, Input, OnInit} from '@angular/core';
import {StartupService} from "../../../service-ui/startup.service";
import {Interior} from "../../../../model/editor/Interior";
import {EditorProjectService} from "../../../service-data/editor/editor-project.service";
import {ThemeService} from "../../../service-data/theme.service";
import {Project} from "../../../../model/editor/Project";
import {InteriorTheme} from "../../../../model/editor/interior-theme/InteriorTheme";
import {environment} from "../../../../environments/environment";


@Component({
  selector: 'app-interior-tools-container',
  templateUrl: './interior-tools-container.component.html',
  styleUrls: ['./interior-tools-container.component.css']
})
export class InteriorToolsContainerComponent implements OnInit {
  @Input() interior: Interior;
  @Input() document: Document;
  showChapter = false;
  showCopyright = false;
  showTOC = false;

  trimSizes: Array<Object> = [
    {value: '5inX7in', name: '5.0inX7.0in', height: 7, width: 5 },
    {value: '5.5inX8.5in', name: '5.5inX8.5in', height: 8.5, width: 5.5 },
    {value: '6inX9in', name: '6.0inX9.0in', height: 9, width: 6 }
  ];

  constructor(public startupService: StartupService, public projectService: EditorProjectService, public themeService: ThemeService) { }

  ngOnInit() {
  }

  setInteriorThemeId(event: any) {
    const newInteriorTheme = this.themeService.getInteriorThemeFromName( event.target.value );
    this.projectService.getPublicCSS(environment.publicBucket, newInteriorTheme.id + 'css.css').then( cssString => {
      this.projectService.saveInteriorTheme(this.startupService.project, cssString).then( _ => {
        this.startupService.project.interior.interiorTheme = new InteriorTheme(newInteriorTheme);
        this.saveProject();
      });
    });
  }

  setTrimSize(event: any) {
    const project: Project = <Project> this.startupService.getProject();
    const widthAndHeight = event.target.value.split('X');
    project.width = widthAndHeight[0].substr(3);
    project.height = widthAndHeight[1];
    project.trimSize = event.target.value.substr(3);
    this.startupService.trimSizeChange++;
    this.saveProject();
  }

  saveProject() {
    // this.projectService.saveProject(this.startupService.project);
    this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
  }

}
