import {MediaMatcher} from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component, EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StartupService} from "../../../service-ui/startup.service";
import {LoggedInCallback} from "../../../service-auth/cognito.service";
import {Subject} from "rxjs";
import { DraftService } from '../../../service-data/draft.service';
import { Draft } from '../../../../model/Draft';

@Component({
  selector: 'app-project-draft',
  templateUrl: './project-draft.component.html',
  styleUrls: ['./project-draft.component.css']
})
export class ProjectDraftComponent implements OnDestroy, OnInit, LoggedInCallback {
  @ViewChild('placeHolderDraftName') placeHolderDraftName;
  @Input() draft: Draft;
  @Input() displayCover: boolean;
  @Input() editingDetails: string;
  @Output() editingDetailsChange = new EventEmitter<any>();

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  // draft: Draft;

  public renameDraft: boolean = false;
  sizeWidth: 0;


  constructor(public router: Router, public startupService: StartupService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, private draftService: DraftService, private route: ActivatedRoute, @Inject(LOCALE_ID) localeId: string) {
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  onDataChange() {
    this.editingDetailsChange.emit(this.editingDetails);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Account");
      this.router.navigate(['/home/login']);
    } else {
      this.startupService.touchLastUserActivity();
      // this.getDraft();
    }
  }

  onSubmit() {

  }
  // getDraft() {
  //   this.startupService.touchLastUserActivity();
  //   const draftId = this.route.snapshot.paramMap.get('id');
  //   this.draftService.getDraft(draftId).subscribe( draft => {
  //     this.draft = draft;
  //   }, err => {
  //     this.router.navigate(['/home/login']);
  //   });
  // }

  onResize(event: any) {
    console.log("onresize " + event);
    const w = event.target.innerWidth;
    this.sizeWidth = w;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  isDraftNameUsed(): boolean {
    if ( !this.placeHolderDraftName || !this.placeHolderDraftName.nativeElement || !this.placeHolderDraftName.nativeElement.value ) {
      return true;
    }
    const newDraftName = this.placeHolderDraftName.nativeElement.value.trim();
    for ( const draft of this.startupService.drafts ) {
      if ( newDraftName === draft.title ) {
        return true;
      }
    }
    return false;
  }
  saveDraftValue() {
    console.log('Save Draft Name' + this.placeHolderDraftName.nativeElement.value);
    this.draft.title = this.placeHolderDraftName.nativeElement.value;
    this.draftService.updateItem(this.draft);
    // this.projectService.saveProjectImmediately(this.project).then( proj => {
    //   console.log('Updated project name');
    // });
  }
  cancelProjectValue() {
    this.placeHolderDraftName.nativeElement.value = this.draft.title;
  }

}
