import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { Item } from '../../../model/cart/Item';
import {Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import { MatDialog } from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../pipe/language.pipe";
import {ProductService} from "../../service-data/product.service";
import {Product} from "../../../model/Product";
import {BookService} from "../../service-data/book.service";
import {ProjectProperties} from "../../../model/ProjectProperties";
import {Draft} from "../../../model/Draft";

@Component({
  selector: 'app-product-thumbnail',
  templateUrl: './product-thumbnail.component.html',
  styleUrls: ['./product-thumbnail.component.css']
})
export class ProductThumbnailComponent implements OnInit, OnChanges {
  @ViewChild('productCoverImage', { static: true }) productCoverImage;
  @Input() product: Product;
  @Input() items: any[];
  @Output() selectedItem = new EventEmitter<Product | Draft>();
  @ViewChild('placeHolderProductTitle') placeHolderProductTitle;
  resourceURL;
  displayScale = 1.0;

  Math = Math;
  // public renameProduct`: boolean = false;
  imageLoaded: boolean = false;
  renameProduct: any;

  constructor(public router: Router, public startupService: StartupService, private bookService: BookService, private productService: ProductService, public dialog: MatDialog) {
    // this.refreshImagePosition();
  }

  ngOnInit() {
    // this.refreshImagePosition();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('Change ', changes);
    // this.refreshImagePosition();
  }

  viewProduct (product: Product): void {
    this.selectedItem.emit(product);
  }

  deleteProduct (product: Product): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        name: product.title,
        title: LanguagePipe.get('Delete', 'compressSpaces') + ' ' + product.title + LanguagePipe.get('?', 'compressSpaces'),
        body: '', // LanguagePipe.get('Delete', 'compressSpaces') + ' ' + product.name + LanguagePipe.get('?', 'compressSpaces'),
        noButton: LanguagePipe.get('Cancel', 'compressSpaces'),
        yesButton: LanguagePipe.get('Delete', 'compressSpaces') + ' ' + product.title,
        returnValue: 'ok'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed. result[' + result + ']');
      if ( result && result === 'ok' ) {
        // this.productService.deleteProduct(product).then( proj => {
        //   console.log('Deleted a product');
        // });
      }
    });

  }

  addToCart(product: Product) {
    this.startupService.getCart().addProductItem(product, 1);
    // this.productService.get.getProjectProperties(project).then( projectProperties => {
    //   const item: Item = this.startupService.getCart().add(projectProperties as ProjectProperties);
    //   // item.setProductProperties(projectProperties as ProductProperties);
    // });
  }

  // refreshImagePositionDetails(cachedImage: any) {
  //   if ( !this.productCoverImage ) {
  //     return;
  //   }
  //   if ( !this.productCoverImage.nativeElement.parentNode ) {
  //     return;
  //   }
  //   if ( !this.productCoverImage.nativeElement.style ) {
  //     this.productCoverImage.nativeElement.style = {};
  //   }
  //   this.productCoverImage.nativeElement.style.display = "block";
  //   this.productCoverImage.nativeElement.src = cachedImage.src;
  //   this.imageLoaded = true;
  //
  //   let naturalWidth = cachedImage.naturalWidth;
  //   if ( !naturalWidth ) {
  //     naturalWidth = cachedImage.width;
  //   }
  //
  //   let naturalHeight = cachedImage.naturalHeight;
  //   if ( !naturalHeight ) {
  //     naturalHeight = cachedImage.height;
  //   }
  //
  //   // var clipWidth = ((bookBody.pageSize.width)*2+(paperWeights[paperWeights.usedWeight].caliper*bookBody.pages.numberOfPages/2*dpi) + this.trimWobble)*bookBody.displayScale.value+2*this.bleed;
  //   // var clipHeight = ((bookBody.pageSize.height + this.trimWobble)*bookBody.displayScale.value)+2*this.bleed;
  //   const clipWidth = this.productCoverImage.nativeElement.parentNode.clientWidth - 2; // - 2 for border thickness
  //   const clipHeight = this.productCoverImage.nativeElement.parentNode.clientHeight - 2;
  //
  //   let pageScreenWidth = ( naturalWidth * this.displayScale * this.product.book.cover.documentScale);
  //   if ( pageScreenWidth ) {
  //     pageScreenWidth = pageScreenWidth / 2; // TODO: This is clearly an aproximation
  //     this.productCoverImage.nativeElement.style.width = pageScreenWidth + 'px';
  //     const pageScreenHeight = ( naturalHeight * this.displayScale * this.product.book.cover.documentScale);
  //
  //     this.productCoverImage.nativeElement.style.height = pageScreenHeight + 'px';
  //
  //     this.productCoverImage.nativeElement.style.left = ( ( clipWidth - pageScreenWidth ) / 2 + this.product.book.cover.horizontalShift * this.displayScale ) + 'px';
  //     this.productCoverImage.nativeElement.style.top = ( ( clipHeight - pageScreenHeight ) / 2 - this.product.book.cover.verticalShift * this.displayScale ) + 'px';
  //     this.productCoverImage.nativeElement.style.width = '436px';
  //     this.productCoverImage.nativeElement.style.height = '297px';
  //     this.productCoverImage.nativeElement.style.left = '-230px';
  //     this.productCoverImage.nativeElement.style.top = '0px';
  //   }
  // }
  //
  // refreshImagePosition() {
  //   if ( !this.product ) {
  //     return;
  //   }
  //   let cachedImage: any = document.getElementById('resource-' + this.product.book.cover.resource_ID);
  //   if ( !cachedImage ) {
  //     const hiddenImagesElement: any = document.getElementById('hiddenImages');
  //     if ( hiddenImagesElement ) {
  //       console.log("Image that should have been loaded was not found in CanvasManager -- Making a element to get the image from.");
  //       const imageElement = document.createElement("img");
  //       console.log(`Getting image for ProductOrgId: ${this.product.book.org_ID } BookOrgId: ${this.product.book.org_ID } resourceId: ${this.product.book.cover.resource_ID} 'COLOR' 72 1`);
  //       this.bookService.getImageURL(this.product.book.ID, 'COVER', 72, 1).subscribe( imageURL => {
  //         if ( imageURL ) {
  //           console.log(`Getting image for resourceId: ${this.product.book.cover.resource_ID} 'COLOR' 72 1 ${imageURL}`);
  //           imageElement.src = imageURL;
  //           imageElement.id = 'resource-' + this.product.book.cover.resource_ID;
  //           hiddenImagesElement.appendChild(imageElement);
  //           cachedImage = imageElement;
  //           if (cachedImage.complete ) {
  //             this.refreshImagePositionDetails(cachedImage);
  //           } else {
  //             cachedImage.addEventListener('load', _ => {
  //               this.refreshImagePositionDetails(cachedImage);
  //             });
  //             cachedImage.addEventListener('error', _ => {
  //               console.log(`Error loading image ${'resource-' + this.product.book.cover.resource_ID}`);
  //             });
  //           }
  //         }
  //       });
  //     } else {
  //       console.log("Image that should have been loaded was not found in CanvasManager");
  //     }
  //   }
  //   if ( cachedImage ) {
  //     if (cachedImage.complete ) {
  //       this.refreshImagePositionDetails(cachedImage);
  //     } else {
  //       cachedImage.addEventListener('load', _ => {
  //         this.refreshImagePositionDetails(cachedImage);
  //       });
  //       cachedImage.addEventListener('error', _ => {
  //         console.log(`Error loading image ${'resource-' + this.product.book.cover.resource_ID}`);
  //       });
  //     }
  //   }
  // }
  isProductTitleUsed(): boolean {
    if ( !this.placeHolderProductTitle || !this.placeHolderProductTitle.nativeElement || !this.placeHolderProductTitle.nativeElement.value ) {
      return true;
    }
    const newProductName = this.placeHolderProductTitle.nativeElement.value.trim();
    for ( const item of this.items ) {
      if ( item['type'] === 'PRODUCT') {
        const product = item as Product;
        if ( newProductName === product.title ) {
          return true;
        }
      }
    }
    return false;
  }
  saveProductTitle() {
    console.log('Save Product Name' + this.placeHolderProductTitle.nativeElement.value);
    this.product.title = this.placeHolderProductTitle.nativeElement.value;
    // this.productService.saveProductImmediately(this.product).then( proj => {
    //   console.log('Updated product name');
    // });
  }
  cancelProductTitle() {
    this.placeHolderProductTitle.nativeElement.value = this.product.title;
  }

}
