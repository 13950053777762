import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatTable} from "@angular/material/table";
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {ServiceProviderService} from "../../service-data/service-provider.service";
import {ServiceProvider} from "../../../model/ServiceProvider";
import {AddUserComponent} from "../users/add-user/add-user.component";
import {MatDialog} from "@angular/material/dialog";
import {AddServiceProviderComponent} from "./add-service-provider/add-service-provider.component";
import {Subject} from "rxjs";

@Component({
  selector: 'app-service-providers',
  templateUrl: './service-providers.component.html',
  styleUrls: ['./service-providers.component.css']
})

export class ServiceProvidersComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {
  columns = [
     { property: 'ID',                         columnDef: 'ID',                         header: 'ID',                 sort: true,  type: 'number', filterType: 'Numeric',  style: 'display: flex; justify-content: space-around; text-align: right',  cell: (element: any) => `${element.ID}` },
     { property: 'name',                       columnDef: 'name',                       header: 'Name',               sort: true,  type: 'string', filterType: 'String',        style: 'text-align: right',  cell: (element: any) => `${element.name}` },
     { property: 'admin_ID',                     columnDef: 'admin_ID',               header: 'Admin Id',       sort: true,  type: 'number', filterType: 'Numeric',  style: 'text-align: right',  cell: (element: any) => `${element.admin_ID}` },
     { property: 'adminOrg_ID',                     columnDef: 'adminOrg_ID',               header: 'Admin Organization Id',       sort: true,  type: 'number', filterType: 'Numeric',  style: 'text-align: right',  cell: (element: any) => `${element.adminOrg_ID}` },
     { property: 'domain',                     columnDef: 'domain',               header: 'Domain',       sort: true,  type: 'string', filterType: 'String',  style: 'text-align: right',  cell: (element: any) => `${element.domain}` },
     // { property: 'administrationOrganization.name', columnDef: 'administrationOrganization', header: 'Admin Organization', sort: true,  type: 'string', filterType: 'String',  style: 'text-align: right',  cell: (element: any) => `${element.administrationOrganization.name}` },
     // { property: 'serviceProvider.Name',       columnDef: 'serviceProviderName',        header: 'Service Provider',   sort: true,  type: 'string', filterType: 'String',  style: 'text-align: right',  cell: (element: any) => `${element.serviceProvider.Name}` },
     // { property: 'marginShare',                columnDef: 'marginShare',                header: 'Margin Share',       sort: true,  type: 'number', filterType: 'NumericRange',  style: 'text-align: right',  cell: (element: any) => `${element.marginShare}` },
     // { property: 'fixedShare',                 columnDef: 'fixedShare',                 header: 'Fixed Share',        sort: true,  type: 'number', filterType: 'NumericRange',  style: 'text-align: right',  cell: (element: any) => `${element.fixedShare}` }
    // // { property: 'active',            columnDef: 'active',       header: 'Active',       sort: true,  type: 'boolean',    filterType: 'Boolean',      style: 'text-align: right',  cell: (element: any) => `${element.active}` },
    // // { property: 'book.ID',           columnDef: 'previewCover', header: 'Preview',      sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.book.ID}`,  buttonColor: 'primary', icon: 'system_update',  clickAction: 'redirectToDelete'},
    // // { property: 'printNodeOrder.ID', columnDef: 'packButton',   header: 'Pack',         sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'},
    // { property: 'ID',                columnDef: 'resendInvite', header: 'Invite',       sort: true,  type: 'iconButton', filterType: 'Empty',        tyle: 'text-align: right',  cell: (element: any) => `${element.ID}`,  buttonColor: 'primary', icon: 'system_update',  clickAction: 'invite'},
    // { property: 'ID',                columnDef: 'delete',       header: 'Delete',       sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'}
  ];

  eventHandler: EventHandler;

   public dataSource = new MatTableDataSource<ServiceProvider>();
  public renderedData: ServiceProvider[];
  tableFilterManager: TableFilterManager<ServiceProvider> = new TableFilterManager(this.dataSource);

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor(public router: Router, public startupService: StartupService, private serviceProviderService: ServiceProviderService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public dialog: MatDialog) {
    this.startupService.addRoleChangeListener(this);
    this.startupService.addRoleChangeListener(this);
    // this.startupService.touchLastServiceProviderActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the serviceProvider is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      // if ( d && d.length > 0 ) {
      //   this.renderedData = d;
      // }
      this.renderedData = this.dataSource.data;
    });

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: ServiceProvider, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
    this.dataSource.paginator = this.paginator;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  // onSelect(printNodeOrderItem: PrintNodeOrderItem): void {
  //   this.selectedPrintNodeOrderItem = printNodeOrderItem;
  //   if ( this.selectedPrintNodeOrderItem ) {
  //     this.selectedPrintNodeOrder = this.selectedPrintNodeOrderItem.printNodeOrder;
  //   }
  // }

  getData(): void {
    // this.dataSource.data = [];
    this.serviceProviderService.getServiceProviders()
      .subscribe(serviceProviders => {
        // for (const serviceProvider of serviceProviders) {
        //   // this.dataSource.data.push(serviceProvider);
        // }
        this.dataSource.filter = '' + Math.random();
        this.dataSource.data = serviceProviders;
        // this.table.renderRows();
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  public openAddDialog (): void {
    const dialogRef = this.dialog.open(AddServiceProviderComponent,   {
      width: '640px', disableClose: false
    });
  }

}
