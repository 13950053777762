import {Item} from "./Item";
import {Draft} from "../Draft";

export class DraftItem extends Item {
  private base: Draft;

  constructor( draft: Draft ) {
    super();
    this.base = draft;
  }

  getBase(): any {
    return this.base;
  }

  getBindingType(): string {
    return this.base.bindingType;
  }

  getCost(): number {
    return 0;
  }

  getCoverImage(): string {
    return "";
  }

  getHeight(): number {
    if ( this.base.draftBody ) {
      return this.base.draftBody.height / 72;
    }
    return 0;
  }

  getId(): number {
    return this.base.getId();
  }

  getInteriorType(): string {
    return this.base.interiorType;
  }

  getLaminateType(): string {
    return this.base.draftCover.laminateType;
  }

  getPages(): number {
    return this.base.draftBody.numPages;
  }

  getPaperType(): number {
    return this.base.draftBody.paperType_ID;
  }

  getTitle(): string {
    return this.base.title;
  }

  getWeight(): number {
    return this.base.weight;
  }

  getWidth(): number {
    if ( this.base.draftBody ) {
      return this.base.draftBody.width / 72;
    }
    return 0;
  }
  getThickness(): number {
    return this.base.thickness;
  }
  getCaseQuantity(): number {
    return 0;
  }
  getLastUpdate(): Date {
    return this.base.lastUpdate;
  }
  getAvailableInventory(): any[] {
    return [];
  }

}

