import { Injectable } from '@angular/core';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {OrderItem} from "../../model/OrderItem";
import {NotificationsService} from "./notifications.service";
import {AbstractLiveDataService} from "./abstract-live-data.service";

@Injectable()
export class OrderItemService extends AbstractLiveDataService<OrderItem> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    notificationsService.setOrderItemService(this);
    notificationsService.startNotification();
  }

  makeObjectInstance(args: any): OrderItem {
    const object = new OrderItem(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "OrderItem";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "orderitem";
  }

  getLinkData(): any[] {
    return [
      { action: 'AddUpdateArray', serviceName: 'printNodeOrderItemService', arrayProperty: 'printNodeOrderItems'},
      { action: 'AddUpdateArray', serviceName: 'orderItemToPackageService', arrayProperty: 'orderItemToPackages'},
      { action: 'Link', objectIdPropertyName: 'product_ID', serviceName: 'productService', arrayProperty: 'orderItems', objectProperty: 'product'},
      { action: 'Link', objectIdPropertyName: 'orderdeliverto_ID', serviceName: 'orderDeliverToService', arrayProperty: 'orderItems', objectProperty: 'orderDeliverTo'},
    ];
  }
}
