import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {UserRegistrationService} from "../../service-auth/user-registration.service";
import {CognitoCallback} from "../../service-auth/cognito.service";
import {environment as env} from "../../../environments/environment";

export class RegistrationUser {
    name: string;
    phone_number: string;
    email: string;
    password: string;
    serviceProviderId: number;
}
/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
    selector: 'app-account',
    templateUrl: './registration.html',
    styleUrls: ['../component-account.scss']

})
export class RegisterComponent implements CognitoCallback {
    environment = env;
    registrationUser: RegistrationUser;
    router: Router;
    errorMessage: string;

    constructor(public userRegistration: UserRegistrationService, router: Router) {
        this.router = router;
        this.onInit();
    }

    onInit() {
        this.registrationUser = new RegistrationUser();
        this.errorMessage = null;
    }

    onRegister() {
        this.errorMessage = null;
        this.userRegistration.register(this.registrationUser, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { // error
            this.errorMessage = message;
            console.log("result: " + this.errorMessage);
        } else { // success
            // move to the next step
            console.log("redirecting");
            this.router.navigate(['/home/confirmRegistration', result.user.username]);
        }
    }

    hasError(event) {
      console.error(event);
    }
    //
    // telInputObject(obj) {
    //   console.log(obj);
    //   obj.setCountry('us');
    // }
    // onCountryChange(obj) {
    //   obj.setCountry('us');
    // }
    // telOutput(obj) {
    //   this.registrationUser.phone_number = obj;
    // }
    // intlTelInputObject(obj) {
    //   // this.registrationUser.phone_number = obj;
    // }

}
