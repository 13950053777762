import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ManufacturingTaskService} from "../../../service-data/manufacturing-task.service";
import {Package} from "../../../../model/Package";
import {PrintNodeOrderItem} from "../../../../model/PrintNodeOrderItem";
import {ManufacturingSet} from "../../../../model/ManufacturingSet";
import {PackageService} from "../../../service-data/package.service";
import {StartupService} from "../../../service-ui/startup.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {EditAddressComponent} from "./edit-address/edit-address.component";
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {ChangePackagesComponent} from "./change-packages/change-packages.component";
import {PackageTypeService} from "../../../service-data/package-type.service";
import {ManufacturingSetService} from "../../../service-data/manufacturing-set.service";
import {ManufacturingSetsComponent} from "../../manufacturing-sets/manufacturing-sets.component";

@Component({
  selector: 'app-facility-package',
  templateUrl: './facility-package.component.html',
  styleUrls: ['./facility-package.component.css']
})
export class FacilityPackageComponent implements OnInit {
  @Input() pkg: Package;
  @Input() manufacturingSetById: any;
  @Input() showPrintNodeOrderItems: boolean;
  @Input() mobileQueryMatchs: boolean;
  @Input() expandPrintNodeOrderPackages: any = {};
  @Input() showExpandPrintNodeOrderPackages: boolean;
  @Input() numberOfNotShippedPackagesInOrder: number;

  manufacturingSetsContributingToPackage: ManufacturingSet[] = [];
  expandReplanPackages = false;
  expandAddress = false;
  expandPackage = false;

  expandPrintNodeOrderItemTools: any = {};

  constructor(public startupService: StartupService, public dialog: MatDialog, private manufacturingSetService: ManufacturingSetService, private manufacturingTaskService: ManufacturingTaskService, private packageService: PackageService, public packageTypeService: PackageTypeService) {
    console.log('constructor for FacilityPackageComponent');
  }

  ngOnInit() {
    console.log('ngOnInit for FacilityPackageComponent');
    for ( const orderItemToPackage of this.pkg.orderItemToPackages ) {
      if ( orderItemToPackage.orderItem && orderItemToPackage.orderItem.printNodeOrderItems ) {
        for (const printNodeOrderItem of orderItemToPackage.orderItem.printNodeOrderItems) {
          if ( printNodeOrderItem.manufacturingSet && this.manufacturingSetsContributingToPackage.indexOf(printNodeOrderItem.manufacturingSet) === -1 ) {
            this.manufacturingSetsContributingToPackage.push(printNodeOrderItem.manufacturingSet);
          }
        }
      }
    }
  }

  print (pkg: Package) {
    // pkg.status = 'STARTED';
    pkg.shippingLabelStatus = 'PRINTING';
    if ( this.manufacturingSetsContributingToPackage && this.manufacturingSetsContributingToPackage[0] && this.manufacturingSetsContributingToPackage[0].ID) {
      this.packageService.print(pkg, Number(this.manufacturingSetsContributingToPackage[0].ID)).subscribe( changedPackage => {
        // pkg.status = changedPackage.status;
        pkg.shippingLabelStatus = changedPackage.shippingLabelStatus;
      });
    }
  }

  reprint (printNodeOrderItem: PrintNodeOrderItem, manufacturingSet: ManufacturingSet, useType: string) {
    for ( const manufacturingTask of manufacturingSet.manufacturingTasks ) {
      if ( manufacturingTask.useType === useType ) {
        this.manufacturingTaskService.reprint(manufacturingTask, printNodeOrderItem, 'lookup').subscribe( changedManufacturingTask => {
          console.log(`Response for ManufacturingSet and Book : ${manufacturingTask.ID} ${printNodeOrderItem.book_ID}`);
        });
      }
    }
  }

  manuallyMarkPackageShipped (pkg: Package, shippingStatus: string) {
    pkg.shippingStatus = shippingStatus;
    this.packageService.recordTrackingNumberShipped(pkg).subscribe( changedPackage => {
      console.log(`Shipping Status Changed`);
    });
  }

  reportBookProblem (printNodeOrderItem: PrintNodeOrderItem) {
  }

  reportShippingProblem (pkg: Package) {
  }


  trackingNumberURL(pkg: Package): string {
    if ( !pkg.trackingnumber ) {
      return '';
    }
    if (pkg.trackingnumber.startsWith('94')) {
      return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + pkg.trackingnumber;
    } else if (pkg.trackingnumber.startsWith('1Z')) {
      return 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + pkg.trackingnumber;
    } else if (pkg.carrier === 'FEDEX') {
      return 'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=' + pkg.trackingnumber;
    } else {
      return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + pkg.trackingnumber;
    }
  }

  getBackgroundColor (pkg: Package): string {
    if ( !pkg.printNodeOrder ) {
      return 'lightgrey';
    }
    // If Not Shipped
    if ( pkg.shippingStatus === 'NONE' && pkg.printNodeOrder.shippingStatus === 'NONE' ) {
      switch (pkg.shippingLabelStatus) {
        case 'READY': {
          if ( pkg.pickSheet ) {
            return ''; // NOT SHIPPED AND SHIPPING LABEL IS READY
          } else {
            return 'lightgreen'; // NOT SHIPPED AND SHIPPING LABEL IS READY -- PACKING SHEET NOT PRINTED
          }
        }
        case 'ERROR': {
          return StartupService.colorAliases['errorBackground']; // NOT SHIPPED AND SHIPPING LABEL IS ERROR
        }
        case 'INITIAL': {
          return 'orange'; // NOT SHIPPED AND SHIPPING LABEL IS NOT CREATED YET
        }
      }
    } else {
      return 'grey'; // Shipped
    }
  }

  getBorderColor (pkg: Package): string {
    if ( !pkg.printNodeOrder ) {
      return 'lightgrey';
    }
    if ( pkg.printNodeOrder.printnode_ID === 0 ) {
      return 'grey';
    }
    if ( pkg.shippingStatus === 'SHIPPED' || pkg.printNodeOrder.shippingStatus === 'SHIPPED' ) {
      return 'lightgrey';
    }
    if ( this.manufacturingSetsContributingToPackage ) {
      let period: string = '';
      for ( const manufacturingSet of this.manufacturingSetsContributingToPackage ) {
        if ( manufacturingSet && manufacturingSet.manufacturingTaskBucketPeriod && manufacturingSet.manufacturingTaskBucketPeriod.period ) {
          if ( manufacturingSet.manufacturingTaskBucketPeriod.period < period || !period ) {
            period = manufacturingSet.manufacturingTaskBucketPeriod.period;
          }
        }
      }
      if ( period ) {
        const periodDate = new Date(period.substring(1) + ' 15:01:01');
        let dateDiff = Math.round(( Number( periodDate ) - Number( new Date() ) ) / ( 24 * 60 * 60 * 1000 ) );
        if ( this.getWeekOfYear(periodDate) > this.getWeekOfYear(new Date())) {
          dateDiff -= 2;
        }
        if ( this.getWeekOfYear(periodDate) < this.getWeekOfYear(new Date())) {
          dateDiff += 2;
        }
        if ( dateDiff > 6 ) {
          return 'lightgreen';
        }
        if ( dateDiff < -3) {
          return 'red';
        }
        switch ( dateDiff ) {
          case -4: {
            return 'pink';
          }
          case -3: {
            return 'lightpink';
          }
          case -2: {
            return 'orange';
          }
          case -1: {
            return 'darkyellow';
          }
          case 0: {
            return 'black';
          }
          case 1: {
            return 'green';
          }
          case 2: {
            return 'green';
          }
          case 3: {
            return 'green';
          }
          case 4: {
            return 'green';
          }
        }
      }
      return 'red';
    } else {
      return 'blue';
    }
  }

  getWeekOfYear(date: Date): number {
    const MILLISECONDS_IN_WEEK = 604800000;
    const firstDayOfWeek = 0; // monday as the first day (0 = sunday)
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    startOfYear.setDate(
      startOfYear.getDate() + (firstDayOfWeek - (startOfYear.getDay() % 7))
    );
    const dayWeek = Math.floor(( Number( date ) - Number(startOfYear) ) / MILLISECONDS_IN_WEEK) + 1;
    return  dayWeek;
  }

  public openEditAddressDialog (): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { printNodeOrder: this.pkg.printNodeOrder };
    const dialogRef = this.dialog.open(EditAddressComponent, dialogConfig);
    if ( dialogRef ) {
      this.pkg.shippingLabelStatus = 'WAITING';
      this.pkg.shippingLabelErrorMessage = '';
    }
  }

  changePackages (pkg: Package, printNodeOrder: PrintNodeOrder) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = this.mobileQueryMatchs ? '600px' : '95%';
    dialogConfig.height = this.mobileQueryMatchs ? '700px' : '95%';
    dialogConfig.disableClose = false;
    dialogConfig.data = { pkg: pkg, printNodeOrder: printNodeOrder, mobileQueryMatches: this.mobileQueryMatchs };
    const dialogRef = this.dialog.open(ChangePackagesComponent, dialogConfig);
    if ( dialogRef ) {
    }
  }

  getManufacturingSetBackgroundColor(period: string) {
    return ManufacturingSetsComponent.getBackgroundColor(period);
  }
}
