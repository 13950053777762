import {AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {TableFilterManager} from "./TableFilterManager";
import {EventHandler} from "./EventHandler";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DataObject} from "../../model/DataObject";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TableComponent<T extends DataObject > implements OnDestroy, OnInit, OnChanges, AfterViewInit {
  @Input() columns: any[];
  @Input() eventHandler: EventHandler;
  @Input() data: Array<T>;
  @Input() expandedTableRowTemplate: TemplateRef<HTMLElement>;
  @Input() dataServiceStatus: string;
  @Input() showFilters: boolean = true;

  displayedColumns = [];
  displayedColumnsFilters = [];

  dataSource: MatTableDataSource<T>;
  tableFilterManager: TableFilterManager<T>;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  selected: T;
  objects: T[];

  expandedElement: any;


  constructor() {
  }

  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  // isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  // isExpansionDetailRow(i: number, row: Object): boolean {
  //   return true;
  //   // return row.hasOwnProperty('detailRow');
  // }

  ngOnInit() {
  }
  ngOnChanges() {
      this.displayedColumns = this.columns.map(c => c.columnDef);
      this.displayedColumnsFilters = this.columns.map(c => c.columnDef + 'Filter');
      this.dataSource = new MatTableDataSource<T>(this.data);
      this.tableFilterManager = new TableFilterManager<T>(this.dataSource);

      // this.dataSource.filterPredicate = this.filterBySubject();
      // Probably put this back after getting simple filter to work
      const _this = this;
      this.dataSource.filterPredicate =
        (data: T, filter: string) => {
          return _this.tableFilterManager.tableFilter.match(data, filter);
        };
      this.dataSource.sortData = this.sortData();
      // this.dataSource.data = this.data;
    // this.displayedColumns = this.columns.map(c => c.columnDef);
    // this.displayedColumnsFilters = this.columns.map(c => c.columnDef + 'Filter');
    // this.dataSource.data = this.data; // Not sure this can just be commented out but rather needs to be totally reloaded with new data after the change
  }

  ngAfterViewInit(): void {
    if ( this.sort ) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toUpperCase();
    console.log(`filter changed to ${this.dataSource.filter}`);
  }

  filterBySubject() {
    const filterFunction = (data: T, filter: any): boolean => {
      if (filter) {
        // const subjects = data.subjects;
        if ( data['ID']) {
          const value = '' + data['ID'];
          if ( value.indexOf(filter) !== -1) {
              return true;
          }
          return false;
        }
      } else {
        return true;
      }
    };
    return filterFunction;
  }

  sortData() {
    const sortFunction = (items: T[], sort: MatSort): T[] => {
        if (!sort.active || sort.direction === '') {
            return items;
        }

        return items.sort((a: T, b: T) => {
            let comparatorResult = 0;
            comparatorResult = a[sort.active].localCompare(b[sort.active]);
            // switch (sort.active) {
            //     case 'name':
            //         comparatorResult = a.ID.localeCompare(b.name);
            //         break;
            //     case 'class':
            //         comparatorResult = a.class.localeCompare(b.class);
            //         break;
            //     case 'section':
            //         comparatorResult = a.section.localeCompare(b.section);
            //         break;
            //     case 'subjects':
            //         comparatorResult = a.subjects.length - b.subjects.length;
            //         break;
            //     case 'marks':
            //         comparatorResult =
            //             a.marks.reduce((prev, curr) => prev + curr) / a.marks.length -
            //             b.marks.reduce((prev, curr) => prev + curr) / b.marks.length;
            //         break;
            //     default:
            //         comparatorResult = a.name.localeCompare(b.name);
            //         break;
            // }
            return comparatorResult * (sort.direction === 'asc' ? 1 : -1);
        });
    };

    return sortFunction;
  }
  // End from testtable

    poke() {
      console.log('poke');
  }

  onSelect(obj: T): void {
    this.selected = obj;
  }

  ngOnDestroy(): void {
  }

  getDataSourceData(): T[]  {
    return this.dataSource.data;
  }

  addItem(item: T) {
    if ( !this.contains(item, this.dataSource.data)) {
      this.dataSource.data.push(item);
      this.dataSource.sort = this.sort;
      // this.computeCumulative();
      this.dataSource.filter = '' + Math.random();
      if ( this.table ) {
        this.table.renderRows();
      }
    }
  }

  removeItem(item: T) {
    const foundIndex = this.dataSource.data.findIndex(i => i.getId() === item.getId());
    if (foundIndex > -1) {
      this.dataSource.data.splice(foundIndex, 1);
      this.dataSource.sort = this.sort;
      // this.computeCumulative();
      this.dataSource.filter = '' + Math.random();
      if ( this.table ) {
        this.table.renderRows();
      }
    }
  }

  contains(object: T, array: T[] ): boolean {
    for ( const o of array ) {
      if ( o.getId() === object.getId() ) {
        return true;
      }
    }
    return false;
  }

}
