<mat-card appearance="outlined">
  <mat-card-content>
    <button *ngIf="allowAdminRescue" mat-icon-button (click)="startupService.selectRole('umbrella', '' + umbrella.ID, umbrella.name); router.navigate(['/secure/organizations']);"><mat-icon>supervised_user_circle</mat-icon></button>
    <ng-container *ngIf="editObject">
      <form>
        <h3 i18n="@@Basic Information">Basic Information</h3>
        <mat-form-field>
          <textarea name="name" [(ngModel)]="editableObject.name" matInput i18n-placeholder="@@Name" placeholder="Name"></textarea>
        </mat-form-field>
      </form>
    </ng-container>
    <button *ngIf="!editObject" mat-icon-button (click)="edit()" title="Edit" i18n-title="@@Edit"><mat-icon>edit</mat-icon></button>
    <button *ngIf="editObject" mat-icon-button (click)="save()" title="Save" i18n-title="@@Save"><mat-icon>save</mat-icon></button>
    <button *ngIf="editObject" mat-icon-button (click)="cancel()" title="Cancel" i18n-title="@@Cancel"><mat-icon>cancel</mat-icon></button>
    <h3 i18n="@@Organizations Within the Umbrella">Organizations Within the Umbrella</h3>
    <ng-container *ngIf="umbrella && umbrella.organizations && umbrella.organizations.length > 0">
      <div *ngFor="let umbrella of umbrella.organizations">
        <div>{{umbrella.ID}} {{umbrella.name}}</div>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
