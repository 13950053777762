import {OrderItem} from "./OrderItem";
import {DataObject} from "./DataObject";
import {PrintNodeOrder} from "./PrintNodeOrder";
import {OrderItemToPackage} from "./OrderItemToPackage";

export class Package implements DataObject {
  printnode_ID: number;
  package_ID: number;
  carrier: string;
  servicelevel: string;
  trackingnumber: string;
  status: string;
  shiptimestamp = new Date(0);
  delivertimestamp = new Date(0);
  lastUpdate = new Date();
  packageType_ID: number;
  weight: number;
  packageLabelStatus = 'INITIAL';
  shippingLabelStatus = 'INITIAL';
  printNodeOrder_ID: number;
  shippingStatus: string;
  pickSheet: string;
  shippingLabelErrorMessage: string;
  printNodeOrder: PrintNodeOrder;
  shippingCost: number;

  orderItemToPackages: OrderItemToPackage[];

  constructor( obj ) {
    this.printnode_ID = 0;
    this.package_ID = 0;
    this.carrier = '';
    this.servicelevel = '';
    this.trackingnumber = '';
    this.status = '';
    this.shiptimestamp = new Date(0);
    this.delivertimestamp = new Date(0);
    this.lastUpdate = new Date();
    this.packageType_ID = 0;
    this.weight = 0;
    this.packageLabelStatus = 'INITIAL';
    this.shippingLabelStatus = 'INITIAL';
    this.printNodeOrder_ID = 0;
    this.shippingStatus = '';
    this.pickSheet = '';
    this.shippingLabelErrorMessage = '';
    this.shippingCost = 0;
    this.orderItemToPackages = [];

    if ( obj && obj['package_ID']) {
      this.package_ID = obj['package_ID'];
    }
    this.update(obj);
    // if ( obj ) {
    //   for ( const prop of Object.keys(obj) ) {
    //     this[prop] = obj[prop];
    //   }
      // if ( obj.orderItemToPackages ) {
      //   for ( const packageToOrderItem of obj.orderItemToPackages ) {
      //     this.orderItemToPackages.push( new PackageToOrderItem( packageToOrderItem ) );
      //   }
      // }
    // }

  }

  getId () {
    return this.package_ID;
  }

  getOrderValue () {
    return this.package_ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'package_ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || obj[prop] instanceof String || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
