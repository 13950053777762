import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { Project } from '../../../model/editor/Project';
import { EditorProjectService } from '../../service-data/editor/editor-project.service';
import {Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import {Chapter} from "../../../model/editor/Chapter";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../pipe/language.pipe";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-chapter-nav-tile',
  templateUrl: './chapter-nav-tile.component.html',
  styleUrls: ['./chapter-nav-tile.component.css']
})
export class ChapterNavTileComponent implements OnInit {
  @ViewChild('placeHolderChapterName') placeHolderChapterName;
  @Input() project: Project;
  @Input() chapter: Chapter;
  @Input() selectedChapter: Chapter;

  public renameChapter: boolean = false;

  constructor(public router: Router, public startupService: StartupService, private projectService: EditorProjectService, public dialog: MatDialog) {
  }

  ngOnInit() {
  }


  deleteChapter(chapter: Chapter): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        title: LanguagePipe.get('Delete Chapter', 'compressSpaces') + ' ' + chapter.title + LanguagePipe.get('?', 'compressSpaces'),
        body: '', // LanguagePipe.get('Delete', 'compressSpaces') + ' ' + project.name + LanguagePipe.get('?', 'compressSpaces'),
        noButton: LanguagePipe.get('Cancel', 'compressSpaces'),
        yesButton: LanguagePipe.get('Delete Chapter', 'compressSpaces'),
        returnValue: 'ok'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed. result[' + result + ']');
      if ( result && result === 'ok' ) {
        const index = this.project.interior.chapters.indexOf(chapter);
        if ( index > -1 ) {
          this.project.interior.chapters.splice(index, 1);
          this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
        }
      }
    });

  }

  isChapterNameUsed(): boolean {
    if ( !this.placeHolderChapterName || !this.placeHolderChapterName.nativeElement || !this.placeHolderChapterName.nativeElement.value ) {
      return true;
    }
    const newTitleName = this.placeHolderChapterName.nativeElement.value.trim();
    for ( const chapter of this.project.interior.chapters ) {
      if ( newTitleName === chapter.title ) {
        return true;
      }
    }
    return false;
  }

  moveChapter(chapter: Chapter, amount: number) {
    const index = this.project.interior.chapters.indexOf(chapter);
    if ( index > -1 ) {
      this.project.interior.chapters.splice(index, 1);
      this.project.interior.chapters.splice(index + amount, 0, chapter);
      // this.projectService.saveProjectImmediately(this.project).then( proj => {
      //   console.log('Reordered chapters');
      // });
      this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
    }
  }
  chapterIndex(): number {
    return this.project.interior.chapters.indexOf(this.chapter);
  }
  saveChapterValue() {
    this.chapter.title = this.placeHolderChapterName.nativeElement.value;
    this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
    // this.projectService.saveProjectImmediately(this.project).then( proj => {
    //   console.log('Updated project');
    // });
  }
  cancelProjectValue() {
    this.placeHolderChapterName.nativeElement.value = this.project.name;
  }
}

