import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import {ManufacturingTaskBucket} from "../../model/ManufacturingTaskBucket";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {T} from "@angular/cdk/keycodes";
import {ObjectAttachRequest} from "./data-service";
import {ManufacturingTaskBucketPeriodService} from "./manufacturing-task-bucket-period.service";
import {NotificationsService} from "./notifications.service";

@Injectable()
export class ManufacturingTaskBucketService extends AbstractLiveDataService<ManufacturingTaskBucket> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setManufacturingTaskBucketService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): ManufacturingTaskBucket {
    const object = new ManufacturingTaskBucket(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "ManufacturingTaskBucket";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "manufacturingtaskbucket";
  }

  getLinkData(): any[] {
    return [
      { action: 'AddUpdateArray', serviceName: 'manufacturingTaskBucketPeriodService', arrayProperty: 'manufacturingTaskBucketPeriods'},
    ];
  }
}
