import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import {catchError, map, retry, tap} from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {Order} from "../../model/Order";
import {DataService} from "./data-service";

@Injectable()
export class OrderService extends DataService {
  private objects: Order[] = [];
  private objectsObservable = of( this.objects );
  public loadingState = 'INITIAL';


  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
    super(startupService);
  }

    reset (filter: string = '') {
    this.loadingState = 'INITIAL';
    while ( this.objects.length > 0) {
      this.objects.pop();
    }
  }

  getOrders(): Observable<Order[]> {
    if ( this.loadingState === 'INITIAL') {
      this.loadingState = 'LOADING';
      const url = `${environment.apiBaseURL}order`;
      this.messageService.add('OrderService: fetched orders');
      return this.http.get<Order[]>(url, this.startupService.getHttpOptions())
        .pipe(
          map(orders => {
            while ( this.objects.length > 0) {
              this.objects.pop();
            }
            for ( const order of orders ) {
              const o = new Order(order);
              this.objects.push(o);
            }
            this.loadingState = 'LOADED';
            return this.objects;
          }), catchError(this.handleError('getOrders', []))
        );
    } else {
      return this.objectsObservable;
    }
  }


  getOrder(id: string): Observable<Order> {
    const url = `${environment.apiBaseURL}order/${id}`;
    this.messageService.add(`OrderService: fetched order id=${id}`);
    return this.http.get<Order>(url, this.startupService.getHttpOptions())
      .pipe(
        map(order => {
          const p = new Order(order);
          return p;
        }),
        catchError(this.handleError<Order>(`getOrder id=${id}`))
    );
  }

  /** PUT: update the order on the server */
  updateOrder (order: Order): Observable<Order> {
    const url = `${environment.apiBaseURL}order/${order.ID}`;
    this.messageService.add(`OrderService: updated order id=${order.ID}`);
    return this.http.put(url, order, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated order id=${order.ID}`)),
      catchError(this.handleError<any>('updateOrder'))
    );
  }

  /** PUT: update the order on the server */
  updateDebitLineEntries (order: Order): Observable<Order | undefined> {
    const url = `${environment.apiBaseURL}order/updateDebitLineEntries/${order.ID}`;
    this.messageService.add(`OrderService: updateDebitLineEntries id=${order.ID}`);
    return this.http.get<Order>(url, this.startupService.getHttpOptions())
      .pipe(
        map(o => {
          const x = new Order(o);
          return x;
        }),
        catchError(this.handleError<Order>(`getOrder id=${order.ID}`))
      );
  }

  addOrder (order: Order): Observable<Order> {
    const url = `${environment.apiBaseURL}order`;
    return this.http.post<Order>(url, order, this.startupService.getHttpOptions()).pipe(retry(6),
      map(o => {
        this.log(`added order w/ id=${order.ID}`);
        const x = new Order(o);
        this.objects.push(x);
        return x;
      }),
      catchError(this.handleError<Order>('addOrder'))
    );
  }

  /** DELETE: delete the order from the server */
  deleteOrder (order: Order): Observable<Order> {
    const url = `${environment.apiBaseURL}order/${order.ID}`;

    return this.http.delete<Order>(url, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`deleted order id=${order.ID}`)),
      catchError(this.handleError<Order>('deleteOrder'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add('OrderService: ' + message);
  }

}
