<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
    <span>
      <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
        <span class="app-toolbar-filler"></span>
        <ng-container *ngIf="!mobileQuery.matches"><span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Manufacturing Sets">Manufacturing Sets</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Manufacturing Sets">Manufacturing Sets</span></ng-container>
      </button>
    </span>
    </div>
    <span class="app-toolbar-filler"></span>
    <div>
      <button (click)="showCompletedSets = !showCompletedSets" mat-icon-button color="primary"><mat-icon *ngIf="!showCompletedSets" i18n-title="@@Hide Completed Sets" title="Hide Completed Sets" color="accent">curtains_closed</mat-icon><mat-icon *ngIf="showCompletedSets" i18n-title="@@Show Completed Sets" title="Show Completed Sets" style="color: yellow">curtains_closed</mat-icon></button>
      <button (click)="showStats = !showStats" mat-icon-button color="primary"><mat-icon *ngIf="!showStats" i18n-title="@@Hide Stats" title="Hide Stats" color="accent">timeline</mat-icon><mat-icon *ngIf="showStats" i18n-title="@@Show Stats" title="Snow Stats" style="color: yellow">timeline</mat-icon></button>
      <button (click)="showPrintNodeOrderItems = !showPrintNodeOrderItems" mat-icon-button color="primary"><mat-icon *ngIf="!showPrintNodeOrderItems" i18n-title="@@Show Print Node Order Items" title="Show Print Node Order Items" color="accent">format_list_bulleted</mat-icon><mat-icon *ngIf="showPrintNodeOrderItems" i18n-title="@@Hide Print Node Order Items" title="Hide Print Node Order Items" style="color: yellow">format_list_bulleted</mat-icon></button>
    </div>
<!--    <div>-->
<!--      <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
<!--    </div>-->
    <span class="app-toolbar-filler"></span>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="sets"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-light">
      <div class="app-content" style="display: flex; flex-wrap: wrap; flex-direction: row;">
        <mat-card appearance="outlined" style="width: 100%; height: auto; overflow-y: auto; display: block;" >
          <mat-card-content>
            <div class="xboard">
              <ng-container *ngIf="manufacturingTaskBucketPeriods && manufacturingTaskBucketPeriods.length > 0">
                <!-- Each ManufacturingSet -->
                <ng-container *ngFor="let manufacturingTaskBucketPeriod of manufacturingTaskBucketPeriods">
                  <div *ngIf="showCompletedSets || atLeastOneManufacturingSetInManufacturingTaskBucketPeriodIsOpen['' + manufacturingTaskBucketPeriod.ID]" class="xxboard" [style.border-color]="periodDisplayColor[manufacturingTaskBucketPeriod.period]" style="padding: 3px; margin: 8px; border-radius: 4px; border: solid 2px darkgrey;">
                    <span style="display: block; font-size: 14pt; padding-start: 3px;">{{manufacturingTaskBucketPeriod.manufacturingTaskBucket.name | languageString }} {{manufacturingTaskBucketPeriod.period}}</span>
                    <div class="board" style="display: block">
                      <ng-container *ngFor="let manufacturingSet of manufacturingSetsInManufacturingTaskBucketPeriods['' + manufacturingTaskBucketPeriod.ID]">
                        <div *ngIf="manufacturingSet.openStatus === 'CLOSED' && manufacturingSet.printNodeOrderItems.length > 0 && (showCompletedSets || manufacturingSet.status !== 'COMPLETED')" class="manufacturingSet card-list mat-elevation-z1" [style.background-color]="manufacturingSetBackgroundColor[manufacturingSet.status]">
                          <ng-container *ngIf="manufacturingSet['issues'] && manufacturingSet['issues'].length > 0">
                            <ng-container *ngFor="let issue of manufacturingSet['issues']">
                              <app-issue [issue]="issue" [objectId]="issue.objectId" [objectType]="issue.objectType" [userRoleName]="issue.userRole" [userRoleId]="issue.userId" [supportRoleName]="issue.supportRole" [supportRoleId]="issue.supportId" [displayOnly]="false"></app-issue>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="manufacturingSet['CoverPrintManufacturingTask'] && manufacturingSet['CoverPrintManufacturingTask']['issues'] && manufacturingSet['CoverPrintManufacturingTask']['issues'].length > 0">
                            <ng-container *ngFor="let issue of manufacturingSet['CoverPrintManufacturingTask']['issues']">
                              <app-issue [issue]="issue" [objectId]="issue.objectId" [objectType]="issue.objectType" [userRoleName]="issue.userRole" [userRoleId]="issue.userId" [supportRoleName]="issue.supportRole" [supportRoleId]="issue.supportId" [displayOnly]="false"></app-issue>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="manufacturingSet['BodyPrintManufacturingTask'] && manufacturingSet['BodyPrintManufacturingTask']['issues'] && manufacturingSet['BodyPrintManufacturingTask']['issues'].length > 0">
                            <ng-container *ngFor="let issue of manufacturingSet['BodyPrintManufacturingTask']['issues']">
                              <app-issue [issue]="issue" [objectId]="issue.objectId" [objectType]="issue.objectType" [userRoleName]="issue.userRole" [userRoleId]="issue.userId" [supportRoleName]="issue.supportRole" [supportRoleId]="issue.supportId" [displayOnly]="false"></app-issue>
                            </ng-container>
                          </ng-container>
                          <h7 *ngIf="mobileQuery.matches" class="mat-h7" style="display: block">
                            <ng-container *ngFor="let manufacturingTask of manufacturingSet.manufacturingTasks">
                              <button  style="display: inline-block; width: 10px; height: 10px;" [title]="manufacturingTask.useType" [style.background-color]="manufacturingSet.status === 'COMPLETED' ? manufacturingTaskStatusColors['COMPLETED'] : manufacturingTaskStatusColors[manufacturingTask.status]"></button>
                            </ng-container>
                          </h7>
                          <h7 class="mat-h7">
                            <span><mat-icon>layers</mat-icon></span><span style="font-size: 20pt; margin-right: 6px; font-weight: bold;">{{manufacturingSet.ID}}</span> <!-- stats for Set here <span>{{manufacturingSet.printNodeOrderItems && manufacturingSet.printNodeOrderItems.length ? manufacturingSet.printNodeOrderItems.length : ''}} </span> -->
                            <button mat-icon-button class="floatingButton" (click)="expandSets['' + manufacturingSet.ID] = !expandSets['' + manufacturingSet.ID]" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon *ngIf="expandSets['' + manufacturingSet.ID]">expand_more</mat-icon><mat-icon *ngIf="!expandSets['' + manufacturingSet.ID]">expand_less</mat-icon></button>
                            <button mat-icon-button class="floatingButton" (click)="expandSetsPackages['' + manufacturingSet.ID] = !expandSetsPackages['' + manufacturingSet.ID]" i18n-style="@@floatEnd" style="float:right"><mat-icon *ngIf="expandSetsPackages['' + manufacturingSet.ID]" color="primary">local_shipping</mat-icon><mat-icon *ngIf="!expandSetsPackages['' + manufacturingSet.ID]" style="color: green">local_shipping</mat-icon></button>

                            <ng-container *ngIf="manufacturingSet['BodyPrintManufacturingTask']">
                              <div *ngIf="!manufacturingSet['BodyPrintManufacturingTask'].details || !manufacturingSet['BodyPrintManufacturingTask'].details['alternate'] || !manufacturingSet['BodyPrintManufacturingTask'].details['alternate']['printerIp']; then bodyOnlyOnePrinterAlternative else bodyMultiplePrinterAlternatives">
                              </div>
                              <ng-template #bodyOnlyOnePrinterAlternative>
                                <ng-container [ngSwitch]="manufacturingSet['BodyPrintManufacturingTask'].inputStatus">
                                  <ng-container *ngSwitchCase="'COMPLETE'" [ngSwitch]="manufacturingSet['BodyPrintManufacturingTask'].status">
                                    <button  *ngSwitchCase="'INITIAL'" mat-icon-button i18n-title="@@Print All Bodies In Set" title="Print All Bodies In Set" class="floatingButton" (click)="print(manufacturingSet, 'BodyPrint', manufacturingSet['BodyPrintManufacturingTask'].details['printerIp'])" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon>library_books</mat-icon></button>
                                    <button *ngSwitchCase="'SENT'" mat-icon-button i18n-title="@@Processing Request to Print All Bodies In Set" title="Processing Request to Print All Bodies In Set" class="floatingButton" i18n-style="@@floatEnd" style="float:right; color: green"><mat-icon>library_books</mat-icon></button>
                                    <button  *ngSwitchDefault mat-icon-button i18n-title="@@Reprint All Bodies In Set" title="Reprint All Bodies In Set" class="floatingButton" (click)="reprint(manufacturingSet, 'BodyPrint', manufacturingSet['BodyPrintManufacturingTask'].details['printerIp'])" i18n-style="@@floatEnd" style="float:right" color="warn"><mat-icon>library_books</mat-icon></button>
                                  </ng-container>
                                  <button  *ngSwitchDefault mat-icon-button i18n-title="@@Bodies Not Ready To Print" title="Bodies Not Ready To Print" class="floatingButton" i18n-style="@@floatEnd" style="float:right" color="accent"><mat-icon>library_books</mat-icon></button>
                                </ng-container>
                              </ng-template>
                              <ng-template #bodyMultiplePrinterAlternatives>
                                <ng-container [ngSwitch]="manufacturingSet['BodyPrintManufacturingTask'].inputStatus">
                                  <ng-container *ngSwitchCase="'COMPLETE'" [ngSwitch]="manufacturingSet['BodyPrintManufacturingTask'].status">
                                    <button  *ngSwitchCase="'INITIAL'" mat-icon-button i18n-title="@@Print All Bodies In Set" title="Print All Bodies In Set" class="floatingButton" (click)="print(manufacturingSet, 'BodyPrint', manufacturingSet['BodyPrintManufacturingTask'].details['printerIp'])" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon>library_books</mat-icon></button>
                                    <button *ngSwitchCase="'SENT'" mat-icon-button i18n-title="@@Processing Request to Print All Bodies In Set" title="Processing Request to Print All Bodies In Set" class="floatingButton" i18n-style="@@floatEnd" style="float:right; color: green"><mat-icon>library_books</mat-icon></button>
                                    <button  *ngSwitchDefault mat-icon-button i18n-title="@@Reprint All Bodies In Set" title="Reprint All Bodies In Set" class="floatingButton" (click)="reprint(manufacturingSet, 'BodyPrint', manufacturingSet['BodyPrintManufacturingTask'].details['printerIp'])" i18n-style="@@floatEnd" style="float:right" color="warn"><mat-icon>library_books</mat-icon></button>
                                  </ng-container>
                                  <button  *ngSwitchDefault mat-icon-button i18n-title="@@Bodies Not Ready To Print" title="Bodies Not Ready To Print" class="floatingButton" i18n-style="@@floatEnd" style="float:right" color="accent"><mat-icon>library_books</mat-icon></button>
                                </ng-container>
                                <ng-container [ngSwitch]="manufacturingSet['BodyPrintManufacturingTask'].inputStatus">
                                  <ng-container *ngSwitchCase="'COMPLETE'" [ngSwitch]="manufacturingSet['BodyPrintManufacturingTask'].status">
                                    <button  *ngSwitchCase="'INITIAL'" mat-icon-button i18n-title="@@Print All Bodies In Set" title="Print All Bodies In Set" class="floatingButton" (click)="print(manufacturingSet, 'BodyPrint', manufacturingSet['BodyPrintManufacturingTask'].details['alternate']['printerIp'])" i18n-style="@@floatEnd"  style="float:right; color: darkblue"><mat-icon>library_books</mat-icon></button>
                                    <button *ngSwitchCase="'SENT'" mat-icon-button i18n-title="@@Processing Request to Print All Bodies In Set" title="Processing Request to Print All Bodies In Set" class="floatingButton" i18n-style="@@floatEnd" style="float:right; color: green"><mat-icon>library_books</mat-icon></button>
                                    <button  *ngSwitchDefault mat-icon-button i18n-title="@@Reprint All Bodies In Set" title="Reprint All Bodies In Set" class="floatingButton" (click)="reprint(manufacturingSet, 'BodyPrint', manufacturingSet['BodyPrintManufacturingTask'].details['alternate']['printerIp'])" i18n-style="@@floatEnd"  style="float:right; color: pink"><mat-icon>library_books</mat-icon></button>
                                  </ng-container>
                                  <button  *ngSwitchDefault mat-icon-button i18n-title="@@Bodies Not Ready To Print" title="Bodies Not Ready To Print" class="floatingButton" i18n-style="@@floatEnd" style="float:right" color="accent"><mat-icon>library_books</mat-icon></button>
                                </ng-container>
                              </ng-template>
                            </ng-container>
                            <ng-container *ngIf="manufacturingSet['CoverPrintManufacturingTask']">
                              <div *ngIf="!manufacturingSet['CoverPrintManufacturingTask'].details || !manufacturingSet['CoverPrintManufacturingTask'].details['alternate'] || !manufacturingSet['CoverPrintManufacturingTask'].details['alternate']['printerIp']; then coverOnlyOnePrinterAlternative else coverMultiplePrinterAlternatives">
                              </div>
                              <ng-template #coverOnlyOnePrinterAlternative>
                                <ng-container [ngSwitch]="manufacturingSet['CoverPrintManufacturingTask'].inputStatus">
                                  <ng-container *ngSwitchCase="'COMPLETE'" [ngSwitch]="manufacturingSet['CoverPrintManufacturingTask'].status">
                                    <button *ngSwitchCase="'INITIAL'" mat-icon-button i18n-title="@@Print All Covers In Set" title="Print All Covers In Set" class="floatingButton" (click)="print(manufacturingSet, 'CoverPrint', manufacturingSet['CoverPrintManufacturingTask'].details['printerIp'])" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon>menu_book</mat-icon></button>
                                    <button *ngSwitchCase="'SENT'" mat-icon-button i18n-title="@@Processing Request to Print All Covers In Set" title="Processing Request to Print All Covers In Set" class="floatingButton" i18n-style="@@floatEnd" style="float:right; color: green" ><mat-icon>menu_book</mat-icon></button>
                                    <button *ngSwitchDefault mat-icon-button i18n-title="@@Reprint All Covers In Set" title="Reprint All Covers In Set" class="floatingButton" (click)="reprint(manufacturingSet, 'CoverPrint', manufacturingSet['CoverPrintManufacturingTask'].details['printerIp'])" i18n-style="@@floatEnd" style="float:right" color="warn"><mat-icon>menu_book</mat-icon></button>
                                  </ng-container>
                                  <button *ngSwitchDefault mat-icon-button i18n-title="@@Covers Not Ready To Print" title="Covers Not Ready To Print" class="floatingButton" i18n-style="@@floatEnd" style="float:right" color="accent"><mat-icon>menu_book</mat-icon></button>
                                </ng-container>
                              </ng-template>
                              <ng-template #coverMultiplePrinterAlternatives>
                                <ng-container [ngSwitch]="manufacturingSet['CoverPrintManufacturingTask'].inputStatus">
                                  <ng-container *ngSwitchCase="'COMPLETE'" [ngSwitch]="manufacturingSet['CoverPrintManufacturingTask'].status">
                                    <button *ngSwitchCase="'INITIAL'" mat-icon-button i18n-title="@@Print All Covers In Set" title="Print All Covers In Set" class="floatingButton" (click)="print(manufacturingSet, 'CoverPrint', manufacturingSet['CoverPrintManufacturingTask'].details['printerIp'])" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon>menu_book</mat-icon></button>
                                    <button *ngSwitchCase="'SENT'" mat-icon-button i18n-title="@@Processing Request to Print All Covers In Set" title="Processing Request to Print All Covers In Set" class="floatingButton" i18n-style="@@floatEnd" style="float:right; color: green" ><mat-icon>menu_book</mat-icon></button>
                                    <button *ngSwitchDefault mat-icon-button i18n-title="@@Reprint All Covers In Set" title="Reprint All Covers In Set" class="floatingButton" (click)="reprint(manufacturingSet, 'CoverPrint', manufacturingSet['CoverPrintManufacturingTask'].details['printerIp'])" i18n-style="@@floatEnd" style="float:right" color="warn"><mat-icon>menu_book</mat-icon></button>
                                  </ng-container>
                                  <ng-container *ngSwitchDefault>
                                    <button mat-icon-button i18n-title="@@Covers Not Ready To Print" title="Covers Not Ready To Print" class="floatingButton" i18n-style="@@floatEnd" style="float:right" color="accent"><mat-icon>menu_book</mat-icon></button>
                                  </ng-container>
                                </ng-container>
                                <ng-container [ngSwitch]="manufacturingSet['CoverPrintManufacturingTask'].inputStatus">
                                  <ng-container *ngSwitchCase="'COMPLETE'" [ngSwitch]="manufacturingSet['CoverPrintManufacturingTask'].status">
                                    <button *ngSwitchCase="'INITIAL'" mat-icon-button i18n-title="@@Print All Covers In Set" title="Print All Covers In Set" class="floatingButton" (click)="print(manufacturingSet, 'CoverPrint', manufacturingSet['CoverPrintManufacturingTask'].details['alternate']['printerIp'])" i18n-style="@@floatEnd" style="float:right; color: darkblue"><mat-icon>menu_book</mat-icon></button>
                                    <button *ngSwitchCase="'SENT'" mat-icon-button i18n-title="@@Processing Request to Print All Covers In Set" title="Processing Request to Print All Covers In Set" class="floatingButton" i18n-style="@@floatEnd" style="float:right; color: green" ><mat-icon>menu_book</mat-icon></button>
                                    <button *ngSwitchDefault mat-icon-button i18n-title="@@Reprint All Covers In Set" title="Reprint All Covers In Set" class="floatingButton" (click)="reprint(manufacturingSet, 'CoverPrint', manufacturingSet['CoverPrintManufacturingTask'].details['alternate']['printerIp'])" i18n-style="@@floatEnd" style="float:right; color: pink"><mat-icon>menu_book</mat-icon></button>
                                  </ng-container>
                                  <ng-container *ngSwitchDefault>
                                    <button mat-icon-button i18n-title="@@Covers Not Ready To Print" title="Covers Not Ready To Print" class="floatingButton" i18n-style="@@floatEnd" style="float:right" color="accent"><mat-icon>menu_book</mat-icon></button>
                                  </ng-container>
                                </ng-container>
                              </ng-template>
                            </ng-container>
                            <ng-container *ngIf="manufacturingSet['PackManufacturingTask']">
                              <ng-container [ngSwitch]="manufacturingSet['PackManufacturingTask'].inputStatus">
                                <ng-container *ngSwitchCase="'COMPLETE'">
                                  <ng-container [ngSwitch]="manufacturingSet.details['inventoryType']">
                                    <ng-container *ngSwitchCase="'FULFILL_FROM_INVENTORY'" [ngSwitch]="manufacturingSet['PackManufacturingTask'].status">
                                      <button *ngSwitchCase="'INITIAL'" mat-icon-button i18n-title="@@Print Packing Sheets" title="Print Packing Sheets" class="floatingButton" (click)="printPickSheet(manufacturingSet, 'PackingSheet', $event.target)" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon>local_post_office</mat-icon></button>
                                      <button *ngSwitchCase="'SENT'" mat-icon-button i18n-title="@@Processing Request to Print Packing Sheets" title="Processing Request to Print Packing Sheets" class="floatingButton" i18n-style="@@floatEnd" style="float:right; color: green"><mat-icon>local_post_office</mat-icon></button>
                                      <button *ngSwitchDefault mat-icon-button i18n-title="@@Reprint Packing Sheets" title="Reprint Packing Sheets" class="floatingButton" (click)="printPickSheet(manufacturingSet, 'PackingSheet', $event.target)" i18n-style="@@floatEnd" style="float:right" color="warn"><mat-icon>local_post_office</mat-icon></button>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault [ngSwitch]="manufacturingSet['PackManufacturingTask'].status">
                                      <button *ngSwitchCase="'INITIAL'" mat-icon-button i18n-title="@@Print Packing Sheets" title="Print Packing Sheets" class="floatingButton" (click)="print(manufacturingSet, 'Pack', 'PackingSheet')" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon>local_post_office</mat-icon></button>
                                      <button *ngSwitchCase="'SENT'" mat-icon-button i18n-title="@@Processing Request to Print Packing Sheets" title="Processing Request to Print Packing Sheets" class="floatingButton" i18n-style="@@floatEnd" style="float:right; color: green"><mat-icon>local_post_office</mat-icon></button>
                                      <button *ngSwitchDefault mat-icon-button i18n-title="@@Reprint Packing Sheets" title="Reprint Packing Sheets" class="floatingButton" (click)="reprint(manufacturingSet, 'Pack', 'PackingSheet')" i18n-style="@@floatEnd" style="float:right" color="warn"><mat-icon>local_post_office</mat-icon></button>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                                <button *ngSwitchDefault mat-icon-button i18n-title="@@Packing Sheets Not Ready To Print" title="Packing Sheets Not Ready To Print" class="floatingButton" i18n-style="@@floatEnd" style="float:right" color="accent"><mat-icon>local_post_office</mat-icon></button>
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!mobileQuery.matches">
                              <ng-container *ngFor="let manufacturingTask of manufacturingSet.manufacturingTasks"><button  style="float:right; display: inline-block; width: 10px; height: 10px;" [title]="manufacturingTask.useType" [style.background-color]="manufacturingSet.status === 'COMPLETED' ? manufacturingTaskStatusColors['COMPLETED'] : manufacturingTaskStatusColors[manufacturingTask.status]" i18n-style="@@floatEnd"></button></ng-container>
                            </ng-container>
                          </h7>
                          <h7 *ngIf="showStats" class="graphicDetails mat-h7">
                            <div class="graphicDetailNumberColumn">
                              <div class="graphicDetailNumberColumn">
                                <div class="graphicDetailNumber" style="width:20px;">{{manufacturingSet['totals'][2] | number:'1.0-0'}}</div>
                                <div class="graphicDetailNumber" style="width:20px;">{{manufacturingSet['totals'][0] | number:'1.0-0'}}</div>
                              </div>
                              <div class="graphicDetailNumberColumn">
                                <div class="graphicDetailNumber" style="width:40px;">{{manufacturingSet['totals'][1] | number:'1.0-0'}}</div>
                                <div class="graphicDetailNumber" style="width:40px;">{{ ( manufacturingSet['totals'][1] * 0.00474 ) | number:'1.1-1'}}</div>
                              </div>
                            </div>
                            <div class="graphicDetailNumberColumn">
                              <div class="graphicDetailColumn" *ngFor="let type of typesToDisplay">
                                <div class="graphicDetail" *ngFor="let category of getTypeCategories(type, manufacturingSet)" [style.background-color]="colors[type][category]" [title]="type + ': ' + category">
                                  <mat-icon>{{icons[type][category]}}</mat-icon>
                                </div>
                              </div>
                            </div>
                          </h7>
                          <h7 style="display: inline-block">
                            <app-issue title="Issue For Set" title-i18n="@@Issue For Set" [objectId]="manufacturingSet.ID" [objectType]="'MANUFACTURINGSET'" [userRoleName]="'facility'" [userRoleId]="manufacturingSet.printnode_id" [supportRoleName]="'network'" [supportRoleId]="1" [displayOnly]="false" [createOnly]="true"></app-issue>
                            <app-issue *ngIf="manufacturingSet['CoverPrintManufacturingTask']" title="Issue For Cover" title-i18n="@@Issue For Cover" [objectId]="manufacturingSet['CoverPrintManufacturingTask'].ID" [objectType]="'MANUFACTURINGTASK'" [userRoleName]="'facility'" [userRoleId]="manufacturingSet.printnode_id" [supportRoleName]="'network'" [supportRoleId]="1" [displayOnly]="false" [createOnly]="true"></app-issue>
                            <app-issue *ngIf="manufacturingSet['BodyPrintManufacturingTask']" title="Issue For Body" title-i18n="@@Issue For Body" [objectId]="manufacturingSet['BodyPrintManufacturingTask'].ID" [objectType]="'MANUFACTURINGTASK'" [userRoleName]="'facility'" [userRoleId]="manufacturingSet.printnode_id" [supportRoleName]="'network'" [supportRoleId]="1" [displayOnly]="false" [createOnly]="true"></app-issue>
                          </h7>
  <!--                        &lt;!&ndash; Stats for ManufacturingSet &ndash;&gt;-->
  <!--                        <div *ngIf="showStats" class="columnArea">-->
  <!--                          <div *ngFor="let type of getTypes()" class="oneColumn">-->
  <!--                            <app-set-stat [categories]="getCategories(manufacturingSet, type)" [(filter)]="manufacturingSet['filter']" [data]="getCategoryData(manufacturingSet, type, dataIndex)" [colors]="getColor(type)" [icons]="getIcons(type)" [total]="manufacturingSet['totals'][dataIndex]" totalHeight="100" [type]="type" [units]="dataIndex === 0 ? 'books' : dataIndex === 1 ? 'sheets' : 'line items'"></app-set-stat>-->
  <!--                          </div>-->
  <!--                        </div>-->
                          <div class="columnArea" *ngIf="expandSetsPackages['' + manufacturingSet.ID]">
                            <div class="packages card-list-content">
                              <h7 i18n="@@Packages">Packages</h7>
                              <mat-card appearance="outlined" *ngFor="let pkg of packagesByManufacturingSets['' + manufacturingSet.ID ]">
                                <mat-card-content style="padding: 2px 2px 4px 6px">
                                  <app-facility-package [style.width]="packagesByManufacturingSets['' + manufacturingSet.ID ].length > 1 ? '50%' : 'auto'" class="packageContainer" [pkg]="pkg" [showPrintNodeOrderItems]="showPrintNodeOrderItems" [expandPrintNodeOrderPackages]="{}"  showExpandPrintNodeOrderPackages="false"  numberOfNotShippedPackagesInOrder="0"></app-facility-package>
                                </mat-card-content>
                              </mat-card>
                            </div>
                          </div>
                          <div class="columnArea" *ngIf="expandSets['' + manufacturingSet.ID]">
                            <div *ngIf="showPrintNodeOrderItems" class="printNodeOrderItems card-list-content">
                              <mat-card appearance="outlined" *ngFor="let printNodeOrderItem of manufacturingSet.printNodeOrderItems" [style.border]="printNodeOrderItem.type === 'REPRINT' ? 'solid 5px blue' : (printNodeOrderItem.type === 'PARTIAL' ? 'solid 5px red' : '')">
                                <mat-card-content style="padding: 2px 2px 4px 6px">
                                  <app-print-node-order-item-in-manufacturing-set [printNodeOrderItem]="printNodeOrderItem" [manufacturingSet]="manufacturingSet"></app-print-node-order-item-in-manufacturing-set>
                                </mat-card-content>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






