import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EditorProjectService} from "../../../../service-data/editor/editor-project.service";
import {StartupService} from "../../../../service-ui/startup.service";
import {InteriorTheme} from "../../../../../model/editor/interior-theme/InteriorTheme";
import {InteriorThemeEditor} from "../../../../../model/editor/interior-theme/InteriorThemeEditor";
import {InteriorThemeConfiguration} from "../../../../../model/editor/interior-theme/InteriorThemeConfiguration";
import {ThemeService} from "../../../../service-data/theme.service";
import {InteriorThemeEditorItem} from "../../../../../model/editor/interior-theme/InteriorThemeEditorItem";


@Component({
  selector: 'app-interior-theme-editor',
  templateUrl: './interior-theme-editor.component.html',
  styleUrls: ['./interior-theme-editor.component.css']
})
export class InteriorThemeEditorComponent implements OnInit, OnChanges {
  @Input() interiorTheme: InteriorTheme;
  @Input() selectedElements: any[];
  @Input() document: Document;

  selectedInteriorThemeEditorItem: InteriorThemeEditorItem;
  interiorThemeEditor: InteriorThemeEditor;
  interiorThemeConfiguration: InteriorThemeConfiguration;
  showAll: boolean = false;
  showHeaders: boolean = false;
  onlyShowSelectedElements: boolean = true;
  showSelected: boolean = true;
  highlightTextImpactedByStyle: boolean = true;
  selectedStyleName: string = '';

  interiorTypeOptions: Array<Object> = [
    {value: 'all', name: 'All'},
    {value: 'standard', name: 'Chapter'},
    {value: 'toc', name: 'Table of Contents'},
    {value: 'title', name: 'Title Page'},
    {value: 'copyright', name: 'Copyright Page'}
  ];

  elementTypeOptions: Array<Object> = [
    {value: 'all', name: 'All'},
    {value: 'div', name: 'Sections'},
    {value: 'p', name: 'Paragraphs'},
    {value: 'li', name: 'List'},
  ];
  constructor(public themeService: ThemeService, public startupService: StartupService, public projectService: EditorProjectService) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.selectedElements && changes.selectedElements.currentValue ) {
      if ( this.interiorThemeEditor ) {
        if ( this.interiorThemeEditor.items.length === 0) {
          this.themeService.getInteriorThemeConfiguration().subscribe(interiorThemeConfigurationJSON => {
            this.interiorThemeConfiguration = new InteriorThemeConfiguration(interiorThemeConfigurationJSON);
            this.interiorThemeEditor = new InteriorThemeEditor( this.interiorTheme, this.interiorThemeConfiguration, this.document, this.projectService, this.startupService.project);
            if ( this.selectedElements ) {
              this.interiorThemeEditor.updateSelectedElements(this.selectedElements);
            }
          });
        } else {
          this.interiorThemeEditor.updateSelectedElements(changes.selectedElements.currentValue as any[]);
        }
      }
    }
    if ( changes.document ) {
      this.themeService.getInteriorThemeConfiguration().subscribe(interiorThemeConfigurationJSON => {
        this.interiorThemeConfiguration = new InteriorThemeConfiguration(interiorThemeConfigurationJSON);
        this.interiorThemeEditor = new InteriorThemeEditor( this.interiorTheme, this.interiorThemeConfiguration, this.document, this.projectService, this.startupService.project);
        if ( this.selectedElements ) {
          this.interiorThemeEditor.updateSelectedElements(this.selectedElements);
        }
      });
    }
    if ( changes.interiorTheme ) {
      this.init();
    }
  }

  private init() {
    this.themeService.getInteriorThemeConfiguration().subscribe(interiorThemeConfigurationJSON => {
      this.interiorThemeConfiguration = new InteriorThemeConfiguration(interiorThemeConfigurationJSON);
      this.interiorThemeEditor = new InteriorThemeEditor( this.interiorTheme, this.interiorThemeConfiguration, this.document, this.projectService, this.startupService.project);
      if ( this.selectedElements ) {
        this.interiorThemeEditor.updateSelectedElements(this.selectedElements);
      }
    });
  }


}
