import {Criteria} from "./Criteria";

export class ManufacturingSetRule {
    name: string;
    type: string; // SINGLE_PRINT_NODE_ORDER_ITEM, SINGLE_PRINT_NODE_ORDER, EXPANDING, CATCHALL
    criteria: Criteria[] = [];
    setPropertyCriteria: Criteria[] = [];
    //     - interiorType
    //     - interiorPaperType
    //     - bindingType
    //     - dueDate
    //     - height
    //     - width
    //     - quantity
    //     - squareInchesOfPrint
    //     - laminationType

    constructor ( obj ) {
        this.name = '';
        if (obj) {
            for (const prop of Object.keys(obj)) {
                // if ( this[prop] ) {
                this[prop] = obj[prop];
                // }
            }
            if ( obj.criteria ) {
                this.criteria = [];
                for ( const o of obj.criteria) {
                    this.criteria.push(new Criteria(o));
                }
            }
            if ( obj.setPropertyCriteria ) {
                this.setPropertyCriteria = [];
                for ( const o of obj.setPropertyCriteria) {
                    this.setPropertyCriteria.push(new Criteria(o));
                }
            }
        }
    }

}
