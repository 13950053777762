import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Draft} from "../../../../../model/Draft";
import {Item} from "../../../../../model/cart/Item";
import {Product} from "../../../../../model/Product";
import {StartupService} from "../../../../service-ui/startup.service";
import {Router} from "@angular/router";
import {ResourceService} from "../../../../service-data/resource.service";
import {MatDialog} from "@angular/material/dialog";
import {DraftService} from "../../../../service-data/draft.service";
import {LanguagePipe} from "../../../../pipe/language.pipe";
import {ConfirmDialogComponent} from "../../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-project-draft-thumbnail',
  templateUrl: './project-draft-thumbnail.component.html',
  styleUrls: ['./project-draft-thumbnail.component.css']
})
export class ProjectDraftThumbnailComponent implements OnInit, OnChanges {
  @ViewChild('draftCoverImage', { static: true }) draftCoverImage;
  @Input() draft: Draft;
  @Input() items: Item[];
  @Output() selectedItem = new EventEmitter<Product | Draft>();

  displayScale = 1.0;

  Math = Math;
  // public renameDraft`: boolean = false;

  constructor(public router: Router, public startupService: StartupService, private resourceService: ResourceService, private draftService: DraftService, public dialog: MatDialog) {
  }

  ngOnInit() {
    // this.refreshImagePosition();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('Change ', changes);
    // this.refreshImagePosition();
  }

  viewDraft (draft: Draft): void {
    this.selectedItem.emit(draft);
  }

  deleteDraft (draft: Draft): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        name: draft.title,
        title: LanguagePipe.get('Delete', 'compressSpaces') + ' ' + draft.title + LanguagePipe.get('?', 'compressSpaces'),
        body: '', // LanguagePipe.get('Delete', 'compressSpaces') + ' ' + draft.name + LanguagePipe.get('?', 'compressSpaces'),
        noButton: LanguagePipe.get('Cancel', 'compressSpaces'),
        yesButton: LanguagePipe.get('Delete', 'compressSpaces') + ' ' + draft.title,
        returnValue: 'ok'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed. result[' + result + ']');
      if ( result && result === 'ok' ) {
        // this.draftService.deleteDraft(draft).then( proj => {
        //   console.log('Deleted a draft');
        // });
      }
    });

  }

  addToCart(draft: Draft) {
    const item: Item = this.startupService.getCart().addDraftItem(draft, 1);
    // this.draftService.getDraftProperties(project).then( projectProperties => {
    //   const item: Item = this.startupService.getCart().add(projectProperties as DraftProperties);
    //   // item.setDraftProperties(projectProperties as DraftProperties);
    // });
  }

  // refreshImagePositionDetails(cachedImage: any) {
  //   this.draftCoverImage.nativeElement.style.display = "block";
  //   this.draftCoverImage.nativeElement.src = cachedImage.src;
  //
  //   let naturalWidth = cachedImage.naturalWidth;
  //   if ( !naturalWidth ) {
  //     naturalWidth = cachedImage.width;
  //   }
  //
  //   let naturalHeight = cachedImage.naturalHeight;
  //   if ( !naturalHeight ) {
  //     naturalHeight = cachedImage.height;
  //   }
  //
  //   // var clipWidth = ((bookBody.pageSize.width)*2+(paperWeights[paperWeights.usedWeight].caliper*bookBody.pages.numberOfPages/2*dpi) + this.trimWobble)*bookBody.displayScale.value+2*this.bleed;
  //   // var clipHeight = ((bookBody.pageSize.height + this.trimWobble)*bookBody.displayScale.value)+2*this.bleed;
  //   const clipWidth = this.draftCoverImage.nativeElement.parentNode.clientWidth - 2; // - 2 for border thickness
  //   const clipHeight = this.draftCoverImage.nativeElement.parentNode.clientHeight - 2;
  //
  //   const pageScreenWidth = ( naturalWidth * this.displayScale * this.draft.draftCover.documentScale);
  //   if ( pageScreenWidth ) {
  //     this.draftCoverImage.nativeElement.style.width = pageScreenWidth + 'px';
  //     const pageScreenHeight = ( naturalHeight * this.displayScale * this.draft.draftCover.documentScale);
  //
  //     this.draftCoverImage.nativeElement.style.height = pageScreenHeight + 'px';
  //
  //     this.draftCoverImage.nativeElement.style.left = ( ( clipWidth - pageScreenWidth ) / 2 + this.draft.draftCover.horizontalShift * this.displayScale ) + 'px';
  //     this.draftCoverImage.nativeElement.style.top = ( ( clipHeight - pageScreenHeight ) / 2 - this.draft.draftCover.verticalShift * this.displayScale ) + 'px';
  //
  //     this.draftCoverImage.nativeElement.style.width = '436px';
  //     this.draftCoverImage.nativeElement.style.height = '297px';
  //     this.draftCoverImage.nativeElement.style.left = '-230px';
  //     this.draftCoverImage.nativeElement.style.top = '0px';
  //
  //   }
  // }
  //
  // refreshImagePosition() {
  //   if ( !this.draft.draftCover ) {
  //     return;
  //   }
  //   let resourceId = this.draft.draftCover.resource_ID;
  //   let bodyOrCover = 'COVER';
  //   if ( this.draft.bindingType === 'SADDLE_STITCH' ) {
  //     resourceId = this.draft.draftBody.resource_ID;
  //     bodyOrCover = 'BODY';
  //   }
  //
  //   if ( !resourceId ) {
  //     this.draftCoverImage.nativeElement.src = '';
  //     return;
  //   }
  //
  //   // let imageUrl;
  //   let imageId;
  //   switch ( bodyOrCover ) {
  //     case 'COVER': {
  //       imageId = 'resource-' + this.draft.draftCover.resource_ID;
  //       break;
  //     }
  //     case 'BODY': {
  //       imageId = 'resource-' + this.draft.draftBody.resource_ID;
  //       break;
  //     }
  //   }
  //   let cachedImage: any = document.getElementById(imageId);
  //   if ( !cachedImage ) {
  //     const hiddenImagesElement: any = document.getElementById('hiddenImages');
  //     if ( hiddenImagesElement ) {
  //       const imageElement = document.createElement("img");
  //       this.resourceService.getImageURL(resourceId, 'COLOR', 72, 1).subscribe( imageURL => {
  //         if ( imageURL ) {
  //           console.log(`Getting image for resourceId: ${resourceId} 'COLOR' 72 1 ${imageURL}`);
  //           imageElement.src = imageURL;
  //           imageElement.id = 'resource-' + resourceId;
  //           hiddenImagesElement.appendChild(imageElement);
  //           cachedImage = imageElement;
  //           imageElement.src = imageURL;
  //           if (cachedImage.complete ) {
  //             this.refreshImagePositionDetails(cachedImage);
  //           } else {
  //             cachedImage.addEventListener('load', _ => {
  //               this.refreshImagePositionDetails(cachedImage);
  //             });
  //             cachedImage.addEventListener('error', _ => {
  //               console.log(`Error loading image ${'resource-' + resourceId}`);
  //             });
  //           }
  //         }
  //       });
  //
  //       imageElement.id = imageId;
  //       hiddenImagesElement.appendChild(imageElement);
  //       cachedImage = imageElement;
  //     }
  //   }
  //   if ( cachedImage ) {
  //     if (cachedImage.complete ) {
  //       this.refreshImagePositionDetails(cachedImage);
  //     } else {
  //       cachedImage.addEventListener('load', _ => {
  //         this.refreshImagePositionDetails(cachedImage);
  //       });
  //       cachedImage.addEventListener('error', _ => {
  //         console.log(`Error loading image ${imageId}`);
  //       });
  //     }
  //   }
  // }
}
