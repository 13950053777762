import {Component, Input, OnInit} from '@angular/core';
import {StartupService} from "../../../service-ui/startup.service";
import {FacilityInvoice} from "../../../../model/FacilityInvoice";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {of} from "rxjs";
import {FacilityInvoiceService} from "../../../service-data/facility-invoice.service";

@Component({
  selector: 'app-facility-invoice-details',
  templateUrl: './facility-invoice-details.component.html',
  styleUrls: ['../facility-invoices.component.css'] // NOTE THIS IS FROM THE PARENT FOLDER
})
export class FacilityInvoiceDetailsComponent implements OnInit {
  @Input() facilityInvoice: FacilityInvoice;
  constructor(private http: HttpClient, private facilityInvoiceService: FacilityInvoiceService, public startupService: StartupService) {

  }

  ngOnInit() {
  }

  changeNoteToCustomer () {
    this.facilityInvoiceService.updateItem(this.facilityInvoice).subscribe( changedFacilityInvoice => {
      console.log(`Change Note To Customer ${this.facilityInvoice.noteToCustomer}`);
    });
  }

  changeStatus (value: string) {
    this.facilityInvoice.status = value;
    this.facilityInvoiceService.updateItem(this.facilityInvoice).subscribe( changedFacilityInvoice => {
      console.log(`Change Status ${this.facilityInvoice.status}`);
    });
  }

  changeRecurring (value: string) {
    this.facilityInvoice.recurring = value;
    this.facilityInvoiceService.updateItem(this.facilityInvoice).subscribe( changedFacilityInvoice => {
      console.log(`Change Status ${this.facilityInvoice.recurring}`);
    });
  }

  changeContestStatus (value: string) {
    this.facilityInvoice.contestStatus = value;
    this.facilityInvoiceService.updateItem(this.facilityInvoice).subscribe( changedFacilityInvoice => {
      console.log(`Change Consest Status ${this.facilityInvoice.contestStatus}`);
    });
  }

  sendInvoice () {
    this.facilityInvoice.status = 'SENT';
    console.log(`SENT EMAIL TO CUSTOMER ${this.facilityInvoice.status}`);
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

}
