import { Injectable } from '@angular/core';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {Project} from "../../model/Project";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {NotificationsService} from "./notifications.service";

@Injectable()
export class ProjectService extends AbstractLiveDataService<Project> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setProjectService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): Project {
    return new Project(args);
  }

  getLoggingObjectTypeName (): string {
    return "Project";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "project";
  }

  getLinkData(): any[] {
    return [
      { action: 'AddUpdateArray', serviceName: 'bookService', arrayProperty: 'books'},
      { action: 'AddUpdateArray', serviceName: 'draftService', arrayProperty: 'drafts'},
      { action: 'Link', objectIdPropertyName: 'primaryBook_ID', serviceName: 'bookService', arrayProperty: 'primaryInProject', objectProperty: 'primaryBook'},
      { action: 'Link', objectIdPropertyName: 'primaryDraft_ID', serviceName: 'draftService', arrayProperty: 'primaryInProject', objectProperty: 'primaryDraft'},
    ];
  }
}
