import {Component, Inject, OnInit} from '@angular/core';
import {AwsUtil} from "./service-auth/aws.service";
import {CognitoUtil, LoggedInCallback} from "./service-auth/cognito.service";
import {StartupService} from "./service-ui/startup.service";
import {NotificationsService} from "./service-data/notifications.service";
import {WindowRef} from "./WindowRef";
import { LOCALE_ID } from '@angular/core';
import {Router} from "@angular/router";
import {LanguagePipe} from "./pipe/language.pipe";
import {SubscriptionService} from "./service-data/subscription.service";
import {BookService} from "./service-data/book.service";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {AndTableFilter} from "./table/AndTableFilter";
import {OrTableFilter} from "./table/OrTableFilter";
import {NumericRangeTableFilter} from "./table/NumericRangeTableFilter";
import {TableFilter} from "./table/TableFilter";
import {StringRangeTableFilter} from "./table/StringRangeTableFilter";
import {NumericTableFilter} from "./table/NumericTableFilter";
import {DateTableFilter} from "./table/DateTableFilter";
import {DateRangeTableFilter} from "./table/DateRangeTableFilter";
import {NotTableFilter} from "./table/NotTableFilter";
import {DataEnumTableFilter} from "./table/DataEnumTableFilter";
import {CompositeStringTableFilter} from "./table/CompositeStringTableFilter";
import {PackageService} from "./service-data/package.service";
import {PrintNodeOrderService} from "./service-data/print-node-order.service";
import {PrintNodeOrderItemService} from "./service-data/print-node-order-item.service";
import {OrderItemService} from "./service-data/order-item.service";
import {ManufacturingSetService} from "./service-data/manufacturing-set.service";
import {ManufacturingTaskService} from "./service-data/manufacturing-task.service";
import {IssueService} from "./service-data/issue.service";
import {FacilityInventoryProductService} from "./service-data/facility-inventory-product.service";
import {ProductService} from "./service-data/product.service";
import {OrderItemToPackageService} from "./service-data/order-item-to-package.service";
import {BodyService} from "./service-data/body.service";
import {CoverService} from "./service-data/cover.service";
import {ResourceService} from "./service-data/resource.service";
import {FacilityInvoiceService} from "./service-data/facility-invoice.service";
import {PackageTypeService} from "./service-data/package-type.service";
import {OrganizationService} from "./service-data/organization.service";
import {ManufacturingTaskBucketPeriodService} from "./service-data/manufacturing-task-bucket-period.service";
import {ManufacturingTaskBucketService} from "./service-data/manufacturing-task-bucket.service";
import {ManufacturingResourceService} from "./service-data/manufacturing-resource.service";
import {StringTableFilter} from "./table/StringTableFilter";
import {DraftService} from "./service-data/draft.service";
import {PackageAndShippingComputationService} from "./service-data/package-and-shipping-computation.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, LoggedInCallback {
  // We need these classes to be loaded so we can load them by name later
  // const tableFilter = new TableFilter(undefined);
    // .classMap['StringTableFilter'] = StringTableFilter;
  // public stringTableFilter: StringTableFilter;
  // public andTableFilter: AndTableFilter;
  // public orTableFilter: OrTableFilter;
  // public numericRangeTableFilter: NumericRangeTableFilter;

  constructor(public startupService: StartupService,
              public router: Router,
              public notificationsService: NotificationsService,
              private packageAndShippingComputationService: PackageAndShippingComputationService,
              private packageService: PackageService,
              private printNodeOrderService: PrintNodeOrderService,
              private printNodeOrderItemService: PrintNodeOrderItemService,
              private orderItemService: OrderItemService,
              private manufacturingSetService: ManufacturingSetService,
              private manufacturingTaskService: ManufacturingTaskService,
              private manufacturingTaskBucketService: ManufacturingTaskBucketService,
              private manufacturingTaskBucketPeriodService: ManufacturingTaskBucketPeriodService,
              private manufacturingResourceService: ManufacturingResourceService,
              private issueService: IssueService,
              private facilityInventoryProductService: FacilityInventoryProductService,
              private productService: ProductService,
              private orderItemToPackageService: OrderItemToPackageService,
              private bodyService: BodyService,
              private coverService: CoverService,
              private resourceService: ResourceService,
              private bookService: BookService,
              private organizationService: OrganizationService,
              private facilityInvoiceService: FacilityInvoiceService,
              private packageTypeService: PackageTypeService,
              private draftService: DraftService,
              public cognitoUtil: CognitoUtil,
              public awsUtil: AwsUtil,
              public winRef: WindowRef,
              @Inject(LOCALE_ID) locale: string,
              public subscriptionService: SubscriptionService,
              public titleService: Title,
              public sanitizer: DomSanitizer ) {
    TableFilter.classMap['String'] = new StringTableFilter({ type: 'String', fieldName: 'title', value: 'title' });
    TableFilter.classMap['String'] = TableFilter.classMap['String'].constructor;
    TableFilter.classMap['CompositeString'] = new CompositeStringTableFilter({ type: 'CompositeString', fieldName: 'title', value: 'title' });
    TableFilter.classMap['CompositeString'] = TableFilter.classMap['CompositeString'].constructor;
    TableFilter.classMap['StringRange'] = new StringRangeTableFilter({ type: 'StringRange', fieldName: 'title', startValue: 'title1', endValue: 'title2' });
    TableFilter.classMap['StringRange'] = TableFilter.classMap['StringRange'].constructor;
    TableFilter.classMap['Date'] = new DateTableFilter({ type: 'Date', fieldName: 'dueDate', value: '2020/01/01' });
    TableFilter.classMap['Date'] = TableFilter.classMap['Date'].constructor;
    TableFilter.classMap['DateRange'] = new DateRangeTableFilter({ type: 'DateRange', fieldName: 'dueDate', startValue: '2019/01/01', endValue: '2020/01/01' });
    TableFilter.classMap['DateRange'] = TableFilter.classMap['DateRange'].constructor;
    TableFilter.classMap['Not'] = new NotTableFilter({ type: 'Not', child: { type: 'String', fieldName: 'title', value: 'title' }});
    TableFilter.classMap['Not'] = TableFilter.classMap['Not'].constructor;
    TableFilter.classMap['Numeric'] = new NumericTableFilter({ type: 'Numeric', fieldName: 'quantity', value: 1});
    TableFilter.classMap['Numeric'] = TableFilter.classMap['Numeric'].constructor;
    TableFilter.classMap['NumericRange'] = new NumericRangeTableFilter({ type: 'NumericRange', fieldName: 'quantity', startValue: 1, endValue: 100 });
    TableFilter.classMap['NumericRange'] = TableFilter.classMap['NumericRange'].constructor;
    TableFilter.classMap['Or'] = new OrTableFilter({ type: 'Or', children: [{ type: 'String', fieldName: 'title', value: 'title' }, { type: 'String', fieldName: 'title', value: 'title' }]});
    TableFilter.classMap['Or'] = TableFilter.classMap['Or'].constructor;
    TableFilter.classMap['And'] = new AndTableFilter({ type: 'And', children: [{ type: 'String', fieldName: 'title', value: 'title' }, { type: 'String', fieldName: 'title', value: 'title' }]});
    TableFilter.classMap['And'] = TableFilter.classMap['And'].constructor;
    TableFilter.classMap['DataEnum'] = new DataEnumTableFilter({ type: 'DataEnum', fieldName: 'carrier', value: '', values: ['UPS', 'USPS'] } );
    TableFilter.classMap['DataEnum'] = TableFilter.classMap['DataEnum'].constructor;

    TableFilter.create({ type: 'NumericRange', fieldName: 'quantity', startValue: 1, endValue: 100 });
    localStorage.setItem('localeId', locale);
    console.log('locale set to:' + locale);
    console.log("AppComponent: constructor");
    const x = new NumericRangeTableFilter({ type: 'NumericRange', fieldName: 'quantity', startValue: 1, endValue: 100 });
  }

  ngOnInit() {
    console.log("AppComponent: Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this); // not sure if this should be here or not
    const startupS = this.startupService;
    const languageStringElements = document.getElementsByClassName('languageStringText');
    startupS.dir = 'ltr';
    const _this = this;
    Array.prototype.filter.call(languageStringElements, function(languageStringElement) {
      const languageKey = languageStringElement.id.substring('languageStringText'.length);
      const languageValue = languageStringElement.innerText;
      LanguagePipe.add(languageKey, languageValue);
      if ( languageKey === 'dir') {
        startupS.dir = languageValue;
      }
      if ( languageKey === 'Main Organization Name') {
        _this.titleService.setTitle( languageValue );
      }
      // if ( languageKey === 'limitToOnlyCSVAndOrderHistory' && languageValue === 'limit') {
      //   startupS.limitToOnlyCSVAndOrderHistory = true;
      // }
      if ( languageKey === 'showLanguagesOptions' && languageValue === 'hide') {
        startupS.showLanguagesOptions = false;
      }
      // if ( languageKey === 'backgroundImage') {
      //   const bodyElements = document.getElementsByTagName("body");
      //   Array.prototype.filter.call(bodyElements, function(bodyElement) {
      //     // bodyElement.style.backgroundImage = _this.sanitizer.bypassSecurityTrustStyle(languageValue);
      //     // bodyElement.style.background = _this.sanitizer.bypassSecurityTrustStyle(languageValue);
      //     bodyElement.class = languageValue;
      //     // bodyElement.style.background = '#ff0000';
      //   });
      // }
      // console.log("LanguageString: " + languageKey + " " + languageValue );
    });
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    const notificationServiceLocal = this.notificationsService;
    if ( isLoggedIn ) {
      console.log("AppComponent isLoggedIn - " + isLoggedIn + " - " + message);
      console.log("AppComponent: the user is authenticated: " + isLoggedIn);
      const mythis = this;
      this.cognitoUtil.getIdToken({
        callback() {

        },
        callbackWithParam(token: any) {
          // Include the passed-in callback here as well so that it's executed downstream
          console.log("AppComponent: calling initAwsService in callback");
          mythis.awsUtil.initAwsService(null, isLoggedIn, token);
          // notificationServiceLocal.startNotification();
        }
      });
      // this.themeService.getCoverThemes().subscribe( coverThemes => {
      //   this.startupService.setCoverThemes(coverThemes);
      // });

    } else {
      console.log("AppComponent isLoggedIn - " + isLoggedIn + " - " + message);
      this.router.navigate(['/home/login']);  }
  }
}

