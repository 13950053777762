<table id="bookPages" style="margin-left: auto; margin-right: auto;">
  <tbody>
  <tr style="margin-top: 50px;">
    <td id="evenPageWrapper">
<!--      <div ID="evenPage" class="bookPage" [style]="this.leftPageNumber === 0 ? 'width: 621px; height: 810px; url(images/noPageTile.png) repeat' : 'width: 621px; height: 810px; background-position: initial; background-size: initial; background-repeat: repeat; background-attachment: initial; background-origin: initial; background-clip: initial; background-color: white;'">-->
      <div id="evenPage" class="bookPage" style="width: 621px; height: 810px; background-position: initial; background-size: initial; background-repeat: repeat; background-attachment: initial; background-origin: initial; background-clip: initial">
        <div class="clippingRect">
          <img #leftImage id="evenPageImage" style="display: block; position: absolute; top: 9px; right: 0px; width: 612px; height: 792px;" src="">
          <img id="evenPageGutter" class="pageGutter" src="assets/images/evenPageGutter.png" style="width: 21.6px;">
          <div id="evenPage_outerVertMargin" class="margin vertMargin" style="left: 44px;"></div>
          <div id="evenPage_innerVertMargin" class="margin vertMargin" style="right: 35px;"></div>
          <div id="evenPage_topHorMargin" class="margin horMargin" style="top: 44px;"></div>
          <div id="evenPage_bottomHorMargin" class="margin horMargin" style="bottom: 44px;"></div>

          <div id="evenPage_outerVertCut" class="cutLine vertCut" style="left: 8px;"></div>
          <div id="evenPage_topHorCut" class="cutLine horCut" style="top: 8px;"></div>
          <div id="evenPage_bottomHorCut" class="cutLine horCut" style="bottom: 8px;"></div>

          <div id="evenPage_topDangerZone" class="dangerZone dangerZoneHor" style="top: 0px;"></div>
          <div id="evenPage_bottomDangerZone" class="dangerZone dangerZoneHor" style="bottom: 0px;"></div>
          <div id="evenPage_leftDangerZone" class="dangerZone dangerZoneVert" style="height: 778px; top: 16px;"></div>

          <img id="ruler_hor_even" class="ruler" src="assets/images/ruler.gif" style="left: -4px; width: 869px; height: 18px;">
          <img id="ruler_vert_even" class="rulerVertLeft" src="assets/images/ruler_vertLeft.gif" style="bottom: -4px; right: 0px; width: 18px; height: 869px;">
          <img id="hideRuler_even" class="hideRuler" src="assets/images/hideRuler.gif" style="position: absolute; top: 0px; right: 0px; width: 18px; height: 18px;">
        </div>
<!--        <img ID="pageBackward" alt="Previous Pages" title="Back" src="assets/images/pageBackward.png" onclick="bookBody.pages.pageImages.decrementCurrentPages();">-->
        <button id="evenPageShiftUp" mat-icon-button (click)="draft.draftBody.leftVerticalShift = draft.draftBody.leftVerticalShift - 9"><mat-icon i18n-title="@@Shift page up" title="Shift page up" color="primary">arrow_upward</mat-icon></button>
        <button id="evenPageShiftDown" mat-icon-button (click)="draft.draftBody.leftVerticalShift = draft.draftBody.leftVerticalShift + 9"><mat-icon i18n-title="@@Shift page down" title="Shift page down" color="primary">arrow_downward</mat-icon></button>
        <button id="evenPageShiftLeft" mat-icon-button (click)="draft.draftBody.leftHorizontalShift = draft.draftBody.leftHorizontalShift - 9"><mat-icon i18n-title="@@Shift page left" title="Shift page left" color="primary">arrow_back</mat-icon></button>
        <button id="evenPageShiftRight" mat-icon-button (click)="draft.draftBody.leftHorizontalShift = draft.draftBody.leftHorizontalShift + 9"><mat-icon i18n-title="@@Shift page right" title="Shift page right" color="primary">arrow_forward</mat-icon></button>
      </div>
    </td>
    <td id="oddPageWrapper">
      <div id="oddPage" class="bookPage" style="width: 621px; height: 810px;">
        <div class="clippingRect">
          <img #rightImage id="oddPageImage" style="display: block; position: absolute; top: 9px; left: 0px; width: 612px; height: 792px;" src="">
          <img id="oddPageGutter" class="pageGutter" src="assets/images/oddPageGutter.png" style="width: 21.6px;">
          <div id="oddPage_outerVertMargin" class="margin vertMargin" style="right: 44px;"></div>
          <div id="oddPage_innerVertMargin" class="margin vertMargin" style="left: 35px;"></div>
          <div id="oddPage_topHorMargin" class="margin horMargin" style="top: 44px;"></div>
          <div id="oddPage_bottomHorMargin" class="margin horMargin" style="bottom: 44px;"></div>

          <div id="oddPage_outerVertCut" class="cutLine vertCut" style="right: 8px;"></div>
          <div id="oddPage_topHorCut" class="cutLine horCut" style="top: 8px;"></div>
          <div id="oddPage_bottomHorCut" class="cutLine horCut" style="bottom: 8px;"></div>

          <div id="oddPage_topDangerZone" class="dangerZone dangerZoneHor" style="top: 0px;"></div>
          <div id="oddPage_bottomDangerZone" class="dangerZone dangerZoneHor" style="bottom: 0px;"></div>
          <div id="oddPage_rightDangerZone" class="dangerZone dangerZoneVert" style="right: 0px; height: 778px; top: 16px;"></div>

          <img id="ruler_hor_odd" class="ruler" src="/assets/images/ruler.gif" style="left: -4px; width: 869px; height: 18px;">
          <img id="ruler_vert_odd" class="ruler" src="/assets/images/ruler_vertRight.gif" style="top: -4px; left: 0px; width: 18px; height: 869px;">
          <img id="hideRuler_odd" class="hideRuler" src="/assets/images/hideRuler.gif" style="position: absolute; top: 0px; left: 0px; width: 18px; height: 18px;">
        </div>
<!--        <img ID="pageForward" alt="Next Pages" title="Forward" src="/assets/images/pageForward.png" onclick="bookBody.pages.pageImages.incrementCurrentPages();">-->
        <button id="oddPageShiftUp" mat-icon-button (click)="draft.draftBody.rightVerticalShift = draft.draftBody.rightVerticalShift - 9"><mat-icon i18n-title="@@Shift page up" title="Shift page up" color="primary">arrow_upward</mat-icon></button>
        <button id="oddPageShiftDown" mat-icon-button (click)="draft.draftBody.rightVerticalShift = draft.draftBody.rightVerticalShift + 9"><mat-icon i18n-title="@@Shift page down" title="Shift page down" color="primary">arrow_downward</mat-icon></button>
        <button id="oddPageShiftLeft" mat-icon-button (click)="draft.draftBody.rightHorizontalShift = draft.draftBody.rightHorizontalShift - 9"><mat-icon i18n-title="@@Shift page left" title="Shift page left" color="primary">arrow_back</mat-icon></button>
        <button id="oddPageShiftRight" mat-icon-button (click)="draft.draftBody.rightHorizontalShift = draft.draftBody.rightHorizontalShift + 9"><mat-icon i18n-title="@@Shift page right" title="Shift page right" color="primary">arrow_forward</mat-icon></button>
      </div>
    </td>
    <td>
    </td>
  </tr>
  </tbody>
</table>
