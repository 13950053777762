import {CoverThemeItemSetting} from "./CoverThemeItemSetting";
import {ColorPalette} from "../color/ColorPalette";
import {FontSettings} from "./FontSettings";

export class CoverTheme {
  public name: string;
  public id: string;
  public version: number;
  public colorPalette: ColorPalette;
  public fontSettings: FontSettings;
  public objects: CoverThemeItemSetting[];
  public colorPalettes: ColorPalette[] = [];
  public fontSettingsList: FontSettings[] = [];

  constructor (obj) {
    this.version = 0.0;
    if (obj) {
      this.id = obj.id;
      this.name = obj.name;
      if ( obj.version ) {
        this.version = obj.version;
      }
      this.colorPalette = new ColorPalette(obj.colorPalette);
      this.fontSettings = new FontSettings(obj.fontSettings);
      this.objects = [];
      let orderId = 100;
      if (obj.objects) {
        for (const o of obj.objects) {
          const coverThemeItemSetting = new CoverThemeItemSetting(o, orderId);
          orderId++;
          this.objects.push(coverThemeItemSetting);
        }
      }
      if (obj.colorPalettes) {
        for (const o of obj.colorPalettes) {
          const colorPalette = new ColorPalette(o);
          this.colorPalettes.push(colorPalette);
        }
      }
      if (obj.fontSettingsList) {
        for (const o of obj.fontSettingsList) {
          const fontSettings = new FontSettings(o);
          this.fontSettingsList.push(fontSettings);
        }
      }
    } else {
      this.id = '';
      this.name = '';
      this.colorPalette = new ColorPalette(undefined);
      this.fontSettings = new FontSettings(undefined);
      this.objects = [];
    }
    if ( this.version < 0.1 ) {
      this.removeDuplicateRoles();
      this.normalizeRoles();
      this.version = 0.1;
    }
  }

  addColorPalette( colorPalette: ColorPalette ) {
    const index = this.colorPalettes.indexOf( colorPalette );
    if ( index !== -1 ) {
      return;
    }
    colorPalette.name = Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16);
    this.colorPalettes.push( new ColorPalette(colorPalette) );
  }

  removeColorPalette( colorPalette: ColorPalette ) {
    const index = this.colorPalettes.indexOf( colorPalette );
    if ( index === -1 ) {
      return;
    }
    this.colorPalettes.splice(index, 1 );
  }

  addFontSettings( fontSettings: FontSettings ) {
    const index = this.fontSettingsList.indexOf( fontSettings );
    if ( index !== -1 ) {
      return;
    }
    fontSettings.id = Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16);

    this.fontSettingsList.push( new FontSettings(fontSettings) );
  }

  removeFontSettings( fontSettings: FontSettings ) {
    const index = this.fontSettingsList.indexOf( fontSettings );
    if ( index === -1 ) {
      return;
    }
    this.fontSettingsList.splice( index, 1 );
  }

  getActiveImageKeysByRole(): any {
    const activeImages = {};
    for (const themeItem of this.objects) {
      if (themeItem.getType() === 'image' && themeItem.isActive() ) { // TODO: Warning if a role becomes active the images for it will not be loaded but the Cover will load more quickly
        if ( themeItem.imageOptions && themeItem.imageOptions.length > 0 && themeItem.imageOptions[0].imageKey ) {
          activeImages[themeItem.getRole()] = themeItem.imageOptions[0].imageKey;
        }

      }
    }
    return activeImages;
  }

  getCoverThemeItemSetting (role: string): CoverThemeItemSetting {
    for (const themeItem of this.objects) {
      if (role === themeItem.getRole()) {
        return themeItem;
      }
    }
    return undefined;
  }

  getRolesAndImageKeys (): object {
    const rolesAndImageKeys = {};
    for (const coverThemeItemSetting of this.objects) {
      if (coverThemeItemSetting.getType() === 'image' && coverThemeItemSetting.getRole()) {
        if ( coverThemeItemSetting.imageOptions && coverThemeItemSetting.imageOptions.length > 0 ) {
          rolesAndImageKeys[coverThemeItemSetting.getRole()] = coverThemeItemSetting.imageOptions[0];
        }
      }
    }
    return rolesAndImageKeys;
  }

  addCoverThemeItemSetting (role: string, type: string, coverArea: string): CoverThemeItemSetting {
    let coverThemeItemSetting = this.getCoverThemeItemSetting(role);
    if (!coverThemeItemSetting) {
      coverThemeItemSetting = new CoverThemeItemSetting(undefined, 0);
      coverThemeItemSetting.setRole(role);
      coverThemeItemSetting.setType(type);
      coverThemeItemSetting.setCoverArea(coverArea);
      coverThemeItemSetting.setActive('inactive');
      coverThemeItemSetting.setHidden(true);
      // This does not do anything relative to position -- which is important for layering
      this.objects.push(coverThemeItemSetting);
    }
    return coverThemeItemSetting;
  }

  removeDuplicateRoles() {
    const roles = {};
    for ( const themeItem of this.objects ) {
      if ( roles[themeItem.getRole()] ) {
        if ( themeItem.isActive() ) {
           if ( roles[themeItem.getRole()].isActive() ) {
             const index = this.objects.indexOf(themeItem);
             if (index >= 0) {
               console.log('Removing duplicate role[' + themeItem.getRole() + '] ' + themeItem.type + '] in theme[' + this.id + '][' + this.name + ']');
               this.objects.splice(index, 1);
             }
           } else {
             const index = this.objects.indexOf(roles[themeItem.getRole()]);
             if (index >= 0) {
               console.log('Removing duplicate role[' + themeItem.getRole() + '] ' + themeItem.type + '] in theme[' + this.id + '][' + this.name + ']');
               this.objects.splice(index, 1);
             }
           }
        } else {
          const index = this.objects.indexOf(themeItem);
          if (index >= 0) {
            console.log('Removing duplicate role[' + themeItem.getRole() + '] ' + themeItem.type + '] in theme[' + this.id + '][' + this.name + ']');
            this.objects.splice(index, 1);
          }
        }

      } else {
        // console.log('Logging role[' + themeItem.getRole() + '] in theme[' + this.ID + '][' + this.name + ']')
        roles[themeItem.getRole()] = themeItem;
      }
    }
  }

  normalizeRoles () {
    const standardizedRoles = {
      "whole-hidden-image": { "type": "rectangle", "area": "whole"},
      "whole-background": { "type": "rectangle", "area": "whole"},
      "whole-background-image": { "type": "image", "area": "whole"},
      "whole-rectangle-1": { "type": "rectangle", "area": "whole"},
      "whole-image-1": { "type": "image", "area": "whole"},
      "whole-image-2": { "type": "image", "area": "whole"},
      "whole-image-3": { "type": "image", "area": "whole"},
      "whole-line-1": { "type": "line", "area": "whole"},
      "whole-line-2": { "type": "line", "area": "whole"},
      "whole-rectangle-2": {"type": "rectangle", "area": "whole"},
      "whole-rectangle-3": { "type": "rectangle", "area": "whole"},
      "front-background": { "type": "rectangle", "area": "front"},
      "front-background-image": { "type": "image", "area": "front"},
      "front-rectangle-1": { "type": "rectangle", "area": "front"},
      "front-rectangle-2": { "type": "rectangle", "area": "front"},
      "front-rectangle-3": { "type": "rectangle", "area": "front"},
      "front-rectangle-4": { "type": "rectangle", "area": "front"},
      "front-circle-1": { "type": "circle", "area": "front"},
      "front-circle-2": { "type": "circle", "area": "front"},
      "front-image-1": { "type": "image", "area": "front"},
      "front-image-2": { "type": "image", "area": "front"},
      "front-border-image": { "type": "image", "area": "front"},
      "front-frame-image": { "type": "rectangle", "area": "front"},
      "front-line": { "type": "line", "area": "front"},
      "front-line-behind-text": { "type": "line", "area": "front"},
      "front-title-1": { "type": "textbox", "area": "front"},
      "front-title-2": { "type": "textbox", "area": "front"},
      "front-title-3": { "type": "textbox", "area": "front"},
      "front-title-4": { "type": "textbox", "area": "front"},
      "front-text-1": { "type": "textbox", "area": "front"},
      "front-text-2": { "type": "textbox", "area": "front"},
      "front-text-3": { "type": "textbox", "area": "front"},
      "front-text-4": { "type": "textbox", "area": "front"},
      "front-author-1": { "type": "textbox", "area": "front"},
      "front-author-2": { "type": "textbox", "area": "front"},
      "front-author-3": { "type": "textbox", "area": "front"},
      "front-series": { "type": "textbox", "area": "front"},
      "front-publisher-1": { "type": "textbox", "area": "front"},
      "front-publisher-2": { "type": "textbox", "area": "front"},
      "front-volume": { "type": "textbox", "area": "front"},
      "front-tag-line-1": { "type": "textbox", "area": "front"},
      "front-tag-line-2": { "type": "textbox", "area": "front"},
      "front-tag-line-3": { "type": "textbox", "area": "front"},
      "front-edition": { "type": "textbox", "area": "front"},
      "front-category": { "type": "textbox", "area": "front"},
      "front-forward-by": {"type": "textbox", "area": "front"},
      "front-introduction-by": {"type": "textbox", "area": "front"},
      "front-transparent-image": {"type": "image", "area": "front"},
      "front-publisher-logo": {"type": "image", "area": "front"},
      "spine-background": {"type": "rectangle", "area": "spine"},
      "spine-background-image": {"type": "image", "area": "spine"},
      "spine-rectangle-1": {"type": "rectangle", "area": "spine"},
      "spine-rectangle-2": {"type": "rectangle", "area": "spine"},
      "spine-image-1": {"type": "image", "area": "spine"},
      "spine-image-2": {"type": "image", "area": "spine"},
      "spine-publisher-logo": {"type": "image", "area": "spine"},
      "spine-title-1": {"type": "textbox", "area": "spine"},
      "spine-title-2": {"type": "textbox", "area": "spine"},
      "spine-title-3": {"type": "textbox", "area": "spine"},
      "spine-title-4": {"type": "textbox", "area": "spine"},
      "spine-author-1": {"type": "textbox", "area": "spine"},
      "spine-author-2": {"type": "textbox", "area": "spine"},
      "spine-series": {"type": "textbox", "area": "spine"},
      "spine-volume": {"type": "textbox", "area": "spine"},
      "spine-line-behind-text": {"type": "textbox", "area": "spine"},
      "back-background": {"type": "rectangle", "area": "back"},
      "back-background-image": {"type": "image", "area": "back"},
      "back-rectangle-1": {"type": "rectangle", "area": "back"},
      "back-rectangle-2": {"type": "rectangle", "area": "back"},
      "back-rectangle-3": {"type": "rectangle", "area": "back"},
      "back-rectangle-4": {"type": "rectangle", "area": "back"},
      "back-rectangle-5": {"type": "rectangle", "area": "back"},
      "back-rectangle-6": {"type": "rectangle", "area": "back"},
      "back-image-1": {"type": "image", "area": "back"},
      "back-image-2": {"type": "image", "area": "back"},
      "back-transparent-image": {"type": "image", "area": "back"},
      "back-publisher-logo-1": {"type": "image", "area": "back"},
      "back-publisher-logo-2": {"type": "image", "area": "back"},
      "back-edition": {"type": "textbox", "area": "back"},
      "back-category": {"type": "textbox", "area": "back"},
      "back-line-1": {"type": "line", "area": "back"},
      "back-line-2": {"type": "line", "area": "back"},
      "back-line-3": {"type": "line", "area": "back"},
      "back-line-4": {"type": "line", "area": "back"},
      "back-title-1": {"type": "textbox", "area": "back"},
      "back-title-2": {"type": "textbox", "area": "back"},
      "back-title-3": {"type": "textbox", "area": "back"},
      "back-title-4": {"type": "textbox", "area": "back"},
      "back-author-1": {"type": "textbox", "area": "back"},
      "back-author-2": {"type": "textbox", "area": "back"},
      "back-series": {"type": "textbox", "area": "back"},
      "back-volume": {"type": "textbox", "area": "back"},
      "back-tag-line-1": {"type": "textbox", "area": "back"},
      "back-tag-line-2": {"type": "textbox", "area": "back"},
      "back-author-picture": {"type": "image", "area": "back"},
      "back-price": {"type": "textbox", "area": "back"},
      "back-barcode": {"type": "image", "area": "back"},
      "back-address": {"type": "textbox", "area": "back"},
      "back-phone": {"type": "textbox", "area": "back"},
      "back-ISBN": {"type": "textbox", "area": "back"},
      "back-heading-1": {"type": "textbox", "area": "back"},
      "back-text-1": {"type": "textbox", "area": "back"},
      "back-heading-2": {"type": "textbox", "area": "back"},
      "back-text-2": {"type": "textbox", "area": "back"},
      "back-heading-3": {"type": "textbox", "area": "back"},
      "back-text-3": {"type": "textbox", "area": "back"},
      "back-heading-4": {"type": "textbox", "area": "back"},
      "back-text-4": {"type": "textbox", "area": "back"},
      "back-heading-5": {"type": "textbox", "area": "back"},
      "back-text-5": {"type": "textbox", "area": "back"},
      "back-heading-6": {"type": "textbox", "area": "back"},
      "back-text-6": {"type": "textbox", "area": "back"}
    };
    const starndardizedRolesList = Object.keys(standardizedRoles);
    const currentThemeRolesList = [];
    for (const themeItem of this.objects) {
      currentThemeRolesList.push(themeItem.getRole());
    }

    // Find all the roles in the current theme that are not standardized and find the standardized roles missing from the current theme
    for (const role of Object.keys(standardizedRoles)) {
      const stardardizedRole = standardizedRoles[role];
      const coverThemeItemSetting = this.getCoverThemeItemSetting(role);
      if ( coverThemeItemSetting ) {
        let index = starndardizedRolesList.indexOf(role);
        if ( index >= 0 ) {
          starndardizedRolesList.splice(index, 1);
        }
        index = currentThemeRolesList.indexOf(role);
        if ( index >= 0 ) {
          currentThemeRolesList.splice(index, 1);
        }
      }
    }

    console.log("Reporting on the current theme[" + this.name + "]");
    for ( const missingStandardizedRole of starndardizedRolesList ) {
      console.log("Missing Standardized Role in the current theme[" + this.name + "]: " + missingStandardizedRole );
      this.addCoverThemeItemSetting(missingStandardizedRole, standardizedRoles[missingStandardizedRole].type, standardizedRoles[missingStandardizedRole].area);
    }
    for ( const nonStandardCurrentThemeRole of currentThemeRolesList ) {
      console.log("Non-Standard Role in the current theme[" + this.name + "]: " + nonStandardCurrentThemeRole );
      for (const themeItem of this.objects) {
        if ( themeItem.getRole() === nonStandardCurrentThemeRole ) {
          const index = this.objects.indexOf(themeItem);
          if ( index >= 0 ) {
            this.objects.splice(index, 1);
          }
        }
      }
    }
  }
}



