import {PrintNodeOrderItem} from "../PrintNodeOrderItem";

export class Criteria {
    property: string;
    type: string;
    value: any;
    enumValues: string[] = [];
    rangeBreaks: any;
    normalizedPageData: any;
    min: number = 0;
    max: number = Number.MAX_VALUE;

    constructor (obj) {
        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }
        }
    }

    getValue (printNodeOrderItem: PrintNodeOrderItem) {
        switch (this.property) {
            case 'interiorType': {
                if (printNodeOrderItem.book) {
                    return printNodeOrderItem.book.interiorType;
                } else {
                    return 'ERROR';
                }
            }
            case 'interiorPaperType': {
                if (printNodeOrderItem.book && printNodeOrderItem.book.body) {
                    return printNodeOrderItem.book.body.paperType_ID;
                } else {
                    return 'ERROR';
                }
            }
            case 'bindingType': {
                if (printNodeOrderItem.book) {
                    return printNodeOrderItem.book.bindingType;
                } else {
                    return 'ERROR';
                }
            }
            case 'dueDate': {
                if (printNodeOrderItem.printNodeOrder) {
                    return new Date(printNodeOrderItem.printNodeOrder.dueDate.getUTCFullYear(), printNodeOrderItem.printNodeOrder.dueDate.getUTCMonth(), printNodeOrderItem.printNodeOrder.dueDate.getUTCDate());
                } else {
                    return 'ERROR';
                }
            }
            case 'height': {
                if (printNodeOrderItem.book && printNodeOrderItem.book.body) {
                    return printNodeOrderItem.book.body.height;
                } else {
                    return 'ERROR';
                }
            }
            case 'width': {
                if (printNodeOrderItem.book && printNodeOrderItem.book.body) {
                    return printNodeOrderItem.book.body.width;
                } else {
                    return 'ERROR';
                }
            }
            case 'trimSizeBreaks': {
                if (this.rangeBreaks) {
                    if (printNodeOrderItem.book && printNodeOrderItem.book.body) {
                        for (const prop of Object.keys(this.rangeBreaks)) {
                            const ranges = this.rangeBreaks[prop];
                            if (Number(printNodeOrderItem.book.body.width / 72) >= Number(ranges.aMin) && Number(printNodeOrderItem.book.body.width / 72) <= Number(ranges.aMax) && Number(printNodeOrderItem.book.body.height / 72) >= Number(ranges.bMin) && Number(printNodeOrderItem.book.body.height / 72) <= Number(ranges.bMax)) {
                                return prop;
                            }
                        }
                        return 'OUT OF RANGE';
                    } else {
                        return 'ERROR';
                    }
                } else {
                    return 'ERROR';
                }
            }
            case 'quantity': {
                return printNodeOrderItem.quantity;
            }
            case 'orgId': {
                if (printNodeOrderItem.book) {
                    return printNodeOrderItem.book.org_ID;
                } else {
                    return 'ERROR';
                }
            }
            case 'normalizedPages': {
                if (this.rangeBreaks) {
                    if (printNodeOrderItem.book && printNodeOrderItem.book.body) {
                        let multiplier = 0;
                        for (const prop of Object.keys(this.rangeBreaks)) {
                            const ranges = this.rangeBreaks[prop];
                            if (Number(printNodeOrderItem.book.body.width / 72) >= Number(ranges.aMin) && Number(printNodeOrderItem.book.body.width / 72) <= Number(ranges.aMax) && Number(printNodeOrderItem.book.body.height / 72) >= Number(ranges.bMin) && Number(printNodeOrderItem.book.body.height / 72) <= Number(ranges.bMax)) {
                                multiplier = ranges.multiplier;
                            }
                        }
                        if ( multiplier === 0) {
                            return 'OUT OF RANGE';
                        }
                        return multiplier * printNodeOrderItem.book.body.numPages * printNodeOrderItem.quantity;
                    } else {
                        return 'ERROR';
                    }
                } else {
                    return 'NORMALIZED_PAGES_DATA_MISSING';
                }
                return 1;
            }
            case 'feet': {
                return 1;
            }
            case 'quantityBreaks': {
                if (this.rangeBreaks) {
                    if (printNodeOrderItem.book && printNodeOrderItem.book.body) {
                        const value = printNodeOrderItem.quantity;
                        for (const prop of Object.keys(this.rangeBreaks)) {
                            const ranges = this.rangeBreaks[prop];
                            if (Number(value) >= Number(ranges.min) && Number(value) <= Number(ranges.max)) {
                                return prop;
                            }
                        }
                        return 'OUT OF RANGE';
                    } else {
                        return 'ERROR';
                    }
                } else {
                    return 'ERROR';
                }
            }
            case 'laminateType': {
                if (printNodeOrderItem.book && printNodeOrderItem.book.cover) {
                    return printNodeOrderItem.book.cover.laminateType;
                } else {
                    return 'ERROR';
                }

                break;
            }
            case 'squareInchesOfPrint': {
                if (printNodeOrderItem.book && printNodeOrderItem.book.body) {
                    return (printNodeOrderItem.book.body.height / 72) * (printNodeOrderItem.book.body.width / 72) * printNodeOrderItem.book.body.numPages * printNodeOrderItem.quantity;
                } else {
                    return 'ERROR';
                }

                break;
            }
            case 'squareInchesOfPrintBreaks': {
                if (this.rangeBreaks) {
                    if (printNodeOrderItem.book && printNodeOrderItem.book.body) {
                        const value = (printNodeOrderItem.book.body.height / 72) * (printNodeOrderItem.book.body.width / 72) * printNodeOrderItem.book.body.numPages * printNodeOrderItem.quantity;
                        for (const prop of Object.keys(this.rangeBreaks)) {
                            const ranges = this.rangeBreaks[prop];
                            if (Number(value) >= Number(ranges.min) && Number(value) <= Number(ranges.max)) {
                                return prop;
                            }
                        }
                        return 'OUT OF RANGE';
                    } else {
                        return 'ERROR';
                    }
                } else {
                    return 'ERROR';
                }
            }
        }
        return 'ERROR';
   }


    getValueFromPrintNodeOrderItems(printNodeOrderItems: PrintNodeOrderItem[]): any {
        switch ( this.type) {
            case 'ENUM': {
                const value = new Array();
                for ( const printNodeOrderItem of printNodeOrderItems ) {
                    const v = String(this.getValue(printNodeOrderItem));
                    if ( value.indexOf( v ) === -1 ) {
                        value.push( v );
                    }
                }
                return value;
            }
            case 'NUMERIC': {
                let value = 0;
                for ( const printNodeOrderItem of printNodeOrderItems ) {
                    value += Number(this.getValue(printNodeOrderItem));
                }
                return value;
            }
            case 'DATE': {
                const value = new Array();
                for ( const printNodeOrderItem of printNodeOrderItems ) {
                    const v = this.getValue(printNodeOrderItem);
                    if ( value.indexOf( v ) === -1 ) {
                        value.push( v );
                    }
                }
                return value;
            }
        }
    }

    matchesFilterForPrintNodeOrderItems(printNodeOrderItems: PrintNodeOrderItem[]): boolean {
        const value = this.getValueFromPrintNodeOrderItems(printNodeOrderItems);
        switch ( this.type) {
            case 'ENUM': {
                if ( this.enumValues.indexOf('ANY') !== -1 && this.enumValues.indexOf(String(value)) === -1) {
                    console.log('FALSE Testing Criteria.matchesFilterForPrintNodeOrderItems value: ' + value + ' Criteria: ' + JSON.stringify(this) );
                    return false;
                } else {
                    console.log('TRUE Testing Criteria.matchesFilterForPrintNodeOrderItems value: ' + value + ' Criteria: ' + JSON.stringify(this)  );
                }
                break;
            }
            case 'NUMERIC': {
                if ( Number(value) < Number(this.min) || Number(value) > Number(this.max) ) {
                    console.log('FALSE Testing Criteria.matchesFilterForPrintNodeOrderItems value: ' + value + ' Criteria: ' + JSON.stringify(this) );
                    return false;
                } else {
                    console.log('TRUE Testing Criteria.matchesFilterForPrintNodeOrderItems value: ' + value + ' Criteria: ' + JSON.stringify(this) );
                }
                break;
            }
        }
        return true;
    }
    matchesFilter(printNodeOrderItem: PrintNodeOrderItem): boolean {
        const value = this.getValue(printNodeOrderItem);
        switch ( this.type) {
            case 'ENUM': {
                if ( this.enumValues.indexOf('ANY') !== -1 && this.enumValues.indexOf(String(value)) === -1) {
                    console.log('FALSE Testing Criteria.matchesFilter value: ' + value + ' Criteria: ' + JSON.stringify(this) );
                    return false;
                } else {
                    console.log('TRUE Testing Criteria.matchesFilter value: ' + value + ' Criteria: ' + JSON.stringify(this) );
                }
                break;
            }
            case 'NUMERIC': {
                if ( Number(value) < Number(this.min) || Number(value) > Number(this.max) ) {
                    console.log('FALSE Testing Criteria.matchesFilter value: ' + value + ' Criteria: ' + JSON.stringify(this) );
                    return false;
                } else {
                    console.log('TRUE Testing Criteria.matchesFilter value: ' + value + ' Criteria: ' + JSON.stringify(this) );
                }
                break;
            }
        }
        return true;
    }
    matchesFilterValues(printNodeOrderItem: PrintNodeOrderItem): boolean {
        const value = this.getValue(printNodeOrderItem);
        switch ( this.type) {
            case 'ENUM': {
                if ( this.enumValues.indexOf('ANY') !== -1 && this.enumValues.indexOf(String(value)) === -1) {
                    console.log('FALSE Testing Criteria.matchesFilterValues value: ' + value + ' Criteria: ' + JSON.stringify(this) );
                    return false;
                } else {
                    console.log('TRUE Testing Criteria.matchesFilterValues value: ' + value + ' Criteria: ' + JSON.stringify(this) );
                }
                break;
            }
            case 'DATE': {
                if ( this.value instanceof Date && value instanceof Date ) {
                    if ( value > this.value || value < this.value ) {
                        return false;
                    }
                } else {
                    if ( this.value !== value) {
                        return false;
                    }
                }
                break;
            }
            case 'NUMERIC': {
                if ( Number(value) < Number(this.min) || Number(value) > Number(this.max) ) {
                    console.log('FALSE Testing Criteria.matchesFilterValues value: ' + value + ' Criteria: ' + JSON.stringify(this) );
                    return false;
                } else {
                    console.log('TRUE Testing Criteria.matchesFilterValues value: ' + value + ' Criteria: ' + JSON.stringify(this) );
                }
                break;
            }
        }
        return true;
    }
}
