import {ManufacturingResourceType} from "./ManufacturingResourceType";
import {ManufacturingStaffType} from "./ManufacturingStaffType";
import {ManufacturingSetRule} from "./ManufacturingSetRule";
import {ManufacturingStepType} from "./ManufacturingStepType";
import {ManufacturingTaskType} from "./ManufacturingTaskType";

export class ManufacturingStaticData {
    manufacturingTaskTypes: ManufacturingTaskType[] = [];
    manufacturingResourceTypes: ManufacturingResourceType[] = [];
    manufacturingStaffTypes: ManufacturingStaffType[] = [];
    manufacturingStepTypes: ManufacturingStepType[] = [];
    manufacturingSetRules: ManufacturingSetRule[] = [];

    constructor ( obj ) {
        if (obj) {
            if (obj.manufacturingTaskTypes) {
                for (const o of obj.manufacturingTaskTypes) {
                    this.manufacturingTaskTypes.push(new ManufacturingTaskType(o));
                }
            }
            if (obj.manufacturingResourceTypes) {
                for (const o of obj.manufacturingResourceTypes) {
                    this.manufacturingResourceTypes.push(new ManufacturingResourceType(o));
                }
            }
            if (obj.manufacturingStaffTypes) {
                for (const o of obj.manufacturingStaffTypes) {
                    this.manufacturingStaffTypes.push(new ManufacturingStaffType(o));
                }
            }
            if (obj.manufacturingStepTypes) {
                for (const o of obj.manufacturingStepTypes) {
                    this.manufacturingStepTypes.push(new ManufacturingStepType(o));
                }
            }
            if (obj.manufacturingSetRules) {
                for (const o of obj.manufacturingSetRules) {
                    this.manufacturingSetRules.push(new ManufacturingSetRule(o));
                }
            }
        }
    }

    public getManufacturingTaskType(name: string): ManufacturingTaskType |  undefined {
        for ( const obj of this.manufacturingTaskTypes ) {
            if ( obj.name === name ) {
                return obj;
            }
        }
        return undefined;
    }

    public getManufacturingResourceType(name: string): ManufacturingResourceType |  undefined {
        for ( const obj of this.manufacturingResourceTypes ) {
            if ( obj.name === name ) {
                return obj;
            }
        }
        return undefined;
    }

    public getManufacturingStaffType(name: string): ManufacturingStaffType |  undefined {
        for ( const obj of this.manufacturingStaffTypes ) {
            if ( obj.name === name ) {
                return obj;
            }
        }
        return undefined;
    }
    public getManufacturingStepType(name: string): ManufacturingStepType |  undefined {
        for ( const obj of this.manufacturingStepTypes ) {
            if ( obj.name === name ) {
                return obj;
            }
        }
        return undefined;
    }
    public getManufacturingSetRule(name: string): ManufacturingSetRule |  undefined {
        for ( const obj of this.manufacturingSetRules ) {
            if ( obj.name === name ) {
                return obj;
            }
        }
        return undefined;
    }

    public initWithDefaults() {
        this.manufacturingTaskTypes.push(new ManufacturingTaskType({ name: 'CoverPrinting'}));
        this.manufacturingTaskTypes.push(new ManufacturingTaskType({ name: 'Laminating'}));
        this.manufacturingTaskTypes.push(new ManufacturingTaskType({ name: 'PrintBindTrimPackShip'}));
        this.manufacturingTaskTypes.push(new ManufacturingTaskType({ name: 'StackerPrint'}));
        this.manufacturingTaskTypes.push(new ManufacturingTaskType({ name: 'CutApart'}));
        this.manufacturingTaskTypes.push(new ManufacturingTaskType({ name: 'ExternalBindTrim'}));
        this.manufacturingTaskTypes.push(new ManufacturingTaskType({ name: 'Bind'}));
        this.manufacturingTaskTypes.push(new ManufacturingTaskType({ name: 'Trim'}));
        this.manufacturingTaskTypes.push(new ManufacturingTaskType({ name: 'Pack'}));
        this.manufacturingTaskTypes.push(new ManufacturingTaskType({ name: 'Ship'}));

        this.manufacturingStepTypes.push(new ManufacturingStepType({ name: 'CoverPrinting'}));
        this.manufacturingStepTypes.push(new ManufacturingStepType({ name: 'Laminating'}));
        this.manufacturingStepTypes.push(new ManufacturingStepType({ name: 'PrintBindTrimPackShip'}));
        this.manufacturingStepTypes.push(new ManufacturingStepType({ name: 'StackerPrint'}));
        this.manufacturingStepTypes.push(new ManufacturingStepType({ name: 'CutApart'}));
        this.manufacturingStepTypes.push(new ManufacturingStepType({ name: 'ExternalBindTrim'}));
        this.manufacturingStepTypes.push(new ManufacturingStepType({ name: 'Bind'}));
        this.manufacturingStepTypes.push(new ManufacturingStepType({ name: 'Trim'}));
        this.manufacturingStepTypes.push(new ManufacturingStepType({ name: 'Pack'}));
        this.manufacturingStepTypes.push(new ManufacturingStepType({ name: 'Ship'}));
        this.manufacturingResourceTypes.push(new ManufacturingResourceType({ name: 'Printer'}));
        this.manufacturingResourceTypes.push(new ManufacturingResourceType({ name: 'Laminator'}));
        this.manufacturingResourceTypes.push(new ManufacturingResourceType({ name: 'Trimmer'}));
        this.manufacturingResourceTypes.push(new ManufacturingResourceType({ name: 'Binder'}));
        this.manufacturingResourceTypes.push(new ManufacturingResourceType({ name: 'Packing'}));
        this.manufacturingResourceTypes.push(new ManufacturingResourceType({ name: 'Shipping'}));
        this.manufacturingResourceTypes.push(new ManufacturingResourceType({ name: 'Transportation'}));
        this.manufacturingResourceTypes.push(new ManufacturingResourceType({ name: 'BindAndTrim'}));
        this.manufacturingStaffTypes.push(new ManufacturingStaffType({ name: 'Production'}));
        this.manufacturingStaffTypes.push(new ManufacturingStaffType({ name: 'Laminator'}));
        this.manufacturingStaffTypes.push(new ManufacturingStaffType({ name: 'Packing'}));
        this.manufacturingStaffTypes.push(new ManufacturingStaffType({ name: 'Shipping'}));
        this.manufacturingStaffTypes.push(new ManufacturingStaffType({ name: 'LineOperator'}));
        // SINGLE_PRINT_NODE_ORDER, EXPANDING
        // 'interiorType':
        // 'interiorPaperType':
        // 'bindingType':
        // 'dueDate':
        // 'height':
        // 'width':
        // 'quantity':
        // 'laminateType':
        // 'squareInchesOfPrint':
        this.manufacturingSetRules.push(new ManufacturingSetRule({
            name: 'Organization',
            type: 'ORGANIZATION',
            criteria: [{
                property: 'normalizedPages',
                rangeBreaks: {
                    _4UP: { aMin: 4, aMax: 6, bMin: 6, bMax: 9, multiplier: 1 },
                    _2UP: { aMin: 4, aMax: 9, bMin: 6, bMax: 12, multiplier: 2 }
                },
                type: 'NUMERIC',
                min: 100000,
                max: 1000000000000
            }],
            setPropertyCriteria: [ {
                property: 'orgId',
                type: 'ENUM',
            }, {
                property: 'dueDate',
                type: 'DATE',
            }, {
                property: 'trimSizeBreaks',
                type: 'EMUM',
                rangeBreaks: {
                    _4UP: { aMin: 4, aMax: 6, bMin: 6, bMax: 9},
                    _2UP: { aMin: 4, aMax: 9, bMin: 6, bMax: 12}
                }
            }, {
                property: 'interiorPaperType',
                type: 'ENUM',
                enumValues: [1, 2, 4],
            }, {
                property: 'bindingType',
                type: 'ENUM',
                enumValues: ['PERFECT', 'COIL'],
            }, {
                property: 'laminateType',
                type: 'ENUM',
                enumValues: ['GLOSS', 'MATTE'],
            } ]
        }));
        this.manufacturingSetRules.push(new ManufacturingSetRule({
            name: 'Single Print Node Order Item B&W',
            type: 'SINGLE_PRINT_NODE_ORDER_ITEM',
            criteria: [ {
                property: 'interiorType',
                type: 'ENUM',
                enumValues: ['GRAY'],
            },
            {
                property: 'squareInchesOfPrint',
                type: 'NUMERIC',
                min: 6 * 9 * 200 * 50,
                max: 1000000000000
            } ],
            setPropertyCriteria: [ ]
        }));
        this.manufacturingSetRules.push(new ManufacturingSetRule({
            name: 'Single Print Node Order Item Color',
            type: 'SINGLE_PRINT_NODE_ORDER_ITEM',
            criteria: [ {
                property: 'interiorType',
                type: 'ENUM',
                enumValues: ['COLOR'],
            }, {
                property: 'squareInchesOfPrint',
                type: 'NUMERIC',
                min: 6 * 9 * 200 * 20,
                max: 1000000000000
            } ],
            setPropertyCriteria: [ ]
        }));
        this.manufacturingSetRules.push(new ManufacturingSetRule({
            name: 'Base Expanding B&W',
            type: 'EXPANDING',
            criteria: [ {
                property: 'interiorType',
                type: 'ENUM',
                enumValues: ['COLOR'],
            }, {
                property: 'squareInchesOfPrint',
                type: 'NUMERIC',
                min: 0,
                max: 6 * 9 * 200 * 20
            } ],
            // 'height':
            // 'width':
            // 'quantity':
            setPropertyCriteria: [ {
                property: 'dueDate',
                type: 'DATE',
                // }, {
                //     property: 'quantityBreaks',
                //     type: 'RANGE',
                //     value: '',
                //     rangeBreaks: {
                //         _1_8: { min: 1, max: 8 },
                //         _9_12: { min: 9, max: 12 },
                //         _13_19: { min: 13, max: 19},
                //         _20_49: { min: 20, max: 49},
                //         _50_99: { min: 50, max: 99},
                //         _100_249: { min: 100, max: 249},
                //         _250_499: { min: 250, max: 499},
                //         _500_999: { min: 500, max: 999},
                //         _1000_100000: { min: 1000, max: 100000}
                //         },
                //     min: 0,
                //     max: 0
            }, {
                property: 'trimSizeBreaks',
                type: 'EMUM',
                rangeBreaks: {
                    _4UP: { aMin: 4, aMax: 6, bMin: 6, bMax: 9},
                    _2UP: { aMin: 4, aMax: 9, bMin: 6, bMax: 12}
                },
            }, {
                property: 'interiorPaperType',
                type: 'ENUM',
                enumValues: [1, 2, 4],
            }, {
                property: 'bindingType',
                type: 'ENUM',
                enumValues: ['PERFECT', 'COIL'],
            }, {
                property: 'laminateType',
                type: 'ENUM',
                enumValues: ['GLOSS', 'MATTE'],
            } ]
        }));
        this.manufacturingSetRules.push(new ManufacturingSetRule({
            name: 'Base Expanding B&W',
            type: 'EXPANDING',
            criteria: [ {
                property: 'interiorType',
                type: 'ENUM',
                enumValues: ['GRAY'],
            }, {
                property: 'squareInchesOfPrint',
                type: 'NUMERIC',
                min: 0,
                max: 6 * 9 * 200 * 50
            } ],
            // 'height':
            // 'width':
            // 'quantity':
            setPropertyCriteria: [ {
                property: 'dueDate',
                type: 'DATE',
            // }, {
            //     property: 'quantityBreaks',
            //     type: 'RANGE',
            //     value: '',
            //     rangeBreaks: {
            //         _1_8: { min: 1, max: 8 },
            //         _9_12: { min: 9, max: 12 },
            //         _13_19: { min: 13, max: 19},
            //         _20_49: { min: 20, max: 49},
            //         _50_99: { min: 50, max: 99},
            //         _100_249: { min: 100, max: 249},
            //         _250_499: { min: 250, max: 499},
            //         _500_999: { min: 500, max: 999},
            //         _1000_100000: { min: 1000, max: 100000}
            //         },
            //     min: 0,
            //     max: 0
            }, {
                property: 'trimSizeBreaks',
                type: 'ENUM',
                rangeBreaks: {
                    _4UP: { aMin: 4, aMax: 6, bMin: 6, bMax: 9},
                    _2UP: { aMin: 4, aMax: 9, bMin: 6, bMax: 12}
                },
            }, {
                property: 'interiorPaperType',
                type: 'ENUM',
                enumValues: [1, 2, 4],
            }, {
                property: 'bindingType',
                type: 'ENUM',
                enumValues: ['PERFECT', 'COIL'],
            }, {
                property: 'laminateType',
                type: 'ENUM',
                enumValues: ['GLOSS', 'MATTE'],
            } ]
        }));

        this.manufacturingSetRules.push(new ManufacturingSetRule({
            name: 'Catch All',
            type: 'CATCHALL',
            criteria: [],
            setPropertyCriteria: []
        }));
   }
}
