import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import {ManufacturingTask} from "../../model/ManufacturingTask";
import {Observable} from "rxjs";
import {of} from "rxjs";
import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {PrintNodeOrderItem} from "../../model/PrintNodeOrderItem";
import {NotificationsService} from "./notifications.service";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {ObjectAttachRequest} from "./data-service";

@Injectable()
export class ManufacturingTaskService extends AbstractLiveDataService<ManufacturingTask> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setManufacturingTaskService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): ManufacturingTask {
    const manufacturingResource = args['manufacturingResource'] ? args['manufacturingResource'] : undefined;
    const manufacturingSet = args['manufacturingSet'] ? args['manufacturingSet'] : undefined;
    const manufacturingWork = args['manufacturingWork'] ? args['manufacturingWork'] : undefined;
    const object = new ManufacturingTask(args, manufacturingResource, manufacturingSet, manufacturingWork);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "ManufacturingTask";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "manufacturingtask";
  }

  getLinkData(): any[] {
    return [
      { action: 'Link', objectIdPropertyName: 'manufacturingset_id', serviceName: 'manufacturingSetService', arrayProperty: 'manufacturingTasks', objectProperty: 'manufacturingSet'},
      { action: 'Link', objectIdPropertyName: 'manufacturingresource_id', serviceName: 'manufacturingResourceService', arrayProperty: 'manufacturingTasks', objectProperty: 'manufacturingResource'},
    ];
  }

  // linkObject(newObject: ManufacturingTask, rawObject: ManufacturingTask) {
  //   if ( rawObject.manufacturingSet ) {
  //     this.notificationsService.addUpdateLocalItem('manufacturingSetService', rawObject.manufacturingSet);
  //   }
  //   if ( newObject.manufacturingset_id ) {
  //     this.notificationsService.addObjectAttachRequest('manufacturingSetService', new ObjectAttachRequest(newObject.manufacturingset_id, 'manufacturingTasks', 'manufacturingSet', newObject));
  //   }
  //   if ( rawObject.manufacturingResource ) {
  //     this.notificationsService.addUpdateLocalItem('manufacturingResourceService', rawObject.manufacturingResource);
  //   }
  //   if ( newObject.manufacturingresource_id ) {
  //     this.notificationsService.addObjectAttachRequest('manufacturingResourceService', new ObjectAttachRequest(newObject.manufacturingresource_id, 'manufacturingTasks', 'manufacturingResource', newObject));
  //   }
  // }

  /** PUT: prepare */
  prepare (manufacturingTask: ManufacturingTask): Observable<ManufacturingTask> {
    const url = `${environment.apiBaseURL}manufacturingtask/prepare/${manufacturingTask.ID}`;
    this.messageService.add(`ManufacturingTask prepare: manufacturingTaskId=${manufacturingTask.ID}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(changedManufacturingTask => {
          const m = new ManufacturingTask(changedManufacturingTask, manufacturingTask.manufacturingResource,  manufacturingTask.manufacturingSet,  manufacturingTask.manufacturingWork);
          return m;
        }),
        catchError(this.handleErrorT<any>('setManufacturingProcess'))
      );
  }

  /** PUT: print */
  print (manufacturingTask: ManufacturingTask, printer: string): Observable<ManufacturingTask> {
    const url = `${environment.apiBaseURL}manufacturingtask/print/${manufacturingTask.ID}/${printer}`;
    this.messageService.add(`ManufacturingTask print: manufacturingTaskId=${manufacturingTask.ID}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(changedManufacturingTask => {
          const m = new ManufacturingTask(changedManufacturingTask, manufacturingTask.manufacturingResource,  manufacturingTask.manufacturingSet,  manufacturingTask.manufacturingWork);
          return m;
        }),
        catchError(this.handleErrorT<any>('setManufacturingProcess'))
      );
  }

  /** PUT: reprint */
  reprint (manufacturingTask: ManufacturingTask, printNodeOrderItem: PrintNodeOrderItem, printer: string): Observable<ManufacturingTask> {
    const url = `${environment.apiBaseURL}manufacturingtask/reprint/${manufacturingTask.ID}/${printNodeOrderItem.book_ID}/${printer}`;
    this.messageService.add(`ManufacturingTask reprintbook: manufacturingTaskId=${manufacturingTask.ID} ${printNodeOrderItem.book_ID} `);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(changedManufacturingTask => {
          const m = new ManufacturingTask(changedManufacturingTask, manufacturingTask.manufacturingResource,  manufacturingTask.manufacturingSet,  manufacturingTask.manufacturingWork);
          return m;
        }),
        catchError(this.handleErrorT<any>('reprint'))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleErrorT<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
