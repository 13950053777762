import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../model/User";
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PickSheetService} from "../../../service-data/pick-sheet.service";
import {FacilityService} from "../../../service-data/facility.service";
import {StartupService} from "../../../service-ui/startup.service";
import {ShippingService} from "../../../service-data/shipping.service";
import {Facility} from "../../../../model/Facility";

@Component({
  selector: 'app-fulfilment-order',
  templateUrl: './fulfillment-order.component.html',
  styleUrls: ['./fulfillment-order.component.css']
})
export class FulfillmentOrderComponent implements OnInit {
  @Input() printNodeOrder: PrintNodeOrder;

  constructor(private startupService: StartupService, private pickSheetService: PickSheetService, private facilityService: FacilityService, private shippingService: ShippingService) { }

  ngOnInit() {
  }

  printPickSheet ( printNodeOrder: PrintNodeOrder, printer: string ) {
    if ( this.startupService.selectedRole === 'facility') {
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe( facility => {
        const printNodeOrderIds = [];
        printNodeOrderIds.push(printNodeOrder.ID);
        this.pickSheetService.createPickAndShipSheets (facility, printNodeOrderIds, printer, 0).subscribe( result => {
        // this.pickSheetService.createPickSheet(facility, printNodeOrder, printer).subscribe( result => {
          console.log(`createdPickSheet for printNodeOrder: ${printNodeOrder.ID} ${printer}`);
        });
      });
    }
  }

  printShippingLabels(printNodeOrder: PrintNodeOrder, shippingCarrierAndService: string, printer: string) {
    if ( this.startupService.selectedRole === 'facility') {
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe( facility => {
        this.shippingService.createShippingLabels(facility, printNodeOrder, shippingCarrierAndService, printer).subscribe( result => {
          console.log(`createdShipping documents for printNodeOrder: ${printNodeOrder.ID} ${shippingCarrierAndService} ${printer}`);
        });
      });
    }
  }
  printFreightAddressSheets(printNodeOrder: PrintNodeOrder, printer: string) {
    if ( this.startupService.selectedRole === 'facility') {
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe( facility => {
        this.shippingService.createFreightAddressSheets(facility, printNodeOrder, printer).subscribe( result => {
          console.log(`createdShipping documents for printNodeOrder: ${printNodeOrder.ID} ${printer}`);
        });
      });
    }
  }
}
