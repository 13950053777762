import {environment} from "../../environments/environment";
import {Callback, CognitoUtil} from './cognito.service';
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import {Observable} from "rxjs";
import {of} from "rxjs";
import { Inject, Injectable } from "@angular/core";
import {CognitoUser} from "amazon-cognito-identity-js";

@Injectable()
export class S3Service {

    constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil) {

    }

    public getS3(): any {
        AWS.config.update({
            region: environment.bucketRegion,
        });

        const clientParams: any = {
            region: environment.bucketRegion,
            apiVersion: '2006-03-01',
            params: {Bucket: environment.dataBucket}
        };
        if (environment.s3_endpoint) {
            clientParams.endpoint = environment.s3_endpoint;
        }
        const s3 = new S3(clientParams);

        return s3;
    }

    public getUsername(): string {
      const currentUser: CognitoUser = this.cognitoUtil.getCurrentUser();
      if ( currentUser ) {
        return 'cognito-' + currentUser.getUsername();
      }
      return 'no-current-user';
    }

    public getObject<T>(key: string): Observable<T> {
      let ret: T;

      this.getS3().getObject({
        Bucket: environment.dataBucket,
        Key: key
      }, function (err, data) {
        if (err) {
          console.log('There was an error getting content: ', err);
          ret = null;
        } else {
          console.log('Successfully got content.');
          ret = <T> data.Body.valueOf();
        }
      });
      return of(ret);
    }
    public getObjectFromProject<T>(name: string): Observable<T> {
      let ret: T;

      const key = this.cognitoUtil.getCurrentUser().getUsername() + '/' + name;

      this.getS3().getObject({
        Key: key
      }, function (err, data) {
        if (err) {
          console.log('There was an error getting content: ', err);
          ret = null;
        } else {
          console.log('Successfully got content.');
          ret = <T> data.Body.valueOf();
        }
      });
      return of(ret);
    }

    public addJSONToAccount<T>(obj: T, contentType: string, name: string): Observable<T> {
      let ret: T = obj;

      if (!obj) {
        console.log('No object was passed to add to S3.');
        ret = null;
      }
      const key = 'cognito-' + this.cognitoUtil.getCurrentUser().getUsername() + '/' + name;

      this.getS3().upload({
        Key: key,
        ContentType: contentType,
        Body: JSON.stringify(obj),
        StorageClass: 'STANDARD',
        ACL: 'private'
      }, function (err, data) {
        if (err) {
          console.log('There was an error uploading your json content: ', err);
          ret = null;
        } else {
          console.log('Successfully uploaded object content.');
          ret = obj;
        }
      });
      return of(ret);
    }
  public deleteJSONFromAccount<T>(obj: T, contentType: string, name: string): Observable<T> {
    let ret: T;
    const retObservable = of(ret);
    if (!obj) {
      console.log('No object was passed to add to S3.');
      ret = null;
      return retObservable;
    }

    const key = 'cognito-' + this.cognitoUtil.getCurrentUser().getUsername() + '/' + name;
    this.getS3().deleteObject({Key: key}, function (err, data) {
      if (err) {
        console.log('There was an error deleting your content: ', err);
        ret = null;
      } else {
        console.log('Successfully deleted content.');
        ret = obj;
      }
    });
    return retObservable;
  }

  public addJSONToProject<T>(obj: T, contentType: string, projectName: string, name: string): Observable<T> {
      let ret: T = obj;

      if (!obj) {
        console.log('No object was passed to add to S3.');
        ret = null;
      }
      const key = 'cognito-' + this.cognitoUtil.getCurrentUser().getUsername() + '/' + projectName + '/' + name;

      this.getS3().upload({
        Key: key,
        ContentType: contentType,
        Body: JSON.stringify(obj),
        StorageClass: 'STANDARD',
        ACL: 'private'
      }, function (err, data) {
        if (err) {
          console.log('There was an error uploading your json content: ', err);
          ret = null;
        } else {
          console.log('Successfully uploaded object content.');
          ret = obj;
        }
      });
      return of(ret);
    }

    public deleteJSONFromProject<T>(obj: T, contentType: string, projectName: string, name: string): Observable<T> {
      let ret: T;
      const retObservable = of(ret);
      if (!obj) {
        console.log('No object was passed to add to S3.');
        ret = null;
        return retObservable;
      }

      const key = 'cognito-' + this.cognitoUtil.getCurrentUser().getUsername() + '/' + projectName + '/' + name;
      this.getS3().deleteObject({Key: key}, function (err, data) {
        if (err) {
          console.log('There was an error deleting your content: ', err);
          ret = null;
        } else {
          console.log('Successfully deleted content.');
          ret = obj;
        }
      });
      return retObservable;
    }
    public addFileToProject(selectedFile, projectName: string, name: string): boolean {
          if (!selectedFile) {
              console.log('Please choose a file to upload first.');
              return;
          }
          const fileName = selectedFile.name;
          const key = 'cognito-' + this.cognitoUtil.getCurrentUser().getUsername() + '/' + projectName + '/' + name;

          this.getS3().upload({
              Key: key,
              ContentType: selectedFile.type,
              Body: selectedFile,
              StorageClass: 'STANDARD',
              ACL: 'private'
          }, function (err, data) {
              if (err) {
                  console.log('There was an error uploading your photo: ', err);
                  return false;
              }
              console.log('Successfully uploaded photo.');
              return true;
          });
      }

      public deleteContent(selectedFile, projectName: string, name: string) {
          // this.getS3().deleteObjectStore("").promise().then(function () {
          //
          // }
        const key = 'cognito-' + this.cognitoUtil.getCurrentUser().getUsername() + '/' + projectName + '/' + name;
          this.getS3().deleteObject({Key: key}, function (err, data) {
              if (err) {
                  console.log('There was an error deleting your photo: ', err.message);
                  return;
              }
              console.log('Successfully deleted photo.');
          });
      }

      public getList<T>( prefix: string ): Observable<T[]> {
        const objects: T[] = [];
        const objectsObservable = of(objects);

        const key = 'cognito-' + this.cognitoUtil.getCurrentUser().getUsername() + '/' + prefix;
        // const key = 'cognito-' + this.cognitoUtil.getCognitoIdentity() + '/' + prefix;
        this.getS3().listObjects({Prefix: key}, function (err, data) {
          if (err) {
            console.log('There was an error getting your list: ' + err);
          } else {
            for (const item of data.Contents) {
              const k: string = item.Key;
              this.getObjectFromProject(k).subscribe(p => {
                if ( p ) {
                  objects.push(p);
                }
              });

            }
          }
        });
        return objectsObservable;
      }

}
