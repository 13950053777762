<div>
  <app-table *ngIf="columns && renderedData" [columns]="columns" [eventHandler]="eventHandler" [data]="renderedData" [expandedTableRowTemplate]="expandedTableRowTemplate" [dataServiceStatus]="printNodeOrderService.loadingState"  [showFilters]="false">
    <ng-template #expandedTableRowTemplate let-element>
      <app-print-node-order-admin [products]="products" [printNodeOrder]="element"></app-print-node-order-admin>
    </ng-template>
  </app-table>
</div>






