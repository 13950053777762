import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import { AbstractDataService } from "./abstract-data.service";
import {ManufacturingResourceBucket} from "../../model/ManufacturingResourceBucket";
import {ManufacturingResourceBucketPeriod} from "../../model/ManufacturingResourceBucketPeriod";
import {FacilityInventoryProductEvent} from "../../model/FacilityInventoryProductEvent";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {catchError, tap} from "rxjs/operators";

@Injectable()
export class ManufacturingResourceBucketPeriodService extends AbstractDataService<ManufacturingResourceBucketPeriod> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService) {
    super(httpa, messageServicea, startupServicea);
  }

  makeObjectInstance(args: any): ManufacturingResourceBucketPeriod {
    const object = new ManufacturingResourceBucketPeriod(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "ManufacturingResourceBucketPeriod";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "manufacturingresourcebucketperiod";
  }


  updateCapacities (manufacturingResouceBucketPeriodCapacities: ManufacturingResouceBucketPeriodCapacity[]): Observable<ManufacturingResourceBucketPeriod[]> {
    const url = `${environment.apiBaseURL}updateCapacities`;
    this.messageService.add(`updated ManufacturingResourceBucketPeriod.updateCapacities ${JSON.stringify(manufacturingResouceBucketPeriodCapacities)}`);
    return this.http.put(url, manufacturingResouceBucketPeriodCapacities, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated ManufacturingResourceBucketPeriod.updateCapacities`)),
      catchError(this.handleError<ManufacturingResourceBucketPeriod>(`updated ManufacturingResourceBucketPeriod.updateCapacities ${JSON.stringify(manufacturingResouceBucketPeriodCapacities)}`))
    );
  }

  private handleError<T> (updateCapacities1: string) {
    return function (p1: any, p2: Observable<any>) {
      return undefined;
    };
  }

  private log (updateCapacities1: string) {
    console.log(`ERROR in ManufacturingResourceBucketPeriodService.updateCapacities ${updateCapacities1}`);
  }
}
export class ManufacturingResouceBucketPeriodCapacity {
  ID: number;
  amount: number;
}
