import { Injectable } from '@angular/core';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {NotificationsService} from "./notifications.service";
import {OrganizationInvoice} from "../../model/OrganizationInvoice";
import {Observable} from "rxjs";
import {of} from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';
import {environment} from "../../environments/environment";
import {S3} from "aws-sdk";

@Injectable()
export class OrganizationInvoiceService extends AbstractLiveDataService<OrganizationInvoice> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setOrganizationInvoiceService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): OrganizationInvoice {
    const object = new OrganizationInvoice(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "OrganizationInvoice";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "organizationinvoice";
  }

  getLinkData(): any[] {
    return [
      { action: 'Link', objectIdPropertyName: 'org_ID', serviceName: 'organizationService', arrayProperty: 'organizationInvoices', objectProperty: 'organization'},
      { action: 'Link', objectIdPropertyName: 'serviceprovider_ID', serviceName: 'serviceProviderService', arrayProperty: 'organizationInvoices', objectProperty: 'serviceProvider'},
    ];
  }

  getOrganizationInvoiceURL(id: string, language: string, subFolder: string): Observable<String> {
    const url = `${environment.apiBaseURL}organizationinvoice/url/${id}/${language}/${subFolder}`;
    this.messageService.add(`OrganizationInvoiceService: fetched organizationInvoiceURL id=${id}/${language}/${subFolder}`);
    return this.http.get<String>(url, this.startupService.getHttpOptions())
      .pipe(
        map(organizationInvoiceURL => {
          if ( organizationInvoiceURL) {
            return organizationInvoiceURL;
          } else {
            return '';
          }
        }),
        catchError(this.handleErrorX<String>(`getOrganizationInvoiceURL id=${id}/${language}/${subFolder}`))
      );
  }


  /** PUT: update the organizationInvoice on the server */
  updateOrganizationInvoiceItems(changeItems: any) {
      const url = `${environment.apiBaseURL}organizationinvoice/items/${changeItems.organizationInvoiceId}`;
      this.messageService.add(`OrganizationInvoiceService: updated organizationInvoice id=${changeItems.organizationInvoiceId}`);

      return this.http.put(url, changeItems, this.startupService.getHttpOptions()).pipe(
        tap(_ => this.log(`updated organizationInvoice id=${changeItems.organizationInvoiceId}`)),
        catchError(this.handleErrorX<any>('updateOrganizationInvoiceWithItems'))
      );
      // return this.http.put(url, organizationInvoice, this.startupService.getHttpOptions()).pipe(
      //   map(organizationInvoice => {
      //     const p = new OrganizationInvoice(organizationInvoice);
      //     return p;
      //   }),
      //   catchError(this.handleError<any>('updateOrganizationInvoice'))
      // );
    }

  public getS3(region: string, bucket: string): any {
    // AWS.config.update({
    //   region: environment.bucketRegion,
    // });
    //
    const clientParams: any = {
      region: environment.bucketRegion,
      apiVersion: '2006-03-01',
      params: {Bucket: environment.dataBucket}
    };
    if (environment.s3_endpoint) {
      clientParams.endpoint = environment.s3_endpoint;
    }
    const s3 = new S3(clientParams);

    return s3;
  }

  public getObject<T>(region: string, bucket: string, key: string): Observable<T> {
    let ret: T;

    this.getS3(region, bucket).getObject({
      Bucket: bucket,
      Key: key
    }, function (err, data) {
      if (err) {
        console.log('There was an error getting content: ', err);
        ret = null;
      } else {
        console.log('Successfully got content.');
        ret = <T> data.Body.valueOf();
      }
    });
    return of(ret);
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleErrorX<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.loadingState = 'INITIAL';
      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }




}
