<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Orders">Orders</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Orders">Orders</span>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="facility-scan"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <div class="app-content">
        <mat-card appearance="outlined" class="formCard">
          <mat-card-content>
            <mat-card-title style="text-align: center; font-size: 36pt" i18n="@@Scan Values With The Cursor In The Box">Scan Values With The Cursor In The Box</mat-card-title>
            <form style="padding: 200px">
              <mat-form-field class="full-width">
                <input matInput i18n-placeholder="@@Scan Value" placeholder="Scan Value" id="scanValue" required type="text" maxlength="40" (change)="scan($event.target)" > <!--[ngModelOptions]="{standalone: true}"-->
                <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
              </mat-form-field>
              <div *ngIf="scanResponse">{{scanResponse}}</div>
            </form>
          </mat-card-content>
<!--          <mat-card-actions class="primaryAction">-->
<!--            <button mat-raised-button color="primary" (click)="onRegister()" id="signupSubmit" type="submit" class="btn btn-info btn-block" i18n="@@Create your account">Create your account</button>-->
<!--          </mat-card-actions>-->
<!--          <mat-card-actions class="secondaryAction">-->
<!--            <p>Already have an account? <button mat-button color="primary" [routerLink]="['/home/login']"><ng-container i18n="@@Login">Login</ng-container></button></p>-->
<!--          </mat-card-actions>-->
        </mat-card>

      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






