import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {PrintNodeOrderItem} from "../../../model/PrintNodeOrderItem";
import {PrintNodeOrderItemService} from "../../service-data/print-node-order-item.service";
import {ManufacturingSetsComponent} from "../manufacturing-sets/manufacturing-sets.component";
import {ManufacturingTaskBucket} from "../../../model/ManufacturingTaskBucket";
import {ManufacturingTaskBucketService} from "../../service-data/manufacturing-task-bucket.service";
import {ManufacturingTaskBucketPeriod} from "../../../model/ManufacturingTaskBucketPeriod";
import {ManufacturingSetService} from "../../service-data/manufacturing-set.service";
import {ManufacturingSet} from "../../../model/ManufacturingSet";
import {ManufacturingTask} from "../../../model/ManufacturingTask";
import {ManufacturingTaskService} from "../../service-data/manufacturing-task.service";
import {ManufacturingTaskBucketPeriodService} from "../../service-data/manufacturing-task-bucket-period.service";
import {AssignPrintNodeOrderItemToManufacturingSet} from "./assignPrintNodeOrderItemToManufacturingSet";
import {SetPlanningGroup} from "./SetPlanningGroup";
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import {ManufacturingSetAssignerService} from "../../service-data/manufacturing-set-assigner.service";
import {FacilityInventoryProduct} from "../../../model/FacilityInventoryProduct";
import {FacilityInventoryProductService} from "../../service-data/facility-inventory-product.service";
import {Subject} from "rxjs";
import {DataObject} from "../../../model/DataObject";
import {Package} from "../../../model/Package";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-manufacturing-buckets',
  templateUrl: './manufacturing-buckets.component.html',
  styleUrls: ['./manufacturing-buckets.component.css']
})

export class ManufacturingBucketsComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, RoleChangeListener, AssignPrintNodeOrderItemToManufacturingSet {
  public static types = ['interior', 'binding', 'lamination', 'impositionLayout', 'trimSize', 'paperType', 'quantityGroup', 'oneDigitZip'];

  @ViewChild('mainSideNav', {static: true}) mainSideNav: MatSidenav;

  assignPrintNodeOrderItemToManufacturingSet: AssignPrintNodeOrderItemToManufacturingSet = this;

  manufacturingTaskBuckets: ManufacturingTaskBucket[];
  actionableManufacturingTaskBuckets: any[];

  // Display flags and filters
  showEmptyTaskBuckets: boolean = false;
  showClosedTaskBuckets: boolean = false;
  showLockedTaskBuckets: boolean = true;
  showOpenTaskBuckets: boolean = true;
  showOnlyPinned: boolean = false;
  showPrintNodeOrderItems: boolean = true;
  showDates: boolean = true;
  showStats: boolean = true;
  showEmptySets: boolean = false;

  expandSets: any = {};
  expandBucketPeriods: any = {};
  expandBucketPeriodSets: any = {};
  expandBuckets: any = {};

  dataIndex = 0; // 0 for Quantity, 1 for feetOrSheets, 2 for number of line items

  facilityInventoryProducts: FacilityInventoryProduct[] = [];

  Math = Math;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  setPlanningGroups: SetPlanningGroup[] = [];
  selectedPrintNodeOrderId: number = 0;

  public static getFacilityBasedOnCountryStateAndOneDigitZip(country: string, state: string, oneDigitZip: string, facilityId: number): string {
    let facility = '';
    if ( country === 'US' || country === 'USA' ) {
      switch (oneDigitZip) {
        case '0': {
          facility = '7';
          break;
        }
        case '1': {
          facility = '7';
          break;
        }
        case '2': {
          facility = '7';
          break;
        }
        case '3': {
          facility = '7';
          break;
        }
        case '4': {
          facility = '7';
          break;
        }
        case '5': {
          facility = '7';
          break;
        }
        case '6': {
          facility = '7';
          break;
        }
        case '7': {
          facility = '7';
          break;
        }
        case '8': {
          switch (state) {
            case 'AZ': {
              facility = '1';
              break;
            }
            case 'CO': {
              facility = '1';
              break;
            }
            case 'ID': {
              facility = '1';
              break;
            }
            case 'NM': {
              facility = '1';
              break;
            }
            case 'TX': {
              facility = '7';
              break;
            }
            case 'UT': {
              facility = '1';
              break;
            }
            case 'WY': {
              facility = '1';
              break;
            }
            default: {
              facility = '2';
              break;
            }
          }
          break;
        }
        case '9': {
          facility = '1';
          break;
        }
        default : {
          facility = '2';
          break;
        }
      }
    } else {
      facility = '2';
    }
    if ('' + facilityId === facility ) {
      return '0';
    } else {
      if ( oneDigitZip === '2' ) {
        return '2';
      } else {
        return 'X';
      }
    }
  }

  constructor(public router: Router,
              public startupService: StartupService,
              private manufacturingTaskBucketPeriodService: ManufacturingTaskBucketPeriodService,
              private manufacturingTaskService: ManufacturingTaskService,
              private manufacturingSetService: ManufacturingSetService,
              private manufacturingTaskBucketService: ManufacturingTaskBucketService,
              private printNodeOrderItemService: PrintNodeOrderItemService,
              private printNodeOrderService: PrintNodeOrderService,
              private manufacturingSetAssignerService: ManufacturingSetAssignerService,
              private facilityInventoryProductService: FacilityInventoryProductService,
              public changeDetectorRef: ChangeDetectorRef,
              public media: MediaMatcher) {
    this.startupService.addRoleChangeListener(this);
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }


  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  ngAfterViewInit(): void {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  addUpdateManufacturingTaskBucket(o: ManufacturingTaskBucket) {
    try {
      if (this.matchManufacturingTaskBucket(o)) {
        // console.log(`Match in ManufacturingBucketsComponent addUpdateManufacturingTaskBucket ${o.getId()} ${this.manufacturingTaskBuckets} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);

        if (!this.contains(o, this.manufacturingTaskBuckets)) {
          this.manufacturingTaskBuckets.push(o);
        }
      } else {
        if (this.contains(o, this.manufacturingTaskBuckets)) {
          const foundIndex = this.manufacturingTaskBuckets.findIndex(item => item.getId() === o.getId());
          if (foundIndex > -1) {
            this.manufacturingTaskBuckets.splice(foundIndex, 1);
          }
        }
      }
      this.computeActionalbeManufacturingTaskBuckets();
    } catch (e) {
      console.log(`ERROR in ManufacturingBucketsComponent addUpdateManufacturingTaskBucket ${o.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  deleteManufacturingTaskBucket(o: ManufacturingTaskBucket) {
    try {
      if (this.contains(o, this.manufacturingTaskBuckets)) {
        const foundIndex = this.manufacturingTaskBuckets.findIndex(item => item.getId() === o.getId());
        if (foundIndex > -1) {
          this.manufacturingTaskBuckets.splice(foundIndex, 1);
        }
      }
      this.computeActionalbeManufacturingTaskBuckets();
    } catch (e) {
      console.log(`ERROR in ManufacturingBucketsComponent deleteManufacturingTaskBucket ${o.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  matchManufacturingTaskBucket(o: ManufacturingTaskBucket): boolean {
    if (o) {
      return true;
    }
    return false;
  }

  addUpdateManufacturingTaskBucketPeriod(o: ManufacturingTaskBucketPeriod) {
    try {
      if (this.matchManufacturingTaskBucketPeriod(o)) {
        this.computeActionalbeManufacturingTaskBuckets();
      }
    } catch (e) {
      console.log(`ERROR in ManufacturingBucketsComponent addUpdateManufacturingTaskBucket ${o.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  deleteManufacturingTaskBucketPeriod(o: ManufacturingTaskBucketPeriod) {
    try {
      this.computeActionalbeManufacturingTaskBuckets();
    } catch (e) {
      console.log(`ERROR in ManufacturingBucketsComponent deleteManufacturingTaskBucketPeriod ${o.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  matchManufacturingTaskBucketPeriod(o: ManufacturingTaskBucketPeriod): boolean {
    if (o) {
      return true;
    }
    return false;
  }

  addUpdateManufacturingSet(o: ManufacturingSet) {
    try {
      if (this.matchManufacturingSet(o)) {
        this.computeActionalbeManufacturingTaskBuckets();
      }
    } catch (e) {
      console.log(`ERROR in ManufacturingBucketsComponent addUpdateManufacturingSet ${o.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  deleteManufacturingSet(o: ManufacturingSet) {
    try {
      this.computeActionalbeManufacturingTaskBuckets();
    } catch (e) {
      console.log(`ERROR in ManufacturingBucketsComponent deleteManufacturingSet ${o.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  matchManufacturingSet(o: ManufacturingSet): boolean {
    if (o) {
      return true;
    }
    return false;
  }

  addUpdatePrintNodeOrderItem(o: PrintNodeOrderItem) {
    try {
      if (this.matchPrintNodeOrderItem(o)) {
        this.computeActionalbeManufacturingTaskBuckets();
      }
    } catch (e) {
      console.log(`ERROR in ManufacturingBucketsComponent addUpdatePrintNodeOrderItem ${o.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  deletePrintNodeOrderItem(o: PrintNodeOrderItem) {
    try {
      this.computeActionalbeManufacturingTaskBuckets();
    } catch (e) {
      console.log(`ERROR in ManufacturingBucketsComponent deletePrintNodeOrderItem ${o.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  matchPrintNodeOrderItem(o: PrintNodeOrderItem): boolean {
    if (o) {
      return true;
    }
    return false;
  }

  addUpdateFacilityInventoryProduct(o: FacilityInventoryProduct) {
    try {
      if (this.matchFacilityInventoryProduct(o)) {
        // console.log(`Match in ManufacturingBucketsComponent addUpdateFacilityInventoryProduct ${o.getId()} ${this.manufacturingTaskBuckets} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);

        if (!this.contains(o, this.manufacturingTaskBuckets)) {
          this.facilityInventoryProducts.push(o);
        }
      } else {
        if (this.contains(o, this.facilityInventoryProducts)) {
          const foundIndex = this.facilityInventoryProducts.findIndex(item => item.getId() === o.getId());
          if (foundIndex > -1) {
            this.facilityInventoryProducts.splice(foundIndex, 1);
          }
        }
      }
      this.computeActionalbeManufacturingTaskBuckets();
    } catch (e) {
      console.log(`ERROR in ManufacturingBucketsComponent addUpdateFacilityInventoryProduct ${o.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  deleteFacilityInventoryProduct(o: FacilityInventoryProduct) {
    try {
      if (this.contains(o, this.facilityInventoryProducts)) {
        const foundIndex = this.facilityInventoryProducts.findIndex(item => item.getId() === o.getId());
        if (foundIndex > -1) {
          this.facilityInventoryProducts.splice(foundIndex, 1);
        }
      }
      this.computeActionalbeManufacturingTaskBuckets();
    } catch (e) {
      console.log(`ERROR in ManufacturingBucketsComponent deleteFacilityInventoryProduct ${o.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  matchFacilityInventoryProduct(o: FacilityInventoryProduct): boolean {
    if (o) {
      return true;
    }
    return false;
  }

  contains(object: DataObject, array: DataObject[]): boolean {
    for (const o of array) {
      if (o.getId() === object.getId()) {
        return true;
      }
    }
    return false;
  }

  // Note: This is primative and recomputes everything with ANY change
  computeActionalbeManufacturingTaskBuckets() {
    this.actionableManufacturingTaskBuckets = [];
    for (const manufacturingTaskBucket of this.manufacturingTaskBuckets) {
      const actionableManufacturingTaskBucket = {};
      actionableManufacturingTaskBucket['ID'] = manufacturingTaskBucket.ID;
      actionableManufacturingTaskBucket['name'] = manufacturingTaskBucket.name;
      actionableManufacturingTaskBucket['printnode_ID'] = manufacturingTaskBucket.printnode_ID;
      actionableManufacturingTaskBucket['manufacturingProcess_id'] = manufacturingTaskBucket.manufacturingProcess_id;
      actionableManufacturingTaskBucket['SinglesOrBatch'] = manufacturingTaskBucket.name.indexOf('Single') !== -1 ? 'SINGLES' : 'BATCH';
      actionableManufacturingTaskBucket['manufacturingTaskBucketPeriods'] = [];
      for (const manufacturingTaskBucketPeriod of manufacturingTaskBucket.manufacturingTaskBucketPeriods) {
        const actionableMacturingTaskBucketPeriod = {};
        actionableMacturingTaskBucketPeriod['ID'] = manufacturingTaskBucketPeriod.ID;
        actionableMacturingTaskBucketPeriod['period'] = manufacturingTaskBucketPeriod.period;
        actionableMacturingTaskBucketPeriod['SinglesOrBatch'] = actionableManufacturingTaskBucket['SinglesOrBatch'];
        actionableMacturingTaskBucketPeriod['manufacturingSets'] = [];
        actionableMacturingTaskBucketPeriod['status'] = manufacturingTaskBucketPeriod.status;
        for (const manufacturingSet of manufacturingTaskBucketPeriod.manufacturingSets) {
          actionableMacturingTaskBucketPeriod['manufacturingSets'].push(manufacturingSet);
          manufacturingSet['quantity'] = 0;
          manufacturingSet['sheets'] = 0;
          manufacturingSet['stack'] = 0;
          for (const printNodeOrderItem of manufacturingSet.printNodeOrderItems) {
            manufacturingSet['quantity'] += printNodeOrderItem.quantity;
            manufacturingSet['sheets'] += (printNodeOrderItem.book.body.width / 72 > 6 || printNodeOrderItem.book.body.height / 72 > 9) ? (printNodeOrderItem.book.body.numPages * printNodeOrderItem.quantity / 4) : (printNodeOrderItem.book.body.numPages * printNodeOrderItem.quantity / 8);
            manufacturingSet['stack'] += (printNodeOrderItem.book.body.width / 72 > 6 || printNodeOrderItem.book.body.height / 72 > 9) ? (printNodeOrderItem.book.body.numPages * printNodeOrderItem.quantity * 0.00474 / 4) : (printNodeOrderItem.book.body.numPages * printNodeOrderItem.quantity * 0.00474 / 8);
          }
        }
        actionableManufacturingTaskBucket['manufacturingTaskBucketPeriods'].push(actionableMacturingTaskBucketPeriod);
      }
      this.actionableManufacturingTaskBuckets.push(actionableManufacturingTaskBucket);
    }
    for (const manufacturingTaskBucket of this.manufacturingTaskBuckets) {
      for (const manufacturingTaskBucketPeriod of manufacturingTaskBucket.manufacturingTaskBucketPeriods) {
        const printNodeOrderItemsInManufacturingTaskBucketPeriod = [];
        for (const manufacturingSet of manufacturingTaskBucketPeriod.manufacturingSets) {
          this.computeProperties(manufacturingSet, manufacturingSet.printNodeOrderItems);
          for (const printNodeOrderItem of manufacturingSet.printNodeOrderItems) {
            printNodeOrderItemsInManufacturingTaskBucketPeriod.push(printNodeOrderItem);
          }
          manufacturingSet.printNodeOrderItems.sort((a, b): number => {
            const aPaperType = a.book.body.paperType_ID;
            const bPaperType = b.book.body.paperType_ID;
            if (aPaperType > bPaperType) {
              return -1;
            }
            if (aPaperType < bPaperType) {
              return 1;
            }
            const aNumPages = a.book.body.numPages;
            const bNumPages = b.book.body.numPages;
            if (aNumPages < bNumPages) {
              return -1;
            }
            if (aNumPages > bNumPages) {
              return 1;
            }
            return 0;
          });
        }
        this.computeProperties(manufacturingTaskBucketPeriod, printNodeOrderItemsInManufacturingTaskBucketPeriod);
        manufacturingTaskBucketPeriod.manufacturingSets.sort((a, b): number => {
          const keys = ['interior', 'binding', 'lamination', 'impositionLayout', 'paperType', 'trimSize'];
          for (const key of keys) {
            const x = this.compare(a[key], b[key]);
            if (x !== 0) {
              return x;
            }
          }
          return 0;
        });
      }
    }
  }

  getData(reset: boolean = false): void {
    this.startupService.touchLastUserActivity();
    this.manufacturingTaskBuckets = [];
    this.manufacturingTaskBucketService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.addUpdateManufacturingTaskBucket(o);
    });
    this.manufacturingTaskBucketService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.addUpdateManufacturingTaskBucket(o);
    });
    this.manufacturingTaskBucketService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.deleteManufacturingTaskBucket(o);
    });
    this.manufacturingTaskBucketService.objects.forEach(o => {
      this.addUpdateManufacturingTaskBucket(o);
    });

    this.manufacturingTaskBucketPeriodService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.addUpdateManufacturingTaskBucketPeriod(o);
    });
    this.manufacturingTaskBucketPeriodService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.addUpdateManufacturingTaskBucketPeriod(o);
    });
    this.manufacturingTaskBucketPeriodService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.deleteManufacturingTaskBucketPeriod(o);
    });
    this.manufacturingTaskBucketPeriodService.objects.forEach(o => {
      this.addUpdateManufacturingTaskBucketPeriod(o);
    });

    this.manufacturingSetService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.addUpdateManufacturingSet(o);
    });
    this.manufacturingSetService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.addUpdateManufacturingSet(o);
    });
    this.manufacturingSetService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.deleteManufacturingSet(o);
    });
    this.manufacturingSetService.objects.forEach(o => {
      this.addUpdateManufacturingSet(o);
    });

    this.printNodeOrderItemService.getAddNotifier(true).pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.addUpdatePrintNodeOrderItem(o);
    });
    this.printNodeOrderItemService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.addUpdatePrintNodeOrderItem(o);
    });
    this.printNodeOrderItemService.objects.forEach(o => {
      this.addUpdatePrintNodeOrderItem(o);
    });

    this.facilityInventoryProductService.getAddNotifier(true).pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.addUpdateFacilityInventoryProduct(o);
    });
    this.facilityInventoryProductService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.addUpdateFacilityInventoryProduct(o);
    });
    this.facilityInventoryProductService.objects.forEach(o => {
      this.addUpdateFacilityInventoryProduct(o);
    });

    // const filter = {
    //   eq: {},
    //   ge: {},
    //   ne: {}
    // };
    // filter['eq']['printNodeOrder.shippingStatus'] = 'NONE';
    // filter['eq']['printNodeOrderItem.manufacturingTaskBucketPeriod_id'] = 0;
    // filter['eq']['printNodeOrder.printnode_ID'] = 3;
    //
    //
    // this.facilityInventoryProductService.getItems()
    //   .subscribe(facilityInventoryProducts => {
    //     this.facilityInventoryProducts = facilityInventoryProducts;
    //   });
    //
    // const manufacturingTaskBucketFilter = {
    //   eq: {
    //     ' date_add(date(substring(manufacturingTaskBucketPeriod.period, 2, 10)), INTERVAL 6 DAY) > now() ': 1,
    //     ' date_add(date(substring(manufacturingTaskBucketPeriod.period, 2, 10)), INTERVAL -6 DAY) < now() ': 1
    //   }
    // };
    // this.manufacturingTaskBucketService.getFilteredItems(manufacturingTaskBucketFilter, reset, 1)
    //   .subscribe(manufacturingTaskBuckets => {
    //     this.manufacturingTaskBuckets = manufacturingTaskBuckets;
    //   });
  }

  compare(aObject: any, bObject: any) {
    const a = Object.keys(aObject['0'])[0];
    const b = Object.keys(bObject['0'])[0];
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    }
    return 0;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  isPrintNodeOrderItemCompatableWithManufacturingSet(printNodeOrderItem: PrintNodeOrderItem, manufacturingSet: ManufacturingSet): boolean {
    if (manufacturingSet.openStatus === 'CLOSED') {
      return false;
    }
    if (manufacturingSet.details && manufacturingSet.details['SinglesOrBatch'] && manufacturingSet.details['SinglesOrBatch'] !== 'SINGLES' && printNodeOrderItem.quantity < 18) {
      return false;
    }
    if (manufacturingSet.details && manufacturingSet.details['trimSizeBreaks'] && manufacturingSet.details['trimSizeBreaks'] === '_4UP' && (printNodeOrderItem.book.body.height / 72 > 9 || printNodeOrderItem.book.body.width / 72 > 6)) {
      return false;
    }
    if (manufacturingSet.details && manufacturingSet.details['laminateType'] && manufacturingSet.details['laminateType'] !== printNodeOrderItem.book.cover.laminateType) {
      return false;
    }
    return true;
  }

  computeProperties(wrapper: any, printNodeOrderItems: PrintNodeOrderItem[]) {
    if (!wrapper.filter) {
      wrapper.filter = {};
    }

    // wrapper.filter['paperType.4'] = true;
    wrapper.lineItems = 0;
    wrapper.quantity = 0;
    wrapper.feetOrSheets = 0;
    wrapper.totals = [0, 0, 0];
    wrapper.binding = [{}, {}, {}];
    wrapper.lamination = [{}, {}, {}];
    wrapper.paperType = [{}, {}, {}];
    wrapper.createdDate = [{}, {}, {}];
    wrapper.trimSize = [{}, {}, {}];
    wrapper.interior = [{}, {}, {}];
    wrapper.impositionLayout = [{}, {}, {}];
    wrapper.quantityGroup = [{}, {}, {}];
    wrapper.serviceProvider = [{}, {}, {}];
    wrapper.oneDigitZip = [{}, {}, {}];
    for (const printNodeOrderItem of printNodeOrderItems) {
      try {
        const quantity = printNodeOrderItem.quantity;
        const width = printNodeOrderItem.book.body.width;
        const height = printNodeOrderItem.book.body.height;
        const thickness = printNodeOrderItem.book.body.spine;
        const pages = printNodeOrderItem.book.body.numPages;
        const interior = printNodeOrderItem.book.interiorType;
        const paperType = String(printNodeOrderItem.book.body.paperType_ID);
        const lamination = printNodeOrderItem.book.cover.laminateType;

        let createdDate = '';
        let oneDigitZip = '';
        let serviceProvider = 0;
        if (printNodeOrderItem.printNodeOrder) {
          createdDate = new Date(printNodeOrderItem.printNodeOrder.created).toISOString().split('T')[0];
          oneDigitZip = ManufacturingBucketsComponent.getFacilityBasedOnCountryStateAndOneDigitZip(printNodeOrderItem.printNodeOrder.country, printNodeOrderItem.printNodeOrder.state, printNodeOrderItem.printNodeOrder.postalcode ? printNodeOrderItem.printNodeOrder.postalcode.substring(0, 1) : '', printNodeOrderItem.printNodeOrder.printnode_ID);
          if (printNodeOrderItem.printNodeOrder.organization) {
            serviceProvider = printNodeOrderItem.printNodeOrder.organization.serviceProvider_ID;
          }
        }
        // const createdDate = new Date(printNodeOrderItem.printNodeOrder.created).toISOString().split('T')[0];

        const binding = printNodeOrderItem.book.bindingType;
        let trimSize = '';
        if (width / 72 === 5.5 && height / 72 === 8.5) {
          trimSize = '_5.5X8.5';
        } else {
          if (width / 72 === 6.0 && height / 72 === 9.0) {
            trimSize = '_6.0X9.0';
          } else {
            if (width / 72 === 8.5 && height / 72 === 11) {
              trimSize = '_8.5X11.0';
            } else {
              trimSize = 'Other';
            }
          }
        }

        let impositionLayout = width / 72 > 6 || height / 72 > 9 ? '_2UP' : '_4UP';
        let feetOrSheets = width / 72 > 6 || height / 72 > 9 ? quantity * pages / 4 : quantity * pages / 8;
        // Get a criteria to determine that it is comming off of a roll to make it feet
        if (false) {
          feetOrSheets = width / 72 > 6 || height / 72 > 9 ? quantity * pages / 4 : (quantity * pages / 6) * 10 / 12;
          impositionLayout = width / 72 > 6 || height / 72 > 9 ? '_2UP' : '_3UP';
        }

        let quantityGroup = '_001-019';
        if (quantity < 20) {
          quantityGroup = '_001-019';
        } else {
          if (quantity < 50) {
            quantityGroup = '_020-049';
          } else {
            if (quantity < 100) {
              quantityGroup = '_050-099';
            } else {
              if (quantity < 250) {
                quantityGroup = '_100-249';
              } else {
                if (quantity < 500) {
                  quantityGroup = '_250-499';
                } else {
                  quantityGroup = '_500+';
                }
              }
            }
          }
        }

        wrapper.lineItems += 1;
        wrapper.quantity += quantity;
        wrapper.feetOrSheets += feetOrSheets;
        wrapper.totals[0] += quantity;
        wrapper.totals[1] += feetOrSheets;
        wrapper.totals[2] += 1;
        if (!wrapper.quantityGroup[0][quantityGroup]) {
          wrapper.quantityGroup[0][quantityGroup] = 0;
        }
        if (!wrapper.quantityGroup[1][quantityGroup]) {
          wrapper.quantityGroup[1][quantityGroup] = 0;
        }
        if (!wrapper.quantityGroup[2][quantityGroup]) {
          wrapper.quantityGroup[2][quantityGroup] = 0;
        }
        wrapper.quantityGroup[0][quantityGroup] += quantity;
        wrapper.quantityGroup[1][quantityGroup] += feetOrSheets;
        wrapper.quantityGroup[2][quantityGroup] += 1;

        if (!wrapper.binding[0][binding]) {
          wrapper.binding[0][binding] = 0;
        }
        if (!wrapper.binding[1][binding]) {
          wrapper.binding[1][binding] = 0;
        }
        if (!wrapper.binding[2][binding]) {
          wrapper.binding[2][binding] = 0;
        }
        wrapper.binding[0][binding] += quantity;
        wrapper.binding[1][binding] += feetOrSheets;
        wrapper.binding[2][binding] += 1;

        if (!wrapper.paperType[0][paperType]) {
          wrapper.paperType[0][paperType] = 0;
        }
        if (!wrapper.paperType[1][paperType]) {
          wrapper.paperType[1][paperType] = 0;
        }
        if (!wrapper.paperType[2][paperType]) {
          wrapper.paperType[2][paperType] = 0;
        }
        wrapper.paperType[0][paperType] += quantity;
        wrapper.paperType[1][paperType] += feetOrSheets;
        wrapper.paperType[2][paperType] += 1;

        if (!wrapper.trimSize[0][trimSize]) {
          wrapper.trimSize[0][trimSize] = 0;
        }
        if (!wrapper.trimSize[1][trimSize]) {
          wrapper.trimSize[1][trimSize] = 0;
        }
        if (!wrapper.trimSize[2][trimSize]) {
          wrapper.trimSize[2][trimSize] = 0;
        }
        wrapper.trimSize[0][trimSize] += quantity;
        wrapper.trimSize[1][trimSize] += feetOrSheets;
        wrapper.trimSize[2][trimSize] += 1;

        if (!wrapper.createdDate[0][createdDate]) {
          wrapper.createdDate[0][createdDate] = 0;
        }
        if (!wrapper.createdDate[1][createdDate]) {
          wrapper.createdDate[1][createdDate] = 0;
        }
        if (!wrapper.createdDate[2][createdDate]) {
          wrapper.createdDate[2][createdDate] = 0;
        }
        wrapper.createdDate[0][createdDate] += quantity;
        wrapper.createdDate[1][createdDate] += feetOrSheets;
        wrapper.createdDate[2][createdDate] += 1;

        if (!wrapper.lamination[0][lamination]) {
          wrapper.lamination[0][lamination] = 0;
        }
        if (!wrapper.lamination[1][lamination]) {
          wrapper.lamination[1][lamination] = 0;
        }
        if (!wrapper.lamination[2][lamination]) {
          wrapper.lamination[2][lamination] = 0;
        }
        wrapper.lamination[0][lamination] += quantity;
        wrapper.lamination[1][lamination] += feetOrSheets;
        wrapper.lamination[2][lamination] += 1;

        if (!wrapper.interior[0][interior]) {
          wrapper.interior[0][interior] = 0;
        }
        if (!wrapper.interior[1][interior]) {
          wrapper.interior[1][interior] = 0;
        }
        if (!wrapper.interior[2][interior]) {
          wrapper.interior[2][interior] = 0;
        }
        wrapper.interior[0][interior] += quantity;
        wrapper.interior[1][interior] += feetOrSheets;
        wrapper.interior[2][interior] += 1;

        if (!wrapper.impositionLayout[0][impositionLayout]) {
          wrapper.impositionLayout[0][impositionLayout] = 0;
        }
        if (!wrapper.impositionLayout[1][impositionLayout]) {
          wrapper.impositionLayout[1][impositionLayout] = 0;
        }
        if (!wrapper.impositionLayout[2][impositionLayout]) {
          wrapper.impositionLayout[2][impositionLayout] = 0;
        }
        wrapper.impositionLayout[0][impositionLayout] += quantity;
        wrapper.impositionLayout[1][impositionLayout] += feetOrSheets;
        wrapper.impositionLayout[2][impositionLayout] += 1;

        if (!wrapper.oneDigitZip[0][oneDigitZip]) {
          wrapper.oneDigitZip[0][oneDigitZip] = 0;
        }
        if (!wrapper.oneDigitZip[1][oneDigitZip]) {
          wrapper.oneDigitZip[1][oneDigitZip] = 0;
        }
        if (!wrapper.oneDigitZip[2][oneDigitZip]) {
          wrapper.oneDigitZip[2][oneDigitZip] = 0;
        }
        wrapper.oneDigitZip[0][oneDigitZip] += quantity;
        wrapper.oneDigitZip[1][oneDigitZip] += feetOrSheets;
        wrapper.oneDigitZip[2][oneDigitZip] += 1;

        if (!wrapper.serviceProvider[0][serviceProvider]) {
          wrapper.serviceProvider[0][serviceProvider] = 0;
        }
        if (!wrapper.serviceProvider[1][serviceProvider]) {
          wrapper.serviceProvider[1][serviceProvider] = 0;
        }
        if (!wrapper.serviceProvider[2][serviceProvider]) {
          wrapper.serviceProvider[2][serviceProvider] = 0;
        }
        wrapper.serviceProvider[0][serviceProvider] += quantity;
        wrapper.serviceProvider[1][serviceProvider] += feetOrSheets;
        wrapper.serviceProvider[2][serviceProvider] += 1;
      } catch (e) {
        console.log(`Error setting up Wrapper Properties for a ManufacturingSet ${e.message}`);
      }

    }
  }

  getTypes(): string[] {
    return ManufacturingSetsComponent.types;
  }

  getFilter(list: any, type: any): string[] {
    return Object.keys(list[type][0]);
  }

  getCategories(list: any, type: any): string[] {
    if (list && list[type] && list[type][0]) {
      return Object.keys(list[type][0]);
    } else {
      return ['', '', ''];
    }
  }

  getCategoryData(list: any, type: any, dataIndex: any): number[] {
    return list[type][dataIndex];
  }

  getColor(type: any): any {
    return ManufacturingSetsComponent.colors[type];
  }

  getIcons(type: any): any {
    return ManufacturingSetsComponent.icons[type];
  }

  filtered(list: any, printNodeOrderItem: PrintNodeOrderItem): boolean {
    if (!list.filter) {
      return false;
    }
    for (const key of Object.keys(list.filter)) {
      const parts = key.split('*');
      if (parts.length === 2) {
        switch (parts[0]) {
          case 'interior': {
            if (String(printNodeOrderItem.book.interiorType) === parts[1]) {
              return true;
            }
            break;
          }
          case 'paperType': {
            if (String(printNodeOrderItem.book.body.paperType_ID) === parts[1]) {
              return true;
            }
            break;
          }
          case 'lamination': {
            if (printNodeOrderItem.book.cover.laminateType === parts[1]) {
              return true;
            }
            break;
          }
          case 'binding': {
            if (printNodeOrderItem.book.bindingType === parts[1]) {
              return true;
            }
            break;
          }
          case 'trimSize': {
            if (printNodeOrderItem.book.body.width / 72 === 5.5 && printNodeOrderItem.book.body.height / 72 === 8.5) {
              if (parts[1] === '_5.5X8.5') {
                return true;
              }
            } else {
              if (printNodeOrderItem.book.body.width / 72 === 6.0 && printNodeOrderItem.book.body.height / 72 === 9.0) {
                if (parts[1] === '_6.0X9.0') {
                  return true;
                }
              } else {
                if (printNodeOrderItem.book.body.width / 72 === 8.5 && printNodeOrderItem.book.body.height / 72 === 11) {
                  if (parts[1] === '_8.5X11.0') {
                    return true;
                  }
                } else {
                  if (parts[1] === 'Other') {
                    return true;
                  }
                }
              }
            }
            break;
          }
          case 'impositionLayout': {
            if (printNodeOrderItem.book.body.width / 72 > 6 || printNodeOrderItem.book.body.height / 72 > 9) {
              if (parts[1] === '_2UP') {
                return true;
              }
            } else {
              if (parts[1] === '_4UP') {
                return true;
              }
            }
            break;
          }
          case 'quantityGroup': {
            if (printNodeOrderItem.quantity < 20) {
              if (parts[1] === '_001-019') {
                return true;
              }
            } else {
              if (printNodeOrderItem.quantity >= 20 && printNodeOrderItem.quantity < 50) {
                if (parts[1] === '_020-049') {
                  return true;
                }
              } else {
                if (printNodeOrderItem.quantity >= 50 && printNodeOrderItem.quantity < 100) {
                  if (parts[1] === '_050-099') {
                    return true;
                  }
                } else {
                  if (printNodeOrderItem.quantity >= 100 && printNodeOrderItem.quantity < 250) {
                    if (parts[1] === '_100-249') {
                      return true;
                    }
                  } else {
                    if (printNodeOrderItem.quantity >= 250 && printNodeOrderItem.quantity < 500) {
                      if (parts[1] === '_250-499') {
                        return true;
                      }
                    } else {
                      if (printNodeOrderItem.quantity >= 500) {
                        if (parts[1] === '_500+') {
                          return true;
                        }
                      }
                    }
                  }
                }
              }
            }
            break;
          }
          case 'oneDigitZip': {
            const oneDigitZip = ManufacturingBucketsComponent.getFacilityBasedOnCountryStateAndOneDigitZip(printNodeOrderItem.printNodeOrder.country, printNodeOrderItem.printNodeOrder.state, printNodeOrderItem.printNodeOrder.postalcode ? printNodeOrderItem.printNodeOrder.postalcode.substring(0, 1) : '', printNodeOrderItem.printNodeOrder.printnode_ID);
            if (oneDigitZip === parts[1]) {
              return true;
            }
            break;
          }
          default: {
            return false;
          }
        }
      }
    }
    return false;
  }

  // addManufacturingSet(manufacturingTaskBucketPeriod: ManufacturingTaskBucketPeriod) {
  //   const manufacturingSet = new ManufacturingSet(undefined);
  //   manufacturingSet.manufacturingTaskBucketPeriod_id = manufacturingTaskBucketPeriod.ID;
  //   manufacturingSet.printnode_id = manufacturingTaskBucketPeriod.printnode_ID;
  //   manufacturingSet.details = {
  //     rule: "Base Expanding B&W",
  //     ruleType: "EXPANDING",
  //     interiorType: "",
  //     squareInchesOfPrint: [0, 216000],
  //     dueDate: "2021-11-03T05:00:00.000Z",
  //     trimSizeBreaks: "",
  //     SinglesOrBatch: "",
  //     interiorPaperType: 0,
  //     laminateType: ""
  //   };
  //   this.manufacturingSetService.addItem(manufacturingSet).subscribe(object => {
  //     if (object) {
  //       manufacturingTaskBucketPeriod.manufacturingSets.push(object);
  //     }
  //     console.log(`Added ManufacturingSet : ${object}`);
  //   });
  //
  // }

  toggleManufacturingSetOpenStatus(manufacturingSet: ManufacturingSet) {
    if (manufacturingSet.openStatus === 'OPEN') {
      manufacturingSet.openStatus = 'CLOSED';
    } else {
      manufacturingSet.openStatus = 'OPEN';
    }
    this.manufacturingSetService.setOpenStatus(manufacturingSet.ID, manufacturingSet.openStatus).subscribe(object => {
      console.log(`Added ManufacturingSet : ${object}`);
    });
  }

  toggleManufacturingTaskBucketPeriodOpenStatus(manufacturingTaskBucketPeriod: ManufacturingTaskBucketPeriod) {
    if (manufacturingTaskBucketPeriod.status === 'OPEN') {
      manufacturingTaskBucketPeriod.status = 'CLOSED';
    } else {
      manufacturingTaskBucketPeriod.status = 'OPEN';
    }
    if (!(manufacturingTaskBucketPeriod instanceof ManufacturingTaskBucketPeriod)) {
      manufacturingTaskBucketPeriod = new ManufacturingTaskBucketPeriod(manufacturingTaskBucketPeriod);
    }
    this.manufacturingTaskBucketPeriodService.updateItem(manufacturingTaskBucketPeriod).subscribe(object => {
      console.log(`Changed manufacturingTaskBucketPeriod status : ${object}`);
    });
  }

  getManufacturingTask(manufacturingSet: ManufacturingSet, useType: string): ManufacturingTask {
    if (manufacturingSet && manufacturingSet.manufacturingTasks) {
      for (const manufacturingTask of manufacturingSet.manufacturingTasks) {
        if (manufacturingTask.useType === useType) {
          return manufacturingTask;
        }
      }
    }
    return undefined;
  }

  reload(manufacturingSet: ManufacturingSet) {
  }

  prepare(manufacturingSet: ManufacturingSet, useType: string) {
    const manufacturingTask = this.getManufacturingTask(manufacturingSet, useType);
    this.manufacturingTaskService.prepare(manufacturingTask).subscribe(changedManufacturingTask => {
      manufacturingTask.inputStatus = changedManufacturingTask.inputStatus;
      console.log(`Perpare Response for ManufacturingSet : ${manufacturingTask.ID} ${manufacturingTask.inputStatus}`);
    });
  }

  refresh() {
    this.getData(true);
  }

  // getManufacturingSets(printNodeorderItem: PrintNodeOrderItem): ManufacturingSet[] {
  //   const ret: ManufacturingSet[] = [];
  //   this.manufacturingSets.forEach(manufacturingSet => {
  //     if (!manufacturingSet.details.laminateType || manufacturingSet.details.laminateType === printNodeorderItem.book.cover.laminateType) {
  //       ret.push(manufacturingSet);
  //     }
  //     const trimSizeBreaks = '_2UP';
  //     if (!manufacturingSet.details.trimSizeBreaks || manufacturingSet.details.trimSizeBreaks === trimSizeBreaks) {
  //       ret.push(manufacturingSet);
  //     }
  //   });
  //   return ret;
  // }

  ////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  // AssignPrintNodeOrderItemToManufacturingSet Interface Implementation
  ////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////

  movePrintNodeOrderToManufacturingTaskBucket(printNodeOrder: PrintNodeOrder, manufacturingTaskBucketId: number, element: any) {
    element.classList.add('spinner');
    this.manufacturingSetAssignerService.movePrintNodeOrderToManufacturingTaskBucket(printNodeOrder.ID, manufacturingTaskBucketId).subscribe(p => {
      element.classList.remove('spinner');
      console.log('movePrintNodeOrderToManufacturingTaskBucket');
    });
  }

  movePrintNodeOrderToManufacturingTaskBucketPeriod(printNodeOrder: PrintNodeOrder, manufacturingTaskBucketPeriodId: number, element: any) {
    element.classList.add('spinner');
    this.manufacturingSetAssignerService.movePrintNodeOrderToManufacturingTaskBucketPeriod(printNodeOrder.ID, manufacturingTaskBucketPeriodId).subscribe(p => {
      element.classList.remove('spinner');
      console.log('movePrintNodeOrderToManufacturingTaskBucketPeriod');
    });
  }

  movePrintNodeOrderItemToManufacturingTaskBucketPeriod(printNodeOrderItem: PrintNodeOrderItem, manufacturingTaskBucketPeriodId: number, element: any) {
    element.classList.add('spinner');
    this.manufacturingSetAssignerService.movePrintNodeOrderItemToManufacturingTaskBucketPeriod(printNodeOrderItem.ID, manufacturingTaskBucketPeriodId).subscribe(p => {
      element.classList.remove('spinner');
      console.log('movePrintNodeOrderToManufacturingTaskBucketPeriod');
    });
  }

  movePrintNodeOrderItemToNextManufacturingTaskBucketPeriod(printNodeOrderItem: PrintNodeOrderItem, manufacturingTaskBucketPeriodId: number, element: any) {
    element.classList.add('spinner');
    this.manufacturingSetAssignerService.movePrintNodeOrderItemToNextManufacturingTaskBucketPeriod(printNodeOrderItem.ID, manufacturingTaskBucketPeriodId).subscribe(p => {
      element.classList.remove('spinner');
      console.log('movePrintNodeOrderToManufacturingTaskBucketPeriod');
    });
  }

  // testMovePrintNodeOrderItemToNextManufacturingTaskBucketPeriod(printNodeOrderItem: PrintNodeOrderItem, manufacturingTaskBucketPeriodId: number, element: any) {
  //   element.classList.add('spinner');
  //   this.manufacturingSetAssignerService.movePrintNodeOrderItemToNextManufacturingTaskBucketPeriod(printNodeOrderItem.ID, manufacturingTaskBucketPeriodId).subscribe(p => {
  //     element.classList.remove('spinner');
  //     console.log(element['id']);
  //     console.log('movePrintNodeOrderToManufacturingTaskBucketPeriod');
  //   });
  // }

  movePrintNodeOrderItemToManufacturingSet(printNodeOrderItem: PrintNodeOrderItem, manufacturingSetId: number, element: any) {
    element.classList.add('spinner');
    printNodeOrderItem.manufacturingset_id = manufacturingSetId;
    printNodeOrderItem.manufacturingtaskbucketperiod_id = 0;
    // if (manufacturingSet) {
    //   if ( manufacturingSet.details ) {
    //     manufacturingSet.details['SinglesOrBatch'] = printNodeOrderItem.quantity < 15 ? 'SINGLES' : 'BATCH';
    //     manufacturingSet.details['trimSizeBreaks'] = ( printNodeOrderItem.book.body.height / 72 > 9 || printNodeOrderItem.book.body.width / 72 > 6 ) ? '_2UP' : '_4UP';
    //     manufacturingSet.details['laminateType'] = printNodeOrderItem.book.cover.laminateType;
    //     manufacturingSet.details['interiorPaperType'] = printNodeOrderItem.book.body.paperType_ID;
    //     manufacturingSet.details['bindingType'] = printNodeOrderItem.book.bindingType;
    //     manufacturingSet.details['numberUp'] = ( printNodeOrderItem.book.body.height / 72 > 9 || printNodeOrderItem.book.body.width / 72 > 6 ) ? 2 : 4;
    //   }
    this.manufacturingSetAssignerService.movePrintNodeOrderItemToManufacturingSet(printNodeOrderItem.ID, manufacturingSetId).subscribe(updatedPrintNodeOrder => {
      element.classList.remove('spinner');
      // TODO Update the other printNodeOrderItems in the printNodeOrder if they were moved to different sets
    });
    // }
  }

  addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingTaskBucket(printNodeOrderItem: PrintNodeOrderItem, manufacturingTaskBucketId: number, element: any) {
    element.classList.add('spinner');
    this.manufacturingSetAssignerService.addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingTaskBucket(printNodeOrderItem.ID, manufacturingTaskBucketId).subscribe(p => {
      element.classList.remove('spinner');
      console.log('addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingTaskBucket');
    });
  }

  addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingSet(printNodeOrderItem: PrintNodeOrderItem, manufacturingTaskBucketPeriodId: number, element: any) {
    element.classList.add('spinner');
    this.manufacturingSetAssignerService.addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingSet(printNodeOrderItem.ID, manufacturingTaskBucketPeriodId).subscribe(p => {
      element.classList.remove('spinner');
      console.log('addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingSet');
    });
  }

  changeFacility(printNodeOrder: PrintNodeOrder, facilityId: number, element: any) {
    element.classList.add('spinner');
    printNodeOrder.printnode_ID = facilityId;
    this.manufacturingSetAssignerService.changeFacility(printNodeOrder).subscribe(changedPrintNodeOrder => {
      element.classList.remove('spinner');
      printNodeOrder = changedPrintNodeOrder;
    });
  }

  changeFacilityAndInventoryType(printNodeOrder: PrintNodeOrder, facilityId: number, inventoryType: string, element: any) {
    element.classList.add('spinner');
    printNodeOrder.printnode_ID = facilityId;
    printNodeOrder.inventoryType = inventoryType;
    this.manufacturingSetAssignerService.changeFacilityAndInventoryType(printNodeOrder).subscribe(changedPrintNodeOrder => {
      element.classList.remove('spinner');
      printNodeOrder = changedPrintNodeOrder;
    });
  }


  selectPrintNodeOrder(printNodeOrderId: number) {
    if (this.selectedPrintNodeOrderId === printNodeOrderId) {
      this.selectedPrintNodeOrderId = 0;
    } else {
      this.selectedPrintNodeOrderId = printNodeOrderId;
    }
  }
}
