<div class="package card-list mat-elevation-z1" [style.background-color]="getBackgroundColor(pkg)"  [style.border-color]="getBorderColor(pkg)">
  <ng-container *ngIf="pkg['issues'] && pkg['issues'].length > 0">
    <ng-container *ngFor="let issue of pkg['issues']">
      <app-issue [issue]="issue" [objectId]="issue.objectId" [objectType]="issue.objectType" [userRoleName]="issue.userRole" [userRoleId]="issue.userId" [supportRoleName]="issue.selectedRole" [supportRoleId]="issue.selectedId" [displayOnly]="false"></app-issue>
    </ng-container>
  </ng-container>
  <h4 class="mat-h4" style="margin: 0; font-size: smaller; padding: 0; color: white">
    <span class="dataBit" *ngFor="let manufacturingSet of manufacturingSetsContributingToPackage" [style.color]="getManufacturingSetBackgroundColor(manufacturingSet.manufacturingTaskBucketPeriod.period)">{{manufacturingSet.ID}} {{manufacturingSet.manufacturingTaskBucketPeriod.period}}</span><ng-container *ngIf="numberOfNotShippedPackagesInOrder > 1 && showExpandPrintNodeOrderPackages"><button (click)="expandPrintNodeOrderPackages['' + pkg.printNodeOrder_ID ] = !expandPrintNodeOrderPackages['' + pkg.printNodeOrder_ID ]" mat-icon-button color="primary"><mat-icon *ngIf="!expandPrintNodeOrderPackages['' + pkg.printNodeOrder_ID ]" i18n-title="@@Expand" title="Expand" color="primary">open_in_full</mat-icon><mat-icon *ngIf="expandPrintNodeOrderPackages['' + pkg.printNodeOrder_ID ]" i18n-title="@@Collapse" title="Collapse" color="primary">close_fullscreen</mat-icon></button><span style="color: #e405ff; font-size: xx-large" [style.color]="expandPrintNodeOrderPackages['' + pkg.printNodeOrder_ID ] ? 'white' : 'green'" [style.background-color]="expandPrintNodeOrderPackages['' + pkg.printNodeOrder_ID ] ? 'green' : ''">&nbsp;{{numberOfNotShippedPackagesInOrder}}&nbsp;</span></ng-container>
  </h4>
  <h2 class="mat-h2">
    <span class="dataBit" [style.color]="expandPrintNodeOrderPackages['' + pkg.printNodeOrder_ID ] ? '#e405ff' : ''">{{pkg.printNodeOrder_ID}}</span>/<span class="dataBit" style="font-size: smaller">{{pkg.package_ID}}</span>
    <button mat-icon-button class="floatingButton" (click)="expandPackage = !expandPackage" i18n-style="@@floatEnd" style="float:right"><mat-icon *ngIf="expandPackage" color="primary">expand_more</mat-icon><mat-icon *ngIf="!expandPackage" style="color: green">expand_less</mat-icon></button>
    <button mat-icon-button class="floatingButton" (click)="expandAddress = !expandAddress" i18n-style="@@floatEnd" style="float:right"><mat-icon *ngIf="expandAddress" color="primary">contact_mail</mat-icon><mat-icon *ngIf="!expandAddress" style="color: green">contact_mail</mat-icon></button>
    <ng-container [ngSwitch]="pkg.pickSheet">
      <ng-container *ngSwitchCase="'INITIAL'" [ngSwitch]="pkg.shippingLabelStatus">
        <button *ngSwitchCase="'READY'" mat-icon-button i18n-title="@@Print" title="Print" class="floatingButton" (click)="print(pkg)" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon>print</mat-icon></button>
        <button *ngSwitchCase="'INITIAL'" mat-icon-button i18n-title="@@Not Ready" title="Not Ready" class="floatingButton" i18n-style="@@floatEnd" style="float:right;" color="accent"><mat-icon>print_disabled</mat-icon></button>
        <button *ngSwitchCase="'ERROR'" mat-icon-button i18n-title="@@Error" title="Error" class="floatingButton" i18n-style="@@floatEnd" style="float:right;" color="warn"><mat-icon>print_disabled</mat-icon></button>
        <button *ngSwitchCase="'PRINTING'" mat-icon-button i18n-title="@@Reprint" title="Reprint" class="floatingButton" i18n-style="@@floatEnd" style="float:right; color: grey;"><mat-icon>print</mat-icon></button>
      </ng-container>
      <button *ngSwitchDefault mat-icon-button i18n-title="@@Reprint" title="Reprint" class="floatingButton" (click)="print(pkg)" i18n-style="@@floatEnd" style="float:right" color="warn"><mat-icon>print</mat-icon></button>
    </ng-container>
  </h2>
  <div class="packageDetails" *ngIf="pkg.shippingLabelErrorMessage" style="background-color: red; color: white;">{{pkg.shippingLabelErrorMessage}}</div>
  <div class="packageDetails">
    <span class="dataBit">{{pkg.carrier}}_{{pkg.servicelevel}}</span>
    <span class="dataBit">{{pkg.weight | number: '1.1-1'}}lbs</span>
<!--    <span class="dataBitNewLine" *ngIf="packageTypeById[pkg.packageType_ID]">{{packageTypeById[pkg.packageType_ID].name}}</span>-->
    <span class="dataBitNewLine" *ngIf="packageTypeService.getLocalItem(pkg.packageType_ID)">{{packageTypeService.getLocalItem(pkg.packageType_ID).name}}</span>
  </div>
  <div class="packageDetails" *ngIf="pkg.trackingnumber"><a [href]="trackingNumberURL(pkg)" target="_blank" rel="noopener noreferrer" style="color: lightskyblue">{{pkg.trackingnumber}}</a></div>
  <div class="basicInfo" *ngIf="pkg.printNodeOrder && expandAddress">
    <div class="clippedWidth">
      <span title="Created" i18n-title="@@Created">{{pkg.printNodeOrder.created | date: 'yyyy-MM-dd' }}</span>
    </div>
    <div class="clippedWidth">
      <span title="Name" i18n-title="@@Name">{{pkg.printNodeOrder.firstname}} {{pkg.printNodeOrder.lastname}} </span>
      <span title="Address" i18n-title="@@Address">{{pkg.printNodeOrder.address1}}</span>
    </div>
    <div class="details clippedWidth">
      <span title="City" i18n-title="@@City">{{pkg.printNodeOrder.city}}, </span>
      <span title="State" i18n-title="@@State">{{pkg.printNodeOrder.state}} </span>
      <span title="Postal Code" i18n-title="@@Postal Code">{{pkg.printNodeOrder.postalcode}} </span>
      <span title="Country" i18n-title="@@Country">{{pkg.printNodeOrder.country}}</span>
    </div>
    <button *ngIf="pkg.shippingLabelStatus==='ERROR' || startupService.admin" mat-icon-button color="primary" (click)="openEditAddressDialog()" i18n-matTooltip="@@Edit Address" matTooltip="Edit Address"><i class="material-icons">edit</i></button>
    <button *ngIf="pkg.shippingStatus === 'NONE'" mat-icon-button i18n-title="@@Manually Mark Package Shipped" title="Manually Mark Package Shipped" class="floatingButton" (click)="manuallyMarkPackageShipped(pkg, 'SHIPPED')" color="primary"><mat-icon>done_outline</mat-icon></button>
    <button *ngIf="pkg.shippingStatus === 'NONE'" mat-icon-button i18n-title="@@Change Packages" title="Change Packages" class="floatingButton" (click)="changePackages(pkg, pkg.printNodeOrder)" color="primary"><mat-icon>change_history</mat-icon></button>
    <app-issue  title="Issue For Package" title-i18n="@@Issue For Package" [objectId]="pkg.package_ID" [objectType]="'PACKAGE'" [userRoleName]="'facility'" [userRoleId]="pkg.printnode_ID" [supportRoleName]="'network'" [supportRoleId]="1" [displayOnly]="false"></app-issue>
<!--    <button mat-icon-button i18n-title="@@Report A Problem With This Package" title="Report A Problem With This Package" (click)="reportShippingProblem(pkg)" color="primary"><mat-icon>report_problem</mat-icon></button>-->
<!--    <button *ngIf="pkg.shippingStatus === 'SHIPPED'" mat-icon-button i18n-title="@@Manually Mark Package NOT Shipped" title="Manually Mark Package NOT Shipped" class="floatingButton" (click)="manuallyMarkPackageShipped(package, 'NONE')" color="primary"><mat-icon>change_history</mat-icon></button>-->
    <!--                                  <button *ngIf="!expandReplanPackages" mat-icon-button i18n-title="@@Change Quantity In Package" title="Change Quantity In Package" class="floatingButton" (click)="expandReplanPackages = !expandReplanPackages" color="primary"><mat-icon>change_history</mat-icon></button>-->
  </div>
  <div class="columnArea" *ngIf="(!expandPackage && showPrintNodeOrderItems) || (expandPackage && !showPrintNodeOrderItems)">
    <div class="printNodeOrderItems card-list-content">
      <ng-container *ngFor="let orderItemToPackage of pkg.orderItemToPackages">
        <ng-container *ngIf="orderItemToPackage.orderItem">
          <mat-card appearance="outlined" *ngFor="let printNodeOrderItem of orderItemToPackage.orderItem.printNodeOrderItems" [style.border]="printNodeOrderItem.type === 'REPRINT' ? 'solid 5px blue' : (printNodeOrderItem.type === 'PARTIAL' ? 'solid 5px red' : '')">
            <mat-card-content style="padding: 2px 2px 4px 6px">
              <div style="width: 80%; display: inline-block;">
                <div class="clippedWidth" style="font-weight: bold">
                  <span style="width: 25px">
                    <ng-container *ngIf="printNodeOrderItem.type === 'INITIAL'">
                    <span class="clippedWidth dataBit">{{orderItemToPackage.quantity}}</span>
                    <span class="clippedWidth dataBit" style="color: darkgrey" *ngIf="orderItemToPackage.quantity !== printNodeOrderItem.quantity">{{printNodeOrderItem.quantity}}</span>
                    </ng-container>
                    <ng-container *ngIf="printNodeOrderItem.type === 'PARTIAL'">
                      <span class="clippedWidth dataBit">{{orderItemToPackage.quantity}}</span>
                      <span class="clippedWidth dataBit" style="color: red">{{printNodeOrderItem.quantity}}</span>
                    </ng-container>
                    <ng-container *ngIf="printNodeOrderItem.type === 'REPRINT'">
                      <span class="clippedWidth dataBit">{{orderItemToPackage.quantity}}</span>
                      <span class="clippedWidth dataBit" style="color: red">{{printNodeOrderItem.quantity}}</span>
                    </ng-container>
                  </span>
                  <span class="clippedWidth dataBit" title="Book Id" i18n-title="@@Book Id" style="width: 25px">{{printNodeOrderItem.book.ID}} </span>
                  <span class="clippedWidth dataBit" title="Title" i18n-title="@@Title">{{printNodeOrderItem.book.title}}</span>
                </div>
                <div class="details clippedWidth">
                  <span class="clippedWidth dataBit">{{printNodeOrderItem.book.body.width/72 | number: '1.1-1' }}in X{{printNodeOrderItem.book.body.height/72 | number: '1.1-1' }}in </span>
                  <!--                                <span title="Organization" i18n-title="@@Organization" style="font-weight: bold">{{printNodeOrderItem.printNodeOrder.org_ID}}</span>-->
                  <span class="clippedWidth dataBit" title="Thickness" i18n-title="@@Thickness">{{printNodeOrderItem.book.body.spine / 72 * orderItemToPackage.quantity | number: '1.1-1' }}in</span>
                  <span class="clippedWidth dataBit" title="Weight" i18n-title="@@Weight">{{printNodeOrderItem.book.weight * orderItemToPackage.quantity | number: '1.1-1' }}lbs</span>
                </div>
              </div>
              <div style="width: 20%; display: inline-block; color: white">
                <div>
                  <span *ngIf="manufacturingSetService.getLocalItem(printNodeOrderItem.manufacturingset_id)" [style.color]="getManufacturingSetBackgroundColor(manufacturingSetService.getLocalItem(printNodeOrderItem.manufacturingset_id).manufacturingTaskBucketPeriod.period)"><span class="clippedWidth dataBit" >{{printNodeOrderItem.manufacturingset_id}}</span><span class="clippedWidth dataBit">{{printNodeOrderItem.manufacturingSetItemId}}</span></span>
                </div>
                <div>
                  <button *ngIf="!expandPrintNodeOrderItemTools[printNodeOrderItem.ID + '-' + pkg.package_ID]" mat-icon-button i18n-title="@@Expand Options" title="Expand Options" class="floatingButton" (click)="expandPrintNodeOrderItemTools[printNodeOrderItem.ID + '-' + pkg.package_ID] = !expandPrintNodeOrderItemTools[printNodeOrderItem.ID + '-' + pkg.package_ID]" color="primary"><mat-icon>more_vert</mat-icon></button>
                  <button *ngIf="expandPrintNodeOrderItemTools[printNodeOrderItem.ID + '-' + pkg.package_ID]" mat-icon-button i18n-title="@@Collapse Options" title="Collapse Options" class="floatingButton" (click)="expandPrintNodeOrderItemTools[printNodeOrderItem.ID + '-' + pkg.package_ID] = !expandPrintNodeOrderItemTools[printNodeOrderItem.ID + '-' + pkg.package_ID]" color="primary"><mat-icon>expand_less</mat-icon></button>
                </div>
              </div>
              <div *ngIf="expandPrintNodeOrderItemTools[printNodeOrderItem.ID + '-' + pkg.package_ID]">
<!--                <button disabled mat-icon-button i18n-title="@@Report A Problem With Shipping" title="Report A Problem With Shipping" (click)="reportShippingProblem(pkg)" color="primary"><mat-icon>report_problem</mat-icon></button>-->
                <button mat-icon-button i18n-title="@@Report A Problem With The Book" title="Report A Problem With The Book" (click)="reportBookProblem(printNodeOrderItem)" color="primary"><mat-icon>report_problem</mat-icon></button>
                <button mat-icon-button i18n-title="@@Reprint Body" title="Reprint Body" (click)="reprint(printNodeOrderItem, printNodeOrderItem.manufacturingSet,'BodyPrint')" color="primary"><mat-icon>note_add</mat-icon></button>
                <button mat-icon-button i18n-title="@@Reprint Cover" title="Reprint Cover" (click)="reprint(printNodeOrderItem, printNodeOrderItem.manufacturingSet, 'CoverPrint')" color="primary"><mat-icon>add_to_queue</mat-icon></button>
              </div>

            </mat-card-content>
          </mat-card>
        </ng-container>
      </ng-container>
      <div *ngIf="expandReplanPackages" style="width: 100%">
        <button mat-icon-button i18n-title="@@Change Quantity In Package" title="Change Quantity In Package" class="floatingButton" (click)="expandReplanPackages = !expandReplanPackages" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon>expand_less</mat-icon></button>
        <div>Comming Soon - Change the quantities in Packages</div>
      </div>
    </div>
  </div>
</div>
