import {Organization} from "./Organization";
import {Umbrella} from "./Umbrella";

export class ServiceProvider {
  ID: number;
  Name: string;
  admin_ID: number;
  adminOrg_ID: number;
  collectionprovider_ID: number;
  signupHash: string | null;
  domain: string | null;
  overridePassword: string | null;
  defaultLabel_ID: number | null;
  invoiceServerURL: string | null;
  invoiceServerKey: string | null;
  invoiceClientId: number;
  invoiceRecurringId: number;
  firstName: string | null;
  lastName: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  country: string | null;
  email: string | null;
  phone: string | null;
  currency: string;
  crmServerURL: string | null;
  crmServerKey: string | null;
  productionRate: string | null;
  noticeEmail: string | null;
  tax: string | null;
  defaultOrganizationTax: string | null;
  lastUpdate: Date;
  defaultPackageLabelResource_ID: number;
  serviceProviderChargedProductionRate: string | null;
  smtpServer: string | null;
  smtpPort: string | null;
  emailUser: string | null;
  emailPassword: string | null;
  reportSalesTaxTransaction: string;
  organizations: Organization[];
  umbrellas: Umbrella[];
  catalogs: string;


  constructor ( obj ) {
    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        if (prop === 'organizations') {
          for (const o of obj[prop]) {
            this.organizations.push(new Organization(o));
          }
        } else {
          if (prop === 'umbrellas') {
            for (const o of obj[prop]) {
              this.umbrellas.push(new Umbrella(o));
            }
          } else {
            this[prop] = obj[prop];
          }
        }
      }
    }
  }
}

