import { Component, OnInit } from '@angular/core';
import {Recurring} from "../../../../model/cart/Recurring";
import {Subscription} from "../../../../model/Subscription";
import {Router} from "@angular/router";
import {StartupService} from "../../../service-ui/startup.service";
import {NavbarService} from "../../../service-ui/navbar.service";
import {SubscriptionService} from "../../../service-data/subscription.service";
import {LoggedInCallback} from "../../../service-auth/cognito.service";
import { FormsModule } from '@angular/forms';
import {Product} from "../../../../model/Product";
import {EcommerceConnection} from "../../../../model/EcommerceConnection";
import {EcommerceConnectionService} from "../../../service-data/ecommerce-connection.service";

@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.css']
})
export class EcommerceComponent implements OnInit, LoggedInCallback {
  selectedEcommerceConnection: EcommerceConnection;
  ecommerceConnections: EcommerceConnection[];

  constructor(public router: Router, public startupService: StartupService, private ecommerceConnectionService: EcommerceConnectionService) {
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Account");
      // this.router.navigate(['/home/login']);
    } else {
      this.getEcommerceConnections();
    }
  }
  getEcommerceConnections(): void {
    this.ecommerceConnectionService.getEcommerceConnections()
      .subscribe(ecommerceConnections => {
        this.ecommerceConnections = ecommerceConnections;
      });
  }
}
