import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../model/User";
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../../service-data/print-node-order.service";

@Component({
  selector: 'app-print-node-order',
  templateUrl: './print-node-order.component.html',
  styleUrls: ['./print-node-order.component.css']
})
export class PrintNodeOrderComponent implements OnInit {
  @Input() printNodeOrder: PrintNodeOrder;

  printNodeOrderWithItems: PrintNodeOrder;

  constructor(private printNodeOrderService: PrintNodeOrderService) {

  }

  ngOnInit() {
    if ( this.printNodeOrder.printNodeOrderItems && this.printNodeOrder.printNodeOrderItems.length > 0 ) {
      this.printNodeOrderWithItems = this.printNodeOrder;
    } else {
      this.printNodeOrderService.getItem(this.printNodeOrder.ID).subscribe(pno => {
        this.printNodeOrderWithItems = pno;
      });
    }
  }

}
