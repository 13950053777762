import {InteriorThemeConfigurationSelector} from "./InteriorThemeConfigurationSelector";
import {InteriorThemeConfigurationPropertyType} from "./InteriorThemeConfigurationPropertyType";
import {isUndefined} from "util";
import {InteriorThemeEditor} from "./InteriorThemeEditor";

export class InteriorThemeEditorItem {
  public propertiesType: InteriorThemeConfigurationPropertyType;
  public selector: InteriorThemeConfigurationSelector;
  public rule: CSSStyleRule;
  public isSelectedElement: boolean = false;
  public interiorThemeEditor: InteriorThemeEditor;

  constructor( selector: InteriorThemeConfigurationSelector,  propertiesType: InteriorThemeConfigurationPropertyType, rule: CSSStyleRule, interiorThemeEditor: InteriorThemeEditor) {
    // if ( propertiesType === undefined || propertiesType === null ) {
    //   console.log('Undefined propertiesType');
    // }
    this.selector = selector;
    this.propertiesType = propertiesType;
    this.rule = rule;
    this.interiorThemeEditor = interiorThemeEditor;
  }

  contains(propertyName: string): boolean {
    if ( this.propertiesType.properties.indexOf(propertyName) > -1 ) {
      return true;
    }
    return false;
  }

  getValue(propertyName: string): string {
    const value = this.rule.style.getPropertyValue(propertyName);
    if ( value.startsWith('"') && value.endsWith('"')) {
      return value.substr(1, value.length - 2 );
    }
    return value;
  }

  setValue(propertyName: string, value: string) {
    this.rule.style.setProperty(propertyName, value);
    if ( propertyName !== 'background') {
      this.interiorThemeEditor.save();
    }
  }

  removeProperty(propertyName: string) {
    this.rule.style.removeProperty(propertyName);
    if ( propertyName !== 'background') {
      this.interiorThemeEditor.save();
    }
  }
  getStyle(): string {
    let style = '';
    for ( const prop of this.propertiesType.properties ) {
      const value = this.getValue(prop);
      if ( value ) {
        style += '    ' + prop + ':' + value + ';\n';
      }
    }
    return style;
  }
  getCSS(): string {
    let css = '';
    css += this.selector.selector + '\n';
    css += '{\n';
    css += this.getStyle() + '\n';
    css += '}\n';
    return css;
  }
  // hyphenToCamelCase(hyphen: string): string {
  //   return hyphen.replace(/-([a-z])/g, function (match) {
  //     return match[1].toUppercase();
  //   });
  // }
}


