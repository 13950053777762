<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Edit Facility Inventory Product">Edit Facility Inventory Product</h2>
    <h3 class="primary">{{facilityInventoryProduct.title}}</h3>
  </div>
  <div>
    <form [formGroup]="formGroup" (change)="formChanged()">
      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-select placeholder="State" i18n-placeholder="@@State" formControlName="state" required>
                <mat-option value="PREPARING" i18n='@@Preparing'[disabled]="startupService.selectedRole === 'organization' && facilityInventoryProduct.state !== 'PREPARING'">Preparing</mat-option>
                <mat-option value="ACTIVE" i18n='@@Active' [disabled]="startupService.selectedRole === 'organization' && facilityInventoryProduct.state === 'PREPARING'">Active</mat-option>
                <mat-option value="INACTIVE" i18n='@@Inactive'>Inactive</mat-option>
                <mat-option value="RETIRE" i18n='@@Retire'>Retire</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-select  placeholder="Inventory Management Type" i18n-placeholder="@@Inventory Management Type" formControlName="inventoryManagementType" required>
                <mat-option value="CUSTOMER" i18n='@@Customer'>Customer</mat-option>
                <mat-option value="LEVEL" i18n='@@Level'>Level</mat-option>
                <mat-option value="ADHOC" i18n='@@Adhoc'>Adhoc</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
<!--        <mat-grid-tile *ngIf="products && products.length > 0">-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <mat-select  placeholder="Product" i18n-placeholder="@@Product" formControlName="product" required>-->
<!--                <mat-option *ngFor="let p of products" [value]="p">{{p.ID}} {{p.title}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
        <mat-grid-tile *ngIf="startupService.selectedRole === 'facility'">
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Sequence" placeholder="Sequence" id="sequence" required type="number" min="0"  max="1500" formControlName="sequence">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="startupService.selectedRole === 'facility'">
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@location" placeholder="Location" id="location" required type="text" formControlName="location">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="startupService.selectedRole === 'facility'">
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Shelf" placeholder="Shelf" id="shelf" required type="text" formControlName="shelf">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Reorder Quantity" placeholder="Reorder Quantity" id="reorderQuantity" required type="number" min="4"  max="1500" formControlName="reorderQuantity">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Reorder Level" placeholder="Reorder Level" id="reorderLevel" required type="number" min="0"  max="1500" formControlName="reorderLevel">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="startupService.selectedRole === 'facility'">
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Case Quantity" placeholder="Case Quantity" id="caseQuantity" required type="number" min="0"  max="1500" formControlName="caseQuantity">
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="formGroup.invalid" (click)="onSave()" i18n="@@Save Facility Inventory Product">Save Facility Inventory Product</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
