import {TableFilter} from "./TableFilter";

export class AndTableFilter extends TableFilter {
  public children: TableFilter[] = [];

  constructor (obj: any) {
    super(obj);
    if ( obj.children && obj.children instanceof Array ) {
      for ( const child of obj.children ) {
        this.children.push(TableFilter.create(child));
      }
    }
  }

  public match(data: any, filter: string): boolean {
    for ( const child of this.children ) {
      if ( !child.match(data, filter)) {
        return false;
      }
    }
    return true;
  }
}
