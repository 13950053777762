<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Orders">Orders</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Orders">Orders</span>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="ordersearch"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <div class="app-content">
        <ng-container>
<!--          <form [formGroup]="dateRangeForm">-->
          <form [formGroup]="formGroup" style="padding: 10px">
            <h3 i18n="@@Search Criteria">Search Criteria</h3>
            <mat-form-field>
              <textarea name="name" [(ngModel)]="name" matInput i18n-placeholder="@@Name" placeholder="Name" formControlName="name"></textarea>
            </mat-form-field>
            <br>
            <mat-form-field>
              <textarea name="address" [(ngModel)]="address" matInput i18n-placeholder="@@Address" placeholder="Address" formControlName="address"></textarea>
            </mat-form-field>
            <mat-form-field>
              <input name="city" [(ngModel)]="city" matInput  i18n-placeholder="@@City" placeholder="City" formControlName="city">
            </mat-form-field>
            <mat-form-field>
              <input name="state" [(ngModel)]="state" matInput  i18n-placeholder="@@State" placeholder="State" formControlName="state">
            </mat-form-field>
            <mat-form-field>
              <mat-select name="country" [(ngModel)]="country"  i18n-placeholder="@@Country" placeholder="Country" formControlName="country">
                <mat-option>None</mat-option>
                <mat-option value="AF" i18n='@@Afghanistan'>Afghanistan</mat-option>
                <mat-option value="AL" i18n='@@Albania'>Albania</mat-option>
                <mat-option value="DZ" i18n='@@Algeria'>Algeria</mat-option>
                <mat-option value="AS" i18n='@@American Samoa'>American Samoa</mat-option>
                <mat-option value="AD" i18n='@@Andorra'>Andorra</mat-option>
                <mat-option value="AG" i18n='@@Angola'>Angola</mat-option>
                <mat-option value="AI" i18n='@@Anguilla'>Anguilla</mat-option>
                <mat-option value="AG" i18n='@@Antigua &amp; Barbuda'>Antigua &amp; Barbuda</mat-option>
                <mat-option value="AR" i18n='@@Argentina'>Argentina</mat-option>
                <mat-option value="AA" i18n='@@Armenia'>Armenia</mat-option>
                <mat-option value="AW" i18n='@@Aruba'>Aruba</mat-option>
                <mat-option value="AU" i18n='@@Australia'>Australia</mat-option>
                <mat-option value="AT" i18n='@@Austria'>Austria</mat-option>
                <mat-option value="AZ" i18n='@@Azerbaijan'>Azerbaijan</mat-option>
                <mat-option value="BS" i18n='@@Bahamas'>Bahamas</mat-option>
                <mat-option value="BH" i18n='@@Bahrain'>Bahrain</mat-option>
                <mat-option value="BD" i18n='@@Bangladesh'>Bangladesh</mat-option>
                <mat-option value="BB" i18n='@@Barbados'>Barbados</mat-option>
                <mat-option value="BY" i18n='@@Belarus'>Belarus</mat-option>
                <mat-option value="BE" i18n='@@Belgium'>Belgium</mat-option>
                <mat-option value="BZ" i18n='@@Belize'>Belize</mat-option>
                <mat-option value="BJ" i18n='@@Benin'>Benin</mat-option>
                <mat-option value="BM" i18n='@@Bermuda'>Bermuda</mat-option>
                <mat-option value="BT" i18n='@@Bhutan'>Bhutan</mat-option>
                <mat-option value="BO" i18n='@@Bolivia'>Bolivia</mat-option>
                <mat-option value="BL" i18n='@@Bonaire'>Bonaire</mat-option>
                <mat-option value="BA" i18n='@@Bosnia &amp; Herzegovina'>Bosnia &amp; Herzegovina</mat-option>
                <mat-option value="BW" i18n='@@Botswana'>Botswana</mat-option>
                <mat-option value="BR" i18n='@@Brazil'>Brazil</mat-option>
                <mat-option value="BC" i18n='@@British Indian Ocean Ter'>British Indian Ocean Ter</mat-option>
                <mat-option value="BN" i18n='@@Brunei'>Brunei</mat-option>
                <mat-option value="BG" i18n='@@Bulgaria'>Bulgaria</mat-option>
                <mat-option value="BF" i18n='@@Burkina Faso'>Burkina Faso</mat-option>
                <mat-option value="BI" i18n='@@Burundi'>Burundi</mat-option>
                <mat-option value="KH" i18n='@@Cambodia'>Cambodia</mat-option>
                <mat-option value="CM" i18n='@@Cameroon'>Cameroon</mat-option>
                <mat-option value="CA" i18n='@@Canada'>Canada</mat-option>
                <mat-option value="IC" i18n='@@Canary Islands'>Canary Islands</mat-option>
                <mat-option value="CV" i18n='@@Cape Verde'>Cape Verde</mat-option>
                <mat-option value="KY" i18n='@@Cayman Islands'>Cayman Islands</mat-option>
                <mat-option value="CF" i18n='@@Central African Republic'>Central African Republic</mat-option>
                <mat-option value="TD" i18n='@@Chad'>Chad</mat-option>
                <mat-option value="CD" i18n='@@Channel Islands'>Channel Islands</mat-option>
                <mat-option value="CL" i18n='@@Chile'>Chile</mat-option>
                <mat-option value="CN" i18n='@@China'>China</mat-option>
                <mat-option value="CI" i18n='@@Christmas Island'>Christmas Island</mat-option>
                <mat-option value="CS" i18n='@@Cocos Island'>Cocos Island</mat-option>
                <mat-option value="CO" i18n='@@Colombia'>Colombia</mat-option>
                <mat-option value="CC" i18n='@@Comoros'>Comoros</mat-option>
                <mat-option value="CG" i18n='@@Congo'>Congo</mat-option>
                <mat-option value="CK" i18n='@@Cook Islands'>Cook Islands</mat-option>
                <mat-option value="CR" i18n='@@Costa Rica'>Costa Rica</mat-option>
                <mat-option value="CT" i18n='@@Cote DIvoire'>Cote D'Ivoire</mat-option>
                <mat-option value="HR" i18n='@@Croatia'>Croatia</mat-option>
                <mat-option value="CU" i18n='@@Cuba'>Cuba</mat-option>
                <mat-option value="CB" i18n='@@Curacao'>Curacao</mat-option>
                <mat-option value="CY" i18n='@@Cyprus'>Cyprus</mat-option>
                <mat-option value="CZ" i18n='@@Czech Republic'>Czech Republic</mat-option>
                <mat-option value="DK" i18n='@@Denmark'>Denmark</mat-option>
                <mat-option value="DJ" i18n='@@Djibouti'>Djibouti</mat-option>
                <mat-option value="DM" i18n='@@Dominica'>Dominica</mat-option>
                <mat-option value="DO" i18n='@@Dominican Republic'>Dominican Republic</mat-option>
                <mat-option value="TM" i18n='@@East Timor'>East Timor</mat-option>
                <mat-option value="EC" i18n='@@Ecuador'>Ecuador</mat-option>
                <mat-option value="EG" i18n='@@Egypt'>Egypt</mat-option>
                <mat-option value="SV" i18n='@@El Salvador'>El Salvador</mat-option>
                <mat-option value="GQ" i18n='@@Equatorial Guinea'>Equatorial Guinea</mat-option>
                <mat-option value="ER" i18n='@@Eritrea'>Eritrea</mat-option>
                <mat-option value="EE" i18n='@@Estonia'>Estonia</mat-option>
                <mat-option value="ET" i18n='@@Ethiopia'>Ethiopia</mat-option>
                <mat-option value="FA" i18n='@@Falkland Islands'>Falkland Islands</mat-option>
                <mat-option value="FO" i18n='@@Faroe Islands'>Faroe Islands</mat-option>
                <mat-option value="FJ" i18n='@@Fiji'>Fiji</mat-option>
                <mat-option value="FI" i18n='@@Finland'>Finland</mat-option>
                <mat-option value="FR" i18n='@@France'>France</mat-option>
                <mat-option value="GF" i18n='@@French Guiana'>French Guiana</mat-option>
                <mat-option value="PF" i18n='@@French Polynesia'>French Polynesia</mat-option>
                <mat-option value="FS" i18n='@@French Southern Ter'>French Southern Ter</mat-option>
                <mat-option value="GA" i18n='@@Gabon'>Gabon</mat-option>
                <mat-option value="GM" i18n='@@Gambia'>Gambia</mat-option>
                <mat-option value="GE" i18n='@@Georgia'>Georgia</mat-option>
                <mat-option value="DE" i18n='@@Germany'>Germany</mat-option>
                <mat-option value="GH" i18n='@@Ghana'>Ghana</mat-option>
                <mat-option value="GI" i18n='@@Gibraltar'>Gibraltar</mat-option>
                <mat-option value="GB" i18n='@@Great Britain'>Great Britain</mat-option>
                <mat-option value="GR" i18n='@@Greece'>Greece</mat-option>
                <mat-option value="GL" i18n='@@Greenland'>Greenland</mat-option>
                <mat-option value="GD" i18n='@@Grenada'>Grenada</mat-option>
                <mat-option value="GP" i18n='@@Guadeloupe'>Guadeloupe</mat-option>
                <mat-option value="GU" i18n='@@Guam'>Guam</mat-option>
                <mat-option value="GT" i18n='@@Guatemala'>Guatemala</mat-option>
                <mat-option value="GN" i18n='@@Guinea'>Guinea</mat-option>
                <mat-option value="GY" i18n='@@Guyana'>Guyana</mat-option>
                <mat-option value="HT" i18n='@@Haiti'>Haiti</mat-option>
                <mat-option value="HW" i18n='@@Hawaii'>Hawaii</mat-option>
                <mat-option value="HN" i18n='@@Honduras'>Honduras</mat-option>
                <mat-option value="HK" i18n='@@Hong Kong'>Hong Kong</mat-option>
                <mat-option value="HU" i18n='@@Hungary'>Hungary</mat-option>
                <mat-option value="IS" i18n='@@Iceland'>Iceland</mat-option>
                <mat-option value="IN" i18n='@@India'>India</mat-option>
                <mat-option value="ID" i18n='@@Indonesia'>Indonesia</mat-option>
                <mat-option value="IR" i18n='@@Iran'>Iran</mat-option>
                <mat-option value="IQ" i18n='@@Iraq'>Iraq</mat-option>
                <mat-option value="IE" i18n='@@Ireland'>Ireland</mat-option>
                <mat-option value="IM" i18n='@@Isle of Man'>Isle of Man</mat-option>
                <mat-option value="IL" i18n='@@Israel'>Israel</mat-option>
                <mat-option value="IT" i18n='@@Italy'>Italy</mat-option>
                <mat-option value="JM" i18n='@@Jamaica'>Jamaica</mat-option>
                <mat-option value="JP" i18n='@@Japan'>Japan</mat-option>
                <mat-option value="JO" i18n='@@Jordan'>Jordan</mat-option>
                <mat-option value="KZ" i18n='@@Kazakhstan'>Kazakhstan</mat-option>
                <mat-option value="KE" i18n='@@Kenya'>Kenya</mat-option>
                <mat-option value="KI" i18n='@@Kiribati'>Kiribati</mat-option>
                <mat-option value="NK" i18n='@@Korea North'>Korea North</mat-option>
                <mat-option value="KS" i18n='@@Korea South'>Korea South</mat-option>
                <mat-option value="KW" i18n='@@Kuwait'>Kuwait</mat-option>
                <mat-option value="KG" i18n='@@Kyrgyzstan'>Kyrgyzstan</mat-option>
                <mat-option value="LA" i18n='@@Laos'>Laos</mat-option>
                <mat-option value="LV" i18n='@@Latvia'>Latvia</mat-option>
                <mat-option value="LB" i18n='@@Lebanon'>Lebanon</mat-option>
                <mat-option value="LS" i18n='@@Lesotho'>Lesotho</mat-option>
                <mat-option value="LR" i18n='@@Liberia'>Liberia</mat-option>
                <mat-option value="LY" i18n='@@Libya'>Libya</mat-option>
                <mat-option value="LI" i18n='@@Liechtenstein'>Liechtenstein</mat-option>
                <mat-option value="LT" i18n='@@Lithuania'>Lithuania</mat-option>
                <mat-option value="LU" i18n='@@Luxembourg'>Luxembourg</mat-option>
                <mat-option value="MO" i18n='@@Macau'>Macau</mat-option>
                <mat-option value="MK" i18n='@@Macedonia'>Macedonia</mat-option>
                <mat-option value="MG" i18n='@@Madagascar'>Madagascar</mat-option>
                <mat-option value="MY" i18n='@@Malaysia'>Malaysia</mat-option>
                <mat-option value="MW" i18n='@@Malawi'>Malawi</mat-option>
                <mat-option value="MV" i18n='@@Maldives'>Maldives</mat-option>
                <mat-option value="ML" i18n='@@Mali'>Mali</mat-option>
                <mat-option value="MT" i18n='@@Malta'>Malta</mat-option>
                <mat-option value="MH" i18n='@@Marshall Islands'>Marshall Islands</mat-option>
                <mat-option value="MQ" i18n='@@Martinique'>Martinique</mat-option>
                <mat-option value="MR" i18n='@@Mauritania'>Mauritania</mat-option>
                <mat-option value="MU" i18n='@@Mauritius'>Mauritius</mat-option>
                <mat-option value="ME" i18n='@@Mayotte'>Mayotte</mat-option>
                <mat-option value="MX" i18n='@@Mexico'>Mexico</mat-option>
                <mat-option value="MI" i18n='@@Midway Islands'>Midway Islands</mat-option>
                <mat-option value="MD" i18n='@@Moldova'>Moldova</mat-option>
                <mat-option value="MC" i18n='@@Monaco'>Monaco</mat-option>
                <mat-option value="MN" i18n='@@Mongolia'>Mongolia</mat-option>
                <mat-option value="MS" i18n='@@Montserrat'>Montserrat</mat-option>
                <mat-option value="MA" i18n='@@Morocco'>Morocco</mat-option>
                <mat-option value="MZ" i18n='@@Mozambique'>Mozambique</mat-option>
                <mat-option value="MM" i18n='@@Myanmar'>Myanmar</mat-option>
                <mat-option value="NA" i18n='@@Nambia'>Nambia</mat-option>
                <mat-option value="NU" i18n='@@Nauru'>Nauru</mat-option>
                <mat-option value="NP" i18n='@@Nepal'>Nepal</mat-option>
                <mat-option value="AN" i18n='@@Netherland Antilles'>Netherland Antilles</mat-option>
                <mat-option value="NL" i18n='@@Netherlands (Holland, Europe)'>Netherlands (Holland, Europe)</mat-option>
                <mat-option value="NV" i18n='@@Nevis'>Nevis</mat-option>
                <mat-option value="NC" i18n='@@New Caledonia'>New Caledonia</mat-option>
                <mat-option value="NZ" i18n='@@New Zealand'>New Zealand</mat-option>
                <mat-option value="NI" i18n='@@Nicaragua'>Nicaragua</mat-option>
                <mat-option value="NE" i18n='@@Niger'>Niger</mat-option>
                <mat-option value="NG" i18n='@@Nigeria'>Nigeria</mat-option>
                <mat-option value="NW" i18n='@@Niue'>Niue</mat-option>
                <mat-option value="NF" i18n='@@Norfolk Island'>Norfolk Island</mat-option>
                <mat-option value="NO" i18n='@@Norway'>Norway</mat-option>
                <mat-option value="OM" i18n='@@Oman'>Oman</mat-option>
                <mat-option value="PK" i18n='@@Pakistan'>Pakistan</mat-option>
                <mat-option value="PW" i18n='@@Palau Island'>Palau Island</mat-option>
                <mat-option value="PS" i18n='@@Palestine'>Palestine</mat-option>
                <mat-option value="PA" i18n='@@Panama'>Panama</mat-option>
                <mat-option value="PG" i18n='@@Papua New Guinea'>Papua New Guinea</mat-option>
                <mat-option value="PY" i18n='@@Paraguay'>Paraguay</mat-option>
                <mat-option value="PE" i18n='@@Peru'>Peru</mat-option>
                <mat-option value="PH" i18n='@@Philippines'>Philippines</mat-option>
                <mat-option value="PO" i18n='@@Pitcairn Island'>Pitcairn Island</mat-option>
                <mat-option value="PL" i18n='@@Poland'>Poland</mat-option>
                <mat-option value="PT" i18n='@@Portugal'>Portugal</mat-option>
                <!--<mat-option value="PR" i18n='@@Puerto Rico'>Puerto Rico</mat-option> -->
                <mat-option value="QA" i18n='@@Qatar'>Qatar</mat-option>
                <mat-option value="ME" i18n='@@Republic of Montenegro'>Republic of Montenegro</mat-option>
                <mat-option value="RS" i18n='@@Republic of Serbia'>Republic of Serbia</mat-option>
                <mat-option value="RE" i18n='@@Reunion'>Reunion</mat-option>
                <mat-option value="RO" i18n='@@Romania'>Romania</mat-option>
                <mat-option value="RU" i18n='@@Russia'>Russia</mat-option>
                <mat-option value="RW" i18n='@@Rwanda'>Rwanda</mat-option>
                <mat-option value="NT" i18n='@@St Barthelemy'>St Barthelemy</mat-option>
                <mat-option value="EU" i18n='@@St Eustatius'>St Eustatius</mat-option>
                <mat-option value="HE" i18n='@@St Helena'>St Helena</mat-option>
                <mat-option value="KN" i18n='@@St Kitts-Nevis'>St Kitts-Nevis</mat-option>
                <mat-option value="LC" i18n='@@St Lucia'>St Lucia</mat-option>
                <mat-option value="MB" i18n='@@St Maarten'>St Maarten</mat-option>
                <mat-option value="PM" i18n='@@St Pierre &amp; Miquelon'>St Pierre &amp; Miquelon</mat-option>
                <mat-option value="VC" i18n='@@St Vincent &amp; Grenadines'>St Vincent &amp; Grenadines</mat-option>
                <mat-option value="SP" i18n='@@Saipan'>Saipan</mat-option>
                <mat-option value="SO" i18n='@@Samoa'>Samoa</mat-option>
                <mat-option value="AS" i18n='@@Samoa American'>Samoa American</mat-option>
                <mat-option value="SM" i18n='@@San Marino'>San Marino</mat-option>
                <mat-option value="ST" i18n='@@Sao Tome &amp; Principe'>Sao Tome &amp; Principe</mat-option>
                <mat-option value="SA" i18n='@@Saudi Arabia'>Saudi Arabia</mat-option>
                <mat-option value="SN" i18n='@@Senegal'>Senegal</mat-option>
                <mat-option value="SC" i18n='@@Seychelles'>Seychelles</mat-option>
                <mat-option value="SL" i18n='@@Sierra Leone'>Sierra Leone</mat-option>
                <mat-option value="SG" i18n='@@Singapore'>Singapore</mat-option>
                <mat-option value="SK" i18n='@@Slovakia'>Slovakia</mat-option>
                <mat-option value="SI" i18n='@@Slovenia'>Slovenia</mat-option>
                <mat-option value="SB" i18n='@@Solomon Islands'>Solomon Islands</mat-option>
                <mat-option value="OI" i18n='@@Somalia'>Somalia</mat-option>
                <mat-option value="ZA" i18n='@@South Africa'>South Africa</mat-option>
                <mat-option value="ES" i18n='@@Spain'>Spain</mat-option>
                <mat-option value="LK" i18n='@@Sri Lanka'>Sri Lanka</mat-option>
                <mat-option value="SD" i18n='@@Sudan'>Sudan</mat-option>
                <mat-option value="SR" i18n='@@Suriname'>Suriname</mat-option>
                <mat-option value="SZ" i18n='@@Swaziland'>Swaziland</mat-option>
                <mat-option value="SE" i18n='@@Sweden'>Sweden</mat-option>
                <mat-option value="CH" i18n='@@Switzerland'>Switzerland</mat-option>
                <mat-option value="SY" i18n='@@Syria'>Syria</mat-option>
                <mat-option value="TW" i18n='@@Taiwan'>Taiwan</mat-option>
                <mat-option value="TJ" i18n='@@Tajikistan'>Tajikistan</mat-option>
                <mat-option value="TZ" i18n='@@Tanzania'>Tanzania</mat-option>
                <mat-option value="TH" i18n='@@Thailand'>Thailand</mat-option>
                <mat-option value="TG" i18n='@@Togo'>Togo</mat-option>
                <mat-option value="TK" i18n='@@Tokelau'>Tokelau</mat-option>
                <mat-option value="TO" i18n='@@Tonga'>Tonga</mat-option>
                <mat-option value="TT" i18n='@@Trinidad &amp; Tobago'>Trinidad &amp; Tobago</mat-option>
                <mat-option value="TN" i18n='@@Tunisia'>Tunisia</mat-option>
                <mat-option value="TR" i18n='@@Turkey'>Turkey</mat-option>
                <mat-option value="TU" i18n='@@Turkmenistan'>Turkmenistan</mat-option>
                <mat-option value="TC" i18n='@@Turks &amp; Caicos Is'>Turks &amp; Caicos Is</mat-option>
                <mat-option value="TV" i18n='@@Tuvalu'>Tuvalu</mat-option>
                <mat-option value="UG" i18n='@@Uganda'>Uganda</mat-option>
                <mat-option value="UA" i18n='@@Ukraine'>Ukraine</mat-option>
                <mat-option value="AE" i18n='@@United Arab Emirates'>United Arab Emirates</mat-option>
                <mat-option value="GB" i18n='@@United Kingdom'>United Kingdom</mat-option>
                <mat-option value="US" selected="selected" i18n='@@United States'>United States</mat-option>
                <mat-option value="UY" i18n='@@Uruguay'>Uruguay</mat-option>
                <mat-option value="UZ" i18n='@@Uzbekistan'>Uzbekistan</mat-option>
                <mat-option value="VU" i18n='@@Vanuatu'>Vanuatu</mat-option>
                <mat-option value="VS" i18n='@@Vatican City State'>Vatican City State</mat-option>
                <mat-option value="VE" i18n='@@Venezuela'>Venezuela</mat-option>
                <mat-option value="VN" i18n='@@Vietnam'>Vietnam</mat-option>
                <mat-option value="VB" i18n='@@Virgin Islands (Brit)'>Virgin Islands (Brit)</mat-option>
                <mat-option value="VI" i18n='@@Virgin Islands (USA)'>Virgin Islands (USA)</mat-option>
                <mat-option value="WK" i18n='@@Wake Island'>Wake Island</mat-option>
                <mat-option value="WF" i18n='@@Wallis &amp; Futana Is'>Wallis &amp; Futana Is</mat-option>
                <mat-option value="YE" i18n='@@Yemen'>Yemen</mat-option>
                <mat-option value="ZR" i18n='@@Zaire'>Zaire</mat-option>
                <mat-option value="ZM" i18n='@@Zambia'>Zambia</mat-option>
                <mat-option value="ZW" i18n='@@Zimbabwe'>Zimbabwe</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <input name="postalcode" [(ngModel)]="postalcode" matInput  i18n-placeholder="@@Zip" placeholder="Zip" formControlName="postalcode">
            </mat-form-field>
            <br>
            <mat-form-field>
              <input name="fromDate" [(ngModel)]="minCreated" matInput  i18n-placeholder="@@Start Date" placeholder="Start Date" formControlName="fromDate" type="date">
            </mat-form-field>
            <mat-form-field>
              <input name="fromDate" [(ngModel)]="maxCreated" matInput  i18n-placeholder="@@End Date" placeholder="End Date" formControlName="toDate" type="date">
            </mat-form-field>
            <br>
<!--            <ng-container *ngIf="umbrellas && umbrellas.length > 0">-->
<!--              <mat-form-field>-->
<!--                <mat-select placeholder="Umbrella" i18n-placeholder="@@Umbrella" (selectionChange)="selectedUmbrella=$event.value" required  [ngModel]="selectedUmbrella"  formControlName="umbrella">-->
<!--                  <mat-option value="0">All Umbrellas</mat-option>-->
<!--                  <ng-container *ngFor="let umbrella of umbrellas">-->
<!--                    <mat-option [value]="umbrella.ID">{{umbrella.ID}} {{umbrella.name}}</mat-option>-->
<!--                  </ng-container>-->
<!--                </mat-select>-->
<!--              </mat-form-field>-->
<!--            </ng-container>-->
            <ng-container *ngIf="organizations && organizations.length > 1">
              <mat-form-field>
                <mat-select placeholder="Organization" i18n-placeholder="@@Organization" (selectionChange)="selectedOrganization=$event.value" required [ngModel]="selectedOrganization" formControlName="organization">
                  <mat-option value="0">All Organizations</mat-option>
                  <ng-container *ngFor="let organization of organizations">
                    <mat-option [value]="organization.ID">{{organization.ID}} {{organization.name}}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <br>


<!--            <mat-form-field>-->
<!--              <mat-label i18n="@@Enter Date Range">Enter Date Range</mat-label>-->
<!--              <mat-date-range-input [rangePicker]="picker">-->
<!--                <input matStartDate placeholder="From Date" required formControlName="fromDate">-->
<!--                <input matEndDate placeholder="To Date" required formControlName="toDate">-->
<!--              </mat-date-range-input>-->
<!--              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--              <mat-date-range-picker #picker></mat-date-range-picker>-->

<!--              &lt;!&ndash; Invalid Date Messages &ndash;&gt;-->
<!--              <mat-error *ngIf="formGroup.controls.fromDate.hasError('matStartDateInvalid')">Invalid start date</mat-error>-->
<!--              <mat-error *ngIf="formGroup.controls.toDate.hasError('matEndDateInvalid')">Invalid end date</mat-error>-->

<!--              &lt;!&ndash; Required Date Messages &ndash;&gt;-->
<!--              <mat-error *ngIf="formGroup.controls.fromDate.errors?.required">From Date Required</mat-error>-->
<!--              <mat-error *ngIf="formGroup.controls.toDate.errors?.required">To Date Required</mat-error>-->
<!--            </mat-form-field>-->

<!--            <p>Selected Date Range: {{formGroup.value | json}}</p>-->






<!--            <mat-form-field appearance="fill">-->
<!--              <mat-label>Start Date</mat-label>-->
<!--              &lt;!&ndash;        [min]="minDate" [max]="maxDate"&ndash;&gt;-->
<!--              <input matInput [matDatepicker]="picker"-->
<!--                     (dateInput)="dateChangeEvent($event)" (dateChange)="dateChangeEvent($event)">-->
<!--              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--              <mat-datepicker #picker [startAt]="datePickerStartDate"></mat-datepicker>-->
<!--            </mat-form-field>-->


<!--            <mat-form-field>-->
<!--              <input name="maxCreated" [(ngModel)]="minCreated" matInput  i18n-placeholder="@@Earliest Order Date" placeholder="Earliest Order Date">-->
<!--            </mat-form-field>-->
<!--            <mat-form-field>-->
<!--              <input name="maxCreated" [(ngModel)]="maxCreated" matInput  i18n-placeholder="@@Latest Order Date" placeholder="Latest Order Date">-->
<!--            </mat-form-field>-->
<!--            <br>-->
<!--            <mat-form-field>-->
<!--              <input matInput [matDatepicker]="picker" placeholder="Choose a date">-->
<!--              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--              <mat-datepicker #picker></mat-datepicker>-->
<!--            </mat-form-field>-->
          </form>
<!--          <mat-form-field appearance="fill">-->
<!--            <mat-label>Enter a date range</mat-label>-->
<!--            <mat-date-range-input [rangePicker]="picker">-->
<!--              <input matStartDate placeholder="Start date">-->
<!--              <input matEndDate placeholder="End date">-->
<!--            </mat-date-range-input>-->
<!--            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--            <mat-date-range-picker #picker></mat-date-range-picker>-->
<!--          </mat-form-field>-->
          <button color="primary" *ngIf="isReadyToSearch()" mat-button (click)="search()" title="Search" i18n-title="@@Search"><mat-icon>search</mat-icon><ng-container i18n="@@Search">Search</ng-container></button>

          <button color="primary" [disabled]="!printNodeOrders || (printNodeOrders && printNodeOrders.length === 0)" *ngIf="isReadyToSearch()" mat-button (click)="downloadCSV()" title="Download CSV" i18n-title="@@Download CSV"><mat-icon>download</mat-icon><ng-container i18n="@@Download CSV">Download CSV</ng-container></button>

          <button color="primary" mat-button (click)="clear()" title="Clear" i18n-title="@@Clear"><mat-icon>clear</mat-icon><ng-container i18n="@@Clear">Clear</ng-container></button>

          <h4 *ngIf="!isReadyToSearch()" i18n="@@Please Provide Search Criteria">Please Provide Search Criteria</h4>
          <h4 *ngIf="searchingMessage">{{searchingMessage}}</h4>
          <div *ngIf="!searchingMessage" class="printNodeOrderResults">
            <div class="example-container mat-elevation-z8">
              <mat-table #table [dataSource]="dataSource" matSort multiTemplateDataRows>

                <!-- Organization Name Column -->
                <ng-container matColumnDef="organizationName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Organization Name </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.organization.name}} </mat-cell>
                </ng-container>

                <!-- OrderId Column -->
                <ng-container matColumnDef="orderId">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> OrderId </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.order_ID}} </mat-cell>
                </ng-container>

                <!-- ShipmentId Column -->
                <ng-container matColumnDef="shipmentId">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Shipment Id </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.ID}} </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.firstname}}  {{element.lastname}} </mat-cell>
                </ng-container>

                <!-- Address1 Column -->
                <ng-container matColumnDef="address">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Address </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.address1}} </mat-cell>
                </ng-container>

                <!-- City Column -->
                <ng-container matColumnDef="city">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.city}} </mat-cell>
                </ng-container>

                <!-- State Column -->
                <ng-container matColumnDef="state">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> State </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.state}} </mat-cell>
                </ng-container>

                <!-- Postalcode Column -->
                <ng-container matColumnDef="postalcode">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Zip </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.postalcode}} </mat-cell>
                </ng-container>

                <!-- Quantity Column -->
                <ng-container matColumnDef="quantity">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.quantity}} </mat-cell>
                </ng-container>

                <!-- Carrier Column -->
                <ng-container matColumnDef="carrier">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Carrier </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.carrier}} </mat-cell>
                </ng-container>

                <!-- Created Column -->
                <ng-container matColumnDef="created">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Order Date </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.created | date:'shortDate' }} </mat-cell>
                </ng-container>

                <!-- ShippedTimestamp Column -->
                <ng-container matColumnDef="shippedTimestamp">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Ship Date </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.shippingStatus === 'NONE' ? '' : element.shippedTimestamp | date:'shortDate' }} </mat-cell>
                </ng-container>

                <!-- Tracking Numbers Column -->
                <ng-container matColumnDef="trackingNumbers">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Tracking Numbers </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.shippingStatus === 'NONE' ? '' : element.trackingNumbers}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                  <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="elementDetail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                      <app-print-node-order [printNodeOrder]="element"></app-print-node-order>
                    </div>
                  </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
<!--                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>-->
                <mat-row *matRowDef="let element; columns: displayedColumns;" class="elementRow" [class.expandedRow]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element"></mat-row>
                <mat-row *matRowDef="let element; columns: ['expandedDetail']" class="detailRow" [class]="expandedElement === element ? 'displayDetailRow' : 'hideDetailRow' ">
                </mat-row>
              </mat-table>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






