export class DraftCover {
  ID: number;
  resource_ID: number;
  documentScale: number = 1;
  verticalShift: number = 0;
  horizontalShift: number = 0;
  paperType_ID: number = 3;
  barcodeSide: string = 'NONE';
  barcodeOrientationHorz: string = 'LEFT';
  barcodeOrientationVert: string = 'BOTTOM';
  barcodePaddingHorz: number = 0;
  barcodePaddingVert: number = 0;
  barcodePositionHorz: number = 36;
  barcodePositionVert: number = 36;
  isColorBoosted: string = 'FALSE';
  laminateType: string = 'GLOSS';
  lastUpdate: Date = new Date();

  constructor( obj ) {
    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        if ( obj[prop]) {
          this[prop] = obj[prop];
        }
      }
    }
  }

}
