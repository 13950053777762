import {Component, Input, OnInit} from '@angular/core';
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PrintNodeOrderItem} from "../../../../model/PrintNodeOrderItem";

@Component({
  selector: 'app-fulfilment-order-item',
  templateUrl: './fulfillment-order-item.component.html',
  styleUrls: ['./fulfillment-order-item.component.css']
})
export class FulfillmentOrderItemComponent implements OnInit {
  @Input() printNodeOrderItem: PrintNodeOrderItem;

  constructor() { }

  ngOnInit() {
  }

}
