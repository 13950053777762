import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { Organization } from '../../model/Organization';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {User} from "../../model/User";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {NotificationsService} from "./notifications.service";

@Injectable()
export class OrganizationService extends AbstractLiveDataService<Organization> {
  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.startupService.setOrganizationService(this);
    this.notificationsService.setOrganizationService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): Organization {
    const object = new Organization(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "Organization";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "organization";
  }

  getLinkData(): any[] {
    return [];
  }

  getOrganization(id: string): Observable<Organization> {
    const url = `${environment.apiBaseURL}organization/${id}`;
    this.messageService.add(`OrganizationService: fetched organization id=${id}`);
    return this.http.get<Organization>(url, this.startupService.getHttpOptions())
      .pipe(
        map(organization => {
          const p = new Organization(organization);
          return p;
        }),
        catchError(this.handleErrorX<Organization>(`getOrganization id=${id}`))
      );
  }

  getServiceProviderOrganization (serviceProviderId: string): Observable<Organization> {
    const url = `${environment.apiBaseURL}organization/serviceProviderId/${serviceProviderId}`;
    this.messageService.add(`OrganizationService: fetched organization id=${serviceProviderId}`);
    return this.http.get<Organization>(url, this.startupService.getHttpOptions())
      .pipe(
        map(organization => {
          const p = new Organization(organization);
          return p;
        }),
        catchError(this.handleErrorX<Organization>(`getOrganization id=${serviceProviderId}`))
      );
  }

  getUmbrellaOrganization (umbrellaId: string): Observable<Organization> {
    const url = `${environment.apiBaseURL}organization/umbrellaId/${umbrellaId}`;
    this.messageService.add(`OrganizationService: fetched organization id=${umbrellaId}`);
    return this.http.get<Organization>(url, this.startupService.getHttpOptions())
      .pipe(
        map(organization => {
          const p = new Organization(organization);
          return p;
        }),
        catchError(this.handleErrorX<Organization>(`getOrganization id=${umbrellaId}`))
      );
  }

  /** PUT: update the organization on the server */
  updateOrganization (organization: Organization): Observable<Organization> {
    const url = `${environment.apiBaseURL}organization/${organization.ID}`;
    this.messageService.add(`OrganizationService: updated organization id=${organization.ID}`);
    return this.http.put(url, organization, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated organization id=${organization.ID}`)),
      catchError(this.handleErrorX<any>('updateOrganization'))
    );
  }

  /** PUT: set a user to be an admin of an organizatoin */
  grantOrganizationAdmin (user: User): Observable<Organization> {
    const url = `${environment.apiBaseURL}organization/grantOrganizationAdmin/${user.focusOrg_ID}/${user.ID}`;
    this.messageService.add(`OrganizationService: grantOrganizationAdmin organization id=${user.focusOrg_ID}`);
    return this.http.put(url, user, this.startupService.getHttpOptions()).pipe(
      tap(x => {
        user.isAdmin = 'TRUE';
        this.log(`grantOrganizationAdmin organization id=${user.focusOrg_ID}`);
      }),
      catchError(this.handleErrorX<any>('grantOrganizationAdmin'))
    );
  }
  /** PUT: set a user to be an admin of an organizatoin */
  revokeOrganizationAdmin (user: User): Observable<Organization> {
    const url = `${environment.apiBaseURL}organization/revokeOrganizationAdmin/${user.focusOrg_ID}/${user.ID}`;
    this.messageService.add(`OrganizationService: makeOrganizationAdmin organization id=${user.focusOrg_ID}`);
    return this.http.put<Organization>(url, user, this.startupService.getHttpOptions()).pipe(
      tap(x => {
        user.isAdmin = 'FALSE';
        this.log(`revokeOrganizationAdmin organization id=${user.focusOrg_ID}`);
      }),
      catchError(this.handleErrorX<Organization>(`revokeOrganizationAdmin userEmail${user.email}`))
    );
  }

  /** PUT: set a user to be an admin of an organizatoin */
  resendEmailInvite (user: User): Observable<Organization> {
    const url = `${environment.apiBaseURL}organization/resendEmailInvite/${user.ID}`;
    this.messageService.add(`OrganizationService: updated organization id=${user.ID}`);
    return this.http.put(url, user, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated organization id=${user.ID}`)),
      catchError(this.handleErrorX<any>('resendEmailInvite'))
    );
  }
  addOrganization (organization: Organization, primaryUsersFullName: string, primaryUsersEmail: string, primaryUsersPhone: string): Observable<Organization> {
    const url = `${environment.apiBaseURL}organization?primaryUsersFullName=${btoa(primaryUsersFullName)}&primaryUsersEmail=${btoa(primaryUsersEmail)}&primaryUsersPhone=${btoa(primaryUsersPhone)}`;
    return this.http.post<Organization>(url, organization, this.startupService.getHttpOptions()).pipe(
      tap(o => {
        this.log(`added organization w/ id=${organization.ID}`);
        const org = new Organization(o);
        this.objects.push(org);
        // if ( org.users && org.users.length > 0) {
        //   // TODO: Add the new User to the UserService cache
        // }
      }),
      catchError(this.handleErrorX<Organization>('addOrganization'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleErrorX<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for organization consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
