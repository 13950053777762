import {Item} from "./Item";
import {TranscriptionItem} from "./TranscriptionItem";
import {LanguagePipe} from "../../app/pipe/language.pipe";
import {ProductItem} from "./ProductItem";
import {Product} from "../Product";
import {Draft} from "../Draft";
import {DraftItem} from "./DraftItem";
import {OrderOption} from "./OrderOption";
import {PackageAndShippingComputationService} from "../../app/service-data/package-and-shipping-computation.service";
import {Address} from "../Address";
import {Organization} from "../Organization";
import {ChargeProfile} from "../ChargeProfile";

export class Cart {
  userId: string;
  key: string;
  expeditedManufacturing: string;
  expeditedManufacturingCost: number;
  shippingMethod: string;
  shippingCost: number;
  transcriptionCost: number;
  itemsCost: number = 0;
  packingAndPackagingCost: number = 0;
  taxCost: number = 0;
  accountId: number;
  verificationKey: string;
  address: Address = new Address(undefined);
  packItemsInSeparateBoxes: boolean;
  useCaseQuantities: boolean;
  chargeProfile: ChargeProfile;
  invoiceable: boolean = false;
  organization: Organization;

  purchaseOrder: string;
  purchaseOrderPrivate: string;
  phone: string;
  phoneCountry: string;
  cardNumber: string;
  cardNumberWithSpaces: string;
  csc: string;
  expMonth: number;
  expYear: number;
  expMonthYear: string;
  firstNamePayment: string;
  lastNamePayment: string;
  address1Payment: string;
  address2Payment: string;
  cityPayment: string;
  statePayment: string;
  postalCodePayment: string;
  countryPayment: string;
  language: string;
  paymentErrorMessage: string = "";
  costsAndShippingErrorMessage: string = "";
  deliveryAddressErrorMessage: string = "";
  shippingErrorMessage: string = "";
  weight: number = 0;
  totalQuantity: number;
  totalWeight: number;
  totalCost: number;

  itemsInCart: number = 0;
  inventoryType = 'PRINT_TO_ORDER'; // 'PRINT_TO_ORDER';
  payer = 'ORGANIZATION';

  orderOptions: OrderOption[] = [];
  selectedOrderOption: OrderOption;
  shippingStatus = 'INITIAL';
  currentPackageCollectionNumber = 1;
  computationStatus: string = 'INITIAL';
  packageCollections: any = {};

  public items: Item[];
  public nonZeroItems: Item[];
//  public transcriptionItems: TranscriptionItem[];
  addressValidationStatus: string = 'INITIAL';
  packageAndShippingState: string = 'INITIAL';
  private packageAndShippingComputationService: PackageAndShippingComputationService;

  addressReady: boolean = false;
  shippingReady: boolean = false;
  paymentInfoReady: boolean = false;
  readyForInvoiceCheckout: boolean = false;
  readyForCreditCardCheckout: boolean = false;

  constructor (obj, packageAndShippingComputationService: PackageAndShippingComputationService, organization: Organization) {
    this.userId = '';
    this.key = '';
    this.expeditedManufacturing = '';
    this.expeditedManufacturingCost = 0;
    this.shippingMethod = '';
    this.shippingCost = 0;
    this.transcriptionCost = 0;
    this.itemsCost = 0;
    this.taxCost = 0;
    this.accountId = 0;
    this.verificationKey = '';
    this.phone = '';
    this.phoneCountry = '';
    this.cardNumber = '';
    this.csc = '';
    this.expMonth = 12;
    this.expYear = 2020;
    this.firstNamePayment = '';
    this.lastNamePayment = '';
    this.address1Payment = '';
    this.address2Payment = '';
    this.cityPayment = '';
    this.statePayment = '';
    this.postalCodePayment = '';
    this.countryPayment = 'US';
    this.language = 'en';
    this.paymentErrorMessage = "";
    this.costsAndShippingErrorMessage = "";
    this.deliveryAddressErrorMessage = "";
    this.shippingErrorMessage = "";
    this.packageAndShippingState = 'INITIAL';
    this.packItemsInSeparateBoxes = false;
    this.useCaseQuantities = false;

    this.organization = organization;
    if ( this.organization ) {
      if ( this.organization.canCheckOutWithInvoicePayment === 'TRUE' && this.organization.hasActiveTab === 'TRUE') {
        this.invoiceable = true;
      } else {
        this.invoiceable = false;
      }
    }

    if (obj) {
      for (const prop of Object.keys(obj)) {
        this[prop] = obj[prop];
      }
    }

    this.items = [];
    // if (obj) {
    //   if (obj.items) {
    //     for (const o of obj.items) {
    //       const item = new ProjectItem(o);
    //       this.items.push(item);
    //     }
    //   }
    // } else {
    // }
    this.packageAndShippingComputationService = packageAndShippingComputationService;
  }

  setNonZeroItems() {
    this.nonZeroItems = [];
    for ( const item of this.items ) {
      if ( item.quantity > 0 ) {
        this.nonZeroItems.push(item);
      }
    }
  }
  // setOrganization(organziation: Organization) {
  //   this.organization = organziation;
  //   if ( this.organization ) {
  //     if ( this.organization.) {
  //       this.invoicable = true;
  //     } else {
  //       this.invoicable = true;
  //     }
  //   }
  // }

  setChargeProfile(chargeProfile: ChargeProfile) {
    this.chargeProfile = chargeProfile;
  }

  setAddress(address: Address) {
    if ( !address ) {
      address = new Address(undefined);
    }
    this.address = address;
    this.compute();
  }

  // computeCosts(orderOption: OrderOption) {
  //   for ( const printNodeOrderOption of orderOption.printNodeOrderOptions ) {
  //     printNodeOrderOption.getShippingItems();
  //     this.packageAndShippingComputationService.getPackages("ANY", "ANY", printNodeOrderOption.facility, printNodeOrderOption.getShippingItems(), 1).subscribe( packages => {
  //       orderOption.state = 'READY';
  //
  //     });
  //   }
  // }

  setOrderOption(orderOption: OrderOption) {
    this.selectedOrderOption = orderOption;
  }

  addProductItem(product: Product, additionalQuantity: number): Item {
    const item: Item = new ProductItem( product );
    for ( const i of this.items ) {
      if ( i.getId() === product.getId() ) {
        const beforeQuantity = i.quantity;
        i.quantity += additionalQuantity;
        if ( beforeQuantity !== i.quantity && (beforeQuantity === 0 || i.quantity === 0 )) {
          this.computeItemsInCart();
        }
        return i;
      }
    }
    item.quantity += additionalQuantity;
    this.items.push(item);
    return item;
  }
    addDraftItem(draft: Draft, additionalQuantity: number): Item {
      const item: Item = new DraftItem( draft );
      for ( const i of this.items ) {
        if ( i.getId() === draft.getId() ) {
          const beforeQuantity = i.quantity;
          i.quantity += additionalQuantity;
          if ( beforeQuantity !== i.quantity && (beforeQuantity === 0 || i.quantity === 0 )) {
            this.computeItemsInCart();
          }
          return i;
        }
      }
      item.quantity += additionalQuantity;
      this.items.push(item);
      this.computeItemsInCart();
      return item;
  }

  remove(item: Item) {
    const index = this.items.indexOf(item);
    if ( index !== -1 ) {
      this.items.splice(index, 1);
    }
  }

  setBillingAddressTheSameAsShipping() {
    this.firstNamePayment = this.address.firstname;
    this.lastNamePayment = this.address.lastname;
    this.address1Payment = this.address.address1;
    this.address2Payment = this.address.address2;
    this.cityPayment = this.address.city;
    this.statePayment = this.address.state;
    this.postalCodePayment = this.address.postalcode;
    this.countryPayment = this.address.country;
  }

  setShippingAddressTheSameAsBilling() {
    this.address.firstname = this.firstNamePayment;
    this.address.lastname = this.lastNamePayment;
    this.address.address1 = this.address1Payment;
    this.address.address2 = this.address2Payment;
    this.address.city = this.cityPayment;
    this.address.state = this.statePayment;
    this.address.postalcode = this.postalCodePayment;
    this.address.country = this.countryPayment;
  }

  isPaymentInfoReady(): boolean {
    if ( this.chargeProfile ) {
      return true;
    }
    if ( !this.expMonthYear ) {
      this.paymentErrorMessage = LanguagePipe.get('NeedExpirationMonthAndYear', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }

    if ( !this.cardNumberWithSpaces ) {
      this.paymentErrorMessage = LanguagePipe.get('NeedCardNumber', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    this.cardNumber = this.cardNumberWithSpaces.split(' ').join('');

    this.expMonthYear.split(' ').join('');

    this.expMonth = Number(this.expMonthYear.substring(0, 2));
    this.expYear = Number(this.expMonthYear.substring(5));
    if ( this.expYear < 100 ) {
      this.expYear += 2000;
    }

    if (!this.isNotUndefinedAndMinimumLength(this.firstNamePayment, 1, 32)) {
      this.paymentErrorMessage = LanguagePipe.get('MustHaveFirstName', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.lastNamePayment, 2, 32)) {
      this.paymentErrorMessage = LanguagePipe.get('MustHaveLastName', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.address1Payment, 1, 32)) {
      this.paymentErrorMessage = LanguagePipe.get('MustHaveStreetAddress', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    // if (!this.isNotUndefinedAndMinimumLength(this.address2Payment, 0, 32)) {
    //   this.paymentErrorMessage = LanguagePipe.get('MustHaveStreetAddress', 'compressSpaces');
    //   this.paymentInfoReady = false;
    //   return false;
    // }
    if (!this.isNotUndefinedAndMinimumLength(this.cityPayment, 2, 32)) {
      this.paymentErrorMessage = LanguagePipe.get('MustHaveCity', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.statePayment, 2, 2)) {
      this.paymentErrorMessage = LanguagePipe.get('MustHaveState', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.postalCodePayment, 5, 16)) {
      this.paymentErrorMessage = LanguagePipe.get('MustHavePostalCode', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.countryPayment, 2, 2)) {
      this.paymentErrorMessage = LanguagePipe.get('MustHaveCountry', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.csc, 3, 4)) {
      this.paymentErrorMessage = LanguagePipe.get('CSCCodeMustBeThreeOrFourCharacters', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndValueRange(this.expMonth, 1, 12)) {
      this.paymentErrorMessage = LanguagePipe.get('MonthMustBeBetween1And12', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndValueRange(this.expYear, 2024, 2038)) {
      this.paymentErrorMessage = LanguagePipe.get('YearMustBeBetween2024And2038', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.cardNumber, 16, 19)) {
      this.paymentErrorMessage = LanguagePipe.get('CardNumberMustBeBetween16And19Numbers', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    if (!this.validCardNumberWithCardNumber(this.cardNumber)) {
      this.paymentErrorMessage = LanguagePipe.get('CardNumberMustHaveAValildCheckSum', 'compressSpaces');
      this.paymentInfoReady = false;
      return false;
    }
    this.paymentErrorMessage = "";
    this.paymentInfoReady = true;
    return true;
  }

  validCardNumber(): boolean {
    this.cardNumber = this.cardNumberWithSpaces.split(' ').join('');
    if ( this.validCardNumberWithCardNumber( this.cardNumber ) ) {
      return true;
    }
    return false;
  }

  validCardNumberWithCardNumber(number: string) {
    const regex = new RegExp("^[0-9]{16,19}$");
    if (!regex.test(number)) {
      return false;
    }
    return this.luhnCheck(number);
  }

  luhnCheck(val: string) {
    let sum = 0;
    for (let i = 0; i < val.length; i++) {
      let intVal = Number((val.substr(i, 1)));
      if (i % 2 === 0) {
        intVal *= 2;
        if (intVal > 9) {
          intVal = 1 + (intVal % 10);
        }
      }
      sum += intVal;
    }
    return (sum % 10) === 0;
  }
  isShippingReady(): boolean {
    this.shippingReady = false;
    return false;
  }

  isAddressReady(): boolean {
    if (!this.isNotUndefinedAndMinimumLength(this.address.firstname, 1, 32)) {
      this.addressReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.address.lastname, 2, 32)) {
        this.addressReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.address.address1, 1, 32)) {
        this.addressReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.address.address2, 0, 32)) {
        this.addressReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.address.city, 2, 32)) {
        this.addressReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.address.state, 2, 2)) {
        this.addressReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.address.postalcode, 5, 16)) {
        this.addressReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.address.country, 2, 2)) {
        this.addressReady = false;
      return false;
    }
    if (!this.isNotUndefinedAndMinimumLength(this.address.email, 7, 64)) {
        this.addressReady = false;
      return false;
    }
    // if (!this.isNotUndefinedAndMinimumLength(this.emailConfirmation, 7, 64)) {
    //   this.addressReady = false;
    //   return false;
    // }
    // if ( this.email !== this.emailConfirmation ) {
    //  this.addressReady = false;
    //   return false;
    // }
    if ( this.addressValidationStatus !== 'VALIDATED' && this.addressValidationStatus !== 'MANUAL_OVERRIDE' ) {
        this.addressReady = false;
      return false;
    }
    this.addressReady = true;
    return true;
  }

  isNotUndefinedAndValueRange(value: number, minValue: number, maxValue: number): boolean {
    if (value === undefined || value === null) {
      return false;
    }
    if ( value < minValue ) {
      return false;
    }
    if ( value > maxValue ) {
      return false;
    }
    return true;
  }
  isNotUndefinedAndMinimumLength(value: string, minLength: number, maxLength: number): boolean {
    if (value === undefined || value === null) {
      return false;
    }
    if ( value.length >= minLength ) {
      return true;
    }
    return false;
  }

  compute() {
    this.orderOptions = [];
    this.shippingStatus = 'COMPUTING';
    while ( this.orderOptions.length > 0 ) {
      this.orderOptions.pop();
    }

    // Overall weight
    let totalWeight: number = 0;

    // All quantities 20 and over or all under 20
    const over20 = [];
    const under20 = [];

    // All Inventory items or all not inventory items or a mixture
    const inventoryItemFacilities: any = {};
    const hasInventoryItems = [];
    const noInventoryItems = [];

    // All the same binding type or a mixture
    const bindingItems: any = {};
    const paperTypeItems: any = {};

    const productionFacilities: any = {};

    for ( const item of this.nonZeroItems) {
      totalWeight += item.getWeight() * item.getQuantity();

      // Determine items with with over and under 20 quantity
      if ( item.getQuantity() >= 20 ) {
        over20.push(item);
      } else {
        under20.push(item);
      }

      // Determine facilities that can fulfill the whole order
      for ( const facilityId of ['1', '7']) {
        if ( this.canFacilityProduce(Number(facilityId), item.getBindingType(), item.quantity, item.getInteriorType(), item.getLaminateType(), item.getPages(), item.getPaperType()) ) {
          if ( !productionFacilities[facilityId] ) {
            productionFacilities[facilityId] = [];
          }
          productionFacilities[facilityId].push(item);
        }
      }

      // Determine items with inventory and have enough to fulfill
      if ( item instanceof ProductItem ) {
        const base = item.getBase() as Product;
        if ( base.facilityInventoryProducts && base.facilityInventoryProducts.length > 0 ) {
          let notFound = true;
          for ( const facilityInventoryProduct of base.facilityInventoryProducts ) {
            if ( facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited >= item.getQuantity() ) {
              // Enough to fulfill this item
              if ( !inventoryItemFacilities['' + facilityInventoryProduct.printnode_ID] ) {
                inventoryItemFacilities['' + facilityInventoryProduct.printnode_ID] = [];
              }
              inventoryItemFacilities['' + facilityInventoryProduct.printnode_ID].push(item);
              notFound = false;
            }
          }
          if ( notFound ) {
            noInventoryItems.push(item);
          } else {
            hasInventoryItems.push(item);
          }

        }
      } else {
        if ( item instanceof DraftItem ) {
          noInventoryItems.push(item);
          const base = item.getBase() as Draft;
        }
      }

      // Determine items with different binding
      const binding = item.getBindingType();
      if ( !bindingItems[binding] ) {
        bindingItems[binding] = [];
      }
      bindingItems[binding].push(item);

      // Determine items with different binding
      const paperType = '' + item.getPaperType();
      if ( !paperTypeItems[paperType] ) {
        paperTypeItems[paperType] = [];
      }
      paperTypeItems[paperType].push(item);
    }

    // For the pure options
    // All over 20 that can all be made at the most geographically advantageous facility
    const allOver20 = under20.length === 0 ? false : true;

    // Only one title
    const onlyOneTitle = this.nonZeroItems.length === 1 ? true : false;

    // All in stock inventory at one facility
    let allInStock = hasInventoryItems.length > 0 ? false : true;
    const facilitiesWithInventoryToSatisfyOrder = [];
    if ( allInStock ) {
      const facilitiesForInventory = Object.keys(inventoryItemFacilities);
      for ( const facilityForInventory in facilitiesForInventory ) {
        if ( this.nonZeroItems.length === inventoryItemFacilities[facilityForInventory].length ) {
          facilitiesWithInventoryToSatisfyOrder.push(Number(facilityForInventory));
        }
      }
      allInStock = facilitiesWithInventoryToSatisfyOrder.length > 0 ? true : false;
    }

    let geographicallyAdvantageousFacilities = [7, 1];
    if ( this.address.country === 'US' || this.address.country === 'USA') {
      const firstDigitOfPostalCode = this.address.postalcode.length > 0 ? this.address.postalcode.substring(1, 1) : '7';
      switch ( firstDigitOfPostalCode ) {
        case '0': {
          geographicallyAdvantageousFacilities = [7, 1];
          break;
        }
        case '1': {
          geographicallyAdvantageousFacilities = [7, 1];
          break;
        }
        case '2': {
          geographicallyAdvantageousFacilities = [7, 1];
          break;
        }
        case '3': {
          geographicallyAdvantageousFacilities = [7, 1];
          break;
        }
        case '4': {
          geographicallyAdvantageousFacilities = [7, 1];
          break;
        }
        case '5': {
          geographicallyAdvantageousFacilities = [7, 1];
          break;
        }
        case '6': {
          geographicallyAdvantageousFacilities = [7, 1];
          break;
        }
        case '7': {
          geographicallyAdvantageousFacilities = [7, 1];
          break;
        }
        case '8': {
          geographicallyAdvantageousFacilities = [1, 7];
          break;
        }
        case '9': {
          geographicallyAdvantageousFacilities = [1, 7];
          break;
        }
      }
    }

    // Start looking for the right facility or combination of facilities
    // If the geographically advantageous facility has all the items in inventory then ship from there
    let oneDominantFacility = 0;
    if ( allInStock ) {
      for ( const facilityWithInventoryToSatisfyOrder in facilitiesWithInventoryToSatisfyOrder ) {
        if ( Number(facilityWithInventoryToSatisfyOrder) === geographicallyAdvantageousFacilities[0] ) {
          oneDominantFacility = Number(facilityWithInventoryToSatisfyOrder);
        }
      }
    }

    // If there are no inventory items and the geographically advantageous facility can produce the whole order then ship from there
    if ( hasInventoryItems.length === 0 && productionFacilities['' + geographicallyAdvantageousFacilities[0] ] && this.nonZeroItems.length === productionFacilities['' + geographicallyAdvantageousFacilities[0] ].length ) {
      oneDominantFacility = geographicallyAdvantageousFacilities[0];
    }

    // - ship all from one facility AND is the geographically advantageous facility
    // - ship all from inventory AND is the geographically advantageous facility
    // - ship all print to order AND is the geographically advantageous facility
    if ( oneDominantFacility ) {
      const orderOption = new OrderOption(this.address, 'ANY', 'ANY', this.packItemsInSeparateBoxes, this.useCaseQuantities, 'SINGLEFACILITY', this.packageAndShippingComputationService);
      const printNodeOrderOption = orderOption.addPrintNodeOrderOption(geographicallyAdvantageousFacilities[0]);
      for ( const item of this.nonZeroItems ) {
        printNodeOrderOption.addItem(item);
      }
      orderOption.compute(this);
      this.orderOptions.push(orderOption);
      const clonedOrderOption = orderOption.cloneWIthDifferentCarrierAndServiceLevel('USPS', 'PRIORITY_FLAT');
      clonedOrderOption.compute(this);
      this.orderOptions.push(clonedOrderOption);
    } else {
        // The geographically advantageous facility can do none of the order -- then the geographically advantageous facility becomes the next closest one
        // Ignore the possibility that there could be a split inventory and print to order option and go with all print to order
      let secondBestFacility = 0;
      if ( geographicallyAdvantageousFacilities.length > 1 ) {
        if ( allInStock ) {
          for ( const facilityWithInventoryToSatisfyOrder in facilitiesWithInventoryToSatisfyOrder ) {
            if ( Number(facilityWithInventoryToSatisfyOrder) === geographicallyAdvantageousFacilities[1] ) {
              secondBestFacility = Number(facilityWithInventoryToSatisfyOrder);
            }
          }
        }
        if ( !secondBestFacility && geographicallyAdvantageousFacilities.length > 1 && productionFacilities['' + geographicallyAdvantageousFacilities[1] ] && this.items.length === productionFacilities['' + geographicallyAdvantageousFacilities[1] ].lenth ) {
          secondBestFacility = geographicallyAdvantageousFacilities[1];
        }
      }
      if ( secondBestFacility ) {
        const orderOption = new OrderOption(this.address, 'ANY', 'ANY', this.packItemsInSeparateBoxes, this.useCaseQuantities, 'SINGLEFACILITY', this.packageAndShippingComputationService);
        const printNodeOrderOption = orderOption.addPrintNodeOrderOption(geographicallyAdvantageousFacilities[0]);
        for ( const item of this.nonZeroItems ) {
          printNodeOrderOption.addItem(item);
        }
        orderOption.compute(this);
        this.orderOptions.push(orderOption);
        const clonedOrderOption = orderOption.cloneWIthDifferentCarrierAndServiceLevel('USPS', 'PRIORITY_FLAT');
        clonedOrderOption.compute(this);
        this.orderOptions.push(clonedOrderOption);
      } else {
        // Must be split
        // Split and all from the non-geographically advantageous facility

        // No shipping disadvantage for splitting shipment
        // Determine the weight split between the using two facilities

        // Options
        // - ship all from one facility (not always feasible or lowest cost shipping)
        // - ship all from inventory
        // - ship all print to order
        // - ship from multiple facilities because of inventory and/or manufacturing constraints combined with lowest shipping cost
        // Rank geographically advantages facilities that are feasible
        // For each item identify the facilities that can produce an item
        // For each item identify the facilities that have the ability fulfill from inventory

        // Options selected by the customer
        // - each title in its own box
        // - case quantity for individual items
        // - IF any are inventory -- ship all from inventory / print all / inventory when possible but possbily mix / print when possibl but possibily mix
        // - ship all from one facility / ship from multiple facilities
      }
    }
  }

  checkComputationStatus(): string {
    if ( this.computationStatus === 'INITIAL') {
      return 'INITIAL';
    }
    if ( this.computationStatus === 'ERROR') {
      return 'ERROR';
    }
    let cs = '';
    this.orderOptions.forEach( orderOptions => {
      if ( orderOptions.computationStatus === 'ERROR') {
        this.computationStatus = 'ERROR';
        return this.computationStatus;
      }
      if ( orderOptions.computationStatus === 'COMPUTING' ) {
        cs = 'COMPUTING';
      }
    });
    if ( cs.length === 0) {
      this.computationStatus = 'COMPLETE';
    } else {
      this.computationStatus = 'COMPUTING';
    }
    return this.computationStatus;
  }

  canFacilityProduce(facilityId: number, bindingType: string, quantity: number, interiorType: string, laminationType: string, pages: number, paperType: number) {
    switch ( facilityId ) {
      case 1: {
        // if ( pages < 24 ) {
        //   return false;
        // }
        if ( pages > 1200 ) {
          return false;
        }
        if ( bindingType === 'PERFECT' && quantity < 20 && interiorType === 'GREY' ) {
          return false;
        }
        if ( bindingType === 'SADDLE_STITCH') {
          return false;
        }
        break;
      }
      case 7: {
        // if ( pages < 38 ) {
        //   return false;
        // }
        if ( pages > 1200 ) {
          return false;
        }
        if ( bindingType === 'COIL') {
          return false;
        }
        if ( bindingType === 'SADDLE_STITCH') {
          return false;
        }
        break;
      }
      default: {
        break;
      }
    }
    return true;
  }

  // getFacilities(): number[] {
  //   const facilities: number[] = [];
  //   for ( const orderOption of this.orderOptions) {
  //     for ( const printNodeOrderOption of orderOption.printNodeOrderOptions) {
  //       if ( facilities.indexOf(printNodeOrderOption.facilityId ) === -1 ) {
  //         facilities.push(printNodeOrderOption.facilityId);
  //       }
  //     }
  //   }
  //   return facilities;
  // }
  // getPackages(carrier: string, serviceLevel: string) {
  //
  // }
  //
  // getShippingOptions() {
  //
  // }
  verifyCosts(): boolean {
    return true;
  }

  verifyCostsAndShipping(): boolean {
    this.totalQuantity = 0;
    this.totalWeight = 0;
    this.totalCost = 0;

    for ( const item of this.items ) {
      this.totalCost += item.cost;
      this.totalWeight += ( item.getWeight() * item.quantity );
      this.totalQuantity += item.quantity;
    }
    if ( this.verifyShipping() && this.verifyCosts() ) {
      return true;
    }
    return false;
  }

  verifyShipping(): boolean {
    return true;
  }

  clear() {
    this.userId = '';
    this.key = '';
    this.expeditedManufacturing = '';
    this.expeditedManufacturingCost = 0;
    this.shippingMethod = 'USPS_PRIORITY';
    this.shippingCost = 0;
    this.transcriptionCost = 0;
    this.itemsCost = 0;
    this.packingAndPackagingCost = 0;
    this.totalQuantity = 0;
    this.taxCost = 0;
    this.accountId = 0;
    this.verificationKey = '';
    this.address.firstname = '';
    this.address.lastname = '';
    this.address.address1 = '';
    this.address.address2 = '';
    this.address.company = '';
    this.address.city = '';
    this.address.state = '';
    this.address.postalcode = '';
    this.address.country = 'US';
    this.address.email = '';
    this.purchaseOrder = '';
    this.purchaseOrderPrivate = '';
    this.phone = '';
    this.phoneCountry = '';
    this.cardNumber = '';
    this.csc = '';
    this.expMonth = 12;
    this.expYear = 2020;
    this.firstNamePayment = '';
    this.lastNamePayment = '';
    this.address1Payment = '';
    this.address2Payment = '';
    this.cityPayment = '';
    this.statePayment = '';
    this.postalCodePayment = '';
    this.countryPayment = 'US';

    this.itemsInCart = 0;

    this.inventoryType = 'PRINT_TO_ORDER';
    this.payer = 'ORGANIZATION';

    this.orderOptions = [];
    this.packageCollections = {};

    this.addressValidationStatus = 'INITIAL';
    this.packItemsInSeparateBoxes = false;
    this.useCaseQuantities = false;

    if ( this.items ) {
      for ( const item of this.items ) {
        item.quantity = 0;
      }
    }
  }

  getItemsInCart() {
    return this.itemsInCart;
  }

  computeItemsInCart() {
    let count = 0;
    for ( const item of this.items ) {
      if ( item.quantity > 0 ) {
        count++;
      }
    }
    this.itemsInCart = count;
  }
}

