import {Component, Input, OnInit} from '@angular/core';
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PrintNodeOrderItem} from "../../../../model/PrintNodeOrderItem";
import {Product} from "../../../../model/Product";

@Component({
  selector: 'app-print-node-order-item-admin',
  templateUrl: './print-node-order-item-admin.component.html',
  styleUrls: ['../print-node-orders-admin.component.css'] // NOTE THIS IS FROM THE PARENT FOLDER
})
export class PrintNodeOrderItemAdminComponent implements OnInit {
  @Input() products: Product[];
  @Input() printNodeOrder: PrintNodeOrder;
  @Input() printNodeOrderItem: PrintNodeOrderItem;
  @Input() editableItem: boolean;
  @Input() changeItem: any;
  @Input() orgId: number;

  selectedProduct: Product;

  constructor() { }

  ngOnInit() {
  }

  isProductAlreadyUsedInTheOrder(bookId: number) {
    for ( const printNodeOrderItem of this.printNodeOrder.printNodeOrderItems ) {
      if ( printNodeOrderItem.book_ID === bookId ){
        return true;
      }
    }
    return false;
  }

  changeProduct(product: Product) {
    this.selectedProduct = product;
    this.changeItem.product_ID = product.ID;
    this.changeItem.book_ID = product.book_ID;
    this.changeItem.type = 'ADD';
    if ( !this.changeItem.quantity || this.changeItem.quantity === 0 ) {
      this.changeItem.quantity = 1;
    }
  }
}
