import {Component, Input, OnInit} from '@angular/core';
import { Clipboard } from "@angular/cdk/clipboard";
import {Order} from "../../../../model/Order";

@Component({
  selector: 'app-group-order',
  templateUrl: './group-order.component.html',
  styleUrls: ['./group-order.component.css']
})
export class GroupOrderComponent implements OnInit {
  @Input() order: Order;
  constructor(private clipboard: Clipboard) { }

  ngOnInit() {
  }

  copyOrderDataToCSV () {
    // const id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //   // tslint:disable-next-line:no-bitwise
    //   const r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8);
    //   return v.toString(16);
    // });
    this.clipboard.copy(this.buildCSVContent());
  }

  downloadOrderDataToCSV () {
    const downloadFileName = this.order.ID + '_' + this.order.customerorderid + '_' + this.order.purchaseOrder_private + '.csv';
    const downloadFileContents = this.buildCSVContent();
    const blob = new Blob([downloadFileContents], {type: 'text/text'});
    const e = document.createEvent('MouseEvents');
    const a = document.createElement('a');
    a.download = downloadFileName;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
    e.initEvent('click', true, false);
    a.dispatchEvent(e);
  }

  buildCSVContent(): string {
    let value = 'x, y, z\n';
    value += '1, 2, 3';
    return value;
  }
}
