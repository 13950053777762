import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { Book } from '../../model/Book';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {NotificationsService} from "./notifications.service";

@Injectable()
export class BookService extends AbstractLiveDataService<Book> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setBookService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): Book {
    return new Book(args);
  }

  getLoggingObjectTypeName (): string {
    return "Book";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "book";
  }

  getLinkData(): any[] {
    return [
      { action: 'Link', objectIdPropertyName: 'body_ID', serviceName: 'bodyService', arrayProperty: 'books', objectProperty: 'body'},
      { action: 'Link', objectIdPropertyName: 'cover_ID', serviceName: 'coverService', arrayProperty: 'books', objectProperty: 'cover'},
      { action: 'Link', objectIdPropertyName: 'work_ID', serviceName: 'projectService', arrayProperty: 'books', objectProperty: 'project'},
    ];
  }

  public getImageURL(id: number, body: string, dpi: number, page: number): Observable<string> {
    const url = `${environment.apiBaseURL}book/imageurl/${id}/${body}/${dpi}/${page}`;
    return this.http.get(url, this.startupService.getHttpOptions())
      .pipe(
        map(image => {
          return image;
        }),
        catchError(this.handleErrorX<any>('getImageURL'))
      );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleErrorX<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.loadingState = 'INITIAL';
      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
