import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Catalog} from "../../../model/Catalog";
import {Product} from "../../../model/Product";
import {ProductService} from "../../service-data/product.service";
import {CatalogService} from "../../service-data/catalog.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {EditFacilityInventoryProductNoteComponent} from "../facility-inventory-products/facility-inventory-product/edit-facility-inventory-product-note/edit-facility-inventory-product-note-component";
import {ManageFacilityInventoryProductsComponent} from "./manage-facility-inventory-products/manage-facility-inventory-products.component";
import {FacilityService} from "../../service-data/facility.service";
import {FacilityInvoice} from "../../../model/FacilityInvoice";
import {Resource} from "../../../model/Resource";
import {ResourceService} from "../../service-data/resource.service";
import {Subject} from "rxjs";
import {FacilityInventoryProductService} from "../../service-data/facility-inventory-product.service";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})

export class ProductsComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, RoleChangeListener {
  catalogs: Catalog[];
  catalogIds: string[] = [];
  products: Product[] = [];

  // Table
  displayedColumns: string[] = undefined;
  dataSource = new MatTableDataSource(this.products);

  @ViewChild(MatSort) sort: MatSort;

  eventHandler: EventHandler;


  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  role: string;
  showCoverImages: boolean = false;

  constructor(public router: Router, public startupService: StartupService, private facilityInventoryProductService: FacilityInventoryProductService, private productService: ProductService, private catalogService: CatalogService, public resourceService: ResourceService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, private formBuilder: FormBuilder, public dialog: MatDialog) {
    this.startupService.addRoleChangeListener(this);
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
    });
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  getData(): void {
    const catalogsList = CatalogService.catalogCacheList;

    this.displayedColumns = ['id', 'title', 'subtitle', 'tag', 'inventoryItems'];
    if (this.startupService.getCurrentOrganization().microInventory !== 'ACTIVE') {
      this.displayedColumns = ['id', 'title', 'subtitle', 'tag'];
    }
    for ( let i = 0; i < catalogsList.length; i++) {
      this.displayedColumns.push('catalog' + i);
    }

    this.catalogs = catalogsList;
    this.catalogIds = [];
    for ( const catalog of this.catalogs ) {
      this.catalogIds.push(String(catalog.ID));
    }
    const facilityInventoryProductFilter = { };
    this.facilityInventoryProductService.getFilteredItems(facilityInventoryProductFilter)
      .subscribe(facilityInventoryProducts => {
    });

    const filter = { eq: {
        'product.productType': 'BOOK'
      }
    };
    this.productService.getFilteredItems(filter)
      .subscribe(products => {
        this.products = products;
        this.dataSource = new MatTableDataSource(this.products);
        this.products.forEach( product => {
          if ( product.facilityInventoryProducts.length > 0 ) {
            console.log('FacilityInventoryProducts');
          }
          if ( product.book && product.book.body ) {
            const b = this.getImageURL(product.org_ID, product.book.body.resource_ID, product.book.interiorType, 360, 1);
            const c = this.getImageURL(product.org_ID, product.book.body.resource_ID, 'COLOR', 360, 1);
            this.download(product.book.body.resource_ID, 'GREY', 360, 1);
          }
        });
        setTimeout(() => {
          this.dataSource.sort = this.sort;
        });
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  refresh () {
    this.productService.reset();
    this.getData();
  }


  getCoverImageURL(product: Product, pageNumber: number, ): string {
    // return 'http://app.snowfallpress.com/users/ripImage.jsp?color=true&resourceId=' + product.book.cover.resource_ID + '&dpi=72&pageNumbers=' + pageNumber + '&maxWidth=1584&maxHeight=936&authtoken=a339-5d3b-2192-4c04-b161-1dff-637b-44f2&source=' + product.org_ID;
    return 'http://app.snowfallpress.com/users/ripImage.jsp?color=true&resourceId=205558&dpi=72&pageNumbers=' + pageNumber + '&maxWidth=300&maxHeight=300&authtoken=a339-5d3b-2192-4c04-b161-1dff-637b-44f2&source=' + product.org_ID;
  }

  isInCatalog(index: number, product: Product): boolean {
    const selectedCatalogId: string = this.catalogIds[index];
    const productCatalogs: string[] = product.catalogs.split(',');
    if ( productCatalogs.indexOf(selectedCatalogId) >= 0 ) {
      return true;
    }
    return false;
  }

  catalogChecked(index: number, product: Product, checked: boolean): void {
    const selectedCatalogId: string = this.catalogIds[index];
    let productCatalogs: string[] = product.catalogs.split(',');
    if ( checked ) {
      if ( productCatalogs.length === 1 && productCatalogs[0].length === 0) {
        productCatalogs = [];
      }
      productCatalogs.push(selectedCatalogId);
    } else {
      productCatalogs.splice(productCatalogs.indexOf(selectedCatalogId), 1);
      // for ( const catalog of productCatalogs ) {
      //   if ( selectedCatalogId !== catalog ) {
      //     catalogStrings.push(catalog.ID);
      //   }
      // }
    }

    const prevValue = product.catalogs;
    product.catalogs = productCatalogs.join(',');
    console.log(`catalogChecked ${index} ${product.catalogs} previous value ${prevValue} new value ${product.catalogs}`);
    this.productService.updateItem(product).subscribe( p => {
      console.log(`Product Updated ${product.ID}`);
    });
  }

  toggleCoverImages () {
    const index = this.displayedColumns.indexOf('coverImage');

    // If not displayed add it
    if ( index === -1) {
      this.displayedColumns.splice(4, 0, 'coverImage');
    } else { // If present remove it
      this.displayedColumns.splice(index, 1);
    }
  }

  public manageMicroInventory(product: Product) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { product: product, products: this.products };
    const dialogRef = this.dialog.open(ManageFacilityInventoryProductsComponent, dialogConfig);
  }

  public getImageURL(orgId: number, resourceId: number, color: string, dpi: number, page: number) {
    return this.resourceService.getImageURL(resourceId, color, dpi, page);
  }
  public download(resourceId: number, color: string, dpi: number, page: number) {

    console.log(`Download Resource Image: ${resourceId}`);
    this.resourceService.getImage(resourceId, 'GREY', 360, 1).subscribe( data => {
      const downloadFileName = `/${resourceId}/${color}/${dpi}/${page}.png`;
      const blob = new Blob([data.toString()], {type: 'text/html'});
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = downloadFileName;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/html', a.download, a.href].join(':');
      e.initEvent('click', true, false);
      a.dispatchEvent(e);
    });
  }

}
