import {TableFilter} from "./TableFilter";

export class NotTableFilter extends TableFilter {
  public child: TableFilter; //

  constructor (obj: any) {
    super(obj);
    if ( obj.child ) {
      this.child = TableFilter.create(obj.child);
    } else {
      this.child = TableFilter.create(undefined);
    }
  }

  public match(data: any, filter: string): boolean {
      return !(this.child.match(data, filter));
  }
}
