<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Receive Replenishment Order">Receive Replenishment Order</h2>
  </div>
  <div>
    <form [formGroup]="formGroup" (change)="formChanged()">
      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Date" placeholder="Date" id="date" required type="date" formControlName="date">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Quantity" placeholder="Quantity" id="quantity" required type="number" min="0"  max="1500" formControlName="quantity">
            </mat-form-field>
          </div>
        </mat-grid-tile>
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <mat-select  placeholder="Action Type" i18n-placeholder="@@Action Type" formControlName="actionType" required>-->
<!--                <mat-option value="RecordCurrentOnHandInventoryLevel" i18n='@@Record Current On Hand Inventory Level' selected='selected'>Record Current On Hand Inventory Level</mat-option>-->
<!--                <mat-option value="Return" i18n='@@Return'>Return</mat-option>-->
<!--                <mat-option value="Correction" i18n='@@Correction'>Correction</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
      </mat-grid-list>
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="formGroup.invalid" (click)="onSave()" i18n="@@Receive Replenishment Order">Receive Replenishment Order</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
