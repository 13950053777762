import {Location} from '@angular/common';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { NavbarService } from '../../service-ui/navbar.service';
import { ProjectNavbarService } from '../../service-ui/project.navbar.service';
import {ActivatedRoute, Router} from "@angular/router";
import {UserLoginService} from "../../service-auth/user-login.service";
import {StartupService} from "../../service-ui/startup.service";
import {ThemeService} from "../../service-data/theme.service";
import {EditorProjectService} from "../../service-data/editor/editor-project.service";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit, OnChanges {
  @Input() focus: string = 'createProject';
  state: string;

  showLanguagesMenu = false;

  showInteriorToolsContainer: boolean = false;

  constructor(public navbarService: NavbarService, public projectNavbarService: ProjectNavbarService, public startupService: StartupService, public userService: UserLoginService, public router: Router, private route: ActivatedRoute, private themeService: ThemeService, private projectService: EditorProjectService, public location: Location) {
    // Listen for changes in the state of the NavbarService -- values are set in each component
    navbarService.navState.subscribe( (state) => this.state = state );
    this.startupService.touchLastUserActivity();
  }

  ngOnInit() {
    this.startupService.touchLastUserActivity();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.startupService.touchLastUserActivity();
  }

  toggleFilterState() {
    this.projectNavbarService.toggleFilterState();
  }

  logout() {
    this.startupService.logout();
  }
}
