import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import {PickSheetService} from "../../service-data/pick-sheet.service";
import {FacilityService} from "../../service-data/facility.service";
import {Subject} from "rxjs";
import {FacilityInvoice} from "../../../model/FacilityInvoice";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-facility-print-node-orders',
  templateUrl: './fulfillment-orders.component.html',
  styleUrls: ['./fulfillment-orders.component.css']
})

export class FulfillmentOrdersComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [
    { columnDef: 'ID',               header: 'ID',               sort: true,  type: 'number',    filterType: 'String',          style: 'text-align: right',  property: 'ID',                                                                 cell: (element: any) => `${element.ID}`  },
    { columnDef: 'created',          header: 'Created',          sort: true,  type: 'date',      filterType: 'String',          style: 'text-align: center', property: 'created',                                                            cell: (element: any) => `${element.created ? element.created : new Date() }`, dateFormat: 'MM/dd/yy' },
    // { columnDef: 'tag',             header: 'Tag',              sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'tag',                                                             cell: (element: any) => `${element.tag}`  },
    { columnDef: 'organizationName', header: 'Organization',     sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'name',                                                               cell: (element: any) => `${ element.organization ? element.organization.name : ''}`  },
    { columnDef: 'name',             header: 'Name',             sort: true,  type: 'string',    filterType: 'CompositeString', style: 'text-align: left',   properties: ['firstname', 'lastname', 'company'],                               cell: (element: any) => `${element.firstname.toUpperCase()} ${element.lastname.toUpperCase()}`,                        cellRow2: (element: any) => `${element.company}`  },
    { columnDef: 'address',          header: 'Address',          sort: true,  type: 'string',    filterType: 'CompositeString', style: 'text-align: left',   properties: ['address1', 'address2', 'state', 'city', 'postalcode', 'country'], cell: (element: any) => `${element.address1.toUpperCase()} ${element.address2 ? element.address2.toUpperCase() : ''}`, cellRow2: (element: any) => `${element.city.toUpperCase()} ${element.state.toUpperCase()} ${element.postalcode ? element.postalcode : ''} ${element.country ? element.country.toUpperCase() : ''}`  },
    { columnDef: 'weight',           header: 'Weight',           sort: true,  type: 'number',    filterType: 'NumericRange',    style: 'text-align: right',  property: 'weight',                                                             cell: (element: any) => `${Math.round(element.weight * 10) / 10}` },
    { columnDef: 'carrier',          header: 'Carrier',          sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'carrier',                                                            cell: (element: any) => `${element.servicelevel}` },
    { columnDef: 'shippingStatus',   header: 'Shipping Status',  sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'shippingStatus',                                                     cell: (element: any) => `${element.shippingStatus}`},
    { columnDef: 'inventoryType',    header: 'Inventory',        sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'inventoryType',                                                      cell: (element: any) => `${element.inventoryType}`},
    { columnDef: 'packageCreationStatus',       header: 'Packages',        sort: true,  type: 'string', filterType: 'String',   style: 'text-align: left',   property: 'packageCreationStatus',                                              cell: (element: any) => `${element.packageCreationStatus}`},
    { columnDef: 'shippingLabelCreationStatus', header: 'Shipping Labels',  sort: true, type: 'string', filterType: 'String',   style: 'text-align: left',   property: 'shippingLabelCreationStatus',                                        cell: (element: any) => `${element.shippingLabelCreationStatus}`},
    { columnDef: 'pickSheet',        header: 'Pick Sheet',       sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'pickSheet',                                                          cell: (element: any) => `${element.pickSheet}`},
    { columnDef: 'trackingNumbers',  header: 'Tracking Numbers', sort: true,  type: 'link',      filterType: 'String',          style: 'text-align: left',   property: 'trackingNumbers',                                                    cell: (element: any) => `${element.shippingStatus === 'NONE' ? '' : element.trackingNumbers}`,   link: (element: any) => {
        const trackingNumbers = element.trackingNumbers; // .split(' ').join('_');
        if ( element.shippingStatus === 'NONE') {
          return '';
        }
        if (trackingNumbers.startsWith('94')) {
          return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + trackingNumbers.split(/_(.+)/)[0]; // trackingNumbers.split(',').join('%20');
        } else if (trackingNumbers.startsWith('1Z')) {
          return 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + trackingNumbers.split(/_(.+)/)[0]; // .split(',').join('%20');
        } else if (element.carrier === 'FEDEX') {
          return 'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=' + trackingNumbers.split(/_(.+)/)[0];
        } else {
          return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + trackingNumbers.split(/_(.+)/)[0]; // trackingNumbers.split(',').join('%20');
        }
      }
    }
//     { property: 'orgName',         columnDef: 'orgName',         header: 'Organization',     sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                             cell: (element: any) => `${element.organization.Name }` }
  ];

//   columns = [
//      // { property: 'dueDate',         columnDef: 'dueDate',         header: 'Due Date',         sort: true,  type: 'date',      filterType: 'NumericRange', style: 'display: flex; justify-content: space-around; text-align: right', cell: (element: any) => `${ element.dueDate ? element.dueDate : new Date() }`, dateFormat: 'MM/dd/yy' },
//      { property: 'ID',              columnDef: 'ID',              header: 'ID',               sort: true,  type: 'number',    filterType: 'String', style: 'display: flex; justify-content: space-around; text-align: right', cell: (element: any) => `${element.ID}` },
//      { property: 'created',         columnDef: 'created',         header: 'Created',          sort: true,  type: 'date',      filterType: 'String', style: 'display: flex; justify-content: space-around; text-align: right', cell: (element: any) => `${element.created ? element.created : new Date() }`, dateFormat: 'MM/dd/yy' },
//      { property: 'tag',             columnDef: 'tag',             header: 'Tag',              sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.tag}`  },
//      { property: 'name',            columnDef: 'name',            header: 'Name',             sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.firstname.toUpperCase()} ${element.lastname.toUpperCase()}`  },
//      { property: 'company',         columnDef: 'company',         header: 'Company',          sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.company}`  },
//      { property: 'address1',        columnDef: 'address1',        header: 'Address1',         sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.address1.toUpperCase()}` },
//      { property: 'address2',        columnDef: 'address2',        header: 'Address2',         sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.address2 ? element.address2.toUpperCase() : ''}` },
//      { property: 'city',            columnDef: 'city',            header: 'City',             sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.city.toUpperCase()}` },
//      { property: 'state',           columnDef: 'state',           header: 'State',            sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.state.toUpperCase()}` },
//      { property: 'zip',             columnDef: 'zip',             header: 'Zip',              sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.postalCode ? element.postalCode : ''}` },
//      { property: 'country',         columnDef: 'country',         header: 'Country',          sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.country ? element.country.toUpperCase() : ''}` },
//      { property: 'weight',          columnDef: 'weight',          header: 'Weight',           sort: true,  type: 'number',    filterType: 'NumericRange', style: 'text-align: right',                                               cell: (element: any) => `${Math.round(element.weight * 10) / 10}` },
//      // { property: 'status',       columnDef: 'status',          header: 'Status',           sort: true,  type: 'string',    filterType: 'DataEnum',     style: 'text-align: right',                                               cell: (element: any) => `${element.status}`, values: { USPS: 'USPS', UPS: 'UPS'} },
//      { property: 'carrier',         columnDef: 'carrier',         header: 'Carrier',          sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.carrier}`, values: { USPS: 'USPS', UPS: 'UPS'} },
//      { property: 'servicelevel',    columnDef: 'serviceLevel',    header: 'Service Level',    sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.servicelevel}` },
//      { property: 'shippingStatus',  columnDef: 'shippingStatus',  header: 'Shipping Status',  sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.shippingStatus}` },
//      { property: 'trackingNumbers', columnDef: 'trackingNumbers', header: 'Tracking Numbers', sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.getTrackingNumbers()}` }
// //   { property: 'phoneNumber',       columnDef: 'phoneNumber',      header: 'Phone Number',      sort: true,  type: 'string',     filterType: 'String',     style: 'text-align: right',  cell: (element: any) => `${element.phoneNumber}` },
// //   { property: 'organization.ID',   columnDef: 'organizationId',   header: 'Organization Id',   sort: true,  type: 'number',     filterType: 'NumericRange',      style: 'text-align: right',  cell: (element: any) => `${element.organization.ID}` },
// //      { property: 'organization.name', columnDef: 'organizationName', header: 'Organization Name', sort: false, type: 'string',     filterType: 'String',        style: 'text-align: right',  cell: (element: any) => `${element.organization.Name }` },
// //   { property: 'printNodePrintNodeOrder.ID', columnDef: 'packButton',   header: 'Pack',         sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.printNodePrintNodeOrder.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'},
//   ];

  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<PrintNodeOrder>();
  public renderedData: PrintNodeOrder[];
  tableFilterManager: TableFilterManager<PrintNodeOrder> = new TableFilterManager(this.dataSource);
  filter: any = {};
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  role: string;

  showShipped: boolean = false;
  showPickSheetPrinted: boolean = false;

  constructor(public router: Router, public startupService: StartupService, private printNodeOrderService: PrintNodeOrderService, private pickSheetService: PickSheetService, private facilityService: FacilityService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.addRoleChangeListener(this);
    // this.startupService.touchLastPrintNodeOrderActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the printNodeOrder is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      this.renderedData = this.dataSource.data;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: PrintNodeOrder, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  match(o: PrintNodeOrder ): boolean {
    if ( o && o.inventoryType === "FULFILL_FROM_INVENTORY") {
      if ( this.showShipped || o.shippingStatus === 'NONE') {
        if ( this.showPickSheetPrinted || o.pickSheet === "INITIAL") {
          return true;
        }
      }
    }
    return false;
  }

  addUpdate(o: PrintNodeOrder) {
    if ( this.match(o)) {
      if ( this.dataSource.data.findIndex(item => item.getId() === o.getId()) === -1) {
        this.dataSource.data.push(o);
      } else {
      }
    } else {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
      }
    }
    this.dataSource.sort = this.sort;
    this.dataSource.filter = '' + Math.random();
  }

  getData(): void {
    this.startupService.touchLastUserActivity();
    this.dataSource.data = [];
    this.printNodeOrderService.getAddNotifier(true).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.printNodeOrderService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.printNodeOrderService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
    });
    this.printNodeOrderService.objects.forEach( o => {
      this.addUpdate(o);
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  refresh () {
    this.printNodeOrderService.reset();
    this.getData();
  }

  printLargePickSheets () {
    if ( this.startupService.selectedRole === 'facility') {
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe( facility => {
        this.pickSheetService.createAllPickSheets(facility, 'large', 'B').subscribe( result => {
          console.log(`createdPickSheet for facility: ${facility.ID}`);
        });
      });
    }
  }
  printSmallPickSheets () {
    if ( this.startupService.selectedRole === 'facility') {
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe( facility => {
        this.pickSheetService.createAllPickSheets(facility, 'small', 'B').subscribe( result => {
          console.log(`createdPickSheet for facility: ${facility.ID}`);
        });
      });
    }
  }
}
