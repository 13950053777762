<ng-container *ngIf="navbarService.state !== 'login'">
  <div style="display:flex; width:100%">
    <span>
      <button mat-icon-button (click)="startupService.mainSideNav.toggle()" title="ShowHideSideNavigation" i18n-title="@@ShowHideSideNavigation"><mat-icon>supervised_user_circle</mat-icon></button>
      <!--<button mat-icon-button (click)="location.back()"><mat-icon>{{startupService.dir === 'rtl' ? 'arrow_forward_ios' : ' arrow_back_ios'}}</mat-icon></button>-->
      <!--<h2 style="display:inline-block" i18n="@@Main Organization Name">Main Organization Name</h2>-->
      <!--<a style="margin-right: 10px !important; margin-left: 10px !important;" class="navbar-brand" [routerLink]="['/secure/projects']"><img src="/whiteLogo.png" class=""></a>-->
      <!--<button *ngIf="state === 'projects'" mat-raised-button color="primary" [routerLink]="['/secure/createProject']"><mat-icon>add</mat-icon><span>&nbsp;</span><span i18n="@@Create Project">Create Project</span></button>-->
    </span>
    <span class="app-toolbar-filler"></span>
  </div>
  <span class="app-toolbar-filler"></span>
  <div>
    <span>
      <button *ngIf="state !== 'cart' && startupService.cart && startupService.cart.items && startupService.cart.items.length > 0" mat-list-item [routerLink]="['/secure/cart']" title="ShoppingCartToolTip" i18n-title="@@ShoppingCartToolTip"><mat-icon [matBadge]="startupService.cart.items.length" matBadgeColor="warn" >shopping_cart</mat-icon><span> </span><ng-container i18n="@@Shopping Cart">ShoppingCart</ng-container></button>
      <!--<ng-container *ngIf="state === 'cover' || state === 'chapter'">-->
      <!--<span *ngIf="startupService.selectedChapter" [ngClass]="startupService.showInteriorTools ? 'inverse': ''"  class="rightTabControl fa fa-screwdriver" (click)="startupService.showInteriorTools = !startupService.showInteriorTools; startupService.showCoverTools = !startupService.showCoverTools" i18n="@@Tools" i18n-title="@@Show Interior Tools" title="Show Tools">Tools</span>-->
      <!--<span *ngIf="startupService.selectedCover" [ngClass]="startupService.showCoverTools ? 'inverse': ''"  class="rightTabControl fa fa-screwdriver" (click)="startupService.showCoverTools = !startupService.showCoverTools; startupService.showInteriorTools = !startupService.showInteriorTools" i18n="@@Tools" i18n-title="@@Show Tools" title="Show Tools">Tools</span>-->
      <!--</ng-container>-->
      <span *ngIf="state !== 'projects' && startupService.project" class="statusRectangle" [ngClass]="startupService.project.savingStatus"></span>
    </span>
  </div>
</ng-container>
