import {ColorPalette} from "../color/ColorPalette";
import {FontSettings} from "./FontSettings";
import {CoverTheme} from "./CoverTheme";
import {Image} from "../Image";

export class Cover {
  public colorPalette: ColorPalette;
  public fontSettings: FontSettings;
  public coverTheme: CoverTheme;
  public scale: number;
  public images: Image[];
  public imagesByRole: any = {};
  public imagePropertiesByRole: any = {};

  constructor( obj ) {
    this.images = [];
    if ( obj ) {
      this.coverTheme = new CoverTheme( obj.coverTheme );
      this.colorPalette = new ColorPalette( obj.colorPalette );
      this.fontSettings = new FontSettings( obj.fontSettings );
      // this.jsonContents = obj.jsonContents;
      // if ( obj.scale ) {
      //   this.scale = obj.scale;
      // } else {
      //   this.scale = 1.0;
      // }
      this.scale = 0.6;
    } else {
      this.coverTheme = new CoverTheme( undefined );
      this.colorPalette = new ColorPalette( undefined );
      this.fontSettings = new FontSettings( undefined );
      // this.jsonContents = { objects: [] };
      this.scale = 0.6;
    }

    if ( obj && obj.images ) {
      for ( const o of obj.images ) {
        const image = new Image(o);
        this.images.push(image);
      }
    }

    if ( obj && obj.imagesByRole ) {
      this.imagesByRole = obj.imagesByRole;
    }

    if ( obj && obj.imagePropertiesByRole ) {
      this.imagePropertiesByRole = obj.imagePropertiesByRole;
    }
    this.cleanImagesForInactiveRoles();
    this.verifyImages();
  }

  clearImages() {
    this.imagesByRole = {};
    this.clearAllButTextValues();
    while ( this.images.length > 0 ) {
      this.images.pop();
    }
  }

  clearAllButTextValues() {
    this.images = [];
    this.imagesByRole = {};
    for ( const role of Object.keys(this.imagePropertiesByRole) ) {
      const imageProperties = this.imagePropertiesByRole[role];
      for ( const prop of Object.keys( imageProperties ) ) {
        if ( prop !== 'text' &&  prop !== 'notext'  &&  prop !== 'textStyles' ) {
          delete imageProperties[prop];
        }
      }
    }
  }

  verifyImages() {
    const newImages: Image[] = [];
    const activeImageKeysByRoleInTheme = this.coverTheme.getActiveImageKeysByRole();
    delete this.imagesByRole['whole-hidden-image'];
    delete activeImageKeysByRoleInTheme['whole-hidden-image'];
    // Make a new list of images in the cover from the default images in the active roles in the theme and then override them if a different image has been selected
    // Walk through the active roles for images in the theme
    const unionOfImageKeyRoles = Object.keys(activeImageKeysByRoleInTheme).concat(Object.keys(this.imagesByRole)); // TODO: de-duplicate this list
    for ( const role of unionOfImageKeyRoles ) {
      // If there is an overriding image in the cover then use it otherwise use the value from the theme
      if ( this.imagesByRole[role] ) {
        // Only add the key if it exists and is not already in the list
        if ( this.imagesByRole[role] && newImages.indexOf( this.imagesByRole[role]) < 0 ) {
          newImages.push(this.imagesByRole[role]);
        }
      } else {
        let imageAlreadyInNewList = false;
        for ( const img of newImages ) {
          if ( img.key === activeImageKeysByRoleInTheme[role] ) {
            imageAlreadyInNewList = true;
          }
        }
        if ( activeImageKeysByRoleInTheme[role] && !imageAlreadyInNewList ) {
          const newImage = new Image( undefined );
          newImage.key = activeImageKeysByRoleInTheme[role];
          newImages.push(newImage);
        }
      }
    }
    this.images = newImages;
  }

  clearImageProperty(role: string, property: string ) {
    if ( role && property ) {
      if ( !this.imagePropertiesByRole[role] ) {
        return;
      }
      const imageProperties = this.imagePropertiesByRole[role];
      delete imageProperties[property];
    }
  }

  setImagePropertyNumber(role: string, property: string, value: number ) {
    if ( role && property ) {
      if ( !this.imagePropertiesByRole[role] ) {
        this.imagePropertiesByRole[role] = {};
      }
      const imageProperties = this.imagePropertiesByRole[role];
      imageProperties[property] = Number(value);
    }
  }

  setImagePropertyString(role: string, property: string, value: string ) {
    if ( role && property ) {
      if ( !this.imagePropertiesByRole[role] ) {
        this.imagePropertiesByRole[role] = {};
      }
      const imageProperties = this.imagePropertiesByRole[role];
      imageProperties[property] = String(value);
    }
  }

  setImagePropertyObject(role: string, property: string, value: Object ) {
    if ( role && property ) {
      if ( !this.imagePropertiesByRole[role] ) {
        this.imagePropertiesByRole[role] = {};
      }
      const imageProperties = this.imagePropertiesByRole[role];
      imageProperties[property] = value;
    }
  }
  // toggleImagePropertyBoolean(role: string, property: string) {
  //   if ( role && property ) {
  //     if ( !this.imagePropertiesByRole[role] ) {
  //       this.imagePropertiesByRole[role] = {};
  //     }
  //     const imageProperties = this.imagePropertiesByRole[role];
  //     if ( imageProperties[property] === false ) {
  //       delete imageProperties[property];
  //     } else if ( imageProperties[property] === true ) {
  //       imageProperties[property] = Boolean(false);
  //     } else if ( imageProperties[property] === undefined ) {
  //       imageProperties[property] = Boolean(true);
  //     }
  //   }
  // }

  setImagePropertyBoolean(role: string, property: string, value: boolean ) {
    if ( role && property ) {
      if ( !this.imagePropertiesByRole[role] ) {
        this.imagePropertiesByRole[role] = {};
      }
      const imageProperties = this.imagePropertiesByRole[role];
      imageProperties[property] = Boolean(value);
    }
  }

  getImagePropertyNumber(role: string, property: string, defaultValue: number ): number {

    if ( this.imagePropertiesByRole[role] ) {
      const imageProperties = this.imagePropertiesByRole[role];
      if ( imageProperties[property] ) {
        return Number(imageProperties[property]);
      }
    }
    return defaultValue;
  }

  getImagePropertyString(role: string, property: string, defaultValue: string ): string {

    if ( this.imagePropertiesByRole[role] ) {
      const imageProperties = this.imagePropertiesByRole[role];
      if ( imageProperties[property] ) {
        return String(imageProperties[property]);
      }
    }
    return defaultValue;
  }

  getImagePropertyObject(role: string, property: string, defaultValue: Object ): Object {

    if ( this.imagePropertiesByRole[role] ) {
      const imageProperties = this.imagePropertiesByRole[role];
      if ( imageProperties[property] ) {
        return imageProperties[property];
      }
    }
    return defaultValue;
  }

  getImagePropertyBoolean(role: string, property: string, defaultValue: boolean ): boolean {

    if ( this.imagePropertiesByRole[role] ) {
      const imageProperties = this.imagePropertiesByRole[role];
      if ( imageProperties[property] === undefined ) {
        return defaultValue;
      }
      return imageProperties[property];
    }
    return defaultValue;
  }

  getImagePropertyAny(role: string, property: string, defaultValue: any ): any {

    if ( this.imagePropertiesByRole[role] ) {
      const imageProperties = this.imagePropertiesByRole[role];
      if ( imageProperties[property] ) {
        return imageProperties[property];
      }
    }
    return defaultValue;
  }
  addImage(key: string, role: string) {
    if ( key ) {
      let found = false;
      for ( const image of this.images ) {
        if ( key === image.key ) {
          found = true;
          if ( this.imagesByRole[role] ) {
            if ( this.imagesByRole[role].key !== key ) {
              this.removeImageIfNotUsedInARole( key );
            }
          }
          this.imagesByRole[role] = image;
        }
      }
      if ( !found ) {
        const image = new Image( undefined );
        image.key = key;
        this.images.push(image);
        this.imagesByRole[role] = image;
      }
    }
    this.verifyImages();
  }

  cleanImagesForInactiveRoles() {
    for ( const prop of Object.keys(this.imagesByRole) ) {
      const coverThemeItemSetting = this.coverTheme.getCoverThemeItemSetting(prop);
      if ( coverThemeItemSetting ) {
        if (!coverThemeItemSetting.isActive()) {
          delete this.imagesByRole[prop];
        }
      }
    }
  }

  removeImage(key: string, role: string) {
    // First wipe out the role usage of the imageKey
    const currentKeyForRole = this.imagesByRole[role];
    this.imagesByRole[role] = undefined;
    this.removeImageIfNotUsedInARole(key);
    this.removeImageIfNotUsedInARole(currentKeyForRole);
    this.verifyImages();
  }

  removeImageIfNotUsedInARole(key: string) {
    for ( const prop of Object.keys(this.imagesByRole) ) {
      // If the same image is used by in a different role then don't remove it from the list of images
      if ( this[prop] && this[prop].key === key ) {
        return;
      }
    }

    // Remove the imageKey from the list of images if not used in any role
    for ( const image of this.images ) {
      if ( key === image.key ) {
        const index = this.images.indexOf(image);
        if ( index >= 0 ) {
          this.images.splice(index, 1 );
          return;
        }
      }
    }
  }

  getImages(): Image[] {
    return this.images;
  }
  copyCoverThemeColorPalette() {
    if ( this.colorPalette && this.coverTheme.colorPalette ) {
      this.colorPalette = new ColorPalette(this.coverTheme.colorPalette);
    }
  }

  copyCoverThemeFontSettings() {
    if ( this.fontSettings && this.coverTheme.fontSettings ) {
      this.fontSettings = new FontSettings(this.coverTheme.fontSettings);
    }
  }

  setCoverTheme(coverTheme: CoverTheme) {
    this.coverTheme = new CoverTheme( coverTheme );
  }

  getCoverTheme(): CoverTheme {
    return this.coverTheme;
  }

  setColorPalette(colorPalette: ColorPalette) {
    this.colorPalette = new ColorPalette( colorPalette );
  }

  getCoverPalette(): ColorPalette {
    return this.colorPalette;
  }

  setFontSettings(fontSettings: FontSettings) {
    this.fontSettings = fontSettings;
  }

  getFontSettings(): FontSettings {
    return this.fontSettings;
  }
  // setJsonContents(jsonContents) {
  //   this.jsonContents = jsonContents;
  // }
  //
  // getJsonContents(): any {
  //   return this.jsonContents;
  // }

}
