import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { User } from '../../model/User';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {DataService} from "./data-service";
import {Facility} from "../../model/Facility";
import {PrintNodeOrder} from "../../model/PrintNodeOrder";
import {PickSheetsGroup} from "../component-facility/picksheets/PickSheetGroup";

@Injectable()
export class PickSheetService extends DataService {

  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
    super(startupService);
    // this.startupService.setUserService(this);
  }

  getPickSheetsGroups(facility: Facility, filter: any): Observable<PickSheetsGroup[]> {
    let url = `${environment.apiBaseURL}picksheet/picksheetsgroups?facilityId=${facility.ID}`;
    const filterString = `filter=${Buffer.from(JSON.stringify(filter), 'binary').toString('base64')}`;
    if ( filterString ) {
      url += '&' + filterString;
    }
    return this.http.get<PickSheetsGroup[]>(url, this.startupService.getHttpOptions())
      .pipe(
        map(pickSheetsGroups => {
          const ret: PickSheetsGroup[] = [];
          for ( const pickSheetsGroup of pickSheetsGroups ) {
            ret.push( new PickSheetsGroup(pickSheetsGroup));
          }
          return ret;
        }),
        catchError(this.handleError<PickSheetsGroup[]>(`getPickSheetsGroups`))
      );
  }

  /** PUT: createAllPickSheets for a facility */
  createAllPickSheets (facility: Facility, smallOrLarge: string, printer: string): Observable<User> {
    const url = `${environment.apiBaseURL}picksheet/${smallOrLarge}/${facility.ID}/${printer}`;
    this.messageService.add(`PickSheetService: createAllPickSheets id=${facility.ID}`);
    return this.http.put(url, undefined, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`createAllPickSheets facility id=${facility.ID}`)),
      catchError(this.handleError<any>('createAllPickSheets'))
    );
  }
  /** PUT: createPickSheet for a PrintNodeOrder */
  createPickSheet (facility: Facility, printNodeOrder: PrintNodeOrder, printer: string ): Observable<User> {
    const url = `${environment.apiBaseURL}picksheet/${facility.ID}/${printer}`;
    const printNodeOrderIds: number[] = [];
    printNodeOrderIds.push(printNodeOrder.ID);
    this.messageService.add(`PickSheetService: createPickSheet facility printNodeOrder id=${facility.ID} ${printNodeOrder.ID}`);
    return this.http.put(url, printNodeOrderIds, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`createPickSheet facility printNodeOrder id=${facility.ID} ${printNodeOrder.ID}`)),
      catchError(this.handleError<any>(`createPickSheet id=${facility.ID} ${printNodeOrder.ID}`))
    );
  }

  /** PUT: createPickSheets for a PrintNodeOrder */
  createPickSheets (facility: Facility, printNodeOrderIds: number[], printer: string ): Observable<User> {
    const url = `${environment.apiBaseURL}picksheet/${facility.ID}/${printer}`;
    this.messageService.add(`PickSheetService: createPickSheets facility printNodeOrder id=${facility.ID} ${JSON.stringify(printNodeOrderIds)}`);
    return this.http.put(url, printNodeOrderIds, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`createPickSheet facility printNodeOrder id=${facility.ID} ${JSON.stringify(printNodeOrderIds)}`)),
      catchError(this.handleError<any>(`createPickSheet id=${facility.ID} ${JSON.stringify(printNodeOrderIds)}`))
    );
  }

  /** PUT: createPickAndShipSheets for a PrintNodeOrder */
  createPickAndShipSheets (facility: Facility, printNodeOrderIds: number[], printer: string, manufacturingSetId: number): Observable<User> {
    const url = `${environment.apiBaseURL}picksheet/pickpackandship/${facility.ID}/${printer}/${manufacturingSetId}`;
    this.messageService.add(`PickSheetService: createPickAndShipSheets facility printNodeOrder id=${facility.ID} ${JSON.stringify(printNodeOrderIds)}`);
    return this.http.put(url, printNodeOrderIds, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`createPickSheet facility printNodeOrder id=${facility.ID} ${JSON.stringify(printNodeOrderIds)}`)),
      catchError(this.handleError<any>(`createPickSheet id=${facility.ID} ${JSON.stringify(printNodeOrderIds)}`))
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add('UserService: ' + message);
  }

  reset (filter: string = '') {
  }

}
