<div style="width: 640px">
  <div class="add-title">
    <h2 class="primary" i18n="@@Filter Packages">Filter Packages</h2>
  </div>
  <div>
    <form [formGroup]="form" (change)="formChanged()">
<!--      <mat-grid-list cols="1" [cols]="breakpoint" rowHeight="100px" (window:resize)="onResize($event)">-->
      <mat-grid-list cols="1" rowHeight="100px">
        <mat-grid-tile>
          <mat-button-toggle-group id="selectedFilter" [(value)]="selectedFilter" formControlName="selectedFilter">
            <mat-button-toggle value="Set" i18n='@@Set'>Set</mat-button-toggle>
            <mat-button-toggle value="Package" i18n='@@Package'>Package</mat-button-toggle>
            <mat-button-toggle value="BookTitle" i18n='@@BookTitle'>BookTitle</mat-button-toggle>
            <mat-button-toggle value="Book" i18n='@@Book'>Book</mat-button-toggle>
            <mat-button-toggle value="Order" i18n='@@Order'>Order</mat-button-toggle>
            <mat-button-toggle value="None" title="None" i18n="@@noneUpperCase">None</mat-button-toggle>
          </mat-button-toggle-group>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="selectedFilter !== 'None'">
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Value" placeholder="Value" [ngModel]="selectedFilterValue" id="selectedFilterValue" type="text" formControlName="selectedFilterValue">
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="form.invalid" (click)="onChange()" i18n="@@Done">Done</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
