<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <div>  </div>
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Create a new project.">Create a new project</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Create a new project.">Create a new project</span>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
    <div>
      <span>
        <button [mat-menu-trigger-for]="languagesMenu" mat-raised-button color="primary" (click)="showLanguagesMenu = !showLanguagesMenu"><mat-icon>language</mat-icon> </button>
        <mat-menu #languagesMenu="matMenu">
          <button mat-menu-item i18n-href="@@enURL" href="/" i18n="@@English">English</button>
          <button mat-menu-item i18n-href="@@frURL" href="/" i18n="@@French">français</button>
          <button mat-menu-item i18n-href="@@esURL" href="/" i18n="@@Spanish">Español</button>
          <button mat-menu-item i18n-href="@@deURL" href="/" i18n="@@German">Deutsche</button>
          <button mat-menu-item i18n-href="@@arURL" href="/" i18n="@@Arabic" style="text-align:right">عربى</button>
          <button mat-menu-item i18n-href="@@zhURL" href="/" i18n="@@Chinese">中文</button>
          <button mat-menu-item i18n-href="@@amURL" href="/" i18n="@@Amharic">አማርኛ</button>
          <button mat-menu-item i18n-href="@@koURL" href="/" i18n="@@Korean">한국어</button>
          <button mat-menu-item i18n-href="@@roURL" href="/" i18n="@@Romanian">Română</button>
          <button mat-menu-item i18n-href="@@ruURL" href="/" i18n="@@Russian">русский</button>
        </mat-menu>
      </span>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" opened="true">
      <mat-nav-list>
        <app-side-nav focus="createProject"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <mat-card appearance="outlined" class="app-content">
        <mat-card-content style="padding: 20px; background: #333;">
          <mat-card appearance="outlined">
            <mat-card-content>
              <h3 i18n="@@Step 1: Select a cover design">Step 1: Select a cover design</h3>
              <ng-container *ngIf="startupService.project && themeService.coverThemes">
                <div  class="sideAreaItem sideAreaItemHorizonalScrollingSmall coverTheme" (click)="setCoverTheme(coverTheme)" [ngClass]="startupService.project.cover.coverTheme.id === coverTheme.id ? 'selected' : ''" *ngFor="let coverTheme of themeService.coverThemes">
                  <h4>{{coverTheme.name | languageString }}</h4>
                  <img [src]="themeService.getCoverThemePreviewImageURL(coverTheme)" style="max-width: 130px; margin-top: -10px; background: white;">
                </div>
              </ng-container>
            </mat-card-content>
          </mat-card>

          <mat-card appearance="outlined">
            <mat-card-content>
              <h3 i18n="@@Step 2: Select an interior design">Step 2: Select an interior design</h3>
              <ng-container *ngIf="startupService.project && startupService.project.interior && startupService.project.interior.interiorTheme && startupService.project && themeService.interiorThemes && themeService.interiorThemes.length > 0">
                <div  class="sideAreaItem sideAreaItemHorizonalScrollingMedium interiorTheme" style="margin: 20px;" (click)="setInteriorThemeId(interiorTheme)" [style.fontWeight]="interiorTheme.name === startupService.project.interior.interiorTheme.name ? 'bold': 'normal'" [ngClass]="interiorTheme.name === startupService.project.interior.interiorTheme.name ? 'selected' : ''" *ngFor="let interiorTheme of themeService.interiorThemes">
                  <h4>{{interiorTheme.name | languageString }}</h4>
                  <figure class="zoom" style="max-width: 330px;" (mousemove)="zoom($event)" [style.backgroundImage]="'url(' + interiorTheme.getPreviewImageURL() +')'">
                    <img [src]="interiorTheme.getPreviewImageURL()" style="margin-top: -20px; margin-left: -20px; margin-right: -10px; margin-bottom: -10px; background: white;">
                  </figure>
                </div>
              </ng-container>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined">
            <mat-card-content >
              <h3 i18n="@@Step 3: Select a trim size">Step 3: Select a trim size</h3>
              <div  class="sideAreaItem sideAreaItemHorizonalScrollingSmall" (click)="setTrimSize(trimSize)" [ngClass]="( startupService.project.width === trimSize.width && startupService.project.height === trimSize.height ) ? 'selected' : ''" *ngFor="let trimSize of trimSizes">
                <h4>{{trimSize.name | languageString }}</h4>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined">
            <mat-card-content>
              <h3 i18n="@@Step 4: Enter your books title">Step 4: Enter your book's title</h3>
              <form *ngIf="project" (ngSubmit)="onSubmit()" #form="ngForm" class="rbform">
                <mat-form-field class="creditCardFullWidth">
                  <mat-label i18n="@@Name">Name</mat-label>
                  <input type="text" [(ngModel)]="project.name" name="name" i18n-placeholder="@@Project Name" placeholder="Project Name" matInput required [ngModelOptions]="{standalone: true}"/>
                </mat-form-field>
                <button type="submit" (click)="save()" mat-raised-button color="primary" [disabled]="!form.form.valid" i18n="@@Create project and get started">Create project and get started</button>
              </form>
            </mat-card-content>
          </mat-card>

        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
