<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Add Organization">Add Organization</h2>
  </div>
  <div>
    <form [formGroup]="addForm" (change)="formChanged()">
      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <input matInput placeholder="Organization Name" i18n-placeholder="@@Organization Name" value={{organizationName}} formControlName="organizationName" required>
              <mat-error *ngIf="addForm.controls.organizationName.hasError('required')" i18n="@@Please enter an organization name">Please enter an organization name</mat-error>
              <mat-error *ngIf="addForm.controls.organizationName.hasError('pattern')" i18n="@@Please enter a valid organization name">Please enter a valid organization name</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <input matInput placeholder="Name of Initial User" i18n-placeholder="@@Name of Initial User" value={{name}} formControlName="name" required>
              <mat-error *ngIf="addForm.controls.name.hasError('required')" i18n="@@Please enter the name of the initial user">Please enter the name of the initial user</mat-error>
              <mat-error *ngIf="addForm.controls.name.hasError('pattern')" i18n="@@Please enter a valid organization name">Please enter a valid organization name for the initial user</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
                <input matInput placeholder="Initial User Email Address" i18n-placeholder="@@Initial User Email Address" value={{email}} formControlName="email" required>
              <mat-error *ngIf="addForm.controls.email.hasError('required')" i18n="@@Please enter an email">Please enter an email</mat-error>
              <mat-error *ngIf="addForm.controls.email.hasError('email')" i18n="@@Please enter a valid email">Please enter a valid email</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput placeholder="Initial Phone Number" i18n-placeholder="@@Initial User Phone Number" value={{email}} formControlName="phone" required>
              <mat-error *ngIf="addForm.controls.phone.hasError('required')" i18n="@@Please enter a phone number">Please enter a phone number</mat-error>
              <mat-error *ngIf="addForm.controls.phone.hasError('email')" i18n="@@Please enter a valid phone number">Please enter a valid phone number</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn"  mat-dialog-close="" [disabled]="addForm.invalid" (click)="onAdd()" i18n="@@Add Organization">Add Organization</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
