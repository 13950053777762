import {InteriorThemeConfigurationPropertyType} from "./InteriorThemeConfigurationPropertyType";
import {InteriorThemeConfigurationSelector} from "./InteriorThemeConfigurationSelector";

export class InteriorThemeConfiguration {
  public propertiesTypes: any; // InteriorThemeConfigurationPropertyType[];
  public selectors: any; // InteriorThemeConfigurationSelector[];

  constructor( obj ) {
    this.propertiesTypes = {};
    if (obj ) {
      if ( obj.propertiesTypes ) {
        for ( const prop of Object.keys(obj.propertiesTypes) ) {
          this.propertiesTypes[prop] = new InteriorThemeConfigurationPropertyType(prop, obj.propertiesTypes[prop]);
        }
      }
    }
    this.selectors = {};
    if (obj ) {
      if ( obj.selectors ) {
        for ( const prop of Object.keys(obj.selectors) ) {
          this.selectors[prop] = new InteriorThemeConfigurationSelector(obj.selectors[prop].name, prop, obj.selectors[prop].propertiesType);
        }
      }
    }
  }
}
