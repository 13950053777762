export class ColorPaletteItem {
    public name: string;
    public color: string;
    public contrast: string;


  constructor( obj ) {
    this.name = '';
    this.color = '#cccccc';
    this.contrast = '#ffffff';
    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        this[prop] = obj[prop];
      }
    }
  }

}
