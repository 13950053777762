import { Injectable } from '@angular/core';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {PrintNodeOrderItem} from "../../model/PrintNodeOrderItem";
import {NotificationsService} from "./notifications.service";
import {AbstractLiveDataService} from "./abstract-live-data.service";

@Injectable()
export class PrintNodeOrderItemService extends AbstractLiveDataService<PrintNodeOrderItem> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setPrintNodeOrderItemService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): PrintNodeOrderItem {
    const object = new PrintNodeOrderItem(args, args['printNodeOrder']);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "PrintNodeOrderItem";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "printnodeorderitem";
  }

  getLinkData(): any[] {
    return [
      { action: 'Link', objectIdPropertyName: 'orderitem_ID', serviceName: 'orderItemService', arrayProperty: 'printNodeOrderItems', objectProperty: 'orderItem'},
      { action: 'Link', objectIdPropertyName: 'manufacturingset_id', serviceName: 'manufacturingSetService', arrayProperty: 'printNodeOrderItems', objectProperty: 'manufacturingSet'},
      { action: 'Link', objectIdPropertyName: 'printnodeorder_ID', serviceName: 'printNodeOrderService', arrayProperty: 'printNodeOrderItems', objectProperty: 'printNodeOrder'},
      { action: 'Link', objectIdPropertyName: 'book_ID', serviceName: 'bookService', arrayProperty: 'printNodeOrderItems', objectProperty: 'book'},
    ];
  }

  // linkObject(newObject: PrintNodeOrderItem, rawObject: PrintNodeOrderItem) {
  //   for ( const data of this.getLinkData())  {
  //     switch ( data.action ) {
  //       case 'Link': {
  //         if (rawObject[data.objectProperty]) {
  //           this.notificationsService.addUpdateLocalItem(data.serviceName, rawObject[data.objectProperty]);
  //         }
  //         if (newObject[data.objectIdPropertyName]) {
  //           this.notificationsService.addObjectAttachRequest(data.serviceName, new ObjectAttachRequest(newObject[data.objectIdPropertyName], data.arrayProperty, data.objectProperty, newObject));
  //         }
  //         break;
  //       }
  //       case 'AddUpdateArray': {
  //         if ( rawObject[data.arrayProperty] ) {
  //           for (const object of rawObject[data.arrayProperty]) {
  //             this.notificationsService.addUpdateLocalItem(data.arrayProperty, object);
  //           }
  //         }
  //         break;
  //       }
  //     }
  //   }
  //   // { action: 'AddUpdateArray', serviceName: 'manufacturingTaskService', arrayProperty: 'manufacturingTasks'},    // if (rawObject['orderItem']) {
  //   //   this.notificationsService.addUpdateLocalItem('orderItemService', rawObject['orderItem']);
  //   // }
  //   // if (newObject.orderitem_ID) {
  //   //   this.notificationsService.addObjectAttachRequest('orderItemService', new ObjectAttachRequest(newObject.orderitem_ID, 'printNodeOrderItems', 'orderItem', newObject));
  //   // }
  //   // if (rawObject.manufacturingSet) {
  //   //   this.notificationsService.addUpdateLocalItem('manufacturingSetService', rawObject.manufacturingSet);
  //   // }
  //   // if (newObject.manufacturingset_id) {
  //   //   this.notificationsService.addObjectAttachRequest('manufacturingSetService', new ObjectAttachRequest(newObject.manufacturingset_id, 'printNodeOrderItems', 'manufacturingSet', newObject));
  //   // }
  //   // if (rawObject.printNodeOrder) {
  //   //   this.notificationsService.addUpdateLocalItem('printNodeOrderService', rawObject.printNodeOrder);
  //   // }
  //   // if (newObject.printnodeorder_ID) {
  //   //   this.notificationsService.addObjectAttachRequest('printNodeOrderService', new ObjectAttachRequest(newObject.printnodeorder_ID, 'printNodeOrderItems', 'printNodeOrder', newObject));
  //   // }
  //   // if (rawObject.book) {
  //   //   this.notificationsService.addUpdateLocalItem('bookService', rawObject.book);
  //   // }
  //   // if (newObject.book_ID) {
  //   //   this.notificationsService.addObjectAttachRequest('bookService', new ObjectAttachRequest(newObject.book_ID, 'printNodeOrderItems', 'book', newObject));
  //   // }
  // }
  //
  // const xxx =  [
  //   { action: 'AddUpdateArray', serviceName: 'printNodeOrderItemService', arrayProperty: 'printNodeOrderItems'},
  //   { action: 'Link', objectIdPropertyName: 'manufacturingTaskBucketPeriod_id', serviceName: 'manufacturingTaskBucketPeriodService', arrayProperty: 'manufacturingSets', objectProperty: 'manufacturingTaskBucketPeriod'},
  //   { action: 'AddUpdateArray', serviceName: 'manufacturingTaskService', arrayProperty: 'manufacturingTasks'},
  // ];
  //
  // updateLinks(existingObject: PrintNodeOrderItem, newObject: PrintNodeOrderItem, rawObject: PrintNodeOrderItem) {
  //   // if ( existingObject && newObject && rawObject ) {
  //     for ( const data of this.getLinkData())  {
  //     switch ( data.action ) {
  //       case 'Link': {
  //         if ( rawObject[data.objectProperty] ) {
  //           this.notificationsService.addUpdateLocalItem('manufacturingTaskBucketPeriodService', rawObject[data.objectProperty]);
  //         }
  //         if ( newObject[data.objectIdPropertyName] ) {
  //           if ( existingObject[data.objectIdPropertyName] !== newObject[data.objectIdPropertyName] ) {
  //             if ( existingObject[data.objectProperty] && existingObject[data.objectProperty][data.arrayProperty] ) {
  //               const index = existingObject[data.objectProperty][data.arrayProperty].indexOf(existingObject, 0);
  //               if (index > -1) {
  //                 existingObject[data.objectProperty][data.arrayProperty].splice(index, 1);
  //               }
  //             }
  //             delete existingObject[data.objectProperty];
  //             existingObject[data.objectIdPropertyName] = newObject[data.objectIdPropertyName];
  //             this.notificationsService.addObjectAttachRequest(data.serviceName, new ObjectAttachRequest(existingObject[data.objectIdPropertyName], data.arrayProperty, data.objectProperty, existingObject));
  //           }
  //         }
  //         break;
  //       }
  //       case 'AddUpdateArray': {
  //         if ( rawObject[data.arrayProperty] ) {
  //           for (const object of rawObject[data.arrayProperty]) {
  //             this.notificationsService.addUpdateLocalItem(data.arrayProperty, object);
  //           }
  //         }
  //         break;
  //       }
  //     }
  //   }
  // }

}
