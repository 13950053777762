import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {Sort} from '@angular/material/sort';
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {PrintNodeOrderItem} from "../../../model/PrintNodeOrderItem";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import {Subject} from "rxjs";

export interface Dessert {
  calories: number;
  carbs: number;
  fat: number;
  name: string;
  protein: number;
}
@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.css']
})

export class OrderItemsComponent implements OnInit, OnDestroy, LoggedInCallback {
  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  selectedPrintNodeOrder: PrintNodeOrder;
  selectedPrintNodeOrderItem: PrintNodeOrderItem;
  printNodeOrders: PrintNodeOrder[];
  printNodeOrderItems: PrintNodeOrderItem[];

  showLanguagesMenu = false;

  sortedData: PrintNodeOrderItem[];




  constructor(public router: Router, public startupService: StartupService, private printNodeOrderService: PrintNodeOrderService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    console.log("SecureHomeComponent: constructor");
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    // this.sortedData = this.desserts.slice();
  }

  ngOnInit() {
    console.log("AppComponent: Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Projects");
      this.startupService.logout();
    } else {
      this.getPrintNodeOrders();
    }
  }

  onSelectPrintNodeOrderItem(printNodeOrderItem: PrintNodeOrderItem): void {
    // this.selectedPrintNodeOrder = printNodeOrder;
    this.selectedPrintNodeOrderItem = printNodeOrderItem;
  }

  getPrintNodeOrders(): void {
    this.printNodeOrderService.getItems()
      .subscribe(printNodeOrders => {
        this.printNodeOrders = printNodeOrders;
        this.printNodeOrderItems = [];
        for ( const printNodeOrder of this.printNodeOrders ) {
          for ( const printNodeOrderItem of printNodeOrder.printNodeOrderItems ) {
            this.printNodeOrderItems.push(printNodeOrderItem);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }



  sortData(sort: Sort) {
    const data = this.printNodeOrderItems.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a: PrintNodeOrderItem, b: PrintNodeOrderItem) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title': return compare(a.quantity, b.quantity, isAsc);
        case 'bindingType': return compare(a.book.bindingType, b.book.bindingType, isAsc);
        // case 'fat': return compare(a.fat, b.fat, isAsc);
        // case 'carbs': return compare(a.carbs, b.carbs, isAsc);
        // case 'protein': return compare(a.protein, b.protein, isAsc);
        default: return 0;
      }
    });
  }
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
