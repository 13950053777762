import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {OrderItemToPackage} from "../../model/OrderItemToPackage";
import {NotificationsService} from "./notifications.service";
import {AbstractLiveDataService} from "./abstract-live-data.service";

@Injectable()
export class OrderItemToPackageService extends AbstractLiveDataService<OrderItemToPackage> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setOrderItemToPackageService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): OrderItemToPackage {
    const object = new OrderItemToPackage(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "OrderItemToPackage";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "package";
  }

  getLinkData(): any[] {
    return [
      { action: 'Link', objectIdPropertyName: 'package_ID', serviceName: 'packageService', arrayProperty: 'orderItemToPackages', objectProperty: 'package'},
      { action: 'Link', objectIdPropertyName: 'orderitem_ID', serviceName: 'orderItemService', arrayProperty: 'orderItemToPackages', objectProperty: 'orderItem'},
    ];
  }
}
