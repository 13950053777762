import { Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../pipe/language.pipe";
import {UserService} from "../../../service-data/user.service";
import {Organization} from "../../../../model/Organization";
import {OrganizationService} from "../../../service-data/organization.service";
import {ServiceProviderService} from "../../../service-data/service-provider.service";
import {Umbrella} from "../../../../model/Umbrella";
import {ServiceProvider} from "../../../../model/ServiceProvider";

@Component({
  selector: 'app-add-service-provider',
  templateUrl: './add-service-provider.component.html',
  styleUrls: ['./add-service-provider.component.css']
})
export class AddServiceProviderComponent implements OnInit {
  public breakpoint: number; // Breakpoint observer code
  public org_ID: number;
  public name: string = ``;
  public domain: string = ``;
  public addForm: FormGroup;
  wasFormChanged = false;
  organizations: Organization[];

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public userService: UserService, public organizationService: OrganizationService, public serviceProviderService: ServiceProviderService) {
  }

  public ngOnInit(): void {
    this.organizationService.getItems()
      .subscribe(organizations => {
        this.organizations = organizations;
      });
    this.addForm = this.formBuilder.group({
      org_ID: [this.org_ID, [Validators.required, Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      domain: [this.domain, [Validators.required, Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9. ]+)*')]],
      name: [this.name, [Validators.required, Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]]
    });
    // this.addForm.controls.phoneCountry.validator = PhoneValidator.validCountryPhone(this.addForm.controls.phoneCountry);

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onAdd(): void {
    const objectToAdd = new ServiceProvider( undefined );
    objectToAdd.adminOrg_ID = this.addForm.controls.organization.value.ID;
    objectToAdd.Name = this.addForm.controls.name.value;
    objectToAdd.domain = this.addForm.controls.domain.value;
    this.serviceProviderService.addServiceProvider(objectToAdd).subscribe( addResult => {
      console.log("Added service provider");
    });
    this.markAsDirty(this.addForm);
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
