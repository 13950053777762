import {FacilityInventoryProductEvent} from "./FacilityInventoryProductEvent";
import {Facility} from "./Facility";
import {Product} from "./Product";
import {DataObject} from "./DataObject";

export class FacilityInventoryProduct implements DataObject {
  public ID: number;
  public product_ID: number;
  public printnode_ID: number;
  public lastUpdate: Date;
  public location: string;
  public shelf: string;
  public quantityOnHand: number;
  public quantityCommited: number;
  public quantityOnOrder: number;
  public caseQuantity: number;
  public state: string;
  public inventoryManagementType: string; //    enum: ["CUSTOMER", "LEVEL"],
  public title: string;

  public timeline: string;
  public facilityInventoryProductEvents: FacilityInventoryProductEvent[];
  public printNode: Facility;
  public product: Product;
  public productIds: string;
  public sequence: number;
  public reorderQuantity: number;
  public reorderLevel: number;
  public note: string;

  constructor( obj ) {
    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);

    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        // if ( prop === 'product') {
        //   this.product = new Product(obj.product);
        // } else
        if ( prop === 'printNode') {
          this.printNode = new Facility(obj.printNode);
        } else {
//          this[prop] = obj[prop];
        }
      }
      this.facilityInventoryProductEvents = [];
      if ( obj.facilityInventoryProductEvents ) {
        for ( const facilityInventoryProductEvent of obj.facilityInventoryProductEvents ) {
          this.facilityInventoryProductEvents.push( new FacilityInventoryProductEvent( facilityInventoryProductEvent, this ) );
        }
      }
    }
  }

  public getTimelineData(): Timeline {
    return new Timeline(this.timeline);
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}

export class Timeline {
  public replenishmentOrdered: number[] = [];
  public replenishmentActual: number[] = [];
  public fulfillmentOrdered: number[] = [];
  public fulfillmentActual: number[] = [];

  constructor( obj ) {

    const startOfTime = this.getStartOfTime();

    const today = new Date();
    const todayDay = this.dateDiff(today, startOfTime);
    if (obj) {
      const x = JSON.parse(obj);
      if (x) {
        if (!x.replenishmentOrdered) {
          x.replenishmentOrdered = [];
          for (let i = 0; i < todayDay + 180; i++) {
            x.replenishmentOrdered[i] = 0;
          }
        }
        if (!x.replenishmentActual) {
          x.replenishmentActual = [];
          for (let i = 0; i < todayDay + 180; i++) {
            x.replenishmentActual[i] = 0;
          }
        }
        if (!x.fulfillmentOrdered) {
          x.fulfillmentOrdered = [];
          for (let i = 0; i < todayDay + 180; i++) {
            x.fulfillmentOrdered[i] = 0;
          }
        }
        if (!x.fulfillmentActual) {
          x.fulfillmentActual = [];
          for (let i = 0; i < todayDay + 180; i++) {
            x.fulfillmentActual[i] = 0;
          }
        }
      }
      this.replenishmentOrdered = x.replenishmentOrdered;
      this.replenishmentActual = x.replenishmentActual;
      this.fulfillmentOrdered = x.fulfillmentOrdered;
      this.fulfillmentActual = x.fulfillmentActual;
    } else {
      this.replenishmentOrdered = [];
      this.replenishmentActual = [];
      this.fulfillmentOrdered = [];
      this.fulfillmentActual = [];
      for (let i = 0; i < todayDay + 180; i++) {
        this.replenishmentOrdered[i] = 0;
        this.replenishmentActual[i] = 0;
        this.fulfillmentOrdered[i] = 0;
        this.fulfillmentActual[i] = 0;
      }
    }
  }

  private dateDiff(one: Date, two: Date): number {
    const diff = one.getTime() - two.getTime();
    return Number(Math.max(0, Math.ceil(diff / (1000 * 3600 * 24))));
  }

  getStartOfTime () {
    const startOfTime = new Date();
    startOfTime.setUTCFullYear(2020, 6, 1);
    return startOfTime;
  }
}
