import {Font} from "../font/Font";

export class FontSettings {
  public id: string;
  public primary: string;
  public secondary: string;
  public tertiary: string;
  public accent: string;

  constructor( obj ) {
    this.primary = 'Arial';
    this.secondary = 'Arial';
    this.tertiary = 'Arial';
    this.accent = 'Arial';
    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        this[prop] = obj[prop];
      }
    }

    if ( !this.primary || this.primary.length === 0 ) {
      this.primary = 'Arial';
    }
    if ( !this.secondary || this.secondary.length === 0 ) {
      this.secondary = this.primary;
    }
    if ( !this.tertiary || this.tertiary.length === 0 ) {
      this.tertiary = this.primary;
    }
    if ( !this.accent || this.accent.length === 0 ) {
      this.accent = this.primary;
    }
    if ( !this.id ) {
      this.id = Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16);
    }
  }

  // Gets a value as a simple string but if inside <> it looks up the value as a variable
  getFontFromKey(simpleValue: string, defaultKey: string) {
    // if ( !simpleValue || ( simpleValue && simpleValue.length === 0 ) ) {
    //   return defaultKey;
    // }
    if ( simpleValue.startsWith('<') && simpleValue.endsWith('>') ) {
      const key = simpleValue.substring(1, simpleValue.length - 1 );
      switch ( key ) {
        case 'primary':
        {
          return this.primary;
        }
        case 'secondary':
        {
          return this.secondary;
        }
        case 'tertiary':
        {
          return this.tertiary;
        }
        case 'accent':
        {
          return this.accent;
        }
        default:
        {
          // console.error("Returning default from FontSettings.getFontFromKey:" + key);
          return 'Arial';
        }
      }
    }
    return simpleValue;
  }

  getFontsAsArray (): string[] {
    const fonts = [];
    if ( this.primary && this.primary.length > 0 && this.primary !== 'undefined') {
      fonts.push(this.primary);
    }
    if (this.secondary && this.secondary.length > 0 && this.secondary !== 'undefined') {
      fonts.push(this.secondary);
    }
    if (this.tertiary && this.tertiary.length > 0 && this.tertiary !== 'undefined') {
      fonts.push(this.tertiary);
    }
    if (this.accent && this.accent.length > 0 && this.accent !== 'undefined') {
      fonts.push(this.accent);
    }
    return fonts;
  }


}

