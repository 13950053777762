<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <ng-container *ngIf="!mobileQuery.matches"><span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Manufacturing Tasks">Manufacturing Tasks</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Manufacturing Tasks">Manufacturing Tasks</span></ng-container>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
    <div>
      <button (click)="hideUseType['BodyPrint'] = !hideUseType['BodyPrint']" mat-icon-button color="primary"><mat-icon *ngIf="!hideUseType['BodyPrint']" i18n-title="@@Hide Print Body" title="Hide Print Body" color="accent">library_books</mat-icon><mat-icon *ngIf="hideUseType['BodyPrint']" i18n-title="@@Show Print Body" title="Show Print Body" style="color: lightgreen">library_books</mat-icon></button>
      <button (click)="hideUseType['CutApart'] = !hideUseType['CutApart']" mat-icon-button color="primary"><mat-icon *ngIf="!hideUseType['CutApart']" i18n-title="@@Hide Cut Apart" title="Hide Cut Apart" color="accent">content_cut</mat-icon><mat-icon *ngIf="hideUseType['CutApart']" i18n-title="@@Show Cut Apart" title="Show Cut Apart" style="color: lightgreen">content_cut</mat-icon></button>
      <button (click)="hideUseType['CoverPrint'] = !hideUseType['CoverPrint']" mat-icon-button color="primary"><mat-icon *ngIf="!hideUseType['CoverPrint']" i18n-title="@@Hide Print Cover" title="Hide Print Cover" color="accent">menu_book</mat-icon><mat-icon *ngIf="hideUseType['CoverPrint']" i18n-title="@@Show Print Cover" title="Show Print Cover" style="color: lightgreen">menu_book</mat-icon></button>
      <button (click)="hideUseType['Laminate'] = !hideUseType['Laminate']" mat-icon-button color="primary"><mat-icon *ngIf="!hideUseType['Laminate']" i18n-title="@@Hide Laminate" title="Hide Laminate" color="accent">density_large</mat-icon><mat-icon *ngIf="hideUseType['Laminate']" i18n-title="@@Show Laminate" title="Show Laminate" style="color: lightgreen">density_large</mat-icon></button>
      <button (click)="hideUseType['Bind'] = !hideUseType['Bind']" mat-icon-button color="primary"><mat-icon *ngIf="!hideUseType['Bind']" i18n-title="@@Hide Bind" title="Hide Bind" color="accent">view_day</mat-icon><mat-icon *ngIf="hideUseType['Bind']" i18n-title="@@Show Trim" title="Show Bind" style="color: lightgreen">view_day</mat-icon></button>
      <button (click)="hideUseType['Trim'] = !hideUseType['Trim']" mat-icon-button color="primary"><mat-icon *ngIf="!hideUseType['Trim']" i18n-title="@@Hide Trim" title="Hide Trim" color="accent">select_all</mat-icon><mat-icon *ngIf="hideUseType['Trim']" i18n-title="@@Show Trim" title="Show Trim" style="color: lightgreen">select_all</mat-icon></button>
      <button (click)="hideUseType['Pack'] = !hideUseType['Pack']" mat-icon-button color="primary"><mat-icon *ngIf="!hideUseType['Pack']" i18n-title="@@Hide Pack" title="Hide Pack" color="accent">local_post_office</mat-icon><mat-icon *ngIf="hideUseType['Pack']" i18n-title="@@Show Pack" title="Show Pack" style="color: lightgreen">local_post_office</mat-icon></button>
      <button (click)="hideUseType['Ship'] = !hideUseType['Ship']" mat-icon-button color="primary"><mat-icon *ngIf="!hideUseType['Ship']" i18n-title="@@Hide Ship" title="Hide Ship" color="accent">local_shipping</mat-icon><mat-icon *ngIf="hideUseType['Ship']" i18n-title="@@Show Ship" title="Show Ship" style="color: lightgreen">local_shipping</mat-icon></button>

<!--      <button (click)="showEmptySets = !showEmptySets" mat-icon-button color="primary"><mat-icon *ngIf="!showEmptySets" i18n-title="@@Hide Empty Sets" title="Hide Empty Sets" color="accent">space_bar</mat-icon><mat-icon *ngIf="showEmptySets" i18n-title="@@Show Empty Sets" title="Show Empty Sets" style="color: yellow">space_bar</mat-icon></button>-->
<!--      <button (click)="showClosedSets = !showClosedSets" mat-icon-button color="primary"><mat-icon *ngIf="!showClosedSets" i18n-title="@@Hide Closed Sets" title="Hide Closed Sets" color="accent">curtains_closed</mat-icon><mat-icon *ngIf="showClosedSets" i18n-title="@@Show Closed Sets" title="Show Closed Sets" style="color: yellow">curtains_closed</mat-icon></button>-->
<!--      <button (click)="showOnlyPinned = !showLockedSets" mat-icon-button color="primary"><mat-icon *ngIf="!showLockedSets" i18n-title="@@Hide Locked Sets" title="Hide Locked Sets" color="accent">screen_lock_portrait</mat-icon><mat-icon *ngIf="showLockedSets" i18n-title="@@Show Locked Sets" title="Show Locked Sets" style="color: yellow">screen_lock_portrait</mat-icon></button>-->
<!--      <button (click)="showOpenSets = !showOpenSets" mat-icon-button color="primary"><mat-icon *ngIf="!showOpenSets" i18n-title="@@Hide Open Sets" title="Hide Open Sets" color="accent">folder_open</mat-icon><mat-icon *ngIf="showOpenSets" i18n-title="@@Show Open Sets" title="Show Open Sets" style="color: yellow">folder_open</mat-icon></button>-->
<!--      <button (click)="showOnlyPinned = !showOnlyPinned" mat-icon-button color="primary"><mat-icon *ngIf="!showOnlyPinned" i18n-title="@@Show Pinned and Unpinned" title="Snow Pinned and Unpinned" color="accent">push_pin</mat-icon><mat-icon *ngIf="showOnlyPinned" i18n-title="@@Show Only Pinned" title="Snow Only Pinned" style="color: yellow">push_pin</mat-icon></button>-->
    </div>
    <span class="app-toolbar-filler"></span>
    <div>
      <button (click)="showPrintNodeOrderItems = !showPrintNodeOrderItems" mat-icon-button color="primary"><mat-icon *ngIf="!showPrintNodeOrderItems" i18n-title="@@Show Print Node Order Items" title="Show Print Node Order Items" color="accent">format_list_bulleted</mat-icon><mat-icon *ngIf="showPrintNodeOrderItems" i18n-title="@@Hide Print Node Order Items" title="Hide Print Node Order Items" style="color: yellow">format_list_bulleted</mat-icon></button>
<!--      <span class="app-toolbar-filler"></span>-->
<!--      <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
    </div>
    <span class="app-toolbar-filler"></span>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="tasks"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <div class="app-content" style="display: flex; flex-wrap: wrap; flex-direction: row;">
        <mat-card appearance="outlined" style="width: 100%; height: auto; overflow-y: auto; display: block;" >
          <mat-card-content>
            <div class="board">
              <!-- Each ManufacturingSet -->
              <ng-container *ngFor="let manufacturingSet of manufacturingSets">
                <ng-container *ngIf="manufacturingSet.printNodeOrderItems && manufacturingSet.printNodeOrderItems.length > 0">
                  <ng-container *ngFor="let manufacturingTask of manufacturingSet.nextManufacturingTasks">
                    <div style="width: 540px" class="manufacturingSet card-list mat-elevation-z1" *ngIf="!(hideUseType[manufacturingTask.useType])" [style.background-color]="useTypeColors[manufacturingTask.useType]">
                      <h7 style="display: inline-block; width: 280px">
                        <span><mat-icon>{{useTypeIcons[manufacturingTask.useType]}}</mat-icon></span><span>{{manufacturingSet.ID}}</span><span>{{manufacturingTask.useType}}</span>
                        <ng-container *ngFor="let manufacturingTask of manufacturingSet.manufacturingTasks"><button  style="display: inline-block; width: 10px; height: 10px;" [title]="manufacturingTask.useType" [style.background-color]="manufacturingSet.status === 'COMPLETED' ? manufacturingTaskStatusColors['COMPLETED'] : manufacturingTaskStatusColors[manufacturingTask.status]"></button></ng-container>
                      </h7>
                      <h7>
                        <button  *ngIf="canReprint(manufacturingTask)" mat-icon-button i18n-title="@@Reprint All In Set" title="Reprint All In Set" class="floatingButton" (click)="reprint(manufacturingTask, 'lookup')" color="warn"><mat-icon>print</mat-icon></button>
                        <ng-container [ngSwitch]="manufacturingTask.status">
                          <ng-container *ngSwitchCase="'INITIAL'">
                            <button *ngIf="canStart(manufacturingTask)" mat-icon-button i18n-title="@@Start" title="Start" class="floatingButton" (click)="start(manufacturingTask, 'lookup')" color="primary"><mat-icon>play</mat-icon></button>
                            <button *ngIf="!canStart(manufacturingTask)" mat-icon-button i18n-title="@@Start" title="Start" class="floatingButton" style="color: grey" ><mat-icon>play</mat-icon></button>
                            <button *ngIf="canCompleteWithoutStarting(manufacturingTask)" mat-icon-button i18n-title="@@Complete" title="Complete" class="floatingButton" (click)="complete(manufacturingTask)" color="primary"><mat-icon>check</mat-icon></button>
                          </ng-container>
                          <ng-container *ngSwitchCase="'STARTED'">
                            <button mat-icon-button i18n-title="@@Start" title="Start" class="floatingButton" (click)="pause(manufacturingTask)" color="primary"><mat-icon>pause</mat-icon></button>
                            <button mat-icon-button i18n-title="@@Reset" title="Reset" class="floatingButton" (click)="reset(manufacturingTask)" color="primary"><mat-icon>undo</mat-icon></button>
                            <button mat-icon-button i18n-title="@@Complete" title="Complete" class="floatingButton" (click)="complete(manufacturingTask)" style="color: grey" ><mat-icon>check</mat-icon></button>
                          </ng-container>
                          <ng-container *ngSwitchCase="'PAUSED'">
                            <button mat-icon-button i18n-title="@@Start" title="Start" class="floatingButton" (click)="continue(manufacturingTask)" color="primary"><mat-icon>play</mat-icon></button>
                            <button mat-icon-button i18n-title="@@Reset" title="Reset" class="floatingButton" (click)="reset(manufacturingTask)" color="primary"><mat-icon>undo</mat-icon></button>
                            <button mat-icon-button i18n-title="@@Complete" title="Complete" class="floatingButton" (click)="complete(manufacturingTask)" style="color: grey" ><mat-icon>check</mat-icon></button>
                            <button *ngIf="canCompleteWithoutStarting(manufacturingTask)" mat-icon-button i18n-title="@@Complete" title="Complete" class="floatingButton" (click)="complete(manufacturingTask)" color="primary"><mat-icon>check</mat-icon></button>
                          </ng-container>
                          <ng-container *ngSwitchCase="'COMPLETED'">
                            <button mat-icon-button i18n-title="@@Reset" title="Reset" class="floatingButton" (click)="reset(manufacturingTask)" color="primary"><mat-icon>undo</mat-icon></button>
                          </ng-container>
                          <ng-container *ngSwitchCase="'ERROR'">
                            <button mat-icon-button i18n-title="@@Reset" title="Reset" class="floatingButton" (click)="reset(manufacturingTask)" color="primary"><mat-icon>undo</mat-icon></button>
                          </ng-container>
                        </ng-container>
                         <button mat-icon-button class="floatingButton" (click)="expandSets['' + manufacturingSet.ID] = !expandSets['' + manufacturingSet.ID]" color="primary"><mat-icon *ngIf="expandSets['' + manufacturingSet.ID]">expand_more</mat-icon><mat-icon *ngIf="!expandSets['' + manufacturingSet.ID]">expand_less</mat-icon></button>
                      </h7>
                      <!-- Stats for ManufacturingSet -->
    <!--                          <div *ngIf="showStats" class="columnArea">-->
    <!--                            <div *ngFor="let type of getTypes()" class="oneColumn">-->
    <!--                              <app-stacked-column [categories]="getCategories(manufacturingSet, type)" [(filter)]="manufacturingSet['filter']" [data]="getCategoryData(manufacturingSet, type, dataIndex)" [colors]="getColor(type)" [icons]="getIcons(type)" [total]="manufacturingSet['totals'][dataIndex]" totalHeight="100" [type]="type" [units]="dataIndex === 0 ? 'books' : dataIndex === 1 ? 'sheets' : 'line items'"></app-stacked-column>-->
    <!--                            </div>-->
    <!--                          </div>-->
  <!--                    <div class="columnArea" *ngIf="expandSetsPackages['' + manufacturingSet.ID]">-->
  <!--                      <div class="packages card-list-content">-->
  <!--                        <h7>Coming Soon</h7>-->
  <!--                        <mat-card *ngFor="let package of packagesByManufacturingSets['' + manufacturingSet.ID ]">-->
  <!--                          <mat-card-content style="padding: 2px 2px 4px 6px">{{package.ID}}-->
  <!--                          </mat-card-content>-->
  <!--                        </mat-card>-->
  <!--                      </div>-->
  <!--                    </div>-->
                      <div class="columnArea" *ngIf="expandSets['' + manufacturingSet.ID]">
                        <!-- PrintNodeOrderItems in ManufacturingSet -->
                        <div *ngIf="showPrintNodeOrderItems" class="printNodeOrderItems card-list-content">
                          <mat-card appearance="outlined" *ngFor="let printNodeOrderItem of manufacturingSet.printNodeOrderItems"  [style.border]="printNodeOrderItem.type === 'REPRINT' ? 'solid 5px blue' : (printNodeOrderItem.type === 'PARTIAL' ? 'solid 5px red' : '')">
                            <mat-card-content style="padding: 2px 2px 4px 6px">
                              <app-print-node-order-item-in-manufacturing-set [printNodeOrderItem]="printNodeOrderItem" [manufacturingSet]="manufacturingSet"></app-print-node-order-item-in-manufacturing-set>
                            </mat-card-content>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






