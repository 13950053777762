import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {PrintNodeOrderItem} from "../../model/PrintNodeOrderItem";
import {PrintNodeOrder} from "../../model/PrintNodeOrder";
import {NotificationsService} from "./notifications.service";
import {ManufacturingTaskBucketPeriod} from "../../model/ManufacturingTaskBucketPeriod";
import {ManufacturingTaskBucket} from "../../model/ManufacturingTaskBucket";
import {PrintNodeOrderService} from "./print-node-order.service";

@Injectable()
export class ManufacturingSetAssignerService {

  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService, private printNodeOrderService: PrintNodeOrderService, private notificationsService: NotificationsService) {
  }

  getURLEndPoint (): string {
    return "manufacturingsetassigner";
  }

  /** PUT: move PrintNodeOrderItem to a specific ManufacturingTaskBucketPeriod */
  movePrintNodeOrderItemToManufacturingSet (printNodeOrderItemId: number, manufacturingSetId: number): Observable<PrintNodeOrderItem> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/moveprintnodeorderitemtomanufacturingset/${printNodeOrderItemId}/${manufacturingSetId}`;
    this.messageService.add(`PrintNodeOrderService: movePrintNodeOrderItemToManufacturingSet manufacturingSetId=${manufacturingSetId}  printNodeOrderItemId=${printNodeOrderItemId}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(printNodeOrder => {
          const p = new PrintNodeOrder(printNodeOrder);
          const localPrintNodeOrder = this.printNodeOrderService.getLocalItem(p.ID);
          if ( localPrintNodeOrder ) {
            return this.printNodeOrderService.addUpdateLocalItem(p);
          } else {
            return this.printNodeOrderService.addUpdateLocalItem(p);
          }
        }),
        catchError(this.handleErrorX<any>('movePrintNodeOrderItemToManufacturingSet'))
      );
  }

  /** PUT: move PrintNodeOrderItem to a specific ManufacturingTaskBucketPeriod */
  movePrintNodeOrderItemToManufacturingTaskBucketPeriod (printNodeOrderItemId: number, manufacturingTaskBucketPeriodId: number): Observable<PrintNodeOrderItem> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/moveprintnodeorderitemtomanufacturingtaskbucketperiod/${printNodeOrderItemId}/${manufacturingTaskBucketPeriodId}`;
    this.messageService.add(`PrintNodeOrderService: movePrintNodeOrderItemToManufacturingTaskBucketPeriod manufacturingTaskBucketPeriodId=${manufacturingTaskBucketPeriodId}  printNodeOrderItemId=${printNodeOrderItemId}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingTaskBucketPeriod => {
          const m = new ManufacturingTaskBucketPeriod(manufacturingTaskBucketPeriod);
          return m;
        }),
        catchError(this.handleErrorX<any>('movePrintNodeOrder'))
      );
  }

  /** PUT: move PrintNodeOrderItem to a specific ManufacturingTaskBucketPeriod */
  movePrintNodeOrderItemToNextManufacturingTaskBucketPeriod (printNodeOrderItemId: number, manufacturingTaskBucketPeriodId: number): Observable<PrintNodeOrderItem> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/moveprintnodeordertonextmanufacturingtaskbucketperiod/${printNodeOrderItemId}/${manufacturingTaskBucketPeriodId}`;
    this.messageService.add(`PrintNodeOrderService: movePrintNodeOrderItemToNextManufacturingTaskBucketPeriod manufacturingTaskBucketPeriodId=${manufacturingTaskBucketPeriodId}  printNodeOrderItemId=${printNodeOrderItemId}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingTaskBucketPeriod => {
          const m = new ManufacturingTaskBucketPeriod(manufacturingTaskBucketPeriod);
          return m;
        }),
        catchError(this.handleErrorX<any>('movePrintNodeOrder'))
      );
  }

  /** PUT: create a new ManufacturingSet in the specified ManufacturingTaskBucketPeriod and move PrintNodeOrderItem to it */
  addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingTaskBucket (printNodeOrderItemId: number, manufacturingTaskBucketId: number): Observable<PrintNodeOrderItem> {
     const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/addmanufacturingsetandassignprintnodeorderitemtomanufacturingtaskbucket/${printNodeOrderItemId}/${manufacturingTaskBucketId}`;
     this.messageService.add(`PrintNodeOrderService: addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingTaskBucket manufacturingTaskBucketId=${manufacturingTaskBucketId}  printNodeOrderItemId=${printNodeOrderItemId}`);
     return this.http.put(url, {}, this.startupService.getHttpOptions())
        .pipe(
           map(manufacturingTaskBucket => {
              const m = new ManufacturingTaskBucket(manufacturingTaskBucket);
              return m;
           }),
           catchError(this.handleErrorX<any>('movePrintNodeOrder'))
     );
  }

  addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingSet (printNodeOrderItemId: number, manufacturingTaskBucketPeriodId: number): Observable<PrintNodeOrderItem> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/addmanufacturingsetandassignprintnodeorderitemtomanufacturingset/${printNodeOrderItemId}/${manufacturingTaskBucketPeriodId}`;
    this.messageService.add(`PrintNodeOrderService: addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingSet manufacturingTaskBucketPeriodId=${manufacturingTaskBucketPeriodId}  printNodeOrderItemId=${printNodeOrderItemId}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingTaskBucketPeriod => {
          const m = new ManufacturingTaskBucketPeriod(manufacturingTaskBucketPeriod);
          return m;
        }),
        catchError(this.handleErrorX<any>('movePrintNodeOrder'))
      );
    }

  changeFacility (printNodeOrder: PrintNodeOrder) {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/changefacility/${printNodeOrder.ID}/${printNodeOrder.printnode_ID}`;
    this.messageService.add(`PrintNodeOrderService: change facility printNodeOrder id=${printNodeOrder.ID} new facility ${printNodeOrder.printnode_ID}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions()).
    pipe(tap(_ => this.log(`updated printNodeOrder id=${printNodeOrder.ID}`)), catchError(this.handleErrorX<any>('updated address in PrintNodeOrder')));
  }

  changeFacilityAndInventoryType (printNodeOrder: PrintNodeOrder) {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/changefacilityandinventorytype/${printNodeOrder.ID}/${printNodeOrder.printnode_ID}/${printNodeOrder.inventoryType}`;
    this.messageService.add(`PrintNodeOrderService: change facility and inventorytype printNodeOrder id=${printNodeOrder.ID} new facility ${printNodeOrder.printnode_ID} new inventoryType ${printNodeOrder.inventoryType}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions()).
    pipe(tap(_ => this.log(`updated printNodeOrder id=${printNodeOrder.ID}`)), catchError(this.handleErrorX<any>('updated address in PrintNodeOrder')));
  }

  /** PUT: move PrintNodeOrderItem to a specific ManufacturingTaskBucket */
  movePrintNodeOrderToManufacturingTaskBucket (printNodeOrderId: number, manufacturingTaskBucketId: number): Observable<ManufacturingTaskBucket> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/moveprintnodeordertomanufacturingtaskbucket/${printNodeOrderId}/${manufacturingTaskBucketId}`;
    this.messageService.add(`PrintNodeOrderService: movePrintNodeOrderToManufacturingTaskBucket manufacturingTaskBucketId=${manufacturingTaskBucketId}  printNodeOrderId=${printNodeOrderId}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingTaskBucket => {
          const m = new ManufacturingTaskBucket(manufacturingTaskBucket);
          return m;
        }),
        catchError(this.handleErrorX<any>('movePrintNodeOrder'))
      );
  }

  /** PUT: move PrintNodeOrderItem to a specific ManufacturingTaskBucketPeriod */
  movePrintNodeOrderToManufacturingTaskBucketPeriod (printNodeOrderId: number, manufacturingTaskBucketPeriodId: number): Observable<ManufacturingTaskBucketPeriod> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/moveprintnodeordertomanufacturingtaskbucketperiod/${printNodeOrderId}/${manufacturingTaskBucketPeriodId}`;
    this.messageService.add(`PrintNodeOrderService: movePrintNodeOrderToManufacturingTaskBucketPeriod manufacturingTaskBucketPeriodId=${manufacturingTaskBucketPeriodId}  printNodeOrderId=${printNodeOrderId}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingTaskBucketPeriod => {
          const m = new ManufacturingTaskBucketPeriod(manufacturingTaskBucketPeriod);
          return m;
        }),
        catchError(this.handleErrorX<any>('movePrintNodeOrder'))
      );
  }

  private handleErrorX<T> (role: string, operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  protected log(message: string) {
    this.messageService.add(`ManufacturingSet Assigner Service:` + message);
  }

}
