// import 'hammerjs';
import {enableProdMode, LOCALE_ID, TRANSLATIONS, TRANSLATIONS_FORMAT} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {WindowRef} from './app/WindowRef';


// getTranslationProviders().then(providers => {
//   const options = { providers };
//   platformBrowserDynamic().bootstrapModule(AppModule, options);
// });

if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));


// let locale;
// if (localStorage.getItem('localeId') !== null) {
//   locale = localStorage.getItem('localeId');
// } else {
//   locale = null;
// }
// console.log('reading locale:' + locale);
//
// // use the require method provided by webpack
// declare const require;
// let translations;
// switch ( locale ) {
//   case 'fr': {
//     translations = require(`raw-loader!./locale/messages.fr.xtb`);
//     console.log('using locale file:' + locale);
//     break;
//   }
//   case 'es': {
//     translations = require(`raw-loader!./locale/messages.es.xtb`);
//     console.log('using locale file:' + locale);
//     break;
//   }
//   case 'de': {
//     translations = require(`raw-loader!./locale/messages.de.xtb`);
//     console.log('using locale file:' + locale);
//     break;
//   }
//   case 'zh': {
//     translations = require(`raw-loader!./locale/messages.zh.xtb`);
//     console.log('using locale file:' + locale);
//     break;
//   }
//   case 'ar': {
//     translations = require(`raw-loader!./locale/messages.ar.xtb`);
//     console.log('using locale file:' + locale);
//     break;
//   }
//   case 'am': {
//     translations = require(`raw-loader!./locale/messages.am.xtb`);
//     console.log('using locale file:' + locale);
//     break;
//   }
//   case 'en': {
//     translations = require(`raw-loader!./locale/messages.en.xtb`);
//     // platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
//     console.log('using locale file:' + locale);
//     break;
//   }
//   default: {
//     translations = require(`raw-loader!./locale/messages.en.xtb`);
//     platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
//     console.log('using default locale file');
//   }
// }
//
// platformBrowserDynamic().bootstrapModule(AppModule, {
//   providers: [
//     {provide: TRANSLATIONS, useValue: translations},
//     {provide: TRANSLATIONS_FORMAT, useValue: 'xtb'}
//   ]
// });
//

