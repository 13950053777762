import {Cover} from "./Cover";
import {Body} from "./Body";
import {DataObject} from "./DataObject";
import {Book} from "./Book";
import {Draft} from "./Draft";

export class Project implements DataObject {
  ID: number;
  org_ID: number;
  name: string;
  primaryBook_ID: number;
  primaryDraft_ID: number;
  created: Date;
  lastUpdate: Date;
  archived: string;

  books: Book[];
  drafts: Draft[];

  primaryBook: Book;
  primaryDraft: Draft;

  constructor (obj) {
    this.ID = 0;
    this.org_ID = 0;
    this.primaryBook_ID = 0;
    this.primaryDraft_ID = 0;
    this.lastUpdate = new Date(0);
    this.archived = 'FALSE';

    this.primaryBook = undefined;
    this.primaryDraft = undefined;

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
  }

  getId() {
    return this.ID;
  }

  getOrderValue() {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
