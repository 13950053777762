import {Component, Input, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../pipe/language.pipe";
import {UserService} from "../../../service-data/user.service";
import {Organization} from "../../../../model/Organization";
import {OrganizationService} from "../../../service-data/organization.service";
import {UmbrellaService} from "../../../service-data/umbrella.service";
import {Umbrella} from "../../../../model/Umbrella";
import {StartupService} from "../../../service-ui/startup.service";

@Component({
  selector: 'app-add-umbrella',
  templateUrl: './add-umbrella.component.html',
  styleUrls: ['./add-umbrella.component.css']
})
export class AddUmbrellaComponent implements OnInit {
  public breakpoint: number; // Breakpoint observer code
  public organization: Organization;
  public name: string;
  public addForm: FormGroup;
  wasFormChanged = false;
  organizations: Organization[];
  umbrellas: Umbrella[];

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public userService: UserService, public organizationService: OrganizationService, public umbrellaService: UmbrellaService, private startupService: StartupService) {
  }

  public ngOnInit(): void {
    this.organizationService.getItems()
      .subscribe(organizations => {
        this.organizations = organizations;
      });
    this.umbrellaService.objects.subscribe(umbrellas => {
        this.umbrellas = umbrellas;
      });
    // this.umbrellaService.getUmbrellas()
    //   .subscribe(umbrellas => {
    //     this.umbrellas = umbrellas;
    //   });

    this.addForm = this.formBuilder.group({
      organization: [this.organization, [Validators.required]],
      name: [this.name, [Validators.required, Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]]
      // email: [null, [Validators.required, Validators.email]],
      // phone: [this.phone, [Validators.required]],
      // phone: [this.phone, [Validators.required, PhoneValidator.validCountryPhone(this.addForm.controls.phoneCountry)]],
      // phoneCountry: [this.phoneCountry, [Validators.required, Validators.pattern('^\\d*[0-9]\\d*$')]]
    });
    // this.addForm.controls.phoneCountry.validator = PhoneValidator.validCountryPhone(this.addForm.controls.phoneCountry);

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onAdd(): void {
    const objectToAdd = new Umbrella( undefined );
    const organization = this.startupService.getCurrentOrganization();
    if ( organization ) {
      objectToAdd.org_id = this.addForm.controls.organization.value.ID;
      objectToAdd.name = this.addForm.controls.name.value;
      objectToAdd.marginShare = 0;
      objectToAdd.fixedShare = 0;
      switch ( this.startupService.selectedRole ) {
        case 'serviceProvider': {
          objectToAdd.serviceprovider_id = Number(this.startupService.selectedRoleId);
          this.umbrellaService.create(objectToAdd);
          break;
        }
      }
    }
    this.markAsDirty(this.addForm);
    //  close the dialog here?
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  public isOrganizationAlreadyAnUmbrella(orgId: number) {
    for ( const umbrella of this.umbrellas ) {
      if ( umbrella.org_id === orgId ) {
        return true;
      }
    }
    return false;
  }
}
