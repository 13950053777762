<div class="accountSection" *ngIf="subscription">
  <h2  i18n="@@Subscription">Subscription</h2>
  <p *ngIf="subscription.getSubscriptionStatus() === 'TRIAL'"><ng-container i18n="@@Your Main Organization Name Trial ends on">Your Main Organization Name Trial ends on</ng-container> {{subscription.paidUntilDate}}. <button (click)="subscription.setSubscriptionLevel('STARTER')" mat-button color="accent" i18n="@@Cancel subscription">Cancel subscription</button></p>
  <p *ngIf="subscription.getSubscriptionStatus() === 'EXPIRED'"><ng-container i18n="@@Your Main Organization Name subscription is expired.">Your Main Organization Name subscription is expired.</ng-container> <button mat-button color="primary" i18n="@@Update payment and billing information">Update payment and billing information</button></p>
  <p *ngIf="subscription.getSubscriptionStatus() === 'CURRENT'"><ng-container i18n="@@Your Main Organization Name Trial subscription will renew on">Your Main Organization Name Trial subscription will renew on</ng-container> {{subscription.paidUntilDate}}.</p>
  <mat-grid-list class="subscriptionOptions" cols="2" rowHeight="4:3" gutterSize="20px">
    <mat-grid-tile i18n-class="@@dir">
      <mat-card appearance="outlined" class="subscriptionOption">
        <mat-card-header>
          <mat-card-title>Starter</mat-card-title>
          <mat-card-subtitle>For staring users</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li>Maximum of three titles</li>
            <li>Basic themes</li>
            <li>Transcription for $1.2/minute</li>
            <li>Knowledge base for self-service</li>
          </ul>
          <h2>$0 <span class="perMonth">/month</span></h2>
        </mat-card-content>
        <mat-card-actions>
          <button *ngIf="subscription.getSubscriptionLevel() === 'STARTER'" mat-flat-button color="accent"><mat-icon>check</mat-icon> <span i18n="@@YOUR PLAN">YOUR PLAN</span></button>
          <button *ngIf="subscription.getSubscriptionLevel() !== 'STARTER'" (click)="subscription.setSubscriptionLevel('STARTER'); subscriptionService.changeSubscription(subscription);" mat-flat-button color="primary" i18n="@@CHANGE">CHANGE</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile i18n-class="@@dir">
      <mat-card appearance="outlined" class="subscriptionOption">
        <mat-card-header>
          <mat-card-title>Standard</mat-card-title>
          <mat-card-subtitle>For regular users</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li>All the features of 'Starter'</li>
            <li>Unlimited number of titles</li>
            <li>Basic stock image library</li>
            <li>Email support</li>
            <li>Upload logo image for use on covers</li>
          </ul>
          <h2>$20 <span class="perMonth">/month</span></h2>
        </mat-card-content>
        <mat-card-actions>
          <button *ngIf="subscription.getSubscriptionLevel() === 'STANDARD'" mat-flat-button color="accent"><mat-icon>check</mat-icon> <span i18n="@@YOUR PLAN">YOUR PLAN</span></button>
          <button *ngIf="subscription.getSubscriptionLevel() !== 'STANDARD'" (click)="subscription.setSubscriptionLevel('STANDARD'); subscriptionService.changeSubscription(subscription);" mat-flat-button color="primary" i18n="@@CHANGE">CHANGE</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile i18n-class="@@dir">
      <mat-card appearance="outlined" class="subscriptionOption">
        <mat-card-header>
          <mat-card-title>Plus</mat-card-title>
          <mat-card-subtitle>For heavier users</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li>All the features of 'Standard'</li>
            <li>Expanded themes</li>
            <li>Expanded stock image library</li>
            <li>Upload custom images for use on covers</li>
            <li>Ebooks</li>
            <li>Support via phone_number</li>
          </ul>
          <h2>$49 <span class="perMonth">/month</span></h2>
        </mat-card-content>
        <mat-card-actions>
          <button *ngIf="subscription.getSubscriptionLevel() === 'PLUS'" mat-flat-button color="accent"><mat-icon>check</mat-icon> <span i18n="@@YOUR PLAN">YOUR PLAN</span></button>
          <button *ngIf="subscription.getSubscriptionLevel() !== 'PLUS'" (click)="subscription.setSubscriptionLevel('PLUS'); subscriptionService.changeSubscription(subscription);" mat-flat-button color="primary" i18n="@@CHANGE">CHANGE</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile i18n-class="@@dir">
      <mat-card appearance="outlined" class="subscriptionOption">
        <mat-card-header>
          <mat-card-title>Ecommerce</mat-card-title>
          <mat-card-subtitle>For online sales</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li>All the features of 'Plus'</li>
            <li>Online ecommerce</li>
          </ul>
          <h2>$99 <span class="perMonth">/month</span></h2>
        </mat-card-content>
        <mat-card-actions>
          <button *ngIf="subscription.getSubscriptionLevel() === 'ECOMMERCE'" mat-flat-button color="accent"><mat-icon>check</mat-icon> <span i18n="@@YOUR PLAN">YOUR PLAN</span></button>
          <button *ngIf="subscription.getSubscriptionLevel() !== 'ECOMMERCE'" (click)="subscription.setSubscriptionLevel('ECOMMERCE'); subscriptionService.changeSubscription(subscription);" mat-flat-button color="primary" i18n="@@CHANGE">CHANGE</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="startupService.getSubscription().getSubscriptionLevel() === 'ECOMMERCE' || startupService.getSubscription().getSubscriptionLevel() === 'DESIGNER' || startupService.admin">
      <mat-card appearance="outlined" class="subscriptionOption">
        <mat-card-header>
          <mat-card-title>Designer</mat-card-title>
          <mat-card-subtitle>For template creators.</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li>All the features of 'ecommerce'</li>
            <li>Features to create interior and cover templates.</li>
          </ul>
          <h2>$199 <span class="perMonth">/month</span></h2>
        </mat-card-content>
        <mat-card-actions>
          <button *ngIf="subscription.getSubscriptionLevel() === 'DESIGNER'" mat-flat-button color="accent"><mat-icon>check</mat-icon> <span i18n="@@YOUR PLAN">YOUR PLAN</span></button>
          <button *ngIf="subscription.getSubscriptionLevel() !== 'DESIGNER'" (click)="subscription.setSubscriptionLevel('DESIGNER'); subscriptionService.changeSubscription(subscription);" mat-flat-button color="primary" i18n="@@CHANGE">CHANGE</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>

