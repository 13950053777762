import {PrintNodeOrderOption} from "./PrintNodeOrderOption";
import {Item} from "./Item";
import {Address} from "../Address";
import {Facility} from "../Facility";
import {PrintNodeOrderService} from "../../app/service-data/print-node-order.service";
import {PackageAndShippingComputationService} from "../../app/service-data/package-and-shipping-computation.service";
import {Cart} from "./Cart";

export class OrderOption { // An OrderOption must represent all of the items in the Cart in exactly one of the PrintNodeOrderOptions
  printNodeOrderOptions: PrintNodeOrderOption[] = [];
  strategy: string;
  packItemsInSeparateBoxes: boolean;
  useCaseQuantities: boolean;
  address: Address;
  carrier: string;
  serviceLevel: string;
  packageAndShippingComputationService: PackageAndShippingComputationService;
  computationStatus: string;

  constructor (deliveryAddress: Address, carrier: string, serviceLevel: string, packItemsInSeparateBoxes: boolean, useCaseQuantities: boolean, strategy: string, packageAndShippingComputationService: PackageAndShippingComputationService) {
    this.address = deliveryAddress;
    this.strategy = strategy;
    this.carrier = carrier;
    this.serviceLevel = serviceLevel;
    this.packItemsInSeparateBoxes = packItemsInSeparateBoxes;
    this.useCaseQuantities = useCaseQuantities;
    this.packageAndShippingComputationService = packageAndShippingComputationService;
    this.computationStatus = 'INITIAL';
  }

  cloneWIthDifferentCarrierAndServiceLevel(carrier: string, serviceLevel: string) {
    const clone = new OrderOption(this.address, carrier, serviceLevel, this.packItemsInSeparateBoxes, this.useCaseQuantities, this.strategy, this.packageAndShippingComputationService);
    this.printNodeOrderOptions.forEach( printNodeOrderOption => {
      const clonedPrintNodeOrderOption = clone.addPrintNodeOrderOption(printNodeOrderOption.facilityId);
      printNodeOrderOption.items.forEach( item => {
        clonedPrintNodeOrderOption.addItem(item);
      });
    });
    return clone;
  }
  addPrintNodeOrderOption(facilityId: number): PrintNodeOrderOption {
    const printNodeOrderOption = new PrintNodeOrderOption(this.carrier, this.serviceLevel, this.address, facilityId, this.packItemsInSeparateBoxes, this.useCaseQuantities, this.packageAndShippingComputationService)
    this.printNodeOrderOptions.push(printNodeOrderOption);
    return printNodeOrderOption;
  }

  compute(cart: Cart) {
    this.computationStatus = 'COMPUTING';
    this.printNodeOrderOptions.forEach( printNodeOrderOption => {
      printNodeOrderOption.compute(cart);
    });
  }

  checkComputationStatus(): string {
    if ( this.computationStatus === 'INITIAL') {
      return 'INITIAL';
    }
    if ( this.computationStatus === 'ERROR') {
      return 'ERROR';
    }
    let cs = '';
    this.printNodeOrderOptions.forEach( printNodeOrderOption => {
      const printNodeOrderOptionComputeStatus = printNodeOrderOption.checkComputationStatus();
      if ( printNodeOrderOption.computationStatus === 'ERROR') {
        this.computationStatus = 'ERROR';
        return this.computationStatus;
      }
      if ( printNodeOrderOption.computationStatus === 'COMPUTING' ) {
        cs = 'COMPUTING';
      }
    });
    if ( cs.length === 0) {
      this.computationStatus = 'COMPLETE';
    } else {
      this.computationStatus = 'COMPUTING';
    }
    return this.computationStatus;
  }

  getTotalPackages(): number {
    let packages = 0;
    for ( const printNodeOrderOption of this.printNodeOrderOptions ) {
      packages += printNodeOrderOption.getTotalPackages();
    }
    return packages;
  }

  getTotalPackingAndShippingCost(carrier: string, serviceLevel: string): number {
    let cost = 0;
    for ( const printNodeOrderOption of this.printNodeOrderOptions ) {
      cost += printNodeOrderOption.getTotalPackingAndShippingCost(carrier, serviceLevel);
    }
    return cost;
  }
}

