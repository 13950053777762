import { Injectable } from '@angular/core';
import { StartupService } from '../service-ui/startup.service';

@Injectable()
export abstract class DataService {

  constructor(private dataServiceStartupService: StartupService) {
    dataServiceStartupService.addService(this);
  }

  public abstract reset ();
}
export class ObjectAttachRequest {
  public objectIdToAttachTo: number;
  public arrayPropertyToAddTo: string;
  public objectPropertyToSet: string;
  public objectToAttach: any;
  constructor(objectIdToAttachTo: number, arrayPropertyToAddTo: string, objectPropertyToSet: string, objectToAttach: any) {
    this.objectIdToAttachTo = objectIdToAttachTo;
    this.arrayPropertyToAddTo = arrayPropertyToAddTo;
    this.objectPropertyToSet = objectPropertyToSet;
    this.objectToAttach = objectToAttach;
  }
}

export class ObjectDetachRequest {
  public objectIdToAttachTo: number;
  public arrayPropertyToAddTo: string;
  public objectPropertyToSet: string;
  public objectToAttach: any;
  constructor(objectIdToAttachTo: number, arrayPropertyToAddTo: string, objectPropertyToSet: string, objectToAttach: any) {
    this.objectIdToAttachTo = objectIdToAttachTo;
    this.arrayPropertyToAddTo = arrayPropertyToAddTo;
    this.objectPropertyToSet = objectPropertyToSet;
    this.objectToAttach = objectToAttach;
  }
}
