import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {DataService} from "./data-service";
import {Facility} from "../../model/Facility";
import {ShippingOption} from "../../model/cart/ShippingOption";
import {Address} from "../../model/Address";
import {ShippingItem} from "../../model/cart/ShippingItem";
import {ShippingPackage} from "../../model/cart/ShippingPackage";

@Injectable()
export class PackageAndShippingComputationService extends DataService {

  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
    super(startupService);
    startupService.setPackageAndShippingComputationService(this);
  }

  /** PUT: sendScan */
  scan(value: string ): Observable<String> {
    const url = `${environment.apiBaseURL}scan/${value}`;
    return this.http.post(url, undefined, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`scan value:${value}`)),
      catchError(this.handleError<any>(`Error scan value:${value}`))
    );
  }
  getPackages(carrier: string, serviceLevel: string, facilityId: number, shippingItems: ShippingItem[], packItemsInSeparateBoxes: boolean, useCaseQuantities: boolean, computationVersion: number): Observable<any> {
      const url = `${environment.apiBaseURL}packageandshippingcomputation/packages`;
      return this.http.post(url, { carrier: carrier, serviceLevel: serviceLevel, facility: facilityId, shippingItems: shippingItems, packItemsInSeparateBoxes: packItemsInSeparateBoxes, useCaseQuantities: useCaseQuantities, computationVersion: computationVersion}, this.startupService.getHttpOptions()).pipe(
        tap(_ => this.log(`getPackages value:${{ carrier: carrier, serviceLevel: serviceLevel, facility: facilityId, shippingItems: shippingItems, packItemsInSeparateBoxes: packItemsInSeparateBoxes, useCaseQuantities: useCaseQuantities, computationVersion: computationVersion}}`)),
        catchError(this.handleError<any>(`Error getPackages value:${{ carrier: carrier, serviceLevel: serviceLevel, facility: facilityId, shippingItems: shippingItems, packItemsInSeparateBoxes: packItemsInSeparateBoxes, useCaseQuantities: useCaseQuantities, computationVersion: computationVersion}}`))
      );
  }

  getShippingOptions(carrier: string, serviceLevel: string, facilityId: number, toAddress: Address, shippingPackage: ShippingPackage, computationVersion: number): Observable<any> {
    const url = `${environment.apiBaseURL}packageandshippingcomputation/shippingoptions`;
    return this.http.post(url, { carrier: carrier, serviceLevel: serviceLevel, toAddress: toAddress, facilityId: facilityId, shippingPackage: shippingPackage, computationVersion: computationVersion}, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`getPackages value:${{ carrier: carrier, serviceLevel: serviceLevel, toAddress: toAddress, facilityId: facilityId, shippingPackage: shippingPackage, computationVersion: computationVersion}}`)),
      catchError(this.handleError<any>(`Error getPackages value:${{ carrier: carrier, serviceLevel: serviceLevel, fromAddress: facilityId, toAddress: toAddress, shippingPackage: shippingPackage, computationVersion: computationVersion}}`))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add('UserService: ' + message);
  }

  reset (filter: string = '') {
  }
}
