import {TableFilter} from "./TableFilter";
import {PrintNodeOrderItem} from "../../model/PrintNodeOrderItem";
import {MatTableDataSource} from "@angular/material/table";

export class TableFilterManager<T> {

  dataSource: MatTableDataSource<T>;
  tableFilterParams: {} = {};
  tableFilter: TableFilter = TableFilter.create({ type: 'NumericRange', fieldName: 'quantity', startValue: 1, endValue: 100 });
  quantityTableFilter: TableFilter = TableFilter.create({ type: 'NumericRange', fieldName: 'quantity', startValue: 1, endValue: 100 });
  largeQuantityTableFilter: TableFilter = TableFilter.create({ type: 'NumericRange', fieldName: 'quantity', startValue: 100, endValue: 100000 });

  public constructor (dataSource: MatTableDataSource<T>) {
    this.dataSource = dataSource;
  }
  public setFilter(tableFilter: TableFilter) {
    this.tableFilter = tableFilter;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: T, filter: string) => {
        return _this.tableFilter.match(data, filter);
      };
    this.dataSource.filter = '' + Math.random();
  }

  applySubStringFilter(columnName: string, event: any) {
    const newTableFilter = TableFilter.create({ type: 'String', fieldName: columnName, value: event.value });
    this.setFilter(newTableFilter);
  }
  applyCompositeSubStringFilter(columnName: string, event: any) {
    const newTableFilter = TableFilter.create({ type: 'CompositeString', fieldNames: columnName, value: event.value });
    this.setFilter(newTableFilter);
  }
  applyMinRangeFilter(columnName: string, event: any) {
    this.tableFilterParams[columnName + '_Min'] = Number(event.value);
    if ( !this.tableFilterParams[columnName + '_Max']) {
      this.tableFilterParams[columnName + '_Max'] = 100000000;
    }
    const newTableFilter = TableFilter.create({ type: 'NumericRange', fieldName: columnName, startValue: this.tableFilterParams[columnName + '_Min'], endValue: this.tableFilterParams[columnName + '_Max'] });
    this.setFilter(newTableFilter);
  }
  applyMaxRangeFilter(columnName: string, event: any) {
    this.tableFilterParams[columnName + '_Max'] = Number(event.value);
    if ( !this.tableFilterParams[columnName + '_Min']) {
      this.tableFilterParams[columnName + '_Min'] = 1;
    }
    const newTableFilter = TableFilter.create({ type: 'NumericRange', fieldName: columnName, startValue: this.tableFilterParams[columnName + '_Min'], endValue: this.tableFilterParams[columnName + '_Max'] });
    this.setFilter(newTableFilter);
  }
  applyExactNumberFilter(columnName: string, event: any) {
    const newTableFilter = TableFilter.create({ type: 'Numeric', fieldName: columnName, value: event.value });
    this.setFilter(newTableFilter);
  }
  applyDataEnumFilter(columnName: string, eventValue: any, valuesMap: any) {
    const newTableFilter = TableFilter.create({ type: 'DataEnum', fieldName: columnName, value: eventValue, valuesMap: valuesMap });
    this.setFilter(newTableFilter);
  }

}
