import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import {NotificationsService} from "./notifications.service";
import {Facility} from "../../model/Facility";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {Observable} from "rxjs";
import {of} from "rxjs";
import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class FacilityService extends AbstractLiveDataService<Facility> {

  private startupServicea: StartupService;

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServiceaa: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServiceaa, notificationsService);
    this.startupServicea = startupServiceaa;
    this.notificationsService.setFacilityService(this);
    notificationsService.startNotification();
  }

  makeObjectInstance(args: any): Facility {
    const object = new Facility(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "Facility";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "facility";
  }

  getLinkData(): any[] {
    return [];
  }
}
