import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef,  Component, EventEmitter,  Inject,  Input,  LOCALE_ID,  OnDestroy,  OnInit,  Output,  ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {Project} from "../../../../../model/Project";
import {StartupService} from "../../../../service-ui/startup.service";
import {ProjectService} from "../../../../service-data/project.service";

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnDestroy, OnInit {
  @ViewChild('placeHolderProjectName') placeHolderProjectName;
  @Input() project: Project;
  @Input() displayCover: boolean;
  @Input() editingDetails: string;
  @Output() editingDetailsChange = new EventEmitter<any>();

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  public renameProject: boolean = false;
  sizeWidth: 0;


  constructor(public router: Router, public startupService: StartupService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, private projectService: ProjectService, private route: ActivatedRoute, @Inject(LOCALE_ID) localeId: string) {
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  onDataChange() {
    this.editingDetailsChange.emit(this.editingDetails);
  }

  ngOnInit() {
  }

  onSubmit() {
  }

  onResize(event: any) {
    console.log("onresize " + event);
    const w = event.target.innerWidth;
    this.sizeWidth = w;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  isProjectNameUsed(): boolean {
    if ( !this.placeHolderProjectName || !this.placeHolderProjectName.nativeElement || !this.placeHolderProjectName.nativeElement.value ) {
      return true;
    }
    const newProjectName = this.placeHolderProjectName.nativeElement.value.trim();
    for ( const product of this.startupService.products ) {
      if ( newProjectName === product.title ) {
        return true;
      }
    }
    return false;
  }
  saveProjectValue() {
    console.log('Save Project Name' + this.placeHolderProjectName.nativeElement.value);
    this.project.name = this.placeHolderProjectName.nativeElement.value;
    // this.productService.saveProjectImmediately(this.product).then( proj => {
    //   console.log('Updated product name');
    // });
  }
  cancelProjectValue() {
    this.placeHolderProjectName.nativeElement.value = this.project.name;
  }

}
