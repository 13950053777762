import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { ServiceProvider } from '../../model/ServiceProvider';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {DataService} from "./data-service";

@Injectable()
export class ServiceProviderService extends DataService {
  private objects: ServiceProvider[] = [];
  private objectsObservable = of( this.objects );
  public loadingState = 'INITIAL';


  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
    super(startupService);
    // this.startupService.setServiceProviderService(this);
  }

    reset (filter: string = '') {
    this.loadingState = 'INITIAL';
    while ( this.objects.length > 0) {
      this.objects.pop();
    }
  }

  getServiceProviders(): Observable<ServiceProvider[]> {
    if ( this.loadingState === 'INITIAL') {
      this.loadingState = 'LOADING';
      const url = `${environment.apiBaseURL}serviceProvider`;
      this.messageService.add('ServiceProviderService: fetched serviceProviders');
      return this.http.get<ServiceProvider[]>(url, this.startupService.getHttpOptions())
        .pipe(
          map(serviceProviders => {
            while ( this.objects.length > 0) {
              this.objects.pop();
            }
            for ( const serviceProvider of serviceProviders ) {
              const o = new ServiceProvider(serviceProvider);
              this.objects.push(o);
            }
            // this.startupService.serviceProviders = this.objects;
            this.loadingState = 'LOADED';
            return this.objects;
          }), catchError(this.handleError('getServiceProviders', []))
        );
    } else {
      return this.objectsObservable;
    }
  }


  getServiceProvider(id: string): Observable<ServiceProvider> {
    const url = `${environment.apiBaseURL}serviceProvider/${id}`;
    this.messageService.add(`ServiceProviderService: fetched serviceProvider id=${id}`);
    return this.http.get<ServiceProvider>(url, this.startupService.getHttpOptions())
      .pipe(
        map(serviceProvider => {
          const p = new ServiceProvider(serviceProvider);
          return p;
        }),
        catchError(this.handleError<ServiceProvider>(`getServiceProvider id=${id}`))
      );
  }

  /** PUT: update the serviceProvider on the server */
  updateServiceProvider (serviceProvider: ServiceProvider): Observable<ServiceProvider> {
    const url = `${environment.apiBaseURL}serviceProvider/${serviceProvider.ID}`;
    this.messageService.add(`ServiceProviderService: updated serviceProvider id=${serviceProvider.ID}`);
    return this.http.put(url, serviceProvider, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated serviceProvider id=${serviceProvider.ID}`)),
      catchError(this.handleError<any>('updateServiceProvider'))
    );
  }

  addServiceProvider (serviceProvider: ServiceProvider): Observable<ServiceProvider> {
    const url = `${environment.apiBaseURL}serviceProvider`;
    return this.http.post<ServiceProvider>(url, serviceProvider, this.startupService.getHttpOptions()).pipe(
      tap(o => {
        this.log(`added serviceProvider w/ id=${serviceProvider.ID}`);
        this.objects.push(new ServiceProvider(o));
      }),
      catchError(this.handleError<ServiceProvider>('addServiceProvider'))
    );
  }

  /** DELETE: delete the serviceProvider from the server */
  deleteServiceProvider (serviceProvider: ServiceProvider): Observable<ServiceProvider> {
    const url = `${environment.apiBaseURL}serviceProvider/${serviceProvider.ID}`;

    return this.http.delete<ServiceProvider>(url, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`deleted serviceProvider id=${serviceProvider.ID}`)),
      catchError(this.handleError<ServiceProvider>('deleteServiceProvider'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for serviceProvider consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add('ServiceProviderService: ' + message);
  }

}
