import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import {MatSidenav} from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableFilterManager} from "../../table/TableFilterManager";
import {EventHandler} from "../../table/EventHandler";
import {FacilityInventoryProduct} from "../../../model/FacilityInventoryProduct";
import {FacilityInventoryProductService} from "../../service-data/facility-inventory-product.service";
import {AddFacilityInventoryProductComponent} from "./add-facility-inventory-product/add-facility-inventory-product.component";
import {ProductService} from "../../service-data/product.service";
import {Product} from "../../../model/Product";
import {Facility} from "../../../model/Facility";
import {OrganizationService} from "../../service-data/organization.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {LanguagePipe} from "../../pipe/language.pipe";

@Component({
  selector: 'app-facility-inventory-products',
  templateUrl: './facility-inventory-products.component.html',
  styleUrls: ['./facility-inventory-products.component.css']
})

export class FacilityInventoryProductsComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [
    { property: 'ID',               columnDef: 'ID',                header: 'ID',                sort: true,  type: 'number', filterType: 'String',                                style: 'text-align: right',  cell: (element: any) => `${element.ID }`},
    // { property: 'printnode_ID',     columnDef: 'printNodeId',       header: 'Facility',          sort: true,  type: 'number', filterType: 'String',                                  style: 'text-align: right',  cell: (element: any) => `${element.printnode_ID === 1 ? 'AZ' : 'TX'}`},
    { property: 'inventoryManagementType', columnDef: 'inventoryManagementType', header: 'Type', sort: true,  type: 'number', filterType: 'DataEnum',    filterEnums: [LanguagePipe.get('LEVEL', 'compressSpaces'), LanguagePipe.get('ADHOC', 'compressSpaces'), LanguagePipe.get('CUSTOMER', 'compressSpaces')],             cell: (element: any) => `${LanguagePipe.get(element.inventoryManagementType, 'compressSpaces')}`, styleX: (element: any) => `${"max-height: 20px;  text-overflow: clip; text-align: right; color: " + ( element.inventoryManagementType === 'ADHOC' ? 'white; background-color: darkorange' : (element.inventoryManagementType === 'CUSTOMER' ? 'white; background-color: darkred' : 'white' ) ) }`   },
    { property: 'location',         columnDef: 'location',          header: 'Location',          sort: true,  type: 'number', filterType: 'String',                                  style: 'text-align: right',  cell: (element: any) => `${element.location}`   },
    { property: 'shelf',            columnDef: 'shelf',             header: 'Shelf',             sort: true,  type: 'number', filterType: 'String',                                  style: 'text-align: right',  cell: (element: any) => `${element.shelf}`   },
    { property: 'bookId',           columnDef: 'bookId',            header: 'BookId',            sort: true,  type: 'number', filterType: 'String',                                  style: 'text-align: right',  cell: (element: any) => `${element.product.book_ID}`   },
    { property: 'product.org_ID',   columnDef: 'orgId',             header: 'OrgId',             sort: true,  type: 'number', filterType: 'String',                                  style: 'text-align: right',  cell: (element: any) => `${element.product.org_ID}`   },
    { property: 'title',            columnDef: 'title',             header: 'Title',             sort: true,  type: 'string', filterType: 'String',                                  style: 'text-align: right',  cell: (element: any) => `${element.title}`},
    { property: 'present',          columnDef: 'present',           header: 'Present',           sort: true,  type: 'number', filterType: 'NumericRange',                                                         cell: (element: any) => `${element.quantityOnHand - element.quantityCommited}`, styleX: (element: any) => `${"max-height: 20px;  text-overflow: clip; text-align: right; color: " + (( element.quantityOnHand - element.quantityCommited ) <= 0 ? 'white; background-color: darkred' : (( element.quantityOnHand - element.quantityCommited - element.reorderLevel ) <= 0 ? 'white; background-color: darkorange' : 'lightgreen' ) ) }`   },
    { property: 'net',              columnDef: 'net',               header: 'Net',               sort: true,  type: 'number', filterType: 'NumericRange',                                                         cell: (element: any) => `${element.quantityOnOrder + element.quantityOnHand - element.quantityCommited}`,                            styleX: (element: any) => `${"max-height: 20px;  text-overflow: clip; text-align: right; color: " + (( element.quantityOnOrder + element.quantityOnHand - element.quantityCommited ) <= 0 ? 'white; background-color: darkred' : (( element.quantityOnOrder + element.quantityOnHand - element.quantityCommited - element.reorderLevel ) <= 0 ? 'white; background-color: darkorange' : 'lightgreen')) }`   },
    { property: 'caseQuantity',     columnDef: 'caseQuantity',      header: 'Case Quantity',     sort: true,  type: 'number', filterType: 'String',                                  style: 'text-align: right',  cell: (element: any) => `${element.caseQuantity}`   },
    { property: 'quantityOnOrder',  columnDef: 'quantityOnOrder',   header: 'Quantity On Order', sort: true,  type: 'number', filterType: 'NumericRange',                            style: 'text-align: right',  cell: (element: any) => `${element.quantityOnOrder}`   },
    { property: 'quantityOnHand',   columnDef: 'quantityOnHand',    header: 'Quantity On Hand',  sort: true,  type: 'number', filterType: 'NumericRange',                            style: 'text-align: right',  cell: (element: any) => `${element.quantityOnHand}`   },
    { property: 'quantityCommited', columnDef: 'quantityCommited',  header: 'Quantity Commited', sort: true,  type: 'number', filterType: 'String',                                  style: 'text-align: right',  cell: (element: any) => `${element.quantityCommited}`   },
    { property: 'reorderQuantity',  columnDef: 'reorderQuantity',   header: 'Reorder Quantity',  sort: true,  type: 'number', filterType: 'String',                                  style: 'text-align: right',  cell: (element: any) => `${element.reorderQuantity}`   },
    { property: 'reorderLevel',     columnDef: 'reorderLevel',      header: 'Reorder Level',     sort: true,  type: 'number', filterType: 'String',                                  style: 'text-align: right',  cell: (element: any) => `${element.reorderLevel}`   }

// <button (click)="createReplenishmentOrder(facilityInventoryProduct, '' + Math.max ( facilityInventoryProduct.quantityCommited - facilityInventoryProduct.quantityOnOrder - facilityInventoryProduct.quantityOnHand + facilityInventoryProduct.reorderQuantity , facilityInventoryProduct.reorderQuantity ), '', '')" title="Reorder Quantity" i18n-title="@@Reorder Quantity">
// <mat-icon [matBadge]="Math.max ( facilityInventoryProduct.quantityCommited - facilityInventoryProduct.quantityOnOrder - facilityInventoryProduct.quantityOnHand + facilityInventoryProduct.reorderQuantity , facilityInventoryProduct.reorderQuantity )" matBadgeColor="primary" >shopping_cart_checkout</mat-icon>
//   </button>
// <button (click)="placeReplenishmentOrder()" title="Reorder Manually" i18n-title="@@Reorder Manually">
//   <mat-icon matBadgeColor="secondary" >shopping_cart</mat-icon>
//   </button>
  ];


  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<FacilityInventoryProduct>();
  public renderedData: FacilityInventoryProduct[];
  tableFilterManager: TableFilterManager<FacilityInventoryProduct> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  role: string;

  public facilityInventoryProducts: FacilityInventoryProduct[];
  public printNode: Facility;
  public product: Product;

  public products: Product[];
  public organizations: number[] = [];

  @ViewChild('mainSideNav', {static: true}) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  displayToggle: boolean = false;
  showCharts: boolean = false;
  edit: boolean = false;

  selectedOrganization = '0';

  constructor (private zone: NgZone, public router: Router, public startupService: StartupService, private productService: ProductService, public facililtyInventoryProductService: FacilityInventoryProductService, public organizationService: OrganizationService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public dialog: MatDialog) {
    this.startupService.addRoleChangeListener(this);
    this.startupService.touchLastUserActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    // this.getData();
  }

  ngOnInit () {
    console.log("Checking if the facilityInventoryProduct is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => this.renderedData = d);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: FacilityInventoryProduct, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
      };
    // this.dataSource.paginator = this.paginator;
  }
  // ngAfterContentChecked(): void {
  //   // this.dataSource.sort = this.sort;
  //   // this.dataSource.filter = '' + Math.random();
  //   this.changeDetectorRef.detectChanges();
  // }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  isLoggedIn (message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }
  match(o: FacilityInventoryProduct ): boolean {
    if ( o ) {
      return true;
    }
    return false;
  }

  addUpdate(o: FacilityInventoryProduct) {
    if ( this.match(o)) {
      if ( this.dataSource.data.findIndex(item => item.getId() === o.getId()) === -1) {
        this.dataSource.data.push(o);
        this.facilityInventoryProducts.push(o);
      } else {
        console.log(`Did not match on FacilityInventoryProduct in FacilityInventoryProductsComponent ${o.getId()}`);
      }
    } else {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
      }
      const foundIndex2 = this.facilityInventoryProducts.findIndex(item => item.getId() === o.getId());
      if (foundIndex2 > -1) {
        this.facilityInventoryProducts.splice(foundIndex2, 1);
      }
    }
    // this.dataSource.sort = this.sort;
    // this.dataSource.filter = '' + Math.random();
    // this.facilityInventoryProducts.sort(( a, b): number => {
    //   if ( a.printnode_ID < b.printnode_ID ) {
    //     return 1;
    //   } else if ( a.printnode_ID === b.printnode_ID ) {
    //     if ( a.sequence > b.sequence ) {
    //       return 1;
    //     } else if ( a.sequence === b.sequence ) {
    //       return 0;
    //     } else {
    //       return -1;
    //     }
    //   } else {
    //     return -1;
    //   }
    // });
    if ( this.organizations.indexOf(o.product.org_ID) === -1 ) {
      this.organizations.push(o.product.org_ID);
    }
  }

  addUpdateProduct(o: Product) {
    if (this.products.findIndex(item => item.getId() === o.getId()) === -1) {
      this.products.push(o);
    }
  }

  getData(): void {
    this.startupService.touchLastUserActivity();
    this.products = [];
    this.organizations = [];
    this.facilityInventoryProducts = [];

    while ( this.dataSource.data.length > 0 ) {
      this.dataSource.data.pop();
    }

    this.productService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdateProduct(o);
    });
    this.productService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdateProduct(o);
    });
    this.productService.objects.forEach( o => {
      this.addUpdateProduct(o);
    });


    this.facililtyInventoryProductService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.facililtyInventoryProductService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.facililtyInventoryProductService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
      const foundIndex2 = this.facilityInventoryProducts.findIndex(item => item.getId() === o.getId());
      if (foundIndex2 > -1) {
        this.facilityInventoryProducts.splice(foundIndex2, 1);
      }
    });
    this.facililtyInventoryProductService.objects.forEach( o => {
      this.addUpdate(o);
    });
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event (action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  refresh () {
    // this.facililtyInventoryProductService.reset();
    // this.getData();
  }

  public openAddDialog (): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { organizations: this.organizations };
    const dialogRef = this.dialog.open(AddFacilityInventoryProductComponent, dialogConfig);
  }
}
