import { Component, OnInit } from '@angular/core';
import {Recurring} from "../../../../model/cart/Recurring";
import {Subscription} from "../../../../model/Subscription";
import {Router} from "@angular/router";
import {StartupService} from "../../../service-ui/startup.service";
import {NavbarService} from "../../../service-ui/navbar.service";
import {SubscriptionService} from "../../../service-data/subscription.service";
import {LoggedInCallback} from "../../../service-auth/cognito.service";
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, LoggedInCallback {
  subscription: Subscription;
  recurring: Recurring;
  showCreditCardForm: boolean = false;
  paymentFormFieldsVerified: boolean = false;
  updateRecurringStatus: string = '';
  updateRecurringMessage: string = '';

  messages: {
    validDate: 'GOOD\nTHRU',
    monthYear: 'mm/aaaa'
  };
  placeholders: {
    number: '•••• •••• •••• ••••',
    name: 'Nome Completo',
    expiry: '••/••',
    cvc: '•••'
  };
  masks: {
    cardNumber: '•' // optional - mask card number
  };

  constructor(public router: Router, public startupService: StartupService, private navbarService: NavbarService, public subscriptionService: SubscriptionService) {
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Account");
      // this.router.navigate(['/home/login']);
    } else {
      this.navbarService.setNavBarState('account', "");
      this.startupService.showUpdateRecurring = false;
      this.subscription = this.startupService.getSubscription();
      this.recurring = new Recurring( undefined );
    }
  }

  // save() {
  //   this.subscriptionService.changeSubscription(this.subscription); // TODO: Make this a Promise or an Observable
  // }
  //
  verifyPaymentInfo() {
    this.paymentFormFieldsVerified = this.recurring.isPaymentInfoReady();
  }

  getUpdateRecurringButtonClass (): string {
    if ( this.updateRecurringStatus === 'Success') {
      return 'processing btn-success';
    }
    if ( this.updateRecurringStatus === 'PleaseWait') {
      return 'processing btn-light';
    }
    if ( this.updateRecurringStatus !== '') {
      return 'processingProblem btn-danger';
    }
    if ( this.paymentFormFieldsVerified ) {
      return 'ready btn-primary';
    } else {
      return 'notReady btn-warning';
    }
  }

  updateRecurring() {
    // this.updateRecurringStatus = 'PleaseWait';
    // this.updateRecurringMessage = 'PleaseWait';
    // this.orderService.updateRecurring(this.recurring).then(lambdaResponse => {
    //   this.updateRecurringStatus = lambdaResponse.response;
    //   this.updateRecurringMessage = '';
    //   switch ( lambdaResponse.response ) {
    //     case 'Success': {
    //       this.router.navigate(['/secure/orders']);
    //       break;
    //     }
    //     case 'CreditCardChargedButNoOrder': {
    //       break;
    //     }
    //     case 'CreditCardDidNotCharge': {
    //       this.updateRecurringMessage = lambdaResponse.responseMessage;
    //       break;
    //     }
    //     case 'ConnectionError': {
    //       break;
    //     }
    //     case 'GeneralError': {
    //       break;
    //     }
    //     default: {
    //       this.updateRecurringStatus = 'Undefined';
    //       this.updateRecurringMessage = lambdaResponse.responseMessage;
    //       break;
    //     }
    //   }
    // }).catch( error => {
    //   this.updateRecurringStatus = 'Error';
    //   this.updateRecurringMessage = 'Error completing the order. ' + error;
    // });
  }
}
