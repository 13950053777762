import { Injectable } from '@angular/core';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {NotificationsService} from "./notifications.service";
import {ChargeProfile} from "../../model/ChargeProfile";

@Injectable()
export class ChargeProfileService extends AbstractLiveDataService<ChargeProfile> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setChargeProfileService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): ChargeProfile {
    return new ChargeProfile(args);
  }

  getLoggingObjectTypeName (): string {
    return "ChargeProfile";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "chargeprofile";
  }

  getLinkData(): any[] {
    return [];
  }
}
