import {InteriorThemeConfiguration} from "./InteriorThemeConfiguration";
import {InteriorTheme} from "./InteriorTheme";
import {InteriorThemeEditorItem} from "./InteriorThemeEditorItem";
import {InteriorThemeConfigurationSelector} from "./InteriorThemeConfigurationSelector";
import {InteriorThemeEditorSaver} from "./InteriorThemeEditorSaver";
import {Project} from "../Project";

declare var CKEDITOR: any;

export class InteriorThemeEditor {
  public interiorTheme: InteriorTheme;
  public interiorThemeConfiguration: InteriorThemeConfiguration;
  public document: Document;
  public styleSheet: CSSStyleSheet;
  public items: InteriorThemeEditorItem[] = [];
  public interiorThemeEditorSaver: InteriorThemeEditorSaver;
  public project: Project;

  constructor (interiorTheme: InteriorTheme, interiorThemeConfiguration: InteriorThemeConfiguration, document: Document, interiorThemeEditorSaver: InteriorThemeEditorSaver, project: Project) {
    this.interiorThemeConfiguration = interiorThemeConfiguration;
    this.interiorTheme = interiorTheme;
    this.document = document;
    this.interiorThemeEditorSaver = interiorThemeEditorSaver;
    this.project = project;
    for (let i = 0; i < this.document.styleSheets.length; i++) {
      const tempStyleSheet = this.document.styleSheets[i];
      const styleSheetId = tempStyleSheet.ownerNode['ID'];
      if (styleSheetId === 'ProjectCSS' || (this.document.styleSheets[i].href && (this.document.styleSheets[i].href.indexOf('interior-theme') > -1))) {
        this.styleSheet = document.styleSheets[i] as CSSStyleSheet; // Dangerous
        this.createItems();
      }
    }
  }

  createItems () {
    let rule: CSSStyleRule;
    for (const selectorProp of Object.keys(this.interiorThemeConfiguration.selectors)) {
      for (let i = 0; i < this.styleSheet.cssRules.length; i++) {
        const r: CSSStyleRule = this.styleSheet.cssRules[i] as CSSStyleRule;
        if (r.selectorText === selectorProp) {
          rule = r;
        }
      }
      if ( rule ) {
        const selector = this.interiorThemeConfiguration.selectors[selectorProp] as InteriorThemeConfigurationSelector;
        const propertiesType = this.interiorThemeConfiguration.propertiesTypes[selector.propertiesType];
        const item = new InteriorThemeEditorItem(selector, propertiesType, rule, this);
        this.items.push(item);
      } else {
        console.warn("No Rule found for selector[" + selectorProp + "]");
      }
    }
  }

  getCSS (): string {
    let css = '';
    for (const item of this.items) {
      css += item.getCSS();
    }
    return css;
  }
  updateSelectedElements (selectedElements: any[]) {
    // CKEDITOR.dom.element.prototype.matches = Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    CKEDITOR.dom.element.prototype.matches = Element.prototype.matches || Element.prototype.webkitMatchesSelector; // Removed msMatchesSelector because it could not compile with Angular 7

    for (const item of this.items) {
      item.isSelectedElement = false;
    }
    for (const e of selectedElements) {
      const element = e as Element;
      for (const item of this.items) {
        // if (element.matches(item.rule.selectorText)) {
        if (this.matches( element, item.rule.selectorText)) {
          item.isSelectedElement = true;
        }
      }
    }
  }

  getElementMatchFunctionName() {
    // Temporary here, until #1205 is not resolved.
    return CKEDITOR.tools.array.filter( [ 'matches', 'msMatchesSelector', 'webkitMatchesSelector', 'mozMatchesSelector', 'oMatchesSelector' ], function( fnName ) { return fnName in HTMLElement.prototype; } )[ 0 ];
  }

  matches(element, selector: string): boolean {
    const matchingFunctionName = this.getElementMatchFunctionName();
    return matchingFunctionName && !!element.$[ matchingFunctionName ]( selector ) ? element : false;
  }

  save() {
    this.interiorThemeEditorSaver.saveInteriorTheme(this.project, this.getCSS());
  }
}
