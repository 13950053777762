<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Administration">Administration</span><span *ngIf="startupService.project" class="toolbarProjectTitle" i18n="@@Administration">Administration</span>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>

  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="admin"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <mat-card appearance="outlined" class="app-content">
        <mat-card-content>
          <table class="users" *ngIf="users">
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Email</th>
              <th>Subcription Level</th>
              <th>Subcription Status</th>
              <th>Subcription Period</th>
              <th>Recurring Status</th>
              <th>Start Date</th>
              <th>Paid Until</th>
              <th></th>
            </tr>
            <ng-container *ngFor="let user of users">
              <tr [class.selected]="user === selectedUser" (click)="selectedUser = user">
                <td>{{user.ID}}</td>
                <td>{{user.name}}</td>
                <td>{{user.email}}</td>
<!--                <ng-container *ngIf="user.subscription">-->
<!--                  <td>{{user.subscription.getSubscriptionLevel()}}</td>-->
<!--                  <td>{{user.subscription.getSubscriptionStatus()}}</td>-->
<!--                  <td>{{user.subscription.getSubscriptionPeriod()}}</td>-->
<!--                  <td>{{user.subscription.getRecurringStatus()}}</td>-->
<!--                  <td>{{user.subscription.startDate}}</td>-->
<!--                  <td>{{user.subscription.paidUntilDate}}</td>-->
<!--                </ng-container>-->
<!--                <ng-container *ngIf="!user.subscription">-->
<!--                  <td></td>-->
<!--                  <td></td>-->
<!--                  <td></td>-->
<!--                  <td></td>-->
<!--                  <td></td>-->
<!--                  <td></td>-->
<!--                </ng-container>-->
                <td>
                  <!-- <button mat-button type="button" (click)="assumeUserIdentity(user.ID)"><mat-icon>supervised_user_circle</mat-icon></button> -->
                </td>
              </tr>
            </ng-container>
          </table>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
