import { Injectable } from '@angular/core';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {PackageType} from "../../model/PackageType";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {NotificationsService} from "./notifications.service";

@Injectable()
export class PackageTypeService extends AbstractLiveDataService<PackageType> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setPackageTypeService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): PackageType {
    const object = new PackageType(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "PackageType";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "packageType";
  }

  getLinkData(): any[] {
    return [];
  }

}
