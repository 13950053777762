import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NavbarService } from '../../service-ui/navbar.service';
import { EditorProjectService } from '../../service-data/editor/editor-project.service';
import { StartupService } from '../../service-ui/startup.service';
import { Location } from '@angular/common';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {Project} from "../../../model/editor/Project";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {ThemeService} from "../../service-data/theme.service";
import {CoverTheme} from "../../../model/editor/CoverTheme";
import {InteriorTheme} from "../../../model/editor/interior-theme/InteriorTheme";
import {Subject} from "rxjs";


@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})
export class CreateProjectComponent implements OnDestroy, OnInit, LoggedInCallback {
  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  project: Project =  new Project( undefined );

  showLanguagesMenu = false;

  trimSizes: Array<Object> = [
    {value: '5.00inX7.00in', name: '5.00inX7.00in', height: '7.00in', width: '5.00in' },
    {value: '5.50inX8.50in', name: '5.50inX8.50in', height: '8.50in', width: '5.50in' },
    {value: '6.00inX9.00in', name: '6.00inX9.00in', height: '9.00in', width: '6.00in' }
  ];


  constructor(public router: Router, public navbarService: NavbarService, public projectService: EditorProjectService, public startupService: StartupService, public location: Location, public themeService: ThemeService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.startupService.touchLastUserActivity();
    this.project.name = '';
    this.startupService.isAuthenticated(this);
    this.startupService.project = this.project;
    this.setTrimSize(this.trimSizes[2]);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      // This will only get cover themes if they are not already retreived
      this.themeService.getCoverThemes().subscribe( coverThemes => {
        this.startupService.setCoverThemes(coverThemes);
      });
    }
  }

  onSubmit() {}

  save() {
    this.project.name = this.project.name.trim();
    this.project.id = Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16);
    const proj = this.project;
    this.projectService.addProjectWithSpecificThemes(this.project, proj.interior.interiorTheme.id + 'css.css', this.project.cover.coverTheme).then(p => {
      this.router.navigate([`/secure/project/`, proj.id]);
    });
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  setTrimSize(trimSize: any) {
    const project: Project = <Project> this.startupService.getProject();
    project.trimSize = trimSize.value;
    project.width = trimSize.width;
    project.height = trimSize.height;
    this.startupService.trimSizeChange++;
  }

  setCoverTheme(coverTheme: CoverTheme) {
    this.startupService.project.cover.coverTheme = new CoverTheme(coverTheme);
    this.startupService.project.cover.copyCoverThemeColorPalette();
    this.startupService.project.cover.copyCoverThemeFontSettings();
    this.startupService.coverThemeChange++;
  }
  setInteriorThemeId(interiorTheme: InteriorTheme) {
    this.project.interior.interiorTheme.name = interiorTheme.name;
    this.project.interior.interiorTheme.id = interiorTheme.id;
  }
  private remapPercentage(percentage: number): number {
    percentage = percentage * 1.1 - 5;
    if (percentage < 0) {
      percentage = 0;
    } else if ( percentage > 100) {
      percentage = 100;
    }
    return percentage;
  }

  zoom(event) {
    const zoomer = event.currentTarget;
    let offsetX: number;
    let offsetY: number;
    offsetX = event.offsetX
      ? event.offsetX
      : 0;
    offsetY = event.offsetY
      ? event.offsetY
      : 0;

    // it's hard to get near the edges; adjust
    const x = this.remapPercentage(offsetX / zoomer.offsetWidth * 100);
    const y = this.remapPercentage(offsetY / zoomer.offsetHeight * 100);

    zoomer.style.backgroundPosition = x + '% ' + y + '%';
  }
}
