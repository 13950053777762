import {Product} from "./Product";
import {OrderItemToPackage} from "./OrderItemToPackage";
import {PrintNodeOrderItem} from "./PrintNodeOrderItem";
import {DataObject} from "./DataObject";

export class OrderItem implements DataObject {
  orderdeliverto_ID: number;
  quantity: number;
  price: number | null;
  currency_ID: string | null;
  cost: number | null;
  ID: number;
  lastUpdate: Date;
  product_ID: number;
  salesPrice: number | null;
  gatewayCharge: number | null;
  manufacturingCharge: number | null;
  serviceProviderGatewayCharge: number | null;
  serviceProviderManufacturingcharge: number | null;
  printNodeManufacturingCost: number | null;
  agentMargin: number | null;
  serviceProviderContestStatus: string;
  agentContestStatus: string;
  organizationContestStatus: string;
  serviceProviderContestNote: string;
  agentContestNote: string;
  organizationContestNote: string;
  product: Product;
  orderItemToPackages: OrderItemToPackage[];
  printNodeOrderItems: PrintNodeOrderItem[];

  constructor( obj ) {
    this.quantity = 0;
    this.price = 0;
    this.currency_ID = '';
    this.cost = 0;
    this.lastUpdate = new Date();
    this.product_ID = 0;
    this.salesPrice = 0;
    this.gatewayCharge = 0;
    this.manufacturingCharge = 0;
    this.serviceProviderGatewayCharge = 0;
    this.serviceProviderManufacturingcharge = 0;
    this.printNodeManufacturingCost = 0;
    this.agentMargin = 0;
    this.serviceProviderContestStatus = 'UNCONTESTED';
    this.agentContestStatus = 'UNCONTESTED';
    this.organizationContestStatus = 'UNCONTESTED';
    this.serviceProviderContestNote = '';
    this.agentContestNote = '';
    this.organizationContestNote = '';
    // this.orderItemToPackages = [];

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if ( obj ) {
    //   for ( const prop of Object.keys(obj) ) {
    //     this[prop] = obj[prop];
    //   }
    //   if ( obj.orderItemToPackages ) {
    //     this.orderItemToPackages = [];
    //     for ( const orderItemToPackage of obj.orderItemToPackages ) {
    //       this.orderItemToPackages.push( new OrderItemToPackage( orderItemToPackage ) );
    //     }
    //   }
    //   if ( obj.printNodeOrderItems ) {
    //     this.printNodeOrderItems = [];
    //     for ( const printNodeOrderItem of obj.printNodeOrderItems ) {
    //       this.printNodeOrderItems.push( new PrintNodeOrderItem( printNodeOrderItem, printNodeOrderItem.printNodeOrder ) );
    //     }
    //   }
    //
    //   if ( obj.product ) {
    //     this.product = new Product( obj.product );
    //   } else {
    //     this.product = undefined; // new Product( undefined );
    //   }
    // } else {
    //   this.product = undefined; // new Product( undefined );
    // }
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }

  getId() {
    return this.ID;
  }

  getOrderValue() {
    return this.ID;
  }

}
