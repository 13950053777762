export class Column {
  property: string;
  type: string;
  index: number;
  ignored: boolean;
  confirmed: boolean;
  headerColumnName: string;
  firstDataValues: string[];

  constructor (index: number, headerColumnName: string, firstDataValues: string[]) {
    this.property = '';
    this.type = 'String';
    this.index = index;
    this.ignored = false;
    this.confirmed = false;
    if ( headerColumnName ) {
      this.headerColumnName = headerColumnName;
    } else {
      this.headerColumnName = '';
    }
    this.firstDataValues = firstDataValues;
  }

  getSuggestedProperty(data: Array<any>): string {
    return 'FirstName';
  }

  getPercentValidated(data: Array<any>): number {
    return 100;
  }

  isValueValid (value: any) {
    return false;
  }
}
