<ng-container *ngIf="printNodeOrderItem || changeItem.type!='REMOVE'">
  <ng-container *ngIf="!editableItem">
    <div class="row">
      <div class="cell">{{printNodeOrderItem ? printNodeOrderItem.quantity : '' }}</div>
      <div *ngIf="printNodeOrderItem" class="cell">{{printNodeOrderItem.book.title}}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="editableItem">
    <div [ngClass]="changeItem.type" class="editableRow">
      <div class="cell">
        <mat-form-field>
<!--          <mat-label>Clearable input</mat-label>-->
          <input matInput type="number" (change)="printNodeOrderItem && changeItem.quantity !== printNodeOrderItem.quantity ? changeItem.type = 'UPDATE' : (changeItem.type === 'REMOVE'? changeItem.type = 'REMOVE' : changeItem.type = 'NONE' )" [(ngModel)]="changeItem.quantity">
          <button mat-button *ngIf="changeItem.quantity" matSuffix mat-icon-button aria-label="Clear" (click)="changeItem.type = 'NONE'; printNodeOrderItem ? changeItem.quantity = printNodeOrderItem.quantity : changeItem.quantity = 1; ">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div *ngIf="printNodeOrderItem" class="cell">{{printNodeOrderItem.quantity}}</div>
      <div *ngIf="!printNodeOrderItem" class="cell"> </div>
      <div *ngIf="printNodeOrderItem" class="cell">{{printNodeOrderItem.book.title}}</div>
      <div *ngIf="!printNodeOrderItem" class="cell">
        <mat-form-field>
          <mat-label i18n="@@Select an option">Select an option</mat-label>
          <mat-select  (selectionChange)="changeProduct($event.value)" placeholder="Product" i18n-placeholder="@@Product"required>
            <ng-container *ngFor="let product of products">
              <mat-option *ngIf="!isProductAlreadyUsedInTheOrder(product.book_ID) && product.org_ID === orgId" [value]="product">{{product.title}}</mat-option>
<!--              <mat-option [value]="product">{{product.title}}</mat-option>-->
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="cellButton">
        <button *ngIf="changeItem.type !== 'REMOVE'" style="width: 120px;" mat-icon-button (click)="printNodeOrderItem ? changeItem.type = 'REMOVE' : changeItem.type = 'REMOVE'" i18n-matTooltip="@@Delete" matTooltip="Delete"><mat-icon>delete</mat-icon></button>
        <button *ngIf="changeItem.type === 'REMOVE'" style="width: 120px;" mat-icon-button (click)="changeItem.type = 'NONE'; printNodeOrderItem ? changeItem.quantity = printNodeOrderItem.quantity : changeItem.quantity = 1; " i18n-matTooltip="@@Undo Changes" matTooltip="Undo Changes"><mat-icon>undo</mat-icon></button>
      </div>
<!--      <div class="cellButton">-->
<!--        <button *ngIf="changeItem.type !== 'NONE'" style="width: 120px;" mat-icon-button (click)="changeItem.type = 'NONE'; printNodeOrderItem ? changeItem.quantity = printNodeOrderItem.quantity : changeItem.quantity = 1; " i18n-matTooltip="@@Undo Changes" matTooltip="Undo Changes"><mat-icon>undo</mat-icon></button>-->
<!--      </div>-->
    </div>
  </ng-container>
</ng-container>
