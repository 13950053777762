import {Product} from "../../../../model/Product";
import {PossibleMatch} from "./PossibleMatch";

export class ProductCode {
  csvProductCode: string;
  product: Product;
  product_ID: number;
  possibleMatches: PossibleMatch[] = [];
  minPossibleMatchIntersections = 9999;
  maxPossibleMatchIntersections = 0;

  constructor (csvProductCode: string, products: Product[]) {
    this.csvProductCode = csvProductCode;
    this.identifyPossibleMatches(products);
  }

  setProduct( product: Product ) {
    this.product = product;
    if ( this.product ) {
      this.product_ID = this.product.ID;
    } else {
      this.product_ID = 0;
    }
  }
  identifyPossibleMatches(products: Product[]) {
    if ( !products || products.length === 0 ) {
      return;
    }
    this.minPossibleMatchIntersections = 9999;
    this.maxPossibleMatchIntersections = 0;
    const csvProductCodeParts: string[] = this.getParts(this.csvProductCode);
    for ( const product of products) {
      const parts1 = this.getParts(product.title);
      const parts2 = this.getParts(product.customerProduct_ID);
      const parts = this.unique(parts1, parts2);
      const intersections = this.getIntersections(csvProductCodeParts, parts);
      if ( intersections > 0 ) {
        if ( this.maxPossibleMatchIntersections < intersections ) {
          this.maxPossibleMatchIntersections = intersections;
        }
        if ( this.minPossibleMatchIntersections > intersections ) {
          this.minPossibleMatchIntersections = intersections;
        }
        this.addObjectInOrder(new PossibleMatch(intersections, product), this.possibleMatches);
        // this.possibleMatches.push( new PossibleMatch(intersections, product));
      }
    }
    if ( this.possibleMatches.length > 0 ) {
      this.setProduct(this.possibleMatches[0].product);
    }
  }

  addObjectInOrder(object: PossibleMatch, array: Array<PossibleMatch>) {
    if ( object && array ) {
      let i = array.length - 1;
      for (; i >= 0; i--) {
        if ( array[i].matches === object.matches) {
          array.splice(i, 0);
        }
      }

      i = 0;
      for (; i < array.length && array[i].matches && array[i].matches > object.matches; i++) {
      }
      array.splice(i, 0, object);
    }
  }

  getIntersections(listA: string[], listB: string[]): number {
    let intersectionCount = 0;
    for ( const value of listA) {
      if ( listB.includes(value) ) {
        intersectionCount++;
      }
    }
    return intersectionCount;
  }

  getParts(data: string): string[] {
    if ( data === 'Math 1 A' || data === 'Math 1A' || data === 'Elementary Language Arts 1A') {
      console.log(`In getParts ${data}`);
    }
    const parts = [];
    // TODO: Do something to break apart camel case
    if ( data && data.split(' ').length > 1) {
      for ( const part of data.split(' ')) {
        if ( part.length > 1 && (/^\d/.test(part) || part.length === 2)) {
          parts.push(part.substr(0, 1));
          parts.push(part.substr(1));
        } else {
          parts.push(part);
        }
      }
    }
    if ( data && data.split('-').length > 1) {
      for ( const part of data.split('-')) {
        if ( part.length > 1 && (/^\d/.test(part) || part.length === 2)) {
          parts.push(part.substr(0, 1));
          parts.push(part.substr(1));
        } else {
          parts.push(part);
        }
      }
    }
    if ( data && data.split('_').length > 1) {
      for ( const part of data.split('_')) {
        if ( part.length > 1 && (/^\d/.test(part) || part.length === 2)) {
          parts.push(part.substr(0, 1));
          parts.push(part.substr(1));
        } else {
          parts.push(part);
        }
      }
    }
    return parts;
  }

  unique(list1: string[], list2: string[]) {
    const list = new Array();
    list1.forEach(value => { list.push(value); });
    list2.forEach(value => { list.push(value); });
    return list.filter((v, i, a) => a.indexOf(v) === i);
  }

  getBestPossibleMatches(maxNumber: number): PossibleMatch[] {
    if ( this.possibleMatches.length <= maxNumber) {
      return this.possibleMatches;
    }
    return this.possibleMatches.splice(maxNumber, this.possibleMatches.length);
  }
}
