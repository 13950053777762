<mat-card appearance="outlined" class="mat-elevation-z">
  <mat-card-content class="mat-elevation-z8" style="height: 100%;">
    <!--    <div>-->
    <!--      <button mat-icon-button color="primary" (click)="tableFilterManager.setFilter(tableFilterManager.quantityTableFilter)"><ng-container i18n="@@Under 100">Under 100</ng-container></button>-->
    <!--      <button mat-icon-button color="primary" (click)="tableFilterManager.setFilter(tableFilterManager.largeQuantityTableFilter)"><ng-container i18n="@@Over 100">Over 100</ng-container></button>-->
    <!--    </div>-->
    <ng-container [ngSwitch]="dataServiceStatus">
      <ng-container *ngSwitchCase="'LOADING'"><h2 style="padding: 20px; text-align: center;" i18n="@@Loading Data">Loading Data</h2><mat-spinner style="margin:0 auto;"></mat-spinner></ng-container>
      <ng-container *ngSwitchCase="'INITIAL'"><h2 style="padding: 20px; text-align: center;" i18n="@@Data Not Requested Yet">Data Not Requested Yet</h2><span style="font-size: 36px; margin:0 auto;"><mat-icon [inline]="true">error</mat-icon></span></ng-container>
      <ng-container *ngSwitchCase="'ERROR'"><h2 style="padding: 20px; text-align: center;" i18n="@@Error Loading Data">Error Loading Data</h2><span style="font-size: 36px; margin:0 auto;"><mat-icon [inline]="true">error</mat-icon></span></ng-container>

      <table mat-table *ngSwitchCase="'LOADED'" #table [dataSource]="dataSource" matSort>
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span style="display: block">{{ column.header | languageString }}</span>
          </th>
          <td mat-cell *matCellDef="let row" [style]="column.styleX ? column.styleX(row) : column.style">
            <ng-container [ngSwitch]="column.type">
              <span *ngSwitchCase="'date'" class="cellSpan" [style]="column.style" [ngClass]="column.displayClass ? column.displayClass : ''">{{ column.cell(row) | date : column.dateFormat }}</span>
              <span *ngSwitchCase="'string'" class="cellSpan" [style]="column.styleX ? column.styleX(row) : column.style" [ngClass]="column.displayClass ? column.displayClass : ''">{{ column.cell(row) }}<ng-container *ngIf="column.cellRow2"><br>{{ column.cellRow2(row) }}</ng-container></span>
              <span *ngSwitchCase="'number'" class="cellSpan" [style]="column.style" [ngClass]="column.displayClass ? column.displayClass : ''">{{ column.cell(row) }}</span>
              <span *ngSwitchCase="'currency'" class="cellSpan" [style]="column.style" [ngClass]="column.displayClass ? column.displayClass : ''">{{ column.cell(row) | number:'1.2-2' }}</span>
              <span *ngSwitchCase="'link'" class="cellSpan" [style]="column.style" [ngClass]="column.displayClass ? column.displayClass : ''"><a href="{{ column.link(row) }}" target="_blank">{{ column.cell(row) }}</a></span>
              <span *ngSwitchCase="'iconButton'" class="cellSpan" [style]="column.style" [ngClass]="column.displayClass ? column.displayClass : ''">
                <button mat-icon-button [color]="column.buttonColor" (click)="eventHandler.event(column.clickAction, column.cell(row), $event.target)">
                  <mat-icon class="mat-18">{{ column.icon }}</mat-icon>
                </button>
              </span>
              <span *ngSwitchCase="'iconToggleButton'" class="cellSpan" [style]="column.style" [ngClass]="column.displayClass ? column.displayClass : ''">
                <button *ngIf="column.cell(row)==='TRUE'" mat-icon-button [color]="column.trueButtonColor" (click)="eventHandler.event(column.clickAction, column.cell(row), $event.target)">
                  <mat-icon class="mat-18">{{ column.trueIcon }}</mat-icon>
                </button>
                <button *ngIf="column.cell(row)==='FALSE'" mat-icon-button [color]="column.falseButtonColor" (click)="eventHandler.event(column.clickAction, column.cell(row), $event.target)">
                  <mat-icon class="mat-18">{{ column.falseIcon }}</mat-icon>
                </button>
                <ng-container *ngIf="column.cell(row) > 0 && column.cell(row) <= 20">
                  <button mat-icon-button [color]="column.buttonColor[column.cell(row)-1]" (click)="eventHandler.event(column.clickAction, column.cell(row), $event.target)">
                    <mat-icon class="mat-18">{{ column.icon[column.cell(row)-1] }}</mat-icon>
                  </button>
                </ng-container>
              </span>
              <span *ngSwitchCase="'icon'" class="cellSpan" [style]="column.style" [ngClass]="column.displayClass ? column.displayClass : ''">
                <mat-icon class="mat-18">{{ column.cell(row) }}</mat-icon>
              </span>
              <span *ngSwitchDefault class="cellSpan" [style]="column.style" [ngClass]="column.displayClass ? column.displayClass : ''">{{ column.cell(row) }}</span>
            </ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="showFilters">
          <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef + 'Filter'">
            <th mat-header-cell *matHeaderCellDef>
              <ng-container *ngIf="showFilters">
                <span *ngIf="column.filterType==='NumericRange'" class="headerCell">
                  <input style="width: 50px" (keyup)="tableFilterManager.applyMinRangeFilter(column.property, $event.target)"/>
                  <input style="width: 50px" (keyup)="tableFilterManager.applyMaxRangeFilter(column.property, $event.target)"/>
                </span>
                <span *ngIf="column.filterType==='Numeric'" class="headerCell">
                  <input (keyup)="tableFilterManager.applyExactNumberFilter(column.property, $event.target)"/>
                </span>
                <span *ngIf="column.filterType==='String'" class="headerCell">
                  <input (keyup)="tableFilterManager.applySubStringFilter(column.property, $event.target)"/>
                </span>
                <span *ngIf="column.filterType==='CompositeString'" class="headerCell">
                  <input (keyup)="tableFilterManager.applyCompositeSubStringFilter(column.properties, $event.target)"/>
                </span>
                <span *ngIf="column.filterType==='DataEnum'" class="headerCell">
                  <mat-select (selectionChange)="tableFilterManager.applyDataEnumFilter(column.property, $event.value, column.values)">
                    <mat-option value=""></mat-option>
                    <ng-container *ngIf="column.filterEnums">
                      <ng-container *ngFor="let enumValue of column.filterEnums" >
                        <mat-option [value]="enumValue">{{enumValue}}</mat-option>
                      </ng-container>
                    </ng-container>
                  </mat-select>
                </span>
                <span *ngIf="column.filterType==='Empty'" class="headerCell">
                </span>
              </ng-container>
            </th>
          </ng-container>
        </ng-container>

        <!--      <ng-container matColumnDef="expandedDetail">-->
        <!--        <mat-cell *matCellDef="let detail">ddddddddddddddddddddd-->
        <!--          The symbol for {{detail.element.name}} is {{detail.element.symbol}}-->
        <!--        </mat-cell>-->
        <!--      </ng-container>-->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class=""></div>
              <div class=""><span class=""></span></div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <ng-container *ngIf="showFilters">
          <tr mat-header-row *matHeaderRowDef="displayedColumnsFilters; sticky: true"></tr>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row" [cdkDetailRow]="row" [cdkDetailRowTpl]="tpl"></tr>

        <!--      <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row" [class.expanded]="expandedElement === row" (click)="expandedElement = row; poke();"></mat-row>-->
        <!--      <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>-->
      </table>
    </ng-container>
  </mat-card-content>
</mat-card>
<ng-template #tpl let-element>
  <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
    <ng-container [ngTemplateOutlet]="expandedTableRowTemplate" [ngTemplateOutletContext]="{ $implicit: element }"></ng-container>
  </div>
</ng-template>
