import {DataObject} from "./DataObject";

export class Resource implements DataObject {
  ID: number;
  org_ID: number;
  type: string;
  hash: string | null;
  hashbytes: string;
  problems: string;
  lastUpdate: Date;
  problemStatus: string;
  prepared: string;
  instructions: string;
  problemNote: string;

  constructor (obj) {
    this.ID = 0;
    this.org_ID = 0;
    this.type = '';
    this.hash = '';
    this.hashbytes = '';
    this.problems = '';
    this.lastUpdate = new Date(0);
    this.prepared = '';
    this.problemStatus = '';
    this.problemNote = '';
    this.instructions = '';

    this.lastUpdate = new Date(0);
    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if (obj) {
    //   for (const prop of Object.keys(obj)) {
    //     this[prop] = obj[prop];
    //   }
    // }
  }

  getId() {
    return this.ID;
  }

  getOrderValue() {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
