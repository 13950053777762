import {DataObject} from "./DataObject";

export class PackageType  implements DataObject {
  public ID: number;
  public name: string;
  public carrier: string;
  public height: number;
  public width: number;
  public depth: number;
  public boxWeight: number;
  public maxWeight: number;
  public smallDepth: number;
  public largeDepth: number;
  public carrierBoxName: number;
  public service: string;
  public lastUpdate: Date;

  constructor ( obj ) {
    this.ID = 0;
    this.name = '';
    this.carrier = '';
    this.height = 0;
    this.width = 0;
    this.depth = 0;
    this.boxWeight = 0;
    this.maxWeight = 0;
    this.smallDepth = 0;
    this.largeDepth = 0;
    this.carrierBoxName = 0;
    this.service = '';
    this.lastUpdate = new Date();
    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if ( obj ) {
    //   for ( const prop of Object.keys(obj) ) {
    //     this[prop] = obj[prop];
    //   }
    // }
  }

  public getId() {
    return this.ID;
  }
  public getOrderValue() {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}

