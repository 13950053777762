import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../model/User";
import {Organization} from "../../../../model/Organization";
import {StartupService} from "../../../service-ui/startup.service";
import {OrganizationService} from "../../../service-data/organization.service";
import {CatalogService} from "../../../service-data/catalog.service";

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {
  @Input() organization: Organization;
  @Input() allowAdminRescue: boolean;
  editableOrganization: Organization;
  editOrganization: boolean = false;

  constructor(public startupService: StartupService, private organizationService: OrganizationService, public catalogService: CatalogService) {
    this.editOrganization = false;
  }

  ngOnInit() {
    this.editOrganization = false;
  }

  edit() {
    this.editableOrganization = new Organization(this.organization);
    this.editOrganization = true;
  }

  cancel() {
    this.editOrganization = false;
    this.editableOrganization = undefined;
  }

  save() {
    this.organizationService.updateOrganization(this.editableOrganization).subscribe( changedOrganization => {
      console.log("Organization was changed.");
    });
    this.editOrganization = false;
    this.organizationService.reset();
  }

  revokeMicroInventory (organization: Organization) {
    // organization.microInventory = 'INACTIVE';
    this.editableOrganization.microInventory = 'INACTIVE';
    // this.organizationService.updateOrganization(organization).subscribe( x => {
    //   console.log("Revoke Micro Inventory " + organization.name);
    // });
  }
  grantMicroInventory (organization: Organization) {
    // organization.microInventory = 'ACTIVE';
    this.editableOrganization.microInventory = 'ACTIVE';
    // this.organizationService.updateOrganization(organization).subscribe( x => {
    //   console.log("Granted Micro Inventory " + organization.name);
    // });
  }


}


