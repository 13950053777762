import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {StartupService} from "../../../../service-ui/startup.service";
import {Book} from "../../../../../model/Book";
import {Body} from "../../../../../model/Body";


@Component({
  selector: 'app-project-book-body',
  templateUrl: './project-book-body.component.html',
  styleUrls: ['./project-book-body.component.css']
})
export class ProjectBookBodyComponent implements OnInit {
  @Input() book: Book;
  @Input() body: Body;

  constructor (public router: Router, public startupService: StartupService) {
  }

  ngOnInit() {
    console.log("Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
    }
  }

  pageNumbers(countBy: number) {
    const pageNumbers = [];
    for ( let i = 0; i <= this.body.numPages; i += countBy ) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }
}


