import {Item} from "./Item";
import {Product} from "../Product";
import {Draft} from "../Draft";

export class ProductItem extends Item {

  private base: Product;

  constructor( product: Product ) {
    super();
    this.base = product;
  }

  getBindingType(): string {
    if ( this.base.book ) {
      return this.base.book.bindingType;
    }
  }
  getBase(): any {
    return this.base;
  }
  getCost(): number {
    return 0;
  }

  getCoverImage(): string {
    return "";
  }

  getHeight(): number {
    if ( this.base.book && this.base.book.body ) {
      return this.base.book.body.height / 72;
    }
    return 0;
  }

  getId(): number {
    return this.base.getId();
  }

  getInteriorType(): string {
    return this.base.book.interiorType;
  }

  getLaminateType(): string {
    if ( this.base.book && this.base.book.cover ) {
      return this.base.book.cover.laminateType;
    }
    return "";
  }

  getPages(): number {
    if ( this.base.book && this.base.book.body ) {
      return this.base.book.body.numPages;
    }
    return 0;
  }

  getPaperType(): number {
    if ( this.base.book && this.base.book.body ) {
      return this.base.book.body.paperType_ID;
    }
    return 0;
  }

  getTitle(): string {
    return this.base.title;
  }

  getWeight(): number {
    return this.base.book.weight;
  }

  getWidth(): number {
    if ( this.base.book && this.base.book.body ) {
      return this.base.book.body.width / 72;
    }
    return 0;
  }
  getThickness(): number {
    if ( this.base.book ) {
      return this.base.book.thickness;
    }
    return 0;
  }

  getCaseQuantity(): number {
    if ( this.base.book ) {
      return this.base.book.caseQuantity;
    }
    return 0;
  }

  getLastUpdate(): Date {
    return this.base.lastUpdate;
  }

  getAvailableInventory(): any[] {
    const availableInventory = [];
    if ( this.base.facilityInventoryProducts ) {
      for ( const facilityInventoryProduct of this.base.facilityInventoryProducts ) {
        if ( facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited > 0 ) {
          availableInventory.push( { 'facility': '' + facilityInventoryProduct.printnode_ID, 'quantity': facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited } );
        }
      }
    }
    return availableInventory;
  }
}

