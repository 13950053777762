<div class="app-container" *ngIf="project && interior" [class.is-mobile]="mobileQuery.matches">
  <mat-sidenav-container class="app-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #sideNav mode="side" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" opened="true">
      <mat-nav-list>
        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'chapters' ? 'selected': ''" (click)="activeSideArea === 'chapters' ? activeSideArea = '' : activeSideArea = 'chapters'">
          <div class="centeredIcon">
            <mat-icon>layers</mat-icon>
            <span mat-list-text i18n="@@Chapters">Chapters</span>
          </div>
        </a>
        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'designs' ? 'selected': ''" (click)="activeSideArea === 'designs' ? activeSideArea = '' : activeSideArea = 'designs'">
          <div class="centeredIcon">
            <mat-icon>developer_board</mat-icon>
            <span mat-list-text i18n="@@Designs">Designs</span>
          </div>
        </a>
        <!--<a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'fonts' ? 'selected': ''" (click)="activeSideArea === 'fonts' ? activeSideArea = '' : activeSideArea = 'fonts'">-->
          <!--<div class="centeredIcon">-->
            <!--<mat-icon>font_download</mat-icon>-->
            <!--<span i18n="@@Fonts">Fonts</span>-->
          <!--</div>-->
        <!--</a>-->
        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'trim' ? 'selected': ''" (click)="activeSideArea === 'trim' ? activeSideArea = '' : activeSideArea = 'trim'">
          <div class="centeredIcon">
            <mat-icon>crop</mat-icon>
            <span i18n="@@Trim Size">Trim Size</span>
          </div>
        </a>
        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'showWidth' ? 'selected': ''" (click)="activeSideArea === 'showWidth' ? activeSideArea = '' : activeSideArea = 'showWidth'">
          <div class="centeredIcon">
            <mat-icon>wrap_text</mat-icon>
            <span i18n="@@Show Width">Show Width</span>
          </div>
        </a>
        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'style' ? 'selected': ''" (click)="activeSideArea === 'style' ? activeSideArea = '' : activeSideArea = 'style'">
          <div class="centeredIcon">
            <mat-icon>text_fields</mat-icon>
            <span i18n="@@Styles">Styles</span>
          </div>
        </a>

        <a class="startNavBigButton" mat-list-item [ngClass]="activeSideArea === 'audio' ? 'selected': ''" (click)="activeSideArea === 'audio' ? activeSideArea = '' : activeSideArea = 'audio'">
          <div class="centeredIcon">
            <!--<mat-icon>speaker_notes</mat-icon>-->
            <mat-icon>record_voice_over</mat-icon>
            <span i18n="@@Add Audio">Add Audio</span>
          </div>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <mat-sidenav-container *ngIf="activeSideArea !== 'audio'" class="main-content-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
        <mat-sidenav class="middleToolArea" [style.width.px]="activeSideArea === '' ? 0 : 340" #middleSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" opened="true">
          <mat-card appearance="outlined" *ngIf="activeSideArea === 'chapters'" class="sideAreaMatCard">
            <mat-card-content>
              <span>
                <h3><ng-container i18n="@@Chapters">Chatpers</ng-container>
                  <span i18n-style="@@floatEnd" style="float:right">
  <!--                  <span *ngIf="!showAddChaptersOptions" style="color: white;" (click)="showAddChaptersOptions = !showAddChaptersOptions"><mat-icon>add</mat-icon></span>-->
                    <span style="color: white;" (click)="addChapter('standard')" i18n-title="@@Add Chapter" title="Add Chapter"  i18n-defaultchaptertitle="@@DefaultChapterTitle" defaultchaptertitle="Chapter Title" i18n-defaultchaptercontent="@@DefaultChapterContent" defaultchaptercontent='<h2>Your Chapter Title Here</h><p>Replace with your content.</p>' #standard ><span class="mdi mdi-book-plus"></span></span>
                    <span style="color: white;" (click)="addChapter('copyright')" i18n-title="@@Add Copyright" title="Add Copyright"  i18n-defaultchaptertitle="@@DefaultCopyrightTitle" defaultchaptertitle="Copyright" i18n-defaultchaptercontent="@@DefaultCopyrightPageContent" defaultchaptercontent='<h3>&#x24B8; Your Name Here</h3>' #copyright ><span class="mdi mdi-copyright"></span></span>
                  </span>
                </h3>
              </span>

              <ng-container *ngFor="let chapter of project.interior.chapters"  [class.selected]="chapter === startupService.selectedChapter">
                <div class="sideAreaItem" [class.selected]="chapter === startupService.selectedChapter" (click)="onSelectChapter(chapter)" [style.color]="chapter.status === 'FAILED' ? 'red': chapter.status === 'INITIAL' ? 'yellow' : '' ">
                  <app-chapter-nav-tile [chapter]="chapter" [selectedChapter]="startupService.selectedChapter" [project]="project"></app-chapter-nav-tile>
                </div>
              </ng-container>
<!--              <span style="position: fixed; bottom: 20px; color: white;"><button class="addChaptersFAB" mat-fab color="primary" (click)="showAddChaptersOptions = !showAddChaptersOptions"><mat-icon>more_vert</mat-icon></button></span>-->
<!--              <span *ngIf="showAddChaptersOptions" style="position: fixed; bottom: 80px; color: white;"><button class="addChaptersFAB" mat-fab color="primary" (click)="showAddChaptersOptions = !showAddChaptersOptions; addChapter('standard')" i18n-title="@@Add Chapter" title="Add Chapter"  i18n-defaultchaptertitle="@@DefaultChapterTitle" defaultchaptertitle="Default Chapter Title" i18n-defaultchaptercontents="@@DefaultChapterContents" defaultchaptercontent='<h2>Your Chapter Title Here</h><p>Replace with your content.</p>' #standard><mat-icon>book</mat-icon><span> </span><ng-container i18n="@@Chapter">Chatper</ng-container></button></span>-->
<!--              <span *ngIf="showAddChaptersOptions" style="position: fixed; bottom: 140px; color: white;"><button class="addChaptersFAB" mat-fab color="primary" (click)="showAddChaptersOptions = !showAddChaptersOptions; addChapter('copyright')" i18n-title="@@Add Copyright" title="Add Copyright"  i18n-defaultchaptertitle="@@DefaultCopyrightTitle" defaultchaptertitle="Default Copyright Title" i18n-defaultchaptercontents="@@DefaultCopyrightPageContents" defaultchaptercontent='<h3>&#x24B8; Your Name Here</h3>' #copyright ><mat-icon>copyright</mat-icon><span> </span><ng-container i18n="@@Copyright">Copyright</ng-container></button></span>-->
            </mat-card-content >
          </mat-card>
          <mat-card appearance="outlined" *ngIf="activeSideArea === 'designs'" class="sideAreaMatCard">
            <mat-card-content >
              <h3 i18n="@@Designs">Designs</h3>
              <ng-container *ngIf="startupService.project && startupService.project.interior && startupService.project.interior.interiorTheme && startupService.project && themeService.interiorThemes && themeService.interiorThemes.length > 0">
                <div  class="sideAreaItem interiorTheme" (click)="setInteriorThemeId(interiorTheme)" [style.fontWeight]="interiorTheme.name === startupService.project.interior.interiorTheme.name ? 'bold': 'normal'" [ngClass]="interiorTheme.name === startupService.project.interior.interiorTheme.name ? 'selected' : ''" *ngFor="let interiorTheme of themeService.interiorThemes">
                  <h4>{{interiorTheme.name | languageString }}</h4>
                  <img [src]="interiorTheme.getPreviewImageURL()" style="max-width: 300px; margin-top: -10px">
                </div>
              </ng-container>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" *ngIf="activeSideArea === 'trim'" class="sideAreaMatCard">
            <mat-card-content >
              <h3 i18n="@@Trim Size">Trim Size</h3>
              <div  class="sideAreaItem" (click)="setTrimSize(trimSize)" [ngClass]="( startupService.project.width === trimSize.width && startupService.project.height === trimSize.height ) ? 'selected' : ''" *ngFor="let trimSize of trimSizes">
                <h4>{{trimSize.name | languageString }}</h4>
                <!--<img [src]="interiorTheme.getPreviewImageURL()" style="max-width: 300px; margin-top: -10px; background: white;">-->
              </div>
            </mat-card-content >
          </mat-card>
          <mat-card appearance="outlined" *ngIf="activeSideArea === 'showWidth'" class="sideAreaMatCard">
            <mat-card-content >
              <h3 i18n="@@Show Text In Editor The Width Of The Trim Size">Show Text In Editor The Width Of The Trim Size</h3>
              <div class="sideAreaItem">
                <mat-slide-toggle color="primary" labelPosition="before"  [checked]="startupService.useBookWidthInEditor" (toggleChange)="startupService.useBookWidthInEditor = !startupService.useBookWidthInEditor;" i18n="@@Show Approximate Book Width In Editor">Show Approximate Book Width In Editor</mat-slide-toggle>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" *ngIf="activeSideArea === 'style'" class="sideAreaMatCard">
            <mat-card-content>
              <h3><span i18n="@@Style">Style</span><span style="padding-inline-start: 10px; font-size: 0.7em;"><mat-slide-toggle color="primary" labelPosition="before"  [checked]="startupService.showCurrentlySelectedElementInteriorStyles" (toggleChange)="startupService.showCurrentlySelectedElementInteriorStyles = !startupService.showCurrentlySelectedElementInteriorStyles;" i18n="@@Current Text Styles Only">Current Text Styles Only</mat-slide-toggle></span></h3>
              <app-interior-theme-editor [selectedElements]="startupService.selectedElements" [interiorTheme]="interior.interiorTheme" [document]="startupService.editorDocument"></app-interior-theme-editor>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" *ngIf="activeSideArea === 'audio'" class="sideAreaMatCard">
            <mat-card-content >
              <h3 i18n="@@Upload Audio For Transcription">Upload Audio For Transcription</h3>
              <div *ngIf="!startupService.showUploadCheckout">
                <div style="height: 25px">
                  <span i18n-style="@@floatEnd" style="float:right" class="toolbarButton fa fa-close" (click)="startupService.showUpload = !startupService.showUpload" i18n-title="@@Cancel" title="Cancel"></span>
                </div>
                <div class="audioFileAddArea">
                  <app-upload [project]="project"></app-upload>
                </div>
              </div>
              <div *ngIf="startupService.showUploadCheckout">
                <div style="height: 25px">
                  <span i18n-style="@@floatEnd" style="float:right" class="toolbarButton fa fa-close" (click)="startupService.showUpload = !startupService.showUpload" i18n-title="@@Cancel" title="Cancel"></span>
                </div>
                <div class="audioFileAddArea">
                  <app-cart-page></app-cart-page>
                </div>
              </div>
            </mat-card-content >
          </mat-card>
        </mat-sidenav>
        <mat-sidenav-content [style.marginLeft.px]="activeSideArea === '' ? 0 : 340" class="mainArea m2app-dark" style="overflow: hidden;">
          <mat-card appearance="outlined" class="chapterArea">
            <mat-card-content >
              <app-chapter *ngIf="startupService.selectedChapter && project.interior.chapters.indexOf(startupService.selectedChapter) > -1" class="chapterArea2" [project]="project" [chapter]="startupService.selectedChapter" [sizeWidth]="sizeWidth" [interiorTheme]="project.interior.interiorTheme"  [trimSizeChange]="startupService.trimSizeChange"  [showAudioCertantity]="startupService.showAudioCertantity" [useBookWidthInEditor]="startupService.useBookWidthInEditor" [debounce]="500" [config]="getConfig()"></app-chapter>
            </mat-card-content >
          </mat-card>
        </mat-sidenav-content>
      </mat-sidenav-container>
      <mat-card appearance="outlined" *ngIf="activeSideArea === 'audio'">
        <mat-card-content >
          <h3 i18n="@@Upload Audio For Transcription">Upload Audio For Transcription</h3>
          <div>
            <div style="height: 25px">
              <span i18n-style="@@floatEnd" style="float:right" class="toolbarButton fa fa-close" (click)="startupService.showUpload = !startupService.showUpload" i18n-title="@@Cancel" title="Cancel"></span>
            </div>
            <div class="audioFileAddArea">
              <app-upload [project]="project"></app-upload>
              <app-cart-page></app-cart-page>
            </div>
          </div>
        </mat-card-content >
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
