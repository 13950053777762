import {DataObject} from "./DataObject";

export class Address implements DataObject {
  ID: number;
  org_ID: number;
  firstname: string;
  lastname: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalcode: string;
  email: string;
  phoneCountry: string;
  phone: string;
  validated: boolean;
  override: boolean;
  default: boolean;

  constructor (obj: any) {
    this.ID = 0;
    this.org_ID = 0;
    this.firstname = '';
    this.lastname = '';
    this.company = '';
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.state = '';
    this.country = '';
    this.postalcode = '';
    this.email = '';
    this.phoneCountry = '';
    this.phone = '';
    this.validated = false;
    this.override = false;
    this.default = false;

    if (obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
  }
  getId() {
    return this.ID;
  }

  getOrderValue() {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}

