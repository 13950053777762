import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {OrganizationService} from "../../service-data/organization.service";
import {Organization} from "../../../model/Organization";
import {Umbrella} from "../../../model/Umbrella";
import {UmbrellaService} from "../../service-data/umbrella.service";
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subject} from "rxjs";

const papa = require('papaparse');

@Component({
  selector: 'app-print-node-order-search',
  templateUrl: './print-node-order-search.component.html',
  styleUrls: ['./print-node-order-search.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class PrintNodeOrderSearchComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, RoleChangeListener {
  orgId: number = 0;
  orderId: number = 0;
  printNodeOrderId: number = 0;
  name: string = '';
  address: string = '';
  city: string = '';
  state: string = '';
  postalcode: string = '';
  country: string = 'US';
  tag: string = '';
  tag4: string = '';
  hold: string = '';
  carrier: string = '';
  shippingStatus: string = '';
  minQuantity: number = 0;
  maxQuantity: number = 100000000;
  minWeight: number = 0;
  maxWeight: number = 100000000;
  minCreated: Date = new Date();
  maxCreated: Date = new Date();
  trackingNumber: string = '';
  inventoryType: string = '';

  datePickerStartDate = new Date(2021, 3, 1);

  printNodeOrders: PrintNodeOrder[] = [];
  organizations: Organization[] = [];
  umbrellas: Umbrella[] = [];

  selectedOrganization = 0;
  selectedUmbrella = 0;

  // Table
  displayedColumns = ['organizationName', 'orderId', 'shipmentId', 'created', 'shippedTimestamp', 'name', 'address', 'city', 'state', 'postalcode', 'quantity', 'carrier', 'trackingNumbers'];
  dataSource = new MatTableDataSource(this.printNodeOrders);

  @ViewChild(MatSort) sort: MatSort;


  formGroup: FormGroup;

  eventHandler: EventHandler;


  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  role: string;

  searchingMessage = 'No search results found.';

  expandedElement: any;

  constructor(public router: Router, public startupService: StartupService, private printNodeOrderService: PrintNodeOrderService, private organizationService: OrganizationService, private umbrellaService: UmbrellaService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, private formBuilder: FormBuilder) {
    this.startupService.addRoleChangeListener(this);
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.resetPrintNodeOrderData();
    this.getData();
  }

  ngOnInit() {
    this.minCreated = new Date();
    this.minCreated.setMonth(this.minCreated.getMonth() - 1 );
    this.maxCreated = new Date();
    console.log("Checking if the printNodeOrder is already authenticated");
    this.startupService.isAuthenticated(this);
    this.formGroup = this.formBuilder.group({
      name: [this.name, [Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      address: [this.address, [Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      city: [this.city, [Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      state: [this.state, [Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      country: [this.country, [Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      postalcode: [this.postalcode, [Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      umbrella: [this.selectedUmbrella, [Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      organization: [this.selectedOrganization, [Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      fromDate: [this.minCreated, Validators.required],
      toDate: [this.maxCreated, Validators.required],
      // fromDate: new FormControl(this.minCreated, Validators.required),
      // toDate: new FormControl(this.maxCreated, Validators.required)
    });
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  resetPrintNodeOrderData() {
    this.printNodeOrders = [];
    this.dataSource = new MatTableDataSource(this.printNodeOrders);
    this.dataSource.sort = this.sort;
  }

  getData(): void {
    // Get that data that is NOT the printNodeOrder data -- that data is only searched with the search button is selected
    this.organizationService.getItems()
      .subscribe(organizations => {
        this.organizations = organizations;
      });

    // TODO: Verify that we don't need to reset the Umbrellas list
    this.umbrellaService.loadAll();
    this.umbrellaService.objects.subscribe(objects => {
      this.umbrellas = objects;
    });
  }

  getPrintNodeOrderData(): void {
    const filter = this.getFilter();
    // this.role = this.printNodeOrderService.getRole(JSON.stringify(filter));
    this.resetPrintNodeOrderData();

    this.searchingMessage = 'Searching....';
    this.printNodeOrderService.getFilteredItems(filter)
      .subscribe(printNodeOrders => {
        this.printNodeOrders = printNodeOrders;
        this.dataSource = new MatTableDataSource(this.printNodeOrders);
        this.dataSource.sort = this.sort;
        if ( this.printNodeOrders.length === 0 ) {
          this.searchingMessage = 'No search results found.';
        } else {
          this.searchingMessage = '';
        }
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  search(): void {
    console.log(`Searching for ${this.getFilter()}`);
    this.getPrintNodeOrderData();
  }
  downloadCSV(): void {
    const csvData = [];

    for ( const printNodeOrder of this.printNodeOrders ) {
      const item = {};
      item['OrderId'] = printNodeOrder.order_ID;
      item['ShipmentId'] = printNodeOrder.ID;
      item['Name'] = ( printNodeOrder.firstname ? ( printNodeOrder.firstname + ' ' ) : '' )  + ( printNodeOrder.lastname ? printNodeOrder.lastname : '' );
      item['Address1'] = printNodeOrder.address1;
      item['Address2'] = printNodeOrder.address2;
      item['City'] = printNodeOrder.city;
      item['State'] = printNodeOrder.state;
      item['Contry'] = printNodeOrder.country;
      item['PostalCode'] = printNodeOrder.postalcode;
      item['Quantity'] = printNodeOrder.quantity;
      item['Carrier'] = printNodeOrder.carrier;
      item['OrderedDate'] = printNodeOrder.created;
      item['ShippedDate'] = printNodeOrder.shippedTimestamp;
      item['TrackingNumbers'] = printNodeOrder.trackingNumbers;
      csvData.push(item);
    }
    const csv = papa.unparse(csvData, {
      quotes: true,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
      columns: null
    });
    const dateForFilename = new Date();
    const filename = 'OrderShipmentDetails-' + dateForFilename.getFullYear() + '.' + ( dateForFilename.getMonth() + 1 ) + '.' + dateForFilename.getDate() + '.csv';
    // const blob = new Blob([JSON.stringify({a: '3333' })], {type: 'text/plain'});
    const blob = new Blob([csv], {type: 'text/plain'});
    const e = document.createEvent('MouseEvents');
    const a = document.createElement('a');
    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
    e.initEvent('click', true, false);
    a.dispatchEvent(e);
  }

  clear(): void {
    this.orgId = 0;
    this.orderId = 0;
    this.printNodeOrderId = 0;
    this.name = '';
    this.address = '';
    this.city = '';
    this.state = '';
    this.postalcode = '';
    this.country = 'US';
    this.tag = '';
    this.tag4 = '';
    this.hold = '';
    this.carrier = '';
    this.shippingStatus = '';
    this.minQuantity = 0;
    this.maxQuantity = 100000000;
    this.minWeight = 0;
    this.maxWeight = 100000000;
    this.minCreated = new Date();
    this.minCreated.setMonth(this.minCreated.getMonth() - 1 );
    this.maxCreated = new Date();
    this.trackingNumber = '';
    this.inventoryType = '';

    this.selectedOrganization = 0;
    this.selectedUmbrella = 0;
    this.resetPrintNodeOrderData();
    this.searchingMessage = 'Select something to search.';
  }

  getFilter(): any {
    const filter = { };
    if ( this.name ) {
      if ( !filter['like'] ) {
        filter['like'] = {};
      }
      filter['like']['concat(printNodeOrder.firstName, printNodeOrder.lastName)'] = '%' + this.name + '%';
    }
    if ( this.address ) {
      if ( !filter['like'] ) {
        filter['like'] = {};
      }
      filter['like']['printNodeOrder.address1'] = '%' + this.address + '%';
    }
    if ( this.city ) {
      if ( !filter['like'] ) {
        filter['like'] = {};
      }
      filter['like']['printNodeOrder.city'] = '%' + this.city + '%';
    }
    if ( this.state ) {
      if ( !filter['eq'] ) {
        filter['eq'] = {};
      }
      filter['eq']['printNodeOrder.state'] = this.state;
    }

    if ( this.postalcode ) {
      if ( !filter['like'] ) {
        filter['like'] = {};
      }
      filter['like']['printNodeOrder.postalcode'] = '%' + this.postalcode + '%';
    }

    if ( this.shippingStatus ) {
      if ( !filter['eq'] ) {
        filter['eq'] = {};
      }
      filter['eq']['printNodeOrder.shippingStatus'] = this.shippingStatus;
    }

    if ( this.carrier ) {
      if ( !filter['eq'] ) {
        filter['eq'] = {};
      }
      filter['eq']['printNodeOrder.carrier'] = this.carrier;
    }

    if ( this.selectedUmbrella !== 0 ) {
      if ( !filter['eq'] ) {
        filter['eq'] = {};
      }
      filter['eq']['organization.agent_ID'] = this.selectedUmbrella;
    }

    if ( this.selectedOrganization !== 0 ) {
      if ( !filter['eq'] ) {
        filter['eq'] = {};
      }
      filter['eq']['organization.ID'] = this.selectedOrganization;
    }

    if ( this.maxQuantity < 100000000 ) {
      if ( !filter['le'] ) {
        filter['le'] = {};
      }
      filter['le']['printNodeOrder.quantity'] = this.maxQuantity;
    }

    if ( this.minQuantity > 0 ) {
      if ( !filter['ge'] ) {
        filter['ge'] = {};
      }
      filter['ge']['printNodeOrder.quantity'] = this.minQuantity;
    }


    if ( this.maxCreated ) {
      // Needs to be the next Day to be inclusive because of the hour of the day.
      const mCreated = new Date();
      mCreated.setDate(new Date(this.maxCreated).getDate() + 1);
      if ( !filter['le'] ) {
        filter['le'] = {};
      }
      filter['le']['printNodeOrder.created'] = mCreated;
    }

    if ( this.minCreated ) {
      if ( !filter['ge'] ) {
        filter['ge'] = {};
      }
      filter['ge']['printNodeOrder.created'] = this.minCreated;
    }

    return filter;

  }

  isReadyToSearch(): boolean {
    const filter = this.getFilter();
    if (  Object.keys(filter).length > 0 ) {
      return true;
    }
    return false;
  }
}
