<div class="body" style="margin-top: 20px; margin-left:20px; margin-right:20px;overflow-y: scroll;">
<!--  <div class="spread">-->
<!--    <div class="oneSide spreadStart" [ngClass]="'0'">-->
<!--    </div>-->
<!--    <div class="oneSide spreadEnd" [ngClass]="'1'">-->
<!--      <app-resource [resourceId]="book.body.resource_ID"-->
<!--                    [verticalShift]="book.body.leftVerticalShift"-->
<!--                    [horizontalShift]="book.body.leftHorizontalShift"-->
<!--                    [scale]="book.body.documentScale"-->
<!--                    [dpi]="'72'"-->
<!--                    [pageNumber]="'1'"-->
<!--                    [orgId]="book.org_ID"-->
<!--                    [bodyOrCover]="'BODY'"-->
<!--                    [bindingType]="book.bindingType">-->
<!--      </app-resource>-->
<!--    </div>-->
<!--  </div>-->
  <div class="spread" *ngFor="let pageNumber of pageNumbers( 2 )">
    <app-spread [resourceId]="book.body.resource_ID"
                  [book]="book"
                  [leftVerticalShift]="book.body.leftVerticalShift"
                  [leftHorizontalShift]="book.body.leftHorizontalShift"
                  [rightVerticalShift]="book.body.rightVerticalShift"
                  [rightHorizontalShift]="book.body.rightHorizontalShift"
                  [scale]="book.body.documentScale"
                  [dpi]="'72'"
                  [leftPageNumber]="pageNumber"
                  [rightPageNumber]="pageNumber + 1"
                  [orgId]="book.org_ID"
                  [bodyOrCover]="'BODY'"
                  [bindingType]="book.bindingType">
    </app-spread>
  </div>

<!--  <div class="spread" *ngFor="let pageNumber of pageNumbers( 2 ) ">-->
<!--    <div class="oneSide spreadStart">-->
<!--      <app-resource [resourceId]="book.body.resource_ID"-->
<!--                    [verticalShift]="book.body.leftVerticalShift"-->
<!--                    [horizontalShift]="book.body.leftHorizontalShift"-->
<!--                    [scale]="book.body.documentScale"-->
<!--                    [dpi]="'72'"-->
<!--                    [pageNumber]="pageNumber"-->
<!--                    [orgId]="book.org_ID"-->
<!--                    [bodyOrCover]="'BODY'"-->
<!--                    [bindingType]="book.bindingType">-->
<!--      </app-resource>-->
<!--    </div>-->
<!--    <div class="oneSide spreadEnd">-->
<!--      <app-resource [resourceId]="book.body.resource_ID"-->
<!--                    [verticalShift]="book.body.leftVerticalShift"-->
<!--                    [horizontalShift]="book.body.leftHorizontalShift"-->
<!--                    [scale]="book.body.documentScale"-->
<!--                    [dpi]="'72'"-->
<!--                    [pageNumber]="pageNumber+1"-->
<!--                    [orgId]="book.org_ID"-->
<!--                    [bodyOrCover]="'BODY'"-->
<!--                    [bindingType]="book.bindingType">-->
<!--      </app-resource>-->
<!--    </div>-->
<!--  </div>-->
</div>
