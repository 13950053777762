<div *ngIf="!renameChapter || chapter!=selectedChapter" class="chapterTitle">{{chapter.title}}</div>
<div *ngIf="selectedChapter===chapter" class="selectedChapterControls">
  <span *ngIf="renameChapter">
    <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="text" i18n-title="@@Rename the chapter"  title="Rename the chapter." [ngModel]="chapter.title" #placeHolderChapterName maxlength="128" size="29"></textarea>
    <span *ngIf="placeHolderChapterName.value.trim()!==chapter.title" >
      <span i18n-title="@@Save the new name of the chapter."  title="Save the new name of the chapter." *ngIf="!isChapterNameUsed()" (click)="renameChapter = !renameChapter; saveChapterValue();"><mat-icon>check</mat-icon></span>
    </span>
    <span i18n-title="@@Cancel the new name of the chapter." title="Cancel the new name of the chapter." (click)="renameChapter = !renameChapter; cancelChapterValue();"><mat-icon>close</mat-icon></span>
  </span>
  <span *ngIf="!renameChapter" (click)="renameChapter = !renameChapter" i18n-title="@@Rename Chapter" title="Rename Chapter"><mat-icon>edit</mat-icon></span>
  <!--<span *ngIf="!renameChapter" (click)="copyChapterCallDialog(chapter)" i18n-title="@@Copy Chapter" title="Copy Chapter"><mat-icon>file_copy</mat-icon></span>-->
  <span *ngIf="!renameChapter && chapterIndex() > 0" (click)="moveChapter(chapter, -1)" i18n-title="@@Move Chapter Up" title="Move Chapter Up"><mat-icon>arrow_upward</mat-icon></span>
  <span *ngIf="!renameChapter && chapterIndex() < project.interior.chapters.length-1" (click)="moveChapter(chapter, 1)" i18n-title="@@Move Chapter Down" title="Move Chapter Down"><mat-icon>arrow_downward</mat-icon></span>

  <span *ngIf="!renameChapter && startupService.showStructure" class="trashColor" i18n-style="@@floatEnd" style="float:right" (click)="deleteChapter(chapter)"  i18n-title="@@Delete Chapter"  title="Delete Chapter"><mat-icon>delete</mat-icon></span>
  <span *ngIf="!renameChapter && !startupService.showStructure" class="trashColor" (click)="deleteChapter(chapter)"  i18n-title="@@Delete Chapter"  title="Delete Chapter"><mat-icon>delete</mat-icon></span>
</div>
