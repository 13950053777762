import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {AddressIssueOrderDeliverToService} from "../../service-data/address-issue-order-deliver-to.service";
import {OrderDeliverTo} from "../../../model/OrderDeliverTo";
import {Subject} from "rxjs";

@Component({
  selector: 'app-address-issues',
  templateUrl: './address-issues.component.html',
  styleUrls: ['./address-issues.component.css']
})

export class AddressIssuesComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [
    { columnDef: 'Org Id',                        header: 'Org Id',               sort: true,  type: 'number',    filterType: 'String', style: 'text-align: right',         property: 'org_ID',                                                                 cell: (element: any) => `${element.order.org_ID}` },
    { columnDef: 'Order Id',                        header: 'Order Id',               sort: true,  type: 'number',    filterType: 'String', style: 'text-align: right',         property: 'order_ID',                                                                 cell: (element: any) => `${element.order.ID}` },
     // { columnDef: 'ID',                        header: 'ID',               sort: true,  type: 'number',    filterType: 'String', style: 'text-align: right',         property: 'ID',                                                                 cell: (element: any) => `${element.ID}` },
     // { property: 'tag',             columnDef: 'tag',             header: 'Tag',              sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.tag}`  },
    // { property: 'tag',             columnDef: 'tag',             header: 'Tag',              sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: left',                                               cell: (element: any) => `${element.tag}`  },
    // { property: 'name',            columnDef: 'name',            header: 'Name',             sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: left',                                               cell: (element: any) => `${element.firstname} ${element.lastname}`,                        cellRow2: (element: any) => `${element.company}`  },
    // { property: 'address',         columnDef: 'address',         header: 'Address',          sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: left',                                               cell: (element: any) => `${element.address1} ${element.address2 ? element.address2 : ''}`, cellRow2: (element: any) => `${element.city} ${element.state} ${element.postalcode ? element.postalcode : ''} ${element.country ? element.country : ''}`  },
    { columnDef: 'name',                       header: 'Name',             sort: true,  type: 'string',    filterType: 'CompositeString', style: 'text-align: left', properties: ['firstname', 'lastname', 'company'],                               cell: (element: any) => `${element.firstname.toUpperCase()} ${element.lastname.toUpperCase()}`,                        cellRow2: (element: any) => `${element.company}`  },
    { columnDef: 'address',                    header: 'Address',          sort: true,  type: 'string',    filterType: 'CompositeString', style: 'text-align: left', properties: ['address1', 'address2', 'city', 'state', 'postalcode', 'country'], cell: (element: any) => `${element.address1.toUpperCase()} ${element.address2 ? element.address2.toUpperCase() : ''}`, cellRow2: (element: any) => `${element.city.toUpperCase()} ${element.state.toUpperCase()} ${element.postalcode ? element.postalcode : ''} ${element.country ? element.country.toUpperCase() : ''}`  },
    // { property: 'weight',          columnDef: 'weight',          header: 'Weight',           sort: true,  type: 'number',    filterType: 'NumericRange', style: 'text-align: right',                                              cell: (element: any) => `${Math.round(element.weight * 10) / 10}` },
     // { property: 'phone',       columnDef: 'phone',      header: 'Phone',      sort: true,  type: 'string',     filterType: 'String',     style: 'text-align: right',  cell: (element: any) => `${element.phone}` },
     // { property: 'email',       columnDef: 'email',      header: 'Email',      sort: true,  type: 'string',     filterType: 'String',     style: 'text-align: right',  cell: (element: any) => `${element.email}` },
     { columnDef: 'addressValidationStatus',   header: 'Validation',      sort: true,  type: 'string',     filterType: 'String',     style: 'text-align: right',     property: 'addressValidationStatus',                                            cell: (element: any) => `${element.addressValidationStatus}` },
     { columnDef: 'addressValidationMessage',  header: 'Message',      sort: true,  type: 'string',     filterType: 'String',     style: 'text-align: right',        property: 'addressValidationMessage',                                           cell: (element: any) => `${element.addressValidationMessage}` }
  ];

  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<OrderDeliverTo>();
  public renderedData: OrderDeliverTo[];
  tableFilterManager: TableFilterManager<OrderDeliverTo> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor(public router: Router, public startupService: StartupService, private addressIssueOrderDeliverToService: AddressIssueOrderDeliverToService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.addRoleChangeListener(this);
    // this.startupService.touchLastPrintNodeOrderActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the printNodeOrder is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      // if ( d && d.length > 0 ) {
      //   this.renderedData = d;
      // }
      this.renderedData = this.dataSource.data;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: OrderDeliverTo, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
    // this.dataSource.paginator = this.paginator;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  // onSelect(printNodePrintNodeOrderItem: PrintNodePrintNodeOrderItem): void {
  //   this.selectedPrintNodePrintNodeOrderItem = printNodePrintNodeOrderItem;
  //   if ( this.selectedPrintNodePrintNodeOrderItem ) {
  //     this.selectedPrintNodePrintNodeOrder = this.selectedPrintNodePrintNodeOrderItem.printNodePrintNodeOrder;
  //   }
  // }

  getData(): void {
    // this.dataSource.data = [];
    this.addressIssueOrderDeliverToService.getOrderDeliverTos()
      .subscribe(orderDeliverTos => {
        // for (const printNodeOrder of printNodeOrders) {
        //   console.log("In PrintNodeOrderComponent getting data from PrintNodeOrderService: " + JSON.stringify(printNodeOrder));
        //   this.dataSource.data.push(printNodeOrder);
        // }
        this.dataSource.data = orderDeliverTos;
        this.dataSource.filter = '' + Math.random();
        // this.table.renderRows();
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  refresh () {
    this.addressIssueOrderDeliverToService.reset();
    this.getData();
  }
}
