import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../model/User";
import {Organization} from "../../../../model/Organization";
import {StartupService} from "../../../service-ui/startup.service";
import {Umbrella} from "../../../../model/Umbrella";
import {Router} from "@angular/router";
import {OrganizationService} from "../../../service-data/organization.service";
import {UmbrellaService} from "../../../service-data/umbrella.service";

@Component({
  selector: 'app-umbrella',
  templateUrl: './umbrella.component.html',
  styleUrls: ['./umbrella.component.css']
})
export class UmbrellaComponent implements OnInit {
  @Input() umbrella: Umbrella;
  @Input() allowAdminRescue: boolean;
  editableObject: Umbrella;
  editObject: boolean = false;

  constructor(public startupService: StartupService, public router: Router, private umbrellaService: UmbrellaService) {
    this.editObject = false;
  }

  ngOnInit() {
    this.editObject = false;
  }

  edit() {
    this.editableObject = new Umbrella(this.umbrella);
    this.editObject = true;
  }

  cancel() {
    this.editObject = false;
    this.editableObject = undefined;
  }

  save() {
    this.umbrellaService.update(this.editableObject);
    // .subscribe( changedObject => {
    //   console.log("Object was changed.");
    // });
    this.editObject = false;
    this.umbrellaService.reset();
  }
}
