<!--<div  i18n-dir="@@dir" dir="ltr" i18n-style="@@direction" style="direction: ltr">-->
<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon></button>
      </span>
      <span class="app-toolbar-filler"></span>
      <h2><span class="toolbarProductTitle" i18n="@@My Book Shelf">My Book Shelf</span></h2>
    </div>
    <span class="app-toolbar-filler"></span>
    <div>
      <span>
        <button [mat-menu-trigger-for]="languagesMenu" mat-raised-button color="primary" (click)="showLanguagesMenu = !showLanguagesMenu" title="Change Language" i18n-title="@@Change Language"><mat-icon>language</mat-icon> </button>
        <mat-menu #languagesMenu="matMenu">
          <a mat-menu-item i18n-href="@@enURL" href="/" i18n="@@English">English</a>
          <a mat-menu-item i18n-href="@@frURL" href="/" i18n="@@French">français</a>
          <a mat-menu-item i18n-href="@@esURL" href="/" i18n="@@Spanish">Español</a>
          <a mat-menu-item i18n-href="@@deURL" href="/" i18n="@@German">Deutsche</a>
          <a mat-menu-item i18n-href="@@arURL" href="/" i18n="@@Arabic" style="text-align:right">عربى</a>
          <a mat-menu-item i18n-href="@@zhURL" href="/" i18n="@@Chinese">中文</a>
          <a mat-menu-item i18n-href="@@amURL" href="/" i18n="@@Amharic">አማርኛ</a>
          <a mat-menu-item i18n-href="@@koURL" href="/" i18n="@@Korean">한국어</a>
          <a mat-menu-item i18n-href="@@roURL" href="/" i18n="@@Romanian">Română</a>
          <a mat-menu-item i18n-href="@@ruURL" href="/" i18n="@@Russian">русский</a>
        </mat-menu>
      </span>
    </div>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>

  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" opened="true">
      <mat-nav-list>
        <app-side-nav focus="products"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <div class="app-content">
        <div class="shelfThumbnailArea">
          <ng-container *ngIf="drafts && drafts.length > 0">
            <app-draft-thumbnail [draft]="draft" [drafts]="drafts" style="float:left" *ngFor="let draft of drafts" [class.selected]="draft === selectedDraft" (click)="onSelectDraft(draft)"></app-draft-thumbnail>
          </ng-container>
          <ng-container *ngIf="products && products.length > 0">
            <app-product-thumbnail [product]="product" [products]="products" style="float:left" *ngFor="let product of products" [class.selected]="product === selectedProduct" (click)="onSelectProduct(product)"></app-product-thumbnail>
          </ng-container>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
