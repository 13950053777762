import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Project } from '../../../model/editor/Project';
import { EditorProjectService } from '../../service-data/editor/editor-project.service';
import {Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import { MatDialog } from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../pipe/language.pipe";

@Component({
  selector: 'app-project-thumbnail',
  templateUrl: './project-thumbnail.component.html',
  styleUrls: ['./project-thumbnail.component.css']
})
export class ProjectThumbnailComponent implements OnInit, OnChanges {
  // @ViewChild('placeHolderProjectName') placeHolderProjectName;
  @Input() project: Project;
  @Input() projects: Project[];

  Math = Math;
  // public renameProject: boolean = false;

  constructor(public router: Router, public startupService: StartupService, private projectService: EditorProjectService, public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('Change ', changes);
  }

  viewProject(project: Project): void {
    this.router.navigate([`/secure/project/`, project.id]);
  }

  deleteProject(project: Project): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        name: project.name,
        title: LanguagePipe.get('Delete', 'compressSpaces') + ' ' + project.name + LanguagePipe.get('?', 'compressSpaces'),
        body: '', // LanguagePipe.get('Delete', 'compressSpaces') + ' ' + project.name + LanguagePipe.get('?', 'compressSpaces'),
        noButton: LanguagePipe.get('Cancel', 'compressSpaces'),
        yesButton: LanguagePipe.get('Delete', 'compressSpaces') + ' ' + project.name,
        returnValue: 'ok'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed. result[' + result + ']');
      if ( result && result === 'ok' ) {
        this.projectService.deleteProject(project).then( proj => {
          console.log('Deleted a project');
        });
      }
    });

  }

  addToCart(project: Project) {
    this.projectService.getProjectProperties(project).then( projectProperties => {
      // const item: Item = this.startupService.getCart().addProjectItem(projectProperties as ProjectProperties);
      // item.setProjectProperties(projectProperties as ProjectProperties);
    });
  }

}
