
import {Subject} from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class NavbarService {
  pageTitle: string = '';

  private stateSource = new Subject<string>();
  navState = this.stateSource.asObservable();
  state: string = 'login';
  constructor() {
    this.pageTitle = '';
    this.setNavBarState('login', "");
    this.stateSource.subscribe( state => this.state = state );
  }

  setNavBarState(state: string, pageTitle: string) {
    this.state = state;
    this.stateSource.next( state );
    if ( pageTitle ) {
      this.pageTitle = pageTitle;
    } else {
      this.pageTitle = '';
    }
  }
}
