export class ManufacturingStepType {
    name: string;

    constructor ( obj ) {
        this.name = '';
        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }
        }
    }

}
