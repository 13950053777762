import {Component, OnInit, Optional} from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-testdialog',
  templateUrl: './testdialog.component.html',
  styleUrls: ['./testdialog.component.css']
})
export class TestdialogComponent implements OnInit {

  constructor(@Optional() public dialogRef: MatDialogRef<TestdialogComponent>) {
  }

  ngOnInit() {
  }

}

