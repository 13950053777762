import {FontSettings} from "./FontSettings";
import {ImageOption} from "./ImageOption";
import {ColorPalette} from "../color/ColorPalette";

export class CoverThemeItemSetting {
  public type: string;
  public position: string;
  public font: string;
  public color: string;
  public rotation: number;
  public opacity: number;
  public positionFit: string; // inside: both height and width must fit inside, outside: both height and width must be outside, matchHeight: match height, matchWidth: match width, widthOutside: width must be outdide, heightOutside: height must be outside
  public imageType: string; // background, transparent, logo
  public movement: string; // vertical, horizontal, all, none
  public orderId: number;
  public defaultText: string;
  public hidden: boolean;
  public imageOptions: ImageOption[] = [];

  constructor ( obj, orderId: number) {
    this.orderId = 0;
    this.type = '';
    this.position = '';
    this.font = '';
    this.color = '';
    this.rotation = 0;
    this.opacity = 1.0;
    this.positionFit = 'inside';
    this.imageType = undefined;
    this.movement = 'none';
    this.defaultText = '';
    this.hidden = false;
    this.imageOptions = [];

    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        this[prop] = obj[prop];
      }
      if (obj.imageOptions && obj.imageOptions.length > 0) {
        // this.imageOptions = obj.imageOptions;
        this.imageOptions = [];
        for (const o of obj.imageOptions) {
          if ( o.imageKey && o.imageKey.length > 0 ) {
            const imageOption = new ImageOption(o);
            this.imageOptions.push(imageOption);
          }
        }
      }

      if ( this.getType() === 'image') {
        if ( !this.imageType ) {
          const role = this.getRole();
          if ( role.indexOf('background') !== -1 ) {
            this.imageType = 'background';
          } else if ( role.indexOf('logo') !== -1 ) {
            this.imageType = 'logo';
          } else {
            this.imageType = 'transparent';
          }
        }
      }
    }
    this.orderId = orderId;
    this.rotation = Number(this.rotation);
    if ( this.type ) {
      this.setRole(this.remapRole(this.type.split(' ')[4]));
    }

  }


  // could be replaced by checking the 'type' if the method did not require the parsing of the string every time
  hasFont(): boolean {
    if ( this.font && this.font.length > 0 ) {
      return true;
    }
    return false;
  }
  //  The type of object -- textbox, rectangle, line, circle, image -- NOTE: the property of the object holds more than just this value
  getType () {
    if ( !this.type ) {
      return 'rectangle';
    }
    try {
      return this.type.split(' ')[0];
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from type :" + this.type + ' ' + e);
      return 'rectangle';
    }
  }

  // front, back, spine or whole
  getCoverArea (): string {
    if ( !this.type ) {
      return 'front';
    }
    try {
      return this.type.split(' ')[1];
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from type :" + this.type + ' ' + e);
      return 'front';
    }
  }

  // center, ??
  getOriginX (): string {
    if ( !this.type ) {
      return 'center';
    }
    try {
      return this.type.split(' ')[2];
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from type :" + this.type + ' ' + e);
      return 'center';
    }
  }

  // center, ??
  getOriginY (): string {
    if ( !this.type ) {
      return 'center';
    }
    try {
      return this.type.split(' ')[3];
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from type :" + this.type + ' ' + e);
      return 'center';
    }
  }

  remapRole (oldRole: string ): string {
    switch ( oldRole ) {
      case 'back-address': return 'back-address';
      case 'back-author': return 'back-author-1';
      case 'back-author-1': return 'back-author-1';
      case 'back-author-2': return 'back-author-2';
      case 'back-author-picture': return 'back-author-picture';
      case 'back-background': return 'back-background';
      case 'backBack': return 'back-background';
      case 'backBack3': return 'back-rectangle-1';
      case 'back-background-1': return 'back-rectangle-1';
      case 'back-background-2': return 'back-rectangle-2';
      case 'back-background-3': return 'back-rectangle-3';
      case 'back-background-4': return 'back-rectangle-4';
      case 'back-background-5': return 'back-rectangle-5';
      case 'back-background-6': return 'back-rectangle-6';
      case 'back-rectangle-1': return 'back-rectangle-1';
      case 'back-rectangle-2': return 'back-rectangle-2';
      case 'back-rectangle-3': return 'back-rectangle-3';
      case 'back-rectangle-4': return 'back-rectangle-4';
      case 'back-rectangle-5': return 'back-rectangle-5';
      case 'back-rectangle-6': return 'back-rectangle-6';
      case 'back-text-background-1': return 'back-rectangle-1';
      case 'back-text-background-2': return 'back-rectangle-2';
      case 'back-text-background-3': return 'back-rectangle-3';
      case 'back-text-background-4': return 'back-rectangle-4';
      case 'backBack2': return 'back-rectangle-2';
      case 'back-background-image': return 'back-background-image';
      case 'back-image': return 'back-background-image';

      case 'back-barcode': return 'back-barcode';
      case 'back-category': return 'back-category';
      case 'backLine': return 'back-line-1';
      case 'back-line-1': return 'back-line-1';
      case 'back-line-2': return 'back-line-2';
      case 'back-line-3': return 'back-line-3';
      case 'back-line-4': return 'back-line-4';
      case 'back-divider-line-1': return 'back-line-1';
      case 'back-divider-line-2': return 'back-line-2';
      case 'back-divider-line-3': return 'back-line-3';
      case 'back-divider-line-4': return 'back-line-4';
      case 'back-divider-line-1': return 'back-line-1';
      case 'back-divider-line-2': return 'back-line-2';
      case 'back-divider-line-4': return 'back-line-4';
      case 'back-dividerline-3': return 'back-line-3';
      case 'back-edition': return 'back-edition';
      case 'back-heading-1': return 'back-heading-1';
      case 'back-heading-2': return 'back-heading-2';
      case 'back-heading-3': return 'back-heading-3';
      case 'back-heading-4': return 'back-heading-4';
      case 'back-heading-5': return 'back-heading-5';
      case 'back-heading-6': return 'back-heading-6';
      case 'back-image-1': return 'back-image-1';
      case 'back-image-2': return 'back-image-2';
      case 'back-ISBN': return 'back-ISBN';
      case 'back-phone': return 'back-phone';
      case 'back-price': return 'back-price';

      case 'back-publisherLogo-1': return 'back-publisher-logo-1';
      case 'back-publisher-logo-1': return 'back-publisher-logo-1';
      case 'back-publisher-logo-2': return 'back-publisher-logo-2';
      case 'back-series': return 'back-series';
      case 'back-tag-line-1': return 'back-tag-line-1';
      case 'back-tag-line-2': return 'back-tag-line-2';
      case 'back-tag-line': return 'back-tag-line-1';
      case 'backTop': return 'back-text-1';
      case 'backBody': return 'back-text-2';
      case 'backMiddle': return 'back-text-2';
      case 'backMiddle2': return 'back-text-3';
      case 'backLeft': return 'back-text-4';
      case 'backRight': return 'back-text-5';
      case 'backBottom': return 'back-text-6';
      case 'back-text-1': return 'back-text-1';
      case 'back-text-2': return 'back-text-2';
      case 'back-text-3': return 'back-text-3';
      case 'back-text-4': return 'back-text-4';
      case 'back-text-5': return 'back-text-5';
      case 'back-text-6': return 'back-text-6';
      case 'back-title-1': return 'back-title-1';
      case 'back-title-2': return 'back-title-2';
      case 'back-title-3': return 'back-title-3';
      case 'back-title-4': return 'back-title-4';
      case 'back-transparent-image': return 'back-transparent-image';
      case 'back-volume': return 'back-volume';
      case 'frontAuthor': return 'front-author-1';
      case 'frontAuthor1': return 'front-author-1';
      case 'frontAuthor2': return 'front-author-2';
      case 'frontAuthor3': return 'front-author-3';
      case 'author': return 'front-author-1';
      case 'author1': return 'front-author-1';
      case 'author2': return 'front-author-2';
      case 'author3': return 'front-author-3';
      case 'front-author': return 'front-author-1';
      case 'front-author-1': return 'front-author-1';
      case 'front-author-2': return 'front-author-2';
      case 'front-author-3': return 'front-author-3';
      case 'front-background-image': return 'front-background-image';
      case 'front-background': return 'front-background';
      case 'frontBack': return 'front-background';
      case 'frontBack2': return 'front-rectangle-2';
      case 'frontBack3': return 'front-rectangle-3';
      case 'front-text-background-1': return 'front-rectangle-1';
      case 'front-text-background-2': return 'front-rectangle-2';
      case 'front-text-background-3': return 'front-rectangle-3';
      case 'front-text-background-4': return 'front-rectangle-4';
      case 'front-rectangle-1': return 'front-rectangle-1';
      case 'front-rectangle-2': return 'front-rectangle-2';
      case 'front-rectangle-3': return 'front-rectangle-3';
      case 'front-rectangle-4': return 'front-rectangle-4';
      case 'front-background': return 'front-background';
      case 'front-border-image': return 'front-border-image';
      case 'front-image-border': return 'front-border-image';

      case 'frontCircle': return 'front-circle-1';
      case 'front-circle': return 'front-circle-1';
      case 'front-circle-1': return 'front-circle-1';
      case 'front-circle-2': return 'front-circle-2';
      case 'front-category': return 'front-category';
      case 'front-edition': return 'front-edition';
      case 'front-forward-by': return 'front-forward-by';
      case 'front-frame': return 'front-frame-image';
      case 'front-frame-1': return 'front-frame-image';
      case 'front-frame-image': return 'front-frame-image';
      case 'front-image-1': return 'front-image-1';
      case 'front-image-2': return 'front-image-2';
      case 'front-introduction-by': return 'front-introduction-by';
      case 'front-line-behind-text': return 'front-line-behind-text';
      case 'front-line': return 'front-line';
      case 'front-publisher-logo': return 'front-publisher-logo';
      case 'front-series': return 'front-series';
      case 'front-series-1': return 'front-series';
      case 'front-text-1': return 'front-text-1';
      case 'front-text-2': return 'front-text-2';
      case 'front-text-3': return 'front-text-3';
      case 'front-text-4': return 'front-text-4';
      case 'subTitle': return 'front-title-2';
      case 'front-title-1': return 'front-title-1';
      case 'front-title-2': return 'front-title-2';
      case 'front-title-3': return 'front-title-3';
      case 'front-title-4': return 'front-title-4';
      case 'frontText2': return 'front-edition';
      case 'mainTitleBackground': return 'front-frame-image';
      case 'frontLine': return 'front-line';

      case 'front-publisher': return 'front-publisher-1';
      case 'publisher': return 'front-publisher-1';
      case 'front-publisher-1': return 'front-publisher-1';
      case 'front-publisher-2': return 'front-publisher-2';
      case 'publisher2': return 'front-publisher-2';
      case 'front-publisher-logo': return 'front-publisher-logo';
      case 'series-title': return 'front-series';
      case 'seriesTitle': return 'front-series';
      case 'seriesTitle2': return 'front-series';
      case 'seriesTitle3': return 'front-series';
      case 'front-transparentImage': return 'front-transparent-image';
      case 'frontText3': return 'front-volume';
      case 'front-tag-line-1': return 'front-tag-line-1';
      case 'front-tag-line-2': return 'front-tag-line-2';
      case 'front-tag-line-3': return 'front-tag-line-3';
      case 'front-tag-line': return 'front-tag-line-1';
      case 'tagLine': return 'front-tag-line-1';
      case 'tagLine1': return 'front-tag-line-1';
      case 'tagLine2': return 'front-tag-line-2';
      case 'tagLine3': return 'front-tag-line-3';
      case 'frontText': return 'front-tag-line-1';
      case 'mainTitle': return 'front-title-1';
      case 'mainTitle1': return 'front-title-1';
      case 'mainTitle2': return 'front-title-2';
      case 'front-transparent-image': return 'front-transparent-image';
      case 'front-volume': return 'front-volume';

      case 'spineAuthor': return 'spine-author-1';
      case 'spineAuthor2': return 'spine-author-1';
      case 'spineBack': return 'spine-background';
      case 'spine-author': return 'spine-author-1';
      case 'spine-author-1': return 'spine-author-1';
      case 'spine-author-2': return 'spine-author-2';
      case 'spine-background-image': return 'spine-background-image';
      case 'spineBack': return 'spine-background';
      case 'spine-background': return 'spine-background';
      case 'spine-image-1': return 'spine-image-1';
      case 'spine-image-2': return 'spine-image-2';
      case 'spineLine': return 'spine-line-behind-text';
      case 'spine-line-behind-text': return 'spine-line-behind-text';
      case 'spine-publisher-logo': return 'spine-publisher-logo';
      case 'spineBack2': return 'spine-rectangle-1';
      case 'spineBack3': return 'spine-rectangle-2';
      case 'spine-rectangle-1': return 'spine-rectangle-1';
      case 'spine-rectangle-2': return 'spine-rectangle-2';
      case 'spine-series': return 'spine-series';
      case 'spineMainTitle': return 'spine-title-1';
      case 'spineMainTitle1': return 'spine-title-1';
      case 'spineMainTitle2': return 'spine-title-2';
      case 'spine-title-1': return 'spine-title-1';
      case 'spine-title-2': return 'spine-title-2';
      case 'spine-title-3': return 'spine-title-3';
      case 'spine-title-4': return 'spine-title-4';
      case 'spine-volume': return 'spine-volume';
      case 'whole-background': return 'whole-background';
      case 'whole-background-image': return 'whole-background-image';
      case 'wholeBack': return 'whole-background';
      case 'whole-line': return 'whole-line-1';
      case 'wholeLine': return 'whole-line-1';
      case 'whole-line-1': return 'whole-line-1';
      case 'whole-line-2': return 'whole-line-2';
      case 'wholeLine-1': return 'whole-line-1';
      case 'wholeLine-2': return 'whole-line-2';
      case 'wholeLine2': return 'whole-line-2';
      case 'whole-image-1': return 'whole-image-1';
      case 'whole-image-2': return 'whole-image-2';
      case 'whole-image-3': return 'whole-image-3';
      case 'whole-background-image': return 'whole-image-1';
      case 'wholeBack2': return 'whole-rectangle-2';
      case 'wholeBack3': return 'whole-rectangle-3';
      case 'whole-rectangle-1': return 'whole-rectangle-1';
      case 'whole-rectangle-2': return 'whole-rectangle-2';
      case 'whole-rectangle-3': return 'whole-rectangle-3';
      case 'whole-background-1': return 'whole-rectangle-1';
      case 'whole-hidden-image': return 'whole-hidden-image';
      default: {
        console.log('Unrecognized Role: ' + oldRole);
        return oldRole;
      }
    }
  }

  // the named role of the object used to look up user input values that correspond to the template
  getRole (): string {
    if ( !this.type ) {
      return '';
    }

    try {
      return this.type.split(' ')[4];
      // return this.remapRole(this.type.split(' ')[4]);
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from type :" + this.type + ' ' + e);
      return '';
    }
  }

  // Is this element active in the currently defined theme
  isActive (): boolean {
    if ( !this.type ) {
      return true;
    }
    try {
      return this.type.split(' ')[5] === 'active' ? true : false;
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from type :" + this.type + ' ' + e);
      return true;
    }
  }
  getActiveString (): string {
    if ( !this.type ) {
      return 'active';
    }
    try {
      return this.type.split(' ')[5];
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from type :" + this.type + ' ' + e);
      return 'active';
    }
  }

  getFontFamily (): string {
    if ( !this.font ) {
      return '<primary>';
    }
    try {
      return this.font.split(' ')[0];
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from font :" + this.font + ' ' + e);
      return '<primary>';
    }
  }

  getFontColor (): string {
    if ( !this.font ) {
      return '<quinary.normal>';
    }
    try {
      return this.font.split(' ')[1];
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from font :" + this.font + ' ' + e);
      return '<quinary.normal>';
    }
  }

  getTextAlign (): string {
    if ( !this.font ) {
      return 'center';
    }
    try {
      return this.font.split(' ')[2];
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from font :" + this.font + ' ' + e);
      return 'center';
    }
  }

  getFontWeight (): string {
    if ( !this.font ) {
      return '500';
    }
    try {
      return this.font.split(' ')[3];
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from font :" + this.font + ' ' + e);
      return '500';
    }
  }

  getFontStyle (): string {
    if ( !this.font ) {
      return 'normal';
    }
    try {
      return this.font.split(' ')[4];
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from font :" + this.font + ' ' + e);
      return 'normal';
    }
  }

  getFontSize (): number {
    if ( !this.font ) {
      return 12.0;
    }
    try {
      return Number.parseFloat(this.font.split(' ')[5]);
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from font :" + this.font + ' ' + e);
      return 12.0;
    }
  }

  // This is the multiplier times the default line height
  getLineHeight (): number {
    try {
      const value = this.font.split(' ')[6];
      if ( value === 'NaN' ) {
        return 1.0;
      }
      return Number.parseFloat( value );
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from font :" + this.font + ' ' + e);
      return 1.0;
    }
  }

  // This is the multiplier times the default character spacking
  getCharSpacing (): number {
    try {
      const value = this.font.split(' ')[7];
      if ( value === 'NaN' ) {
        return 1.0;
      }
      return Number.parseFloat( value );
    } catch (e) {
      console.log("Error in CoverThemeItemSetting getting a value from font :" + this.font + ' ' + e);
      return 1.0;
    }
  }

  // This is the multiplier times the default character spacking
  getRotation (): number {
    if ( this.rotation ) {
      return this.rotation;
    }
    return 0;
  }

  getDefaultText(): string {
    if ( this.defaultText ) {
      return this.defaultText;
    }
    return this.getRole().replace(/-/g, ' ');
  }

  // The background color
  getColor(): string {
    if ( this.color ) {
      return this.color;
    }
    return '<primary.normal>';
  }

  getOpacity(): number {
    if ( this.opacity ) {
      return this.opacity;
    }
    return 1;
  }

  isHidden(): boolean {
    if ( this.hidden ) {
      return true;
    }
    return false;
  }

  setHidden(hidden: boolean) {
    this.hidden = hidden;
  }

  setType (type: string) {
    this.type = type + ' ' + this.getCoverArea() + ' ' + this.getOriginX() + ' ' + this.getOriginY() + ' ' + this.getRole() + ' ' + this.getActiveString();
  }

  setCoverArea(coverArea: string) {
    this.type = this.getType() + ' ' + coverArea + ' ' + this.getOriginX() + ' ' + this.getOriginY() + ' ' + this.getRole() + ' ' + this.getActiveString();
  }
  setOriginX (originX: string) {
    this.type = this.getType() + ' ' + this.getCoverArea() + ' ' + originX + ' ' + this.getOriginY() + ' ' + this.getRole() + ' ' + this.getActiveString();
  }
  setOriginY (originY: string) {
    this.type = this.getType() + ' ' + this.getCoverArea() + ' ' + this.getOriginX() + ' ' + originY + ' ' + this.getRole() + ' ' + this.getActiveString();
  }
  setRole (role: string) {
    this.type = this.getType() + ' ' + this.getCoverArea() + ' ' + this.getOriginX() + ' ' + this.getOriginY() + ' ' + role + ' ' + this.getActiveString();
  }
  setActive (active: string) {
    this.type = this.getType() + ' ' + this.getCoverArea() + ' ' + this.getOriginX() + ' ' + this.getOriginY() + ' ' + this.getRole() + ' ' + active;
  }


  setFontFamily (fontFamily: string) {
    this.font = fontFamily + ' ' + this.getFontColor() + ' ' + this.getTextAlign() + ' ' + this.getFontWeight() + ' ' + this.getFontStyle() + ' ' + this.getFontSize() + ' ' + this.getLineHeight() + ' ' + this.getCharSpacing();
  }
  setFontColor (fontColor: string) {
    this.font = this.getFontFamily() + ' ' + fontColor + ' ' + this.getTextAlign() + ' ' + this.getFontWeight() + ' ' + this.getFontStyle() + ' ' + this.getFontSize() + ' ' + this.getLineHeight() + ' ' + this.getCharSpacing();
  }
  setTextAlign (textAlign: string) {
    this.font = this.getFontFamily() + ' ' + this.getFontColor() + ' ' + textAlign + ' ' + this.getFontWeight() + ' ' + this.getFontStyle() + ' ' + this.getFontSize() + ' ' + this.getLineHeight() + ' ' + this.getCharSpacing();
  }
  setFontWeight (fontWeight: string) {
    this.font = this.getFontFamily() + ' ' + this.getFontColor() + ' ' + this.getTextAlign() + ' ' + fontWeight + ' ' + this.getFontStyle() + ' ' + this.getFontSize() + ' ' + this.getLineHeight() + ' ' + this.getCharSpacing();
  }
  setFontStyle (fontStyle: string) {
    this.font = this.getFontFamily() + ' ' + this.getFontColor() + ' ' + this.getTextAlign() + ' ' + this.getFontWeight() + ' ' + fontStyle + ' ' + this.getFontSize() + ' ' + this.getLineHeight() + ' ' + this.getCharSpacing();
  }
  setFontSize (fontSize: number) {
    this.font = this.getFontFamily() + ' ' + this.getFontColor() + ' ' + this.getTextAlign() + ' ' + this.getFontWeight() + ' ' + this.getFontStyle() + ' ' + fontSize + ' ' + this.getLineHeight() + ' ' + this.getCharSpacing();
  }
  setLineHeight (lineHeight: number) {
    this.font = this.getFontFamily() + ' ' + this.getFontColor() + ' ' + this.getTextAlign() + ' ' + this.getFontWeight() + ' ' + this.getFontStyle() + ' ' + this.getFontSize() + ' ' + lineHeight + ' ' + this.getCharSpacing();
  }
  setCharSpacing (charSpacing: number) {
    this.font = this.getFontFamily() + ' ' + this.getFontColor() + ' ' + this.getTextAlign() + ' ' + this.getFontWeight() + ' ' + this.getFontStyle() + ' ' + this.getFontSize() + ' ' + this.getLineHeight() + ' ' + charSpacing;
  }


  setRotation (rotation: number) {
    this.rotation = rotation;
  }

  setDefaultText(defaultText: string) {
    this.defaultText = defaultText;
  }

  setColor(color: string) {
    this.color = color;
  }

  setOpacity(opacity: number) {
    this.opacity = opacity;
  }

  // order of values in position top left bottom right
  getTopPosition(): string {
    if ( !this.position || !this.position.split(' ')[0] ) {
      return '%0';
    }
    return this.position.split(' ')[0];
  }

  getTopPositionNumber(): number {
    if ( !this.position || !this.position.split(' ')[0].substring(1) ) {
      return 0;
    }
    return Number.parseFloat(this.position.split(' ')[0].substring(1));
  }

  getTopPositionCode(): string {
    if ( !this.position || !this.position.split(' ')[0].substring(0, 1) ) {
      return '%';
    }
    return this.position.split(' ')[0].substring(0, 1);
  }

  getLeftPosition(): string {
    if ( !this.position || !this.position.split(' ')[1] ) {
      return '%0';
    }
    return this.position.split(' ')[1];
  }

  getLeftPositionNumber(): number {
    if ( !this.position || !this.position.split(' ')[1].substring(1) ) {
      return 0;
    }
    return Number.parseFloat(this.position.split(' ')[1].substring(1));
  }

  getLeftPositionCode(): string {
    if ( !this.position || !this.position.split(' ')[1].substring(0, 1) ) {
      return '%';
    }
    return this.position.split(' ')[1].substring(0, 1);
  }

  getBottomPosition(): string {
    if ( !this.position || !this.position.split(' ')[2] ) {
      return '%0';
    }
    return this.position.split(' ')[2];
  }

  getBottomPositionNumber(): number {
    if ( !this.position || !this.position.split(' ')[2].substring(1) ) {
      return 0;
    }
    return Number.parseFloat(this.position.split(' ')[2].substring(1));
  }

  getBottomPositionCode(): string {
    if ( !this.position || !this.position.split(' ')[2].substring(0, 1) ) {
      return '%';
    }
    return this.position.split(' ')[2].substring(0, 1);
  }

  getRightPosition(): string {
    if ( !this.position || !this.position.split(' ')[3] ) {
      return '%0';
    }
    return this.position.split(' ')[3];
  }

  getRightPositionNumber(): number {
    if ( !this.position || !this.position.split(' ')[3].substring(1) ) {
      return 0;
    }
    return Number.parseFloat(this.position.split(' ')[3].substring(1));
  }

  getRightPositionCode(): string {
    if ( !this.position || !this.position.split(' ')[3].substring(0, 1) ) {
      return '%';
    }
    return this.position.split(' ')[3].substring(0, 1);
  }

  setTopPositionNumber(value: number) {
    this.position = this.getTopPositionCode() + value + ' ' + this.getLeftPosition() + ' ' + this.getBottomPosition() + ' ' + this.getRightPosition();
  }

  setTopPositionCode(code: string) {
    this.position = code + this.getTopPositionNumber() + ' ' + this.getLeftPosition() + ' ' + this.getBottomPosition() + ' ' + this.getRightPosition();
  }

  setLeftPositionNumber(value: number) {
    this.position = this.getTopPosition() + ' ' + this.getLeftPositionCode() + value + ' ' + this.getBottomPosition() + ' ' + this.getRightPosition();
  }

  setLeftPositionCode(code: string) {
    this.position = this.getTopPosition() + ' ' + code + this.getLeftPositionNumber() + ' ' + this.getBottomPosition() + ' ' + this.getRightPosition();
  }

  setBottomPositionNumber(value: number) {
    this.position = this.getTopPosition() + ' ' + this.getLeftPosition() + ' ' + this.getBottomPositionCode() + value + ' ' + this.getRightPosition();
  }

  setBottomPositionCode(code: string) {
    this.position = this.getTopPosition() + ' ' + this.getLeftPosition() + ' ' + code + this.getBottomPositionNumber() + ' ' + this.getRightPosition();
  }

  setRightPositionNumber(value: number) {
    this.position = this.getTopPosition() + ' ' + this.getLeftPosition() + ' ' + this.getBottomPosition() + ' ' + this.getRightPositionCode() + value;
  }

  setRightPositionCode(code: string) {
    this.position = this.getTopPosition() + ' ' + this.getLeftPosition() + ' ' + this.getBottomPosition() + ' ' + code + this.getRightPositionNumber();
  }

  getPosition (areaTop: number, areaLeft: number, areaBottom: number, areaRight: number, scale: number): PositionAndSize {
    let positionParts = this.position.split(' ');
    if (positionParts.length < 4) {
      console.log("In Cover Theme Item Settins the position value must have four components:" + this.position);
      this.position = '%10 %10 %90 %90';
      positionParts = this.position.split(' ');
    }
    const topPart = positionParts[0];
    const leftPart = positionParts[1];
    const bottomPart = positionParts[2];
    const rightPart = positionParts[3];

    const positionAndSize = new PositionAndSize();


    positionAndSize.top = areaTop;
    positionAndSize.left = areaLeft;
    positionAndSize.right = areaRight;
    positionAndSize.bottom = areaBottom;
    positionAndSize.height = areaBottom - areaTop;
    positionAndSize.width = areaRight - areaLeft;

    if (topPart.startsWith("T")) {
      positionAndSize.top = areaTop + Number.parseFloat(topPart.substring(1)) * scale;
    } else if (topPart.startsWith("B")) {
      positionAndSize.top = areaBottom + Number.parseFloat(topPart.substring(1)) * scale;
    } else if (topPart.startsWith("%")) {
      positionAndSize.top = areaTop + ( areaBottom - areaTop ) * Number(topPart.substring(1)) / 100;
    }

    if (bottomPart.startsWith("T")) {
      positionAndSize.bottom = areaTop + Number.parseFloat(bottomPart.substring(1)) * scale;
    } else if (bottomPart.startsWith("B")) {
      positionAndSize.bottom = areaBottom + Number.parseFloat(bottomPart.substring(1)) * scale;
    } else if (bottomPart.startsWith("H")) {
      positionAndSize.bottom = positionAndSize.top + Number.parseFloat(bottomPart.substring(1)) * scale;
    } else if (bottomPart.startsWith("%")) {
      positionAndSize.bottom = areaTop + ( areaBottom - areaTop ) * Number(bottomPart.substring(1)) / 100;
    }

    if (topPart.startsWith("H")) {
      positionAndSize.top = positionAndSize.bottom - Number.parseFloat(topPart.substring(1)) * scale;
    }

    if (leftPart.startsWith("L")) {
      positionAndSize.left = areaLeft + Number.parseFloat(leftPart.substring(1)) * scale;
    } else if (leftPart.startsWith("R")) {
      positionAndSize.left = areaRight + Number.parseFloat(leftPart.substring(1)) * scale;
    } else if (leftPart.startsWith("%")) {
      positionAndSize.left = areaLeft + ( areaRight - areaLeft ) * Number.parseFloat(leftPart.substring(1)) / 100;
    }

    if (rightPart.startsWith("L")) {
      positionAndSize.right = areaLeft + Number.parseFloat(rightPart.substring(1)) * scale;
    } else if (rightPart.startsWith("R")) {
      positionAndSize.right = areaRight + Number.parseFloat(rightPart.substring(1)) * scale;
    } else if (rightPart.startsWith("W")) {
      positionAndSize.right = positionAndSize.left + Number.parseFloat(rightPart.substring(1)) * scale;
    } else if (rightPart.startsWith("%")) {
      positionAndSize.right = areaLeft + (areaRight - areaLeft) * Number.parseFloat(rightPart.substring(1)) / 100;
    }

    if (leftPart.startsWith("W")) {
      positionAndSize.left = positionAndSize.right - Number.parseFloat(leftPart.substring(1)) * scale;
    }

    positionAndSize.height = positionAndSize.bottom - positionAndSize.top;
    positionAndSize.width = positionAndSize.right - positionAndSize.left;
    if (this.rotation === 90 || this.rotation === 270) {
      positionAndSize.height = positionAndSize.right - positionAndSize.left;
      positionAndSize.width = positionAndSize.bottom - positionAndSize.top;

      // Must use the centered formulas if rotated
      const horizontalChange = (positionAndSize.right - positionAndSize.left ) / 2;
      positionAndSize.left = positionAndSize.left + horizontalChange;
      positionAndSize.right = positionAndSize.right + horizontalChange;
      const verticalChange = (positionAndSize.bottom - positionAndSize.top ) / 2;
      positionAndSize.top = positionAndSize.top + verticalChange;
      positionAndSize.bottom = positionAndSize.bottom + verticalChange;
    } else {
      if (this.getOriginX() === 'center') {
        const horizontalChange = (positionAndSize.right - positionAndSize.left ) / 2;
        positionAndSize.left = positionAndSize.left + horizontalChange;
        positionAndSize.right = positionAndSize.right + horizontalChange;
      }
      if (this.getOriginY() === 'center') {
        const verticalChange = (positionAndSize.bottom - positionAndSize.top ) / 2;
        positionAndSize.top = positionAndSize.top + verticalChange;
        positionAndSize.bottom = positionAndSize.bottom + verticalChange;
      }
    }
    return positionAndSize;
  }

  public getImageType(): string {
    return this.imageType;
  }

  public setImageType( imageType: string) {
    switch ( imageType ) {
      case 'background':
      {
        this.imageType = imageType;
        break;
      }
      case 'transparent':
      {
        this.imageType = imageType;
        break;
      }
      case 'logo':
      {
        this.imageType = imageType;
        break;
      }
      default: {
        console.log('Invalid imageType value of :' + imageType );
      }
    }
  }

  public getPositionFit(): string {
    return this.positionFit;
  }

  public setPositionFit( positionFit: string) {
    switch ( positionFit ) {
      case 'inside':
      {
        this.positionFit = positionFit;
        break;
      }
      case 'outside':
      {
        this.positionFit = positionFit;
        break;
      }
      case 'matchHeight':
      {
        this.positionFit = positionFit;
        break;
      }
      case 'matchWidth':
      {
        this.positionFit = positionFit;
        break;
      }
      case 'widthOutside':
      {
        this.positionFit = positionFit;
        break;
      }
      case 'heightOutside':
      {
        this.positionFit = positionFit;
        break;
      }
      default: {
        console.log('Invalid positionFit value of :' + positionFit );
      }
    }
  }

  public getMovement(): string {
    return this.movement;
  }

  public setMovement(movement: string) {
    switch ( movement ) {
      case 'vertical':
      {
        this.movement = movement;
        break;
      }
      case 'horizontal':
      {
        this.movement = movement;
        break;
      }
      case 'none':
      {
        this.movement = movement;
        break;
      }
      case 'all':
      {
        this.movement = movement;
        break;
      }
      default: {
        console.log('Invalid movement value of :' + movement );
      }
    }
  }

  public addImageOption( imageKey: string, horizontalAdjustment: number, verticalAdjustment: number, scaleAdjustment: number) {
    const imageOption = new ImageOption(undefined);
    imageOption.imageKey = imageKey;
    imageOption.horizontalAdjustment = horizontalAdjustment;
    imageOption.verticalAdjustment = verticalAdjustment;
    imageOption.scaleAdjustment = scaleAdjustment;
    this.imageOptions.push(imageOption);
  }

  public removeImageOption( imageOption: ImageOption) {
    const index = this.imageOptions.indexOf(imageOption);
    if ( index !== -1 ) {
      this.imageOptions.splice(index, 1);
    }
  }
}
export class PositionAndSize {
  public top: number;
  public left: number;
  public bottom: number;
  public right: number;
  public width: number;
  public height: number;
}
