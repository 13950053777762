import {Product} from "./Product";

export class ProjectProperties {
  ID: number;
  title: string;
  description: string;
  accountId: number;
  verificationKey: string;

  coverBucket: string;
  bodyBucket: string;
  coverKey: string;
  bodyKey: string;
  pages: number;
  height: string;
  width: string;
  weight: number;
  firstPageHeight: string;
  firstPageWidth: string;
  minPageHeight: string;
  minPageWidth: string;
  maxPageHeight: string;
  maxPageWidth: string;
  leftVerticalShift: string;
  leftHorizontalShift: string;
  rightVerticalShift: string;
  rightHorizontalShift: string;
  numberOfPages: number;
  timestamp: number;
  pagesCoverIsBasedOn: number;
  coverImageUrl: string;

  amountOfHorizontalCoverShift: string;
  expeditedManufacturingCost: number;
  firstClassShippingCostBase: number;
  mediaShippingCostBase: number;
  priorityShippingCostBase: number;
  expressShippingCostBase: number;
  firstClassMaxBooks: number;
  firstClassShippingCostIncremental: number;
  mediaShippingCostIncremental: number;
  priorityShippingCostIncremental: number;
  expressShippingCostIncremental: number;
  normalizedPages: number;
  coilCost: number;
  bookCostColorPerfect: number;
  bookCostColorCoil: number;
  bookCostBWPerfect: number;
  bookCostBWCoil: number;
  interiorType: string;
  bindingType: string;
  language: string;
  direction: string;

  tagId: number;

  constructor (obj) {
    this.accountId = 5899;
    this.verificationKey = '';

    this.coverBucket = '';
    this.bodyBucket = '';
    this.coverKey = '';
    this.bodyKey = '';
    // coverResourceHash = coverResourceHash);
    // bodyResourceHash = bodyResourceHash);
    // coverURL = coverURL.toString());
    // bodyURL = bodyURL.toString());
    this.pages = 0;
    this.height = '9in';
    this.width = '6in';
    this.firstPageHeight = '';
    this.firstPageWidth = '';
    this.minPageHeight = '';
    this.minPageWidth = '';
    this.maxPageHeight = '';
    this.maxPageWidth = '';
    this.leftVerticalShift = "0.5in";
    this.leftHorizontalShift = "0.5in";
    this.rightVerticalShift = "0.5in";
    this.rightHorizontalShift = "-0.5in";
    this.numberOfPages = 0;
    this.timestamp = 0;
    this.pagesCoverIsBasedOn = 0;

    this.amountOfHorizontalCoverShift = "0in";
    this.expeditedManufacturingCost = 10.0;
    this.firstClassShippingCostBase = 4.0;
    this.mediaShippingCostBase = 4.5;
    this.priorityShippingCostBase = 6.5;
    this.expressShippingCostBase = 30.0;
    this.firstClassMaxBooks = 1;
    this.firstClassShippingCostIncremental = 0.5;
    this.mediaShippingCostIncremental = 0.5;
    this.priorityShippingCostIncremental = 1.0;
    this.expressShippingCostIncremental = 5.0;
    this.normalizedPages = this.pages;
    this.coilCost = 1.0 + this.pages * 0.006;
    this.bookCostColorPerfect = 4.5 + 0.065 * this.normalizedPages;
    this.bookCostColorCoil = 4.5 + 0.065 * this.normalizedPages + this.coilCost;
    this.bookCostBWPerfect = 3.5 + 0.015 * this.normalizedPages;
    this.bookCostBWCoil = 3.5 + 0.015 * this.normalizedPages + this.coilCost;
    this.interiorType = "GRAY";
    this.bindingType = "PERFECT";
    this.language = 'en';
    this.direction = 'ltr';
    this.coverImageUrl = '';
    this.tagId = 0;
    if (obj) {
      for (const prop of Object.keys(obj)) {
        this[prop] = obj[prop];
      }
    }
  }

  static create( product: Product ): ProjectProperties {
    const projectProperties = new ProjectProperties(product);
    projectProperties.bindingType = product.productBindingType;
    projectProperties.interiorType = product.book.interiorType;
    projectProperties.pages = product.productNumPages;
    projectProperties.language = product.languageCode;
    projectProperties.height = String(product.productHeight);
    projectProperties.width = String(product.productWidth);
    projectProperties.title = product.title;
    projectProperties.coverImageUrl = product.getCoverImageUrl();
    projectProperties.weight = product.productWeight;
    projectProperties.tagId = product.book.tagId;
    return new ProjectProperties(projectProperties);
  }

}
