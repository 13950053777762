import {MediaMatcher} from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component, EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Product} from "../../../model/Product";
import {ProductService} from "../../service-data/product.service";
import {Draft} from "../../../model/Draft";
import {Subject} from "rxjs";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnDestroy, OnInit {
  @ViewChild('placeHolderProductName') placeHolderProductName;
  @Input() product: Product;
  @Input() displayCover: boolean;
  @Input() editingDetails: string;
  @Output() editingDetailsChange = new EventEmitter<any>();

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  public renameProduct: boolean = false;
  sizeWidth: 0;


  constructor(public router: Router, public startupService: StartupService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, private productService: ProductService, private route: ActivatedRoute, @Inject(LOCALE_ID) localeId: string) {
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  onDataChange() {
    this.editingDetailsChange.emit(this.editingDetails);
  }

  ngOnInit() {
  }

  onSubmit() {
  }

  onResize(event: any) {
    console.log("onresize " + event);
    const w = event.target.innerWidth;
    this.sizeWidth = w;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  isProductNameUsed(): boolean {
    if ( !this.placeHolderProductName || !this.placeHolderProductName.nativeElement || !this.placeHolderProductName.nativeElement.value ) {
      return true;
    }
    const newProductName = this.placeHolderProductName.nativeElement.value.trim();
    for ( const product of this.startupService.products ) {
      if ( newProductName === product.title ) {
        return true;
      }
    }
    return false;
  }
  saveProductValue() {
    console.log('Save Product Name' + this.placeHolderProductName.nativeElement.value);
    this.product.title = this.placeHolderProductName.nativeElement.value;
    // this.productService.saveProductImmediately(this.product).then( proj => {
    //   console.log('Updated product name');
    // });
  }
  cancelProductValue() {
    this.placeHolderProductName.nativeElement.value = this.product.title;
  }

}
