import {DataObject} from "./DataObject";

export class ChargeProfile implements DataObject {
  ID: number;
  org_ID: number;
  name: string;
  value: string;
  default: boolean;
  lastUpdate: Date;

  constructor (obj: any) {
    this.ID = 0;
    this.org_ID = 0;
    this.name = '';
    this.value = '';
    this.default = false;
    this.lastUpdate = new Date();

    if (obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
  }

  getId() {
    return this.ID;
  }

  getOrderValue() {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}

