<div *ngIf="printNodeOrderItem.book && printNodeOrderItem.book.cover && printNodeOrderItem.book.body" class="printNodeOrderItems" [style.background-color]=" printNodeOrderItem.book.body.standardized !== 'COMPLETE' || printNodeOrderItem.book.cover.standardized !== 'COMPLETE' ? ( selectedPrintNodeOrderId && printNodeOrderItem.printnodeorder_ID === selectedPrintNodeOrderId ? startupService.colorAliases['errorAndSelectedBackground'] : startupService.colorAliases['errorBackground'] ) : ( selectedPrintNodeOrderId && printNodeOrderItem.printnodeorder_ID === selectedPrintNodeOrderId ? startupService.colorAliases['selectedBackground'] : '' )">
  <div style="background-color: darkgreen" *ngIf="printNodeOrderItem.supportPerson || printNodeOrderItem.supportNote">
    <span>{{printNodeOrderItem.supportPerson}}</span>: <span>{{printNodeOrderItem.supportNote}}</span>
  </div>
  <ng-container *ngIf="printNodeOrderItem.book.body.standardized !== 'COMPLETE'">
    <ng-container [ngSwitch]="printNodeOrderItem.book.body.standardized">
      <ng-container *ngSwitchCase="'IN_PROGRESS'"><div style="background-color: yellow; font-weight: bold" i18n="@@Body Standardization In Progress">Body Standardization In Progress</div></ng-container>
      <ng-container *ngSwitchCase="'ERROR'">
        <div style="background-color: pink" >
          <span  style="font-weight: bold" i18n="@@Body Standardization Error">Body Standardization Error</span>
          <span *ngIf="printNodeOrderItem.book.body.problemStatus || printNodeOrderItem.book.body.problemNote">{{printNodeOrderItem.book.body.problemStatus}} -- {{printNodeOrderItem.book.body.problemNote}}</span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'WAITING'">
        <ng-container *ngIf="printNodeOrderItem.book && printNodeOrderItem.book.body && printNodeOrderItem.book.body.resource" [ngSwitch]="printNodeOrderItem.book.body.resource.prepared">
          <ng-container *ngSwitchCase="'IN_PROGRESS'"><div style="background-color: yellow" i18n="@@Body Resource Preparation In Progress">Body Resource Preparation In Progress</div></ng-container>
          <ng-container *ngSwitchCase="'WAITING'"><div style="background-color: darkorange" i18n="@@Body Resource Preparation Waiting To Be Processes">Body Resource Preparation Waiting To Be Processes</div></ng-container>
          <ng-container *ngSwitchCase="'ERROR'">
            <div style="background-color: pink" >
              <div style="font-weight: bold" i18n="@@Body Resource File Preparation Error">Body Resource File Preparation Error</div>
              <div *ngIf="printNodeOrderItem.book.body.problemStatus || printNodeOrderItem.book.body.problemNote">{{printNodeOrderItem.book.body.problemStatus}} -- {{printNodeOrderItem.book.body.problemNote}}</div>
              <div *ngIf="printNodeOrderItem.book.body.resource.problems">{{printNodeOrderItem.book.body.resource.problems}}</div>
              <div *ngIf="printNodeOrderItem.book.body.resource.instructions">{{printNodeOrderItem.book.body.resource.instructions}}</div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="printNodeOrderItem.book.cover.standardized !== 'COMPLETE'">
    <ng-container [ngSwitch]="printNodeOrderItem.book.cover.standardized">
      <ng-container *ngSwitchCase="'IN_PROGRESS'"><div style="background-color: yellow; font-weight: bold" i18n="@@Cover Standardization In Progress">Cover Standardization In Progress</div></ng-container>
      <ng-container *ngSwitchCase="'ERROR'">
        <div style="background-color: pink" >
          <span  style="font-weight: bold" i18n="@@Cover Standardization Error">Cover Standardization Error</span>
          <span *ngIf="printNodeOrderItem.book.cover.problemStatus || printNodeOrderItem.book.cover.problemNote">{{printNodeOrderItem.book.cover.problemStatus}} -- {{printNodeOrderItem.book.cover.problemNote}}</span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'WAITING'">
        <ng-container [ngSwitch]="printNodeOrderItem.book.cover.resource.prepared">
          <ng-container *ngSwitchCase="'IN_PROGRESS'"><div style="background-color: yellow" i18n="@@Cover Resource Preparation In Progress">Cover Resource Preparation In Progress</div></ng-container>
          <ng-container *ngSwitchCase="'WAITING'"><div style="background-color: lightyellow" i18n="@@Cover Resource Preparation Waiting To Be Processes">Cover Resource Preparation Waiting To Be Processes</div></ng-container>
          <ng-container *ngSwitchCase="'ERROR'">
            <div style="background-color: pink" >
              <div style="font-weight: bold" i18n="@@Cover Resource File Preparation Error">Cover Resource File Preparation Error</div>
              <div *ngIf="printNodeOrderItem.book.cover.problemStatus || printNodeOrderItem.book.cover.problemNote">{{printNodeOrderItem.book.cover.problemStatus}} -- {{printNodeOrderItem.book.cover.problemNote}}</div>
              <div *ngIf="printNodeOrderItem.book.cover.resource.problems">{{printNodeOrderItem.book.cover.resource.problems}}</div>
              <div *ngIf="printNodeOrderItem.book.cover.resource.instructions">{{printNodeOrderItem.book.cover.resource.instructions}}</div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <div class="printNodeOrderItemInfoSide">
    <div class="graphicDetails">
      <div class="graphicDetail" *ngFor="let type of types" [style.background-color]="colors[type][properties[type]]" [title]="type + ': ' + properties[type] + units">
        <mat-icon *ngIf="icons[type][properties[type]]">{{icons[type][properties[type]]}}</mat-icon>
        <!--      <br/>-->
        <!--      <span style="font-size: 8pt">{{properties[type]}}</span>-->
      </div>
    </div>
    <div class="basicInfo">
      <div class="clippedWidth" style="font-weight: bold"><span title="Book Id" i18n-title="@@Book Id">{{printNodeOrderItem.book.ID}} </span><span title="Title" i18n-title="@@Title">{{printNodeOrderItem.book.title}}</span></div>
      <div class="details clippedWidth">
        <span title="Service Provider" i18n-title="@@Service Provider" [style.color]="printNodeOrderItem.printNodeOrder.organization && printNodeOrderItem.printNodeOrder.organization.serviceProvider_ID === 4? 'red': ''"> {{printNodeOrderItem.printNodeOrder.organization ? printNodeOrderItem.printNodeOrder.organization.serviceProvider_ID : 'X'}} </span>
        <span title="Organization" i18n-title="@@Organization" style="font-weight: bold">{{printNodeOrderItem.printNodeOrder.org_ID}}</span>
        <span title="Organization" i18n-title="@@Organization" [style.color]="printNodeOrderItem.printNodeOrder.organization && printNodeOrderItem.printNodeOrder.organization.serviceProvider_ID === 4? 'red': ''">{{printNodeOrderItem.printNodeOrder.organization ? printNodeOrderItem.printNodeOrder.organization.name : ''}}</span>
      </div>
    </div>
    <div class="basicInfo">
      <div class="details clippedWidth" style="font-weight: bold; font-size: larger">
        <span title="Quantity" i18n-title="@@Quantity" style="color: yellow">{{quantity}} - </span>
        <span title="Pages" i18n-title="@@Pages">{{pages}}</span>
        <span style="font-weight: normal; font-size: smaller" title="Stacker Height" i18n-title="@@Stacker Height">{{ ( ( printNodeOrderItem.book.body.width/72 > 6 || printNodeOrderItem.book.body.height/72 > 9 ) ? thickness * quantity / 2 : thickness * quantity / 4 ) | number: '1.1-1' }}in</span>
      </div>
      <div class="details clippedWidth">
        <span  title="Trim Size" i18n-title="@@Trim Size" stytle="font-weight: bold; color: yellow;">{{printNodeOrderItem.book.body.width/72  | number: '1.2-2' }}in X{{printNodeOrderItem.book.body.height/72 | number: '1.2-2' }}in </span>
        <span title="Thickness" i18n-title="@@Thickness">{{thickness | number: '1.1-1' }}in</span>
        <span title="Weight" i18n-title="@@Weight">{{printNodeOrderItem.book.weight * quantity | number: '1.1-1' }}lbs</span>
<!--        <span title="Total Pages" i18n-title="@@Total Pages">{{pages * quantity}}</span>-->
        <span title="Created" i18n-title="@@Created">{{createdDate | date: 'yyyy-MM-dd' }}</span>
      </div>
    </div>
    <div class="basicInfo">
      <div class="clippedWidth">{{printNodeOrderItem.printnodeorder_ID}}/{{printNodeOrderItem.ID}}</div>
      <div class="clippedWidth">
        <span title="Shipping " i18n-title="@@Shipping" style="font-weight: bold" [style.color]="printNodeOrderItem.printNodeOrder.servicelevel === 'PRIORITY_FLAT' ? 'red': ''">{{printNodeOrderItem.printNodeOrder.carrier}}_{{printNodeOrderItem.printNodeOrder.servicelevel}} </span>
      </div>
      <div class="clippedWidth" [style.color]="isDateOld(printNodeOrderItem.printNodeOrder.created) ? 'red' : 'inherit'">{{printNodeOrderItem.printNodeOrder.created | date: 'yyyy-MM-dd' }}</div>    </div>
    <div class="basicInfo">
      <div class="clippedWidth">
        <span title="Name" i18n-title="@@Name">{{printNodeOrderItem.printNodeOrder.firstname}} {{printNodeOrderItem.printNodeOrder.lastname}} </span>
        <span title="Address" i18n-title="@@Address">{{printNodeOrderItem.printNodeOrder.address1}}</span>
      </div>
      <div class="details clippedWidth">
        <span title="City" i18n-title="@@City">{{printNodeOrderItem.printNodeOrder.city}}, </span>
        <span title="State" i18n-title="@@State">{{printNodeOrderItem.printNodeOrder.state}} </span>
        <span title="Postal Code" i18n-title="@@Postal Code" style="font-weight: bolder; font-size: larger">{{printNodeOrderItem.printNodeOrder.postalcode}} </span>
        <span title="Country" i18n-title="@@Country">{{printNodeOrderItem.printNodeOrder.country}}</span>
      </div>
    </div>
    <ng-container *ngIf="printNodeOrderItem.printNodeOrder.inventoryType !== 'REPLENISH_INVENTORY' && facilityInventoryProductsByBookId && facilityInventoryProductsByBookId['' + printNodeOrderItem.book.ID]">
      <div class="basicInfo" *ngFor="let facilityInventoryProduct of facilityInventoryProductsByBookId['' + printNodeOrderItem.book.ID]" >
        <div class="details clippedWidth" style="font-weight: bold; font-size: larger; color: darkorange">
          <span title="Facility" i18n-title="@@Facility">{{facilityInventoryProduct.printnode_ID === 1 ? 'PHX' : 'HOU'}} </span>
          <span title="On Hand" i18n-title="@@On Hand">{{facilityInventoryProduct.quantityOnHand}} </span>
          <span title="Committed" i18n-title="@@Committed">{{facilityInventoryProduct.quantityCommited}} </span>
          <span title="Present" i18n-title="@@Present" [style.color]="( facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited ) <= 0 ? 'red' : 'green'">{{facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited}}</span><span>/</span>
          <span title="Net" i18n-title="@@Net" [style.color]="( facilityInventoryProduct.quantityOnOrder + facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited ) <= 0 ? 'red' : 'green'">{{facilityInventoryProduct.quantityOnOrder + facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited}}</span>

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="printNodeOrderItem.printNodeOrder.inventoryType === 'REPLENISH_INVENTORY'">
      <div class="basicInfo" *ngFor="let facilityInventoryProduct of facilityInventoryProductsByBookId['' + printNodeOrderItem.book.ID]"  style="font-weight: bold; font-size: larger; color: darkorange" i18n="@@Replenishment">Replenishment</div>
    </ng-container>
    <div class="basicInfo">
      <div class="clippedWidth">
        <button mat-button (click)="assignPrintNodeOrderItemToManufacturingSet.selectPrintNodeOrder(printNodeOrderItem.printnodeorder_ID)" [ngClass]="printNodeOrderItem.printNodeOrder.itemCount > 1 ? 'standout' : 'normal'" title="Total Number of Order Items In This Order" i18n-title="@@Total Number of Order Items In This Order">{{printNodeOrderItem.printNodeOrder.itemCount}}</button>
        <span title="Inventory Score" i18n-title="@@Inventory Score">{{printNodeOrderItem.book.inventoryScore | number: '1.0-0'}} </span>
      </div>
    </div>
<!--    <div class="basicInfo">-->
<!--      <div class="clippedWidth">-->
<!--        <span title="Name" i18n-title="@@Name">{{printNodeOrderItem.printNodeOrder.firstname}} {{printNodeOrderItem.printNodeOrder.lastname}} </span>-->
<!--        <span title="Address" i18n-title="@@Address">{{printNodeOrderItem.printNodeOrder.address1}}</span>-->
<!--      </div>-->
<!--      <div class="details clippedWidth">-->
<!--        <span title="City" i18n-title="@@City">{{printNodeOrderItem.printNodeOrder.city}}, </span>-->
<!--        <span title="State" i18n-title="@@State">{{printNodeOrderItem.printNodeOrder.state}} </span>-->
<!--        <span title="Postal Code" i18n-title="@@Postal Code">{{printNodeOrderItem.printNodeOrder.postalcode}} </span>-->
<!--        <span title="Country" i18n-title="@@Country">{{printNodeOrderItem.printNodeOrder.country}}</span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <ng-container *ngIf="currentManufacturingSet.openStatus === 'OPEN'">
    <div *ngIf="expandActions" class="printNodeOrderItemActionSide">
      <ng-container *ngFor="let manufacturingTaskBucket of manufacturingTaskBuckets">
        <div class="manufacturingTaskBucket" *ngIf="showManufacturingTaskBucket(printNodeOrderItem, manufacturingTaskBucket)" [style]="getManufacturingTaskBucketStyle(manufacturingTaskBucket)">
          <h4 class="manufacturingTaskBucketTitle">{{manufacturingTaskBucket['name']}}</h4>
          <ng-container *ngIf="!manufacturingTaskBucket['manufacturingTaskBucketPeriods']">
            <button class="addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingSet" mat-button (click)="assignPrintNodeOrderItemToManufacturingSet.addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingTaskBucket(printNodeOrderItem, manufacturingTaskBucket['ID'], $event.target)" title="Move To Bucket and Put In A New Set" i18n-title="@@Move To Bucket and Put In A New Set" ><mat-icon>call_made</mat-icon></button>
          </ng-container>
          <ng-container *ngFor="let manufacturingTaskBucketPeriod of manufacturingTaskBucket['manufacturingTaskBucketPeriods']">
            <div class="manufacturingTaskBucketPeriod" *ngIf="showManufacturingTaskBucketPeriod(printNodeOrderItem, manufacturingTaskBucketPeriod)">
              <h4 class="manufacturingTaskBucketPeriodTitle">{{manufacturingTaskBucketPeriod['period']}}</h4>
              <ng-container *ngFor="let manufacturingSet of manufacturingTaskBucketPeriod['manufacturingSets']">
                <ng-container class="manufacturingSet" *ngIf="showManufacturingSet(printNodeOrderItem, manufacturingSet)">
                  <button class="assignPrintNodeOrderItemToManufacturingSet" [style.background-color]="getAssignToSpecificManufacturingSetColor(printNodeOrderItem, manufacturingSet)" mat-button (click)="assignPrintNodeOrderItemToManufacturingSet.movePrintNodeOrderItemToManufacturingSet(printNodeOrderItem, manufacturingSet['ID'], $event.target)" i18n-title="@@Move A Spicific Set" title="Move A Spicific Set">
                    <div style="padding: 2px; line-height: normal;">{{manufacturingSet['ID']}}</div>
                    <div style="padding: 2px; line-height: normal; font-size: smaller; overflow: clip;" *ngIf="!isSameTrimSize(printNodeOrderItem, manufacturingSet) || !isSamePaper(printNodeOrderItem, manufacturingSet)">
                      <span style="padding: 2px;" *ngIf="!isSameTrimSize(printNodeOrderItem, manufacturingSet)">{{manufacturingSet.details.trimSize}}</span>
                      <span style="padding: 2px; line-height: 8px; font-size: xx-small;" *ngIf="!isSamePaper(printNodeOrderItem, manufacturingSet)">{{manufacturingSet.details.interiorPaperType}}</span>
                    </div>
                  </button>
                </ng-container>
              </ng-container>
              <button class="addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingSet" mat-button (click)="assignPrintNodeOrderItemToManufacturingSet.addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingSet(printNodeOrderItem, manufacturingTaskBucketPeriod['ID'], $event.target)" title="Move To Bucket and Put In A New Set" i18n-title="@@Move To Bucket and Put In A New Set" ><mat-icon>call_made</mat-icon></button>
            </div>
          </ng-container>
  <!--        <ng-container *ngIf="showAddButtons">-->
  <!--          <ng-container *ngFor="let manufacturingTaskBucketPeriod of manufacturingTaskBucket['manufacturingTaskBucketPeriods']">-->
  <!--            <button *ngIf="manufacturingTaskBucketPeriod['status'] !== 'CLOSED'" class="addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingSet" mat-button (click)="assignPrintNodeOrderItemToManufacturingSet.addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingSet(printNodeOrderItem, manufacturingTaskBucketPeriod)">{{manufacturingTaskBucketPeriod['period'].substring(7)}}</button>-->
  <!--          </ng-container>-->
  <!--        </ng-container>-->
        </div>
      </ng-container>
      <button *ngIf="!editSupportNote" mat-icon-button (click)="editSupportNote = !editSupportNote" title="Edit" i18n-title="@@Edit"><mat-icon>edit</mat-icon></button>
      <ng-container *ngIf="editSupportNote">
        <textarea name="supportPerson" [(ngModel)]="printNodeOrderItem.supportPerson" matInput i18n-placeholder="@@Support Person" placeholder="Support Person"></textarea>
        <textarea name="supportNote" [(ngModel)]="printNodeOrderItem.supportNote" matInput i18n-placeholder="@@Support Note" placeholder="Support Note"></textarea>
        <button mat-icon-button (click)="saveSupportNote(); editSupportNote = !editSupportNote" title="Save Support Note" i18n-title="@@Save Support Note"><mat-icon>save</mat-icon></button>
        <button mat-icon-button (click)="cancelSupportNote(); editSupportNote = !editSupportNote" title="Cancel Support Note" i18n-title="@@Cancel Support Note"><mat-icon>cancel</mat-icon></button>
      </ng-container>
    </div>
    <div class="printNodeOrderItemActionSide">
      <button class="moveToNextBucketPeriodAndAutoAssign" mat-button (click)="assignPrintNodeOrderItemToManufacturingSet.movePrintNodeOrderItemToNextManufacturingTaskBucketPeriod(printNodeOrderItem, currentManufacturingTaskBucketPeriod.ID, $event.target)" title="Move to the next bucket period" i18n-title="@@Move to the next bucket period" ><mat-icon>arrow_downward</mat-icon></button>
      <button class="moveToBucketPeriodAndAutoAssign" mat-button (click)="assignPrintNodeOrderItemToManufacturingSet.movePrintNodeOrderItemToManufacturingTaskBucketPeriod(printNodeOrderItem, currentManufacturingTaskBucketPeriod.ID, $event.target)" title="Move to a new set in the same bucket period" i18n-title="@@Move to a new Set in the same bucket period" ><mat-icon>call_made</mat-icon></button>
  <!--    <button class="unassignPrintNodeOrderItem" mat-button (click)="assignPrintNodeOrderItemToManufacturingSet.unassignPrintNodeOrderItem(printNodeOrderItem)" title="Unassign" i18n-title="@@Unassign" ><mat-icon>flash_on</mat-icon></button>-->
      <button class="quarantine" *ngIf="printNodeOrderItem.printNodeOrder.printnode_ID!==8" mat-button (click)="assignPrintNodeOrderItemToManufacturingSet.changeFacilityAndInventoryType(printNodeOrderItem.printNodeOrder, 8, 'PRINT_TO_ORDER', $event.target)" title="Quarantine" i18n-title="@@Quarantine" ><mat-icon>medical_services</mat-icon></button>
      <button class="fulfilment" *ngIf="canMoveToFulfillment(printNodeOrderItem.printNodeOrder)" mat-button (click)="assignPrintNodeOrderItemToManufacturingSet.changeFacilityAndInventoryType(printNodeOrderItem.printNodeOrder, 7, 'FULFILL_FROM_INVENTORY', $event.target)" title="Move to Fulfillment" i18n-title="@@Move to Fulfillment" ><mat-icon>local_shipping</mat-icon></button>
      <button class="fulfilment" *ngIf="printNodeOrderItem.printNodeOrder.quantity > 1" mat-button (click)="splitPrintNodeOrder(printNodeOrderItem.printNodeOrder)" title="Split Order" i18n-title="@@Split Order" ><mat-icon>call_split</mat-icon></button>
  <!--    <button class="addManufacturingSetAndAssignPrintNodeOrderItemToManufacturingSetToggle" mat-icon-button (click)="showAddButtons=!showAddButtons"><mat-icon>add</mat-icon></button>-->
      <button *ngIf="!expandActions" mat-icon-button i18n-title="@@Expand Options" title="Expand Options" class="floatingButton" (click)="expandActions = !expandActions" color="primary"><mat-icon>more_vert</mat-icon></button>
      <button *ngIf="expandActions" mat-icon-button i18n-title="@@Collapse Options" title="Collapse Options" class="floatingButton" (click)="expandActions = !expandActions" color="primary"><mat-icon>expand_less</mat-icon></button>
    </div>
  </ng-container>
  <ng-container *ngIf="currentManufacturingSet.openStatus === 'CLOSED'">
    <button *ngIf="!editSupportNote" mat-icon-button (click)="editSupportNote = !editSupportNote" title="Edit" i18n-title="@@Edit"><mat-icon>edit</mat-icon></button>
    <ng-container *ngIf="editSupportNote">
      <textarea name="supportPerson" [(ngModel)]="printNodeOrderItem.supportPerson" matInput i18n-placeholder="@@Support Person" placeholder="Support Person"></textarea>
      <textarea name="supportNote" [(ngModel)]="printNodeOrderItem.supportNote" matInput i18n-placeholder="@@Support Note" placeholder="Support Note"></textarea>
      <button mat-icon-button (click)="saveSupportNote(); editSupportNote = !editSupportNote" title="Save Support Note" i18n-title="@@Save Support Note"><mat-icon>save</mat-icon></button>
      <button mat-icon-button (click)="cancelSupportNote(); editSupportNote = !editSupportNote" title="Cancel Support Note" i18n-title="@@Cancel Support Note"><mat-icon>cancel</mat-icon></button>
    </ng-container>
  </ng-container>
</div>
