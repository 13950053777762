import {ShippingOption} from "./ShippingOption";

export class ShippingPackage {
  height: number;
  width: number;
  depth: number;
  weight: number;
  quantity: number;
  status = 'INITIAL';
  computationVersion: number;
  shippingOptions: ShippingOption[] = [];
  computationStatus = 'DIRTY';

  constructor ( height: number, width: number, depth: number, weight: number, quantity: number, shippingOptions: ShippingOption[] ) {
    this.height = height;
    this.width = width;
    this.depth = depth;
    this.weight = weight;
    this.quantity = quantity;
    this.shippingOptions = shippingOptions;
  }

  getCost(carrier: string, serviceLevel: string ) {
    for ( const shippingOption of this.shippingOptions ) {
      if ( shippingOption.carrier === carrier && shippingOption.serviceLevel === serviceLevel ) {
        return shippingOption.cost * this.quantity;
      }
    }
    return -1;
  }

  setShippingOptions(shippingOptions) {
    this.shippingOptions = shippingOptions;
  }
}

