<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Add CSV Import Settings">Add CSV Import Settings</h2>
  </div>
  <div>
    <form [formGroup]="addForm" (change)="formChanged()">
      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <mat-label i18n="@@Name of CSV Import Settings">Name of CSV Import Settings</mat-label>
              <input matInput placeholder="Name of CSV Import Settings" i18n-placeholder="@@Name of CSV Import Settings" value={{name}} formControlName="name" required>
              <mat-error *ngIf="addForm.controls.name.hasError('required')" i18n="@@Please enter the name of the CSV import settings">Please enter the name of the CSV import settings</mat-error>
              <mat-error *ngIf="addForm.controls.name.hasError('pattern')" i18n="@@Please enter a valid name for the CSV import settings">Please enter a valid name for the CSV import settings</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field>
              <mat-label i18n="@@Description of CSV Import Settings">Description of CSV Import Settings</mat-label>
              <input matInput placeholder="Description of CSV Import Settings" i18n-placeholder="@@Description of CSV Import Settings" value={{description}} formControlName="description" required>
              <mat-error *ngIf="addForm.controls.description.hasError('required')" i18n="@@Please enter the description of the CSV import settings">Please enter the description of the CSV import settings</mat-error>
              <mat-error *ngIf="addForm.controls.description.hasError('pattern')" i18n="@@Please enter a description name for the CSV import settings">Please enter a valid description for the CSV import settings</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn"  mat-dialog-close="" [disabled]="addForm.invalid" (click)="onAdd()" i18n="@@Add CSV Import Settings">Add CSV Import Settings</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
