export class EcommerceConnection {
  ID: number;
  org_ID: number;
  type: string | null;
  state: string;
  secret: string | null;
  remoteUser: string | null;
  remotePassword: string | null;
  remoteKey: string | null;
  defaultShippingService: string | null;
  defaultFreeShippingService: string | null;
  synchronizeProducts: string;
  publishFeed: string;
  remoteURL: string | null;
  lastOrderCheck: Date;
  lastOrderProcessed: Date;
  lastProductProcessed: Date;
  lastShipmentProcessed: Date;
  productIdentifierType: string;
  sendEmailMessage: string;
  notificationEmail: string | null;
  customerorderstate: string;
  carrierServiceMap_ID: number;
  gatewayChargePercent: number;
  perOrderAmount: number;
  perBookAmount: number;
  currency: string;
  verification_ID: number;
  options: string;
  sendNotificationToEndConsumer: string;
  synchronizeShipments: string;
  useUpstreamPrice: string;
  lastUpdate: Date;


  constructor( obj ) {
    // this.org_ID Make sure to set the orgId with newly created instances
    this.type = 'API';
    this.state = 'ACTIVE';
    this.secret = '';
    this.remoteUser = '';
    this.remotePassword = '';
    this.remoteKey = '';
    this.defaultShippingService = 'USPS_PRIORITY_FLAT';
    this.defaultFreeShippingService = 'USPS_PRIORITY_FLAT';
    this.synchronizeProducts = 'TRUE';
    this.publishFeed = 'FALSE';
    this.remoteURL = '';
    this.lastOrderCheck = new Date(0);
    this.lastOrderProcessed = new Date(0);
    this.lastProductProcessed = new Date(0);
    this.lastShipmentProcessed = new Date(0);
    this.productIdentifierType = 'CUSTOMER_PRODUCT_ID';
    this.sendEmailMessage = 'TRUE';
    this.notificationEmail = '';
    this.customerorderstate = '';
    this.carrierServiceMap_ID = 0;
    this.gatewayChargePercent = 0;
    this.perOrderAmount = 0;
    this.perBookAmount = 0;
    this.currency = 'USD';
    this.verification_ID = 0;
    this.options = '';
    this.sendNotificationToEndConsumer = 'SHIP';
    this.synchronizeShipments = 'TRUE';
    this.useUpstreamPrice = 'TRUE';
    this.lastUpdate = new Date();
    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        if ( obj[prop] ) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
