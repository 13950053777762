<mat-card appearance="outlined" class="interiorThemeEditorItem" [ngClass]="getSelectedClass()">
  <mat-card-content>
    <mat-accordion displayMode="default">
      <mat-expansion-panel [expanded]="fontExpanded" *ngIf="interiorThemeEditorItem.contains('font-family') || interiorThemeEditorItem.contains('text-align') || interiorThemeEditorItem.contains('letter-spacing') || interiorThemeEditorItem.contains('color') || interiorThemeEditorItem.contains('font-size') || interiorThemeEditorItem.contains('font-weight') || interiorThemeEditorItem.contains('font-style')">
        <mat-expansion-panel-header>
          <mat-panel-title i18n="@@Font">Font</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="toolAreaItem">
          <mat-form-field>
            <mat-select #fontFamilySelect i18n-placeholder="@@Font Family" placeholder="Font Family" [ngModel]="interiorThemeEditorItem.getValue('font-family')" (selectionChange)="interiorThemeEditorItem.setValue('font-family', fontFamilySelect.value)" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')">
              <mat-option [class.selected]="isFontFamilyNameTheSame('inherit', interiorThemeEditorItem.getValue('font-family'))" [style.fontFamily]="'inherit'" [value]="'inherit'" i18n="@@Inherit">Inherit</mat-option>
              <mat-option *ngFor="let fontItem of themeService.fonts"  [class.selected]="isFontFamilyNameTheSame(fontItem.name, interiorThemeEditorItem.getValue('font-family'))" [style.fontFamily]="fontItem.name" [value]="fontItem.name">
                {{fontItem.name | languageString: 'compressSpaces' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="max-width: 80px; margin-left: 10px;">
            <mat-select #fontSize i18n-placeholder="@@Font Size" placeholder="Font Size"  [ngModel]="interiorThemeEditorItem.getValue('font-size').slice(0, -2)" required (selectionChange)="interiorThemeEditorItem.setValue('font-size', fontSize.value + 'pt')" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" [ngModelOptions]="{standalone: true}">
              <mat-option value="inherit" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@Inherit">Inherit</mat-option>
              <mat-option value="6" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@6pt">6pt</mat-option>
              <mat-option value="7" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@7pt">7pt</mat-option>
              <mat-option value="8" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@8pt">8pt</mat-option>
              <mat-option value="9" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@9pt">9pt</mat-option>
              <mat-option value="10" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@10pt">10pt</mat-option>
              <mat-option value="11" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@11pt">11pt</mat-option>
              <mat-option value="12" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@12pt">12pt</mat-option>
              <mat-option value="13" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@13pt">13pt</mat-option>
              <mat-option value="14" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@14pt">14pt</mat-option>
              <mat-option value="15" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@15pt">15pt</mat-option>
              <mat-option value="16" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@16pt">16pt</mat-option>
              <mat-option value="17" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@17pt">17pt</mat-option>
              <mat-option value="18" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@18pt">18pt</mat-option>
              <mat-option value="20" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@20pt">20pt</mat-option>
              <mat-option value="22" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@22pt">22pt</mat-option>
              <mat-option value="24" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@24pt">24pt</mat-option>
              <mat-option value="26" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@26pt">26pt</mat-option>
              <mat-option value="28" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@28pt">28pt</mat-option>
              <mat-option value="30" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@30pt">30pt</mat-option>
              <mat-option value="32" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@32pt">32pt</mat-option>
              <mat-option value="34" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@34pt">34pt</mat-option>
              <mat-option value="36" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@36pt">36pt</mat-option>
              <mat-option value="38" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@38pt">38pt</mat-option>
              <mat-option value="40" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@40pt">40pt</mat-option>
              <mat-option value="42" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@42pt">42pt</mat-option>
              <mat-option value="44" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@44pt">44pt</mat-option>
              <mat-option value="46" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@46pt">46pt</mat-option>
              <mat-option value="48" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@48pt">48pt</mat-option>
              <mat-option value="50" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@50pt">50pt</mat-option>
              <mat-option value="52" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@52pt">52pt</mat-option>
              <mat-option value="54" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@54pt">54pt</mat-option>
              <mat-option value="56" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@56pt">56pt</mat-option>
              <mat-option value="58" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@58pt">58pt</mat-option>
              <mat-option value="60" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@60pt">60pt</mat-option>
              <mat-option value="64" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@64pt">64pt</mat-option>
              <mat-option value="68" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@68pt">68pt</mat-option>
              <mat-option value="72" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@72pt">72pt</mat-option>
              <mat-option value="76" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@76pt">76pt</mat-option>
              <mat-option value="80" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@80pt">80pt</mat-option>
              <mat-option value="84" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@84pt">84pt</mat-option>
              <mat-option value="88" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@88pt">88pt</mat-option>
              <mat-option value="92" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@92pt">92pt</mat-option>
              <mat-option value="96" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@96pt">96pt</mat-option>
              <mat-option value="100" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@100pt">100pt</mat-option>
              <mat-option value="104" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@104pt">104pt</mat-option>
              <mat-option value="108" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@108pt">108pt</mat-option>
              <mat-option value="116" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@116pt">116pt</mat-option>
              <mat-option value="120" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@120pt">120pt</mat-option>
              <mat-option value="128" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@128pt">128pt</mat-option>
              <mat-option value="132" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@132pt">132pt</mat-option>
              <mat-option value="140" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@140pt">140pt</mat-option>
              <mat-option value="148" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@148pt">148pt</mat-option>
              <mat-option value="156" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@156pt">156pt</mat-option>
              <mat-option value="164" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@164pt">168pt</mat-option>
              <mat-option value="172" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@172pt">172pt</mat-option>
              <mat-option value="180" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@180pt">180pt</mat-option>
              <mat-option value="188" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@188pt">188pt</mat-option>
              <mat-option value="196" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@196pt">196pt</mat-option>
              <mat-option value="240" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@240pt">240pt</mat-option>
              <mat-option value="264" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@264pt">264pt</mat-option>
              <mat-option value="288" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@288pt">288pt</mat-option>
            </mat-select>
          </mat-form-field>
          <!--<div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('font-size')">-->
          <!--<div class="toolAreaSlider">-->
          <!--<span i18n="@@Font Size">Font Size</span><mat-slider #fontSizeSlider min="4" max="144" step="1" thumbLabel="true" tickInterval="10" [value]="interiorThemeEditorItem.getValue('font-size').slice(0, -2)" (change)="interiorThemeEditorItem.setValue('font-size', fontSizeSlider.value + 'pt')"></mat-slider>-->
          <!--</div>-->
          <!--</div>-->
          <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('font-weight')">
            <!--<button *ngIf="interiorThemeEditorItem.getValue('font-weight') === 'normal' || interiorThemeEditorItem.getValue('font-weight') === '400'" mat-button (click)="interiorThemeEditorItem.setValue('font-weight', '700')"><mat-icon>format_bold</mat-icon></button>-->
            <!--<button *ngIf="interiorThemeEditorItem.getValue('font-weight') === 'bold' || interiorThemeEditorItem.getValue('font-weight') === '700'" mat-button (click)="interiorThemeEditorItem.setValue('font-weight', '400')"><mat-icon style="color: white">format_bold</mat-icon></button>-->
            <mat-form-field>
              <mat-select #fontWeight i18n-placeholder="@@Font Weight (Bold)" placeholder="Font Weight (Bold)"  [ngModel]="interiorThemeEditorItem.getValue('font-weight')" required (selectionChange)="interiorThemeEditorItem.setValue('font-weight', fontWeight.value)" [style.fontWeight]="interiorThemeEditorItem.getValue('font-weight')" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" [ngModelOptions]="{standalone: true}">
                <mat-option value="inherit" style="font-weight: inherit" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@Inherit">Inherit</mat-option>
                <mat-option value="100" style="font-weight: 100" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@Lighter">Lighter</mat-option>
                <mat-option value="200" style="font-weight: 200" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@200">200</mat-option>
                <mat-option value="300" style="font-weight: 300" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@300">300</mat-option>
                <mat-option value="400" style="font-weight: 400" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@Normal">Normal</mat-option>
                <mat-option value="500" style="font-weight: 500" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@500">500</mat-option>
                <mat-option value="600" style="font-weight: 600" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@600">600</mat-option>
                <mat-option value="700" style="font-weight: 700" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@Bold">Bold</mat-option>
                <mat-option value="800" style="font-weight: 800" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@800">800</mat-option>
                <mat-option value="900" style="font-weight: 900" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" i18n="@@Bolder">Bolder</mat-option>
              </mat-select>
            </mat-form-field>
            <button *ngIf="interiorThemeEditorItem.getValue('font-style') === 'italic'" mat-button (click)="interiorThemeEditorItem.setValue('font-style', 'normal')"><mat-icon style="color: white">format_italic</mat-icon></button>
            <button *ngIf="interiorThemeEditorItem.getValue('font-style') === 'normal'" mat-button (click)="interiorThemeEditorItem.setValue('font-style', 'inherit')"><mat-icon style="color: #333333">format_italic</mat-icon></button>
            <button *ngIf="interiorThemeEditorItem.getValue('font-style') === 'inherit'" mat-button (click)="interiorThemeEditorItem.setValue('font-style', 'italic')"><mat-icon>block</mat-icon></button>
          </div>

        </div>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('text-align')">
          <mat-button-toggle-group #alignment="matButtonToggleGroup" [value]="interiorThemeEditorItem.getValue('text-align')" (change)="interiorThemeEditorItem.setValue('text-align', alignment.value)" [style.fontFamily]="interiorThemeEditorItem.getValue('text-align')">
            <mat-button-toggle value="left">
              <mat-icon>format_align_left</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="center">
              <mat-icon>format_align_center</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="right">
              <mat-icon>format_align_right</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="justify">
              <mat-icon>format_align_justify</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="inherit">
              <mat-icon>block</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <!--<div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('letter-spacing')">-->
          <!--<div class="toolAreaSlider">-->
            <!--<span i18n="@@Character Spacing">Char Spacing</span><mat-slider #letterSpacingSlider min="-3" max="10" step="0.25" thumbLabel="true" tickInterval="0.5" [value]="getLetterSpacing(interiorThemeEditorItem)" (change)="interiorThemeEditorItem.setValue('letter-spacing', letterSpacingSlider.value + 'px')"></mat-slider><span>{{letterSpacingSlider.value}}px</span>-->
          <!--</div>-->
        <!--</div>-->
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('line-height')">
          <div class="toolAreaSlider">
            <span i18n="@@Line Height">Line Height</span>
            <!-- TODO: The 'tickInterval' property no longer exists -->
            <mat-slider #lineHeightSlider min="0.6" max="4" step="0.05" thumbLabel="true" #ngSlider><input matSliderThumb [value]="interiorThemeEditorItem.getValue('line-height').slice(0, -2)" (change)="interiorThemeEditorItem.setValue('line-height', lineHeightSlider.value + 'em')" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{lineHeightSlider.value}}em</span>
          </div>
        </div>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('color')">
          <mat-form-field>
            <mat-select #color i18n-placeholder="@@Color" placeholder="Color"  [ngModel]="interiorThemeEditorItem.getValue('color').toString()" required (selectionChange)="interiorThemeEditorItem.setValue('color', color.value)" [style.background]="interiorThemeEditorItem.getValue('color')" [style.color]="interiorThemeEditorItem.getValue('color')" [style.fontFamily]="interiorThemeEditorItem.getValue('font-family')" [ngModelOptions]="{standalone: true}">
              <mat-option value="inherit" i18n="@@Inherit" >Inherit</mat-option>
              <mat-option value="#000000" style="background: #000000" >#000</mat-option>
              <mat-option value="#111111" style="background: #111111" >#111</mat-option>
              <mat-option value="#222222" style="background: #222222" >#222</mat-option>
              <mat-option value="#333333" style="background: #333333" >#333</mat-option>
              <mat-option value="#444444" style="background: #444444" >#444</mat-option>
              <mat-option value="#555555" style="background: #555555" >#555</mat-option>
              <mat-option value="#666666" style="background: #666666" >#666</mat-option>
              <mat-option value="#777777" style="background: #777777" >#777</mat-option>
              <mat-option value="#888888" style="background: #888888" >#888</mat-option>
              <mat-option value="#999999" style="background: #999999" >#999</mat-option>
              <mat-option value="#aaaaaa" style="background: #aaaaaa" >#aaa</mat-option>
              <mat-option value="#bbbbbb" style="background: #bbbbbb" >#bbb</mat-option>
              <mat-option value="#cccccc" style="background: #cccccc" >#ccc</mat-option>
              <mat-option value="#dddddd" style="background: #dddddd" >#ddd</mat-option>
              <mat-option value="#eeeeee" style="background: #eeeeee" >#eee</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="marginExpanded" *ngIf="interiorThemeEditorItem.contains('margin-top') || interiorThemeEditorItem.contains('margin-bottom') || interiorThemeEditorItem.contains('margin-left') || interiorThemeEditorItem.contains('margin-right')">
        <mat-expansion-panel-header>
          <mat-panel-title i18n="@@Margins">Margins</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('margin-top')">
          <div class="toolAreaSlider">
            <span i18n="@@Top">Top</span>
            <!-- TODO: The 'tickInterval' property no longer exists -->
            <mat-slider #topMarginSlider min="-48" max="72" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="interiorThemeEditorItem.getValue('margin-top').slice(0, -2)" (change)="interiorThemeEditorItem.setValue('margin-top', topMarginSlider.value + 'px')" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{topMarginSlider.value}}px</span>
          </div>
        </div>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('margin-bottom')">
          <div class="toolAreaSlider">
            <span i18n="@@Bottom">Bottom</span>
            <!-- TODO: The 'tickInterval' property no longer exists -->
            <mat-slider #bottomMarginSlider min="-48" max="72" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="interiorThemeEditorItem.getValue('margin-bottom').slice(0, -2)" (change)="interiorThemeEditorItem.setValue('margin-bottom', bottomMarginSlider.value + 'px')" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{bottomMarginSlider.value}}px</span>
          </div>
        </div>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('margin-left')">
          <div class="toolAreaSlider">
            <span i18n="@@Left">Left</span>
            <!-- TODO: The 'tickInterval' property no longer exists -->
            <mat-slider #leftMarginSlider min="-48" max="72" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="interiorThemeEditorItem.getValue('margin-left').slice(0, -2)" (change)="interiorThemeEditorItem.setValue('margin-left', leftMarginSlider.value + 'px')" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{leftMarginSlider.value}}px</span>
          </div>
        </div>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('margin-right')">
          <div class="toolAreaSlider">
            <span i18n="@@Right">Right</span>
            <!-- TODO: The 'tickInterval' property no longer exists -->
            <mat-slider #rightMarginSlider min="-48" max="72" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="interiorThemeEditorItem.getValue('margin-right').slice(0, -2)" (change)="interiorThemeEditorItem.setValue('margin-right', rightMarginSlider.value + 'px')" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{rightMarginSlider.value}}px</span>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="paddingExpanded" *ngIf="interiorThemeEditorItem.contains('padding-top') || interiorThemeEditorItem.contains('padding-bottom') || interiorThemeEditorItem.contains('padding-left') || interiorThemeEditorItem.contains('padding-right')">
        <mat-expansion-panel-header>
          <mat-panel-title i18n="@@Padding">Padding</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('padding-top')">
          <div class="toolAreaSlider">
            <span i18n="@@Top">Top</span>
            <!-- TODO: The 'tickInterval' property no longer exists -->
            <mat-slider #topPaddingSlider min="-48" max="72" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="interiorThemeEditorItem.getValue('padding-top').slice(0, -2)" (change)="interiorThemeEditorItem.setValue('padding-top', topPaddingSlider.value + 'px')" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{topPaddingSlider.value}}px</span>
          </div>
        </div>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('padding-bottom')">
          <div class="toolAreaSlider">
            <span i18n="@@Bottom">Bottom</span>
            <!-- TODO: The 'tickInterval' property no longer exists -->
            <mat-slider #bottomPaddingSlider min="-48" max="72" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="interiorThemeEditorItem.getValue('padding-bottom').slice(0, -2)" (change)="interiorThemeEditorItem.setValue('padding-bottom', bottomPaddingSlider.value + 'px')" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{bottomPaddingSlider.value}}px</span>
          </div>
        </div>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('padding-left')">
          <div class="toolAreaSlider">
            <span i18n="@@Left">Left</span>
            <!-- TODO: The 'tickInterval' property no longer exists -->
            <mat-slider #leftPaddingSlider min="-48" max="72" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="interiorThemeEditorItem.getValue('padding-left').slice(0, -2)" (change)="interiorThemeEditorItem.setValue('padding-left', leftPaddingSlider.value + 'px')" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{leftPaddingSlider.value}}px</span>
          </div>
        </div>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('padding-right')">
          <div class="toolAreaSlider">
            <span i18n="@@Right">Right</span>
            <!-- TODO: The 'tickInterval' property no longer exists -->
            <mat-slider #rightPaddingSlider min="-48" max="72" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="interiorThemeEditorItem.getValue('padding-right').slice(0, -2)" (change)="interiorThemeEditorItem.setValue('padding-right', rightPaddingSlider.value + 'px')" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{rightPaddingSlider.value}}px</span>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="otherExpanded" *ngIf="interiorThemeEditorItem.contains('text-indent') || interiorThemeEditorItem.contains('page-break-after') || interiorThemeEditorItem.contains('text-shadow') || interiorThemeEditorItem.contains('list-style-type')">
        <mat-expansion-panel-header>
          <mat-panel-title i18n="@@Other">Other</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('text-indent')">
          <div class="toolAreaSlider">
            <span i18n="@@Text Indent">Text Indent</span>
            <!-- TODO: The 'tickInterval' property no longer exists -->
            <mat-slider #textIndentSlider min="0" max="72" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="interiorThemeEditorItem.getValue('text-indent').slice(0, -2)" (change)="interiorThemeEditorItem.setValue('text-indent', textIndentSlider.value + 'px')" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{textIndentSlider.value}}px</span>
          </div>
        </div>
        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('page-break-after')">
          <mat-form-field>
            <mat-select #pageBreakAfter i18n-placeholder="@@Page Break After" placeholder="Page Break After" [ngModel]="interiorThemeEditorItem.getValue('page-break-after')" required (selectionChange)="interiorThemeEditorItem.setValue('page-break-after', pageBreakAfter.value)" [ngModelOptions]="{standalone: true}">
              <mat-option value="auto" i18n="@@auto">Auto</mat-option>
              <mat-option value="always" i18n="@@always">Always</mat-option>
              <mat-option value="avoid" i18n="@@avoid">Avoid</mat-option>
              <!--<mat-option value="left" i18n="@@Left">Left</mat-option>-->
              <!--<mat-option value="right" i18n="@@Right">Right</mat-option>-->
              <mat-option value="initial" i18n="@@Initial">Initial</mat-option>
              <mat-option value="inherit" i18n="@@Inherit">Inherit</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('text-shadow')">
          <mat-form-field>
            <mat-select #textShadow i18n-placeholder="@@Text Shadow" placeholder="Text Shadow" [ngModel]="interiorThemeEditorItem.getValue('text-shadow')" required (selectionChange)="interiorThemeEditorItem.setValue('text-shadow', textShadow.value)" [ngModelOptions]="{standalone: true}">
              <mat-option value="inherit" i18n="@@Inherit">Inherit</mat-option>
              <mat-option value="none" i18n="@@none">none</mat-option>
              <mat-option value="1px 1px rbg(51,51,51)" i18n="@@Light">Light</mat-option>
              <mat-option value="1.5px 1.5px rbg(51,51,51)" i18n="@@Medium">Medium</mat-option>
              <mat-option value="2px 2px rbg(51,51,51)" i18n="@@Heavy">Heavy</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="toolAreaItem" *ngIf="interiorThemeEditorItem.contains('list-style-type')">
          <mat-form-field>
            <mat-select #listStyleType i18n-placeholder="@@List Style Type" placeholder="List Style Type" [ngModel]="interiorThemeEditorItem.getValue('list-style-type')" required (selectionChange)="interiorThemeEditorItem.setValue('list-style-type', listStyleType.value)" [ngModelOptions]="{standalone: true}">
              <mat-option value="none" i18n="@@none">none</mat-option>
              <mat-option value="inherit" i18n="@@Inherit">Inherit</mat-option>
              <mat-option value="circle" i18n="@@circle">circle</mat-option>
              <mat-option value="disc" i18n="@@disc">dics</mat-option>
              <mat-option value="square" i18n="@@square">square</mat-option>
              <mat-option value="lower-alpha" i18n="@@lower-alpha">lower-alpha</mat-option>
              <mat-option value="lower-greek" i18n="@@lower-greek">lower-greek</mat-option>
              <mat-option value="lower-latin" i18n="@@lower-latin">lower-latin</mat-option>
              <mat-option value="lower-roman" i18n="@@lower-roman">lower-roman</mat-option>
              <mat-option value="upper-alpha" i18n="@@upper-alpha">upper-alpha</mat-option>
              <mat-option value="upper-greek" i18n="@@upper-greek">upper-greek</mat-option>
              <mat-option value="upper-latin" i18n="@@upper-latin">upper-latin</mat-option>
              <mat-option value="upper-roman" i18n="@@upper-roman">upper-roman</mat-option>
              <mat-option value="decimal" i18n="@@decimal">decimal</mat-option>
              <mat-option value="decimal-leading-zero" i18n="@@decimal-leading-zero">decimal-leading-zero</mat-option>
              <mat-option value="georgian" i18n="@@georgian">georgian</mat-option>
              <mat-option value="armenian" i18n="@@armenian">armenian</mat-option>
              <mat-option value="cjk-ideographic" i18n="@@cjk-ideographic">cjk-ideographic</mat-option>
              <mat-option value="hebrew" i18n="@@hebrew">hebrew</mat-option>
              <mat-option value="hiragana" i18n="@@hiragana">hiragana</mat-option>
              <mat-option value="hiragana-iroha" i18n="@@hiragana-iroha">hiragana-iroha</mat-option>
              <mat-option value="katakana" i18n="@@katakana">katakana</mat-option>
              <mat-option value="katakana-iroha" i18n="@@katakana-iroha">@katakana-iroha</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="labeledInput" *ngIf="interiorThemeEditorItem.contains('display')">
          <label class="labeledInput">
            <span class="labeledInput" i18n="@@Display">Display</span>
          </label>
          <input class="labeledInput display" type='text' [ngModel]="interiorThemeEditorItem.getValue('display')" (change)="interiorThemeEditorItem.setValue('display', $event.target.value)" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="labeledInput" *ngIf="interiorThemeEditorItem.contains('scaling')">
          <label class="labeledInput">
            <span class="labeledInput" i18n="@@Scaling">Scaling</span>
          </label>
          <input class="labeledInput scaling" type='text' [ngModel]="interiorThemeEditorItem.getValue('scaling')" (change)="interiorThemeEditorItem.setValue('scaling', $event.target.value)" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="labeledInput" *ngIf="interiorThemeEditorItem.contains('src')">
          <label class="labeledInput">
            <span class="labeledInput" i18n="@@src">src</span>
          </label>
          <input class="labeledInput src" type='text' [ngModel]="interiorThemeEditorItem.getValue('src')" (change)="interiorThemeEditorItem.setValue('src', $event.target.value)" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="labeledInput" *ngIf="interiorThemeEditorItem.contains('border-style')">
          <label class="labeledInput">
            <span class="labeledInput" i18n="@@Border Style">Border Style</span>
          </label>
          <input class="labeledInput border-style" type='text' [ngModel]="interiorThemeEditorItem.getValue('border-style')" (change)="interiorThemeEditorItem.setValue('border-style', $event.target.value)" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="labeledInput" *ngIf="interiorThemeEditorItem.contains('border-width')">
          <label class="labeledInput">
            <span class="labeledInput" i18n="@@Border Width">Border Width</span>
          </label>
          <input class="labeledInput border-width" type='text' [ngModel]="interiorThemeEditorItem.getValue('border-width')" (change)="interiorThemeEditorItem.setValue('border-width', $event.target.value)" [ngModelOptions]="{standalone: true}">
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!--<div class="toolAreaSection">-->
      <!--<h4 i18n="@@Other">Other</h4>-->

    <!--</div>-->
  </mat-card-content>
</mat-card>
