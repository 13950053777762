import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {EcommerceConnection} from "../../model/EcommerceConnection";
import {DataService} from "./data-service";

@Injectable()
export class EcommerceConnectionService extends DataService {
  private objects: EcommerceConnection[] = [];
  private objectsObservable = of( this.objects );
  public loadingState = 'INITIAL';

  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
    super(startupService);
  }

    reset (filter: string = '') {
    this.loadingState = 'INITIAL';
    while ( this.objects.length > 0) {
      this.objects.pop();
    }
  }


  getEcommerceConnections(): Observable<EcommerceConnection[]> {
    const url = `${environment.apiBaseURL}ecommerceConnection`;
    this.messageService.add('EcommerceConnectionService: fetched ecommerceConnections');
    return this.http.get<EcommerceConnection[]>(url, this.startupService.getHttpOptions())
      .pipe(
        map(ecommerceConnections => {
          const ecs = [];
          for ( const ecommerceConnection of ecommerceConnections ) {
            const ec = new EcommerceConnection(ecommerceConnection);
            ecs.push(ec);
          }
          return ecs;
        }),

        catchError(this.handleError('getEcommerceConnections', []))
      );
  }


  getEcommerceConnection(id: string): Observable<EcommerceConnection> {
    const url = `${environment.apiBaseURL}ecommerceConnection/${id}`;
    this.messageService.add(`EcommerceConnectionService: fetched ecommerceConnection id=${id}`);
    return this.http.get<EcommerceConnection>(url, this.startupService.getHttpOptions())
      .pipe(
        map(ecommerceConnection => {
          const ec = new EcommerceConnection(ecommerceConnection);
          return ec;
        }),
        catchError(this.handleError<EcommerceConnection>(`getEcommerceConnection id=${id}`))
    );
  }

  /** PUT: update the ecommerceConnection on the server */
  updateEcommerceConnection (ecommerceConnection: EcommerceConnection): Observable<EcommerceConnection> {
    const url = `${environment.apiBaseURL}ecommerceConnection/${ecommerceConnection.ID}`;
    this.messageService.add(`EcommerceConnectionService: updated ecommerceConnection id=${ecommerceConnection.ID}`);
    return this.http.put(url, ecommerceConnection, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated ecommerceConnection id=${ecommerceConnection.ID}`)),
      catchError(this.handleError<any>('updateEcommerceConnection'))
    );
  }

  addEcommerceConnection (ecommerceConnection: EcommerceConnection): Observable<EcommerceConnection> {
    const url = `${environment.apiBaseURL}ecommerceConnection`;
    return this.http.post<EcommerceConnection>(url, ecommerceConnection, this.startupService.getHttpOptions()).pipe(
      tap(o => {
        this.log(`added ecommerceConnection w/ id=${ecommerceConnection.ID}`);
        this.objects.push(new EcommerceConnection(o));
      }),
      catchError(this.handleError<EcommerceConnection>('addEcommerceConnection'))
    );
  }

  /** DELETE: delete the ecommerceConnection from the server */
  deleteEcommerceConnection (ecommerceConnection: EcommerceConnection): Observable<EcommerceConnection> {
    const url = `${environment.apiBaseURL}ecommerceConnection/${ecommerceConnection.ID}`;

    return this.http.delete<EcommerceConnection>(url, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`deleted ecommerceConnection id=${ecommerceConnection.ID}`)),
      catchError(this.handleError<EcommerceConnection>('deleteEcommerceConnection'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add('EcommerceConnectionService: ' + message);
  }

}
