<mat-card appearance="outlined">
  <mat-card-content>
    <ng-container *ngIf="printNodeOrder.weight < 100">
      <button *ngIf="printNodeOrder.inventoryType === 'FULFILL_FROM_INVENTORY'" mat-icon-button (click)="printPickSheet(printNodeOrder, 'B')" i18n-matTooltip="@@Print Small Order Pick Sheet" matTooltip="Print Small Order Pick Sheet"><mat-icon>print</mat-icon></button>
    </ng-container>
    <ng-container *ngIf="printNodeOrder.weight >= 100 && printNodeOrder.weight <= 500">
      <button  *ngIf="printNodeOrder.inventoryType === 'FULFILL_FROM_INVENTORY'" mat-icon-button (click)="printPickSheet(printNodeOrder, 'B')" i18n-matTooltip="@@Print Large Order Pick Sheets" matTooltip="Print Large Order Pick Sheets"><mat-icon>print</mat-icon></button>
      <button mat-icon-button (click)="printShippingLabels(printNodeOrder, printNodeOrder.carrier + '_' + printNodeOrder.servicelevel, 'B')" i18n-matTooltip="@@Print Pick Sheet" matTooltip="Print Freight Documents"><mat-icon>print</mat-icon></button>
    </ng-container>
    <ng-container *ngIf="printNodeOrder.weight >= 500">
      <button  *ngIf="printNodeOrder.inventoryType === 'FULFILL_FROM_INVENTORY'" mat-icon-button (click)="printPickSheet(printNodeOrder, 'B')" i18n-matTooltip="@@Print Large Order Pick Sheets" matTooltip="Print Large Order Pick Sheets"><mat-icon>print</mat-icon></button>
      <button mat-icon-button (click)="printShippingLabels(printNodeOrder, printNodeOrder.carrier + '_' + printNodeOrder.servicelevel, 'B')" i18n-matTooltip="@@Print Freight Documents" matTooltip="Print Freight Documents"><mat-icon>print</mat-icon></button>
      <button mat-icon-button (click)="printFreightAddressSheets(printNodeOrder,'B')" i18n-matTooltip="@@Print Freight Address Sheets" matTooltip="Print Freight Address Sheets"><mat-icon>print</mat-icon></button>
    </ng-container>
    <ng-container *ngFor="let printNodeOrderItem of printNodeOrder.printNodeOrderItems">
      <app-fulfilment-order-item [printNodeOrderItem]="printNodeOrderItem"></app-fulfilment-order-item>
    </ng-container>
  </mat-card-content>
</mat-card>
