<div class="alertContainer" *ngIf="alerts && alerts.length > 0">
    <div class="alertDiv">
        <div class="alertButton">
            <button type="submit" class="btn btn-primary mat-focus-indicator mat-raised-button mat-button-base mat-primary ng-star-inserted" (click)="clear()">Clear All Alerts</button>
        </div>
    </div>
    <div class="alertDiv" *ngFor="let alert of alerts">
        <div class="{{ cssClass(alert) }} alert-dismissable">
            {{alert.message}}
            <a class="close" (click)="removeAlert(alert)">&times;</a>
        </div>
    </div>
</div>
