import {Component, Inject, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../pipe/language.pipe";
import {UserService} from "../../../service-data/user.service";
import {Product} from "../../../../model/Product";
import {FacilityInventoryProduct} from "../../../../model/FacilityInventoryProduct";
import {FacilityInventoryProductService} from "../../../service-data/facility-inventory-product.service";
import {Organization} from "../../../../model/Organization";
import {OrganizationService} from "../../../service-data/organization.service";
import {ProductService} from "../../../service-data/product.service";
import {MatTableDataSource} from "@angular/material/table";
import {StartupService} from "../../../service-ui/startup.service";
import {BookService} from "../../../service-data/book.service";
import {Facility} from "../../../../model/Facility";
import {FacilityInventoryProductEventService} from "../../../service-data/facility-inventory-product-event.service";

@Component({
  selector: 'app-add-facility-inventory-product',
  templateUrl: './add-facility-inventory-product.component.html',
  styleUrls: ['./add-facility-inventory-product.component.css']
})
export class AddFacilityInventoryProductComponent implements OnInit {
  public products: Product[];
  public organizations: Organization[];
  public breakpoint: number; // Breakpoint observer code
  public printnode_ID: number = 0;
  public orgaizationId: number = 0;
  public location: string = '';
  public shelf: string = '';
  public quantityOnHand: number = 0;
  public quantityCommited: number = 0;
  public state: string = 'PREPARING';
  public inventoryManagementType: string = 'LEVEL';
  public product: Product;
  public facilityInventoryProduct: FacilityInventoryProduct;
  public sequence: string = '1';
  public reorderQuantity: number = 20;
  public reorderLevel: number = 10;
  public caseQuantity: number = 0;
  public bookId: number;
  public initialQuantity: number = 0;

  public errorMessage = '';
  public message = '';

  public addForm: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public organizationService: OrganizationService, public productService: ProductService, public bookService: BookService, public facilityInventoryProductService: FacilityInventoryProductService, public facilityInventoryProductEventService: FacilityInventoryProductEventService, public startupService: StartupService, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<AddFacilityInventoryProductComponent>) {
    if ( data.organizations ) {
      this.organizations = data.organizations;
    }
  }

  public ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      printnode_ID: [this.printnode_ID, [Validators.required]],
      inventoryManagementType: [this.inventoryManagementType, [Validators.required]],
      // product: [this.product, [Validators.required]],
      // organizationId: [this.orgaizationId, []],
      sequence: [this.sequence, [Validators.required]],
      reorderQuantity: [this.reorderQuantity, [Validators.required]],
      reorderLevel: [this.reorderLevel, [Validators.required]],
      location: [this.location, [Validators.required]],
      shelf: [this.shelf, [Validators.required]],
      caseQuantity: [this.caseQuantity, [Validators.required]],
      bookId: [this.bookId, [Validators.required]],
      initialQuantity: [this.initialQuantity, [Validators.required]]
    });

    if ( this.startupService.selectedRole === 'facility' ) {
      this.printnode_ID = Number(this.startupService.selectedRoleId);
    }
    this.breakpoint = window.innerWidth <= 300 ? 1 : 2; // Breakpoint observer code
    if ( this.startupService.getRole() === 'organization') {
      this.orgaizationId = Number(this.startupService.getRoleId());
    } else {
      this.orgaizationId = this.startupService.getCurrentOrganization().ID;
    }
  }

  public lookupProductFromBookId() {
    let facilityID = this.addForm.controls.printnode_ID.value;
    if ( this.startupService.selectedRole === 'facility') {
      facilityID = Number(this.startupService.selectedRoleId);
    }
    const bookId = this.addForm.controls.bookId.value;
    this.message = 'Looking for Product for Book ID ' + bookId;
    this.errorMessage = '';
    this.product = undefined;
    let facilityInventoryProduct;
    for (const fip of this.facilityInventoryProductService.objects) {
      if ( fip.product && fip.product.book_ID === bookId && fip.printnode_ID === facilityID ) {
        facilityInventoryProduct = fip;
      }
    }
    // If no existing FacilityInventoryProduct exists for the bookId and facility then continue attempting to add
    if (!facilityInventoryProduct) {
      this.facilityInventoryProduct = undefined;
      let product;
      for (const p of this.productService.objects) {
        if (p.book_ID === bookId) {
          product = p;
        }
      }
      if ( product ) {
        this.product = product;
        this.message = '';
      } else {
        this.productService.getFilteredItems({ eq: { 'product.book_ID': bookId } }, true, 10).subscribe( products => {
          if ( products && products.length > 0 ) {
            this.product = products[0];
            for (const p of products ) {
              // Use a product that is not archived and is not a proof if one exists
              if ( p.archived === 'FALSE' ) {
                this.product = p;
              }
            }
            this.message = '';
          } else {
            this.message = '';
            this.errorMessage = 'Could not find a product for this bookId';
          }
        });
      }
    } else {
      this.facilityInventoryProduct = facilityInventoryProduct;
      this.message = '';
      this.errorMessage = 'An Inventory item already exists for this book ID and can not be added.';
    }
  }

  public onAdd(): void {
    // Look to see if there is already a FacilityInventoryProduct
    const objectToAdd = new FacilityInventoryProduct(undefined);
    objectToAdd.printnode_ID = this.addForm.controls.printnode_ID.value;
    if ( this.startupService.selectedRole === 'facility') {
      objectToAdd.printnode_ID = Number(this.startupService.selectedRoleId);
    }
    if ( this.product && !this.facilityInventoryProduct ) {
      objectToAdd.location = this.addForm.controls.location.value;
      objectToAdd.shelf = this.addForm.controls.shelf.value;
      objectToAdd.inventoryManagementType = this.addForm.controls.inventoryManagementType.value;
      objectToAdd.product_ID = this.product.ID;
      objectToAdd.productIds = '' + this.product.ID;
      objectToAdd.title = this.product.title;
      objectToAdd.quantityCommited = 0;
      objectToAdd.state = 'ACTIVE';
      objectToAdd.lastUpdate = new Date();
      objectToAdd.sequence = this.addForm.controls.sequence.value;
      objectToAdd.reorderQuantity = this.addForm.controls.reorderQuantity.value;
      objectToAdd.reorderLevel = this.addForm.controls.reorderLevel.value;
      objectToAdd.caseQuantity = this.addForm.controls.caseQuantity.value;
      objectToAdd.quantityOnHand = this.addForm.controls.initialQuantity.value;

      this.facilityInventoryProductService.addItem(objectToAdd).subscribe( addedFacilityInventoryProduct => {
        console.log(`Added FacilityInventoryProduct productId: ${addedFacilityInventoryProduct.product_ID}`);
        this.product.facilityInventoryProducts.push(new FacilityInventoryProduct(addedFacilityInventoryProduct));
        // if ( addedFacilityInventoryProduct.facilityInventoryProductEvents ) {
        //   if ( addedFacilityInventoryProduct.facilityInventoryProductEvents ) {
        //     for ( const facilityInventoryProductEvent of addedFacilityInventoryProduct.facilityInventoryProductEvents ) {
        //       if ( facilityInventoryProductEvent.source === 'INITIAL') {
        //         facilityInventoryProductEvent.quantity = Number(this.addForm.controls.initialQuantity);
        //         this.facilityInventoryProductEventService.updateFacilityInventoryProductEvent(facilityInventoryProductEvent).subscribe( changedFacilityInventoryProductEvent => {
        //           console.log(`Set the quantity of FacilityInventoryProduct productId: ${addedFacilityInventoryProduct.product_ID}`);
        //         });
        //       }
        //     }
        //   }
        // }
      });
      this.markAsDirty(this.addForm);
      this.dialogRef.close();
    } else {
      this.errorMessage = 'Could not find a product for this bookId';
    }
  }

  public organizationSet() {
    const filter = { eq: {
        'product.org_ID': this.orgaizationId
      }
    };
    this.productService.getFilteredItems(filter)
      .subscribe(products => {
        this.products = products;
      });
  }

  public productSet() {
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
