<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Orders">Orders</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Orders">Orders</span>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="orders"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-card appearance="outlined">
        <mat-card-content>
          <mat-accordion class="headers-align">
            <ng-container *ngFor="let order of orders">
              <ng-container *ngIf="order.orderDeliverTos[0]">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{order.ID}}</mat-panel-title>
                    <mat-panel-description>
                      <div style="display: inline-block">
                        <span class="row" *ngIf="order.orderDeliverTos[0].orderItems && order.orderDeliverTos[0].orderItems.length > 0">{{order.orderDeliverTos[0].orderItems[0].quantity}} X {{order.orderDeliverTos[0].orderItems[0].ID}}</span>
                        <span class="row" *ngIf="order.orderDeliverTos[0].orderItems && order.orderDeliverTos[0].orderItems.length > 1">{{order.orderDeliverTos[0].orderItems[1].quantity}} X {{order.orderDeliverTos[0].orderItems[1].ID}}</span>
                        <span class="row" *ngIf="order.orderDeliverTos[0].orderItems && order.orderDeliverTos[0].orderItems.length > 2">{{order.orderDeliverTos[0].orderItems[2].quantity}} X {{order.orderDeliverTos[0].orderItems[2].ID}}</span>
                      </div>


                      <div style="display: inline-block">
                        <span class="row"><span i18n="@@Ordered: ">Ordered: </span>{{order.created | date: 'MM/dd/yy hh:mm'}}</span>
                        <span class="row"><span i18n="@@Shipped: ">Shipped: </span>{{order.shippedTimestamp | date: 'MM/dd/yy hh:mm'}}</span>
                      </div>
                      <div style="display: inline-block">
                        <span class="row">{{order.orderDeliverTos[0].firstname}} {{order.orderDeliverTos[0].lastname}}</span>
                        <span class="row">{{order.orderDeliverTos[0].address1}}</span>
                        <span class="row">{{order.orderDeliverTos[0].address2}}</span>
                        <span class="row">{{order.orderDeliverTos[0].city}}, {{order.orderDeliverTos[0].state}} {{order.orderDeliverTos[0].postalcode}} {{order.orderDeliverTos[0].country}}</span>
                      </div>
                      <div style="display: inline-block">
                        <span class="row">{{order.orderDeliverTos[0].carrier}}</span>
                        <span class="row">{{order.orderDeliverTos[0].servicelevel}}</span>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <ng-container *ngIf="order.orderDeliverTos[0].orderItems && order.orderDeliverTos[0].orderItems.length > 0">
                    <table class="items">
                      <tr>
                        <th i18n="@@Id">Id</th>
                        <th i18n="@@Title">Title</th>
                        <th i18n="@@Size">Size</th>
                        <th i18n="@@Quantity">Quantity</th>
                        <th i18n="@@Amount" style="text-align: right">Amount</th>
                      </tr>
                      <ng-container *ngFor="let item of order.orderDeliverTos[0].orderItems">
                        <tr class="item">
                          <td>{{item.ID}}</td><td>{{item.product.title}}</td><td>inXin</td><td>{{item.quantity}}</td><td style="text-align: right">{{item.manufacturingCharge | currency:'USD':'symbol':'1.2-2' }}</td>
                        </tr>
                      </ng-container>
                      <tr style="font-weight: bold">
                        <td i18n="@@Books">Books</td><td></td><td></td><td></td><td style="text-align: right">{{order.manufacturingCharge | currency:'USD':'symbol':'1.2-2' }}</td>
                      </tr>
                      <tr style="font-weight: bold">
                        <td i18n="@@Total">Packing</td><td></td><td></td><td></td><td  style="text-align: right">{{order.packingCharge | currency:'USD':'symbol':'1.2-2' }}</td>
                      </tr>
                      <ng-container *ngIf="order.gatewayCharge > 0">
                        <tr style="font-weight: bold">
                          <td i18n="@@Total">Gateway</td><td></td><td></td><td></td><td  style="text-align: right">{{order.gatewayCharge | currency:'USD':'symbol':'1.2-2' }}</td>
                        </tr>
                      </ng-container>
                      <tr style="font-weight: bold">
                        <td i18n="@@Shipping">Shipping</td><td></td><td></td><td></td><td style="text-align: right">{{order.shippingCharge | currency:'USD':'symbol':'1.2-2' }}</td>
                      </tr>
                      <tr style="font-weight: bold">
                        <td i18n="@@Tax">Tax</td><td></td><td></td><td></td><td style="text-align: right">{{ ( order.packingTaxCharge + order.manufacturingTaxCharge + order.shippingTaxCharge + order.gatewayTaxCharge ) | currency:'USD':'symbol':'1.2-2' }}</td>
                      </tr>
                      <tr style="font-weight: bold">
                        <td i18n="@@Total">Total</td><td></td><td></td><td></td><td style="text-align: right">{{ ( order.packingCharge + order.manufacturingCharge + order.shippingCharge + order.gatewayCharge + order.packingTaxCharge + order.manufacturingTaxCharge + order.shippingTaxCharge + order.gatewayTaxCharge ) | currency:'USD':'symbol':'1.2-2' }}</td>
                      </tr>
                    </table>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
            </ng-container>
          </mat-accordion>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






