import {Component, Input, OnInit} from '@angular/core';
import { Clipboard } from "@angular/cdk/clipboard";
import {Order} from "../../../../model/Order";

@Component({
  selector: 'app-billing-order',
  templateUrl: './billing-order.component.html',
  styleUrls: ['./billing-order.component.css']
})
export class BillingOrderComponent implements OnInit {
  @Input() order: Order;
  constructor(private clipboard: Clipboard) { }

  ngOnInit() {
  }

  copyOrderDataToCSV () {
    // const id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //   // tslint:disable-next-line:no-bitwise
    //   const r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8);
    //   return v.toString(16);
    // });
    this.clipboard.copy(this.buildCSVContent());
  }

  downloadOrderDataToCSV () {
    const downloadFileName = this.order.ID + '_' + this.order.customerorderid + '_' + this.order.purchaseOrder_private + '.csv';
    const downloadFileContents = this.buildCSVContent();
    const blob = new Blob([downloadFileContents], {type: 'text/text'});
    const e = document.createEvent('MouseEvents');
    const a = document.createElement('a');
    a.download = downloadFileName;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
    e.initEvent('click', true, false);
    a.dispatchEvent(e);
  }

  buildCSVContent(): string {
    let value = 'orderDate, name, organization, address1, address2, city, state, zip, country, book, quantity\n';
    const orderDate = this.order.created;
    for ( const orderDeliverTo of this.order.orderDeliverTos ) {
      const name = ((orderDeliverTo.firstname ? orderDeliverTo.firstname : '') + ' ' + (orderDeliverTo.lastname ? orderDeliverTo.lastname : '')).trim();
      const organization = orderDeliverTo.company ? orderDeliverTo.company : '';
      const address1 = orderDeliverTo.address1 ? orderDeliverTo.address1 : '';
      const address2 = orderDeliverTo.address2 ? orderDeliverTo.address2 : '';
      const city = orderDeliverTo.city ? orderDeliverTo.city : '';
      const state = orderDeliverTo.state ? orderDeliverTo.state : '';
      const zip = orderDeliverTo.postalcode ? orderDeliverTo.postalcode : '';
      const country = orderDeliverTo.country ? orderDeliverTo.country : '';
      for ( const orderItem of orderDeliverTo.orderItems ) {
        value += `${orderDate},${name},${organization},${address1},${address2},${city},${state},${zip},${country},${orderItem.product_ID},${orderItem.quantity}\n`;
      }
    }
    return value;
  }
}
