import {Component, Inject, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../../pipe/language.pipe";
import {UserService} from "../../../../service-data/user.service";
import {Product} from "../../../../../model/Product";
import {FacilityInventoryProduct} from "../../../../../model/FacilityInventoryProduct";
import {FacilityInventoryProductService} from "../../../../service-data/facility-inventory-product.service";
import {Organization} from "../../../../../model/Organization";
import {OrganizationService} from "../../../../service-data/organization.service";
import {ProductService} from "../../../../service-data/product.service";
import {MatTableDataSource} from "@angular/material/table";
import {FacilityInventoryProductEvent} from "../../../../../model/FacilityInventoryProductEvent";
import {FacilityInventoryProductEventService} from "../../../../service-data/facility-inventory-product-event.service";
import {PrintNodeOrder} from "../../../../../model/PrintNodeOrder";
import {PrintNodeOrderItem} from "../../../../../model/PrintNodeOrderItem";
import {PrintNodeOrderService} from "../../../../service-data/print-node-order.service";
import {PrintNodeOrderItemService} from "../../../../service-data/print-node-order-item.service";

@Component({
  selector: 'app-unreceive-replenishment-print-node-order',
  templateUrl: './unreceive-replenishment-print-node-order-component.html',
  styleUrls: ['./unreceive-replenishment-print-node-order-component.css']
})
export class UnreceiveReplenishmentPrintNodeOrderComponent implements OnInit {
  public printNodeOrder: PrintNodeOrder;
  public printNodeOrderItem: PrintNodeOrderItem;
  public breakpoint: number; // Breakpoint observer code
  public actionType: string = ``;
  public date: Date = new Date();
  public quantity: number = 0;
  public formGroup: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public printNodeOrderService: PrintNodeOrderService, public printNodeOrderItemService: PrintNodeOrderItemService, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<UnreceiveReplenishmentPrintNodeOrderComponent>) {
    this.printNodeOrder = data.printNodeOrder;
    this.printNodeOrderItem = data.printNodeOrderItem;
    this.quantity = this.printNodeOrderItem.quantity;
  }

  public ngOnInit(): void {
    this.date = new Date();
    this.formGroup = this.formBuilder.group({
      quantity: [this.quantity, [Validators.required]],
      date: [this.date, [Validators.required]],
    });

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onSave(): void {
    this.printNodeOrderItem.quantity = this.formGroup.controls.quantity.value;
    this.printNodeOrder.shippingStatus = 'SHIPPED';
    this.printNodeOrder.shippedTimestamp = new Date(this.formGroup.controls.date.value + 'T12:00:00');
    this.printNodeOrder.shippedTimestamp.setDate(this.printNodeOrder.shippedTimestamp.getDate() + 1);

    this.printNodeOrderService.updateItem(this.printNodeOrder).subscribe( object => {
      console.log(`Updated PrintNodeOrderStatus : ${object.ID}`);
    });
    this.printNodeOrderItemService.updateItem(this.printNodeOrderItem).subscribe( object => {
      console.log(`Updated PrintNodeOrderStatus : ${object.ID}`);
    });
    this.markAsDirty(this.formGroup);
    this.dialogRef.close();
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
