export class Chapter {
  public id: string;
  public title: string;
  public type: string;
  public status: string;
  public source: string;
  public mediaFile: string;
  public audioDuration: number;
  public audioCharge: number;
  public audioChargeStatus: string;

  constructor( obj ) {
    this.type = 'standard';
    this.status = '';
    this.source = '';
    this.title = '';
    this.mediaFile = '';
    this.audioCharge = 0;
    this.audioDuration = 0;
    this.audioChargeStatus = 'INITIAL';

    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        this[prop] = obj[prop];
      }
    } else {
      this.init();
    }
  }
  public init() {
    this.id = Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16) + '-' + Math.floor((Math.random()) * 0x10000).toString(16);
    let locale;
    if (localStorage.getItem('localeId') !== null) {
      locale = localStorage.getItem('localeId');
    } else {
      locale = null;
    }

    this.title = 'New Chapter';
    switch ( locale ) {
      case 'en': {
        this.title = 'New Chapter';
        break;
      }
      case 'fr': {
        this.title = 'FRNew Chapter';
        break;
      }
      case 'es': {
        this.title = 'ESNew Chapter';
        break;
      }
      case 'de': {
        this.title = 'DENew Chapter';
        break;
      }
      case 'zh': {
        this.title = 'ZHNew Chapter';
        break;
      }
      case 'ar': {
        this.title = 'ARNew Chapter';
        break;
      }
      case 'am': {
        this.title = 'AMNew Chapter';
        break;
      }
      default: {
        this.title = 'Default New Chapter';
        break;
      }
    }
  }

}
