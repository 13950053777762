import {DataObject} from "./DataObject";
import {ManufacturingResourceProvider} from "./supporting/ManufacturingResourceProvider";
import {ManufacturingResouceConsumer} from "./supporting/ManufacturingResourceConsumer";
import {CriteriaSet} from "./supporting/CriteriaSet";
import {ManufacturingResourceBucket} from "./ManufacturingResourceBucket";
import {PrintNodeOrderItem} from "./PrintNodeOrderItem";

export class ManufacturingResourceBucketPeriod implements DataObject, ManufacturingResourceProvider {
  ID: number;
  manufacturingResourceBucket: ManufacturingResourceBucket;
  printNodeOrderItems: PrintNodeOrderItem[];

  constructor (obj) {
    this.ID = 0;
    this.printNodeOrderItems = [];
    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if (obj) {
    //   for (const prop of Object.keys(obj)) {
    //     this[prop] = obj[prop];
    //     if ( prop === 'manufacturingResourceBucket') {
    //       this.manufacturingResourceBucket = new ManufacturingResourceBucket(obj[prop]);
    //     }
    //     if ( prop === 'printNodeOrderItems') {
    //       this.printNodeOrderItems = [];
    //       for ( const pnoi of obj[prop]) {
    //         this.printNodeOrderItems.push(new PrintNodeOrderItem(pnoi, pnoi.printNodeOrder));
    //       }
    //     }
    //   }
    // }
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }

  isCompatible(manufacturingResouceConsumer: ManufacturingResouceConsumer): boolean {
    return false;
  }

  areResourcesAvailable(manufacturingResouceConsumer: ManufacturingResouceConsumer): boolean {
    return false;
  }

  consumeResources(manufacturingResouceConsumer: ManufacturingResouceConsumer) {
  }

  releaseResources(manufacturingResouceConsumer: ManufacturingResouceConsumer) {
  }

  getAllowedCharacteristics(manufacturingResouceConsumer: ManufacturingResouceConsumer): CriteriaSet {
    return this.manufacturingResourceBucket.criteriaSet;
  }
  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
