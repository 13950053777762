import { Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../pipe/language.pipe";
import {UserService} from "../../../service-data/user.service";
import {OrganizationService} from "../../../service-data/organization.service";
import {Umbrella} from "../../../../model/Umbrella";
import {Organization} from "../../../../model/Organization";
import {StartupService} from "../../../service-ui/startup.service";

@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.css']
})
export class AddOrganizationComponent implements OnInit {
  public breakpoint: number; // Breakpoint observer code
  public organizationName: string = ``;
  public name: string = ``;
  public email: string = ``;
  public phone: string = ``;
  // public phoneCountry: string = ``;
  public addForm: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public userService: UserService, public organizationService: OrganizationService, public startupService: StartupService) {
  }

  public ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      organizationName: [this.organizationName, [Validators.required, Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      name: [this.name, [Validators.required, Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      email: [this.email, [Validators.required, Validators.email]],
      phone: [this.phone, [Validators.required]]
      // phone: [this.phone, [Validators.required, PhoneValidator.validCountryPhone(this.addForm.controls.phoneCountry)]],
      // phoneCountry: [this.phoneCountry, [Validators.required, Validators.pattern('^\\d*[0-9]\\d*$')]]
    });
    // this.addForm.controls.phoneCountry.validator = PhoneValidator.validCountryPhone(this.addForm.controls.phoneCountry);

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onAdd(): void {
    const objectToAdd = new Organization( undefined );
    const organization = this.startupService.getCurrentOrganization();
    if ( organization ) {
      objectToAdd.name = this.addForm.controls.organizationName.value;
      const name = this.addForm.controls.name.value;
      const email = this.addForm.controls.email.value;
      const phone = this.addForm.controls.phone.value;
      switch ( this.startupService.selectedRole ) {
        case 'serviceProvider': {
          objectToAdd.serviceProvider_ID = Number(this.startupService.selectedRoleId);
          objectToAdd.printnode_ID = 0;
          objectToAdd.agent_ID = 0;
          this.organizationService.addOrganization(objectToAdd, name, email, phone).subscribe( addResult => {
            console.log("Added organization");
          });
          break;
        }
        // case 'facility': {
        //   objectToAdd.serviceProvider_ID = organization.serviceProvider_ID;
        //   objectToAdd.printnode_ID = Number(this.startupService.selectedRoleId);
        //   objectToAdd.agent_ID = 0;
        //   this.organizationService.addOrganization(objectToAdd).subscribe( addResult => {
        //     console.log("Added organization");
        //   });
        //   break;
        // }
        case 'umbrella': {
          objectToAdd.serviceProvider_ID = organization.serviceProvider_ID;
          objectToAdd.printnode_ID = 0;
          objectToAdd.agent_ID = Number(this.startupService.selectedRoleId);
          this.organizationService.addOrganization(objectToAdd, name, email, phone).subscribe( addResult => {
            console.log("Added organization");
          });
          break;
        }
      }
    }
    // this.organizationService.getOrganization(this.startupService.getRoleId()).subscribe( organization => {
    // });
    this.markAsDirty(this.addForm);
    //  close the dialog here?
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
