import {ManufacturingSetRule} from "./supporting/ManufacturingSetRule";
import {PrintNodeOrderItem} from "./PrintNodeOrderItem";
import {Criteria} from "./supporting/Criteria";
import {CriteriaSet} from "./supporting/CriteriaSet";
import {ManufacturingStep} from "./ManufacturingStep";
import {ManufacturingResource} from "./ManufacturingResource";
import {ManufacturingSet} from "./ManufacturingSet";
import {ManufacturingWork} from "./ManufacturingWork";
import {DataObject} from "./DataObject";

export class ManufacturingTask implements DataObject {
  ID: number;
  start: Date | null;
  end: Date | null;
  durationtotal: number | null;
  durationremaining: number | null;
  manufacturingresource_id: number | null;
  manufacturingstaff_id: number | null;
  manufacturingwork_id: number | null;
  tasktype: string | null;
  status: string | null; // "INITIAL", "STARTED", "PAUSED", "COMPLETED", "ERROR"
  percentcomplete: number | null;
  manufacturingset_id: number;
  manufacturingresourcetype_id: number;
  useType: string | null;
  next: string | null;
  printnode_ID: number;
  lastUpdate: Date;
  units: string;
  details: string;
  inputStatus: string;
  locked: string;
  inputIds: string;



  manufacturingResource: ManufacturingResource;
  manufacturingSet: ManufacturingSet;
  manufacturingWork: ManufacturingWork;
  manufacturingSteps: ManufacturingStep[];

  nextManufacturingTasks: ManufacturingTask[] = [];
  prevManufacturingTasks: ManufacturingTask[] = [];

  constructor (obj, manufacturingResource, manufacturingSet, manufacturingWork) {
    this.ID = 0;
    this.start = new Date(0);
    this.end = new Date(0);
    this.durationtotal = 0;
    this.durationremaining = 0;
    this.manufacturingresource_id = 0;
    this.manufacturingstaff_id = 0;
    this.manufacturingwork_id = 0;
    this.tasktype = '';
    this.status = "INITIAL"; // "INITIAL", "STARTED", "PAUSED", "COMPLETED", "ERROR"
    this.percentcomplete = 0;
    this.manufacturingset_id = 0;
    this.manufacturingresourcetype_id = 0;
    this.useType = '';
    this.next = '';
    this.printnode_ID = 0;
    this.lastUpdate = new Date(0);
    this.units = 'UNDEFINED';
    this.details = '';
    this.inputStatus = '';
    this.locked = '';
    this.inputIds = '';

    this.manufacturingResource = undefined;
    this.manufacturingSet = undefined;
    this.manufacturingWork = undefined;
    this.manufacturingSteps = undefined;

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if (obj) {
    //   for (const prop of Object.keys(obj)) {
    //     this[prop] = obj[prop];
    //   }
    //   if ( manufacturingResource ) {
    //     this.manufacturingResource = manufacturingResource;
    //   } else {
    //     if ( obj.manufacturingResource ) {
    //       this.manufacturingResource = new ManufacturingResource(obj.manufacturingResource);
    //     } else {
    //       this.manufacturingResource = new ManufacturingResource(undefined);
    //     }
    //   }
    //   if ( manufacturingSet ) {
    //     this.manufacturingSet = manufacturingSet;
    //   } else {
    //     if ( obj.manufacturingSet ) {
    //       this.manufacturingSet = new ManufacturingSet(obj.manufacturingSet);
    //     } else {
    //       this.manufacturingSet = new ManufacturingSet(undefined);
    //     }
    //   }
    //   if ( manufacturingWork ) {
    //     this.manufacturingWork = manufacturingWork;
    //   } else {
    //     if ( obj.manufacturingWork ) {
    //       this.manufacturingWork = new ManufacturingWork(obj.manufacturingWork);
    //     } else {
    //       this.manufacturingWork = new ManufacturingWork(undefined);
    //     }
    //   }
    //
    //   if ( obj.manufacturingSteps ) {
    //     this.manufacturingSteps = [];
    //     for ( const manufacturingStep of obj.manufacturingSteps ) {
    //       if ( manufacturingStep instanceof ManufacturingStep ) {
    //         this.manufacturingSteps.push(manufacturingStep);
    //       } else {
    //         const ms = new ManufacturingStep(manufacturingStep);
    //         manufacturingStep.manufacturingTask = this;
    //         this.manufacturingSteps.push(ms);
    //       }
    //     }
    //   }
    // }

  }

  async setManufacturingResource(manufacturingResource: ManufacturingResource) {
    this.manufacturingResource = manufacturingResource;
   // Put this back this.computeStatistics();
  }

  getRate(): number {
    // if ( this.manufacturingResource && this.manufacturingResource.useTypeRates ) {
    //   const workTypeRates = JSON.stringify(this.manufacturingResource.useTypeRates);
    //   if ( workTypeRates ) {
    //     return 1;
    //   }
    // }
    return 0;
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }

  // START Put this back in once ManufacturingStep object is complete (and possibly other classes
  // START Put this back in once ManufacturingStep object is complete (and possibly other classes
  // START Put this back in once ManufacturingStep object is complete (and possibly other classes
  // computeStatistics() {
  //   if ( this.manufacturingResource ) {
  //     const rate = this.getRate();
  //     if ( rate ) {
  //       let total = 0;
  //       let completed = 0;
  //       for ( const manufacturingStep of this.manufacturingSteps ) {
  //         manufacturingStep.total = ( !manufacturingStep.total || manufacturingStep.total === 0 ) ? 1 : manufacturingStep.total;
  //         manufacturingStep.totalcompleted = ( !manufacturingStep.totalcompleted || manufacturingStep.totalcompleted === 0 ) ? 0 : manufacturingStep.totalcompleted;
  //         manufacturingStep.durationtotal = rate * manufacturingStep.total;
  //         manufacturingStep.percentcomplete = manufacturingStep.totalcompleted / manufacturingStep.total;
  //         manufacturingStep.durationremaining = rate * manufacturingStep.totalcompleted;
  //         manufacturingStep.durationtotal = rate * manufacturingStep.total;
  //         total += manufacturingStep.total;
  //         completed += manufacturingStep.totalcompleted;
  //       }
  //       this.durationtotal = total * rate;
  //       this.durationremaining = ( total - completed ) * rate;
  //       this.percentcomplete = completed / total;
  //     }
  //   }
  // }
  //
  // // TODO: WHere do we save changes here?
  // async addPrintNodeOrderItem(printNodeOrderItem: PrintNodeOrderItem) {
  //   const manufacturingStep: ManufacturingStep = new ManufacturingStep();
  //   // TODO: Remove the following from the database if they prove to be redundant to the ManufacturingTask
  //   manufacturingStep.manufacturingresourcetype_id = this.manufacturingresourcetype_id;
  //   manufacturingStep.manufacturingTaskType = this.tasktype ? this.tasktype : 'NOT_SET';
  //   manufacturingStep.printnode_id = this.printnode_ID;
  //   manufacturingStep.useType = this.useType;
  //   manufacturingStep.printnodeorderitem_id = printNodeOrderItem.ID;
  //   manufacturingStep.units = this.units;
  //   manufacturingStep.totalcompleted = 0;
  //   manufacturingStep.percentcomplete = 0.0;
  //   manufacturingStep.durationremaining = 0;
  //   manufacturingStep.durationtotal = 0;
  //   manufacturingStep.manufacturingTask = this;
  //   manufacturingStep.start = new Date();
  //   manufacturingStep.end = new Date();
  //   // Put in the value based on the Units
  //   switch ( this.units ) {
  //     case Units.BOOKS: {
  //       manufacturingStep.total = printNodeOrderItem.quantity;
  //       break;
  //     }
  //     case Units.LIFTS: {
  //       const numberUP =  ( printNodeOrderItem.book.body.height / 72 ) > 9 || ( printNodeOrderItem.book.body.width / 72 ) > 6 ? 2 : 4;
  //       manufacturingStep.total = 4 / ( printNodeOrderItem.quantity * printNodeOrderItem.book.thickness / numberUP ); // 4 inches per lift needs to be verfieid
  //       break;
  //     }
  //     case Units.FEET: {
  //       try {
  //         const pagesPerFoot =  ( printNodeOrderItem.book.body.height / 72 ) > 9 || ( printNodeOrderItem.book.body.width / 72 ) > 6 ? ( 4 * 12 / 11.5 ) : ( ( 12 / 9.5 ) * 6 );
  //         const pagesPerBook = Math.ceil(printNodeOrderItem.book.body.numPages / 2 ) * 2;
  //         manufacturingStep.total = printNodeOrderItem.quantity * pagesPerBook / pagesPerFoot;
  //         manufacturingStep.total = printNodeOrderItem.quantity * printNodeOrderItem.book.body.numPages;
  //       } catch ( e ) {
  //         console.log('ERROR Getting setting the number of feet in a Manufacturing Process');
  //       }
  //       break;
  //     }
  //     case Units.SHEETS: {
  //       try {
  //         const pagesPerSheet =  ( printNodeOrderItem.book.body.height / 72 ) > 9 || ( printNodeOrderItem.book.body.width / 72 ) > 6 ? 4 : 8;
  //         const pagesPerBook = Math.ceil(printNodeOrderItem.book.body.numPages / 2 ) * 2;
  //         manufacturingStep.total = printNodeOrderItem.quantity * pagesPerBook / pagesPerSheet;
  //       } catch ( e ) {
  //         console.log('ERROR Getting setting the number of sheets in a Manufacturing Process');
  //       }
  //       break;
  //     }
  //     case Units.NORMALIZED_PAGES: {
  //       try {
  //         const pagesPerSheet =  ( printNodeOrderItem.book.body.height / 72 ) > 9 || ( printNodeOrderItem.book.body.width / 72 ) > 6 ? 4 : 8;
  //         const pagesPerBook = Math.ceil(printNodeOrderItem.book.body.numPages / 2 ) * 2;
  //         manufacturingStep.total = printNodeOrderItem.quantity * pagesPerBook * 8 / pagesPerSheet; // Larger than 6in or 9in counts as two normalized pages
  //       } catch ( e ) {
  //         console.log('ERROR Getting setting the number of normalized pages in a Manufacturing Process');
  //       }
  //       break;
  //     }
  //     case Units.PAGES: {
  //       try {
  //         const pagesPerBook = Math.ceil(printNodeOrderItem.book.body.numPages / 2 ) * 2;
  //         manufacturingStep.total = printNodeOrderItem.quantity * pagesPerBook;
  //       } catch ( e ) {
  //         console.log('ERROR Getting setting the number of pages in a Manufacturing Process');
  //       }
  //       break;
  //     }
  //     case Units.NOT_SET: {
  //       manufacturingStep.total = printNodeOrderItem.quantity;
  //       break;
  //     }
  //     case Units.ORDERS: {
  //       manufacturingStep.total = 1;
  //       break;
  //     }
  //   }
  //
  //   if ( !manufacturingStep.total ) {
  //     manufacturingStep.total = 1;
  //   }
  //   manufacturingStep.durationtotal = manufacturingStep.total * this.getRate();
  //   manufacturingStep.totalcompleted = 0;
  //   manufacturingStep.percentcomplete = 0;
  //   this.manufacturingSteps.push(manufacturingStep);
  //   this.computeStatistics();
  //   // await ManufacturingSteps.add(role, manufacturingStep);
  //   // await ManufacturingTasks.save(role, this);
  // }
  //
  // async removePrintNodeOrderItem(printNodeOrderItem: PrintNodeOrderItem) {
  //   for ( const manufacturingStep of this.manufacturingSteps ) {
  //     if ( manufacturingStep.printnodeorderitem_id === printNodeOrderItem.ID ) {
  //       const index = this.manufacturingSteps.indexOf(manufacturingStep);
  //       if ( index !== -1 ) {
  //         this.manufacturingSteps.splice(index, 1);
  //       }
  //       // await ManufacturingSteps.remove(role, manufacturingStep.ID);
  //     }
  //   }
  // }
  //
  // removePrevManufacturingTask(manufacturingTask: ManufacturingTask) {
  //   const index = this.prevManufacturingTasks.indexOf(manufacturingTask);
  //   if ( index !== -1 ) {
  //     this.prevManufacturingTasks.splice(index, 1);
  //   }
  // }
  //
  // removeNextManufacturingTask(manufacturingTask: ManufacturingTask) {
  //   const index = this.nextManufacturingTasks.indexOf(manufacturingTask);
  //   if ( index !== -1 ) {
  //     this.nextManufacturingTasks.splice(index, 1);
  //     manufacturingTask.removePrevManufacturingTask(this);
  //     this.setNextField();
  //   }
  // }
  //
  // addPrevManufacturingTask(manufacturingTask: ManufacturingTask) {
  //   const index = this.prevManufacturingTasks.indexOf(manufacturingTask);
  //   if ( index === -1 ) {
  //     this.prevManufacturingTasks.push(manufacturingTask);
  //   }
  // }
  //
  // addNextManufacturingTask(manufacturingTask: ManufacturingTask) {
  //   const index = this.nextManufacturingTasks.indexOf(manufacturingTask);
  //   if ( index === -1 ) {
  //     this.nextManufacturingTasks.push(manufacturingTask);
  //     manufacturingTask.addPrevManufacturingTask(this);
  //     this.setNextField();
  //   }
  // }
  // getPrevManufacturingTasks(): ManufacturingTask[] {
  //   return this.prevManufacturingTasks;
  // }
  // getNextManufacturingTasks(): ManufacturingTask[] {
  //   return this.prevManufacturingTasks;
  // }
  // setNextField() {
  //   const newNext: number[] = [];
  //   for ( const nextManufacturingTask of this.nextManufacturingTasks ) {
  //     const x: number = nextManufacturingTask.ID;
  //     newNext.push(x);
  //   }
  //   this.next = JSON.stringify(newNext);
  // }
  //
  // getManufacturingTaskDetails (): ManufacturingTaskDetails | undefined  {
  //   if ( this.details ) {
  //     try {
  //       const d = JSON.parse(this.details);
  //       const manufacturingTaskDetails = new ManufacturingTaskDetails(d);
  //       if ( manufacturingTaskDetails ) { // TODO: Add some verification that it is a real PrinterDetails
  //         return manufacturingTaskDetails;
  //       }
  //     } catch ( e ) {
  //       console.log(`Error getting manufacturing task details for ManufacturingTask ${this.ID}`);
  //     }
  //   }
  //   return undefined;
  // }
  //
  // setManufacturingTaskDetails(manufacturingTaskDetails: ManufacturingTaskDetails) {
  //   this.details = JSON.stringify(manufacturingTaskDetails);
  // }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
