import {Component, Inject, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-facility-packages-set-filter',
  templateUrl: './facility-packages-set-filter.component.html',
  styleUrls: ['./facility-packages-set-filter.component.css']
})
export class FacilityPackagesSetFilterComponent implements OnInit {
  filterOn;
  public selectedFilter;
  public selectedFilterValue;

  public printnode_ID: number = 5;
  public inventoryManagementType: string = 'LEVEL';
  public reorderQuantity: number = 20;
  public reorderLevel: number = 10;

  public breakpoint: number; // Breakpoint observer code

  public form: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<FacilityPackagesSetFilterComponent>) {
    if ( data.filterOn ) {
      this.filterOn = data.filterOn;
    }
    if ( data.selectedFilter ) {
      this.selectedFilter = data.selectedFilter;
    }
    if ( data.selectedFilterValue ) {
      this.selectedFilterValue = data.selectedFilterValue;
    }
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      selectedFilter: [this.selectedFilter, [Validators.required]],
      selectedFilterValue: [this.selectedFilterValue, [Validators.required]],
    });

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onChange(): void {
    const returnObject = {};
    returnObject['selectedFilterValue'] = this.form.controls.selectedFilterValue.value;
    returnObject['selectedFilter'] = this.form.controls.selectedFilter.value;
    if ( this.form.controls.selectedFilter.value === 'NONE') {
      returnObject['filterOn'] = false;
    } else {
      returnObject['filterOn'] = true;
    }

    this.markAsDirty(this.form);
    this.dialogRef.close(returnObject);
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 1200 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
