import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatTable} from "@angular/material/table";
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import {PrintNodeOrderItem} from "../../../model/PrintNodeOrderItem";
import {Book} from "../../../model/Book";
import {ManufacturingSet} from "../../../model/ManufacturingSet";
import {Subject} from "rxjs";

@Component({
  selector: 'app-print-node-orders',
  templateUrl: './print-node-orders.component.html',
  styleUrls: ['./print-node-orders.component.css']
})

export class PrintNodeOrdersComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  static UpsTracking = new RegExp(/\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/i);
  static Fedex1 = new RegExp(/(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/i);
  static Fedex2 = new RegExp(/\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b/i);
  static Fedex3 = new RegExp(/^[0-9]{15}$/i);
  static USPS1 = new RegExp(/(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)/i);
  static USPS2 = new RegExp(/^E\D{1}\d{9}\D{2}$|^9\d{15,21}$/i);
  static USPS3 = new RegExp(/^91[0-9]+$/i);
  static USPS4 = new RegExp(/^[A-Za-z]{2}[0-9]+US$/i);
  static USPS5 = new RegExp( /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)|(\b\d{26}\b)| ^E\D{1}\d{9}\D{2}$|^9\d{15,21}$| ^91[0-9]+$| ^[A-Za-z]{2}[0-9]+US$/i);

  columns = [
    { columnDef: 'Order',            header: 'Order',         sort: true,  type: 'number',    filterType: 'String',          style: 'text-align: right',  property: 'order_ID',                                                                 cell: (element: any) => `${element.order_ID}`  },
    { columnDef: 'Shipment',         header: 'Shipment',               sort: true,  type: 'number',    filterType: 'String',          style: 'text-align: right',  property: 'ID',                                                                 cell: (element: any) => `${element.ID}`  },
    { columnDef: 'created',          header: 'Created',          sort: true,  type: 'date',      filterType: 'String',          style: 'text-align: center', property: 'created',                                                            cell: (element: any) => `${element.created ? element.created : new Date() }`, dateFormat: 'MM/dd/yy' },
    // { columnDef: 'tag',             header: 'Tag',              sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'tag',                                                             cell: (element: any) => `${element.tag}`  },
    // { columnDef: 'organizationName', header: 'Organization',     sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'name',                                                               cell: (element: any) => `${ element.organization ? element.organization.name : ''}`  },
    { columnDef: 'name',             header: 'Name',             sort: true,  type: 'string',    filterType: 'CompositeString', style: 'text-align: left',   properties: ['firstname', 'lastname', 'company'],                               cell: (element: any) => `${element.firstname.toUpperCase()} ${element.lastname.toUpperCase()}`,                        cellRow2: (element: any) => `${element.company}`  },
    { columnDef: 'address',          header: 'Address',          sort: true,  type: 'string',    filterType: 'CompositeString', style: 'text-align: left',   properties: ['address1', 'address2', 'state', 'city', 'postalcode', 'country'], cell: (element: any) => `${element.address1.toUpperCase()} ${element.address2 ? element.address2.toUpperCase() : ''}`, cellRow2: (element: any) => `${element.city.toUpperCase()} ${element.state.toUpperCase()} ${element.postalcode ? element.postalcode : ''} ${element.country ? element.country.toUpperCase() : ''}`  },
    // { columnDef: 'weight',           header: 'Weight',           sort: true,  type: 'number',    filterType: 'NumericRange',    style: 'text-align: right',  property: 'weight',                                                             cell: (element: any) => `${Math.round(element.weight * 10) / 10}` },
    // { columnDef: 'thickness',        header: 'Thickness',        sort: true,  type: 'number',    filterType: 'NumericRange',    style: 'text-align: right',  property: 'thickness',                                                          cell: (element: any) => `${Math.round(element.thickness * 10) / 10}` },
    // { columnDef: 'quantity',         header: 'Quantity',         sort: true,  type: 'number',    filterType: 'NumericRange',    style: 'text-align: right',  property: 'quantity',                                                           cell: (element: any) => `${element.quantity}` },
    // { columnDef: 'duplicate',        header: 'Dup',              sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'tag4',                                                               cell: (element: any) => `${element.tag4 === 'DONTSHIP' ? 'Y' : ''}`},
    // { columnDef: 'hold',             header: 'Hold',             sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'hold',                                                               cell: (element: any) => `${element.hold === 'CUSTOMER_REVIEW' ? 'Y' : ''}`},
    // { columnDef: 'canceled',         header: 'Canceled',         sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'printnode_ID',                                                       cell: (element: any) => `${element.printnode_ID === 0 ? 'Y' : ''}`},
    { columnDef: 'addressIssue',     header: 'Address',          sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'printnode_ID',                                                       cell: (element: any) => `${element.printnode_ID === 10000 ? 'Y' : ''}`},
    { columnDef: 'carrier',          header: 'Carrier',          sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'carrier',                                                            cell: (element: any) => `${element.servicelevel}` },
    { columnDef: 'shippingStatus',   header: 'Shipping Status',  sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'shippingStatus',                                                     cell: (element: any) => `${element.tag4 === 'DONTSHIP' ? 'DUPLICATE' : element.printnode_ID === 0 ? 'CANCELED' : element.hold === 'CUSTOMER_REVIEW' ? 'HOLD' : element.shippingStatus }`},
    // { columnDef: 'trackingNumbers', header: 'Tracking Numbers', sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'trackingNumbers',                                                    cell: (element: any) => `${element.trackingNumbers}`},
    { columnDef: 'trackingNumbers',  header: 'Tracking Numbers', sort: true,  type: 'link',      filterType: 'String',          style: 'text-align: left',   property: 'trackingNumbers',                                                    cell: (element: any) => `${element.shippingStatus === 'NONE' ? '' : element.trackingNumbers}`,   link: (element: any) => {
        const trackingNumbers = element.trackingNumbers; // .split(' ').join('_');
        if ( element.shippingStatus === 'NONE') {
          return '';
        }
        return this.getTrackingLink(trackingNumbers.split(/_(.+)/)[0]);
        if (trackingNumbers.startsWith('94')) {
          return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + trackingNumbers.split(/_(.+)/)[0]; // trackingNumbers.split(',').join('%20');
        } else if (trackingNumbers.startsWith('1Z')) {
          return 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + trackingNumbers.split(/_(.+)/)[0]; // .split(',').join('%20');
        } else if (element.carrier === 'FEDEX') {
          return 'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=' + trackingNumbers.split(/_(.+)/)[0];
        } else {
          return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + trackingNumbers.split(/_(.+)/)[0]; // trackingNumbers.split(',').join('%20');
        }
      }
    }
//     { property: 'orgName',         columnDef: 'orgName',         header: 'Organization',     sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                             cell: (element: any) => `${element.organization.Name }` }
  ];

//   columns = [
//      // { property: 'dueDate',         columnDef: 'dueDate',         header: 'Due Date',         sort: true,  type: 'date',      filterType: 'NumericRange', style: 'display: flex; justify-content: space-around; text-align: right', cell: (element: any) => `${ element.dueDate ? element.dueDate : new Date() }`, dateFormat: 'MM/dd/yy' },
//      { property: 'ID',              columnDef: 'ID',              header: 'ID',               sort: true,  type: 'number',    filterType: 'String', style: 'display: flex; justify-content: space-around; text-align: right', cell: (element: any) => `${element.ID}` },
//      { property: 'created',         columnDef: 'created',         header: 'Created',          sort: true,  type: 'date',      filterType: 'String', style: 'display: flex; justify-content: space-around; text-align: right', cell: (element: any) => `${element.created ? element.created : new Date() }`, dateFormat: 'MM/dd/yy' },
//      { property: 'tag',             columnDef: 'tag',             header: 'Tag',              sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.tag}`  },
//      { property: 'name',            columnDef: 'name',            header: 'Name',             sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.firstname.toUpperCase()} ${element.lastname.toUpperCase()}`  },
//      { property: 'company',         columnDef: 'company',         header: 'Company',          sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.company}`  },
//      { property: 'address1',        columnDef: 'address1',        header: 'Address1',         sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.address1.toUpperCase()}` },
//      { property: 'address2',        columnDef: 'address2',        header: 'Address2',         sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.address2 ? element.address2.toUpperCase() : ''}` },
//      { property: 'city',            columnDef: 'city',            header: 'City',             sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.city.toUpperCase()}` },
//      { property: 'state',           columnDef: 'state',           header: 'State',            sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.state.toUpperCase()}` },
//      { property: 'zip',             columnDef: 'zip',             header: 'Zip',              sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.postalCode ? element.postalCode : ''}` },
//      { property: 'country',         columnDef: 'country',         header: 'Country',          sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.country ? element.country.toUpperCase() : ''}` },
//      { property: 'weight',          columnDef: 'weight',          header: 'Weight',           sort: true,  type: 'number',    filterType: 'NumericRange', style: 'text-align: right',                                               cell: (element: any) => `${Math.round(element.weight * 10) / 10}` },
//      // { property: 'status',       columnDef: 'status',          header: 'Status',           sort: true,  type: 'string',    filterType: 'DataEnum',     style: 'text-align: right',                                               cell: (element: any) => `${element.status}`, values: { USPS: 'USPS', UPS: 'UPS'} },
//      { property: 'carrier',         columnDef: 'carrier',         header: 'Carrier',          sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.carrier}`, values: { USPS: 'USPS', UPS: 'UPS'} },
//      { property: 'servicelevel',    columnDef: 'serviceLevel',    header: 'Service Level',    sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.servicelevel}` },
//      { property: 'shippingStatus',  columnDef: 'shippingStatus',  header: 'Shipping Status',  sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.shippingStatus}` },
//      { property: 'trackingNumbers', columnDef: 'trackingNumbers', header: 'Tracking Numbers', sort: true,  type: 'string',    filterType: 'String',       style: 'text-align: right',                                               cell: (element: any) => `${element.getTrackingNumbers()}` }
// //   { property: 'phoneNumber',       columnDef: 'phoneNumber',      header: 'Phone Number',      sort: true,  type: 'string',     filterType: 'String',     style: 'text-align: right',  cell: (element: any) => `${element.phoneNumber}` },
// //   { property: 'organization.ID',   columnDef: 'organizationId',   header: 'Organization Id',   sort: true,  type: 'number',     filterType: 'NumericRange',      style: 'text-align: right',  cell: (element: any) => `${element.organization.ID}` },
// //      { property: 'organization.name', columnDef: 'organizationName', header: 'Organization Name', sort: false, type: 'string',     filterType: 'String',        style: 'text-align: right',  cell: (element: any) => `${element.organization.Name }` },
// //   { property: 'printNodePrintNodeOrder.ID', columnDef: 'packButton',   header: 'Pack',         sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.printNodePrintNodeOrder.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'},
//   ];

  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<PrintNodeOrder>();
  public renderedData: PrintNodeOrder[];
  tableFilterManager: TableFilterManager<PrintNodeOrder> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  role: string;

  constructor(public router: Router, public startupService: StartupService, private printNodeOrderService: PrintNodeOrderService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.addRoleChangeListener(this);
    // this.startupService.touchLastPrintNodeOrderActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the printNodeOrder is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      // if ( d && d.length > 0 ) {
      //   this.renderedData = d;
      // }
      this.renderedData = this.dataSource.data;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: PrintNodeOrder, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
    // this.dataSource.paginator = this.paginator;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  // onSelect(printNodePrintNodeOrderItem: PrintNodePrintNodeOrderItem): void {
  //   this.selectedPrintNodePrintNodeOrderItem = printNodePrintNodeOrderItem;
  //   if ( this.selectedPrintNodePrintNodeOrderItem ) {
  //     this.selectedPrintNodePrintNodeOrder = this.selectedPrintNodePrintNodeOrderItem.printNodePrintNodeOrder;
  //   }
  // }

  getData(): void {
    // this.dataSource.data = [];
    // this.role = this.printNodeOrderService.getRole('');
    this.printNodeOrderService.getItems()
      .subscribe(printNodeOrders => {
        // for (const printNodeOrder of printNodeOrders) {
        //   console.log("In PrintNodeOrderComponent getting data from PrintNodeOrderService: " + JSON.stringify(printNodeOrder));
        //   this.dataSource.data.push(printNodeOrder);
        // }
        this.dataSource.data = printNodeOrders;
        this.dataSource.filter = '' + Math.random();
        // this.table.renderRows();
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  refresh () {
    this.printNodeOrderService.reset();
    this.getData();
  }

  private getTrackingLink(trackingNumber: string) {
    if ( PrintNodeOrdersComponent.UpsTracking.test(trackingNumber)) {
      return `UPS: <a href="https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${encodeURIComponent(trackingNumber)}">${trackingNumber}</a>`;
    } else if ( PrintNodeOrdersComponent.Fedex1.test(trackingNumber) || PrintNodeOrdersComponent.Fedex2.test(trackingNumber) || PrintNodeOrdersComponent.Fedex3.test(trackingNumber)) {
      return `FEDEX: <a href="https://www.fedex.com/apps/fedextrack/?tracknumbers=${encodeURIComponent(trackingNumber)}">${trackingNumber}</a>`;
    } else if (PrintNodeOrdersComponent.USPS1.test(trackingNumber) || PrintNodeOrdersComponent.USPS2.test(trackingNumber) || PrintNodeOrdersComponent.USPS3.test(trackingNumber) || PrintNodeOrdersComponent.USPS4.test(trackingNumber) || PrintNodeOrdersComponent.USPS5.test(trackingNumber)) {
      return `USPS: <a href="https://tools.usps.com/go/TrackConfirmAction?tLabels=${encodeURIComponent(trackingNumber)}">${trackingNumber}</a>`;
    }
    return trackingNumber;
  }

}
