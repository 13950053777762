import {Component, Input, OnInit} from '@angular/core';
import {Project} from "../../../model/editor/Project";
import {FileUploader} from "../../file-upload/file-uploader.class";
import {environment} from "../../../environments/environment";
import {Chapter} from "../../../model/editor/Chapter";
import {EditorProjectService} from "../../service-data/editor/editor-project.service";
import {StartupService} from "../../service-ui/startup.service";
import {ImageService} from "../../service-data/image.service";

@Component({
  selector: 'app-upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.css']
})
export class UploadImagesComponent implements OnInit {
  @Input() library: string;

  public uploader: FileUploader = new FileUploader( { } );

  public hasBaseDropZoneOver: boolean = false;


  constructor(public startupService: StartupService, public imageService: ImageService) {
  }

  ngOnInit() {
    this.uploader.setOptions({isHTML5: true, url: environment.apiBaseURL, disableMultipart: true, method: 'PUT', allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg'], onSuccessFunction: this.imageAdded.bind(this), urlEndPointType: 'image', library: this.library, imageService: this.imageService, headers: [{name: 'x-amz-acl', value: 'public-read'}, {name: 'accesstoken', value: this.startupService.getJWTToken()}]});
  }

  imageAdded(downloadPath: string) {
    console.log("Image Added " + downloadPath);
    // const chapter = new Chapter( undefined );
    // chapter.ID = '---';
    // chapter.source = 'Audio';
    // chapter.type = 'standard';
    // this.project.interior.chapters.push(chapter);
  }

  public fileOverBase( e: any ): void {
    this.hasBaseDropZoneOver = e;
  }
}

