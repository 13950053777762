
import { ReplaySubject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ProjectNavbarService {

  private showCoverThumbnailSource = new ReplaySubject<string>(1);
  private showProjectStatusSource = new BehaviorSubject<string>('on');
  showCoverThumbnail = 'on'; // this.showOriginalSource.asObservable();
  showProjectStatus = 'on'; // this.showConvertedSource.asObservable();
  filterState = 'filterOn';

  toggleFilterState() {
    if ( this.filterState === 'filterOn') {
      this.filterState = 'filterOff';
    } else {
      this.filterState = 'filterOn';
    }


  }

  constructor() {
  }

  // setShowOriginalState( showHide: string ) {
  //   this.showOriginalSource.next( showHide );
  // }
  // setShowConvertedState( showHide: string ) {
  //   this.showConvertedSource.next( showHide );
  // }
  // setShowJustTopState( showHide: string ) {
  //   this.showJustTopSource.next( showHide );
  // }
}
