<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Edit Facility Inventory Product Note">Edit Facility Inventory Product Note</h2>
    <h3 class="primary">{{facilityInventoryProduct.title}}</h3>
  </div>
  <div>
    <form [formGroup]="formGroup" (change)="formChanged()">
      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-select  placeholder="Reason" i18n-placeholder="@@Reason" formControlName="reason" required>
                <mat-option value="Problem" i18n='@@Problem'>Problem</mat-option>
                <mat-option value="Question" i18n='@@Question' selected='selected'>Question</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Note" placeholder="Note" id="note" required type="text" formControlName="note">
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="formGroup.invalid" (click)="onSave()" i18n="@@Save Note Changes">Save Note Changes</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
