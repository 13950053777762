import { Injectable } from '@angular/core';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {Product} from "../../model/Product";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {NotificationsService} from "./notifications.service";

@Injectable()
export class ProductService extends AbstractLiveDataService<Product> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setProductService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): Product {
    return new Product(args);
  }

  getLoggingObjectTypeName (): string {
    return "Product";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "product";
  }

  getLinkData(): any[] {
    return [
      { action: 'Link', objectIdPropertyName: 'book_ID', serviceName: 'bookService', arrayProperty: 'products', objectProperty: 'book'},
      { action: 'AddUpdateArray', serviceName: 'facilityInventoryProductService', arrayProperty: 'facilityInventoryProducts'},
    ];
  }
}
