import {ProjectProperties} from "../ProjectProperties";
import {Product} from "../Product";

export abstract class Item {
  public quantity: number;
  cost: number;
  tagId: number;
  useInventory: boolean = false;
  inventoryFacilityId: number = 0;

  constructor() {
    this.quantity = 0;
    this.cost = 0;
  }

  quantityChanged() {
    this.computeCost();
  }

  getCost(): number {
    if ( this.quantity === 0 ) {
      this.cost = 0;
    } else {
      this.computeCost();
    }
    return this.cost;
  }
  getQuantity(): number {
    return this.quantity;
  }

  computeCost() {
    if ( this.quantity === 0 ) {
      this.cost = 0;
    } else {
      switch ( this.getInteriorType() ) {
        case 'COLOR': {
          switch ( this.getBindingType() ) {
            case 'PERFECT': {
              this.cost = this.quantity * 2;
              break;
            }
            case 'COIL': {
              this.cost = this.quantity * 2.5;
              break;
            }
            default: {
              this.cost = 100000;
              break;
            }
          }
          break;
        }
        case 'GRAY': {
          switch ( this.getBindingType() ) {
            case 'PERFECT': {
              this.cost = this.quantity * 1;
              break;
            }
            case 'COIL': {
              this.cost = this.quantity * 1.5;
              break;
            }
            default: {
              this.cost = 100000;
              break;
            }
          }
          break;
        }
        default: {
          this.cost = 100000;
          break;
        }
      }
      if ( this.cost === 0 ) {
        this.cost = 100000;
      }
    }
  }

  abstract getId(): number;
  abstract getBase(): any;
  abstract getCoverImage(): string;
  abstract getTitle(): string;
  abstract getPages(): number;
  abstract getInteriorType(): string;
  abstract getBindingType(): string;
  abstract getLaminateType(): string;
  abstract getWidth(): number;
  abstract getHeight(): number;
  abstract getWeight(): number;
  abstract getThickness(): number;
  abstract getCaseQuantity(): number;
  abstract getPaperType(): number;
  abstract getLastUpdate(): Date;
  abstract getAvailableInventory(): any[];
}

