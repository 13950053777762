import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Draft} from "../../../model/Draft";
import {ResourceService} from "../../service-data/resource.service";

@Component({
  selector: 'app-spread',
  templateUrl: './spread.component.html',
  styleUrls: ['./spread.component.css']
})

export class SpreadComponent implements OnInit, OnChanges {
  @ViewChild('leftImage', { static: true }) leftImage;
  @ViewChild('rightImage', { static: true }) rightImage;
  @Input() draft: Draft;
  @Input() resourceId: number;
  @Input() leftVerticalShift: number;
  @Input() leftHorizontalShift: number;
  @Input() rightVerticalShift: number;
  @Input() rightHorizontalShift: number;
  @Input() scale: number;
  @Input() leftPageNumber: number;
  @Input() rightPageNumber: number;
  @Input() dpi: number;
  @Input() orgId: number;
  @Input() bodyOrCover: string;
  @Input() bindingType: string;

  displayScale = 1.0;

  Math = Math;

  constructor (private resourceService: ResourceService) {
  }

  ngOnInit() {
    this.refreshImagePositions();
  }

  ngOnChanges() {
    this.refreshImagePositions();
  }

  refreshImagePositionDetails(cachedImage: any, image: any, horizontalShift: number, verticalShift: number) {
    // image.nativeElement.style.display = "block";
    image.nativeElement.src = cachedImage.src;

    // let naturalWidth = cachedImage.naturalWidth;
    // if ( !naturalWidth ) {
    //   naturalWidth = cachedImage.width;
    // }
    //
    // let naturalHeight = cachedImage.naturalHeight;
    // if ( !naturalHeight ) {
    //   naturalHeight = cachedImage.height;
    // }
    //
    // // var clipWidth = ((bookBody.pageSize.width)*2+(paperWeights[paperWeights.usedWeight].caliper*bookBody.pages.numberOfPages/2*dpi) + this.trimWobble)*bookBody.displayScale.value+2*this.bleed;
    // // var clipHeight = ((bookBody.pageSize.height + this.trimWobble)*bookBody.displayScale.value)+2*this.bleed;
    // const clipWidth = this.image.nativeElement.parentNode.clientWidth - 2; // - 2 for border thickness
    // const clipHeight = this.image.nativeElement.parentNode.clientHeight - 2;
    //
    // const pageScreenWidth = ( naturalWidth * this.displayScale * this.scale);
    // if ( pageScreenWidth ) {
    //   image.nativeElement.style.width = pageScreenWidth + 'px';
    //   const pageScreenHeight = ( naturalHeight * this.displayScale * this.scale);
    //
    //   image.nativeElement.style.height = pageScreenHeight + 'px';
    //
      image.nativeElement.style.left = horizontalShift + 'px';
      image.nativeElement.style.top = verticalShift + 'px';
    // image.nativeElement.style.left = ( ( clipWidth - pageScreenWidth ) / 2 + this.horizontalShift * this.displayScale ) + 'px';
    // image.nativeElement.style.top = ( ( clipHeight - pageScreenHeight ) / 2 - this.verticalShift * this.displayScale ) + 'px';
    //   image.nativeElement.style.width = '436px';
    //   image.nativeElement.style.height = '297px';
    //   image.nativeElement.style.width = '100%';
    //   image.nativeElement.style.height = '100%';
    //   image.nativeElement.style.left = '0px';
    //   image.nativeElement.style.top = '0px';
    //
    // }
  }

  refreshImagePositions() {
    this.refreshImagePosition(this.leftPageNumber, this.leftImage, this.leftHorizontalShift, this.leftVerticalShift);
    this.refreshImagePosition(this.rightPageNumber, this.rightImage, this.rightHorizontalShift, this.rightVerticalShift);
  }
  refreshImagePosition(pageNumber: number, image: any, horizontalShift: number, verticalShift: number) {
    if ( !this.draft || !this.draft.draftBody ) {
      return;
    }
    this.resourceId = this.draft.draftBody.resource_ID;
    // if ( !this.resourceId ) {
    //   this.leftImage.nativeElement.src = '';
    //   this.rightImage.nativeElement.src = '';
    //   return;
    // }

    const imageId = 'resource-' + this.resourceId + '-' + pageNumber + '-' + this.dpi;

    if ( pageNumber === 0 || pageNumber > this.draft.draftBody.numPages ) {
      image.nativeElement.src = '';
      // image.nativeElement.style.background = 'url("images/noPageTile.png") repeat';
      image.nativeElement.style.display = 'none';
      image.nativeElement.parentElement.parentElement.style.background = 'url("assets/images/noPageTile.png") repeat';
    } else {
      let cachedImage: any = document.getElementById(imageId);
      if ( !cachedImage ) {
        const hiddenImagesElement: any = document.getElementById('hiddenImages');
        if ( hiddenImagesElement ) {
          const imageElement = document.createElement("img");
          console.log(`Getting image for resourceId: ${this.resourceId} 'COLOR' 72 ${pageNumber}`);
          this.resourceService.getImageURL(this.resourceId, this.draft.interiorType, 72, pageNumber).subscribe(imageURL => {
            if ( imageURL ) {
              console.log(`GOT     image for resourceId: ${this.resourceId} 'COLOR' 72  ${pageNumber} ${imageURL}`);
              imageElement.src = imageURL;
              imageElement.id = imageId;
              hiddenImagesElement.appendChild(imageElement);
              cachedImage = imageElement;
              if (cachedImage.complete ) {
                this.refreshImagePositionDetails(cachedImage, image, horizontalShift, verticalShift);
              } else {
                cachedImage.addEventListener('load', _ => {
                  this.refreshImagePositionDetails(cachedImage, image, horizontalShift, verticalShift);
                });
                cachedImage.addEventListener('error', _ => {
                  console.log(`Error loading image ${imageId}`);
                });
              }
            } else {
              console.log(`ERROR GETTING image for resourceId: ${this.resourceId} 'COLOR' 72  ${pageNumber} ${imageURL}`);
            }
          });
        }
      }
      if ( cachedImage ) {
        if (cachedImage.complete ) {
          this.refreshImagePositionDetails(cachedImage, image, horizontalShift, verticalShift);
        } else {
          cachedImage.addEventListener('load', _ => {
            this.refreshImagePositionDetails(cachedImage, image, horizontalShift, verticalShift);
          });
          cachedImage.addEventListener('error', _ => {
            console.log(`Error loading image ${imageId}`);
          });
        }
      }
    }
  }
}


