import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../model/User";
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PickSheetService} from "../../../service-data/pick-sheet.service";
import {FacilityService} from "../../../service-data/facility.service";
import {StartupService} from "../../../service-ui/startup.service";
import {PrintNodeOrderService} from "../../../service-data/print-node-order.service";

@Component({
  selector: 'app-facility-print-node-order',
  templateUrl: './facility-print-node-order.component.html',
  styleUrls: ['./facility-print-node-order.component.css']
})
export class FacilityPrintNodeOrderComponent implements OnInit {
  @Input() printNodeOrder: PrintNodeOrder;
  printNodeOrderWithItems: PrintNodeOrder;

  constructor(private startupService: StartupService, private pickSheetService: PickSheetService, private facilityService: FacilityService, private printNodeOrderService: PrintNodeOrderService) { }

  ngOnInit() {
    if ( this.printNodeOrder.printNodeOrderItems && this.printNodeOrder.printNodeOrderItems.length > 0 ) {
      this.printNodeOrderWithItems = this.printNodeOrder;
    } else {
      this.printNodeOrderService.getItem(this.printNodeOrder.ID).subscribe(pno => {
        this.printNodeOrderWithItems = pno;
      });
    }
  }

  printPickSheet ( printNodeOrder: PrintNodeOrder, printer: string) {
    if ( this.startupService.selectedRole === 'facility') {
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe( facility => {
        this.pickSheetService.createPickSheet(facility, printNodeOrder, printer).subscribe( result => {
          console.log(`createdPickSheet for printNodeOrder: ${printNodeOrder.ID}`);
        });
      });
    }
  }

}
