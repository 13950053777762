import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import {NotificationsService} from "./notifications.service";
import {FacilityInvoice} from "../../model/FacilityInvoice";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {Observable} from "rxjs";
import {of} from "rxjs";
import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class FacilityInvoiceService extends AbstractLiveDataService<FacilityInvoice> {

  private startupServicea: StartupService;

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServiceaa: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServiceaa, notificationsService);
    this.startupServicea = startupServiceaa;
    this.notificationsService.setFacilityInvoiceService(this);
    notificationsService.startNotification();
  }

  makeObjectInstance(args: any): FacilityInvoice {
    const object = new FacilityInvoice(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "FacilityInvoice";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "facilityinvoice";
  }

  getLinkData(): any[] {
    return [
      { action: 'Link', objectIdPropertyName: 'printNode_ID', serviceName: 'facilityService', arrayProperty: 'facilityInvoices', objectProperty: 'facility'},
    ];
  }

  getInvoice (facilityInvoice: FacilityInvoice, language: String, subFolder: String): Observable<string> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/invoice/${facilityInvoice.ID}/${language}/${subFolder}`;
    return this.http.get(url, this.startupService.getHttpOptions())
      .pipe(
        map(invoice => {
          return invoice;
        }),
        catchError(this.handleErrorT<any>('getInvoice'))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleErrorT<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
