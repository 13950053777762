import { Injectable } from '@angular/core';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {NotificationsService} from "./notifications.service";
import {Address} from "../../model/Address";

@Injectable()
export class AddressService extends AbstractLiveDataService<Address> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setAddressService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): Address {
    return new Address(args);
  }

  getLoggingObjectTypeName (): string {
    return "Address";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "address";
  }

  getLinkData(): any[] {
    return [];
  }
}
