import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CoverThemeItemSetting} from "../../../../../model/editor/CoverThemeItemSetting";
import {StartupService} from "../../../../service-ui/startup.service";
import {InteriorThemeEditorItem} from "../../../../../model/editor/interior-theme/InteriorThemeEditorItem";
import {ThemeService} from "../../../../service-data/theme.service";

@Component({
  selector: 'app-interior-theme-item-editor',
  templateUrl: './interior-theme-item-editor.component.html',
  styleUrls: ['./interior-theme-item-editor.component.css']
})
export class InteriorThemeItemEditorComponent implements OnInit, OnChanges {
  @Input() interiorThemeEditorItem: InteriorThemeEditorItem;
  @Input() isSelectedElement: boolean;
  @Input() isSelected: boolean;
  @Input() showHeaders: boolean;
  @Input() onlyShowSelectedElements: boolean;
  @Input() highlightTextImpactedByStyle: boolean;
  @Input() selectedStyleName: string;

  fontExpanded = true;
  marginExpanded = false;
  paddingExpanded = false;
  otherExpanded = false;

  constructor(public startupService: StartupService, public themeService: ThemeService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.isSelected && changes.isSelected.currentValue !== undefined) {
      if ( changes.isSelected.previousValue === undefined) {
        if ( changes.isSelected.currentValue && this.highlightTextImpactedByStyle ) {
          this.interiorThemeEditorItem.setValue('background', 'yellow');
        } else {
          this.interiorThemeEditorItem.removeProperty('background');
        }
      } else if (changes.isSelected.currentValue !== changes.isSelected.previousValue ) {
        if ( changes.isSelected.currentValue  && this.highlightTextImpactedByStyle ) {
          this.interiorThemeEditorItem.setValue('background', 'yellow');
        } else {
          this.interiorThemeEditorItem.removeProperty('background');
        }
      }
    }
    if ( changes.highlightTextImpactedByStyle && changes.highlightTextImpactedByStyle.currentValue !== undefined) {
      if ( changes.highlightTextImpactedByStyle.previousValue === undefined) {
        if ( changes.highlightTextImpactedByStyle.currentValue && this.isSelected ) {
          this.interiorThemeEditorItem.setValue('background', 'yellow');
        } else {
          this.interiorThemeEditorItem.removeProperty('background');
        }
      } else if (changes.highlightTextImpactedByStyle.currentValue !== changes.highlightTextImpactedByStyle.previousValue ) {
        if ( changes.highlightTextImpactedByStyle.currentValue  && this.isSelected ) {
          this.interiorThemeEditorItem.setValue('background', 'yellow');
        } else {
          this.interiorThemeEditorItem.removeProperty('background');
        }
      }
    }
  }

  getSelectedClass(): string {
    if ( this.isSelected && this.isSelectedElement ) {
      return 'selected elementSelected';
    } else if ( this.isSelectedElement ) {
      return 'elementSelected';
    } else if ( this.isSelected ) {
      return 'selected';
    }
  }
  // isFontTheSame(fontFamily1: string, fontFamily2: string): boolean {
  //   const orderId = fontFamily2.indexOf(fontFamily1);
  //   if ( orderId !== -1 ) {
  //     return true;
  //   }
  //   return false;
  // }
  // getInteriorThemeEditorItemFontFamily(): string {
  //   const fontFamily = this.interiorThemeEditorItem.getValue('font-family');
  //   const firstChar = fontFamily.substr(0,1 );
  //   if ( firstChar === '"' || firstChar === "'") {
  //     return fontFamily.substring(1, fontFamily.length - 1 );
  //   }
  //   return fontFamily;
  // }
  isFontFamilyNameTheSame(fontFamilyName1: string, fontFamilyName2: string): boolean {
    if ( fontFamilyName1 === fontFamilyName2 ) {
      return true;
    }
    if ( fontFamilyName1.length === fontFamilyName2.length - 2 && fontFamilyName1 === fontFamilyName2.substr(1, fontFamilyName2.length - 2 )) {
      return true;
    }
    if ( fontFamilyName2.length === fontFamilyName1.length - 2 && fontFamilyName2 === fontFamilyName1.substr(1, fontFamilyName1.length - 2 )) {
      return true;
    }
    return false;
  }

  getLetterSpacing(interiorThemeEditorItem: InteriorThemeEditorItem): number {
    const letterSpacing = interiorThemeEditorItem.getValue('letter-spacing');
    const number = Number(letterSpacing.slice(0, -2));
    if ( letterSpacing.endsWith('em')) {
      const fontSize = interiorThemeEditorItem.getValue('font-size');
      if ( fontSize && !fontSize.endsWith('inherit')) {
        return Number(fontSize.slice(0, -2)) * 1.33333 * number;
      } else {
        return 12 * 1.33333 * number;
      }

    } else {
      return number;
    }

  }
}



