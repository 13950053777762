import {Component, Input, OnInit} from '@angular/core';
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PrintNodeOrderItem} from "../../../../model/PrintNodeOrderItem";
import {ManufacturingSet} from "../../../../model/ManufacturingSet";
import {ManufacturingTask} from "../../../../model/ManufacturingTask";
import {ManufacturingTaskService} from "../../../service-data/manufacturing-task.service";
import {ManufacturingSetsComponent} from "../../manufacturing-sets/manufacturing-sets.component";
import {StartupService} from "../../../service-ui/startup.service";
// const ManufacturingSetsComponent = require("../manufacturing-sets.component").ManufacturingSetsComponent;

@Component({
  selector: 'app-print-node-order-item-in-manufacturing-task',
  templateUrl: './print-node-order-item-in-manufacturing-task.component.html',
  styleUrls: ['./print-node-order-item-in-manufacturing-task.component.css']
})
export class PrintNodeOrderItemInManufacturingTaskComponent implements OnInit {
  @Input() printNodeOrderItem: PrintNodeOrderItem;
  @Input() manufacturingSet: ManufacturingSet;
  properties = {};
  quantity: number;
  thickness: number;
  createdDate: string;
  pages: number;
  serviceProvider: number;
  oneDigitZip: string;

  units = '';
  types = ManufacturingSetsComponent.types;
  colors = ManufacturingSetsComponent.colors;
  icons = ManufacturingSetsComponent.icons;

  constructor(public startupService: StartupService, private manufacturingTaskService: ManufacturingTaskService) { }

  ngOnInit() {
    this.setProperties();
  }

  setProperties() {
    this.properties['binding'] = this.printNodeOrderItem.book.bindingType;

    const width = this.printNodeOrderItem.book.body.width;
    const height = this.printNodeOrderItem.book.body.height;
    this.properties['trimSize'] = '';
    if ( width / 72 === 5.5 && height / 72 === 8.5 ) {
      this.properties['trimSize'] = '_5.5X8.5';
    } else {
      if ( width / 72 === 6.0 && height / 72 === 9.0 ) {
        this.properties['trimSize'] = '_6.0X9.0';
      } else {
        if ( width / 72 === 8.5 && height / 72 === 11 ) {
          this.properties['trimSize'] = '_8.5X11.0';
        } else {
          this.properties['trimSize'] = 'Other';
        }
      }
    }
    this.properties['impositionLayout'] = width / 72 > 6 || height / 72 > 9 ? '_2UP' : '_4UP';
    this.properties['interior'] = this.printNodeOrderItem.book.interiorType;

    this.quantity = this.printNodeOrderItem.quantity;
    this.thickness = this.printNodeOrderItem.book.body.spine;
    this.pages = this.printNodeOrderItem.book.body.numPages;

    this.properties['paperType'] = String(this.printNodeOrderItem.book.body.paperType_ID);
    this.properties['lamination'] = this.printNodeOrderItem.book.cover.laminateType;

    this.createdDate = '';
    this.serviceProvider = 0;
    this.oneDigitZip = '0';
    // try {
    //   this.createdDate = new Date(this.printNodeOrderItem.printNodeOrder.created).toISOString().split('T')[0];
    // } catch (e) {
    //   console.log(`Problem setting createdDate ${this.printNodeOrderItem.printNodeOrder.created}`);
    // }

    let feetOrSheets = width / 72 > 6 || height / 72 > 9 ? this.quantity * this.pages / 4 : this.quantity * this.pages / 8;
    // Get a criteria to determine that it is comming off of a roll to make it feet
    if ( false ) {
      feetOrSheets = width / 72 > 6 || height / 72 > 9 ? this.quantity * this.pages / 4 : ( this.quantity * this.pages / 6 ) * 10 / 12;
      this.properties['impositionLayout'] =  width / 72 > 6 || height / 72 > 9 ? '_2UP' : '_3UP';
    }

    this.properties['quantityGroup'] = '_001-019';
    if ( this.quantity < 20 ) {
      this.properties['quantityGroup'] = '_001-019';
    } else {
      if ( this.quantity < 50 ) {
        this.properties['quantityGroup'] = '_020-049';
      } else {
        if ( this.quantity < 100 ) {
          this.properties['quantityGroup'] = '_050-099';
        } else {
          if ( this.quantity < 250 ) {
            this.properties['quantityGroup'] = '_100-249';
          } else {
            if ( this.quantity < 500 ) {
              this.properties['quantityGroup'] = '_250-499';
            } else {
              this.properties['quantityGroup'] = '_500+';
            }
          }
        }
      }
    }
  }

  getManufacturingTask(useType: string): ManufacturingTask {
    if ( this.manufacturingSet && this.manufacturingSet.manufacturingTasks ) {
      for ( const manufacturingTask of this.manufacturingSet.manufacturingTasks ) {
        if ( manufacturingTask.useType === useType) {
          return manufacturingTask;
        }
      }
    }
    return undefined;
  }

  reprint (useType: string) {
    const manufacturingTask = this.getManufacturingTask(useType);
    this.manufacturingTaskService.reprint(manufacturingTask, this.printNodeOrderItem, 'lookup').subscribe( changedManufacturingTask => {
      console.log(`Response for ManufacturingSet and Book : ${manufacturingTask.ID} ${this.printNodeOrderItem.book_ID}`);
    });
  }


}
