
import { MatStepper } from '@angular/material/stepper';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {StartupService} from "../../../service-ui/startup.service";
import {NavbarService} from "../../../service-ui/navbar.service";
import {EditorProjectService} from "../../../service-data/editor/editor-project.service";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoggedInCallback} from "../../../service-auth/cognito.service";
import {Column} from "./Column";
import {FileUploader} from "../../../file-upload/file-uploader.class";
import {environment} from "../../../../environments/environment";
import {ProductCode} from "./ProductCode";
import {SimpleOrder} from "./SimpleOrder";
import {SimpleItem} from "./SimpleItem";
import {ProductService} from "../../../service-data/product.service";
import {Product} from "../../../../model/Product";
import {ValidationAddress, AddressValidationResult, AddressValidationService} from "../../../service-data/address-validation.service";
import {Order} from "../../../../model/Order";
import {OrderService} from "../../../service-data/order.service";
import {OrderDeliverTo} from "../../../../model/OrderDeliverTo";
import {OrderDeliverToService} from "../../../service-data/order-deliver-to.service";
import {OrderItem} from "../../../../model/OrderItem";
import {CsvImportSettingsService} from "../../../service-data/csv-import-settings.service";
import {CsvImportSettings} from "../../../../model/CsvImportSettings";
import {LanguagePipe} from "../../../pipe/language.pipe";
import {PrintNodeOrderItem} from "../../../../model/PrintNodeOrderItem";
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../../service-data/print-node-order.service";


const papa = require('papaparse');

@Component({
  selector: 'app-csv-page',
  templateUrl: './csv-page.component.html',
  styleUrls: ['./csv-page.component.css']
})
export class CSVPageComponent implements OnDestroy, OnInit, LoggedInCallback {
  @ViewChild('stepper', { static: true }) private stepper: MatStepper;
    public uploader: FileUploader = new FileUploader( { } );
  private csvFile: File;
  public breakpoint: number; // Breakpoint observer code

  productIdToBookIdMap = {};
  public hasBaseDropZoneOver: boolean = false;

  renameImportSettings: boolean = false;

  addressStillToValidate: number = 0;
  orderDeliverTosSaving: number = 0;
  printNodeOrdersSaving: number = 0;
  orderDeliverTosRetrying: number = 0;
  printNodeOrdersRetrying: number = 0;


  data: Array<any> = [];
  orders: Array<SimpleOrder> = [];
  columns: Column[] = [];
  columnMap = {};
  csvProductCodes = {};

  products: Product[];
  csvImportSettingss: CsvImportSettings[];
  selectedCsvImportSettings: CsvImportSettings = undefined;


  uploadFileVerified: boolean = false;
  orderStrategyVerified: boolean = false;
  headerRowVerified: boolean = false;
  columnMatchesVerified: boolean = false;
  productMatchesVerified: boolean = false;
  repairProblemsVerified: boolean = false;

  uploadFileMessages: string[] = [];
  uploadFileErrorMessages: string[] = [];
  orderStrategyErrorMessages: string[] = [];
  headerRowErrorMessages: string[] = [];
  matchColumnsErrorMessages: string[] = [];
  matchProductsErrorMessages: string[] = [];
  repairProblemsErrorMessages: string[] = [];

  csvFileHasAHeaderRow: boolean = false;
  placingOrderMessage: string = '';
  placingOrderStatus: string = '';
  makingOrder: boolean = false;

  // Default Values
  alwaysUseDefaultCarrierAndServiceLevel: boolean = true;
  alwaysUseDefaultCountry: boolean = true;

  csvImportSettings: CsvImportSettings;
  defaultCurrency: string = 'USD';
  defaultCountry: string = 'USA';
  defaultCarrierService: string = 'USPS_PRIORITY_FLAT';
  mappingFromHeaderRow: boolean = true;
  defaultNumberOfProductsPerRow: number = 1;
  // const test = false;

  // Data Objects created
  public createdSummaryOrder: Order;




  // STEPPER VARIABLES
  isEditable = true;
  firstFormGroup: FormGroup;
  orderStrategyFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  availablePropertyList = [];
  propertyList = ['name1', 'name2', 'organization', 'address1', 'address2', 'city', 'state', 'zip', 'productId', 'quantity', 'name3', 'name4', 'tag1', 'tag2', 'tag3', 'tag4'];
    //
    // 'productId2', 'quantity2', 'productId3', 'quantity3', 'productId4', 'quantity4', 'productId5', 'quantity5', 'productId6', 'quantity6',
    // 'productId7', 'quantity7', 'productId8', 'quantity8', 'productId9', 'quantity9', 'productId10', 'quantity10', 'productId11', 'quantity11', 'productId12', 'quantity12'];
  // propertyList = ['firstName', 'lastName', 'organization', 'address1', 'address2', 'address3', 'address4', 'purchaseOrder', 'city', 'state', 'country', 'postalCode', 'carrier', 'serviceLevel', 'productId', 'quantity',
  //   'productId2', 'quantity2', 'productId3', 'quantity3', 'productId4', 'quantity4', 'productId5', 'quantity5', 'productId6', 'quantity6',
  //   'productId7', 'quantity7', 'productId8', 'quantity8', 'productId9', 'quantity9', 'productId10', 'quantity10', 'productId11', 'quantity11', 'productId12', 'quantity12'];
  requiredPropertyList = ['name1', 'address1', 'city', 'state', 'zip', 'productId'];
  // requiredPropertyList = ['orderId', 'firstName', 'lastName', 'address1', 'city', 'state', 'postalCode', 'country', 'productId'];

  letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  // firstPartOfOrderId: string = '';


  constructor(public router: Router, public startupService: StartupService, private navbarService: NavbarService, private productService: ProductService, private formBuilder: FormBuilder,
              private addressValidationService: AddressValidationService, private orderService: OrderService, private orderDeliverToService: OrderDeliverToService, private csvImportSettingsService: CsvImportSettingsService, private printNodeOrderService: PrintNodeOrderService) {
  }

  ngOnInit() {
    this.uploader.setOptions({isHTML5: true, url: environment.apiBaseURL, disableMultipart: true, method: 'PUT', afterAddingFileListener: this.fileAdded.bind(this), urlEndPointType: 'csv'});
    console.log("AppComponent: Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.orderStrategyFormGroup = this.formBuilder.group({
      // firstPartOfOrderId: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9-_. ]+([a-zA-Z0-9-_. ]+)*')]],
      defaultCarrierService: ['', Validators.required],
      defaultCountry: ['', Validators.required],
      alwaysUseDefaultCarrierAndServiceLevel: [null, Validators.required],
      alwaysUseDefaultCountry: [null, Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
    this.makingOrder = false;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  getData(): void {
    const filter = { eq: {
        'product.productType': 'BOOK'
      }
    };
    this.productService.getFilteredItems(filter)
      .subscribe(products => {
        this.products = products;
        // Setup a ProductId to BookId Map
        for ( const product of this.products ) {
          this.productIdToBookIdMap[String(product.ID)] = product.book_ID;
        }

      });
    this.csvImportSettingsService.loadAll();
    this.csvImportSettingsService.objects
      .subscribe(csvImportSettingss => {
        this.csvImportSettingss = csvImportSettingss;
        if ( !this.selectedCsvImportSettings && this.csvImportSettingss && this.csvImportSettingss.length > 0 ) {
          this.selectedCsvImportSettings = this.csvImportSettingss[0];
        }
      });
  }


  public fileOverBase( e: any ): void {
    console.log('fileOverBase');
    this.hasBaseDropZoneOver = e;
  }

  fileAdded(file: File) {
    if ( file ) {
      console.log(`fileAdded ${file.name} ${file.size}`);
    } else {
      console.log(`fileAdded file is undefined`);
    }

    this.csvFile = file;
    this.loadFile();
  }

  setProperty(event: any) {
    console.log('setProperty event');
  }

  loadFile() {
    this.uploadFileMessages = [];
    this.uploadFileErrorMessages = [];
    this.data = [];
    this.columns = [];
    this.csvProductCodes = {};
    if ( this.csvFile ) {
      this.uploadFileMessages.push(`FileAdded ${this.csvFile.name}`);
      console.log(`fileAdded ${JSON.stringify(this.csvFile)}`);
    } else {
      this.uploadFileErrorMessages.push('Uploaded File Error');
      console.log(`fileAdded file is undefined`);
    }

    const _this = this;
    let rowCounter =  0;
    papa.parse(this.csvFile, {
      worker: false, // Don't bog down the main thread if its a big file
      skipEmptyLines: true,
      step: function (result) {
        if ( result.errors && result.errors.length > 0 ) {
          console.log('parsing complete errors:' + JSON.stringify(result.errors));
        }
        if ( _this.uploadFileMessages.length > 0 ) {
          rowCounter++;
          _this.uploadFileMessages[0] = `FileAdded ${_this.csvFile.name} row in file ${rowCounter}`;
        } else {
          _this.uploadFileMessages.push(`FileAdded ${_this.csvFile.name} row in file ${rowCounter}`);
        }

        if (_this.columns && _this.columns.length === 0) {
          console.log('loadFile step no columns:' + JSON.stringify(result));
        } else {
          console.log('loadFile step columns present:' + JSON.stringify(result));
        }
        _this.data.push(result);
      }, error : function (results, f) {

      }, complete: function (results, f) {
        console.log('parsing complete read:');
        if ( results.errors && results.errors.length > 0 ) {
          console.log('parsing complete errors:' + JSON.stringify(results.errors));
        }
        // Setup the Columns
        if (_this.data.length > 0) {
          _this.uploadFileMessages.push(`Uploaded File : Parsing complete ${_this.data.length} rows in the file.`);
          console.log(`parsing complete read : ${_this.data.length}`);
          const firstRow = _this.data[0];
          for (let i = 0; i < _this.data[0].data.length; i++) {
            const firstDataValues = [];
            for (let x = 0; x < 5; x++) {
              if (_this.data.length > x && _this.data[x].data.length > i) {
                firstDataValues.push(_this.data[x].data[i]);
              }
            }
            const column = new Column(i, _this.data[0].data[i], firstDataValues);
            _this.columns.push(column);
          }
          // Put out an error message
          _this.uploadFileVerified = true;
          _this.setupColumnMap();
          if ( _this.selectedCsvImportSettings ) {
            _this.createOrderFromCsvImportSettings();
            _this.stepForward();
            _this.stepForward();
            _this.stepForward();
            _this.stepForward();
            _this.stepForward();
          } else {
            _this.stepForward();
          }
        } else {
          _this.uploadFileErrorMessages.push(`Parsing Complete : no lines!`);
          console.log(`parsing complete read : no lines!`);
        }
      }
    });
  }

  adjustPropertyListBasedOnDefaults() {
    if ( !this.alwaysUseDefaultCarrierAndServiceLevel) {
      this.propertyList.push('carrier');
      this.propertyList.push('serviceLevel');
    }
    if ( !this.alwaysUseDefaultCountry) {
      this.propertyList.push('country');
    }
  }

  lookForColumnDataPropertyDefaults() {
    for ( const column of this.columns ) {
      const matchingDataProperty = this.lookForAMatchingDataProperty(column.index);
      if ( matchingDataProperty ) {
        column.property = matchingDataProperty;
      }
    }
  }
  lookForAMatchingDataProperty(index: number) {
    if ( this.csvFileHasAHeaderRow ) {
      for ( let propertyName of this.propertyList ) {
        propertyName = propertyName.toLowerCase();
        if ( this.data[0].data[index] && this.data[0].data[index].toLowerCase().indexOf(propertyName) !== -1 ) {
          return propertyName;
        }
      }
    } else {
      // Look at the data and see if there something that makes sense
      if ( this.data.length > 2) {
        if ( this.data[1].data[index] ) {
          if (this.data[1].data[index].length === 5 && this.data[2].data[index].length === 5) {
            return 'zip';
          }
          if (this.data[1].data[index].toUpperCase() === 'US' || this.data[1].data[index].toUpperCase() === 'USA') {
            return 'country';
          }
          if (this.data[1].data[index].length === 13 && this.data[2].data[index].length === 13) {
            return 'productId';
          }
        }
      }
    }
  }

  lookForProductIdMatches() {
    for ( const productCode of this.getCSVFileProductCodes() ) {
      const matchingProduct = this.lookForAMatchingProduct( productCode );
      if ( matchingProduct ) {
        productCode.setProduct(matchingProduct);
      }
    }
    this.verifyAllProductsAreMapped();
  }
  lookForAMatchingProduct(productCode: ProductCode): Product | undefined {
    for ( const product of this.products ) {
      if ( String(product.ID) === productCode.csvProductCode ) {
        return product;
      }
    }

    return undefined;
  }

  verifyAllProductsAreMapped() {
    const productCodes = [];
    for ( const key of Object.keys(this.csvProductCodes)) {
      if ( this.csvProductCodes[key].productId === 0 ) {
        this.productMatchesVerified = false;
        this.matchProductsErrorMessages.push(LanguagePipe.get('Not All Products Are Mapped', 'compressSpaces'));
        return;
      }
    }
    this.productMatchesVerified = true;
  }
  verifyAllRequiredColumnsAreMapped() {
    this.setupColumnMap();
    this.columnMatchesVerified = true;
    // Make sure all of the columns were processed
    for ( const column of this.columns ) {
      if ( column.property === '' && !column.ignored ) {
        this.columnMatchesVerified = false;
      }
    }

    this.matchColumnsErrorMessages = [];
    // Look for all of the required columns
    for ( const propertyName of this.requiredPropertyList ) {
      if ( !this.columnMap[propertyName] ) {
        this.matchColumnsErrorMessages.push('A column for ' + propertyName + ' is required.');
        this.columnMatchesVerified = false;
      }
    }
  }


  setupColumnMap() {
    // Setup the csvProductCodes
    this.columnMap = {};
    this.availablePropertyList = [];
    for ( const propertyName of this.propertyList ) {
      this.availablePropertyList.push(propertyName);
    }
    for ( const column of this.columns ) {
      this.columnMap[column.property] = column;
      const index = this.availablePropertyList.indexOf(column.property);
      if ( index !== -1 ) {
        this.availablePropertyList.splice(index, 1);
      }
    }
  }

  isPropertyNameAvailable(propertyName: string): boolean {
    if ( this.availablePropertyList.indexOf(propertyName) === -1) {
      return true;
    }
    return false;
  }

  setupCSVProductCodes() {
    // Setup the csvProductCodes
    let column = this.columnMap['productId'];
    if ( column ) {
      for ( let i = this.csvFileHasAHeaderRow ? 1 : 0; i < this.data.length; i++ ) {
        const row = this.data[i];
        if ( column.index < row.data.length ) {
          const value = row.data[column.index];
          if ( value ) {
            if ( !this.csvProductCodes[value] ) {
              this.csvProductCodes[value] = new ProductCode(value, this.products);
            }
          }
        }
      }
    }
    for ( let i = 1; i <= 12; i++ ) {
      const propertyName = 'productId' + i;
      column = this.columnMap[propertyName];
      if ( column ) {
        for ( const row of this.data ) {
          if ( row.data.length < column.index ) {
            const value = row.data[column.index];
            if ( value ) {
              if ( !this.csvProductCodes[value] ) {
                this.csvProductCodes[value] = new ProductCode(value, this.products);
              }
            }
          }
        }
      }
    }
    this.lookForProductIdMatches();
  }

  getColumnLetter(index: number) {
    const i = index % 26;
    const j = Math.floor( index / 26 );
    if ( j > 0 && j < 26 )  {
      return this.letters[j] + this.letters[i];
    }
    return this.letters[i];
  }
  getRowNumber(index: number) {
    return index + 1;
  }

  stepBack() {
    this.stepper.previous();
  }

  stepForward() {
    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  getPercentOfColumnPassedVerification(column: Column): number {
    let verifiedCount = 0;
    if ( column ) {
      for (const row of this.data) {
        if (row.data.length < column.index) {
          const value = row.data[column.index];
          if (value) {
            if ( column.isValueValid(value) ) {
              verifiedCount++;
            }
          }
        }
      }
    }
    return Math.ceil(100 * verifiedCount / this.data.length);
  }

  getProductList(): Product[] {
    return this.products;
  }
  getCSVFileProductCodes(): ProductCode[] {
    const productCodes = [];
    for ( const key of Object.keys(this.csvProductCodes)) {
      productCodes.push(this.csvProductCodes[key]);
    }
    return productCodes;
  }

  getPlaceOrderButtonClass(): string {
    if ( this.placingOrderStatus === 'Success') {
      return 'processing btn-success';
    }
    if ( this.placingOrderStatus === 'PleaseWait') {
      return 'processing btn-light';
    }
    if ( this.placingOrderStatus !== '') {
      return 'processingProblem btn-danger';
    }
    if ( this.columnMatchesVerified && this.productMatchesVerified && this.uploadFileVerified ) {
      return 'ready btn-primary';
    } else {
      return 'notReady btn-warning';
    }
  }

  getValueFromMapIntoData(data: Array<any>, columnName: string): any {
    if ( this.columnMap[columnName] ) {
      try {
        return data[this.columnMap[columnName].index];
      } catch (error) {
        console.log("Error reading column[" + columnName + " / " + this.columnMap[columnName].index + "]");
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  makeOrderId(address1: string, address2: string, city: string, state: string, postalcode: string): string {
    return (address1 + '_' + address2 + '_' + city + '_' + state  + '_' + postalcode).toUpperCase();
  }

  makeOrders() {
    this.orders = [];
    const productIdFieldNames = [];
    const quantityFieldNames = [];
    for ( const column of this.columns ) {
      if ( column.property.startsWith('productId') ) {
        productIdFieldNames.push(column.property);
      } else if ( column.property.startsWith('quantity') ) {
        quantityFieldNames.push(column.property);
      }
    }

    const ecommerceConnectionId = 2;

    const mappedCarrierService = {}; // Hashtable<string, string> // SnowfallPressGatewayImpl.SELF.getMappedCarrierService(ecommerceConnectionId);

    let prevOrderId = '';
    let bookOrder: SimpleOrder;
    let orderId;

    let numberOfProductsPerRow = this.defaultNumberOfProductsPerRow;
    if ( numberOfProductsPerRow < 1 ) {
      numberOfProductsPerRow = 1;
    }
    if ( numberOfProductsPerRow > 100 ) {
      numberOfProductsPerRow = 100;
    }

    let numberOfLeadingRowsToIgnore = 1; // Integer.valueOf(initialSettings.get("numberOfLeadingRowsToIgnore"));
    if ( numberOfLeadingRowsToIgnore < 0 ) {
      numberOfLeadingRowsToIgnore = 0;
    }

    // Hashtable<String, Integer> settings = new Hashtable<String, Integer>();
    const response = [];
    // ONLY PROCESS ORDERS IF THE .settings FILE HAS ALL OF THE NEEDED VALUES
    if ( this.productMatchesVerified && this.columnMatchesVerified ) {
      // PRODCESS THE ROWS

      let namesList = [];
      let tagsList = [];
      const this_ = this;

      const dataWithOutTitleRow = [];
      for (let i = this.csvFileHasAHeaderRow ? 1 : 0; i < this.data.length; i++) {
        const row = this.data[i];
        dataWithOutTitleRow.push(row);
      }

      const sortedDataWithOutTitleRow = dataWithOutTitleRow.sort((a, b) => {
        const aAddress1 = this.getValueFromMapIntoData(a.data, 'address1');
        const bAddress1 = this.getValueFromMapIntoData(b.data, 'address1');
        if ( aAddress1 > bAddress1 ) {
          return -1;
        }
        if ( aAddress1 < bAddress1 ) {
          return 1;
        }
        const aName1 = this.getValueFromMapIntoData(a.data, 'name1');
        const bName1 = this.getValueFromMapIntoData(b.data, 'name1');
        if ( aName1 > bName1 ) {
          return -1;
        }
        if ( aName1 < bName1 ) {
          return 1;
        }
        const aName2 = this.getValueFromMapIntoData(a.data, 'name2');
        const bName2 = this.getValueFromMapIntoData(b.data, 'name2');
        if ( aName2 > bName2 ) {
          return -1;
        }
        if ( aName2 < bName2 ) {
          return 1;
        }
        return 0;
      });
      for (let i = 0; i < sortedDataWithOutTitleRow.length; i++) {
        const row = sortedDataWithOutTitleRow[i];
        const title = this.getValueFromMapIntoData(row.data, 'title');
        const name1 = this.getValueFromMapIntoData(row.data, 'name1');
        const name2 = this.getValueFromMapIntoData(row.data, 'name2');
        const name3 = this.getValueFromMapIntoData(row.data, 'name3');
        const name4 = this.getValueFromMapIntoData(row.data, 'name4');

        // Make a name from the list of selected name fields
        let name = name1 ? name1 : '';
        name += name2 ? ' ' + name2 : '';
        name += name3 ? ' ' + name3 : '';
        name += name4 ? ' ' + name4 : '';

        const tag1 = this.getValueFromMapIntoData(row.data, 'tag1');
        const tag2 = this.getValueFromMapIntoData(row.data, 'tag2');
        const tag3 = this.getValueFromMapIntoData(row.data, 'tag3');
        const tag4 = this.getValueFromMapIntoData(row.data, 'tag4');

        const company = this.getValueFromMapIntoData(row.data, 'organization');
        const address1 = this.getValueFromMapIntoData(row.data, 'address1');
        const address2 = this.getValueFromMapIntoData(row.data, 'address2');
        const address3 = this.getValueFromMapIntoData(row.data, 'address3');
        const address4 = this.getValueFromMapIntoData(row.data, 'address4');
        const city = this.getValueFromMapIntoData(row.data, 'city');
        const state = this.getValueFromMapIntoData(row.data, 'state');
        let country = this.getValueFromMapIntoData(row.data, 'country');
        const postalCode = this.getValueFromMapIntoData(row.data, 'zip');
        const email = this.getValueFromMapIntoData(row.data, 'email');
        const telephone = this.getValueFromMapIntoData(row.data, 'telephone');
        const carrier = this.getValueFromMapIntoData(row.data, 'carrier');
        let service = this.getValueFromMapIntoData(row.data, 'serviceLevel');

        orderId = this.makeOrderId(address1, address2, city, state, postalCode);

        let currency = this.defaultCurrency; // this.getValueFromMapIntoData(row.data, 'currency');

        // Apply default values for fields that are needed but may not be available
        if (!country && this.defaultCountry) {
          country = this.defaultCountry;
        }
        if (!service && this.defaultCarrierService) {
          service = this.defaultCarrierService;
        }
        if (!currency && this.defaultCurrency) {
          currency = this.defaultCurrency;
        }

        if ( orderId !== prevOrderId ) {
          if (bookOrder) {
            let x = 0;
            bookOrder.firstName = '';
            for (; x < namesList.length / 2; x++) {
              bookOrder.firstName += namesList[x] + ' ';
            }
            bookOrder.firstName = bookOrder.firstName.trim();

            bookOrder.lastName = '';
            for (; x < namesList.length; x++) {
              bookOrder.lastName += namesList[x] + ' ';
            }
            bookOrder.lastName = bookOrder.lastName.trim();

            this.orders.push(bookOrder);
            namesList = [];
            tagsList = [];
            bookOrder = undefined;
          }
        }
        // Build up a unique list of names for this order.
        if ( namesList.indexOf(name) === -1 ) {
          namesList.push(name);
        }

        // Build up a unique list of names for this order.
        if ( tagsList.indexOf(tag1) === -1 ) {
          tagsList.push(tag1);
        }
        if ( tagsList.indexOf(tag2) === -1 ) {
          tagsList.push(tag2);
        }
        if ( tagsList.indexOf(tag3) === -1 ) {
          tagsList.push(tag3);
        }
        if ( tagsList.indexOf(tag4) === -1 ) {
          tagsList.push(tag4);
        }

        if (!bookOrder) {
          bookOrder = new SimpleOrder();
          bookOrder.setOrderId(orderId);
          prevOrderId = orderId;
          bookOrder.setCurrency(currency);
          // bookOrder.setOrgId(orgId);
          bookOrder.setEcommerceConnection(ecommerceConnectionId);
          bookOrder.setOrderDate(new Date().toString());
          bookOrder.setSubmissionMethod("BATCH_ORDER");

          bookOrder.setEmail(email);
          bookOrder.setPhone(telephone);
          bookOrder.setStreet(address1);
          bookOrder.setAddress2(address2);
          bookOrder.setAddress3(address3);
          bookOrder.setAddress4(address4);
          bookOrder.setCity(city);
          bookOrder.setPostalCode(postalCode);
          bookOrder.setRegion(state);
          bookOrder.setCountry(country);

          bookOrder.setTitle(title);
          bookOrder.setFirst(name);
          bookOrder.setLast('');
          bookOrder.setCompany(company);

          let shippingInfo = "ERROR_ERROR";
          if (!carrier && service) {
            shippingInfo = service;
          } else if (!service && carrier) {
            shippingInfo = carrier;
          } else if (!service && !carrier) {
            shippingInfo = "ERROR_ERROR";
          } else {
            shippingInfo = carrier.trim() + "_" + service.trim();
          }

          if (mappedCarrierService && mappedCarrierService[shippingInfo]) {
            shippingInfo = mappedCarrierService[shippingInfo];
          }

          // TODO: Confirm it is a valid carrier/service
          bookOrder.setShippingInfo(shippingInfo);
        }

        for (const productIdFieldName of productIdFieldNames) {
          const productId = this.getValueFromMapIntoData(row.data, productIdFieldName);
          let quantity = 1;
          if (productIdFieldName === 'productId') {
            quantity = this.getValueFromMapIntoData(row.data, 'quantity');
          } else {
            const fieldNumber = productId.substring(9);
            quantity = this.getValueFromMapIntoData(row.data, 'quantity' + fieldNumber);
          }
          if ( !quantity ) {
            quantity = 1;
          }
          if (productId && quantity) {
            let item: SimpleItem;
            for ( const alreadyCreatedItem of bookOrder.items ) {
              if ( alreadyCreatedItem.id === productId ) {
                item = alreadyCreatedItem;
              }
            }
            if ( item ) {
              item.quantity += quantity;
            } else {
              item = new SimpleItem();
              item.setId(productId);
              item.setQuantity(quantity);
              bookOrder.addItem(item);
            }
          }
        }
      }
      if ( bookOrder ) {
        let x = 0;
        bookOrder.firstName = '';
        for (; x < namesList.length / 2; x++) {
          bookOrder.firstName += namesList[x] + ' ';
        }
        bookOrder.firstName = bookOrder.firstName.trim();

        bookOrder.lastName = '';
        for (; x < namesList.length; x++) {
          bookOrder.lastName += namesList[x] + ' ';
        }
        bookOrder.lastName = bookOrder.lastName.trim();
        this.orders.push(bookOrder);
      }
      //   //Place the last line"s order
      //   if(bookOrder!=null)
      //   {
      //     placeOrder(bookOrder, orgId, orderId, ecommerceConnectionId, rawMessageGathered, response, usedCustomerOrderIds);
      //   }
      // }
    }
    this.validateAddresses();
  }

  async validateAddresses() {
    this.addressStillToValidate = this.orders.length;
    for (const simpleOrder of this.orders) {
      await this.validateAddress(simpleOrder, 0);
    }
    this.sortOrders();
  }

  async validateAddress(simpleOrder: SimpleOrder, retryCount: number) {
    if ( retryCount > 8) {
      console.log('Giving up validating -- too many retries');
      // TODO: recored in memory at least the issues and setup a retry commit with a manual button
      return;
    }
    await this.delay(retryCount * 2000);

    simpleOrder.addressValidationResult = undefined;
    const address = new ValidationAddress(undefined);
    address.title = simpleOrder.title;
    address.firstname = simpleOrder.title;
    address.lastname = simpleOrder.lastName;
    address.address1 = simpleOrder.address1;
    address.address2 = simpleOrder.address2;
    address.address3 = simpleOrder.address3;
    address.address4 = simpleOrder.address4;
    address.city = simpleOrder.city;
    address.state = simpleOrder.region;
    address.country = simpleOrder.country;
    address.postalcode = simpleOrder.postalCode;

    this.addressValidationService.validate(address).subscribe(addressValidationResult => {
      if ( addressValidationResult ) {
        simpleOrder.setAddressValidationResult(addressValidationResult);
        this.addressStillToValidate--;
        this.sortOrders();
        if ( this.addressStillToValidate === 0 ) {
          this.combineSameAddresses();
        }
      } else {
        this.validateAddress(simpleOrder, retryCount++);
      }
    });
  }

  combineSameAddresses() {
    for (let i = this.orders.length - 1; i >= 0; i--) {
      const simpleOrderA = this.orders[i];
      for (let j = i - 1; j >= 0; j--) {
        const simpleOrderB = this.orders[j];
        if ( simpleOrderA.isSameAddress(simpleOrderB)) {
          simpleOrderB.addOrderItems(simpleOrderA);
          this.orders.splice(i, 1);
        }
      }
    }
  }

  sortOrders() {
    this.orders.sort( ( a, b): number => {
      if ( !a.addressValidationResult ) {
        return 20;
      }
      if ( !b.addressValidationResult ) {
        return -20;
      }
      if ( a.addressToUse !== 'VERIFIED' ) {
        return 10;
      }
      if ( b.addressToUse !== 'VERIFIED' ) {
        return -10;
      }
      if ( a.addressValidationResult.recommendedAddresses && a.addressValidationResult.recommendedAddresses.length === 0 ) {
        return 5;
      }
      if ( b.addressValidationResult.recommendedAddresses && b.addressValidationResult.recommendedAddresses.length === 0 ) {
        return -5;
      }
      if ( a.addressValidationResult.recommendedAddresses && b.addressValidationResult.recommendedAddresses && a.addressValidationResult.recommendedAddresses.length < b.addressValidationResult.recommendedAddresses.length ) {
        return 1;
      } else {
        if ( a.addressValidationResult.recommendedAddresses && b.addressValidationResult.recommendedAddresses && a.addressValidationResult.recommendedAddresses.length > b.addressValidationResult.recommendedAddresses.length ) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }

  createOrderFromCsvImportSettings() {
    if ( this.selectedCsvImportSettings ) {
      const settings = JSON.parse(this.selectedCsvImportSettings.settings);
      if ( settings && settings.columns && settings.csvProductCodes ) {
        this.csvFileHasAHeaderRow = settings.csvFileHasAHeaderRow;
        this.alwaysUseDefaultCarrierAndServiceLevel = settings.alwaysUseDefaultCarrierAndServiceLevel;
        this.alwaysUseDefaultCountry = settings.alwaysUseDefaultCountry;
        this.defaultCurrency = settings.defaultCurrency;
        this.defaultCountry = settings.defaultCountry;
        this.defaultCarrierService = settings.defaultCarrierService;
        this.mappingFromHeaderRow = settings.mappingFromHeaderRow;
        this.defaultNumberOfProductsPerRow = settings.defaultNumberOfProductsPerRow;
        this.columns = settings.columns;
        this.setupColumnMap();
        this.setupCSVProductCodes();
        for ( const key of Object.keys(this.csvProductCodes)) {
          const importedProductCode = settings.csvProductCodes[key];
          const liveProductCode = this.csvProductCodes[key];
          if ( importedProductCode && liveProductCode ) {
            if ( !liveProductCode.product || liveProductCode.product.ID !== importedProductCode.product_ID ) {
              for ( const product of this.products ) {
                if ( product.ID === importedProductCode.product_ID) {
                  liveProductCode.setProduct(product);
                }
              }
            }
          }
        }
        this.verifyAllRequiredColumnsAreMapped();
        this.verifyAllProductsAreMapped();
        this.makeOrders();
      }
    }
  }

  saveCurrentSettings() {
    let csvImportSettings = new CsvImportSettings(undefined);
    const idDate = new Date();
    csvImportSettings.name = '' + ( idDate.getMonth() + 1 ) + '.' + ( idDate.getDate() ) + '.' + idDate.getFullYear() + '-' + Math.floor(Math.random() * 1000);
    if ( this.selectedCsvImportSettings ) {
      csvImportSettings = this.selectedCsvImportSettings;
    }
    const settings: any = {};
    csvImportSettings.archived = 'FALSE';
    csvImportSettings.org_ID = Number(this.startupService.selectedRoleId);
    settings.csvFileHasAHeaderRow = this.csvFileHasAHeaderRow;
    settings.alwaysUseDefaultCarrierAndServiceLevel = this.alwaysUseDefaultCarrierAndServiceLevel;
    settings.alwaysUseDefaultCountry = this.alwaysUseDefaultCountry;
    settings.defaultCurrency = this.defaultCurrency;
    settings.defaultCountry = this.defaultCountry;
    settings.defaultCarrierService = this.defaultCarrierService;
    settings.mappingFromHeaderRow = this.mappingFromHeaderRow;
    settings.defaultNumberOfProductsPerRow = this.defaultNumberOfProductsPerRow;

    settings.columns = [];
    for ( const column of this.columns ) {
      const c: any = {};
      c.property = column.property;
      c.type = column.type;
      c.index = column.index;
      c.ignored = column.ignored;
      c.confirmed = column.confirmed;
      c.headerColumnName = column.headerColumnName;
      settings.columns.push(c);
    }
    settings.csvProductCodes = {};
    for ( const prop of Object.keys(this.csvProductCodes) ) {
      settings.csvProductCodes[prop] = {};
      settings.csvProductCodes[prop].product_ID = this.csvProductCodes[prop].product_ID;
    }
    csvImportSettings.settings = JSON.stringify(settings);
    this.csvImportSettingsService.create(csvImportSettings);
  }



  // This is the Order that contains multiple orderDeliverTo orders -- each with its own delivery address
  createOrder() {
    this.makingOrder = true;

    this.orderDeliverTosSaving = 0;
    this.printNodeOrdersSaving = 0;
    this.orderDeliverTosRetrying = 0;
    this.printNodeOrdersRetrying = 0;

    const order = new Order(undefined);
    order.org_ID = Number(this.startupService.selectedRoleId);
    const customerOrderIdDate = new Date();
    order.customerorderid = 'CSVBatchOrder-' + ( customerOrderIdDate.getMonth() + 1 ) + '.' + ( customerOrderIdDate.getDate() + 1 ) + '.' + customerOrderIdDate.getFullYear() + '.' + Math.floor(Math.random() * 1000); // this.orderStrategyFormGroup.controls.firstPartOfOrderId.value; // this.firstPartOfOrderId; // Add the current date and/or the org_id and or the umbrella to the id?
    order.inventoryType = 'FULFILL_FROM_INVENTORY';
    this.orderService.addOrder(order).subscribe( createdSummaryOrder => {
      this.createdSummaryOrder = createdSummaryOrder;
      if ( this.createdSummaryOrder ) {
        for ( const simpleOrder of this.orders )  {
          simpleOrder.inventoryType = order.inventoryType;
          this.orderDeliverTosSaving++;
          this.createOrderDeliverTo(simpleOrder, createdSummaryOrder, 0);
        }
      } else {
        // TODO: put out a message
      }
    });
  }
  async createPrintNodeOrder(simpleOrder: SimpleOrder, orderDeliverTo: OrderDeliverTo, retryCount: number) {
    if ( retryCount > 8) {
      console.log('Giving up creating printNodeOrder -- too many retries');
      // TODO: recored in memory at least the issues and setup a retry commit with a manual button
      return;
    }
    await this.delay(retryCount * 2000);

    const printNodeOrder = new PrintNodeOrder(undefined);
    printNodeOrder.inventoryType = orderDeliverTo.inventoryType;
    printNodeOrder.org_ID = Number(this.startupService.selectedRoleId);
    printNodeOrder.order_ID = orderDeliverTo.order_ID;
    printNodeOrder.printnode_ID = 1;
    printNodeOrder.orderdeliverto_ID = orderDeliverTo.ID;
    printNodeOrder.address1 = orderDeliverTo.address1;
    printNodeOrder.address2 = orderDeliverTo.address2;
    printNodeOrder.city = orderDeliverTo.city;
    printNodeOrder.state = orderDeliverTo.state;
    printNodeOrder.postalcode = orderDeliverTo.postalcode;
    printNodeOrder.country = orderDeliverTo.country;
    printNodeOrder.title = orderDeliverTo.title;
    printNodeOrder.firstname = orderDeliverTo.firstname;
    printNodeOrder.lastname = orderDeliverTo.lastname;
    printNodeOrder.carrier = orderDeliverTo.carrier;
    printNodeOrder.servicelevel = orderDeliverTo.servicelevel;
    const dueDate = new Date();
    dueDate.setDate(dueDate.getDate() + 1);
    printNodeOrder.dueDate = dueDate;

    printNodeOrder.printNodeOrderItems = [];
    for ( const orderItem of orderDeliverTo.orderItems ) {
      const printNodeOrderItem = new PrintNodeOrderItem(undefined, printNodeOrder);
      printNodeOrderItem.printNodeOrder = undefined;
      printNodeOrderItem.book_ID = this.productIdToBookIdMap[String(orderItem.product_ID)];
      printNodeOrderItem.orderitem_ID = orderItem.ID;
      printNodeOrderItem.quantity = orderItem.quantity;
      printNodeOrder.printNodeOrderItems.push(printNodeOrderItem);
    }
    // Move this part to createPrintNodeOrder in the same patter as Validate and CreateOrderDeliverTo
    this.printNodeOrderService.addItem(printNodeOrder).subscribe( createdPrintNodeOrder => {
      if ( retryCount > 0 ) {
        this.printNodeOrdersRetrying--;
      }
      if ( createdPrintNodeOrder ) {
        this.createdSummaryOrder.orderDeliverTos.push(orderDeliverTo);
        simpleOrder.orderDeliverTo = orderDeliverTo;
        simpleOrder.saving = false;
        this.printNodeOrdersSaving--;
        this.setMakingOrders(undefined);
      } else {
        this.createPrintNodeOrder(simpleOrder, orderDeliverTo, retryCount++);
        this.printNodeOrdersRetrying++;
      }
    });
  }

  async createOrderDeliverTo(simpleOrder: SimpleOrder, order: Order, retryCount: number) {
    if ( retryCount > 8) {
      console.log('Giving up creating orders -- too many retries');
      // TODO: recored in memory at least the issues and setup a retry commit with a manual button
      return;
    }
    await this.delay(( retryCount + 8 ) * 2000);

    const orderDeliverTo = new OrderDeliverTo(undefined);
    orderDeliverTo.inventoryType = simpleOrder.inventoryType;
    orderDeliverTo.order_ID = this.createdSummaryOrder.ID;

    orderDeliverTo.title = simpleOrder.title ? simpleOrder.title.toUpperCase() : '';
    orderDeliverTo.firstname = simpleOrder.firstName ? simpleOrder.firstName.toUpperCase() : '';
    orderDeliverTo.lastname = simpleOrder.lastName ? simpleOrder.lastName.toUpperCase() : '';

    switch ( simpleOrder.addressToUse ) {
      case 'VERIFIED': {
        orderDeliverTo.address1 = simpleOrder.verifiedAddress1 ? simpleOrder.verifiedAddress1 : simpleOrder.address1;
        orderDeliverTo.address2 = simpleOrder.verifiedAddress2 ? simpleOrder.verifiedAddress2 : simpleOrder.address2;
        orderDeliverTo.city = simpleOrder.verifiedCity ? simpleOrder.verifiedCity : simpleOrder.city;
        orderDeliverTo.state = simpleOrder.verifiedRegion ? simpleOrder.verifiedRegion : simpleOrder.region;
        orderDeliverTo.postalcode = simpleOrder.verifiedPostalCode ? simpleOrder.verifiedPostalCode : simpleOrder.postalCode;
        orderDeliverTo.country = simpleOrder.verifiedCountry ? simpleOrder.verifiedCountry : simpleOrder.country;
        break;
      }
      case 'EDITED': {
        orderDeliverTo.address1 = simpleOrder.editedAddress1 ? simpleOrder.editedAddress1 : simpleOrder.address1;
        orderDeliverTo.address2 = simpleOrder.editedAddress2 ? simpleOrder.editedAddress2 : simpleOrder.address2;
        orderDeliverTo.city = simpleOrder.editedCity ? simpleOrder.editedCity : simpleOrder.city;
        orderDeliverTo.state = simpleOrder.editedRegion ? simpleOrder.editedRegion : simpleOrder.region;
        orderDeliverTo.postalcode = simpleOrder.editedPostalCode ? simpleOrder.editedPostalCode : simpleOrder.postalCode;
        orderDeliverTo.country = simpleOrder.editedCountry ? simpleOrder.editedCountry : simpleOrder.country;
        break;
      }
      case 'ORIGINAL': {
        orderDeliverTo.address1 = simpleOrder.address1 ? simpleOrder.address1 : '';
        orderDeliverTo.address2 = simpleOrder.address2 ? simpleOrder.address2 : '';
        orderDeliverTo.city = simpleOrder.city ? simpleOrder.city : '';
        orderDeliverTo.state = simpleOrder.region ? simpleOrder.region : '';
        orderDeliverTo.postalcode = simpleOrder.postalCode ? simpleOrder.postalCode : '';
        orderDeliverTo.country = simpleOrder.country ? simpleOrder.country : '';
        break;
      }
    }

    orderDeliverTo.addressValidationStatus = simpleOrder.getAddressValidationStatus();

    let shippingInfoParts = simpleOrder.shippingInfo.split('_');
    if ( !shippingInfoParts || shippingInfoParts.length < 2 ) {
      shippingInfoParts = this.defaultCarrierService.split('_');
    }
    if ( shippingInfoParts && shippingInfoParts.length >= 2) {
      orderDeliverTo.carrier = shippingInfoParts[0];
      orderDeliverTo.servicelevel = shippingInfoParts[1];
      if ( shippingInfoParts && shippingInfoParts.length >= 3) {
        orderDeliverTo.servicelevel += "_" + shippingInfoParts[2];
      }
    }
    // orderId;
    // type;
    // ignored: boolean;
    // confirmed: boolean;

    for ( const item of simpleOrder.items) {
      if ( this.csvProductCodes && this.csvProductCodes[item.id] && this.csvProductCodes[item.id].product_ID) {
        let orderItem: OrderItem;
        for ( const alreadyCreatedOrderItem of orderDeliverTo.orderItems ) {
          if ( alreadyCreatedOrderItem.product_ID === this.csvProductCodes[item.id].product_ID ) {
            orderItem = alreadyCreatedOrderItem;
          }
        }
        if ( orderItem ) {
          orderItem.quantity += item.quantity;
        } else {
          orderItem = new OrderItem(undefined);
          orderItem.product_ID = this.csvProductCodes[item.id].product_ID;
          orderItem.quantity = item.quantity;
          orderDeliverTo.orderItems.push(orderItem);
        }
      }
    }

    simpleOrder.saving = true;
    if ( !simpleOrder.verifiedAddress1 || simpleOrder.manualAddressValidationStatus === 'INVALID' ) {
      orderDeliverTo.addressValidationStatus = 'INVALID';
    }
    this.orderDeliverToService.addOrderDeliverTo(orderDeliverTo).subscribe( createdOrderDeliverTo => {
      if ( retryCount > 0 ) {
        this.orderDeliverTosRetrying--;
      }
      if ( createdOrderDeliverTo ) {
        const structuredCreatedOrderDeliverTo = new OrderDeliverTo(createdOrderDeliverTo);
        this.orderDeliverTosSaving--;
        this.setMakingOrders(order);
        if ( structuredCreatedOrderDeliverTo ) {
          // if ( orderDeliverTo.addressValidationStatus === 'VALID' ) { // If this is a validated address then make a PrintNodeOrder
          //   this.createPrintNodeOrder(simpleOrder, structuredCreatedOrderDeliverTo, 0);
          // } else { // If the OrderDeliverTo is going to be address validated then wait to make the PrintNodeOrder
          //   // Just leave it alone and make the PrintNodeOrder after the address is valided on the other side
            this.createdSummaryOrder.orderDeliverTos.push(structuredCreatedOrderDeliverTo);
            simpleOrder.orderDeliverTo = structuredCreatedOrderDeliverTo;
            simpleOrder.saving = false;
          // }
        } else {
          console.log(`Trouble saving an OrderDeliverTo ${simpleOrder.firstName}`);
        }
      } else {
        this.orderDeliverTosRetrying++;
        this.createOrderDeliverTo(simpleOrder, order, retryCount++);
      }
    });
  }

  ngOnDestroy(): void {
  }

  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  validateEditedAddress (simpleOrder: SimpleOrder) {
    const address = new ValidationAddress(undefined);
    address.address1 = simpleOrder.editedAddress1;
    address.address2 = simpleOrder.editedAddress2;
    address.address3 = '';
    address.address4 = '';
    address.city = simpleOrder.editedCity;
    address.state = simpleOrder.editedRegion;
    address.country = simpleOrder.editedCountry;
    address.postalcode = simpleOrder.editedPostalCode;

    this.addressValidationService.validate(address).subscribe( addressValidationResult => {
      if ( addressValidationResult ) {
        simpleOrder.setAddressValidationResult(addressValidationResult);
      } else {
        this.addressValidationService.validate(address).subscribe( addressValidationResult2 => {
          if ( addressValidationResult2 ) {
            simpleOrder.setAddressValidationResult(addressValidationResult2);
          } else {
            this.addressValidationService.validate(address).subscribe( addressValidationResult3 => {
              if ( addressValidationResult3 ) {
                simpleOrder.setAddressValidationResult(addressValidationResult3);
              } else {
                this.addressValidationService.validate(address).subscribe( addressValidationResult4 => {
                  if ( addressValidationResult4 ) {
                    simpleOrder.setAddressValidationResult(addressValidationResult4);
                  } else {
                    console.log(`Error doing address validation`);
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  areAllSimpleOrdersSubmitted() {
    for ( const simpleOrder of this.orders ) {
      if ( !simpleOrder.orderDeliverTo ) {
        return false;
      }
    }
    return true;
  }

  getColor (intensity: number): string {
    if ( intensity === 1.0) {
      return 'rgb(0, 128, 0)';
    }
    if ( intensity > 0.6 ) {
      return 'rgb(240, 240, 0)';
    }
    if ( intensity > 0.3 ) {
      return 'rgb(240, 128, 0)';
    }
    return 'rgb(240, 0, 0)';
  }

  saveImportSettings (importSettings: CsvImportSettings) {
    this.csvImportSettingsService.update(importSettings);
  }
  deleteImportSettings (importSettings: CsvImportSettings) {
    this.csvImportSettingsService.delete(importSettings.ID);
    this.csvImportSettingsService.reset();
  }

  isImportSettingsNameUsed (proposedName: string) {
    for ( const importSettings of this.csvImportSettingss ) {
      if ( importSettings.name === proposedName ) {
        return true;
      }
    }
    return false;
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(true), ms)).then(() => console.log("fired"));
  }

  setMakingOrders(order: Order) {
    if ( this.orderDeliverTosSaving === 0 && this.printNodeOrdersSaving === 0 ) {
      this.makingOrder = false;
      this.orderService.updateDebitLineEntries(order).subscribe( x => {
        console.log('Closed out making order');
      });
    }
  }
}

