import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {Organization} from "../../../model/Organization";
import {OrganizationService} from "../../service-data/organization.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, RoleChangeListener {
  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  organization: Organization;

  constructor(public router: Router, public startupService: StartupService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, private organizationService: OrganizationService) {
    this.startupService.addRoleChangeListener(this);
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the umbrella is already authenticated");
    this.startupService.isAuthenticated(this);
    this.getData();
  }

  ngAfterViewInit(): void {
    this.getData();
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  getData(): void {
    if ( this.startupService.selectedRole === 'organization') {
      this.organizationService.getOrganization(this.startupService.selectedRoleId)
        .subscribe(organization => {
          this.organization = organization;
        });
    }
  }

}
