import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'languageString', pure: true
})
export class LanguagePipe implements PipeTransform {
  static map = [];

  static add(key: string, value: string) {
    LanguagePipe.map[key] = value;
  }

  static get(languageString: string, compressSpaces?: string) {
    if ( languageString.length === 0) {
      return '';
    }
    if ( compressSpaces && compressSpaces === 'compressSpaces' ) {
      const compressedLanguageString = languageString.split(' ').join('');
      if ( LanguagePipe.map[compressedLanguageString]) {
        return LanguagePipe.map[compressedLanguageString];
      }
    } else {
      if ( LanguagePipe.map[languageString]) {
        return LanguagePipe.map[languageString];
      }
    }
    console.warn('No Language String for: ' + languageString);
    return '' + languageString + '';
  }

  transform (languageString: string, compressSpaces?: string): string {
    return LanguagePipe.get(languageString, compressSpaces);
  }
}
