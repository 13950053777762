import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {Issue} from "../../model/Issue";
import {NotificationsService} from "./notifications.service";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {ObjectAttachRequest} from "./data-service";

@Injectable()
export class IssueService extends AbstractLiveDataService<Issue> {
  issues: any;

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setIssueService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): Issue {
    const object = new Issue(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "Issue";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "issue";
  }

  getLinkData(): any[] {
    return [];
  }

  linkObject(newObject: Issue, rawObject: Issue) {
    switch ( newObject.objectType ) {
      case 'PRINTNODEORDER': {
        this.notificationsService.addObjectAttachRequest('printNodeOrderService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      case 'PRINTNODEORDERITEM': {
        this.notificationsService.addObjectAttachRequest('printNodeOrderItemService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      case 'PACKAGE': {
        this.notificationsService.addObjectAttachRequest('packageService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      case 'MANUFACTURINGSET': {
        this.notificationsService.addObjectAttachRequest('manufacturingSetService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      case 'MANUFACTURINGTASK': {
        this.notificationsService.addObjectAttachRequest('manufacturingTaskService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      case 'DRAFT': {
        this.notificationsService.addObjectAttachRequest('draftService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      case 'DRAFTBODY': {
        this.notificationsService.addObjectAttachRequest('draftBodyService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      case 'DRAFTCOVER': {
        this.notificationsService.addObjectAttachRequest('draftCoverService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      case 'BOOK': {
        this.notificationsService.addObjectAttachRequest('bookService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      case 'BODY': {
        this.notificationsService.addObjectAttachRequest('bodyService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      case 'COVER': {
        this.notificationsService.addObjectAttachRequest('coverService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      case 'PRODUCT': {
        this.notificationsService.addObjectAttachRequest('productService', new ObjectAttachRequest(newObject.objectId, 'issues', 'object', newObject));
        break;
      }
      default: {
        console.log(`Linking Object for issue objectType ${newObject.objectType} not found newObject[${JSON.stringify(newObject)}] and no objectType is found to link`);
        break;
      }
    }
  }

  updated(object: Issue) {
    this.issues[object.objectType + '_' + object.objectId] = object;
  }

  added(object: Issue) {
    this.issues[object.objectType + '_' + object.objectId] = object;
  }

  cacheIssues(): Observable<Issue[]> {
    this.getItems().subscribe( issues => {
      if ( issues ) {
        const temp: any = {};
        for ( const issue of issues ) {
          temp[issue.objectType + '_' + issue.objectId] = issue;
        }
        this.issues = temp;
        const observable = of( issues );
        return observable;
      } else {
        return new Observable<Issue[]>();
      }
    });
    return new Observable<Issue[]>();
  }
  getIssue(objectType: string, objectId: number, userRoleName: string, userRoleId: number, supportRoleName: string, supportRoleId: number): Observable<Issue> {
    if ( !this.issues ) {
      this.cacheIssues().subscribe( issues => {
        if ( this.issues[objectType + '_' + objectId] ) {
          const observable = of( this.issues[objectType + '_' + objectId] );
          return observable;
        }
        return new Observable<Issue>(undefined);
      });
    } else {
      if ( this.issues[objectType + '_' + objectId] ) {
        const observable = of( this.issues[objectType + '_' + objectId] );
        return observable;
      }
      return new Observable<Issue>(undefined);
    }
  }
}
