<div class="productThumbnail">
  <div *ngIf="imageLoaded" >
    <app-book-cover-image [book]="product.book"></app-book-cover-image>
<!--    <img #productCoverImage style="display: block; width: 436px; height: 304px; left: -230px; top: -1px; object-fit: cover; position: absolute; max-height: unset; min-height: unset; min-width: unset; max-width: unset;" class="image" i18n-alt="" alt="">-->
  </div>
  <div *ngIf="!imageLoaded" class="productWaitingArea">
    <p class="waitingText" i18n="@@Your book is being prepared and will be ready soon.">Your book is being prepared and will be ready soon.</p>
    <img src="/loading.gif" class="waitingImage">
  </div>
  <div class="hoveringDisplayCommands">
    <button mat-raised-button style="width:100%" color="primary" type="button" (click)="viewProduct(product)" title="EditProductToolTip" i18n-title="@@EditProductToolTip"><mat-icon>edit</mat-icon><span> </span><ng-container i18n="@@Edit">Edit</ng-container></button>
    <button mat-raised-button style="width:100%" color="primary" type="button" (click)="addToCart(product)" title="AddToCartToolTip" i18n-title="@@AddToCartToolTip"><ng-container i18n="@@Add to Cart">Add to Cart</ng-container></button>
  </div>
  <div class="productThumbnailBottom1">
    <!--    <h5 style="font-size: 0.4em; margin-top: 3px; margin-bottom: 3px;">{{product.ID}}</h5>-->
    <h5 style="font-size: 0.4em; margin-top: 3px; margin-bottom: 3px;"><span i18n="@@Last Saved">Last Saved</span> {{product.ID}}</h5>
    <h5 style="font-size: 0.7em; margin-top: -6px; margin-bottom: 3px;">{{product.lastUpdate | date: 'MM/dd/yy hh:mm:ss'}} {{product.productNumPages}}</h5>
    <div style="position: absolute; bottom: 0px; left: 5px; width: calc(100% - 10px)">
      <span *ngIf="renameProduct">
        <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="text" i18n-title="@@Rename the product." title="Rename the product." [ngModel]="product.title" #placeHolderProductTitle maxlength="40"></textarea>
        <!--<input type="text" i18n-title="@@Rename the product." title="Rename the product." [ngModel]="product.name" #placeHolderProductTitle maxlength="32" size="18">-->
        <span *ngIf="placeHolderProductTitle.value.trim()!==product.title">
        <span i18n-title="@@Save the new name of the product." title="Save the new name of the product." *ngIf="!isProductTitleUsed()" (click)="renameProduct = !renameProduct; saveProductTitle();"><mat-icon>check</mat-icon></span>
        </span>
        <span i18n-title="@@Cancel the new name of the product." title="Cancel the new name of the product." (click)="renameProduct = !renameProduct; cancelProductTitle();"><mat-icon>close</mat-icon></span>
      </span>
      <ng-container *ngIf="!renameProduct">
        <span>{{ (product.title.length>60)? (product.title | slice:0:60)+'..':(product.title) }}</span>
        <span style="position:absolute;" (click)="renameProduct = !renameProduct" i18n-title="@@Rename Project" title="Rename Project"><mat-icon>edit</mat-icon></span>
        <span i18n-style="@@floatEnd" style="float:right" (click)="deleteProduct(product)" i18n-title="@@Delete Project" title="Delete Project"><mat-icon>delete</mat-icon></span>
      </ng-container>
    </div>
  </div>
</div>
