import {Book} from "./Book";
import {FacilityInventoryProduct} from "./FacilityInventoryProduct";
import {DataObject} from "./DataObject";

export class Product implements DataObject {
  ID: number;
  work_ID: number;
  lastUpdate: Date;
  org_ID: number;
  productType: string;
  customerProduct_ID: string | null;
  EAN13: string;
  title: string;
  subtitle: string | null;
  shortDescription: string | null;
  description: string | null;
  languageCode: string | null;
  book_ID: number | null;
  previewPages: string | null;
  msrp: string | null;
  msrpCurrency: string;
  upstreamCost: string | null;
  archived: string;
  created: Date;
  updateTimestamp: Date;
  isProof: string;
  externalProductInfo_ID: number | null;
  productNumPages: number;
  productHeight: number;
  productWidth: number;
  productBindingType: string;
  productInteriorType: string;
  productPublicationYear: number;
  productAuthor: string;
  productCoverImage: string;
  productWeight: number;
  productDiscountOfRetail: number;
  productQuantityOnHand: number;
  productPublisher: string | null;
  productQuantityCommited: number;
  productPointBackTo_ID: number | null;
  productDownstreamSalesState: string;
  productAcceptanceState: string;
  book: Book;
  catalogs: string;

  facilityInventoryProducts: FacilityInventoryProduct[];

  constructor( obj ) {
    this.facilityInventoryProducts = [];
    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
  }

  public getCoverImageUrl() {
    return 'http://app.snowfallpress.com/users/ripImage.jsp?color=true&resourceId=' + this.book.cover.resource_ID + '&dpi=72&pageNumbers=1&maxWidth=1584&maxHeight=936&authtoken=a339-5d3b-2192-4c04-b161-1dff-637b-44f2&source=' + this.org_ID;
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
