import {Cover} from "./Cover";
import {Body} from "./Body";
import {DataObject} from "./DataObject";
import {Project} from "./Project";

export class Book implements DataObject {
  ID: number;
  work_ID: number;
  org_ID: number;
  title: string;
  subtitle: string;
  author: string;
  comment: string;
  body_ID: number;
  cover_ID: number;
  lastUpdate: Date;
  locked_by_user_ID: number;
  isProof: string;
  isbn: string;
  publicationYear: number;
  sfbn: string;
  languageCode: string;
  archived: string;
  msrp: string;
  bindingType: string;
  interiorType: string;
  isComposeOnDemand: string;
  coverImagesGenerationStatus: string;
  bodyImagesGenerationStatus: string;
  bodyPagesGenerated: string;
  weight: number;
  thickness: number;
  tagId: number;
  inventoryScore: number;
  caseQuantity: number;

  body: Body;
  cover: Cover;
  project: Project;
  primaryInProject: Project;


  constructor (obj) {
    this.ID = 0;
    this.work_ID = 0;
    this.org_ID = 0;
    this.title = '';
    this.subtitle = '';
    this.author = '';
    this.comment = '';
    this.body_ID = 0;
    this.cover_ID = 0;
    this.lastUpdate = new Date(0);
    this.locked_by_user_ID = 0;
    this.isProof = 'FALSE';
    this.isbn = '';
    this.publicationYear = 0;
    this.sfbn = '';
    this.languageCode = '';
    this.archived = 'FALSE';
    this.msrp = '';
    this.bindingType = 'PERFECT';
    this.interiorType = 'GRAY';
    this.isComposeOnDemand = 'FALSE';
    this.coverImagesGenerationStatus = 'INITIAL';
    this.bodyImagesGenerationStatus = 'INITIAL';
    this.bodyPagesGenerated = '';
    this.weight = 0;
    this.thickness = 0;
    this.tagId = 0;
    this.inventoryScore = 0;
    this.caseQuantity = 0;
    this.primaryInProject = undefined;
    this.project = undefined;

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
  }

  public getNormalizedPages(): number {
    if ( this.body ) {
      return this.body.getNormalizedPages();
    }
    return 0;
  }

  getId() {
    return this.ID;
  }

  getOrderValue() {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
