import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import {NavbarService} from "../../service-ui/navbar.service";
import {EditorProjectService} from "../../service-data/editor/editor-project.service";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {EventHandler} from "../../table/EventHandler";
import {Subject} from "rxjs";

@Component({
  selector: 'app-csv',
  templateUrl: './csv.component.html',
  styleUrls: ['./csv.component.css']
})
export class CSVComponent implements OnDestroy, OnInit, LoggedInCallback {
  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor(public router: Router, public startupService: StartupService, private navbarService: NavbarService, private projectService: EditorProjectService, private formBuilder: FormBuilder, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    console.log("AppComponent: Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }
}

