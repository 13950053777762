import { Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../pipe/language.pipe";
import {UserService} from "../../../service-data/user.service";
import {OrganizationService} from "../../../service-data/organization.service";
import {Umbrella} from "../../../../model/Umbrella";
import {Organization} from "../../../../model/Organization";
import {StartupService} from "../../../service-ui/startup.service";
import {CsvImportSettingsService} from "../../../service-data/csv-import-settings.service";
import {CsvImportSettings} from "../../../../model/CsvImportSettings";

@Component({
  selector: 'app-add-csv-import-settings',
  templateUrl: './add-csv-import-settings.component.html',
  styleUrls: ['./add-csv-import-settings.component.css']
})
export class AddCsvImportSettingsComponent implements OnInit {
  public breakpoint: number; // Breakpoint observer code
  public name: string = ``;
  public description: string = ``;
  public addForm: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public csvImportSettingsService: CsvImportSettingsService, public startupService: StartupService) {
  }

  public ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      name: [this.name, [Validators.required, Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
      description: [this.description, [Validators.required, Validators.pattern('[a-zA-Z0-9]+([a-zA-Z0-9 ]+)*')]],
    });

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onAdd(): void {
    const objectToAdd = new CsvImportSettings( undefined );
    objectToAdd.org_ID = Number(this.startupService.selectedRoleId);
    objectToAdd.name = this.addForm.controls.name.value;
    objectToAdd.description = this.addForm.controls.description.value;
    objectToAdd.settings = JSON.stringify({ test: 'value'});
    this.csvImportSettingsService.create(objectToAdd);
    this.markAsDirty(this.addForm);
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
