export class InteriorTheme {
    public name: string;
    public id: string;

    constructor( obj ) {
      this.name = 'Classic';
      this.id = `assets/interior-themes/Classic/`;
      if ( obj ) {
        for ( const prop of Object.keys(obj) ) {
          this[prop] = obj[prop];
        }
      }
    }

    getPreviewImageURL(): string {
      return this.id + 'standard.png';
    }
}

