import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NavbarService } from '../../service-ui/navbar.service';
import {Router} from "@angular/router";
import { Location } from '@angular/common';
import {StartupService} from "../../service-ui/startup.service";
import {User} from "../../../model/User";
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {AdminService} from "../../service-data/admin.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {Subject} from "rxjs";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnDestroy, OnInit, LoggedInCallback {
  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  selectedUser: User;
  users: User[];

  constructor(public router: Router, public startupService: StartupService, public location: Location, public adminService: AdminService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    console.log("AppAdmin: Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Account");
      this.router.navigate(['/home/login']);
    } else {
      if (!this.startupService.admin) {
        console.log("Not an admin");
        this.router.navigate(['/home/login']);
      } else {
        {
          this.getUsers();
        }

      }
    }
  }
  getUsers(): void {
    this.adminService.getUsers().subscribe(users => {
      this.users = users;
    });
  }

  // assumeUserIdentity(userId: string) {
  //   this.startupService.assumeUserIdentity(userId);
  //   this.router.navigate(['/secure/shelf']);
  // }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

}
