import {Component, Input, OnInit} from '@angular/core';
import {StartupService} from "../../../service-ui/startup.service";
import {ServiceProvider} from "../../../../model/ServiceProvider";
import {Router} from "@angular/router";

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.css']
})
export class ServiceProviderComponent implements OnInit {
  @Input() serviceProvider: ServiceProvider;

  constructor(public startupService: StartupService, public router: Router) { }

  ngOnInit() {
  }

}


