import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import { AbstractDataService } from "./abstract-data.service";
import {ManufacturingProcess} from "../../model/ManufacturingProcess";

@Injectable()
export class ManufacturingProcessService extends AbstractDataService<ManufacturingProcess> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService) {
    super(httpa, messageServicea, startupServicea);
  }

  makeObjectInstance(args: any): ManufacturingProcess {
    const object = new ManufacturingProcess(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "ManufacturingProcess";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "manufacturingresourcebucket";
  }
}
