import {TableFilter} from "./TableFilter";

export class NumericTableFilter extends TableFilter {
  public value: number = 0;

  constructor (obj: any) {
    super(obj);
    if ( obj.value ) {
      this.value = Number(obj.value);
    }
  }

  public match(data: any, filter: string): boolean {
    const value = this.getNumericValue(data);
    if ( this.value === value ) {
      return true;
    }
    return false;
  }
}
