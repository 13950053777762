import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {Order} from "../../../model/Order";
import {OrderService} from "../../service-data/order.service";
import {AddGroupOrderComponent} from "./add-group-order/add-group-order.component";
import {Subject} from "rxjs";

@Component({
  selector: 'app-group-orders',
  templateUrl: './group-orders.component.html',
  styleUrls: ['./group-orders.component.css']
})

export class GroupOrdersComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [{
    property: 'ID',
    columnDef: 'ID',
    header: 'ID',
    sort: true,
    type: 'number',
    filterType: 'NumericRange',
    style: 'display: flex; justify-content: space-around; text-align: right',
    cell: (element: any) => `${element.ID}`
  }, {
    property: 'email',
    columnDef: 'email',
    header: 'Email',
    sort: true,
    type: 'string',
    filterType: 'String',
    style: 'text-align: right',
    cell: (element: any) => `${element.email}`
  }, {
    property: 'phoneNumber',
    columnDef: 'phoneNumber',
    header: 'Phone Number',
    sort: true,
    type: 'string',
    filterType: 'String',
    style: 'text-align: right',
    cell: (element: any) => `${element.phoneNumber}`
  }, //      { property: 'organization.ID',   columnDef: 'organizationId',   header: 'Organization Id',   sort: true,  type: 'number',     filterType: 'NumericRange',      style: 'text-align: right',  cell: (element: any) => `${element.organization.ID}` },
    {
      property: 'organization.name',
      columnDef: 'organizationName',
      header: 'Organization Name',
      sort: false,
      type: 'string',
      filterType: 'String',
      style: 'text-align: right',
      cell: (element: any) => {
        return (element.organization && element.organization.name ) ? element.organization.name : '';
      }
    }, // { property: 'printNodeOrder.ID', columnDef: 'packButton',   header: 'Pack',         sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'},
    {
      property: 'ID',
      columnDef: 'resendInvite',
      header: 'Invite',
      sort: true,
      type: 'iconButton',
      filterType: 'Empty',
      tyle: 'text-align: right',
      cell: (element: any) => `${element.ID}`,
      buttonColor: 'primary',
      icon: 'system_update',
      clickAction: 'invite'
    }, {
      property: 'ID',
      columnDef: 'delete',
      header: 'Delete',
      sort: false,
      type: 'iconButton',
      filterType: 'Empty',
      style: 'text-align: right',
      cell: (element: any) => `${element.ID}`,
      buttonColor: 'warn',
      icon: 'delete',
      clickAction: 'redirectToDelete'
    }];

  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<Order>();
  public renderedData: Order[];
  tableFilterManager: TableFilterManager<Order> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', {static: true}) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor (public router: Router, public startupService: StartupService, private orderService: OrderService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public dialog: MatDialog) {
    this.startupService.addRoleChangeListener(this);
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit () {
    console.log("Checking if the groupOrder is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      // if ( d && d.length > 0 ) {
      //   this.renderedData = d;
      // }
      this.renderedData = this.dataSource.data;
    });
  }

  ngAfterViewInit (): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate = (data: Order, filter: string) => {
      return _this.tableFilterManager.tableFilter.match(data, filter);
    };
    // this.dataSource.paginator = this.paginator;
  }

  isLoggedIn (message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  // onSelect(printNodeOrderItem: PrintNodeOrderItem): void {
  //   this.selectedPrintNodeOrderItem = printNodeOrderItem;
  //   if ( this.selectedPrintNodeOrderItem ) {
  //     this.selectedPrintNodeOrder = this.selectedPrintNodeOrderItem.printNodeOrder;
  //   }
  // }

  getData (): void {
    // this.dataSource.data = [];
    this.orderService.getOrders()
      .subscribe(orders => {
        // for (const groupOrder of groupOrders) {
        //   console.log("In GroupOrderComponent getting data from GroupOrderService: " + JSON.stringify(groupOrder));
        //   this.dataSource.data.push(groupOrder);
        // }
        this.dataSource.data = orders;
        this.dataSource.filter = '' + Math.random();
        // this.table.renderRows();
      });
  }

  ngOnDestroy (): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event (action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  downloadOrderDataToCSV () {
    const downloadFileName = 'today' + '.csv';
    const downloadFileContents = this.buildCSVContent();
    const blob = new Blob([downloadFileContents], {type: 'text/text'});
    const e = document.createEvent('MouseEvents')
    const a = document.createElement('a');
    a.download = downloadFileName;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
    e.initEvent('click', true, false);
    a.dispatchEvent(e);
  }

  buildCSVContent(): string {
    let value = 'Organization Name, Order Date, Shipping Addresses, Quantity\n';
    for ( const order of this.dataSource.data ) {
      const organizationName = 'organizaiton'; // order.organization.name;
      const orderDate = order.created.getMonth() + "/" + order.created.getDay() + "/" + order.created.getFullYear();
      const shippingAddresses = order.orderDeliverTos.length;
      const quantity = order.getQuantity();
      value += organizationName + ', ' + orderDate + ', ' + shippingAddresses + ', ' + quantity + ', \n';
    }
    return value;
  }


}
