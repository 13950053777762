import {Component, Input, OnInit} from '@angular/core';
import {StartupService} from "../../../service-ui/startup.service";
import {ServiceProvider} from "../../../../model/ServiceProvider";
import {Facility} from "../../../../model/Facility";
import {User} from "../../../../model/User";
import {UserService} from "../../../service-data/user.service";

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.css']
})
export class FacilityComponent implements OnInit {
  @Input() facility: Facility;

  userId: number;

  constructor(public startupService: StartupService, private userService: UserService) { }

  ngOnInit() {
  }

  revokeProperty (userId: number, property: string) {
    this.userService.getUser('' + userId).subscribe( user => {
      this.userService.revokeProperty(user, property, '' + this.facility.ID).subscribe( user2 => {
        // user.properties[property] = '' + this.facility.ID; // This may be a problem when it is not the focusOrg_ID that is getting the property
        console.log("revokeOrganizationAdmin " + userId);
      });
    });
  }
  grantProperty (userId: number, property: string) {
    this.userService.getUser('' + userId).subscribe( user => {
      this.userService.grantProperty(user, property, '' + this.facility.ID).subscribe( user2 => {
        // delete user.properties[property];
        console.log("revokeProperty " + userId);
      });
    });
  }

}


