export class ShippingOption {
  carrier: string;
  serviceLevel: string;
  cost: number;
  deliveryDays: number;

  constructor(carrier: string, serviceLevel: string, cost: number, deliveryDays: number) {
    this.carrier = carrier;
    this.serviceLevel = serviceLevel;
    this.cost = cost;
    this.deliveryDays = deliveryDays;
  }
}
