<mat-card appearance="outlined" class="app-content">
  <mat-card-content>
    <div class="csv">
      <mat-horizontal-stepper linear="true" #stepper>
        <!-- Upload -->
        <!-- Upload -->
        <!-- Upload -->
        <mat-step [completed]="uploadFileVerified" [hasError]="uploadFileErrorMessages.length > 0" [errorMessage]="uploadFileErrorMessages" [editable]="isEditable">
          <ng-template matStepLabel i18n="@@Upload">Upload</ng-template>
          <form [formGroup]="firstFormGroup">
            <div class="dottedBox secondaryTextColor my-drop-zone" style="min-height: 100px;" appNg2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" [uploader]="uploader">
              <div class="fileUploader">
                <input mat-raised-button style="background-color: #60b700; color: white;" type="file" appNg2FileSelect [uploader]="uploader" multiple accept="text/csv">
                <p class="secondaryTextColor">.csv, .tsv spreadsheets accepted.</p>
              </div>
              <p i18n="@@UploadCSVOrderFileDetails">You can upload a .csv file with any set of columns. The following steps will allow you to match your spreadsheet columns with order data fields. Before finalizing your batch order you will be able to clean up or remove any issues.</p>
              <p i18n="@@UploadCSVOrderFileInstructions">The columns with the following data are required: name1, address1, city, state, postalCode, country (can be defaulted if all addresses in the order are to the same country), productId, quantity</p>
            </div>
            <div *ngIf="uploadFileMessages && uploadFileMessages.length > 0"><p *ngFor="let message of uploadFileMessages">{{message}}</p></div>
            <div *ngIf="uploadFileErrorMessages && uploadFileErrorMessages.length > 0"><p *ngFor="let message of uploadFileErrorMessages">{{message}}</p></div>
            <button mat-raised-button color="primary" (click)="selectedCsvImportSettings = undefined" [class.selected]="!selectedCsvImportSettings"><span i18n="@@Walk through settings">Walk through settings</span></button>
            <ng-container *ngIf="csvImportSettingss && csvImportSettingss.length > 0">
              <ng-container *ngFor="let importSettings of csvImportSettingss" >
                <button (click)="selectedCsvImportSettings = importSettings"  mat-raised-button color="primary" [class.selected]="selectedCsvImportSettings && importSettings.ID === selectedCsvImportSettings.ID">
                  <span i18n="@@Use saved settings">Use saved settings</span>
                  <span>{{importSettings.name}}</span>
                  <div *ngIf="renameImportSettings && selectedCsvImportSettings && importSettings.ID === selectedCsvImportSettings.ID">
                    <textarea style="width: calc(100% - 10px); height: 45px; font-size:1em" type="text" i18n-title="@@Rename the chapter"  title="Rename the chapter." [ngModel]="importSettings.name" #placeHolderChapterName maxlength="128" size="29"></textarea>
                    <div>
                      <ng-container *ngIf="placeHolderChapterName.value.trim()!==importSettings.name">
                        <span i18n-title="@@Save the new name of the import settings"  title="Save the new name of the import settings" *ngIf="!isImportSettingsNameUsed(placeHolderChapterName.value.trim())" (click)="importSettings.name = placeHolderChapterName.value.trim(); saveImportSettings(importSettings); renameImportSettings = !renameImportSettings; "><mat-icon>check</mat-icon></span>
                      </ng-container>
                      <span i18n-title="@@Cancel the new name of the import settings" title="Cancel the new name of the import settings" (click)="renameImportSettings = !renameImportSettings;"><mat-icon>close</mat-icon></span>
                      <span i18n-title="@@Delete import settings" title="Delete import settings" (click)="deleteImportSettings(importSettings);"><mat-icon>delete</mat-icon></span>
                    </div>
                  </div>
                </button>
                <span *ngIf="!renameImportSettings && selectedCsvImportSettings && importSettings.ID === selectedCsvImportSettings.ID" (click)="renameImportSettings = !renameImportSettings" i18n-title="@@Rename Import Settings" title="Rename Import Settings"><mat-icon>edit</mat-icon></span>
              </ng-container>
            </ng-container>
          </form>
          <div class="nextPreviousButtonContainer">
            <button i18n-style="@@floatEnd" style="float:right" matStepperNext mat-raised-button color="primary"><ng-container i18n="@@Next">Next</ng-container><span>&nbsp;</span><mat-icon>navigate_next</mat-icon></button>
          </div>
        </mat-step>
        <!-- Generate Order Strategy -->
        <!-- Generate Order Strategy -->
        <mat-step [completed]="orderStrategyVerified" [hasError]="orderStrategyErrorMessages.length > 0" [errorMessage]="orderStrategyErrorMessages" [editable]="isEditable">
          <ng-template matStepLabel i18n="@@Order Id">Default Settings</ng-template>
          <form [formGroup]="orderStrategyFormGroup">
<!--          <form [formGroup]="orderStrategyFormGroup" (change)="formChanged()">            -->
            <mat-grid-tile>
              <div class="text-inside">
                <mat-form-field>
                  <mat-label i18n="@@CSV Import Settings">CSV Import Settings</mat-label>
                  <mat-select [(ngModel)]="csvImportSettings" name="csvImportSettings" i18n-placeholder="@@CSV Import Settings" placeholder="CSV Import Settings" autocomplete="csvImportSettings">
                    <mat-option *ngFor="let importSettings of csvImportSettingss" [value]="importSettings">{{importSettings.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-grid-tile>
            <mat-grid-list [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
<!--              <mat-grid-tile>-->
<!--                <div class="text-inside">-->
<!--                  <mat-form-field>-->
<!--                    <input matInput i18n-placeholder="@@First Part of Order Id" placeholder="First Part of Order Id" value={{firstPartOfOrderId}} formControlName="firstPartOfOrderId" required>-->
<!--                    <mat-error *ngIf="orderStrategyFormGroup.controls.firstPartOfOrderId.hasError('required')" i18n="@@Please enter the first part of the order id">Please enter the first part of the order id</mat-error>-->
<!--                    <mat-error *ngIf="orderStrategyFormGroup.controls.firstPartOfOrderId.hasError('pattern')" i18n="@@Please enter a valid value for the first part of the order id">Please enter a valid value for the first part of the order id</mat-error>-->
<!--                  </mat-form-field>-->
<!--                </div>-->
<!--              </mat-grid-tile>-->
<!--              <mat-grid-tile>-->
<!--                <div class="text-inside">-->
<!--                  <mat-form-field>-->
<!--                    <mat-slide-toggle [(ngModel)]="alwaysUseDefaultCarrierAndServiceLevel" formControlName="alwaysUseDefaultCarrierAndServiceLevel" i18n="@@Always use default carrier and level of service">Always use default carrier and level of service</mat-slide-toggle>-->
<!--                  </mat-form-field>-->
<!--                </div>-->
<!--              </mat-grid-tile>-->
              <mat-grid-tile>
                <div class="text-inside">
                  <mat-form-field>
                    <mat-label i18n="@@Shipping">Shipping</mat-label>
                    <mat-select [(ngModel)]="defaultCarrierService" name="defaultCarrierService" i18n-placeholder="@@Default Shipping" placeholder="Default Shipping" formControlName="defaultCarrierService" autocomplete="defaultCarrierService">
                      <mat-option value="USPS_PRIORITY_FLAT" selected="selected" i18n='@@USPS Priority Flat Mail'>USPS Priority Flat Mail</mat-option>
                      <mat-option value="USPS_PRIORITY" i18n='@@USPS Priority Mail'>USPS Priority Mail</mat-option>
                      <mat-option value="USPS_MEDIA" i18n='@@USPS Media Mail'>USPS Media Mail</mat-option>
                      <mat-option value="UPS_GROUND" i18n='@@UPS Ground'>UPS Ground</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-grid-tile>
<!--              <mat-grid-tile>-->
<!--                <div class="text-inside">-->
<!--                  <mat-form-field>-->
<!--                    <mat-slide-toggle [(ngModel)]="alwaysUseDefaultCountry" formControlName="alwaysUseDefaultCountry" i18n="@@Always use default country">Always use default country</mat-slide-toggle>-->
<!--                  </mat-form-field>-->
<!--                </div>-->
<!--              </mat-grid-tile>-->
              <mat-grid-tile>
                <div class="text-inside">
                  <mat-form-field>
                    <mat-label i18n="@@Country">Country</mat-label>
                    <mat-select [(ngModel)]="defaultCountry" name="defaultCountry" i18n-placeholder="@@Default Country" placeholder="Default Country" formControlName="defaultCountry" autocomplete="country">
                      <mat-option value="AF" i18n='@@Afghanistan'>Afghanistan</mat-option>
                      <mat-option value="AL" i18n='@@Albania'>Albania</mat-option>
                      <mat-option value="DZ" i18n='@@Algeria'>Algeria</mat-option>
                      <mat-option value="AS" i18n='@@American Samoa'>American Samoa</mat-option>
                      <mat-option value="AD" i18n='@@Andorra'>Andorra</mat-option>
                      <mat-option value="AG" i18n='@@Angola'>Angola</mat-option>
                      <mat-option value="AI" i18n='@@Anguilla'>Anguilla</mat-option>
                      <mat-option value="AG" i18n='@@Antigua &amp; Barbuda'>Antigua &amp; Barbuda</mat-option>
                      <mat-option value="AR" i18n='@@Argentina'>Argentina</mat-option>
                      <mat-option value="AA" i18n='@@Armenia'>Armenia</mat-option>
                      <mat-option value="AW" i18n='@@Aruba'>Aruba</mat-option>
                      <mat-option value="AU" i18n='@@Australia'>Australia</mat-option>
                      <mat-option value="AT" i18n='@@Austria'>Austria</mat-option>
                      <mat-option value="AZ" i18n='@@Azerbaijan'>Azerbaijan</mat-option>
                      <mat-option value="BS" i18n='@@Bahamas'>Bahamas</mat-option>
                      <mat-option value="BH" i18n='@@Bahrain'>Bahrain</mat-option>
                      <mat-option value="BD" i18n='@@Bangladesh'>Bangladesh</mat-option>
                      <mat-option value="BB" i18n='@@Barbados'>Barbados</mat-option>
                      <mat-option value="BY" i18n='@@Belarus'>Belarus</mat-option>
                      <mat-option value="BE" i18n='@@Belgium'>Belgium</mat-option>
                      <mat-option value="BZ" i18n='@@Belize'>Belize</mat-option>
                      <mat-option value="BJ" i18n='@@Benin'>Benin</mat-option>
                      <mat-option value="BM" i18n='@@Bermuda'>Bermuda</mat-option>
                      <mat-option value="BT" i18n='@@Bhutan'>Bhutan</mat-option>
                      <mat-option value="BO" i18n='@@Bolivia'>Bolivia</mat-option>
                      <mat-option value="BL" i18n='@@Bonaire'>Bonaire</mat-option>
                      <mat-option value="BA" i18n='@@Bosnia &amp; Herzegovina'>Bosnia &amp; Herzegovina</mat-option>
                      <mat-option value="BW" i18n='@@Botswana'>Botswana</mat-option>
                      <mat-option value="BR" i18n='@@Brazil'>Brazil</mat-option>
                      <mat-option value="BC" i18n='@@British Indian Ocean Ter'>British Indian Ocean Ter</mat-option>
                      <mat-option value="BN" i18n='@@Brunei'>Brunei</mat-option>
                      <mat-option value="BG" i18n='@@Bulgaria'>Bulgaria</mat-option>
                      <mat-option value="BF" i18n='@@Burkina Faso'>Burkina Faso</mat-option>
                      <mat-option value="BI" i18n='@@Burundi'>Burundi</mat-option>
                      <mat-option value="KH" i18n='@@Cambodia'>Cambodia</mat-option>
                      <mat-option value="CM" i18n='@@Cameroon'>Cameroon</mat-option>
                      <mat-option value="CA" i18n='@@Canada'>Canada</mat-option>
                      <mat-option value="IC" i18n='@@Canary Islands'>Canary Islands</mat-option>
                      <mat-option value="CV" i18n='@@Cape Verde'>Cape Verde</mat-option>
                      <mat-option value="KY" i18n='@@Cayman Islands'>Cayman Islands</mat-option>
                      <mat-option value="CF" i18n='@@Central African Republic'>Central African Republic</mat-option>
                      <mat-option value="TD" i18n='@@Chad'>Chad</mat-option>
                      <mat-option value="CD" i18n='@@Channel Islands'>Channel Islands</mat-option>
                      <mat-option value="CL" i18n='@@Chile'>Chile</mat-option>
                      <mat-option value="CN" i18n='@@China'>China</mat-option>
                      <mat-option value="CI" i18n='@@Christmas Island'>Christmas Island</mat-option>
                      <mat-option value="CS" i18n='@@Cocos Island'>Cocos Island</mat-option>
                      <mat-option value="CO" i18n='@@Colombia'>Colombia</mat-option>
                      <mat-option value="CC" i18n='@@Comoros'>Comoros</mat-option>
                      <mat-option value="CG" i18n='@@Congo'>Congo</mat-option>
                      <mat-option value="CK" i18n='@@Cook Islands'>Cook Islands</mat-option>
                      <mat-option value="CR" i18n='@@Costa Rica'>Costa Rica</mat-option>
                      <mat-option value="CT" i18n='@@Cote DIvoire'>Cote D'Ivoire</mat-option>
                      <mat-option value="HR" i18n='@@Croatia'>Croatia</mat-option>
                      <mat-option value="CU" i18n='@@Cuba'>Cuba</mat-option>
                      <mat-option value="CB" i18n='@@Curacao'>Curacao</mat-option>
                      <mat-option value="CY" i18n='@@Cyprus'>Cyprus</mat-option>
                      <mat-option value="CZ" i18n='@@Czech Republic'>Czech Republic</mat-option>
                      <mat-option value="DK" i18n='@@Denmark'>Denmark</mat-option>
                      <mat-option value="DJ" i18n='@@Djibouti'>Djibouti</mat-option>
                      <mat-option value="DM" i18n='@@Dominica'>Dominica</mat-option>
                      <mat-option value="DO" i18n='@@Dominican Republic'>Dominican Republic</mat-option>
                      <mat-option value="TM" i18n='@@East Timor'>East Timor</mat-option>
                      <mat-option value="EC" i18n='@@Ecuador'>Ecuador</mat-option>
                      <mat-option value="EG" i18n='@@Egypt'>Egypt</mat-option>
                      <mat-option value="SV" i18n='@@El Salvador'>El Salvador</mat-option>
                      <mat-option value="GQ" i18n='@@Equatorial Guinea'>Equatorial Guinea</mat-option>
                      <mat-option value="ER" i18n='@@Eritrea'>Eritrea</mat-option>
                      <mat-option value="EE" i18n='@@Estonia'>Estonia</mat-option>
                      <mat-option value="ET" i18n='@@Ethiopia'>Ethiopia</mat-option>
                      <mat-option value="FA" i18n='@@Falkland Islands'>Falkland Islands</mat-option>
                      <mat-option value="FO" i18n='@@Faroe Islands'>Faroe Islands</mat-option>
                      <mat-option value="FJ" i18n='@@Fiji'>Fiji</mat-option>
                      <mat-option value="FI" i18n='@@Finland'>Finland</mat-option>
                      <mat-option value="FR" i18n='@@France'>France</mat-option>
                      <mat-option value="GF" i18n='@@French Guiana'>French Guiana</mat-option>
                      <mat-option value="PF" i18n='@@French Polynesia'>French Polynesia</mat-option>
                      <mat-option value="FS" i18n='@@French Southern Ter'>French Southern Ter</mat-option>
                      <mat-option value="GA" i18n='@@Gabon'>Gabon</mat-option>
                      <mat-option value="GM" i18n='@@Gambia'>Gambia</mat-option>
                      <mat-option value="GE" i18n='@@Georgia'>Georgia</mat-option>
                      <mat-option value="DE" i18n='@@Germany'>Germany</mat-option>
                      <mat-option value="GH" i18n='@@Ghana'>Ghana</mat-option>
                      <mat-option value="GI" i18n='@@Gibraltar'>Gibraltar</mat-option>
                      <mat-option value="GB" i18n='@@Great Britain'>Great Britain</mat-option>
                      <mat-option value="GR" i18n='@@Greece'>Greece</mat-option>
                      <mat-option value="GL" i18n='@@Greenland'>Greenland</mat-option>
                      <mat-option value="GD" i18n='@@Grenada'>Grenada</mat-option>
                      <mat-option value="GP" i18n='@@Guadeloupe'>Guadeloupe</mat-option>
                      <mat-option value="GU" i18n='@@Guam'>Guam</mat-option>
                      <mat-option value="GT" i18n='@@Guatemala'>Guatemala</mat-option>
                      <mat-option value="GN" i18n='@@Guinea'>Guinea</mat-option>
                      <mat-option value="GY" i18n='@@Guyana'>Guyana</mat-option>
                      <mat-option value="HT" i18n='@@Haiti'>Haiti</mat-option>
                      <mat-option value="HW" i18n='@@Hawaii'>Hawaii</mat-option>
                      <mat-option value="HN" i18n='@@Honduras'>Honduras</mat-option>
                      <mat-option value="HK" i18n='@@Hong Kong'>Hong Kong</mat-option>
                      <mat-option value="HU" i18n='@@Hungary'>Hungary</mat-option>
                      <mat-option value="IS" i18n='@@Iceland'>Iceland</mat-option>
                      <mat-option value="IN" i18n='@@India'>India</mat-option>
                      <mat-option value="ID" i18n='@@Indonesia'>Indonesia</mat-option>
                      <mat-option value="IR" i18n='@@Iran'>Iran</mat-option>
                      <mat-option value="IQ" i18n='@@Iraq'>Iraq</mat-option>
                      <mat-option value="IE" i18n='@@Ireland'>Ireland</mat-option>
                      <mat-option value="IM" i18n='@@Isle of Man'>Isle of Man</mat-option>
                      <mat-option value="IL" i18n='@@Israel'>Israel</mat-option>
                      <mat-option value="IT" i18n='@@Italy'>Italy</mat-option>
                      <mat-option value="JM" i18n='@@Jamaica'>Jamaica</mat-option>
                      <mat-option value="JP" i18n='@@Japan'>Japan</mat-option>
                      <mat-option value="JO" i18n='@@Jordan'>Jordan</mat-option>
                      <mat-option value="KZ" i18n='@@Kazakhstan'>Kazakhstan</mat-option>
                      <mat-option value="KE" i18n='@@Kenya'>Kenya</mat-option>
                      <mat-option value="KI" i18n='@@Kiribati'>Kiribati</mat-option>
                      <mat-option value="NK" i18n='@@Korea North'>Korea North</mat-option>
                      <mat-option value="KS" i18n='@@Korea South'>Korea South</mat-option>
                      <mat-option value="KW" i18n='@@Kuwait'>Kuwait</mat-option>
                      <mat-option value="KG" i18n='@@Kyrgyzstan'>Kyrgyzstan</mat-option>
                      <mat-option value="LA" i18n='@@Laos'>Laos</mat-option>
                      <mat-option value="LV" i18n='@@Latvia'>Latvia</mat-option>
                      <mat-option value="LB" i18n='@@Lebanon'>Lebanon</mat-option>
                      <mat-option value="LS" i18n='@@Lesotho'>Lesotho</mat-option>
                      <mat-option value="LR" i18n='@@Liberia'>Liberia</mat-option>
                      <mat-option value="LY" i18n='@@Libya'>Libya</mat-option>
                      <mat-option value="LI" i18n='@@Liechtenstein'>Liechtenstein</mat-option>
                      <mat-option value="LT" i18n='@@Lithuania'>Lithuania</mat-option>
                      <mat-option value="LU" i18n='@@Luxembourg'>Luxembourg</mat-option>
                      <mat-option value="MO" i18n='@@Macau'>Macau</mat-option>
                      <mat-option value="MK" i18n='@@Macedonia'>Macedonia</mat-option>
                      <mat-option value="MG" i18n='@@Madagascar'>Madagascar</mat-option>
                      <mat-option value="MY" i18n='@@Malaysia'>Malaysia</mat-option>
                      <mat-option value="MW" i18n='@@Malawi'>Malawi</mat-option>
                      <mat-option value="MV" i18n='@@Maldives'>Maldives</mat-option>
                      <mat-option value="ML" i18n='@@Mali'>Mali</mat-option>
                      <mat-option value="MT" i18n='@@Malta'>Malta</mat-option>
                      <mat-option value="MH" i18n='@@Marshall Islands'>Marshall Islands</mat-option>
                      <mat-option value="MQ" i18n='@@Martinique'>Martinique</mat-option>
                      <mat-option value="MR" i18n='@@Mauritania'>Mauritania</mat-option>
                      <mat-option value="MU" i18n='@@Mauritius'>Mauritius</mat-option>
                      <mat-option value="ME" i18n='@@Mayotte'>Mayotte</mat-option>
                      <mat-option value="MX" i18n='@@Mexico'>Mexico</mat-option>
                      <mat-option value="MI" i18n='@@Midway Islands'>Midway Islands</mat-option>
                      <mat-option value="MD" i18n='@@Moldova'>Moldova</mat-option>
                      <mat-option value="MC" i18n='@@Monaco'>Monaco</mat-option>
                      <mat-option value="MN" i18n='@@Mongolia'>Mongolia</mat-option>
                      <mat-option value="MS" i18n='@@Montserrat'>Montserrat</mat-option>
                      <mat-option value="MA" i18n='@@Morocco'>Morocco</mat-option>
                      <mat-option value="MZ" i18n='@@Mozambique'>Mozambique</mat-option>
                      <mat-option value="MM" i18n='@@Myanmar'>Myanmar</mat-option>
                      <mat-option value="NA" i18n='@@Nambia'>Nambia</mat-option>
                      <mat-option value="NU" i18n='@@Nauru'>Nauru</mat-option>
                      <mat-option value="NP" i18n='@@Nepal'>Nepal</mat-option>
                      <mat-option value="AN" i18n='@@Netherland Antilles'>Netherland Antilles</mat-option>
                      <mat-option value="NL" i18n='@@Netherlands (Holland, Europe)'>Netherlands (Holland, Europe)</mat-option>
                      <mat-option value="NV" i18n='@@Nevis'>Nevis</mat-option>
                      <mat-option value="NC" i18n='@@New Caledonia'>New Caledonia</mat-option>
                      <mat-option value="NZ" i18n='@@New Zealand'>New Zealand</mat-option>
                      <mat-option value="NI" i18n='@@Nicaragua'>Nicaragua</mat-option>
                      <mat-option value="NE" i18n='@@Niger'>Niger</mat-option>
                      <mat-option value="NG" i18n='@@Nigeria'>Nigeria</mat-option>
                      <mat-option value="NW" i18n='@@Niue'>Niue</mat-option>
                      <mat-option value="NF" i18n='@@Norfolk Island'>Norfolk Island</mat-option>
                      <mat-option value="NO" i18n='@@Norway'>Norway</mat-option>
                      <mat-option value="OM" i18n='@@Oman'>Oman</mat-option>
                      <mat-option value="PK" i18n='@@Pakistan'>Pakistan</mat-option>
                      <mat-option value="PW" i18n='@@Palau Island'>Palau Island</mat-option>
                      <mat-option value="PS" i18n='@@Palestine'>Palestine</mat-option>
                      <mat-option value="PA" i18n='@@Panama'>Panama</mat-option>
                      <mat-option value="PG" i18n='@@Papua New Guinea'>Papua New Guinea</mat-option>
                      <mat-option value="PY" i18n='@@Paraguay'>Paraguay</mat-option>
                      <mat-option value="PE" i18n='@@Peru'>Peru</mat-option>
                      <mat-option value="PH" i18n='@@Philippines'>Philippines</mat-option>
                      <mat-option value="PO" i18n='@@Pitcairn Island'>Pitcairn Island</mat-option>
                      <mat-option value="PL" i18n='@@Poland'>Poland</mat-option>
                      <mat-option value="PT" i18n='@@Portugal'>Portugal</mat-option>
                      <!--<mat-option value="PR" i18n='@@Puerto Rico'>Puerto Rico</mat-option> -->
                      <mat-option value="QA" i18n='@@Qatar'>Qatar</mat-option>
                      <mat-option value="ME" i18n='@@Republic of Montenegro'>Republic of Montenegro</mat-option>
                      <mat-option value="RS" i18n='@@Republic of Serbia'>Republic of Serbia</mat-option>
                      <mat-option value="RE" i18n='@@Reunion'>Reunion</mat-option>
                      <mat-option value="RO" i18n='@@Romania'>Romania</mat-option>
                      <mat-option value="RU" i18n='@@Russia'>Russia</mat-option>
                      <mat-option value="RW" i18n='@@Rwanda'>Rwanda</mat-option>
                      <mat-option value="NT" i18n='@@St Barthelemy'>St Barthelemy</mat-option>
                      <mat-option value="EU" i18n='@@St Eustatius'>St Eustatius</mat-option>
                      <mat-option value="HE" i18n='@@St Helena'>St Helena</mat-option>
                      <mat-option value="KN" i18n='@@St Kitts-Nevis'>St Kitts-Nevis</mat-option>
                      <mat-option value="LC" i18n='@@St Lucia'>St Lucia</mat-option>
                      <mat-option value="MB" i18n='@@St Maarten'>St Maarten</mat-option>
                      <mat-option value="PM" i18n='@@St Pierre &amp; Miquelon'>St Pierre &amp; Miquelon</mat-option>
                      <mat-option value="VC" i18n='@@St Vincent &amp; Grenadines'>St Vincent &amp; Grenadines</mat-option>
                      <mat-option value="SP" i18n='@@Saipan'>Saipan</mat-option>
                      <mat-option value="SO" i18n='@@Samoa'>Samoa</mat-option>
                      <mat-option value="AS" i18n='@@Samoa American'>Samoa American</mat-option>
                      <mat-option value="SM" i18n='@@San Marino'>San Marino</mat-option>
                      <mat-option value="ST" i18n='@@Sao Tome &amp; Principe'>Sao Tome &amp; Principe</mat-option>
                      <mat-option value="SA" i18n='@@Saudi Arabia'>Saudi Arabia</mat-option>
                      <mat-option value="SN" i18n='@@Senegal'>Senegal</mat-option>
                      <mat-option value="SC" i18n='@@Seychelles'>Seychelles</mat-option>
                      <mat-option value="SL" i18n='@@Sierra Leone'>Sierra Leone</mat-option>
                      <mat-option value="SG" i18n='@@Singapore'>Singapore</mat-option>
                      <mat-option value="SK" i18n='@@Slovakia'>Slovakia</mat-option>
                      <mat-option value="SI" i18n='@@Slovenia'>Slovenia</mat-option>
                      <mat-option value="SB" i18n='@@Solomon Islands'>Solomon Islands</mat-option>
                      <mat-option value="OI" i18n='@@Somalia'>Somalia</mat-option>
                      <mat-option value="ZA" i18n='@@South Africa'>South Africa</mat-option>
                      <mat-option value="ES" i18n='@@Spain'>Spain</mat-option>
                      <mat-option value="LK" i18n='@@Sri Lanka'>Sri Lanka</mat-option>
                      <mat-option value="SD" i18n='@@Sudan'>Sudan</mat-option>
                      <mat-option value="SR" i18n='@@Suriname'>Suriname</mat-option>
                      <mat-option value="SZ" i18n='@@Swaziland'>Swaziland</mat-option>
                      <mat-option value="SE" i18n='@@Sweden'>Sweden</mat-option>
                      <mat-option value="CH" i18n='@@Switzerland'>Switzerland</mat-option>
                      <mat-option value="SY" i18n='@@Syria'>Syria</mat-option>
                      <mat-option value="TW" i18n='@@Taiwan'>Taiwan</mat-option>
                      <mat-option value="TJ" i18n='@@Tajikistan'>Tajikistan</mat-option>
                      <mat-option value="TZ" i18n='@@Tanzania'>Tanzania</mat-option>
                      <mat-option value="TH" i18n='@@Thailand'>Thailand</mat-option>
                      <mat-option value="TG" i18n='@@Togo'>Togo</mat-option>
                      <mat-option value="TK" i18n='@@Tokelau'>Tokelau</mat-option>
                      <mat-option value="TO" i18n='@@Tonga'>Tonga</mat-option>
                      <mat-option value="TT" i18n='@@Trinidad &amp; Tobago'>Trinidad &amp; Tobago</mat-option>
                      <mat-option value="TN" i18n='@@Tunisia'>Tunisia</mat-option>
                      <mat-option value="TR" i18n='@@Turkey'>Turkey</mat-option>
                      <mat-option value="TU" i18n='@@Turkmenistan'>Turkmenistan</mat-option>
                      <mat-option value="TC" i18n='@@Turks &amp; Caicos Is'>Turks &amp; Caicos Is</mat-option>
                      <mat-option value="TV" i18n='@@Tuvalu'>Tuvalu</mat-option>
                      <mat-option value="UG" i18n='@@Uganda'>Uganda</mat-option>
                      <mat-option value="UA" i18n='@@Ukraine'>Ukraine</mat-option>
                      <mat-option value="AE" i18n='@@United Arab Emirates'>United Arab Emirates</mat-option>
                      <mat-option value="GB" i18n='@@United Kingdom'>United Kingdom</mat-option>
                      <mat-option value="USA" selected="selected" i18n='@@United States'>United States</mat-option>
                      <mat-option value="UY" i18n='@@Uruguay'>Uruguay</mat-option>
                      <mat-option value="UZ" i18n='@@Uzbekistan'>Uzbekistan</mat-option>
                      <mat-option value="VU" i18n='@@Vanuatu'>Vanuatu</mat-option>
                      <mat-option value="VS" i18n='@@Vatican City State'>Vatican City State</mat-option>
                      <mat-option value="VE" i18n='@@Venezuela'>Venezuela</mat-option>
                      <mat-option value="VN" i18n='@@Vietnam'>Vietnam</mat-option>
                      <mat-option value="VB" i18n='@@Virgin Islands (Brit)'>Virgin Islands (Brit)</mat-option>
                      <mat-option value="VI" i18n='@@Virgin Islands (USA)'>Virgin Islands (USA)</mat-option>
                      <mat-option value="WK" i18n='@@Wake Island'>Wake Island</mat-option>
                      <mat-option value="WF" i18n='@@Wallis &amp; Futana Is'>Wallis &amp; Futana Is</mat-option>
                      <mat-option value="YE" i18n='@@Yemen'>Yemen</mat-option>
                      <mat-option value="ZR" i18n='@@Zaire'>Zaire</mat-option>
                      <mat-option value="ZM" i18n='@@Zambia'>Zambia</mat-option>
                      <mat-option value="ZW" i18n='@@Zimbabwe'>Zimbabwe</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </form>
<!--            <button mat-raised-button color="primary" (click)="orderStrategyVerified = true; stepForward()" i18n="@@Yes">Yes</button>-->
<!--            <button mat-raised-button (click)="csvFileHasAHeaderRow = false; lookForColumnDataPropertyDefaults(); headerRowVerified = true; stepForward()" i18n="@@No">No</button>-->
          <div class="nextPreviousButtonContainer">
            <button i18n-style="@@floatStart" style="float:left" matStepperPrevious mat-raised-button color="basic" type="button"><mat-icon>navigate_before</mat-icon><span>&nbsp;</span><ng-container i18n="@@PREVIOUS">PREVIOUS</ng-container></button>
            <button i18n-style="@@floatEnd" style="float:right" (click)="adjustPropertyListBasedOnDefaults(); setupColumnMap(); setupCSVProductCodes(); stepForward();" matStepperNext mat-raised-button color="primary" type="button"><ng-container i18n="@@Next">Next</ng-container><span>&nbsp;</span><mat-icon>navigate_next</mat-icon></button>
          </div>
        </mat-step>
        <!-- Header -->
        <!-- Header -->
        <!-- Header -->
        <mat-step [completed]="headerRowVerified" [hasError]="headerRowErrorMessages.length > 0" [errorMessage]="headerRowErrorMessages" [editable]="isEditable">
          <ng-template matStepLabel i18n="@@Header">Header</ng-template>
          <div class="columnContainer">
            <div class="column" style="width: 40%; display: inline-block">
              <h2 i18n="@@Does yellow highlighted row contain column names?">Does yellow highlighted row contain column names?</h2>
              <div>
                <button mat-raised-button color="primary" (click)="csvFileHasAHeaderRow = true; lookForColumnDataPropertyDefaults(); headerRowVerified = true; stepForward()" i18n="@@Yes">Yes</button>
                <button mat-raised-button (click)="csvFileHasAHeaderRow = false; lookForColumnDataPropertyDefaults(); headerRowVerified = true; stepForward()" i18n="@@No">No</button>
              </div>
            </div>
            <div class="column" style="width: 60%; display: inline-block">
              <table style="overflow: hidden">
                <ng-container *ngIf="data && data.length > 0 && data[0].data && data[0].data.length > 0">
                  <thead>
                  <tr style="min-height: 43px;">
                    <th class="first"></th>
                    <ng-container *ngFor="let value of data[0].data; index as i;">
                      <th *ngIf="i < 4" >{{getColumnLetter(i)}}</th>
                    </ng-container>
                  </tr>
                  </thead>
                </ng-container>
                <tbody>
                <ng-container *ngFor="let row of data; index as i;">
                  <tr *ngIf="i < 6" [class]="'_' + i">
                    <th class="first">{{ getRowNumber(i) }}</th>
                    <ng-container *ngFor="let value of data[i].data; index as j;">
                      <td *ngIf="j < 4" style="word-wrap: break-word">{{value}}</td>
                    </ng-container>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <h3 color="warn">{{matchColumnsErrorMessages}}</h3>
          <div class="nextPreviousButtonContainer">
            <button i18n-style="@@floatStart" style="float:left" matStepperPrevious mat-raised-button color="basic" type="button"><mat-icon>navigate_before</mat-icon><span>&nbsp;</span><ng-container i18n="@@PREVIOUS">PREVIOUS</ng-container></button>
            <button i18n-style="@@floatEnd" style="float:right" (click)="setupColumnMap(); setupCSVProductCodes();" matStepperNext mat-raised-button color="primary" type="button"><ng-container i18n="@@Next">Next</ng-container><span>&nbsp;</span><mat-icon>navigate_next</mat-icon></button>
          </div>
        </mat-step>
        <!-- Columns -->
        <!-- Columns -->
        <!-- Columns -->
        <mat-step [completed]="columnMatchesVerified" [hasError]="matchColumnsErrorMessages.length > 0" [errorMessage]="matchColumnsErrorMessages" [editable]="isEditable">
          <ng-template matStepLabel i18n="@@Match Columns">Match Columns</ng-template>
          <div *ngFor="let column of columns" class="columnContainer">
            <ng-container *ngIf="column.ignored">
              <div class="columnContainer ignored" [class.ignored]="column.ignored">
                <div class="column">
                  <table>
                    <thead>
                      <tr style="min-height: 43px;">
                        <th class="first">{{getColumnLetter(column.index)}}</th>
                        <th>{{column.headerColumnName}}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="column">
                  <aside class="ignored">
                    <ul>
                      <li><mat-icon style="color: red;">visibility_off</mat-icon><ng-container i18n="@@Ignored">Ignored</ng-container><button i18n-style="@@floatEnd" style="float:right; background-color: transparent; border: none;" mat-button (click)="column.ignored = false; verifyAllRequiredColumnsAreMapped();"><mat-icon>edit</mat-icon></button></li>
                    </ul>
                  </aside>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="column.confirmed">
              <div class="columnContainer ignored" [class.confirmed]="column.confirmed">
                <div class="column">
                  <table>
                    <thead>
                    <tr style="min-height: 43px;">
                        <th class="first">{{getColumnLetter(column.index)}}</th>
                        <th>{{column.headerColumnName}}</th>
                        <th>
                          <button i18n-style="@@floatStart" style="float:left; background-color: transparent; border: none;"  *ngIf="column.property !== ''" i18n-title="@@UnSetTheSelectedDataField" title="UnSetTheSelectedDataField" (click)="column.property = ''; column.confirmed = false; verifyAllRequiredColumnsAreMapped();"><mat-icon>close</mat-icon></button>
                          <mat-select style="width: 70%; padding-top: 8px;" #dataProperty i18n-placeholder="@@Select a data property" placeholder="Select a data property" [ngModel]="column.property" (selectionChange)="column.property = dataProperty.value; verifyAllRequiredColumnsAreMapped()">
                            <mat-option  [class.selected]="property === column.property" [hidden]="isPropertyNameAvailable(property)" [disabled]="isPropertyNameAvailable(property)" *ngFor="let property of propertyList" [value]="property">{{property | languageString }}</mat-option>
                          </mat-select>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="column">
                  <aside class="confirmed">
                    <ul>
                      <li><mat-icon style="color: #60b700;">check</mat-icon> <ng-container i18n="@@Confirmed">Confirmed</ng-container><button i18n-style="@@floatEnd" style="float:right; background-color: transparent; border: none;" (click)="column.confirmed = false; verifyAllRequiredColumnsAreMapped();"><mat-icon>edit</mat-icon></button></li>
                    </ul>
                  </aside>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!column.ignored && !column.confirmed">
              <div class="columnContainer primaryTextColor">
                <div class="column">
                  <table>
                    <thead>
                      <tr style="min-height: 43px;">
                        <th class="first">{{getColumnLetter(column.index)}}</th>
                        <ng-container *ngIf="csvFileHasAHeaderRow"><th>{{column.headerColumnName}}</th></ng-container>
                        <th [attr.colspan]="csvFileHasAHeaderRow ? 1 : 2">
                          <button i18n-style="@@floatStart" style="float:left; background-color: transparent; border: none;"  *ngIf="column.property !== ''" i18n-title="@@UnSetTheSelectedDataField" title="UnSetTheSelectedDataField" (click)="column.property = ''; column.confirmed = false; verifyAllRequiredColumnsAreMapped();"><mat-icon>close</mat-icon></button>
                          <mat-select style="width: 70%; padding-top: 8px;" #dataProperty i18n-placeholder="@@Select a data property" placeholder="Select a data property" [ngModel]="column.property" (selectionChange)="column.property = dataProperty.value; verifyAllRequiredColumnsAreMapped()">
                            <mat-option  [class.selected]="property === column.property" [disabled]="isPropertyNameAvailable(property)" *ngFor="let property of propertyList" [value]="property">{{property | languageString }}</mat-option>
                          </mat-select>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let value of column.firstDataValues; index as i;">
                      <tr *ngIf="(csvFileHasAHeaderRow && i > 0) || (!csvFileHasAHeaderRow && i < 4)">
                        <th class="first"> {{ getRowNumber(i) }} </th>
                        <td colspan="2">{{value}}</td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </div>
                <div class="column">
                  <aside class="column-matched">
                    <ul>
                      <li *ngIf="!column.property"><mat-icon style="color: orange;">warning</mat-icon><ng-container i18n="@@No data field has been selected.">No data field has been selected.</ng-container></li>
                      <li *ngIf="column.property"><mat-icon style="color: #60b700;">check</mat-icon><ng-container i18n="@@Matched the">Matched the</ng-container><span class="suggested-fieldname primaryTextColor"> {{column.property | languageString }} </span><ng-container i18n="@@field.">field.</ng-container></li>
<!--                      <li *ngIf="column.property"><mat-icon style="color: #60b700;">check</mat-icon> {{getPercentOfColumnPassedVerification(column)}} % <ng-container i18n="@@of values pass validation for this field">of values pass validation for this field</ng-container></li>-->
                    </ul>
                    <div class="confirm-box">
                      <button *ngIf="column.property && column.property !== ''" mat-raised-button style="background-color: #60b700; color: white;" (click)="column.confirmed = true; verifyAllRequiredColumnsAreMapped()" i18n="@@Confirm mapping">Confirm mapping</button>
                      <button mat-raised-button style="border: 1px solid #c3c3c3; color: #9b9b9b;" (click)="column.ignored = true; column.property = ''; verifyAllRequiredColumnsAreMapped()" i18n="@@Ignore this column">Ignore this column</button>
                    </div>
                  </aside>
                </div>
              </div>
            </ng-container>
          </div>
          <h3 color="warn">{{matchColumnsErrorMessages}}</h3>
          <div class="nextPreviousButtonContainer">
            <button i18n-style="@@floatStart" style="float:left" matStepperPrevious mat-raised-button color="basic" type="button"><mat-icon>navigate_before</mat-icon><span>&nbsp;</span><ng-container i18n="@@PREVIOUS">PREVIOUS</ng-container></button>
            <button i18n-style="@@floatEnd" style="float:right" (click)="setupColumnMap(); setupCSVProductCodes();" matStepperNext mat-raised-button color="primary" type="button"><ng-container i18n="@@Next">Next</ng-container><span>&nbsp;</span><mat-icon>navigate_next</mat-icon></button>
          </div>
        </mat-step>
        <!-- Products -->
        <!-- Products -->
        <!-- Products -->
        <mat-step [completed]="productMatchesVerified" [hasError]="matchProductsErrorMessages.length > 0" [errorMessage]="matchProductsErrorMessages" [editable]="isEditable">
          <form>
            <ng-template matStepLabel i18n="@@Match Products">Match Products</ng-template>
            <div>
              <table>
                <thead>
                  <tr>
                    <th i18n="@@Product Id From CSV File">Product Id From CSV File</th>
                    <th></th>
                    <th i18n="@@Selected Product Option">Selected Product Option</th>
                    <th i18n="@@Likely Options">Likely Options</th>
                    <th i18n="@@Selection List">Selection List</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let productCode of getCSVFileProductCodes()" class="productCode">
                    <td [class.notMapped]="!productCode.product" style="text-align: right; padding: 5px;">{{productCode.csvProductCode}}</td>
                    <td>
                      <ng-container *ngIf="productCode.product">
                        <button i18n-style="@@floatStart" style="float:right; background-color: transparent; border: none;" *ngIf="productCode.product" i18n-title="@@UnSetTheSelectedProduct" title="UnSetTheSelectedProduct" (click)="productCode.setProduct(undefined); verifyAllProductsAreMapped();"><mat-icon>close</mat-icon></button>
                      </ng-container>
                    </td>
                    <td>
                      <ng-container *ngIf="productCode.product">
                        <span *ngIf="productCode.product">{{productCode.product.title}}</span>
                      </ng-container>
                    </td>
                    <td>
                      <mat-chip-listbox *ngIf="productCode && productCode.possibleMatches && productCode.possibleMatches.length > 0">
                        <mat-chip-option *ngFor="let possibleMatch of productCode.getBestPossibleMatches( 6 )" (click)="productCode.setProduct(possibleMatch.product)" [style.background-color]="getColor(possibleMatch.matches / productCode.maxPossibleMatchIntersections )">{{possibleMatch.product.title}}</mat-chip-option>
                      </mat-chip-listbox>
                    </td>
                    <td>
                      <mat-select style="width: 70%; padding-top: 8px;" #productId i18n-placeholder="@@Select a product" placeholder="Select a product" [ngModel]="productCode.product" (selectionChange)="productCode.setProduct(productId.value); verifyAllProductsAreMapped()" [ngModelOptions]="{standalone: true}">
                        <mat-option  [class.selected]="productCode.product && productCode.product === product" *ngFor="let product of products" [value]="product">{{product.title}}</mat-option>
                      </mat-select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3 color="warn">{{matchProductsErrorMessages}}</h3>
            <div class="nextPreviousButtonContainer">
              <button i18n-style="@@floatStart" style="float:left" matStepperPrevious mat-raised-button color="basic" type="button"><mat-icon>navigate_before</mat-icon><span>&nbsp;</span><ng-container i18n="@@PREVIOUS">PREVIOUS</ng-container></button>
              <button i18n-style="@@floatEnd" (click)="makeOrders();" style="float:right" matStepperNext mat-raised-button color="primary" type="button"><ng-container i18n="@@Next">Next</ng-container><span>&nbsp;</span><mat-icon>navigate_next</mat-icon></button>
            </div>
          </form>
        </mat-step>
        <!-- Verify -->
        <!-- Verify -->
        <!-- Verify -->
        <mat-step  [completed]="repairProblemsVerified" [hasError]="repairProblemsErrorMessages.length > 0" [errorMessage]="repairProblemsErrorMessages"  [editable]="isEditable">
          <ng-template matStepLabel i18n="@@Verify">Verify</ng-template>
          <div>
            <h2 *ngIf="addressStillToValidate > 0">{{addressStillToValidate}}<span>&nbsp;</span><span i18n="@@Addresses Validating - Please Wait">Addresses Validating - Please Wait</span></h2>
            <h2 *ngIf="orderDeliverTosSaving > 0">{{orderDeliverTosSaving}}<span>&nbsp;</span><span i18n="@@Orders Saving - Please Wait">Orders Saving - Please Wait</span></h2>
            <h2 *ngIf="printNodeOrdersSaving > 0">{{printNodeOrdersSaving}}<span>&nbsp;</span><span i18n="@@Order Details Saving - Please Wait">Order Details Saving - Please Wait</span></h2>
            <h2 *ngIf="orderDeliverTosRetrying > 0">{{orderDeliverTosRetrying}}<span>&nbsp;</span><span i18n="@@Orders Retrying Saving - Please Wait">Orders Retrying Saving - Please Wait</span></h2>
            <h2 *ngIf="printNodeOrdersRetrying > 0">{{printNodeOrdersRetrying}}<span>&nbsp;</span><span i18n="@@Order Details Retrying Saving - Please Wait">Order Details Retrying Saving - Please Wait</span></h2>

            <ng-container  *ngFor="let order of orders">
              <mat-card appearance="outlined" class="order" *ngIf="!order.orderDeliverTo">
                <mat-card-content>
                  <div class="order">
                    <h2 *ngIf="order.saving" i18n="@@Saving">Saving</h2>
                    <div class="orderBlock">
                      <h3 i18n="@@Ship To Address">Ship To Address</h3>
                      <div class="addressBlock">
                        <h4><span i18n="@@Original">Original</span><span *ngIf="order.addressToUse === 'ORIGINAL'" i18n="@@Using This Address">Using This Address</span></h4>
                        <span class="row">{{order.firstName}}<span>&nbsp;</span>{{order.lastName}}</span>
                        <span class="row" *ngIf="order.company"><span>&nbsp;</span>{{order.company}}</span>
                        <span class="row">{{order.address1}}<span *ngIf="order.address2"><span>&nbsp;</span>{{order.address2}}</span></span>
                        <span class="row">{{order.city}},<span>&nbsp;</span>{{order.region}}<span>&nbsp;</span>{{order.postalCode}}<span>&nbsp;</span>{{order.country}}</span>
                        <span  *ngIf="!order.edit" (click)="order.editedAddress1 = order.address1; order.editedAddress2 = order.address2; order.editedCity = order.city; order.editedPostalCode = order.postalCode; order.editedRegion = order.region; order.editedCountry = order.country; order.edit = true" i18n-title="@@Edit Address" title="Edit Address"><mat-icon>edit</mat-icon></span>
                        <span style="float:right;" color="warn" (click)="order.toggleMannualAddressValidation()" i18n-title="@@Mark Address As Invalid" title="Mark Address As Invalid"><mat-icon>report_problem</mat-icon></span>
                        <button *ngIf="order.addressToUse !== 'ORIGINAL'" mat-raised-button color="accent" (click)="order.useOriginalAddress()" i18n="@@Use This Address">Use This Address</button>

                      </div>
                      <div *ngIf="order.manualAddressValidationStatus==='INVALID'"  class="addressInvalidBlock">
                        <h4 style="color: red" i18n="@@This Address Has Been Manually Marked As Invalid and Will Be Addressed Later Using The Address Issues Interface">This Address Has Been Manually Marked As Invalid and Will Be Addressed Later Using The Address Issues Interface</h4>
                      </div>
                      <div *ngIf="order.addressToUse !=='VERIFIED'"  class="addressInvalidBlock">
                        <h4 style="color: red" i18n="@@The Address Selected To Use Has Not Been Validated And May Not Be Deliverable">The Address Selected To Use Has Not Been Validated And May Not Be Deliverable</h4>
                      </div>
                      <div *ngIf="order.edit" class="addressBlock">
                        <h4 i18n="@@Edited Address">Edited Address</h4>
                        <form>
                          <mat-form-field>
                            <textarea name="address1" [(ngModel)]="order.editedAddress1" matInput i18n-placeholder="@@Address 1" placeholder="Address 1"></textarea>
                          </mat-form-field>
                          <br>
                          <mat-form-field>
                            <textarea name="address2" [(ngModel)]="order.editedAddress2" matInput i18n-placeholder="@@Address 2" placeholder="Address 2"></textarea>
                          </mat-form-field>
                          <br>
                          <mat-form-field>
                            <input name="city" [(ngModel)]="order.editedCity" matInput  i18n-placeholder="@@City" placeholder="City">
                          </mat-form-field>
                          <mat-form-field>
                            <input name="state" [(ngModel)]="order.editedRegion" matInput  i18n-placeholder="@@State" placeholder="State">
                          </mat-form-field>
                          <br>
                          <mat-form-field>
                            <mat-select name="country" [(ngModel)]="order.editedCountry"  i18n-placeholder="@@Country" placeholder="Country" id="country"  autocomplete="country">
                              <mat-option>None</mat-option>
                              <mat-option value="AF" i18n='@@Afghanistan'>Afghanistan</mat-option>
                              <mat-option value="AL" i18n='@@Albania'>Albania</mat-option>
                              <mat-option value="DZ" i18n='@@Algeria'>Algeria</mat-option>
                              <mat-option value="AS" i18n='@@American Samoa'>American Samoa</mat-option>
                              <mat-option value="AD" i18n='@@Andorra'>Andorra</mat-option>
                              <mat-option value="AG" i18n='@@Angola'>Angola</mat-option>
                              <mat-option value="AI" i18n='@@Anguilla'>Anguilla</mat-option>
                              <mat-option value="AG" i18n='@@Antigua &amp; Barbuda'>Antigua &amp; Barbuda</mat-option>
                              <mat-option value="AR" i18n='@@Argentina'>Argentina</mat-option>
                              <mat-option value="AA" i18n='@@Armenia'>Armenia</mat-option>
                              <mat-option value="AW" i18n='@@Aruba'>Aruba</mat-option>
                              <mat-option value="AU" i18n='@@Australia'>Australia</mat-option>
                              <mat-option value="AT" i18n='@@Austria'>Austria</mat-option>
                              <mat-option value="AZ" i18n='@@Azerbaijan'>Azerbaijan</mat-option>
                              <mat-option value="BS" i18n='@@Bahamas'>Bahamas</mat-option>
                              <mat-option value="BH" i18n='@@Bahrain'>Bahrain</mat-option>
                              <mat-option value="BD" i18n='@@Bangladesh'>Bangladesh</mat-option>
                              <mat-option value="BB" i18n='@@Barbados'>Barbados</mat-option>
                              <mat-option value="BY" i18n='@@Belarus'>Belarus</mat-option>
                              <mat-option value="BE" i18n='@@Belgium'>Belgium</mat-option>
                              <mat-option value="BZ" i18n='@@Belize'>Belize</mat-option>
                              <mat-option value="BJ" i18n='@@Benin'>Benin</mat-option>
                              <mat-option value="BM" i18n='@@Bermuda'>Bermuda</mat-option>
                              <mat-option value="BT" i18n='@@Bhutan'>Bhutan</mat-option>
                              <mat-option value="BO" i18n='@@Bolivia'>Bolivia</mat-option>
                              <mat-option value="BL" i18n='@@Bonaire'>Bonaire</mat-option>
                              <mat-option value="BA" i18n='@@Bosnia &amp; Herzegovina'>Bosnia &amp; Herzegovina</mat-option>
                              <mat-option value="BW" i18n='@@Botswana'>Botswana</mat-option>
                              <mat-option value="BR" i18n='@@Brazil'>Brazil</mat-option>
                              <mat-option value="BC" i18n='@@British Indian Ocean Ter'>British Indian Ocean Ter</mat-option>
                              <mat-option value="BN" i18n='@@Brunei'>Brunei</mat-option>
                              <mat-option value="BG" i18n='@@Bulgaria'>Bulgaria</mat-option>
                              <mat-option value="BF" i18n='@@Burkina Faso'>Burkina Faso</mat-option>
                              <mat-option value="BI" i18n='@@Burundi'>Burundi</mat-option>
                              <mat-option value="KH" i18n='@@Cambodia'>Cambodia</mat-option>
                              <mat-option value="CM" i18n='@@Cameroon'>Cameroon</mat-option>
                              <mat-option value="CA" i18n='@@Canada'>Canada</mat-option>
                              <mat-option value="IC" i18n='@@Canary Islands'>Canary Islands</mat-option>
                              <mat-option value="CV" i18n='@@Cape Verde'>Cape Verde</mat-option>
                              <mat-option value="KY" i18n='@@Cayman Islands'>Cayman Islands</mat-option>
                              <mat-option value="CF" i18n='@@Central African Republic'>Central African Republic</mat-option>
                              <mat-option value="TD" i18n='@@Chad'>Chad</mat-option>
                              <mat-option value="CD" i18n='@@Channel Islands'>Channel Islands</mat-option>
                              <mat-option value="CL" i18n='@@Chile'>Chile</mat-option>
                              <mat-option value="CN" i18n='@@China'>China</mat-option>
                              <mat-option value="CI" i18n='@@Christmas Island'>Christmas Island</mat-option>
                              <mat-option value="CS" i18n='@@Cocos Island'>Cocos Island</mat-option>
                              <mat-option value="CO" i18n='@@Colombia'>Colombia</mat-option>
                              <mat-option value="CC" i18n='@@Comoros'>Comoros</mat-option>
                              <mat-option value="CG" i18n='@@Congo'>Congo</mat-option>
                              <mat-option value="CK" i18n='@@Cook Islands'>Cook Islands</mat-option>
                              <mat-option value="CR" i18n='@@Costa Rica'>Costa Rica</mat-option>
                              <mat-option value="CT" i18n='@@Cote DIvoire'>Cote D'Ivoire</mat-option>
                              <mat-option value="HR" i18n='@@Croatia'>Croatia</mat-option>
                              <mat-option value="CU" i18n='@@Cuba'>Cuba</mat-option>
                              <mat-option value="CB" i18n='@@Curacao'>Curacao</mat-option>
                              <mat-option value="CY" i18n='@@Cyprus'>Cyprus</mat-option>
                              <mat-option value="CZ" i18n='@@Czech Republic'>Czech Republic</mat-option>
                              <mat-option value="DK" i18n='@@Denmark'>Denmark</mat-option>
                              <mat-option value="DJ" i18n='@@Djibouti'>Djibouti</mat-option>
                              <mat-option value="DM" i18n='@@Dominica'>Dominica</mat-option>
                              <mat-option value="DO" i18n='@@Dominican Republic'>Dominican Republic</mat-option>
                              <mat-option value="TM" i18n='@@East Timor'>East Timor</mat-option>
                              <mat-option value="EC" i18n='@@Ecuador'>Ecuador</mat-option>
                              <mat-option value="EG" i18n='@@Egypt'>Egypt</mat-option>
                              <mat-option value="SV" i18n='@@El Salvador'>El Salvador</mat-option>
                              <mat-option value="GQ" i18n='@@Equatorial Guinea'>Equatorial Guinea</mat-option>
                              <mat-option value="ER" i18n='@@Eritrea'>Eritrea</mat-option>
                              <mat-option value="EE" i18n='@@Estonia'>Estonia</mat-option>
                              <mat-option value="ET" i18n='@@Ethiopia'>Ethiopia</mat-option>
                              <mat-option value="FA" i18n='@@Falkland Islands'>Falkland Islands</mat-option>
                              <mat-option value="FO" i18n='@@Faroe Islands'>Faroe Islands</mat-option>
                              <mat-option value="FJ" i18n='@@Fiji'>Fiji</mat-option>
                              <mat-option value="FI" i18n='@@Finland'>Finland</mat-option>
                              <mat-option value="FR" i18n='@@France'>France</mat-option>
                              <mat-option value="GF" i18n='@@French Guiana'>French Guiana</mat-option>
                              <mat-option value="PF" i18n='@@French Polynesia'>French Polynesia</mat-option>
                              <mat-option value="FS" i18n='@@French Southern Ter'>French Southern Ter</mat-option>
                              <mat-option value="GA" i18n='@@Gabon'>Gabon</mat-option>
                              <mat-option value="GM" i18n='@@Gambia'>Gambia</mat-option>
                              <mat-option value="GE" i18n='@@Georgia'>Georgia</mat-option>
                              <mat-option value="DE" i18n='@@Germany'>Germany</mat-option>
                              <mat-option value="GH" i18n='@@Ghana'>Ghana</mat-option>
                              <mat-option value="GI" i18n='@@Gibraltar'>Gibraltar</mat-option>
                              <mat-option value="GB" i18n='@@Great Britain'>Great Britain</mat-option>
                              <mat-option value="GR" i18n='@@Greece'>Greece</mat-option>
                              <mat-option value="GL" i18n='@@Greenland'>Greenland</mat-option>
                              <mat-option value="GD" i18n='@@Grenada'>Grenada</mat-option>
                              <mat-option value="GP" i18n='@@Guadeloupe'>Guadeloupe</mat-option>
                              <mat-option value="GU" i18n='@@Guam'>Guam</mat-option>
                              <mat-option value="GT" i18n='@@Guatemala'>Guatemala</mat-option>
                              <mat-option value="GN" i18n='@@Guinea'>Guinea</mat-option>
                              <mat-option value="GY" i18n='@@Guyana'>Guyana</mat-option>
                              <mat-option value="HT" i18n='@@Haiti'>Haiti</mat-option>
                              <mat-option value="HW" i18n='@@Hawaii'>Hawaii</mat-option>
                              <mat-option value="HN" i18n='@@Honduras'>Honduras</mat-option>
                              <mat-option value="HK" i18n='@@Hong Kong'>Hong Kong</mat-option>
                              <mat-option value="HU" i18n='@@Hungary'>Hungary</mat-option>
                              <mat-option value="IS" i18n='@@Iceland'>Iceland</mat-option>
                              <mat-option value="IN" i18n='@@India'>India</mat-option>
                              <mat-option value="ID" i18n='@@Indonesia'>Indonesia</mat-option>
                              <mat-option value="IR" i18n='@@Iran'>Iran</mat-option>
                              <mat-option value="IQ" i18n='@@Iraq'>Iraq</mat-option>
                              <mat-option value="IE" i18n='@@Ireland'>Ireland</mat-option>
                              <mat-option value="IM" i18n='@@Isle of Man'>Isle of Man</mat-option>
                              <mat-option value="IL" i18n='@@Israel'>Israel</mat-option>
                              <mat-option value="IT" i18n='@@Italy'>Italy</mat-option>
                              <mat-option value="JM" i18n='@@Jamaica'>Jamaica</mat-option>
                              <mat-option value="JP" i18n='@@Japan'>Japan</mat-option>
                              <mat-option value="JO" i18n='@@Jordan'>Jordan</mat-option>
                              <mat-option value="KZ" i18n='@@Kazakhstan'>Kazakhstan</mat-option>
                              <mat-option value="KE" i18n='@@Kenya'>Kenya</mat-option>
                              <mat-option value="KI" i18n='@@Kiribati'>Kiribati</mat-option>
                              <mat-option value="NK" i18n='@@Korea North'>Korea North</mat-option>
                              <mat-option value="KS" i18n='@@Korea South'>Korea South</mat-option>
                              <mat-option value="KW" i18n='@@Kuwait'>Kuwait</mat-option>
                              <mat-option value="KG" i18n='@@Kyrgyzstan'>Kyrgyzstan</mat-option>
                              <mat-option value="LA" i18n='@@Laos'>Laos</mat-option>
                              <mat-option value="LV" i18n='@@Latvia'>Latvia</mat-option>
                              <mat-option value="LB" i18n='@@Lebanon'>Lebanon</mat-option>
                              <mat-option value="LS" i18n='@@Lesotho'>Lesotho</mat-option>
                              <mat-option value="LR" i18n='@@Liberia'>Liberia</mat-option>
                              <mat-option value="LY" i18n='@@Libya'>Libya</mat-option>
                              <mat-option value="LI" i18n='@@Liechtenstein'>Liechtenstein</mat-option>
                              <mat-option value="LT" i18n='@@Lithuania'>Lithuania</mat-option>
                              <mat-option value="LU" i18n='@@Luxembourg'>Luxembourg</mat-option>
                              <mat-option value="MO" i18n='@@Macau'>Macau</mat-option>
                              <mat-option value="MK" i18n='@@Macedonia'>Macedonia</mat-option>
                              <mat-option value="MG" i18n='@@Madagascar'>Madagascar</mat-option>
                              <mat-option value="MY" i18n='@@Malaysia'>Malaysia</mat-option>
                              <mat-option value="MW" i18n='@@Malawi'>Malawi</mat-option>
                              <mat-option value="MV" i18n='@@Maldives'>Maldives</mat-option>
                              <mat-option value="ML" i18n='@@Mali'>Mali</mat-option>
                              <mat-option value="MT" i18n='@@Malta'>Malta</mat-option>
                              <mat-option value="MH" i18n='@@Marshall Islands'>Marshall Islands</mat-option>
                              <mat-option value="MQ" i18n='@@Martinique'>Martinique</mat-option>
                              <mat-option value="MR" i18n='@@Mauritania'>Mauritania</mat-option>
                              <mat-option value="MU" i18n='@@Mauritius'>Mauritius</mat-option>
                              <mat-option value="ME" i18n='@@Mayotte'>Mayotte</mat-option>
                              <mat-option value="MX" i18n='@@Mexico'>Mexico</mat-option>
                              <mat-option value="MI" i18n='@@Midway Islands'>Midway Islands</mat-option>
                              <mat-option value="MD" i18n='@@Moldova'>Moldova</mat-option>
                              <mat-option value="MC" i18n='@@Monaco'>Monaco</mat-option>
                              <mat-option value="MN" i18n='@@Mongolia'>Mongolia</mat-option>
                              <mat-option value="MS" i18n='@@Montserrat'>Montserrat</mat-option>
                              <mat-option value="MA" i18n='@@Morocco'>Morocco</mat-option>
                              <mat-option value="MZ" i18n='@@Mozambique'>Mozambique</mat-option>
                              <mat-option value="MM" i18n='@@Myanmar'>Myanmar</mat-option>
                              <mat-option value="NA" i18n='@@Nambia'>Nambia</mat-option>
                              <mat-option value="NU" i18n='@@Nauru'>Nauru</mat-option>
                              <mat-option value="NP" i18n='@@Nepal'>Nepal</mat-option>
                              <mat-option value="AN" i18n='@@Netherland Antilles'>Netherland Antilles</mat-option>
                              <mat-option value="NL" i18n='@@Netherlands (Holland, Europe)'>Netherlands (Holland, Europe)</mat-option>
                              <mat-option value="NV" i18n='@@Nevis'>Nevis</mat-option>
                              <mat-option value="NC" i18n='@@New Caledonia'>New Caledonia</mat-option>
                              <mat-option value="NZ" i18n='@@New Zealand'>New Zealand</mat-option>
                              <mat-option value="NI" i18n='@@Nicaragua'>Nicaragua</mat-option>
                              <mat-option value="NE" i18n='@@Niger'>Niger</mat-option>
                              <mat-option value="NG" i18n='@@Nigeria'>Nigeria</mat-option>
                              <mat-option value="NW" i18n='@@Niue'>Niue</mat-option>
                              <mat-option value="NF" i18n='@@Norfolk Island'>Norfolk Island</mat-option>
                              <mat-option value="NO" i18n='@@Norway'>Norway</mat-option>
                              <mat-option value="OM" i18n='@@Oman'>Oman</mat-option>
                              <mat-option value="PK" i18n='@@Pakistan'>Pakistan</mat-option>
                              <mat-option value="PW" i18n='@@Palau Island'>Palau Island</mat-option>
                              <mat-option value="PS" i18n='@@Palestine'>Palestine</mat-option>
                              <mat-option value="PA" i18n='@@Panama'>Panama</mat-option>
                              <mat-option value="PG" i18n='@@Papua New Guinea'>Papua New Guinea</mat-option>
                              <mat-option value="PY" i18n='@@Paraguay'>Paraguay</mat-option>
                              <mat-option value="PE" i18n='@@Peru'>Peru</mat-option>
                              <mat-option value="PH" i18n='@@Philippines'>Philippines</mat-option>
                              <mat-option value="PO" i18n='@@Pitcairn Island'>Pitcairn Island</mat-option>
                              <mat-option value="PL" i18n='@@Poland'>Poland</mat-option>
                              <mat-option value="PT" i18n='@@Portugal'>Portugal</mat-option>
                              <!--<mat-option value="PR" i18n='@@Puerto Rico'>Puerto Rico</mat-option> -->
                              <mat-option value="QA" i18n='@@Qatar'>Qatar</mat-option>
                              <mat-option value="ME" i18n='@@Republic of Montenegro'>Republic of Montenegro</mat-option>
                              <mat-option value="RS" i18n='@@Republic of Serbia'>Republic of Serbia</mat-option>
                              <mat-option value="RE" i18n='@@Reunion'>Reunion</mat-option>
                              <mat-option value="RO" i18n='@@Romania'>Romania</mat-option>
                              <mat-option value="RU" i18n='@@Russia'>Russia</mat-option>
                              <mat-option value="RW" i18n='@@Rwanda'>Rwanda</mat-option>
                              <mat-option value="NT" i18n='@@St Barthelemy'>St Barthelemy</mat-option>
                              <mat-option value="EU" i18n='@@St Eustatius'>St Eustatius</mat-option>
                              <mat-option value="HE" i18n='@@St Helena'>St Helena</mat-option>
                              <mat-option value="KN" i18n='@@St Kitts-Nevis'>St Kitts-Nevis</mat-option>
                              <mat-option value="LC" i18n='@@St Lucia'>St Lucia</mat-option>
                              <mat-option value="MB" i18n='@@St Maarten'>St Maarten</mat-option>
                              <mat-option value="PM" i18n='@@St Pierre &amp; Miquelon'>St Pierre &amp; Miquelon</mat-option>
                              <mat-option value="VC" i18n='@@St Vincent &amp; Grenadines'>St Vincent &amp; Grenadines</mat-option>
                              <mat-option value="SP" i18n='@@Saipan'>Saipan</mat-option>
                              <mat-option value="SO" i18n='@@Samoa'>Samoa</mat-option>
                              <mat-option value="AS" i18n='@@Samoa American'>Samoa American</mat-option>
                              <mat-option value="SM" i18n='@@San Marino'>San Marino</mat-option>
                              <mat-option value="ST" i18n='@@Sao Tome &amp; Principe'>Sao Tome &amp; Principe</mat-option>
                              <mat-option value="SA" i18n='@@Saudi Arabia'>Saudi Arabia</mat-option>
                              <mat-option value="SN" i18n='@@Senegal'>Senegal</mat-option>
                              <mat-option value="SC" i18n='@@Seychelles'>Seychelles</mat-option>
                              <mat-option value="SL" i18n='@@Sierra Leone'>Sierra Leone</mat-option>
                              <mat-option value="SG" i18n='@@Singapore'>Singapore</mat-option>
                              <mat-option value="SK" i18n='@@Slovakia'>Slovakia</mat-option>
                              <mat-option value="SI" i18n='@@Slovenia'>Slovenia</mat-option>
                              <mat-option value="SB" i18n='@@Solomon Islands'>Solomon Islands</mat-option>
                              <mat-option value="OI" i18n='@@Somalia'>Somalia</mat-option>
                              <mat-option value="ZA" i18n='@@South Africa'>South Africa</mat-option>
                              <mat-option value="ES" i18n='@@Spain'>Spain</mat-option>
                              <mat-option value="LK" i18n='@@Sri Lanka'>Sri Lanka</mat-option>
                              <mat-option value="SD" i18n='@@Sudan'>Sudan</mat-option>
                              <mat-option value="SR" i18n='@@Suriname'>Suriname</mat-option>
                              <mat-option value="SZ" i18n='@@Swaziland'>Swaziland</mat-option>
                              <mat-option value="SE" i18n='@@Sweden'>Sweden</mat-option>
                              <mat-option value="CH" i18n='@@Switzerland'>Switzerland</mat-option>
                              <mat-option value="SY" i18n='@@Syria'>Syria</mat-option>
                              <mat-option value="TW" i18n='@@Taiwan'>Taiwan</mat-option>
                              <mat-option value="TJ" i18n='@@Tajikistan'>Tajikistan</mat-option>
                              <mat-option value="TZ" i18n='@@Tanzania'>Tanzania</mat-option>
                              <mat-option value="TH" i18n='@@Thailand'>Thailand</mat-option>
                              <mat-option value="TG" i18n='@@Togo'>Togo</mat-option>
                              <mat-option value="TK" i18n='@@Tokelau'>Tokelau</mat-option>
                              <mat-option value="TO" i18n='@@Tonga'>Tonga</mat-option>
                              <mat-option value="TT" i18n='@@Trinidad &amp; Tobago'>Trinidad &amp; Tobago</mat-option>
                              <mat-option value="TN" i18n='@@Tunisia'>Tunisia</mat-option>
                              <mat-option value="TR" i18n='@@Turkey'>Turkey</mat-option>
                              <mat-option value="TU" i18n='@@Turkmenistan'>Turkmenistan</mat-option>
                              <mat-option value="TC" i18n='@@Turks &amp; Caicos Is'>Turks &amp; Caicos Is</mat-option>
                              <mat-option value="TV" i18n='@@Tuvalu'>Tuvalu</mat-option>
                              <mat-option value="UG" i18n='@@Uganda'>Uganda</mat-option>
                              <mat-option value="UA" i18n='@@Ukraine'>Ukraine</mat-option>
                              <mat-option value="AE" i18n='@@United Arab Emirates'>United Arab Emirates</mat-option>
                              <mat-option value="GB" i18n='@@United Kingdom'>United Kingdom</mat-option>
                              <mat-option value="USA" selected="selected" i18n='@@United States'>United States</mat-option>
                              <mat-option value="UY" i18n='@@Uruguay'>Uruguay</mat-option>
                              <mat-option value="UZ" i18n='@@Uzbekistan'>Uzbekistan</mat-option>
                              <mat-option value="VU" i18n='@@Vanuatu'>Vanuatu</mat-option>
                              <mat-option value="VS" i18n='@@Vatican City State'>Vatican City State</mat-option>
                              <mat-option value="VE" i18n='@@Venezuela'>Venezuela</mat-option>
                              <mat-option value="VN" i18n='@@Vietnam'>Vietnam</mat-option>
                              <mat-option value="VB" i18n='@@Virgin Islands (Brit)'>Virgin Islands (Brit)</mat-option>
                              <mat-option value="VI" i18n='@@Virgin Islands (USA)'>Virgin Islands (USA)</mat-option>
                              <mat-option value="WK" i18n='@@Wake Island'>Wake Island</mat-option>
                              <mat-option value="WF" i18n='@@Wallis &amp; Futana Is'>Wallis &amp; Futana Is</mat-option>
                              <mat-option value="YE" i18n='@@Yemen'>Yemen</mat-option>
                              <mat-option value="ZR" i18n='@@Zaire'>Zaire</mat-option>
                              <mat-option value="ZM" i18n='@@Zambia'>Zambia</mat-option>
                              <mat-option value="ZW" i18n='@@Zimbabwe'>Zimbabwe</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field>
                            <input name="postalcode" [(ngModel)]="order.editedPostalCode" matInput  i18n-placeholder="@@Zip" placeholder="Zip">
                          </mat-form-field>
                        </form>
<!--                        <span class="row">{{order.editedAddress1}}<span *ngIf="order.editedAddress2"><span>&nbsp;</span>{{order.editedAddress2}}</span></span>-->
<!--                        <span class="row">{{order.editedCity}},<span>&nbsp;</span>{{order.editedRegion}}<span>&nbsp;</span>{{order.editedPostalCode}}<span>&nbsp;</span>{{order.editedCountry}}</span>-->
                        <button mat-raised-button color="accent" (click)="validateEditedAddress(order); order.edit = true;" i18n="@@Validate Edited Address">Validate Edited Address</button>
                        <button mat-raised-button color="warn" (click)="order.useEditedAddress()" i18n="@@Use Edited Address Without Validation">Use Edited Address Without Validation</button>
                      </div>
                      <div *ngIf="order.addressValidationResult">
                        <div *ngIf="order.verifiedAddress1" class="addressBlock" [class]="order.getAddressValidationStatus() === 'VALID' ? ( order.manualAddressValidationStatus === 'INVALID' ? 'invalidbyuser' : ( order.verifiedAddressSetByUser ? 'setbyuser' : 'valid' ) ) : ''">
                          <h4><span i18n="@@Recommended">Recommended</span><span *ngIf="order.addressToUse === 'VERIFIED'" i18n="@@Using This Address">Using This Address</span></h4>
                          <span class="row" [class]="order.address1 !== order.verifiedAddress1 ? 'change': 'same'" *ngIf="order.verifiedAddress1">{{order.verifiedAddress1}}<span [class]="order.address2 !== order.verifiedAddress2 ? 'change': 'same'" *ngIf="order.verifiedAddress2">&nbsp;{{order.verifiedAddress2}}</span></span>
                          <span class="row"><span [class]="order.city !== order.verifiedCity ? 'change': 'same'">{{order.verifiedCity}}</span><span>,&nbsp;</span><span [class]="order.region !== order.verifiedRegion ? 'change': 'same'">{{order.verifiedRegion}}</span><span>&nbsp;</span><span [class]="order.postalCode !== order.verifiedPostalCode ? 'change': 'same'">{{order.verifiedPostalCode}}</span><span>&nbsp;</span><span [class]="order.country !== order.verifiedCountry ? 'change': 'same'">{{order.verifiedCountry}}</span></span>
                          <button *ngIf="order.addressToUse !== 'VERIFIED'" mat-raised-button color="accent" (click)="order.useVerifiedAddress()" i18n="@@Use This Address">Use This Address</button>
                        </div>
                        <ng-container *ngIf="order.addressToUse === 'EDITED' ">
                          <h4><span i18n="@@Edited Address">Edited Address</span><span *ngIf="order.addressToUse === 'EDITED'" i18n="@@Using This Address">Using This Address</span></h4>
                          <div class="addressBlock">
                            <span class="row"><span [class.different]="order.editedAddress1 !== order.address1">{{order.editedAddress1}}</span><span [class.different]="order.editedAddress2 !== order.address2">&nbsp;{{order.editedAddress2}}</span></span>
                            <span class="row"><span [class.different]="order.editedCity !== order.city">{{order.editedCity}}</span>,<span>&nbsp;</span><span [class.different]="order.editedRegion !== order.region">{{order.editedRegion}}</span><span>&nbsp;</span><span [class.different]="order.editedPostalCode !== order.postalCode">{{order.editedPostalCode}}</span><span>&nbsp;</span><span [class.different]="order.editedCountry !== order.country">{{order.editedCountry}}</span></span>
                          </div>
                        </ng-container>
                        <ng-container>
                          <ng-container [ngSwitch]="order.addressValidationResult.validationResult">
                            <ng-container *ngSwitchCase="'Success'">
                              <h3 i18n="@@Suggestion(s)">Suggestion(s)</h3>
                              <div *ngFor="let address of order.addressValidationResult.recommendedAddresses"   class="addressBlock">
                                <span class="row"><span [class.different]="address.address1 !== order.address1">{{address.address1}}</span><span [class.different]="address.address2 !== order.address2">&nbsp;{{address.address2}}</span></span>
                                <span class="row"><span [class.different]="address.city !== order.city">{{address.city}}</span>,<span>&nbsp;</span><span [class.different]="address.state !== order.region">{{address.state}}</span><span>&nbsp;</span><span [class.different]="address.postalcode !== order.postalCode">{{address.postalcode}}</span><span>&nbsp;</span><span [class.different]="address.country !== order.country">{{address.country}}</span></span>
                                <button *ngIf="!order.doesAddressHaveARangeCharacter()" mat-raised-button color="accent" (click)="order.setVerifiedAddressOption(address, true)" i18n="@@Use This Address">Use This Address</button>
                              </div>
                            </ng-container>
                            <div *ngSwitchCase="'NO_COUNTRY_MATCH'" i18n="@@No Country Match">No Country Match</div>
                            <div *ngSwitchCase="'EXACT_MATCH'">
                              <h3 i18n="@@Exact Match">Exact Match</h3>
                              <button *ngIf="order.addressValidationResult.recommendedAddresses && order.addressValidationResult.recommendedAddresses.length > 0" mat-raised-button color="accent" (click)="order.setVerifiedAddressOption(order.addressValidationResult.recommendedAddresses[0], true)" i18n="@@Use This Address">Use This Address</button>
                            </div>
                            <div *ngSwitchCase="'NO_MEANINGFUL_SUGGESTIONS'" i18n="@@No Meaningful Suggestions">No Meaningful Suggestions</div>
                            <div *ngSwitchCase="'HAVE_SUGGESTIONS'" i18n="@@Suggested Address Alternatives">Suggested Address Alternatives</div>
                            <div *ngSwitchCase="'ERROR'" i18n="@@Error">Error</div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                    <div class="orderBlock">
                      <h3 i18n="@@Order Details">Order Details</h3>
                      <table>
                        <thead>
                        <tr>
                          <th i18n="@@Product Id">Product Id</th>
                          <th i18n="@@Title">Title</th>
                          <th i18n="@@Quantity">Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of order.items">
                          <td>{{item.id}}</td>
                          <td>{{item.id}}</td>
                          <td>{{item.quantity}}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </ng-container>
<!--            <button mat-icon-button (click)="validateAddresses()" title="Reverify Addresses" i18n-title="@@Reverify Addresses"><mat-icon>verified</mat-icon></button>-->
<!--            <button mat-icon-button (click)="createOrder()" title="Save Orders" i18n-title="@@Save Orders"><mat-icon>flight_takeoff</mat-icon></button>-->
          </div>
          <div style="width: 100%;">
            <div style="margin-left: auto; margin-right: auto">
              <button style="width: 240px; height: 200px;" *ngIf="!makingOrder" mat-raised-button color="accent" (click)="createOrder()" title="Save Orders" i18n-title="@@Save Orders"><span i18n="@@Save Orders">Save Orders</span></button>
              <button style="width: 240px; height: 200px;"   mat-raised-button color="accent" (click)="saveCurrentSettings()" title="Save Current Settings" i18n-title="@@Save Current Settings">Save Current Settings</button>
            </div>
          </div>
          <div *ngIf="areAllSimpleOrdersSubmitted()">
            <a [routerLink]="['/secure/orders']"><ng-container i18n="@@Order Submitted Successfully. The OrderId For This Order Is ">Order Submitted Successfully. The OrderId For This Order Is </ng-container><span> </span><ng-container *ngIf="createdSummaryOrder && createdSummaryOrder.ID"> {{createdSummaryOrder.ID}}</ng-container>.</a>
            <a [routerLink]="['/secure/orders']"><ng-container i18n="@@Go to Orders to review order information.">Go to Orders to review order information.</ng-container></a>
          </div>
          <h3 color="warn">{{repairProblemsErrorMessages}}</h3>
          <div class="nextPreviousButtonContainer">
            <button i18n-style="@@floatStart" style="float:left" matStepperPrevious mat-raised-button color="basic" type="button"><mat-icon>navigate_before</mat-icon><span>&nbsp;</span><ng-container i18n="@@PREVIOUS">PREVIOUS</ng-container></button>
            <!--            <button i18n-style="@@floatEnd" style="float:right" matStepperNext mat-raised-button color="primary"><ng-container i18n="@@Next">Next</ng-container><span>&nbsp;</span><mat-icon>navigate_next</mat-icon></button>-->
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </mat-card-content>
</mat-card>
