<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Inventory Products">Inventory Products</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Inventory">Inventory</span>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
    <ng-container *ngIf="organizations && organizations.length > 1">
      <div style="min-width: 100px;">
        <mat-select placeholder="Organization" i18n-placeholder="@@Organization" (selectionChange)="selectedOrganization=$event.value" required [ngModel]="selectedOrganization" style="display: inline">
          <mat-option value="0" i18n="@@All Organizations">All Organizations</mat-option>
          <ng-container *ngFor="let organization of organizations">
            <!--            <mat-option [value]="organization.ID">{{organization.ID}} {{organization.name}}</mat-option>-->
            <mat-option [value]="organization">{{organization}}</mat-option>
          </ng-container>
        </mat-select>
<!--        <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
      </div>
    </ng-container>
    <span class="app-toolbar-filler"></span>
<!--    <div *ngIf="startupService.selectedRole === 'facility'">-->
<!--      <button mat-fab color="primary" (click)="openAddDialog()" i18n-matTooltip="@@Add Facility Inventory Product" matTooltip="Add Facility Inventory Product"><i class="material-icons">add</i></button>-->
<!--    </div>-->
<!--    <div>-->
<!--      <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
<!--    </div>-->
    <span class="app-toolbar-filler"></span>
    <button (click)="displayToggle = !displayToggle; refresh()" mat-icon-button color="primary"><mat-icon *ngIf="!displayToggle" i18n-title="@@Show Blocks" title="Show Blocks" color="accent">format_list_bulleted</mat-icon><mat-icon *ngIf="displayToggle" i18n-title="@@Show Table" title="Show Table" style="color: yellow">format_list_bulleted</mat-icon></button>
<!--    <button style="width: 120px;" mat-icon-button (click)="showCharts = !showCharts" i18n-matTooltip="@@Shipped Status" matTooltip="Shipped Status"><mat-icon *ngIf="showCharts">indeterminate_check_box</mat-icon><mat-icon *ngIf="!showCharts">check_box</mat-icon></button>-->
<!--    <button style="width: 120px;" mat-icon-button (click)="edit = !edit" i18n-matTooltip="@@Shipped Status" matTooltip="Toggle Edit"><mat-icon *ngIf="edit">edit</mat-icon><mat-icon *ngIf="!edit">edit</mat-icon></button>-->
<!--    <div *ngIf="products && products.length > 0 ">-->
      <button mat-fab color="primary" (click)="openAddDialog()" i18n-matTooltip="@@Add Inventory Product" matTooltip="Add Inventory Product"><i class="material-icons">add</i></button>
<!--    </div>-->
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="facilityinventory"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <ng-container *ngIf="displayToggle">
        <div class="app-content" style="display: flex; flex-wrap: wrap;">
          <ng-container *ngFor="let facililtyInventoryProduct of facilityInventoryProducts">
            <app-facility-inventory-product *ngIf="selectedOrganization === '0' || facililtyInventoryProduct.product.org_ID === selectedOrganization" [facilityInventoryProduct]="facililtyInventoryProduct" style="max-width: 800px" [showCharts]="showCharts" [edit]="edit" [products]="products"></app-facility-inventory-product>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!displayToggle">
        <div class="app-content app-table-content">
          <app-table *ngIf="columns && eventHandler && renderedData" [columns]="columns" [eventHandler]="eventHandler" [data]="renderedData" [expandedTableRowTemplate]="expandedTableRowTemplate" [dataServiceStatus]="'LOADED'" [showFilters]="true">
            <ng-template #expandedTableRowTemplate let-element>
              <app-facility-inventory-product *ngIf="selectedOrganization === '0' || element.product.org_ID === selectedOrganization" [facilityInventoryProduct]="element" style="max-width: 800px" [showCharts]="showCharts" [edit]="edit" [products]="products"></app-facility-inventory-product>
            </ng-template>
          </app-table>
        </div>
      </ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






