<div class="draftThumbnail">
  <div>
    <app-project-book-cover-image *ngIf="project.primaryBook" [book]="project.primaryBook"></app-project-book-cover-image>
    <app-project-draft-cover-image *ngIf="project.primaryDraft" [draft]="project.primaryDraft"></app-project-draft-cover-image>
    <!--    <img #draftCoverImage src=""  style="display: block; width: 436px; height: 304px; left: -230px; top: -1px; object-fit: cover; position: absolute; max-height: unset; min-height: unset; min-width: unset; max-width: unset;" class="image" i18n-alt="" alt="">-->
  </div>
  <div class="hoveringDisplayCommands">
    <button mat-raised-button style="width:100%" color="primary" type="button" (click)="viewProject(project, 'PROJECT')" title="EditProjectToolTip" i18n-title="@@EditProjectToolTip"><mat-icon>edit</mat-icon><span> </span><ng-container i18n="@@Edit Project">Edit Project</ng-container></button>
    <button *ngIf="project.books" mat-raised-button style="width:100%" color="primary" type="button" (click)="viewProject(project, 'BOOK')" title="EditPrimaryBookToolTip" i18n-title="@@EditPrimaryBookToolTip"><mat-icon>edit</mat-icon><span> </span><ng-container i18n="@@Edit Book">Edit Book</ng-container></button>
    <button *ngIf="project.drafts" mat-raised-button style="width:100%" color="primary" type="button" (click)="viewProject(project, 'DRAFT')" title="EditPrimaryDraftToolTip" i18n-title="@@EditPrimaryDraftToolTip"><mat-icon>edit</mat-icon><span> </span><ng-container i18n="@@Edit Draft">Edit Draft</ng-container></button>
    <button mat-raised-button style="width:100%" color="primary" type="button" (click)="addToCart(project)" title="AddToCartToolTip" i18n-title="@@AddToCartToolTip"><ng-container i18n="@@Add to Cart">Add to Cart</ng-container></button>
  </div>
  <div class="draftThumbnailBottom1" >
    <h5 style="font-size: 0.4em; margin-top: 3px; margin-bottom: 3px;"><span i18n="@@Last Saved">Last Saved</span> {{project.ID}}</h5>
    <h5 style="font-size: 0.7em; margin-top: -6px; margin-bottom: 3px;">
      <span *ngIf="project.primaryDraft" style="font-size: 0.7em; margin-top: -6px; margin-bottom: 3px;">{{project.primaryDraft.lastUpdate | date: 'MM/dd/yy hh:mm:ss'}}</span>
      <span *ngIf="project.primaryBook" style="font-size: 0.7em; margin-top: -6px; margin-bottom: 3px;">{{project.primaryBook.lastUpdate | date: 'MM/dd/yy hh:mm:ss'}}</span>
    </h5>
    <h5 style="font-size: 1.5em; margin-top: -6px; margin-bottom: 3px;">{{project.drafts && project.drafts.length ? project.drafts.length : "-"}} {{project.books && project.books.length ? project.books.length : "-"}}</h5>
    <div style="position: absolute; bottom: 0px; left: 5px; width: calc(100% - 10px)">
      <span *ngIf="project.name">{{ (project.name.length>60)? (project.name | slice:0:60)+'..':(project.name) }}</span>
      <span *ngIf="!project.name">{{ 'No Title Set' | languageString }}</span>
      <span i18n-style="@@floatEnd" style="float:right" (click)="deleteProject(project)" i18n-title="@@Delete" title="Delete"><mat-icon>delete</mat-icon></span>
    </div>
  </div>
</div>
