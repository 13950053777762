<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon></button>
        <button *ngIf="project" mat-raised-button color="primary" (click)="displayCover = !displayCover"><mat-icon>swap_horiz</mat-icon><span>&nbsp;</span><span *ngIf="!displayCover" i18n="@@Edit Cover">Edit Cover</span><span *ngIf="displayCover" i18n="@@Edit Interior">Edit Interior</span></button>
        <ng-container *ngIf="startupService.project && !renameProject">
          <span class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after">{{ startupService.project.name }}</span>
          <span>&nbsp;</span>
          <span (click)="renameProject = !renameProject" i18n-title="@@Rename Project" title="Rename Project"><mat-icon>edit</mat-icon></span>
        </ng-container>
        <span *ngIf="renameProject">
          <textarea type="text" i18n-title="@@Rename the project." title="Rename the project." [ngModel]="startupService.project.name" #placeHolderProjectName maxlength="40"></textarea>
          <span *ngIf="placeHolderProjectName.value.trim()!==startupService.project.name">
            <span i18n-title="@@Save the new name of the project." title="Save the new name of the project." *ngIf="!isProjectNameUsed()" (click)="renameProject = !renameProject; saveProjectValue();"><mat-icon>check</mat-icon></span>
          </span>
          <span i18n-title="@@Cancel the new name of the project." title="Cancel the new name of the project." (click)="renameProject = !renameProject; cancelProjectValue();"><mat-icon>close</mat-icon></span>
        </span>
      </span>
      <span class="app-toolbar-filler"></span>
    </div>
    <span class="app-toolbar-filler"></span>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>

  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="project"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <mat-card appearance="outlined" class="app-content">
        <mat-card-content>
          <!-- START NESTED SIDENAV-->
          <app-cover [style.opacity]="displayCover ? 1 : 0"></app-cover>
          <app-interior-editor [style.opacity]="displayCover ? 0 : 1"></app-interior-editor>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
