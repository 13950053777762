<ng-container *ngIf="startupService.isDataLoaded">
<!--  <a mat-list-item [ngClass]="focus === 'testtable' ? 'selected': ''" [routerLink]="['/secure/testtable']"><mat-icon>cloud_upload</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Test Table">Test Table</span></a>-->
  <ng-container *ngIf="startupService.selectedRole === 'organization'">
    <!--  <ng-container *ngIf="!startupService.limitToOnlyCSVAndOrderHistory">-->
    <!--    <a mat-list-item *ngIf="startupService.selectedRole === 'organization'" [ngClass]="focus === 'createProject' ? 'selected': ''" [routerLink]="['/secure/createProject']" title="CreateProjectToolTip" i18n-title="@@CreateProjectToolTip"><mat-icon>add</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Create Project">Create Project</span></a>-->
    <!--    <a mat-list-item *ngIf="startupService.selectedRole === 'organization'" [ngClass]="focus === 'shelf' ? 'selected': ''" [routerLink]="['/secure/shelf']" title="ShelfToolTip" i18n-title="@@ShelfToolTip"><mat-icon>store</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@My Book Shelf">My Book Shelf</span></a>-->
    <!--  </ng-container>-->
    <!-- Dashboard here -->
    <a mat-list-item [ngClass]="focus === 'projects' ? 'selected': ''" [routerLink]="['/secure/projects']" title="ProjectsToolTip" i18n-title="@@ProjectsToolTip"><mat-icon>ballot</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Projects">Projects</span></a>
<!--    <a mat-list-item [ngClass]="focus === 'createProject' ? 'selected': ''" [routerLink]="['/secure/createProject']" title="CreateProjectToolTip" i18n-title="@@CreateProjectToolTip"><mat-icon>add</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Create Project">Create Project</span></a>-->
<!--    <a mat-list-item [ngClass]="focus === 'shelf' ? 'selected': ''" [routerLink]="['/secure/shelf']" title="ShelfToolTip" i18n-title="@@ShelfToolTip"><mat-icon>store</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@My Book Shelf">My Book Shelf</span></a>-->
    <a mat-list-item [ngClass]="focus === 'cart' ? 'selected': ''" [routerLink]="['/secure/cart']" title="CartToolTip" i18n-title="@@CartToolTip"><mat-icon>shopping_cart</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Cart">Cart</span></a>
<!--    <a mat-list-item [ngClass]="focus === 'ordersearch' ? 'selected': ''" [routerLink]="['/secure/ordersearch']" title="OrderSearchToolTip" i18n-title="@@OrderSearchToolTip"><mat-icon>search</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Order Search">Order Search</span></a>-->
    <a mat-list-item [ngClass]="focus === 'Shipment Orders' ? 'selected': ''" [routerLink]="['/secure/shipmentorders']" title="ShipmentOrdersToolTip" i18n-title="@@ShipmentOrdersToolTip"><mat-icon>local_shipping</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Shipment Orders">Shipment Orders</span></a>
<!--    <a mat-list-item [ngClass]="focus === 'orders' ? 'selected': ''" [routerLink]="['/secure/adminorders']" title="OrdersToolTip" i18n-title="@@OrdersToolTip"><mat-icon>local_shipping</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Orders">Orders</span></a>-->
    <a mat-list-item [ngClass]="focus === 'orderItems' ? 'selected': ''" [routerLink]="['/secure/orderitems']" title="OrderItemsToolTip" i18n-title="@@OrderItemsToolTip"><mat-icon>local_shipping</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Order Items">Order Items</span></a>
    <a mat-list-item [ngClass]="focus === 'csv' ? 'selected': ''" [routerLink]="['/secure/csv']" title="CreateBatchOrderToolTip" i18n-title="@@CreateBatchOrderToolTip"><mat-icon>cloud_upload</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Create Batch Order">Create Batch Order</span></a>
    <a mat-list-item [ngClass]="focus === 'addressissues' ? 'selected': ''" [routerLink]="['/secure/addressissues']" title="AddressIssuesToolTip" i18n-title="@@AddressIssuesToolTip"><mat-icon>flag</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Address Issues">Address Issues</span></a>
    <a mat-list-item [ngClass]="focus === 'products' ? 'selected': ''" [routerLink]="['/secure/products']" title="ProductsToolTip" i18n-title="@@ProductsToolTip"><mat-icon>category</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Products">Products</span></a>
    <ng-container *ngIf="startupService.inventory">
    </ng-container>
<!--    <a mat-list-item [ngClass]="focus === 'groupOrders' ? 'selected': ''" [routerLink]="['/secure/groupOrders']" title="GroupOrdersToolTip" i18n-title="@@GroupOrdersToolTip"><mat-icon>ballot</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Group Orders">Group Orders</span></a>-->
    <a *ngIf="startupService.admin" mat-list-item [ngClass]="focus === 'users' ? 'selected': ''" [routerLink]="['/secure/users']" title="UsersToolTip" i18n-title="@@UsersToolTip"><mat-icon>account_box</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Users">Users</span></a>
    <a mat-list-item [ngClass]="focus === 'settings' ? 'selected': ''" [routerLink]="['/secure/settings']" title="SettingsToolTip" i18n-title="@@SettingsToolTip"><mat-icon>account_box</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Account Settings">Account Settings</span></a>
  </ng-container>
  <!--<ng-container *ngIf="startupService.limitToOnlyCSVAndOrderHistory">-->
  <!--  <a mat-list-item *ngIf="startupService.selectedRole === 'organization' && startupService.getRoleIdFromRole('serviceProvider') !== 0" [ngClass]="focus === 'cart' ? 'selected': ''" [routerLink]="['/secure/cart']" title="CartToolTip" i18n-title="@@CartToolTip"><mat-icon>shopping_cart</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Cart">Cart</span></a>-->
  <!--  <a mat-list-item [ngClass]="focus === 'ordersearch' ? 'selected': ''" [routerLink]="['/secure/ordersearch']" title="OrderSearchToolTip" i18n-title="@@OrderSearchToolTip"><mat-icon>search</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Order Search">Order Search</span></a>-->
  <!--  <a mat-list-item  *ngIf="startupService.selectedRole === 'serviceProvider'" [ngClass]="focus === 'products' ? 'selected': ''" [routerLink]="['/secure/products']" title="ProductsToolTip" i18n-title="@@ProductsToolTip"><mat-icon>category</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Products">Products</span></a>-->
  <!--&lt;!&ndash;  <a mat-list-item [ngClass]="focus === 'Shipment Orders' ? 'selected': ''" [routerLink]="['/secure/shipmentorders']" title="ShipmentOrdersToolTip" i18n-title="@@ShipmentOrdersToolTip"><mat-icon>local_shipping</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Shipment Orders">Shipment Orders</span></a>&ndash;&gt;-->
  <!--</ng-container>-->
  <ng-container *ngIf="startupService.selectedRole === 'facility'">
    <a mat-list-item [ngClass]="focus === 'orderItems' ? 'selected': ''" [routerLink]="['/secure/orderitems']" title="OrderItemsToolTip" i18n-title="@@OrderItemsToolTip"><mat-icon>format_list_bulleted</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Order Items">Order Items</span></a>
    <a mat-list-item [ngClass]="focus === 'sets' ? 'selected': ''" [routerLink]="['/secure/manufacturingsets']" title="ManufacturingSetsToolTip" i18n-title="@@ManufacturingSetsToolTip"><mat-icon>layers</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Sets">Sets</span></a>
    <a mat-list-item [ngClass]="focus === 'facilitypackages' ? 'selected': ''" [routerLink]="['/secure/facilitypackages']" title="FacilityPackagesToolTip" i18n-title="@@FacilityPackagesToolTip"><mat-icon>local_shipping</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Packages">Packages</span></a>
    <a mat-list-item [ngClass]="focus === 'tasks' ? 'selected': ''" [routerLink]="['/secure/manufacturingtasks']" title="ManufacturingTasksToolTip" i18n-title="@@ManufacturingTasksToolTip"><mat-icon>done_outline</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Tasks">Tasks</span></a>
    <a mat-list-item [ngClass]="focus === 'issues' ? 'selected': ''" [routerLink]="['/secure/issues']" title="IssuesToolTip" i18n-title="@@IssuesToolTip"><mat-icon>report_problem</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Issues">Issues</span></a>
    <!--  <a mat-list-item [ngClass]="focus === 'picksheets' ? 'selected': ''" [routerLink]="['/secure/picksheets']" title="PicksheetsToolTip" i18n-title="@@PicksheetsToolTip"><mat-icon>table_view</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Pick Sheets">Pick Sheets</span></a>-->
    <a mat-list-item [ngClass]="focus === 'facilityorders' ? 'selected': ''" [routerLink]="['/secure/facilityprintnodeorders']" title="FacilityOrdersToolTip" i18n-title="@@FacilityOrdersToolTip"><mat-icon>local_shipping</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Facility Orders">Facility Orders</span></a>
    <a mat-list-item [ngClass]="focus === 'facility-scan' ? 'selected': ''" [routerLink]="['/secure/facilityscan']" title="FacilityScanToolTip" i18n-title="@@FacilityScanToolTip"><mat-icon>vertical_shades_closed</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Scan">Scan</span></a>
    <a mat-list-item [ngClass]="focus === 'facility-invoices' ? 'selected': ''" [routerLink]="['/secure/facility-invoices']" title="FacilityInvoicesToolTip" i18n-title="@@FacilityInvoicesToolTip" *ngIf="startupService.selectedRoleId === '7'"><mat-icon>attach_money</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Facility Invoices">Facility Invoices</span></a>
    <ng-container *ngIf="startupService.inventory || ( startupService.selectedRole === 'facility' && startupService.selectedRoleId === '7' )">
      <a mat-list-item [ngClass]="focus === 'facilityinventory' ? 'selected': ''" mat-list-item [ngClass]="focus === '  facilityinventory' ? 'selected': ''" [routerLink]="['/secure/facilityinventory']" title="FacilityInventoryToolTip" i18n-title="@@FacilityInventoryToolTip"><mat-icon>layers</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Inventory">Inventory</span></a>
<!--      <a mat-list-item [ngClass]="focus === 'fulfillmentorders' ? 'selected': ''" [routerLink]="['/secure/fulfillmentorders']" title="FulfillmentOrdersToolTip" i18n-title="@@FulfillmentOrdersToolTip"><mat-icon>local_shipping</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Fulfillment Orders">Fulfillment Orders</span></a>-->
    </ng-container>
    <a mat-list-item [ngClass]="focus === 'settings' ? 'selected': ''" [routerLink]="['/secure/settings']" title="SettingsToolTip" i18n-title="@@SettingsToolTip"><mat-icon>account_box</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Account Settings">Account Settings</span></a>
  </ng-container>
  <ng-container *ngIf="startupService.selectedRole === 'serviceProvider'">
    <a mat-list-item [ngClass]="focus === 'orderItems' ? 'selected': ''" [routerLink]="['/secure/orderitems']" title="OrderItemsToolTip" i18n-title="@@OrderItemsToolTip"><mat-icon>format_list_bulleted</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Order Items">Order Items</span></a>
    <a mat-list-item [ngClass]="focus === 'billingorders' ? 'selected': ''" [routerLink]="['/secure/billingorders']" title="BillingOrdersToolTip" i18n-title="@@BillingOrdersToolTip"><mat-icon>attach_money</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Billing Orders">Billing Orders</span></a>
    <a mat-list-item [ngClass]="focus === 'organization-invoices' ? 'selected': ''" [routerLink]="['/secure/organization-invoices']" title="InvoicesToolTip" i18n-title="@@InvoicesToolTip"><mat-icon>attach_money</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Invoices">Invoices</span></a>
    <a mat-list-item [ngClass]="focus === 'umbrellas' ? 'selected': ''" [routerLink]="['/secure/umbrellas']" title="OrganizationsToolTip" i18n-title="@@OrganizationsToolTip"><mat-icon>beach_access</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Umbrellas">Umbrellas</span></a>
    <a mat-list-item [ngClass]="focus === 'organizations' ? 'selected': ''" [routerLink]="['/secure/organizations']" title="OrganizationsToolTip" i18n-title="@@OrganizationsToolTip"><mat-icon>group</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Organizations">Organizations</span></a>
    <a mat-list-item [ngClass]="focus === 'groupOrders' ? 'selected': ''" [routerLink]="['/secure/groupOrders']" title="GroupOrdersToolTip" i18n-title="@@GroupOrdersToolTip"><mat-icon>ballot</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Group Orders">Group Orders</span></a>
    <a *ngIf="startupService.admin" mat-list-item [ngClass]="focus === 'users' ? 'selected': ''" [routerLink]="['/secure/users']" title="UsersToolTip" i18n-title="@@UsersToolTip"><mat-icon>account_box</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Users">Users</span></a>
  </ng-container>
  <ng-container *ngIf="startupService.selectedRole === 'network'">
    <a mat-list-item [ngClass]="focus === 'orderItems' ? 'selected': ''" [routerLink]="['/secure/orderitems']" title="OrderItemsToolTip" i18n-title="@@OrderItemsToolTip"><mat-icon>format_list_bulleted</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Order Items">Order Items</span></a>
    <a mat-list-item [ngClass]="focus === 'buckets' ? 'selected': ''" [routerLink]="['/secure/manufacturingbuckets']" title="ManufacturingBucketsToolTip" i18n-title="@@ManufacturingBucketsToolTip"><mat-icon>local_drink</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Buckets">Buckets</span></a>
    <a mat-list-item [ngClass]="focus === 'sets' ? 'selected': ''" [routerLink]="['/secure/manufacturingsets']" title="ManufacturingSetsToolTip" i18n-title="@@ManufacturingSetsToolTip"><mat-icon>layers</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Sets">Sets</span></a>
    <a mat-list-item [ngClass]="focus === 'tasks' ? 'selected': ''" [routerLink]="['/secure/manufacturingtasks']" title="ManufacturingTasksToolTip" i18n-title="@@ManufacturingTasksToolTip"><mat-icon>done_outline</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Tasks">Tasks</span></a>
    <a mat-list-item [ngClass]="focus === 'facilities' ? 'selected': ''" [routerLink]="['/secure/facilities']" title="FacilitiesToolTip" i18n-title="@@FacilitiesToolTip"><mat-icon>home_work</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Facilities">Facilities</span></a>
    <a mat-list-item [ngClass]="focus === 'facility-invoices' ? 'selected': ''" [routerLink]="['/secure/facility-invoices']" title="FacilityInvoicesToolTip" i18n-title="@@FacilityInvoicesToolTip"><mat-icon>attach_money</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Facility Invoices">Facility Invoices</span></a>
    <a mat-list-item [ngClass]="focus === 'serviceproviders' ? 'selected': ''" [routerLink]="['/secure/serviceproviders']" title="ServiceProvidersToolTip" i18n-title="@@ServiceProvidersToolTip"><mat-icon>room_service</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Service Providers">Service Providers</span></a>
  </ng-container>
  <ng-container *ngIf="startupService.selectedRole === 'umbrella'">
    <a mat-list-item [ngClass]="focus === 'organizations' ? 'selected': ''" [routerLink]="['/secure/organizations']" title="OrganizationsToolTip" i18n-title="@@OrganizationsToolTip"><mat-icon>group</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Organizations">Organizations</span></a>
    <a mat-list-item [ngClass]="focus === 'groupOrders' ? 'selected': ''" [routerLink]="['/secure/groupOrders']" title="GroupOrdersToolTip" i18n-title="@@GroupOrdersToolTip"><mat-icon>ballot</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Group Orders">Group Orders</span></a>
    <a *ngIf="startupService.admin" mat-list-item [ngClass]="focus === 'users' ? 'selected': ''" [routerLink]="['/secure/users']" title="UsersToolTip" i18n-title="@@UsersToolTip"><mat-icon>account_box</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Users">Users</span></a>
  </ng-container>
</ng-container>
<!--<a mat-list-item [ngClass]="focus === 'account' ? 'selected': ''" [routerLink]="['/secure/account']" title="AccountToolTip" i18n-title="@@AccountToolTip" ><mat-icon>settings</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Account">Account</span></a>-->
<!--<ng-container *ngIf="!startupService.limitToOnlyCSVAndOrderHistory">-->
<!--  <a mat-list-item [ngClass]="focus === 'admin' ? 'selected': ''"  [routerLink]="['/secure/admin']" *ngIf="startupService.admin" title="AdminToolTip" i18n-title="@@AdminToolTip"><mat-icon>rowing</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Admin">Admin</span></a>-->
<!--</ng-container>-->
<a mat-list-item (click)="logout()" title="LogoutToolTip" i18n-title="@@LogoutToolTip"><mat-icon>exit_to_app</mat-icon><span>&nbsp;</span><span style="vertical-align: super;" i18n="@@Logout">Logout</span></a>
<ng-container *ngIf="startupService.hasRoleOptions()">
  <button [ngClass]="( startupService.selectedRole ===  startupService.getRole() && startupService.selectedRoleId === startupService.getRoleId() ) ? 'selected': ''" style="dispay: block !important" mat-icon-button (click)="startupService.selectRole(startupService.getRole(), startupService.getRoleId(), startupService.getRoleId())"><mat-icon aria-hidden="false" [matBadge]="startupService.getRoleId()" matBadgeColor="primary">account_box</mat-icon></button>
  <div *ngFor="let roleOption of startupService.roleOptions">
    <ng-container *ngIf="roleOption.role === 'network'">
      <button [routerLink]="['/secure/startup']" [ngClass]="( startupService.selectedRole ===  roleOption.role && startupService.selectedRoleId === roleOption.roleId ) ? 'selected': ''" style="dispay: block !important"mat-icon-button (click)="startupService.selectRole(roleOption.role, roleOption.roleId, '')">
        <mat-icon aria-hidden="false" [matBadge]="roleOption.roleId" matBadgeColor="primary">device_hub</mat-icon></button>
    </ng-container>
  </div>
  <div *ngFor="let roleOption of startupService.roleOptions">
    <ng-container *ngIf="roleOption.role === 'facility'">
      <button [routerLink]="['/secure/startup']" [ngClass]="( startupService.selectedRole ===  roleOption.role && startupService.selectedRoleId === roleOption.roleId ) ? 'selected': ''" style="dispay: block !important"mat-icon-button (click)="startupService.selectRole(roleOption.role, roleOption.roleId, '')">
        <mat-icon aria-hidden="false" [matBadge]="roleOption.roleId" matBadgeColor="primary">home_work</mat-icon></button>
    </ng-container>
  </div>
  <div *ngFor="let roleOption of startupService.roleOptions">
    <ng-container *ngIf="roleOption.role === 'serviceProvider'">
      <button [routerLink]="['/secure/startup']" [ngClass]="( startupService.selectedRole ===  roleOption.role && startupService.selectedRoleId === roleOption.roleId ) ? 'selected': ''" style="dispay: block !important"mat-icon-button (click)="startupService.selectRole(roleOption.role, roleOption.roleId, '')">
        <mat-icon aria-hidden="false" [matBadge]="roleOption.roleId" matBadgeColor="primary">room_service</mat-icon></button>
    </ng-container>
  </div>
  <div *ngFor="let roleOption of startupService.roleOptions">
    <ng-container *ngIf="roleOption.role === 'umbrella'">
      <button [routerLink]="['/secure/organizations']" [ngClass]="( startupService.selectedRole ===  roleOption.role && startupService.selectedRoleId === roleOption.roleId ) ? 'selected': ''" style="dispay: block !important"mat-icon-button (click)="startupService.selectRole(roleOption.role, roleOption.roleId, '')">
        <mat-icon aria-hidden="false" [matBadge]="roleOption.roleId" matBadgeColor="primary">beach_access</mat-icon></button>
    </ng-container>
  </div>
  <div *ngFor="let roleOption of startupService.roleOptions">
    <ng-container *ngIf="roleOption.role === 'organization'">
      <button [routerLink]="['/secure/startup']" [ngClass]="( startupService.selectedRole ===  roleOption.role && startupService.selectedRoleId === roleOption.roleId ) ? 'selected': ''" style="dispay: block !important"mat-icon-button (click)="startupService.selectRole(roleOption.role, roleOption.roleId, '')">
        <mat-icon aria-hidden="false" [matBadge]="roleOption.roleId" matBadgeColor="primary">handyman</mat-icon></button>
    </ng-container>
  </div>
</ng-container>
