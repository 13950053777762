import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {EditorProjectService} from '../../service-data/editor/editor-project.service';
import {Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import {Project} from '../../../model/editor/Project';
import {Cover} from "../../../model/editor/Cover";
import {CoverTheme} from "../../../model/editor/CoverTheme";
import {ThemeService} from "../../service-data/theme.service";
// import 'fabric';
import {ColorPalette} from "../../../model/color/ColorPalette";
import {FontSettings} from "../../../model/editor/FontSettings";
import {CanvasManager} from "../../../canvas-manager/CanvasManager";
import {ImageService} from "../../service-data/image.service";
import {ImageOption} from "../../../model/editor/ImageOption";
import {Image} from "../../../model/Image";


// declare const fabric: any;

@Component({
  selector: 'app-cover-editor',
  templateUrl: './cover-editor.component.html',
  styleUrls: ['./cover-editor.component.css']
})
export class CoverEditorComponent implements OnInit, OnChanges {
  @ViewChild('canvasElement', { static: true }) canvasElement;
  @Input() project: Project;
  @Input() cover: Cover;
  @Input() coverTheme: CoverTheme;
  @Input() colorPalette: ColorPalette;
  @Input() fontSettings: FontSettings;
  @Input() colorPaletteChange;
  @Input() fontSettingsChange;
  @Input() trimSizeChange;
  @Input() coverThemeChange;
  @Input() activeSideArea;
  @Input() mainSideNavIsOpen: boolean;

  canvasManager: CanvasManager;
  loadingImages: boolean = false;
  imagesToLoad: string[] = [];
  totalImagesToLoad = 1;
  displayToolArea: string = '';
  designerMode: boolean = false;

  opacity: number = 0;

  fontSizes = [6	,
    7	,
    8	,
    9	,
    10	,
    11	,
    12	,
    13	,
    14	,
    15	,
    16	,
    17	,
    18	,
    20	,
    22	,
    24	,
    26	,
    28	,
    30	,
    32	,
    34	,
    36	,
    38	,
    40	,
    42	,
    44	,
    46	,
    48	,
    50	,
    52	,
    54	,
    56	,
    58	,
    60	,
    64	,
    68	,
    72	,
    76	,
    80	,
    84	,
    88	,
    92	,
    96	,
    100	,
    104	,
    108	,
    116	,
    120	,
    128	,
    132	,
    140	,
    148	,
    156	,
    164	,
    172	,
    180	,
    188	,
    196	,
    240	,
    264	,
    288	];

  backgroundLibrary: Image[];
  transparentLibrary: Image[];
  userBackgroundLibrary: Image[];
  userTransparentLibrary: Image[];
  userLogoLibrary: Image[];

  constructor (public router: Router, public startupService: StartupService, private projectService: EditorProjectService, private themeService: ThemeService, private imageService: ImageService) {
  }

  ngOnInit () {
    this.canvasManager = undefined;
    this.loadingImages = true;
    this.imagesToLoad = [];
    for ( const imageToLoad of this.project.cover.getImages()) {
      if ( imageToLoad && imageToLoad.key ) {
        if (imageToLoad.key['imageKey']) {
          imageToLoad.key = imageToLoad.key['imageKey'];
        }
      }
      this.imagesToLoad.push(imageToLoad.key);
      this.totalImagesToLoad = this.imagesToLoad.length;
    }
    this.cover.verifyImages();
    this.loadCanvas();
    this.imageService.getImages('backgrounds').subscribe(images => {
      this.backgroundLibrary = images;
    });
    this.imageService.getImages('transparent').subscribe(images => {
      this.transparentLibrary = images;
    });
    this.imageService.getImages('userbackgrounds').subscribe(images => {
      this.userBackgroundLibrary = images;
    });
    this.imageService.getImages('usertransparent').subscribe(images => {
      this.userTransparentLibrary = images;
    });
    this.imageService.getImages('userlogo').subscribe(images => {
      this.userLogoLibrary = images;
    });

  }


  loadCanvas() {
    if ( !this.imagesToLoad.length ) { // when array is empty all of the images have been loaded
      if ( !this.canvasManager ) {
        this.canvasManager = new CanvasManager(this.project, this.canvasElement, this.projectService);
        this.startupService.canvasManager = this.canvasManager;
        this.canvasManager.loadCover().then( _ => {
          this.loadingImages = false;
        });
      } else {
        if ( this.loadingImages ) {
          this.canvasManager.applyCoverTheme(this.project.cover.coverTheme, false);
          this.loadingImages = false;
        }
      }
    }
  }

  addImagesToLoadFromNewCoverTheme(coverTheme: CoverTheme, cover: Cover) {
    cover.verifyImages();
    // // Get the images in the NEW CoverTheme by role and eleminate ones that would be overridden by images in the same role in the current theme
    // const rolesAndImageKeys = coverTheme.getRolesAndImageKeys();
    // for ( const prop of Object.keys(cover.imagesByRole) ) {
    //   const existingImageKeyCurrentlyInTheRole = cover.imagesByRole[prop];
    //   if ( existingImageKeyCurrentlyInTheRole && existingImageKeyCurrentlyInTheRole.key ) {
    //     rolesAndImageKeys[prop] = existingImageKeyCurrentlyInTheRole.key;
    //   }
    // }
    //
    //
    // // Step 2: leave alone ones that are already there and are needed for the new CoverTheme
    // for ( const prop of Object.keys(rolesAndImageKeys) ) {
    //   const index = currentImageKeysInCover.indexOf(rolesAndImageKeys[prop]);
    //   // If an image needed for the new CoverTheme is not in the current Cover then add it
    //   if ( index === -1) {
    //     this.project.cover.addImage(rolesAndImageKeys[prop], prop);
    //     this.imagesToLoad.push(rolesAndImageKeys[prop]);
    //     this.totalImagesToLoad = this.imagesToLoad.length;
    //     this.loadingImages = false;
    //   } else {
    //     currentImageKeysInCover.splice(index, 1);
    //   }
    // }
    //
    // // TODO: we may be stranding some roles that are not used -- this will cause additional wait time when loading
    // // // Step 3: remove the newly uneeded roles from the Cover list of images
    // // for ( const obsoleteImageKey of currentImageKeysInCover) {
    // //   this.project.cover.removeImage(obsoleteImageKey, obsoleteImageKey);
    // //   const orderId = this.imagesToLoad.indexOf(obsoleteImageKey);
    // //   if ( orderId !== -1 ) {
    // //     this.imagesToLoad.splice(orderId, 1);
    // //   }
    // // }
  }

  imageLoaded(imageKey: string) {
    if ( this.imagesToLoad && this.imagesToLoad.length > 0 ) {
      const index = this.imagesToLoad.indexOf(imageKey);
      if ( index >= 0 ) {
        this.imagesToLoad.splice(index, 1 );
      } else {
        if ( this.imagesToLoad[0].length === 0 ) {
          this.imagesToLoad.splice(0, 1 );
        }
        console.log('Image Key was loaded but was not in the list: ' + imageKey);
      }
    }
    this.loadCanvas();
  }

  errorHandler(event: any, imageKey: string) {
    event.target.src = '/assets/images/placeholder.jpg';
    console.log(event);
    if ( this.imagesToLoad && this.imagesToLoad.length > 0 ) {
      const index = this.imagesToLoad.indexOf(imageKey);
      if ( index >= 0 ) {
        this.imagesToLoad.splice(index, 1 );
      } else {
        if ( this.imagesToLoad[0].length === 0 ) {
          this.imagesToLoad.splice(0, 1 );
        }
      }
    }
    this.loadCanvas();
  }

  ngOnChanges (changes: SimpleChanges) {
    console.log("Change in the cover-editor.component");
    if (changes.coverTheme && changes.coverTheme.currentValue !== undefined && changes.coverTheme.currentValue !== changes.coverTheme.previousValue) {
      console.log("The project CoverTheme changed");
      const coverTheme: CoverTheme = changes.coverTheme.currentValue;
      if (coverTheme && this.canvasManager) {
        this.addImagesToLoadFromNewCoverTheme(coverTheme, this.project.cover);
        if ( this.imagesToLoad.length === 0 ) {
          this.canvasManager.applyCoverTheme(coverTheme, true);
        }
      }
    }
    if (changes.colorPalette && changes.colorPalette.currentValue !== undefined && changes.colorPalette.currentValue !== changes.colorPalette.previousValue) {
      console.log("The project ColorPalette changed");
      if ( this.canvasManager ) {
        this.canvasManager.applyCoverTheme(this.coverTheme, false);
      }
    }
    if (changes.fontSettings && changes.fontSettings.currentValue !== undefined && changes.fontSettings.currentValue !== changes.fontSettings.previousValue) {
      console.log("The project FontSettings changed");
      if ( this.canvasManager ) {
        this.canvasManager.applyCoverTheme(this.coverTheme, false);
      }
    }
    if (changes.colorPaletteChange) {
      console.log("The project ColorPalette changed");
      if ( this.canvasManager ) {
        this.canvasManager.applyCoverTheme(this.startupService.project.cover.coverTheme, false);
      }
    }
    if (changes.fontSettingsChange) {
      console.log("The project font settings changed");
      if ( this.canvasManager ) {
        this.canvasManager.applyCoverTheme(this.startupService.project.cover.coverTheme, false);
      }
    }
    if (changes.trimSizeChange) {
      console.log("The project trim size changed");
      if ( this.canvasManager ) {
        this.canvasManager.applyCoverTheme(this.startupService.project.cover.coverTheme, false);
      }
    }
    if (changes.coverThemeChange) {
      console.log("The project cover theme changed");
      if ( this.canvasManager ) {
        this.ngOnInit();
        // this.canvasManager.applyCoverTheme(this.startupService.project.cover.coverTheme, false);
      }
    }
  }

  areImagesLoading(): boolean {
    if ( !this.imagesToLoad ) {
      return true;
    }
    if ( this.imagesToLoad && this.imagesToLoad.length > 0 && this.loadingImages ) {
      return true;
    }
    return false;
  }

  // getFloatingToolStripLeft(): string {
  //   const elements = document.getElementsByClassName('canvas-container');
  //   if ( elements && elements.length > 0 ) {
  //     const offsetLeft = elements.item(0 )["offsetLeft"];
  //     if( offsetLeft > 60 ) {
  //       return '' + ( offsetLeft - 50 ) + 'px';
  //     }
  //   }
  //   return '10px';
  // }
  changedCoverTheme() { // TODO: Just update the ThemeItem is possible.
    this.canvasManager.refreshObject(this.canvasManager.getSelected());
    // this.projectService.saveProject(this.startupService.project);
    this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
    // this.startupService.coverThemeChange++;
  }

  shiftVertically(amount: number) {
    const verticalAdjustment = this.canvasManager.getOverridePropertyNumber('verticalAdjustment', 0);
    this.canvasManager.setOverridePropertyNumber('verticalAdjustment', amount + Number(verticalAdjustment) );
  }

  shiftHorizontally(amount: number) {
    const horizontalAdjustment = this.canvasManager.getOverridePropertyNumber('horizontalAdjustment', 0);
    this.canvasManager.setOverridePropertyNumber('horizontalAdjustment', amount + Number(horizontalAdjustment) );
  }

  resetHorizontally() {
    this.canvasManager.setOverridePropertyNumber('horizontalAdjustment', 0 );
  }

  resetVertically() {
    this.canvasManager.setOverridePropertyNumber('verticalAdjustment', 0 );
  }

  setFontSize(value) {
    this.canvasManager.setOverridePropertyString('fontSize', value);
  }
  getFontSize() {
    const fontSize = this.canvasManager.getOverridePropertyString('fontSize', '');
    // const fontSize = this.canvasManager.getFontSize();
    return fontSize;
  }

  imageSelected(imageKey: string) {
    if ( this.startupService.canvasManager ) {
      if ( this.startupService.canvasManager.getType() === 'image' ) {
        // Remove the privious image from the list of images for the cover and add the new one to that list
        const currentImageKey = this.startupService.canvasManager.getImageKey();

        this.startupService.canvasManager.project.cover.removeImage(currentImageKey, this.startupService.canvasManager.getRole());
        this.startupService.canvasManager.project.cover.addImage(imageKey, this.startupService.canvasManager.getRole());

        const image = this.imageService.createImage(imageKey);
        this.startupService.canvasManager.setImageSrc(imageKey, this.imageService.getFreshImageUrl(image, false));
      }
    }
  }

  saveProject() {
    this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
  }

  increaseFontSize() {
    const currentFontSizeString = this.getFontSize();
    let currentFontSize = Number(this.getFontSize());
    if ( currentFontSizeString === 'Inherit' || currentFontSizeString === '') {
      currentFontSize = this.canvasManager.getThemeItem().getFontSize();
    }

    const index = this.fontSizes.indexOf( currentFontSize );
    let newFontSize = currentFontSize + 4;
    if ( index >= 0 && index < this.fontSizes.length ) {
      newFontSize = this.fontSizes[index + 1];
    }
    this.setFontSize(newFontSize);
    this.saveProject();
  }

  decreaseFontSize() {
    const currentFontSizeString = this.getFontSize();
    let currentFontSize = Number(this.getFontSize());
    if ( currentFontSizeString === 'Inherit' || currentFontSizeString === '') {
      currentFontSize = this.canvasManager.getThemeItem().getFontSize();
    }
    const index = this.fontSizes.indexOf( currentFontSize );
    let newFontSize = currentFontSize - 4;
    if ( index > 0 && index < this.fontSizes.length ) {
      newFontSize = this.fontSizes[index - 1];
    }
    if (newFontSize < 6) {
      newFontSize = 6;
    }
    this.setFontSize(newFontSize);
    this.saveProject();
  }


}
