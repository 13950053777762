<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Pick Sheets">Pick Sheets</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Pick Sheets">Pick Sheets</span>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
<!--    <div>-->
<!--      <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
<!--    </div>-->
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="picksheets"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <mat-card appearance="outlined" style="padding:20px">
        <mat-card-content>
          <ng-container *ngIf="pickSheetsGroups">
            <h2 class="filters-h2" i18n="@@Filters">Filters</h2>
            <ng-container *ngIf="organizations && organizations.length > 1">
              <div>
                <mat-select placeholder="Organization" i18n-placeholder="@@Organization" (selectionChange)="selectedOrganization=$event.value" required [ngModel]="selectedOrganization" style="display: inline">
                  <mat-option value="0">All Organizations</mat-option>
                  <ng-container *ngFor="let organization of organizations">
                    <mat-option [value]="organization.ID">{{organization.ID}} {{organization.name}}</mat-option>
                  </ng-container>
                </mat-select>
<!--                <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
              </div>
            </ng-container>
            <section class="filters-section">
              <mat-checkbox class="filter-margin" [(ngModel)]="bulk" i18n="@@Bulk Shipments">Bulk Shipments</mat-checkbox>
              <mat-checkbox class="filter-margin" [(ngModel)]="individual" i18n="@@Individual Shipments">Individual Shipments</mat-checkbox>
            </section>
            <section class="filters-section">
              <mat-checkbox class="filter-margin" [(ngModel)]="envelopes" i18n="@@Envelopes">Envelopes</mat-checkbox>
              <mat-checkbox class="filter-margin" [(ngModel)]="fourInBoxes" i18n="@@4in Boxes">4in Boxes</mat-checkbox>
              <mat-checkbox class="filter-margin" [(ngModel)]="medFlatRate" i18n="@@Medium Flat Rate">Medium Flat Rate</mat-checkbox>
              <mat-checkbox class="filter-margin" [(ngModel)]="ground" i18n="@@Ground">Ground</mat-checkbox>
              <mat-checkbox class="filter-margin" [(ngModel)]="groundFreightPricing" i18n="@@Ground With Freight Pricing">Ground With Freight Pricing</mat-checkbox>
              <mat-checkbox class="filter-margin" [(ngModel)]="freight" i18n="@@Freight">Freight</mat-checkbox>
            </section>
            <section class="filters-section">
              <mat-checkbox class="filter-margin" [(ngModel)]="single" i18n="@@Single Book">Single Book</mat-checkbox>
              <mat-checkbox class="filter-margin" [(ngModel)]="only" i18n="@@Multiple Books Same Grade">Multiple Books Same Grade</mat-checkbox>
              <mat-checkbox class="filter-margin" [(ngModel)]="pure" i18n="@@Pure">Pure</mat-checkbox>
              <mat-checkbox class="filter-margin" [(ngModel)]="pureMixed" i18n="@@Pure Mixed">Pure Mixed</mat-checkbox>
              <mat-checkbox class="filter-margin" [(ngModel)]="mixed" i18n="@@Mixed">Mixed</mat-checkbox>
              <mat-checkbox class="filter-margin" [(ngModel)]="abmixed" i18n="@@AB Mixed">AB Mixed</mat-checkbox>
<!--              <mat-checkbox class="filter-margin" [(ngModel)]="aOnly" i18n="@@A Only">A Only</mat-checkbox>-->
<!--              <mat-checkbox class="filter-margin" [(ngModel)]="bOnly" i18n="@@B Only">B Only</mat-checkbox>-->
<!--              <mat-checkbox class="filter-margin" [(ngModel)]="aAndB" i18n="@@A and B">A and B</mat-checkbox>-->
            </section>
<!--            <section class="filters-section">-->
<!--              <div class="filter-margin"><ng5-slider style="max-width: 250px;" clsss="slider" [(value)]="minWeight" [(highValue)]="maxWeight" [options]="weightOptions"></ng5-slider></div>-->
<!--              <div class="filter-margin"><ng5-slider style="max-width: 250px;"  clsss="slider" [(value)]="minItems" [(highValue)]="maxItems" [options]="itemsOptions"></ng5-slider></div>-->
<!--              <div class="filter-margin"><ng5-slider style="max-width: 250px;"  clsss="slider" [(value)]="minPrintNodeOrderId" [(highValue)]="maxPrintNodeOrderId" [options]="printNodeOrderIdOptions"></ng5-slider></div>-->
<!--              <div class="filter-margin"><ng5-slider style="max-width: 250px;"  clsss="slider" [(value)]="minDate" [(highValue)]="maxDate" [options]="dateOptions"></ng5-slider></div>-->
<!--            </section>-->
            <section class="options">
              <div class="headerRow row">
              </div>
              <ng-container *ngFor="let date of dates">
                <ng-container class="row" *ngIf="this.dateQuantities[date] && this.dateQuantities[date] > 0">
                  <div class="date">
                    <table>
                      <tr><td rowspan="2">{{date}}</td></tr>
                      <tr><td>{{dateQuantities[date]}}</td><td>{{dateOrders[date]}}</td></tr>
                    </table>
                  </div>
                  <div>
                    <ng-container *ngIf="individual">
                      <div class="single grades" *ngIf="dateIndexes[date] && datesMatrix[dateIndexes[date]] && single">
                        <div i18n="@@Single Book">Single Book</div>
                        <div [ngClass]="pickSheetsGroup.selected ? 'selected': ''" class="option" *ngFor="let pickSheetsGroup of datesMatrix[dateIndexes[date]]['single']">
                          <ng-container *ngIf="pickSheetsGroup && pickSheetsGroup.packageType && (pickSheetsGroup.packageType ==='Envelope' && envelopes) || (pickSheetsGroup.packageType ==='4in Box' && fourInBoxes) || (pickSheetsGroup.packageType ==='Med Flat' && medFlatRate) || (pickSheetsGroup.packageType ==='X' && ground) || (pickSheetsGroup.packageType ==='GFP' && groundFreightPricing) || (pickSheetsGroup.packageType ==='Freight' && freight)">
                            <ng-container *ngIf="(pickSheetsGroup.tagType ==='Pure' && pure) || (pickSheetsGroup.tagType ==='PureMixed' && pureMixed) || (pickSheetsGroup.tagType ==='Single' && single) || (pickSheetsGroup.tagType ==='Only' && only) || (pickSheetsGroup.tagType ==='Mixed' && mixed) || (pickSheetsGroup.tagType ==='ABMixed' && abmixed)">
                              <ng-container *ngIf="(pickSheetsGroup.type ==='Individual' && individual) || (pickSheetsGroup.type ==='Bulk' && bulk)">
                                <app-picksheets-group [pickSheetsGroup]="pickSheetsGroup" [inventoryLevelsComputer]="inventoryLevlesComputer"></app-picksheets-group>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                      <div class="only grades" *ngIf="dateIndexes[date] && datesMatrix[dateIndexes[date]] && only">
                        <div i18n="@@Multiple Books">Multiple Books</div>
                        <div [ngClass]="pickSheetsGroup.selected ? 'selected': ''" class="option" *ngFor="let pickSheetsGroup of datesMatrix[dateIndexes[date]]['only']">
                          <ng-container *ngIf="pickSheetsGroup && pickSheetsGroup.packageType && (pickSheetsGroup.packageType ==='Envelope' && envelopes) || (pickSheetsGroup.packageType ==='4in Box' && fourInBoxes) || (pickSheetsGroup.packageType ==='Med Flat' && medFlatRate) || (pickSheetsGroup.packageType ==='X' && ground) || (pickSheetsGroup.packageType ==='GFP' && groundFreightPricing) || (pickSheetsGroup.packageType ==='Freight' && freight)">
                            <ng-container *ngIf="(pickSheetsGroup.tagType ==='Pure' && pure) || (pickSheetsGroup.tagType ==='PureMixed' && pureMixed) || (pickSheetsGroup.tagType ==='Single' && single) || (pickSheetsGroup.tagType ==='Only' && only) || (pickSheetsGroup.tagType ==='Mixed' && mixed) || (pickSheetsGroup.tagType ==='ABMixed' && abmixed)">
                              <ng-container *ngIf="(pickSheetsGroup.type ==='Individual' && individual) || (pickSheetsGroup.type ==='Bulk' && bulk)">
                                <app-picksheets-group [pickSheetsGroup]="pickSheetsGroup" [inventoryLevelsComputer]="inventoryLevlesComputer"></app-picksheets-group>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                      <div class="pure grades" *ngIf="dateIndexes[date] && datesMatrix[dateIndexes[date]] && pure">
                        <div i18n="@@4 Books">4 Books</div>
                        <div [ngClass]="pickSheetsGroup.selected ? 'selected': ''" [ngClass]="pickSheetsGroup.selected ? 'selected': ''" class="option" *ngFor="let pickSheetsGroup of datesMatrix[dateIndexes[date]]['pure']">
                          <ng-container *ngIf="pickSheetsGroup && pickSheetsGroup.packageType && (pickSheetsGroup.packageType ==='Envelope' && envelopes) || (pickSheetsGroup.packageType ==='4in Box' && fourInBoxes) || (pickSheetsGroup.packageType ==='Med Flat' && medFlatRate) || (pickSheetsGroup.packageType ==='X' && ground) || (pickSheetsGroup.packageType ==='GFP' && groundFreightPricing) || (pickSheetsGroup.packageType ==='Freight' && freight)">
                            <ng-container *ngIf="(pickSheetsGroup.tagType ==='Pure' && pure) || (pickSheetsGroup.tagType ==='PureMixed' && pureMixed) || (pickSheetsGroup.tagType ==='Single' && single) || (pickSheetsGroup.tagType ==='Only' && only) || (pickSheetsGroup.tagType ==='Mixed' && mixed) || (pickSheetsGroup.tagType ==='ABMixed' && abmixed)">
                              <ng-container *ngIf="(pickSheetsGroup.type ==='Individual' && individual) || (pickSheetsGroup.type ==='Bulk' && bulk)">
                                <app-picksheets-group [pickSheetsGroup]="pickSheetsGroup" [inventoryLevelsComputer]="inventoryLevlesComputer"></app-picksheets-group>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                      <div class="pureMixed grades" *ngIf="dateIndexes[date] && datesMatrix[dateIndexes[date]] && pureMixed">
                        <div i18n="@@Mixed 4 Books">Mixed 4 Books</div>
                        <div [ngClass]="pickSheetsGroup.selected ? 'selected': ''" class="option" *ngFor="let pickSheetsGroup of datesMatrix[dateIndexes[date]]['pureMixed']">
                          <ng-container *ngIf="pickSheetsGroup && pickSheetsGroup.packageType && (pickSheetsGroup.packageType ==='Envelope' && envelopes) || (pickSheetsGroup.packageType ==='4in Box' && fourInBoxes) || (pickSheetsGroup.packageType ==='Med Flat' && medFlatRate) || (pickSheetsGroup.packageType ==='X' && ground) || (pickSheetsGroup.packageType ==='GFP' && groundFreightPricing) || (pickSheetsGroup.packageType ==='Freight' && freight)">
                            <ng-container *ngIf="(pickSheetsGroup.tagType ==='Pure' && pure) || (pickSheetsGroup.tagType ==='PureMixed' && pureMixed) || (pickSheetsGroup.tagType ==='Single' && single) || (pickSheetsGroup.tagType ==='Only' && only) || (pickSheetsGroup.tagType ==='Mixed' && mixed) || (pickSheetsGroup.tagType ==='ABMixed' && abmixed)">
                              <ng-container *ngIf="(pickSheetsGroup.type ==='Individual' && individual) || (pickSheetsGroup.type ==='Bulk' && bulk)">
                                <app-picksheets-group [pickSheetsGroup]="pickSheetsGroup" [inventoryLevelsComputer]="inventoryLevlesComputer"></app-picksheets-group>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                      <div class="mixed grades" *ngIf="dateIndexes[date] && datesMatrix[dateIndexes[date]] && mixed">
                        <div i18n="@@Mixed">Mixed</div>
                        <div [ngClass]="pickSheetsGroup.selected ? 'selected': ''" class="option" *ngFor="let pickSheetsGroup of datesMatrix[dateIndexes[date]]['mixed']">
                          <ng-container *ngIf="pickSheetsGroup && pickSheetsGroup.packageType && (pickSheetsGroup.packageType ==='Envelope' && envelopes) || (pickSheetsGroup.packageType ==='4in Box' && fourInBoxes) || (pickSheetsGroup.packageType ==='Med Flat' && medFlatRate) || (pickSheetsGroup.packageType ==='X' && ground) || (pickSheetsGroup.packageType ==='GFP' && groundFreightPricing) || (pickSheetsGroup.packageType ==='Freight' && freight)">
                            <ng-container *ngIf="(pickSheetsGroup.tagType ==='Pure' && pure) || (pickSheetsGroup.tagType ==='PureMixed' && pureMixed) || (pickSheetsGroup.tagType ==='Single' && single) || (pickSheetsGroup.tagType ==='Only' && only) || (pickSheetsGroup.tagType ==='Mixed' && mixed) || (pickSheetsGroup.tagType ==='ABMixed' && abmixed)">
                              <ng-container *ngIf="(pickSheetsGroup.type ==='Individual' && individual) || (pickSheetsGroup.type ==='Bulk' && bulk)">
                                <app-picksheets-group [pickSheetsGroup]="pickSheetsGroup" [inventoryLevelsComputer]="inventoryLevlesComputer"></app-picksheets-group>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                      <div class="mixed grades" *ngIf="dateIndexes[date] && datesMatrix[dateIndexes[date]] && abmixed">
                        <div i18n="@@Mixed">Mixed</div>
                        <div [ngClass]="pickSheetsGroup.selected ? 'selected': ''" class="option" *ngFor="let pickSheetsGroup of datesMatrix[dateIndexes[date]]['abmixed']">
                          <ng-container *ngIf="pickSheetsGroup && pickSheetsGroup.packageType && (pickSheetsGroup.packageType ==='Envelope' && envelopes) || (pickSheetsGroup.packageType ==='4in Box' && fourInBoxes) || (pickSheetsGroup.packageType ==='Med Flat' && medFlatRate) || (pickSheetsGroup.packageType ==='X' && ground) || (pickSheetsGroup.packageType ==='GFP' && groundFreightPricing) || (pickSheetsGroup.packageType ==='Freight' && freight)">
                            <ng-container *ngIf="(pickSheetsGroup.tagType ==='Pure' && pure) || (pickSheetsGroup.tagType ==='PureMixed' && pureMixed) || (pickSheetsGroup.tagType ==='Single' && single) || (pickSheetsGroup.tagType ==='Only' && only) || (pickSheetsGroup.tagType ==='Mixed' && mixed) || (pickSheetsGroup.tagType ==='ABMixed' && abmixed)">
                              <ng-container *ngIf="(pickSheetsGroup.type ==='Individual' && individual) || (pickSheetsGroup.type ==='Bulk' && bulk)">
                                <app-picksheets-group [pickSheetsGroup]="pickSheetsGroup" [inventoryLevelsComputer]="inventoryLevlesComputer"></app-picksheets-group>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                    <div class="bulk" *ngIf="dateIndexes[date] && datesMatrix[dateIndexes[date]] && bulk">
                      <div i18n="@@Bulk A">Bulk A</div>
                      <div class="bulkItems">
                        <div [ngClass]="pickSheetsGroup.selected ? 'selected': ''" class="option" *ngFor="let pickSheetsGroup of datesMatrix[dateIndexes[date]]['abulk']">
                          <ng-container *ngIf="pickSheetsGroup && pickSheetsGroup.packageType && (pickSheetsGroup.packageType ==='Envelope' && envelopes) || (pickSheetsGroup.packageType ==='4in Box' && fourInBoxes) || (pickSheetsGroup.packageType ==='Med Flat' && medFlatRate) || (pickSheetsGroup.packageType ==='X' && ground) || (pickSheetsGroup.packageType ==='GFP' && groundFreightPricing) || (pickSheetsGroup.packageType ==='Freight' && freight)">
                            <ng-container *ngIf="(pickSheetsGroup.tagType ==='Pure' && pure) || (pickSheetsGroup.tagType ==='PureMixed' && pureMixed) || (pickSheetsGroup.tagType ==='Single' && single) || (pickSheetsGroup.tagType ==='Only' && only) || (pickSheetsGroup.tagType ==='Mixed' && mixed) || (pickSheetsGroup.tagType ==='ABMixed' && abmixed)">
                              <ng-container *ngIf="(pickSheetsGroup.type ==='Individual' && individual) || (pickSheetsGroup.type ==='Bulk' && bulk)">
                                <app-picksheets-group [pickSheetsGroup]="pickSheetsGroup" [inventoryLevelsComputer]="inventoryLevlesComputer"></app-picksheets-group>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="bulk" *ngIf="dateIndexes[date] && datesMatrix[dateIndexes[date]] && bulk">
                      <div i18n="@@Bulk B">Bulk B</div>
                      <div class="bulkItems">
                        <div [ngClass]="pickSheetsGroup.selected ? 'selected': ''" class="option" *ngFor="let pickSheetsGroup of datesMatrix[dateIndexes[date]]['bbulk']">
                          <ng-container *ngIf="pickSheetsGroup && pickSheetsGroup.packageType && (pickSheetsGroup.packageType ==='Envelope' && envelopes) || (pickSheetsGroup.packageType ==='4in Box' && fourInBoxes) || (pickSheetsGroup.packageType ==='Med Flat' && medFlatRate) || (pickSheetsGroup.packageType ==='X' && ground) || (pickSheetsGroup.packageType ==='GFP' && groundFreightPricing) || (pickSheetsGroup.packageType ==='Freight' && freight)">
                            <ng-container *ngIf="(pickSheetsGroup.tagType ==='Pure' && pure) || (pickSheetsGroup.tagType ==='PureMixed' && pureMixed) || (pickSheetsGroup.tagType ==='Single' && single) || (pickSheetsGroup.tagType ==='Only' && only) || (pickSheetsGroup.tagType ==='Mixed' && mixed) || (pickSheetsGroup.tagType ==='ABMixed' && abmixed)">
                              <ng-container *ngIf="(pickSheetsGroup.type ==='Individual' && individual) || (pickSheetsGroup.type ==='Bulk' && bulk)">
                                <app-picksheets-group [pickSheetsGroup]="pickSheetsGroup" [inventoryLevelsComputer]="inventoryLevlesComputer"></app-picksheets-group>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="bulk" *ngIf="dateIndexes[date] && datesMatrix[dateIndexes[date]] && bulk">
                      <div i18n="@@Bulk AB">Bulk AB</div>
                      <div class="bulkItems">
                        <div [ngClass]="pickSheetsGroup.selected ? 'selected': ''" class="option" *ngFor="let pickSheetsGroup of datesMatrix[dateIndexes[date]]['abbulk']">
                          <ng-container *ngIf="pickSheetsGroup && pickSheetsGroup.packageType && (pickSheetsGroup.packageType ==='Envelope' && envelopes) || (pickSheetsGroup.packageType ==='4in Box' && fourInBoxes) || (pickSheetsGroup.packageType ==='Med Flat' && medFlatRate) || (pickSheetsGroup.packageType ==='X' && ground) || (pickSheetsGroup.packageType ==='GFP' && groundFreightPricing) || (pickSheetsGroup.packageType ==='Freight' && freight)">
                            <ng-container *ngIf="(pickSheetsGroup.tagType ==='Pure' && pure) || (pickSheetsGroup.tagType ==='PureMixed' && pureMixed) || (pickSheetsGroup.tagType ==='Single' && single) || (pickSheetsGroup.tagType ==='Only' && only) || (pickSheetsGroup.tagType ==='Mixed' && mixed) || (pickSheetsGroup.tagType ==='ABMixed' && abmixed)">
                              <ng-container *ngIf="(pickSheetsGroup.type ==='Individual' && individual) || (pickSheetsGroup.type ==='Bulk' && bulk)">
                                <app-picksheets-group [pickSheetsGroup]="pickSheetsGroup" [inventoryLevelsComputer]="inventoryLevlesComputer"></app-picksheets-group>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="bulk" *ngIf="dateIndexes[date] && datesMatrix[dateIndexes[date]] && bulk">
                      <div i18n="@@Bulk AB">ALL</div>
                      <div class="bulkItems">
                        <div [ngClass]="pickSheetsGroup.selected ? 'selected': ''" class="option" *ngFor="let pickSheetsGroup of datesMatrix[dateIndexes[date]]['all']">
                          <ng-container *ngIf="pickSheetsGroup && pickSheetsGroup.packageType && (pickSheetsGroup.packageType ==='Envelope' && envelopes) || (pickSheetsGroup.packageType ==='4in Box' && fourInBoxes) || (pickSheetsGroup.packageType ==='Med Flat' && medFlatRate) || (pickSheetsGroup.packageType ==='X' && ground) || (pickSheetsGroup.packageType ==='GFP' && groundFreightPricing) || (pickSheetsGroup.packageType ==='Freight' && freight)">
                            <ng-container *ngIf="(pickSheetsGroup.tagType ==='Pure' && pure) || (pickSheetsGroup.tagType ==='PureMixed' && pureMixed) || (pickSheetsGroup.tagType ==='Single' && single) || (pickSheetsGroup.tagType ==='Only' && only) || (pickSheetsGroup.tagType ==='Mixed' && mixed) || (pickSheetsGroup.tagType ==='ABMixed' && abmixed)">
                              <ng-container *ngIf="(pickSheetsGroup.type ==='Individual' && individual) || (pickSheetsGroup.type ==='Bulk' && bulk)">
                                <app-detailed-picksheets-group [pickSheetsGroup]="pickSheetsGroup" [inventoryLevelsComputer]="inventoryLevlesComputer"></app-detailed-picksheets-group>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <div i18n="@@Inventory Levels">Inventory Levels</div>
              <div class="inventoryLevlesContainer">
                <div class="groups">
                  <div class="inventoryLevels">
                    <table>
                      <tr><td i18n="@@Grade">Grade</td><td colspan="4">KA</td></tr>
                      <tr><td i18n="@@Title">Title</td><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <td i18n="@@Selected">Selected</td>
                        <ng-container *ngFor="let i of [1,2,3,4]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <td i18n="@@On Hand After Selected">On Hand After Selected</td>
                        <ng-container *ngFor="let i of [1,2,3,4]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <td i18n="@@Ordered">Ordered</td>
                        <ng-container *ngFor="let i of [1,2,3,4]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <td i18n="@@Committed">Committed</td>
                        <ng-container *ngFor="let i of [1,2,3,4]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <td i18n="@@On Hand">On Hand</td>
                        <ng-container *ngFor="let i of [1,2,3,4]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <td i18n="@@Net Inventory">Net Inventory</td>
                        <ng-container *ngFor="let i of [1,2,3,4]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <td></td>
                        <ng-container *ngFor="let i of [1,2,3,4]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                    <table>
                      <tr><td colspan="4">1A</td></tr>
                      <tr><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <ng-container *ngFor="let i of [5,6,7,8]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [5,6,7,8]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [5,6,7,8]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [5,6,7,8]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [5,6,7,8]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [5,6,7,8]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [5,6,7,8]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                    <table>
                      <tr><td colspan="4">2A</td></tr>
                      <tr><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <ng-container *ngFor="let i of [9,10,11,12]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [9,10,11,12]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [9,10,11,12]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [9,10,11,12]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [9,10,11,12]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [9,10,11,12]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [9,10,11,12]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                    <table>
                      <tr><td colspan="4">3A</td></tr>
                      <tr><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <ng-container *ngFor="let i of [13,14,15,16]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [13,14,15,16]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [13,14,15,16]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [13,14,15,16]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [13,14,15,16]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [13,14,15,16]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [13,14,15,16]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                    <table>
                      <tr><td colspan="4">4A</td></tr>
                      <tr><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <ng-container *ngFor="let i of [17,18,19,20]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [17,18,19,20]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [17,18,19,20]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [17,18,19,20]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [17,18,19,20]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [17,18,19,20]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [17,18,19,20]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                    <table>
                      <tr><td colspan="4">5A</td></tr>
                      <tr><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <ng-container *ngFor="let i of [21,22,23,24]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [21,22,23,24]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [21,22,23,24]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [21,22,23,24]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [21,22,23,24]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [21,22,23,24]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [21,22,23,24]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                  </div>
                  <div class="inventoryLevels">
                    <table>
                      <tr><td colspan="4">KB</td></tr>
                      <tr><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <ng-container *ngFor="let i of [25,26,27,28]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [25,26,27,28]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [25,26,27,28]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [25,26,27,28]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [25,26,27,28]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [25,26,27,28]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [25,26,27,28]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                    <table>
                      <tr><td colspan="4">1B</td></tr>
                      <tr><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <ng-container *ngFor="let i of [29,30,31,32]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [29,30,31,32]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [29,30,31,32]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [29,30,31,32]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [29,30,31,32]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [29,30,31,32]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [29,30,31,32]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                    <table>
                      <tr><td colspan="4">2B</td></tr>
                      <tr><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <ng-container *ngFor="let i of [33,34,35,36]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [33,34,35,36]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [33,34,35,36]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [33,34,35,36]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [33,34,35,36]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [33,34,35,36]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [33,34,35,36]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                    <table>
                      <tr><td colspan="4">3B</td></tr>
                      <tr><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <ng-container *ngFor="let i of [37,38,39,40]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [37,38,39,40]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [37,38,39,40]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [37,38,39,40]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [37,38,39,40]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [37,38,39,40]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [37,38,39,40]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                    <table>
                      <tr><td colspan="4">4B</td></tr>
                      <tr><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <ng-container *ngFor="let i of [41,42,43,44]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [41,42,43,44]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [41,42,43,44]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [41,42,43,44]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [41,42,43,44]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [41,42,43,44]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [41,42,43,44]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                    <table>
                      <tr><td colspan="4">5B</td></tr>
                      <tr><td>LA</td><td>MA</td><td>SC</td><td>SS</td></tr>
                      <tr>
                        <ng-container *ngFor="let i of [45,46,47,48]">
                          <td>{{inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [45,46,47,48]">
                          <td>{{inventoryOnHand[i] - inventoryNeededForSelectedPickSheets[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [45,46,47,48]">
                          <td>{{inventoryOnOrder[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [45,46,47,48]">
                          <td>{{inventoryCommited[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [45,46,47,48]">
                          <td>{{inventoryOnHand[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [45,46,47,48]">
                          <td>{{inventoryNet[i]}}</td>
                        </ng-container>
                      </tr>
                      <tr>
                        <ng-container *ngFor="let i of [45,46,47,48]">
                          <td><div class="bar"><div class="insideBar" [style.height]="(100 * (1.0001 - ( inventoryNeededForSelectedPickSheets[i] / maxInventoryNeeded ) ) ) + '%'"></div></div></td>
                        </ng-container>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <section class="filters-section">
              <div class="buttons" *ngIf="displayButtons && this.startupService.selectedRoleId === '1'">
                <button style="width: auto; height: 48px; font-size: 20pt; padding: 20px;" mat-icon-button (click)="printSelectedPickSheetsGroups('B'); displayButtons = false;"><mat-icon>print</mat-icon><span i18n="@@Print Selected Pick Sheets 24th Small Printer">Print Selected Pick Sheets 24th Small Printer</span></button>
                <button style="width: auto; height: 48px; font-size: 20pt; padding: 20px;" mat-icon-button (click)="printSelectedPickSheetsGroups('A'); displayButtons = false;"><mat-icon>print</mat-icon><span i18n="@@Print Selected Pick Sheets 24th Titan">Print Selected Pick Sheets 24th Titan</span></button>
                <button style="width: auto; height: 48px; font-size: 20pt; padding: 20px;" mat-icon-button (click)="printSelectedPickAndShipSheetsGroups('B'); displayButtons = false;"><mat-icon>print</mat-icon><span i18n="@@Print Selected Pick and Ship Sheets 24th Small Printer">Print Selected Pick and Ship Sheets 24th Small Printer</span></button>
                <button style="width: auto; height: 48px; font-size: 20pt; padding: 20px;" mat-icon-button (click)="printSelectedPickAndShipSheetsGroups('A'); displayButtons = false;"><mat-icon>print</mat-icon><span i18n="@@Print Selected Pick and Ship Sheets 24th Oce">Print Selected Pick and Ship Sheets 24th Oce</span></button>
              </div>
              <div class="buttons" *ngIf="displayButtons && this.startupService.selectedRoleId === '5'">
                <button style="width: auto; height: 48px; font-size: 20pt; padding: 20px;" mat-icon-button (click)="printSelectedPickSheetsGroups('B'); displayButtons = false;"><mat-icon>print</mat-icon><span>Print Selected Pick Sheets Station 1</span></button>
                <button style="width: auto; height: 48px; font-size: 20pt; padding: 20px;" mat-icon-button (click)="printSelectedPickSheetsGroups('A'); displayButtons = false;"><mat-icon>print</mat-icon><span>Print Selected Pick Sheets Station 2</span></button>
                <button style="width: auto; height: 48px; font-size: 20pt; padding: 20px;" mat-icon-button (click)="printSelectedPickAndShipSheetsGroups('A'); displayButtons = false;"><mat-icon>print</mat-icon><span>Print Selected Pick and Ship Sheets Station 1</span></button>
                <button style="width: auto; height: 48px; font-size: 20pt; padding: 20px;" mat-icon-button (click)="printSelectedPickAndShipSheetsGroups('B'); displayButtons = false;"><mat-icon>print</mat-icon><span>Print Selected Pick and Ship Sheets Station 2</span></button>
              </div>
            </section>
          </ng-container>
          <ng-container *ngIf="!pickSheetsGroups"><h2 style="padding: 20px; text-align: center;" i18n="@@Loading Data">Loading Data</h2><mat-spinner style="margin:0 auto;"></mat-spinner></ng-container>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






