<div *ngIf="areImagesLoading()" style="width: 100%">
  <mat-card appearance="outlined">
    <mat-card-content>
      <h2 i18n="@@LoadingImages" style="margin-left: auto; margin-right: auto;">Loading Images</h2>
      <mat-progress-spinner class="imagesLoading" color="primary" mode="determinate" diameter="500" [value]="100-(imagesToLoad.length/totalImagesToLoad*100)" style="margin-left: auto; margin-right: auto;"></mat-progress-spinner>
      <div style=" position:relative; top: -350px; margin-left: auto; margin-right: auto; height: 200px; width: 300px; align-content: center;">
        <p style="font-size:3em; text-align: center">{{(100-imagesToLoad.length/totalImagesToLoad*100).toFixed(0)}}%</p>
        <p style="font-size:0.6em" *ngFor="let imageToLoad of imagesToLoad">{{imageToLoad}}</p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div id="coverCanvasContainer" class="coverCanvasContainer">
  <div class="floatingToolStrip" *ngIf="canvasManager && canvasManager.getSelected()" [class.floatingToolStripLeft]="startupService.floatingToolStripLeft" [class.floatingToolStripRight]="!startupService.floatingToolStripLeft" [class.navigationDrawOpen]="mainSideNavIsOpen" [ngClass]="activeSideArea ? 'areaDrawOpen' : ''" >
    <ng-container *ngIf="startupService.getSubscription().getSubscriptionLevel() === 'DESIGNER'">
      <div class="toolStripItem">
        <button mat-button (click)="designerMode = !designerMode"><mat-icon *ngIf="designerMode" title="Switch to Designer Tools" i18n-title="@@Switch to Designer Tools">person</mat-icon><mat-icon *ngIf="!designerMode" title="Switch to User Tools" i18n-title="@@Switch to User Tools">person_outline</mat-icon></button>
      </div>
    </ng-container>
    <!-- START NORMAL FLOATING TOOL BAR -->
    <ng-container *ngIf="!designerMode">
      <!--<div class="toolStripItem" *ngIf="canvasManager.getType().toString() === 'textbox'">-->
        <!--<button mat-button (click)="displayToolArea === 'edit' ? displayToolArea = '' : displayToolArea = 'edit'"><mat-icon>edit</mat-icon></button>-->
        <!--<div *ngIf="displayToolArea === 'edit'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">-->
          <!--<span class="title" i18n="@@Edit Text">Edit Text</span>-->
          <!--<div class="toolAreaSection">-->
            <!--<div class="toolAreaSlider">-->
              <!--<mat-slide-toggle #editText min="0" max="100" [checked]="canvasManager.getEditText()" (change)="canvasManager.setEditText(!canvasManager.getEditText())"></mat-slide-toggle>-->
            <!--</div>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <div class="toolStripItem" *ngIf="canvasManager.getType().toString() === 'textbox'">
        <button mat-button (click)="increaseFontSize()" title="Increase Font Size" i18n-title="@@Increase Font Size"><span class="mdi mdi-format-font-size-increase"></span></button>
      </div>
      <div class="toolStripItem" *ngIf="canvasManager.getType().toString() === 'textbox'">
        <button mat-button (click)="decreaseFontSize()" title="Decrease Font Size" i18n-title="@@Decrease Font Size"><span class="mdi mdi-format-font-size-decrease"></span></button>
      </div>
      <div class="toolStripItem" *ngIf="canvasManager.getType().toString() === 'textbox'">
        <button mat-button (click)="displayToolArea === 'text' ? displayToolArea = '' : displayToolArea = 'text'" title="Show/Hide Text Tools" i18n-title="@@Show/Hide Text Tools"><mat-icon>text_fields</mat-icon></button>
        <div *ngIf="displayToolArea === 'text'"  [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft"  style="top: -10px">
          <span class="title" i18n="@@Text Options">Text Options</span>
          <div class="textOptions">

            <!-- Text formatting for the whole text area -->
            <ng-container *ngIf="!canvasManager.getEditText()">
              <div class="toolAreaSection">
                <div class="toolAreaItem"> <!-- designer font-family select -->
                  <!--<span class="subTitle" i18n="@@Font">Font</span>-->
                  <!-- TODO: Deal with the difference between fontFamily actual value and the position value-->
                  <mat-button-toggle-group style="display: grid" #fontFamily="matButtonToggleGroup"  [value]="cover.getImagePropertyString(role, 'fontFamily', '')" (change)="canvasManager.setOverridePropertyString('fontFamily', fontFamily.value); saveProject();" >
                    <mat-button-toggle value="" style="line-height: 24px" title="Use the cover design selected font" i18n-title="@@Use the cover design selected font">{{ 'Design Selected Font' | languageString }} <br> {{ cover.fontSettings.getFontFromKey(canvasManager.getThemeItem().getFontFamily(), '') | languageString }}</mat-button-toggle>
                    <mat-button-toggle value="<primary>" [style.fontFamily]="cover.fontSettings.primary" title="Use font #1 from the current font collection" i18n-title="@@Use font #1 from the current font collection">{{ cover.fontSettings.primary | languageString }}</mat-button-toggle>
                    <mat-button-toggle value="<secondary>" [style.fontFamily]="cover.fontSettings.secondary" title="Use font #2 from the current font collection" i18n-title="@@Use font #2 from the current font collection">{{ cover.fontSettings.secondary | languageString }}</mat-button-toggle>
                    <mat-button-toggle value="<tertiary>" [style.fontFamily]="cover.fontSettings.tertiary" title="Use font #3 from the current font collection" i18n-title="@@Use font #3 from the current font collection">{{ cover.fontSettings.tertiary | languageString }}</mat-button-toggle>
                    <mat-button-toggle value="<accent>" [style.fontFamily]="cover.fontSettings.accent" title="Use font #4 from the current font collection" i18n-title="@@Use font #4 from the current font collection">{{ cover.fontSettings.accent | languageString }}</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>

                <div class="toolAreaItem">
                  <mat-select #fontSizeSelect [ngModel]="getFontSize()" required style="width: 70%" (selectionChange)="setFontSize(fontSizeSelect.value); saveProject();" [ngModelOptions]="{standalone: true}" title="Change the font size" i18n-title="@@Change the font size">
                    <mat-option value="" i18n="@@Inherit">Inherit</mat-option>
                    <mat-option value="6" i18n="@@6pt">6pt</mat-option>
                    <mat-option value="7" i18n="@@7pt">7pt</mat-option>
                    <mat-option value="8" i18n="@@8pt">8pt</mat-option>
                    <mat-option value="9" i18n="@@9pt">9pt</mat-option>
                    <mat-option value="10" i18n="@@10pt">10pt</mat-option>
                    <mat-option value="11" i18n="@@11pt">11pt</mat-option>
                    <mat-option value="12" i18n="@@12pt">12pt</mat-option>
                    <mat-option value="13" i18n="@@13pt">13pt</mat-option>
                    <mat-option value="14" i18n="@@14pt">14pt</mat-option>
                    <mat-option value="15" i18n="@@15pt">15pt</mat-option>
                    <mat-option value="16" i18n="@@16pt">16pt</mat-option>
                    <mat-option value="17" i18n="@@17pt">17pt</mat-option>
                    <mat-option value="18" i18n="@@18pt">18pt</mat-option>
                    <mat-option value="20" i18n="@@20pt">20pt</mat-option>
                    <mat-option value="22" i18n="@@22pt">22pt</mat-option>
                    <mat-option value="24" i18n="@@24pt">24pt</mat-option>
                    <mat-option value="26" i18n="@@26pt">26pt</mat-option>
                    <mat-option value="28" i18n="@@28pt">28pt</mat-option>
                    <mat-option value="30" i18n="@@30pt">30pt</mat-option>
                    <mat-option value="32" i18n="@@32pt">32pt</mat-option>
                    <mat-option value="34" i18n="@@34pt">34pt</mat-option>
                    <mat-option value="36" i18n="@@36pt">36pt</mat-option>
                    <mat-option value="38" i18n="@@38pt">38pt</mat-option>
                    <mat-option value="40" i18n="@@40pt">40pt</mat-option>
                    <mat-option value="42" i18n="@@42pt">42pt</mat-option>
                    <mat-option value="44" i18n="@@44pt">44pt</mat-option>
                    <mat-option value="46" i18n="@@46pt">46pt</mat-option>
                    <mat-option value="48" i18n="@@48pt">48pt</mat-option>
                    <mat-option value="50" i18n="@@50pt">50pt</mat-option>
                    <mat-option value="52" i18n="@@52pt">52pt</mat-option>
                    <mat-option value="54" i18n="@@54pt">54pt</mat-option>
                    <mat-option value="56" i18n="@@56pt">56pt</mat-option>
                    <mat-option value="58" i18n="@@58pt">58pt</mat-option>
                    <mat-option value="60" i18n="@@60pt">60pt</mat-option>
                    <mat-option value="64" i18n="@@64pt">64pt</mat-option>
                    <mat-option value="68" i18n="@@68pt">68pt</mat-option>
                    <mat-option value="72" i18n="@@72pt">72pt</mat-option>
                    <mat-option value="76" i18n="@@76pt">76pt</mat-option>
                    <mat-option value="80" i18n="@@80pt">80pt</mat-option>
                    <mat-option value="84" i18n="@@84pt">84pt</mat-option>
                    <mat-option value="88" i18n="@@88pt">88pt</mat-option>
                    <mat-option value="92" i18n="@@92pt">92pt</mat-option>
                    <mat-option value="96" i18n="@@96pt">96pt</mat-option>
                    <mat-option value="100" i18n="@@100pt">100pt</mat-option>
                    <mat-option value="104" i18n="@@104pt">104pt</mat-option>
                    <mat-option value="108" i18n="@@108pt">108pt</mat-option>
                    <mat-option value="116" i18n="@@116pt">116pt</mat-option>
                    <mat-option value="120" i18n="@@120pt">120pt</mat-option>
                    <mat-option value="128" i18n="@@128pt">128pt</mat-option>
                    <mat-option value="132" i18n="@@132pt">132pt</mat-option>
                    <mat-option value="140" i18n="@@140pt">140pt</mat-option>
                    <mat-option value="148" i18n="@@148pt">148pt</mat-option>
                    <mat-option value="156" i18n="@@156pt">156pt</mat-option>
                    <mat-option value="164" i18n="@@164pt">168pt</mat-option>
                    <mat-option value="172" i18n="@@172pt">172pt</mat-option>
                    <mat-option value="180" i18n="@@180pt">180pt</mat-option>
                    <mat-option value="188" i18n="@@188pt">188pt</mat-option>
                    <mat-option value="196" i18n="@@196pt">196pt</mat-option>
                    <mat-option value="240" i18n="@@240pt">240pt</mat-option>
                    <mat-option value="264" i18n="@@264pt">264pt</mat-option>
                    <mat-option value="288" i18n="@@288pt">288pt</mat-option>
                  </mat-select>
                </div>

                <div class="toolAreaItem"> <!-- font-weight, font-type -->
                </div>
              </div>
              <div class="toolAreaSection">
                <div class="toolAreaItem">
                  <mat-form-field>
                    <mat-label i18n="@@Text">Text</mat-label>
                    <input matInput #textInput i18n-placeholder="@@Text" placeholder="Text" type="text" maxlength="100" [ngModel]="canvasManager.getText()" (change)="canvasManager.setOverridePropertyString('text', textInput.value); saveProject();" [ngModelOptions]="{standalone: true}" title="Text of the current component" i18n-title="@@Text of the current component"/>
                  </mat-form-field>
                </div>
                <div class="toolAreaItem"> <!-- font-weight, font-type -->
                  <!--<mat-button-toggle>-->
                  <!--<mat-icon>format_bold</mat-icon>-->
                  <!--</mat-button-toggle>-->
                  <!--<mat-button-toggle>-->
                  <!--<mat-icon>format_italic</mat-icon>-->
                  <!--</mat-button-toggle>-->
                  <!--<button mat-button><mat-icon>format_italic</mat-icon></button>-->
                  <!--<button mat-button><mat-icon>format_underline</mat-icon></button>-->
                </div>
                <div class="toolAreaItem"> <!-- alignment -->
                  <mat-button-toggle-group #group="matButtonToggleGroup" [value]="canvasManager.getTextAlign()" (change)="canvasManager.setOverridePropertyString('textAlign', group.value); canvasManager.setTextAlign(group.value); saveProject();">
                    <mat-button-toggle value="left" title="Left Align Text" i18n-title="@@Left Align Text">
                      <mat-icon>format_align_left</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="center" title="Center Align Text" i18n-title="@@Center Align Text">
                      <mat-icon>format_align_center</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="right" title="Right Align Text" i18n-title="@@Right Align Text">
                      <mat-icon>format_align_right</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="justify" title="Justify Text" i18n-title="@@Justify Text">
                      <mat-icon>format_align_justify</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="" title="Use the Design Specified Text Alignment" i18n-title="@@Use the Design Specified Text Alignment">
                      <mat-icon>block</mat-icon>
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
              <div class="toolAreaSection">
                <div class="toolAreaSlider"> <!-- line-height -->
                  <span i18n="@@Line Height">Line Height</span>
                  <!-- TODO: The 'tickInterval' property no longer exists -->
                  <mat-slider #lineHeightSlider min="0.5" max="5" step="0.05" thumbLabel="true" title="Adjust Line Height" i18n-title="@@Adjust Line Height" #ngSlider><input matSliderThumb [value]="canvasManager.getLineHeight()" (change)="canvasManager.setOverridePropertyNumber('lineHeight', lineHeightSlider.value); canvasManager.setLineHeight(lineHeightSlider.value); saveProject();" #ngSliderThumb="matSliderThumb" /></mat-slider>
                </div>
                <div class="toolAreaSlider"> <!-- spacing -->
                  <span i18n="@@Spacing">Spacing</span>
                  <!-- TODO: The 'tickInterval' property no longer exists -->
                  <mat-slider #spacingSlider min="-200" max="400" step="1" thumbLabel="true" title="Adjust Character Spacing" i18n-title="@@Adjust Character Spacing" #ngSlider><input matSliderThumb [value]="canvasManager.getCharSpacing()" (change)="canvasManager.setOverridePropertyNumber('charSpacing', spacingSlider.value); canvasManager.setCharSpacing(spacingSlider.value); saveProject();" #ngSliderThumb="matSliderThumb" /></mat-slider>
                </div>
              </div>
              <div class="toolAreaSection">
                <div class="toolAreaItem">
                  <button mat-raised-button color="warn" (click)="canvasManager.clearOverrideProperty('textAlign');canvasManager.clearOverrideProperty('fontSize');canvasManager.clearOverrideProperty('fontWeight');canvasManager.clearOverrideProperty('fontType'); canvasManager.clearOverrideProperty('lineHeight'); canvasManager.clearOverrideProperty('charSpacing'); canvasManager.clearOverrideProperty('textStyles'); canvasManager.clearOverrideProperty('fontFamily'); saveProject();" title="Reset changes to the design values for this text element" i18n-title="@@Reset changes to the design values for this text element"><ng-container i18n="@@Clear Text Settings">Clear Text Settings</ng-container><mat-icon>format_clear</mat-icon></button>
                </div>
              </div>
            </ng-container>
            <!-- Text formatting for the selected text within a text area -->
            <ng-container *ngIf="canvasManager.getEditText()">
              <div class="toolAreaSection">
                <div class="toolAreaItem"> <!-- designer font-family select -->
                  <mat-button-toggle-group style="display: grid" #fontFamilySelectedText="matButtonToggleGroup"  [value]="canvasManager.getFontFamilySelectedText()" (change)="canvasManager.setFontFamilySelectedText(fontFamilySelectedText.value); saveProject();" >
                    <mat-button-toggle value="" style="line-height: 24px" title="Use the cover design selected font" i18n-title="@@Use the cover design selected font">{{ 'Design Selected Font' | languageString }} <br> {{ cover.fontSettings.getFontFromKey(canvasManager.getThemeItem().getFontFamily(), '') | languageString }}</mat-button-toggle>
                    <mat-button-toggle [value]="cover.fontSettings.primary" [style.fontFamily]="cover.fontSettings.primary" title="Use font #1 from the current font collection" i18n-title="@@Use font #1 from the current font collection">{{ cover.fontSettings.primary | languageString }}</mat-button-toggle>
                    <mat-button-toggle [value]="cover.fontSettings.secondary" [style.fontFamily]="cover.fontSettings.secondary" title="Use font #2 from the current font collection" i18n-title="@@Use font #2 from the current font collection">{{ cover.fontSettings.secondary | languageString }}</mat-button-toggle>
                    <mat-button-toggle [value]="cover.fontSettings.tertiary" [style.fontFamily]="cover.fontSettings.tertiary" title="Use font #3 from the current font collection" i18n-title="@@Use font #3 from the current font collection">{{ cover.fontSettings.tertiary | languageString }}</mat-button-toggle>
                    <mat-button-toggle [value]="cover.fontSettings.accent" [style.fontFamily]="cover.fontSettings.accent" title="Use font #4 from the current font collection" i18n-title="@@Use font #4 from the current font collection">{{ cover.fontSettings.accent | languageString }}</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>

                <div class="toolAreaItem">
                  <mat-select #fontSizeSelectedTextSelect [ngModel]="canvasManager.getFontSize()" required style="width: 70%" (selectionChange)="canvasManager.setFontSize(fontSizeSelectedTextSelect.value); saveProject();" [ngModelOptions]="{standalone: true}" title="Change the font size" i18n-title="@@Change the font size">
                    <mat-option value="" i18n="@@Inherit">Inherit</mat-option>
                    <mat-option value="6" i18n="@@6pt">6pt</mat-option>
                    <mat-option value="7" i18n="@@7pt">7pt</mat-option>
                    <mat-option value="8" i18n="@@8pt">8pt</mat-option>
                    <mat-option value="9" i18n="@@9pt">9pt</mat-option>
                    <mat-option value="10" i18n="@@10pt">10pt</mat-option>
                    <mat-option value="11" i18n="@@11pt">11pt</mat-option>
                    <mat-option value="12" i18n="@@12pt">12pt</mat-option>
                    <mat-option value="13" i18n="@@13pt">13pt</mat-option>
                    <mat-option value="14" i18n="@@14pt">14pt</mat-option>
                    <mat-option value="15" i18n="@@15pt">15pt</mat-option>
                    <mat-option value="16" i18n="@@16pt">16pt</mat-option>
                    <mat-option value="17" i18n="@@17pt">17pt</mat-option>
                    <mat-option value="18" i18n="@@18pt">18pt</mat-option>
                    <mat-option value="20" i18n="@@20pt">20pt</mat-option>
                    <mat-option value="22" i18n="@@22pt">22pt</mat-option>
                    <mat-option value="24" i18n="@@24pt">24pt</mat-option>
                    <mat-option value="26" i18n="@@26pt">26pt</mat-option>
                    <mat-option value="28" i18n="@@28pt">28pt</mat-option>
                    <mat-option value="30" i18n="@@30pt">30pt</mat-option>
                    <mat-option value="32" i18n="@@32pt">32pt</mat-option>
                    <mat-option value="34" i18n="@@34pt">34pt</mat-option>
                    <mat-option value="36" i18n="@@36pt">36pt</mat-option>
                    <mat-option value="38" i18n="@@38pt">38pt</mat-option>
                    <mat-option value="40" i18n="@@40pt">40pt</mat-option>
                    <mat-option value="42" i18n="@@42pt">42pt</mat-option>
                    <mat-option value="44" i18n="@@44pt">44pt</mat-option>
                    <mat-option value="46" i18n="@@46pt">46pt</mat-option>
                    <mat-option value="48" i18n="@@48pt">48pt</mat-option>
                    <mat-option value="50" i18n="@@50pt">50pt</mat-option>
                    <mat-option value="52" i18n="@@52pt">52pt</mat-option>
                    <mat-option value="54" i18n="@@54pt">54pt</mat-option>
                    <mat-option value="56" i18n="@@56pt">56pt</mat-option>
                    <mat-option value="58" i18n="@@58pt">58pt</mat-option>
                    <mat-option value="60" i18n="@@60pt">60pt</mat-option>
                    <mat-option value="64" i18n="@@64pt">64pt</mat-option>
                    <mat-option value="68" i18n="@@68pt">68pt</mat-option>
                    <mat-option value="72" i18n="@@72pt">72pt</mat-option>
                    <mat-option value="76" i18n="@@76pt">76pt</mat-option>
                    <mat-option value="80" i18n="@@80pt">80pt</mat-option>
                    <mat-option value="84" i18n="@@84pt">84pt</mat-option>
                    <mat-option value="88" i18n="@@88pt">88pt</mat-option>
                    <mat-option value="92" i18n="@@92pt">92pt</mat-option>
                    <mat-option value="96" i18n="@@96pt">96pt</mat-option>
                    <mat-option value="100" i18n="@@100pt">100pt</mat-option>
                    <mat-option value="104" i18n="@@104pt">104pt</mat-option>
                    <mat-option value="108" i18n="@@108pt">108pt</mat-option>
                    <mat-option value="116" i18n="@@116pt">116pt</mat-option>
                    <mat-option value="120" i18n="@@120pt">120pt</mat-option>
                    <mat-option value="128" i18n="@@128pt">128pt</mat-option>
                    <mat-option value="132" i18n="@@132pt">132pt</mat-option>
                    <mat-option value="140" i18n="@@140pt">140pt</mat-option>
                    <mat-option value="148" i18n="@@148pt">148pt</mat-option>
                    <mat-option value="156" i18n="@@156pt">156pt</mat-option>
                    <mat-option value="164" i18n="@@164pt">168pt</mat-option>
                    <mat-option value="172" i18n="@@172pt">172pt</mat-option>
                    <mat-option value="180" i18n="@@180pt">180pt</mat-option>
                    <mat-option value="188" i18n="@@188pt">188pt</mat-option>
                    <mat-option value="196" i18n="@@196pt">196pt</mat-option>
                    <mat-option value="240" i18n="@@240pt">240pt</mat-option>
                    <mat-option value="264" i18n="@@264pt">264pt</mat-option>
                    <mat-option value="288" i18n="@@288pt">288pt</mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="toolAreaSection">
                <div class="toolAreaItem">
                  <button mat-button (click)="canvasManager.setBold(!canvasManager.isBold()); saveProject();" [class.selected]="canvasManager.isBold()" title="Bold/Unbold text" i18n-title="@@Bold/Unbold text"><mat-icon>format_bold</mat-icon></button>
                  <button mat-button (click)="canvasManager.toggleItalic(); saveProject();" [class.selected]="canvasManager.isItalic()" title="Italicize/Unitalicize text" i18n-title="@@Italicize/Unitalicize text"><mat-icon>format_italic</mat-icon></button>
                  <button mat-button (click)="canvasManager.toggleUnderline(); saveProject();" [class.selected]="canvasManager.isUnderline()" title="Underline/Ununderline text" i18n-title="@@Underline/Ununderline text"><mat-icon>format_underline</mat-icon></button>
                </div>
              </div>
              <div class="toolAreaSection">
                <!--<div class="toolAreaSlider"> &lt;!&ndash; line-height &ndash;&gt;-->
                <!--<span i18n="@@Line Height">Line Height</span><mat-slider #lineHeightSlider min="0.5" max="5" step="0.1" thumbLabel="true" tickInterval="0.5" [value]="canvasManager.getLineHeight()" (change)="canvasManager.setOverridePropertyNumber('lineHeight', lineHeightSlider.value); canvasManager.setLineHeight(lineHeightSlider.value)"></mat-slider><input type="number">-->
                <!--</div>-->
                <!--<div class="toolAreaSlider"> &lt;!&ndash; spacing &ndash;&gt;-->
                <!--<span i18n="@@Spacing">Spacing</span><mat-slider #spacingSlider min="-200" max="400" step="1" thumbLabel="true" tickInterval="10" [value]="canvasManager.getCharSpacing()" (change)="canvasManager.setOverridePropertyNumber('charSpacing', spacingSlider.value); canvasManager.setCharSpacing(spacingSlider.value)"></mat-slider><input type="number">-->
                <!--</div>-->
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="toolStripItem" *ngIf="canvasManager.getType().toString() === 'image'">
        <button mat-button (click)="displayToolArea === 'curratedImageOptions' ? displayToolArea = '' : displayToolArea = 'curratedImageOptions'" title="Show/Hide Currated Image Options" i18n-title="@@Show/Hide Currated Image Options"><mat-icon>image</mat-icon></button>
        <div *ngIf="displayToolArea === 'curratedImageOptions'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
          <span class="title" i18n="@@Currated Image Options">Currated Image Options</span>
          <div class="imageOptions">
            <div class="image" *ngFor="let imageOption of canvasManager.getThemeItem().imageOptions" (click)="canvasManager.setImageOption(imageOption.imageKey, imageOption.horizontalAdjustment, imageOption.verticalAdjustment, imageOption.scaleAdjustment); saveProject();">
              <!-- if designer have a remove imageOption button -->
              <button *ngIf="startupService.getSubscription().getSubscriptionLevel() === 'DESIGNER'" mat-button (click)="canvasManager.getThemeItem().removeImageOption(imageOption); saveProject();" title="Remove Image Option From Cover Design" i18n-title="@@Remove Image Option From Cover Design"><mat-icon>delete</mat-icon></button>
              <img [src]="imageService.getFreshImageUrlFromImageKey(imageOption.imageKey, true)" [id]="imageOption.imageKey + '-thumbnailX'" (click)="imageSelected(imageOption.imageKey); saveProject();" title="Select This Image For The Current Image Object" i18n-title="@@Select This Image For the Current Image Object">
              <span style="font-size: 0.2em">{{imageOption.imageKey}} {{imageOption.verticalAdjustment}} {{imageOption.horizontalAdjustment}} {{imageOption.scaleAdjustment}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="toolStripItem" *ngIf="canvasManager.getType().toString() === 'image'">
        <button mat-button (click)="displayToolArea === 'myImageOptions' ? displayToolArea = '' : displayToolArea = 'myImageOptions'" title="Show/Hide My Image Options" i18n-title="@@Show/Hide My Image Options"><span class="mdi mdi-file-image"></span></button>
        <div *ngIf="displayToolArea === 'myImageOptions'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
          <span class="title" i18n="@@My Images">My Images</span>
          <div class="imageOptions" *ngIf="canvasManager.getThemeItem().getImageType() === 'background'">
            <div class="image" *ngFor="let image of userBackgroundLibrary" (click)="canvasManager.setImageOption( image.key, 0, 0, 0 ); saveProject();">
              <img [src]="imageService.getFreshImageUrlFromImageKey(image.key, true)" [id]="image.key + '-thumbnailX'" (click)="imageSelected(image.key); saveProject();" title="Select This Image For The Current Image Object" i18n-title="@@Select This Image For the Current Image Object">
              <span style="font-size: 0.2em">{{image.key}}</span>
            </div>
          </div>
          <div class="imageOptions" *ngIf="canvasManager.getThemeItem().getImageType() === 'transparent'">
            <div class="image" *ngFor="let image of userTransparentLibrary" (click)="canvasManager.setImageOption( image.key, 0, 0, 0 ); saveProject();">
              <img [src]="imageService.getFreshImageUrlFromImageKey(image.key, true)" [id]="image.key + '-thumbnailX'" (click)="imageSelected(image.key); saveProject();" title="Select This Image For The Current Image Object" i18n-title="@@Select This Image For the Current Image Object">
              <span style="font-size: 0.2em">{{image.key}}</span>
            </div>
          </div>
          <div class="imageOptions" *ngIf="canvasManager.getThemeItem().getImageType() === 'logo'">
            <div class="image" *ngFor="let image of userLogoLibrary" (click)="canvasManager.setImageOption( image.key, 0, 0, 0 ); saveProject();">
              <img [src]="imageService.getFreshImageUrlFromImageKey(image.key, true)" [id]="image.key + '-thumbnailX'" (click)="imageSelected(image.key); saveProject();" title="Select This Image For The Current Image Object" i18n-title="@@Select This Image For the Current Image Object">
              <span style="font-size: 0.2em">{{image.key}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="toolStripItem" *ngIf="canvasManager.getType().toString() === 'image' && canvasManager.getThemeItem().getImageType() !== 'logo'">
        <button mat-button (click)="displayToolArea === 'libraryImageOptions' ? displayToolArea = '' : displayToolArea = 'libraryImageOptions'" title="Show/Hide Library Image Options" i18n-title="@@Show/Hide Library Image Options"><span class="mdi mdi-camera-image"></span></button>
        <div *ngIf="displayToolArea === 'libraryImageOptions'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
          <span class="title" i18n="@@Library Images">Library Images</span>
          <div class="imageOptions" *ngIf="canvasManager.getThemeItem().getImageType() === 'background'">
            <div class="image" *ngFor="let image of backgroundLibrary" (click)="canvasManager.setImageOption( image.key, 0, 0, 0 ); saveProject();">
              <img [src]="imageService.getFreshImageUrlFromImageKey(image.key, true)" [id]="image.key + '-thumbnailX'" (click)="imageSelected(image.key); saveProject();" title="Select This Image For The Current Image Object" i18n-title="@@Select This Image For the Current Image Object">
              <span style="font-size: 0.2em">{{image.key}}</span>
            </div>
          </div>
          <div class="imageOptions" *ngIf="canvasManager.getThemeItem().getImageType() === 'transparent'">
            <div class="image" *ngFor="let image of transparentLibrary" (click)="canvasManager.setImageOption( image.key, 0, 0, 0 ); saveProject();">
              <img [src]="imageService.getFreshImageUrlFromImageKey(image.key, true)" [id]="image.key + '-thumbnailX'" (click)="imageSelected(image.key); saveProject();" title="Select This Image For The Current Image Object" i18n-title="@@Select This Image For the Current Image Object">
              <span style="font-size: 0.2em">{{image.key}}</span>
            </div>
          </div>
<!--          <div class="imageOptions" *ngIf="canvasManager.getThemeItem().getImageType() === 'logo'">-->
<!--            <div class="image" *ngFor="let image of logoLibrary" (click)="canvasManager.setImageOption( image.key, 0, 0, 0 ); saveProject();">-->
<!--              <img [src]="imageService.getFreshImageUrlFromImageKey(image.key, true)" [ID]="image.key + '-thumbnailX'" (click)="imageSelected(image.key); saveProject();" title="Select This Image For The Current Image Object" i18n-title="@@Select This Image For the Current Image Object">-->
<!--              <span style="font-size: 0.2em">{{image.key}}</span>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="toolStripItem" *ngIf="canvasManager.getType().toString() === 'image'">
        <button mat-button (click)="canvasManager.setImageOption( undefined, 0, 0, 0 ); saveProject();"><span title="Clear the current image. Cover Design default will still show if nothing is selected." i18n-title="@@Clear the current image. Cover Design default will still show if nothing is selected." class="mdi mdi-image-off"></span></button>
      </div>
      <div class="toolStripItem" *ngIf="canvasManager.getType().toString() !== 'image' && canvasManager.getType().toString() !== 'textbox'">
        <button mat-button (click)="displayToolArea === 'color' ? displayToolArea = '' : displayToolArea = 'color'" title="Show/Hide Fill Color Options" i18n-title="@@Show/Hide Fill Color Options"><mat-icon>format_color_fill</mat-icon></button>
        <div *ngIf="displayToolArea === 'color'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
          <span class="title" i18n="@@Color">Color</span>
          <div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<primary.normal>'" (click)="canvasManager.setOverridePropertyString( 'color', '<primary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.primary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<primary.dark>'" (click)="canvasManager.setOverridePropertyString( 'color', '<primary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.primary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<primary.light>'" (click)="canvasManager.setOverridePropertyString( 'color', '<primary.light>'); saveProject();" [style.background-color]="cover.colorPalette.primary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>

            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<secondary.normal>'" (click)="canvasManager.setOverridePropertyString( 'color', '<secondary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.secondary.normal.color"><span i18n="@@Secondary Normal">Secondary Normal</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<secondary.dark>'" (click)="canvasManager.setOverridePropertyString( 'color', '<secondary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.secondary.dark.color"><span i18n="@@Secondary Dark">Secondary Dark</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<secondary.light>'" (click)="canvasManager.setOverridePropertyString( 'color', '<secondary.light>'); saveProject();" [style.background-color]="cover.colorPalette.secondary.light.color"><span i18n="@@Secondary Light">Secondary Light</span><button mat-button></button></div>

            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<tertiary.normal>'" (click)="canvasManager.setOverridePropertyString( 'color', '<tertiary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.tertiary.normal.color"><span i18n="@@Tertiary Normal">Tertiary Normal</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<tertiary.dark>'" (click)="canvasManager.setOverridePropertyString( 'color', '<tertiary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.tertiary.dark.color"><span i18n="@@Tertiary Dark">Tertiary Dark</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<tertiary.light>'" (click)="canvasManager.setOverridePropertyString( 'color', '<tertiary.light>'); saveProject();" [style.background-color]="cover.colorPalette.tertiary.light.color"><span i18n="@@Tertiary Light">Tertiary Light</span><button mat-button></button></div>

            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<quaternary.normal>'" (click)="canvasManager.setOverridePropertyString( 'color', '<quaternary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.quaternary.normal.color"><span i18n="@@Quaternary Normal">Quaternary Normal</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<quaternary.dark>'" (click)="canvasManager.setOverridePropertyString( 'color', '<quaternary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.quaternary.dark.color"><span i18n="@@Quaternary Dark">Quaternary Dark</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<quaternary.light>'" (click)="canvasManager.setOverridePropertyString( 'color', '<quaternary.light>'); saveProject();" [style.background-color]="cover.colorPalette.quaternary.light.color"><span i18n="@@Quaternary Light">Quaternary Light</span><button mat-button></button></div>

            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<quinary.normal>'" (click)="canvasManager.setOverridePropertyString( 'color', '<quinary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.quinary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<quinary.dark>'" (click)="canvasManager.setOverridePropertyString( 'color', '<quinary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.quinary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<quinary.light>'" (click)="canvasManager.setOverridePropertyString( 'color', '<quinary.light>'); saveProject();" [style.background-color]="cover.colorPalette.quinary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>

            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<senary.normal>'" (click)="canvasManager.setOverridePropertyString( 'color', '<senary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.senary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<senary.dark>'" (click)="canvasManager.setOverridePropertyString( 'color', '<senary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.senary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'color', canvasManager.getThemeItem().getColor()) === '<senary.light>'" (click)="canvasManager.setOverridePropertyString( 'color', '<senary.light>'); saveProject();" [style.background-color]="cover.colorPalette.senary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>
          </div>
        </div>
      </div>
      <div class="toolStripItem" *ngIf="canvasManager.getType().toString() === 'textbox'">
        <button mat-button (click)="displayToolArea === 'fontColor' ? displayToolArea = '' : displayToolArea = 'fontColor'" title="Show/Hide Text Color Options" i18n-title="@@Show/Hide Text Color Options"><mat-icon>format_color_text</mat-icon></button>
        <div *ngIf="displayToolArea === 'fontColor'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
          <span class="title" i18n="@@Text Color">Text Color</span>
          <div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<primary.normal>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<primary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.primary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<primary.dark>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<primary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.primary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<primary.light>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<primary.light>'); saveProject();" [style.background-color]="cover.colorPalette.primary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>

            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<secondary.normal>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<secondary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.secondary.normal.color"><span i18n="@@Secondary Normal">Secondary Normal</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<secondary.dark>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<secondary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.secondary.dark.color"><span i18n="@@Secondary Dark">Secondary Dark</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<secondary.light>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<secondary.light>'); saveProject();" [style.background-color]="cover.colorPalette.secondary.light.color"><span i18n="@@Secondary Light">Secondary Light</span><button mat-button></button></div>

            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<tertiary.normal>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<tertiary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.tertiary.normal.color"><span i18n="@@Tertiary Normal">Tertiary Normal</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<tertiary.dark>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<tertiary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.tertiary.dark.color"><span i18n="@@Tertiary Dark">Tertiary Dark</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<tertiary.light>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<tertiary.light>'); saveProject();" [style.background-color]="cover.colorPalette.tertiary.light.color"><span i18n="@@Tertiary Light">Tertiary Light</span><button mat-button></button></div>

            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<quaternary.normal>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<quaternary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.quaternary.normal.color"><span i18n="@@Quaternary Normal">Quaternary Normal</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<quaternary.dark>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<quaternary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.quaternary.dark.color"><span i18n="@@Quaternary Dark">Quaternary Dark</span><button mat-button></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<quaternary.light>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<quaternary.light>'); saveProject();" [style.background-color]="cover.colorPalette.quaternary.light.color"><span i18n="@@Quaternary Light">Quaternary Light</span><button mat-button></button></div>

            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<quinary.normal>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<quinary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.quinary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<quinary.dark>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<quinary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.quinary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<quinary.light>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<quinary.light>'); saveProject();" [style.background-color]="cover.colorPalette.quinary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>

            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<senary.normal>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<senary.normal>'); saveProject();" [style.background-color]="cover.colorPalette.senary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<senary.dark>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<senary.dark>'); saveProject();" [style.background-color]="cover.colorPalette.senary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
            <div class="colorSwatch" [class.selected]="canvasManager.getOverridePropertyString( 'fontColor', canvasManager.getThemeItem().getFontColor()) === '<senary.light>'" (click)="canvasManager.setOverridePropertyString( 'fontColor', '<senary.light>'); saveProject();" [style.background-color]="cover.colorPalette.senary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>
          </div>
        </div>
      </div>

      <div class="toolStripItem">
        <button mat-button (click)="displayToolArea === 'opacity' ? displayToolArea = '' : displayToolArea = 'opacity'" title="Show/Hide Transparency Tool" i18n-title="@@Show/Hide Transparency Tool"><mat-icon>opacity</mat-icon></button>
        <div *ngIf="displayToolArea === 'opacity'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
          <span class="title" i18n="@@Transparency">Transparency</span>
          <div class="toolAreaSection">
            <div class="toolAreaSlider">
              <span i18n="@@Transparency">Transparency</span>
              <!-- TODO: The 'tickInterval' property no longer exists -->
              <mat-slider #transparencySlider min="0" max="100" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="canvasManager.getOpacity()*100.0" (change)="canvasManager.setOverridePropertyNumber('opacity', transparencySlider.value/100.0); canvasManager.setOpacity(transparencySlider.value/100.0); saveProject();" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{transparencySlider.value}}%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="toolStripItem">
        <button mat-button (click)="startupService.selectedCover.setImagePropertyBoolean(canvasManager.getThemeItem().getRole(), 'hidden', !startupService.selectedCover.getImagePropertyBoolean(canvasManager.getThemeItem().getRole(), 'hidden', canvasManager.getThemeItem().isHidden())); startupService.canvasManager.refreshObjectBasedOnRole(canvasManager.getThemeItem().getRole()); startupService.canvasManager.canvas.calcOffset(); startupService.canvasManager.canvas.renderAll(); saveProject();"><mat-icon *ngIf="startupService.selectedCover.getImagePropertyBoolean(canvasManager.getThemeItem().getRole(), 'hidden', canvasManager.getThemeItem().isHidden())" title="Show The Currently Selected Object" i18n-title="@@Show The Currently Selected Object">visibility_off</mat-icon><mat-icon *ngIf="!(startupService.selectedCover.getImagePropertyBoolean(canvasManager.getThemeItem().getRole(), 'hidden', canvasManager.getThemeItem().isHidden()))" title="Hide The Currently Selected Object" i18n-title="@@Hide The Currently Selected Object">visibility_on</mat-icon></button>
      </div>
      <div class="toolStripItem">
        <button mat-button (click)="displayToolArea === 'position' ? displayToolArea = '' : displayToolArea = 'position'" title="Show/Hide Position Tools" i18n-title="@@Show/Hide Position Tools"><mat-icon>featured_video</mat-icon></button>
        <div *ngIf="displayToolArea === 'position'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
          <span class="title" i18n="@@Position">Position</span>
          <div class="toolAreaSection">
            <div class="toolAreaSlider">
              <div style="padding-left: 26px;">
                <button mat-button (click)="shiftVertically(-0.002); saveProject();" title="Shift Object Upward" i18n-title="@@Shift Object Upward"><mat-icon>arrow_upward</mat-icon></button>
              </div>
              <div>
                <button mat-button (click)="shiftHorizontally(-0.002); saveProject();" title="Shift Object Left" i18n-title="@@Shift Object Left"><mat-icon>arrow_backward</mat-icon></button>
                <button mat-button (click)="shiftHorizontally(0.002); saveProject();" title="Shift Object Right" i18n-title="@@Shift Object Right"><mat-icon>arrow_forward</mat-icon></button>
              </div>
              <div style="padding-left: 26px;">
                <button mat-button (click)="shiftVertically(0.002); saveProject();" title="Shift Object Downward" i18n-title="@@Shift Object Downward"><mat-icon>arrow_downward</mat-icon></button>
              </div>
              <div>
                <button mat-button (click)="resetHorizontally(); saveProject();" title="Reset Horizontal Adjustments" i18n-title="@@Reset Horizontal Adjustments"><mat-icon>swap_horiz</mat-icon><mat-icon>delete</mat-icon></button>
                <button mat-button (click)="resetVertically(); saveProject();" title="Reset Horizontal Adjustments" i18n-title="@@Reset Horizontal Adjustments"><mat-icon>swap_vert</mat-icon><mat-icon>delete</mat-icon></button>
              </div>
            </div>
            <div class="toolAreaSlider">
              <span i18n="@@Scale">Scale</span>
              <!-- TODO: The 'tickInterval' property no longer exists -->
              <mat-slider #scaleSlider min="1" max="300" step="0.5" thumbLabel="true" title="Scale The Current Object" i18n-title="@@Scale The Current Object" #ngSlider><input matSliderThumb [value]="canvasManager.getScaleAdjustment() * 100" (change)="canvasManager.setScaleAdjustment( scaleSlider.value / 100 ); saveProject();" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{canvasManager.getScaleAdjustment() * 100 | number:'.0'}}%</span>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="toolStripItem" *ngIf="canvasManager.getType().toString() === 'image'">-->
        <!--<button mat-button><mat-icon>filter</mat-icon></button>-->
      <!--</div>-->
      <!--<div class="toolStripItem">-->
        <!--<button mat-button><mat-icon>rotate_90_degrees_ccw</mat-icon></button>-->
      <!--</div>-->
    </ng-container>
    <!-- START DESIGNER FLOATING TOOL BAR -->
    <!-- START DESIGNER FLOATING TOOL BAR -->
    <!-- START DESIGNER FLOATING TOOL BAR -->
    <!-- START DESIGNER FLOATING TOOL BAR -->
    <ng-container *ngIf="startupService.getSubscription().getSubscriptionLevel() === 'DESIGNER' && designerMode && canvasManager.getThemeItem(); ">
      <ng-container *ngIf="canvasManager.getThemeItem() as themeItem">
        <!-- DESIGNER TEXT -->
        <div class="toolStripItem designerToolStripItem" *ngIf="canvasManager.getType().toString() === 'textbox'">
          <button mat-button (click)="displayToolArea === 'text' ? displayToolArea = '' : displayToolArea = 'text'" title="Show/Hide Designer Text Options" i18n-title="@@Show/Hide Designer Text Options"><mat-icon>text_fields</mat-icon></button>
          <div *ngIf="displayToolArea === 'text'"  [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft"  style="top: -10px">
            <span class="title" i18n="@@Designer Text Options">Designer Text Options</span>
            <div class="toolAreaSection">
              <!--<span class="title" i18n="@@Text & Font">Text and Font</span>-->
              <div class="toolAreaItem"> <!-- designer font-family select -->
                <!--<span class="subTitle" i18n="@@Font">Font</span>-->
                <mat-button-toggle-group style="display: grid" #designerFontFamily="matButtonToggleGroup" [value]="themeItem.getFontFamily()" (change)="themeItem.setFontFamily(designerFontFamily.value); changedCoverTheme(); saveProject();">
                  <mat-button-toggle value="<primary>" [style.fontFamily]="cover.fontSettings.primary">{{ cover.fontSettings.primary | languageString }}</mat-button-toggle>
                  <mat-button-toggle value="<secondary>" [style.fontFamily]="cover.fontSettings.secondary">{{ cover.fontSettings.secondary | languageString }}</mat-button-toggle>
                  <mat-button-toggle value="<tertiary>" [style.fontFamily]="cover.fontSettings.tertiary">{{ cover.fontSettings.tertiary | languageString }}</mat-button-toggle>
                  <mat-button-toggle value="<accent>" [style.fontFamily]="cover.fontSettings.accent">{{ cover.fontSettings.accent | languageString }}</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div class="toolAreaSection">
              <div class="toolAreaItem"> <!-- designer font-weight select -->
                <button *ngIf="themeItem.getFontWeight() === 'normal' || themeItem.getFontWeight() === '400'" mat-button (click)="themeItem.setFontWeight('700'); changedCoverTheme(); saveProject();"><mat-icon>format_bold</mat-icon></button>
                <button *ngIf="themeItem.getFontWeight() === 'bold' || themeItem.getFontWeight() === '700'" mat-button (click)="themeItem.setFontWeight('400'); changedCoverTheme(); saveProject();"><mat-icon style="color: white">format_bold</mat-icon></button>
                <mat-select #fontWeight [ngModel]="themeItem.getFontWeight()" required style="width: 70%" (selectionChange)="themeItem.setFontWeight(fontWeight.value); changedCoverTheme(); saveProject();" [style.fontWeight]="themeItem.getFontWeight()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" [ngModelOptions]="{standalone: true}">
                  <mat-option value="100" [style.fontWeight]="themeItem.getFontWeight()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@Lighter">Lighter</mat-option>
                  <mat-option value="200" [style.fontWeight]="themeItem.getFontWeight()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@200">200</mat-option>
                  <mat-option value="300" [style.fontWeight]="themeItem.getFontWeight()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@300">300</mat-option>
                  <mat-option value="400" [style.fontWeight]="themeItem.getFontWeight()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@Normal">Normal</mat-option>
                  <mat-option value="500" [style.fontWeight]="themeItem.getFontWeight()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@500">500</mat-option>
                  <mat-option value="600" [style.fontWeight]="themeItem.getFontWeight()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@600">600</mat-option>
                  <mat-option value="700" [style.fontWeight]="themeItem.getFontWeight()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@Bold">Bold</mat-option>
                  <mat-option value="800" [style.fontWeight]="themeItem.getFontWeight()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@800">800</mat-option>
                  <mat-option value="900" [style.fontWeight]="themeItem.getFontWeight()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@Bolder">Bolder</mat-option>
                </mat-select>
              </div>
              <div class="toolAreaItem"> <!-- designer font-style select -->
                <mat-button-toggle-group #designerFontStyle="matButtonToggleGroup" [value]="themeItem.getFontStyle()" (change)="themeItem.setFontStyle(designerFontStyle.value); changedCoverTheme(); saveProject();">
                  <mat-button-toggle value="italic" [style.fontStyle]="themeItem.getFontStyle()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@Italic">Italic</mat-button-toggle>
                  <mat-button-toggle value="normal" [style.fontStyle]="themeItem.getFontStyle()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@Normal">Normal</mat-button-toggle>
                  <mat-button-toggle value="oblique" [style.fontStyle]="themeItem.getFontStyle()" [style.fontFamily]="cover.fontSettings.getFontFromKey(themeItem.getFontFamily(), '')" i18n="@@Oblique">Oblique</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="toolAreaItem"> <!-- alignment -->
                <mat-button-toggle-group #designerAlignment="matButtonToggleGroup" [value]="themeItem.getTextAlign()" (change)="themeItem.setTextAlign(designerAlignment.value); changedCoverTheme(); saveProject();">
                  <mat-button-toggle value="left">
                    <mat-icon>format_align_left</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="center">
                    <mat-icon>format_align_center</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="right">
                    <mat-icon>format_align_right</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="justify">
                    <mat-icon>format_align_justify</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="toolAreaItem"> <!-- rotation -->
                <mat-button-toggle-group #designerRotation="matButtonToggleGroup" [value]="themeItem.getRotation()" (change)="themeItem.setRotation(designerRotation.value); changedCoverTheme(); saveProject();">
                  <mat-button-toggle value="0"><mat-icon>text_rotation_none</mat-icon></mat-button-toggle>
                  <mat-button-toggle value="90"><mat-icon>text_rotation_down</mat-icon></mat-button-toggle>
                  <mat-button-toggle value="180"><mat-icon>text_rotate_vertical</mat-icon></mat-button-toggle>
                  <mat-button-toggle value="270"><mat-icon>text_rotate_up</mat-icon></mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div class="toolAreaSection">
              <div class="toolAreaItem">
                <mat-select #designerFontSizeSelect [ngModel]="'' + themeItem.getFontSize()" required style="width: 70%" (selectionChange)="themeItem.setFontSize(designerFontSizeSelect.value); changedCoverTheme(); saveProject();" [ngModelOptions]="{standalone: true}">
                  <mat-option value="6" i18n="@@6pt">6pt</mat-option>
                  <mat-option value="7" i18n="@@7pt">7pt</mat-option>
                  <mat-option value="8" i18n="@@8pt">8pt</mat-option>
                  <mat-option value="9" i18n="@@9pt">9pt</mat-option>
                  <mat-option value="10" i18n="@@10pt">10pt</mat-option>
                  <mat-option value="11" i18n="@@11pt">11pt</mat-option>
                  <mat-option value="12" i18n="@@12pt">12pt</mat-option>
                  <mat-option value="13" i18n="@@13pt">13pt</mat-option>
                  <mat-option value="14" i18n="@@14pt">14pt</mat-option>
                  <mat-option value="15" i18n="@@15pt">15pt</mat-option>
                  <mat-option value="16" i18n="@@16pt">16pt</mat-option>
                  <mat-option value="17" i18n="@@17pt">17pt</mat-option>
                  <mat-option value="18" i18n="@@18pt">18pt</mat-option>
                  <mat-option value="20" i18n="@@20pt">20pt</mat-option>
                  <mat-option value="22" i18n="@@22pt">22pt</mat-option>
                  <mat-option value="24" i18n="@@24pt">24pt</mat-option>
                  <mat-option value="26" i18n="@@26pt">26pt</mat-option>
                  <mat-option value="28" i18n="@@28pt">28pt</mat-option>
                  <mat-option value="30" i18n="@@30pt">30pt</mat-option>
                  <mat-option value="32" i18n="@@32pt">32pt</mat-option>
                  <mat-option value="34" i18n="@@34pt">34pt</mat-option>
                  <mat-option value="36" i18n="@@36pt">36pt</mat-option>
                  <mat-option value="38" i18n="@@38pt">38pt</mat-option>
                  <mat-option value="40" i18n="@@40pt">40pt</mat-option>
                  <mat-option value="42" i18n="@@42pt">42pt</mat-option>
                  <mat-option value="44" i18n="@@44pt">44pt</mat-option>
                  <mat-option value="46" i18n="@@46pt">46pt</mat-option>
                  <mat-option value="48" i18n="@@48pt">48pt</mat-option>
                  <mat-option value="50" i18n="@@50pt">50pt</mat-option>
                  <mat-option value="52" i18n="@@52pt">52pt</mat-option>
                  <mat-option value="54" i18n="@@54pt">54pt</mat-option>
                  <mat-option value="56" i18n="@@56pt">56pt</mat-option>
                  <mat-option value="58" i18n="@@58pt">58pt</mat-option>
                  <mat-option value="60" i18n="@@60pt">60pt</mat-option>
                  <mat-option value="64" i18n="@@64pt">64pt</mat-option>
                  <mat-option value="68" i18n="@@68pt">68pt</mat-option>
                  <mat-option value="72" i18n="@@72pt">72pt</mat-option>
                  <mat-option value="76" i18n="@@76pt">76pt</mat-option>
                  <mat-option value="80" i18n="@@80pt">80pt</mat-option>
                  <mat-option value="84" i18n="@@84pt">84pt</mat-option>
                  <mat-option value="88" i18n="@@88pt">88pt</mat-option>
                  <mat-option value="92" i18n="@@92pt">92pt</mat-option>
                  <mat-option value="96" i18n="@@96pt">96pt</mat-option>
                  <mat-option value="100" i18n="@@100pt">100pt</mat-option>
                  <mat-option value="104" i18n="@@104pt">104pt</mat-option>
                  <mat-option value="108" i18n="@@108pt">108pt</mat-option>
                  <mat-option value="116" i18n="@@116pt">116pt</mat-option>
                  <mat-option value="120" i18n="@@120pt">120pt</mat-option>
                  <mat-option value="128" i18n="@@128pt">128pt</mat-option>
                  <mat-option value="132" i18n="@@132pt">132pt</mat-option>
                  <mat-option value="140" i18n="@@140pt">140pt</mat-option>
                  <mat-option value="148" i18n="@@148pt">148pt</mat-option>
                  <mat-option value="156" i18n="@@156pt">156pt</mat-option>
                  <mat-option value="164" i18n="@@164pt">168pt</mat-option>
                  <mat-option value="172" i18n="@@172pt">172pt</mat-option>
                  <mat-option value="180" i18n="@@180pt">180pt</mat-option>
                  <mat-option value="188" i18n="@@188pt">188pt</mat-option>
                  <mat-option value="196" i18n="@@196pt">196pt</mat-option>
                  <mat-option value="240" i18n="@@240pt">240pt</mat-option>
                  <mat-option value="264" i18n="@@264pt">264pt</mat-option>
                  <mat-option value="288" i18n="@@288pt">288pt</mat-option>
                </mat-select>
              </div>

              <!--<div class="toolAreaSlider"> &lt;!&ndash; designer font-size &ndash;&gt;-->
                <!--<span i18n="@@Font Size">Font Size</span><mat-slider #designerFontSizeSlider min="4" max="144" step="1" thumbLabel="true" tickInterval="10" [value]="themeItem.getFontSize()" (change)="themeItem.setFontSize(designerFontSizeSlider.value); changedCoverTheme()"></mat-slider>-->
              <!--</div>-->
              <div class="toolAreaSlider"> <!-- designer line-height -->
                <span i18n="@@Line Height">Line Height</span>
                <!-- TODO: The 'tickInterval' property no longer exists -->
                <mat-slider #designerLineHeightSlider min="0.5" max="5" step="0.1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="themeItem.getLineHeight()" (change)="themeItem.setLineHeight(designerLineHeightSlider.value); changedCoverTheme(); saveProject();" #ngSliderThumb="matSliderThumb" /></mat-slider>
              </div>
              <div class="toolAreaSlider"> <!-- designer spacing -->
                <span i18n="@@Spacing">Spacing</span>
                <!-- TODO: The 'tickInterval' property no longer exists -->
                <mat-slider #designerSpacingSlider min="-200" max="400" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="themeItem.getCharSpacing()" (change)="themeItem.setCharSpacing(designerSpacingSlider.value); changedCoverTheme(); saveProject();" #ngSliderThumb="matSliderThumb" /></mat-slider>
              </div>
            </div>
            <div class="toolAreaSection">
              <mat-form-field>
                <input matInput #designerDefaultText i18n-placeholder="@@Default Text" placeholder="Default Text" type="text" [ngModel]="themeItem.getDefaultText()" (change)="themeItem.setDefaultText(designerDefaultText.value); changedCoverTheme(); saveProject();"/>
              </mat-form-field>
           </div>
          </div>
        </div>
        <!-- DESIGNER FILL COLOR -->
        <div class="toolStripItem designerToolStripItem" *ngIf="themeItem.getType() !== 'image'"> <!-- designer color -->
          <button mat-button (click)="displayToolArea === 'designerColor' ? displayToolArea = '' : displayToolArea = 'designerColor'" title="Show/Hide Designer Fill Color Options" i18n-title="@@Show/Hide Designer Fill Color Options"><mat-icon>format_color_fill</mat-icon></button>
          <div *ngIf="displayToolArea === 'designerColor'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
            <span class="title" i18n="@@Designer Color">Designer Color</span>
            <div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<primary.normal>'" (click)="themeItem.setColor('<primary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.primary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<primary.dark>'" (click)="themeItem.setColor('<primary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.primary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<primary.light>'" (click)="themeItem.setColor('<primary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.primary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>

              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<secondary.normal>'" (click)="themeItem.setColor('<secondary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.secondary.normal.color"><span i18n="@@Secondary Normal">Secondary Normal</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<secondary.dark>'" (click)="themeItem.setColor('<secondary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.secondary.dark.color"><span i18n="@@Secondary Dark">Secondary Dark</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<secondary.light>'" (click)="themeItem.setColor('<secondary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.secondary.light.color"><span i18n="@@Secondary Light">Secondary Light</span><button mat-button></button></div>

              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<tertiary.normal>'" (click)="themeItem.setColor('<tertiary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.tertiary.normal.color"><span i18n="@@Tertiary Normal">Tertiary Normal</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<tertiary.dark>'" (click)="themeItem.setColor('<tertiary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.tertiary.dark.color"><span i18n="@@Tertiary Dark">Tertiary Dark</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<tertiary.light>'" (click)="themeItem.setColor('<tertiary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.tertiary.light.color"><span i18n="@@Tertiary Light">Tertiary Light</span><button mat-button></button></div>

              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<quaternary.normal>'" (click)="themeItem.setColor('<quaternary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quaternary.normal.color"><span i18n="@@Quaternary Normal">Quaternary Normal</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<quaternary.dark>'" (click)="themeItem.setColor('<quaternary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quaternary.dark.color"><span i18n="@@Quaternary Dark">Quaternary Dark</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<quaternary.light>'" (click)="themeItem.setColor('<quaternary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quaternary.light.color"><span i18n="@@Quaternary Light">Quaternary Light</span><button mat-button></button></div>

              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<quinary.normal>'" (click)="themeItem.setColor('<quinary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quinary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<quinary.dark>'" (click)="themeItem.setColor('<quinary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quinary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<quinary.light>'" (click)="themeItem.setColor('<quinary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quinary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>

              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<senary.normal>'" (click)="themeItem.setColor('<senary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.senary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<senary.dark>'" (click)="themeItem.setColor('<senary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.senary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getColor() === '<senary.light>'" (click)="themeItem.setColor('<senary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.senary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>
            </div>
          </div>
        </div>
        <!-- DESIGNER TEXT COLOR -->
        <div class="toolStripItem designerToolStripItem" *ngIf="themeItem.getType() === 'textbox'"> <!-- designer text color -->
          <button mat-button (click)="displayToolArea === 'designerTextColor' ? displayToolArea = '' : displayToolArea = 'designerTextColor'" title="Show/Hide Designer Text Color Options" i18n-title="@@Show/Hide Designer Text Color Options"><mat-icon>format_color_text</mat-icon></button>
          <div *ngIf="displayToolArea === 'designerTextColor'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
            <span class="title" i18n="@@Designer Text Color">Designer Text Color</span>
            <div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<primary.normal>'" (click)="themeItem.setFontColor('<primary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.primary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<primary.dark>'" (click)="themeItem.setFontColor('<primary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.primary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<primary.light>'" (click)="themeItem.setFontColor('<primary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.primary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>

              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<secondary.normal>'" (click)="themeItem.setFontColor('<secondary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.secondary.normal.color"><span i18n="@@Secondary Normal">Secondary Normal</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<secondary.dark>'" (click)="themeItem.setFontColor('<secondary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.secondary.dark.color"><span i18n="@@Secondary Dark">Secondary Dark</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<secondary.light>'" (click)="themeItem.setFontColor('<secondary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.secondary.light.color"><span i18n="@@Secondary Light">Secondary Light</span><button mat-button></button></div>

              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<tertiary.normal>'" (click)="themeItem.setFontColor('<tertiary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.tertiary.normal.color"><span i18n="@@Tertiary Normal">Tertiary Normal</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<tertiary.dark>'" (click)="themeItem.setFontColor('<tertiary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.tertiary.dark.color"><span i18n="@@Tertiary Dark">Tertiary Dark</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<tertiary.light>'" (click)="themeItem.setFontColor('<tertiary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.tertiary.light.color"><span i18n="@@Tertiary Light">Tertiary Light</span><button mat-button></button></div>

              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<quaternary.normal>'" (click)="themeItem.setFontColor('<quaternary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quaternary.normal.color"><span i18n="@@Quaternary Normal">Quaternary Normal</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<quaternary.dark>'" (click)="themeItem.setFontColor('<quaternary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quaternary.dark.color"><span i18n="@@Quaternary Dark">Quaternary Dark</span><button mat-button></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<quaternary.light>'" (click)="themeItem.setFontColor('<quaternary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quaternary.light.color"><span i18n="@@Quaternary Light">Quaternary Light</span><button mat-button></button></div>

              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<quinary.normal>'" (click)="themeItem.setFontColor('<quinary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quinary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<quinary.dark>'" (click)="themeItem.setFontColor('<quinary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quinary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<quinary.light>'" (click)="themeItem.setFontColor('<quinary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.quinary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>

              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<senary.normal>'" (click)="themeItem.setFontColor('<senary.normal>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.senary.normal.color"><span i18n="@@Primary Normal">Primary Normal</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<senary.dark>'" (click)="themeItem.setFontColor('<senary.dark>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.senary.dark.color"><span  i18n="@@Primary Dark">Primary Dark</span><button mat-button ></button></div>
              <div class="colorSwatch" [class.selected]="themeItem.getFontColor() === '<senary.light>'" (click)="themeItem.setFontColor('<senary.light>'); changedCoverTheme(); saveProject();" [style.background-color]="cover.colorPalette.senary.light.color"><span i18n="@@Primary Light">Primary Light</span><button mat-button></button></div>
            </div>
          </div>
        </div>
        <!-- DESIGNER POSITION -->
        <div class="toolStripItem designerToolStripItem">
          <button mat-button (click)="displayToolArea === 'designerPosition' ? displayToolArea = '' : displayToolArea = 'designerPosition'" title="Show/Hide Designer Position Tools" i18n-title="@@Show/Hide Designer Position Tools"><mat-icon>grid_on</mat-icon></button>
          <div *ngIf="displayToolArea === 'designerPosition'"  [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft"  style="top: -10px">
            <span class="title" i18n="@@Designer Position">Designer Position</span>
            <div class="toolAreaSection">
              <div class="toolAreaItem"> <!-- horizontal alignment -->
                <span class="subTitle" i18n="@@Horizontal Alignment">Horizontal Alignment</span>
                <mat-button-toggle-group #originX="matButtonToggleGroup" [value]="themeItem.getOriginX()" (change)="themeItem.setOriginX(originX.value); changedCoverTheme(); saveProject();">
                  <mat-button-toggle value="left">
                    <mat-icon>border_left</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="center">
                    <mat-icon>border_vertical</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="right">
                    <mat-icon>border_right</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="toolAreaItem"> <!-- vertical alignment -->
                <span class="subTitle" i18n="@@Vertical Alignment">Vertical Alignment</span>
                <mat-button-toggle-group #originY="matButtonToggleGroup" [value]="themeItem.getOriginY()" (change)="themeItem.setOriginY(originY.value); changedCoverTheme(); saveProject();">
                  <mat-button-toggle value="top">
                    <mat-icon>vertical_align_top</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="center">
                    <mat-icon>vertical_align_center</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="bottom">
                    <mat-icon>vertical_align_bottom</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div class="toolAreaSection positioningArrowArea">
              <div style="padding-left: 94px;">
                <button mat-button (click)="themeItem.setTopPositionNumber( themeItem.getTopPositionNumber() - 0.25 ); changedCoverTheme(); saveProject();" title="Shift Top Upward" i18n-title="@@Shift Top Upward"><mat-icon>arrow_upward</mat-icon></button>
              </div>
              <div style="padding-left: 90px; margin-top: -25px; margin-bottom: -25px;"> <!-- top -->
                <mat-form-field style="width: 33px;">
                  <input matInput #topPositionNumberInput type="number" [ngModel]="themeItem.getTopPositionNumber()" (change)="themeItem.setTopPositionNumber(topPositionNumberInput.value); saveProject(); changedCoverTheme();"/>
                </mat-form-field>
                <mat-select style="width: 29px;" #topPositionCode [ngModel]="themeItem.getTopPositionCode()" required (selectionChange)="themeItem.setTopPositionCode(topPositionCode.value); changedCoverTheme(); saveProject();"  [ngModelOptions]="{standalone: true}">
                  <mat-option value="%" i18n="@@%">%</mat-option>
                  <mat-option value="T" i18n="@@T">T</mat-option>
                  <mat-option value="B" i18n="@@B">B</mat-option>
                  <mat-option value="H" i18n="@@H">H</mat-option>
                </mat-select>
              </div>
              <div style="padding-left: 94px;">
                <button mat-button (click)="themeItem.setTopPositionNumber( themeItem.getTopPositionNumber() + 0.25 ); changedCoverTheme(); saveProject();" title="Shift Top Downward" i18n-title="@@Shift Top Downward"><mat-icon>arrow_downward</mat-icon></button>
              </div>
              <div style="margin-bottom: -25px; margin-top: -25px; margin-left: -11px;">
                <button mat-button (click)="themeItem.setLeftPositionNumber( themeItem.getLeftPositionNumber() - 0.25 ); changedCoverTheme(); saveProject();" title="Shift Left Leftward" i18n-title="@@Shift Left Leftward"><mat-icon>arrow_backward</mat-icon></button>
                <mat-form-field  style="width: 33px;">
                  <input matInput #leftPositionNumberInput type="number" [ngModel]="themeItem.getLeftPositionNumber()" (change)="themeItem.setLeftPositionNumber(leftPositionNumberInput.value); changedCoverTheme(); saveProject();"/>
                </mat-form-field>
                <mat-select style="width: 29px;" #leftPositionCode [ngModel]="themeItem.getLeftPositionCode()" required (selectionChange)="themeItem.setLeftPositionCode(leftPositionCode.value); changedCoverTheme(); saveProject();"  [ngModelOptions]="{standalone: true}">
                  <mat-option value="%" i18n="@@%">%</mat-option>
                  <mat-option value="L" i18n="@@L">L</mat-option>
                  <mat-option value="R" i18n="@@R">R</mat-option>
                  <mat-option value="W" i18n="@@W">W</mat-option>
                </mat-select>
                <button style="" mat-button (click)="themeItem.setLeftPositionNumber( themeItem.getLeftPositionNumber() + 0.25 ); changedCoverTheme(); saveProject();" title="Shift Left Rightward" i18n-title="@@Shift Left Rightward"><mat-icon>arrow_forward</mat-icon></button>
                <button style="" mat-button (click)="themeItem.setRightPositionNumber( themeItem.getRightPositionNumber() - 0.25 ); changedCoverTheme(); saveProject();" title="Shift Right Leftward" i18n-title="@@Shift Right Leftward"><mat-icon>arrow_backward</mat-icon></button>
                <mat-form-field style="width: 33px;">
                  <input matInput #rightPositionNumberInput type="number" [ngModel]="themeItem.getRightPositionNumber()" (change)="themeItem.setRightPositionNumber(rightPositionNumberInput.value); changedCoverTheme(); saveProject();"/>
                </mat-form-field>
                <mat-select style="width: 29px; width: 29px" #rightPositionCode [ngModel]="themeItem.getRightPositionCode()" required (selectionChange)="themeItem.setRightPositionCode(rightPositionCode.value); changedCoverTheme(); saveProject();"  [ngModelOptions]="{standalone: true}">
                  <mat-option value="%" i18n="@@%">%</mat-option>
                  <mat-option value="L" i18n="@@L">L</mat-option>
                  <mat-option value="R" i18n="@@R">R</mat-option>
                  <mat-option value="W" i18n="@@W">W</mat-option>
                </mat-select>
                <button style="margin-right: -25px;" mat-button (click)="themeItem.setRightPositionNumber( themeItem.getRightPositionNumber() + 0.25 ); changedCoverTheme(); saveProject();" title="Shift Right Rightward" i18n-title="@@Shift Right Rightward"><mat-icon>arrow_forward</mat-icon></button>
              </div>
              <div style="padding-left: 94px;">
                <button mat-button (click)="themeItem.setBottomPositionNumber( themeItem.getBottomPositionNumber() - 0.25 ); changedCoverTheme(); saveProject();" title="Shift Bottom Upward" i18n-title="@@Shift Bottom Upward"><mat-icon>arrow_upward</mat-icon></button>
              </div>
              <div style="padding-left: 90px; margin-top: -25px; margin-bottom: -25px;"> <!-- bottom -->
                <mat-form-field  style="width: 33px;">
                  <input matInput #bottomPositionNumberInput type="number" [ngModel]="themeItem.getBottomPositionNumber()" (change)="themeItem.setBottomPositionNumber(bottomPositionNumberInput.value); changedCoverTheme(); saveProject();"/>
                </mat-form-field>
                <mat-select style="width: 29px; width: 29px" #bottomPositionCode [ngModel]="themeItem.getBottomPositionCode()" required (selectionChange)="themeItem.setBottomPositionCode(bottomPositionCode.value); changedCoverTheme(); saveProject();"  [ngModelOptions]="{standalone: true}">
                  <mat-option value="%" i18n="@@%">%</mat-option>
                  <mat-option value="T" i18n="@@T">T</mat-option>
                  <mat-option value="B" i18n="@@B">B</mat-option>
                  <mat-option value="H" i18n="@@H">H</mat-option>
                </mat-select>
              </div>
              <div style="padding-left: 94px;">
                <button mat-button (click)="themeItem.setBottomPositionNumber( themeItem.getBottomPositionNumber() + 0.25 ); changedCoverTheme(); saveProject();" title="Shift Bottom Downward" i18n-title="@@Shift Bottom Downward"><mat-icon>arrow_downward</mat-icon></button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="themeItem.getType() === 'image'" class="toolStripItem designerToolStripItem"> <!-- designer image -->
          <button mat-button (click)="displayToolArea === 'designerImage' ? displayToolArea = '' : displayToolArea = 'designerImage'" title="Show/Hide Designer Image Tools" i18n-title="@@Show/Hide Designer Image Tools"><mat-icon>image</mat-icon></button>
          <div *ngIf="displayToolArea === 'designerImage'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
            <span class="title" i18n="@@Designer Image">Designer Image</span>
            <div class="toolAreaSection">
              <div class="toolAreaItem"> <!-- movement allowed -->
                <span class="subTitle" i18n="@@Movement Allowed">Movement Allowed</span>
                <mat-button-toggle-group #movement="matButtonToggleGroup" [value]="themeItem.getMovement()" (change)="themeItem.setMovement(movement.value); changedCoverTheme(); saveProject();">
                  <mat-button-toggle value="horizontal">
                    <mat-icon>swap_horizontal_circle</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="vertical">
                    <mat-icon>swap_vertical_circle</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="none">
                    <mat-icon>fullscreen_exit</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="all">
                    <mat-icon>cancel</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
            <div class="toolAreaSection">
              <div class="toolAreaItem"> <!-- position fit -->
                <span class="subTitle" i18n="@@Position Fit">Position Fit</span>
                <mat-select #designerPositionFit  [ngModel]="themeItem.getPositionFit()" required (selectionChange)="themeItem.setPositionFit(designerPositionFit.value); changedCoverTheme(); saveProject();"  [ngModelOptions]="{standalone: true}">
                  <mat-option value="inside" i18n="@@Inside">Inside</mat-option>
                  <mat-option value="outside" i18n="@@Outside">Outside</mat-option>
                  <mat-option value="matchHeight" i18n="@@Match Height">Match Height</mat-option>
                  <mat-option value="matchWidth" i18n="@@Match Width">Match Width</mat-option>
                  <mat-option value="widthOutside" i18n="@@Width Outside">Width Outside</mat-option>
                  <mat-option value="heightOutside" i18n="@@Height Outside">Height Outside</mat-option>
                </mat-select>
              </div>
            </div>
            <div class="toolAreaSection">
              <div class="toolAreaItem"> <!-- Image Type -->
                <span class="subTitle" i18n="@@Image Type">Image Type</span>
                <mat-select #designerImageType  [ngModel]="themeItem.getImageType()" required (selectionChange)="themeItem.setImageType(designerImageType.value); changedCoverTheme(); saveProject();"  [ngModelOptions]="{standalone: true}">
                  <mat-option value="background" i18n="@@Background">Background</mat-option>
                  <mat-option value="transparent" i18n="@@Transparent">Transparent</mat-option>
                  <mat-option value="logo" i18n="@@Logo">Logo</mat-option>
                </mat-select>
              </div>
            </div>
            <div class="toolAreaSection">
              <div class="toolAreaItem"> <!-- set theme item image key to the current object image key -->
                <button mat-button color="alert" (click)="themeItem.addImageOption(canvasManager.getImageKey(), canvasManager.getOverridePropertyNumber('horizontalAdjustment', 0), canvasManager.getOverridePropertyNumber('verticalAdjustment', 0), canvasManager.getOverridePropertyNumber('scaleAdjustment', 1)); saveProject();" i18n-title="@@Add Current Image and Settings as a Design Option" title="Add Current Image and Settings as a Design Option" i18n="@@Add Current Image">Add Current Image</button>
                <!--<button mat-button color="primary" (click)="themeItem.setImageKey(canvasManager.getImageKey()); saveProject();" i18n="@@Use Current Image in The Design Default">Use Current Image in The Design Default</button>-->
                <!--<button mat-button color="alert" (click)="themeItem.setImageKey(''); saveProject();" i18n="@@Reset the Current Image">Reset the Current Image</button>-->
              </div>
            </div>
          </div>
        </div>
        <div class="toolStripItem designerToolStripItem">
          <button mat-button (click)="displayToolArea === 'designerOpacity' ? displayToolArea = '' : displayToolArea = 'designerOpacity'" title="Show/Hide Designer Transparency Tool" i18n-title="@@Show/Hide Designer Transparency Tool"><mat-icon>opacity</mat-icon></button>
          <div *ngIf="displayToolArea === 'designerOpacity'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
            <span class="title" i18n="@@Transparency">Transparency</span>
            <div class="toolAreaSection">
              <div class="toolAreaSlider">
                <span i18n="@@Transparency">Transparency</span>
                <!-- TODO: The 'tickInterval' property no longer exists -->
                <mat-slider #designerOpacitySlider min="0" max="100" step="1" thumbLabel="true" #ngSlider><input matSliderThumb [value]="themeItem.getOpacity()*100.0" (change)="themeItem.setOpacity( designerOpacitySlider.value/100.0); changedCoverTheme(); saveProject();" #ngSliderThumb="matSliderThumb" /></mat-slider><span>{{designerOpacitySlider.value}}%</span>
              </div>
            </div>
          </div>
        </div>
        <div class="toolStripItem designerToolStripItem">
          <button mat-button (click)="displayToolArea === 'designerThemeName' ? displayToolArea = '' : displayToolArea = 'designerThemeName'" title="Show/Hide Tool To Set The Designs Name" i18n-title="@@Show/Hide Tool To Set The Designs Name"><mat-icon>label</mat-icon></button>
          <div *ngIf="displayToolArea === 'designerThemeName'" [class.toolAreaLeft]="startupService.floatingToolStripLeft" [class.toolAreaRight]="!startupService.floatingToolStripLeft" style="top: -10px">
            <span class="title" i18n="@@Cover Theme Name">Cover Theme Name</span>
            <div class="toolAreaSection">
              <div class="toolAreaItem"> <!-- Theme Name -->
                <mat-form-field>
                  <input type="text" [(ngModel)]="startupService.project.cover.coverTheme.name" (change)="saveProject();" i18n-placeholder="@@Cover Theme Name" placeholder="Cover Theme Name" value="" matInput [ngModelOptions]="{standalone: true}"/>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="toolStripItem designerToolStripItem">
          <button mat-button (click)="themeItem.setHidden(!themeItem.isHidden()); startupService.canvasManager.refreshObjectBasedOnRole(themeItem.getRole()); startupService.canvasManager.canvas.calcOffset(); startupService.canvasManager.canvas.renderAll(); saveProject();"><mat-icon color="accent" *ngIf="themeItem.isHidden()" title="Show The Current Object In The Cover Design" i18n-title="@@Show The Current Object In The Cover Design">visibility_off</mat-icon><mat-icon color="accent" *ngIf="!themeItem.isHidden()" title="Hide The Current Object In The Cover Design" i18n-title="@@Hide The Current Object In The Cover Design">visibility_on</mat-icon></button>
        </div>
        <div class="toolStripItem designerToolStripItem">
          <button mat-button (click)="projectService.saveCoverTheme('cover-themes', startupService.project); projectService.saveCoverThemeImage('cover-themes', startupService.project); saveProject();" i18n-title="@@Save cover theme to local project" title="Save cover theme to local project"><mat-icon>save</mat-icon></button>
        </div>
      </ng-container>
    </ng-container>

    <div class="toolStripItem">
      <button mat-button (click)="startupService.floatingToolStripLeft = !startupService.floatingToolStripLeft" title="Switch This Tool Bar To The Other Side" i18n-title="@@Switch This Tool Bar To The Other Side"><mat-icon *ngIf="startupService.floatingToolStripLeft">arrow_forward</mat-icon><mat-icon *ngIf="!startupService.floatingToolStripLeft">arrow_back</mat-icon></button>
    </div>
  </div>
  <div class="coverCanvasDirectContainer">
    <canvas spellcheck="true" #canvasElement id="canvasElement" style="border: 1px solid #ccc;"><span></span></canvas>
  </div>

  <div *ngIf="canvasManager" class="zoomToolsContainer" [ngClass]="activeSideArea ? 'areaDrawOpen' : ''" [class.navigationDrawOpen]="mainSideNavIsOpen">
    <div class="zoomTools">
      <button mat-button (click)="canvasManager.zoomOut()" title="Zoom Out" i18n-title="@@Zoom Out"><mat-icon>zoom_out</mat-icon></button>
      <mat-select [(value)]="cover.scale">
        <mat-option value="3.00">300%</mat-option>
        <mat-option value="2.00">200%</mat-option>
        <mat-option value="1.75">175%</mat-option>
        <mat-option value="1.50">150%</mat-option>
        <mat-option value="1.25">125%</mat-option>
        <mat-option value="1.00">100%</mat-option>
        <mat-option value="0.75">75%</mat-option>
        <mat-option value="0.50">50%</mat-option>
        <mat-option value="0.25">25%</mat-option>
        <mat-option value="0.10">20%</mat-option>
      </mat-select>
      <button mat-button (click)="canvasManager.zoomIn()" title="Zoom In" i18n-title="@@Zoom In"><mat-icon>zoom_in</mat-icon></button>
      <ng-container *ngIf="canvasManager.getType().toString() === 'textbox'">
        <button *ngIf="canvasManager.getEditText()" mat-button (click)="canvasManager.setEditText(!canvasManager.getEditText())"><mat-icon>format_shapes</mat-icon></button>
        <button *ngIf="!canvasManager.getEditText()" mat-button (click)="canvasManager.setEditText(!canvasManager.getEditText())"><mat-icon>edit</mat-icon></button>
      </ng-container>
      <!--<span class="fa fa-eraser" style="color:red" (click)="canvasManager.toggleSelectBackgroundObjects()" i18n-title="@@Make Background Objects Selectable" title="Make Background Objects Selectable"></span>-->
      <!--<button mat-button *ngIf="canvasManager.backgroundObjectsSelectable" (click)="canvasManager.toggleBackgroundObjectsSelectable()" i18n-title="@@Make Background Objects Not Selectable" title="Make Background Not Objects Selectable"><mat-icon>format_shapes</mat-icon></button>-->
      <!--<button mat-button *ngIf="!canvasManager.backgroundObjectsSelectable" (click)="canvasManager.toggleBackgroundObjectsSelectable()" i18n-title="@@Make Background Objects Selectable" title="Make Background Objects Selectable"><mat-icon>select_all</mat-icon></button>-->

      <ng-container *ngIf="startupService.getSubscription().getSubscriptionLevel() === 'DESIGNER'">
        <button mat-button *ngIf="canvasManager" (click)="canvasManager.refresh()" i18n-title="@@Refresh" title="Refresh"><mat-icon>refresh</mat-icon></button>
        <button mat-button *ngIf="canvasManager.spineSizeForDesigner" (click)="canvasManager.toggleSpineSizeForDesigner()" i18n-title="@@Show Real Spine Size" title="Show Real Spine Size"><mat-icon>flip</mat-icon></button>
        <button mat-button *ngIf="!canvasManager.spineSizeForDesigner" (click)="canvasManager.toggleSpineSizeForDesigner()" i18n-title="@@Show Spine Size for Designer" title="Show Spine Size for Designer"><mat-icon>flip</mat-icon></button>
      </ng-container>

      <button mat-button *ngIf="canvasManager.coverGridLines" (click)="canvasManager.toggleGridLines()" title="Hide Trim Area And Spine" i18n-title="@@Hide Trim Area And Spine"><mat-icon>grid_off</mat-icon></button>
      <button mat-button *ngIf="!canvasManager.coverGridLines" (click)="canvasManager.toggleGridLines()" title="Show Trim Area And Spine" i18n-title="@@Show Trim Area And Spine"><mat-icon>grid_on</mat-icon></button>
      <!--<button mat-button color="alert" (click)="canvasManager.resetAdjustments()"><mat-icon>clear_all</mat-icon></button>-->
    </div>
  </div>
</div>
<div class="hiddenImages" *ngIf="cover && cover.getImages()">
  <p>Place for hidden images</p>
  <img *ngFor="let image of project.cover.getImages()" [src]='imageService.getFreshImageUrl(image, false)' [id]="image.key" (load)="imageLoaded(image.key)" (error)="errorHandler($event, image.key)">
</div>
