import {TableFilter} from "./TableFilter";

export class StringTableFilter extends TableFilter {
  public value: string = '';

  constructor (obj: any) {
    super(obj);
    if ( obj.value ) {
      this.value = String(obj.value);
    }
  }

  public match(data: any, filter: string): boolean {
    const compareValue = this.value.toLowerCase();
    const value = this.getStringValue(data).toLowerCase();
    if ( this.value === '') {
      return true;
    }
    if ( value && value.indexOf(compareValue) >= 0 ) {
      return true;
    }
    return false;
  }
}
