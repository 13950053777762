import {Book} from "./Book";
import {Resource} from "./Resource";
import {DataObject} from "./DataObject";

export class Body implements DataObject {
  ID: number;
  lastUpdate: Date;
  resource_ID: number | null;
  leftVerticalShift: number;
  leftHorizontalShift: number;
  rightVerticalShift: number;
  rightHorizontalShift: number;
  documentScale: number;
  numPages: number;
  height: number;
  width: number;
  spine: number;
  marginTop: number;
  marginBottom: number;
  marginOut: number;
  marginIn: number;
  paperType_ID: number;
  weight: number;
  correctBlackText: boolean;
  useICC: string;
  kOnlyInputPages: string;
  book: Book;
  resource: Resource;
  standardized: string;
  problemStatus: string;
  problemNote: string;

  constructor (obj) {
    this.ID = 0;
    this.lastUpdate = new Date(0);
    this.resource_ID = 0;
    this.leftVerticalShift = 0;
    this.leftHorizontalShift = 0;
    this.rightVerticalShift = 0;
    this.rightHorizontalShift = 0;
    this.documentScale = 1;
    this.numPages = 0;
    this.height = 648;
    this.width = 432;
    this.spine = 0;
    this.marginTop = 36;
    this.marginBottom = 36;
    this.marginOut = 36;
    this.marginIn = 36;
    this.paperType_ID = 0;
    this.weight = 0;
    this.correctBlackText = false;
    this.useICC = 'FALSE';
    this.kOnlyInputPages = '';
    this.standardized = '';
    this.problemStatus = '';
    this.problemNote = '';

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if (obj) {
    //   for (const prop of Object.keys(obj)) {
    //     this[prop] = obj[prop];
    //   }
    // }
    //
    // if (obj) {
    //   this.resource = new Resource(obj.resource);
    // } else {
    //   this.resource = new Resource(undefined);
    // }
  }
  public getNormalizedPages(): number {
    let normalizedPagesMultiplier = 1;
    if ( this.height > 9 * 72 || this.width > 6 * 72 ) {
      normalizedPagesMultiplier = 2;
    }

    const pagesWithSignatureAccountedFor = Math.ceil(this.numPages * normalizedPagesMultiplier / 8) * 8 / normalizedPagesMultiplier;

    return pagesWithSignatureAccountedFor * normalizedPagesMultiplier;
  }

  getId() {
    return this.ID;
  }

  getOrderValue() {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
