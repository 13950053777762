import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import {Project} from "../../../model/editor/Project";
import {EditorProjectService} from "../../service-data/editor/editor-project.service";
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {ThemeService} from "../../service-data/theme.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnDestroy, OnInit, LoggedInCallback {
  @ViewChild('placeHolderProjectName') placeHolderProjectName;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  project: Project;

  displayCover: boolean = true;
  public renameProject: boolean = false;
  sizeWidth: 0;


  constructor(public router: Router, public startupService: StartupService, public themeService: ThemeService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, private projectService: EditorProjectService, private route: ActivatedRoute, @Inject(LOCALE_ID) localeId: string) {
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Account");
    } else {
      // This will only get cover themes if they are not already retreived
      this.themeService.getCoverThemes().subscribe( coverThemes => {
        this.startupService.setCoverThemes(coverThemes);
      });
      this.getProject();
    }
  }

  onSubmit() {

  }
  getProject() {
    const projectId = this.route.snapshot.paramMap.get('id');
    this.project = this.projectService.retreiveLocalProject(projectId);
    if ( this.project && this.project.interior ) {
      if ( this.startupService.project && this.startupService.project.id === this.project.id ) {
        if ( !this.startupService.selectedChapter ) {
          this.startupService.selectedChapter = undefined;
        }
      } else {
        this.startupService.selectedChapter = undefined;
      }
      for ( const chapter of this.project.interior.chapters ) {
        if ( this.startupService.selectedChapter === undefined ) {
          this.startupService.selectedChapter = chapter;
        } else {
          if ( this.startupService.selectedChapter.type !==  'standard') {
            this.startupService.selectedChapter = chapter;
          }
        }
      }
      this.startupService.selectedCover = this.project.cover;
      this.startupService.setProject(this.project);
    } else {
      this.startupService.selectedChapter = undefined;
      this.startupService.selectedCover = undefined;
      this.startupService.project = undefined;
      this.router.navigate(['/home/login']);
    }
  }

  onResize(event: any) {
    console.log("onresize " + event);
    const w = event.target.innerWidth;
    this.sizeWidth = w;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  isProjectNameUsed(): boolean {
    if ( !this.placeHolderProjectName || !this.placeHolderProjectName.nativeElement || !this.placeHolderProjectName.nativeElement.value ) {
      return true;
    }
    const newProjectName = this.placeHolderProjectName.nativeElement.value.trim();
    for ( const project of this.startupService.products ) {
      if ( newProjectName === project.title ) {
        return true;
      }
    }
    return false;
  }
  saveProjectValue() {
    console.log('Save Project Name' + this.placeHolderProjectName.nativeElement.value);
    this.project.name = this.placeHolderProjectName.nativeElement.value;
    this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
    // this.projectService.saveProjectImmediately(this.project).then( proj => {
    //   console.log('Updated project name');
    // });
  }
  cancelProjectValue() {
    this.placeHolderProjectName.nativeElement.value = this.project.name;
  }

}
