<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <ng-container *ngIf="!mobileQuery.matches"><span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Manufacturing Sets">Manufacturing Sets</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Manufacturing Sets">Manufacturing Sets</span></ng-container>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
    <div>
      <button (click)="setFilter();" mat-icon-button color="primary"><mat-icon *ngIf="!filterOn" i18n-title="@@Filter" title="Filter" color="accent">filter_alt_off</mat-icon><mat-icon *ngIf="filterOn" i18n-title="@@Filter" title="Filter" style="color: yellow">filter_alt</mat-icon></button>
      <span class="app-toolbar-filler"></span>
      <span *ngIf="selectedFilter !== 'None'"><span i18n="@@Filter">Filter</span><span>: {{ selectedFilter | languageString }} [{{ selectedFilterValue }}]</span></span>
      <span class="app-toolbar-filler"></span>
      <button (click)="showOnlyNotShipped = !showOnlyNotShipped; filterChanged();" mat-icon-button color="primary"><mat-icon *ngIf="!showOnlyNotShipped" i18n-title="@@Show Shipped and Not Shipped Packages" title="Show Shipped and Not Shipped Packages" color="accent">space_bar</mat-icon><mat-icon *ngIf="showOnlyNotShipped" i18n-title="@@Show Only Not Shipped Packages" title="Show Only Not Shipped Packages" style="color: yellow">space_bar</mat-icon></button>
      <button (click)="showPrintNodeOrderItems = !showPrintNodeOrderItems; filterChanged()" mat-icon-button color="primary"><mat-icon *ngIf="!showPrintNodeOrderItems" i18n-title="@@Show Print Node Order Items" title="Show Print Node Order Items" color="accent">format_list_bulleted</mat-icon><mat-icon *ngIf="showPrintNodeOrderItems" i18n-title="@@Hide Print Node Order Items" title="Hide Print Node Order Items" style="color: yellow">format_list_bulleted</mat-icon></button>
      <button (click)="showOnlyTrimmed = !showOnlyTrimmed; filterChanged()" mat-icon-button color="primary"><mat-icon *ngIf="!showOnlyTrimmed" i18n-title="@@Show Trimmed and Untrimmed" title="Show Trimmed and Untrimmed" color="accent">curtains_closed</mat-icon><mat-icon *ngIf="showOnlyTrimmed" i18n-title="@@Show Only Trimmed" title="Show Only Trimmed" style="color: yellow">curtains_closed</mat-icon></button>
<!--      <button (click)="showCompletedSets = !showCompletedSets" mat-icon-button color="primary"><mat-icon *ngIf="!showCompletedSets" i18n-title="@@Show Completed Sets" title="Show Completed Sets" color="accent">folder_open</mat-icon><mat-icon *ngIf="showCompletedSets" i18n-title="@@Show Completed Sets" title="Show Completed Sets" style="color: yellow">folder_open</mat-icon></button>-->
<!--      <button (click)="showPrintNodeOrderItems = !showPrintNodeOrderItems" mat-icon-button color="primary"><mat-icon *ngIf="!showPrintNodeOrderItems" i18n-title="@@Show Print Node Order Items" title="Show Print Node Order Items" color="accent">format_list_bulleted</mat-icon><mat-icon *ngIf="showPrintNodeOrderItems" i18n-title="@@Hide Print Node Order Items" title="Hide Print Node Order Items" style="color: yellow">format_list_bulleted</mat-icon></button>-->
    </div>
<!--    <div>-->
<!--      <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
<!--    </div>-->
    <span class="app-toolbar-filler"></span>
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="facilitypackages"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <div class="app-content" style="display: flex; flex-wrap: wrap; flex-direction: row;">
        <mat-card appearance="outlined" style="width: 100%; height: auto; overflow-y: auto; display: block;" >
          <mat-card-content>
            <div class="board">
              <ng-container *ngIf="printNodeOrders && printNodeOrders.length > 0">
                <ng-container *ngFor="let printNodeOrder of printNodeOrders">
                  <ng-container *ngIf="printNodeOrder.shippingStatus === 'NONE' || !showOnlyNotShipped">
                    <!-- showOnlyNotShipped -->
                    <ng-container *ngIf="printNodeOrder.packages.length === 1 || (printNodeOrder.packages.length > 1 && !expandPrintNodeOrderPackages['' + printNodeOrder.ID ] ) ">
                      <app-facility-package class="packageContainer" *ngIf="getFirstNotShippedPackage(printNodeOrder.packages) && !(packagesToHide['' + getFirstNotShippedPackage(printNodeOrder.packages).package_ID])" [pkg]="getFirstNotShippedPackage(printNodeOrder.packages)" [showPrintNodeOrderItems]="showPrintNodeOrderItems" [mobileQueryMatchs]="mobileQuery.matches" [expandPrintNodeOrderPackages]="expandPrintNodeOrderPackages" showExpandPrintNodeOrderPackages="true" [numberOfNotShippedPackagesInOrder]="numberOfNotShippedPackagesInPrintNodeOrder['' + printNodeOrder.ID]"></app-facility-package>
                    </ng-container>
                    <ng-container *ngIf="printNodeOrder.packages.length > 1 && expandPrintNodeOrderPackages['' + printNodeOrder.ID ]">
                      <ng-container *ngFor="let pkg of printNodeOrder.packages">
                        <app-facility-package class="packageContainer" *ngIf="!(packagesToHide['' + pkg.package_ID])" [pkg]="pkg" [showPrintNodeOrderItems]="showPrintNodeOrderItems" [mobileQueryMatchs]="mobileQuery.matches" [expandPrintNodeOrderPackages]="expandPrintNodeOrderPackages" showExpandPrintNodeOrderPackages="true" [numberOfNotShippedPackagesInOrder]="numberOfNotShippedPackagesInPrintNodeOrder['' + printNodeOrder.ID]"></app-facility-package>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






