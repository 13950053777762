import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {ProductService} from "../../service-data/product.service";
import {Product} from "../../../model/Product";
import {OrganizationInvoiceService} from "../../service-data/organization-invoice.service";
import {OrganizationInvoice} from "../../../model/OrganizationInvoice";
import {Subject} from "rxjs";
import {FacilityInvoice} from "../../../model/FacilityInvoice";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-organization-invoices',
  templateUrl: './organization-invoices.component.html',
  styleUrls: ['./organization-invoices.component.css']
})

export class OrganizationInvoicesComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [
    { columnDef: 'ID',               header: 'ID',               sort: true,  type: 'number',    filterType: 'String',          style: 'text-align: right',  property: 'ID',             cell: (element: any) => `${element.ID}` },
    { columnDef: 'organizationId',   header: 'Org ID',           sort: true,  type: 'number',    filterType: 'String',          style: 'text-align: left',   property: 'org_ID',         cell: (element: any) => `${ element.org_ID}` },
    { columnDef: 'periodType',       header: 'Period Type',      sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: center', property: 'periodType',     cell: (element: any) => `${element.periodType }` },
    { columnDef: 'periodStart',      header: 'Period Start',     sort: true,  type: 'date',      filterType: 'DateRange',       style: 'text-align: center', property: 'periodStart',    cell: (element: any) => `${element.periodStart }`, dateFormat: 'MM/dd/yy' },
    { columnDef: 'periodEnd',        header: 'Period End',       sort: true,  type: 'date',      filterType: 'DateRange',       style: 'text-align: center', property: 'periodEnd',      cell: (element: any) => `${element.periodEnd }`,   dateFormat: 'MM/dd/yy' },
    { columnDef: 'submittedDate',    header: 'Submitted',        sort: true,  type: 'date',      filterType: 'DateRange',       style: 'text-align: center', property: 'submittedDate',  cell: (element: any) => `${element.submittedDate }`,   dateFormat: 'MM/dd/yy' },
    { columnDef: 'dueDate',          header: 'Due',              sort: true,  type: 'date',      filterType: 'DateRange',       style: 'text-align: center', property: 'dueDate',        cell: (element: any) => `${element.dueDate }`,   dateFormat: 'MM/dd/yy' },
    { columnDef: 'status',           header: 'Status',           sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: center', property: 'status',         cell: (element: any) => `${element.status }`, styleFunction: (element: any) => {
        if ( element.status === 'PREPARING') {
          return 'text-align: center; background-color: green; color: white;';
        } else {
          if ( element.status === 'SENT') {
            return 'text-align: center; color: darkorange;';
          } else {
            if ( element.status === 'INITIAL') {
              return 'text-align: center; color: grey;';
            } else {
              return 'text-align: center;';
            }
          }
        }
      }},
//     { columnDef: 'status',           header: 'Status',           sort: true,  type: 'string',    filterType: 'String',          style: (element: any) => `${element.status === 'PREPARING' ? 'text-align: center; color: green' : (element.status === 'SENT' ? 'text-align: center; color: darkorange' : 'text-align: center')  }`,   property: 'status',         cell: (element: any) => `${element.status }`},
    { columnDef: 'amount',           header: 'Amount',           sort: true,  type: 'currency',  filterType: 'NumericRange',    style: 'text-align: right',  property: 'amount',         cell: (element: any) => `${element.amount}`, displayClass: 'USCurrency' },
    { columnDef: 'contestStatus',    header: 'Contest Status',   sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'contestStatus',  cell: (element: any) => `${element.contestStatus }`},
    { columnDef: 'recurring',        header: 'Recurring',        sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'recurring',      cell: (element: any) => `${element.recurring }`},
    { columnDef: 'noteToCustomer',   header: 'Note',             sort: true,  type: 'string',    filterType: 'String',          style: 'text-align: left',   property: 'noteToCustomer', cell: (element: any) => `${element.noteToCustomer ? element.noteToCustomer : '' }`}
    // { columnDef: 'url',  header: 'URL', sort: true,  type: 'link',      filterType: 'String',          style: 'text-align: left',   property: 'url',                                                    cell: (element: any) => `${element.url && element.url.length > 0 ? 'download' : ''}`,   link: (element: any) => {
    //     if ( !element.url || element.url.length === 0 ) {
    //       return '';
    //     }
    //     return element.url;
    //   }
    // }

  ];

  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<OrganizationInvoice>();
  public renderedData: OrganizationInvoice[];
  public products: Product[] = [];
  tableFilterManager: TableFilterManager<OrganizationInvoice> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  role: string;
  // shippedFilter: string = 'NONE';
  // holdFilter: string = 'FLAGGED';
  // duplicateFilter: string = 'NONE';
  // minDate: Date;
  // maxDate: Date;


  constructor(public router: Router, public startupService: StartupService, private organizationInvoiceService: OrganizationInvoiceService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public productService: ProductService) {
    this.startupService.addRoleChangeListener(this);
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the printNodeOrder is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      this.renderedData = this.dataSource.data;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: OrganizationInvoice, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  match(o: OrganizationInvoice ): boolean {
    if ( o ) {
      return true;
    }
    return false;
  }

  addUpdate(o: OrganizationInvoice) {
    if ( this.match(o)) {
      if ( this.dataSource.data.findIndex(item => item.getId() === o.getId()) === -1) {
        this.dataSource.data.push(o);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      } else {
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
    } else {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
    }
  }

  getData(): void {
    this.startupService.touchLastUserActivity();
    this.organizationInvoiceService.getAddNotifier(true).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.organizationInvoiceService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.organizationInvoiceService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      const foundIndex = this.dataSource.data.findIndex(item => item.getId() === o.getId());
      if (foundIndex > -1) {
        this.dataSource.data.splice(foundIndex, 1);
        this.dataSource.sort = this.sort;
        this.dataSource.filter = '' + Math.random();
      }
    });
    this.organizationInvoiceService.objects.forEach( o => {
      this.addUpdate(o);
    });
  }

  // const filter = {
    //   eq: {
    //     ' ( date_add(organizationInvoice.startPeriod, INTERVAL 90 DAY) > now() ) ': 1
    //   },
    //   ne: {
    //     'organizationInvoice.amount': 0
    //   }
    // };
  //   if ( true ) {
  //     this.organizationInvoiceService.getOrganizationInvoices()
  //       .subscribe(organizationInvoices => {
  //         const filteredOrganizationInvoices = [];
  //         for ( const organizationInvoice of organizationInvoices ) {
  //           if ( organizationInvoice.status !== 'INITIAL') {
  //             filteredOrganizationInvoices.push( new OrganizationInvoice( organizationInvoice ));
  //           }
  //         }
  //         this.dataSource.data = filteredOrganizationInvoices;
  //         this.dataSource.filter = '' + Math.random();
  //       });
  //   } else {
  //     this.organizationInvoiceService.getFilteredOrganizationInvoices(filter)
  //       .subscribe(organizationInvoices => {
  //         this.dataSource.data = organizationInvoices;
  //         this.dataSource.filter = '' + Math.random();
  //         // this.table.renderRows();
  //     });
  //   }
  //   this.productService.getItems()
  //     .subscribe(products => {
  //       this.products = products.sort((a, b) => b.book.tagId - a.book.tagId);
  //     });
  // }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  refresh () {
    this.organizationInvoiceService.reset();
    this.getData();
  }
}
