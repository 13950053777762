import {DraftCover} from "./DraftCover";
import {DraftBody} from "./DraftBody";
import {DataObject} from "./DataObject";
import {Project} from "./Project";

export class Draft implements DataObject {
  ID: number;
  work_ID: number = 0;
  org_ID: number;
  title: string = '';
  subtitle: string = '';
  author: string = '';
  comment: string = '';
  body_ID: number;
  cover_ID: number;
  lastUpdate: Date = new Date();
  locked_by_user_ID: number;
  bindingType: string = 'PERFECT';
  interiorType: string = 'GRAY';
  weight: number = 0;
  thickness: number = 0;
  draftBody: DraftBody;
  draftCover: DraftCover;
  project: Project;
  primaryInProject: Project;

  constructor( obj ) {
    this.primaryInProject = undefined;
    this.project = undefined;
    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        if ( obj[prop]) {
          this[prop] = obj[prop];
        }
      }
    }

    if ( obj ) {
      this.draftCover = new DraftCover( obj.draftCover );
      this.draftBody = new DraftBody( obj.draftBody );
    } else {
      this.draftCover = new DraftCover( undefined );
      this.draftBody = new DraftBody( undefined );
    }
  }

  public getCoverImageUrl(page: number, dpi: number) {
    if ( this.draftCover && this.draftCover.resource_ID ) {
      return 'http://app.snowfallpress.com/users/ripImage.jsp?color=true&resourceId=' + this.draftCover.resource_ID + '&dpi=' + dpi + '&pageNumbers=' + page + '&maxWidth=1584&maxHeight=936&authtoken=a339-5d3b-2192-4c04-b161-1dff-637b-44f2&source=' + this.org_ID;
    }
    return '';
  }
  public getBodyImageUrl(page: number, dpi: number) {
    if ( this.draftBody && this.draftBody.resource_ID ) {
      return 'http://app.snowfallpress.com/users/ripImage.jsp?color=true&resourceId=' + this.draftBody.resource_ID + '&dpi=' + dpi + '&pageNumbers=' + page + '&maxWidth=1584&maxHeight=936&authtoken=a339-5d3b-2192-4c04-b161-1dff-637b-44f2&source=' + this.org_ID;
    }
    return '';
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }

}
