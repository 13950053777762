import {Component, Input, OnInit} from '@angular/core';
import {PrintNodeOrderItem} from "../../../../model/PrintNodeOrderItem";
import {ManufacturingSetsComponent} from "../../manufacturing-sets/manufacturing-sets.component";
import {ManufacturingSet} from "../../../../model/ManufacturingSet";
import {AssignPrintNodeOrderItemToManufacturingSet} from "../assignPrintNodeOrderItemToManufacturingSet";
import {ManufacturingTaskBucketPeriod} from "../../../../model/ManufacturingTaskBucketPeriod";
import {ManufacturingTaskBucket} from "../../../../model/ManufacturingTaskBucket";
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PrintNodeOrderItemService} from "../../../service-data/print-node-order-item.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {SplitPrintNodeOrderComponent} from "../../split-print-node-order/split-print-node-order.component";
import {StartupService} from "../../../service-ui/startup.service";
import {ManufacturingBucketsComponent} from "../manufacturing-buckets.component";

@Component({
  selector: 'app-print-node-order-item-in-manufacturing-bucket',
  templateUrl: './print-node-order-item-in-manufacturing-bucket.component.html',
  styleUrls: ['./print-node-order-item-in-manufacturing-bucket.component.css']
})
export class PrintNodeOrderItemInManufacturingBucketComponent implements OnInit {
  @Input() printNodeOrderItem: PrintNodeOrderItem;
  @Input() currentManufacturingSet: ManufacturingSet;
  @Input() currentManufacturingTaskBucketPeriod: ManufacturingTaskBucketPeriod;
  @Input() currentManufacturingTaskBucket: ManufacturingTaskBucket;
  @Input() manufacturingTaskBuckets: [];
  @Input() assignPrintNodeOrderItemToManufacturingSet: AssignPrintNodeOrderItemToManufacturingSet;
  @Input() selectedPrintNodeOrderId: number;
  @Input() facilityInventoryProducts: any;
  @Input() mobileQueryMatchs: boolean;

  facilityInventoryProductsByBookId = {};
  properties = {};
  quantity: number;
  thickness: number;
  createdDate: string;
  pages: number;
  serviceProvider: number;
  oneDigitZip: string;

  units = '';
  types = ManufacturingSetsComponent.types;
  colors = ManufacturingSetsComponent.colors;
  icons = ManufacturingSetsComponent.icons;
  showAddButtons: boolean = false;
  expandActions: boolean = false;
  editSupportNote: boolean = false;

  constructor(public startupService: StartupService, private printNodeOrderItemService: PrintNodeOrderItemService, public dialog: MatDialog) { }

  ngOnInit() {
    this.setProperties();
    this.facilityInventoryProductsByBookId = {};
    this.facilityInventoryProducts.forEach( facilityInventoryProduct => {
      if ( facilityInventoryProduct.product ) {
        if ( !this.facilityInventoryProductsByBookId['' + facilityInventoryProduct.product.book_ID] ) {
          this.facilityInventoryProductsByBookId['' + facilityInventoryProduct.product.book_ID] = [];
        }
        this.facilityInventoryProductsByBookId['' + facilityInventoryProduct.product.book_ID].push(facilityInventoryProduct);
      }
    });

  }

  setProperties() {
    this.properties['binding'] = this.printNodeOrderItem.book.bindingType;

    const width = this.printNodeOrderItem.book.body.width;
    const height = this.printNodeOrderItem.book.body.height;
    this.properties['trimSize'] = '';
    if ( width / 72 === 5.5 && height / 72 === 8.5 ) {
      this.properties['trimSize'] = '_5.5X8.5';
    } else {
      if ( width / 72 === 6.0 && height / 72 === 9.0 ) {
        this.properties['trimSize'] = '_6.0X9.0';
      } else {
        if ( width / 72 === 8.5 && height / 72 === 11 ) {
          this.properties['trimSize'] = '_8.5X11.0';
        } else {
          this.properties['trimSize'] = 'Other';
        }
      }
    }
    this.properties['impositionLayout'] = width / 72 > 6 || height / 72 > 9 ? '_2UP' : '_4UP';
    this.properties['interior'] = this.printNodeOrderItem.book.interiorType;

    this.quantity = this.printNodeOrderItem.quantity;
    this.thickness = this.printNodeOrderItem.book.body.spine / 72;
    this.pages = this.printNodeOrderItem.book.body.numPages;

    this.properties['paperType'] = String(this.printNodeOrderItem.book.body.paperType_ID);
    this.properties['lamination'] = this.printNodeOrderItem.book.cover.laminateType;

    this.createdDate = '';
    this.serviceProvider = 0;
    this.oneDigitZip = ManufacturingBucketsComponent.getFacilityBasedOnCountryStateAndOneDigitZip(this.printNodeOrderItem.printNodeOrder.country, this.printNodeOrderItem.printNodeOrder.state, this.printNodeOrderItem.printNodeOrder.postalcode ? this.printNodeOrderItem.printNodeOrder.postalcode.substring(0, 1) : '', this.printNodeOrderItem.printNodeOrder.printnode_ID);

    let feetOrSheets = width / 72 > 6 || height / 72 > 9 ? this.quantity * this.pages / 4 : this.quantity * this.pages / 8;
    // Get a criteria to determine that it is comming off of a roll to make it feet
    if ( false ) {
      feetOrSheets = width / 72 > 6 || height / 72 > 9 ? this.quantity * this.pages / 4 : ( this.quantity * this.pages / 6 ) * 10 / 12;
      this.properties['impositionLayout'] =  width / 72 > 6 || height / 72 > 9 ? '_2UP' : '_3UP';
    }

    this.properties['quantityGroup'] = '_001-019';
    if ( this.quantity < 20 ) {
      this.properties['quantityGroup'] = '_001-019';
    } else {
      if ( this.quantity < 50 ) {
        this.properties['quantityGroup'] = '_020-049';
      } else {
        if ( this.quantity < 100 ) {
          this.properties['quantityGroup'] = '_050-099';
        } else {
          if ( this.quantity < 250 ) {
            this.properties['quantityGroup'] = '_100-249';
          } else {
            if ( this.quantity < 500 ) {
              this.properties['quantityGroup'] = '_250-499';
            } else {
              this.properties['quantityGroup'] = '_500+';
            }
          }
        }
      }
    }
  }

  getManufacturingTaskBucketStyle (manufacturingTaskBucket: any): string {
    let style = '';
    switch ( manufacturingTaskBucket['SinglesOrBatch'] ) {
      case 'SINGLES': {
        style += 'border-line: dashed;';
        break;
      }
      case 'BATCH': {
        style += 'border-line: sold;';
        break;
      }
      if ( manufacturingTaskBucket['name'] && manufacturingTaskBucket['name'].indexOf('Simon')) {
        style += 'border-color: green';
      } else {
        style += 'border-color: blue';
      }
    }
    return style;
  }

  showManufacturingTaskBucketPeriod (printNodeOrderItem: PrintNodeOrderItem, manufacturingTaskBucketPeriod: ManufacturingTaskBucketPeriod): boolean {
    if ( manufacturingTaskBucketPeriod.status === 'CLOSED') {
      return false;
    }
    return true;
  }

  showManufacturingSet (printNodeOrderItem: PrintNodeOrderItem, manufacturingSet: ManufacturingSet): boolean {
    if ( printNodeOrderItem.manufacturingset_id === manufacturingSet.ID ) {
      return false;
    }
    if ( manufacturingSet.openStatus === 'CLOSED' ) {
      return false;
    }
    if ( manufacturingSet.printNodeOrderItems.length === 0 ) {
      return true;
    }
    if ( manufacturingSet.details.interiorType && printNodeOrderItem.book.interiorType !== manufacturingSet.details.interiorType) {
      return false;
    }
    if ( printNodeOrderItem.book.cover.laminateType !== manufacturingSet.details.laminateType) {
      return false;
    }

    if (this.getNumberUp(printNodeOrderItem) !== manufacturingSet.details.numberUp) {
      return false;
    }
    return true;
  }

  getAssignToSpecificManufacturingSetColor(printNodeOrderItem: PrintNodeOrderItem, manufacturingSet: ManufacturingSet): string {
    if ( manufacturingSet.details.quantity && manufacturingSet.details.quantity === 0 ) {
      return 'lightgrey';
    }
    if ( this.isSamePaper(printNodeOrderItem, manufacturingSet)) {
      if ( this.isSameTrimSize(printNodeOrderItem, manufacturingSet)) {
        return 'green';
      } else {
        return '#c2c016'; // Yellow
      }
    } else {
      if ( this.isSameTrimSize(printNodeOrderItem, manufacturingSet)) {
        return 'darkorange';
      } else {
        return 'red';
      }
    }
  }
  isSameTrimSize(printNodeOrderItem: PrintNodeOrderItem, manufacturingSet: ManufacturingSet): boolean {
    if ( this.getTrimSize(printNodeOrderItem) !== manufacturingSet.details.trimSize) {
      return false;
    }
    return true;
  }

  isSamePaper(printNodeOrderItem: PrintNodeOrderItem, manufacturingSet: ManufacturingSet): boolean {
    if ( printNodeOrderItem.book.body.paperType_ID === manufacturingSet.details.interiorPaperType) {
      return true;
    }
    return false;
  }

  showManufacturingTaskBucket (printNodeOrderItem: PrintNodeOrderItem, manufacturingTaskBucket: any): boolean {
    if ( manufacturingTaskBucket['printnode_ID'] === 8) {
      return false;
    }
    if ( manufacturingTaskBucket['printnode_ID'] === 5) { // TODO: Change this to check if the facility is a fulfillment one or not and THEN check to see if it can fulfill this order
      return false;
    }
    if ( manufacturingTaskBucket['status'] === 'CLOSED') {
      return false;
    }
    if ( ( printNodeOrderItem.quantity < 20 && manufacturingTaskBucket['SinglesOrBatch'] === 'SINGLES' ) ||  ( printNodeOrderItem.quantity > 14 && manufacturingTaskBucket['SinglesOrBatch'] === 'BATCH' ) ) {
      return true;
    }
    return false;
  }

  getNumberUp (printNodeOrderItem: PrintNodeOrderItem): number {
    if ( printNodeOrderItem.book.body.width / 72 > 6 || printNodeOrderItem.book.body.height / 72 > 9 ) {
      return 2;
    }
    return 4;
  }

  getTrimSize (printNodeOrderItem: PrintNodeOrderItem): string {
    const width = Math.round(printNodeOrderItem.book.body.width / 72 * 100) / 100;
    const height = Math.round(printNodeOrderItem.book.body.height / 72 * 100) / 100;
    if ( this.fuzzyEquals(width, 8.50) && this.fuzzyEquals(height, 11.00)) {
      return '8.50x11.00';
    }
    if ( this.fuzzyEquals(width, 6.00) && this.fuzzyEquals(height, 9.00)) {
      return '6.00x9.00';
    }
    if ( this.fuzzyEquals(width, 5.50) && this.fuzzyEquals(height, 8.50)) {
      return '5.50x8.50';
    }
    if ( this.fuzzyEquals(width, 8.25) && this.fuzzyEquals(height, 10.75)) {
      return '8.25 x 10.75';
    }
    if ( this.fuzzyEquals(width, 5.00) && this.fuzzyEquals(height,  8.00)) {
      return '5.00x8.00';
    }
    if ( this.fuzzyEquals(width, 7.00) && this.fuzzyEquals(height, 10.00)) {
      return '7.00x10.00';
    }
    if ( this.fuzzyEquals(width, 5.25) && this.fuzzyEquals(height, 8.25)) {
      return '5.25x8.25';
    }
    return '---';
  }

  fuzzyEquals(a: number, b: number): boolean {
    if ( Math.abs( a - b ) <= 0.055 ) {
      return true;
    }
    return false;
  }

  canMoveToFulfillment (printNodeOrder: PrintNodeOrder): boolean {
    return true;
  }

  canMoveToSpecificFulfillmentManufacturingTaskBucket (printNodeOrder: PrintNodeOrder, manufacturingTaskBucket: ManufacturingTaskBucket): boolean {
    return false;
  }

  saveSupportNote () {
    const localPrintNodeOrderItem = new PrintNodeOrderItem(this.printNodeOrderItem, this.printNodeOrderItem.printNodeOrder);
    this.printNodeOrderItemService.updateItem(localPrintNodeOrderItem).subscribe( newPrintNodeOrderItem => {
      console.log(`Updated printNodeOrderItem Support Info`);
    });
  }

  cancelSupportNote () {
    this.printNodeOrderItem.supportNote = '';
  }


  isDateOld(date: Date): boolean {
    try {
      const d = new Date(date);
      const now = new Date();
      const milliSecondsOld = now.getTime() - d.getTime();
      if ( milliSecondsOld > 2 * 24 * 60 * 60 * 1000) {
        return true;
      }
    } catch ( e ) {
      console.log(`isDateOld Error`);
    }
    return false;
  }

  splitPrintNodeOrder (printNodeOrder: PrintNodeOrder) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = this.mobileQueryMatchs ? '600px' : '95%';
    dialogConfig.height = this.mobileQueryMatchs ? '700px' : '95%';
    dialogConfig.disableClose = false;
    dialogConfig.data = { printNodeOrder: printNodeOrder, mobileQueryMatches: this.mobileQueryMatchs };
    const dialogRef = this.dialog.open(SplitPrintNodeOrderComponent, dialogConfig);
    if ( dialogRef ) {
    }
  }

  getTest(): boolean {
    return true;
  }
}
