import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { User } from '../../model/User';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {Organization} from "../../model/Organization";
import {DataService} from "./data-service";

@Injectable()
export class UserService extends DataService {
  private objects: User[] = [];
  private objectsObservable = of( this.objects );
  public loadingState = 'INITIAL';

  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
    super(startupService);
    // this.startupService.setUserService(this);
  }

    reset (filter: string = '') {
    this.loadingState = 'INITIAL';
    while ( this.objects.length > 0) {
      this.objects.pop();
    }
  }

  /** PUT: set a user to be an admin of an organizatoin */
  resendEmailInvite (user: User): Observable<Organization> {
    const url = `${environment.apiBaseURL}user/resendEmailInvite/${user.ID}`;
    this.messageService.add(`UserService: resent email invite id=${user.ID}`);
    return this.http.put(url, user, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated organization id=${user.ID}`)),
      catchError(this.handleError<any>('resendEmailInvite'))
    );
  }

  getUsers(): Observable<User[]> {
    if ( this.loadingState === 'INITIAL') {
      this.loadingState = 'LOADING';
      const url = `${environment.apiBaseURL}user`;
      this.messageService.add('UserService: fetched users');
      return this.http.get<User[]>(url, this.startupService.getHttpOptions())
        .pipe(
          map(users => {
            while ( this.objects.length > 0) {
              this.objects.pop();
            }
            for ( const user of users ) {
              const o = new User(user);
              this.objects.push(o);
            }
            this.loadingState = 'LOADED';
            return this.objects;
          }),
          catchError(this.handleError('getUsers', []))
        );
    } else {
      return this.objectsObservable;
    }
  }

  getUser(id: string): Observable<User> {
    const url = `${environment.apiBaseURL}user/${id}`;
    this.messageService.add(`UserService: fetched user id=${id}`);
    return this.http.get<User>(url, this.startupService.getHttpOptions())
      .pipe(
        map(user => {
          const p = new User(user);
          return p;
        }),
        catchError(this.handleError<User>(`getUser id=${id}`))
      );
  }

  /** PUT: update the user on the server */
  updateUser (user: User): Observable<User> {
    const url = `${environment.apiBaseURL}user/${user.ID}`;
    this.messageService.add(`UserService: updated user id=${user.ID}`);
    return this.http.put(url, user, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated user id=${user.ID}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }
  /** PUT: update the user on the server */
  enable (user: User): Observable<User> {
    const url = `${environment.apiBaseURL}user/enable/${user.ID}`;
    this.messageService.add(`UserService: disabled user id=${user.ID}`);
    return this.http.put(url, user, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated user id=${user.ID}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }
  /** PUT: update the user on the server */
  disable (user: User): Observable<User> {
    const url = `${environment.apiBaseURL}user/disable/${user.ID}`;
    this.messageService.add(`UserService: disabled user id=${user.ID}`);
    return this.http.put(url, user, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated user id=${user.ID}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }

  addUser (user: User): Observable<User> {
    const url = `${environment.apiBaseURL}user`;
    return this.http.post<User>(url, user, this.startupService.getHttpOptions()).pipe(
      tap(o => {
        this.log(`added user w/ email=${user.email}`);
        this.objects.push(new User(o));
      }),
      catchError(this.handleError<User>('addUser'))
    );
  }

  /** DELETE: delete the user from the server */
  deleteUser (user: User): Observable<User> {
    const url = `${environment.apiBaseURL}user/${user.ID}`;

    return this.http.delete<User>(url, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`deleted user id=${user.ID}`)),
      catchError(this.handleError<User>('deleteUser'))
    );
  }

  /** PUT: set a user to be an admin of an organizatoin */
  grantProperty (user: User, property: string, value: string): Observable<User> {
    const userId = user.ID;
    const url = `${environment.apiBaseURL}user/grantProperty/${userId}/${property}/${value}`;
    this.messageService.add(`UserService: grantProperty user id=${userId} property=${property} value=${value}`);
    return this.http.put(url, user, this.startupService.getHttpOptions()).pipe(
      tap(x => {
        this.log(`grantProperty user id=${userId} property=${property} value=${value}`);
      }),
      catchError(this.handleError<any>('grantProperty'))
    );
  }

  /** PUT: set a user to be an admin of an organizatoin */
  revokeProperty (user: User, property: string, value: string): Observable<User> {
    const userId = user.ID;
    const url = `${environment.apiBaseURL}user/revokeProperty/${userId}/${property}/${value}`;
    this.messageService.add(`UserService: revokeProperty user id=${userId} property=${property} value=${value}`);
    return this.http.put(url, user, this.startupService.getHttpOptions()).pipe(
      tap(x => {
        this.log(`revokeProperty userId=${userId} property=${property} value=${value}`);
      }),
      catchError(this.handleError<any>(`revokeProperty userId${userId} property=${property} value=${value}`))
    );
  }



  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add('UserService: ' + message);
  }

}
