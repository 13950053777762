import {MediaMatcher} from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component, EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StartupService} from "../../../service-ui/startup.service";
import {LoggedInCallback} from "../../../service-auth/cognito.service";
import {Subject} from "rxjs";
import {Book} from "../../../../model/Book";
import {BookService} from "../../../service-data/book.service";

@Component({
  selector: 'app-project-book',
  templateUrl: './project-book.component.html',
  styleUrls: ['./project-book.component.css']
})
export class ProjectBookComponent implements OnDestroy, OnInit, LoggedInCallback {
  @ViewChild('placeHolderBookName') placeHolderBookName;
  @Input() book: Book;
  @Input() displayCover: boolean;
  @Input() editingDetails: string;
  @Output() editingDetailsChange = new EventEmitter<any>();

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  // book: Book;

  public renameBook: boolean = false;
  sizeWidth: 0;


  constructor(public router: Router, public startupService: StartupService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, private bookService: BookService, private route: ActivatedRoute, @Inject(LOCALE_ID) localeId: string) {
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  onDataChange() {
    this.editingDetailsChange.emit(this.editingDetails);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Account");
      this.router.navigate(['/home/login']);
    } else {
      this.startupService.touchLastUserActivity();
      // this.getBook();
    }
  }

  onSubmit() {

  }
  // getBook() {
  //   this.startupService.touchLastUserActivity();
  //   const bookId = this.route.snapshot.paramMap.get('id');
  //   this.bookService.getBook(bookId).subscribe( book => {
  //     this.book = book;
  //   }, err => {
  //     this.router.navigate(['/home/login']);
  //   });
  // }

  onResize(event: any) {
    console.log("onresize " + event);
    const w = event.target.innerWidth;
    this.sizeWidth = w;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  isBookNameUsed(): boolean {
    if ( !this.placeHolderBookName || !this.placeHolderBookName.nativeElement || !this.placeHolderBookName.nativeElement.value ) {
      return true;
    }
    const newBookName = this.placeHolderBookName.nativeElement.value.trim();
    for ( const book of this.startupService.books ) {
      if ( newBookName === book.title ) {
        return true;
      }
    }
    return false;
  }
  saveBookValue() {
    console.log('Save Book Name' + this.placeHolderBookName.nativeElement.value);
    this.book.title = this.placeHolderBookName.nativeElement.value;
    this.bookService.updateItem(this.book);
    // this.projectService.saveProjectImmediately(this.project).then( proj => {
    //   console.log('Updated project name');
    // });
  }
  cancelProjectValue() {
    this.placeHolderBookName.nativeElement.value = this.book.title;
  }

}
