  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ecommerceConnection.type | languageString }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>{{ecommerceConnection.ID}}</div>
      <div>{{ecommerceConnection.state}}</div>
      <div>{{ecommerceConnection.remoteUser}}</div>
      <div>{{ecommerceConnection.remoteURL}}</div>
      <div>{{ecommerceConnection.remoteKey}}</div>
      <div>{{ecommerceConnection.remotePassword}}</div>
      <div>{{ecommerceConnection.defaultShippingService}}</div>
      <div>{{ecommerceConnection.defaultFreeShippingService}}</div>
      <div>{{ecommerceConnection.carrierServiceMap_ID}}</div>
      <div>{{ecommerceConnection.customerorderstate}}</div>
    </mat-card-content>
    <mat-card-actions>
<!--      <button *ngIf="recurring && !showCreditCardForm" mat-flat-button color="primary" (click)="showCreditCardForm = !showCreditCardForm" i18n="@@Add">Add</button>-->
<!--      <button *ngIf="recurring && recurring.cardNumber && !showCreditCardForm" mat-stroked-button color="primary" (click)="showCreditCardForm = !showCreditCardForm" i18n="@@Edit">Edit</button>-->
    </mat-card-actions>
  </mat-card>
