import {SimpleItem} from "./SimpleItem";
import {ValidationAddress, AddressValidationResult} from "../../../service-data/address-validation.service";
import {OrderDeliverTo} from "../../../../model/OrderDeliverTo";

export class SimpleOrder {
  orderId: string;
  type: string;
  ignored: boolean;
  confirmed: boolean;

  items: SimpleItem[] = [];
  currency: string;
  ecommerceConnectionId: number;
  orderDate: string;
  submissionMethod: string;
  email: string;
  telephone: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  postalCode: string;
  region: string;
  country: string;
  title: string;
  firstName: string;
  lastName: string;
  company: string;
  shippingInfo: string;
  verifiedAddress1: string;
  verifiedAddress2: string;
  verifiedCity: string;
  verifiedRegion: string;
  verifiedPostalCode: string;
  verifiedCountry: string;
  editedAddress1: string;
  editedAddress2: string;
  editedCity: string;
  editedPostalCode: string;
  editedRegion: string;
  editedCountry: string;
  inventoryType: string;
  orderDeliverTo: OrderDeliverTo;

  edit: boolean = false;

  manualAddressValidationStatus: string = '';
  addressToUse = 'ORIGINAL';  // Also 'VERIFIED' and 'EDITED'

  verifiedStatus: string;
  verifiedResponse: string;
  public addressValidationResult: AddressValidationResult;
  saving: boolean = false;
  verifiedAddressSetByUser: boolean = false;

  constructor () {
    this.orderId = '';
    this.type = 'String';
    this.ignored = false;
    this.confirmed = false;
  }

  setVerifiedAddressOption (address: ValidationAddress, setByUser: boolean) {
    this.verifiedAddress1 = address.address1;
    this.verifiedAddress2 = address.address2;
    this.verifiedCity = address.city;
    this.verifiedRegion = address.state;
    this.verifiedPostalCode = address.postalcode;
    this.verifiedCountry = address.country;
    this.verifiedAddressSetByUser = setByUser;
    if (setByUser) {
      this.useVerifiedAddress();
    }
  }

  setOrderId (orderId: string) {
    this.orderId = orderId;
  }

  setCurrency (currency: string) {
    this.currency = currency;
  }

  setEcommerceConnection (ecommerceConnectionId: number) {
    this.ecommerceConnectionId = ecommerceConnectionId;
  }

  setOrderDate (orderDate: string) {
    this.orderDate = orderDate;
  }

  setSubmissionMethod (submissionMethod: string) {
    this.submissionMethod = submissionMethod;
  }

  setEmail (email: string) {
    this.email = email;
  }

  setPhone (telephone: string) {
    this.telephone = telephone;
  }

  setStreet (address1: string) {
    this.address1 = address1 ? address1.toUpperCase() : '';
  }

  setAddress2 (address2: string) {
    this.address2 = address2 ? address2.toUpperCase() : '';
  }

  setAddress3 (address3: string) {
    this.address3 = address3 ? address3.toUpperCase() : '';
  }

  setAddress4 (address4: string) {
    this.address4 = address4 ? address4.toUpperCase() : '';
  }

  setCity (city: string) {
    this.city = city ? city.toUpperCase() : '';
  }

  setPostalCode (postalCode: string) {
    this.postalCode = postalCode;
  }

  setRegion (region: string) {
    this.region = region ? region.toUpperCase() : '';
  }

  setCountry (country: string) {
    this.country = country;
  }

  setTitle (title: string) {
    this.title = title;
  }

  setFirst (firstName: string) {
    this.firstName = firstName;
  }

  setLast (lastName: string) {
    this.lastName = lastName;
  }

  setCompany (company: string) {
    this.company = company;
  }

  setShippingInfo (shippingInfo: string) {
    this.shippingInfo = shippingInfo;
  }

  addItem (item: SimpleItem) {
    this.items.push(item);
  }

  setAddressValidationResult (addressValidationResult: AddressValidationResult) {
    this.addressValidationResult = addressValidationResult;
    if (this.getAddressValidationStatus() === 'VALID' && this.addressValidationResult.recommendedAddresses.length > 0) {
      const originalAddress1 = this.address1 ? this.address1 : '';
      const firstValidatedAddress1 = this.addressValidationResult.recommendedAddresses[0].address1 ? this.addressValidationResult.recommendedAddresses[0].address1 : '';
      const originalAddress2 = this.address2 ? this.address2 : '';
      const firstValidatedAddress2 = this.addressValidationResult.recommendedAddresses[0].address2 ? this.addressValidationResult.recommendedAddresses[0].address2 : '';
      const originalNumericEnd = this.getNumberAtEndOfString(originalAddress1 + originalAddress2);
      const firstValidatedNumericEnd = this.getNumberAtEndOfString(firstValidatedAddress1 + firstValidatedAddress2);
      if ( originalNumericEnd === firstValidatedNumericEnd && firstValidatedAddress2.indexOf('-') === -1) {
        this.setVerifiedAddressOption(this.addressValidationResult.recommendedAddresses[0], false);
        this.useVerifiedAddress();
      }
    }
  }

  getNumberAtEndOfString(value: string): string {
    const matches = value.match(/\d+$/);
    if ( matches ) {
      return matches[0];
    }
    return '';
  }

  doesAddressHaveARangeCharacter(): boolean {
    if ( this.verifiedAddress1 && this.verifiedAddress1.indexOf('-') >= 0 ) {
      return true;
    }
    if ( this.verifiedAddress2 && this.verifiedAddress2.indexOf('-') >= 0 ) {
      return true;
    }
    return false;
  }
  getAddressValidationStatus (): string {
    if ( this.manualAddressValidationStatus === 'INVALID') {
      return 'INVALID';
    }
    if ( this.addressValidationResult && this.addressValidationResult.validationResult === 'Success' && this.addressValidationResult.recommendedAddresses.length === 1 ) {
      return 'VALID';
    } else {
      if ( this.verifiedAddress1 ) {
        return 'VALID';
      } else {
        return 'INVALID';
      }
    }
  }

  isSameAddress (simpleOrder: SimpleOrder) {
    // If the two unvalidated addresses are identical then it is the same address
    try {
      if ( this.address1 === simpleOrder.address1 && this.address2 === simpleOrder.address2 && this.city === simpleOrder.city && this.region === simpleOrder.region && this.postalCode === simpleOrder.postalCode ) {
        return true;
      }
    } catch (err) {}

    // If either did not get validated or if either does not have an address1 then they are not the same address
    if ( !this.addressValidationResult || !simpleOrder.addressValidationResult || !this.verifiedAddress1 || !simpleOrder.verifiedAddress1 ) {
      return false;
    }
    try {
      if ( this.verifiedAddress1 === simpleOrder.verifiedAddress1 && this.verifiedAddress2 === simpleOrder.verifiedAddress2 && this.verifiedCity === simpleOrder.verifiedCity && this.verifiedRegion === simpleOrder.verifiedRegion && this.verifiedPostalCode === simpleOrder.verifiedPostalCode ) {
        return true;
      }
    } catch (err) {}
    return false;
  }

  addOrderItems (simpleOrder: SimpleOrder) {
    const thisFirstName = this.firstName ? this.firstName : '';
    const thisLastName = this.lastName ? this.lastName : '';

    this.lastName = simpleOrder.firstName ? simpleOrder.firstName : '' + " " + simpleOrder.lastName ? simpleOrder.lastName : '';
    simpleOrder.items.forEach( item => {
      let foundSameItem = false;
      for ( const localItem of this.items ) {
        if ( localItem.id === item.id ) {
          foundSameItem = true;
          localItem.quantity += localItem.quantity;
        }
      }
      if ( !foundSameItem ) {
        this.items.push(item);
      }
    });
  }

  toggleMannualAddressValidation () {
    if ( this.manualAddressValidationStatus === 'INVALID' ) {
      this.manualAddressValidationStatus = '';
    } else {
      this.manualAddressValidationStatus = 'INVALID';
    }
  }

  useEditedAddress() {
    this.addressToUse = 'EDITED';
  }

  useOriginalAddress() {
    this.addressToUse = 'ORIGINAL';
  }

  useVerifiedAddress() {
    this.addressToUse = 'VERIFIED';
  }
}
