import {Component, Input, OnInit} from '@angular/core';
import {OrderDeliverTo} from "../../../../model/OrderDeliverTo";

@Component({
  selector: 'app-shipment-order',
  templateUrl: './shipment-order.component.html',
  styleUrls: ['./shipment-order.component.css']
})
export class ShipmentOrderComponent implements OnInit {
  @Input() orderDeliverTo: OrderDeliverTo;

  constructor() { }

  ngOnInit() {
  }

}
