<mat-card appearance="outlined">
  <mat-card-content>
    <ng-container *ngIf="printNodeOrderWithItems && products && products.length > 0">
      <div *ngIf="((startupService.selectedRole === 'organization' && startupService.admin)  || startupService.selectedRole === 'serviceProvider' || startupService.selectedRole === 'network')">
        <mat-form-field appearance="fill">
          <mat-label i18n="@@Select an option">Select an option</mat-label>
          <mat-select (selectionChange)="changeHoldValue($event.value)" [(value)]="printNodeOrderWithItems.hold">
            <mat-option value="NONE" i18n="@@noneUpperCase">NONE</mat-option>
            <mat-option value="CUSTOMER_REVIEW" i18n="@@CUSTOMER_REVIEW">CUSTOMER_REVIEW</mat-option>
            <mat-option value="CUSTOMER_HOLD" i18n="@@CUSTOMER_HOLD">CUSTOMER_HOLD</mat-option>
            <mat-option value="PRODUCTION_HOLD" i18n="@@PRODUCTION_HOLD">PRODUCTION_HOLD</mat-option>
            <mat-option value="CANCEL_REQUESTED" i18n="@@CANCEL_REQUESTED">CANCEL_REQUESTED</mat-option>
            <mat-option value="CANCELED" i18n="@@CANCELED">CANCELED</mat-option>
            <mat-option value="PARTIALLY_CANCELED" i18n="@@PARTIALLY_CANCELED">PARTIALLY_CANCELED</mat-option>
            <mat-option value="NOT_CANCELED" i18n="@@NOT_CANCELED">NOT_CANCELED</mat-option>
            <mat-option value="CUSTOMER_ACCEPTED" i18n="@@CUSTOMER_ACCEPTED">CUSTOMER_ACCEPTED</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <div *ngIf="!editItems" class="row">
          <div class="cell" i18n="@@Quantity">Quantity</div>
          <div class="cell" i18n="@@Title">Title</div>
        </div>
        <div *ngIf="editItems" class="editableRow">
          <div class="cell" i18n="@@New Quantity">New Quantity</div>
          <div class="cell" i18n="@@Quantity">Quantity</div>
          <div class="cell" i18n="@@Title">Title</div>
          <div class="cell"></div>
        </div>
        <ng-container *ngFor="let changeItem of changeItems.items">
          <app-print-node-order-item-admin [products]="products"  [printNodeOrderItem]="printNodeOrderWithItems.printNodeOrderItems[changeItems.items.indexOf(changeItem)]" [editableItem]="editItems" [changeItem]="changeItem" [orgId]="printNodeOrderWithItems.org_ID"></app-print-node-order-item-admin>
        </ng-container>
        <ng-container *ngIf="products && products.length > 0">
          <div *ngIf="editItems" >
            <span i18n-title="@@Save"  title="Save" (click)="saveEdit(); editItems = !editItems;"><mat-icon>check</mat-icon></span>
            <span i18n-title="@@Cancel" title="Cancel" (click)="cancelEdit(); editItems = !editItems;"><mat-icon>close</mat-icon></span>
            <span i18n-title="@@Add"  title="Add" (click)="addChangeItem();"><mat-icon>add</mat-icon></span>
          </div>
          <div *ngIf="!editItems  && printNodeOrderWithItems.inventoryType === 'FULFILL_FROM_INVENTORY' && ((startupService.selectedRole === 'organization' && startupService.admin)  || startupService.selectedRole === 'serviceProvider' || startupService.selectedRole === 'network')" (click)="editItems = !editItems" i18n-title="@@Edit Items and/or Quantities" title="Edit Items and/or Quantities"><mat-icon>edit</mat-icon></div>
        </ng-container>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
