import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import {MediaMatcher} from "@angular/cdk/layout";
import {EventHandler} from "../../table/EventHandler";
import {ScanService} from "../../service-data/scan.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-facility-scan',
  templateUrl: './facility-scan.component.html',
  styleUrls: ['./facility-scan.component.css']
})

export class FacilityScanComponent implements OnInit, LoggedInCallback, AfterViewInit, EventHandler {
  errorMessage: String = '';
  scanResponse: String = '';
  eventHandler: EventHandler;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor(public router: Router, public startupService: StartupService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public scanService: ScanService) {
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  ngAfterViewInit(): void {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    }
  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }
  public scan(eventTarget) {
    console.log("Event: " + JSON.stringify(eventTarget.value));
    this.scanService.scan(eventTarget.value).subscribe( response => {
      this.scanResponse = response;
    });
  }
}
