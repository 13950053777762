<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Orders">Orders</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Orders">Orders</span>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
<!--    <div>-->
<!--      <button mat-icon-button (click)="printLargePickSheets()" i18n-matTooltip="@@Print Large Pick Sheets" matTooltip="Print Large Pick Sheets"><mat-icon>print</mat-icon></button>-->
<!--    </div>-->
<!--    <div>-->
<!--      <button mat-icon-button (click)="printSmallPickSheets()" i18n-matTooltip="@@Print Small Pick Sheets" matTooltip="Print Small Pick Sheets"><mat-icon style="font-size: 0.7em">print</mat-icon></button>-->
<!--    </div>-->
    <div>
      <button (click)="showPickSheetPrinted = !showPickSheetPrinted; getData();" mat-icon-button color="primary"><mat-icon *ngIf="!showPickSheetPrinted" i18n-title="@@Show Printed and Not Printed Picksheets" title="Show Printed and Not Printed Picksheets" color="accent">timeline</mat-icon><mat-icon *ngIf="showPickSheetPrinted" i18n-title="@@Show Only Not Printed Picksheets" title="Show Only Not Printed Picksheets" style="color: yellow">timeline</mat-icon></button>
      <button (click)="showShipped = !showShipped; getData();" mat-icon-button color="primary"><mat-icon *ngIf="!showShipped" i18n-title="@@Show Shipped and Not Shipped" title="Show Shipped and Not Shipped" color="accent">curtains_closed</mat-icon><mat-icon *ngIf="showShipped" i18n-title="@@Show Only Not Shipped" title="Show Only Not Shipped" style="color: yellow">curtains_closed</mat-icon></button>
    </div>
<!--    <div>-->
<!--      <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
<!--    </div>-->
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="fulfillmentorders"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <div class="app-content app-table-content">
        <app-table *ngIf="columns && eventHandler && renderedData" [columns]="columns" [eventHandler]="eventHandler" [data]="renderedData" [expandedTableRowTemplate]="expandedTableRowTemplate" [dataServiceStatus]="'LOADED'" [showFilters]="true">
          <ng-template #expandedTableRowTemplate let-element>
            <app-fulfilment-order [printNodeOrder]="element"></app-fulfilment-order>
          </ng-template>
        </app-table>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






