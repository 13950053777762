import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatTable} from "@angular/material/table";
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {UserService} from "../../service-data/user.service";
import {User} from "../../../model/User";
import {PrintNodeOrderItem} from "../../../model/PrintNodeOrderItem";
import {AddUserComponent} from "./add-user/add-user.component";
import {Organization} from "../../../model/Organization";
import {Observable} from "rxjs";
import {OrganizationService} from "../../service-data/organization.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [{  property: 'ID', columnDef: 'ID', header: 'ID',
    sort: true,
    type: 'number',
    filterType: 'NumericRange',
    style: 'display: flex; justify-content: space-around; text-align: right',
    cell: (element: any) => `${element.ID}`
  }, {
    property: 'email',
    columnDef: 'email',
    header: 'Email',
    sort: true,
    type: 'string',
    filterType: 'String',
    style: 'text-align: right',
    cell: (element: any) => `${element.email}`
  },
  //   {
  //   property: 'phoneNumber',
  //   columnDef: 'phoneNumber',
  //   header: 'Phone Number',
  //   sort: true,
  //   type: 'string',
  //   filterType: 'String',
  //   style: 'text-align: right',
  //   cell: (element: any) => `${element.phoneNumber}`
  // }, //      { property: 'organization.ID',   columnDef: 'organizationId',   header: 'Organization Id',   sort: true,  type: 'number',     filterType: 'NumericRange',      style: 'text-align: right',  cell: (element: any) => `${element.organization.ID}` },
    {
      property: 'organization.name',
      columnDef: 'organizationName',
      header: 'Organization Name',
      sort: false,
      type: 'string',
      filterType: 'String',
      style: 'text-align: left',
      cell: (element: any) => {
        return (element.organization && element.organization.name ) ? element.organization.name : '';
      }
    }, // { property: 'printNodeOrder.ID', columnDef: 'packButton',   header: 'Pack',         sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'},
    // {
    //   property: 'ID',
    //   columnDef: 'resendInvite',
    //   header: 'Invite',
    //   sort: true,
    //   type: 'iconButton',
    //   filterType: 'Empty',
    //   tyle: 'text-align: right',
    //   cell: (element: any) => `${element.ID}`,
    //   buttonColor: 'primary',
    //   icon: 'system_update',
    //   clickAction: 'invite'
    // },
    // {
    //   property: 'ID',
    //   columnDef: 'delete',
    //   header: 'Delete',
    //   sort: false,
    //   type: 'iconButton',
    //   filterType: 'Empty',
    //   style: 'text-align: right',
    //   cell: (element: any) => `${element.ID}`,
    //   buttonColor: 'warn',
    //   icon: 'delete',
    //   clickAction: 'redirectToDelete'
    // },
    {
      property: 'ID',
      columnDef: 'admin',
      header: 'Admin',
      sort: true,
      type: 'iconToggleButton',
      filterType: 'Empty',
      style: 'text-align: right',
      cell: (element: any) => `${element.isAdmin}`,
      trueButtonColor: 'accent',
      falseButtonColor: 'warn',
      trueIcon: 'group',
      falseIcon: 'not_interested',
      clickAction: 'toggleAdmin'
    },
    {
      property: 'active',
      columnDef: 'active',
      header: 'Enabled',
      sort: true,
      type: 'iconToggleButton',
      filterType: 'Empty',
      style: 'text-align: right',
      cell: (element: any) => `${element.active}`,
      trueButtonColor: 'accent',
      falseButtonColor: 'warn',
      trueIcon: 'desktop_windows',
      falseIcon: 'desktop_access_disabled',
      clickAction: 'toggleAdmin'
    }];


  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<User>();
  public renderedData: User[];
  tableFilterManager: TableFilterManager<User> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', {static: true}) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor (public router: Router, public startupService: StartupService, private userService: UserService, public organizationService: OrganizationService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public dialog: MatDialog) {
    this.startupService.addRoleChangeListener(this);
    this.startupService.touchLastUserActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit () {
    console.log("Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      // if ( d && d.length > 0 ) {
      //   this.renderedData = d;
      // }
      this.renderedData = this.dataSource.data;
    });
  }

  ngAfterViewInit (): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate = (data: User, filter: string) => {
      return _this.tableFilterManager.tableFilter.match(data, filter);
    };
    // this.dataSource.paginator = this.paginator;
  }

  isLoggedIn (message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  // onSelect(printNodeOrderItem: PrintNodeOrderItem): void {
  //   this.selectedPrintNodeOrderItem = printNodeOrderItem;
  //   if ( this.selectedPrintNodeOrderItem ) {
  //     this.selectedPrintNodeOrder = this.selectedPrintNodeOrderItem.printNodeOrder;
  //   }
  // }

  getData (): void {
    this.startupService.touchLastUserActivity();
    // this.dataSource.data = [];
    this.userService.getUsers()
      .subscribe(users => {
        // for (const user of users) {
        //   console.log("In UserComponent getting data from UserService: " + JSON.stringify(user));
        //   this.dataSource.data.push(user);
        // }
        this.dataSource.data = users;
        this.dataSource.filter = '' + Math.random();
        // this.table.renderRows();
      });
  }

  ngOnDestroy (): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event (action: string, data: any, event) {
    // console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  public openAddDialog (): void {
    const dialogRef = this.dialog.open(AddUserComponent,   {
      width: '640px', disableClose: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        this.getData();
      }
    );
  }

  public toggleAdmin = (id: string) => {
    console.log("toggleAdmin: " + id);
    // this.organizationService.makeOrganizationAdmin(user.organization, user);
  }

  refresh () {
    this.userService.reset();
    this.getData();
  }

}
