import {DataObject} from "./DataObject";

export class OrganizationInvoice implements DataObject {
  ID: number;
  serviceprovider_ID: number;
  org_ID: number;
  fromOrganizationType: string;  // enum: ["FACILITY", "ORGANIZATION", "SERVICEPROVIDER", "AGENT", "NETWORK"],
  toOrganizationType: string; //    enum: ["FACILITY", "ORGANIZATION", "SERVICEPROVIDER", "AGENT", "NETWORK"],
  periodType: string; // enum: ["DAY", "WEEK", "MONTH", "QUARTER", "YEAR"],
  periodStart: Date;
  periodEnd: Date;
  invoiceNumber: number;
  lastOrderDate: Date;
  generatedDate: Date;
  submittedDate: Date;
  noteToCustomer: string | null;
  status: string; // enum: ["INITIAL", "PREPARING", "FINALIZED", "HELD", "SENT", "PAID", "CONTESTED", 'EMPTY','IGNORE'],
  paymentMethod: string | null;
  lastUpdate: Date | null;
  dueDate: Date;
  invoiceDate: Date | null;
  emailSentTo: string;
  currency: string;
  amount: number;
  recurring: string;
  contestStatus: string; // enum: ["RESOLVED", "ADJUSTED", "UNCONTESTED", "CONTESTED"],

  constructor ( obj ) {
    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
  }
  getId() {
    return this.ID;
  }

  getOrderValue() {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }

}

