<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Manufacturing Task Buckets">Manufacturing Task Buckets</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Manufacturing Task Buckets">Manufacturing Task Buckets</span>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
    <div>
      <button (click)="showEmptySets = !showEmptySets" mat-icon-button color="primary"><mat-icon *ngIf="!showEmptySets" i18n-title="@@Show Empty Sets" title="Show Empty Sets" color="accent">layers_clear</mat-icon><mat-icon *ngIf="showEmptySets" i18n-title="@@Hide Empty Sets" title="Hide Empty Sets" style="color: darkorange">layers_clear</mat-icon></button>

      <button (click)="showEmptyTaskBuckets = !showEmptyTaskBuckets" mat-icon-button color="primary"><mat-icon *ngIf="!showEmptyTaskBuckets" i18n-title="@@Show Empty Task Buckets" title="Show Empty Task Buckets" color="accent">local_drink</mat-icon><mat-icon *ngIf="showEmptyTaskBuckets" i18n-title="@@Hide Empty Task Buckets" title="Hide Empty Task Buckets" style="color: darkorange">local_drink</mat-icon></button>
      <button (click)="showClosedTaskBuckets = !showClosedTaskBuckets" mat-icon-button color="primary"><mat-icon *ngIf="!showClosedTaskBuckets" i18n-title="@@Show Closed Task Buckets" title="Show Closed Task Buckets" color="accent">lock_outline</mat-icon><mat-icon *ngIf="showClosedTaskBuckets" i18n-title="@@Hide Closed Task Buckets" title="Hide Closed Task Buckets" style="color: darkorange">lock_outline</mat-icon></button>
<!--      <button (click)="showOpenTaskBuckets = !showOpenTaskBuckets" mat-icon-button color="primary"><mat-icon *ngIf="!showOpenTaskBuckets" i18n-title="@@Hide Open Task Buckets" title="Hide Open Task Buckets" color="accent">lock_open</mat-icon><mat-icon *ngIf="showOpenTaskBuckets" i18n-title="@@Hide Open Task Buckets" title="Hide Open Task Buckets" style="color: darkorange">lock_open</mat-icon></button>-->
      <button (click)="showPrintNodeOrderItems = !showPrintNodeOrderItems" mat-icon-button color="primary"><mat-icon *ngIf="!showPrintNodeOrderItems" i18n-title="@@Show Print Node Order Items" title="Show Print Node Order Items" color="accent">format_list_bulleted</mat-icon><mat-icon *ngIf="showPrintNodeOrderItems" i18n-title="@@Hide Print Node Order Items" title="Hide Print Node Order Items" style="color: darkorange">format_list_bulleted</mat-icon></button>
<!--      <mat-button-toggle-group #group="matButtonToggleGroup" [value]="dataIndex" (change)="dataIndex = group.value">-->
<!--        <mat-button-toggle value="2" title="Line Items" i18n-title="@@Line Items">-->
<!--          <mat-icon>format_align_left</mat-icon>-->
<!--        </mat-button-toggle>-->
<!--        <mat-button-toggle value="0" title="Quantity" i18n-title="@@Quantity">-->
<!--          <mat-icon>format_align_center</mat-icon>-->
<!--        </mat-button-toggle>-->
<!--        <mat-button-toggle value="1" title="Sheets or Feet" i18n-title="@@Sheets or Feet">-->
<!--          <mat-icon>format_align_right</mat-icon>-->
<!--        </mat-button-toggle>-->
<!--      </mat-button-toggle-group>-->
    </div>
    <span class="app-toolbar-filler"></span>
<!--    <div>-->
<!--      <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
<!--    </div>-->
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="buckets"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <!-- Whole area to the right of the side nav -->
      <div class="app-content" style="display: block;"> <!-- display: flex; flex-wrap: wrap; flex-direction: row; -->
        <mat-card appearance="outlined" style="width: 100%; height: auto; overflow-y: auto; display: block;" >
          <mat-card-content>
            <div class="board">
              <!-- Each ManufacturingTaskBucket -->
              <div class="manufacturingTaskBucket" *ngFor="let manufacturingTaskBucket of manufacturingTaskBuckets">
                <h2 class="manufacturingTaskBucketH2">{{manufacturingTaskBucket.name | languageString }}</h2>
                <!-- Each ManufacturingTaskBucket Period -->
                <ng-container  *ngFor="let manufacturingTaskBucketPeriod of manufacturingTaskBucket.manufacturingTaskBucketPeriods">
                  <div class="manufacturingTaskBucketPeriod" *ngIf="( showClosedTaskBuckets || manufacturingTaskBucketPeriod.status !== 'CLOSED' ) && ( showEmptyTaskBuckets || manufacturingTaskBucketPeriod.manufacturingSets.length > 0 )" [style.background-color]="manufacturingTaskBucketPeriod.status === 'CLOSED' ? 'grey' : ''">
                    <h2 class="mat-h2">
                      <span><mat-icon>local_drink</mat-icon></span><span>{{manufacturingTaskBucketPeriod.ID}}  {{manufacturingTaskBucketPeriod.period}}</span>
                      <button mat-icon-button i18n-title="@@Close Period" title="Close Period" class="floatingButton" (click)="toggleManufacturingTaskBucketPeriodOpenStatus(manufacturingTaskBucketPeriod)" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon color="primary" *ngIf="manufacturingTaskBucketPeriod.status==='OPEN'">lock_open</mat-icon><mat-icon color="warn" *ngIf="manufacturingTaskBucketPeriod.status==='CLOSED'">lock_outline</mat-icon></button>
  <!--                    <button mat-icon-button (click)="expandBucketPeriods['' + manufacturingTaskBucketPeriod.ID] = !expandBucketPeriods['' + manufacturingTaskBucketPeriod.ID]" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon *ngIf="expandBucketPeriods['' + manufacturingTaskBucketPeriod.ID]">expand_more</mat-icon><mat-icon *ngIf="!expandBucketPeriods['' + manufacturingTaskBucketPeriod.ID]">expand_less</mat-icon></button>-->
<!--                      <button mat-icon-button i18n-title="@@Add Set" title="Add Set" (click)="addManufacturingSet(manufacturingTaskBucketPeriod); expandBucketPeriods['' + manufacturingTaskBucketPeriod.ID] = true" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon>add</mat-icon></button>-->
                      <button mat-icon-button (click)="expandBucketPeriodSets['' + manufacturingTaskBucketPeriod.ID] = !expandBucketPeriodSets['' + manufacturingTaskBucketPeriod.ID]" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon *ngIf="expandBucketPeriodSets['' + manufacturingTaskBucketPeriod.ID]">expand_more</mat-icon><mat-icon *ngIf="!expandBucketPeriodSets['' + manufacturingTaskBucketPeriod.ID]">expand_less</mat-icon></button>                    <!-- Stats for ManufacturingTaskBucketPeriod -->
                      <div *ngIf="showStats" class="columnArea">
                        <div *ngFor="let type of getTypes()" class="oneColumn">
                          <app-stacked-column [categories]="getCategories(manufacturingTaskBucketPeriod, type)" [(filter)]="manufacturingTaskBucketPeriod['filter']" [data]="getCategoryData(manufacturingTaskBucketPeriod, type, dataIndex)" [colors]="getColor(type)" [icons]="getIcons(type)" [total]="manufacturingTaskBucketPeriod['totals'][dataIndex]" totalHeight="100" [type]="type" [units]="dataIndex === 0 ? 'books' : dataIndex === 1 ? 'sheets' : 'line items'"></app-stacked-column>
                        </div>
                      </div>
                    </h2>
                    <!-- All of the sets withing the ManufacturingTaskBucketPeriod -->
                    <div  *ngIf="expandBucketPeriodSets['' + manufacturingTaskBucketPeriod.ID]" class="setsInTaskBucket">
  <!--                    <h2 class="mat-h2"><span i18n="@@Sets">Sets</span></h2>-->
                      <!-- Each ManufacturingSet -->
                      <ng-container *ngFor="let manufacturingSet of manufacturingTaskBucketPeriod.manufacturingSets">
                        <div class="manufacturingSet card-list mat-elevation-z1" *ngIf="manufacturingSet.ID != 0 && ( manufacturingSet.printNodeOrderItems.length > 0 || showEmptySets )">
                          <h7 class="mat-h2">
                            <span><mat-icon>layers</mat-icon></span><span>{{manufacturingSet.ID}}</span> <!-- stats for Set here <span>{{manufacturingSet.printNodeOrderItems && manufacturingSet.printNodeOrderItems.length ? manufacturingSet.printNodeOrderItems.length : ''}} </span> -->
                            <button mat-icon-button class="floatingButton" (click)="expandSets['' + manufacturingSet.ID] = !expandSets['' + manufacturingSet.ID]" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon *ngIf="expandSets['' + manufacturingSet.ID]">expand_more</mat-icon><mat-icon *ngIf="!expandSets['' + manufacturingSet.ID]">expand_less</mat-icon></button>
                            <button *ngIf="manufacturingSet.printnode_id !== 8 && manufacturingTaskBucketPeriod.status === 'OPEN'" mat-icon-button i18n-title="@@Close Set" title="Close Set" class="floatingButton" (click)="toggleManufacturingSetOpenStatus(manufacturingSet)" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon color="primary" *ngIf="manufacturingSet.openStatus==='OPEN'">lock_open</mat-icon><mat-icon color="warn" *ngIf="manufacturingSet.openStatus==='CLOSED'">lock_outline</mat-icon></button>
                            <ng-container *ngIf="getManufacturingTask(manufacturingSet, 'BodyPrint')">
                              <ng-container [ngSwitch]="getManufacturingTask(manufacturingSet, 'BodyPrint').inputStatus">
                                <button  *ngSwitchCase="'INITIAL'" mat-icon-button i18n-title="@@Start Prepare Body" title="Start Prepare Body" class="floatingButton" (click)="prepare(manufacturingSet, 'BodyPrint')" i18n-style="@@floatEnd" style="float:right" color="warn"><mat-icon>videogame_asset</mat-icon></button>
                                <button  *ngSwitchCase="'COMPLETE'" mat-icon-button i18n-title="@@Check Prepare Body" title="Check Prepare Body" class="floatingButton" (click)="prepare(manufacturingSet, 'BodyPrint')" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon>videogame_asset</mat-icon></button>
<!--                                <button  *ngSwitchDefault mat-icon-button i18n-title="@@Print Body" title="Print Body" class="floatingButton" (click)="print(manufacturingSet, 'BodyPrint')" i18n-style="@@floatEnd" style="float:right" color="accent"><mat-icon>videogame_asset</mat-icon></button>-->
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="getManufacturingTask(manufacturingSet, 'CoverPrint')">
                              <ng-container [ngSwitch]="getManufacturingTask(manufacturingSet, 'CoverPrint').inputStatus">
                                <button  *ngSwitchCase="'INITIAL'" mat-icon-button i18n-title="@@Start Prepare Cover" title="Start Prepare Cover" class="floatingButton" (click)="prepare(manufacturingSet, 'CoverPrint')" i18n-style="@@floatEnd" style="float:right" color="warn"><mat-icon>videogame_asset</mat-icon></button>
                                <button  *ngSwitchCase="'COMPLETE'" mat-icon-button i18n-title="@@Check Prepare Cover" title="Check Prepare Cover" class="floatingButton" (click)="prepare(manufacturingSet, 'CoverPrint')" i18n-style="@@floatEnd" style="float:right" color="primary"><mat-icon>videogame_asset</mat-icon></button>
<!--                                <button  *ngSwitchDefault mat-icon-button i18n-title="@@Print Cover" title="Print Cover" class="floatingButton" (click)="print(manufacturingSet, 'CoverPrint')" i18n-style="@@floatEnd" style="float:right" color="accent"><mat-icon>videogame_asset</mat-icon></button>-->
                              </ng-container>
                            </ng-container>
                          </h7>
                          <!-- Stats for ManufacturingSet -->
                          <div *ngIf="showStats" class="columnArea">
                            <div *ngFor="let type of getTypes()" class="oneColumn">
                              <app-stacked-column [categories]="getCategories(manufacturingSet, type)" [(filter)]="manufacturingSet['filter']" [data]="getCategoryData(manufacturingSet, type, dataIndex)" [colors]="getColor(type)" [icons]="getIcons(type)" [total]="manufacturingSet['totals'][dataIndex]" totalHeight="100" [type]="type" [units]="dataIndex === 0 ? 'books' : dataIndex === 1 ? 'sheets' : 'line items'"></app-stacked-column>
                            </div>
                          </div>
                          <div>
                            <!-- PrintNodeOrderItems in ManufacturingSet -->
  <!--                          <h2 *ngIf="showPrintNodeOrderItems" class="mat-h2"><span i18n="@@Items In Set">Items In Set</span></h2>-->
                            <div *ngIf="showPrintNodeOrderItems || expandSets['' + manufacturingSet.ID]" class="printNodeOrderItems card-list-content" [id]="'set-' + manufacturingSet.ID">
                              <mat-card appearance="outlined" *ngFor="let printNodeOrderItem of manufacturingSet.printNodeOrderItems" [id]="'printNodeOrderItem-' + printNodeOrderItem.ID"  [style.border]="printNodeOrderItem.type === 'REPRINT' ? 'solid 5px blue' : (printNodeOrderItem.type === 'PARTIAL' ? 'solid 5px red' : '')">
                                <mat-card-content style="padding: 2px 2px 4px 6px">
<!--                                  <app-print-node-order-item-in-manufacturing-set [printNodeOrderItem]="printNodeorderItem" [manufacturingSet]="manufacturingSet"></app-print-node-order-item-in-manufacturing-set>-->
                                  <app-print-node-order-item-in-manufacturing-bucket [printNodeOrderItem]="printNodeOrderItem" [currentManufacturingSet]="manufacturingSet" [currentManufacturingTaskBucketPeriod]="manufacturingTaskBucketPeriod" [currentManufacturingTaskBucket]="manufacturingTaskBucket" [manufacturingTaskBuckets]="actionableManufacturingTaskBuckets" [assignPrintNodeOrderItemToManufacturingSet]="assignPrintNodeOrderItemToManufacturingSet" [selectedPrintNodeOrderId]="selectedPrintNodeOrderId" [facilityInventoryProducts]="facilityInventoryProducts" [mobileQueryMatchs]="mobileQuery.matches"></app-print-node-order-item-in-manufacturing-bucket>
                                </mat-card-content>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






