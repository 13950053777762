import {OrderDeliverTo} from "./OrderDeliverTo";
import {PrintNodeOrderItem} from "./PrintNodeOrderItem";
import {Package} from "./Package";
import {Organization} from "./Organization";
import {Catalog} from "./Catalog";
import {DataObject} from "./DataObject";

export class PrintNodeOrder implements DataObject {
    ID: number;
    lastUpdate: Date;
    org_ID: number;
    printnode_ID: number;
    order_ID: number;
    tag: string;
    title: string | null;
    firstname: string;
    lastname: string | null;
    company: string | null;
    address1: string;
    address2: string | null;
    address3: string | null;
    address4: string | null;
    city: string;
    state: string;
    country: string;
    carrier: string;
    servicelevel: string;
    phonecountry: string | null;
    phone: string | null;
    email: string | null;
    addresstype: string;
    status: number;
    printnodestatus: number;
    orderdeliverto_ID: number;
    postalcode: string;
    created: Date;
    shippingStatus: string;
    shippedTimestamp: Date;
    purchaseOrder: string | null;
    purchaseOrder_private: string | null;
    actualShippingCost: number | null;
    packingCost: number | null;
    printNodePackingCost: number | null;
    printNodeShippingCost: number | null;
    printNodeManufacturingCost: number | null;
    addedToPrintNodeInvoiceStatus: string;
    hold: string;
    dueDate: Date;
    note: string | null;
    productionMethod: string;
    weight: number | null;
    printNodeOrderStatus: string;
    facilityinvoice_ID: string | null;
    contestStatus: string;
    contestValues: string;
    contestNote: string;
    inventoryType: string;
    trackingNumbers: string;
    thickness: number;
    quantity: number;
    itemCount: number;
    tag1: string;
    tag2: string;
    tag4: string;
    tag6: string;
    invoice_ID: number;
    shippingCharge: number;
  replenishmentFacility_ID: number;

    pickSheet: string;

    printNodeOrderItems: PrintNodeOrderItem[];
    packages: Package[];
    organization: Organization;

  constructor( obj ) {
    this.ID = 0;
    this.tag = '';
    this.tag1 = '';
    this.tag2 = '';
    this.tag4 = '';
    this.tag6 = '';
    this.title = '';
    this.firstname = '';
    this.lastname = '';
    this.company = '';
    this.address1 = '';
    this.address2 = '';
    this.address3 = '';
    this.address4 = '';
    this.city = '';
    this.state = '';
    this.country = '';
    this.carrier = '';
    this.servicelevel = '';
    this.phonecountry = '';
    this.phone = '';
    this.email = '';
    this.addresstype = '';
    this.status = 0;
    this.printnodestatus = 0;
    this.orderdeliverto_ID = 0;
    this.postalcode = '';
    this.created = new Date(0);
    this.shippingStatus = 'NONE';
    this.purchaseOrder = '';
    this.purchaseOrder_private = '';
    this.actualShippingCost = 0;
    this.packingCost = 0;
    this.printNodePackingCost = 0;
    this.printNodeShippingCost = 0;
    this.printNodeManufacturingCost = 0;
    this.addedToPrintNodeInvoiceStatus = '';
    this.hold = '';
    this.note = '';
    this.productionMethod = 'PRINT_TO_ORDER';
    this.weight = 0;
    this.thickness = 0;
    this.quantity = 0;
    this.itemCount = 0;
    this.printNodeOrderStatus = 'INITIAL';
    this.facilityinvoice_ID = '';
    this.contestStatus = 'UNCONTESTED';
    this.contestValues = '';
    this.contestNote = '';
    this.inventoryType = 'PRINT_TO_ORDER';
    this.trackingNumbers = '';
    this.invoice_ID = 0;
    this.shippingCharge = 0;
    this.pickSheet = 'INITIAL';
    this.replenishmentFacility_ID = 0;

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if ( obj ) {
    //   for ( const prop of Object.keys(obj) ) {
    //     this[prop] = obj[prop];
    //   }
    //   if ( obj.printNodeOrderItems ) {
    //     this.printNodeOrderItems = [];
    //     for ( const printNodeOrderItem of obj.printNodeOrderItems ) {
    //       this.printNodeOrderItems.push( new PrintNodeOrderItem( printNodeOrderItem, this ) );
    //     }
    //   }
    //   if ( obj.packages ) {
    //     this.packages = [];
    //     for ( const pkg of obj.packages ) {
    //       this.packages.push( new Package( pkg ) );
    //     }
    //   }
    //   if ( obj.organization ) {
    //     this.organization = new Organization( obj.organization );
    //   }
    // }
  }

  public getTrackingNumbers(): string {
    if ( this.trackingNumbers && this.trackingNumbers.length > 0 ) {
      return this.trackingNumbers.split(',').join(' ');
    }
    return '';
    // for ( const pkg of this.packages) {
    //   if ( pkg.trackingnumber && trackingNumbers.length > 2 ) {
    //     if ( pkg.trackingnumber.length > 0 ) {
    //       trackingNumbers += ' ';
    //     }
    //   }
    //  trackingNumbers += pkg.trackingnumber;
    // }
    // return trackingNumbers;
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
