import {ColorPalette} from "../color/ColorPalette";
import {Chapter} from "./Chapter";
import {InteriorTheme} from "./interior-theme/InteriorTheme";
import {Image} from "../Image";

export class Interior {
  public chapters: Chapter[];
  public interiorTheme: InteriorTheme;
  public blackAndWhite: boolean;
  public images: Image[];


  constructor( obj ) {
    this.blackAndWhite = true;
    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        this[prop] = obj[prop];
      }
    }
    this.chapters = [];
    if (obj ) {
      if ( obj.chapters ) {
        for ( const o of obj.chapters ) {
          const chapter = new Chapter( o );
          this.chapters.push(chapter);
        }
      }
      this.interiorTheme = new InteriorTheme( obj.interiorTheme );
    } else {
      this.interiorTheme = new InteriorTheme( undefined );
    }
    this.images = [];
    if ( obj && obj.images) {
      for (const o of obj.images) {
        const image = new Image(o);
        this.images.push(image);
      }
    }

  }

  addImage(key: string, url: string) {
    let found = false;
    for ( const image of this.images ) {
      if ( key === image.key ) {
        image.url = url;
        found = true;
      }
    }
    if ( !found ) {
      const image = new Image( undefined );
      image.key = key;
      image.url = url;
      this.images.push(image);
    }
  }

  removeImage(key: string) {
    for ( const image of this.images ) {
      if ( key === image.key ) {
        const index = this.images.indexOf(image);
        if ( index >= 0 ) {
          this.images.splice(index, 1 );
          return;
        }
      }
    }
  }

  setInteriorTheme(interiorTheme: InteriorTheme) {
    this.interiorTheme = interiorTheme;
  }

  getInteriorTheme(): InteriorTheme {
    return this.interiorTheme;
  }
}
