import { Injectable } from '@angular/core';
import { Cover } from '../../model/Cover';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {NotificationsService} from "./notifications.service";

@Injectable()
export class CoverService extends AbstractLiveDataService<Cover> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setCoverService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): Cover {
    return new Cover(args);
  }

  getLoggingObjectTypeName (): string {
    return "Cover";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "cover";
  }

  getLinkData(): any[] {
    return [
      { action: 'Link', objectIdPropertyName: 'resource_ID', serviceName: 'resourceService', arrayProperty: 'covers', objectProperty: 'resource'},
    ];
  }
}
