import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatTable} from "@angular/material/table";
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {OrganizationService} from "../../service-data/organization.service";
import {Organization} from "../../../model/Organization";
import {MatDialog} from "@angular/material/dialog";
import {AddOrganizationComponent} from "./add-organization/add-organization.component";
import {CatalogService} from "../../service-data/catalog.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css']
})

export class OrganizationsComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [
     { property: 'ID',                 columnDef: 'ID',              header: 'ID',       sort: true,  type: 'number', filterType: 'String', style: 'display: flex; justify-content: space-around; text-align: right',  cell: (element: any) => `${element.ID}` },
     { property: 'name',               columnDef: 'name',            header: 'Name',     sort: true,  type: 'string', filterType: 'String', style: 'text-align: right',  cell: (element: any) => `${element.name}` },
     // { property: 'agent_ID',           columnDef: 'umbrella',        header: 'Umbrella', sort: true,  type: 'string', filterType: 'NumericRange',     style: 'text-align: right',  cell: (element: any) => `${element.agent_ID}` },
     { property: 'umbrella.name', columnDef: 'umbrellaName', header: 'Umbrella', sort: true,  type: 'string', filterType: 'String',  style: 'text-align: right',  cell: (element: any) => `${element.umbrella ? element.umbrella.name : ''}` },
     // { property: 'catalogs',               columnDef: 'catalog',            header: 'Catalog',     sort: false,  type: 'iconToggleButton', trueIcon: '', falseIcon: '', filterType: 'String', style: 'text-align: right',  cell: (element: any) => `${element.catalogs === '1' ? 'star_rate' : 'circle' }` },
    { property: 'catalogs',               columnDef: 'catalog',            header: 'Catalog',     sort: false,  type: 'icon', filterType: 'String', style: 'text-align: right',  cell: (element: any) => `${ this.getCatalogIcon(element.catalogs) }` }

     // TODO: Put back for Network only     // { property: 'serviceProvider_ID', columnDef: 'serviceProvider', header: 'Service Provider', sort: true,  type: 'string', filterType: 'NumericRange',     style: 'text-align: right',  cell: (element: any) => `${element.serviceProvider_ID}` },
     // { property: 'serviceProvider.Name',       columnDef: 'serviceProviderName',        header: 'Service Provider',   sort: true,  type: 'string', filterType: 'String',  style: 'text-align: right',  cell: (element: any) => `${element.serviceProvider.Name}` }
    // // { property: 'active',            columnDef: 'active',       header: 'Active',       sort: true,  type: 'boolean',    filterType: 'Boolean',      style: 'text-align: right',  cell: (element: any) => `${element.active}` },
    // // { property: 'book.ID',           columnDef: 'previewCover', header: 'Preview',      sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.book.ID}`,  buttonColor: 'primary', icon: 'system_update',  clickAction: 'redirectToDelete'},
    // // { property: 'printNodeOrder.ID', columnDef: 'packButton',   header: 'Pack',         sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'},
//    { property: 'ID',                columnDef: 'switchToAccount', header: 'Switch To Account',       sort: false,  type: 'iconButton', filterType: 'Empty',        style: 'content-align: center',  cell: (element: any) => `${element.ID}`,  buttonColor: 'accent', icon: 'supervised_user_circle',  clickAction: 'switchToAccount'}
    // { property: 'ID',                columnDef: 'delete',       header: 'Delete',       sort: false, type: 'iconButton', filterType: 'Empty',        style: 'text-align: right',  cell: (element: any) => `${element.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'}
  ];

  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<Organization>();
  public renderedData: Organization[];
  tableFilterManager: TableFilterManager<Organization> = new TableFilterManager(this.dataSource);

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor(public router: Router, public startupService: StartupService, private organizationService: OrganizationService, public catalogService: CatalogService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public dialog: MatDialog) {
    this.startupService.addRoleChangeListener(this);
    // this.startupService.touchLastOrganizationActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }


  ngOnInit() {
    console.log("Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      // if ( d && d.length > 0 ) {
      //   this.renderedData = d;
      // }
      this.renderedData = this.dataSource.data;
    });

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: Organization, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
    this.dataSource.paginator = this.paginator;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  // onSelect(printNodeOrderItem: PrintNodeOrderItem): void {
  //   this.selectedPrintNodeOrderItem = printNodeOrderItem;
  //   if ( this.selectedPrintNodeOrderItem ) {
  //     this.selectedPrintNodeOrder = this.selectedPrintNodeOrderItem.printNodeOrder;
  //   }
  // }

  getData(): void {
    // this.dataSource.data = [];
    this.organizationService.getItems()
      .subscribe(organizations => {
        // for (const organization of organizations) {
        //   // this.dataSource.data.push(organization);
        // }
        this.dataSource.filter = '' + Math.random();
        this.dataSource.data = organizations;
        // this.table.renderRows();
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  // public switchToAccount = (id: string) => {
  //   console.log("switchToAccount: " + id);
  //   this.startupService.selectRole('organization', id);
  // }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  public openAddDialog (): void {
    const dialogRef = this.dialog.open(AddOrganizationComponent,   {
      width: '640px', disableClose: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        this.getData();
      }
    );

  }
  refresh () {
    this.organizationService.reset();
    this.getData();
  }

  getCatalogIcon(catalog: string): string {
    if ( CatalogService.catalogCache[catalog] ) {
      return CatalogService.catalogCache[catalog].iconName;
    }
    return '';
  }

}
