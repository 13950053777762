import { Injectable } from '@angular/core';
import { FacilityInventoryProduct } from '../../model/FacilityInventoryProduct';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {NotificationsService} from "./notifications.service";

@Injectable()
export class FacilityInventoryProductService extends AbstractLiveDataService<FacilityInventoryProduct> {
  // private objects: FacilityInventoryProduct[] = [];
  // private objectsObservable = of( this.objects );
  // public loadingState = 'INITIAL';


  constructor(public http: HttpClient, public messageService: MessageService, public startupService: StartupService, protected notificationsService: NotificationsService) {
    super(http, messageService, startupService, notificationsService);
    notificationsService.setFacilityInventoryProductService(this);
    notificationsService.startNotification();
  }

  makeObjectInstance(args: any): FacilityInventoryProduct {
    const object = new FacilityInventoryProduct(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "FacilityInventoryProduct";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "facilityinventoryproduct";
  }

  getLinkData(): any[] {
    return [
      { action: 'Link', objectIdPropertyName: 'product_ID', serviceName: 'productService', arrayProperty: 'facilityInventoryProducts', objectProperty: 'product'},
      { action: 'AddUpdateArray', serviceName: 'facilityInventoryProductEventService', arrayProperty: 'facilityInventoryProductEvents'},
    ];
  }

  // public getFacilityInventoryProducts(): Observable<FacilityInventoryProduct[]> {
  //   if ( this.loadingState === 'INITIAL') {
  //     this.loadingState = 'LOADING';
  //     const url = `${environment.apiBaseURL}facilityinventoryproduct`;
  //     this.messageService.add('FacilityInventoryProductService: fetched facilityInventoryProducts');
  //     return this.http.get<FacilityInventoryProduct[]>(url, this.startupService.getHttpOptions())
  //       .pipe(
  //         map(facilityInventoryProducts => {
  //           while ( this.objects.length > 0) {
  //             this.objects.pop();
  //           }
  //           for ( const facilityInventoryProduct of facilityInventoryProducts ) {
  //             const o = new FacilityInventoryProduct(facilityInventoryProduct);
  //             this.objects.push(o);
  //           }
  //           // this.startupService.facilityInventoryProducts = ps;
  //           this.loadingState = 'LOADED';
  //           return this.objects;
  //         }), catchError(this.handleError('getFacilityInventoryProducts', []))
  //       );
  //   } else {
  //     return this.objectsObservable;
  //   }
  // }
  //
  //
  // getFacilityInventoryProduct(id: number): Observable<FacilityInventoryProduct> {
  //   const url = `${environment.apiBaseURL}facilityinventoryproduct/${id}`;
  //   this.messageService.add(`FacilityInventoryProductService: fetched facilityInventoryProduct id=${id}`);
  //   return this.http.get<FacilityInventoryProduct>(url, this.startupService.getHttpOptions())
  //     .pipe(
  //       map(facilityInventoryProduct => {
  //         const p = new FacilityInventoryProduct(facilityInventoryProduct);
  //         return p;
  //       }),
  //       catchError(this.handleError<FacilityInventoryProduct>(`getFacilityInventoryProduct id=${id}`))
  //     );
  // }
  //
  // /** PUT: update the facilityInventoryProduct on the server */
  // updateFacilityInventoryProduct (facilityInventoryProduct: FacilityInventoryProduct): Observable<FacilityInventoryProduct> {
  //   const object = {};
  //   for (const prop of Object.keys(facilityInventoryProduct)) {
  //     if (facilityInventoryProduct[prop] !== null && !(facilityInventoryProduct[prop] instanceof Array) && (typeof facilityInventoryProduct[prop] === "string" || typeof facilityInventoryProduct[prop] === "number")) {
  //       object[prop] = facilityInventoryProduct[prop];
  //     } else {
  //       console.log("Not updating FacilityInventoryProduct property [" + prop + "]");
  //     }
  //   }
  //   const url = `${environment.apiBaseURL}facilityinventoryproduct/${facilityInventoryProduct.ID}`;
  //   this.messageService.add(`FacilityInventoryProductService: updated facilityInventoryProduct id=${facilityInventoryProduct.ID}`);
  //   return this.http.put(url, object, this.startupService.getHttpOptions()).pipe(
  //     tap(_ => this.log(`updated facilityInventoryProduct id=${facilityInventoryProduct.ID}`)),
  //     catchError(this.handleError<any>('updateFacilityInventoryProduct'))
  //   );
  // }
  //
  // addFacilityInventoryProduct (facilityInventoryProduct: FacilityInventoryProduct): Observable<FacilityInventoryProduct> {
  //   const url = `${environment.apiBaseURL}facilityinventoryproduct`;
  //   return this.http.post<FacilityInventoryProduct>(url, facilityInventoryProduct, this.startupService.getHttpOptions()).pipe(
  //     tap(o => {
  //       this.log(`added facilityInventoryProduct w/ id=${facilityInventoryProduct.ID}`);
  //       this.objects.push(new FacilityInventoryProduct(o));
  //     }),
  //     catchError(this.handleError<FacilityInventoryProduct>('addFacilityInventoryProduct'))
  //   );
  // }
  //
  // /** DELETE: delete the facilityInventoryProduct from the server */
  // deleteFacilityInventoryProduct (facilityInventoryProduct: FacilityInventoryProduct): Observable<FacilityInventoryProduct> {
  //   const url = `${environment.apiBaseURL}facilityinventoryproduct/${facilityInventoryProduct.ID}`;
  //
  //   return this.http.delete<FacilityInventoryProduct>(url, this.startupService.getHttpOptions()).pipe(
  //     tap(_ => this.log(`deleted facilityInventoryProduct id=${facilityInventoryProduct.ID}`)),
  //     catchError(this.handleError<FacilityInventoryProduct>('deleteFacilityInventoryProduct'))
  //   );
  // }
  //
  // /**
  //  * Handle Http operation that failed.
  //  * Let the app continue.
  //  * @param operation - name of the operation that failed
  //  * @param result - optional value to return as the observable result
  //  */
  // private handleError<T> (operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {
  //     this.loadingState = 'INITIAL';
  //
  //     // TODO: send the error to remote logging infrastructure
  //     console.error(error, operation); // log to console instead
  //
  //     // TODO: better job of transforming error for facilityInventoryProduct consumption
  //     this.log(`${operation} failed: ${error.message}`);
  //
  //     // Let the app keep running by returning an empty result.
  //     return of(result as T);
  //   };
  // }
  // private log(message: string) {
  //   this.messageService.add('FacilityInventoryProductService: ' + message);
  // }

}
