import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UserLoginService} from "../../service-auth/user-login.service";
import {CognitoCallback} from "../../service-auth/cognito.service";
import {environment as env} from "../../../environments/environment";

@Component({
    selector: 'app-language',
    templateUrl: './language.html',
    styleUrls: ['../component-account.scss']

})
export class LanguageComponent {
  environment = env;

  constructor(public router: Router) {
  }
}
