import {Injectable, NgZone} from '@angular/core';
import { Observable } from 'rxjs';
import {of} from 'rxjs';
import {MessageService} from '../service-ui/message.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {StartupService} from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import * as AWS from "aws-sdk";
import {CognitoUtil} from "../service-auth/cognito.service";
import {Message} from "aws-sdk/clients/sqs";
// import {QueueUrl} from "aws-sdk/clients/iot";
import {Router} from "@angular/router";
import {AwsUtil} from "../service-auth/aws.service";
import {WindowRef} from "../WindowRef";
import {PackageService} from "./package.service";
import {QueueUrl} from "aws-sdk/clients/iot";
import {PrintNodeOrderService} from "./print-node-order.service";
import {PrintNodeOrderItemService} from "./print-node-order-item.service";
import {ManufacturingSetService} from "./manufacturing-set.service";
import {ManufacturingTaskService} from "./manufacturing-task.service";
import {IssueService} from "./issue.service";
import {DataNotificationClient} from "./DataNotificationClient";
import {FacilityInventoryProductService} from "./facility-inventory-product.service";
import {ProductService} from "./product.service";
import {OrderItemToPackageService} from "./order-item-to-package.service";
import {CoverService} from "./cover.service";
import {BodyService} from "./body.service";
import {ResourceService} from "./resource.service";
import {BookService} from "./book.service";
import {OrderItemService} from "./order-item.service";
import {DataService, ObjectAttachRequest} from "./data-service";
import {OrganizationService} from "./organization.service";
import {FacilityInvoiceService} from "./facility-invoice.service";
import {PackageTypeService} from "./package-type.service";
import {ManufacturingTaskBucketPeriodService} from "./manufacturing-task-bucket-period.service";
import {ManufacturingTaskBucketService} from "./manufacturing-task-bucket.service";
import {ManufacturingResourceService} from "./manufacturing-resource.service";
import {FacilityService} from "./facility.service";
import {OrganizationInvoiceService} from "./organization-invoice.service";
import {DraftService} from "./draft.service";
import {AddressService} from "./address-service";
import {ChargeProfile} from "../../model/ChargeProfile";
import {ChargeProfileService} from "./charge-profile-service";
import {ProjectService} from "./project.service";

export interface NotificationListener {
  event(data);
}

@Injectable()
export class NotificationsService {
  private packageService: PackageService;
  private printNodeOrderService: PrintNodeOrderService;
  private printNodeOrderItemService: PrintNodeOrderItemService;
  private orderItemService: OrderItemService;
  private manufacturingResourceService: ManufacturingResourceService;
  private manufacturingSetService: ManufacturingSetService;
  private manufacturingTaskService: ManufacturingTaskService;
  private manufacturingTaskBucketService: ManufacturingTaskBucketService;
  private manufacturingTaskBucketPeriodService: ManufacturingTaskBucketPeriodService;
  private issueService: IssueService;
  private facilityInventoryProductService: FacilityInventoryProductService;
  private productService: ProductService;
  private projectService: ProjectService;
  private draftService: DraftService;
  private orderItemToPackageService: OrderItemToPackageService;
  private addressService: AddressService;
  private bodyService: BodyService;
  private coverService: CoverService;
  private resourceService: ResourceService;
  private bookService: BookService;
  private chargeProfileService: ChargeProfileService;
  private organizationService: OrganizationService;
  private facilityInvoiceService: FacilityInvoiceService;
  private facilityService: FacilityService;
  private packageTypeService: PackageTypeService;
  private organizationInvoiceService: OrganizationInvoiceService;

  private urlService = 'notifications';
  private serviceState = 'INITIAL';
  private sequentialSQSFailures = 0;
  private sqsStartupTime = 0;
  private sqsEndPoint: string = '';

  private pendingAddUpldateLocalItems: any = {};
  private pendingAddObjectAttachRequest: any = {};
  private instanceId: number;
  public services: any = {};

  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService, private awsUtil: AwsUtil, private cognitoUtil: CognitoUtil, private router: Router, public zone: NgZone, public windowRef: WindowRef) {
    startupService.setNotificationsService(this);
    this.instanceId = Math.ceil(Math.random() * 1000);
  }

  // setProjectService is called before startNotification is called
  setAddressService(addressService: AddressService) {
    this.services['addressService'] = addressService;
    this.addressService = addressService;
    this.processQueuedServiceActions('addressService');
  }
  setBodyService(bodyService: BodyService) {
    this.services['bodyService'] = bodyService;
    this.bodyService = bodyService;
    this.processQueuedServiceActions('bodyService');
  }
  setBookService(bookService: BookService) {
    this.services['bookService'] = bookService;
    this.bookService = bookService;
    this.processQueuedServiceActions('bookService');
  }
  setChargeProfileService(chargeProfileService: ChargeProfileService) {
    this.services['chargeProfileService'] = chargeProfileService;
    this.chargeProfileService = chargeProfileService;
    this.processQueuedServiceActions('chargeProfileService');
  }
  setCoverService(coverService: CoverService) {
    this.services['coverService'] = coverService;
    this.coverService = coverService;
    this.processQueuedServiceActions('coverService');
  }
  setFacilityInvoiceService(facilityInvoiceService: FacilityInvoiceService) {
    this.services['facilityInvoiceService'] = facilityInvoiceService;
    this.facilityInvoiceService = facilityInvoiceService;
    this.processQueuedServiceActions('facilityInvoiceService');
  }

  setFacilityService(facilityService: FacilityService) {
    this.services['facilityService'] = facilityService;
    this.facilityService = facilityService;
    this.processQueuedServiceActions('facilityService');
  }
  setFacilityInventoryProductService(facilityInventoryProductService: FacilityInventoryProductService) {
    this.services['facilityInventoryProductService'] = facilityInventoryProductService;
    this.facilityInventoryProductService = facilityInventoryProductService;
    this.processQueuedServiceActions('facilityInventoryProductService');
  }
  setIssueService(issueService: IssueService) {
    this.services['issueService'] = issueService;
    this.issueService = issueService;
    this.processQueuedServiceActions('issueService');
  }
  setManufacturingResourceService(manufacturingResourceService: ManufacturingResourceService) {
    this.services['manufacturingResourceService'] = manufacturingResourceService;
    this.manufacturingResourceService = manufacturingResourceService;
    this.processQueuedServiceActions('manufacturingResourceService');
  }

  setManufacturingSetService(manufacturingSetService: ManufacturingSetService) {
    this.services['manufacturingSetService'] = manufacturingSetService;
    this.manufacturingSetService = manufacturingSetService;
    this.processQueuedServiceActions('manufacturingSetService');
  }

  setManufacturingTaskService(manufacturingTaskService: ManufacturingTaskService) {
    this.services['manufacturingTaskService'] = manufacturingTaskService;
    this.manufacturingTaskService = manufacturingTaskService;
    this.processQueuedServiceActions('manufacturingTaskService');
  }

  setManufacturingTaskBucketService(manufacturingTaskBucketService: ManufacturingTaskBucketService) {
    this.services['manufacturingTaskBucketService'] = manufacturingTaskBucketService;
    this.manufacturingTaskBucketService = manufacturingTaskBucketService;
    this.processQueuedServiceActions('manufacturingTaskBucketService');
  }

  setManufacturingTaskBucketPeriodService(manufacturingTaskBucketPeriodService: ManufacturingTaskBucketPeriodService) {
    this.services['manufacturingTaskBucketPeriodService'] = manufacturingTaskBucketPeriodService;
    this.manufacturingTaskBucketPeriodService = manufacturingTaskBucketPeriodService;
    this.processQueuedServiceActions('manufacturingTaskBucketPeriodService');
  }

  setDraftService(draftService: DraftService) {
    this.services['draftService'] = draftService;
    this.draftService = draftService;
    this.processQueuedServiceActions('draftService');
  }

  setOrderItemService(orderItemService: OrderItemService) {
    this.services['orderItemService'] = orderItemService;
    this.orderItemService = orderItemService;
    this.processQueuedServiceActions('orderItemService');
  }
  setOrderItemToPackageService(orderItemToPackageService: OrderItemToPackageService) {
    this.services['orderItemToPackageService'] = orderItemToPackageService;
    this.orderItemToPackageService = orderItemToPackageService;
    this.processQueuedServiceActions('orderItemToPackageService');
  }
  setOrganizationService(organizationService: OrganizationService) {
    this.services['organizationService'] = organizationService;
    this.organizationService = organizationService;
    this.processQueuedServiceActions('organizationService');
  }

  setOrganizationInvoiceService(organizationInvoiceService: OrganizationInvoiceService) {
    this.services['organizationInvoiceService'] = organizationInvoiceService;
    this.organizationInvoiceService = organizationInvoiceService;
    this.processQueuedServiceActions('organizationInvoiceService');
  }
  setPackageService(packageService: PackageService) {
    this.services['packageService'] = packageService;
    this.packageService = packageService;
    this.processQueuedServiceActions('packageService');
  }
  setPackageTypeService(packageTypeService: PackageTypeService) {
    this.services['packageTypeService'] = packageTypeService;
    this.packageTypeService = packageTypeService;
    this.processQueuedServiceActions('packageTypeService');
  }

  setPrintNodeOrderService(printNodeOrderService: PrintNodeOrderService) {
    this.services['printNodeOrderService'] = printNodeOrderService;
    this.printNodeOrderService = printNodeOrderService;
    this.processQueuedServiceActions('printNodeOrderService');
  }

  setPrintNodeOrderItemService(printNodeOrderItemService: PrintNodeOrderItemService) {
    this.services['printNodeOrderItemService'] = printNodeOrderItemService;
    this.printNodeOrderItemService = printNodeOrderItemService;
    this.processQueuedServiceActions('printNodeOrderItemService');
  }

  setProductService(productService: ProductService) {
    this.services['productService'] = productService;
    this.productService = productService;
    this.processQueuedServiceActions('productService');
  }

  setProjectService(projectService: ProjectService) {
    this.services['projectService'] = projectService;
    this.projectService = projectService;
    this.processQueuedServiceActions('projectService');
  }

  setResourceService(resourceService: ResourceService) {
    this.services['resourceService'] = resourceService;
    this.resourceService = resourceService;
    this.processQueuedServiceActions('resourceService');
  }


  processQueuedServiceActions(serviceName: string) {
    if (this.services[serviceName]) {
      if (this.pendingAddObjectAttachRequest[serviceName]) {
        while (this.pendingAddObjectAttachRequest[serviceName].length > 0) {
          const x = this.pendingAddObjectAttachRequest[serviceName].pop();
          this.services[serviceName].addObjectAttachRequest(x);
        }
      }
      if (this.pendingAddUpldateLocalItems[serviceName]) {
        while (this.pendingAddObjectAttachRequest[serviceName].length > 0) {
          const y = this.pendingAddObjectAttachRequest[serviceName].pop();
          this.services[serviceName].addUpdateLocalItem(y);
        }
      }
    }
  }

  addObjectAttachRequest(serviceName: string, objectAttachRequest: ObjectAttachRequest) {
    if (this.services[serviceName]) {
      this.services[serviceName].addObjectAttachRequest(objectAttachRequest);
    } else {
      if (!this.pendingAddObjectAttachRequest[serviceName]) {
        this.pendingAddObjectAttachRequest[serviceName] = [];
      }
      this.pendingAddObjectAttachRequest[serviceName].push(objectAttachRequest);
    }
  }

  addUpdateLocalItem(serviceName: string, object: any) {
    if ( object ) {
      if (this.services[serviceName]) {
        this.services[serviceName].addUpdateLocalItem(object);
      } else {
        if (!this.pendingAddUpldateLocalItems[serviceName]) {
          this.pendingAddUpldateLocalItems[serviceName] = [];
        }
        this.pendingAddUpldateLocalItems[serviceName].push(object);
      }
    } else {
      console.log(`Error in notificationsService.addUpdateLocalItem object is null serviceName[${serviceName}]`);
    }
  }

  // startNotification () {
  //   if (this.serviceState === 'INITIAL' || this.serviceState === 'RESTARTING' || this.serviceState === 'ERROR') {
  //     const queueUrl =  `${environment.sqsURL}${this.startupService.getUserId()}`;
  //     this.sqsStartupTime = Date.now();
  //     this.serviceState = 'STARTING';
  //     this.sequentialSQSFailures = 0;
  //     this.register(queueUrl);
  //   }
  // }
  startNotification() {
    if (this.serviceState === 'INITIAL' || this.serviceState === 'RESTARTING' || this.serviceState === 'ERROR' || this.serviceState === 'STOP') {
      if (this.startupService.doesSelectedRoleNeedNotification()) {
        this.serviceState = 'STARTING';
        this.getNotificationsSQSEndPoint().subscribe(queueURLMessage => {
          console.log(`In startNotification: ${queueURLMessage} InstanceId[${this.instanceId}]`);
          if (queueURLMessage) {
            console.log("In startNotification 2: " + queueURLMessage.queueUrl);
            const msg = queueURLMessage;
            const qURL: QueueUrl = msg.queueUrl;
            this.sqsStartupTime = Date.now();
            // this.serviceState = 'STARTING';
            this.sequentialSQSFailures = 0;
            this.register(qURL);
          }
        });
      }
    }
  }

  stopNotification() {
    if (this.serviceState !== 'INITIAL') {
      this.serviceState = 'STOP';
    }
  }

  addNotificationListener(dataType: string, notificationsListener: NotificationListener) {
    // Add notification listeners to update data
  }

  // getNotificationsSQSEndPoint (): string {
  //   return `${environment.sqsURL}${this.startupService.getUserId()}`;
  // }
  getNotificationsSQSEndPoint(): Observable<any> {
    const url = `${environment.apiBaseURL}notification`;
    console.log(`GetNotificationsSQSEndPoint - InstanceId[${this.instanceId}] ${url}`);
    return this.http.get<any>(url,
      this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`fetched an SQS endpoint for data update events`)),
      catchError(this.handleError<any>(`getNotificationsSQSEndPoint`))
    );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      if (error.name === 'TokenExpiredError') {
        this.startupService.registrationRetries++;
        this.startupService.isAuthenticated(this);
      } else {
        this.startupService.registrationRetries++;
        this.startupService.isAuthenticated(this);
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("In NotificationService.isLoggedIn and not logged in and redirecting to login.");
      this.router.navigate(['/home/login']);
    } else {
      console.log("In NotificationService.isLoggedIn and  IS logged in.  StartupService.registrationRetries: " + this.startupService.registrationRetries);
      if (this.startupService.registrationRetries > 2) {
        console.log("In NotificationService.isLoggedIn and  IS logged in.  StartupService.registrationRetries: " + this.startupService.registrationRetries + " which is too many -- loging out.");
        // this.startupService.logout(); // this needs to be put back once the notification service is up and going
      } else {
        console.log("In NotificationService.isLoggedIn and  IS logged in.  StartupService.registrationRetries: " + this.startupService.registrationRetries + " trying to register again.");
        this.startNotification();
      }
    }
  }

  private log(message: string) {
    this.messageService.add('StubsService: ' + message);
  }

  register(queueUrl: string) {
    // Initilize SQS
    this.sqsEndPoint = queueUrl;
    if (this.cognitoUtil.getCurrentUser() != null) {
      this.cognitoUtil.getCurrentUser().getSession((err, session) => {
        if (err) {
          console.log("CognitoUtil: Can't set the credentials:" + err);
        } else {
          if (session.isValid()) {
            // const token = session.getRefreshToken();
            this.awsUtil.addCognitoCredentials(this.startupService.getJWTToken());
            const sqs = new AWS.SQS({region: environment.region});

            // AWS.config.update({region: environment.region});
            // AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: environment.identityPoolId });
            // sqs.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: environment.identityPoolId });

            // sqs.createQueue({ QueueName: 'cognito-' + this.startupService.getUserId(), Attributes: { } }, function(error, data) {
            //   if (error) {
            //     console.log(err, err.stack);
            //   } else {
            //     console.log(data);           // successful response
            //   }
            // });
            const queue = new AWS.SQS({params: {QueueUrl: queueUrl}}); // using url to queue
            queue.config.credentials = AWS.config.credentials;
            this.startupService.registrationRetries = 0;
            this.receiveSQSMessage(queue, queueUrl);
          }
        }
      });
    }
  }

  receiveSQSMessage(sqs, queueURL: string) {
    let callReceiveSQSMessageAgainWithTheCurrentQueue = true;

    new Promise((resolve, reject) => {
      // AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: environment.identityPoolId });
      // sqs.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: environment.identityPoolId });
      sqs.config.credentials = AWS.config.credentials;
      sqs.receiveMessage({
        AttributeNames: ["SentTimestamp"],
        MaxNumberOfMessages: 10,
        MessageAttributeNames: ["All"],
        QueueUrl: queueURL,
        VisibilityTimeout: 60,
        WaitTimeSeconds: 10
      }, (err, data) => {
        if (err) {
          this.serviceState = 'ERROR';
          console.log(err);
          this.sequentialSQSFailures++;

          if (!err.retryable || this.sequentialSQSFailures > 10) {
            reject();
          } else {
            resolve(true);
          }
        } else {
          this.serviceState = 'RUNNING';
          this.sequentialSQSFailures = 0;
          if (data.Messages.length > 0) {
            const messagePromises: Promise<any>[] = [];

            // Process ALL of the messages
            for (const messageContent of data.Messages) {
              messagePromises.push(this.processMessage(messageContent).then(() => this.deleteMessage(messageContent, sqs, queueURL)));
            }
            Promise.all(messagePromises).then(resolve);
          } else {
            // After 6 minutes abandon the current SQS queue and start listening to another one.
            // if ((this.sqsStartupTime + 6 * 60 * 1000) < Date.now() ) {
            if ((this.sqsStartupTime + 6 * 60 * 1000) < Date.now()) {
              this.serviceState = 'RESTARTING';
              this.sqsStartupTime = Date.now();
              this.startNotification();
              callReceiveSQSMessageAgainWithTheCurrentQueue = false;
            }
            resolve(true);
            // this.receiveSQSMessage(sqs, queueURL);
          }
        }
      });
    }).then(() => {
      if (callReceiveSQSMessageAgainWithTheCurrentQueue) {
        this.receiveSQSMessage(sqs, queueURL);
      }
    }).catch(() => {
      if (callReceiveSQSMessageAgainWithTheCurrentQueue) {
        if (this.cognitoUtil.getCurrentUser()) {
          this.windowRef.nativeWindow.setTimeout(() => {
            this.startNotification();
          }, 120000); // Two minutes
        } else {
          this.router.navigate(['/home']);
          // this.router.navigate(['/home/login']);
        }
      }
    });
  }

  processMessage(messageContent: Message) {
    return new Promise(resolve => {
      try {
        const message = JSON.parse(messageContent.Body);

        // Process the message
        console.log(`InstanceId[${this.instanceId}] Incomming [${message.MessageAttributes.changeType.Value}] [${message.MessageAttributes.modelType.Value}] message: ${JSON.stringify(message)}`);
        let dataNotificationClient: DataNotificationClient<any>;
        switch (message.MessageAttributes.modelType.Value) {
          case 'ADDRESS': {
            dataNotificationClient = this.addressService;
            break;
          }
          case 'BODY': {
            dataNotificationClient = this.bodyService;
            break;
          }
          case 'BOOK': {
            dataNotificationClient = this.bookService;
            break;
          }
          case 'CHARGEPROFILE': {
            dataNotificationClient = this.chargeProfileService;
            break;
          }
          case 'COVER': {
            dataNotificationClient = this.coverService;
            break;
          }
          // case 'CSV_IMPORT_SETTINGS': {
          //   dataNotificationClient = this.csvImportSettingsService;
          //   break;
          // }
          // case 'ECOMMERCE_CONNECTION': {
          //   dataNotificationClient = this.ecommerceConnectionService;
          //   break;
          // }
          case 'FACILITY': {
            dataNotificationClient = this.facilityService;
            break;
          }
          case 'FACILITY_INVOICE': {
            dataNotificationClient = this.facilityInvoiceService;
            break;
          }
          case 'FACILITY_INVENTORY_PRODUCT': {
            dataNotificationClient = this.facilityInventoryProductService;
            break;
          }
          // case 'FACILITY_INVENTORY_PRODUCT_EVENT': {
          //   dataNotificationClient = this.facilityInventoryProductEventService;
          //   break;
          // }
          // case 'IMPOSE_TASK': {
          //   dataNotificationClient = this.imposeTaskService;
          //   break;
          // }
          case 'ISSUE': {
            dataNotificationClient = this.issueService;
            break;
          }
          case 'MANUFACTURING_RESOURCE': {
            dataNotificationClient = this.manufacturingResourceService;
            break;
          }
          // case 'MANUFACTURING_RESOURCE_BUCKET': {
          //   dataNotificationClient = this.manufacturingResourceBucketService;
          //   break;
          // }
          case 'MANUFACTURING_SET': {
            dataNotificationClient = this.manufacturingSetService;
            break;
          }
          case 'MANUFACTURING_TASK': {
            dataNotificationClient = this.manufacturingTaskService;
            break;
          }
          case 'MANUFACTURING_TASK_BUCKET': {
            dataNotificationClient = this.manufacturingTaskBucketService;
            break;
          }
          case 'MANUFACTURING_TASK_BUCKET_PERIOD': {
            dataNotificationClient = this.manufacturingTaskBucketPeriodService;
            break;
          }
          // case 'MANUFACTURING_WORK': {
          //   dataNotificationClient = this.manufacturingWorkService;
          //   break;
          // }
          case 'DRAFT': {
            dataNotificationClient = this.draftService;
            break;
          }
          // case 'ORDER': {
          //   dataNotificationClient = this.orderService;
          //   break;
          // }
          // case 'ORDER_DELIVER_TO': {
          //   dataNotificationClient = this.orderDeliverToService;
          //   break;
          // }
          case 'ORGANIZATION_INVOICE': {
            dataNotificationClient = this.organizationInvoiceService;
            break;
          }
          case 'ISSUE': {
            dataNotificationClient = this.issueService;
            break;
          }
          case 'PACKAGE': {
            dataNotificationClient = this.packageService;
            break;
          }
          case 'PRINT_NODE_ORDER': {
            dataNotificationClient = this.printNodeOrderService;
            break;
          }
          case 'PRINT_NODE_ORDER_ITEM': {
            dataNotificationClient = this.printNodeOrderItemService;
            break;
          }
          case 'PRODUCT': {
            dataNotificationClient = this.productService;
            break;
          }
          case 'RESOURCE': {
            dataNotificationClient = this.resourceService;
            break;
          }
          // case 'SERVICE_PROVIDER': {
          //   dataNotificationClient = this.serviceProviderService;
          //   break;
          // }
          // case 'UMBRELLA': {
          //   dataNotificationClient = this.umberllaService;
          //   break;
          // }
          // case 'USER': {
          //   dataNotificationClient = this.userService;
          //   break;
          // }
          // case 'WORK': {
          //   dataNotificationClient = this.workService;
          //   break;
          // }
          default: {
            break;
          }
        }

        let object;
        try {
          object = JSON.parse(message.Message);
        } catch (err) {
          console.log(`Error in notifications service parsing object [${message.Message}]`);
        }
        if (object && dataNotificationClient) {
          switch (message.MessageAttributes.changeType.Value) {
            case 'CREATE': {
              this.zone.run(() => {
                dataNotificationClient.addUpdateLocalItem(object);
              });
              break;
            }
            case 'UPDATE': {
              this.zone.run(() => {
                console.log(`Notification UPDATE ${JSON.stringify(object)}`);
                dataNotificationClient.addUpdateLocalItem(object);
              });
              break;
            }
            case 'DELETE': {
              this.zone.run(() => {
                dataNotificationClient.deleteLocalItem(object);
              });
              break;
            }
              break;
            default:
              break;
          }
        }

        // const project = this.projectService.retreiveLocalProject(projectId);
        // if ( project ) {
        //   if ( message.event === 'Audio') {
        //     for ( const chapter of project.interior.chapters ) {
        //       if ( chapter.id === message.chapterId && chapter.source === 'Audio') {
        //         chapter.status = message.status;
        //         console.log("Project and chapter has transcribed audio: " + project.id + ' ' + chapter.id + ' ' + chapter.title + ' ' + chapter.status);
        //         project.status = 'INITIAL';
        //         project.coverNeedsSavingWithNewSpineSize = true;
        //         this.projectService.saveProjectImmediately(project);
        //       }
        //     }
        //   } else if ( (message.timestamp || message.timestamp === 0) && message.timestamp === project.timestamp ) {
        //     this.zone.run(() => {
        //       if ( message.pages ) {
        //         project.pages = message.pages;
        //       }
        //       switch (message.event) {
        //         case 'Cover Step 1': {
        //           if (project.progress < 1 ) {
        //             project.status = "RENDERING";
        //             project.progress = 1;
        //           }
        //           break;
        //         }
        //         case 'Cover Step 2': {
        //           if (project.progress < 2 ) {
        //             project.status = "RENDERING";
        //             project.progress = 2;
        //           }
        //           break;
        //         }
        //         case 'Cover Step 3': {
        //           if (project.progress < 3) {
        //             project.status = "RENDERING";
        //             project.progress = 3;
        //           }
        //           break;
        //         }
        //         case 'Cover Step 4': {
        //           if (project.progress < 4) {
        //             project.status = "RENDERING";
        //             project.progress = 4;
        //           }
        //           break;
        //         }
        //         case 'Interior Step 1': {
        //           if (project.progress < 5) {
        //             project.status = "RENDERING";
        //             project.progress = 5;
        //             this.projectService.getURLs(project);
        //           }
        //           break;
        //         }
        //         case 'Interior Step 2': {
        //           if (project.progress < 6) {
        //             project.status = "RENDERING";
        //             project.progress = 6;
        //           }
        //           break;
        //         }
        //         case 'Interior Step 3': {
        //           if (project.progress < 7) {
        //             project.status = "RENDERING";
        //             project.progress = 7;
        //           }
        //           break;
        //         }
        //         case 'Interior Step 4': {
        //           if (project.progress < 8) {
        //             project.status = "RENDERING";
        //             project.progress = 8;
        //           }
        //           break;
        //         }
        //         case 'Interior Step 5': {
        //           if (project.progress < 9) {
        //             project.status = "RENDERING";
        //             project.progress = 9;
        //           }
        //           break;
        //         }
        //         case 'Interior Step 6': {
        //           if (project.progress < 10) {
        //             project.status = "RENDERING";
        //             project.progress = 10;
        //           }
        //           break;
        //         }
        //         case 'Interior Step Remake Cover': {
        //           if (project.progress < 10) {
        //             project.progress = 10;
        //             this.projectService.getProjectProperties(project);
        //             this.projectService.getURLs(project);
        //             project.pages = message.pages;
        //             project.coverNeedsSavingWithNewSpineSize = true;
        //             project.status = 'SAVE_AND_RERENDER';
        //             // TODO -- BIG TODO
        //             // Make the canvas for the cover recompute and save the project again with the new page count
        //           }
        //           break;
        //         }
        //         case 'Ready': {
        //           if (project.progress < 11) {
        //             project.status = "READY";
        //             project.progress = 11;
        //             project.coverNeedsSavingWithNewSpineSize = false;
        //             this.projectService.getProjectProperties(project).then( projectProperties => {
        //               project.updateStatusAndPages(projectProperties);
        //               console.log('Got ProjectProperties for Project ' + project.id );
        //             }).catch( getProjectPropertiesError => {
        //               console.warn('Get ProjectProperties did not return an object:' + getProjectPropertiesError);
        //             });
        //             this.projectService.getURLs(project);
        //           }
        //           break;
        //         }
        //         default: {
        //           console.log("Default event: " + JSON.stringify(message));
        //         }
        //       }
        //     });
        //   }
        // }
      } catch (jsonParseError) {
        console.log("JSON Parse Error in Notifications: ", jsonParseError);
      }
      resolve(true);
    });
  }

  deleteMessage(messageContent: Message, sqs, queueURL: string) {
    return new Promise(resolve => {
      const deleteParams = {QueueUrl: queueURL, ReceiptHandle: messageContent.ReceiptHandle};
      sqs.deleteMessage(deleteParams, (err2, data2) => {
        if (err2) {
          console.log("Delete Error", err2);
          resolve(true);
        } else {
          console.log("Message Deleted", data2);
          resolve(true);
        }
      });
    });
  }

  sendKeepAlive() {
    if (this.serviceState === 'RUNNING' && this.sqsEndPoint && this.sqsEndPoint.length > 0) {
      const url = `${environment.apiBaseURL}notification/keep-alive`;
      this.http.post<any>(url, {url: this.sqsEndPoint}, this.startupService.getHttpOptions()).pipe(
        tap(_ => this.log(`keep-alive response`)),
        catchError(this.handleError<string>('keep-alive'))
      ).subscribe();
    }
  }
}




