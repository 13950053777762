import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ResourceService} from "../../../../service-data/resource.service";
import {Draft} from "../../../../../model/Draft";
import {Book} from "../../../../../model/Book";

@Component({
  selector: 'app-project-book-cover-image',
  templateUrl: './project-book-cover-image.component.html',
  styleUrls: ['./project-book-cover-image.component.css']
})
export class ProjectBookCoverImageComponent implements OnInit, OnChanges {
  @ViewChild('draftCoverImage', { static: true }) bookCoverImage;
  @Input() book: Book;

  displayScale = 1.0;

  Math = Math;

  constructor(private resourceService: ResourceService) {
  }

  ngOnInit() {
    this.refreshImagePosition();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('Change ', changes);
    this.refreshImagePosition();
  }

  refreshImagePositionDetails(cachedImage: any) {
    this.bookCoverImage.nativeElement.style.display = "block";
    this.bookCoverImage.nativeElement.src = cachedImage.src;

    let naturalWidth = cachedImage.naturalWidth;
    if ( !naturalWidth ) {
      naturalWidth = cachedImage.width;
    }

    let naturalHeight = cachedImage.naturalHeight;
    if ( !naturalHeight ) {
      naturalHeight = cachedImage.height;
    }

    // var clipWidth = ((bookBody.pageSize.width)*2+(paperWeights[paperWeights.usedWeight].caliper*bookBody.pages.numberOfPages/2*dpi) + this.trimWobble)*bookBody.displayScale.value+2*this.bleed;
    // var clipHeight = ((bookBody.pageSize.height + this.trimWobble)*bookBody.displayScale.value)+2*this.bleed;
    const clipWidth = this.bookCoverImage.nativeElement.parentNode.clientWidth - 2; // - 2 for border thickness
    const clipHeight = this.bookCoverImage.nativeElement.parentNode.clientHeight - 2;

    const pageScreenWidth = ( naturalWidth * this.displayScale * this.book.cover.documentScale);
    if ( pageScreenWidth ) {
      this.bookCoverImage.nativeElement.style.width = pageScreenWidth + 'px';
      const pageScreenHeight = ( naturalHeight * this.displayScale * this.book.cover.documentScale);

      this.bookCoverImage.nativeElement.style.height = pageScreenHeight + 'px';

      this.bookCoverImage.nativeElement.style.left = ( ( clipWidth - pageScreenWidth ) / 2 + this.book.cover.horizontalShift * this.displayScale ) + 'px';
      this.bookCoverImage.nativeElement.style.top = ( ( clipHeight - pageScreenHeight ) / 2 - this.book.cover.verticalShift * this.displayScale ) + 'px';

      this.bookCoverImage.nativeElement.style.width = '436px';
      this.bookCoverImage.nativeElement.style.height = '297px';
      this.bookCoverImage.nativeElement.style.left = '-230px';
      this.bookCoverImage.nativeElement.style.top = '0px';

    }
  }

  refreshImagePosition() {
    if ( !this.book.cover ) {
      return;
    }
    let resourceId = this.book.cover.resource_ID;
    let bodyOrCover = 'COVER';
    if ( this.book.bindingType === 'SADDLE_STITCH' ) {
      resourceId = this.book.body.resource_ID;
      bodyOrCover = 'BODY';
    }

    if ( !resourceId ) {
      this.bookCoverImage.nativeElement.src = '';
      return;
    }

    // let imageUrl;
    let imageId;
    switch ( bodyOrCover ) {
      case 'COVER': {
        imageId = 'resource-' + this.book.cover.resource_ID;
        break;
      }
      case 'BODY': {
        imageId = 'resource-' + this.book.body.resource_ID;
        break;
      }
    }
    let cachedImage: any = document.getElementById(imageId);
    if ( !cachedImage ) {
      const hiddenImagesElement: any = document.getElementById('hiddenImages');
      if ( hiddenImagesElement ) {
        const imageElement = document.createElement("img");
        this.resourceService.getImageURL(resourceId, 'COLOR', 72, 1).subscribe( imageURL => {
          if ( imageURL ) {
            console.log(`Getting image for resourceId: ${resourceId} 'COLOR' 72 1 ${imageURL}`);
            imageElement.src = imageURL;
            imageElement.id = 'resource-' + resourceId;
            hiddenImagesElement.appendChild(imageElement);
            cachedImage = imageElement;
            imageElement.src = imageURL;
            if (cachedImage.complete ) {
              this.refreshImagePositionDetails(cachedImage);
            } else {
              cachedImage.addEventListener('load', _ => {
                this.refreshImagePositionDetails(cachedImage);
              });
              cachedImage.addEventListener('error', _ => {
                console.log(`Error loading image ${'resource-' + resourceId}`);
              });
            }
          }
        });

        imageElement.id = imageId;
        hiddenImagesElement.appendChild(imageElement);
        cachedImage = imageElement;
      }
    }
    if ( cachedImage ) {
      if (cachedImage.complete ) {
        this.refreshImagePositionDetails(cachedImage);
      } else {
        cachedImage.addEventListener('load', _ => {
          this.refreshImagePositionDetails(cachedImage);
        });
        cachedImage.addEventListener('error', _ => {
          console.log(`Error loading image ${imageId}`);
        });
      }
    }
  }
}
