import {OrderDeliverTo} from "./OrderDeliverTo";
import {Organization} from "./Organization";

export class Order {
  ID: number;
  org_ID: number;
  created: Date;
  lastUpdate: Date;
  customerorderid: string;
  user_ID: number;
  status: string;
  shipmentUpdateStatus: string;
  shipmentUpdateTimestamp: Date;
  customerOrderBlob: string;
  ecommerceconnection_ID: number;
  purchaseOrder: string;
  purchaseOrder_private: string;
  shippingStatus: string;
  shippedTimestamp: Date;
  invoice_ID: number;
  productCollected: number;
  taxCollected: number;
  shippingCollected: number;
  packingCollected: number;
  currency: string;
  addedToInvoiceStatus: string;
  sentShippedEmailToConsumer: string;
  gatewayCharge: number;
  shippingCharge: number;
  packingCharge: number;
  manufacturingCharge: number;
  serviceProviderGatewayCharge: number;
  serviceProviderShippingCharge: number;
  serviceProviderPackingCharge: number;
  serviceProviderManufacturingCharge: number;
  manufacturingTaxCharge: number;
  gatewayTaxCharge: number;
  packingTaxCharge: number;
  shippingTaxCharge: number;
  negotiatedShippingCost: number;
  agentMargin: number;
  chargeCurrency: string;
  addedToServiceProviderInvoiceStatus: string;
  productionMethod: string;
  weight: number;
  serviceproviderinvoice_ID: string;
  agentinvoice_ID: string;
  organizationinvoice_ID: string;
  discount_id: string;
  discountAmount: number;
  serviceProviderContestStatus: string;
  agentContestStatus: string;
  organizationContestStatus: string;
  serviceProviderContestValues: string;
  agentContestValues: string;
  organizationContestValues: string;
  serviceProviderContestNote: string;
  agentContestNote: string;
  organizationContestNote: string;
  inventoryType: string;
  shipmentsTotal: number;
  shipmentsShipped: number;
  shipmentsHold: number;
  shipmentsBadAddress: number;
  shipmentsDuplicates: number;
  quantity: number;
  invoicedByCustomerStatus: string;
  customerNote: string;
  payer: string;
  replenishmentFacility_ID: number;

  orderDeliverTos: OrderDeliverTo[];
  organization: Organization;

  constructor( obj ) {
    this.created = new Date(0);
    this.lastUpdate = new Date(0);
    this.customerorderid = '';
    this.user_ID = 0;
    this.status = 'PENDING';
    this.shipmentUpdateStatus = 'SENT';
    this.shipmentUpdateTimestamp = new Date(0);
    this.customerOrderBlob = '';
    this.ecommerceconnection_ID = 1;
    this.purchaseOrder = '';
    this.purchaseOrder_private = '';
    this.shippingStatus = 'NONE';
    this.shippedTimestamp = new Date(0);
    this.invoice_ID = 0;
    this.productCollected = 0;
    this.taxCollected = 0;
    this.shippingCollected = 0;
    this.packingCollected = 0;
    this.currency = '';
    this.addedToInvoiceStatus = 'INITIAL';
    this.sentShippedEmailToConsumer = 'INITIAL';
    this.gatewayCharge = 0;
    this.shippingCharge = 0;
    this.packingCharge = 0;
    this.manufacturingCharge = 0;
    this.serviceProviderGatewayCharge = 0;
    this.serviceProviderShippingCharge = 0;
    this.serviceProviderPackingCharge = 0;
    this.serviceProviderManufacturingCharge = 0;
    this.manufacturingTaxCharge = 0;
    this.gatewayTaxCharge = 0;
    this.packingTaxCharge = 0;
    this.shippingTaxCharge = 0;
    this.negotiatedShippingCost = 0;
    this.agentMargin = 0;
    this.chargeCurrency = '';
    this.addedToServiceProviderInvoiceStatus = 'INITIAL';
    this.productionMethod = 'PRINT_TO_ORDER';
    this.weight = 0;
    this.serviceproviderinvoice_ID = '';
    this.agentinvoice_ID = '';
    this.organizationinvoice_ID = '';
    this.discount_id = '';
    this.discountAmount = 0;
    this.serviceProviderContestStatus = 'UNCONTESTED';
    this.agentContestStatus = 'UNCONTESTED';
    this.organizationContestStatus = 'UNCONTESTED';
    this.serviceProviderContestValues = 'NONE';
    this.agentContestValues = 'NONE';
    this.organizationContestValues = 'NONE';
    this.serviceProviderContestNote = '';
    this.agentContestNote = '';
    this.organizationContestNote = '';
    this.inventoryType = 'PRINT_TO_ORDER';
    this.shipmentsTotal = 1;
    this.shipmentsShipped = 0;
    this.shipmentsHold = 0;
    this.shipmentsBadAddress = 0;
    this.shipmentsDuplicates = 0;
    this.quantity = 0;
    this.invoicedByCustomerStatus = 'FALSE';
    this.customerNote = '';
    this.payer = 'ORGANIZATION';
    this.replenishmentFacility_ID = 0;
    // this.orderDeliverTos = [];

    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        this[prop] = obj[prop];
      }
      this.orderDeliverTos = [];
      if ( obj.orderDeliverTos ) {
        for ( const orderDeliverTo of obj.orderDeliverTos ) {
          this.orderDeliverTos.push( new OrderDeliverTo( orderDeliverTo ) );
        }
      }
      if ( obj.organization ) {
        this.organization = new Organization( obj.organization );
      }
    }

  }

  getQuantity (): number {
    let quantity = 0;
    if ( this.orderDeliverTos ) {
      for (const orderDeliverTo of this.orderDeliverTos) {
        if (orderDeliverTo.orderItems) {
          for (const orderItem of orderDeliverTo.orderItems) {
            quantity += orderItem.quantity;
          }
        } else {
          return NaN;
        }
      }
    } else {
      return NaN;
    }
    return quantity;
  }
}
