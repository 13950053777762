
export class ImageOption {
  public imageKey: string;
  public verticalAdjustment: number;
  public horizontalAdjustment: number;
  public scaleAdjustment: number;

  constructor ( obj ) {
    this.imageKey = '';
    this.verticalAdjustment = 0;
    this.horizontalAdjustment = 0;
    this.scaleAdjustment = 1;
    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        this[prop] = obj[prop];
      }
    }
  }

}
