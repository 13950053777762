<div style="width: 100%">
  <div style="padding-top: 10px; margin-left: auto; margin-right: auto;">
<!--    <mat-grid-list cols="2" >-->
<!--      <mat-grid-tile i18n-class="@@dir">-->
<!--        <mat-card style="background: rgba(59, 56, 87, 0.94); height: 100%; width: 100%;">-->
<!--          <mat-card-content>-->
<!--            <div style="padding: 30px;">-->
<!--              <h3 style="padding-top: 150px;" i18n="@@Setting your password.">Setting your password.</h3>-->
<!--              <p i18n="@@....">..................................................................................</p>-->
<!--              <h2 style="padding-top: 40px" i18n="@@Main Organization Name">Main Organization Name</h2>-->
<!--              <p i18n="@@START YOUR PROJECT TODAY">START YOUR PROJECT TODAY</p>-->
<!--            </div>-->
<!--          </mat-card-content>-->
<!--        </mat-card>-->
<!--      </mat-grid-tile>-->
<!--      <mat-grid-tile i18n-class="@@dir">-->
        <mat-card appearance="outlined" class="formCard">
          <h1 i18n="@@Confirm Registration">Confirm Registration</h1>
          <img src="/logo.png" class="intro-logo">
          <mat-card-content>
            <form style="padding-top: 45px; padding-bottom: 45px;">
              <mat-form-field class="full-width" *ngIf="email===null">
                <input matInput i18n-placeholder="@@Email" placeholder="Email" id="signupEmail" required type="email" maxlength="100" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
                <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput i18n-placeholder="@@Confirmation Code" placeholder="Confirmation Code" id="confirmationCode" required type="text" maxlength="25" length="50" [(ngModel)]="confirmationCode" [ngModelOptions]="{standalone: true}">
              </mat-form-field>
            </form>
          </mat-card-content>
          <mat-card-actions class="primaryAction">
            <button mat-raised-button color="primary" (click)="onConfirmRegistration()" id="confirmRegistration" type="submit" i18n="@@Confirm">Confirm</button>
          </mat-card-actions>
          <mat-card-actions class="secondaryAction">
            <p>Can't find your code? <button mat-flat-button color="link" [routerLink]="['/home/resendCode']"><ng-container i18n="@@Resend Code">Resend Code</ng-container></button></p>
            <button *ngIf="environment.allowUsersToSignThemSelvesUp" mat-button color="primary" [routerLink]="['/home/register']"><ng-container i18n="@@Register">Register</ng-container></button>
            <button mat-button color="primary" [routerLink]="['/home/login']"><ng-container i18n="@@Login">Login</ng-container></button>
          </mat-card-actions>
        </mat-card>
<!--      </mat-grid-tile>-->
<!--    </mat-grid-list>-->
  </div>
</div>
