import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import {of} from 'rxjs';
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import {PickSheetService} from "../../service-data/pick-sheet.service";
import {FacilityService} from "../../service-data/facility.service";
import {MediaMatcher} from "@angular/cdk/layout";
import {EventHandler} from "../../table/EventHandler";
import {PickSheetsGroup} from "./PickSheetGroup";
import {InventoryLevelsComputer} from "./InventoryLevelsComputer";
import {FacilityInventoryProduct} from "../../../model/FacilityInventoryProduct";
import {FacilityInventoryProductService} from "../../service-data/facility-inventory-product.service";
import {Organization} from "../../../model/Organization";
import {OrganizationService} from "../../service-data/organization.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-picksheets',
  templateUrl: './picksheets.component.html',
  styleUrls: ['./picksheets.component.css']
})

export class PicksheetsComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener, InventoryLevelsComputer {

  eventHandler: EventHandler;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();
  inventoryLevlesComputer: InventoryLevelsComputer;

  printNodeOrders: PrintNodeOrder[] = [];
  role: string;

  pickSheetsGroups: PickSheetsGroup[] = [];

  organizations: Organization[] = [];
  selectedOrganization = 0;
  displayButtons = false;
  // Sytling guide for range sliders https://github.com/angular-slider/ng5-slider/blob/master/STYLING.md
  // minWeight: number = 0;
  // maxWeight: number = 200;
  // weightOptions: Options = {
  //   floor: 0,
  //   ceil: 200
  // };
  //
  // minItems: number = 1;
  // maxItems: number = 200;
  // itemsOptions: Options = {
  //   floor: 1,
  //   ceil: 200
  // };
  // minPrintNodeOrderId: number = 204;
  // maxPrintNodeOrderId: number = 220;
  // printNodeOrderIdOptions: Options = {
  //   floor: 204,
  //   ceil: 220
  // };
  // minDate: number = -30;
  // maxDate: number = 0;
  // dateOptions: Options = {
  //   floor: -30,
  //   ceil: 0
  // };
  bulk: boolean = true;
  individual: boolean = true;

  envelopes: boolean = true;
  fourInBoxes: boolean = true;
  medFlatRate: boolean = true;
  ground: boolean = true;
  groundFreightPricing: boolean = true;
  freight: boolean = true;

  single: boolean = true;
  only: boolean = true;
  pure: boolean = true;
  pureMixed: boolean = true;
  mixed: boolean = true;
  abmixed: boolean = true;
  // aOnly: boolean = true;
  // bOnly: boolean = true;
  // aAndB: boolean = true;

  dates: string[] = [];
  datesMatrix: any[] = [];
  dateIndexes: any = {};
  dateQuantities: any = {};
  dateOrders: any = {};

  inventoryNeededLables: string[] = [];
  inventoryNeededForSelectedPickSheets: number[] = [];
  inventoryOnHand: number[] = [];
  inventoryCommited: number[] = [];
  inventoryOnOrder: number[] = [];
  inventoryNet: number[] = [];
  private facilityInventoryProducts: FacilityInventoryProduct[];
  maxInventoryNeeded: number = 10;
  maxInventoryOnHand: number = 10;
  maxInventoryCommited: number = 10;

  constructor(public router: Router, public startupService: StartupService, private facilityInventoryProductService: FacilityInventoryProductService, private organizationService: OrganizationService, private printNodeOrderService: PrintNodeOrderService, private pickSheetService: PickSheetService, private facilityService: FacilityService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.inventoryLevlesComputer = this;
    this.startupService.addRoleChangeListener(this);
    // this.startupService.touchLastPrintNodeOrderActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the printNodeOrder is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  ngAfterViewInit(): void {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  // getDateIndex(date: string): number {
  //   return Number(date) % 30;
  // }

  // getDateName(index: number): string {
  //
  // }

  getData(): void {
    this.dates = [];
    this.datesMatrix = [];
    this.dateIndexes = {};
    this.dateQuantities = {};
    this.dateOrders = {};
    this.inventoryNeededLables = [];
    this.inventoryNeededForSelectedPickSheets = [];
    this.inventoryOnHand = [];
    this.inventoryCommited = [];
    this.maxInventoryNeeded = 10;
    this.maxInventoryOnHand = 10;
    this.maxInventoryCommited = 10;

    for ( let i = 0; i <= 48; i++ ) {
      this.inventoryNeededForSelectedPickSheets[i] = 0;
      this.inventoryOnHand[i] = 0;
      this.inventoryCommited[i] = 0;
      this.inventoryOnOrder[i] = 0;
      this.inventoryNet[i] = 0;
    }
    const today = new Date();
    const dateIndexes: any = {};
    for ( let i = 0; i < 45; i++) {
      const date: string = this.formatDate(new Date(today.getTime() + ( -44 + i ) * 24 * 60 * 60 * 1000 ));
      this.dates[i] = date;
      this.dateIndexes[date] = i;
    }

    this.organizationService.getItems()
      .subscribe(organizations => {
        this.organizations = organizations;
      });

    if ( this.startupService.selectedRole === 'facility') {
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe( facility => {
        this.facilityInventoryProductService.getItems().subscribe(facilityInventoryProducts => {
          // console.log(`PickSheetsGroups ${JSON.stringify(pickSheetsGroups)}`);
          this.facilityInventoryProducts = facilityInventoryProducts;
          for ( const facilityInventoryProduct of this.facilityInventoryProducts ) {
            if ( facilityInventoryProduct.product && facilityInventoryProduct.product.book && facilityInventoryProduct.product.book.tagId && facilityInventoryProduct.product.book.tagId <= 48 ) {
              this.inventoryOnHand[facilityInventoryProduct.product.book.tagId] = facilityInventoryProduct.quantityOnHand;
              this.inventoryCommited[facilityInventoryProduct.product.book.tagId] = facilityInventoryProduct.quantityCommited;
              this.inventoryOnOrder[facilityInventoryProduct.product.book.tagId] = facilityInventoryProduct.quantityOnOrder;
              this.inventoryNet[facilityInventoryProduct.product.book.tagId] = this.inventoryOnHand[facilityInventoryProduct.product.book.tagId] - this.inventoryCommited[facilityInventoryProduct.product.book.tagId] - this.inventoryOnOrder[facilityInventoryProduct.product.book.tagId];
              this.maxInventoryOnHand = Math.max(facilityInventoryProduct.quantityOnHand, this.maxInventoryOnHand);
              this.maxInventoryCommited = Math.max(facilityInventoryProduct.quantityCommited, this.maxInventoryCommited);
            }
          }
        });

        const filter = {};
        if ( this.selectedOrganization !== 0 ) {
          if ( !filter['eq'] ) {
            filter['eq'] = {};
          }
          filter['eq']['organization.ID'] = this.selectedOrganization;
        }

        // const filter = { eq: {
        //   },
        //   ge: {
        //   },
        //   ne: {
        //   }
        // };

        this.pickSheetService.getPickSheetsGroups( facility, filter ).subscribe(pickSheetsGroups => {
          // console.log(`PickSheetsGroups ${JSON.stringify(pickSheetsGroups)}`);
          this.pickSheetsGroups = pickSheetsGroups;
          for ( const pickSheetsGroup of pickSheetsGroups ) {
            const index = this.dateIndexes[pickSheetsGroup.date];
            if ( index ) {
              if ( !this.datesMatrix[index] ) {
                this.datesMatrix[index] = {};
                this.datesMatrix[index]['all'] = [];
                this.datesMatrix[index]['single'] = [];
                this.datesMatrix[index]['only'] = [];
                this.datesMatrix[index]['pure'] = [];
                this.datesMatrix[index]['pureMixed'] = [];
                this.datesMatrix[index]['mixed'] = [];
                this.datesMatrix[index]['abmixed'] = [];
                this.datesMatrix[index]['abulk'] = [];
                this.datesMatrix[index]['bbulk'] = [];
                this.datesMatrix[index]['abbulk'] = [];
                for ( let i = 0; i < 12; i++) {
                  this.datesMatrix[index]['single'][i] = new PickSheetsGroup(undefined);
                  this.datesMatrix[index]['only'][i] = new PickSheetsGroup(undefined);
                  this.datesMatrix[index]['pure'][i] = new PickSheetsGroup(undefined);
                  this.datesMatrix[index]['pureMixed'][i] = new PickSheetsGroup(undefined);
                  this.datesMatrix[index]['mixed'][i] = new PickSheetsGroup(undefined);
                  this.datesMatrix[index]['abmixed'][i] = new PickSheetsGroup(undefined);
                }
              }
              this.datesMatrix[index]['all'].push( pickSheetsGroup );
              if ( !this.dateQuantities[pickSheetsGroup.date] ) {
                this.dateQuantities[pickSheetsGroup.date] = 0;
              }
              if ( !this.dateOrders[pickSheetsGroup.date] ) {
                this.dateOrders[pickSheetsGroup.date] = 0;
              }
              this.dateQuantities[pickSheetsGroup.date] += pickSheetsGroup.books;
              this.dateOrders[pickSheetsGroup.date] += pickSheetsGroup.orders;
              switch ( pickSheetsGroup.tagType ) {
                case 'Single': {
                  this.datesMatrix[index]['single'][pickSheetsGroup.getIndex()] = pickSheetsGroup;
                  break;
                }
                case 'Only': {
                  this.datesMatrix[index]['only'][pickSheetsGroup.getIndex()] = pickSheetsGroup;
                  break;
                }
                case 'Pure': {
                  this.datesMatrix[index]['pure'][pickSheetsGroup.getIndex()] = pickSheetsGroup;
                  break;
                }
                case 'PureMixed': {
                  this.datesMatrix[index]['pureMixed'][pickSheetsGroup.getIndex()] = pickSheetsGroup;
                  break;
                }
                case 'Mixed': {
                  if ( pickSheetsGroup.type === 'Individual') {
                    if ( pickSheetsGroup.group === 'AA' || pickSheetsGroup.group === 'BB') {
                      this.datesMatrix[index]['mixed'][pickSheetsGroup.getIndex()] = pickSheetsGroup;
                    } else {
                      this.datesMatrix[index]['abmixed'][pickSheetsGroup.getIndex()] = pickSheetsGroup;
                    }
                  } else {
                    switch (pickSheetsGroup.group) {
                      case 'AA': {
                        this.datesMatrix[index]['abulk'].push( pickSheetsGroup );
                        break;
                      }
                      case 'BB': {
                        this.datesMatrix[index]['bbulk'].push( pickSheetsGroup );
                        break;
                      }
                      case 'AB': {
                        this.datesMatrix[index]['abbulk'].push( pickSheetsGroup );
                        break;
                      }
                    }
                  }
                  break;
                }
              }
            } else {
              console.log(`Index not found for ${pickSheetsGroup.getId()}`);
            }
            this.displayButtons = true;
          }
        });
      });
    }
  }
  private formatDate(date: Date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

  refresh () {
    this.printNodeOrderService.reset();
    this.getData();
  }

  printSelectedPickAndShipSheetsGroups (printer: string) {
    if (this.startupService.selectedRole === 'facility') {
      const tthiss = this;
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe(facility => {
        const printNodeOrderItemIds: number[] = [];
        for (const d of this.dates) {
          for (const x of ['single', 'only', 'pure', 'pureMixed', 'mixed', 'abmixed', 'abulk', 'bbulk', 'abbulk', 'all']) {
            if (tthiss.dateIndexes[d] !== undefined && tthiss.datesMatrix && tthiss.datesMatrix[tthiss.dateIndexes[d]] && tthiss.datesMatrix[tthiss.dateIndexes[d]][x] && tthiss.datesMatrix[this.dateIndexes[d]][x].length > 0) {
              for (const pickSheetsGroup of tthiss.datesMatrix[this.dateIndexes[d]][x]) {
                if (pickSheetsGroup.selected) {
                  pickSheetsGroup.orderIds.forEach(id => {
                    printNodeOrderItemIds.push(id);
                  });
                }
              }
            }
          }
        }
        if (printNodeOrderItemIds.length > 0) {
          tthiss.pickSheetService.createPickAndShipSheets(facility, printNodeOrderItemIds, printer, 0).subscribe(result => {
            console.log(`createdPickSheet for facility: ${facility.ID}`);
            for (const d of this.dates) {
              for (const x of ['single', 'only', 'pure', 'pureMixed', 'mixed', 'abmixed', 'abulk', 'bbulk', 'abbulk', 'all']) {
                if (tthiss.dateIndexes[d] !== undefined && tthiss.datesMatrix && tthiss.datesMatrix[tthiss.dateIndexes[d]] && tthiss.datesMatrix[tthiss.dateIndexes[d]][x] && tthiss.datesMatrix[this.dateIndexes[d]][x].length > 0) {
                  for (const pickSheetsGroup of tthiss.datesMatrix[this.dateIndexes[d]][x]) {
                    if (pickSheetsGroup.selected) {
                      tthiss.datesMatrix[tthiss.dateIndexes[d]][x] = new PickSheetsGroup(undefined);
                    }
                  }
                }
              }
            }
          });
        }
      });
    }
  }

  printSelectedPickSheetsGroups (printer: string) {
    if ( this.startupService.selectedRole === 'facility') {
      const tthiss = this;
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe( facility => {
        const printNodeOrderItemIds: number[] = [];
        for ( const d of this.dates ) {
          for ( const x of ['single', 'only', 'pure', 'pureMixed', 'mixed', 'abmixed', 'abulk', 'bbulk', 'abbulk', 'all']) {
            if ( tthiss.dateIndexes[d] !== undefined && tthiss.datesMatrix && tthiss.datesMatrix[tthiss.dateIndexes[d]] && tthiss.datesMatrix[tthiss.dateIndexes[d]][x] && tthiss.datesMatrix[this.dateIndexes[d]][x].length > 0 ) {
              for ( const pickSheetsGroup of tthiss.datesMatrix[this.dateIndexes[d]][x]) {
                if ( pickSheetsGroup.selected ) {
                  pickSheetsGroup.orderIds.forEach( id => {
                    printNodeOrderItemIds.push( id );
                  });
                }
              }
            }
          }
        }
        if ( printNodeOrderItemIds.length > 0 ) {
          tthiss.pickSheetService.createPickSheets(facility, printNodeOrderItemIds, printer).subscribe( result => {
            console.log(`createdPickSheet for facility: ${facility.ID}`);
            for ( const d of this.dates ) {
              for ( const x of ['single', 'only', 'pure', 'pureMixed', 'mixed', 'abmixed', 'abulk', 'bbulk', 'abbulk', 'all']) {
                if ( tthiss.dateIndexes[d] !== undefined && tthiss.datesMatrix && tthiss.datesMatrix[tthiss.dateIndexes[d]] && tthiss.datesMatrix[tthiss.dateIndexes[d]][x] && tthiss.datesMatrix[this.dateIndexes[d]][x].length > 0 ) {
                  for ( const pickSheetsGroup of tthiss.datesMatrix[this.dateIndexes[d]][x]) {
                    if ( pickSheetsGroup.selected ) {
                      tthiss.datesMatrix[tthiss.dateIndexes[d]][x] = new PickSheetsGroup(undefined);
                    }
                  }
                }
              }
            }
          });
        }
      });
    }
  }

  printLargePickSheets () {
    if ( this.startupService.selectedRole === 'facility') {
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe( facility => {
        this.pickSheetService.createAllPickSheets(facility, 'large', 'B').subscribe( result => {
          console.log(`createdPickSheet for facility: ${facility.ID}`);
        });
      });
    }
  }
  printSmallPickSheets () {
    if ( this.startupService.selectedRole === 'facility') {
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe( facility => {
        this.pickSheetService.createAllPickSheets(facility, 'small', 'B').subscribe( result => {
          console.log(`createdPickSheet for facility: ${facility.ID}`);
        });
      });
    }
  }

  addInventoryCounts (pickSheetsGroup: PickSheetsGroup) {
    for ( const titleKey of Object.keys(pickSheetsGroup.titleQuantities)) {
      const quantity = pickSheetsGroup.titleQuantities[titleKey];
      const parts = titleKey.split('_');
      if ( parts.length === 2 ) {
        const index = Number(parts[0]);
        const title = parts[1];
        if ( this.inventoryNeededLables[index] !== title) {
          this.inventoryNeededLables[index] = title;
        }
        if ( !this.inventoryNeededForSelectedPickSheets[index] ) {
          this.inventoryNeededForSelectedPickSheets[index] = 0;
        }
        this.inventoryNeededForSelectedPickSheets[index] += quantity;
        this.maxInventoryNeeded = Math.max(this.inventoryNeededForSelectedPickSheets[index], this.maxInventoryNeeded);
      }
    }
  }

  removeInventoryCounts (pickSheetsGroup: PickSheetsGroup) {
    for ( const titleKey of Object.keys(pickSheetsGroup.titleQuantities)) {
      const quantity = pickSheetsGroup.titleQuantities[titleKey];
      const parts = titleKey.split('_');
      if ( parts.length === 2 ) {
        const index = Number(parts[0]);
        if ( !this.inventoryNeededForSelectedPickSheets[index] ) {
          this.inventoryNeededForSelectedPickSheets[index] = 0;
        }
        this.inventoryNeededForSelectedPickSheets[index] -= quantity;
      }
    }
  }

}
