import {TableFilter} from "./TableFilter";

export class DataEnumTableFilter extends TableFilter {
  public value: string = '';
  public valuesMap: any = {};

  constructor (obj: any) {
    super(obj);
    if ( obj.value ) {
      this.value = String(obj.value);
    }
    if ( obj.valuesMap ) {
      this.valuesMap = obj.valuesMap;
    }
  }

  public match(data: any, filter: string): boolean {
    const value = this.getStringValue(data);
    const mappedValue = this.valuesMap[String(value)];
    if ( this.value === '') {
      return true;
    }
    if ( mappedValue && mappedValue === this.value ) {
      return true;
    }
    if ( this.value &&  value && this.value.toLowerCase() === value.toLowerCase() ) {
      return true;
    }
    return false;
  }
}
