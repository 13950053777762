import {PrintNodeOrder} from "./PrintNodeOrder";
import {Book} from "./Book";
import {ManufacturingSet} from "./ManufacturingSet";
import {ManufacturingTaskBucket} from "./ManufacturingTaskBucket";
import {DataObject} from "./DataObject";

export class PrintNodeOrderItem implements DataObject {
  ID: number;
  lastUpdate: Date;
  printnodeorder_ID: number;
  book_ID: number;
  orderitem_ID: number;
  quantity: number;
  initialQuantity: number;
  packingcost: number;
  shippingcost: number;
  pagecost: number;
  bookcost: number;
  printNodeManufacturingCost: number;
  externalProductInfo_ID: number;
  weight: number;
  manufacturingset_id: number;
  manufacturingProcess_id: number;
  status: string;
  contestStatus: string;
  contestNote: string;
  printNodeOrder: PrintNodeOrder;
  book: Book;
  manufacturingtaskbucketperiod_id: number;
  manufacturingSetItemId: number;
  manufacturingSet: ManufacturingSet;
  manufacturingTaskBucket: ManufacturingTaskBucket;
  supportNote: string;
  supportPerson: string;
  type: string;
  shippingStatus: string;

  constructor (obj, printNodeOrder: PrintNodeOrder) {
    this.lastUpdate = new Date(0);
    this.quantity = 0;
    this.packingcost = 0;
    this.shippingcost = 0;
    this.pagecost = 0;
    this.bookcost = 0;
    this.printNodeManufacturingCost = 0;
    this.externalProductInfo_ID = 0;
    this.weight = 0;
    this.manufacturingset_id = 0;
    this.manufacturingProcess_id = 0;
    this.status = 'INITIAL';
    this.shippingStatus = 'NONE';
    this.contestStatus = 'UNCONTESTED';
    this.contestNote = '';
    this.manufacturingSetItemId = 0;
    this.printNodeOrder = printNodeOrder;
    this.book = undefined;
    this.manufacturingSet = undefined;
    this.book = undefined;
    this.manufacturingSet = undefined;
    this.manufacturingTaskBucket = undefined;
    this.supportNote = '';
    this.supportPerson = '';

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
    // if (obj) {
    //   for (const prop of Object.keys(obj)) {
    //     this[prop] = obj[prop];
    //   }
    //   if (obj.book) {
    //     this.book = new Book(obj.book);
    //   }
    //   // if (obj.printNodeOrder) {
    //   //   this.printNodeOrder = new PrintNodeOrder(obj.printNodeOrder);
    //   // } else {
    //   //   if (printNodeOrder) {
    //   //     this.printNodeOrder = printNodeOrder;
    //   //   } else {
    //   //     console.log('PrintNodeOrderItem Without a PrintNodeOrder');
    //   //   }
    //   // }
    //   // if (obj.manufacturingSet) {
    //   //   this.manufacturingSet = new ManufacturingSet(obj.manufacturingSet);
    //   // }
    //   // if (obj.manufacturingTaskBucket) {
    //   //   this.manufacturingTaskBucket = new ManufacturingTaskBucket(obj.manufacturingTaskBucket);
    //   // }
    // }
  }

  getNormalizedPages (): number {
    if (this.book) {
      return this.quantity * this.book.getNormalizedPages();
    }
    return 0;
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}
