<div style="width: 100%" class="wrapper">
  <div style="padding-top: 10px; margin-left: auto; margin-right: auto;">
<!--    <mat-grid-list cols="1" >-->
<!--      <mat-grid-tile i18n-class="@@dir">-->
<!--        <mat-card style="background: rgba(59, 56, 87, 0.94); height: 100%; width: 100%;">-->
<!--          <mat-card-content>-->
<!--            <div style="padding: 30px;">-->
<!--              <h3 style="padding-top: 150px;" i18n="@@WELCOME BACK!">WELCOME BACK!</h3>-->
<!--              <p i18n="@@....">..................................................................................</p>-->
<!--              <h2 style="padding-top: 40px" i18n="@@Main Organization Name">Main Organization Name</h2>-->
<!--              <p i18n="@@Any questions along the way please click on (Help) and submit question or input and we will be happy to get back soon.">Any questions along the way please click on (Help) and submit question or input and we will be happy to get back soon.</p>-->
<!--            </div>-->
<!--          </mat-card-content>-->
<!--        </mat-card>-->
<!--      </mat-grid-tile>-->
<!--      <mat-grid-tile i18n-class="@@dir">-->
        <mat-card appearance="outlined" class="formCard">
          <h1 style="padding-top: 20px" i18n="@@Login">Login</h1>
          <img src="/logo.png" class="intro-logo">
          <mat-card-content>
            <form style="padding-top: 45px; padding-bottom: 45px;">
              <mat-form-field class="full-width">
                <input matInput i18n-placeholder="@@Email" placeholder="Email" id="signupEmail" required type="email" maxlength="100" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
<!--                <mat-error *ngIf="errorMessage!=null">-->
<!--                  {{ errorMessage }}-->
<!--                </mat-error>-->
              </mat-form-field>
              <mat-form-field class="full-width">
                <input matInput i18n-placeholder="@@Password" placeholder="Password" id="signupPassword" required type="password" maxlength="25" length="40" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
              </mat-form-field>
              <span style="font-size: 0.8em; text-align: center; display: block" i18n="@@Password must contain an UPPER CASE and lower case letter and a number.">Password must contain an UPPER CASE and lower case letter and a number.</span>
              <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
            </form>
          </mat-card-content>
          <mat-card-actions class="primaryAction">
            <button mat-raised-button (click)="onLogin()" id="signupSubmit" type="submit" i18n="@@Login">Login</button>
          </mat-card-actions>
          <mat-card-actions class="secondaryAction">
            <a *ngIf="environment.allowUsersToSignThemSelvesUp" mat-button [routerLink]="['/home/register']"><ng-container i18n="@@Register">Register</ng-container></a>
            <a *ngIf="environment.allowUsersToSignThemSelvesUp" mat-button [routerLink]="['/home/forgotPassword']"><ng-container i18n="@@Forgot Password?">Forgot Password?</ng-container></a>
            <a *ngIf="environment.allowUsersToSignThemSelvesUp" mat-button [routerLink]="['/home/resendCode']"><ng-container i18n="@@Resend Confirmation">Resend Confirmation Code</ng-container></a>
            <a mat-button [routerLink]="['/home/language']"><ng-container i18n="@@Language">Language</ng-container></a>
          </mat-card-actions>
        </mat-card>
<!--      </mat-grid-tile>-->
<!--    </mat-grid-list>-->
  </div>
</div>
