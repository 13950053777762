import {Component, Input, OnInit, NgZone, AfterViewInit, OnDestroy} from '@angular/core';
import {FacilityInventoryProduct} from "../../../../model/FacilityInventoryProduct";
import {ChartDataSets, ChartOptions} from "chart.js";
// import {Color, Label} from "ng2-charts";
import {FacilityInventoryProductService} from "../../../service-data/facility-inventory-product.service";
import {Order} from "../../../../model/Order";
import {OrderDeliverTo} from "../../../../model/OrderDeliverTo";
import {OrderItem} from "../../../../model/OrderItem";
import {OrderDeliverToService} from "../../../service-data/order-deliver-to.service";
import {OrderService} from "../../../service-data/order.service";
import {FacilityService} from "../../../service-data/facility.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {Product} from "../../../../model/Product";
import {PlaceReplenishmentOrderComponent} from "./place-replenishment-order/place-replenishment-order-component";
import { EditFacilityInventoryProductSettingsComponent } from './edit-facility-inventory-product-settings/edit-facility-inventory-product-settings-component';
import {AdjustFacilityInventoryProductInventoryComponent} from "./adjust-facility-inventory-product-inventory/adjust-facility-inventory-product-inventory-component";
import {EditFacilityInventoryProductNoteComponent} from "./edit-facility-inventory-product-note/edit-facility-inventory-product-note-component";
import {TransferFacilityInventoryProductInventoryComponent} from "./transfer-facility-inventory-product-inventory/transfer-facility-inventory-product-inventory-component";
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../../service-data/print-node-order.service";
import {ProductService} from "../../../service-data/product.service";
import {InventoryEvent} from "./InventoryEvent";
import {PrintNodeOrderItem} from "../../../../model/PrintNodeOrderItem";
import {ReceiveReplenishmentPrintNodeOrderComponent} from "./receive-replenishment-print-node-order/receive-replenishment-print-node-order-component";
import {EditReplenishmentPrintNodeOrderComponent} from "./edit-replenishment-print-node-order/edit-replenishment-print-node-order-component";
import {UnreceiveReplenishmentPrintNodeOrderComponent} from "./unreceive-replenishment-print-node-order/unreceive-replenishment-print-node-order-component";
import {StartupService} from "../../../service-ui/startup.service";
import {FacilityInventoryProductEvent} from "../../../../model/FacilityInventoryProductEvent";
import {EditFacilityInventoryProductEventComponent} from "./edit-facility-inventory-product-event/edit-facility-inventory-product-event.component";
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-facility-inventory-product',
  templateUrl: './facility-inventory-product.component.html',
  styleUrls: ['./facility-inventory-product.component.css']
})
export class FacilityInventoryProductComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() facilityInventoryProduct: FacilityInventoryProduct;
  @Input() showCharts: boolean;
  @Input() edit: boolean;
  @Input() products: Product[];
  @Input() product: Product;

  inventoryEvents: InventoryEvent[] = [];
  // private chart: am4charts.XYChart;
  editableFacilityInventoryProduct: FacilityInventoryProduct;
  printNodeOrders: PrintNodeOrder[];
  editFacilityInventoryProduct: boolean = false;
  replenish: boolean = false;
  replenishmentOrderProductId = 0;
  showDetails: boolean = false;
  hideFulfillment: boolean = true;

  Math = Math;

  // public lineChartData: ChartDataSets[] = [
  //   { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  // ];
  // public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  // public lineChartDates: Date[] = [];
  // public lineChartOptions: ChartOptions = {
  //   responsive: true,
  //   scales : {
  //     yAxes : [{
  //       ticks : {
  //         beginAtZero : true
  //       }
  //     }]
  //   }
  // };
  // public lineChartColors: Color[] = [
  //   {
  //     borderColor: 'black',
  //     backgroundColor: 'rgba(255,0,0,0.3)',
  //   },
  // ];
  // public lineChartLegend = true;
  // public lineChartType = 'line';
  // public lineChartPlugins = [];

  constructor(private zone: NgZone, private productService: ProductService, public startupService: StartupService, private printNodeOrderService: PrintNodeOrderService, private facilityInventoryProductService: FacilityInventoryProductService, private orderDeliverToService: OrderDeliverToService, private orderService: OrderService, private facilityService: FacilityService, public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.replenishmentOrderProductId = this.facilityInventoryProduct.product_ID;
    const timeline = this.facilityInventoryProduct.getTimelineData();
    const startOfTime = timeline.getStartOfTime();
    const startIndex = 365 + 184;
    const endIndex = startIndex + ( 31 * 3 );
    const physical: number[] = [];
    const net: number[] = [];
    const replenishmentOrdered: number[] = [];
    const replenishmentActual: number[] = timeline.replenishmentActual;
    const fulfillmentOrdered: number[] = [];
    const fulfillmentActual: number[] = timeline.fulfillmentActual;
    for ( let i = startIndex; i < timeline.replenishmentActual.length && i < endIndex; i++) {
      physical.push( timeline.replenishmentActual[i] - timeline.fulfillmentActual[i] );
      net.push( timeline.replenishmentActual[i] - timeline.fulfillmentActual[i] - timeline.fulfillmentOrdered[i] );
      // net.push( timeline.replenishmentActual[i] - timeline.fulfillmentActual[i] + timeline.replenishmentOrdered[i] - timeline.fulfillmentOrdered[i] );
      // replenishmentOrdered.push( timeline.replenishmentOrdered[i] + timeline.replenishmentActual[i] );
      // fulfillmentOrdered.push( -timeline.fulfillmentOrdered[i] + timeline.fulfillmentActual[i] );
      // replenishmentActual.push( timeline.replenishmentActual[i] );
      // fulfillmentActual.push( timeline.fulfillmentActual[i] );
    }

    // this.lineChartData = [
    //   { data: physical, label: 'Physical', lineTension: 0, steppedLine: 'after' },
    //   { data: net, label: 'Net', lineTension: 0, steppedLine: 'after' },
    //   // { data: replenishmentOrdered, label: 'Replenishment Ordered', lineTension: 0, steppedLine: 'after' },
    //   // { data: replenishmentActual, label: 'Replenishment Actual', lineTension: 0, steppedLine: 'after' },
    //   // { data: fulfillmentOrdered, label: 'Fulfillment Ordered', lineTension: 0, steppedLine: 'after' },
    //   // { data: fulfillmentActual, label: 'Fulfillment Actual', lineTension: 0, steppedLine: 'after' },
    // ];
    //
    //
    // this.lineChartLabels = [];
    // this.lineChartDates = [];

    const date = timeline.getStartOfTime();
    for ( let i = startIndex; i < timeline.replenishmentActual.length && i < endIndex; i++) {
      let sDate = '';
      if ( date.getDate() === 1 || date.getDate() === 2 || date.getDate() === 3 ) {
        switch ( date.getMonth() ) {
          case 0: {
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
          case 1: {
            sDate = 'Febuary';
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
          case 2: {
            sDate = 'March';
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
          case 3: {
            sDate = 'April';
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
          case 4: {
            sDate = 'May';
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
          case 5: {
            sDate = 'June';
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
          case 6: {
            sDate = 'July';
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
          case 7: {
            sDate = 'August';
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
          case 8: {
            sDate = 'Sept';
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
          case 9: {
            sDate = 'Oct';
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
          case 10: {
            sDate = 'Nov';
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
          case 11: {
            sDate = 'Dec';
            sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            break;
          }
        }
      } else {
        sDate = String(date.getDate());
        sDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
      }
      // this.lineChartLabels.push(sDate);
      // this.lineChartDates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    this.zone.runOutsideAngular(() => {
    //   const chartElement = document.getElementById("chartdiv");
    //   if ( chartElement ) {
    //     console.log("Got ChartElement");
    //   }
    //   const chart = am4core.create("chartdiv", am4charts.XYChart);
    //
    //   chart.paddingRight = 20;
    //
    //   const data = [];
    //   let visits = 10;
    //   data[0] = [];
    //   data[1] = [];
    //   for (let i = 1; i < 366; i++) {
    //     visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
    //     data[0].push({ date: new Date(2018, 0, i), name: "name" + i, value: visits });
    //     data[1].push({ date: new Date(2018, 0, i), name: "name" + i, value: visits });
    //   }
    //
    //   chart.data = data;
    //
    //   const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    //   dateAxis.renderer.grid.template.location = 0;
    //
    //   const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //   valueAxis.tooltip.disabled = true;
    //   valueAxis.renderer.minWidth = 35;
    //
    //   chart.cursor = new am4charts.XYCursor();
    //   const scrollbarX = new am4charts.XYChartScrollbar();
    //
    //   let series = chart.series.push(new am4charts.LineSeries());
    //   series.dataFields.dateX = "date";
    //   series.dataFields.valueY = "value";
    //
    //   series.tooltipText = "{valueY.value}";
    //   scrollbarX.series.push(series);
    //
    //
    //   series = chart.series.push(new am4charts.LineSeries());
    //   series.dataFields.dateX = "date";
    //   series.dataFields.valueY = "value";
    //
    //   series.tooltipText = "{valueY.value}";
    //   scrollbarX.series.push(series);
    //
    //   chart.scrollbarX = scrollbarX;
    //
    //   this.chart = chart;
    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      // if (this.chart) {
      //   this.chart.dispose();
      // }
    });
  }

  setCaseQuantity(facilityInventoryProduct: FacilityInventoryProduct, value: string) {
    const newFacilityInventoryProduct = new FacilityInventoryProduct(undefined);
    newFacilityInventoryProduct.ID = facilityInventoryProduct.ID;
    newFacilityInventoryProduct.caseQuantity = Number(value);
    // newFacilityInventoryProduct.printnode_ID = facilityInventoryProduct.printnode_ID;
    // newFacilityInventoryProduct.product_ID = facilityInventoryProduct.product_ID;
    // newFacilityInventoryProduct.inventoryManagementType = facilityInventoryProduct.inventoryManagementType;
    // newFacilityInventoryProduct.printNode = facilityInventoryProduct.printNode;
    this.facilityInventoryProductService.updateItem(newFacilityInventoryProduct).subscribe( x => {
      console.log(`Saved FacilityInventoryProduct ${JSON.stringify(x)}`);
    });
  }

  createReplenishmentOrder(facilityInventoryProduct: FacilityInventoryProduct, quantityString: string, tag1: string, tag2: string) {
    this.facilityService.getItem(facilityInventoryProduct.printnode_ID).subscribe(facility => {
      const quantity = Number(quantityString);
      const orderx = new Order(undefined);
      if (facilityInventoryProduct.product) {
        orderx.org_ID = facilityInventoryProduct.product.org_ID;
        const customerOrderIdDate = new Date();
        orderx.customerorderid = 'Replenishment-' + (customerOrderIdDate.getMonth() + 1) + '.' + (customerOrderIdDate.getDate() + 1) + '.' + customerOrderIdDate.getFullYear() + '.' + Math.floor(Math.random() * 1000); // this.orderStrategyFormGroup.controls.firstPartOfOrderId.value; // this.firstPartOfOrderId; // Add the current date and/or the org_id and or the umbrella to the id?
        orderx.inventoryType = 'REPLENISH_INVENTORY';
        orderx.replenishmentFacility_ID = facility.ID;
        this.orderService.addOrder(orderx).subscribe(order => {
          if (order && order.ID ) {
            const orderDeliverTo = new OrderDeliverTo(undefined);
            orderDeliverTo.inventoryType = order.inventoryType;
            orderDeliverTo.order_ID = order.ID;

            orderDeliverTo.title = '';
            orderDeliverTo.firstname = facilityInventoryProduct.title;
            orderDeliverTo.lastname = tag2;
            orderDeliverTo.company = tag1;
            orderDeliverTo.address1 = facility.toAddress1;
            orderDeliverTo.address2 = facility.toAddress2;
            orderDeliverTo.address3 = facilityInventoryProduct.location + ' ' + facilityInventoryProduct.shelf;
            orderDeliverTo.city = facility.toCity;
            orderDeliverTo.state = facility.toState;
            orderDeliverTo.postalcode = facility.toPostalCode;
            orderDeliverTo.country = facility.toCountry;
            // orderDeliverTo.addressValidationStatus = ;
            orderDeliverTo.carrier = 'INPLANT';
            orderDeliverTo.servicelevel = 'PICKUP';
            orderDeliverTo.replenishmentFacility_ID = facility.ID;
            const orderItem = new OrderItem( undefined );
            orderItem.product_ID = this.replenishmentOrderProductId; //  facilityInventoryProduct.product_ID;
            orderItem.quantity = quantity;
            orderDeliverTo.orderItems.push(orderItem);
            this.orderDeliverToService.addOrderDeliverTo(orderDeliverTo).subscribe(createdOrderDeliverTo => {
              if (createdOrderDeliverTo) {
                console.log(`Saved an OrderDeliverTo ${JSON.stringify(order)}`);
              } else {
                console.log(`Trouble saving an OrderDeliverTo ${JSON.stringify(order)}`);
              }
            });
          }
        });
      }
    });
  }

  reorderReorderQuantity() {

  }
  editObject() {
    this.editableFacilityInventoryProduct = new FacilityInventoryProduct(this.facilityInventoryProduct);
    this.editFacilityInventoryProduct = true;
  }

  cancel() {
    this.editFacilityInventoryProduct = false;
    this.editableFacilityInventoryProduct = undefined;
  }

  save() {
    this.facilityInventoryProductService.updateItem(this.editableFacilityInventoryProduct).subscribe( changedOrganization => {
      console.log("Organization was changed.");
    });
    this.editFacilityInventoryProduct = false;
    this.facilityInventoryProductService.reset();
  }

  public placeReplenishmentOrder (): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { facilityInventoryProduct: this.facilityInventoryProduct, products: this.products };
    const dialogRef = this.dialog.open(PlaceReplenishmentOrderComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.refreshAll();
    });
  }

  public editFacilityInventoryProductSettings (): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { facilityInventoryProduct: this.facilityInventoryProduct, products: this.products };
    const dialogRef = this.dialog.open(EditFacilityInventoryProductSettingsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.refreshAll();
    });
  }

  public adjustFacilityInventoryProductInventory (actionType: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { facilityInventoryProduct: this.facilityInventoryProduct, actionType: actionType };
    const dialogRef = this.dialog.open(AdjustFacilityInventoryProductInventoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.refreshAll();
    });
  }

  public transferFacilityInventoryProductInventory (): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { facilityInventoryProduct: this.facilityInventoryProduct };
    const dialogRef = this.dialog.open(TransferFacilityInventoryProductInventoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.refreshAll();
    });
  }

  public editFacilityInventoryProductNote (): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { facilityInventoryProduct: this.facilityInventoryProduct };
    const dialogRef = this.dialog.open(EditFacilityInventoryProductNoteComponent, dialogConfig);
  }

  public receiveReplenishmentPrintNodeOrder (printNodeOrder: PrintNodeOrder, printNodeOrderItem: PrintNodeOrderItem): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { printNodeOrder: printNodeOrder, printNodeOrderItem: printNodeOrderItem };
    const dialogRef = this.dialog.open(ReceiveReplenishmentPrintNodeOrderComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.refreshAll();
    });
  }

  public unReceiveReplenishmentPrintNodeOrder (printNodeOrder: PrintNodeOrder, printNodeOrderItem: PrintNodeOrderItem): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { printNodeOrder: printNodeOrder, printNodeOrderItem: printNodeOrderItem };
    const dialogRef = this.dialog.open(UnreceiveReplenishmentPrintNodeOrderComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.refreshAll();
    });
  }

  public editReplenishmentPrintNodeOrder (printNodeOrder: PrintNodeOrder, printNodeOrderItem: PrintNodeOrderItem): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { printNodeOrder: printNodeOrder, printNodeOrderItem: printNodeOrderItem };
    const dialogRef = this.dialog.open(EditReplenishmentPrintNodeOrderComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.refreshAll();
    });
  }

  public editFacilityInventoryProductEvent (facilityInventoryProductEvent: FacilityInventoryProductEvent): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { facilityInventoryProductEvent: facilityInventoryProductEvent };
    const dialogRef = this.dialog.open(EditFacilityInventoryProductEventComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.refreshAll();
    });
  }

  public refreshAll() {
    // this.facilityInventoryProductService.getItem(this.facilityInventoryProduct.ID)
    // .subscribe(facilityInventoryProduct => {
    //   if ( facilityInventoryProduct ) {
    //     this.facilityInventoryProduct = facilityInventoryProduct;
    //   }
    //   this.refreshDetails();
    // });
  }
  public refreshDetails() {
    this.inventoryEvents = undefined;
    const filter = {};
    if ( !filter['eq'] ) {
      filter['eq'] = {};
    }
    if ( !filter['in'] ) {
      filter['in'] = {};
    }

    let product = this.facilityInventoryProduct.product;
    if ( !product ) {
      product = this.product;
    }

    // filter['eq']['printNodeOrderItems.book_ID'] = product.book_ID;
    // // filter['eq']['printNodeOrder.printnode_ID'] = this.facilityInventoryProduct.printnode_ID;
    // filter['eq'][' ( printNodeOrder.inventoryType = "FULFILL_FROM_INVENTORY" AND printNodeOrder.printnode_ID = ' + this.facilityInventoryProduct.printnode_ID + ' ) OR ( printNodeOrder.inventoryType = "REPLENISH_INVENTORY" AND printNodeOrder.replenishmentFacility_ID = ' + this.facilityInventoryProduct.printnode_ID + ' ) '] = 1;
    // // const role = this.printNodeOrderService.getRole(JSON.stringify(filter));
    // this.printNodeOrderService.getFilteredItems(filter, true)
    //   .subscribe(printNodeOrders => {
    //     this.printNodeOrders = printNodeOrders;
    //     this.processAndSortInventoryEvents();
    // });
    this.printNodeOrders = [];
    for (const printNodeOrder of this.printNodeOrderService.objects) {
      for ( const printNodeOrderItem of printNodeOrder.printNodeOrderItems ) {
        if ( printNodeOrderItem.book_ID === product.book_ID && ( ( printNodeOrder.inventoryType === "FULFILL_FROM_INVENTORY" && printNodeOrder.printnode_ID === this.facilityInventoryProduct.printnode_ID ) || ( printNodeOrder.inventoryType === "REPLENISH_INVENTORY" && printNodeOrder.replenishmentFacility_ID === this.facilityInventoryProduct.printnode_ID ) )) {
          this.printNodeOrders.push(printNodeOrder);
        }
      }
    }
    this.processAndSortInventoryEvents();
  }

  processAndSortInventoryEvents() {
    this.inventoryEvents = [];
    for ( const facilityInventoryProductEvent of this.facilityInventoryProduct.facilityInventoryProductEvents ) {
      this.inventoryEvents.push(new InventoryEvent(facilityInventoryProductEvent.ID, facilityInventoryProductEvent.timestamp, facilityInventoryProductEvent.timestamp, facilityInventoryProductEvent.source, facilityInventoryProductEvent.type, facilityInventoryProductEvent.status, facilityInventoryProductEvent.quantity, 0, undefined, undefined, facilityInventoryProductEvent));
    }

    // const timeline = this.facilityInventoryProduct.getTimelineData();
    // const startOfTime = timeline.getStartOfTime();
    //
    // const startIndex = 365;
    // const endIndex = 365 + ( 31 * 3 );
    //
    // const date = timeline.getStartOfTime();
    // for ( let i = startIndex; i < timeline.fulfillmentActual.length && i < endIndex; i++) {
    //   date.setDate(date.getDate() + 1);
    //   if ( i > 1 && timeline.fulfillmentActual[i] - timeline.fulfillmentActual[i - 1] ) {
    //     this.inventoryEvents.push(new InventoryEvent(0, date, 'FULFILLMENT ORDERS', 'ACTUAL', 'ACTUAL', timeline.fulfillmentActual[i] - timeline.fulfillmentActual[i - 1], 0, undefined, undefined));
    //   }
    // }

    // this.inventoryEvents = this.inventoryEvents.sort((a, b) => b.timestamp.getMilliseconds() - a.timestamp.getMilliseconds());
    const today = new Date();
    for (const printNodeOrder of this.printNodeOrders) {
      for (const printNodeOrderItem of printNodeOrder.printNodeOrderItems) {
        let timestamp = printNodeOrder.shippedTimestamp;
        if ( printNodeOrder.shippingStatus === 'NONE' ) {
          if ( printNodeOrder.dueDate.valueOf() < today.valueOf() ) {
            timestamp = today;
          } else {
            timestamp = printNodeOrder.dueDate;
          }
          timestamp = printNodeOrder.shippedTimestamp;
        }

        this.inventoryEvents.push(new InventoryEvent(
          printNodeOrder.ID,
          printNodeOrder.created,
          timestamp,
          printNodeOrder.inventoryType === 'FULFILL_FROM_INVENTORY' ? 'FULFILLMENT*' : 'REPLENISHMENT*',
          'ACTUAL',
          printNodeOrder.shippingStatus === 'NONE' ? 'ON ORDER' : printNodeOrder.inventoryType === 'FULFILL_FROM_INVENTORY' ? 'SHIPPED' : 'RECEIVED',
          printNodeOrder.inventoryType === 'FULFILL_FROM_INVENTORY' ? -printNodeOrderItem.quantity : printNodeOrderItem.quantity,
          0,
          printNodeOrder,
          printNodeOrderItem,
          undefined));
      }
    }
    // this.inventoryEvents = this.inventoryEvents.sort((a, b) => ( b.actualOrExpectedTimestamp ? b.actualOrExpectedTimestamp.getMilliseconds() : 0 ) - ( a.actualOrExpectedTimestamp ? a.actualOrExpectedTimestamp.getMilliseconds() : 0 ));
    this.inventoryEvents = this.inventoryEvents.sort((a: InventoryEvent, b: InventoryEvent) => {
      const at = a.actualOrExpectedTimestamp;
      const bt = b.actualOrExpectedTimestamp;
      if (at && bt) {
        const aa = new Date(at);
        const bb = new Date(bt);
        try {
          const atm = aa.valueOf();
          const btm = bb.valueOf();
          if ( atm === btm ) {
            const ac = a.createdTimestamp;
            const bc = b.createdTimestamp;
            if (ac && bc) {
              const aac = new Date(ac);
              const bbc = new Date(bc);
              try {
                const acm = aac.valueOf();
                const bcm = bbc.valueOf();
                return acm - bcm;
              } catch (e) {
                return 0;
              }
            } else {
              if ( ac ) {
                return -1;
              } else {
                return 1;
              }
            }
          }
          return atm - btm;
        } catch (e) {
          console.log(e.toLocaleString());
          return 0;
        }
      } else {
        if ( at ) {
          return -1;
        } else if ( bt ) {
          return 1;
        } else {
          const ac = a.createdTimestamp;
          const bc = b.createdTimestamp;
          if (ac && bc) {
            const aac = new Date(ac);
            const bbc = new Date(bc);
            try {
              const acm = aac.valueOf();
              const bcm = bbc.valueOf();
              return acm - bcm;
            } catch (e) {
              return 0;
            }
          } else {
            if ( ac ) {
              return -1;
            } else {
              return 1;
            }
          }

        }
      }

    });
    for ( let i = 1; i < this.inventoryEvents.length; i++) {
      this.inventoryEvents[i].balance = this.inventoryEvents[i - 1].balance + this.inventoryEvents[i].quantity;
    }
    console.log('After Sort InventoryEvents');
  }

  public showFaciliityInventoryProductDetails() {
    this.refreshDetails();
  }
}
