import {Component, Inject, Input, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import {PrintNodeOrderItem} from "../../../model/PrintNodeOrderItem";
import {PrintNodeOrderItemService} from "../../service-data/print-node-order-item.service";

@Component({
  selector: 'app-split-print-node-order',
  templateUrl: './split-print-node-order.component.html',
  styleUrls: ['./split-print-node-order.component.css']
})
export class SplitPrintNodeOrderComponent implements OnInit {
  public printNodeOrders: PrintNodeOrder[] = [];
  public printNodeOrderItemToPrintNodeOrderQuantityMap: number[][] = [];
  public printNodeOrderItemToPrintNodeOrderMap: PrintNodeOrderItem[][] = [];
  public printNodeOrderItems: PrintNodeOrderItem[] = [];
  public printNodeOrderItemTotals: number[] = [];
  public originalPrintNodeOrderItemTotals: number[] = [];
  public initialProcessing: boolean;
  public breakpoint: number;

  public form: FormGroup;
  wasFormChanged = false;
  showCompressed: boolean = false;
  canSave: boolean = false;

  public mobileQueryMatches: boolean;
  public addedPackages = [];
  public deletedPackages = [];
  public carrierServiceLevelChanges = [];
  public packageTypeChanges = [];
  public orderItemToPackageChanges = []; // zero implies deletion
  public orderItemToPackageAdditions = [];


  constructor(private printNodeOrderService: PrintNodeOrderService, private printNodeOrderItemService: PrintNodeOrderItemService, private formBuilder: FormBuilder, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<SplitPrintNodeOrderComponent>) {
    this.mobileQueryMatches = data.mobileQueryMatches;
    this.printNodeOrderItems = [];
    if ( data.printNodeOrder ) {
      this.printNodeOrders.push(data.printNodeOrder);
      // Get other PrintNodeOrders with the same OrderDeliverTo
      for ( const printNodeOrder of printNodeOrderService.objects ) {
        if ( printNodeOrder.orderdeliverto_ID === data.printNodeOrder.orderdeliverto_ID && printNodeOrder.ID !== data.printNodeOrder.ID ) {
          this.printNodeOrders.push(printNodeOrder);
        }
      }

      for ( let i = 0; i < this.printNodeOrders.length; i++ ) {
        const printNodeOrder = this.printNodeOrders[i];
        for ( const printNodeOrderItem of printNodeOrder.printNodeOrderItems ) {
          this.addPrintNodeOrderItem(i, printNodeOrderItem);
        }
      }
      this.addPrintNodeOrder();
    }
  }

  public ngOnInit(): void {
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  public addPrintNodeOrderItem(indexOfPrintNodeOrder: number, printNodeOrderItem: PrintNodeOrderItem ) {
    let existingIndex = -1;
    for (let i = 0; i < this.printNodeOrderItems.length; i++) {
      if ( this.printNodeOrderItems[i].book_ID === printNodeOrderItem.book_ID && this.printNodeOrderItems[i].type === printNodeOrderItem.type ) { // New one
        existingIndex = i;
      }
    }
    if ( existingIndex === -1 ) {
      this.printNodeOrderItems.push(printNodeOrderItem);

      // Initilize the data for the new PrintNodeOrderItem
      this.printNodeOrderItemToPrintNodeOrderQuantityMap[this.printNodeOrderItems.length - 1] = [];
      this.printNodeOrderItemToPrintNodeOrderMap[this.printNodeOrderItems.length - 1] = [];
      for ( let i = 0; i < this.printNodeOrders.length; i++) {
        this.printNodeOrderItemToPrintNodeOrderQuantityMap[this.printNodeOrderItems.length - 1].push(0);
        this.printNodeOrderItemToPrintNodeOrderMap[this.printNodeOrderItems.length - 1].push(undefined);
      }
      this.printNodeOrderItemToPrintNodeOrderQuantityMap[this.printNodeOrderItems.length - 1][indexOfPrintNodeOrder] = printNodeOrderItem.quantity;
      this.printNodeOrderItemToPrintNodeOrderMap[this.printNodeOrderItems.length - 1][indexOfPrintNodeOrder] = printNodeOrderItem;
      this.printNodeOrderItemTotals[this.printNodeOrderItems.length - 1] = printNodeOrderItem.quantity;
      this.originalPrintNodeOrderItemTotals[this.printNodeOrderItems.length - 1] = printNodeOrderItem.quantity;
    } else {
      this.printNodeOrderItemToPrintNodeOrderQuantityMap[existingIndex][indexOfPrintNodeOrder] = printNodeOrderItem.quantity;
      this.printNodeOrderItemToPrintNodeOrderMap[existingIndex][indexOfPrintNodeOrder] = printNodeOrderItem;
      this.printNodeOrderItemTotals[existingIndex] += printNodeOrderItem.quantity;
      this.originalPrintNodeOrderItemTotals[existingIndex] += printNodeOrderItem.quantity;
    }
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  setCanSave() {
    for ( let j = 0; j < this.printNodeOrderItems.length; j++ ) {
      if ( this.printNodeOrderItemTotals[j] !== this.originalPrintNodeOrderItemTotals[j] ) {
        this.canSave = false;
        return;
      }
    }
    this.canSave = true;
  }

  computePrintNodeOrderItemTotals(index: number) {
    this.printNodeOrderItemTotals[index] = 0;
    for ( let j = 0; j < this.printNodeOrders.length; j++ ) {
      this.printNodeOrderItemTotals[index] += this.printNodeOrderItemToPrintNodeOrderQuantityMap[index][j];
    }
    this.setCanSave();
  }

  saveChanges() {
    const splitPrintNodeOrder = { changedCurrentPrintNodeOrders: [], newPrintNodeOrders: [ ] };
    for ( let j = 0; j < this.printNodeOrders.length; j++ ) {
      const printNodeOrder = this.printNodeOrders[j];
      if ( printNodeOrder.ID !== 0 ) {
        let messagePrintNodeOrder;
        for (let i = 0; i < this.printNodeOrderItems.length; i++) {
          if (this.printNodeOrderItemToPrintNodeOrderMap[i][j] ) {
            if ( this.printNodeOrderItemToPrintNodeOrderQuantityMap[i][j] !== this.printNodeOrderItemToPrintNodeOrderMap[i][j].quantity ) {
              if ( !messagePrintNodeOrder ) {
                messagePrintNodeOrder = { ID: printNodeOrder.ID, changedPrintNodeOrderItemQuantities: [], addedPrintNodeOrderItems: [] };
                splitPrintNodeOrder.changedCurrentPrintNodeOrders.push( messagePrintNodeOrder );
              }
              messagePrintNodeOrder.changedPrintNodeOrderItemQuantities.push({
                ID: this.printNodeOrderItemToPrintNodeOrderMap[i][j].ID,
                quantity: this.printNodeOrderItemToPrintNodeOrderQuantityMap[i][j]
              });
            }
          } else {
            messagePrintNodeOrder.addedPrintNodeOrderItems.push({
              ID: this.printNodeOrderItems[i].ID,
              quantity: this.printNodeOrderItemToPrintNodeOrderQuantityMap[i][j]
            });
          }
        }
      } else {
        let messagePrintNodeOrder;
        for (let i = 0; i < this.printNodeOrderItems.length; i++) {
          if (this.printNodeOrderItemToPrintNodeOrderQuantityMap[i][j] !== 0) {
            if ( !messagePrintNodeOrder ) {
              messagePrintNodeOrder = { printNodeOrderItemQuantities: []};
              splitPrintNodeOrder.newPrintNodeOrders.push( messagePrintNodeOrder );
            }
            messagePrintNodeOrder.printNodeOrderItemQuantities.push({
              ID: this.printNodeOrderItems[i].ID,
              quantity: this.printNodeOrderItemToPrintNodeOrderQuantityMap[i][j]
            });
          }
        }
      }
    }
    if ( splitPrintNodeOrder.changedCurrentPrintNodeOrders.length > 0 || splitPrintNodeOrder.newPrintNodeOrders.length > 0 ) {
      this.printNodeOrderService.split(splitPrintNodeOrder).subscribe( response => {
        console.log(`Split PrintNodeOrder ${splitPrintNodeOrder}`);
      });
    }
    this.markAsDirty(this.form);
    this.dialogRef.close();
  }

  addPrintNodeOrder() {
    this.printNodeOrders.push(new PrintNodeOrder(undefined));
    for ( let i = 0; i < this.printNodeOrderItems.length; i++) {
    }
    for ( let i = 0; i < this.printNodeOrderItems.length; i++ ) {
      this.printNodeOrderItemToPrintNodeOrderQuantityMap[i].push(0);
      this.printNodeOrderItemToPrintNodeOrderMap[i].push(undefined);
    }
  }

  changeQuantity(i: number, j: number, quantity: number) {
    this.printNodeOrderItemToPrintNodeOrderQuantityMap[i][j] += quantity;
    this.computePrintNodeOrderItemTotals(i);
  }
}
