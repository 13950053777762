import { Injectable } from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import {catchError, map, switchMap, retry, tap} from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {DataService, ObjectAttachRequest, ObjectDetachRequest} from "./data-service";
import {DataObject} from "../../model/DataObject";
import {DataNotificationClient} from "./DataNotificationClient";
import {Subject} from "rxjs";
import {NotificationsService} from "./notifications.service";
import {InteriorTheme} from "../../model/editor/interior-theme/InteriorTheme";

@Injectable()
export abstract class AbstractLiveDataService<T extends DataObject> extends DataService implements DataNotificationClient<T> {
  public objects: T[] = [];
  public objectsById = {};
  public objectAttachRequests: any = {};
  public objectDetachRequests: any = {};

  private objectsObservable = of( this.objects );
  public loadingState: string = 'INITIAL';
  public addNotifier: Subject<T> = new Subject<T>();
  public updateNotifier: Subject<T> = new Subject<T>();
  public deleteNotifier: Subject<T> = new Subject<T>();

  abstract makeObjectInstance(args: any): T;
  abstract getLoggingObjectTypeName(): string;
  abstract supportsParallel(): boolean;
  abstract getURLEndPoint(): string;
  // abstract linkObject(newObject: T, rawObject: T);
  // abstract updateLinks(existingObject: T, newObject: T, rawObject: T);
  abstract getLinkData(): any[];

  protected constructor(protected http: HttpClient, protected messageService: MessageService, protected startupService: StartupService, protected notificationsService: NotificationsService) {
    super(startupService);
  }

  public hash(s: string) {
    return s.split("").reduce(function(a, b) {
      a = (( a << 5 ) - a) + b.charCodeAt(0);
      return a & a;
    } , 0 );
  }

  reset () {
    this.loadingState = 'INITIAL';
    this.objectAttachRequests = {};
    this.objectDetachRequests = {};
    this.objectsById = {};
    if ( this.objects) {
      while ( this.objects.length > 0) {
        this.objects.pop();
      }
    }
  }

  getAddNotifier(flood: boolean) {
    if ( flood ) {
      this.objects.forEach( o => {
        this.addNotifier.next(o);
      });
    }
    return this.addNotifier;
  }

  getUpdateNotifier() {
    return this.updateNotifier;
  }

  getDeleteNotifier() {
    return this.deleteNotifier;
  }

  getItems(reset: boolean = true, limit: number = 25): Observable<T[]> {
    if ( !this.loadingState || this.loadingState === 'INITIAL' || ( reset && this.loadingState !== 'LOADING' )) {
      if ( this.loadingState === 'LOADING' ) {
        console.log(`Already Loading A${this.getURLEndPoint()}`);
      }
      this.reset();
      this.loadingState = 'LOADING';
      const url = `${environment.apiBaseURL}${this.getURLEndPoint()}`;
      return this.getRecursiveItems(url, 1, limit, '', this.startupService.selectedRole);
    } else {
      return this.objectsObservable;
    }
  }

  getFilteredItems(filter: any, reset: boolean = true, limit: number = 25): Observable<T[]> {
    if ( this.loadingState === 'INITIAL' || ( reset && this.loadingState !== 'LOADING' ) ) {
      if ( this.loadingState === 'LOADING' ) {
        console.log(`Already Loading B${this.getURLEndPoint()}`);
      }
      this.reset();
      this.loadingState = 'LOADING';
      const filterString = `filter=${Buffer.from(JSON.stringify(filter), 'binary').toString('base64')}`;
      const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/filter`;
      if ( this.supportsParallel() ) {
        console.log(`getCount ${url}`);
        let countUrl = `${url}?page=-1`;
        if ( filterString ) {
          countUrl += '&' + filterString;
        }
        countUrl += '&liveObjects=true';
        this.messageService.add(`${this.getLoggingObjectTypeName()}Service: fetching count`);
        const selectedRole = this.startupService.selectedRole;
        return this.http.get<T[]>(countUrl, this.startupService.getHttpOptions())
          .pipe(
            switchMap(countResponse => {
              if ( selectedRole !== this.startupService.selectedRole ) {
                return this.objectsObservable;
              } else {
                if ( Number( countResponse) === 0 ) {
                  this.loadingState = 'LOADED';
                  return this.objectsObservable;
                }
              }
              if ( countResponse ) {
                console.log(`Count Objects ${countResponse}`);
              }
              const numberOfPagesToRunInParallel = Math.ceil( Number(countResponse) / limit );
              const requests = [];
              for ( let page = 1; page <= numberOfPagesToRunInParallel; page++ ) {
                // console.log(`getLimitItems ${url} ${page} ${limit}`);
                let pageUrl = `${url}?page=${page}&limit=${limit}`;
                if ( filterString ) {
                  pageUrl += '&' + filterString;
                }
                pageUrl += '&liveObjects=true';
                this.messageService.add(`${this.getLoggingObjectTypeName()}Service: fetching items`);
                requests.push(
                  this.http.get<T[]>(pageUrl, this.startupService.getHttpOptions())
                    .pipe(
                      map(items => {
                        if ( selectedRole !== this.startupService.selectedRole ) {
                          console.log(`SWITCHED ROLES -- selectedRoleWhenRequestWasMade[${selectedRole}] startupServiceSelectedRole[${this.startupService.selectedRole}] ${this.getLoggingObjectTypeName()} getItems with Filter limit[${limit}] totalItems[${items.length}] running in Parallel`);
                          return false;
                        }
                        for ( const item of items ) {
                          this.addUpdateLocalItem(item);
                        }
                        return true;
                      }),
                      catchError(val => of(`I caught: ${val}`))
                    )
                  );
              }
              return forkJoin(requests).
                pipe(
                  map( r => {
                    r.forEach( a => {
                      if ( !a ) {
                        // The selected role changed -- ignore this result
                        return this.objects;
                      }
                    });
                    this.loadingState = 'LOADED';
                    return this.objects;
                  } )
                );
            }),
            catchError(this.handleErrorArray(`getFilteredItems ${filterString}`))
          );
      } else {
        console.log(`${this.getLoggingObjectTypeName()} getItems with Filter limit[${limit}] not Parallel`);
        return this.getRecursiveItems(url, 1, limit, filterString, this.startupService.selectedRole);
      }
    } else {
      return this.objectsObservable;
    }
  }

  private getRecursiveItems(baseUrl: string, page: number, limit: number, filterString: string, selectedRole: string): Observable<T[]> {
    console.log(`getRecursiveItems ${baseUrl} ${page} ${limit}`);
    let url = `${baseUrl}?page=${page}&limit=${limit}`;
    if ( filterString ) {
      url += '&' + filterString;
    }
    url += '&liveObjects=true';
    this.messageService.add(`${this.getLoggingObjectTypeName()}Service: fetched items`);
    return this.http.get<T[]>(url, this.startupService.getHttpOptions())
      .pipe(
        switchMap(items => {
          if ( selectedRole !== this.startupService.selectedRole ) {
            console.log(`SWITCHED ROLES -- selectedRoleWhenRequestWasMade[${selectedRole}] startupServiceSelectedRole[${this.startupService.selectedRole}] ${this.getLoggingObjectTypeName()} getItems with Filter limit[${limit}] totalItems[${items.length}]`);
            return this.objectsObservable;
          }
          for ( const item of items ) {
            this.addUpdateLocalItem(item);
          }
          if ( items.length === limit ) {
            return this.getRecursiveItems(baseUrl, page + 1, limit, filterString, selectedRole);
          } else {
            console.log(`${this.getLoggingObjectTypeName()} getItems ${page} ${limit} DONE ${items.length}`);
            this.loadingState = 'LOADED';
            return this.objectsObservable;
          }
        }),
        catchError(this.handleErrorArray(`getRecursiveItems ${page} ${limit} ${filterString}`))
      );
  }

  getItem(id: number): Observable<T> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/${id}`;
    this.messageService.add(`${this.getLoggingObjectTypeName()} : fetched item id=${id}`);
    return this.http.get<T>(url, this.startupService.getHttpOptions())
      .pipe(
        map(o => {
          return this.addUpdateLocalItem(o);
        }),
        catchError(this.handleError(`get ${this.getLoggingObjectTypeName()} id=${id}`))
    );
  }

  updateItem (item: T): Observable<T> {
    const object = {};
    for (const prop of Object.keys(item)) {
      if ( !(item[prop] instanceof Array ) && ( typeof item[prop] === 'string' || typeof item[prop] === 'number' || typeof item[prop] === 'boolean' || item[prop] instanceof String || item[prop] instanceof Number || item[prop] instanceof Boolean || item[prop] instanceof Date )) {
        object[prop] = item[prop];
      } else {
        if (!(item[prop] instanceof Array )) {
          // Log this with a break point to make sure we have the right conditions in the above if statement
          console.log(`Rejecting saving property ${prop} of ${this.getLoggingObjectTypeName()}`);
        }
      }
    }
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/${item.getId()}`;
    this.messageService.add(`${this.getLoggingObjectTypeName()}: updated id=${item.getId()}`);

    return this.http.put<T>(url, object, this.startupService.getHttpOptions()).pipe(
      tap(o => {
        this.log(`updated ${this.getLoggingObjectTypeName()} id=${item.getId()}`);
        return this.addUpdateLocalItem(o);
      }),
      catchError(this.handleError(`update ${this.getLoggingObjectTypeName()}`))
    );
  }


  addItem (item: T): Observable<T> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}`;
    return this.http.post<T>(url, item, this.startupService.getHttpOptions())
      .pipe( retry(6),
        map(o => {
          return this.addUpdateLocalItem(o);
        }),
      catchError(this.handleError(`add ${this.getLoggingObjectTypeName()}`))
    );
  }

  deleteItem (item: T): Observable<T> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/${item.getId()}`;
    // TODO: Remove the object from the objects list
    const index = this.objects.indexOf(item);
    if ( index >= 0 ) {
      this.objects.splice(index, 1);
    }
    return this.http.delete<T>(url, this.startupService.getHttpOptions()).pipe(
      tap(o => {
        this.deleteLocalItem(o);
        this.log(`deleted ${this.getLoggingObjectTypeName()} id=${item.getId()}`);
      }),
      catchError(this.handleError(`delete ${this.getLoggingObjectTypeName()}`))
    );
  }

  // handleError<T> (role: string, operation = 'operation', result?: T) {
  protected handleError (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  // handleError<T> (role: string, operation = 'operation', result?: T) {
  protected handleErrorArray (operation = 'operation', result?: T[]) {
    return (error: any): Observable<T[]> => {
      this.loadingState = 'ERROR';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T[]);
    };
  }
  protected log(message: string) {
    this.messageService.add(`${this.getLoggingObjectTypeName()} Service:` + message);
  }

  public getLocalItem(id: number): T {
    return this.objectsById['' + id];
    // for (const item of this.objects) {
    //   if (item.getId() === id) {
    //     return item;
    //   }
    // }
    // return null;
  }


  addUpdateLocalItem (rawObject: T): T {
    if ( rawObject['facilityInventoryProducts'] && rawObject['facilityInventoryProducts'].length > 0 ) {
      console.log(`Product with facilityInventoryProducts`);
    }
    const o = this.makeObjectInstance(rawObject);
    const existingObj = this.getLocalItem(o.getId());
    if ( !existingObj ) {
      this.objectsById['' + o.getId()] = o;
      this.objects.push(o);
      try {
        this.linkObject(o, rawObject);
      } catch ( e ) {
        console.log(`Error addUpdateLocalItem linkObject ${e.getMessage()}`);
      }
      try {
        this.resolveObjectAttachmentRequets(o);
      } catch ( e ) {
        console.log(`Error addUpdateLocalItem resolveObjectAttachmentRequets ${e.getMessage()}`);
      }
      this.addNotifier.next(o);
      return o;
    } else {
      try {
        this.updateLinks(existingObj, o, rawObject);
      } catch ( e ) {
        console.log(`Error addUpdateLocalItem updateLinks ${e.getMessage()}`);
      }
      try {
        this.resolveObjectAttachmentRequets(o);
      } catch ( e ) {
        console.log(`Error addUpdateLocalItem resolveObjectAttachmentRequets ${e.getMessage()}`);
      }
      try {
        existingObj.update(rawObject);
      } catch ( e ) {
        console.log(`Error addUpdateLocalItem ${e.getMessage()}`);
      }
      this.updateNotifier.next(existingObj);
      return existingObj;
    }
  }

  linkObject(newObject: T, rawObject: T) {
    if ( newObject && rawObject ) {
      try {
        for (const data of this.getLinkData()) {
          switch (data.action) {
            case 'Link': {
              if (rawObject[data.objectProperty]) {
                this.notificationsService.addUpdateLocalItem(data.serviceName, rawObject[data.objectProperty]);
              }
              if (newObject[data.objectIdPropertyName]) {
                this.notificationsService.addObjectAttachRequest(data.serviceName, new ObjectAttachRequest(newObject[data.objectIdPropertyName], data.arrayProperty, data.objectProperty, newObject));
              }
              break;
            }
            case 'AddUpdateArray': {
              if (rawObject[data.arrayProperty]) {
                for (const object of rawObject[data.arrayProperty]) {
                  this.notificationsService.addUpdateLocalItem(data.serviceName, object);
                }
              }
              break;
            }
          }
        }
      } catch ( e ) {
        console.log(`Error in linkObject for [${this.getLoggingObjectTypeName()}] with linkData of [${this.getLinkData()}] newObject[${newObject}] rawObject[${rawObject}] error[${e.toString()}]`);
      }
    } else {
      console.log(`Error in linkObject for [${this.getLoggingObjectTypeName()}] with linkData of [${this.getLinkData()}] newObject[${newObject}] rawObject[${rawObject}] either newObject or rawObject is null`);
    }
  }

  updateLinks(existingObject: T, newObject: T, rawObject: T) {
    if ( existingObject && newObject && rawObject ) {
      try {
        for ( const data of this.getLinkData())  {
          switch ( data.action ) {
            case 'Link': {
              if ( rawObject[data.objectProperty] ) {
                this.notificationsService.addUpdateLocalItem(data.serviceName, rawObject[data.objectProperty]);
              }
              if ( newObject[data.objectIdPropertyName] ) {
                if ( data.objectIdPropertyName === 'manufacturingset_id' ) {
                  console.log('debug');
                }
                if ( existingObject[data.objectIdPropertyName] !== newObject[data.objectIdPropertyName] || ( existingObject[data.objectProperty] && typeof existingObject[data.objectProperty]['getId'] === 'function' && existingObject[data.objectProperty].getId() !== newObject[data.objectIdPropertyName] ) ) {
                  if ( existingObject[data.objectProperty] && existingObject[data.objectProperty][data.arrayProperty] ) {
                    const index = existingObject[data.objectProperty][data.arrayProperty].indexOf(existingObject, 0);
                    if (index > -1) {
                      existingObject[data.objectProperty][data.arrayProperty].splice(index, 1);
                    }
                  }
                  delete existingObject[data.objectProperty];
                  existingObject[data.objectIdPropertyName] = newObject[data.objectIdPropertyName];
                  this.notificationsService.addObjectAttachRequest(data.serviceName, new ObjectAttachRequest(existingObject[data.objectIdPropertyName], data.arrayProperty, data.objectProperty, existingObject));
                }
              }
              break;
            }
            case 'AddUpdateArray': {
              if ( rawObject[data.arrayProperty] ) {
                for (const object of rawObject[data.arrayProperty]) {
                  this.notificationsService.addUpdateLocalItem(data.serviceName, object);
                }
              }
              break;
            }
          }
        }
      } catch ( e ) {
        console.log(`Error in linkObject for [${this.getLoggingObjectTypeName()}] with linkData of [${this.getLinkData()}] existingObject[${existingObject}] newObject[${newObject}] rawObject[${rawObject}] error[${e.toString()}]`);
      }
    } else {
      console.log(`Error in linkObject for [${this.getLoggingObjectTypeName()}] with linkData of [${this.getLinkData()}] existingObject[${existingObject}] newObject[${newObject}] rawObject[${rawObject}] one of  existingObject, newObject or rawObject is null`);
    }
  }

  deleteLocalItem (rawObject: T) {
    const o = this.makeObjectInstance(rawObject);
    const existingObj = this.getLocalItem(o.getId());
    if ( existingObj ) {
      let found = false;
      for ( let i = 0; i < this.objects.length && !found; i++) {
        if ( existingObj === this.objects[i] ) {
          delete this.objectsById['' + o.getId()];
          this.objects.splice(i, 1);
          this.deleteNotifier.next(existingObj);
          found = true;
        }
      }
    }
  }

  addObjectAttachRequest(objectAttachRequest: ObjectAttachRequest) {
    if ( !this.objectAttachRequests['' + objectAttachRequest.objectIdToAttachTo]) {
      this.objectAttachRequests['' + objectAttachRequest.objectIdToAttachTo] = [];
    }
    this.objectAttachRequests['' + objectAttachRequest.objectIdToAttachTo].push(objectAttachRequest);

    const object = this.getLocalItem(objectAttachRequest.objectIdToAttachTo);
    if ( object ) {
      try {
        this.resolveObjectAttachmentRequets(object);
      } catch ( e ) {
        console.log(`Error addingObjectAttachRequest ${e.getMessage()}`);
      }
    }
  }

  resolveObjectAttachmentRequets(object: T) {
    if ( this.objectAttachRequests[object.getId()] ) {
      const objectAttachmentRequests = this.objectAttachRequests[object.getId()];
      for ( const objectAtachmentRequest of objectAttachmentRequests ) {
        if ( !object[objectAtachmentRequest.arrayPropertyToAddTo] ) {
          object[objectAtachmentRequest.arrayPropertyToAddTo] = [];
          object[objectAtachmentRequest.arrayPropertyToAddTo].push(objectAtachmentRequest.objectToAttach);
          objectAtachmentRequest.objectToAttach[objectAtachmentRequest.objectPropertyToSet] = object;
        } else {
          const foundIndex = object[objectAtachmentRequest.arrayPropertyToAddTo].findIndex(item => item.getId() === objectAtachmentRequest.objectToAttach.getId());
          if ( foundIndex === -1) {
            object[objectAtachmentRequest.arrayPropertyToAddTo].push(objectAtachmentRequest.objectToAttach);
            objectAtachmentRequest.objectToAttach[objectAtachmentRequest.objectPropertyToSet] = object;
          }
        }
      }
      delete this.objectAttachRequests[object.getId()];
    }
  }

  addObjectDetachRequest(objectDetachRequest: ObjectDetachRequest) {
  }

  resolveAttachmentRequests(object: T) {
  }
}
