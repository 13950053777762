import {ManufacturingResourceBucketPeriod} from "./ManufacturingResourceBucketPeriod";
import {DataObject} from "./DataObject";
import {CriteriaSet} from "./supporting/CriteriaSet";

export class ManufacturingResourceBucket implements DataObject {
  ID: number;
  name: string;
  useTypes: string[];
  icon: string;
  status: string;
  criteriaSet: CriteriaSet;
  manufacturingResourceBucketPeriods: ManufacturingResourceBucketPeriod[];

  constructor (obj) {
    this.ID = 0;
    this.name = '';
    this.useTypes = [];
    this.icon = 'gear';
    this.status = 'PREPARING';

    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);

    // if (obj) {
    //   for (const prop of Object.keys(obj)) {
    //     this[prop] = obj[prop];
    //     if ( prop === 'manufacturingResourceBucketPeriods') {
    //        this.manufacturingResourceBucketPeriods = [];
    //       for ( const manufacturingResourceBucketPeriod of obj[prop] ) {
    //         this.manufacturingResourceBucketPeriods.push(new ManufacturingResourceBucketPeriod(manufacturingResourceBucketPeriod));
    //       }
    //     }
    //     if ( prop === 'criteriaSet') {
    //       this.criteriaSet = new CriteriaSet(obj[prop]);
    //     }
    //   }
    // }
  }

  getId () {
    return this.ID;
  }

  getOrderValue () {
    return this.ID;
  }

  update (obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
            if ( prop === 'criteriaSet') {
              this.criteriaSet = new CriteriaSet(obj[prop]);
            }
      }
    }
  }
}
