export class InteriorThemeConfigurationSelector {
    public name: string;
    public selector: string;
    public propertiesType: string;

    constructor(name: string, selector: string,  propertiesType: string ) {
      this.name = name;
      this.propertiesType = propertiesType;
      this.selector = selector;
    }
}



