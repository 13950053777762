import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {ManufacturingSet} from "../../model/ManufacturingSet";
import {NotificationsService} from "./notifications.service";
import {AbstractLiveDataService} from "./abstract-live-data.service";

@Injectable()

export class ManufacturingSetService extends AbstractLiveDataService<ManufacturingSet> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setManufacturingSetService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): ManufacturingSet {
    const object = new ManufacturingSet(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "ManufacturingSet";
  }

  supportsParallel(): boolean {
    return true;
  }

  getURLEndPoint (): string {
    return "manufacturingset";
  }

  getLinkData(): any[] {
    return [
      { action: 'AddUpdateArray', serviceName: 'printNodeOrderItemService', arrayProperty: 'printNodeOrderItems'},
      { action: 'Link', objectIdPropertyName: 'manufacturingTaskBucketPeriod_id', serviceName: 'manufacturingTaskBucketPeriodService', arrayProperty: 'manufacturingSets', objectProperty: 'manufacturingTaskBucketPeriod'},
      { action: 'AddUpdateArray', serviceName: 'manufacturingTaskService', arrayProperty: 'manufacturingTasks'},
    ];
  }

  // linkObject(newObject: ManufacturingSet, rawObject: ManufacturingSet) {
  //   if ( rawObject.printNodeOrderItems ) {
  //     for (const printNodeOrderItem of rawObject.printNodeOrderItems) {
  //       this.notificationsService.addUpdateLocalItem('printNodeOrderItemService', printNodeOrderItem);
  //     }
  //   }
  //   if ( rawObject.manufacturingTaskBucketPeriod ) {
  //     this.notificationsService.addUpdateLocalItem('manufacturingTaskBucketPeriodService', rawObject.manufacturingTaskBucketPeriod);
  //   }
  //   if ( newObject.manufacturingTaskBucketPeriod_id ) {
  //     this.notificationsService.addObjectAttachRequest('manufacturingTaskBucketPeriodService', new ObjectAttachRequest(newObject.manufacturingTaskBucketPeriod_id, 'manufacturingSets', 'manufacturingTaskBucketPeriod', newObject));
  //   }
  //   if ( rawObject.manufacturingTasks ) {
  //     for (const manufacturingTask of rawObject.manufacturingTasks) {
  //       this.notificationsService.addUpdateLocalItem('manufacturingTaskService', manufacturingTask);
  //     }
  //   }
  // }
  //
  // updateLinks(existingObject: ManufacturingSet, newObject: ManufacturingSet, rawObject: ManufacturingSet) {
  //   if ( existingObject && newObject && rawObject ) {
  //     if ( rawObject.manufacturingTaskBucketPeriod ) {
  //       this.notificationsService.addUpdateLocalItem('manufacturingTaskBucketPeriodService', rawObject.manufacturingTaskBucketPeriod);
  //     }
  //     if ( newObject.manufacturingTaskBucketPeriod_id ) {
  //       if ( existingObject.manufacturingTaskBucketPeriod_id !== newObject.manufacturingTaskBucketPeriod_id ) {
  //         if ( existingObject.manufacturingTaskBucketPeriod && existingObject.manufacturingTaskBucketPeriod.manufacturingSets ) {
  //           const index = existingObject.manufacturingTaskBucketPeriod.manufacturingSets.indexOf(existingObject, 0);
  //           if (index > -1) {
  //             existingObject.manufacturingTaskBucketPeriod.manufacturingSets.splice(index, 1);
  //           }
  //         }
  //         delete existingObject.manufacturingTaskBucketPeriod;
  //         existingObject.manufacturingTaskBucketPeriod_id = newObject.manufacturingTaskBucketPeriod_id;
  //         this.notificationsService.addObjectAttachRequest('manufacturingTaskBucketPeriodService', new ObjectAttachRequest(existingObject.manufacturingTaskBucketPeriod_id, 'manufacturingSets', 'manufacturingTaskBucketPeriod', existingObject));
  //       }
  //     }
  //     if ( rawObject.manufacturingTasks ) {
  //       for (const manufacturingTask of rawObject.manufacturingTasks) {
  //         this.notificationsService.addUpdateLocalItem('manufacturingTaskService', manufacturingTask);
  //       }
  //     }
  //   }
  // }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleLocalError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // private log(message: string) {
  //   this.messageService.add('ManufacturingSetService: ' + message);
  // }
  /** PUT: update the manufacturingSet on the server */
  setManufacturingProcess (manufacturingSetId: number, manufacturingProcessId: number): Observable<ManufacturingSet> {
    const url = `${environment.apiBaseURL}manufacturingset/setmanufacturingprocess/${manufacturingSetId}/${manufacturingProcessId}`;
    this.messageService.add(`ManufacturingSetService: setManufacturingProcess manufacturingSetId=${manufacturingSetId}  manufacturingProcessId=${manufacturingProcessId}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingSet => {
          const m = new ManufacturingSet(manufacturingSet);
          return m;
        }),
        catchError(this.handleLocalError<any>('setManufacturingProcess'))
      );
  }

  /** PUT: move a PrintNodeOrderItem to a specific ManufacturingSet *
  movePrintNodeOrderItem (manufacturingSetId: number, manufacturingSetDetails: any,  printNodeOrderItemId: number): Observable<ManufacturingSet> {
    const url = `${environment.apiBaseURL}manufacturingset/addprintnodeorderitem/${manufacturingSetId}/${printNodeOrderItemId}`;
    this.messageService.add(`ManufacturingSetService: addPrintNodeOrderItem manufacturingSetId=${manufacturingSetId}  manufacturingProcessId=${printNodeOrderItemId}`);
    return this.http.put(url, manufacturingSetDetails, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingSet => {
          const m = new ManufacturingSet(manufacturingSet);
          return m;
        }),
        catchError(this.handleLocalError<any>('addPrintNodeOrderItem'))
      );
  }
  /** PUT: update the manufacturingSet on the server */
  removePrintNodeOrderItem (manufacturingSetId: number, printNodeOrderItemId: number): Observable<ManufacturingSet> {
    const url = `${environment.apiBaseURL}manufacturingset/removeprintnodeorderitem/${manufacturingSetId}/${printNodeOrderItemId}`;
    this.messageService.add(`ManufacturingSetService: removePrintNodeOrderItem manufacturingSetId=${manufacturingSetId}  manufacturingProcessId=${printNodeOrderItemId}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingSet => {
          const m = new ManufacturingSet(manufacturingSet);
          return m;
        }),
        catchError(this.handleLocalError<any>('addPrintNodeOrderItem'))
      );
  }

  /** PUT: update the manufacturingSet on the server */
  setOpenStatus (manufacturingSetId: number, openStatus: string): Observable<ManufacturingSet> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/setopenstatus/${manufacturingSetId}/${openStatus}`;
    this.messageService.add(`ManufacturingSetService: setOpenStatus manufacturingSetId=${manufacturingSetId}  manufacturingProcessId=${openStatus}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingSet => {
          const m = new ManufacturingSet(manufacturingSet);
          return m;
        }),
        catchError(this.handleErrorX<any>('setOpenStatus'))
      );
  }

  private handleErrorX<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

// export class ManufacturingSetService extends DataService {
//   private objects: ManufacturingSet[] = [];
//   private objectsObservable = of( this.objects );
//   public loadingState = 'INITIAL';
//
//
//   constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
//     super(startupService);
//   }
//
//     reset (filter: string = '') {
//     this.loadingState = 'INITIAL';
//     while ( this.objects.length > 0) {
//       this.objects.pop();
//     }
//   }
//
//
//   getManufacturingSets(): Observable<ManufacturingSet[]> {
//     const url = `${environment.apiBaseURL}manufacturingset`;
//     this.messageService.add('ManufacturingSetService: fetched manufacturingSets');
//     return this.http.get<ManufacturingSet[]>(url, this.startupService.getHttpOptions())
//       .pipe(
//         map(manufacturingSets => {
//           const ms = [];
//           for ( const manufacturingSet of manufacturingSets ) {
//             const m = new ManufacturingSet(manufacturingSet);
//             ms.push(m);
//           }
//           return ms;
//         }),
//
//         catchError(this.handleError('getManufacturingSets', []))
//       );
//   }
//
//   getFilteredItems(filter: any, reset: boolean = true): Observable<T[]> {
//     const role = this.getRole(JSON.stringify(filter));
//     if ( this.loadingState[role] === 'INITIAL' || reset ) {
//       this.reset(filter);
//       this.loadingState[role] = 'LOADING';
//       const filterString = `filter=${Buffer.from(JSON.stringify(filter), 'binary').toString('base64')}`;
//       const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/filter`;
//       return this.getRecursiveItems(role, url, 1, 25, filterString);
//     } else {
//       return this.objectsObservable[role];
//     }
//   }
//
//   private getRecursiveItems(role: string, baseUrl: string, page: number, limit: number, filterString: string): Observable<T[]> {
//     console.log(`getRecursiveItems ${role}  ${page} ${limit}`);
//     let url = `${baseUrl}?page=${page}&limit=${limit}`;
//     if ( filterString ) {
//       url += '&' + filterString;
//     }
//     this.messageService.add(`${this.getLoggingObjectTypeName()}Service: fetched items`);
//     // const _http = this.http;
//     return this.http.get<T[]>(url, this.startupService.getHttpOptions())
//       .pipe(
//         switchMap(items => {
//           for ( const item of items ) {
//             const o = this.makeObjectInstance(item);
//             this.objects[role].push(o);
//           }
//           if ( items.length === limit ) {
//             return this.getRecursiveItems(role, baseUrl, page + 1, limit, filterString);
//           } else {
//             console.log(`${this.getLoggingObjectTypeName()} getItems ${role} ${page} ${limit} DONE ${items.length}`);
//             this.loadingState[role] = 'LOADED';
//             return this.objectsObservable[role] as Observable<T[]>;
//           }
//         })
//       );
//   }
//
//   getManufacturingSet(id: string): Observable<ManufacturingSet> {
//     const url = `${environment.apiBaseURL}manufacturingset/${id}`;
//     this.messageService.add(`ManufacturingSetService: fetched manufacturingSet id=${id}`);
//     return this.http.get<ManufacturingSet>(url, this.startupService.getHttpOptions())
//       .pipe(
//         map(manufacturingSet => {
//           const m = new ManufacturingSet(manufacturingSet);
//           return m;
//         }),
//         catchError(this.handleError<ManufacturingSet>(`getManufacturingSet id=${id}`))
//     );
//   }
//

  // /** PUT: update the manufacturingSet on the server */
  // updateManufacturingSet (manufacturingSet: ManufacturingSet): Observable<ManufacturingSet> {
  //   const url = `${environment.apiBaseURL}manufacturingset/${manufacturingSet.ID}`;
  //   this.messageService.add(`ManufacturingSetService: updated manufacturingSet id=${manufacturingSet.ID}`);
  //   return this.http.put(url, manufacturingSet, this.startupService.getHttpOptions()).pipe(
  //     tap(_ => this.log(`updated manufacturingSet id=${manufacturingSet.ID}`)),
  //     catchError(this.handleError<any>('updateManufacturingSet'))
  //   );
  // }
  //
  // addManufacturingSet (manufacturingSet: ManufacturingSet): Observable<ManufacturingSet> {
  //   const url = `${environment.apiBaseURL}manufacturingset`;
  //   return this.http.post<ManufacturingSet>(url, manufacturingSet, this.startupService.getHttpOptions()).pipe(
  //     tap(o => {
  //       this.log(`added manufacturingSet w/ id=${manufacturingSet.ID}`);
  //       this.objects.push(new ManufacturingSet(o));
  //     }),
  //     catchError(this.handleError<ManufacturingSet>('addManufacturingSet'))
  //   );
  // }
  //
  // /** DELETE: delete the manufacturingSet from the server */
  // deleteManufacturingSet (manufacturingSet: ManufacturingSet): Observable<ManufacturingSet> {
  //   const url = `${environment.apiBaseURL}manufacturingset/${manufacturingSet.ID}`;
  //
  //   return this.http.delete<ManufacturingSet>(url, this.startupService.getHttpOptions()).pipe(
  //     tap(_ => this.log(`deleted manufacturingSet id=${manufacturingSet.ID}`)),
  //     catchError(this.handleError<ManufacturingSet>('deleteManufacturingSet'))
  //   );
  // }

