<div class="interiorThemeEditor">
  <ng-container *ngIf="interiorThemeEditor && interiorThemeEditor.items">
    <mat-card appearance="outlined" class="sideAreaMatCard">
    <mat-card-content>
      <!--<mat-tab-group>-->
        <!--<mat-tab i18n-label="@@Styles for Selected Text" label="Styles for Selected Text">-->
        <ng-container *ngIf="startupService.showCurrentlySelectedElementInteriorStyles">
          <mat-card appearance="outlined" class="sideAreaMatCard">
            <mat-card-content>
              <mat-tab-group>
                <ng-container *ngFor="let interiorThemeEditorItem of interiorThemeEditor.items">
                  <mat-tab *ngIf="interiorThemeEditorItem.isSelectedElement || interiorThemeEditorItem === selectedInteriorThemeEditorItem" [label]="interiorThemeEditorItem.selector.name | languageString" (click)="selectedInteriorThemeEditorItem = interiorThemeEditorItem">
                    <app-interior-theme-item-editor [interiorThemeEditorItem]="interiorThemeEditorItem" [isSelectedElement]="interiorThemeEditorItem.isSelectedElement" [isSelected]="interiorThemeEditorItem === selectedInteriorThemeEditorItem" [showHeaders]="showHeaders" [onlyShowSelectedElements]="onlyShowSelectedElements" [highlightTextImpactedByStyle]="highlightTextImpactedByStyle"></app-interior-theme-item-editor>
                  </mat-tab>
                </ng-container>
              </mat-tab-group>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="!startupService.showCurrentlySelectedElementInteriorStyles">
        <!--<mat-tab i18n-label="@@All Styles" label="All Styles">-->
          <div class="interiorThemeEditor">
            <mat-toolbar color="primary">
              <div style="display:flex; width:100%">
              <span>
                <mat-form-field>
                  <mat-select i18n-placeholder="@@Styles" placeholder="Styles" [(ngModel)]="selectedStyleName">
                    <mat-option *ngFor="let interiorThemeEditorItem of interiorThemeEditor.items" [value]="interiorThemeEditorItem.selector.name">{{interiorThemeEditorItem.selector.name | languageString }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <!--&lt;!&ndash;<button mat-icon-button [ngClass]="showAll ? 'inverse': ''" (click)="showAll = !showAll" i18n-title="@@Show/hide inactive items" title="Show/hide inactive items"><mat-icon>edit</mat-icon></button>&ndash;&gt;-->
                <!--&lt;!&ndash;<button mat-icon-button [ngClass]="showHeaders ? 'inverse': ''" (click)="showHeaders = !showHeaders" i18n-title="@@Show/hide position values" title="Show/hide position values"><mat-icon>dashboard</mat-icon></button>&ndash;&gt;-->
                <!--<button mat-icon-button [ngClass]="showSelected ? 'inverse': ''" (click)="showSelected = !showSelected" i18n-title="@@Show/hide property values" title="Show/hide property values"><mat-icon>details</mat-icon></button>-->
                <!--<button *ngIf="!onlyShowSelectedElements" mat-icon-button [ngClass]="onlyShowSelectedElements ? 'inverse': ''" (click)="onlyShowSelectedElements = !onlyShowSelectedElements" i18n-title="@@Show styles of selected text." title="Show styles of selected text."><mat-icon>details</mat-icon></button>-->
                <!--<button *ngIf="onlyShowSelectedElements" mat-icon-button [ngClass]="onlyShowSelectedElements ? 'inverse': ''" (click)="onlyShowSelectedElements = !onlyShowSelectedElements" i18n-title="@@Pick style independent of selected text." title="Pick style independent of selected text."><mat-icon>view_list</mat-icon></button>-->
                <!--<button mat-icon-button [ngClass]="highlightTextImpactedByStyle ? 'inverse': ''" (click)="highlightTextImpactedByStyle = !highlightTextImpactedByStyle" i18n-title="@@Highlight Text Impacted By Style" title="Highlight Text Impacted By Style"><mat-icon>highlight</mat-icon></button>-->
              </span>
                <span class="app-toolbar-filler"></span>
              </div>
              <span class="app-toolbar-filler"></span>
            </mat-toolbar>
            <ng-container *ngFor="let interiorThemeEditorItem of interiorThemeEditor.items">
              <ng-container *ngIf="interiorThemeEditorItem.selector.name === selectedStyleName">
                <app-interior-theme-item-editor [interiorThemeEditorItem]="interiorThemeEditorItem" [isSelectedElement]="interiorThemeEditorItem.isSelectedElement" [isSelected]="interiorThemeEditorItem === selectedInteriorThemeEditorItem" [showHeaders]="showHeaders" [onlyShowSelectedElements]="onlyShowSelectedElements" [highlightTextImpactedByStyle]="highlightTextImpactedByStyle"></app-interior-theme-item-editor>
              </ng-container>
            </ng-container>
            <!--<div *ngFor="let interiorThemeEditorItem of interiorThemeEditor.items" (click)="selectedInteriorThemeEditorItem = interiorThemeEditorItem">-->
              <!--<app-interior-theme-item-editor [interiorThemeEditorItem]="interiorThemeEditorItem" [isSelectedElement]="interiorThemeEditorItem.isSelectedElement" [isSelected]="interiorThemeEditorItem === selectedInteriorThemeEditorItem" [showAll]="showAll" [showHeaders]="showHeaders" [onlyShowSelectedElements]="onlyShowSelectedElements" [highlightTextImpactedByStyle]="highlightTextImpactedByStyle"></app-interior-theme-item-editor>-->
            <!--</div>-->

          </div>
        </ng-container>
      <!--</mat-tab-group>-->
    </mat-card-content>
  </mat-card>



  </ng-container>
</div>
