<a name="about"></a>
<div class="intro-header">
  <div class="intro-message">
    <div class="intro-logo">
      <img class="intro-logo" src="/logo.png"/>
    </div>
    <!--<h1 i18n="@@Main Organization Name">Main Organization Name</h1>-->
    <!--<hr class="intro-divider">-->
    <ul class="list-inline intro-social-buttons">
      <li>
        <button mat-raised-button color="primary" [routerLink]="['/home/login']" class="btn btn-primary btn-lg"><ng-container i18n="@@Main Organization Name"></ng-container><ng-container i18n="@@Login">Login</ng-container></button>
      </li>
    </ul>
    <div class="localeButtons" *ngIf="environment.showLanguagesOptions">
      <a mat-raised-button color="primary" i18n-href="@@enURL" href="/" i18n="@@English">English</a>
      <a mat-raised-button color="primary" i18n-href="@@frURL" href="/" i18n="@@French">français</a>
      <a mat-raised-button color="primary" i18n-href="@@esURL" href="/" i18n="@@Spanish">Español</a>
      <a mat-raised-button color="primary" i18n-href="@@deURL" href="/" i18n="@@German">Deutsche</a>
      <a mat-raised-button color="primary" i18n-href="@@arURL" href="/" i18n="@@Arabic" style="text-align:right">عربى</a>
      <a mat-raised-button color="primary"  i18n-href="@@zhURL" href="/" i18n="@@Chinese">中文</a>
      <a mat-raised-button color="primary" i18n-href="@@amURL" href="/" i18n="@@Amharic">አማርኛ</a>
      <a mat-raised-button color="primary" i18n-href="@@koURL" href="/" i18n="@@Korean">한국어</a>
      <a mat-raised-button color="primary" i18n-href="@@roURL" href="/" i18n="@@Romanian">Română</a>
      <a mat-raised-button color="primary" i18n-href="@@ruURL" href="/" i18n="@@Russian">русский</a>
    </div>
  </div>
</div>
