import {Component, Inject, Input, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {OrderItemToPackage} from "../../../../../model/OrderItemToPackage";
import {ValidationAddress, AddressValidationResult, AddressValidationService} from "../../../../service-data/address-validation.service";
import {PrintNodeOrder} from "../../../../../model/PrintNodeOrder";
import {OrderDeliverTo} from "../../../../../model/OrderDeliverTo";
import {PrintNodeOrderService} from "../../../../service-data/print-node-order.service";
import {Package} from "../../../../../model/Package";
import {PrintNodeOrderItem} from "../../../../../model/PrintNodeOrderItem";
import {PackageType} from "../../../../../model/PackageType";
import {PackageService} from "../../../../service-data/package.service";
import {PackageTypeService} from "../../../../service-data/package-type.service";

@Component({
  selector: 'app-change-packages',
  templateUrl: './change-packages.component.html',
  styleUrls: ['./change-packages.component.css']
})
export class ChangePackagesComponent implements OnInit {
  public printNodeOrder: PrintNodeOrder;

  public initialProcessing: boolean;
  public breakpoint: number;

  public carrierServiceLevels = [
    { ID: 'UPS_GROUND', carrier: 'UPS', serviceLevel: 'GROUND', brandedPackagingRequired: false },
    { ID: 'USPS_PRIORITY_FLAT', carrier: 'USPS', serviceLevel: 'PRIORITY_FLAT', brandedPackagingRequired: true },
    { ID: 'USPS_MEDIA', carrier: 'USPS', serviceLevel: 'MEDIA', brandedPackagingRequired: false },
    { ID: 'USPS_PRIORITY', carrier: 'USPS', serviceLevel: 'PRIORITY', brandedPackagingRequired: false },
    { ID: 'USPS_FIRSTCLASS', carrier: 'USPS', serviceLevel: 'FIRSTCLASS', brandedPackagingRequired: false },
    { ID: 'USPS_PARCEL', carrier: 'USPS', serviceLevel: 'PARCEL', brandedPackagingRequired: false },
    { ID: 'UPS_FREIGHT', carrier: 'UPS', serviceLevel: 'FREIGHT', brandedPackagingRequired: false },
    { ID: 'UPS_FREIGHTNODOCK', carrier: 'UPS', serviceLevel: 'FREIGHTNODOCK', brandedPackagingRequired: false },
    { ID: 'UPS_NEXTDAYAIR', carrier: 'UPS', serviceLevel: 'NEXTDAYAIR', brandedPackagingRequired: false },
    { ID: 'UPS_NEXTDAYAIRSAVER', carrier: 'UPS', serviceLevel: 'NEXTDAYAIRSAVER', brandedPackagingRequired: false },
    { ID: 'UPS_NEXTDAYAIREARLYAM', carrier: 'UPS', serviceLevel: 'NEXTDAYAIREARLYAM', brandedPackagingRequired: false },
    { ID: 'UPS_2NDDAYAIR', carrier: 'UPS', serviceLevel: '2NDDAYAIR', brandedPackagingRequired: false },
    { ID: 'UPS_2NDDAYAIRAM', carrier: 'UPS', serviceLevel: '2NDDAYAIRAM', brandedPackagingRequired: false },
    { ID: 'UPS_3DAYSELECT', carrier: 'UPS', serviceLevel: '3DAYSELECT', brandedPackagingRequired: false },
    { ID: 'INPLANT_PICKUP', carrier: 'INPLANT', serviceLevel: 'PICKUP', brandedPackagingRequired: false }
  ];

  public form: FormGroup;
  wasFormChanged = false;
  showCompressed: boolean = false;
  selectedPackageId: number;
  selectedPrintNodeOrderItemId: number;

  public mobileQueryMatches: boolean;
  public addedPackages = [];
  public deletedPackages = [];
  public carrierServiceLevelChanges = [];
  public packageTypeChanges = [];
  public orderItemToPackageChanges = []; // zero implies deletion
  public orderItemToPackageAdditions = [];

  addressValidationStatus = ''; // Needs to be initilized
  addressValidationResult: AddressValidationResult;
  enterAnAlternativeAddress = false;
  alternativeAddress: ValidationAddress = new ValidationAddress(undefined);

  public packages: any = {};
  public packagesArray: Package[] = [];
  public orderItemToPackages: any = {};
  public printNodeOrderItems: any = {};
  public printNodeOrderItemInSets: any = [];
  // public packageTypeById: any = {}; // Add facilityId to the packageTypeById
  layoutType = 'MoreItems';

  nextNewPackageId: number = 1;
  canSave: boolean = true;

  constructor(private printNodeOrderService: PrintNodeOrderService, private addressValidationService: AddressValidationService, private packageService: PackageService, public packageTypeService: PackageTypeService, private formBuilder: FormBuilder, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<ChangePackagesComponent>) {
    this.enterAnAlternativeAddress = false;
    this.mobileQueryMatches = data.mobileQueryMatches;
    if ( data.printNodeOrder ) {
      this.printNodeOrder = data.printNodeOrder;
      if ( this.printNodeOrder.printNodeOrderItems && this.printNodeOrder.printNodeOrderItems.length > 0 ) {
        this.printNodeOrderItemInSets = {};
        for ( const printNodeOrderItem of this.printNodeOrder.printNodeOrderItems ) {
          if ( !this.printNodeOrderItemInSets['' + printNodeOrderItem.manufacturingset_id ] ) {
            this.printNodeOrderItemInSets['' + printNodeOrderItem.manufacturingset_id ] = [];
          }
          this.printNodeOrderItemInSets['' + printNodeOrderItem.manufacturingset_id ].push(printNodeOrderItem);
        }
      }
      if ( this.printNodeOrder.packages ) {
        for ( const pkg of this.printNodeOrder.packages ) {
          this.packages['' + pkg.package_ID] = pkg;
          this.packagesArray.push(pkg);
          for (const orderItemToPackage of pkg.orderItemToPackages) {
            if (orderItemToPackage.orderItem) {
              for (const printNodeOrderItem of orderItemToPackage.orderItem.printNodeOrderItems) {
                printNodeOrderItem['quantityInPackages'] = printNodeOrderItem.quantity;
                const key = printNodeOrderItem.ID + '-' + pkg.package_ID;
                this.orderItemToPackages[key] = orderItemToPackage;
                this.printNodeOrderItems[key] = printNodeOrderItem;
              }
            }
          }
          this.computeThicknessByTrimSize(pkg);
        }
      }
    }
  }

  public ngOnInit(): void {
    this.enterAnAlternativeAddress = false;
    // this.form = this.formBuilder.group({
    //   printnode_ID: [this.printnode_ID, [Validators.required]],
    //   inventoryManagementType: [this.inventoryManagementType, [Validators.required]],
    //   reorderQuantity: [this.reorderQuantity, [Validators.required]],
    //   reorderLevel: [this.reorderLevel, [Validators.required]]
    // });

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  getData(): void {
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  // From addressIsue
  validateAddress (orderDeliverTo: OrderDeliverTo | ValidationAddress) {
    const address = new ValidationAddress(orderDeliverTo);
    this.addressValidationService.validate(address).subscribe( addressValidationResult => {
      this.addressValidationResult = addressValidationResult;
    });
  }

  setEnterAnAlternativeAddress() {
    this.alternativeAddress = new ValidationAddress( this.printNodeOrder );
    this.enterAnAlternativeAddress = true;
  }
  setCancelAlternativeAddress () {
    this.enterAnAlternativeAddress = false;
  }


  useThisAddress (address: ValidationAddress) {
    this.printNodeOrder.address1 = address.address1;
    this.printNodeOrder.address2 = address.address2;
    this.printNodeOrder.address3 = address.address3;
    this.printNodeOrder.address4 = address.address4;
    this.printNodeOrder.city = address.city;
    this.printNodeOrder.state = address.state;
    this.printNodeOrder.postalcode = address.postalcode;
    this.printNodeOrder.country = address.country;
    // this.printNodeOrder.addressValidationStatus = 'VALID';
    this.printNodeOrderService.changeAddress(this.printNodeOrder).subscribe( printNodeOrder => {
      console.log("Saving a validated OrderDeliverTo");
      this.markAsDirty(this.form);
      this.dialogRef.close();
    });
  }

  // deleteFromOrder (orderDeliverTo: OrderDeliverTo) {
  //   console.log("Make this work");
  //   this.printNodeOrder.addressValidationStatus = 'VALID';
  //   this.printNodeOrderService.deleteOrderDeliverTo(this.printNodeOrder).subscribe( odt => {
  //     // TODO: Update the order weight etc.. and delete the order if there are no other OrderDeliverTos in the Order -- make sure to clean up any PrintNodeOrders if the address issue came about after the PrintNodeOrder was created
  //     console.log("Deleting an OrderDeliverTo from an order");
  //   });
  // }

  // processLater (orderDeliverTo: OrderDeliverTo) {
  //   orderDeliverTo.addressValidationStatus = 'INVALID';
  //   this.printNodeOrderService.updateOrderDeliverTo(this.printNodeOrder).subscribe( odt => {
  //     console.log("Saving a validated OrderDeliverTo");
  //   });
  // }

  useAddressAsShownInAlternativeAddress (printNodeOrder: PrintNodeOrder, alternativeAddress: ValidationAddress) {
    printNodeOrder.address1 = alternativeAddress.address1;
    printNodeOrder.address2 = alternativeAddress.address2;
    printNodeOrder.city = alternativeAddress.city;
    printNodeOrder.state = alternativeAddress.state;
    printNodeOrder.country = alternativeAddress.country;
    printNodeOrder.postalcode = alternativeAddress.postalcode;
    this.printNodeOrderService.changeAddress(this.printNodeOrder).subscribe( changedPrintNodeOrder => {
      console.log("Saving a validated Change Address in PrintNodeOrder");
    });
    this.markAsDirty(this.form);
    this.dialogRef.close();
  }

  addPackage() {
    const pkg = new Package(undefined);
    if (this.packagesArray && this.packagesArray.length > 0) {
      const existingPackage = this.packagesArray[0];
      pkg.printNodeOrder = this.printNodeOrder;
      pkg.servicelevel = this.printNodeOrder.servicelevel;
      pkg.carrier = this.printNodeOrder.carrier;
      pkg.printNodeOrder_ID = this.printNodeOrder.ID;
      pkg.printnode_ID = this.printNodeOrder.printnode_ID;
      pkg.packageType_ID = existingPackage.packageType_ID;
      pkg.package_ID = this.nextNewPackageId;
      this.nextNewPackageId++;
      pkg.weight = 0;
    } else {
      pkg.printNodeOrder = this.printNodeOrder;
      pkg.servicelevel = this.printNodeOrder.servicelevel;
      pkg.carrier = this.printNodeOrder.carrier;
      pkg.printNodeOrder_ID = this.printNodeOrder.ID;
      pkg.printnode_ID = this.printNodeOrder.printnode_ID;
      pkg.packageType_ID = 5;
      pkg.package_ID = 0;
      pkg.weight = 0;
    }
    this.packagesArray.push(pkg);
    this.addedPackages.push(pkg);
  }

  deletePackage(pkg: Package) {
    // Make sure that all of the items are removed from it and that the shipping is refunded somehow
    this.deletedPackages.push({
      'packageId': pkg.package_ID
    });
  }

  move(printNodeOrderItem: PrintNodeOrderItem, fromPackage: Package, toPackage: Package, quantity: number) {
    // const key = printNodeOrderItem.ID + '-' + pkg.package_ID;
    // this.orderItemToPackages[key] = orderItemToPackage;
    // this.printNodeOrderItems[key] = printNodeOrderItem;
  }

  changePrintNodeOrderCarrierAndServiceLevel(printNodeOrder: PrintNodeOrder, carrier: string, serviceLevel: string) {
    for ( const pkg of printNodeOrder.packages ) {
      this.changePackageCarrierAndServiceLevel(pkg, carrier, serviceLevel);
    }
  }

  changePackageType(pkg: Package, packageType: PackageType) {
    let originalPackageType = pkg.packageType_ID;

    // Look to see if there is already a packageType change for this package
    for ( let i = 0; i < this.packageTypeChanges.length; i++) {
      const packageTypeChange = this.packageTypeChanges[i];
      if ( packageTypeChange.packageId === pkg.package_ID ) {
        originalPackageType = packageTypeChange.originalPackageType;
        this.packageTypeChanges.splice(i, 1);
      }
    }

    // If setting back to the original carrier and service level then don't send a change event to the server
    if ( packageType.ID !== originalPackageType ) {
      this.carrierServiceLevelChanges.push({
        'packageId': pkg.package_ID,
        'packageType': packageType.ID,
        'originalPackageType': originalPackageType,
      });
    }
    pkg.packageType_ID = packageType.ID;
  }

  changePackageCarrierAndServiceLevelCombined(pkg: Package, carrierServiceLevel: string) {
    const index = carrierServiceLevel.indexOf("_");
    if ( index > 0 ) {
      const carrier = carrierServiceLevel.substring(0, index);
      const serviceLevel =  carrierServiceLevel.substring(index + 1);
      this.changePackageCarrierAndServiceLevel(pkg, carrier, serviceLevel);
    }
  }

  changePackageCarrierAndServiceLevel(pkg: Package, carrier: string, serviceLevel: string) {
    let originalCarrier = pkg.carrier;
    let originalServiceLevel = pkg.servicelevel;

    // Look to see if there is already a carrier and service level change for this package
    for ( let i = 0; i < this.carrierServiceLevelChanges.length; i++) {
      const carrierServiceLevelChange = this.carrierServiceLevelChanges[i];
      if ( carrierServiceLevelChange.packageId === pkg.package_ID ) {
        originalCarrier = carrierServiceLevelChange.originalCarrier;
        originalServiceLevel = carrierServiceLevelChange.originalServiceLevel;
        this.carrierServiceLevelChanges.splice(i, 1);
      }
    }

    // If setting back to the original carrier and service level then don't send a change event to the server
    if ( carrier !== originalCarrier && serviceLevel !== originalServiceLevel ) {
      this.carrierServiceLevelChanges.push({
        'packageId': pkg.package_ID,
        'carrier': carrier,
        'serviceLevel': serviceLevel,
        'originalCarrier': originalCarrier,
        'originalServiceLevel': originalServiceLevel
      });
    }
    pkg.carrier = carrier;
    pkg.servicelevel = serviceLevel;
  }

  saveChanges() {
    // Save changes
    const packageChanges = {
      'addedPackages': this.addedPackages,
      'deletedPackages': this.deletedPackages,
      'carrierServiceLevelChanges': this.carrierServiceLevelChanges,
      'packageTypeChanges': this.packageTypeChanges,
      'orderItemToPackageChanges': this.orderItemToPackageChanges,
      'orderItemToPackageAdditions': this.orderItemToPackageAdditions
    };
    this.printNodeOrderService.changePackages(packageChanges).subscribe( response => {
      console.log(`Split PrintNodeOrder ${packageChanges}`);
    });

  }

  computeThicknessByTrimSize(pkg: Package) {
    let weight: number = 0;
    let thickness: number = 0;
    const thicknessByTrimSize = {};

    if ( pkg ) {
      for ( const orderItemToPackage of pkg.orderItemToPackages ) {
        if ( orderItemToPackage.orderItem ) {
          for ( const printNodeOrderItem of orderItemToPackage.orderItem.printNodeOrderItems ) {
            const key = printNodeOrderItem.ID + '-' + pkg.package_ID;
            const trimSize = ( Math.round(printNodeOrderItem.book.body.width / 7.2 ) / 10 ) + 'X' + ( Math.round(printNodeOrderItem.book.body.height / 7.2 ) / 10 );
            if ( !thicknessByTrimSize[trimSize] ) {
              thicknessByTrimSize[trimSize] = 0;
            }
            thicknessByTrimSize[trimSize] += this.orderItemToPackages[printNodeOrderItem.ID + '-' + pkg.package_ID].quantity * printNodeOrderItem.book.body.spine / 72;
            if ( orderItemToPackage.orderItem ) {
              weight += orderItemToPackage.quantity * printNodeOrderItem.book.weight;
              thickness += orderItemToPackage.quantity * printNodeOrderItem.book.body.spine / 72;
            }
          }
        }
      }
      pkg.weight = weight;
      pkg['thickness'] = thickness;
      pkg['thicknessByTrimSize'] = thicknessByTrimSize;
    }
  }

  changeQuantity(orderItemToPackage: OrderItemToPackage, printNodeOrderItem: PrintNodeOrderItem, quantity: number, setOtherPackagesToZero: boolean = false) {
    orderItemToPackage.quantity += quantity;
    const pkg = this.packages[ '' + orderItemToPackage.package_ID ];

    if ( pkg ) {
      this.computeThicknessByTrimSize(pkg);
    }

    // Compute PrintNodeOrderItem
    let quantityInPackages = 0;
    for ( const pkgX of this.packagesArray ) {
      if ( this.orderItemToPackages[printNodeOrderItem.ID + '-' + pkgX.package_ID] ) {
        if ( setOtherPackagesToZero ) {
          if ( pkgX.package_ID !== pkg.package_ID ) {
            if ( this.orderItemToPackages[printNodeOrderItem.ID + '-' + pkgX.package_ID].quantity > 0 ) {
              this.changeQuantity(this.orderItemToPackages[printNodeOrderItem.ID + '-' + pkgX.package_ID], printNodeOrderItem, 0, false);
            }
          }
        } else {
          quantityInPackages += this.orderItemToPackages[printNodeOrderItem.ID + '-' + pkgX.package_ID].quantity;
        }
      }
    }
    if ( setOtherPackagesToZero ) {
      printNodeOrderItem['quantityInPackages'] = printNodeOrderItem.quantity;
    } else {
      printNodeOrderItem['quantityInPackages'] = quantityInPackages;
    }

    if ( this.orderItemToPackageChanges.findIndex(item => item.getId() === orderItemToPackage.getId()) === -1) {
      this.orderItemToPackageChanges.push(orderItemToPackage);
    }
  }

  getKeys(object: any) {
    return Object.keys(object);
  }
  getSortedKeys(object: any) {
    return Object.keys(object).sort( (a, b) => {
      if ( a > b ) {
        return 1;
      } else {
        if ( a < b ) {
          return -1;
        }
      }
      return 0;
    });
  }

  getNumericKeys(object: any): number[] {
    const keys = Object.keys(object);
    const numericKeys: number[] = [];
    keys.forEach( key => {
      numericKeys.push(Number(key));
    });
    return numericKeys;
  }

  manuallyMarkPackageShipped(pkg: Package, shippingStatus: string) {
    pkg.shippingStatus = shippingStatus;
    // this.packageService.recordTrackingNumberShipped(pkg).subscribe( changedPackage => {
    //   console.log(`Shipping Status Changed`);
    // });
  }

  addOrderItemToPackageChangeQuantity(pkg: Package, printNodeOrderItem: PrintNodeOrderItem, quantity, setOtherPackagesToZero: boolean = false) {
    if ( !this.orderItemToPackages[printNodeOrderItem.ID + '-' + pkg.package_ID] ) {
      const orderItemToPackage = new OrderItemToPackage(undefined);
      orderItemToPackage.orderitem_ID = printNodeOrderItem.orderitem_ID;
      orderItemToPackage.printnode_ID = pkg.printnode_ID;
      orderItemToPackage.package_ID = pkg.package_ID;
      orderItemToPackage.quantity = 0;
      // orderItemToPackage.orderItem;
      this.orderItemToPackages[printNodeOrderItem.ID + '-' + pkg.package_ID] = orderItemToPackage;
      this.orderItemToPackageAdditions.push(orderItemToPackage);
      this.printNodeOrderItems[printNodeOrderItem.ID + '-' + pkg.package_ID] = printNodeOrderItem;
    }
    this.changeQuantity(this.orderItemToPackages[printNodeOrderItem.ID + '-' + pkg.package_ID], printNodeOrderItem, quantity, setOtherPackagesToZero);
  }
}
