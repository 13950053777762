import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import {Project} from "../../../model/editor/Project";
import {EditorProjectService} from "../../service-data/editor/editor-project.service";
import {Chapter} from "../../../model/editor/Chapter";
import {LanguagePipe} from "../../pipe/language.pipe";
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {ThemeService} from "../../service-data/theme.service";
import {Interior} from "../../../model/editor/Interior";
import {InteriorTheme} from "../../../model/editor/interior-theme/InteriorTheme";
import {environment} from "../../../environments/environment";
import {Subject} from "rxjs";

@Component({
  selector: 'app-interior-editor',
  templateUrl: './interior-editor.component.html',
  styleUrls: ['./interior-editor.component.css']
})
export class InteriorEditorComponent implements OnDestroy, OnInit, LoggedInCallback {
  // @ViewChild('mainSideNav') mainSideNav: MatSidenav;
  // @ViewChild('middleSideNav') middleSideNav: MatSidenav;
  @ViewChild('standard') standard;
  @ViewChild('toc') toc;
  @ViewChild('title') title;
  @ViewChild('copyright') copyright;
  showAddChaptersOptions: boolean = false;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  project: Project;
  interior: Interior;

  typeOfChapterToAdd: string = 'standard';
  locale: string;

  leftWidth: string = 'leftNormalWidth';
  rightWidth: string = 'rightNormalWidth';
  sizeWidth: 0;


  activeSideArea: string = 'chapters';
  toggleAddAudioFiles: boolean = false;

  trimSizes: Array<Object> = [
    {value: '5.00inX7.00in', name: '5.00inX7.00in', height: '7.00in', width: '5.00in' },
    {value: '5.50inX8.50in', name: '5.50inX8.50in', height: '8.50in', width: '5.50in' },
    {value: '6.00inX9.00in', name: '6.00inX9.00in', height: '9.00in', width: '6.00in' }
  ];

  constructor(public router: Router, public startupService: StartupService, public themeService: ThemeService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, private projectService: EditorProjectService, private route: ActivatedRoute, @Inject(LOCALE_ID) localeId: string) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Account");
    } else {
      this.getProject();
    }
  }

  onSubmit() {

  }
  getProject() {
    // const projectId = this.route.snapshot.paramMap.get('ID');
    // this.project = this.projectService.retreiveLocalProject(projectId);
    this.project = this.startupService.project;

    if ( this.project && this.project.interior ) {
      this.interior = this.project.interior;
      if ( this.startupService.project && this.startupService.project.id === this.project.id ) {
        if ( !this.startupService.selectedChapter ) {
          this.startupService.selectedChapter = undefined;
        }
      } else {
        this.startupService.selectedChapter = undefined;
      }
      for ( const chapter of this.interior.chapters ) {
        if ( this.startupService.selectedChapter === undefined ) {
          this.startupService.selectedChapter = chapter;
        } else {
          if ( this.startupService.selectedChapter.type !==  'standard') {
            this.startupService.selectedChapter = chapter;
          }
        }
      }
      this.startupService.selectedCover = this.project.cover;

      this.startupService.setProject(this.project);
    } else {
      this.startupService.selectedChapter = undefined;
      this.startupService.selectedCover = undefined;
      this.startupService.project = undefined;
      this.interior = undefined;
      this.router.navigate(['/home/login']);
    }
  }

  addChapter(type: string) {

    const chapter = new Chapter( undefined );
    let defaultChapterContent = '<h2>Your Chapter title Here</h2><p>Replace with your content.</p>';
    switch ( type ) {
      case 'standard': {
        defaultChapterContent = this.standard.nativeElement.attributes.defaultchaptercontent.value;
        chapter.title = this.standard.nativeElement.attributes.defaultchaptertitle.value;
        chapter.type = type;
        break;
      }
      case 'title': {
        defaultChapterContent = this.title.nativeElement.attributes.defaultchaptercontent.value;
        chapter.title = this.title.nativeElement.attributes.defaultchaptertitle.value;
        chapter.type = type;
        break;
      }
      case 'toc': {
        defaultChapterContent = this.toc.nativeElement.attributes.defaultchaptercontent.value;
        chapter.title = this.toc.nativeElement.attributes.defaultchaptertitle.value;
        chapter.type = type;
        break;
      }
      case 'copyright': {
        defaultChapterContent = this.copyright.nativeElement.attributes.defaultchaptercontent.value;
        chapter.title = this.copyright.nativeElement.attributes.defaultchaptertitle.value;
        chapter.type = type;
        break;
      }
      default: {
        defaultChapterContent = this.standard.nativeElement.attributes.defaultchaptercontent.value;
        chapter.title = this.standard.nativeElement.attributes.defaultchaptertitle.value;
        chapter.type = 'standard';
      }
    }
    this.project.interior.chapters.push(chapter);
    this.projectService.addOrUpdateChapter(this.project, chapter, defaultChapterContent).then( _ => {
      this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
      // this.projectService.saveProjectImmediately(this.project).then( project => {
      // });
    });
  }

  onSelectChapter(chapter: Chapter): void {
    this.startupService.selectedChapter = chapter;
  }

  getSideClasses(): string {
    let classes = '';
    if ( this.startupService.showStructure ) {
      classes += this.leftWidth;
    } else {
      classes += 'leftHidden';
    }
    if ( this.startupService.showCoverTools || this.startupService.showInteriorTools) {
      classes += ' ' + this.rightWidth;
    } else {
      classes += ' rightHidden';
    }
    return classes;
  }

  onResize(event: any) {
    console.log("onresize " + event);
    const w = event.target.innerWidth;
    this.sizeWidth = w;
  }

  getConfig(): any {
    const config = {
      uiColor: '#ffffff',
      resize_enabled: false,
      height: '100%',
      language: this.locale,
      fillEmptyBlocks: false,
      ignoreEmptyParagraph: true,
      // autoParagraph: false,
      // allowedContent: 'strong, em, small, u, s, i, b, p[class], blockquote[class], ul, ol, li, br, hr, h1, h2, h3, h4, h5, h6, table, th, tr, td, tbody, thead, tfoot',
      extraAllowedContent: ['strong', 'em', 'small', 'u', 's', 'i', 'b', 'p[class]', 'blockquote[class,ID]', 'ul', 'ol', 'li', 'br', 'hr', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'table', 'th', 'tr', 'td', 'tbody', 'thead', 'tfoot'],
      // allowedContent: 'span[!start_time,!end_time,!confidence], span[class], strong, em, small, u, s, i, b, p[class], blockquote[class], ul, ol, li, br, hr, h1, h2, h3, h4, h5, h6, table, th, tr, td, tbody, thead, tfoot',
      // extraAllowedContent: ['span[start_time,end_time,confidence,class]', 'strong', 'em', 'small', 'u', 's', 'i', 'b', 'p[class]', 'blockquote[class,ID]', 'ul', 'ol', 'li', 'br', 'hr', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'table', 'th', 'tr', 'td', 'tbody', 'thead', 'tfoot'],
      stylesSet: [
        { name : LanguagePipe.get('Heading 1', 'compressSpaces'), element : 'h1' },
        { name : LanguagePipe.get('Heading 2', 'compressSpaces'), element : 'h2' },
        { name : LanguagePipe.get('Heading 3', 'compressSpaces'), element : 'h3' },
        // { name : LanguagePipe.get('Introduction', 'compressSpaces'), element : 'p', attributes: { 'class': 'introduction'} },
        { name : LanguagePipe.get('Paragraph', 'compressSpaces'), element : 'p' },
        { name : LanguagePipe.get('Scripture', 'compressSpaces'), element : 'p', attributes: { 'class': 'scripture'} },
        { name : LanguagePipe.get('Question', 'compressSpaces'), element : 'p', attributes: { 'class': 'question'} },
        // { name : LanguagePipe.get('Poetry', 'compressSpaces'), element : 'p' },
        { name : LanguagePipe.get('Heading 4', 'compressSpaces'), element : 'h4' },
        { name : LanguagePipe.get('Heading 5', 'compressSpaces'), element : 'h5' },
        // { name : LanguagePipe.get('Section', 'compressSpaces'), element : 'div' },
        { name : LanguagePipe.get('Strong', 'compressSpaces'), element : 'strong' },
        { name : LanguagePipe.get('Emphasis', 'compressSpaces'), element : 'em' }
        // { name : LanguagePipe.get('Scripture Inline', 'compressSpaces'), element : 'span', attributes: { 'class': 'scripture'} }
      ],
      removeButtons: 'Underline,Subscript,Superscript,SpecialChar,Source',
      toolbar :
        [
          { name: 'clipboard',   items : [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
          { name: 'editing',     items : [ 'Find', 'Replace', '-', 'SelectAll', '-', 'SpellChecker', 'Scayt' ] },
          { name: 'basicstyles', items : [ 'Bold', 'Italic', 'Underline', 'Subscript', 'Superscript', '-', 'RemoveFormat' ] },
          { name: 'paragraph',   items : [ 'NumberedList', 'BulletedList' ] },
//          { name: 'paragraph',   items : [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent' ] },
          { name: 'insert',      items : [ 'HorizontalRule', 'PageBreak' ] },
//        { name: 'insert',      items : [ 'Table', 'HorizontalRule', 'PageBreak' ] },
          { name: 'styles',      items : [ 'Styles' ] },
          { name: 'tools',       items : [ 'Maximize', 'ShowBlocks' ] }
        ]
    };
    // return JSON.stringify(config);
    return config;
  }

  setInteriorThemeId(interiorTheme: InteriorTheme) {
    this.project.interior.interiorTheme.name = interiorTheme.name;
    this.project.interior.interiorTheme.id = interiorTheme.id;
    this.projectService.getPublicCSS(environment.publicBucket, interiorTheme.id + 'css.css').then( cssString => {
      this.projectService.saveInteriorTheme(this.startupService.project, cssString).then( _ => {
        this.startupService.project.interior.interiorTheme = new InteriorTheme(interiorTheme);
        this.saveProject();
      });
    });
  }

  setTrimSize(trimSize: any) {
    const project: Project = <Project> this.startupService.getProject();
    project.trimSize = trimSize.value;
    project.width = trimSize.width;
    project.height = trimSize.height;
    this.startupService.trimSizeChange++;
    this.saveProject();
  }

  saveProject() {
    this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
    // this.projectService.saveProject(this.startupService.project);
  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }
}
