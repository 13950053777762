import {ColorPaletteGroup} from "./ColorPaletteGroup";

export class ColorPalette {
  public name: string;
  type: string = '';
  primary: ColorPaletteGroup;
  secondary: ColorPaletteGroup;
  tertiary: ColorPaletteGroup;
  quaternary: ColorPaletteGroup;
  quinary: ColorPaletteGroup;
  senary: ColorPaletteGroup;

  constructor( obj ) {
    if ( obj ) {
      this.name = obj.name;
      this.type = obj.type;
      if ( !this.type || this.type.length === 0) {
        this.type = 'Manual';
      }
      this.primary = new ColorPaletteGroup(obj.primary);
      this.secondary = new ColorPaletteGroup(obj.secondary);
      if ( obj.tertiary ) {
        this.tertiary = new ColorPaletteGroup(obj.tertiary);
      } else {
        this.tertiary = new ColorPaletteGroup( undefined );
        this.tertiary.normal.color = '#333333';
        this.tertiary.light.color = '#666666';
        this.tertiary.dark.color = '#000000';
      }
      if ( obj.quaternary ) {
        this.quaternary = new ColorPaletteGroup(obj.quaternary);
      } else {
        this.quaternary = new ColorPaletteGroup( undefined );
        this.quaternary.normal.color = '#333333';
        this.quaternary.light.color = '#666666';
        this.quaternary.dark.color = '#000000';
      }
      if ( obj.quinary ) {
        this.quinary = new ColorPaletteGroup(obj.quinary);
      } else {
        this.quinary = new ColorPaletteGroup( undefined );
        this.quinary.normal.color = '#333333';
        this.quinary.light.color = '#666666';
        this.quinary.dark.color = '#000000';
      }

      if ( obj.senary ) {
        this.senary = new ColorPaletteGroup(obj.senary);
      } else {
        this.senary = new ColorPaletteGroup( undefined );
        this.senary.normal.color = '#333333';
        this.senary.light.color = '#666666';
        this.senary.dark.color = '#000000';
      }
    } else {
      this.name = '';
      this.primary = new ColorPaletteGroup( undefined );
      this.primary.normal.color = '#aaaaaa';
      this.primary.light.color = '#bbbbbb';
      this.primary.dark.color = '#888888';

      this.secondary = new ColorPaletteGroup( undefined );
      this.secondary.normal.color = '#333333';
      this.secondary.light.color = '#666666';
      this.secondary.dark.color = '#000000';

      this.tertiary = new ColorPaletteGroup( undefined );
      this.tertiary.normal.color = '#333333';
      this.tertiary.light.color = '#666666';
      this.tertiary.dark.color = '#000000';

      this.quaternary = new ColorPaletteGroup( undefined );
      this.quaternary.normal.color = '#333333';
      this.quaternary.light.color = '#666666';
      this.quaternary.dark.color = '#000000';

      this.quinary = new ColorPaletteGroup( undefined );
      this.quinary.normal.color = '#333333';
      this.quinary.light.color = '#666666';
      this.quinary.dark.color = '#000000';

      this.senary = new ColorPaletteGroup( undefined );
      this.senary.normal.color = '#333333';
      this.senary.light.color = '#666666';
      this.senary.dark.color = '#000000';
    }
  }

  // Gets a value as a simple string but if inside <> it looks up the value as a variable
  getColorFromKey(simpleValue: string, defaultKey: string): string {
    if ( !simpleValue ) {
      simpleValue = '<' + defaultKey + '>';
    }
    if ( simpleValue.startsWith('<') && simpleValue.endsWith('>') ) {
      const key = simpleValue.substring(1, simpleValue.length - 1 );
      switch ( key ) {
        case 'primary.normal':
        {
          return this.primary.normal.color;
        }
        case 'primary.dark':
        {
          return this.primary.dark.color;
        }
        case 'primary.light':
        {
          return this.primary.light.color;
        }
        case 'secondary.normal':
        {
          return this.secondary.normal.color;
        }
        case 'secondary.dark':
        {
          return this.secondary.dark.color;
        }
        case 'secondary.light':
        {
          return this.secondary.light.color;
        }
        case 'tertiary.normal':
        {
          return this.tertiary.normal.color;
        }
        case 'tertiary.dark':
        {
          return this.tertiary.dark.color;
        }
        case 'tertiary.light':
        {
          return this.tertiary.light.color;
        }
        case 'quaternary.normal':
        {
          return this.quaternary.normal.color;
        }
        case 'quaternary.dark':
        {
          return this.quaternary.dark.color;
        }
        case 'quaternary.light':
        {
          return this.quaternary.light.color;
        }
        case 'quinary.normal':
        {
          return this.quinary.normal.color;
        }
        case 'quinary.dark':
        {
          return this.quinary.dark.color;
        }
        case 'quinary.light':
        {
          return this.quinary.light.color;
        }
        case 'senary.normal':
        {
          return this.senary.normal.color;
        }
        case 'senary.dark':
        {
          return this.senary.dark.color;
        }
        case 'senary.light':
        {
          return this.senary.light.color;
        }
        case 'primaryTextColor':
        {
          return this.quinary.normal.color;
        }
        case 'secondaryTextColor':
        {
          return this.senary.normal.color;
        }
        case 'color1':
        {
          return this.primary.normal.color;
        }
        case 'color2':
        {
          return this.primary.dark.color;
        }
        case 'color3':
        {
          return this.primary.light.color;
        }
        case 'color4':
        {
          return this.secondary.normal.color;
        }
        case 'color5':
        {
          return this.secondary.dark.color;
        }
        case 'color6':
        {
          return this.secondary.light.color;
        }
        case 'color7':
        {
          return this.quinary.normal.color;
        }
        case 'color8':
        {
          return this.senary.normal.color;
        }
        default:
        {
          console.error("Returning default from ColorPalette.getColorFromKey:" + key);
          return 'pink';
        }
      }
    }
    return simpleValue;
  }
}
