<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Manage Facility Inventory Products">Manage Facility Inventory Products</h2>
  </div>
  <div>
    <div *ngIf="product.facilityInventoryProducts && product.facilityInventoryProducts.length > 0">
      <ng-container *ngIf="product.facilityInventoryProducts && product.facilityInventoryProducts.length > 0">
        <ng-container *ngFor="let facilityInventoryProduct of product.facilityInventoryProducts">
          <app-facility-inventory-product style="display: block;" [facilityInventoryProduct]="facilityInventoryProduct" style="max-width: 800px" [showCharts]="false" [edit]="true" [products]="products" [product]="product"></app-facility-inventory-product>
        </ng-container>
      </ng-container>

<!--      <table>-->
<!--        <tr>-->
<!--          <th i18n="@@Id">Id</th>-->
<!--          <th i18n="@@Facility">Facility</th>-->
<!--          <th i18n="@@On Hand">On Hand</th>-->
<!--          <th i18n="@@On Order">On Order</th>-->
<!--          <th i18n="@@Committed">Committed</th>-->
<!--          <th i18n="@@Net">Net</th>-->
<!--        </tr>-->
<!--        <div *ngFor="let facilityInventoryProduct of product.facilityInventoryProducts">-->
<!--            <tr>-->
<!--              <th>{{facilityInventoryProduct.ID}}</th>-->
<!--              <th>{{facilityInventoryProduct.printnode_ID}}</th>-->
<!--              <th>{{facilityInventoryProduct.quantityOnHand}}</th>-->
<!--              <th>{{facilityInventoryProduct.quantityOnOrder}}</th>-->
<!--              <th>{{facilityInventoryProduct.quantityCommited}}</th>-->
<!--              <th>{{facilityInventoryProduct.quantityOnHand - facilityInventoryProduct.quantityCommited + facilityInventoryProduct.quantityOnOrder}}</th>-->
<!--            </tr>-->
<!--        </div>-->
<!--      </table>-->
<!--      <div *ngIf="startupService.selectedRole === 'organization'">-->
    </div>
    <div>
      <button mat-fab color="primary" (click)="openAddDialog()" i18n-matTooltip="@@Add Facility Inventory Product" matTooltip="Add Facility Inventory Product"><i class="material-icons">add</i></button>
    </div>
    <form [formGroup]="addForm" (change)="formChanged()">
<!--      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">-->
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <mat-select  placeholder="Facility" i18n-placeholder="@@Facility" formControlName="printnode_ID" required>-->
<!--                <mat-option value="1" i18n='@@Phoneix, AZ'>Phoneix, AZ</mat-option>-->
<!--                <mat-option value="5" i18n='@@Houston, TX' selected='selected'>Houston, TX</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <mat-select  placeholder="State" i18n-placeholder="@@State" formControlName="state" required>-->
<!--                <mat-option value="ACTIVE" i18n='@@Active' selected='selected'>Active</mat-option>-->
<!--                <mat-option value="INACTIVE" i18n='@@Inactive'>Inactive</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <mat-select  placeholder="Inventory Management Type" i18n-placeholder="@@Inventory Management Type" formControlName="inventoryManagementType" required>-->
<!--                <mat-option value="CUSTOMER" i18n='@@Customer'>Customer</mat-option>-->
<!--                <mat-option value="LEVEL" i18n='@@Level'>Level</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <input matInput i18n-placeholder="@@Sequence" placeholder="Sequence" id="sequence" required type="number" min="0"  max="1500" formControlName="sequence">-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <input matInput i18n-placeholder="@@location" placeholder="Location" id="location" required type="text" formControlName="location">-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <input matInput i18n-placeholder="@@Shelf" placeholder="Shelf" id="shelf" required type="text" formControlName="shelf">-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <input matInput i18n-placeholder="@@Reorder Quantity" placeholder="Reorder Quantity" id="reorderQuantity" required type="number" min="0"  max="1500" formControlName="reorderQuantity">-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <input matInput i18n-placeholder="@@Reorder Level" placeholder="Reorder Level" id="reorderLevel" required type="number" min="0"  max="1500" formControlName="reorderLevel">-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <input matInput i18n-placeholder="@@Case Quantity" placeholder="Case Quantity" id="caseQuantity" required type="number" min="0"  max="1500" formControlName="caseQuantity">-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
<!--      </mat-grid-list>-->
      <div class="btn-sec">
<!--        <button mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="addForm.invalid" (click)="onAdd()" i18n="@@Add Inventory Product">Add Inventory Product</button>-->
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Close">Close</button>
      </div>
    </form>
  </div>
</div>
