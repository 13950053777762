import {OrderItem} from "./OrderItem";
import {Order} from "./Order";

export class OrderDeliverTo {
  ID: number;
  order_ID: number;
  title: string;
  firstname: string;
  lastname: string;
  company: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  state: string;
  country: string;
  carrier: string;
  servicelevel: string;
  phonecountry: string;
  phone: string;
  email: string;
  addresstype: string;
  carriertrackingid: string;
  carrierstatus: string;
  lastupdate: Date;
  shiptimestamp: Date;
  deliverytimestamp: Date;
  postalcode: string;
  packingSlipMimeType: string;
  packingSlipFormat: string;
  packingSlip: string;
  requestedPrintNodeName: string;
  inventoryType: string;
  addressValidationStatus: string;
  addressValidationMessage: string;
  invoice_ID: number;
  replenishmentFacility_ID: number;

  orderItems: OrderItem[];

  order: Order;

  constructor( obj ) {
    this.order_ID = 0;
    this.title = '';
    this.firstname = '';
    this.lastname = '';
    this.company = '';
    this.address1 = '';
    this.address2 = '';
    this.address3 = '';
    this.address4 = '';
    this.city = '';
    this.state = '';
    this.country = 'US';
    this.carrier = '';
    this.servicelevel = '';
    this.phonecountry = 'US';
    this.phone = '';
    this.email = '';
    this.addresstype = '';
    this.carriertrackingid = '';
    this.carrierstatus = '';
    this.lastupdate = new Date();
    this.shiptimestamp = new Date(0);
    this.deliverytimestamp = new Date(0);
    this.postalcode = '';
    this.packingSlipMimeType = '';
    this.packingSlipFormat = '';
    this.packingSlip = 'string';
    this.requestedPrintNodeName = '';
    this.replenishmentFacility_ID = 0;
    this.orderItems = [];

    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        this[prop] = obj[prop];
      }
      if ( obj.orderItems ) {
        this.orderItems = [];
        for ( const orderItem of obj.orderItems ) {
          this.orderItems.push( new OrderItem( orderItem ) );
        }
      }
      if ( obj.order ) {
        this.order = new Order( obj.order );
      }

    }

  }
}
