<div class="accountSection" *ngIf="recurring">
  <h2  i18n="@@Payment and billing">Payment and billing</h2>
  <p *ngIf="subscription.getRecurringStatus() === 'INITIAL'"><button mat-button color="accent" (click)="showCreditCardForm = !showCreditCardForm" i18n="@@Setup your subscription payment information">Setup your subscription payment information</button></p>
  <mat-card appearance="outlined" class="paymentOption">
    <mat-card-header>
      <mat-card-title i18n="@@Payment methods">Payment methods</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngIf="subscription.getRecurringStatus() !== 'INITIAL' && recurring.cardNumber">
        <p><b>--- --- --- {{recurring.cardNumber}}</b></p>
        <p><ng-container i18n="@@Expires">Expires</ng-container> {{recurring.expMonth}}/{{recurring.expYear}} - <ng-container i18n="@@Added on">Added on</ng-container> {{recurring.addDate}}</p>
      </ng-container>
      <div class="creditCardForm" *ngIf="showCreditCardForm">
        <div class="card-container"></div>
        <form class="creditCard"  card container=".card-container" card-width="300" [messages]="messages" [placeholders]="placeholders" [masks]="masks" formatting="true" debug="true">
          <table class="creditCardFullWidth" cellspacing="0">
            <tr>
              <td>
                <mat-form-field class="creditCardFullWidth" focused="true">
                  <mat-label i18n="@@Credit Card Number">Credit Card Number</mat-label>
                  <input matInput placeholder="**** **** **** ****"  type="text" name="number" [(ngModel)]="recurring.cardNumberWithSpaces" (change)="verifyPaymentInfo()" required card-number/>
                </mat-form-field>
                <!--<mat-hint align="end">{{validCardNumber() ? 'Valid' : 'Invalid' }}</mat-hint>-->
              </td>
              <td>
                <mat-form-field class="creditCardFullWidth">
                  <mat-label i18n="@@First Name">First Name</mat-label>
                  <input matInput i18n-placeholder="@@First Name" placeholder="First Name" type="text" name="first-name" [(ngModel)]="recurring.firstNamePayment" (change)="verifyPaymentInfo()" required card-name/>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="creditCardFullWidth">
                  <mat-label i18n="@@Last Name">Last Name</mat-label>
                  <input matInput i18n-placeholder="@@Last Name" placeholder="Last name" type="text" name="last-name" [(ngModel)]="recurring.lastNamePayment" (change)="verifyPaymentInfo()" required card-name/>
                </mat-form-field>
              </td>
            </tr>
          </table>
          <table class="creditCardFullWidth" cellspacing="0">
            <tr>
              <td>
                <mat-form-field class="creditCardFullWidth">
                  <mat-label i18n="@@Expiration">Expiration</mat-label>
                  <input matInput placeholder="mm/yy" type="text" name="expiry" [(ngModel)]="recurring.expMonthYear" (change)="verifyPaymentInfo()" required card-expiry/>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="creditCardFullWidth">
                  <mat-label i18n="@@CCV">CCV</mat-label>
                  <input matInput placeholder="****" type="text" name="cvc" [(ngModel)]="recurring.csc" (change)="verifyPaymentInfo()" required card-cvc/>
                </mat-form-field>
              </td>
            </tr>
          </table>
          <table class="creditCardFullWidth" cellspacing="0">
            <tr>
              <td>
                <mat-form-field class="creditCardFullWidth">
                  <mat-label i18n="@@Address 1">Address 1</mat-label>
                  <textarea name="address1" [(ngModel)]="recurring.address1Payment" matInput i18n-placeholder="@@Address" placeholder="Address"></textarea>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="creditCardFullWidth">
                  <mat-label i18n="@@Address 2">Address 2</mat-label>
                  <textarea name="address2" [(ngModel)]="recurring.address2Payment" matInput i18n-placeholder="@@Address 2" placeholder="Address 2"></textarea>
                </mat-form-field>
              </td>
            </tr>
          </table>

          <table class="creditCardFullWidth" cellspacing="0"><tr>
            <td>
              <mat-form-field class="creditCardFullWidth">
                <mat-label i18n="@@City">City</mat-label>
                <input name="city" [(ngModel)]="recurring.cityPayment" matInput  i18n-placeholder="@@City" placeholder="City">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="creditCardFullWidth">
                <input name="state" [(ngModel)]="recurring.statePayment" matInput  i18n-placeholder="@@State" placeholder="State">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <mat-select name="country" [(ngModel)]="recurring.countryPayment"  i18n-placeholder="@@Country" placeholder="Country" id="countryPayment"  (selectionChange)="verifyPaymentInfo()" autocomplete="billing country">
                  <mat-option>None</mat-option>
                  <mat-option value="AF" i18n='@@Afghanistan'>Afghanistan</mat-option>
                  <mat-option value="AL" i18n='@@Albania'>Albania</mat-option>
                  <mat-option value="DZ" i18n='@@Algeria'>Algeria</mat-option>
                  <mat-option value="AS" i18n='@@American Samoa'>American Samoa</mat-option>
                  <mat-option value="AD" i18n='@@Andorra'>Andorra</mat-option>
                  <mat-option value="AG" i18n='@@Angola'>Angola</mat-option>
                  <mat-option value="AI" i18n='@@Anguilla'>Anguilla</mat-option>
                  <mat-option value="AG" i18n='@@Antigua &amp; Barbuda'>Antigua &amp; Barbuda</mat-option>
                  <mat-option value="AR" i18n='@@Argentina'>Argentina</mat-option>
                  <mat-option value="AA" i18n='@@Armenia'>Armenia</mat-option>
                  <mat-option value="AW" i18n='@@Aruba'>Aruba</mat-option>
                  <mat-option value="AU" i18n='@@Australia'>Australia</mat-option>
                  <mat-option value="AT" i18n='@@Austria'>Austria</mat-option>
                  <mat-option value="AZ" i18n='@@Azerbaijan'>Azerbaijan</mat-option>
                  <mat-option value="BS" i18n='@@Bahamas'>Bahamas</mat-option>
                  <mat-option value="BH" i18n='@@Bahrain'>Bahrain</mat-option>
                  <mat-option value="BD" i18n='@@Bangladesh'>Bangladesh</mat-option>
                  <mat-option value="BB" i18n='@@Barbados'>Barbados</mat-option>
                  <mat-option value="BY" i18n='@@Belarus'>Belarus</mat-option>
                  <mat-option value="BE" i18n='@@Belgium'>Belgium</mat-option>
                  <mat-option value="BZ" i18n='@@Belize'>Belize</mat-option>
                  <mat-option value="BJ" i18n='@@Benin'>Benin</mat-option>
                  <mat-option value="BM" i18n='@@Bermuda'>Bermuda</mat-option>
                  <mat-option value="BT" i18n='@@Bhutan'>Bhutan</mat-option>
                  <mat-option value="BO" i18n='@@Bolivia'>Bolivia</mat-option>
                  <mat-option value="BL" i18n='@@Bonaire'>Bonaire</mat-option>
                  <mat-option value="BA" i18n='@@Bosnia &amp; Herzegovina'>Bosnia &amp; Herzegovina</mat-option>
                  <mat-option value="BW" i18n='@@Botswana'>Botswana</mat-option>
                  <mat-option value="BR" i18n='@@Brazil'>Brazil</mat-option>
                  <mat-option value="BC" i18n='@@British Indian Ocean Ter'>British Indian Ocean Ter</mat-option>
                  <mat-option value="BN" i18n='@@Brunei'>Brunei</mat-option>
                  <mat-option value="BG" i18n='@@Bulgaria'>Bulgaria</mat-option>
                  <mat-option value="BF" i18n='@@Burkina Faso'>Burkina Faso</mat-option>
                  <mat-option value="BI" i18n='@@Burundi'>Burundi</mat-option>
                  <mat-option value="KH" i18n='@@Cambodia'>Cambodia</mat-option>
                  <mat-option value="CM" i18n='@@Cameroon'>Cameroon</mat-option>
                  <mat-option value="CA" i18n='@@Canada'>Canada</mat-option>
                  <mat-option value="IC" i18n='@@Canary Islands'>Canary Islands</mat-option>
                  <mat-option value="CV" i18n='@@Cape Verde'>Cape Verde</mat-option>
                  <mat-option value="KY" i18n='@@Cayman Islands'>Cayman Islands</mat-option>
                  <mat-option value="CF" i18n='@@Central African Republic'>Central African Republic</mat-option>
                  <mat-option value="TD" i18n='@@Chad'>Chad</mat-option>
                  <mat-option value="CD" i18n='@@Channel Islands'>Channel Islands</mat-option>
                  <mat-option value="CL" i18n='@@Chile'>Chile</mat-option>
                  <mat-option value="CN" i18n='@@China'>China</mat-option>
                  <mat-option value="CI" i18n='@@Christmas Island'>Christmas Island</mat-option>
                  <mat-option value="CS" i18n='@@Cocos Island'>Cocos Island</mat-option>
                  <mat-option value="CO" i18n='@@Colombia'>Colombia</mat-option>
                  <mat-option value="CC" i18n='@@Comoros'>Comoros</mat-option>
                  <mat-option value="CG" i18n='@@Congo'>Congo</mat-option>
                  <mat-option value="CK" i18n='@@Cook Islands'>Cook Islands</mat-option>
                  <mat-option value="CR" i18n='@@Costa Rica'>Costa Rica</mat-option>
                  <mat-option value="CT" i18n='@@Cote DIvoire'>Cote D'Ivoire</mat-option>
                  <mat-option value="HR" i18n='@@Croatia'>Croatia</mat-option>
                  <mat-option value="CU" i18n='@@Cuba'>Cuba</mat-option>
                  <mat-option value="CB" i18n='@@Curacao'>Curacao</mat-option>
                  <mat-option value="CY" i18n='@@Cyprus'>Cyprus</mat-option>
                  <mat-option value="CZ" i18n='@@Czech Republic'>Czech Republic</mat-option>
                  <mat-option value="DK" i18n='@@Denmark'>Denmark</mat-option>
                  <mat-option value="DJ" i18n='@@Djibouti'>Djibouti</mat-option>
                  <mat-option value="DM" i18n='@@Dominica'>Dominica</mat-option>
                  <mat-option value="DO" i18n='@@Dominican Republic'>Dominican Republic</mat-option>
                  <mat-option value="TM" i18n='@@East Timor'>East Timor</mat-option>
                  <mat-option value="EC" i18n='@@Ecuador'>Ecuador</mat-option>
                  <mat-option value="EG" i18n='@@Egypt'>Egypt</mat-option>
                  <mat-option value="SV" i18n='@@El Salvador'>El Salvador</mat-option>
                  <mat-option value="GQ" i18n='@@Equatorial Guinea'>Equatorial Guinea</mat-option>
                  <mat-option value="ER" i18n='@@Eritrea'>Eritrea</mat-option>
                  <mat-option value="EE" i18n='@@Estonia'>Estonia</mat-option>
                  <mat-option value="ET" i18n='@@Ethiopia'>Ethiopia</mat-option>
                  <mat-option value="FA" i18n='@@Falkland Islands'>Falkland Islands</mat-option>
                  <mat-option value="FO" i18n='@@Faroe Islands'>Faroe Islands</mat-option>
                  <mat-option value="FJ" i18n='@@Fiji'>Fiji</mat-option>
                  <mat-option value="FI" i18n='@@Finland'>Finland</mat-option>
                  <mat-option value="FR" i18n='@@France'>France</mat-option>
                  <mat-option value="GF" i18n='@@French Guiana'>French Guiana</mat-option>
                  <mat-option value="PF" i18n='@@French Polynesia'>French Polynesia</mat-option>
                  <mat-option value="FS" i18n='@@French Southern Ter'>French Southern Ter</mat-option>
                  <mat-option value="GA" i18n='@@Gabon'>Gabon</mat-option>
                  <mat-option value="GM" i18n='@@Gambia'>Gambia</mat-option>
                  <mat-option value="GE" i18n='@@Georgia'>Georgia</mat-option>
                  <mat-option value="DE" i18n='@@Germany'>Germany</mat-option>
                  <mat-option value="GH" i18n='@@Ghana'>Ghana</mat-option>
                  <mat-option value="GI" i18n='@@Gibraltar'>Gibraltar</mat-option>
                  <mat-option value="GB" i18n='@@Great Britain'>Great Britain</mat-option>
                  <mat-option value="GR" i18n='@@Greece'>Greece</mat-option>
                  <mat-option value="GL" i18n='@@Greenland'>Greenland</mat-option>
                  <mat-option value="GD" i18n='@@Grenada'>Grenada</mat-option>
                  <mat-option value="GP" i18n='@@Guadeloupe'>Guadeloupe</mat-option>
                  <mat-option value="GU" i18n='@@Guam'>Guam</mat-option>
                  <mat-option value="GT" i18n='@@Guatemala'>Guatemala</mat-option>
                  <mat-option value="GN" i18n='@@Guinea'>Guinea</mat-option>
                  <mat-option value="GY" i18n='@@Guyana'>Guyana</mat-option>
                  <mat-option value="HT" i18n='@@Haiti'>Haiti</mat-option>
                  <mat-option value="HW" i18n='@@Hawaii'>Hawaii</mat-option>
                  <mat-option value="HN" i18n='@@Honduras'>Honduras</mat-option>
                  <mat-option value="HK" i18n='@@Hong Kong'>Hong Kong</mat-option>
                  <mat-option value="HU" i18n='@@Hungary'>Hungary</mat-option>
                  <mat-option value="IS" i18n='@@Iceland'>Iceland</mat-option>
                  <mat-option value="IN" i18n='@@India'>India</mat-option>
                  <mat-option value="ID" i18n='@@Indonesia'>Indonesia</mat-option>
                  <mat-option value="IR" i18n='@@Iran'>Iran</mat-option>
                  <mat-option value="IQ" i18n='@@Iraq'>Iraq</mat-option>
                  <mat-option value="IE" i18n='@@Ireland'>Ireland</mat-option>
                  <mat-option value="IM" i18n='@@Isle of Man'>Isle of Man</mat-option>
                  <mat-option value="IL" i18n='@@Israel'>Israel</mat-option>
                  <mat-option value="IT" i18n='@@Italy'>Italy</mat-option>
                  <mat-option value="JM" i18n='@@Jamaica'>Jamaica</mat-option>
                  <mat-option value="JP" i18n='@@Japan'>Japan</mat-option>
                  <mat-option value="JO" i18n='@@Jordan'>Jordan</mat-option>
                  <mat-option value="KZ" i18n='@@Kazakhstan'>Kazakhstan</mat-option>
                  <mat-option value="KE" i18n='@@Kenya'>Kenya</mat-option>
                  <mat-option value="KI" i18n='@@Kiribati'>Kiribati</mat-option>
                  <mat-option value="NK" i18n='@@Korea North'>Korea North</mat-option>
                  <mat-option value="KS" i18n='@@Korea South'>Korea South</mat-option>
                  <mat-option value="KW" i18n='@@Kuwait'>Kuwait</mat-option>
                  <mat-option value="KG" i18n='@@Kyrgyzstan'>Kyrgyzstan</mat-option>
                  <mat-option value="LA" i18n='@@Laos'>Laos</mat-option>
                  <mat-option value="LV" i18n='@@Latvia'>Latvia</mat-option>
                  <mat-option value="LB" i18n='@@Lebanon'>Lebanon</mat-option>
                  <mat-option value="LS" i18n='@@Lesotho'>Lesotho</mat-option>
                  <mat-option value="LR" i18n='@@Liberia'>Liberia</mat-option>
                  <mat-option value="LY" i18n='@@Libya'>Libya</mat-option>
                  <mat-option value="LI" i18n='@@Liechtenstein'>Liechtenstein</mat-option>
                  <mat-option value="LT" i18n='@@Lithuania'>Lithuania</mat-option>
                  <mat-option value="LU" i18n='@@Luxembourg'>Luxembourg</mat-option>
                  <mat-option value="MO" i18n='@@Macau'>Macau</mat-option>
                  <mat-option value="MK" i18n='@@Macedonia'>Macedonia</mat-option>
                  <mat-option value="MG" i18n='@@Madagascar'>Madagascar</mat-option>
                  <mat-option value="MY" i18n='@@Malaysia'>Malaysia</mat-option>
                  <mat-option value="MW" i18n='@@Malawi'>Malawi</mat-option>
                  <mat-option value="MV" i18n='@@Maldives'>Maldives</mat-option>
                  <mat-option value="ML" i18n='@@Mali'>Mali</mat-option>
                  <mat-option value="MT" i18n='@@Malta'>Malta</mat-option>
                  <mat-option value="MH" i18n='@@Marshall Islands'>Marshall Islands</mat-option>
                  <mat-option value="MQ" i18n='@@Martinique'>Martinique</mat-option>
                  <mat-option value="MR" i18n='@@Mauritania'>Mauritania</mat-option>
                  <mat-option value="MU" i18n='@@Mauritius'>Mauritius</mat-option>
                  <mat-option value="ME" i18n='@@Mayotte'>Mayotte</mat-option>
                  <mat-option value="MX" i18n='@@Mexico'>Mexico</mat-option>
                  <mat-option value="MI" i18n='@@Midway Islands'>Midway Islands</mat-option>
                  <mat-option value="MD" i18n='@@Moldova'>Moldova</mat-option>
                  <mat-option value="MC" i18n='@@Monaco'>Monaco</mat-option>
                  <mat-option value="MN" i18n='@@Mongolia'>Mongolia</mat-option>
                  <mat-option value="MS" i18n='@@Montserrat'>Montserrat</mat-option>
                  <mat-option value="MA" i18n='@@Morocco'>Morocco</mat-option>
                  <mat-option value="MZ" i18n='@@Mozambique'>Mozambique</mat-option>
                  <mat-option value="MM" i18n='@@Myanmar'>Myanmar</mat-option>
                  <mat-option value="NA" i18n='@@Nambia'>Nambia</mat-option>
                  <mat-option value="NU" i18n='@@Nauru'>Nauru</mat-option>
                  <mat-option value="NP" i18n='@@Nepal'>Nepal</mat-option>
                  <mat-option value="AN" i18n='@@Netherland Antilles'>Netherland Antilles</mat-option>
                  <mat-option value="NL" i18n='@@Netherlands (Holland, Europe)'>Netherlands (Holland, Europe)</mat-option>
                  <mat-option value="NV" i18n='@@Nevis'>Nevis</mat-option>
                  <mat-option value="NC" i18n='@@New Caledonia'>New Caledonia</mat-option>
                  <mat-option value="NZ" i18n='@@New Zealand'>New Zealand</mat-option>
                  <mat-option value="NI" i18n='@@Nicaragua'>Nicaragua</mat-option>
                  <mat-option value="NE" i18n='@@Niger'>Niger</mat-option>
                  <mat-option value="NG" i18n='@@Nigeria'>Nigeria</mat-option>
                  <mat-option value="NW" i18n='@@Niue'>Niue</mat-option>
                  <mat-option value="NF" i18n='@@Norfolk Island'>Norfolk Island</mat-option>
                  <mat-option value="NO" i18n='@@Norway'>Norway</mat-option>
                  <mat-option value="OM" i18n='@@Oman'>Oman</mat-option>
                  <mat-option value="PK" i18n='@@Pakistan'>Pakistan</mat-option>
                  <mat-option value="PW" i18n='@@Palau Island'>Palau Island</mat-option>
                  <mat-option value="PS" i18n='@@Palestine'>Palestine</mat-option>
                  <mat-option value="PA" i18n='@@Panama'>Panama</mat-option>
                  <mat-option value="PG" i18n='@@Papua New Guinea'>Papua New Guinea</mat-option>
                  <mat-option value="PY" i18n='@@Paraguay'>Paraguay</mat-option>
                  <mat-option value="PE" i18n='@@Peru'>Peru</mat-option>
                  <mat-option value="PH" i18n='@@Philippines'>Philippines</mat-option>
                  <mat-option value="PO" i18n='@@Pitcairn Island'>Pitcairn Island</mat-option>
                  <mat-option value="PL" i18n='@@Poland'>Poland</mat-option>
                  <mat-option value="PT" i18n='@@Portugal'>Portugal</mat-option>
                  <!--<mat-option value="PR" i18n='@@Puerto Rico'>Puerto Rico</mat-option> -->
                  <mat-option value="QA" i18n='@@Qatar'>Qatar</mat-option>
                  <mat-option value="ME" i18n='@@Republic of Montenegro'>Republic of Montenegro</mat-option>
                  <mat-option value="RS" i18n='@@Republic of Serbia'>Republic of Serbia</mat-option>
                  <mat-option value="RE" i18n='@@Reunion'>Reunion</mat-option>
                  <mat-option value="RO" i18n='@@Romania'>Romania</mat-option>
                  <mat-option value="RU" i18n='@@Russia'>Russia</mat-option>
                  <mat-option value="RW" i18n='@@Rwanda'>Rwanda</mat-option>
                  <mat-option value="NT" i18n='@@St Barthelemy'>St Barthelemy</mat-option>
                  <mat-option value="EU" i18n='@@St Eustatius'>St Eustatius</mat-option>
                  <mat-option value="HE" i18n='@@St Helena'>St Helena</mat-option>
                  <mat-option value="KN" i18n='@@St Kitts-Nevis'>St Kitts-Nevis</mat-option>
                  <mat-option value="LC" i18n='@@St Lucia'>St Lucia</mat-option>
                  <mat-option value="MB" i18n='@@St Maarten'>St Maarten</mat-option>
                  <mat-option value="PM" i18n='@@St Pierre &amp; Miquelon'>St Pierre &amp; Miquelon</mat-option>
                  <mat-option value="VC" i18n='@@St Vincent &amp; Grenadines'>St Vincent &amp; Grenadines</mat-option>
                  <mat-option value="SP" i18n='@@Saipan'>Saipan</mat-option>
                  <mat-option value="SO" i18n='@@Samoa'>Samoa</mat-option>
                  <mat-option value="AS" i18n='@@Samoa American'>Samoa American</mat-option>
                  <mat-option value="SM" i18n='@@San Marino'>San Marino</mat-option>
                  <mat-option value="ST" i18n='@@Sao Tome &amp; Principe'>Sao Tome &amp; Principe</mat-option>
                  <mat-option value="SA" i18n='@@Saudi Arabia'>Saudi Arabia</mat-option>
                  <mat-option value="SN" i18n='@@Senegal'>Senegal</mat-option>
                  <mat-option value="SC" i18n='@@Seychelles'>Seychelles</mat-option>
                  <mat-option value="SL" i18n='@@Sierra Leone'>Sierra Leone</mat-option>
                  <mat-option value="SG" i18n='@@Singapore'>Singapore</mat-option>
                  <mat-option value="SK" i18n='@@Slovakia'>Slovakia</mat-option>
                  <mat-option value="SI" i18n='@@Slovenia'>Slovenia</mat-option>
                  <mat-option value="SB" i18n='@@Solomon Islands'>Solomon Islands</mat-option>
                  <mat-option value="OI" i18n='@@Somalia'>Somalia</mat-option>
                  <mat-option value="ZA" i18n='@@South Africa'>South Africa</mat-option>
                  <mat-option value="ES" i18n='@@Spain'>Spain</mat-option>
                  <mat-option value="LK" i18n='@@Sri Lanka'>Sri Lanka</mat-option>
                  <mat-option value="SD" i18n='@@Sudan'>Sudan</mat-option>
                  <mat-option value="SR" i18n='@@Suriname'>Suriname</mat-option>
                  <mat-option value="SZ" i18n='@@Swaziland'>Swaziland</mat-option>
                  <mat-option value="SE" i18n='@@Sweden'>Sweden</mat-option>
                  <mat-option value="CH" i18n='@@Switzerland'>Switzerland</mat-option>
                  <mat-option value="SY" i18n='@@Syria'>Syria</mat-option>
                  <mat-option value="TW" i18n='@@Taiwan'>Taiwan</mat-option>
                  <mat-option value="TJ" i18n='@@Tajikistan'>Tajikistan</mat-option>
                  <mat-option value="TZ" i18n='@@Tanzania'>Tanzania</mat-option>
                  <mat-option value="TH" i18n='@@Thailand'>Thailand</mat-option>
                  <mat-option value="TG" i18n='@@Togo'>Togo</mat-option>
                  <mat-option value="TK" i18n='@@Tokelau'>Tokelau</mat-option>
                  <mat-option value="TO" i18n='@@Tonga'>Tonga</mat-option>
                  <mat-option value="TT" i18n='@@Trinidad &amp; Tobago'>Trinidad &amp; Tobago</mat-option>
                  <mat-option value="TN" i18n='@@Tunisia'>Tunisia</mat-option>
                  <mat-option value="TR" i18n='@@Turkey'>Turkey</mat-option>
                  <mat-option value="TU" i18n='@@Turkmenistan'>Turkmenistan</mat-option>
                  <mat-option value="TC" i18n='@@Turks &amp; Caicos Is'>Turks &amp; Caicos Is</mat-option>
                  <mat-option value="TV" i18n='@@Tuvalu'>Tuvalu</mat-option>
                  <mat-option value="UG" i18n='@@Uganda'>Uganda</mat-option>
                  <mat-option value="UA" i18n='@@Ukraine'>Ukraine</mat-option>
                  <mat-option value="AE" i18n='@@United Arab Emirates'>United Arab Emirates</mat-option>
                  <mat-option value="GB" i18n='@@United Kingdom'>United Kingdom</mat-option>
                  <mat-option value="US" selected="selected" i18n='@@United States'>United States</mat-option>
                  <mat-option value="UY" i18n='@@Uruguay'>Uruguay</mat-option>
                  <mat-option value="UZ" i18n='@@Uzbekistan'>Uzbekistan</mat-option>
                  <mat-option value="VU" i18n='@@Vanuatu'>Vanuatu</mat-option>
                  <mat-option value="VS" i18n='@@Vatican City State'>Vatican City State</mat-option>
                  <mat-option value="VE" i18n='@@Venezuela'>Venezuela</mat-option>
                  <mat-option value="VN" i18n='@@Vietnam'>Vietnam</mat-option>
                  <mat-option value="VB" i18n='@@Virgin Islands (Brit)'>Virgin Islands (Brit)</mat-option>
                  <mat-option value="VI" i18n='@@Virgin Islands (USA)'>Virgin Islands (USA)</mat-option>
                  <mat-option value="WK" i18n='@@Wake Island'>Wake Island</mat-option>
                  <mat-option value="WF" i18n='@@Wallis &amp; Futana Is'>Wallis &amp; Futana Is</mat-option>
                  <mat-option value="YE" i18n='@@Yemen'>Yemen</mat-option>
                  <mat-option value="ZR" i18n='@@Zaire'>Zaire</mat-option>
                  <mat-option value="ZM" i18n='@@Zambia'>Zambia</mat-option>
                  <mat-option value="ZW" i18n='@@Zimbabwe'>Zimbabwe</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="creditCardFullWidth">
                <input [(ngModel)]="recurring.postalCodePayment" name="postalCode" matInput #postalCode maxlength="5" i18n-placeholder="@@Postal Code" placeholder="Postal Code">
                <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
              </mat-form-field>
            </td>
          </tr>
          </table>
        </form>

        <div class="placeOrderButtonAndSummary" *ngIf="recurring" [ngClass]="getUpdateRecurringButtonClass()">
          <p class="placeTranscriptionOrder" *ngIf="!paymentFormFieldsVerified" i18n="@@Please complete recurring payment information.">Please complete recurring payment information.</p>
          <p class="placeTranscriptionOrder" *ngIf="recurring.cardNumber.length > 15 && recurring.cardNumber.length < 20 && !recurring.validCardNumber()" i18n="@@The card number is invalid.">The card number is invalid.</p>
          <p class="orderResponseMessage wait" *ngIf="updateRecurringStatus === 'Ready'"><ng-container i18n="@@Your payment information is ready to be verified.  Click the button below labeled">Your payment information is ready to be verified.  Click the button below labeled</ng-container> '<ng-container i18n="@@Update Recurring Payment Method">Update Recurring Payment Method</ng-container>'</p>
          <p class="orderResponseMessage wait" *ngIf="updateRecurringStatus === 'PleaseWait'" i18n="@@Processing your order please wait.">Processing your recurring payment information.</p>
          <p class="orderResponseMessage success" *ngIf="updateRecurringStatus === 'Success'" i18n="@@Your order has been place.">Your recurring payment information has been updated.</p>
          <!--<p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'CreditCardChargedButNoOrder'" i18n="@@There was an problem processing your order.  Your card was charged but the order did not go through.  Please contact us for a refund.">There was an problem processing your order.  Your card was charged but the order did not go through.  Please contact us for a refund.</p>-->
          <p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'CreditCardDidNotCharge'" i18n="@@There was a problem verifying your credit card.">There was a problem verifying your credit card.</p>
          <p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'ConnectionError'" i18n="@@There was internal system problem accessing resources to place your order.  Please contact our customer service to help resolve this issue.">There was internal system problem accessing resources to verify your payment information.  Please contact our customer service to help resolve this issue.</p>
          <p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'GeneralError'" i18n="@@There was internal system problem.  Please contact our customer service to help resolve this issue.">There was internal system problem.  Please contact our customer service to help resolve this issue.</p>
          <p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'Undefined'" i18n="@@There was an unexpected problem.  Please contact our customer service to help resolve this issue.">There was an unexpected problem.  Please contact our customer service to help resolve this issue.</p>
          <p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'Error'" i18n="@@There was an unexpected error.  Please contact our customer service to help resolve this issue.">There was an unexpected error.  Please contact our customer service to help resolve this issue.</p>
          <p class="orderResponseMessage error" *ngIf="updateRecurringMessage !== ''">{{updateRecurringMessage}}</p>
        </div>
        <!--<form card container=".card-container" card-width="300" [messages]="messages" [placeholders]="placeholders" [masks]="masks" formatting="true" debug="true">-->
            <!--<p class="small">Start typing in here to take over and try it out</p>-->
            <!--<div class="row collapse">-->
              <!--<div class="small-6 columns">-->
                <!--<input placeholder="Card number" type="text" name="number" class="unknown">-->
              <!--</div>-->
              <!--<div class="small-6 columns">-->
                <!--<input placeholder="Full name" type="text" name="name">-->
              <!--</div>-->
            <!--</div>-->
            <!--<div class="row collapse">-->
              <!--<div class="small-3 columns">-->
                <!--<input placeholder="MM/YY" type="text" name="expiry">-->
              <!--</div>-->

              <!--<div class="small-3 columns">-->
                <!--<input placeholder="CVC" type="text" name="cvc">-->
              <!--</div>-->

              <!--<div class="small-6 columns">-->
                <!--<input type="submit" value="Submit" class="button postfix">-->
              <!--</div>-->
            <!--</div>-->
          <!--<div>-->
            <!--<input type="text" name="number" [(ngModel)]="recurring.cardNumber" (change)="verifyPaymentInfo()" required card-number/>-->
            <!--<input type="text" name="first-name" [(ngModel)]="recurring.firstNamePayment" (change)="verifyPaymentInfo()" required card-name/>-->
            <!--<input type="text" name="last-name" [(ngModel)]="recurring.lastNamePayment" (change)="verifyPaymentInfo()" required card-name/>-->
          <!--</div>-->
          <!--<div>-->
            <!--<input type="text" name="expiry" [(ngModel)]="recurring.expMonth" (change)="verifyPaymentInfo()" required card-expiry/>-->
            <!--<input type="text" name="cvc" [(ngModel)]="recurring.csc" (change)="verifyPaymentInfo()" required card-cvc/>-->
          <!--</div>-->
        <!--</form>-->
        <button *ngIf="paymentFormFieldsVerified" mat-flat-button color="primary" (click)="updateRecurring()" i18n="@@Update Recurring Payment Method">Update Recurring Payment Method</button>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="recurring && !showCreditCardForm" mat-flat-button color="primary" (click)="showCreditCardForm = !showCreditCardForm" i18n="@@Add">Add</button>
      <button *ngIf="recurring && recurring.cardNumber && !showCreditCardForm" mat-stroked-button color="primary" (click)="showCreditCardForm = !showCreditCardForm" i18n="@@Edit">Edit</button>
    </mat-card-actions>
  </mat-card>
</div>
  <!--<form *ngIf="subscription && !startupService.showUpdateRecurring" (ngSubmit)="onSubmit()" #form="ngForm" class="rbform">-->
    <!--<h2 i18n="@@Account Settings">Account Settings</h2>-->
    <!--<span class="accountSettingsStaticField"><span class="accountSettingsStaticFieldLabel" i18n="@@UserId">User Id</span>: {{subscription.userId}}</span>-->
    <!--<span class="accountSettingsStaticField"><span class="accountSettingsStaticFieldLabel" i18n="@@Status">Status</span>: {{subscription.subscriptionStatus}}</span>-->
    <!--<button type="submit" class="btn btn-success" (click)="startupService.showUpdateRecurring = !startupService.showUpdateRecurring" i18n="@@Update Subscription Payment Information">Update Subscription Payment Information</button>-->

    <!--<span class="accountSettingsStaticField"><span class="accountSettingsStaticFieldLabel" i18n="@@Start Date">Start Date</span>: {{subscription.startDate | date: 'short' }}</span>-->
    <!--<span class="accountSettingsStaticField"><span class="accountSettingsStaticFieldLabel" i18n="@@Paid Until">Paid Until</span>: {{subscription.paidUntilDate | date: 'short' }}</span>-->
    <!--<div class="form-group">-->
      <!--<label for="subscriptionLevel" i18n="@@Subscription Level">Subscription Level</label>-->
      <!--<select ID="subscriptionLevel" name="subscriptionLevel" [(ngModel)]="subscription.subscriptionLevel" required style="display:inline">-->
        <!--<option value="STARTER" i18n="@@Starter">Starter</option>-->
        <!--<option value="STANDARD" i18n="@@Standard">Standard</option>-->
        <!--<option value="PLUS" i18n="@@Plus">Plus</option>-->
        <!--<option value="ECOMMERCE" i18n="@@Ecommerce">Ecommerce</option>-->
      <!--</select>-->
      <!--&lt;!&ndash;<div [hidden]="subscriptionLevel.valid || subscriptionLevel.pristine" class="alert alert-danger">Subscription Level is required</div>&ndash;&gt;-->
    <!--</div>-->
    <!--<div class="form-group">-->
      <!--<label for="subscriptionPeriod" i18n="@@Subscription Period">Subscription Period</label>-->
      <!--<select ID="subscriptionPeriod" name="subscriptionPeriod" [(ngModel)]="subscription.subscriptionPeriod" required style="display:inline">-->
        <!--<option value="ANNUAL" i18n="@@Annual">Annual</option>-->
        <!--<option value="MONTHLY" i18n="@@Standard">Monthly</option>-->
      <!--</select>-->
      <!--&lt;!&ndash;<div [hidden]="subscriptionPeriod.valid || subscriptionPeriod.pristine" class="alert alert-danger">Subscription Period is required</div>&ndash;&gt;-->
    <!--</div>-->
    <!--<div class="pricing" *ngIf="subscription.getSubscriptionLevel() === 'STARTER' && subscription.getSubscriptionPeriod() === 'ANNUAL'">-->
      <!--{{subscription.subscriptionLevel}} {{subscription.subscriptionPeriod}} FREE-->
    <!--</div>-->
    <!--<div class="pricing" *ngIf="subscription.getSubscriptionLevel() === 'STARTER' && subscription.getSubscriptionPeriod() === 'MONTHLY'">-->
      <!--{{subscription.subscriptionLevel}} {{subscription.subscriptionPeriod}} FREE-->
    <!--</div>-->
    <!--<div class="pricing" *ngIf="subscription.getSubscriptionLevel() === 'STANDARD' && subscription.getSubscriptionPeriod() === 'ANNUAL'">-->
      <!--{{subscription.subscriptionLevel}} {{subscription.subscriptionPeriod}} $20 (10% savings)-->
    <!--</div>-->
    <!--<div class="pricing" *ngIf="subscription.getSubscriptionLevel() === 'STANDARD' && subscription.getSubscriptionPeriod() === 'MONTHLY'">-->
      <!--{{subscription.subscriptionLevel}} {{subscription.subscriptionPeriod}} $22-->
    <!--</div>-->
    <!--<div class="pricing" *ngIf="subscription.getSubscriptionLevel() === 'PLUS' && subscription.getSubscriptionPeriod() === 'ANNUAL'">-->
      <!--{{subscription.subscriptionLevel}} {{subscription.subscriptionPeriod}} $50 (10% savings)-->
    <!--</div>-->
    <!--<div class="pricing" *ngIf="subscription.getSubscriptionLevel() === 'PLUS' && subscription.getSubscriptionPeriod() === 'MONTHLY'">-->
      <!--{{subscription.subscriptionLevel}} {{subscription.subscriptionPeriod}} $55-->
    <!--</div>-->
    <!--<div class="pricing" *ngIf="subscription.getSubscriptionLevel() === 'ECOMMERCE' && subscription.getSubscriptionPeriod() === 'ANNUAL'">-->
      <!--{{subscription.subscriptionLevel}} {{subscription.subscriptionPeriod}} $100 (10% savings)-->
    <!--</div>-->
    <!--<div class="pricing" *ngIf="subscription.getSubscriptionLevel() === 'ECOMMERCE' && subscription.getSubscriptionPeriod() === 'MONTHLY'">-->
      <!--{{subscription.subscriptionLevel}} {{subscription.subscriptionPeriod}} $110-->
    <!--</div>-->

    <!--<button type="submit" class="btn btn-success" (click)="save()" [disabled]="!form.form.valid" i18n="@@Change Account Settings">Change Account Settings</button>-->
    <!--<button type="button" class="btn btn-default" (click)="form.reset(); location.back()">Cancel</button>-->
  <!--</form>-->
  <!--<form *ngIf="subscription && startupService.showUpdateRecurring" (ngSubmit)="updateRecurring()" #form="ngForm" class="rbform">-->
    <!--<div class="recurring" *ngIf="recurring">-->
      <!--<input type="text" [(ngModel)]="recurring.cardNumber" required name="cardNumber" ID="cardNumber" i18n-placeholder="@@Credit Card Number" placeholder="Credit Card Number" (change)="verifyPaymentInfo()"  autocomplete="cc-number" />-->
      <!--<input required [(ngModel)]="recurring.csc" type="text" name="csc" ID="csc" placeholder="CSC" autocomplete="off" (change)="verifyPaymentInfo()" i18n-placeholder="@@3 or 4 digit security code" placeholder="3 or 4 digit security code" pattern="^([0-9]{3,4})$"  autocomplete="cc-csc" >-->
      <!--<select name="expMonth" ID="expMonth" [(ngModel)]="recurring.expMonth" required >-->
        <!--<option value=01 i18n="@@Jan">Jan</option>-->
        <!--<option value=02 i18n="@@Feb">Feb</option>-->
        <!--<option value=03 i18n="@@Mar">Mar</option>-->
        <!--<option value=04 i18n="@@Apr">Apr</option>-->
        <!--<option value=05 i18n="@@May">May</option>-->
        <!--<option value=06 i18n="@@Jun">Jun</option>-->
        <!--<option value=07 i18n="@@Jul">Jul</option>-->
        <!--<option value=08 i18n="@@Aug">Aug</option>-->
        <!--<option value=09 i18n="@@Sep">Sep</option>-->
        <!--<option value=10 i18n="@@Oct">Oct</option>-->
        <!--<option value=11 i18n="@@Nov">Nov</option>-->
        <!--<option value=12 i18n="@@Dec">Dec</option>-->
      <!--</select>-->

      <!--<select name="expYear" ID="expYear" [(ngModel)]="recurring.expYear" (change)="verifyPaymentInfo()" required>-->
        <!--<option value=2018 i18n="@@2018">2018</option>-->
        <!--<option value=2019 i18n="@@2019">2019</option>-->
        <!--<option value=2020 i18n="@@2020">2020</option>-->
        <!--<option value=2021 i18n="@@2021">2021</option>-->
        <!--<option value=2022 i18n="@@2022">2022</option>-->
        <!--<option value=2023 i18n="@@2023">2023</option>-->
        <!--<option value=2024 i18n="@@2024">2024</option>-->
        <!--<option value=2025 i18n="@@2025">2025</option>-->
      <!--</select>-->
      <!--<div ID="creditCardAddress" *ngIf="!billingAddressTheSameAsShipping">-->
        <!--<input [(ngModel)]="recurring.firstNamePayment" type="text" name="firstName" ID="firstNamePayment" i18n-placeholder="@@First Name" placeholder="First Name" (change)="verifyPaymentInfo()" required />-->
        <!--<input [(ngModel)]="recurring.lastNamePayment" type="text" name="lastName" ID="lastNamePayment" i18n-placeholder="@@Last Name" placeholder="Last Name" (change)="verifyPaymentInfo()"  required  autocomplete="cc-name"/>-->
        <!--<input [(ngModel)]="recurring.address1Payment" type="text" name="address1" ID="address1Payment" i18n-placeholder="@@Street Address" placeholder="Streed Address" (change)="verifyPaymentInfo()" required autocomplete="billing street-address" />-->
        <!--<input [(ngModel)]="recurring.address2Payment" type="text" name="address2" ID="address2Payment" i18n-placeholder="@@Apt/Suite" placeholder="Apt/Suite" />-->
        <!--<input [(ngModel)]="recurring.cityPayment" type="text" name="city" ID="cityPayment" i18n-placeholder="@@City" placeholder="City" (change)="verifyPaymentInfo()" required autocomplete="billing locality"/>-->
        <!--<input [(ngModel)]="recurring.statePayment" type="text" name="state" ID="statePayment" i18n-placeholder="@@State" placeholder="State" (change)="verifyPaymentInfo()" required autocomplete="billing region"/>-->
        <!--<input [(ngModel)]="recurring.postalCodePayment" type="text" name="postalCode" ID="postalCodePayment" i18n-placeholder="@@Zip" placeholder="Zip" (change)="verifyPaymentInfo()" required  autocomplete="billing postal-code"/>-->
        <!--<select [(ngModel)]="recurring.countryPayment" name="country"  ID="countryPayment"  (change)="verifyPaymentInfo()" autocomplete="billing country">-->
          <!--<option value="AF">Afghanistan</option>-->
          <!--<option value="AL">Albania</option>-->
          <!--<option value="DZ">Algeria</option>-->
          <!--<option value="AS">American Samoa</option>-->
          <!--<option value="AD">Andorra</option>-->
          <!--<option value="AG">Angola</option>-->
          <!--<option value="AI">Anguilla</option>-->
          <!--<option value="AG">Antigua &amp; Barbuda</option>-->
          <!--<option value="AR">Argentina</option>-->
          <!--<option value="AA">Armenia</option>-->
          <!--<option value="AW">Aruba</option>-->
          <!--<option value="AU">Australia</option>-->
          <!--<option value="AT">Austria</option>-->
          <!--<option value="AZ">Azerbaijan</option>-->
          <!--<option value="BS">Bahamas</option>-->
          <!--<option value="BH">Bahrain</option>-->
          <!--<option value="BD">Bangladesh</option>-->
          <!--<option value="BB">Barbados</option>-->
          <!--<option value="BY">Belarus</option>-->
          <!--<option value="BE">Belgium</option>-->
          <!--<option value="BZ">Belize</option>-->
          <!--<option value="BJ">Benin</option>-->
          <!--<option value="BM">Bermuda</option>-->
          <!--<option value="BT">Bhutan</option>-->
          <!--<option value="BO">Bolivia</option>-->
          <!--<option value="BL">Bonaire</option>-->
          <!--<option value="BA">Bosnia &amp; Herzegovina</option>-->
          <!--<option value="BW">Botswana</option>-->
          <!--<option value="BR">Brazil</option>-->
          <!--<option value="BC">British Indian Ocean Ter</option>-->
          <!--<option value="BN">Brunei</option>-->
          <!--<option value="BG">Bulgaria</option>-->
          <!--<option value="BF">Burkina Faso</option>-->
          <!--<option value="BI">Burundi</option>-->
          <!--<option value="KH">Cambodia</option>-->
          <!--<option value="CM">Cameroon</option>-->
          <!--<option value="CA">Canada</option>-->
          <!--<option value="IC">Canary Islands</option>-->
          <!--<option value="CV">Cape Verde</option>-->
          <!--<option value="KY">Cayman Islands</option>-->
          <!--<option value="CF">Central African Republic</option>-->
          <!--<option value="TD">Chad</option>-->
          <!--<option value="CD">Channel Islands</option>-->
          <!--<option value="CL">Chile</option>-->
          <!--<option value="CN">China</option>-->
          <!--<option value="CI">Christmas Island</option>-->
          <!--<option value="CS">Cocos Island</option>-->
          <!--<option value="CO">Colombia</option>-->
          <!--<option value="CC">Comoros</option>-->
          <!--<option value="CG">Congo</option>-->
          <!--<option value="CK">Cook Islands</option>-->
          <!--<option value="CR">Costa Rica</option>-->
          <!--<option value="CT">Cote D'Ivoire</option>-->
          <!--<option value="HR">Croatia</option>-->
          <!--<option value="CU">Cuba</option>-->
          <!--<option value="CB">Curacao</option>-->
          <!--<option value="CY">Cyprus</option>-->
          <!--<option value="CZ">Czech Republic</option>-->
          <!--<option value="DK">Denmark</option>-->
          <!--<option value="DJ">Djibouti</option>-->
          <!--<option value="DM">Dominica</option>-->
          <!--<option value="DO">Dominican Republic</option>-->
          <!--<option value="TM">East Timor</option>-->
          <!--<option value="EC">Ecuador</option>-->
          <!--<option value="EG">Egypt</option>-->
          <!--<option value="SV">El Salvador</option>-->
          <!--<option value="GQ">Equatorial Guinea</option>-->
          <!--<option value="ER">Eritrea</option>-->
          <!--<option value="EE">Estonia</option>-->
          <!--<option value="ET">Ethiopia</option>-->
          <!--<option value="FA">Falkland Islands</option>-->
          <!--<option value="FO">Faroe Islands</option>-->
          <!--<option value="FJ">Fiji</option>-->
          <!--<option value="FI">Finland</option>-->
          <!--<option value="FR">France</option>-->
          <!--<option value="GF">French Guiana</option>-->
          <!--<option value="PF">French Polynesia</option>-->
          <!--<option value="FS">French Southern Ter</option>-->
          <!--<option value="GA">Gabon</option>-->
          <!--<option value="GM">Gambia</option>-->
          <!--<option value="GE">Georgia</option>-->
          <!--<option value="DE">Germany</option>-->
          <!--<option value="GH">Ghana</option>-->
          <!--<option value="GI">Gibraltar</option>-->
          <!--<option value="GB">Great Britain</option>-->
          <!--<option value="GR">Greece</option>-->
          <!--<option value="GL">Greenland</option>-->
          <!--<option value="GD">Grenada</option>-->
          <!--<option value="GP">Guadeloupe</option>-->
          <!--<option value="GU">Guam</option>-->
          <!--<option value="GT">Guatemala</option>-->
          <!--<option value="GN">Guinea</option>-->
          <!--<option value="GY">Guyana</option>-->
          <!--<option value="HT">Haiti</option>-->
          <!--<option value="HW">Hawaii</option>-->
          <!--<option value="HN">Honduras</option>-->
          <!--<option value="HK">Hong Kong</option>-->
          <!--<option value="HU">Hungary</option>-->
          <!--<option value="IS">Iceland</option>-->
          <!--<option value="IN">India</option>-->
          <!--<option value="ID">Indonesia</option>-->
          <!--<option value="IR">Iran</option>-->
          <!--<option value="IQ">Iraq</option>-->
          <!--<option value="IE">Ireland</option>-->
          <!--<option value="IM">Isle of Man</option>-->
          <!--<option value="IL">Israel</option>-->
          <!--<option value="IT">Italy</option>-->
          <!--<option value="JM">Jamaica</option>-->
          <!--<option value="JP">Japan</option>-->
          <!--<option value="JO">Jordan</option>-->
          <!--<option value="KZ">Kazakhstan</option>-->
          <!--<option value="KE">Kenya</option>-->
          <!--<option value="KI">Kiribati</option>-->
          <!--<option value="NK">Korea North</option>-->
          <!--<option value="KS">Korea South</option>-->
          <!--<option value="KW">Kuwait</option>-->
          <!--<option value="KG">Kyrgyzstan</option>-->
          <!--<option value="LA">Laos</option>-->
          <!--<option value="LV">Latvia</option>-->
          <!--<option value="LB">Lebanon</option>-->
          <!--<option value="LS">Lesotho</option>-->
          <!--<option value="LR">Liberia</option>-->
          <!--<option value="LY">Libya</option>-->
          <!--<option value="LI">Liechtenstein</option>-->
          <!--<option value="LT">Lithuania</option>-->
          <!--<option value="LU">Luxembourg</option>-->
          <!--<option value="MO">Macau</option>-->
          <!--<option value="MK">Macedonia</option>-->
          <!--<option value="MG">Madagascar</option>-->
          <!--<option value="MY">Malaysia</option>-->
          <!--<option value="MW">Malawi</option>-->
          <!--<option value="MV">Maldives</option>-->
          <!--<option value="ML">Mali</option>-->
          <!--<option value="MT">Malta</option>-->
          <!--<option value="MH">Marshall Islands</option>-->
          <!--<option value="MQ">Martinique</option>-->
          <!--<option value="MR">Mauritania</option>-->
          <!--<option value="MU">Mauritius</option>-->
          <!--<option value="ME">Mayotte</option>-->
          <!--<option value="MX">Mexico</option>-->
          <!--<option value="MI">Midway Islands</option>-->
          <!--<option value="MD">Moldova</option>-->
          <!--<option value="MC">Monaco</option>-->
          <!--<option value="MN">Mongolia</option>-->
          <!--<option value="MS">Montserrat</option>-->
          <!--<option value="MA">Morocco</option>-->
          <!--<option value="MZ">Mozambique</option>-->
          <!--<option value="MM">Myanmar</option>-->
          <!--<option value="NA">Nambia</option>-->
          <!--<option value="NU">Nauru</option>-->
          <!--<option value="NP">Nepal</option>-->
          <!--<option value="AN">Netherland Antilles</option>-->
          <!--<option value="NL">Netherlands (Holland, Europe)</option>-->
          <!--<option value="NV">Nevis</option>-->
          <!--<option value="NC">New Caledonia</option>-->
          <!--<option value="NZ">New Zealand</option>-->
          <!--<option value="NI">Nicaragua</option>-->
          <!--<option value="NE">Niger</option>-->
          <!--<option value="NG">Nigeria</option>-->
          <!--<option value="NW">Niue</option>-->
          <!--<option value="NF">Norfolk Island</option>-->
          <!--<option value="NO">Norway</option>-->
          <!--<option value="OM">Oman</option>-->
          <!--<option value="PK">Pakistan</option>-->
          <!--<option value="PW">Palau Island</option>-->
          <!--<option value="PS">Palestine</option>-->
          <!--<option value="PA">Panama</option>-->
          <!--<option value="PG">Papua New Guinea</option>-->
          <!--<option value="PY">Paraguay</option>-->
          <!--<option value="PE">Peru</option>-->
          <!--<option value="PH">Philippines</option>-->
          <!--<option value="PO">Pitcairn Island</option>-->
          <!--<option value="PL">Poland</option>-->
          <!--<option value="PT">Portugal</option>-->
          <!--&lt;!&ndash;<option value="PR">Puerto Rico</option> &ndash;&gt;-->
          <!--<option value="QA">Qatar</option>-->
          <!--<option value="ME">Republic of Montenegro</option>-->
          <!--<option value="RS">Republic of Serbia</option>-->
          <!--<option value="RE">Reunion</option>-->
          <!--<option value="RO">Romania</option>-->
          <!--<option value="RU">Russia</option>-->
          <!--<option value="RW">Rwanda</option>-->
          <!--<option value="NT">St Barthelemy</option>-->
          <!--<option value="EU">St Eustatius</option>-->
          <!--<option value="HE">St Helena</option>-->
          <!--<option value="KN">St Kitts-Nevis</option>-->
          <!--<option value="LC">St Lucia</option>-->
          <!--<option value="MB">St Maarten</option>-->
          <!--<option value="PM">St Pierre &amp; Miquelon</option>-->
          <!--<option value="VC">St Vincent &amp; Grenadines</option>-->
          <!--<option value="SP">Saipan</option>-->
          <!--<option value="SO">Samoa</option>-->
          <!--<option value="AS">Samoa American</option>-->
          <!--<option value="SM">San Marino</option>-->
          <!--<option value="ST">Sao Tome &amp; Principe</option>-->
          <!--<option value="SA">Saudi Arabia</option>-->
          <!--<option value="SN">Senegal</option>-->
          <!--<option value="SC">Seychelles</option>-->
          <!--<option value="SL">Sierra Leone</option>-->
          <!--<option value="SG">Singapore</option>-->
          <!--<option value="SK">Slovakia</option>-->
          <!--<option value="SI">Slovenia</option>-->
          <!--<option value="SB">Solomon Islands</option>-->
          <!--<option value="OI">Somalia</option>-->
          <!--<option value="ZA">South Africa</option>-->
          <!--<option value="ES">Spain</option>-->
          <!--<option value="LK">Sri Lanka</option>-->
          <!--<option value="SD">Sudan</option>-->
          <!--<option value="SR">Suriname</option>-->
          <!--<option value="SZ">Swaziland</option>-->
          <!--<option value="SE">Sweden</option>-->
          <!--<option value="CH">Switzerland</option>-->
          <!--<option value="SY">Syria</option>-->
          <!--<option value="TW">Taiwan</option>-->
          <!--<option value="TJ">Tajikistan</option>-->
          <!--<option value="TZ">Tanzania</option>-->
          <!--<option value="TH">Thailand</option>-->
          <!--<option value="TG">Togo</option>-->
          <!--<option value="TK">Tokelau</option>-->
          <!--<option value="TO">Tonga</option>-->
          <!--<option value="TT">Trinidad &amp; Tobago</option>-->
          <!--<option value="TN">Tunisia</option>-->
          <!--<option value="TR">Turkey</option>-->
          <!--<option value="TU">Turkmenistan</option>-->
          <!--<option value="TC">Turks &amp; Caicos Is</option>-->
          <!--<option value="TV">Tuvalu</option>-->
          <!--<option value="UG">Uganda</option>-->
          <!--<option value="UA">Ukraine</option>-->
          <!--<option value="AE">United Arab Emirates</option>-->
          <!--<option value="GB">United Kingdom</option>-->
          <!--<option value="US" selected="selected">United States</option>-->
          <!--<option value="UY">Uruguay</option>-->
          <!--<option value="UZ">Uzbekistan</option>-->
          <!--<option value="VU">Vanuatu</option>-->
          <!--<option value="VS">Vatican City State</option>-->
          <!--<option value="VE">Venezuela</option>-->
          <!--<option value="VN">Vietnam</option>-->
          <!--<option value="VB">Virgin Islands (Brit)</option>-->
          <!--<option value="VI">Virgin Islands (USA)</option>-->
          <!--<option value="WK">Wake Island</option>-->
          <!--<option value="WF">Wallis &amp; Futana Is</option>-->
          <!--<option value="YE">Yemen</option>-->
          <!--<option value="ZR">Zaire</option>-->
          <!--<option value="ZM">Zambia</option>-->
          <!--<option value="ZW">Zimbabwe</option>-->
        <!--</select>-->
      <!--</div>-->
    <!--</div>-->
    <!--<div class="placeOrderButtonAndSummary" *ngIf="recurring">-->
      <!--<button ID="placeTranscriptionOrder" class="btn placeTranscriptionOrder" (click)="updateRecurring()" [ngClass]="getUpdateRecurringButtonClass()">-->
        <!--<p class="placeTranscriptionOrder" *ngIf="!paymentFormFieldsVerified" i18n="@@Need Payment Information">Need Payment Information</p>-->
        <!--<p class="orderResponseMessage wait" *ngIf="updateRecurringStatus === 'PleaseWait'" i18n="@@Processing your order please wait.">Processing your recurring payment information.</p>-->
        <!--<p class="orderResponseMessage success" *ngIf="updateRecurringStatus === 'Success'" i18n="@@Your order has been place.">Your recurring payment information has been received.</p>-->
        <!--&lt;!&ndash;<p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'CreditCardChargedButNoOrder'" i18n="@@There was an problem processing your order.  Your card was charged but the order did not go through.  Please contact us for a refund.">There was an problem processing your order.  Your card was charged but the order did not go through.  Please contact us for a refund.</p>&ndash;&gt;-->
        <!--<p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'CreditCardDidNotCharge'" i18n="@@There was a problem verifying your credit card.">There was a problem verifying your credit card.</p>-->
        <!--<p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'ConnectionError'" i18n="@@There was internal system problem accessing resources to place your order.  Please contact our customer service to help resolve this issue.">There was internal system problem accessing resources to place your order.  Please contact our customer service to help resolve this issue.</p>-->
        <!--<p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'GeneralError'" i18n="@@There was internal system problem.  Please contact our customer service to help resolve this issue.">There was internal system problem.  Please contact our customer service to help resolve this issue.</p>-->
        <!--<p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'Undefined'" i18n="@@There was an unexpected problem.  Please contact our customer service to help resolve this issue.">There was an unexpected problem.  Please contact our customer service to help resolve this issue.</p>-->
        <!--<p class="orderResponseMessage error" *ngIf="updateRecurringStatus === 'Error'" i18n="@@There was an unexpected error.  Please contact our customer service to help resolve this issue.">There was an unexpected error.  Please contact our customer service to help resolve this issue.</p>-->
        <!--<p class="orderResponseMessage error" *ngIf="updateRecurringMessage !== ''">{{updateRecurringMessage}}</p>-->
      <!--</button>-->
    <!--</div>-->
    <!--<button type="submit" class="btn btn-default" (click)="form.reset(); startupService.showUpdateRecurring = !startupService.showUpdateRecurring" i18n="@@Cancel">Cancel</button>-->
  <!--</form>-->
<!--</div>-->
