import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {PrintNodeOrderItem} from "../../../model/PrintNodeOrderItem";
import {ManufacturingSetService} from "../../service-data/manufacturing-set.service";
import {ManufacturingSet} from "../../../model/ManufacturingSet";
import {ManufacturingTask} from "../../../model/ManufacturingTask";
import {ManufacturingTaskService} from "../../service-data/manufacturing-task.service";
import {ManufacturingTaskBucketPeriod} from "../../../model/ManufacturingTaskBucketPeriod";
import {PackageTypeService} from "../../service-data/package-type.service";
import {Package} from "../../../model/Package";
import {PackageService} from "../../service-data/package.service";
import {DataObject} from "../../../model/DataObject";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {PrintNodeOrderItemService} from "../../service-data/print-node-order-item.service";
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {PickSheetService} from "../../service-data/pick-sheet.service";
import {FacilityService} from "../../service-data/facility.service";


@Component({
  selector: 'app-manufacturing-sets',
  templateUrl: './manufacturing-sets.component.html',
  styleUrls: ['./manufacturing-sets.component.css']
})

export class ManufacturingSetsComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, RoleChangeListener {
  // public static types = ['interior', 'binding', 'lamination', 'impositionLayout', 'trimSize', 'paperType', 'quantityGroup'];
  public static types = ['interior', 'binding', 'lamination', 'impositionLayout', 'trimSize', 'paperType', 'quantityGroup', 'oneDigitZip'];

  public static colors = {
    'quantityGroup' : {
      '_001-019' : StartupService.colorAliases['background1'],
      '_020-049' : '',
      '_050-099' : StartupService.colorAliases['background2'],
      '_100-249' : StartupService.colorAliases['background3'],
      '_250-499' : StartupService.colorAliases['background4'],
      '_500+' : StartupService.colorAliases['background5']
    },
    'binding' : {
      'PERFECT': '',
      'COIL': StartupService.colorAliases['background1'],
      'SADDLE_STITCH': StartupService.colorAliases['background2']
    },
    'lamination' : {
      'GLOSS': '',
      'MATTE': StartupService.colorAliases['background1']
    },
    'paperType' : {
      '1': '',
      '2': StartupService.colorAliases['background2'],
      '4': StartupService.colorAliases['background3']
    },
    'createdDate' : {},
    'trimSize' : {
      '_5.5X8.5' : StartupService.colorAliases['background1'],
      '_6.0X9.0': '',
      '_8.5X11.0': StartupService.colorAliases['background2'],
      'Other': StartupService.colorAliases['background3']
    },
    'interior' : {
      'GRAY': '',
      'COLOR': StartupService.colorAliases['background1']
    },
    'impositionLayout' : {
      '_2UP': StartupService.colorAliases['background1'],
      '_3UP': StartupService.colorAliases['background2'],
      '_4UP': ''
    },
    'serviceProvider' : {
      '1': '',
      '2': '',
      '3': '',
      '4': 'pink',
      '5': '',
      '6': '',
      '7': 'coral',
      '8': 'coral',
      '9': 'coral',
      '10': 'coral',
      '11': 'yellow'

    },
    'oneDigitZip' : {
      '0' : StartupService.colorAliases['background2'],
      '2' : StartupService.colorAliases['background5'],
      'X' : StartupService.colorAliases['errorBackground']
    }
  };

  public static icons = {
    'quantityGroup' : {
      '_001-019' : 'remove',
      '_020-049' : 'density_large',
      '_050-099' : 'density_medium',
      '_100-249' : 'density_small',
      '_250-499' : 'square',
      '_500+' : 'square'
    },
    'binding' : {
      'PERFECT': 'book',
      'COIL': 'vertical_split',
      'SADDLE_STITCH': 'local_library'
    },
    'lamination' : {
      'GLOSS': 'blur_on',
      'MATTE': 'blur_off'
    },
    'paperType' : {
      '1': 'fitness_center',
      '2': 'snowing',
      '4': 'icecream'
    },
    'createdDate' : {},
    'trimSize' : {
      '_5.5X8.5' : '',
      '_6.0X9.0': '',
      '_8.5X11.0': '',
      'Other': 'flaky'
    },
    'interior' : {
      'GRAY': 'invert_colors',
      'COLOR': 'palette'
    },
    'impositionLayout' : {
      '_2UP': 'filter_2',
      '_3UP': 'looks_3',
      '_4UP': 'looks_4'
    },
    'serviceProvider' : {},
    'oneDigitZip' : {
      '0' : '',
      '2' : '',
      'X' : 'flaky'
    }
  };

  public manufacturingTaskStatusColors = {
    'INITIAL': '',
    'STARTED': 'yellow',
    'PAUSED': 'orange',
    'COMPLETED': 'green',
    'ERROR': 'red'
  };

  types = ManufacturingSetsComponent.types;
  colors = ManufacturingSetsComponent.colors;
  icons = ManufacturingSetsComponent.icons;
  typesToDisplay = ['interior', 'binding', 'lamination', 'impositionLayout', 'trimSize', 'paperType'];

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;

  instance: number = Math.floor(Math.random() * 1000);
  goose: number = 1;
  manufacturingTaskBucketPeriods: ManufacturingTaskBucketPeriod[] = [];
  manufacturingSetsInManufacturingTaskBucketPeriods: any = {};
  atLeastOneManufacturingSetInManufacturingTaskBucketPeriodIsOpen: any = {};

  manufacturingSetsById = {};
  packagesByManufacturingSets = {};

  manufacturingSetByPrintNodeOrderId: any = {};
  manufacturingSetByPrintNodeOrderItemId: any = {};
  manufacturingSetByPackageId: any = {};
  manufacturingSetById: any = {};
  manufacturingSetByPackageIdNotCompleted: any = {};
  packageTypeById: any = {};

  manufacturingSets: ManufacturingSet[];
  selectedManufacturingSet: ManufacturingSet;
  selectedPrintNodeOrderItem: PrintNodeOrderItem;

  periodDisplayColor: any = {};

  // Display flags and filters
  showCompletedSets: boolean = false;
  // showClosedSets: boolean = true;
  // showLockedSets: boolean = true;
  // showOpenSets: boolean = true;
  showPrintNodeOrderItems: boolean = true;
  showDates: boolean = true;
  showStats: boolean = true;

  expandSets: any = {};
  expandSetsPackages: any = {};

  dataIndex = 0; // 0 for Quantity, 1 for feetOrSheets, 2 for number of line items
  Math = Math;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  public manufacturingSetBackgroundColor = {
    'INITIAL': '',
    'STARTED': 'lightblue',
    'PAUSED': 'lightapricot',
    'COMPLETED': 'lightgrey',
    'ERROR': 'lightpink'
  };
  private packages: Package[] = [];

  public static getBackgroundColor (period: String): string {
    if ( period ) {
      const periodDate = new Date(period.substring(1) + ' 15:01:01');
      let dateDiff = Math.round(( Number( periodDate ) - Number( new Date() ) ) / ( 24 * 60 * 60 * 1000 ) );
      if ( this.getWeekOfYear(periodDate) > this.getWeekOfYear(new Date())) {
        dateDiff -= 2;
      }
      if ( this.getWeekOfYear(periodDate) < this.getWeekOfYear(new Date())) {
        dateDiff += 2;
      }
      if ( dateDiff > 6 ) {
        return 'lightgreen';
      }
      if ( dateDiff < -3) {
        return 'red';
      }
      switch ( dateDiff ) {
        case -4: {
          return StartupService.colorAliases['errorBackground'];
        }
        case -3: {
          return 'lightpink';
        }
        case -2: {
          return 'orange';
        }
        case -1: {
          return 'darkyellow';
        }
        case 0: {
          return 'black';
        }
        case 1: {
          return 'green';
        }
        case 2: {
          return 'green';
        }
        case 3: {
          return 'green';
        }
        case 4: {
          return 'green';
        }
      }
    }
    return 'red';
  }

  public static getWeekOfYear(date: Date): number {
    const MILLISECONDS_IN_WEEK = 604800000;
    const firstDayOfWeek = 0; // monday as the first day (0 = sunday)
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    startOfYear.setDate(
      startOfYear.getDate() + (firstDayOfWeek - (startOfYear.getDay() % 7))
    );
    const dayWeek = Math.floor(( Number( date ) - Number(startOfYear) ) / MILLISECONDS_IN_WEEK) + 1;
    return  dayWeek;
  }

  constructor(public router: Router, public startupService: StartupService, private pickSheetService: PickSheetService, private facilityService: FacilityService, private manufacturingTaskService: ManufacturingTaskService, private manufacturingSetService: ManufacturingSetService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, private packageTypeService: PackageTypeService, private packageService: PackageService, private printNodeOrderItemService: PrintNodeOrderItemService) {
    this.startupService.addRoleChangeListener(this);
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    console.log(`Calling ManufacturingSetComponent from roleChanged ${this.instance} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    this.getData();
  }


  ngOnInit() {
    console.log(`Calling ManufacturingSetComponent from ngOnInit Before isAuthenticated ${this.instance} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    this.startupService.isAuthenticated(this);
    console.log(`Calling ManufacturingSetComponent from ngOnInit After isAuthenticated ${this.instance} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
  }

  ngAfterViewInit(): void {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      console.log(`Calling ManufacturingSetComponent from isLoggedIn ${this.instance} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
      this.getData();
    }
  }

  onSelect(printNodeOrderItem: PrintNodeOrderItem): void {
    this.selectedPrintNodeOrderItem = printNodeOrderItem;
    if ( this.selectedPrintNodeOrderItem ) {
      this.selectedManufacturingSet = this.selectedPrintNodeOrderItem.manufacturingSet;
    }
  }

  addUpdatePackage(o: Package) {
    try {
      if ( this.matchPackage(o)) {
        // console.log(`Match in ManufacturingSetsComponent addUpdatePackage ${o.getId()} ${this.instance} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);

        if ( !this.contains(o, this.packages)) {
          this.packages.push(o);
        }
      } else {
        if ( this.contains(o, this.packages)) {
          const foundIndex = this.packages.findIndex(item => item.getId() === o.getId());
          if (foundIndex > -1) {
            this.packages.splice(foundIndex, 1);
          }
        }
      }
      this.mapPackageData(o);
    } catch ( e ) {
      console.log(`ERROR in ManufacturingSetsComponent addUpdatePackage ${o.getId()} ${e.message} ${this.instance} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  deletePackage(pkg: Package) {
  }

  addUpdate(manufacturingSet: ManufacturingSet) {
    try {
      if ( this.match(manufacturingSet)) {
        // console.log(`Match in ManufacturingSetsComponent addUpdate ${manufacturingSet.getId()} ${manufacturingSet.ID} ${this.instance} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
        this.setLocalPropertyManufacturingTask(manufacturingSet, 'BodyPrint');
        this.setLocalPropertyManufacturingTask(manufacturingSet, 'CoverPrint');
        this.setLocalPropertyManufacturingTask(manufacturingSet, 'Pack');
        const manufacturingTaskBucketPeriod = manufacturingSet.manufacturingTaskBucketPeriod;
        this.periodDisplayColor[manufacturingTaskBucketPeriod.period] = ManufacturingSetsComponent.getBackgroundColor(manufacturingTaskBucketPeriod.period); // This needs to be reset everyday -- could become static if updating keeps rollin forward
        if ( !this.manufacturingSetsInManufacturingTaskBucketPeriods['' + manufacturingTaskBucketPeriod.ID] ) {
          this.manufacturingSetsInManufacturingTaskBucketPeriods['' + manufacturingTaskBucketPeriod.ID] = [];
          this.manufacturingTaskBucketPeriods.push(manufacturingTaskBucketPeriod);
          this.manufacturingTaskBucketPeriods.sort((a, b): number => {
            if (a.period < b.period) {
              return -1;
            }
            if (a.period > b.period) {
              return 1;
            }

            if (a.manufacturingTaskBucket_id < b.manufacturingTaskBucket_id) {
              return -1;
            }
            if (a.manufacturingTaskBucket_id > b.manufacturingTaskBucket_id) {
              return 1;
            }
            return 0;
          });
        }
        this.atLeastOneManufacturingSetInManufacturingTaskBucketPeriodIsOpen['' + manufacturingTaskBucketPeriod.ID] = false;
        if ( manufacturingTaskBucketPeriod.manufacturingSets ) {
          for ( const ms of manufacturingTaskBucketPeriod.manufacturingSets ) {
            if ( ms.status !== 'COMPLETED' && ms.openStatus === 'CLOSED') {
              this.atLeastOneManufacturingSetInManufacturingTaskBucketPeriodIsOpen['' + manufacturingTaskBucketPeriod.ID] = true;            }
          }
        }
        if ( this.manufacturingSetsInManufacturingTaskBucketPeriods['' + manufacturingTaskBucketPeriod.ID].findIndex(item => item.ID === manufacturingSet.ID) === -1) {
          this.manufacturingSetsInManufacturingTaskBucketPeriods['' + manufacturingTaskBucketPeriod.ID].push(manufacturingSet);
        }

        this.computeProperties(manufacturingSet, manufacturingSet.printNodeOrderItems);
        this.manufacturingSetsById['' + manufacturingSet.ID.toString()] = manufacturingSet;
        this.manufacturingSets.push(manufacturingSet);
        this.mapManufacturingSetData(manufacturingSet);
      }
    } catch ( e ) {
      console.log(`Error in ManufacturingSetsComponent addUpdate ${manufacturingSet.getId()} ${e.message} ${this.instance} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  deleteManufacturingSet(manufacturingSet: ManufacturingSet) {
  }

  contains(object: DataObject, array: DataObject[] ): boolean {
    for ( const o of array ) {
      if ( o.getId() === object.getId() ) {
        return true;
      }
    }
    return false;
  }

  match(manufacturingSet: ManufacturingSet): boolean {
    if ( manufacturingSet ) {
      return true;
    }
    return false;
  }

  matchPackage(pkg: Package): boolean {
    if ( pkg ) {
      return true;
    }
    return false;
  }

  getData(reset: boolean = true): void {
    console.log(`Calling ManufacturingSetComponent from getData ${this.instance} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    this.goose++;
    this.startupService.touchLastUserActivity();
    this.manufacturingTaskBucketPeriods = [];
    this.manufacturingSetsInManufacturingTaskBucketPeriods = {};
    this.atLeastOneManufacturingSetInManufacturingTaskBucketPeriodIsOpen = {};
    this.packagesByManufacturingSets = {};
    this.manufacturingSetByPackageId = {};
    this.manufacturingSetsById = {};
    this.manufacturingSets = []; // manufacturingSets;

    // These three are set in the mapping step
    this.manufacturingSetByPrintNodeOrderId = {};
    this.manufacturingSetByPrintNodeOrderItemId = {};
    this.manufacturingSetByPackageIdNotCompleted = {};

    this.manufacturingSetService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
        this.addUpdate(o);
    });
    this.manufacturingSetService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.manufacturingSetService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.deleteManufacturingSet(o);
    });
    this.manufacturingSetService.objects.forEach( o => {
      this.addUpdate(o);
    });

    this.manufacturingTaskService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o.manufacturingSet);
    });
    this.manufacturingTaskService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o.manufacturingSet);
    });
    // this.manufacturingTaskService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
    //   this.deleteManufacturingSet(o.manufacturingSet);
    // });

    this.packageService.getAddNotifier(true).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdatePackage(o);
    });
    this.packageService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.addUpdatePackage(o);
    });
    this.packageService.objects.forEach( o => {
      this.addUpdatePackage(o);
    });

    this.packageTypeService.getAddNotifier(true).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.packageTypeById['' + o.ID] = o;
    });
    this.packageTypeService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe(o => {
      this.packageTypeById['' + o.ID] = o;
    });

    this.printNodeOrderItemService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      if ( this.manufacturingSetsById[ '' + o.manufacturingset_id]) {
        this.addUpdate(this.manufacturingSetsById[ '' + o.manufacturingset_id]);
      }
    });
    this.printNodeOrderItemService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      if ( this.manufacturingSetsById[ '' + o.manufacturingset_id]) {
        this.addUpdate(this.manufacturingSetsById[ '' + o.manufacturingset_id]);
      }
    });
    this.printNodeOrderItemService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      if ( this.manufacturingSetsById[ '' + o.manufacturingset_id]) {
        this.deleteManufacturingSet(this.manufacturingSetsById[ '' + o.manufacturingset_id]);
      }
    });
    // this.printNodeOrderItemService.objects.forEach( o => {
    //   this.addUpdate(o);
    // });

  }

  mapManufacturingSetData(manufacturingSet: ManufacturingSet) {
    for ( const printNodeOrderItem of manufacturingSet.printNodeOrderItems ) {
      if ( printNodeOrderItem.manufacturingset_id !== 0 ) {
        if ( printNodeOrderItem.manufacturingSet ) {
          this.manufacturingSetById['' + manufacturingSet.ID] = manufacturingSet;
          this.manufacturingSetByPrintNodeOrderItemId['' + printNodeOrderItem.ID] = manufacturingSet;
          if ( printNodeOrderItem.printNodeOrder ) {
            if (!this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID]) {
              this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID] = [];
              this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID].push(manufacturingSet);
            } else {
              let found = false;
              for (const ms of this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID]) {
                if (manufacturingSet.ID === printNodeOrderItem.manufacturingset_id) {
                  found = true;
                }
              }
              if (!found) {
                this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID].push(manufacturingSet);
              }
            }
          }
        }
      }
    }
  }

  mapPackageData(pkg: Package) {
    for ( const orderItemToPackage of pkg.orderItemToPackages ) {
      if ( orderItemToPackage.orderItem && orderItemToPackage.orderItem.printNodeOrderItems ) {
        for ( const printNodeOrderItem of orderItemToPackage.orderItem.printNodeOrderItems ) {
          if ( printNodeOrderItem.manufacturingset_id !== 0 ) {
            if ( printNodeOrderItem.manufacturingSet ) {
              if ( !this.packagesByManufacturingSets['' + printNodeOrderItem.manufacturingset_id] ) {
                this.packagesByManufacturingSets['' + printNodeOrderItem.manufacturingset_id] = [];
              }
              let found = false;
              for (const pkgAlreadyInList of this.packagesByManufacturingSets['' + printNodeOrderItem.manufacturingset_id]) {
                if (pkg.package_ID === pkgAlreadyInList.package_ID) {
                  found = true;
                }
              }
              if (!found) {
                this.packagesByManufacturingSets['' + printNodeOrderItem.manufacturingset_id].push(pkg);
              }

              this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id] = printNodeOrderItem.manufacturingSet;
              this.manufacturingSetByPrintNodeOrderItemId['' + printNodeOrderItem.ID] = this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id];
              if ( printNodeOrderItem.printNodeOrder ) {
                if (!this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID]) {
                  this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID] = [];
                  this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id]);
                } else {
                  for (const manufacturingSet of this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID]) {
                    if (manufacturingSet.ID === printNodeOrderItem.manufacturingset_id) {
                      found = true;
                    }
                  }
                  if (!found) {
                    this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id]);
                  }
                }
              }
            }
            if ( !this.manufacturingSetByPackageId[ '' + pkg.package_ID ] ) {
              this.manufacturingSetByPackageId[ '' + pkg.package_ID ] = [];
              this.manufacturingSetByPackageId[ '' + pkg.package_ID ].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id ]);
            } else {
              let found = false;
              for ( const manufacturingSet of this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
                if ( manufacturingSet.ID === printNodeOrderItem.manufacturingset_id) {
                  found = true;
                }
              }
              if ( !found ) {
                this.manufacturingSetByPackageId[ '' + pkg.package_ID ].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id ]);
              }
            }
          }
        }
      }
    }
    if ( this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
      // console.log(`${pkg.package_ID}`);
      for ( const manufacturingSet of this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
        if (manufacturingSet.status !== 'COMPLETED') {
          this.manufacturingSetByPackageIdNotCompleted['' + pkg.package_ID] = true;
        }
      }
    }
  }

  mapData() {
    this.manufacturingSetByPrintNodeOrderId = {};
    this.manufacturingSetByPrintNodeOrderItemId = {};
    this.manufacturingSetByPackageIdNotCompleted = {};
    for ( const pkg of this.packages ) {
      for ( const orderItemToPackage of pkg.orderItemToPackages ) {
        if ( orderItemToPackage.orderItem && orderItemToPackage.orderItem.printNodeOrderItems ) {
          for ( const printNodeOrderItem of orderItemToPackage.orderItem.printNodeOrderItems ) {
            if ( printNodeOrderItem.manufacturingset_id !== 0 ) {
              if ( printNodeOrderItem.manufacturingSet ) {
                if ( !this.packagesByManufacturingSets['' + printNodeOrderItem.manufacturingset_id] ) {
                  this.packagesByManufacturingSets['' + printNodeOrderItem.manufacturingset_id] = [];
                }
                let found = false;
                for (const pkgAlreadyInList of this.packagesByManufacturingSets['' + printNodeOrderItem.manufacturingset_id]) {
                  if (pkg.package_ID === pkgAlreadyInList.package_ID) {
                    found = true;
                  }
                }
                if (!found) {
                  this.packagesByManufacturingSets['' + printNodeOrderItem.manufacturingset_id].push(pkg);
                }

                this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id] = printNodeOrderItem.manufacturingSet;
                this.manufacturingSetByPrintNodeOrderItemId['' + printNodeOrderItem.ID] = this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id];
                if ( printNodeOrderItem.printNodeOrder ) {
                  if (!this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID]) {
                    this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID] = [];
                    this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id]);
                  } else {
                    for (const manufacturingSet of this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID]) {
                      if (manufacturingSet.ID === printNodeOrderItem.manufacturingset_id) {
                        found = true;
                      }
                    }
                    if (!found) {
                      this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id]);
                    }
                  }
                }
              }
              if ( !this.manufacturingSetByPackageId[ '' + pkg.package_ID ] ) {
                this.manufacturingSetByPackageId[ '' + pkg.package_ID ] = [];
                this.manufacturingSetByPackageId[ '' + pkg.package_ID ].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id ]);
              } else {
                let found = false;
                for ( const manufacturingSet of this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
                  if ( manufacturingSet.ID === printNodeOrderItem.manufacturingset_id) {
                    found = true;
                  }
                }
                if ( !found ) {
                  this.manufacturingSetByPackageId[ '' + pkg.package_ID ].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id ]);
                }
              }
            }
          }
        }
      }
      if ( this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
        console.log(`${pkg.package_ID}`);
        for ( const manufacturingSet of this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
          if (manufacturingSet.status !== 'COMPLETED') {
            this.manufacturingSetByPackageIdNotCompleted['' + pkg.package_ID] = true;
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  computeProperties(wrapper: any, printNodeOrderItems: PrintNodeOrderItem[]) {
    if ( !wrapper.filter ) {
      wrapper.filter = {};
    }

    wrapper.lineItems = 0;
    wrapper.quantity = 0;
    wrapper.feetOrSheets = 0;
    wrapper.totals = [0, 0, 0];
    wrapper.binding = [{}, {}, {}];
    wrapper.lamination = [{}, {}, {}];
    wrapper.paperType = [{}, {}, {}];
    wrapper.createdDate = [{}, {}, {}];
    wrapper.trimSize = [{}, {}, {}];
    wrapper.interior = [{}, {}, {}];
    wrapper.impositionLayout = [{}, {}, {}];
    wrapper.quantityGroup = [{}, {}, {}];
    wrapper.serviceProvider = [{}, {}, {}];
    wrapper.oneDigitZip = [{}, {}, {}];
    for ( const printNodeOrderItem of printNodeOrderItems ) {
      try {
        const quantity = printNodeOrderItem.quantity;
        const width = printNodeOrderItem.book.body.width;
        const height = printNodeOrderItem.book.body.height;
        const thickness = printNodeOrderItem.book.body.spine;
        const pages = printNodeOrderItem.book.body.numPages;
        const interior = printNodeOrderItem.book.interiorType;
        const paperType = String(printNodeOrderItem.book.body.paperType_ID);
        const lamination = printNodeOrderItem.book.cover.laminateType;

        let createdDate = '';
        let oneDigitZip = '';
        let serviceProvider = 0;
        if ( printNodeOrderItem.printNodeOrder ) {
          createdDate = new Date(printNodeOrderItem.printNodeOrder.created).toISOString().split('T')[0];
          oneDigitZip = printNodeOrderItem.printNodeOrder.postalcode ? printNodeOrderItem.printNodeOrder.postalcode.substring(0, 1) : '';
          if ( printNodeOrderItem.printNodeOrder.organization ) {
            serviceProvider = printNodeOrderItem.printNodeOrder.organization.serviceProvider_ID;
          }
        }
        // const createdDate = new Date(printNodeOrderItem.printNodeOrder.created).toISOString().split('T')[0];

        const binding = printNodeOrderItem.book.bindingType;
        let trimSize = '';
        if ( width / 72 === 5.5 && height / 72 === 8.5 ) {
          trimSize = '_5.5X8.5';
        } else {
          if ( width / 72 === 6.0 && height / 72 === 9.0 ) {
            trimSize = '_6.0X9.0';
          } else {
            if ( width / 72 === 8.5 && height / 72 === 11 ) {
              trimSize = '_8.5X11.0';
            } else {
              trimSize = 'Other';
            }
          }
        }

        let impositionLayout = width / 72 > 6 || height / 72 > 9 ? '_2UP' : '_4UP';
        let feetOrSheets = width / 72 > 6 || height / 72 > 9 ? quantity * pages / 4 : quantity * pages / 8;
        // Get a criteria to determine that it is comming off of a roll to make it feet
        if ( false ) {
          feetOrSheets = width / 72 > 6 || height / 72 > 9 ? quantity * pages / 4 : ( quantity * pages / 6 ) * 10 / 12;
          impositionLayout = width / 72 > 6 || height / 72 > 9 ? '_2UP' : '_3UP';
        }

        let quantityGroup = '_001-019';
        if ( quantity < 20 ) {
          quantityGroup = '_001-019';
        } else {
          if ( quantity < 50 ) {
            quantityGroup = '_020-049';
          } else {
            if ( quantity < 100 ) {
              quantityGroup = '_050-099';
            } else {
              if ( quantity < 250 ) {
                quantityGroup = '_100-249';
              } else {
                if ( quantity < 500 ) {
                  quantityGroup = '_250-499';
                } else {
                  quantityGroup = '_500+';
                }
              }
            }
          }
        }

        wrapper.lineItems += 1;
        wrapper.quantity += quantity;
        wrapper.feetOrSheets += feetOrSheets;
        wrapper.totals[0] += quantity;
        wrapper.totals[1] += feetOrSheets;
        wrapper.totals[2] += 1;
        if ( !wrapper.quantityGroup[0][quantityGroup] ) {
          wrapper.quantityGroup[0][quantityGroup] = 0;
        }
        if ( !wrapper.quantityGroup[1][quantityGroup] ) {
          wrapper.quantityGroup[1][quantityGroup] = 0;
        }
        if ( !wrapper.quantityGroup[2][quantityGroup] ) {
          wrapper.quantityGroup[2][quantityGroup] = 0;
        }
        wrapper.quantityGroup[0][quantityGroup] += quantity;
        wrapper.quantityGroup[1][quantityGroup] += feetOrSheets;
        wrapper.quantityGroup[2][quantityGroup] += 1;

        if ( !wrapper.binding[0][binding] ) {
          wrapper.binding[0][binding] = 0;
        }
        if ( !wrapper.binding[1][binding] ) {
          wrapper.binding[1][binding] = 0;
        }
        if ( !wrapper.binding[2][binding] ) {
          wrapper.binding[2][binding] = 0;
        }
        wrapper.binding[0][binding] += quantity;
        wrapper.binding[1][binding] += feetOrSheets;
        wrapper.binding[2][binding] += 1;

        if ( !wrapper.paperType[0][paperType] ) {
          wrapper.paperType[0][paperType] = 0;
        }
        if ( !wrapper.paperType[1][paperType] ) {
          wrapper.paperType[1][paperType] = 0;
        }
        if ( !wrapper.paperType[2][paperType] ) {
          wrapper.paperType[2][paperType] = 0;
        }
        wrapper.paperType[0][paperType] += quantity;
        wrapper.paperType[1][paperType] += feetOrSheets;
        wrapper.paperType[2][paperType] += 1;

        if ( !wrapper.trimSize[0][trimSize] ) {
          wrapper.trimSize[0][trimSize] = 0;
        }
        if ( !wrapper.trimSize[1][trimSize] ) {
          wrapper.trimSize[1][trimSize] = 0;
        }
        if ( !wrapper.trimSize[2][trimSize] ) {
          wrapper.trimSize[2][trimSize] = 0;
        }
        wrapper.trimSize[0][trimSize] += quantity;
        wrapper.trimSize[1][trimSize] += feetOrSheets;
        wrapper.trimSize[2][trimSize] += 1;

        if ( !wrapper.createdDate[0][createdDate] ) {
          wrapper.createdDate[0][createdDate] = 0;
        }
        if ( !wrapper.createdDate[1][createdDate] ) {
          wrapper.createdDate[1][createdDate] = 0;
        }
        if ( !wrapper.createdDate[2][createdDate] ) {
          wrapper.createdDate[2][createdDate] = 0;
        }
        wrapper.createdDate[0][createdDate] += quantity;
        wrapper.createdDate[1][createdDate] += feetOrSheets;
        wrapper.createdDate[2][createdDate] += 1;

        if ( !wrapper.lamination[0][lamination] ) {
          wrapper.lamination[0][lamination] = 0;
        }
        if ( !wrapper.lamination[1][lamination] ) {
          wrapper.lamination[1][lamination] = 0;
        }
        if ( !wrapper.lamination[2][lamination] ) {
          wrapper.lamination[2][lamination] = 0;
        }
        wrapper.lamination[0][lamination] += quantity;
        wrapper.lamination[1][lamination] += feetOrSheets;
        wrapper.lamination[2][lamination] += 1;

        if ( !wrapper.interior[0][interior] ) {
          wrapper.interior[0][interior] = 0;
        }
        if ( !wrapper.interior[1][interior] ) {
          wrapper.interior[1][interior] = 0;
        }
        if ( !wrapper.interior[2][interior] ) {
          wrapper.interior[2][interior] = 0;
        }
        wrapper.interior[0][interior] += quantity;
        wrapper.interior[1][interior] += feetOrSheets;
        wrapper.interior[2][interior] += 1;

        if ( !wrapper.impositionLayout[0][impositionLayout] ) {
          wrapper.impositionLayout[0][impositionLayout] = 0;
        }
        if ( !wrapper.impositionLayout[1][impositionLayout] ) {
          wrapper.impositionLayout[1][impositionLayout] = 0;
        }
        if ( !wrapper.impositionLayout[2][impositionLayout] ) {
          wrapper.impositionLayout[2][impositionLayout] = 0;
        }
        wrapper.impositionLayout[0][impositionLayout] += quantity;
        wrapper.impositionLayout[1][impositionLayout] += feetOrSheets;
        wrapper.impositionLayout[2][impositionLayout] += 1;

        if ( !wrapper.oneDigitZip[0][oneDigitZip] ) {
          wrapper.oneDigitZip[0][oneDigitZip] = 0;
        }
        if ( !wrapper.oneDigitZip[1][oneDigitZip] ) {
          wrapper.oneDigitZip[1][oneDigitZip] = 0;
        }
        if ( !wrapper.oneDigitZip[2][oneDigitZip] ) {
          wrapper.oneDigitZip[2][oneDigitZip] = 0;
        }
        wrapper.oneDigitZip[0][oneDigitZip] += quantity;
        wrapper.oneDigitZip[1][oneDigitZip] += feetOrSheets;
        wrapper.oneDigitZip[2][oneDigitZip] += 1;

        if ( !wrapper.serviceProvider[0][serviceProvider] ) {
          wrapper.serviceProvider[0][serviceProvider] = 0;
        }
        if ( !wrapper.serviceProvider[1][serviceProvider] ) {
          wrapper.serviceProvider[1][serviceProvider] = 0;
        }
        if ( !wrapper.serviceProvider[2][serviceProvider] ) {
          wrapper.serviceProvider[2][serviceProvider] = 0;
        }
        wrapper.serviceProvider[0][serviceProvider] += quantity;
        wrapper.serviceProvider[1][serviceProvider] += feetOrSheets;
        wrapper.serviceProvider[2][serviceProvider] += 1;
      } catch ( e ) {
        console.log(`Error setting up Wrapper Properties for a ManufacturingSet ${e.message}`);
      }

    }
  }

  // getTypes(): string[] {
  //   return ['interior', 'binding', 'lamination', 'impositionLayout', 'trimSize', 'paperType'];
  //   // return ManufacturingSetsComponent.types;
  // }
  getCategories (list: any, type: any): string[] {
    return Object.keys(list[type][0]);
  }

  getCategoryData (list: any, type: any, dataIndex: any): number[] {
    return list[type][dataIndex];
  }

  getColor (type: any): any {
    return ManufacturingSetsComponent.colors[type];
  }

  getIcons (type: any): any {
    return ManufacturingSetsComponent.icons[type];
  }

  setLocalPropertyManufacturingTask(manufacturingSet, useType: string) {
    const manufacturingTask = this.getManufacturingTask(manufacturingSet, useType);
    if ( manufacturingTask ) {
      if ( !manufacturingTask.details || !manufacturingTask.details['printType'] ) {
        try {
          manufacturingTask.details = JSON.parse(manufacturingTask.details);
        } catch ( err ) {
          console.log(`Could not parse details in manufacturingSet[${manufacturingSet.ID}] and useType[${useType}]`);
        }
      }
      manufacturingSet[useType + 'ManufacturingTask'] = manufacturingTask;
    }
  }

  getManufacturingTask(manufacturingSet: ManufacturingSet, useType: string): ManufacturingTask {
    if ( manufacturingSet && manufacturingSet.manufacturingTasks ) {
      for ( const manufacturingTask of manufacturingSet.manufacturingTasks ) {
        if ( manufacturingTask.useType === useType) {
          return manufacturingTask;
        }
      }
    }
    return undefined;
  }

  print (manufacturingSet: ManufacturingSet, useType: string, printer: string) {
    const manufacturingTask = this.getManufacturingTask(manufacturingSet, useType);
    manufacturingTask.status = 'SENT';
    this.manufacturingTaskService.print(manufacturingTask, printer).subscribe( changedManufacturingTask => {
      manufacturingTask.status = changedManufacturingTask.status;
      console.log(`Prepare Response for ManufacturingSet : ${manufacturingTask.ID} ${manufacturingTask.inputStatus}`);
    });
  }

  reprint (manufacturingSet: ManufacturingSet, useType: string, printer: string) {
    const manufacturingTask = this.getManufacturingTask(manufacturingSet, useType);
    manufacturingTask.status = 'STARTED';
    this.manufacturingTaskService.print(manufacturingTask, printer).subscribe( changedManufacturingTask => {
      manufacturingTask.status = changedManufacturingTask.status;
      console.log(`Prepare Response for ManufacturingSet : ${manufacturingTask.ID} ${manufacturingTask.inputStatus}`);
    });
  }

  printPickSheet ( manufacturingSet: ManufacturingSet, printer: string, element: any ) { // For fulfillment
    if ( this.startupService.selectedRole === 'facility') {
      this.facilityService.getItem(Number(this.startupService.selectedRoleId)).subscribe( facility => {
        const printNodeOrderIds = [];
        for ( const printNodeOrderItem of manufacturingSet.printNodeOrderItems ) {
          if ( printNodeOrderIds.indexOf(printNodeOrderItem.printNodeOrder.ID) === -1 ) {
            printNodeOrderIds.push(printNodeOrderItem.printNodeOrder.ID);
          }
        }
        element.classList.add('spinner');
        this.pickSheetService.createPickAndShipSheets (facility, printNodeOrderIds, printer, manufacturingSet.ID).subscribe( result => {
          element.classList.remove('spinner');
          console.log(`createdPickSheet for printNodeOrders: ${JSON.stringify(printNodeOrderIds)} ${printer}`);
        });
      });
    }
  }

  getManufacturingTaskStatusBackgroundColor (manufacturingTask: ManufacturingTask) {
    switch ( manufacturingTask.status ) {
      case 'INITIAL': {

      }
    }
  }

  getTypeCategories (type: string, manufacturingSet: any) {
    const x = Object.keys(manufacturingSet[type][0]);
    return Object.keys(manufacturingSet[type][0]);
  }

  // getPropertyIcon (type: string, category: string, manufacturingSet: any) {
  //   return this.icons[type][category];
  // }
  //
  // getPropertyColor (type: string, category: string, manufacturingSet: any) {
  //   return this.colors[type][category];
  // }

  createGroups() {

  }
}
