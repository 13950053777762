import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {S3Service} from "../service-auth/s3.service";
import {CoverTheme} from "../../model/editor/CoverTheme";
import {InteriorTheme} from "../../model/editor/interior-theme/InteriorTheme";
import {FontItem} from "../../model/font/FontItem";
import {InteriorThemeConfiguration} from "../../model/editor/interior-theme/InteriorThemeConfiguration";
import {ColorPalette} from "../../model/color/ColorPalette";
import {BaseColor} from "../../model/color/BaseColor";
import {ColorPaletteGroup} from "../../model/color/ColorPaletteGroup";
import {environment} from "../../environments/environment";
import {LanguagePipe} from "../pipe/language.pipe";
import {AlertService} from "../service-ui/alert.service";
import {DataService} from "./data-service";

declare const chroma: any;


@Injectable()
export class ThemeService extends DataService {
  public coverThemes: CoverTheme[] = [];
  private coverThemesObservable = of(this.coverThemes);

  public interiorThemes: InteriorTheme[] = [];
  private interiorThemesObservable = of(this.interiorThemes);

  public fonts: FontItem[] = [];
  private fontsObservable = of(this.fonts);

  public colorPalettes: ColorPalette[] = [];
  private colorPalettesObservable = of(this.colorPalettes);

  constructor (private http: HttpClient, private messageService: MessageService, private startupService: StartupService, private s3Service: S3Service, private alertService: AlertService) {
    super(startupService);
  }

    reset (filter: string = '') {
    this.resetColorPalettes();
    this.resetCoverThemes();
    this.resetFonts();
    this.resetInteriorThemes();
  }


  getCoverThemes (): Observable<CoverTheme[]> {
    if (this.coverThemes.length === 0) {
      this.fetchCoverThemes().then( coverThemes => {
        console.log('Got Cover Themes ');
      }).catch( getCoverThemesError => {
        console.warn('Get getCoverThemes did not return an object:' + getCoverThemesError);
      });

    }
    return this.coverThemesObservable;
  }

  getInteriorThemeFromName (interiorThemeName: string): InteriorTheme {
    for (const interiorTheme of this.interiorThemes) {
      if (interiorTheme.name === interiorThemeName) {
        return interiorTheme;
      }
    }
    return undefined;
  }

  getCoverThemeFromId (coverThemeId: string): CoverTheme {
    for (const coverTheme of this.coverThemes) {
      if (coverTheme.id === coverThemeId) {
        return coverTheme;
      }
    }
    return undefined;
  }

  resetCoverThemes () {
    while (this.coverThemes.length > 0) {
      const coverTheme = this.coverThemes.pop();
    }
  }


  // fetchCoverThemesxxx () {
  //   this.resetCoverThemes();
  //   const url = '/assets/cover-themes/coverthemes.json';
  //   // const url = `${environment.apiBaseURL}/covers/coverthemes.json`;
  //   // this.http.get<any>(url, this.startupService.getHttpOptions()).pipe(
  //   this.http.get<any>(url).pipe(tap(listOfCoverThemes => {
  //     console.log("listOfCoverThemes ", listOfCoverThemes);
  //     if (listOfCoverThemes["coverthemes"] && listOfCoverThemes["coverthemes"].length && listOfCoverThemes["coverthemes"].length > 0) {
  //       listOfCoverThemes["coverthemes"].map(coverThemeName => {
  //         // const url2 = `${environment.apiBaseURL}/covers/${coverThemeName}/covertheme.json`;
  //         const url2 = `/assets/cover-themes/${coverThemeName}/covertheme.json`;
  //         // this.http.get<CoverTheme>(url2, this.startupService.getHttpOptions()).pipe(
  //         this.http.get<string>(url2).pipe(tap(json => {
  //           const coverTheme = new CoverTheme(json);
  //           // coverTheme.name = coverThemeName;
  //           // coverTheme.ID = `/assets/cover-themes/${coverThemeName}/covertheme.json`;
  //
  //           this.coverThemes.push(coverTheme);
  //         }), catchError((error: any): Observable<string> => {
  //           // console.error(error, ' error getting a individual cover theme');
  //           return of(error as string);
  //         })).subscribe();
  //       });
  //     }
  //   }), catchError((error: any): Observable<string> => {
  //     console.log(error, ' error getting a cover themes');
  //     console.error(error, ' error getting a cover themes');
  //     return of(error as string);
  //   })).subscribe();
  // }

  // const url = `${environment.apiBaseURL}/styles/styles.json`;
  getInteriorThemes (): Observable<InteriorTheme[]> {
    if (this.interiorThemes.length === 0) {
      this.fetchInteriorThemes();
    }
    return this.interiorThemesObservable;
  }

  resetInteriorThemes () {
    while (this.interiorThemes.length > 0) {
      const interiorTheme = this.interiorThemes.pop();
    }
  }

  fetchInteriorThemes () {
    this.resetInteriorThemes();
    // const url = `${environment.apiBaseURL}/styles/styles.json`;
    const url = `/assets/interior-themes/interiorthemes.json`;
    this.http.get<string>(url).pipe(tap(listOfInteriorThemes => {
      console.log("interiorThemes " + listOfInteriorThemes);
      if (listOfInteriorThemes["interiorThemes"]) {
        listOfInteriorThemes["interiorThemes"].map(interiorThemeName => {
          const interiorTheme: InteriorTheme = new InteriorTheme(undefined);
          interiorTheme.name = interiorThemeName;
          interiorTheme.id = `assets/interior-themes/${interiorThemeName}/`;
          this.interiorThemes.push(interiorTheme);
        });
      }
    }), catchError((error: any): Observable<string> => {
      // console.error(error, ' error getting interiorType themes');
      return of(error as string);
    })).subscribe();
  }

  public fetchCoverThemes() {
    this.resetCoverThemes();
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    const params = {
      Bucket: environment.dataBucket,
      Prefix: 'cover-themes/'
    };

    const coverThemesPromises: Promise<CoverTheme>[] = [];

    return new Promise((resolve, reject) => {
      console.log("About to call S3.listObjects in fetchCoverThemes()");
      try {
        this.s3Service.getS3().listObjectsV2(params, (err, data) => {
          console.log(`Finished calling S3.listObjects in fetchCoverThemes()=>(err:${JSON.stringify(err)}\ndata:${JSON.stringify(data)})`);
          if (err) {
            reject(err);
            return;
          }

          data.Contents.forEach(c => {
            if (CoverThemeKey.isValid(c.Key)) {
              console.log("In fetchCoverThemes valid key for a coverTheme: " + c.Key);
              const objectRetrievedPromise = new Promise<CoverTheme>(((s3Resolve, s3Reject) => {
                this.s3Service.getS3().getObject({Bucket: environment.dataBucket, Key: c.Key}, (err2, data2) => {
                  if (err2) {
                    s3Reject(err2);
                    return;
                  }
                  const json = data2.Body.toString();
                  console.log(`cover theme json: [${json}]`);
                  const coverTheme = new CoverTheme(JSON.parse(json));
                  coverTheme.id = c.Key;

                  // Only add a cover theme that is not already added
                  let coverThemeAlreadyAdded = false;
                  for ( const existingCoverTheme of this.coverThemes ) {
                    if ( existingCoverTheme.id === coverTheme.id ) {
                      coverThemeAlreadyAdded = true;
                    }
                  }

                  if ( !coverThemeAlreadyAdded ) {
                    this.coverThemes.push(coverTheme);
                  }
                  s3Resolve(coverTheme);
                });
              }));
              coverThemesPromises.push(objectRetrievedPromise);
            }
          });

          Promise.all(coverThemesPromises).then((d) => {
            resolve(d);
          }).catch( ee => {
          });
        });

      } catch (e) {
        reject(e);
      }
    });
  }

  getCoverThemePreviewImageURL(coverTheme: CoverTheme): string {
    const idWithoutExtension = coverTheme.id.substring(0, coverTheme.id.length - 5);
    return this.s3Service.getS3().getSignedUrl('getObject', {Bucket: environment.dataBucket, Key: idWithoutExtension + '.png', Expires: 6000000});
  }


  getFonts (): Observable<FontItem[]> {
    if (this.fonts.length === 0) {
      this.fetchFonts();
    }
    return this.fontsObservable;
  }

  resetFonts () {
    while (this.fonts.length > 0) {
      const font = this.fonts.pop();
    }
  }

  fetchFonts () {
    this.resetFonts();
    // const url = `${environment.apiBaseURL}/styles/fonts.json`;
    const url = `/assets/interior-themes/fonts.json`;
    fetch(url, {
      method: 'get', headers: new Headers({
        'Content-Type': 'application/json', 'Cache-Control': 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0'
      })
    }).then(function (response) {
      console.log(response);
    }).catch(function (err) {
      console.log(err);
    });

    this.http.get<string>(url).pipe(tap(listOfFonts => {
      console.log(listOfFonts);
      if (listOfFonts["fonts"]) {
        listOfFonts["fonts"].map(item => {
          const fontItem = <FontItem> item;
          this.fonts.push(fontItem);
        });
      }
    }), catchError((error: any): Observable<string> => {
      // console.error(error, ' error getting fonts');
      return of(error as string);
    })).subscribe();
  }

  getColorPalettes (): Observable<ColorPalette[]> {
    if (this.colorPalettes.length === 0) {
      this.fetchColorPalettes();
    }
    return this.colorPalettesObservable;
  }

  resetColorPalettes () {
    while (this.colorPalettes.length > 0) {
      const colorPalette = this.colorPalettes.pop();
    }
  }

  fetchColorPalettes () {
    this.resetColorPalettes();
    // const url = `${environment.apiBaseURL}/styles/colorPalettes.json`;
    const url = `/assets/cover-themes/colorPalettes.json`;
    fetch(url, {
      method: 'get', headers: new Headers({
        'Content-Type': 'application/json', 'Cache-Control': 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0'
      })
    }).then(function (response) {
      console.log(response);
    }).catch(function (err) {
      console.log(err);
    });

    this.http.get<string>(url).pipe(tap(listOfColorPalettes => {
      console.log(listOfColorPalettes);
      if (listOfColorPalettes["colorPalettes"]) {
        listOfColorPalettes["colorPalettes"].map(item => {
          // const colorPaletteItem = <ColorPalette> item;
          const colorPaletteItem = new ColorPalette(item);
          colorPaletteItem.type = 'Manual';
          this.setDarkerAndLighter(colorPaletteItem);
          this.colorPalettes.push(colorPaletteItem);
        });
      }
      // for ( const c of this.getColors() ) {
      //   for ( const s of  Object.values(c.shades).slice(0, 10)) {
      //     const colorPaletteItem1 = new ColorPalette( undefined );
      //     colorPaletteItem1.name = c.name + " " + s.hex;
      //     colorPaletteItem1.primary.normal.color = s.hex;
      //     this.generateComplementary(colorPaletteItem1);
      //     this.colorPalettes.push(colorPaletteItem1);
      //     const colorPaletteItem2 = new ColorPalette( undefined );
      //     colorPaletteItem2.name = c.name + " " + s.hex;
      //     colorPaletteItem2.primary.normal.color = s.hex;
      //     this.generateRectangle(colorPaletteItem2);
      //     this.colorPalettes.push(colorPaletteItem2);
      //     const colorPaletteItem3 = new ColorPalette( undefined );
      //     colorPaletteItem3.name = c.name + " " + s.hex;
      //     colorPaletteItem3.primary.normal.color = s.hex;
      //     this.generateSplitComplementary(colorPaletteItem3);
      //     this.colorPalettes.push(colorPaletteItem3);
      //     const colorPaletteItem4 = new ColorPalette( undefined );
      //     colorPaletteItem4.name = c.name + " " + s.hex;
      //     colorPaletteItem4.primary.normal.color = s.hex;
      //     this.generateTriad(colorPaletteItem4);
      //     this.colorPalettes.push(colorPaletteItem4);
      //     const colorPaletteItem5 = new ColorPalette( undefined );
      //     colorPaletteItem5.name = c.name + " " + s.hex;
      //     colorPaletteItem5.primary.normal.color = s.hex;
      //     this.generateTriad(colorPaletteItem5);
      //     this.colorPalettes.push(colorPaletteItem5);
      //   }
      // }
    }), catchError((error: any): Observable<string> => {
      // console.error(error, ' error getting colorPalettes');
      return of(error as string);
    })).subscribe();
  }

  setDarkerAndLighter(colorPalette: ColorPalette) {
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.primary);
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.secondary);
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.tertiary);
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.quaternary);
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.quinary);
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.senary);
  }

  setDarkerAndLighterColorPaletteGroup(colorPaletteGroup: ColorPaletteGroup) {
    if ( chroma ) {
      try {
        colorPaletteGroup.dark.color = chroma( colorPaletteGroup.normal.color ).darken().hex();
        colorPaletteGroup.light.color = chroma( colorPaletteGroup.normal.color ).brighten().hex();
      } catch ( err ) {
        console.log('Error working with a color ' + colorPaletteGroup.normal.color + ' ' + err);
      }
    }
  }

  getInteriorThemeConfiguration (): Observable<InteriorThemeConfiguration> {
    const url = `/assets/interior-themes/interiorthemeconfiguration.json`;
    return this.http.get<InteriorThemeConfiguration>(url).pipe(tap(interiorThemeConfigurationJSON => {
      console.log("InteriorThemeConfiguration " + interiorThemeConfigurationJSON);
      // if (interiorThemeConfigurationJSON) {
      //   const interiorThemeConfiguration: InteriorThemeConfiguration = new InteriorThemeConfiguration(interiorThemeConfigurationJSON);
      //   return of(interiorThemeConfiguration);
      // }
    }), catchError((error: any): Observable<any> => {
      // console.error(error, ' error getting interiorType themes');
      return of(error as InteriorThemeConfiguration);
    }));
  }

  getColors (): BaseColor[] {
    const COLORS: BaseColor[] = [
      {
        'name': 'red', 'key': 'red', 'shades': {
        '50': {
          'hex': '#ffebee', 'contrast': 'black'
        }, '100': {
          'hex': '#ffcdd2', 'contrast': 'black'
        }, '200': {
          'hex': '#ef9a9a', 'contrast': 'black'
        }, '300': {
          'hex': '#e57373', 'contrast': 'black'
        }, '400': {
          'hex': '#ef5350', 'contrast': 'black'
        }, '500': {
          'hex': '#f44336', 'contrast': 'white'
        }, '600': {
          'hex': '#e53935', 'contrast': 'white'
        }, '700': {
          'hex': '#d32f2f', 'contrast': 'white'
        }, '800': {
          'hex': '#c62828', 'contrast': 'white'
        }, '900': {
          'hex': '#b71c1c', 'contrast': 'white'
        }, 'A100': {
          'hex': '#ff8a80', 'contrast': 'black'
        }, 'A200': {
          'hex': '#ff5252', 'contrast': 'white'
        }, 'A400': {
          'hex': '#ff1744', 'contrast': 'white'
        }, 'A700': {
          'hex': '#d50000', 'contrast': 'white'
        }
      }
      }, {
        'name': 'pink', 'key': 'pink', 'shades': {
          '50': {
            'hex': '#fce4ec', 'contrast': 'black'
          }, '100': {
            'hex': '#f8bbd0', 'contrast': 'black'
          }, '200': {
            'hex': '#f48fb1', 'contrast': 'black'
          }, '300': {
            'hex': '#f06292', 'contrast': 'black'
          }, '400': {
            'hex': '#ec407a', 'contrast': 'black'
          }, '500': {
            'hex': '#e91e63', 'contrast': 'white'
          }, '600': {
            'hex': '#d81b60', 'contrast': 'white'
          }, '700': {
            'hex': '#c2185b', 'contrast': 'white'
          }, '800': {
            'hex': '#ad1457', 'contrast': 'white'
          }, '900': {
            'hex': '#880e4f', 'contrast': 'white'
          }, 'A100': {
            'hex': '#ff80ab', 'contrast': 'black'
          }, 'A200': {
            'hex': '#ff4081', 'contrast': 'white'
          }, 'A400': {
            'hex': '#f50057', 'contrast': 'white'
          }, 'A700': {
            'hex': '#c51162', 'contrast': 'white'
          }
        }
      }, {
        'name': 'purple', 'key': 'purple', 'shades': {
          '50': {
            'hex': '#f3e5f5', 'contrast': 'black'
          }, '100': {
            'hex': '#e1bee7', 'contrast': 'black'
          }, '200': {
            'hex': '#ce93d8', 'contrast': 'black'
          }, '300': {
            'hex': '#ba68c8', 'contrast': 'white'
          }, '400': {
            'hex': '#ab47bc', 'contrast': 'white'
          }, '500': {
            'hex': '#9c27b0', 'contrast': 'white'
          }, '600': {
            'hex': '#8e24aa', 'contrast': 'white'
          }, '700': {
            'hex': '#7b1fa2', 'contrast': 'white'
          }, '800': {
            'hex': '#6a1b9a', 'contrast': 'white'
          }, '900': {
            'hex': '#4a148c', 'contrast': 'white'
          }, 'A100': {
            'hex': '#ea80fc', 'contrast': 'black'
          }, 'A200': {
            'hex': '#e040fb', 'contrast': 'white'
          }, 'A400': {
            'hex': '#d500f9', 'contrast': 'white'
          }, 'A700': {
            'hex': '#aa00ff', 'contrast': 'white'
          }
        }
      }, {
        'name': 'deeppurple', 'key': 'deep-purple', 'shades': {
          '50': {
            'hex': '#ede7f6', 'contrast': 'black'
          }, '100': {
            'hex': '#d1c4e9', 'contrast': 'black'
          }, '200': {
            'hex': '#b39ddb', 'contrast': 'black'
          }, '300': {
            'hex': '#9575cd', 'contrast': 'white'
          }, '400': {
            'hex': '#7e57c2', 'contrast': 'white'
          }, '500': {
            'hex': '#673ab7', 'contrast': 'white'
          }, '600': {
            'hex': '#5e35b1', 'contrast': 'white'
          }, '700': {
            'hex': '#512da8', 'contrast': 'white'
          }, '800': {
            'hex': '#4527a0', 'contrast': 'white'
          }, '900': {
            'hex': '#311b92', 'contrast': 'white'
          }, 'A100': {
            'hex': '#b388ff', 'contrast': 'black'
          }, 'A200': {
            'hex': '#7c4dff', 'contrast': 'white'
          }, 'A400': {
            'hex': '#651fff', 'contrast': 'white'
          }, 'A700': {
            'hex': '#6200ea', 'contrast': 'white'
          }
        }
      }, {
        'name': 'indigo', 'key': 'indigo', 'shades': {
          '50': {
            'hex': '#e8eaf6', 'contrast': 'black'
          }, '100': {
            'hex': '#c5cae9', 'contrast': 'black'
          }, '200': {
            'hex': '#9fa8da', 'contrast': 'black'
          }, '300': {
            'hex': '#7986cb', 'contrast': 'white'
          }, '400': {
            'hex': '#5c6bc0', 'contrast': 'white'
          }, '500': {
            'hex': '#3f51b5', 'contrast': 'white'
          }, '600': {
            'hex': '#3949ab', 'contrast': 'white'
          }, '700': {
            'hex': '#303f9f', 'contrast': 'white'
          }, '800': {
            'hex': '#283593', 'contrast': 'white'
          }, '900': {
            'hex': '#1a237e', 'contrast': 'white'
          }, 'A100': {
            'hex': '#8c9eff', 'contrast': 'black'
          }, 'A200': {
            'hex': '#536dfe', 'contrast': 'white'
          }, 'A400': {
            'hex': '#3d5afe', 'contrast': 'white'
          }, 'A700': {
            'hex': '#304ffe', 'contrast': 'white'
          }
        }
      }, {
        'name': 'blue', 'key': 'blue', 'shades': {
          '50': {
            'hex': '#e3f2fd', 'contrast': 'black'
          }, '100': {
            'hex': '#bbdefb', 'contrast': 'black'
          }, '200': {
            'hex': '#90caf9', 'contrast': 'black'
          }, '300': {
            'hex': '#64b5f6', 'contrast': 'black'
          }, '400': {
            'hex': '#42a5f5', 'contrast': 'black'
          }, '500': {
            'hex': '#2196f3', 'contrast': 'white'
          }, '600': {
            'hex': '#1e88e5', 'contrast': 'white'
          }, '700': {
            'hex': '#1976d2', 'contrast': 'white'
          }, '800': {
            'hex': '#1565c0', 'contrast': 'white'
          }, '900': {
            'hex': '#0d47a1', 'contrast': 'white'
          }, 'A100': {
            'hex': '#82b1ff', 'contrast': 'black'
          }, 'A200': {
            'hex': '#448aff', 'contrast': 'white'
          }, 'A400': {
            'hex': '#2979ff', 'contrast': 'white'
          }, 'A700': {
            'hex': '#2962ff', 'contrast': 'white'
          }
        }
      }, {
        'name': 'lightblue', 'key': 'light-blue', 'shades': {
          '50': {
            'hex': '#e1f5fe', 'contrast': 'black'
          }, '100': {
            'hex': '#b3e5fc', 'contrast': 'black'
          }, '200': {
            'hex': '#81d4fa', 'contrast': 'black'
          }, '300': {
            'hex': '#4fc3f7', 'contrast': 'black'
          }, '400': {
            'hex': '#29b6f6', 'contrast': 'black'
          }, '500': {
            'hex': '#03a9f4', 'contrast': 'white'
          }, '600': {
            'hex': '#039be5', 'contrast': 'white'
          }, '700': {
            'hex': '#0288d1', 'contrast': 'white'
          }, '800': {
            'hex': '#0277bd', 'contrast': 'white'
          }, '900': {
            'hex': '#01579b', 'contrast': 'white'
          }, 'A100': {
            'hex': '#80d8ff', 'contrast': 'black'
          }, 'A200': {
            'hex': '#40c4ff', 'contrast': 'black'
          }, 'A400': {
            'hex': '#00b0ff', 'contrast': 'black'
          }, 'A700': {
            'hex': '#0091ea', 'contrast': 'white'
          }
        }
      }, {
        'name': 'cyan', 'key': 'cyan', 'shades': {
          '50': {
            'hex': '#e0f7fa', 'contrast': 'black'
          }, '100': {
            'hex': '#b2ebf2', 'contrast': 'black'
          }, '200': {
            'hex': '#80deea', 'contrast': 'black'
          }, '300': {
            'hex': '#4dd0e1', 'contrast': 'black'
          }, '400': {
            'hex': '#26c6da', 'contrast': 'black'
          }, '500': {
            'hex': '#00bcd4', 'contrast': 'white'
          }, '600': {
            'hex': '#00acc1', 'contrast': 'white'
          }, '700': {
            'hex': '#0097a7', 'contrast': 'white'
          }, '800': {
            'hex': '#00838f', 'contrast': 'white'
          }, '900': {
            'hex': '#006064', 'contrast': 'white'
          }, 'A100': {
            'hex': '#84ffff', 'contrast': 'black'
          }, 'A200': {
            'hex': '#18ffff', 'contrast': 'black'
          }, 'A400': {
            'hex': '#00e5ff', 'contrast': 'black'
          }, 'A700': {
            'hex': '#00b8d4', 'contrast': 'black'
          }
        }
      }, {
        'name': 'teal', 'key': 'teal', 'shades': {
          '50': {
            'hex': '#e0f2f1', 'contrast': 'black'
          }, '100': {
            'hex': '#b2dfdb', 'contrast': 'black'
          }, '200': {
            'hex': '#80cbc4', 'contrast': 'black'
          }, '300': {
            'hex': '#4db6ac', 'contrast': 'black'
          }, '400': {
            'hex': '#26a69a', 'contrast': 'black'
          }, '500': {
            'hex': '#009688', 'contrast': 'white'
          }, '600': {
            'hex': '#00897b', 'contrast': 'white'
          }, '700': {
            'hex': '#00796b', 'contrast': 'white'
          }, '800': {
            'hex': '#00695c', 'contrast': 'white'
          }, '900': {
            'hex': '#004d40', 'contrast': 'white'
          }, 'A100': {
            'hex': '#a7ffeb', 'contrast': 'black'
          }, 'A200': {
            'hex': '#64ffda', 'contrast': 'black'
          }, 'A400': {
            'hex': '#1de9b6', 'contrast': 'black'
          }, 'A700': {
            'hex': '#00bfa5', 'contrast': 'black'
          }
        }
      }, {
        'name': 'green', 'key': 'green', 'shades': {
          '50': {
            'hex': '#e8f5e9', 'contrast': 'black'
          }, '100': {
            'hex': '#c8e6c9', 'contrast': 'black'
          }, '200': {
            'hex': '#a5d6a7', 'contrast': 'black'
          }, '300': {
            'hex': '#81c784', 'contrast': 'black'
          }, '400': {
            'hex': '#66bb6a', 'contrast': 'black'
          }, '500': {
            'hex': '#4caf50', 'contrast': 'white'
          }, '600': {
            'hex': '#43a047', 'contrast': 'white'
          }, '700': {
            'hex': '#388e3c', 'contrast': 'white'
          }, '800': {
            'hex': '#2e7d32', 'contrast': 'white'
          }, '900': {
            'hex': '#1b5e20', 'contrast': 'white'
          }, 'A100': {
            'hex': '#b9f6ca', 'contrast': 'black'
          }, 'A200': {
            'hex': '#69f0ae', 'contrast': 'black'
          }, 'A400': {
            'hex': '#00e676', 'contrast': 'black'
          }, 'A700': {
            'hex': '#00c853', 'contrast': 'black'
          }
        }
      }, {
        'name': 'lightgreen', 'key': 'light-green', 'shades': {
          '50': {
            'hex': '#f1f8e9', 'contrast': 'black'
          }, '100': {
            'hex': '#dcedc8', 'contrast': 'black'
          }, '200': {
            'hex': '#c5e1a5', 'contrast': 'black'
          }, '300': {
            'hex': '#aed581', 'contrast': 'black'
          }, '400': {
            'hex': '#9ccc65', 'contrast': 'black'
          }, '500': {
            'hex': '#8bc34a', 'contrast': 'black'
          }, '600': {
            'hex': '#7cb342', 'contrast': 'black'
          }, '700': {
            'hex': '#689f38', 'contrast': 'black'
          }, '800': {
            'hex': '#558b2f', 'contrast': 'white'
          }, '900': {
            'hex': '#33691e', 'contrast': 'white'
          }, 'A100': {
            'hex': '#ccff90', 'contrast': 'black'
          }, 'A200': {
            'hex': '#b2ff59', 'contrast': 'black'
          }, 'A400': {
            'hex': '#76ff03', 'contrast': 'black'
          }, 'A700': {
            'hex': '#64dd17', 'contrast': 'black'
          }
        }
      }, {
        'name': 'lime', 'key': 'lime', 'shades': {
          '50': {
            'hex': '#f9fbe7', 'contrast': 'black'
          }, '100': {
            'hex': '#f0f4c3', 'contrast': 'black'
          }, '200': {
            'hex': '#e6ee9c', 'contrast': 'black'
          }, '300': {
            'hex': '#dce775', 'contrast': 'black'
          }, '400': {
            'hex': '#d4e157', 'contrast': 'black'
          }, '500': {
            'hex': '#cddc39', 'contrast': 'black'
          }, '600': {
            'hex': '#c0ca33', 'contrast': 'black'
          }, '700': {
            'hex': '#afb42b', 'contrast': 'black'
          }, '800': {
            'hex': '#9e9d24', 'contrast': 'black'
          }, '900': {
            'hex': '#827717', 'contrast': 'white'
          }, 'A100': {
            'hex': '#f4ff81', 'contrast': 'black'
          }, 'A200': {
            'hex': '#eeff41', 'contrast': 'black'
          }, 'A400': {
            'hex': '#c6ff00', 'contrast': 'black'
          }, 'A700': {
            'hex': '#aeea00', 'contrast': 'black'
          }
        }
      }, {
        'name': 'yellow', 'key': 'yellow', 'shades': {
          '50': {
            'hex': '#fffde7', 'contrast': 'black'
          }, '100': {
            'hex': '#fff9c4', 'contrast': 'black'
          }, '200': {
            'hex': '#fff59d', 'contrast': 'black'
          }, '300': {
            'hex': '#fff176', 'contrast': 'black'
          }, '400': {
            'hex': '#ffee58', 'contrast': 'black'
          }, '500': {
            'hex': '#ffeb3b', 'contrast': 'black'
          }, '600': {
            'hex': '#fdd835', 'contrast': 'black'
          }, '700': {
            'hex': '#fbc02d', 'contrast': 'black'
          }, '800': {
            'hex': '#f9a825', 'contrast': 'black'
          }, '900': {
            'hex': '#f57f17', 'contrast': 'black'
          }, 'A100': {
            'hex': '#ffff8d', 'contrast': 'black'
          }, 'A200': {
            'hex': '#ffff00', 'contrast': 'black'
          }, 'A400': {
            'hex': '#ffea00', 'contrast': 'black'
          }, 'A700': {
            'hex': '#ffd600', 'contrast': 'black'
          }
        }
      }, {
        'name': 'amber', 'key': 'amber', 'shades': {
          '50': {
            'hex': '#fff8e1', 'contrast': 'black'
          }, '100': {
            'hex': '#ffecb3', 'contrast': 'black'
          }, '200': {
            'hex': '#ffe082', 'contrast': 'black'
          }, '300': {
            'hex': '#ffd54f', 'contrast': 'black'
          }, '400': {
            'hex': '#ffca28', 'contrast': 'black'
          }, '500': {
            'hex': '#ffc107', 'contrast': 'black'
          }, '600': {
            'hex': '#ffb300', 'contrast': 'black'
          }, '700': {
            'hex': '#ffa000', 'contrast': 'black'
          }, '800': {
            'hex': '#ff8f00', 'contrast': 'black'
          }, '900': {
            'hex': '#ff6f00', 'contrast': 'black'
          }, 'A100': {
            'hex': '#ffe57f', 'contrast': 'black'
          }, 'A200': {
            'hex': '#ffd740', 'contrast': 'black'
          }, 'A400': {
            'hex': '#ffc400', 'contrast': 'black'
          }, 'A700': {
            'hex': '#ffab00', 'contrast': 'black'
          }
        }
      }, {
        'name': 'orange', 'key': 'orange', 'shades': {
          '50': {
            'hex': '#fff3e0', 'contrast': 'black'
          }, '100': {
            'hex': '#ffe0b2', 'contrast': 'black'
          }, '200': {
            'hex': '#ffcc80', 'contrast': 'black'
          }, '300': {
            'hex': '#ffb74d', 'contrast': 'black'
          }, '400': {
            'hex': '#ffa726', 'contrast': 'black'
          }, '500': {
            'hex': '#ff9800', 'contrast': 'black'
          }, '600': {
            'hex': '#fb8c00', 'contrast': 'black'
          }, '700': {
            'hex': '#f57c00', 'contrast': 'black'
          }, '800': {
            'hex': '#ef6c00', 'contrast': 'white'
          }, '900': {
            'hex': '#e65100', 'contrast': 'white'
          }, 'A100': {
            'hex': '#ffd180', 'contrast': 'black'
          }, 'A200': {
            'hex': '#ffab40', 'contrast': 'black'
          }, 'A400': {
            'hex': '#ff9100', 'contrast': 'black'
          }, 'A700': {
            'hex': '#ff6d00', 'contrast': 'black'
          }
        }
      }, {
        'name': 'deeporange', 'key': 'deep-orange', 'shades': {
          '50': {
            'hex': '#fbe9e7', 'contrast': 'black'
          }, '100': {
            'hex': '#ffccbc', 'contrast': 'black'
          }, '200': {
            'hex': '#ffab91', 'contrast': 'black'
          }, '300': {
            'hex': '#ff8a65', 'contrast': 'black'
          }, '400': {
            'hex': '#ff7043', 'contrast': 'black'
          }, '500': {
            'hex': '#ff5722', 'contrast': 'white'
          }, '600': {
            'hex': '#f4511e', 'contrast': 'white'
          }, '700': {
            'hex': '#e64a19', 'contrast': 'white'
          }, '800': {
            'hex': '#d84315', 'contrast': 'white'
          }, '900': {
            'hex': '#bf360c', 'contrast': 'white'
          }, 'A100': {
            'hex': '#ff9e80', 'contrast': 'black'
          }, 'A200': {
            'hex': '#ff6e40', 'contrast': 'black'
          }, 'A400': {
            'hex': '#ff3d00', 'contrast': 'white'
          }, 'A700': {
            'hex': '#dd2c00', 'contrast': 'white'
          }
        }
      }, {
        'name': 'brown', 'key': 'brown', 'shades': {
          '50': {
            'hex': '#efebe9', 'contrast': 'black'
          }, '100': {
            'hex': '#d7ccc8', 'contrast': 'black'
          }, '200': {
            'hex': '#bcaaa4', 'contrast': 'black'
          }, '300': {
            'hex': '#a1887f', 'contrast': 'white'
          }, '400': {
            'hex': '#8d6e63', 'contrast': 'white'
          }, '500': {
            'hex': '#795548', 'contrast': 'white'
          }, '600': {
            'hex': '#6d4c41', 'contrast': 'white'
          }, '700': {
            'hex': '#5d4037', 'contrast': 'white'
          }, '800': {
            'hex': '#4e342e', 'contrast': 'white'
          }, '900': {
            'hex': '#3e2723', 'contrast': 'white'
          }, 'A100': {
            'hex': '#000000', 'contrast': 'white'
          }, 'A200': {
            'hex': '#ffffff', 'contrast': 'black'
          }, 'A400': {
            'hex': '#000000', 'contrast': 'white'
          }, 'A700': {
            'hex': '#ffffff', 'contrast': 'black'
          }
        }
      }, {
        'name': 'grey', 'key': 'grey', 'shades': {
          '50': {
            'hex': '#fafafa', 'contrast': 'black'
          }, '100': {
            'hex': '#f5f5f5', 'contrast': 'black'
          }, '200': {
            'hex': '#eeeeee', 'contrast': 'black'
          }, '300': {
            'hex': '#e0e0e0', 'contrast': 'black'
          }, '400': {
            'hex': '#bdbdbd', 'contrast': 'black'
          }, '500': {
            'hex': '#9e9e9e', 'contrast': 'black'
          }, '600': {
            'hex': '#757575', 'contrast': 'white'
          }, '700': {
            'hex': '#616161', 'contrast': 'white'
          }, '800': {
            'hex': '#424242', 'contrast': 'white'
          }, '900': {
            'hex': '#212121', 'contrast': 'white'
          }, 'A100': {
            'hex': '#ffffff', 'contrast': 'black'
          }, 'A200': {
            'hex': '#000000', 'contrast': 'white'
          }, 'A400': {
            'hex': '#ffffff', 'contrast': 'black'
          }, 'A700': {
            'hex': '#000000', 'contrast': 'white'
          }
        }
      }, {
        'name': 'bluegrey', 'key': 'blue-grey', 'shades': {
          '50': {
            'hex': '#eceff1', 'contrast': 'black'
          }, '100': {
            'hex': '#cfd8dc', 'contrast': 'black'
          }, '200': {
            'hex': '#b0bec5', 'contrast': 'black'
          }, '300': {
            'hex': '#90a4ae', 'contrast': 'black'
          }, '400': {
            'hex': '#78909c', 'contrast': 'white'
          }, '500': {
            'hex': '#607d8b', 'contrast': 'white'
          }, '600': {
            'hex': '#546e7a', 'contrast': 'white'
          }, '700': {
            'hex': '#455a64', 'contrast': 'white'
          }, '800': {
            'hex': '#37474f', 'contrast': 'white'
          }, '900': {
            'hex': '#263238', 'contrast': 'white'
          }, 'A100': {
            'hex': '#000000', 'contrast': 'white'
          }, 'A200': {
            'hex': '#ffffff', 'contrast': 'black'
          }, 'A400': {
            'hex': '#000000', 'contrast': 'white'
          }, 'A700': {
            'hex': '#ffffff', 'contrast': 'black'
          }
        }
      }, {
        'name': 'grey', 'key': 'grey', 'shades': {
          '50': {
            'hex': '#ffffff', 'contrast': 'black'
          }, '100': {
            'hex': '#eeeeee', 'contrast': 'black'
          }, '200': {
            'hex': '#dddddd', 'contrast': 'black'
          }, '300': {
            'hex': '#cccccc', 'contrast': 'black'
          }, '400': {
            'hex': '#aaaaaa', 'contrast': 'white'
          }, '500': {
            'hex': '#888888', 'contrast': 'white'
          }, '600': {
            'hex': '#666666', 'contrast': 'white'
          }, '700': {
            'hex': '#444444', 'contrast': 'white'
          }, '800': {
            'hex': '#222222', 'contrast': 'white'
          }, '900': {
            'hex': '#000000', 'contrast': 'white'
          }, 'A100': {
            'hex': '#000000', 'contrast': 'white'
          }, 'A200': {
            'hex': '#ffffff', 'contrast': 'black'
          }, 'A400': {
            'hex': '#000000', 'contrast': 'white'
          }, 'A700': {
            'hex': '#ffffff', 'contrast': 'black'
          }
        }
      }
    ];
    return COLORS;
  }
}
export class InteriorThemeKey {

  private userId: string;
  private interiorTheme: string;

  public static isValid(input: string) {
    if (input && input.startsWith('interior-theme') && input.endsWith('.json')) {
      return true;
    }
    return false;
  }
  public constructor(userId: string, interiorTheme: string) {
    this.userId = userId;
    this.interiorTheme = interiorTheme;
  }

  public toString(): string {
    return `interior-theme/${this.interiorTheme}.json`;
  }
}

export class CoverThemeKey {

  private userId: string;
  private coverTheme: string;

  public static isValid(input: string) {
    if (input && input.startsWith('cover-theme') && input.endsWith('.json')) {
      return true;
    }
    return false;
  }
  public constructor(userId: string, coverTheme: string) {
    this.userId = userId;
    this.coverTheme = coverTheme;
  }

  public toString(): string {
    return `cover-theme/${this.coverTheme}.json`;
  }
}
