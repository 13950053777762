import {DataObject} from "./DataObject";

export class Organization implements DataObject {
  ID: number;
  name: string;
  description: string | null;
  admin_ID: number;
  created: Date;
  serviceProvider_ID: number;
  collectionprovider_ID: number;
  agent_ID: number;
  printnode_ID: number;
  hasActiveTab: string;
  secret: string;
  fromName: string | null;
  fromAddress1: string | null;
  fromAddress2: string | null;
  fromAddress3: string | null;
  fromCity: string | null;
  fromState: string | null;
  fromPostal: string | null;
  fromCountry: string | null;
  fromPhone: string | null;
  fromEmail: string | null;
  returnName: string | null;
  returnAddress1: string | null;
  returnAddress2: string | null;
  returnAddress3: string | null;
  returnCity: string | null;
  returnState: string | null;
  returnPostal: string | null;
  returnCountry: string | null;
  returnPhone: string | null;
  returnEmail: string | null;
  packingSlipCompanyName: string | null;
  packingSlipReturnPolicy: string | null;
  UPSNumber: string | null;
  FedexNumber: string | null;
  shippingMarginDiscount: number;
  packageLabelResource_ID: number | null;
  acceptBubbledProductChanges: string;
  contactName: string | null;
  contactNumber: string | null;
  updated: Date;
  contactFirstName: string | null;
  contactLastName: string | null;
  contactCompanyName: string | null;
  contactAddress1: string | null;
  contactAddress2: string | null;
  contactCity: string | null;
  contactState: string | null;
  contactPostal: string | null;
  contactCountry: string | null;
  contactEmail: string | null;
  category: string | null;
  canSetBindingType: string;
  canOrderShortRun: string;
  canOrderOffset: string;
  shipmentNoticeEmail: string | null;
  canSetLaminateType: string;
  invoiceClientId: number;
  invoiceRecurringId: number;
  crmId: number | null;
  canSetInteriorType: string;
  productionRate: string | null;
  canOrderPrintToPallet: string;
  canCheckOutWithInvoicePayment: string;
  isColoradoCompany: string;
  haveSalesTaxExemptionForm: string;
  gatewaySettings: string | null;
  tax: string | null;
  lastUpdate: Date;
  canUseDiscountCode: string;
  network_id: number;
  orderConfirmationEmail: string | null;
  fromEmailForEndConsumerNotification: string | null;
  sendOrderConfirmationToEndConsumer: string;
  sentShipmentNotificationToEndConsumer: string;
  emailLanguage: string;
  renderSystem: string;
  salesTaxExemptionType: string;
  catalogs: string;
  microInventory: string;
  // serviceProvider: ServiceProvider;
  // ecommerceConnections: EcommerceConnection[];
  // orders: Order[];
  // printNodeOrders: PrintNodeOrder[];
  // products: Product[];
  // users: User[];


  constructor ( obj ) {
    if ( obj && obj['ID']) {
      this.ID = obj['ID'];
    }
    this.update(obj);
  }

  getId() {
    return this.ID;
  }

  getOrderValue() {
    return this.ID;
  }

  update(obj: any) {
    if (obj) {
      for (const prop of Object.keys(obj)) {
        if (obj[prop] !== null && (prop !== 'ID') && ( typeof obj[prop] === 'string' || typeof obj[prop] === 'number' || typeof obj[prop] === 'boolean' || obj[prop] instanceof String || obj[prop] instanceof Boolean || obj[prop] instanceof Number || obj[prop] instanceof Date )) {
          this[prop] = obj[prop];
        }
      }
    }
  }
}

