/* tslint:disable:max-line-length */
import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {PrintNodeOrderItem} from "../../../model/PrintNodeOrderItem";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {EventHandler} from "../../table/EventHandler";
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {PrintNodeOrderItemService} from "../../service-data/print-node-order-item.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {Package} from "../../../model/Package";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ChangePackagesComponent} from "../facility-packages/facility-package/change-packages/change-packages.component";
import {TableComponent} from "../../table/table.component";
import {ManufacturingSet} from "../../../model/ManufacturingSet";
import {LanguagePipe} from "../../pipe/language.pipe";

@Component({
  selector: 'app-print-node-order-items',
  templateUrl: './print-node-order-items.component.html',
  styleUrls: ['./print-node-order-items.component.css']
})

export class PrintNodeOrderItemsComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  paperTypes = {
    1: 'Heavy',
    2: 'Light',
    4: 'Creme',
    5: '60#',
    6: '80# Matte Text',
    7: '80# Gloss Text',
    8: '100# Gloss Text'
  };
  paperTypePresentationColors = {
    1: '',
    2: 'darkgray',
    3: 'darkgray',
    4: 'darkorange',
    5: 'darkblue',
    6: 'darkmagenta',
    7: 'darkcyan',
    8: 'darkred'
  };
  bindingTypePresentationColors = {
    PERFECT: '',
    COIL: 'darkgreen',
    SADDLE_STITCH: 'red'
  };
  laminateTypePresentationColors = {
    GLOSS: '',
    MATTE: 'darkgrey'
  };

  columnsWithProductionData = [
    { property: 'printNodeOrder.created',                columnDef: 'created',                header: 'Created',              sort: true,  type: 'date',            filterType: 'String',    style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.created}`, dateFormat: 'MM/dd'},
    { property: 'printNodeOrder.dueDate',                columnDef: 'dueDate',                header: 'Due Date',             sort: true,  type: 'date',            filterType: 'String',    style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.dueDate}`, dateFormat: 'MM/dd'},
    { property: 'printNodeOrder.ID',                     columnDef: 'printNodeOrderId',       header: 'Order Id',             sort: true,  type: 'number',          filterType: 'String',    style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.ID}`   },
    { property: 'printNodeOrder.printnode_ID',           columnDef: 'printNodeId',            header: 'Facility',             sort: true,  type: 'number',          filterType: 'String',    style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.printnode_ID}`   },
    { property: 'manufacturingSet.manufacturingTaskBucketPeriod.period',  columnDef: 'period', header: 'Period',              sort: true,  type: 'string',          filterType: 'String',          styleX: (element: any) => `${"max-height: 20px;  text-overflow: clip; text-align: right; background-color: " + this.getBackgroundColor(element.manufacturingSet && element.manufacturingSet.manufacturingTaskBucketPeriod && element.manufacturingSet.manufacturingTaskBucketPeriod.period ? element.manufacturingSet.manufacturingTaskBucketPeriod.period : 'D2000-01-01') }`,  cell: (element: any) => `${element.manufacturingSet && element.manufacturingSet.manufacturingTaskBucketPeriod && element.manufacturingSet.manufacturingTaskBucketPeriod.period ? element.manufacturingSet.manufacturingTaskBucketPeriod.period.substring(6) : 'D2000-01-01'}`   },
    { property: 'manufacturingset_id',                   columnDef: 'manufacturingSetId',     header: 'Set',                  sort: true,  type: 'number',          filterType: 'NumericRange',    styleX: (element: any) => `${"max-height: 20px;  text-overflow: clip; text-align: right; background-color: " + (element.printNodeOrder.manufacturingset_id === 0 ? 'red' : '') }`,  cell: (element: any) => `${element.manufacturingset_id}`   },
    { property: 'manufacturingSet.nextManufacturingTasksToExecuteFromRoots',  columnDef: 'nextTasks', header: 'Next Tasks',   sort: true,  type: 'string',          filterType: 'String',          styleX: (element: any) => `${"max-height: 20px;  text-overflow: clip; text-align: right; background-color: " + this.getNextManufacturingTasksBackgroundColor(element.manufacturingSet) }`,  cell: (element: any) => `${element.manufacturingSet ? this.getNextManufacturingTasksString(element.manufacturingSet) : ''}` },
    { property: 'printNodeOrder.servicelevel',           columnDef: 'serviceLevel',           header: 'Service Level',        sort: true,  type: 'string',          filterType: 'DataEnum',        filterEnums: [LanguagePipe.get('PRIORITY'), LanguagePipe.get('MEDIA'), LanguagePipe.get('GROUND'), LanguagePipe.get('PRIORITY_FLAT')],    style: 'max-height: 20px;  text-overflow: clip;text-align: right',  cell: (element: any) => `${LanguagePipe.get(element.printNodeOrder.servicelevel)}`, values: { } },
    { property: 'book.ID',                               columnDef: 'bookId',                 header: 'Book Id',              sort: true,  type: 'number',          filterType: 'String',    styleX: (element: any) => `${"text-align: right; background-color: " + this.bindingTypePresentationColors[element.book.bindingType] }`,  cell: (element: any) => `${element.book_ID}`},
    { property: 'book.body.paperType_ID',                columnDef: 'paperType',              header: 'Paper',                sort: true,  type: 'string',          filterType: 'DataEnum',        filterEnums: [LanguagePipe.get('Heavy'), LanguagePipe.get('Light'), LanguagePipe.get('Creme'), LanguagePipe.get('60#', '80MatteText'), LanguagePipe.get('80GlossText'), LanguagePipe.get('100GlossText')],   styleX: (element: any) => `${"text-align: right; background-color: " + this.paperTypePresentationColors[element.book.body.paperType_ID] }`,  cell: (element: any) => `${LanguagePipe.get(this.paperTypes[element.book.body.paperType_ID], 'compressSpaces')}`, values: { '1': LanguagePipe.get('Heavy', 'compressSpaces'), '2': LanguagePipe.get('Light', 'compressSpaces'), '4': LanguagePipe.get('Creme', 'compressSpaces'), '8': LanguagePipe.get('60#', 'compressSpaces'), '5': LanguagePipe.get('80MatteText', 'compressSpaces'), '6': LanguagePipe.get('80GlossText', 'compressSpaces'), '7': LanguagePipe.get('100GlossText', 'compressSpaces')} },
    { property: 'quantity',                              columnDef: 'quantity',               header: 'Quantity',             sort: true,  type: 'number',          filterType: 'NumericRange',    style: 'text-align: right',  cell: (element: any) => `${element.quantity}` },
    { property: 'initialQuantity',                       columnDef: 'initialQuantity',        header: 'Initial Quantity',     sort: true,  type: 'number',          filterType: 'NumericRange',    style: 'text-align: right',  cell: (element: any) => `${element.initialQuantity}` },
    { property: 'type',                                  columnDef: 'type',                   header: 'Type',                 sort: true,  type: 'string',          filterType: 'String',                                       cell: (element: any) => `${LanguagePipe.get(element.type, 'compressSpaces')}`, styleX: (element: any) => `${"background-color: " + element.type === 'INITIAL' ? 'white' : ( element.type === 'REPRINT' ? 'pink' : 'orange') }`   },
    { property: 'book.numPages',                         columnDef: 'pages',                  header: 'Pages',                sort: true,  type: 'number',          filterType: 'NumericRange',    style: 'text-align: right',  cell: (element: any) => `${element.book.body.numPages}` },
    { property: 'book.title',                            columnDef: 'title',                  header: 'Title',                sort: true,  type: 'string',          filterType: 'String',          style: 'max-height: 20px;  text-overflow: clip;text-align: right',  cell: (element: any) => `${element.book.title}` },
    { property: 'printNodeOrder.organization.name',                     columnDef: 'orgName',                header: 'Org',                  sort: true,  type: 'string',          filterType: 'String',          style: 'max-height: 20px;  text-overflow: clip;text-align: right',  cell: (element: any) => `${element.printNodeOrder.organization.name}` },
    { property: 'sheets',                                columnDef: 'sheets',                 header: 'Sheets',               sort: true,  type: 'number',          filterType: 'NumericRange',    style: 'text-align: right',  cell: (element: any) => `${element.book.body.width > 72 * 6 || element.book.body.height > 72 * 9 ? element.quantity * Math.ceil(element.book.body.numPages / 4) : element.quantity * Math.ceil(element.book.body.numPages / 8) }`   },
    { property: 'numberUp',                              columnDef: 'numberUp',               header: '# Up',                 sort: true,  type: 'string',          filterType: 'DataEnum',        filterEnums: ['4UP', '2UP'],   styleX: (element: any) => `${"" + (element.book.body.width > 72 * 6 || element.book.body.height > 72 * 9 ? 'text-align: right; background-color: darkgreen' : 'text-align: right;')}`,  cell: (element: any) => `${element.book.body.width > 72 * 6 || element.book.body.height > 72 * 9 ? '2UP' : '4UP'}`, values: { '2UP': '2UP', '4UP': '4UP'} },
    { property: 'laminateType',                          columnDef: 'laminateType',           header: 'Laminate',             sort: true,  type: 'string',          filterType: 'DataEnum',        filterEnums: [LanguagePipe.get('GLOSS', 'compressSpaces'), LanguagePipe.get('MATTE', 'compressSpaces')],   styleX: (element: any) => `${"text-align: right; background-color: " + this.laminateTypePresentationColors[element.book.cover.laminateType] }`,  cell: (element: any) => `${LanguagePipe.get(element.book.cover.laminateType, 'compressSpaces') }`, values: { 'GLOSS': LanguagePipe.get('GLOSS'), 'MATTE': LanguagePipe.get('MATTE')} },
    { property: 'bindingType',                           columnDef: 'bindingType',            header: 'Binding',              sort: true,  type: 'string',          filterType: 'DataEnum',        filterEnums: [LanguagePipe.get('PERFECT', 'compressSpaces'), LanguagePipe.get('COIL')],   styleX: (element: any) => `${"text-align: right; background-color: " + this.bindingTypePresentationColors[element.book.bindingType] }`,  cell: (element: any) => `${LanguagePipe.get(element.book.bindingType, 'compressSpaces') }`, values: { 'PERFECT': LanguagePipe.get('PERFECT'), 'COIL': LanguagePipe.get('COIL')} },
    { property: 'trim',                                  columnDef: 'trim',                   header: 'Trim',                 sort: true,  type: 'string',          filterType: 'DataEnum',        filterEnums: ['6x9', '5.5X8.5', '8.5x11', 'Other'],   style: 'text-align: right',  cell: (element: any) => `${ "" + Math.floor(element.book.body.width / 7.2) / 10 + "X" + Math.floor(element.book.body.height / 7.2) / 10 }` },
    { property: 'printNodeOrder.inventoryType',          columnDef: 'inventoryType',          header: 'Inventory Type',       sort: true,  type: 'string',          filterType: 'DataEnum',        filterEnums: ['PRINT_TO_ORDER', 'FULFILL_FROM_INVENTORY', 'REPLENISH_INVENTORY'],   styleX: (element: any) => `${"max-height: 20px;  text-overflow: clip; text-align: right; background-color: " + this.getInventoryTypeBackgroundColor(element.manufacturingSet) }`,  cell: (element: any) => `${element.printNodeOrder.inventoryType}` },
    { property: 'printNodeOrder.packageCreationStatus',  columnDef: 'packageCreationStatus',  header: 'Package Creation',     sort: true,  type: 'string',          filterType: 'DataEnum',        filterEnums: ['INITIAL', 'COMPLETE', 'WAITING', 'ERROR'],   styleX: (element: any) => `${"max-height: 20px;  text-overflow: clip; text-align: right; background-color: " + (element.printNodeOrder.packageCreationStatus === 'ERROR' ? 'red' : '') }`,  cell: (element: any) => `${element.printNodeOrder.packageCreationStatus}` },
    { property: 'cumulativeNormalizedPages',             columnDef: 'cumulativeNormalizedPages', header: 'Cumulative Pages',  sort: false, type: 'number',          filterType: 'NumericRange',    style: 'text-align: right',  cell: (element: any) => `${ element.cumulativeNormalizedPages }` }
    // { property: 'book.ID',                               columnDef: 'previewCover',           header: 'Preview',              sort: false, type: 'iconButton',      filterType: 'Empty',           style: 'text-align: right',  cell: (element: any) => `${element.book.ID}`,  buttonColor: 'primary', icon: 'system_update',  clickAction: 'redirectToDelete'},
    // { property: 'printNodeOrder.ID',                     columnDef: 'packButton',             header: 'Pack',                 sort: false, type: 'iconButton',      filterType: 'Empty',           style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'},
    // { property: 'printNodeOrder.ID',                     columnDef: 'delete',                 header: 'Delete',               sort: false, type: 'iconButton',      filterType: 'Empty',           style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'}
    // { property: 'printNodeOrder.carrier',                columnDef: 'carrier',                header: 'Carrier',              sort: true,  type: 'string',          filterType: 'DataEnum',        style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.carrier}`, values: { USPS: 'USPS', UPS: 'UPS'} },
  ];
  columnsWithOutProductionData = [
    { property: 'printNodeOrder.created',                columnDef: 'created',                header: 'Created',              sort: true,  type: 'date',            filterType: 'String',    style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.created}`, dateFormat: 'MM/dd'},
    { property: 'printNodeOrder.dueDate',                columnDef: 'dueDate',                header: 'Due Date',             sort: true,  type: 'date',            filterType: 'String',    style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.dueDate}`, dateFormat: 'MM/dd'},
    { property: 'printNodeOrder.ID',                     columnDef: 'printNodeOrderId',       header: 'Order Id',             sort: true,  type: 'number',          filterType: 'String',    style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.ID}`   },
    { property: 'printNodeOrder.servicelevel',           columnDef: 'serviceLevel',           header: 'Service Level',        sort: true,  type: 'string',          filterType: 'DataEnum',     filterEnums: ['PRIORITY', 'MEDIA', 'GROUND', 'PRIORITY_FLAT'],    style: 'max-height: 20px;  text-overflow: clip;text-align: right',  cell: (element: any) => `${element.printNodeOrder.servicelevel}`, values: { } },
    { property: 'book.ID',                               columnDef: 'bookId',                 header: 'Book Id',              sort: true,  type: 'number',          filterType: 'String',    styleX: (element: any) => `${"text-align: right; background-color: " + this.bindingTypePresentationColors[element.book.bindingType] }`,  cell: (element: any) => `${element.book_ID}`},
    { property: 'book.body.paperType_ID',                columnDef: 'paperType',              header: 'Paper',                sort: true,  type: 'string',          filterType: 'DataEnum',     filterEnums: ['Heavy', 'Light', 'Creme', '60#', '80# Matte Text', '80# Gloss Text', '100# Gloss Text'],   styleX: (element: any) => `${"text-align: right; background-color: " + this.paperTypePresentationColors[element.book.body.paperType_ID] }`,  cell: (element: any) => `${this.paperTypes[element.book.body.paperType_ID]}`, values: { '1': 'Heavy', '2': 'Light', '4': 'Creme', '8': '60#', '5': '80# Matte Text', '6': '80# Gloss Text', '7': '100# Gloss Text'} },
    { property: 'quantity',                              columnDef: 'quantity',               header: 'Quantity',             sort: true,  type: 'number',          filterType: 'NumericRange',    style: 'text-align: right',  cell: (element: any) => `${element.quantity}` },
    { property: 'book.numPages',                         columnDef: 'pages',                  header: 'Pages',                sort: true,  type: 'number',          filterType: 'NumericRange',    style: 'text-align: right',  cell: (element: any) => `${element.book.body.numPages}` },
    { property: 'book.title',                            columnDef: 'title',                  header: 'Title',                sort: true,  type: 'string',          filterType: 'String',          style: 'max-height: 20px;  text-overflow: clip;text-align: right',  cell: (element: any) => `${element.book.title}` },
    { property: 'printNodeOrder.organization.name',                     columnDef: 'orgName',                header: 'Org',                  sort: true,  type: 'string',          filterType: 'String',          style: 'max-height: 20px;  text-overflow: clip;text-align: right',  cell: (element: any) => `${element.printNodeOrder.organization.name}` },
    { property: 'sheets',                                columnDef: 'sheets',                 header: 'Sheets',               sort: true,  type: 'number',          filterType: 'NumericRange',    style: 'text-align: right',  cell: (element: any) => `${element.book.body.width > 72 * 6 || element.book.body.height > 72 * 9 ? element.quantity * Math.ceil(element.book.body.numPages / 4) : element.quantity * Math.ceil(element.book.body.numPages / 8) }`   },
    { property: 'numberUp',                              columnDef: 'numberUp',               header: '# Up',                 sort: true,  type: 'string',          filterType: 'DataEnum',     filterEnums: ['4UP', '2UP'],   styleX: (element: any) => `${"" + (element.book.body.width > 72 * 6 || element.book.body.height > 72 * 9 ? 'text-align: right; background-color: darkgreen' : 'text-align: right; background-color: darkgrey')}`,  cell: (element: any) => `${element.book.body.width > 72 * 6 || element.book.body.height > 72 * 9 ? '2UP' : '4UP'}`, values: { '2UP': '2UP', '4UP': '4UP'} },
    { property: 'laminateType',                          columnDef: 'laminateType',           header: 'Laminate',             sort: true,  type: 'string',          filterType: 'DataEnum',     filterEnums: [LanguagePipe.get('GLOSS', 'compressSpaces'), LanguagePipe.get('MATTE', 'compressSpaces')],   styleX: (element: any) => `${"text-align: right; background-color: " + this.laminateTypePresentationColors[element.book.cover.laminateType] }`,  cell: (element: any) => `${element.book.cover.laminateType }`, values: { 'GLOSS': 'GLOSS', 'MATTE': 'MATTE'} },
    { property: 'trim',                                  columnDef: 'trim',                   header: 'Trim',                 sort: true,  type: 'string',          filterType: 'DataEnum',     filterEnums: ['6x9', '5.5X8.5', '8.5x11', 'Other'],   style: 'text-align: right',  cell: (element: any) => `${ "" + Math.floor(element.book.body.width / 7.2) / 10 + "X" + Math.floor(element.book.body.height / 7.2) / 10 }` },
    { property: 'cumulativeNormalizedPages',             columnDef: 'cumulativeNormalizedPages', header: 'Cumulative Pages',  sort: false, type: 'number',          filterType: 'NumericRange', style: 'text-align: right',  cell: (element: any) => `${ element.cumulativeNormalizedPages }` }
    // { property: 'book.ID',                               columnDef: 'previewCover',           header: 'Preview',              sort: false, type: 'iconButton',      filterType: 'Empty',           style: 'text-align: right',  cell: (element: any) => `${element.book.ID}`,  buttonColor: 'primary', icon: 'system_update',  clickAction: 'redirectToDelete'},
    // { property: 'printNodeOrder.ID',                     columnDef: 'packButton',             header: 'Pack',                 sort: false, type: 'iconButton',      filterType: 'Empty',           style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'},
    // { property: 'printNodeOrder.ID',                     columnDef: 'delete',                 header: 'Delete',               sort: false, type: 'iconButton',      filterType: 'Empty',           style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.ID}`,  buttonColor: 'warn', icon: 'delete',  clickAction: 'redirectToDelete'}
    // { property: 'printNodeOrder.carrier',                columnDef: 'carrier',                header: 'Carrier',              sort: true,  type: 'string',          filterType: 'DataEnum',        style: 'text-align: right',  cell: (element: any) => `${element.printNodeOrder.carrier}`, values: { USPS: 'USPS', UPS: 'UPS'} },
  ];

  packageTypeById: any = {};

  eventHandler: EventHandler;

  // public dataSource = new MatTableDataSource<PrintNodeOrderItem>();
  public renderedData: PrintNodeOrderItem[];
  // tableFilterManager: TableFilterManager<PrintNodeOrderItem> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav') mainSideNav: MatSidenav;
  @ViewChild('appTable') appTable: TableComponent<PrintNodeOrderItem>;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  role: string;

  constructor(public router: Router, public startupService: StartupService, public dialog: MatDialog, private printNodeOrderService: PrintNodeOrderService, private printNodeOrderItemService: PrintNodeOrderItemService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.addRoleChangeListener(this);
    this.startupService.touchLastUserActivity();
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
//    this.columns = this.columnsWithOutProductionData;
  }

  roleChanged(role: string, roleId: string): void {
    // if ( role === 'organization' || role === 'serviceprovider' ) {
    //   this.columns = this.columnsWithOutProductionData;
    // } else {
    //   this.columns = this.columnsWithProductionData;
    // }
    this.getData();
  }

  ngOnInit() {
    // this.startupService.isAuthenticated(this);
    // this.dataSource.connect().subscribe(d => this.renderedData = d);
  }

  ngAfterViewInit(): void {
    // this.dataSource.sort = this.sort;
    // this.dataSource.filter = undefined;
    // const _this = this;
    // this.dataSource.filterPredicate =
    //   (data: PrintNodeOrderItem, filter: string) => {
    //     return _this.tableFilterManager.tableFilter.match(data, filter);
    //   };
    // this.dataSource.paginator = this.paginator;
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      if ( this.appTable ) {
        this.getData();
      } else {
        console.log(`Logged in but the table in the UI is not yet created`);
      }
    }
  }

  // onSelect(printNodeOrderItem: PrintNodeOrderItem): void {
  //   this.selectedPrintNodeOrderItem = printNodeOrderItem;
  //   if ( this.selectedPrintNodeOrderItem ) {
  //     this.selectedPrintNodeOrder = this.selectedPrintNodeOrderItem.printNodeOrder;
  //   }
  // }
  match(printNodeOrderItem: PrintNodeOrderItem ): boolean {
    if ( printNodeOrderItem.printNodeOrder && printNodeOrderItem.printNodeOrder.shippingStatus === 'NONE') {
      return true;
    }
    return false;
  }

  contains(object: PrintNodeOrderItem, array: PrintNodeOrderItem[] ): boolean {
    for ( const o of array ) {
      if ( o.getId() === object.getId() ) {
        return true;
      }
    }
    return false;
  }

  computeCumulative() {
    let prev = 0;
    const dataSourceData = this.appTable.getDataSourceData();
    for ( let i = 0; i < dataSourceData.length; i++) {
      dataSourceData[i]['cumulativeNormalizedPages'] = dataSourceData[i].getNormalizedPages() + prev;
      prev = dataSourceData[i]['cumulativeNormalizedPages'];
    }
  }

  getData(): void {
    this.startupService.touchLastUserActivity();
    this.startupService.touchLastUserActivity();
    this.printNodeOrderItemService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      if ( this.match(o)) {
        this.appTable.addItem(o);
      }
    });
    this.printNodeOrderItemService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdate(o);
    });
    this.printNodeOrderItemService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.appTable.removeItem(o);
    });
    this.printNodeOrderItemService.objects.forEach( o => {
      this.addUpdate(o);
    });
   }
  addUpdate(o: PrintNodeOrderItem) {
    try {
      if ( this.match(o)) {
        this.appTable.addItem(o);
        this.computeCumulative();
      } else {
        this.appTable.removeItem(o);
        this.computeCumulative();
      }
    } catch ( e ) {
      console.log(`ERROR in PrintNodeOrderItemComponent addUpdate ${o.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }



  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }



  public redirectToDetails = (id: string) => {

  }

  public redirectToUpdate = (id: string) => {

  }

  public redirectToDelete = (id: string) => {

  }

  // public getCumulativeNormalizedPages(index: number): number {
  //   if ( this.renderedData ) {
  //     if ( index > 0 && this.renderedData.length > index && this.renderedData[index].getNormalizedPages() + this.cumulativeNormalizedPages[index - 1] !== this.cumulativeNormalizedPages[index] ) {
  //       this.computeCumulativeNormalizedPages();
  //     } else if ( this.renderedData[0].getNormalizedPages() !== this.cumulativeNormalizedPages[0] ) {
  //       this.computeCumulativeNormalizedPages();
  //     }
  //     return this.cumulativeNormalizedPages[index];
  //   }
  //   return 0;
  // }

  // public computeCumulativeNormalizedPages() {
  //   // this.cumulativeNormalizedPages = {};
  //   let prev = 0;
  //   for ( let i = 0; i < this.renderedData.length; i++) {
  //     this.dataSource.data[i]['cumulativeNormalizedPages'] = this.dataSource.data[i].getNormalizedPages() + prev;
  //     // this.cumulativeNormalizedPages[i] = this.dataSource.data[i].getNormalizedPages() + prev;
  //     prev = this.dataSource.data[i]['cumulativeNormalizedPages'];
  //   }
  // }

  public event(action: string, data: any, event) {

  }

  refresh () {
    this.getData();
  }

  public getInventoryTypeBackgroundColor (inventoryType: String): string {
    switch ( inventoryType ) {
      case 'PRINT_TO_ORDER': {
        return '';
      }
      case 'FULFILL_FROM_INVENTORY': {
        return 'darkgreen';
      }
      case 'REPLENISH_INVENTORY': {
        return 'darkblue';
      }
    }
  }

  public getBackgroundColor (period: String): string {
    if ( period ) {
      const periodDate = new Date(period.substring(1) + ' 15:01:01');
      let dateDiff = Math.round(( Number( periodDate ) - Number( new Date() ) ) / ( 24 * 60 * 60 * 1000 ) );
      if ( this.getWeekOfYear(periodDate) > this.getWeekOfYear(new Date())) {
        dateDiff -= 2;
      }
      if ( this.getWeekOfYear(periodDate) < this.getWeekOfYear(new Date())) {
        dateDiff += 2;
      }
      if ( dateDiff > 6 ) {
        return 'lightgreen';
      }
      if ( dateDiff < -3) {
        return 'red';
      }
      switch ( dateDiff ) {
        case -4: {
          return 'pink';
        }
        case -3: {
          return 'lightpink';
        }
        case -2: {
          return 'orange';
        }
        case -1: {
          return 'darkgoldenrod';
        }
        case 0: {
          return 'black';
        }
        case 1: {
          return 'green';
        }
        case 2: {
          return 'green';
        }
        case 3: {
          return 'green';
        }
        case 4: {
          return 'green';
        }
      }
    }
    return 'red';
  }

  public getNextManufacturingTasksBackgroundColor (manufacturingSet: ManufacturingSet): string {
    const nextManufacturingTasksText = this.getNextManufacturingTasksString(manufacturingSet);
    switch (nextManufacturingTasksText) {
      case 'BodyPrint': {
        return 'darkgoldenrod';
      }
      case 'CoverPrint': {
        return 'darkgoldenrod';
      }
      case 'BodyPrint, CoverPrint': {
        return 'darkgoldenrod';
      }
      case 'CutApart, CoverPrint': {
        return 'darkgoldenrod';
      }
      case 'Laminate, BodyPrint': {
        return 'darkgoldenrod';
      }
      case 'Laminate, BodyPrint': {
        return 'darkgoldenrod';
      }
      case 'Laminate': {
        return 'orange';
      }
      case 'CutApart': {
        return 'orange';
      }
      case 'CutApart, Laminate': {
        return 'orange';
      }
      case 'Laminate, CutApart': {
        return 'orange';
      }
      case 'Bind': {
        return 'darkred';
      }
      case 'Trim': {
        return 'darkgreen';
      }
      case 'Pack': {
        return 'darkblue';
      }
      case 'Ship': {
        return 'green';
      }
    }
    return '';
  }

  getNextManufacturingTasksString(manufacturingSet: ManufacturingSet): String {
    const nextTaskTypeNames = [];
    if (manufacturingSet && manufacturingSet.manufacturingTasks) {
      const nextManufacturingTasks = manufacturingSet.getNextManufacturingTasksToExecuteFromRoots();
      for (const nextManufacturingTask of nextManufacturingTasks) {
        nextTaskTypeNames.push(LanguagePipe.get(nextManufacturingTask.tasktype));
      }
    }
    return nextTaskTypeNames.join(', ');
  }

  getWeekOfYear(date: Date): number {
    const MILLISECONDS_IN_WEEK = 604800000;
    const firstDayOfWeek = 0; // monday as the first day (0 = sunday)
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    startOfYear.setDate(
      startOfYear.getDate() + (firstDayOfWeek - (startOfYear.getDay() % 7))
    );
    const dayWeek = Math.floor(( Number( date ) - Number(startOfYear) ) / MILLISECONDS_IN_WEEK) + 1;
    return  dayWeek;
  }

  changePackages (pkg: Package, printNodeOrder: PrintNodeOrder) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '95%';
    dialogConfig.height = '95%';
    // dialogConfig.width = this.mobileQueryMatchs ? '600px' : '95%';
    // dialogConfig.height = this.mobileQueryMatchs ? '700px' : '95%';
    dialogConfig.disableClose = false;
    dialogConfig.data = { pkg: pkg, printNodeOrder: printNodeOrder, mobileQueryMatches: false };
    const dialogRef = this.dialog.open(ChangePackagesComponent, dialogConfig);
    if ( dialogRef ) {
    }
  }
}
