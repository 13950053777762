<mat-card appearance="outlined">
  <mat-card-content style="padding: 12px;">
    <ng-container *ngIf="orderDeliverTo && orderDeliverTo.addressValidationStatus !== 'VALID'">
      <h3 i18n="@@Current Address">Current Address</h3>
      <div>
        <span>{{orderDeliverTo.address1}} {{orderDeliverTo.address2}}</span>
        <br>
        <span>{{orderDeliverTo.city}}, {{orderDeliverTo.state}} {{orderDeliverTo.postalcode}}</span>
        <br>
        <span>{{orderDeliverTo.country}}</span>
        <!--              <button mat-raised-button color="warn" (click)="ignoreAddressValidationInput(address)" i18n="@@Ignore Address Validation Input And Use The Current Address">Ignore Address Validation Input And Use The Current Address</button>-->
      </div>
      <button *ngIf="!enterAnAlternativeAddress" mat-raised-button color="accent" (click)="setEnterAnAlternativeAddress()" i18n="@@Enter An Alternative Address">Enter An Alternative Address</button>
      <button *ngIf="enterAnAlternativeAddress" mat-raised-button color="accent" (click)="setCancelAlternativeAddress()" i18n="@@Cancel Enter An Alternative Address">Cancel Enter An Alternative Address</button>
      <button mat-raised-button color="accent" (click)="validateAddress(orderDeliverTo)" i18n="@@Validate Address">Validate Address</button>
      <button *ngIf="initialProcessing" mat-raised-button color="accent" (click)="processLater(orderDeliverTo)" i18n="@@Validate Address">Validate Address</button>

<!--      <button mat-raised-button color="warn" (click)="deleteFromOrder(orderDeliverTo)" i18n="@@Delete This Shipment And The Items To Go To This Address From The Order">Delete This Shipment And The Items To Go To This Address From The Order</button>-->
      <ng-container *ngIf="enterAnAlternativeAddress">
        <h3 i18n="@@Alternative Address">Alternative Address</h3>
        <div>
          <form>
            <mat-form-field>
              <mat-label i18n="@@Address 1">Address 1</mat-label>
              <textarea name="address1" [(ngModel)]="alternativeAddress.address1" matInput i18n-placeholder="@@Address" placeholder="Address"></textarea>
            </mat-form-field>
            <br>
            <mat-form-field>
              <mat-label i18n="@@Address 2">Address 2</mat-label>
              <textarea name="address2" [(ngModel)]="alternativeAddress.address2" matInput i18n-placeholder="@@Address 2" placeholder="Address 2"></textarea>
            </mat-form-field>
            <br>
            <mat-form-field>
              <mat-label i18n="@@City">City</mat-label>
              <input name="city" [(ngModel)]="alternativeAddress.city" matInput  i18n-placeholder="@@City" placeholder="City">
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@State">State</mat-label>
              <input name="state" [(ngModel)]="alternativeAddress.state" matInput  i18n-placeholder="@@State" placeholder="State">
            </mat-form-field>
            <br>
            <mat-form-field>
              <mat-label i18n="@@Country">Country</mat-label>
              <mat-select name="country" [(ngModel)]="alternativeAddress.country"  i18n-placeholder="@@Country" placeholder="Country" id="country"  autocomplete="country">
                <mat-option>None</mat-option>
                <mat-option value="AF" i18n='@@Afghanistan'>Afghanistan</mat-option>
                <mat-option value="AL" i18n='@@Albania'>Albania</mat-option>
                <mat-option value="DZ" i18n='@@Algeria'>Algeria</mat-option>
                <mat-option value="AS" i18n='@@American Samoa'>American Samoa</mat-option>
                <mat-option value="AD" i18n='@@Andorra'>Andorra</mat-option>
                <mat-option value="AG" i18n='@@Angola'>Angola</mat-option>
                <mat-option value="AI" i18n='@@Anguilla'>Anguilla</mat-option>
                <mat-option value="AG" i18n='@@Antigua &amp; Barbuda'>Antigua &amp; Barbuda</mat-option>
                <mat-option value="AR" i18n='@@Argentina'>Argentina</mat-option>
                <mat-option value="AA" i18n='@@Armenia'>Armenia</mat-option>
                <mat-option value="AW" i18n='@@Aruba'>Aruba</mat-option>
                <mat-option value="AU" i18n='@@Australia'>Australia</mat-option>
                <mat-option value="AT" i18n='@@Austria'>Austria</mat-option>
                <mat-option value="AZ" i18n='@@Azerbaijan'>Azerbaijan</mat-option>
                <mat-option value="BS" i18n='@@Bahamas'>Bahamas</mat-option>
                <mat-option value="BH" i18n='@@Bahrain'>Bahrain</mat-option>
                <mat-option value="BD" i18n='@@Bangladesh'>Bangladesh</mat-option>
                <mat-option value="BB" i18n='@@Barbados'>Barbados</mat-option>
                <mat-option value="BY" i18n='@@Belarus'>Belarus</mat-option>
                <mat-option value="BE" i18n='@@Belgium'>Belgium</mat-option>
                <mat-option value="BZ" i18n='@@Belize'>Belize</mat-option>
                <mat-option value="BJ" i18n='@@Benin'>Benin</mat-option>
                <mat-option value="BM" i18n='@@Bermuda'>Bermuda</mat-option>
                <mat-option value="BT" i18n='@@Bhutan'>Bhutan</mat-option>
                <mat-option value="BO" i18n='@@Bolivia'>Bolivia</mat-option>
                <mat-option value="BL" i18n='@@Bonaire'>Bonaire</mat-option>
                <mat-option value="BA" i18n='@@Bosnia &amp; Herzegovina'>Bosnia &amp; Herzegovina</mat-option>
                <mat-option value="BW" i18n='@@Botswana'>Botswana</mat-option>
                <mat-option value="BR" i18n='@@Brazil'>Brazil</mat-option>
                <mat-option value="BC" i18n='@@British Indian Ocean Ter'>British Indian Ocean Ter</mat-option>
                <mat-option value="BN" i18n='@@Brunei'>Brunei</mat-option>
                <mat-option value="BG" i18n='@@Bulgaria'>Bulgaria</mat-option>
                <mat-option value="BF" i18n='@@Burkina Faso'>Burkina Faso</mat-option>
                <mat-option value="BI" i18n='@@Burundi'>Burundi</mat-option>
                <mat-option value="KH" i18n='@@Cambodia'>Cambodia</mat-option>
                <mat-option value="CM" i18n='@@Cameroon'>Cameroon</mat-option>
                <mat-option value="CA" i18n='@@Canada'>Canada</mat-option>
                <mat-option value="IC" i18n='@@Canary Islands'>Canary Islands</mat-option>
                <mat-option value="CV" i18n='@@Cape Verde'>Cape Verde</mat-option>
                <mat-option value="KY" i18n='@@Cayman Islands'>Cayman Islands</mat-option>
                <mat-option value="CF" i18n='@@Central African Republic'>Central African Republic</mat-option>
                <mat-option value="TD" i18n='@@Chad'>Chad</mat-option>
                <mat-option value="CD" i18n='@@Channel Islands'>Channel Islands</mat-option>
                <mat-option value="CL" i18n='@@Chile'>Chile</mat-option>
                <mat-option value="CN" i18n='@@China'>China</mat-option>
                <mat-option value="CI" i18n='@@Christmas Island'>Christmas Island</mat-option>
                <mat-option value="CS" i18n='@@Cocos Island'>Cocos Island</mat-option>
                <mat-option value="CO" i18n='@@Colombia'>Colombia</mat-option>
                <mat-option value="CC" i18n='@@Comoros'>Comoros</mat-option>
                <mat-option value="CG" i18n='@@Congo'>Congo</mat-option>
                <mat-option value="CK" i18n='@@Cook Islands'>Cook Islands</mat-option>
                <mat-option value="CR" i18n='@@Costa Rica'>Costa Rica</mat-option>
                <mat-option value="CT" i18n='@@Cote DIvoire'>Cote D'Ivoire</mat-option>
                <mat-option value="HR" i18n='@@Croatia'>Croatia</mat-option>
                <mat-option value="CU" i18n='@@Cuba'>Cuba</mat-option>
                <mat-option value="CB" i18n='@@Curacao'>Curacao</mat-option>
                <mat-option value="CY" i18n='@@Cyprus'>Cyprus</mat-option>
                <mat-option value="CZ" i18n='@@Czech Republic'>Czech Republic</mat-option>
                <mat-option value="DK" i18n='@@Denmark'>Denmark</mat-option>
                <mat-option value="DJ" i18n='@@Djibouti'>Djibouti</mat-option>
                <mat-option value="DM" i18n='@@Dominica'>Dominica</mat-option>
                <mat-option value="DO" i18n='@@Dominican Republic'>Dominican Republic</mat-option>
                <mat-option value="TM" i18n='@@East Timor'>East Timor</mat-option>
                <mat-option value="EC" i18n='@@Ecuador'>Ecuador</mat-option>
                <mat-option value="EG" i18n='@@Egypt'>Egypt</mat-option>
                <mat-option value="SV" i18n='@@El Salvador'>El Salvador</mat-option>
                <mat-option value="GQ" i18n='@@Equatorial Guinea'>Equatorial Guinea</mat-option>
                <mat-option value="ER" i18n='@@Eritrea'>Eritrea</mat-option>
                <mat-option value="EE" i18n='@@Estonia'>Estonia</mat-option>
                <mat-option value="ET" i18n='@@Ethiopia'>Ethiopia</mat-option>
                <mat-option value="FA" i18n='@@Falkland Islands'>Falkland Islands</mat-option>
                <mat-option value="FO" i18n='@@Faroe Islands'>Faroe Islands</mat-option>
                <mat-option value="FJ" i18n='@@Fiji'>Fiji</mat-option>
                <mat-option value="FI" i18n='@@Finland'>Finland</mat-option>
                <mat-option value="FR" i18n='@@France'>France</mat-option>
                <mat-option value="GF" i18n='@@French Guiana'>French Guiana</mat-option>
                <mat-option value="PF" i18n='@@French Polynesia'>French Polynesia</mat-option>
                <mat-option value="FS" i18n='@@French Southern Ter'>French Southern Ter</mat-option>
                <mat-option value="GA" i18n='@@Gabon'>Gabon</mat-option>
                <mat-option value="GM" i18n='@@Gambia'>Gambia</mat-option>
                <mat-option value="GE" i18n='@@Georgia'>Georgia</mat-option>
                <mat-option value="DE" i18n='@@Germany'>Germany</mat-option>
                <mat-option value="GH" i18n='@@Ghana'>Ghana</mat-option>
                <mat-option value="GI" i18n='@@Gibraltar'>Gibraltar</mat-option>
                <mat-option value="GB" i18n='@@Great Britain'>Great Britain</mat-option>
                <mat-option value="GR" i18n='@@Greece'>Greece</mat-option>
                <mat-option value="GL" i18n='@@Greenland'>Greenland</mat-option>
                <mat-option value="GD" i18n='@@Grenada'>Grenada</mat-option>
                <mat-option value="GP" i18n='@@Guadeloupe'>Guadeloupe</mat-option>
                <mat-option value="GU" i18n='@@Guam'>Guam</mat-option>
                <mat-option value="GT" i18n='@@Guatemala'>Guatemala</mat-option>
                <mat-option value="GN" i18n='@@Guinea'>Guinea</mat-option>
                <mat-option value="GY" i18n='@@Guyana'>Guyana</mat-option>
                <mat-option value="HT" i18n='@@Haiti'>Haiti</mat-option>
                <mat-option value="HW" i18n='@@Hawaii'>Hawaii</mat-option>
                <mat-option value="HN" i18n='@@Honduras'>Honduras</mat-option>
                <mat-option value="HK" i18n='@@Hong Kong'>Hong Kong</mat-option>
                <mat-option value="HU" i18n='@@Hungary'>Hungary</mat-option>
                <mat-option value="IS" i18n='@@Iceland'>Iceland</mat-option>
                <mat-option value="IN" i18n='@@India'>India</mat-option>
                <mat-option value="ID" i18n='@@Indonesia'>Indonesia</mat-option>
                <mat-option value="IR" i18n='@@Iran'>Iran</mat-option>
                <mat-option value="IQ" i18n='@@Iraq'>Iraq</mat-option>
                <mat-option value="IE" i18n='@@Ireland'>Ireland</mat-option>
                <mat-option value="IM" i18n='@@Isle of Man'>Isle of Man</mat-option>
                <mat-option value="IL" i18n='@@Israel'>Israel</mat-option>
                <mat-option value="IT" i18n='@@Italy'>Italy</mat-option>
                <mat-option value="JM" i18n='@@Jamaica'>Jamaica</mat-option>
                <mat-option value="JP" i18n='@@Japan'>Japan</mat-option>
                <mat-option value="JO" i18n='@@Jordan'>Jordan</mat-option>
                <mat-option value="KZ" i18n='@@Kazakhstan'>Kazakhstan</mat-option>
                <mat-option value="KE" i18n='@@Kenya'>Kenya</mat-option>
                <mat-option value="KI" i18n='@@Kiribati'>Kiribati</mat-option>
                <mat-option value="NK" i18n='@@Korea North'>Korea North</mat-option>
                <mat-option value="KS" i18n='@@Korea South'>Korea South</mat-option>
                <mat-option value="KW" i18n='@@Kuwait'>Kuwait</mat-option>
                <mat-option value="KG" i18n='@@Kyrgyzstan'>Kyrgyzstan</mat-option>
                <mat-option value="LA" i18n='@@Laos'>Laos</mat-option>
                <mat-option value="LV" i18n='@@Latvia'>Latvia</mat-option>
                <mat-option value="LB" i18n='@@Lebanon'>Lebanon</mat-option>
                <mat-option value="LS" i18n='@@Lesotho'>Lesotho</mat-option>
                <mat-option value="LR" i18n='@@Liberia'>Liberia</mat-option>
                <mat-option value="LY" i18n='@@Libya'>Libya</mat-option>
                <mat-option value="LI" i18n='@@Liechtenstein'>Liechtenstein</mat-option>
                <mat-option value="LT" i18n='@@Lithuania'>Lithuania</mat-option>
                <mat-option value="LU" i18n='@@Luxembourg'>Luxembourg</mat-option>
                <mat-option value="MO" i18n='@@Macau'>Macau</mat-option>
                <mat-option value="MK" i18n='@@Macedonia'>Macedonia</mat-option>
                <mat-option value="MG" i18n='@@Madagascar'>Madagascar</mat-option>
                <mat-option value="MY" i18n='@@Malaysia'>Malaysia</mat-option>
                <mat-option value="MW" i18n='@@Malawi'>Malawi</mat-option>
                <mat-option value="MV" i18n='@@Maldives'>Maldives</mat-option>
                <mat-option value="ML" i18n='@@Mali'>Mali</mat-option>
                <mat-option value="MT" i18n='@@Malta'>Malta</mat-option>
                <mat-option value="MH" i18n='@@Marshall Islands'>Marshall Islands</mat-option>
                <mat-option value="MQ" i18n='@@Martinique'>Martinique</mat-option>
                <mat-option value="MR" i18n='@@Mauritania'>Mauritania</mat-option>
                <mat-option value="MU" i18n='@@Mauritius'>Mauritius</mat-option>
                <mat-option value="ME" i18n='@@Mayotte'>Mayotte</mat-option>
                <mat-option value="MX" i18n='@@Mexico'>Mexico</mat-option>
                <mat-option value="MI" i18n='@@Midway Islands'>Midway Islands</mat-option>
                <mat-option value="MD" i18n='@@Moldova'>Moldova</mat-option>
                <mat-option value="MC" i18n='@@Monaco'>Monaco</mat-option>
                <mat-option value="MN" i18n='@@Mongolia'>Mongolia</mat-option>
                <mat-option value="MS" i18n='@@Montserrat'>Montserrat</mat-option>
                <mat-option value="MA" i18n='@@Morocco'>Morocco</mat-option>
                <mat-option value="MZ" i18n='@@Mozambique'>Mozambique</mat-option>
                <mat-option value="MM" i18n='@@Myanmar'>Myanmar</mat-option>
                <mat-option value="NA" i18n='@@Nambia'>Nambia</mat-option>
                <mat-option value="NU" i18n='@@Nauru'>Nauru</mat-option>
                <mat-option value="NP" i18n='@@Nepal'>Nepal</mat-option>
                <mat-option value="AN" i18n='@@Netherland Antilles'>Netherland Antilles</mat-option>
                <mat-option value="NL" i18n='@@Netherlands (Holland, Europe)'>Netherlands (Holland, Europe)</mat-option>
                <mat-option value="NV" i18n='@@Nevis'>Nevis</mat-option>
                <mat-option value="NC" i18n='@@New Caledonia'>New Caledonia</mat-option>
                <mat-option value="NZ" i18n='@@New Zealand'>New Zealand</mat-option>
                <mat-option value="NI" i18n='@@Nicaragua'>Nicaragua</mat-option>
                <mat-option value="NE" i18n='@@Niger'>Niger</mat-option>
                <mat-option value="NG" i18n='@@Nigeria'>Nigeria</mat-option>
                <mat-option value="NW" i18n='@@Niue'>Niue</mat-option>
                <mat-option value="NF" i18n='@@Norfolk Island'>Norfolk Island</mat-option>
                <mat-option value="NO" i18n='@@Norway'>Norway</mat-option>
                <mat-option value="OM" i18n='@@Oman'>Oman</mat-option>
                <mat-option value="PK" i18n='@@Pakistan'>Pakistan</mat-option>
                <mat-option value="PW" i18n='@@Palau Island'>Palau Island</mat-option>
                <mat-option value="PS" i18n='@@Palestine'>Palestine</mat-option>
                <mat-option value="PA" i18n='@@Panama'>Panama</mat-option>
                <mat-option value="PG" i18n='@@Papua New Guinea'>Papua New Guinea</mat-option>
                <mat-option value="PY" i18n='@@Paraguay'>Paraguay</mat-option>
                <mat-option value="PE" i18n='@@Peru'>Peru</mat-option>
                <mat-option value="PH" i18n='@@Philippines'>Philippines</mat-option>
                <mat-option value="PO" i18n='@@Pitcairn Island'>Pitcairn Island</mat-option>
                <mat-option value="PL" i18n='@@Poland'>Poland</mat-option>
                <mat-option value="PT" i18n='@@Portugal'>Portugal</mat-option>
                <!--<mat-option value="PR" i18n='@@Puerto Rico'>Puerto Rico</mat-option> -->
                <mat-option value="QA" i18n='@@Qatar'>Qatar</mat-option>
                <mat-option value="ME" i18n='@@Republic of Montenegro'>Republic of Montenegro</mat-option>
                <mat-option value="RS" i18n='@@Republic of Serbia'>Republic of Serbia</mat-option>
                <mat-option value="RE" i18n='@@Reunion'>Reunion</mat-option>
                <mat-option value="RO" i18n='@@Romania'>Romania</mat-option>
                <mat-option value="RU" i18n='@@Russia'>Russia</mat-option>
                <mat-option value="RW" i18n='@@Rwanda'>Rwanda</mat-option>
                <mat-option value="NT" i18n='@@St Barthelemy'>St Barthelemy</mat-option>
                <mat-option value="EU" i18n='@@St Eustatius'>St Eustatius</mat-option>
                <mat-option value="HE" i18n='@@St Helena'>St Helena</mat-option>
                <mat-option value="KN" i18n='@@St Kitts-Nevis'>St Kitts-Nevis</mat-option>
                <mat-option value="LC" i18n='@@St Lucia'>St Lucia</mat-option>
                <mat-option value="MB" i18n='@@St Maarten'>St Maarten</mat-option>
                <mat-option value="PM" i18n='@@St Pierre &amp; Miquelon'>St Pierre &amp; Miquelon</mat-option>
                <mat-option value="VC" i18n='@@St Vincent &amp; Grenadines'>St Vincent &amp; Grenadines</mat-option>
                <mat-option value="SP" i18n='@@Saipan'>Saipan</mat-option>
                <mat-option value="SO" i18n='@@Samoa'>Samoa</mat-option>
                <mat-option value="AS" i18n='@@Samoa American'>Samoa American</mat-option>
                <mat-option value="SM" i18n='@@San Marino'>San Marino</mat-option>
                <mat-option value="ST" i18n='@@Sao Tome &amp; Principe'>Sao Tome &amp; Principe</mat-option>
                <mat-option value="SA" i18n='@@Saudi Arabia'>Saudi Arabia</mat-option>
                <mat-option value="SN" i18n='@@Senegal'>Senegal</mat-option>
                <mat-option value="SC" i18n='@@Seychelles'>Seychelles</mat-option>
                <mat-option value="SL" i18n='@@Sierra Leone'>Sierra Leone</mat-option>
                <mat-option value="SG" i18n='@@Singapore'>Singapore</mat-option>
                <mat-option value="SK" i18n='@@Slovakia'>Slovakia</mat-option>
                <mat-option value="SI" i18n='@@Slovenia'>Slovenia</mat-option>
                <mat-option value="SB" i18n='@@Solomon Islands'>Solomon Islands</mat-option>
                <mat-option value="OI" i18n='@@Somalia'>Somalia</mat-option>
                <mat-option value="ZA" i18n='@@South Africa'>South Africa</mat-option>
                <mat-option value="ES" i18n='@@Spain'>Spain</mat-option>
                <mat-option value="LK" i18n='@@Sri Lanka'>Sri Lanka</mat-option>
                <mat-option value="SD" i18n='@@Sudan'>Sudan</mat-option>
                <mat-option value="SR" i18n='@@Suriname'>Suriname</mat-option>
                <mat-option value="SZ" i18n='@@Swaziland'>Swaziland</mat-option>
                <mat-option value="SE" i18n='@@Sweden'>Sweden</mat-option>
                <mat-option value="CH" i18n='@@Switzerland'>Switzerland</mat-option>
                <mat-option value="SY" i18n='@@Syria'>Syria</mat-option>
                <mat-option value="TW" i18n='@@Taiwan'>Taiwan</mat-option>
                <mat-option value="TJ" i18n='@@Tajikistan'>Tajikistan</mat-option>
                <mat-option value="TZ" i18n='@@Tanzania'>Tanzania</mat-option>
                <mat-option value="TH" i18n='@@Thailand'>Thailand</mat-option>
                <mat-option value="TG" i18n='@@Togo'>Togo</mat-option>
                <mat-option value="TK" i18n='@@Tokelau'>Tokelau</mat-option>
                <mat-option value="TO" i18n='@@Tonga'>Tonga</mat-option>
                <mat-option value="TT" i18n='@@Trinidad &amp; Tobago'>Trinidad &amp; Tobago</mat-option>
                <mat-option value="TN" i18n='@@Tunisia'>Tunisia</mat-option>
                <mat-option value="TR" i18n='@@Turkey'>Turkey</mat-option>
                <mat-option value="TU" i18n='@@Turkmenistan'>Turkmenistan</mat-option>
                <mat-option value="TC" i18n='@@Turks &amp; Caicos Is'>Turks &amp; Caicos Is</mat-option>
                <mat-option value="TV" i18n='@@Tuvalu'>Tuvalu</mat-option>
                <mat-option value="UG" i18n='@@Uganda'>Uganda</mat-option>
                <mat-option value="UA" i18n='@@Ukraine'>Ukraine</mat-option>
                <mat-option value="AE" i18n='@@United Arab Emirates'>United Arab Emirates</mat-option>
                <mat-option value="GB" i18n='@@United Kingdom'>United Kingdom</mat-option>
                <mat-option value="US" selected="selected" i18n='@@United States'>United States</mat-option>
                <mat-option value="UY" i18n='@@Uruguay'>Uruguay</mat-option>
                <mat-option value="UZ" i18n='@@Uzbekistan'>Uzbekistan</mat-option>
                <mat-option value="VU" i18n='@@Vanuatu'>Vanuatu</mat-option>
                <mat-option value="VS" i18n='@@Vatican City State'>Vatican City State</mat-option>
                <mat-option value="VE" i18n='@@Venezuela'>Venezuela</mat-option>
                <mat-option value="VN" i18n='@@Vietnam'>Vietnam</mat-option>
                <mat-option value="VB" i18n='@@Virgin Islands (Brit)'>Virgin Islands (Brit)</mat-option>
                <mat-option value="VI" i18n='@@Virgin Islands (USA)'>Virgin Islands (USA)</mat-option>
                <mat-option value="WK" i18n='@@Wake Island'>Wake Island</mat-option>
                <mat-option value="WF" i18n='@@Wallis &amp; Futana Is'>Wallis &amp; Futana Is</mat-option>
                <mat-option value="YE" i18n='@@Yemen'>Yemen</mat-option>
                <mat-option value="ZR" i18n='@@Zaire'>Zaire</mat-option>
                <mat-option value="ZM" i18n='@@Zambia'>Zambia</mat-option>
                <mat-option value="ZW" i18n='@@Zimbabwe'>Zimbabwe</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label i18n="@@Zip Code">Zip Code</mat-label>
              <input name="postalcode" [(ngModel)]="alternativeAddress.postalcode" matInput  i18n-placeholder="@@Zip" placeholder="Zip">
            </mat-form-field>
          </form>
        </div>
        <button mat-raised-button color="accent" (click)="validateAddress(alternativeAddress)" i18n="@@Validate Alternative Address">Validate Alternative Address</button>
        <button mat-raised-button color="warn" (click)="useAddressAsShownInAlternativeAddress(orderDeliverTo, alternativeAddress)" i18n="@@Use Address As Shown In Alternative Address">Use Address As Shown In Alternative Address</button></ng-container>
    </ng-container>
    <ng-container *ngIf="orderDeliverTo.addressValidationStatus!=='VALID' && addressValidationResult">
      <ng-container [ngSwitch]="addressValidationResult.validationResult">
        <ng-container *ngSwitchCase="'Success'">
          <h3 i18n="@@Address Validation Suggestions">Address Validation Suggestions</h3>
          <div *ngFor="let address of addressValidationResult.recommendedAddresses">
            <p><span [class.different]="address.address1 !== orderDeliverTo.address1">{{address.address1}}</span><span> </span><span [class.different]="address.address2 !== orderDeliverTo.address2">{{address.address2}}</span></p>
            <p><span [class.different]="address.city !== orderDeliverTo.city">{{address.city}}</span>,<span> </span><span [class.different]="address.state !== orderDeliverTo.state">{{address.state}}</span><span> </span><span [class.different]="address.postalcode !== orderDeliverTo.postalcode">{{address.postalcode}}</span></p>
            <p><span [class.different]="address.country !== orderDeliverTo.country">{{address.country}}</span></p>
            <button *ngIf="address.address1.indexOf('-')<=0" mat-raised-button color="accent" (click)="useThisAddress(address)" i18n="@@Use This Address">Use This Address</button>
          </div>
        </ng-container>
        <div *ngSwitchCase="'NO_COUNTRY_MATCH'" i18n="@@No Country Match">No Country Match</div>
        <div *ngSwitchCase="'EXACT_MATCH'">
          <h3 i18n="@@Exact Match">Exact Match</h3>
          <button *ngIf="addressValidationResult.recommendedAddresses && addressValidationResult.recommendedAddresses.length > 0" mat-raised-button color="accent" (click)="useThisAddress(addressValidationResult.recommendedAddresses[0])" i18n="@@Use This Address">Use This Address</button>
        </div>
        <div *ngSwitchCase="'NO_MEANINGFUL_SUGGESTIONS'" i18n="@@No Meaningful Suggestions">No Meaningful Suggestions</div>
        <div *ngSwitchCase="'HAVE_SUGGESTIONS'" i18n="@@Suggested Address Alternatives">Suggested Address Alternatives</div>
        <div *ngSwitchCase="'ERROR'" i18n="@@Error">Error</div>
      </ng-container>
    </ng-container>
  </mat-card-content>
</mat-card>
