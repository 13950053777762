<div *ngIf="!createOnly && !edit && issue && issue.status === 'OPEN'" class="displalyOnlyContainer" [style.background-color]="supportStatusColors[issue.supportStatus]"  [style.border-color]="userStatusColors[issue.userStatus]" style="border-width: 10px; border-style: solid">
<!--  <div *ngIf="!createOnly && !edit && issue && issue.status === 'OPEN'" class="displalyOnlyContainer" [style.background-color]="issue.assignedTo === 'SUPPORT' ? supportStatusColors[issue.supportStatus] : userStatusColors[issue.userStatus]"  [style.border]="issue.assignedTo === 'SUPPORT' ? 'solid red 5px' : 'solid blue 5px' ">-->
  <div style="display: inline-block; width: calc(100% - 40px)">
    <div><span style="font-weight: bolder">{{issue.objectType | languageString }}: </span> {{issue.objectId}}</div>
    <div *ngIf="issue.supportPerson || issue.supportNote" [style.font-size]="issue.assignedTo === 'SUPPORT' ? 'larger' : 'smaller'"><span style="font-weight: bolder">{{issue.supportPerson}}: </span> {{issue.supportNote}}</div>
    <div *ngIf="issue.userPerson || issue.userNote" [style.font-size]="issue.assignedTo === 'SUPPORT' ? 'smaller' : 'larger'"><span style="font-weight: bolder">{{issue.userPerson}}: </span> {{issue.userNote}}</div>
  </div>
  <button *ngIf="!displayOnly && !edit" mat-icon-button (click)="edit = !edit; copyIssueForEdits(issue);" title="Edit Issue" i18n-title="@@Edit Issue" class="floatingButton"><mat-icon>report_problem</mat-icon></button>
</div>
<ng-container *ngIf="!displayOnly">
  <button [title]="title ? title : 'Report Issue' | languageString" *ngIf="!edit && !(issue && issue.status === 'OPEN')" mat-icon-button (click)="edit = !edit; copyIssueForEdits(issue);"><mat-icon>report_problem</mat-icon></button>
  <div class="issueArea" *ngIf="edit">
    <div style="margin-bottom: 10px;" >
      <h4 style="width: 33%" class="inputField">{{changedIssue.objectType | languageString }} {{changedIssue.objectId}}</h4>
      <mat-slide-toggle style="width: 33%" class="inputField" [ngModel]="changedIssue.assignedTo === 'SUPPORT'" (change)="changed = true; changedIssue.assignedTo === 'SUPPORT' ? changedIssue.assignedTo = 'USER' : changedIssue.assignedTo = 'SUPPORT'"><span i18n="@@Assigned to ">Assigned to </span>{{changedIssue.assignedTo | languageString }} {{changedIssue.assignedTo === 'SUPPORT' ? changedIssue.supportPerson : changedIssue.userPerson}}</mat-slide-toggle>
      <mat-slide-toggle style="width: 33%" class="inputField" [ngModel]="changedIssue.status === 'OPEN'" (change)="changed = true; changedIssue.status === 'OPEN' ? changedIssue.status = 'CLOSED' : changedIssue.status = 'OPEN'">{{changedIssue.status | languageString }}</mat-slide-toggle>
    </div>
    <div style="width: 99%">
      <h4 i18n="@@Support">Support</h4>
      <mat-select style="width: 49%; display: inline-block" class="inputField" name="supportStatus" [(ngModel)]="changedIssue.supportStatus" (selectionChange)="changed = true;" i18n-placeholder="@@Support" placeholder="Support">
        <mat-option [style.background-color]="supportStatusColors['INITIAL']" value="INITIAL" i18n="@@Initial">Initial</mat-option>
        <mat-option [style.background-color]="supportStatusColors['ASSIGNED']" value="ASSIGNED" i18n="@@Assigned">Assigned</mat-option>
        <mat-option [style.background-color]="supportStatusColors['ACKNOWLEDGED']" value="ACKNOWLEDGED" i18n="@@ACKNOWLEDGED">ACKNOWLEDGED</mat-option>
        <mat-option [style.background-color]="supportStatusColors['WORKING']" value="WORKING" i18n="@@Working">Working</mat-option>
        <mat-option [style.background-color]="supportStatusColors['RESOLVED']" value="RESOLVED" i18n="@@Resolved">Resolved</mat-option>
        <mat-option [style.background-color]="supportStatusColors['BLOCKED']" value="BLOCKED" i18n="@@Blocked">Blocked</mat-option>
        <mat-option [style.background-color]="supportStatusColors['CANCELED']" value="CANCELED" i18n="@@Canceled">Canceled</mat-option>
        <mat-option [style.background-color]="supportStatusColors['WAITINGRESPONSE']" value="WAITINGRESPONSE" i18n="@@Waiting Response">Waiting Response</mat-option>
      </mat-select>
      <input style="width: 49%; display: inline-block"  class="inputField" name="supportPerson"  [(ngModel)]="changedIssue.supportPerson" (change)="changed = true;" matInput i18n-placeholder="@@Support Person" placeholder="Support Person"/>
      <textarea style="margin-bottom: 10px;" class="inputField" name="supportNote" [(ngModel)]="changedIssue.supportNote" (change)="changed = true;" matInput i18n-placeholder="@@Support Note" placeholder="support Note"></textarea>
    </div>

    <div style="width: 99%">
      <h4 i18n="@@User">User</h4>
      <mat-select style="width: 49%; display: inline-block" class="inputField" name="userStatus" [(ngModel)]="changedIssue.userStatus" (selectionChange)="changed = true;" i18n-placeholder="@@User" placeholder="User">
        <mat-option [style.background-color]="userStatusColors['INITIAL']" value="INITIAL" i18n="@@Initial">Initial</mat-option>
        <mat-option [style.background-color]="userStatusColors['ASSIGNED']" value="ASSIGNED" i18n="@@Assigned">Assigned</mat-option>
        <mat-option [style.background-color]="userStatusColors['ACKNOWLEDGED']" value="ACKNOWLEDGED" i18n="@@ACKNOWLEDGED">ACKNOWLEDGED</mat-option>
        <mat-option [style.background-color]="userStatusColors['WORKING']" value="WORKING" i18n="@@Working">Working</mat-option>
        <mat-option [style.background-color]="userStatusColors['RESOLVED']" value="RESOLVED" i18n="@@Resolved">Resolved</mat-option>
        <mat-option [style.background-color]="userStatusColors['BLOCKED']" value="BLOCKED" i18n="@@Blocked">Blocked</mat-option>
        <mat-option [style.background-color]="userStatusColors['CANCELED']" value="CANCELED" i18n="@@Canceled">Canceled</mat-option>
        <mat-option [style.background-color]="userStatusColors['WAITINGRESPONSE']" value="WAITINGRESPONSE" i18n="@@Waiting Response">Waiting Response</mat-option>
      </mat-select>
      <input style="width: 49%; display: inline-block" class="inputField" name="userPerson"  [(ngModel)]="changedIssue.userPerson" (change)="changed = true;" matInput i18n-placeholder="@@User" placeholder="User"/>
      <!--  <textarea name="userPerson" [(ngModel)]="changedIssue.userPerson" (change)="changed = true;" matInput i18n-placeholder="@@User" placeholder="User"></textarea>-->
      <textarea class="inputField" name="userNote" [(ngModel)]="changedIssue.userNote" (change)="changed = true;" matInput i18n-placeholder="@@User Note" placeholder="User Note"></textarea>
    </div>
    <span *ngIf="saving" i18n="@@Saving">Saving</span>
    <button mat-icon-button [disabled]="!changed" (click)="save(); edit = !edit" title="Save Issue" i18n-title="@@Save Issue Changes"><mat-icon>save</mat-icon></button>
    <button mat-icon-button (click)="cancel(); edit = !edit" title="Cancel Issue Changes" i18n-title="@@Cancel Issue Changes"><mat-icon>cancel</mat-icon></button>
    <!--    </form>-->
  </div>
</ng-container>
