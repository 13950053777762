import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {NotificationsService} from "./notifications.service";
import {PrintNodeOrder} from "../../model/PrintNodeOrder";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {ObjectAttachRequest} from "./data-service";

@Injectable()
export class PrintNodeOrderService extends AbstractLiveDataService<PrintNodeOrder> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    notificationsService.setPrintNodeOrderService(this);
    notificationsService.startNotification();
  }

  makeObjectInstance(args: any): PrintNodeOrder {
    return new PrintNodeOrder(args);
  }

  getLoggingObjectTypeName (): string {
    return "PrintNodeOrder";
  }

  supportsParallel(): boolean {
    return true;
  }

  getURLEndPoint (): string {
    return "printnodeorder";
  }

  updatePrintNodeOrderItems(changeItems: any) {
    const url = `${environment.apiBaseURL}printnodeorder/items/${changeItems.printNodeOrderId}`;
    this.messageService.add(`PrintNodeOrderService: updated printNodeOrder id=${changeItems.printNodeOrderId}`);

    return this.http.put(url, changeItems, this.startupService.getHttpOptions()).
    pipe(tap(_ => this.log(`updated printNodeOrder id=${changeItems.printNodeOrderId}`)), catchError(this.handleErrorX<any>('updatePrintNodeOrderWithItems')));
  }

  changeAddress (printNodeOrder: PrintNodeOrder) {
    const url = `${environment.apiBaseURL}printnodeorder/changeaddress/${printNodeOrder.ID}`;
    this.messageService.add(`PrintNodeOrderService: updated printNodeOrder id=${printNodeOrder.ID}`);

    const printNodeOrderAddress = {};
    printNodeOrderAddress['ID'] = printNodeOrder.ID;
    printNodeOrderAddress['orderDeliverToId'] = printNodeOrder.orderdeliverto_ID;
    printNodeOrderAddress['address1'] = printNodeOrder.address1;
    printNodeOrderAddress['address2'] = printNodeOrder.address2;
    printNodeOrderAddress['city'] = printNodeOrder.city;
    printNodeOrderAddress['state'] = printNodeOrder.state;
    printNodeOrderAddress['country'] = printNodeOrder.country;
    printNodeOrderAddress['postalcode'] = printNodeOrder.postalcode;

    return this.http.put(url, printNodeOrderAddress, this.startupService.getHttpOptions()).
    pipe(tap(_ => this.log(`updated printNodeOrder id=${printNodeOrder.ID}`)), catchError(this.handleErrorX<any>('updated address in PrintNodeOrder')));
  }

  split (splitPrintNodeOrderRequest: any) {
    const url = `${environment.apiBaseURL}printnodeorder/split`;
    return this.http.put(url, splitPrintNodeOrderRequest, this.startupService.getHttpOptions()).
    pipe(tap(_ => this.log(`Split printNodeOrder ${splitPrintNodeOrderRequest}`)), catchError(this.handleErrorX<any>('Split PrintNodeOrder')));
  }
  changePackages(packageChangeRequest: any) {
    const url = `${environment.apiBaseURL}printnodeorder/changepackages`;
    return this.http.put(url, packageChangeRequest, this.startupService.getHttpOptions()).
    pipe(tap(_ => this.log(`Change Pakcages in PrintNodeOrder ${packageChangeRequest}`)), catchError(this.handleErrorX<any>('PackageChange PrintNodeOrder')));
  }

  // changeFacility (printNodeOrder: PrintNodeOrder) {
  //   const url = `${environment.apiBaseURL}printnodeorder/changefacility/${printNodeOrder.ID}/${printNodeOrder.printnode_ID}`;
  //   this.messageService.add(`PrintNodeOrderService: change facility printNodeOrder id=${printNodeOrder.ID} new facility ${printNodeOrder.printnode_ID}`);
  //   return this.http.put(url, {}, this.startupService.getHttpOptions()).
  //   pipe(tap(_ => this.log(`updated printNodeOrder id=${printNodeOrder.ID}`)), catchError(this.handleErrorX<any>('updated address in PrintNodeOrder')));
  // }
  //
  // /** PUT: move PrintNodeOrderItem to a specific ManufacturingTaskBucket */
  // movePrintNodeOrderToManufacturingTaskBucket (printNodeOrderId: number, manufacturingTaskBucketId: number): Observable<ManufacturingTaskBucket> {
  //   const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/moveprintnodeordertomanufacturingtaskbucket/${printNodeOrderId}/${manufacturingTaskBucketId}`;
  //   this.messageService.add(`PrintNodeOrderService: movePrintNodeOrderToManufacturingTaskBucket manufacturingTaskBucketId=${manufacturingTaskBucketId}  printNodeOrderId=${printNodeOrderId}`);
  //   return this.http.put(url, {}, this.startupService.getHttpOptions())
  //     .pipe(
  //       map(manufacturingTaskBucket => {
  //         const m = new ManufacturingTaskBucket(manufacturingTaskBucket);
  //         return m;
  //       }),
  //       catchError(this.handleErrorX<any>('movePrintNodeOrder'))
  //     );
  // }
  //
  // /** PUT: move PrintNodeOrderItem to a specific ManufacturingTaskBucketPeriod */
  // movePrintNodeOrderToManufacturingTaskBucketPeriod (printNodeOrderId: number, manufacturingTaskBucketPeriodId: number): Observable<ManufacturingTaskBucketPeriod> {
  //   const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/moveprintnodeordertomanufacturingtaskbucketperiod/${printNodeOrderId}/${manufacturingTaskBucketPeriodId}`;
  //   this.messageService.add(`PrintNodeOrderService: movePrintNodeOrderToManufacturingTaskBucketPeriod manufacturingTaskBucketPeriodId=${manufacturingTaskBucketPeriodId}  printNodeOrderId=${printNodeOrderId}`);
  //   return this.http.put(url, {}, this.startupService.getHttpOptions())
  //     .pipe(
  //       map(manufacturingTaskBucketPeriod => {
  //         const m = new ManufacturingTaskBucketPeriod(manufacturingTaskBucketPeriod);
  //         return m;
  //       }),
  //       catchError(this.handleErrorX<any>('movePrintNodeOrder'))
  //     );
  // }

  private handleErrorX<T> (role: string, operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState[role] = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getLinkData(): any[] {
    return [
      { action: 'AddUpdateArray', serviceName: 'printNodeOrderItemService', arrayProperty: 'printNodeOrderItems'},
      { action: 'Link', objectIdPropertyName: 'manufacturingTaskBucketPeriod_id', serviceName: 'manufacturingTaskBucketPeriodService', arrayProperty: 'manufacturingSets', objectProperty: 'manufacturingTaskBucketPeriod'},
      { action: 'Link', objectIdPropertyName: 'org_ID', serviceName: 'organizationService', arrayProperty: 'printNodeOrders', objectProperty: 'organization'},
      { action: 'AddUpdateArray', serviceName: 'manufacturingTaskService', arrayProperty: 'manufacturingTasks'},
    ];
  }

  // linkObject(newObject: PrintNodeOrder, rawObject: PrintNodeOrder) {
  //   if ( rawObject.organization ) {
  //     this.notificationsService.addUpdateLocalItem('organizationService', rawObject.organization);
  //   }
  //   if ( newObject.org_ID ) {
  //     this.notificationsService.addObjectAttachRequest('organizationService', new ObjectAttachRequest(newObject.org_ID, 'printNodeOrders', 'organization', newObject));
  //   }
  //   if ( rawObject.printNodeOrderItems ) {
  //     for ( const printNodeOrderItem of rawObject.printNodeOrderItems ) {
  //       this.notificationsService.addUpdateLocalItem('printNodeOrderItemService', printNodeOrderItem);
  //     }
  //   }
  // }
}
