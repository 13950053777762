import {Chapter} from "./Chapter";
import {Cover} from "./Cover";
import {Interior} from "./Interior";
import {ProjectProperties} from "../ProjectProperties";

export class Project {
  public id: string;
  public name: string;
  public title: string;
  public author: string;
  public publisher: string;
  public rights: string;
  public description: string;
  public width: string;
  public height: string;
  public spineWidth: string;
  public pages: number;
  public paperType: string;
  public chapterString: string;
  public contentsString: string;
  public numbersString: string;
  public bookTitle: string;
  public trimSize: string;
  public coverImageUrl: string;
  public coverPreviewUrl: string;
  public bodyPreviewUrl: string;
  public ebookPreviewUrl: string;
  public status: string;
  public cover: Cover;
  public interior: Interior;
  public language: string;
  public direction: string;
  public progress: number;
  public timestamp: number;
  public renderedSaveTimestamp: number;
  public pagesRenderedCoverIsBasedOn: number;
  public savingStatus: string;
  public skipCoverRendering: boolean;
  public coverNeedsSavingWithNewSpineSize: boolean;


  constructor( obj ) {
    // Set defaults that will get over written if properties are present in the obj passed in
    this.width = '6in';
    this.height = '9in';
    this.spineWidth = '0.2in';
    this.pages = 9;
    this.chapterString = 'Chapter';
    this.contentsString = 'Contents';
    this.numbersString = '0123456789';
    this.trimSize = this.width + "X" + this.height;
    this.status = 'INITIAL';
    this.id = '';
    this.name = '';
    this.title = '';
    this.author = '';
    this.publisher = '';
    this.rights = '';
    this.description = '';
    this.bookTitle = '';
    this.coverImageUrl = '';
    this.coverPreviewUrl = '';
    this.bodyPreviewUrl = '';
    this.ebookPreviewUrl = '';
    this.language = 'en';
    this.direction = 'ltr';
    this.progress = 0;
    this.timestamp = 0;
    this.renderedSaveTimestamp = 0;
    this.pagesRenderedCoverIsBasedOn = 0;
    this.skipCoverRendering = false;
    this.coverNeedsSavingWithNewSpineSize = false;

    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        this[prop] = obj[prop];
      }
    }

    if ( obj ) {
      this.cover = new Cover( obj.cover );
      this.interior = new Interior( obj.interior );
    } else {
      this.cover = new Cover( undefined );
      this.interior = new Interior( undefined );
    }
    this.savingStatus = 'Saved';
  }


  updateStatusAndPages(projectProperties: ProjectProperties) {
    if ( this.timestamp < projectProperties.timestamp ) {
      // TODO: Should we have them reload to refresh this project -- could end up in cycling if we are not careful.
      this.status = "STALE";
      this.progress = 0;
    } else if ( this.timestamp > projectProperties.timestamp ) {
      this.renderedSaveTimestamp = projectProperties.timestamp;
      this.pagesRenderedCoverIsBasedOn = projectProperties.pagesCoverIsBasedOn;
      if ( this.timestamp + ( 1000 * 60 * 30 ) < Date.now()) { // If more than 30 minutes old
        this.status = "ERROR";
        this.progress = 0;
        console.error('project.ID:' + this.id + '  project.timestamp:' + this.timestamp + '  projectProperties.timestamp' + projectProperties.timestamp + ' This project has not rendered in ' + ( ( Date.now() - this.timestamp ) / ( 1000 * 60 ) )  + 'minutes');
      } else { // Wait for it to get done

      }
    } else if ( this.timestamp === projectProperties.timestamp ) {
      this.renderedSaveTimestamp = projectProperties.timestamp;
      this.pagesRenderedCoverIsBasedOn = projectProperties.pagesCoverIsBasedOn;
      if ( Math.abs(this.pages - projectProperties.pages) > 4 ) {
        this.coverNeedsSavingWithNewSpineSize = true;
        this.status = "SAVE_AND_RERENDER";
        this.progress = 0;
      } else {
        this.coverNeedsSavingWithNewSpineSize = false;
        this.status = "READY";
        this.progress = 11;
      }
      this.pages = projectProperties.pages;
    }
  }
  // public setTrimSize(trimSize: string) {
  //   const widthAndHeight = trimSize.split('X');
  //   this.width = widthAndHeight[0];
  //   this.height = widthAndHeight[1];
  // }
}
