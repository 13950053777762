import {Component, Input, OnInit} from '@angular/core';
import {Project} from "../../../model/editor/Project";
import {FileUploader} from "../../file-upload/file-uploader.class";
import {environment} from "../../../environments/environment";
import {Chapter} from "../../../model/editor/Chapter";
import {EditorProjectService} from "../../service-data/editor/editor-project.service";
import {StartupService} from "../../service-ui/startup.service";
import {FileItem} from "../../file-upload/file-item.class";

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  @Input() project: Project;

  public uploader: FileUploader = new FileUploader( { } );

  public hasBaseDropZoneOver: boolean = false;


  constructor(public startupService: StartupService, public projectService: EditorProjectService) {
    this.uploader.setOptions({isHTML5: true, url: environment.apiBaseURL, disableMultipart: true, method: 'PUT', allowedMimeType: ['audio/mp3', 'video/mp4'], onSuccessFunction: this.audioAdded.bind(this), urlEndPointType: 'audio', projectService: this.projectService, headers: [{name: 'x-amz-acl', value: 'public-read'}, {name: 'accesstoken', value: this.startupService.getJWTToken()}]});
    this.startupService.uploader = this.uploader;
  }

  ngOnInit() {
  }

  audioAdded(fileItem: FileItem) {
    console.log("Audio Added");
    // const chapter = new Chapter( undefined );
    // chapter.ID = fileItem.chapterId;
    // chapter.source = 'Audio';
    // chapter.type = 'standard';
    // this.project.interior.chapters.push(chapter);
    // this.startupService.transcriptionsCart.addTranscription(this.startupService.project.name, fileItem.chapterId, fileItem._file.name, URL.createObjectURL(fileItem._file), fileItem.duration, fileItem.charge);
  }

  public fileOverBase( e: any ): void {
    this.hasBaseDropZoneOver = e;
  }

  // addNotUploadedItemsToCart(): void {
  //   for ( const item of this.uploader.getNotUploadedItems()) {
  //     // this.startupService.transcriptionsCart.addTranscription(this.startupService.project.name, item.chapterId,  item._file.name, item.url, item.duration, item.charge);
  //   }
  // }
  //
}

