<div class="imageSelector">
<!--  <div class="imageSelector" *ngIf="startupService.canvasManager">-->
  <mat-tab-group>
<!--    <span style="position: fixed; bottom: 20px; color: white;"><button class="addChaptersFAB" mat-fab color="primary" (click)="showAddChaptersOptions = !showAddChaptersOptions"><mat-icon>add</mat-icon></button></span>-->
<!--    <span *ngIf="showAddChaptersOptions" style="position: fixed; bottom: 80px; color: white;"><button class="addChaptersFAB" mat-fab color="primary" (click)="showAddChaptersOptions = !showAddChaptersOptions; addChapter('standard')" i18n-title="@@Add Chapter" title="Add Chapter"  i18n-defaultchaptertitle="@@DefaultChapterTitle" defaultchaptertitle="Default Chapter Title" i18n-defaultchaptercontents="@@DefaultChapterContents" defaultchaptercontent='<h2>Your Chapter Title Here</h><p>Replace with your content.</p>' #standard><mat-icon>book</mat-icon><span> </span><ng-container i18n="@@Chapter">Chatper</ng-container></button></span>-->
<!--    <span *ngIf="showAddChaptersOptions" style="position: fixed; bottom: 140px; color: white;"><button class="addChaptersFAB" mat-fab color="primary" (click)="showAddChaptersOptions = !showAddChaptersOptions; addChapter('copyright')" i18n-title="@@Add Copyright" title="Add Copyright"  i18n-defaultchaptertitle="@@DefaultCopyrightTitle" defaultchaptertitle="Default Copyright Title" i18n-defaultchaptercontents="@@DefaultCopyrightPageContents" defaultchaptercontent='<h3>&#x24B8; Your Name Here</h3>' #copyright ><mat-icon>copyright</mat-icon><span> </span><ng-container i18n="@@Copyright">Copyright</ng-container></button></span>-->
    <ng-container *ngIf="startupService.getSubscription().getSubscriptionLevel() !== 'STARTER' && startupService.getSubscription().getSubscriptionLevel() !== 'STANDARD'">
      <mat-tab i18n-label="@@Backgrounds" label="Backgrounds">
        <ng-container *ngIf="userBackgroundLibrary">
          <button mat-raised-button (click)="showAddBackgroundImages = !showAddBackgroundImages" i18n="@@Add Background Images">Add Background Images</button>
          <div *ngIf="showAddBackgroundImages">
            <app-upload-images library="backgrounds"></app-upload-images>
          </div>
          <div class="image userImage" *ngFor="let image of userBackgroundLibrary">
            <img [src]='imageService.getFreshImageUrl(image, true)' [id]="image.key + '-thumbnail'" (click)="onSelect(image)">
          </div>
        </ng-container>
<!--        <ng-container *ngIf="backgroundLibrary && backgroundLibrary.length > 0">-->
<!--          <div class="image" *ngFor="let image of backgroundLibrary">-->
<!--            <img [src]='imageService.getFreshImageUrl(image, true)' [ID]="image.key + '-thumbnail'" (click)="onSelect(image)">-->
<!--          </div>-->
<!--        </ng-container>-->
      </mat-tab>
      <mat-tab i18n-label="@@Transparent" label="Transparent">
        <ng-container *ngIf="userTransparentLibrary">
          <button mat-raised-button (click)="showAddTransparentImages = !showAddTransparentImages" i18n="@@Add Transparent Images">Add Transparent Images</button>
          <div *ngIf="showAddTransparentImages">
            <app-upload-images library="transparent"></app-upload-images>
          </div>
          <div class="image userImage" *ngFor="let image of userTransparentLibrary">
            <img [src]='imageService.getFreshImageUrl(image, true)' [id]="image.key + '-thumbnail'" (click)="onSelect(image)">
          </div>
        </ng-container>
<!--        <ng-container *ngIf="transparentLibrary && transparentLibrary.length > 0">-->
<!--          <div class="image" *ngFor="let image of transparentLibrary">-->
<!--            <img [src]='imageService.getFreshImageUrl(image, true)' [ID]="image.key + '-thumbnail'" (click)="onSelect(image)">-->
<!--          </div>-->
<!--        </ng-container>-->
      </mat-tab>
    </ng-container>
    <mat-tab *ngIf="startupService.getSubscription().getSubscriptionLevel() !== 'STARTER'" i18n-label="@@Logos" label="Logos">
      <ng-container *ngIf="userLogoLibrary">
        <button mat-raised-button (click)="showAddLogoImages = !showAddLogoImages" i18n="@@Add Logo Images">Add Logo Images</button>
        <div *ngIf="showAddLogoImages">
          <app-upload-images library="logo"></app-upload-images>
        </div>
        <div class="image userImage" *ngFor="let image of userLogoLibrary">
          <img [src]='imageService.getFreshImageUrl(image, true)' [id]="image.key + '-thumbnail'" (click)="onSelect(image)">
        </div>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
    <!--<div class="imageLibraries">-->
    <!--<div *ngIf="selectedLibrary === 'backgrounds'" class="background imageLibrary">-->
      <!--<div>-->
        <!--<span class="toolbarButton fa arrowLeft fa-chevron-left" i18n-title="@@Previous Image Library" title="Previous Image Library" (click)="selectedLibrary = 'transparent'"></span>-->
        <!--<h4 class="imageLibraryHeading" i18n="@@Backgrounds">Backgrounds</h4>-->
        <!--<span class="toolbarButton fa fa-chevron-right" i18n-title="@@Next Image Library" title="Next Image Library" (click)="selectedLibrary = 'transparent'"></span>-->
      <!--</div>-->
    <!--</div>-->
    <!--<div *ngIf="selectedLibrary === 'transparent'" class="transparent imageLibrary">-->
      <!--<div>-->
        <!--<span class="toolbarButton fa arrowLeft fa-chevron-left" i18n-title="@@Previous Image Library" title="Previous Image Library" (click)="selectedLibrary = 'backgrounds'"></span>-->
        <!--<h4 class="imageLibraryHeading" i18n="@@Transparent">Transparent</h4>-->
        <!--<span class="toolbarButton fa fa-chevron-right" i18n-title="@@Next Image Library" title="Next Image Library" (click)="selectedLibrary = 'backgrounds'"></span>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->
</div>

