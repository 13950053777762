<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Unreceive Replenishment Order">Unreceive Replenishment Order</h2>
  </div>
  <div>
    <form [formGroup]="formGroup" (change)="formChanged()">
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="formGroup.invalid" (click)="onSave()" i18n="@@Confirm Unreceiving Replenishment Order">Confirm Unreceiving Replenishment Order</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
