import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import { AbstractDataService } from "./abstract-data.service";
import {ManufacturingResource} from "../../model/ManufacturingResource";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {NotificationsService} from "./notifications.service";

@Injectable()
export class ManufacturingResourceService extends AbstractLiveDataService<ManufacturingResource> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setManufacturingResourceService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): ManufacturingResource {
    const object = new ManufacturingResource(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "ManufacturingResource";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "manufacturingresource";
  }

  getLinkData(): any[] {
    return [];
  }
}
