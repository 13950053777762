import {Component, Inject, OnInit, VERSION, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {Product} from "../../../../model/Product";
import {FacilityInventoryProductService} from "../../../service-data/facility-inventory-product.service";
import {StartupService} from "../../../service-ui/startup.service";
import {AddFacilityInventoryProductForOrganizationComponent} from "./add-facility-inventory-product-for-organization/add-facility-inventory-product-for-organization.component";

@Component({
  selector: 'app-manage-facility-inventory-products',
  templateUrl: './manage-facility-inventory-products.component.html',
  styleUrls: ['./manage-facility-inventory-products.component.css']
})
export class ManageFacilityInventoryProductsComponent implements OnInit {
  public product: Product;
  public products: Product[];
  public breakpoint: number; // Breakpoint observer code
  public addForm: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public facilityInventoryProductService: FacilityInventoryProductService, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<ManageFacilityInventoryProductsComponent>, public startupService: StartupService) {
    this.product = data.product;
    this.products = data.products;
  }

  public ngOnInit(): void {
    this.addForm = this.formBuilder.group({
    });

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  public openAddDialog (): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { product: this.product, products: this.products };
    const dialogRef = this.dialog.open(AddFacilityInventoryProductForOrganizationComponent, dialogConfig);
  }

}
