import {Component, Input, OnInit} from '@angular/core';
import {of} from 'rxjs';
import {StartupService} from "../../../service-ui/startup.service";
import {PickSheetService} from "../../../service-data/pick-sheet.service";
import {PrintNodeOrder} from "../../../../model/PrintNodeOrder";
import {PickSheetsGroup} from "../PickSheetGroup";
import {InventoryLevelsComputer} from "../InventoryLevelsComputer";

@Component({
  selector: 'app-picksheets-group',
  templateUrl: './picksheets-group.component.html',
  styleUrls: ['./picksheets-group.component.css']
})

export class PicksheetsGroupComponent implements OnInit {
  @Input() pickSheetsGroup: PickSheetsGroup;
  @Input() inventoryLevelsComputer: InventoryLevelsComputer;

  constructor(public startupService: StartupService, private pickSheetService: PickSheetService) {
  }

  ngOnInit() {
  }


  toggleSelected (pickSheetsGroup: PickSheetsGroup) {
    if ( pickSheetsGroup.selected ) {
      this.inventoryLevelsComputer.removeInventoryCounts(pickSheetsGroup);
    } else {
      this.inventoryLevelsComputer.addInventoryCounts(pickSheetsGroup);
    }
    pickSheetsGroup.selected = !pickSheetsGroup.selected;

  }
}
