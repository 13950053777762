import {Component, Inject, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../../pipe/language.pipe";
import {UserService} from "../../../../service-data/user.service";
import {Product} from "../../../../../model/Product";
import {FacilityInventoryProduct} from "../../../../../model/FacilityInventoryProduct";
import {FacilityInventoryProductService} from "../../../../service-data/facility-inventory-product.service";
import {Organization} from "../../../../../model/Organization";
import {OrganizationService} from "../../../../service-data/organization.service";
import {ProductService} from "../../../../service-data/product.service";
import {MatTableDataSource} from "@angular/material/table";
import {FacilityInventoryProductEvent} from "../../../../../model/FacilityInventoryProductEvent";
import {FacilityInventoryProductEventService} from "../../../../service-data/facility-inventory-product-event.service";

@Component({
  selector: 'app-adjust-facility-inventory-product-inventory',
  templateUrl: './adjust-facility-inventory-product-inventory-component.html',
  styleUrls: ['./adjust-facility-inventory-product-inventory-component.css']
})
export class AdjustFacilityInventoryProductInventoryComponent implements OnInit {
  public products: Product[];
  public facilityInventoryProduct: FacilityInventoryProduct;
  public breakpoint: number; // Breakpoint observer code
  public actionType: string = `RecordCurrentOnHandInventoryLevel`;
  public date: Date = new Date();
  public quantity: number = 0;
  public formGroup: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public facilityInventoryProductEventService: FacilityInventoryProductEventService, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<AdjustFacilityInventoryProductInventoryComponent>) {
    this.facilityInventoryProduct = data.facilityInventoryProduct;
    this.date = new Date();
    this.quantity = 0;
    if ( data.actionType === 'RecordCurrentOnHandInventoryLevel') {
      this.quantity = this.facilityInventoryProduct.quantityOnHand;
    }
    this.actionType = data.actionType;
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      date: [this.date, [Validators.required]],
      quantity: [this.quantity, [Validators.required]],
    });

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }
  saveRange() {
    console.log('ssss');
  }
  public onSave(): void {
    const objectToAdd = new FacilityInventoryProductEvent(undefined, this.facilityInventoryProduct);
    objectToAdd.printnode_ID = this.facilityInventoryProduct.printnode_ID;
    // objectToAdd.facilityInventoryProduct = this.facilityInventoryProduct;
    objectToAdd.facilityinventoryproduct_ID = this.facilityInventoryProduct.ID;
    objectToAdd.quantity = this.formGroup.controls.quantity.value;
    objectToAdd.status = 'VALID';
    objectToAdd.type = 'ACTUAL';
    objectToAdd.timestamp = this.formGroup.controls.date.value;

    switch ( this.actionType ) {
      case 'ReceiveExternalReplenishment': {
        objectToAdd.source = 'REPLENISHMENT';
        break;
      }
      case 'RecordCurrentOnHandInventoryLevel': {
        objectToAdd.source = 'CORRECTION';
        objectToAdd.quantity = this.formGroup.controls.quantity.value - this.facilityInventoryProduct.quantityOnHand;
        break;
      }
      case 'ReceiveReturn': {
        objectToAdd.source = 'RETURN';
        break;
      }
      case 'CorrectInventoryLevel': {
        objectToAdd.source = 'CORRECTION';
        break;
      }
    }

    this.facilityInventoryProductEventService.addFacilityInventoryProductEvent(objectToAdd, this.facilityInventoryProduct).subscribe( object => {
      this.facilityInventoryProduct.facilityInventoryProductEvents.push(object);
      console.log(`Added FacilityInventoryProductEvent : ${JSON.stringify(object)}`);
    });
    this.markAsDirty(this.formGroup);
    this.dialogRef.close();
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
