<mat-card appearance="outlined">
  <mat-card-content>
    <button *ngIf="printNodeOrder.inventoryType === 'FULFILL_FROM_INVENTORY'" mat-icon-button (click)="printPickSheet(printNodeOrder, 'B')" i18n-matTooltip="@@Print Pick Sheet" matTooltip="Print Pick Sheet"><mat-icon>print</mat-icon></button>
    <button *ngIf="printNodeOrder.inventoryType === 'FULFILL_FROM_INVENTORY'" mat-icon-button (click)="printPickSheet(printNodeOrder, 'B')" i18n-matTooltip="@@Print Pick Sheet" matTooltip="Print Pick Sheet"><mat-icon>print</mat-icon></button>
    <ng-container *ngIf="printNodeOrderWithItems">
      <ng-container *ngFor="let printNodeOrderItem of printNodeOrderWithItems.printNodeOrderItems">
        <app-print-node-order-item [printNodeOrderItem]="printNodeOrderItem"></app-print-node-order-item>
        <app-facility-print-node-order-item [printNodeOrderItem]="printNodeOrderItem"></app-facility-print-node-order-item>
      </ng-container>
    </ng-container>
  </mat-card-content>
</mat-card>
