<div>
  <div class="add-title">
    <h2 class="primary">
      <span i18n="@@Change Items In Orders">Change Items In Orders</span>
      <button (click)="addPrintNodeOrder()"><mat-icon>plus</mat-icon></button>
    </h2>
  </div>

  <div class="mainArea">
    <div [class]="mobileQueryMatches ? 'scrollMobile' :'scroll'">
      <table class="scroll">
        <tbody>
          <!-- Print Node Orders -->
          <tr>
            <td class="border: none"></td>
            <td class="cell" *ngFor="let printNodeOrder of printNodeOrders">
              <ng-container *ngIf="printNodeOrder && printNodeOrder.ID !== 0" >{{printNodeOrder.ID}} <ng-container *ngIf="printNodeOrder.shippingStatus === 'SHIPPED'">{{printNodeOrder.shippingStatus}}</ng-container></ng-container>
            </td>
          </tr>
          <tr [style.border-color]="printNodeOrderItemTotals[i] > printNodeOrderItems[i].quantity? 'red': (printNodeOrderItemTotals[i] < printNodeOrderItems[i].quantity? 'orange': '')" *ngFor="let printNodeOrderItem of printNodeOrderItems; index as i" style="border-width: 3px; border-style: solid;">
            <th class="book">
              <div class="bookId">{{printNodeOrderItem.book_ID}}</div>
              <div class="bookTitle">{{printNodeOrderItem.book.title}}</div>
              <div class="bookQuantity">{{printNodeOrderItem.quantity}}</div>
              <div class="bookTrimSize">{{printNodeOrderItem.book.body.width/72 | number: '1.1-1' }}in X{{printNodeOrderItem.book.body.height/72 | number: '1.1-1' }}in</div>
              <div *ngIf="!showCompressed">
                <span class="bookWeight">{{printNodeOrderItem.book.weight | number: '1.1-1'}}lbs</span>
                <span class="bookThickness">{{printNodeOrderItem.book.body.spine /72  | number: '1.1-1'}}in</span>
              </div>
              <div class="bookType" *ngIf="printNodeOrderItem.type !== 'INITIAL'">{{printNodeOrderItem.type}}</div>
              <div *ngIf="printNodeOrderItem.quantity > 1">
                <span class="bookTotalWeight">{{printNodeOrderItem.weight | number: '1.1-1'}}lbs</span>
                <span class="bookTotalThickness">{{printNodeOrderItem.book.body.spine /72 * printNodeOrderItem.quantity | number: '1.1-1'}}in</span>
              </div>
              <ng-container *ngIf="originalPrintNodeOrderItemTotals[i] < printNodeOrderItemTotals[i]">
                <div class="bookQuantityOver">{{printNodeOrderItemTotals[i] - originalPrintNodeOrderItemTotals[i]}}</div>
              </ng-container>
              <ng-container *ngIf="originalPrintNodeOrderItemTotals[i] > printNodeOrderItemTotals[i]">
                <div class="bookQuantityUnder">{{printNodeOrderItemTotals[i] - originalPrintNodeOrderItemTotals[i]}}</div>
              </ng-container>
            </th>
            <td class="cell" *ngFor="let item of printNodeOrderItemToPrintNodeOrderQuantityMap[i]; index as j" >
              <div class="cellQuantity">
                <table>
                  <tr>
                    <td style="border: none">
                      <span class="cellQuantity" >{{ printNodeOrderItemToPrintNodeOrderQuantityMap[i][j] }}</span>
                    </td>
                    <td style="border: none">
                      <button style="display:block" *ngIf="printNodeOrderItemToPrintNodeOrderQuantityMap[i][j] < printNodeOrderItem.quantity" mat-icon-button color="primary" (click)="changeQuantity(i, j, 1)" i18n="@@Increase"><mat-icon>arrow_upward</mat-icon></button>
                      <button style="display:block" *ngIf="printNodeOrderItemToPrintNodeOrderQuantityMap[i][j] > 0" mat-icon-button color="primary" (click)="changeQuantity(i, j, -1)" i18n="@@Decrease"><mat-icon>arrow_downward</mat-icon></button>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
<!--  <ng-container *ngSwitchDefault><div i18n="@@Loading">Loading</div></ng-container>-->
  <button mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="!canSave" (click)="saveChanges()" i18n="@@Save Changes">Save Changes</button>
<!--  <button mat-raised-button type="submit" color="primary" class="Update-btn" (click)="saveChanges()" i18n="@@Save Changes">Save Changes</button>-->
  <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
</div>
