<mat-card appearance="outlined">
  <mat-card-content>
    <p>{{orderDeliverTo.ID}}</p>
    <p>{{orderDeliverTo.firstname}}</p>
    <p>{{orderDeliverTo.lastname}}</p>
    <p>{{orderDeliverTo.address1}}</p>
    <p>{{orderDeliverTo.address2}}</p>
    <p>{{orderDeliverTo.city}}</p>
    <p>{{orderDeliverTo.state}}</p>
    <p>{{orderDeliverTo.country}}</p>
    <p>{{orderDeliverTo.postalcode}}</p>
<!--    <ng-container *ngFor="let orderItem of orderDeliverTo.orderItems">-->
<!--      <app-order-item [orderItem]="orderItem"></app-order-item>-->
<!--    </ng-container>-->

  </mat-card-content>
</mat-card>
