import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {ManufacturingSet} from "../../../model/ManufacturingSet";

import {Package} from "../../../model/Package";
import {ManufacturingSetService} from "../../service-data/manufacturing-set.service";
import {PackageService} from "../../service-data/package.service";
import {PackageTypeService} from "../../service-data/package-type.service";
import {PackageType} from "../../../model/PackageType";
import {ManufacturingTaskService} from "../../service-data/manufacturing-task.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {FacilityPackagesSetFilterComponent} from "./facility-packages-set-filter/facility-packages-set-filter.component";
import {PrintNodeOrder} from "../../../model/PrintNodeOrder";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {PrintNodeOrderService} from "../../service-data/print-node-order.service";

@Component({
  selector: 'app-facility-packages',
  templateUrl: './facility-packages.component.html',
  styleUrls: ['./facility-packages.component.css']
})

export class FacilityPackagesComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, RoleChangeListener {
  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  printNodeOrders: PrintNodeOrder[];
  packages: Package[];
  manufacturingSets: ManufacturingSet[];
  packageTypes: PackageType[];

  // Display flags and filters
  filterOn: boolean = false;
  showOnlyNotShipped: boolean = true;
  showOnlyTrimmed: boolean = false;
  showPrintNodeOrderItems: boolean = true;

  focusedSet = 0;
  expandPackages: any = {};
  expandAddress: any = {};
  packagesToHide: any = {};
  expandPrintNodeOrderItemTools: any = {};
  expandReplanPackages: any = {};

  noData = false;
  selectedFilter = 'None';
  selectedFilterValue = '';

  manufacturingSetByPrintNodeOrderId: any = {};
  manufacturingSetByPrintNodeOrderItemId: any = {};
  manufacturingSetByPackageId: any = {};
  manufacturingSetById: any = {};
  manufacturingTaskById: any = {};
  manufacturingSetByPackageIdNotCompleted: any = {};
  packageTypeById: any = {};
  packagesByPrintNodeOrderId: any = {};

  numberOfNotShippedPackagesInPrintNodeOrder: any = {};
  expandPrintNodeOrderPackages: any = {};

  dataIndex = 0; // 0 for Quantity, 1 for feetOrSheets, 2 for number of line items
  Math = Math;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  nextManufacturingTaskColors = {
    'BodyPrint_INITIAL': '',
    'BodyPrint_STARTED': 'yellow',
    'BodyPrint_ERROR': 'pink',
    'BodyPrint_COMPLETED': 'orange',
    'CoverPrint_INITIAL': '',
    'CoverPrint_STARTED': 'yellow',
    'CoverPrint_ERROR': 'pink',
    'CoverPrint_COMPLETED': 'lightapricot',
    'Laminate_INITIAL': 'lightapricot',
    'Laminate_STARTED': 'lightapricot',
    'Laminate_ERROR': 'pink',
    'Laminate_COMPLETED': 'lightblue',
    'CutApart_INITIAL': 'lightapricot',
    'CutApart_STARTED': 'lightapricot',
    'CutApart_ERROR': 'pink',
    'CutApart_COMPLETED': 'lightblue',
    'Bind_INITIAL': 'lightblue',
    'Bind_STARTED': 'lightblue',
    'Bind_ERROR': 'pink',
    'Bind_COMPLETED': 'lightblue',
    'Trim_INITIAL': 'lightblue',
    'Trim_STARTED': 'lightblue',
    'Trim_ERROR': 'pink',
    'Trim_COMPLETED': 'lightgreen',
    'Pack_INITIAL': 'lightgreen',
    'Pack_STARTED': 'lightgreen',
    'Pack_ERROR': 'pink',
    'Pack_COMPLETED': 'grey',
    'Ship_INITIAL': 'grey',
    'Ship_STARTED': 'grey',
    'Ship_ERROR': 'pink',
    'Ship_COMPLETED': 'grey'
  };

  constructor(public router: Router, public startupService: StartupService, private manufacturingTaskService: ManufacturingTaskService, private manufacturingSetService: ManufacturingSetService, private packageService: PackageService, private packageTypeService: PackageTypeService, private printNodeOrderSevice: PrintNodeOrderService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher, public dialog: MatDialog) {
    this.startupService.addRoleChangeListener(this);
    this.startupService.touchLastUserActivity();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }


  ngOnInit() {
    this.startupService.isAuthenticated(this);
  }

  ngAfterViewInit(): void {
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData(true);
    }
  }

  processOnePackage(pkg: Package) {
    if ( pkg.orderItemToPackages && pkg.orderItemToPackages.length > 0 ) {
      let numberOfNotShippedPackages = 0;
      for ( const p of pkg.printNodeOrder.packages) {
        if ( p.shippingStatus === 'NONE') {
          numberOfNotShippedPackages++;
        }
      }
      this.numberOfNotShippedPackagesInPrintNodeOrder['' + pkg.printNodeOrder_ID] = numberOfNotShippedPackages;
      for ( const orderItemToPackage of pkg.orderItemToPackages ) {
        if ( orderItemToPackage.orderItem && orderItemToPackage.orderItem.printNodeOrderItems && orderItemToPackage.orderItem.printNodeOrderItems.length > 0 ) {
          for ( const printNodeOrderItem of orderItemToPackage.orderItem.printNodeOrderItems ) {
            if ( !this.packagesByPrintNodeOrderId['' + printNodeOrderItem.printnodeorder_ID] ) {
              this.packagesByPrintNodeOrderId['' + printNodeOrderItem.printnodeorder_ID] = [];
              this.packagesByPrintNodeOrderId['' + printNodeOrderItem.printnodeorder_ID].push(pkg);
            } else {
              let found = false;
              for (const pkgInPrintNodeOrder of this.packagesByPrintNodeOrderId['' + printNodeOrderItem.printnodeorder_ID]) {
                if (pkg.package_ID === pkgInPrintNodeOrder.package_ID) {
                  found = true;
                }
              }
              if (!found) {
                this.packagesByPrintNodeOrderId['' + printNodeOrderItem.printnodeorder_ID].push(pkg);
              }
            }

            if ( printNodeOrderItem.manufacturingset_id !== 0 ) {
              if ( printNodeOrderItem.manufacturingSet ) {
                // if ( this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id ]) {
                this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id] = printNodeOrderItem.manufacturingSet;
                this.manufacturingSetByPrintNodeOrderItemId['' + printNodeOrderItem.ID] = this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id];
                if (!this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID]) {
                  this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID] = [];
                  this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id]);
                } else {
                  let found = false;
                  for (const manufacturingSet of this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID]) {
                    if (manufacturingSet.ID === printNodeOrderItem.manufacturingset_id) {
                      found = true;
                    }
                  }
                  if (!found) {
                    this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id]);
                  }
                }
              }
              if ( this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id ] ) {
                if ( !this.manufacturingSetByPackageId[ '' + pkg.package_ID ] ) {
                  this.manufacturingSetByPackageId[ '' + pkg.package_ID ] = [];
                  this.manufacturingSetByPackageId[ '' + pkg.package_ID ].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id ]);
                } else {
                  let found = false;
                  for ( const manufacturingSet of this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
                    if ( manufacturingSet.ID === printNodeOrderItem.manufacturingset_id) {
                      found = true;
                    }
                  }
                  if ( !found ) {
                    this.manufacturingSetByPackageId[ '' + pkg.package_ID ].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id ]);
                  }
                }
              }
            }
          }
        }
      }
    }
    if ( this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
      console.log(`${pkg.package_ID}`);
      for ( const manufacturingSet of this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
        if (manufacturingSet.status !== 'COMPLETED') {
          this.manufacturingSetByPackageIdNotCompleted['' + pkg.package_ID] = true;
        }
      }
    }

    // This could be made more efficient -- rather than running all of them for each package
    for ( const key of Object.keys(this.manufacturingSetById)) {
      const manufacturingSet: ManufacturingSet = this.manufacturingSetById[key];
      const nextManufacturingTasks = manufacturingSet.getNextManufacturingTasksToExecuteFromRoots();
      // for ( const next of nextManufacturingTasks ) {
      //   if ( this.nextManufacturingTaskColors[next.useType + '_' + next.status] ) {
      //     this.manufacturingSetColor['' + manufacturingSet.ID] = this.nextManufacturingTaskColors[next.useType + '_' + next.status];
      //   }
      // }
    }
  }
  getData(reset: boolean = false): void {
    this.startupService.touchLastUserActivity();
    this.manufacturingSetByPrintNodeOrderId = {};
    this.manufacturingSetByPrintNodeOrderItemId = {};
    this.manufacturingSetByPackageIdNotCompleted = {};
    this.packageTypes = [];
    this.packageTypeService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( packageType => {
      if ( this.packageTypes.findIndex(item => item.getId() === packageType.getId()) === -1) {
        this.packageTypes.push(packageType);
      }
      this.packageTypeById['' + packageType.ID] = packageType;
    });
    // this.packageTypeService.getItems()
    //   .subscribe(packageTypes => {
    //     this.packageTypes = packageTypes;
    //     for ( const packageType of packageTypes ) {
    //       this.packageTypeById['' + packageType.ID] = packageType;
    //     }
    //   });

    // const packageFilter = {
    //   eq: {
    //      ' ( package.shippingStatus != "NE" AND printNodeOrder.printnode_ID != 0 AND printNodeOrder.shippingStatus = "NONE" AND date_add(printNodeOrder.created, INTERVAL 30 DAY) > now() ) ': 1
    //   }
    // };
    // ' ( ( package.shippingStatus = "NONE" AND printNodeOrder.shippingStatus = "NONE" AND printNodeOrder.printNode_ID != 0 ) OR date_add(date(substring(manufacturingTaskBucketPeriod.period, 2, 10)), INTERVAL 4 DAY) > now() ) ': 1
  // ,
  //   gt: {
  //     'package.package_ID': 514914,
  //       'printNodeOrderItems.manufacturingset_id': 369
  //   }
    // 'package.shippingStatus': 'NONE',
    // ' date_add(date(substring(manufacturingTaskBucketPeriod.period, 2, 10)), INTERVAL -7 DAY) < now() ': 1
//    this.noData = false;
    this.packages = [];
    this.printNodeOrders = [];
    this.packageService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( pkg => {
      this.addUpdate(pkg);
    });
    this.packageService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( pkg => {
      this.addUpdate(pkg);
    });

    this.packageService.objects.forEach( o => {
      this.addUpdate(o);
    });

    this.printNodeOrderSevice.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdatePrintNodeOrder(o);
    });
    this.printNodeOrderSevice.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdatePrintNodeOrder(o);
    });

    this.printNodeOrderSevice.objects.forEach( o => {
      this.addUpdatePrintNodeOrder(o);
    });
    // this.packageService.getFilteredItems(packageFilter, reset)
    //   .subscribe(packages => {
    //     if ( !packages || packages.length === 0) {
    //       this.noData = true;
    //     }
    //     this.packages = packages;
    //     if ( this.packages && this.packages.length > 0 ) {
    //       this.mapData();
    //     }
    //     this.sort();
    //     // this.selectedFilterValue = '';
    //     // this.selectedFilter = 'None';
    //     // this.filterOn = false;
    //     this.filter(this.selectedFilterValue, this.selectedFilter);
    //   });
  }

  addUpdatePrintNodeOrder(printNodeOrder: PrintNodeOrder) {
    if ( printNodeOrder && printNodeOrder.packages) {
      for ( const pkg of printNodeOrder.packages ) {
        this.addUpdate(pkg);
      }
    }
  }
  addUpdate(pkg: Package) {
    try {
      if ( this.packages.findIndex(item => item.getId() === pkg.getId()) === -1) {
        this.packages.push(pkg);
        if ( pkg.printNodeOrder && this.printNodeOrders.findIndex(item => item.getId() === pkg.printNodeOrder.getId()) === -1) {
          this.printNodeOrders.push(pkg.printNodeOrder);
        }
      }
      this.processOnePackage(pkg);
      this.sort();
      this.filter(this.selectedFilterValue, this.selectedFilter);
    } catch (e) {
      console.log(`Error in FacilityPackagesComponent addUpdate ${pkg.package_ID}`);
    }
  }
  // mapData() {
  //   this.manufacturingSetByPrintNodeOrderId = {};
  //   this.manufacturingSetByPrintNodeOrderItemId = {};
  //   this.manufacturingSetByPackageIdNotCompleted = {};
  //   for ( const pkg of this.packages ) {
  //     for ( const orderItemToPackage of pkg.orderItemToPackages ) {
  //       for ( const printNodeOrderItem of orderItemToPackage.orderItem.printNodeOrderItems ) {
  //         if ( !this.packagesByPrintNodeOrderId['' + printNodeOrderItem.printnodeorder_ID] ) {
  //           this.packagesByPrintNodeOrderId['' + printNodeOrderItem.printnodeorder_ID] = [];
  //           this.packagesByPrintNodeOrderId['' + printNodeOrderItem.printnodeorder_ID].push(pkg);
  //         } else {
  //           let found = false;
  //           for (const pkgInPrintNodeOrder of this.packagesByPrintNodeOrderId['' + printNodeOrderItem.printnodeorder_ID]) {
  //             if (pkg.package_ID === pkgInPrintNodeOrder.package_ID) {
  //               found = true;
  //             }
  //           }
  //           if (!found) {
  //             this.packagesByPrintNodeOrderId['' + printNodeOrderItem.printnodeorder_ID].push(pkg);
  //           }
  //         }
  //
  //         if ( printNodeOrderItem.manufacturingset_id !== 0 ) {
  //           if ( printNodeOrderItem.manufacturingSet ) {
  //           // if ( this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id ]) {
  //             this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id] = printNodeOrderItem.manufacturingSet;
  //             this.manufacturingSetByPrintNodeOrderItemId['' + printNodeOrderItem.ID] = this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id];
  //             if (!this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID]) {
  //               this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID] = [];
  //               this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id]);
  //             } else {
  //               let found = false;
  //               for (const manufacturingSet of this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID]) {
  //                 if (manufacturingSet.ID === printNodeOrderItem.manufacturingset_id) {
  //                   found = true;
  //                 }
  //               }
  //               if (!found) {
  //                 this.manufacturingSetByPrintNodeOrderId['' + printNodeOrderItem.printNodeOrder.ID].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id]);
  //               }
  //             }
  //           }
  //           if ( !this.manufacturingSetByPackageId[ '' + pkg.package_ID ] ) {
  //             this.manufacturingSetByPackageId[ '' + pkg.package_ID ] = [];
  //             this.manufacturingSetByPackageId[ '' + pkg.package_ID ].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id ]);
  //           } else {
  //             let found = false;
  //             for ( const manufacturingSet of this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
  //               if ( manufacturingSet.ID === printNodeOrderItem.manufacturingset_id) {
  //                 found = true;
  //               }
  //             }
  //             if ( !found ) {
  //               this.manufacturingSetByPackageId[ '' + pkg.package_ID ].push(this.manufacturingSetById['' + printNodeOrderItem.manufacturingset_id ]);
  //             }
  //           }
  //         }
  //       }
  //     }
  //     if ( this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
  //       console.log(`${pkg.package_ID}`);
  //       for ( const manufacturingSet of this.manufacturingSetByPackageId[ '' + pkg.package_ID ]) {
  //         if (manufacturingSet.status !== 'COMPLETED') {
  //           this.manufacturingSetByPackageIdNotCompleted['' + pkg.package_ID] = true;
  //         }
  //       }
  //     }
  //   }
  //
  //   this.manufacturingSetColor = {};
  //   for ( const key of Object.keys(this.manufacturingSetById)) {
  //     const manufacturingSet: ManufacturingSet = this.manufacturingSetById[key];
  //     this.manufacturingSetColor['' + manufacturingSet.ID] = 'white';
  //     const nextManufacturingTasks = manufacturingSet.getNextManufacturingTasksToExecuteFromRoots();
  //     for ( const next of nextManufacturingTasks ) {
  //       if ( this.nextManufacturingTaskColors[next.useType + '_' + next.status] ) {
  //         this.manufacturingSetColor['' + manufacturingSet.ID] = this.nextManufacturingTaskColors[next.useType + '_' + next.status];
  //       }
  //     }
  //   }
  // }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  // print (manufacturingSet: ManufacturingSet, useType: string) {
  //   const manufacturingTask = this.getManufacturingTask(manufacturingSet, useType);
  //   manufacturingTask.status = 'SENT';
  //   this.manufacturingTaskService.print(manufacturingTask).subscribe( changedManufacturingTask => {
  //     manufacturingTask.status = changedManufacturingTask.status;
  //     console.log('Prepare Response for ManufacturingSet : ${manufacturingTask.ID} ${manufacturingTask.inputStatus}`);
  //   });
  // }
  //
  // reprint (manufacturingSet: ManufacturingSet, useType: string) {
  //   const manufacturingTask = this.getManufacturingTask(manufacturingSet, useType);
  //   manufacturingTask.status = 'STARTED';
  //   this.manufacturingTaskService.print(manufacturingTask).subscribe( changedManufacturingTask => {
  //     manufacturingTask.status = changedManufacturingTask.status;
  //     console.log(`Prepare Response for ManufacturingSet : ${manufacturingTask.ID} ${manufacturingTask.inputStatus}`);
  //   });
  // }


  refresh () {
    this.getData(true);
  }

  updatePackagesToHide() {
    for ( const pkg of this.packages ) {
      this.packagesToHide['' + pkg.package_ID] = true;
      if ( pkg.shippingStatus === 'NONE' || this.showOnlyNotShipped ) {
        this.packagesToHide['' + pkg.package_ID] = true;
      }
      for ( const orderItemToPackage of pkg.orderItemToPackages ) {
        // for ( const printNodeOrderItem of orderItemToPackage.orderItem.printNodeOrderItems) {
        //   if ( printNodeOrderItem.manufacturingset_ID === this.focusedSet ) {
        //
        //   }
        // }
      }
    }
  }

  // trackingNumberURL(pkg: Package): string {
  //   if ( !pkg.trackingnumber ) {
  //     return '';
  //   }
  //   if (pkg.trackingnumber.startsWith('94')) {
  //     return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + pkg.trackingnumber;
  //   } else if (pkg.trackingnumber.startsWith('1Z')) {
  //     return 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + pkg.trackingnumber;
  //   } else {
  //     return 'https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=' + pkg.trackingnumber;
  //   }
  //
  // }
  //
  // getBorderColor (pkg: Package): string {
  //   // If Not Shipped
  //   if ( pkg.shippingStatus === 'NONE' && pkg.printNodeOrder.shippingStatus === 'NONE' ) {
  //     switch (pkg.shippingLabelStatus) {
  //       case 'READY': {
  //         if ( pkg.pickSheet ) {
  //           return 'lightblue'; // NOT SHIPPED AND SHIPPING LABEL IS READY
  //         } else {
  //           return 'lightgreen'; // NOT SHIPPED AND SHIPPING LABEL IS READY -- PACKING SHEET NOT PRINTED
  //         }
  //         break;
  //       }
  //       case 'ERROR': {
  //         return 'pink'; // NOT SHIPPED AND SHIPPING LABEL IS ERROR
  //         break;
  //       }
  //       case 'INITIAL': {
  //         return 'orange'; // NOT SHIPPED AND SHIPPING LABEL IS NOT CREATED YET
  //         break;
  //       }
  //     }
  //   } else {
  //     return 'grey'; // Shipped
  //   }
  // }
  //
  // getBackgroundColor (pkg: Package): string {
  //   if ( pkg.printNodeOrder.printnode_ID === 0 ) {
  //     return 'grey';
  //   }
  //   if ( pkg.shippingStatus === 'SHIPPED' || pkg.printNodeOrder.shippingStatus === 'SHIPPED' ) {
  //     return 'lightgrey';
  //   }
  //   if ( this.manufacturingSetByPackageId['' + pkg.package_ID] ) {
  //     let period: string = '';
  //     for ( const manufacturingSet of this.manufacturingSetByPackageId['' + pkg.package_ID] ) {
  //       if ( manufacturingSet.manufacturingTaskBucketPeriod.period < period || !period ) {
  //         period = manufacturingSet.manufacturingTaskBucketPeriod.period;
  //       }
  //     }
  //     if ( period ) {
  //       if ( period === 'D2022-07-11') {
  //         console.log('xxx');
  //       }
  //       if ( period === 'D2022-07-10') {
  //         console.log('xxx');
  //       }
  //       if ( period === 'D2022-07-09') {
  //         console.log('xxx');
  //       }
  //       if ( period === 'D2022-07-08') {
  //         console.log('xxx');
  //       }
  //       if ( period === 'D2022-07-07') {
  //         console.log('xxx');
  //       }
  //       if ( period === 'D2022-07-06') {
  //         console.log('xxx');
  //       }
  //       if ( period === 'D2022-07-05') {
  //         console.log('xxx');
  //       }
  //       const periodDate = new Date(period.substring(1) + ' 15:01:01');
  //       let dateDiff = Math.round(( Number( periodDate ) - Number( new Date() ) ) / ( 24 * 60 * 60 * 1000 ) );
  //       if ( this.getWeekOfYear(periodDate) > this.getWeekOfYear(new Date())) {
  //         dateDiff -= 2;
  //       }
  //       if ( this.getWeekOfYear(periodDate) < this.getWeekOfYear(new Date())) {
  //         dateDiff += 2;
  //       }
  //       if ( dateDiff > 6 ) {
  //         return 'lightgreen';
  //       }
  //       if ( dateDiff < -3) {
  //         return 'red';
  //       }
  //       switch ( dateDiff ) {
  //         case -4: {
  //           return 'pink';
  //         }
  //         case -3: {
  //           return 'lightpink';
  //         }
  //         case -2: {
  //           return 'orange';
  //         }
  //         case -1: {
  //           return 'lightyellow';
  //         }
  //         case 0: {
  //           return 'white';
  //         }
  //         case 1: {
  //           return 'lightgreen';
  //         }
  //         case 2: {
  //           return 'lightgreen';
  //         }
  //         case 3: {
  //           return 'lightgreen';
  //         }
  //         case 4: {
  //           return 'lightgreen';
  //         }
  //       }
  //     }
  //     return 'red';
  //   } else {
  //     return 'blue';
  //   }
  // }

  // print (pkg: Package) {
  //   // pkg.status = 'STARTED';
  //   pkg.shippingLabelStatus = 'PRINTING';
  //   if ( this.manufacturingSetByPackageId['' + pkg.package_ID] && this.manufacturingSetByPackageId['' + pkg.package_ID][0] && this.manufacturingSetByPackageId['' + pkg.package_ID][0].ID) {
  //     this.packageService.print(pkg, Number(this.manufacturingSetByPackageId['' + pkg.package_ID][0].ID)).subscribe( changedPackage => {
  //       // pkg.status = changedPackage.status;
  //       pkg.shippingLabelStatus = changedPackage.shippingLabelStatus;
  //     });
  //   }
  // }
  //
  // reprint (printNodeOrderItem: PrintNodeOrderItem, manufacturingSet: ManufacturingSet, useType: string) {
  //   for ( const manufacturingTask of manufacturingSet.manufacturingTasks ) {
  //     if ( manufacturingTask.useType === useType ) {
  //       this.manufacturingTaskService.reprint(manufacturingTask, printNodeOrderItem).subscribe( changedManufacturingTask => {
  //         console.log(`Response for ManufacturingSet and Book : ${manufacturingTask.ID} ${printNodeOrderItem.book_ID}`);
  //       });
  //     }
  //   }
  // }
  //
  // getWeekOfYear(date: Date): number {
  //   const MILLISECONDS_IN_WEEK = 604800000;
  //   const firstDayOfWeek = 0; // monday as the first day (0 = sunday)
  //   const startOfYear = new Date(date.getFullYear(), 0, 1);
  //   startOfYear.setDate(
  //     startOfYear.getDate() + (firstDayOfWeek - (startOfYear.getDay() % 7))
  //   );
  //   const dayWeek = Math.floor(( Number( date ) - Number(startOfYear) ) / MILLISECONDS_IN_WEEK) + 1;
  //   return  dayWeek;
  // }

  // reportBookProblem (printNodeOrderItem: PrintNodeOrderItem) {
  //
  // }
  //
  // reportShippingProblem (pkg: Package) {
  //
  // }

  filter(value: string, filterType: string) {
    switch ( filterType ) {
      case 'Set': {
        this.filterOn = true;
        for ( const pkg of this.packages ) {
          this.packagesToHide['' + pkg.package_ID] = true;
          for (const orderItem of pkg.orderItemToPackages) {
            for (const printNodeOrderItem of orderItem.orderItem.printNodeOrderItems) {
              if (('' + printNodeOrderItem.manufacturingset_id).startsWith(value)) {
                this.packagesToHide['' + pkg.package_ID] = false;
              }
            }
          }
        }
        break;
      }
    case 'BookTitle': {
      this.filterOn = true;
      value = value.toLocaleLowerCase();
      for ( const pkg of this.packages ) {
        this.packagesToHide['' + pkg.package_ID] = true;
        for (const orderItem of pkg.orderItemToPackages) {
          for (const printNodeOrderItem of orderItem.orderItem.printNodeOrderItems) {
            if (('' + printNodeOrderItem.book.title).toLocaleLowerCase().indexOf(value) !== -1) {
              this.packagesToHide['' + pkg.package_ID] = false;
            }
          }
        }
      }
      break;
    }
      case 'Book': {
        this.filterOn = true;
        for ( const pkg of this.packages ) {
          this.packagesToHide['' + pkg.package_ID] = true;
          for (const orderItem of pkg.orderItemToPackages) {
            for (const printNodeOrderItem of orderItem.orderItem.printNodeOrderItems) {
              {
                if (('' + printNodeOrderItem.book_ID).startsWith(value)) {
                  this.packagesToHide['' + pkg.package_ID] = false;
                }
              }
            }
          }
        }
        break;
      }
      case 'Order': {
        this.filterOn = true;
        for ( const pkg of this.packages ) {
          this.packagesToHide['' + pkg.package_ID] = true;
          if ( ('' + pkg.printNodeOrder_ID).startsWith(value) ) {
            this.packagesToHide['' + pkg.package_ID] = false;
          }
        }
        break;
      }
      case 'Package': {
        this.filterOn = true;
        for ( const pkg of this.packages ) {
          this.packagesToHide['' + pkg.package_ID] = true;
          if ( ('' + pkg.package_ID).startsWith(value) ) {
            this.packagesToHide['' + pkg.package_ID] = false;
          }
        }
        break;
      }
      case 'None': {
        this.filterOn = false;
        for ( const pkg of this.packages ) {
          this.packagesToHide['' + pkg.package_ID] = false;
        }
        break;
      }
    }
    // manufacturingSetByPackageIdNotCompleted['' + package.package_ID]
    //
    if ( this.showOnlyTrimmed ) {
      for ( const pkg of this.packages ) {
        if ( !(this.packagesToHide['' + pkg.package_ID]) ) {
          const manufacturingSets = this.manufacturingSetByPackageId['' + pkg.package_ID];
          if ( manufacturingSets ) {
            for ( const manufacturingSet of manufacturingSets ) {
              let dontHide = false;
              const nextManufacturingTasks = manufacturingSet.getNextManufacturingTasksToExecuteFromRoots();
              if ( nextManufacturingTasks && nextManufacturingTasks.length === 1 && nextManufacturingTasks[0].useType === 'Pack' ) {
                dontHide = true;
              }
              if ( !dontHide ) {
                this.packagesToHide['' + pkg.package_ID] = true;
              }
            }
          } else {
            this.packagesToHide['' + pkg.package_ID] = true;
          }
        }
      }
    }
    if ( this.showOnlyNotShipped ) {
      for ( const pkg of this.packages ) {
        if ( !(this.packagesToHide['' + pkg.package_ID]) ) {
          if ( pkg.shippingStatus === 'SHIPPED' || (pkg.printNodeOrder && pkg.printNodeOrder.shippingStatus === 'SHIPPED') || (pkg.printNodeOrder && pkg.printNodeOrder.printnode_ID === 0) ) {
            this.packagesToHide['' + pkg.package_ID] = true;
          }
        }
      }
    }
  }

  sort() {
    this.packages.sort( ( a, b): number => {
      let minA;
      let maxA;
      for (const aItem of a.orderItemToPackages) {
        if ( aItem.orderItem && aItem.orderItem.printNodeOrderItems && aItem.orderItem.printNodeOrderItems.length > 0) {
          for ( const aaItem of aItem.orderItem.printNodeOrderItems) {
            const aPeriod = aaItem.manufacturingSet['manufacturingTaskBucketPeriod'].period;
            if ( !minA || aPeriod < minA ) {
              minA = aPeriod;
            }
            if ( !maxA || aPeriod > maxA ) {
              maxA = aPeriod;
            }
          }
        }
      }
      let minB;
      let maxB;
      for (const bItem of b.orderItemToPackages) {
        if ( bItem.orderItem && bItem.orderItem.printNodeOrderItems && bItem.orderItem.printNodeOrderItems.length > 0) {
          for ( const bbItem of bItem.orderItem.printNodeOrderItems) {
            const bPeriod = bbItem.manufacturingSet['manufacturingTaskBucketPeriod'].period;
            if ( !minB || bPeriod < minB ) {
              minB = bPeriod;
            }
            if ( !maxB || bPeriod > maxB ) {
              maxB = bPeriod;
            }
          }
        }
      }
      if ( !minA && !minB ) {
        return 0;
      }
      if ( minA && minB ) {
        return minA.localeCompare(minB);
      }
      if ( minA ) {
        return -1;
      } else {
        return 1;
      }
    });
    this.printNodeOrders.sort( ( a, b): number => {
      let aPeriod;
      let bPeriod;
      if ( a.printNodeOrderItems && a.printNodeOrderItems.length > 0 && a.printNodeOrderItems[0].manufacturingSet.manufacturingTaskBucketPeriod && a.printNodeOrderItems[0].manufacturingSet.manufacturingTaskBucketPeriod.period) {
        aPeriod = a.printNodeOrderItems[0].manufacturingSet.manufacturingTaskBucketPeriod.period;
      }
      if ( b.printNodeOrderItems && b.printNodeOrderItems.length > 0 && b.printNodeOrderItems[0].manufacturingSet.manufacturingTaskBucketPeriod && b.printNodeOrderItems[0].manufacturingSet.manufacturingTaskBucketPeriod.period) {
        bPeriod = b.printNodeOrderItems[0].manufacturingSet.manufacturingTaskBucketPeriod.period;
      }
      if ( !aPeriod && !bPeriod ) {
        return 0;
      }
      if ( aPeriod && bPeriod ) {
        return aPeriod.localeCompare(bPeriod);
      }
      if ( aPeriod ) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  filterChanged () {
    this.filter(this.selectedFilterValue, this.selectedFilter);
  }

  // manuallyMarkPackageShipped (pkg: Package, shippingStatus: string) {
  //   pkg.shippingStatus = shippingStatus;
  //   this.packageService.updateItem(pkg).subscribe( changedPackage => {
  //     console.log(`Shipping Status Changed`);
  //   });
  // }
  setFilter () {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '640px';
    dialogConfig.disableClose = false;
    dialogConfig.data = { filterOn: this.filterOn, selectedFilter: this.selectedFilter, selectedFilterValue: this.selectedFilterValue};
    const dialogRef = this.dialog.open(FacilityPackagesSetFilterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed. result[' + result + ']');
      if (result) {
        this.filterOn = result['filterOn'];
        this.selectedFilter = result['selectedFilter'];
        this.selectedFilterValue = result['selectedFilterValue'];
        this.filter(this.selectedFilterValue, this.selectedFilter);
      }
    });
  }

  getFirstNotShippedPackage(packages: Package[]): Package {
    for ( const pkg of packages ) {
      if ( pkg.shippingStatus !== 'SHIPPED') {
        return pkg;
      }
    }
    return packages[0];
  }
}
