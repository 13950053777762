<div>
  <div class="add-title">
    <ng-container [ngSwitch]="actionType">
      <h2 *ngSwitchCase="'RecordCurrentOnHandInventoryLevel'" class="primary" i18n="@@Record Current On Hand Inventory Level">Record Current On Hand Inventory Level</h2>
      <h2 *ngSwitchCase="'ReceiveExternalReplenishment'" class="primary" i18n="@@Receive Manual External Replenishment">Receive Manual External Replenishment</h2>
      <h2 *ngSwitchCase="'ReceiveReturn'" class="primary" i18n="@@Return">Receive Return</h2>
      <h2 *ngSwitchCase="'CorrectInventoryLevel'" class="primary" i18n="@@Correct Inventory Level">Correct Inventory Level</h2>
    </ng-container>
  </div>
  <div>
    <form [formGroup]="formGroup" (change)="formChanged()">
      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <mat-select  placeholder="Action Type" i18n-placeholder="@@Action Type" formControlName="actionType" required>-->
<!--                <mat-option value="RecordCurrentOnHandInventoryLevel" i18n='@@Record Current On Hand Inventory Level' selected='selected'>Record Current On Hand Inventory Level</mat-option>-->
<!--                <mat-option value="ReceiveExternalReplenishment" i18n='@@Receive Manual External Replenishment'>Receive Manual External Replenishment</mat-option>-->
<!--                <mat-option value="Return" i18n='@@Return'>Return</mat-option>-->
<!--                <mat-option value="Correction" i18n='@@Correction'>Correction</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Date" placeholder="Date" id="date" required type="date" formControlName="date">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <input matInput i18n-placeholder="@@Quantity" placeholder="Quantity" id="quantity" required type="number" min="0"  max="1500" formControlName="quantity">
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div class="btn-sec">
        <ng-container [ngSwitch]="actionType">
          <button *ngSwitchCase="'RecordCurrentOnHandInventoryLevel'" mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="formGroup.invalid" (click)="onSave()" i18n="@@Record Current On Hand Inventory Level">Record Current On Hand Inventory Level</button>
          <button *ngSwitchCase="'ReceiveExternalReplenishment'" mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="formGroup.invalid" (click)="onSave()" i18n="@@Receive Manual External Replenishment">Receive Manual External Replenishment</button>
          <button *ngSwitchCase="'ReceiveReturn'" mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="formGroup.invalid" (click)="onSave()" i18n="@@Receive Return">Receive Return</button>
          <button *ngSwitchCase="'CorrectInventoryLevel'" mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="formGroup.invalid" (click)="onSave()" i18n="@@Correct Inventory Level">Correct Inventory Level</button>
        </ng-container>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
