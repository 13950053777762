import {TableFilter} from "./TableFilter";

export class NumericRangeTableFilter extends TableFilter {
  public startValue: number = 0;
  public endValue: number = 0;

  constructor (obj: any) {
    super(obj);
    if ( obj.startValue ) {
      this.startValue = Number(obj.startValue);
    }
    if ( obj.endValue ) {
      this.endValue = Number(obj.endValue);
    }
  }

  public match(data: any, filter: string): boolean {
    const value = this.getNumericValue(data);
    if ( this.startValue <= value && this.endValue === 0 ) {
      return true;
    }
    if ( this.startValue <= value && this.endValue >= value ) {
      return true;
    }
    return false;
  }
}
