import {Inject, Injectable} from "@angular/core";
import {CognitoCallback, CognitoUtil} from "./cognito.service";
import {AuthenticationDetails, CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js";
import {RegistrationUser} from "../component-account/register/registration.component";
import {NewPasswordUser} from "../component-account/newpassword/newpassword.component";
import * as AWS from "aws-sdk/global";
import {LanguagePipe} from "../pipe/language.pipe";
import {environment as env} from "../../environments/environment";

@Injectable()
export class UserRegistrationService {
    environment = env;

    constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil) {

    }

    register(user: RegistrationUser, callback: CognitoCallback): void {
        console.log("UserRegistrationService: user is " + user);

        const attributeList = [];

        // const dataUserName = {
        //   Name: 'username',
        //   Value: user.email
        // };
        const dataEmail = {
          Name: 'email',
          Value: user.email
        };
        const dataName = {
          Name: 'name',
          Value: user.name
        };

        const dataPhone = {
          Name: 'phone_number',
          Value: user.phone_number
        };

        let serviceProviderId = "1"; // LanguagePipe.get('ServiceProviderId', 'compressSpaces');
        if ( !serviceProviderId || serviceProviderId.length === 0 || serviceProviderId === 'ServiceProviderId' ) {
          serviceProviderId = "1";
        }
        const dataServiceProvider = {
          Name: 'custom:serviceProviderId',
          Value: String(this.environment.serviceProviderId)
        };

        // attributeList.push(new CognitoUserAttribute(dataUserName));
        attributeList.push(new CognitoUserAttribute(dataEmail));
        attributeList.push(new CognitoUserAttribute(dataName));
        attributeList.push(new CognitoUserAttribute(dataPhone));
        attributeList.push(new CognitoUserAttribute(dataServiceProvider));


        // user.email = btoa(user.email);
        this.cognitoUtil.getUserPool().signUp(user.email, user.password, attributeList, null, function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                console.log("UserRegistrationService: registered user is " + result);
                callback.cognitoCallback(null, result);
            }
        });

    }

    confirmRegistration(username: string, confirmationCode: string, callback: CognitoCallback): void {

        const userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmRegistration(confirmationCode, true, function (err, result) {
            if (err) {
                if ( err.message === 'Invalid version. Version should be 1') {
                    callback.cognitoCallback(null, 'SUCCESS');
                } else {
                    callback.cognitoCallback(err.message, null);
                }
            } else {
                callback.cognitoCallback(null, result);
            }
        });
    }

    resendCode(username: string, callback: CognitoCallback): void {
        const userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
            }
        });
    }

    newPassword(newPasswordUser: NewPasswordUser, callback: CognitoCallback): void {
        console.log(newPasswordUser);
        // Get these details and call
        // cognitoUser.compconsteNewPasswordChallenge(newPassword, userAttributes, this);
        const authenticationData = {
            Username: newPasswordUser.username,
            Password: newPasswordUser.existingPassword,
            Name: '',
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);

        const userData = {
            Username: newPasswordUser.username,
            Pool: this.cognitoUtil.getUserPool()
        };

        console.log("UserLoginService: Params set...Authenticating the user");
        const cognitoUser = new CognitoUser(userData);
        console.log("UserLoginService: config is " + AWS.config);
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.

                // the api doesn't accept this field back
                delete userAttributes.email_verified;
                cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
                    onSuccess: function (result) {
                        callback.cognitoCallback(null, userAttributes);
                    },
                    onFailure: function (err) {
                        callback.cognitoCallback(err, null);
                    }
                });
            },
            onSuccess: function (result) {
                callback.cognitoCallback(null, result);
            },
            onFailure: function (err) {
                callback.cognitoCallback(err, null);
            }
        });
    }
}
