<div class="themeAndTimeSizeSelection">
  <div class="labeledInput">
    <label class="labeledInput">
      <span class="labeledInput" i18n="@@Interior Theme">Interior Theme</span>
    </label>
    <select class="labeledInput" *ngIf="startupService.project && themeService.interiorThemes && themeService.interiorThemes.length > 0" [(ngModel)]="startupService.project.interior.interiorTheme.name" (change)="setInteriorThemeId($event)">
      <option [style.fontWeight]="interiorTheme.name === startupService.project.interior.interiorTheme.name ? 'bold': 'normal'" [selected]="interiorTheme.name === startupService.project.interior.interiorTheme.name" *ngFor="let interiorTheme of themeService.interiorThemes" [value]="interiorTheme.name">{{interiorTheme.name | languageString }}</option>
    </select>
  </div>
  <div class="labeledInput">
    <label class="labeledInput">
      <span class="labeledInput" i18n="@@Trim Size">Trim Size</span>
    </label>
    <select class="labeledInput" *ngIf="startupService.project" type="text" [ngModel]="startupService.project.trimSize" (change)="setTrimSize($event)">
      <option [selected]="'' + startupService.project.width + 'X' + startupService.project.height  === trimSize" *ngFor="let trimSize of trimSizes" [ngValue]="trimSize.name">{{trimSize.name | languageString }}</option>
    </select>
  </div>
  <span class="fa fa-audio-description" (click)="startupService.showAudioCertantity = !startupService.showAudioCertantity" i18n-title="@@Toggle showing audio certantity" title="Toggle showing audio certantity"></span>
  <span class="fa fa-text-width" (click)="startupService.useBookWidthInEditor = !startupService.useBookWidthInEditor" i18n-title="@@Toggle use book width for editor width" title="Toggle use book width for editor width"></span>

</div>

<div class="interiorTools right">
  <div class="interiorToolsContainerToolbar">
    <!--<button class="btn btn-sm cpodbtn-small btn-info" (click)="startupService.project.cover.copyCoverThemeColorPalette()" type="button"><ng-container i18n="@@Use Theme Color Palette">Use Theme Color Palette</ng-container></button>-->
    <!--<button class="btn btn-sm cpodbtn-small btn-info" (click)="startupService.project.cover.copyCoverThemeFontSettings()" type="button"><ng-container i18n="@@Use Theme Fonts">Use Theme Fonts</ng-container></button>-->
  </div>
  <div class="interiorToolsContainerBody">
    <app-interior-theme-editor [selectedElements]="startupService.selectedElements" [interiorTheme]="interior.interiorTheme" [document]="document" ></app-interior-theme-editor>
    <!--<span [ngClass]="showChapter ? 'inverse': ''"  class="accordianControl fa fa-paint-brush" (click)="showChapter = !showChapter; showCopyright = false; showTOC = false" i18n="@@Show/Hide Chapter" title="Show/hide Chapter">Chapter</span>-->
    <!--<app-interior-theme-editor *ngIf="showChapter" [selectedElements]="startupService.selectedElements" [interiorTheme]="interior.interiorTheme" [document]="document"></app-interior-theme-editor>-->
    <!--<span [ngClass]="showCopyright ? 'inverse': ''" class="accordianControl fa fa-font" (click)="showChapter = false; showCopyright = !showCopyright; showTOC = false" i18n="@@Show/Hide Copyright" title="Show/Hide Copyright">Copyright</span>-->
    <!--<app-interior-theme-editor *ngIf="showCopyright" [selectedElements]="startupService.selectedElements" [interiorTheme]="interior.interiorTheme" [document]="document"></app-interior-theme-editor>-->
    <!--<span [ngClass]="showTOC ? 'inverse': ''"  class="accordianControl fa fa-font" (click)="showChapter = false; showCopyright = false; showTOC = !showTOC" i18n-title="@@Show/Hide Table of Contents" title="Show/Hide Table of Contents" i18n="@@TableofContents">Table of Contents</span>-->
    <!--<app-interior-theme-editor *ngIf="showTOC" [selectedElements]="startupService.selectedElements" [interiorTheme]="interior.interiorTheme" [document]="document"></app-interior-theme-editor>-->
  </div>
</div>
