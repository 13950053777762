import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {RoleChangeListener, StartupService} from "../../service-ui/startup.service";
import { MatSidenav } from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableFilterManager} from "../../table/TableFilterManager";
import {of} from 'rxjs';
import {EventHandler} from "../../table/EventHandler";
import {OrderDeliverToService} from "../../service-data/order-deliver-to.service";
import {OrderDeliverTo} from "../../../model/OrderDeliverTo";
import {Subject} from "rxjs";

@Component({
  selector: 'app-shipment-orders',
  templateUrl: './shipment-orders.component.html',
  styleUrls: ['./shipment-orders.component.css']
})

export class ShipmentOrdersComponent implements OnDestroy, OnInit, LoggedInCallback, AfterViewInit, EventHandler, RoleChangeListener {

  columns = [
     { property: 'ID',              columnDef: 'ID',              header: 'ID',                sort: true,  type: 'number',  filterType: 'NumericRange', style: 'flex: 0 0 10px !important;',  cell: (element: any) => `${element.ID}` },
     // { property: 'orderId',      columnDef: 'orderId',         header: 'order Id',          sort: true,  type: 'number',  filterType: 'NumericRange', style: '',  cell: (element: any) => `${element.order.ID}` },
     { property: 'customerOrderId', columnDef: 'customerOrderId', header: 'Customer Order Id', sort: true,  type: 'string',  filterType: 'String',       style: '',  cell: (element: any) => `${element.order.customerorderid}` },
     { property: 'created',         columnDef: 'created',         header: 'Created',           sort: true,  type: 'date',    filterType: 'NumericRange', style: '',  cell: (element: any) => `${element.order.created}`, dateFormat: 'yyyy-MM-dd' },
     { property: 'name',            columnDef: 'name',            header: 'Name',              sort: true,  type: 'string',  filterType: 'String',       style: '',  cell: (element: any) => `${element.title} ${element.firstname} ${element.lastname}`  },
     { property: 'company',         columnDef: 'company',         header: 'Company',           sort: true,  type: 'string',  filterType: 'String',       style: '',  cell: (element: any) => `${element.company}`  },
     { property: 'address1',        columnDef: 'address1',        header: 'Address1',          sort: true,  type: 'string',  filterType: 'String',       style: '',  cell: (element: any) => `${element.address1}` },
     { property: 'address2',        columnDef: 'address2',        header: 'Address2',          sort: true,  type: 'string',  filterType: 'String',       style: '',  cell: (element: any) => `${element.address2}` },
     { property: 'city',            columnDef: 'city',            header: 'City',              sort: true,  type: 'string',  filterType: 'String',       style: '',  cell: (element: any) => `${element.city}` },
     { property: 'state',           columnDef: 'state',           header: 'State',             sort: true,  type: 'string',  filterType: 'String',       style: '',  cell: (element: any) => `${element.state}` },
     { property: 'country',         columnDef: 'country',         header: 'Country',           sort: true,  type: 'string',  filterType: 'String',       style: '',  cell: (element: any) => `${element.country}` },
     { property: 'postalcode',      columnDef: 'postalcode',      header: 'Zip',               sort: true,  type: 'string',  filterType: 'String',       style: '',  cell: (element: any) => `${element.postalcode}` }
     // { property: 'weight',       columnDef: 'weight',          header: 'Weight',            sort: true,  type: 'number',  filterType: 'NumericRange', style: '',  cell: (element: any) => `${element.weight}` },
     // { property: 'status',       columnDef: 'status',          header: 'Status',            sort: true,  type: 'string',  filterType: 'DataEnum',     style: '',  cell: (element: any) => `${element.status}`, values: { USPS: 'USPS', UPS: 'UPS'} },
     // { property: 'carrier',      columnDef: 'carrier',         header: 'Carrier',           sort: true,  type: 'string',  filterType: 'DataEnum',     style: '',  cell: (element: any) => `${element.carrier}`, values: { USPS: 'USPS', UPS: 'UPS'} },
     // { property: 'servicelevel', columnDef: 'serviceLevel',    header: 'Service Level',     sort: true,  type: 'string',  filterType: 'DataEnum',     style: '',  cell: (element: any) => `${element.servicelevel}`, values: { } }
  ];

  eventHandler: EventHandler;

  public dataSource = new MatTableDataSource<OrderDeliverTo>();
  public renderedData: OrderDeliverTo[];
  tableFilterManager: TableFilterManager<OrderDeliverTo> = new TableFilterManager(this.dataSource);

  // @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  constructor(public router: Router, public startupService: StartupService, private orderDeliverToService: OrderDeliverToService, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.startupService.addRoleChangeListener(this);
    this.eventHandler = this;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  roleChanged(role: string, roleId: string): void {
    this.getData();
  }

  ngOnInit() {
    console.log("Checking if the orderDeliverTo is already authenticated");
    this.startupService.isAuthenticated(this);
    this.dataSource.connect().subscribe(d => {
      // if ( d && d.length > 0 ) {
      //   this.renderedData = d;
      // }
      this.renderedData = this.dataSource.data;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filter = undefined;
    const _this = this;
    this.dataSource.filterPredicate =
      (data: OrderDeliverTo, filter: string) => {
        return _this.tableFilterManager.tableFilter.match(data, filter);
     };
    // this.dataSource.paginator = this.paginator;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getData();
    }
  }

  // onSelect(orderDeliverToDeliverToItem: PrintNodeOrderDeliverToItem): void {
  //   this.selectedPrintNodeOrderDeliverToItem = orderDeliverToDeliverToItem;
  //   if ( this.selectedPrintNodeOrderDeliverToItem ) {
  //     this.selectedPrintNodeOrderDeliverTo = this.selectedPrintNodeOrderDeliverToItem.orderDeliverToDeliverTo;
  //   }
  // }

  getData(): void {
    // this.dataSource.data = [];
    this.orderDeliverToService.getOrderDeliverTos()
      .subscribe(orderDeliverTos => {
        this.dataSource.data = orderDeliverTos;
        this.dataSource.filter = '' + Math.random();
        // this.table.renderRows();
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  public invite = (id: string) => {
    console.log("Invite: " + id);
  }

  public redirectToDelete = (id: string) => {
    console.log("RedirectToDelete: " + id);

  }

  public event(action: string, data: any, event) {
    console.log("Event: " + action + " " + JSON.stringify(data) + " " + JSON.stringify(event));
  }

}
