import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { User } from '../../model/User';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {S3Service} from "../service-auth/s3.service";
import {NotificationsService} from "./notifications.service";
import {AlertService} from "../service-ui/alert.service";
import {LanguagePipe} from "../pipe/language.pipe";
import {DataService} from "./data-service";


@Injectable()
export class AdminService extends DataService {
  private users: User[] = [];
  private usersObservable = of(this.users);
  private usersLoadingState = 'INITIAL';

  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService, private s3Service: S3Service, private notificationsService: NotificationsService, private alertService: AlertService) {
    super(startupService);
  }

    reset (filter: string = '') {
    this.usersLoadingState = 'RESETTING';
    while (this.users.length > 0) {
      const user = this.users.pop();
    }
  }

  getUsers(): Observable<User[]> {
    if ( this.usersLoadingState === 'INITIAL') {
      this.usersLoadingState = 'STARTING';
      this.fetchUsers().subscribe();
    }
    return this.usersObservable;
  }


  fetchUsers(): Observable<User[]> {
    this.reset();
    this.usersLoadingState = 'FETCHING';
    this.listUsers().then( projs => {
      projs.map( user => {
        let i = 0;
        for (; i < this.users.length && this.users[i].name && this.users[i].name < user.name; i++) {
        }
        this.users.splice(i, 0, user);
        // this.users.push(user);
      });
      this.usersLoadingState = 'LOADED';
    });
    return this.usersObservable;
  }

  retreiveLocalUser(id: string): User {
    for (const user of this.users) {
      if (user.ID === id) {
        return user;
      }
    }
    return null;
  }

  refresh(id: string) {
    const localUser = this.retreiveLocalUser(id);
    this.loadUser(id).then(user => {
      if (localUser) {
        for (const key in user) {
          if (user[key] !== localUser[key]) {
            localUser[key] = user[key];
          }
        }
      } else {
        this.users.push(user);
      }
    });
  }

  public loadUser(userId: string): Promise<User> {
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }

    const params = {
      Bucket: environment.dataBucket,
      Key: new UserKey(userId).toString(),
    };
    return new Promise((resolve, reject) => {
      this.s3Service.getS3().getObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const json = data.Body.toString();
          const user = new User( JSON.parse(json) );
          resolve(user);
        }
      });
    });
  }

  public listUsers(): Promise<User[]> {
    this.usersLoadingState = 'LOADING';
    const userName = this.startupService.getUserId();
    if ( userName === 'no-current-user' ) {
      this.alertService.error(LanguagePipe.get('Your Session Has Timed Out.  Please Login Again.', 'compressSpaces'), true);
      this.startupService.logout();
    }
    // no-paginate ?
    const params = {
      Bucket: environment.dataBucket,
      Prefix: 'users/'
    };

    const userPromises: Promise<User>[] = [];

    return new Promise((resolve, reject) => {
      console.log("About to call S3.listObjects in listUsers()");
      try {
        this.s3Service.getS3().listObjectsV2(params, (err, data) => {
          console.log(`Finished calling S3.listObjects in listUsers()=>(err:${JSON.stringify(err)}\ndata:${JSON.stringify(data)})`);
          if (err) {
            reject(err);
            return;
          }
          data.Contents.forEach(c => {
            console.log('Listing Admin User Fetch: ' + c.Key);
            if (UserKey.isValid(c.Key)) {
              console.log("In listUsers valid key for a user: " + c.Key);
              const objectRetrievedPromise = new Promise<User>(((s3Resolve, s3Reject) => {
                this.s3Service.getS3().getObject({Bucket: environment.dataBucket, Key: c.Key}, (err2, data2) => {
                  if (err2) {
                    s3Reject(err2);
                    return;
                  }
                  const json = data2.Body.toString();
                  console.log(`user json: [${json}]`);
                  const user = new User(JSON.parse(json));
                  s3Resolve(user);
                });
              }));
              userPromises.push(objectRetrievedPromise);
            }
          });

          Promise.all(userPromises).then((d) => {
            resolve(d);
          });
        });

      } catch (e) {
        reject(e);
      }
    });
  }


  public saveUserImmediately(user: User): Promise<User> {
    const userKey = new UserKey(user.ID);
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: environment.dataBucket,
        Key: new UserKey(user.ID).toString(),
        Body: JSON.stringify(user),
        ContentType: 'text/json'
      };
      this.s3Service.getS3().putObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const ret = user as User;
          resolve(ret);
        }
      });
    });
  }

  // public deleteUser(user: User): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     const params = {
  //       Bucket: environment.dataBucket,
  //       Key: new UserKey(user.ID).toString()
  //     };
  //     console.log(`Deleting params[${JSON.stringify(params)}]`);
  //     this.s3Service.getS3().deleteObject(params, (err, data) => {
  //       if (err) {
  //         reject(err);
  //       } else {
  //         const index = this.users.indexOf(user);
  //         if ( index > -1 ) {
  //           this.users.splice(index, 1);
  //         }
  //         resolve(true);
  //       }
  //     });
  //   });
  // }
}


export class UserKey {
  private static regexp = new RegExp(/users\/[0-9a-z]+(-[0-9a-z]+)+\/config.user$/);
  private userId: string;

  public static isValid(input: string) {
    return this.regexp.test(input);
  }
  public constructor(userId: string) {
    this.userId = userId;
  }

  public toString(): string {
    return `users/${this.userId}/config.user`;
  }
}


