import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, APP_INITIALIZER, NgModule, ErrorHandler} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms'; // <-- NgModel lives here
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { MessagesComponent } from './component/messages/messages.component';
import { AppRoutingModule } from './/app-routing.module';

import {HttpClient, HttpClientModule} from '@angular/common/http';
import { NavigationComponent } from './component/navigation/navigation.component';
import { AlertComponent } from './component/alert/alert.component';
import {AlertService} from "./service-ui/alert.service";


// Services
import { ProjectNavbarService } from './service-ui/project.navbar.service';
import { MessageService } from './service-ui/message.service';
import { NavbarService } from './service-ui/navbar.service';
import { EditorProjectService } from './service-data/editor/editor-project.service';
import { StartupService } from './service-ui/startup.service';
import {CognitoUtil} from "./service-auth/cognito.service";
import {AwsUtil} from "./service-auth/aws.service";
import {UserRegistrationService} from "./service-auth/user-registration.service";
import {UserLoginService} from "./service-auth/user-login.service";
import {UserParametersService} from "./service-auth/user-parameters.service";
import {RegisterComponent} from "./component-account/register/registration.component";
import {ForgotPassword2Component, ForgotPasswordStep1Component} from "./component-account/forgot/forgotPassword.component";
import {  RegistrationConfirmationComponent} from "./component-account/confirm/confirmRegistration.component";
import {LanguagePipe} from "./pipe/language.pipe";
import {LoginComponent} from "./component-account/login/login.component";
import {NewPasswordComponent} from "./component-account/newpassword/newpassword.component";
import {ResendCodeComponent} from "./component-account/resend/resendCode.component";
import {AboutComponent, HomeComponent, HomeLandingComponent} from "./component-public/home.component";
import {SecureHomeComponent} from "./component/secure-home/secure-home.component";
import {NotificationsService} from "./service-data/notifications.service";
import {FileDropDirective} from "./file-upload/file-drop.directive";
import {FileSelectDirective} from "./file-upload/file-select.directive";
import {ProjectsComponent} from "./component/projects/projects.component";
import {ProjectComponent} from "./component/editor-project/project.component";
import {AdminUsersComponent} from "./component-admin/admin-users/admin-users.component";
import { CreateProjectComponent } from './component/create-project/create-project.component';
import {S3Service} from "./service-auth/s3.service";
import { ProjectThumbnailComponent } from './component/editor-project-thumbnail/project-thumbnail.component';
import { CoverComponent } from './component/cover/cover.component';
import { ChapterNavTileComponent } from './component/chapter-nav-tile/chapter-nav-tile.component';
import { CoverEditorComponent } from './component/cover-editor/cover-editor.component';
import { ChapterComponent } from './component/chapter/chapter.component';
import {ThemeService} from "./service-data/theme.service";
import { ColorPaletteComponent } from './component/cover-tools/color-palette/color-palette.component';
import {WindowRef} from "./WindowRef";
import { FontSettingsComponent } from './component/cover-tools/font-settings/font-settings.component';
import { CartComponent } from './component/cart/cart.component';
import {LambdaService} from "./service-auth/lambda.service";
import { InteriorToolsContainerComponent } from './component/interior-tools/interior-tools-container/interior-tools-container.component';
import { InteriorThemeEditorComponent } from './component/interior-tools/interior-theme/interior-theme-editor/interior-theme-editor.component';
import { InteriorThemeItemEditorComponent } from './component/interior-tools/interior-theme/interior-theme-item-editor/interior-theme-item-editor.component';
import { UploadComponent } from './component/upload/upload.component';
import {SubscriptionService} from "./service-data/subscription.service";
import { AccountComponent } from './component/account/account.component';
import {UserService} from "./service-data/user.service";
import { AdminComponent } from './component/admin/admin.component';
import {AdminService} from "./service-data/admin.service";
import {ImageSelectorComponent} from "./component/cover-tools/image-selector/image-selector.component";
import {ImageService} from "./service-data/image.service";
import {LayoutModule} from '@angular/cdk/layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { SubscriptionComponent } from './component/account/subscription/subscription.component';
import { PaymentComponent } from './component/account/payment/payment.component';

// import {CardModule} from 'ngx-card/ngx-card';
import { TestdialogComponent } from './component/testdialog/testdialog.component';
import { SideNavComponent } from './component/side-nav/side-nav.component';
import { InteriorEditorComponent } from './component/interior-editor/interior-editor.component';
import { UploadImagesComponent } from './component/upload-images/upload-images.component';
// import {GlobalErrorHandler} from "./global-error-handler";
import {ConfirmDialogComponent} from "./component/confirm-dialog/confirm-dialog.component";
import {ImagesComponent} from "./component/images/images.component";
import {CartPageComponent} from "./component/cart-page/cart-page.component";

import {ProductService} from "./service-data/product.service";
import {BookService} from "./service-data/book.service";
import {DraftService} from "./service-data/draft.service";
import {ProductThumbnailComponent} from "./component/product-thumbnail/product-thumbnail.component";
import {ProductComponent} from "./component/product/product.component";
import {ShelfComponent} from "./component/shelf/shelf.component";
import {ResourceComponent} from "./component/resource/resource.component";
import {PackageService} from "./service-data/package.service";
import {OrderService} from "./service-data/order.service";
import {EcommerceConnectionService} from "./service-data/ecommerce-connection.service";
import {EcommerceComponent} from "./component/account/ecommerce/ecommerce.component";
import {ContactComponent} from "./component/account/contact/contact.component";
import {EcommerceConnectionComponent} from "./component/account/ecommerce-connection/ecommerce-connection.component";
import {SpreadComponent} from './component/spread/spread.component';
import {OrderItemsComponent} from "./component-facility/order-items/order-items.component";
import {PrintNodeOrderService} from "./service-data/print-node-order.service";
import {PrintNodeOrderItemsComponent} from "./component/print-node-order-items/print-node-order-items.component";
import {ManufacturingSetsComponent} from "./component/manufacturing-sets/manufacturing-sets.component";
import {ManufacturingSetService} from "./service-data/manufacturing-set.service";
import {CSVComponent} from "./component/csv/csv.component";
import {CSVPageComponent} from "./component/csv/csv-page/csv-page.component";
import {TableComponent} from "./table/table.component";
import {UsersComponent} from "./component/users/users.component";
import {OrganizationService} from "./service-data/organization.service";
import {OrganizationsComponent} from "./component/organizations/organizations.component";
import { UmbrellasComponent } from './component/umbrellas/umbrellas.component';
import {UmbrellaService} from "./service-data/umbrella.service";
import {OrdersDeleteComponent} from "./component/orders-delete/orders-delete.component";
import {PrintNodeOrdersComponent} from "./component/print-node-orders/print-node-orders.component";
import { AddUserComponent } from './component/users/add-user/add-user.component';
import {FacilityInventoryProductsComponent} from "./component/facility-inventory-products/facility-inventory-products.component";
import {FacilityInventoryProductService} from "./service-data/facility-inventory-product.service";
import {CdkDetailRowDirective} from "./table/cdk-detail-row.directive";
import { UserComponent } from './component/users/user/user.component';
import {PrintNodeOrderComponent} from "./component/print-node-orders/print-node-order/print-node-order.component";
import { PrintNodeOrderItemComponent } from './component/print-node-orders/print-node-order-item/print-node-order-item.component';
import { GroupOrdersComponent } from './component/group-orders/group-orders.component';
import { GroupOrderComponent } from './component/group-orders/group-order/group-order.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {AddFacilityInventoryProductComponent} from "./component/facility-inventory-products/add-facility-inventory-product/add-facility-inventory-product.component";
import {AddGroupOrderComponent} from "./component/group-orders/add-group-order/add-group-order.component";
import { OrganizationComponent } from './component/organizations/organization/organization.component';
import {UmbrellaComponent} from "./component/umbrellas/umbrella/umbrella.component";
import {ServiceProvidersComponent} from "./component/service-providers/service-providers.component";
import {ServiceProviderComponent} from "./component/service-providers/service-provider/service-provider.component";
import {ServiceProviderService} from "./service-data/service-provider.service";
import {FacilitiesComponent} from "./component/facilities/facilities.component";
import {FacilityComponent} from "./component/facilities/facility/facility.component";
import {FacilityService} from "./service-data/facility.service";
import {FacilityInventoryProductComponent} from "./component/facility-inventory-products/facility-inventory-product/facility-inventory-product.component";
import {ShipmentOrdersComponent} from "./component/shipment-orders/shipment-orders.component";
import {OrderDeliverToService} from "./service-data/order-deliver-to.service";
import {ShipmentOrderComponent} from "./component/shipment-orders/shipment-order/shipment-order.component";
import {OrderItemComponent} from "./component/shipment-orders/order-item/order-item.component";
import {AddOrganizationComponent} from "./component/organizations/add-organization/add-organization.component";
import {AddServiceProviderComponent} from "./component/service-providers/add-service-provider/add-service-provider.component";
import {AddFacilityComponent} from "./component/facilities/add-facility/add-facility.component";
import {AddUmbrellaComponent} from "./component/umbrellas/add-umbrella/add-umbrella.component";
import {AddressIssueComponent} from "./component/address-issues/address-issue/address-issue.component";
import {AddressIssuesComponent} from "./component/address-issues/address-issues.component";
import {AddressIssueOrderDeliverToService} from "./service-data/address-issue-order-deliver-to.service";
import {AddressValidationService} from "./service-data/address-validation.service";
import {LanguageComponent} from "./component-account/language/language.component";
import { ActiveRoleComponent } from './component-sub/active-role/active-role.component';
import { ShoppingCartTopBarButtonComponent } from './component-sub/shopping-cart-top-bar-button/shopping-cart-top-bar-button.component';
import {SettingsComponent} from "./component/settings/settings.component";
import { AddCsvImportSettingsComponent } from './component/csv/add-csv-import-settings/add-csv-import-settings.component';
import {CsvImportSettingsService} from "./service-data/csv-import-settings.service";
import {BillingOrdersComponent} from "./component/billing-orders/billing-orders.component";
import {BillingOrderComponent} from "./component/billing-orders/billing-order/billing-order.component";

// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
import {PickSheetService} from "./service-data/pick-sheet.service";
import {FacilityPrintNodeOrderComponent} from "./component-facility/print-node-orders/facility-print-node-order/facility-print-node-order.component";
import {FacilityPrintNodeOrdersComponent} from "./component-facility/print-node-orders/facility-print-node-orders.component";
import {FacilityPrintNodeOrderItemComponent} from "./component-facility/print-node-orders/facility-print-node-order-item/facility-print-node-order-item.component";
import {FulfillmentOrderItemComponent} from "./component-facility/fulfilment-orders/fulfillment-order-item/fulfillment-order-item.component";
import {FulfillmentOrderComponent} from "./component-facility/fulfilment-orders/fulfillment-order/fulfillment-order.component";
import {FulfillmentOrdersComponent} from "./component-facility/fulfilment-orders/fulfillment-orders.component";
import {ShippingService} from "./service-data/shipping.service";
import {PicksheetsComponent} from "./component-facility/picksheets/picksheets.component";
import {PicksheetsGroupComponent} from "./component-facility/picksheets/picksheets-group/picksheets-group.component";
import {PrintNodeOrdersAdminComponent} from "./component/print-node-orders-admin/print-node-orders-admin.component";
import {PrintNodeOrderAdminComponent} from "./component/print-node-orders-admin/print-node-order/print-node-order-admin.component";
import {PrintNodeOrderItemAdminComponent} from "./component/print-node-orders-admin/print-node-order-item-admin/print-node-order-item-admin.component";
import {PrintNodeOrdersAdminContainedComponent} from "./component/billing-orders/print-node-orders-admin-contained/print-node-orders-admin-contained.component";
import {OrganizationInvoicesComponent} from "./component/organization-invoices/organization-invoices.component";
import {OrganizationInvoiceService} from "./service-data/organization-invoice.service";
import {FacilityInvoicesComponent} from "./component/facility-invoices/facility-invoices.component";
import {PrintNodeOrderSearchComponent} from "./component/print-node-order-search/print-node-order-search.component";
import {CatalogService} from "./service-data/catalog.service";
import {ProductsComponent} from "./component/products/products.component";
import {DetailedPicksheetsGroupComponent} from "./component-facility/picksheets/detailed-picksheets-group/detailed-picksheets-group.component";
import {PlaceReplenishmentOrderComponent} from "./component/facility-inventory-products/facility-inventory-product/place-replenishment-order/place-replenishment-order-component";
import {AdjustFacilityInventoryProductInventoryComponent} from "./component/facility-inventory-products/facility-inventory-product/adjust-facility-inventory-product-inventory/adjust-facility-inventory-product-inventory-component";
import {EditFacilityInventoryProductSettingsComponent} from "./component/facility-inventory-products/facility-inventory-product/edit-facility-inventory-product-settings/edit-facility-inventory-product-settings-component";
import {EditFacilityInventoryProductNoteComponent} from "./component/facility-inventory-products/facility-inventory-product/edit-facility-inventory-product-note/edit-facility-inventory-product-note-component";
import {FacilityInventoryProductEventService} from "./service-data/facility-inventory-product-event.service";
import {ManageFacilityInventoryProductsComponent} from "./component/products/manage-facility-inventory-products/manage-facility-inventory-products.component";
import {TransferFacilityInventoryProductInventoryComponent} from "./component/facility-inventory-products/facility-inventory-product/transfer-facility-inventory-product-inventory/transfer-facility-inventory-product-inventory-component";
import {PrintNodeOrderItemService} from "./service-data/print-node-order-item.service";
import {ReceiveReplenishmentPrintNodeOrderComponent} from "./component/facility-inventory-products/facility-inventory-product/receive-replenishment-print-node-order/receive-replenishment-print-node-order-component";
import {EditReplenishmentPrintNodeOrderComponent} from "./component/facility-inventory-products/facility-inventory-product/edit-replenishment-print-node-order/edit-replenishment-print-node-order-component";
import {UnreceiveReplenishmentPrintNodeOrderComponent} from "./component/facility-inventory-products/facility-inventory-product/unreceive-replenishment-print-node-order/unreceive-replenishment-print-node-order-component";
import {AddFacilityInventoryProductForOrganizationComponent} from "./component/products/manage-facility-inventory-products/add-facility-inventory-product-for-organization/add-facility-inventory-product-for-organization.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {StackedColumnComponent} from "./component/stacked-column/stacked-column.component";
import {PrintNodeOrderItemInManufacturingSetComponent} from "./component/manufacturing-sets/print-node-order-item-in-manufacturing-set/print-node-order-item-in-manufacturing-set.component";
import {EditFacilityInventoryProductEventComponent} from "./component/facility-inventory-products/facility-inventory-product/edit-facility-inventory-product-event/edit-facility-inventory-product-event.component";
import {ManufacturingBucketsComponent} from "./component/manufacturing-buckets/manufacturing-buckets.component";
import {PrintNodeOrderItemInManufacturingBucketComponent} from "./component/manufacturing-buckets/print-node-order-item-in-manufacturing-bucket/print-node-order-item-in-manufacturing-bucket.component";
import {ManufacturingTaskBucketPeriodService} from "./service-data/manufacturing-task-bucket-period.service";
import {ManufacturingTaskBucketService} from "./service-data/manufacturing-task-bucket.service";
import {ManufacturingResourceBucketService} from "./service-data/manufacturing-resource-bucket.service";
import {ManufacturingResourceService} from "./service-data/manufacturing-resource.service";
import {ManufacturingProcessService} from "./service-data/manufacturing-process.service";
import {ManufacturingTaskService} from "./service-data/manufacturing-task.service";
import {ManufacturingResourceBucketPeriodService} from "./service-data/manufacturing-resource-bucket-period.service";
import {FacilityScanComponent} from "./component/facility-scan/facility-scan.component";
import {ScanService} from "./service-data/scan.service";
import {FacilityPackagesComponent} from "./component/facility-packages/facility-packages.component";
import {PackageTypeService} from "./service-data/package-type.service";
import {ManufacturingTasksComponent} from "./component/manufacturing-tasks/manufacturing-tasks.component";
import {PrintNodeOrderItemInManufacturingTaskComponent} from "./component/manufacturing-tasks/print-node-order-item-in-manufacturing-task/print-node-order-item-in-manufacturing-task.component";
import {FacilityPackageComponent} from "./component/facility-packages/facility-package/facility-package.component";
import {EditAddressComponent} from "./component/facility-packages/facility-package/edit-address/edit-address.component";
import {ChangePackagesComponent} from "./component/facility-packages/facility-package/change-packages/change-packages.component";
import {SetStatComponent} from "./component/set-stat/set-stat.component";
import {FacilityPackagesSetFilterComponent} from "./component/facility-packages/facility-packages-set-filter/facility-packages-set-filter.component";
import {OrganizationInvoiceDetailsComponent} from "./component/organization-invoices/organization-invoice-details/organization-invoice-details.component";
import {ManufacturingSetAssignerService} from "./service-data/manufacturing-set-assigner.service";
import {FacilityInvoiceService} from "./service-data/facility-invoice.service";
import {FacilityInvoiceDetailsComponent} from "./component/facility-invoices/facility-invoice-details/facility-invoice-details.component";
import {ResourceService} from "./service-data/resource.service";
import {IssueService} from "./service-data/issue.service";
import {IssuesComponent} from "./component/issues/issues.component";
import {IssueComponent} from "./component/issue/issue.component";
import {StartupComponent} from "./component/startup/startup.component";
import {OrderItemService} from "./service-data/order-item.service";
import {SplitPrintNodeOrderComponent} from "./component/split-print-node-order/split-print-node-order.component";
import {OrderItemToPackageService} from "./service-data/order-item-to-package.service";
import {CoverService} from "./service-data/cover.service";
import {BodyService} from "./service-data/body.service";
import {RouterModule} from "@angular/router";
import {BookCoverImageComponent} from "./component/book-cover-image/book-cover-image.component";
import {AddressService} from "./service-data/address-service";
import {ChargeProfileService} from "./service-data/charge-profile-service";
import {PackageAndShippingComputationService} from "./service-data/package-and-shipping-computation.service";
import {ProjectService} from "./service-data/project.service";
import {ClassicProjectThumbnailComponent } from "./component/projects/project/classic-project-thumbnail/classic-project-thumbnail.component";
import {ProjectDetailsComponent} from "./component/projects/project/project-details/project-details.component";
import {ProjectDraftCoverComponent} from "./component/projects/draft/project-draft-cover/project-draft-cover.component";
import {ProjectDraftBodyComponent} from "./component/projects/draft/project-draft-body/project-draft-body.component";
import {ProjectDraftThumbnailComponent} from "./component/projects/draft/project-draft-thumbnail/project-draft-thumbnail.component";
import {ProjectDraftComponent} from "./component/projects/draft/project-draft.component";
import {ProjectDraftCoverImageComponent} from "./component/projects/draft/project-draft-cover-image/project-draft-cover-image.component";
import {ProjectBookBodyComponent} from "./component/projects/book/project-book-body/project-book-body.component";
import {ProjectBookCoverComponent} from "./component/projects/book/project-book-cover/project-book-cover.component";
import {
  ProjectBookCoverImageComponent
} from "./component/projects/book/project-book-cover-image/project-book-cover-image.component";
import {
  ProjectBookThumbnailComponent
} from "./component/projects/book/project-book-thumbnail/project-book-thumbnail.component";
import {ProjectBookComponent} from "./component/projects/book/project-book.component";
// import {TestTableComponent} from "./component/testtable/testtable.component";

// import { AngularMaterialModule } from './angular-material.module';
@NgModule({
    declarations: [
        AddressIssueComponent,
        AddressIssuesComponent,
        CdkDetailRowDirective,
        AppComponent,
        ProjectComponent,
        TableComponent,
        MessagesComponent,
        HomeComponent,
        HomeLandingComponent,
        AboutComponent,
        NavigationComponent,
        NewPasswordComponent,
        LanguagePipe,
        LoginComponent,
        LanguageComponent,
        RegistrationConfirmationComponent,
        ResendCodeComponent,
        ForgotPasswordStep1Component,
        ForgotPassword2Component,
        RegisterComponent,
        SecureHomeComponent,
        HomeLandingComponent,
        HomeComponent,
        ProjectDraftCoverComponent,
        ProjectDraftBodyComponent,
        ProjectBookBodyComponent,
        ProjectBookCoverComponent,
        ProjectBookCoverImageComponent,
        ProjectBookThumbnailComponent,
        ProjectBookComponent,
        ProjectsComponent,
        AdminUsersComponent,
        ActiveRoleComponent,
        FulfillmentOrdersComponent,
        FulfillmentOrderComponent,
        FulfillmentOrderItemComponent,
        ShoppingCartTopBarButtonComponent,
        PrintNodeOrderComponent,
        FacilityPrintNodeOrderComponent,
        FacilityPrintNodeOrdersComponent,
        FacilityPrintNodeOrderItemComponent,
        PrintNodeOrderItemInManufacturingSetComponent,
        PrintNodeOrderItemInManufacturingBucketComponent,
        PicksheetsComponent,
        PicksheetsGroupComponent,
        DetailedPicksheetsGroupComponent,
        IssueComponent,
        IssuesComponent,
        StartupComponent,
        SplitPrintNodeOrderComponent,
        ResourceComponent,
        SpreadComponent,
        FileSelectDirective,
        FileDropDirective,
        CreateProjectComponent,
        ProjectThumbnailComponent,
        ClassicProjectThumbnailComponent,
        ProjectDetailsComponent,
        ProductThumbnailComponent,
        ProjectDraftThumbnailComponent,
        ProjectDraftCoverImageComponent,
        BookCoverImageComponent,
        ProjectDraftComponent,
        ProductComponent,
        ShelfComponent,
        ConfirmDialogComponent,
        CoverComponent,
        ImagesComponent,
        PrintNodeOrdersComponent,
        PrintNodeOrderSearchComponent,
        PrintNodeOrdersAdminComponent,
        PrintNodeOrdersAdminContainedComponent,
        PrintNodeOrderAdminComponent,
        OrganizationInvoiceDetailsComponent,
        FacilityInvoiceDetailsComponent,
        PrintNodeOrderItemAdminComponent,
        BillingOrdersComponent,
        BillingOrderComponent,
        ShipmentOrdersComponent,
        ShipmentOrderComponent,
        OrderItemComponent,
        OrdersDeleteComponent,
        ImagesComponent,
        ChapterNavTileComponent,
        CoverEditorComponent,
        ChapterComponent,
        AlertComponent,
        ColorPaletteComponent,
        ImageSelectorComponent,
        FontSettingsComponent,
        CartComponent,
        CartPageComponent,
        CSVComponent,
        CSVPageComponent,
        InteriorToolsContainerComponent,
        PrintNodeOrderItemInManufacturingTaskComponent,
        InteriorThemeEditorComponent,
        InteriorThemeItemEditorComponent,
        PrintNodeOrderItemsComponent,
        ManufacturingSetsComponent,
        ManufacturingTasksComponent,
        FacilityPackagesComponent,
        FacilityPackageComponent,
        ManufacturingBucketsComponent,
        StackedColumnComponent,
        SetStatComponent,
        FacilitiesComponent,
        FacilityComponent,
        FacilityInventoryProductsComponent,
        FacilityInventoryProductComponent,
        ProductsComponent,
        AddFacilityInventoryProductComponent,
        AddFacilityInventoryProductForOrganizationComponent,
        EditAddressComponent,
        ChangePackagesComponent,
        FacilityPackagesSetFilterComponent,
        ManageFacilityInventoryProductsComponent,
        PlaceReplenishmentOrderComponent,
        EditReplenishmentPrintNodeOrderComponent,
        ReceiveReplenishmentPrintNodeOrderComponent,
        UnreceiveReplenishmentPrintNodeOrderComponent,
        AdjustFacilityInventoryProductInventoryComponent,
        TransferFacilityInventoryProductInventoryComponent,
        EditFacilityInventoryProductSettingsComponent,
        EditFacilityInventoryProductNoteComponent,
        EditFacilityInventoryProductEventComponent,
        UsersComponent,
        OrganizationInvoicesComponent,
        FacilityInvoicesComponent,
        FacilityScanComponent,
        AddFacilityComponent,
        AddOrganizationComponent,
        AddServiceProviderComponent,
        AddUmbrellaComponent,
        AddUserComponent,
        AddCsvImportSettingsComponent,
        UploadComponent,
        AccountComponent,
        AdminComponent,
        SubscriptionComponent,
        PaymentComponent,
        EcommerceComponent,
        OrganizationsComponent,
        OrganizationComponent,
        ServiceProvidersComponent,
        ServiceProviderComponent,
        UmbrellasComponent,
        SettingsComponent,
        UmbrellaComponent,
        ContactComponent,
        EcommerceConnectionComponent,
        TestdialogComponent,
        SideNavComponent,
        InteriorEditorComponent,
        UploadImagesComponent,
        OrderItemsComponent,
        UserComponent,
        PrintNodeOrderItemComponent,
        GroupOrdersComponent,
        AddGroupOrderComponent,
        GroupOrderComponent
    ],
    imports: [
        // ChartsModule,
        // AngularMaterialModule,
        ClipboardModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        RouterModule,
        HttpClientModule,
        // CKEditorModule,
        // CardModule,
        // NgbModule.forRoot(),
        LayoutModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        DragDropModule,
    ],
    providers: [
        // {
        //   provide: ErrorHandler,
        //   useClass: GlobalErrorHandler
        // },
          NotificationsService, HttpClient, CognitoUtil, AwsUtil, UserRegistrationService, WindowRef, UserLoginService,
          AlertService, UserParametersService, ImageService,
          PackageAndShippingComputationService, ProductService, EditorProjectService, AddressService, BookService, BodyService,
          ChargeProfileService, CoverService, DraftService, ProjectService, OrderService, PackageTypeService,
          AddressIssueOrderDeliverToService, OrderDeliverToService, ManufacturingSetService, OrderItemService,
          IssueService, PrintNodeOrderService, ManufacturingSetAssignerService, PrintNodeOrderItemService,
          OrderItemToPackageService, PackageService, ManufacturingProcessService, ManufacturingResourceService,
          ManufacturingResourceBucketService, ManufacturingResourceBucketPeriodService, ManufacturingTaskService,
          ManufacturingTaskBucketService, ManufacturingTaskBucketPeriodService, EcommerceConnectionService,
          MessageService, AdminService, NavbarService, ProjectNavbarService, StartupService, S3Service, LambdaService,
          FacilityInventoryProductService, FacilityInventoryProductEventService, ShippingService, PickSheetService,
          UserService, OrganizationService, OrganizationInvoiceService, FacilityInvoiceService, FacilityService,
          ServiceProviderService, AddressValidationService, UmbrellaService, CatalogService, ResourceService,
          SubscriptionService, CsvImportSettingsService, ThemeService, FormBuilder, ScanService,
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'en_US' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  // public language;
  // public name;

  constructor() {
 }}

export function startupServiceFactory(startupService: StartupService): Function {
  return () => startupService.load();
}


