import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./component-account/login/login.component";
import {RegisterComponent} from "./component-account/register/registration.component";
import {RegistrationConfirmationComponent} from "./component-account/confirm/confirmRegistration.component";
import {ResendCodeComponent} from "./component-account/resend/resendCode.component";
import {ForgotPassword2Component, ForgotPasswordStep1Component} from "./component-account/forgot/forgotPassword.component";
import {NewPasswordComponent} from "./component-account/newpassword/newpassword.component";
import {AboutComponent, HomeComponent} from "./component-public/home.component";
import {SecureHomeComponent} from "./component/secure-home/secure-home.component";
import {CreateProjectComponent} from "./component/create-project/create-project.component";
import {CartComponent} from "./component/cart/cart.component";
import {AccountComponent} from "./component/account/account.component";
import {PaymentComponent} from "./component/account/payment/payment.component";
import {SubscriptionComponent} from "./component/account/subscription/subscription.component";
import {ProjectComponent} from "./component/editor-project/project.component";
import {ImagesComponent} from "./component/images/images.component";
import {ProductComponent} from "./component/product/product.component";
import {ShelfComponent} from "./component/shelf/shelf.component";
import {PrintNodeOrderItemsComponent} from "./component/print-node-order-items/print-node-order-items.component";
import {ManufacturingSetsComponent} from "./component/manufacturing-sets/manufacturing-sets.component";
import {CSVComponent} from "./component/csv/csv.component";
import {UsersComponent} from "./component/users/users.component";
import {OrganizationsComponent} from "./component/organizations/organizations.component";
import {UmbrellasComponent} from "./component/umbrellas/umbrellas.component";
import {PrintNodeOrdersComponent} from "./component/print-node-orders/print-node-orders.component";
import {FacilityInventoryProductsComponent} from "./component/facility-inventory-products/facility-inventory-products.component";
import {GroupOrdersComponent} from "./component/group-orders/group-orders.component";
import {ServiceProvidersComponent} from "./component/service-providers/service-providers.component";
import {FacilitiesComponent} from "./component/facilities/facilities.component";
import {ShipmentOrdersComponent} from "./component/shipment-orders/shipment-orders.component";
import {AddressIssuesComponent} from "./component/address-issues/address-issues.component";
import {LanguageComponent} from "./component-account/language/language.component";
import {SettingsComponent} from "./component/settings/settings.component";
import {BillingOrdersComponent} from "./component/billing-orders/billing-orders.component";
import {FacilityPrintNodeOrdersComponent} from "./component-facility/print-node-orders/facility-print-node-orders.component";
import {FulfillmentOrdersComponent} from "./component-facility/fulfilment-orders/fulfillment-orders.component";
import {PicksheetsComponent} from "./component-facility/picksheets/picksheets.component";
import {PrintNodeOrdersAdminComponent} from "./component/print-node-orders-admin/print-node-orders-admin.component";
import {OrganizationInvoicesComponent} from "./component/organization-invoices/organization-invoices.component";
import {FacilityInvoicesComponent} from "./component/facility-invoices/facility-invoices.component";
import {PrintNodeOrderSearchComponent} from "./component/print-node-order-search/print-node-order-search.component";
import {ProductsComponent} from "./component/products/products.component";
import {ManufacturingBucketsComponent} from "./component/manufacturing-buckets/manufacturing-buckets.component";
import {FacilityScanComponent} from "./component/facility-scan/facility-scan.component";
import {FacilityPackagesComponent} from "./component/facility-packages/facility-packages.component";
import {ManufacturingTasksComponent} from "./component/manufacturing-tasks/manufacturing-tasks.component";
import {IssuesComponent} from "./component/issues/issues.component";
import {StartupComponent} from "./component/startup/startup.component";
import {ProjectDraftComponent} from "./component/projects/draft/project-draft.component";
import {ProjectsComponent} from "./component/projects/projects.component";
// import {TestTableComponent} from "./component/testtable/testtable.component";


export const homeRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {path: 'about', component: AboutComponent},
      {path: 'login', component: LoginComponent},
      {path: 'language', component: LanguageComponent},
      {path: 'register', component: RegisterComponent},
      {path: 'confirmRegistration/:username', component: RegistrationConfirmationComponent},
      {path: 'resendCode', component: ResendCodeComponent},
      {path: 'forgotPassword/:email', component: ForgotPassword2Component},
      {path: 'forgotPassword', component: ForgotPasswordStep1Component},
      {path: 'newPassword', component: NewPasswordComponent},
      {path: '', component: LoginComponent},
      {path: 'landing', component: LoginComponent}
    ]
  },
];

export const secureRoutes: Routes = [
  {

    path: '',
    redirectTo: '/secure',
    pathMatch: 'full'
  },
  {
    path: 'secure',
    component: SecureHomeComponent,
    children: [
        {path: 'account', component: AccountComponent,
          children: [
            {path: 'payment', component: PaymentComponent, outlet: 'account' },
            {path: 'subscription', component: SubscriptionComponent, outlet: 'account'}
          ]
        },
        // {path: 'admin', component: AdminComponent },
        {path: 'ordersearch', component: PrintNodeOrderSearchComponent },
        {path: 'orders', component: PrintNodeOrdersComponent },
        {path: 'products', component: ProductsComponent },
        {path: 'adminorders', component: PrintNodeOrdersAdminComponent },
        {path: 'picksheets', component: PicksheetsComponent },
        {path: 'facilityprintnodeorders', component: FacilityPrintNodeOrdersComponent },
        {path: 'settings', component: SettingsComponent },
        // {path: 'dataSource', component: OrdersComponent },
        {path: 'images', component: ImagesComponent },
        {path: 'cart', component: CartComponent },
        {path: 'shelf', component: ShelfComponent },
        {path: 'csv', component: CSVComponent },
        {path: 'projects', component: ProjectsComponent },
        // {path: 'project/:ID', component: ProjectComponent },
        // {path: 'product/:ID', component: ProductComponent },
        {path: 'shipmentorders', component: ShipmentOrdersComponent },
        {path: 'addressissues', component: AddressIssuesComponent },
        {path: 'startup', component: StartupComponent },
        {path: 'issues', component: IssuesComponent },
        // {path: 'orderitems', component: OrderItemsComponent },
        {path: 'groupOrders', component: GroupOrdersComponent },
        {path: 'orderitems', component: PrintNodeOrderItemsComponent },
        {path: 'manufacturingsets', component: ManufacturingSetsComponent },
        {path: 'manufacturingtasks', component: ManufacturingTasksComponent },
        {path: 'facilitypackages', component: FacilityPackagesComponent },
        {path: 'manufacturingbuckets', component: ManufacturingBucketsComponent },
        {path: 'billingorders', component: BillingOrdersComponent },
        {path: 'facilities', component: FacilitiesComponent },
        {path: 'facilityinventory', component: FacilityInventoryProductsComponent },
        {path: 'facilityscan', component: FacilityScanComponent },
        {path: 'fulfillmentorders', component: FulfillmentOrdersComponent },
        {path: 'users', component: UsersComponent },
        {path: 'organization-invoices', component: OrganizationInvoicesComponent },
        {path: 'facility-invoices', component: FacilityInvoicesComponent },
        {path: 'organizations', component: OrganizationsComponent },
//         {path: 'testtable', component: TestTableComponent },
        {path: 'serviceproviders', component: ServiceProvidersComponent },
        {path: 'umbrellas', component: UmbrellasComponent },
        {path: 'draftbook/:ID', component: ProjectDraftComponent },
        {path: 'createProject', component: CreateProjectComponent },
        {path: 'secure', component: PrintNodeOrderSearchComponent },
        {path: '', component: StartupComponent }
      // {path: '', component: ShelfComponent }
      ]
  }
];

export const adminRoutes: Routes = [
  {
    path: '',
    redirectTo: '/admin',
    pathMatch: 'full'
  },
  {
    path: 'admin',
    component: SecureHomeComponent,
    children: [
      {path: '', component: UsersComponent},
      {path: 'adminusers', component: UsersComponent}
    ]
  },
];

const routes: Routes = [
  {
    path: '',
    children: [
      ...homeRoutes,
      ...secureRoutes,
      ...adminRoutes,
      {
        path: '',
        component: HomeComponent
      }
    ]
  },


];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
