<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon>
          <span class="app-toolbar-filler"></span>
          <span *ngIf="startupService.project" class="toolbarProjectTitle" [matBadge]=" ( startupService.project.savingStatus === 'Failed' ) ? '99' : ( startupService.project.savingStatus === 'Saving' ) ? '1' : ( startupService.project.savingStatus === 'Saved' ) ? '' : ( startupService.project.savingStatus === 'Queued' ) ? '2' : '98' " [matBadgeSize]="( startupService.project.savingStatus === 'Failed' || startupService.project.savingStatus === 'Saving' ) ?  'medium' : 'medium'" [matBadgeColor]=" ( startupService.project.savingStatus === 'Failed' ) ? 'warn' : ( startupService.project.savingStatus === 'Saving' ) ? 'primary' : ( startupService.project.savingStatus === 'Saved' ) ? 'accent' : ( startupService.project.savingStatus === 'Queued' ) ? 'warn' : 'warn' " matBadgePosition="above after" i18n="@@Orders">Orders</span><span *ngIf="!startupService.project" class="toolbarProjectTitle" i18n="@@Orders">Orders</span>
        </button>
      </span>
    </div>
    <span class="app-toolbar-filler"></span>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Start Date</mat-label>
<!--        [min]="minDate" [max]="maxDate"-->
        <input matInput [matDatepicker]="picker"
               (dateInput)="dateChangeEvent($event)" (dateChange)="dateChangeEvent($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <button style="width: 120px;" mat-icon-button (click)="toggleDuplicatesFilter()" i18n-matTooltip="@@Duplicates" matTooltip="Duplicates"><mat-icon *ngIf="duplicateFilter==='ALL'">indeterminate_check_box</mat-icon><mat-icon *ngIf="duplicateFilter==='DUPLICATE'">check_box</mat-icon><mat-icon *ngIf="duplicateFilter==='NONE'">check_box_outline_blank</mat-icon>
<!--          <ng-container i18n="@@Duplicates">Duplicates</ng-container>-->
        {{duplicateFilter}}</button>
      <button style="width: 120px;" mat-icon-button (click)="toggleShippingFilter()" i18n-matTooltip="@@Shipped Status" matTooltip="Shipped Status"><mat-icon *ngIf="shippedFilter==='ALL'">indeterminate_check_box</mat-icon><mat-icon *ngIf="shippedFilter==='SHIPPED'">check_box</mat-icon><mat-icon *ngIf="shippedFilter==='NONE'">check_box_outline_blank</mat-icon>
<!--        <ng-container i18n="@@Shipped Status">Shipped Status</ng-container>-->
        {{shippedFilter}}</button>
      <button style="width: 90px;" mat-icon-button (click)="toggleHoldsFilter()" i18n-matTooltip="@@Holds" matTooltip="Holds"><mat-icon *ngIf="holdFilter==='ALL'">indeterminate_check_box</mat-icon><mat-icon *ngIf="holdFilter==='FLAGGED'">check_box</mat-icon><mat-icon *ngIf="holdFilter==='NONE'">check_box_outline_blank</mat-icon>
<!--        <ng-container i18n="@@Holds">Holds</ng-container>-->
        {{holdFilter}}</button>
    </div>
<!--    <div>-->
<!--      <button mat-icon-button (click)="refresh()" i18n-matTooltip="@@Refresh" matTooltip="Refresh"><mat-icon>refresh</mat-icon></button>-->
<!--    </div>-->
    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>
    <app-active-role></app-active-role>
  </mat-toolbar>
  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-side-nav focus="orders"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <div class="app-content app-table-content">
        <app-table *ngIf="columns && eventHandler && renderedData" [columns]="columns" [eventHandler]="eventHandler" [data]="renderedData" [expandedTableRowTemplate]="expandedTableRowTemplate" [dataServiceStatus]="printNodeOrderService.loadingState" [showFilters]="true">
          <ng-template #expandedTableRowTemplate let-element>
            <app-print-node-order-admin [products]="products" [printNodeOrder]="element"></app-print-node-order-admin>
          </ng-template>
        </app-table>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>






