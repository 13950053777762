<!--<div  i18n-dir="@@dir" dir="ltr" i18n-style="@@direction" style="direction: ltr">-->
<div class="app-container" [class.is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="app-toolbar">
    <div style="display:flex; width:100%">
      <span>
        <button mat-icon-button (click)="mainSideNav.toggle()"><mat-icon>menu</mat-icon></button>
      </span>
      <span class="app-toolbar-filler"></span>
      <ng-container *ngIf="selectedItem">
        <h2 class="toolbarProjectTitle">{{selectedItem.name}}</h2>
      </ng-container>
      <h2 *ngIf="!selectedItem"><span class="toolbarProjectsTitle" i18n="@@My Projects">My Projects</span></h2>
    </div>
    <span class="app-toolbar-filler"></span>
    <div *ngIf="!selectedItem">
      <button (click)="showFinalized = !showFinalized" mat-icon-button color="primary"><mat-icon *ngIf="!showFinalized" i18n-title="@@Show Finalized Books" title="Show Finalized Books" color="accent">lock_outline</mat-icon><mat-icon *ngIf="showFinalized" i18n-title="@@Hide Finalized Books" title="Hide Finalized Books" style="color: yellow">lock_outline</mat-icon></button>
      <button (click)="showEditable = !showEditable" mat-icon-button color="primary"><mat-icon *ngIf="!showEditable" i18n-title="@@Show Editalbe Books" title="Show Editalbe Books" color="accent">system_update_alt</mat-icon><mat-icon *ngIf="showEditable" i18n-title="@@Hide Editalbe Books" title="Hide Editalbe Books" style="color: yellow">system_update_alt</mat-icon></button>
<!--      <button (click)="showGrid = !showGrid" mat-icon-button color="primary"><mat-icon *ngIf="!showGrid" i18n-title="@@Show Grid" title="Show Grid" color="accent">grid_view</mat-icon><mat-icon *ngIf="showGrid" i18n-title="@@Show List" title="Show List" color="accent">table_view</mat-icon></button>-->
<!--      <button (click)="showSplitView = !showSplitView" mat-icon-button color="primary"><mat-icon *ngIf="!showSplitView" i18n-title="@@Show Split View" title="Show Split View" color="accent">horizontal_split</mat-icon><mat-icon *ngIf="showSplitView" i18n-title="@@Show Full View" title="Show Full View" color="accent">rectangle</mat-icon></button>-->
    </div>
<!--    <div>-->
<!--      <span>-->
<!--        <button [mat-menu-trigger-for]="languagesMenu" mat-raised-button color="primary" (click)="showLanguagesMenu = !showLanguagesMenu" title="Change Language" i18n-title="@@Change Language"><mat-icon>language</mat-icon> </button>-->
<!--        <mat-menu #languagesMenu="matMenu">-->
<!--          <a mat-menu-item i18n-href="@@enURL" href="/" i18n="@@English">English</a>-->
<!--          <a mat-menu-item i18n-href="@@frURL" href="/" i18n="@@French">français</a>-->
<!--          <a mat-menu-item i18n-href="@@esURL" href="/" i18n="@@Spanish">Español</a>-->
<!--          <a mat-menu-item i18n-href="@@deURL" href="/" i18n="@@German">Deutsche</a>-->
<!--          <a mat-menu-item i18n-href="@@arURL" href="/" i18n="@@Arabic" style="text-align:right">عربى</a>-->
<!--          <a mat-menu-item i18n-href="@@zhURL" href="/" i18n="@@Chinese">中文</a>-->
<!--          <a mat-menu-item i18n-href="@@amURL" href="/" i18n="@@Amharic">አማርኛ</a>-->
<!--          <a mat-menu-item i18n-href="@@koURL" href="/" i18n="@@Korean">한국어</a>-->
<!--          <a mat-menu-item i18n-href="@@roURL" href="/" i18n="@@Romanian">Română</a>-->
<!--          <a mat-menu-item i18n-href="@@ruURL" href="/" i18n="@@Russian">русский</a>-->
<!--        </mat-menu>-->
<!--      </span>-->
<!--    </div>-->
<!--    <app-shopping-cart-top-bar-button></app-shopping-cart-top-bar-button>-->
    <app-active-role></app-active-role>
  </mat-toolbar>

  <mat-sidenav-container class="app-sidenav-container" (backdropClick)="mainSideNav.close()" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav opened #mainSideNav [mode]="mobileQuery.matches ? 'over' : 'side'" (keydown.escape)="mainSideNav.close()" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" opened="true">
      <mat-nav-list>
        <app-side-nav focus="library"></app-side-nav>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="m2app-dark">
      <div class="app-content">
<!--        <div *ngFor="let project of items">-->
<!--          <div><span>Project {{project.ID}} {{project.name}} {{project.primaryBook_ID}} {{project.primaryDraft_ID}} {{project.created}} {{project.archived}}</span>-->
<!--            <div *ngIf="project.primaryBook"><span>PRIMARY BOOK {{project.primaryBook.ID}} {{project.primaryBook.title}}</span></div>-->
<!--            <div *ngIf="project.primaryDraft"><span>PRIMARY DRAFT {{project.primaryDraft.ID}} {{project.primaryDraft.title}}</span></div>-->
<!--            <div *ngFor="let book of project.books"><span>BOOK {{book.ID}} {{book.title}}</span></div>-->
<!--            <div *ngFor="let draft of project.drafts"><span>DRAFT {{draft.ID}} {{draft.title}}</span></div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="projectThumbnailArea" *ngIf="showSplitView || !editingDetails" [style.height]="showSplitView ? '50%' : '100%'">
          <ng-container *ngIf="items && items.length > 0">
            <ng-container *ngFor="let item of items">
              <app-classic-project-thumbnail [project]="item" [projects]="items" style="float:left" [class.selected]="item === selectedItem" (editingDetailsChange)="editingDetailsChanged($event)" (selectedItem)="selectedItemChanged($event)"></app-classic-project-thumbnail>
            </ng-container>
          </ng-container>
        </div>
        <div class="projectThumbnailArea" *ngIf="showSplitView || editingDetails" [style.height]="showSplitView ? '50%' : '100%'">
          <div style="background: blue; height: 10px">XXXXXX {{ selectedItem ? selectedItem.ID : 'NO SELECTED ITEM'}} {{ editingDetails }}</div>
          <ng-container *ngIf="selectedItem" [ngSwitch]="editingDetails">
            <ng-container *ngSwitchCase="'PROJECT'">
              <div style="background: pink; height: 10px">CCCCCC {{ selectedItem ? selectedItem.ID : 'NO SELECTED ITEM'}} {{ editingDetails }}</div>
              <app-project-details [project]="selectedItem" [displayCover]="displayCover" [editingDetails]="editingDetails" (editingDetailsChange)="editingDetailsChanged($event)"></app-project-details>
            </ng-container>
            <ng-container *ngSwitchCase="'BOOK'">
              <div style="background: purple; height: 10px">BBBBBB {{ selectedItem ? selectedItem.ID : 'NO SELECTED ITEM'}} {{ editingDetails }}</div>
              <app-project-book [book]="selectedItem.primaryBook" [displayCover]="displayCover" [editingDetails]="editingDetails" (editingDetailsChange)="editingDetailsChanged($event)"></app-project-book>
            </ng-container>
            <ng-container *ngSwitchCase="'DRAFT'">
              <div style="background: green; height: 10px">AAAAA {{ selectedItem ? selectedItem.ID : 'NO SELECTED ITEM'}} {{ editingDetails }}</div>
              <app-project-draft [draft]="selectedItem.primaryDraft" [displayCover]="displayCover" [editingDetails]="editingDetails" (editingDetailsChange)="editingDetailsChanged($event)"></app-project-draft>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div style="background: red; height: 10px">AAAAA {{ selectedItem ? selectedItem.ID : 'NO SELECTED ITEM'}} {{ editingDetails }}</div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
