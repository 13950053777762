import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import {ManufacturingTaskBucketPeriod} from "../../model/ManufacturingTaskBucketPeriod";
import {Observable} from "rxjs";
import {ManufacturingSet} from "../../model/ManufacturingSet";
import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs";
import {AbstractLiveDataService} from "./abstract-live-data.service";
import {T} from "@angular/cdk/keycodes";
import {ObjectAttachRequest} from "./data-service";
import {NotificationsService} from "./notifications.service";

@Injectable()
export class ManufacturingTaskBucketPeriodService extends AbstractLiveDataService<ManufacturingTaskBucketPeriod> {

  constructor(private httpa: HttpClient, private messageServicea: MessageService, private startupServicea: StartupService, protected notificationsService: NotificationsService) {
    super(httpa, messageServicea, startupServicea, notificationsService);
    this.notificationsService.setManufacturingTaskBucketPeriodService(this);
    this.notificationsService.startNotification();
  }

  makeObjectInstance(args: any): ManufacturingTaskBucketPeriod {
    const object = new ManufacturingTaskBucketPeriod(args);
    return object;
  }

  getLoggingObjectTypeName (): string {
    return "ManufacturingTaskBucketPeriod";
  }

  supportsParallel(): boolean {
    return false;
  }

  getURLEndPoint (): string {
    return "manufacturingtaskbucketperiod";
  }

  getLinkData(): any[] {
    return [
      { action: 'AddUpdateArray', serviceName: 'manufacturingSetService', arrayProperty: 'manufacturingSets'},
      { action: 'Link', objectIdPropertyName: 'manufacturingTaskBucket_id', serviceName: 'manufacturingTaskBucketService', arrayProperty: 'manufacturingTaskBucketPeriods', objectProperty: 'manufacturingTaskBucket'},
    ];
  }

  /** PUT: update the manufacturingSet on the server */
  addPrintNodeOrderItem (manufacturingSetId: number, printNodeOrderItemId: number): Observable<ManufacturingSet> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/addprintnodeorderitem/${manufacturingSetId}/${printNodeOrderItemId}`;
    this.messageService.add(`ManufacturingSetService: addPrintNodeOrderItem manufacturingSetId=${manufacturingSetId}  manufacturingProcessId=${printNodeOrderItemId}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingSet => {
          const m = new ManufacturingSet(manufacturingSet);
          return m;
        }),
        catchError(this.handleErrorX<any>('addPrintNodeOrderItem'))
      );
  }
  /** PUT: update the manufacturingSet on the server */
  removePrintNodeOrderItem (manufacturingSetId: number, printNodeOrderItemId: number): Observable<ManufacturingSet> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/removeprintnodeorderitem/${manufacturingSetId}/${printNodeOrderItemId}`;
    this.messageService.add(`ManufacturingSetService: removePrintNodeOrderItem manufacturingSetId=${manufacturingSetId}  manufacturingProcessId=${printNodeOrderItemId}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingSet => {
          const m = new ManufacturingSet(manufacturingSet);
          return m;
        }),
        catchError(this.handleErrorX<any>('addPrintNodeOrderItem'))
      );
  }

  /** PUT: update the manufacturingSet on the server */
  setStatus (manufacturingTaskBucketPeriodId: number, status: number): Observable<ManufacturingSet> {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}setstatus/${manufacturingTaskBucketPeriodId}/${status}`;
    this.messageService.add(`ManufacturingSetService: setOpenStatus manufacturingSetId=${manufacturingTaskBucketPeriodId}  manufacturingProcessId=${status}`);
    return this.http.put(url, {}, this.startupService.getHttpOptions())
      .pipe(
        map(manufacturingSet => {
          const m = new ManufacturingSet(manufacturingSet);
          return m;
        }),
        catchError(this.handleErrorX<any>('setOpenStatus'))
      );
  }

  private handleErrorX<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
