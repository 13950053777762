export const environment = {
  production: true,
  region: 'us-east-1',
  sqsURL: 'https://sqs.us-east-1.amazonaws.com/180022872643/',
  apiBaseURL: 'https://api.snowfallpress.com/1.0/ui/',

  identityPoolId: 'us-east-1:879491d8-5653-4080-8562-1373ce55636b',
  userPoolId: 'us-east-1_VDWCn50Wz',
  clientId: '11tdnup9ve5ua9b4ajfl1hrnbd',

  imageBucket: 'imagebucket',
  dataBucket: 'databucket',
  bucketRegion: 'us-east-1',

  publicBucket: 'en.snowfallpress.com',
  defaultInteriorThemeKey: 'assets/interior-themes/Classic/css.css',

  ddbTableName: '',

  organizationName: 'Snowfall Press',
  limitToOnlyCSVAndOrderHistory: false,
  allowUsersToSignThemSelvesUp: true,
  showLanguagesOptions: true,
  serviceProviderId: 1,

  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  dynamodb_endpoint: '',
  s3_endpoint: ''

};
