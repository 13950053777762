import {Component, Inject, OnInit, VERSION, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../../pipe/language.pipe";
import {UserService} from "../../../../service-data/user.service";
import {Product} from "../../../../../model/Product";
import {FacilityInventoryProduct} from "../../../../../model/FacilityInventoryProduct";
import {FacilityInventoryProductService} from "../../../../service-data/facility-inventory-product.service";
import {Organization} from "../../../../../model/Organization";
import {OrganizationService} from "../../../../service-data/organization.service";
import {ProductService} from "../../../../service-data/product.service";
import {MatTableDataSource} from "@angular/material/table";
import {FacilityInventoryProductEvent} from "../../../../../model/FacilityInventoryProductEvent";
import {FacilityInventoryProductEventService} from "../../../../service-data/facility-inventory-product-event.service";

@Component({
  selector: 'app-transfer-facility-inventory-product-inventory',
  templateUrl: './transfer-facility-inventory-product-inventory-component.html',
  styleUrls: ['./transfer-facility-inventory-product-inventory-component.css']
})
export class TransferFacilityInventoryProductInventoryComponent implements OnInit {
  public facilityInventoryProducts: FacilityInventoryProduct[];
  public facilityInventoryProduct: FacilityInventoryProduct;
  public breakpoint: number; // Breakpoint observer code
  public actionType: string = ``;
  public date: Date = new Date();
  public quantity: number = 0;
  public transferFacilityInventoryProduct: FacilityInventoryProduct;
  public formGroup: FormGroup;
  wasFormChanged = false;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public facililtyInventoryProductService: FacilityInventoryProductService, public facilityInventoryProductEventService: FacilityInventoryProductEventService, @Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<TransferFacilityInventoryProductInventoryComponent>) {
    this.facilityInventoryProduct = data.facilityInventoryProduct;
  }

  public ngOnInit(): void {
    this.date = new Date();
    this.formGroup = this.formBuilder.group({
      actionType: [this.actionType, [Validators.required]],
      quantity: [this.quantity, [Validators.required]],
      date: [this.date, [Validators.required]],
      transferFacilityInventoryProduct: [this.transferFacilityInventoryProduct, [Validators.required]]
    });

    // TODO: Filter this a query for just the FacilityInventoryProducts that have the same product_ID or are a part of productIds at a different facility
    this.facililtyInventoryProductService.getItems()
      .subscribe(facililtyInventoryProducts => {
        this.facilityInventoryProducts = [];
        for ( const facililtyInventoryProduct of facililtyInventoryProducts ) {
          if (facililtyInventoryProduct.product_ID === this.facilityInventoryProduct.product_ID || facililtyInventoryProduct.productIds.indexOf(',' + facililtyInventoryProduct.product_ID) >= 0 ) {
            this.facilityInventoryProducts.push(facililtyInventoryProduct);
          }
        }
      });

    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onSave(): void {
    const objectToAdd = new FacilityInventoryProductEvent(undefined, this.facilityInventoryProduct);
    objectToAdd.printnode_ID = this.formGroup.controls.printnode_ID.value;
    objectToAdd.facilityInventoryProduct = this.facilityInventoryProduct;
    objectToAdd.quantity = this.quantity;
    objectToAdd.status = 'VALID';
    objectToAdd.type = 'ACTUAL';
    objectToAdd.timestamp = this.date;
    switch ( this.actionType ) {
      case 'TransferTo': {
        objectToAdd.source = 'TRANSFER';
        objectToAdd.quantity = -this.quantity;
        const otherObjectToAdd = new FacilityInventoryProductEvent(undefined, this.facilityInventoryProduct);
        otherObjectToAdd.printnode_ID = this.formGroup.controls.printnode_ID.value;
        otherObjectToAdd.facilityInventoryProduct = this.facilityInventoryProduct;
        otherObjectToAdd.quantity = this.quantity;
        otherObjectToAdd.status = 'VALID';
        otherObjectToAdd.type = 'ACTUAL';
        this.facilityInventoryProductEventService.addFacilityInventoryProductEvent(otherObjectToAdd, this.facilityInventoryProduct).subscribe( object => {
          console.log(`Added FacilityInventoryProductEvent : ${object.facilityinventoryproduct_ID}`);
        });
        break;
      }
      case 'TransferFrom': {
        objectToAdd.source = 'TRANSFER';
        const otherObjectToAdd = new FacilityInventoryProductEvent(undefined, this.facilityInventoryProduct);
        otherObjectToAdd.printnode_ID = this.formGroup.controls.printnode_ID.value;
        otherObjectToAdd.facilityInventoryProduct = this.facilityInventoryProduct;
        otherObjectToAdd.quantity = -this.quantity;
        otherObjectToAdd.status = 'VALID';
        otherObjectToAdd.type = 'ACTUAL';
        this.facilityInventoryProductEventService.addFacilityInventoryProductEvent(otherObjectToAdd, this.facilityInventoryProduct).subscribe( object => {
          console.log(`Added FacilityInventoryProductEvent : ${object.facilityinventoryproduct_ID}`);
        });
        break;
      }
    }

    this.facilityInventoryProductEventService.addFacilityInventoryProductEvent(objectToAdd, this.facilityInventoryProduct).subscribe( object => {
      console.log(`Added FacilityInventoryProductEvent : ${object.facilityinventoryproduct_ID}`);
    });
    this.markAsDirty(this.formGroup);
    this.dialogRef.close();
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
