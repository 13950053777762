import {Component, Input, OnInit} from '@angular/core';
import {ColorPaletteGroup} from "../../../../model/color/ColorPaletteGroup";
import {BaseColor, Shade, Shades} from "../../../../model/color/BaseColor";
import {StartupService} from "../../../service-ui/startup.service";
import {CoverTheme} from "../../../../model/editor/CoverTheme";
import {Image} from "../../../../model/Image";
import {ImageService} from "../../../service-data/image.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.css']
})
export class ImageSelectorComponent implements OnInit {
  // @Input() coverTheme: CoverTheme;
  selectedImage: Image;
  selectedLibrary: string = 'backgrounds';
  backgroundLibrary: Image[];
  transparentLibrary: Image[];
  userBackgroundLibrary: Image[];
  userTransparentLibrary: Image[];
  userLogoLibrary: Image[];


  showAddBackgroundImages: boolean = false;
  showAddTransparentImages: boolean = false;
  showAddLogoImages: boolean = false;

  constructor (public router: Router, public startupService: StartupService, public imageService: ImageService) {
  }

  ngOnInit() {
    console.log("Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.imageService.getImages('backgrounds').subscribe(images => {
          this.backgroundLibrary = images;
      });
      this.imageService.getImages('transparent').subscribe(images => {
        this.transparentLibrary = images;
      });
      this.imageService.getImages('userbackgrounds').subscribe(images => {
        this.userBackgroundLibrary = images;
      });
      this.imageService.getImages('usertransparent').subscribe(images => {
        this.userTransparentLibrary = images;
      });
      this.imageService.getImages('userlogo').subscribe(images => {
        this.userLogoLibrary = images;
      });
    }
  }

  onSelect(image: Image) {
    this.selectedImage = image;
    if ( this.startupService.canvasManager ) {
      if ( this.startupService.canvasManager.getType() === 'image' ) {
        // Remove the privious image from the list of images for the cover and add the new one to that list
        const currentImageKey = this.startupService.canvasManager.getImageKey();

        this.startupService.canvasManager.project.cover.removeImage(currentImageKey, this.startupService.canvasManager.getRole());
        this.startupService.canvasManager.project.cover.addImage(image.key, this.startupService.canvasManager.getRole());
        this.startupService.canvasManager.setImageSrc(image.key, this.imageService.getFreshImageUrl(image, false));
      }
    }
  }

}


