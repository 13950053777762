import {Product} from "../../../../model/Product";

export class PossibleMatch {
  product: Product;
  matches: number;

  constructor (matches: number, product: Product) {
    this.matches = matches;
    this.product = product;
  }
}
