import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import {retry} from 'rxjs/operators';
import { Umbrella } from '../../model/Umbrella';
import { MessageService } from '../service-ui/message.service';
import { StartupService } from '../service-ui/startup.service';
import {DataService} from "./data-service";
import {environment} from "../../environments/environment";
import {DataObject} from "../../model/DataObject";

// https://coryrylan.com/blog/angular-observable-data-services
@Injectable()
export abstract class AbstractDataService<T extends DataObject> extends DataService {
  private _objects = new BehaviorSubject<T[]>([]);
  private dataStore: { objects: T[] } = { objects: [] };
  readonly objects = this._objects.asObservable();
  public loadingState = 'INITIAL';

  constructor (protected http: HttpClient, protected messageService: MessageService, protected startupService: StartupService) {
    super(startupService);
  }

  abstract makeObjectInstance(args: any): T;
  abstract getLoggingObjectTypeName(): string;
  abstract getURLEndPoint(): string;

  loadAll() {
    if ( this.loadingState === 'INITIAL') {
      this.loadingState = 'LOADING';
      const url = `${environment.apiBaseURL}${this.getURLEndPoint()}`;
      this.http.get<T[]>(url, this.startupService.getHttpOptions()).subscribe(data => {
        const os: T[] = [];
        data.forEach( o => {
          const oo = this.makeObjectInstance( o );
          if ( oo ) {
            this.addObjectInAlphabeticalOrder(oo, os);
          }
        });
        this.dataStore.objects = os;
        this._objects.next(Object.assign({}, this.dataStore).objects);
        this.loadingState = 'LOADED';
      }, error => {
        console.log('Could not load ' + this.getLoggingObjectTypeName());
        this.loadingState = 'ERROR';
      });
    }
  }

  load(id: number | string) {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/${id}`;
    this.http.get<T>(url, this.startupService.getHttpOptions()).subscribe(
      data => {
        let notFound = true;
        this.dataStore.objects.forEach((item, index) => {
          if (item.getId() === data.getId()) {
            this.dataStore.objects[index] = data;
            notFound = false;
          }
        });
        if (notFound) {
          const oo = this.makeObjectInstance( data );
          if ( oo ) {
            this.addObjectInAlphabeticalOrder(oo, this.dataStore.objects);
          }
        }
        this._objects.next(Object.assign({}, this.dataStore).objects);
      },
      error => console.log('Could not load  ' + this.getLoggingObjectTypeName())
    );
  }

  create(object: T) {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}`;
    this.http.post<T>(url, object, this.startupService.getHttpOptions())  .pipe(
        retry(6))
      .subscribe(
        data => {
          const oo = this.makeObjectInstance( data );
          if ( oo ) {
            this.addObjectInAlphabeticalOrder(oo, this.dataStore.objects);
          }
          this._objects.next(Object.assign({}, this.dataStore).objects);
        },
        error => console.log('Could not create object.')
      );
  }

  update(object: T) {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/${object.getId()}`;
    this.http.put<T>(url, object, this.startupService.getHttpOptions())
      .subscribe(
        data => {
          const oo = this.makeObjectInstance( data );
          if ( oo ) {
            // this.addObjectInAlphabeticalOrder(oo, this.dataStore.objects); The data change could have caused a resort
            this.dataStore.objects.forEach((o, i) => {
              if (o.getId() === oo.getId()) {
                // this.dataStore.objects[i] = oo;
                this.dataStore.objects.splice(i, 1);
                this.addObjectInAlphabeticalOrder(oo, this.dataStore.objects);
              }
            });

            this._objects.next(Object.assign({}, this.dataStore).objects);
          }
        },
        error => console.log('Could not update todo.')
      );
  }

  delete(id: number) {
    const url = `${environment.apiBaseURL}${this.getURLEndPoint()}/${id}`;
    this.http.delete<Umbrella>(url, this.startupService.getHttpOptions()).subscribe(
      response => {
        this.dataStore.objects.forEach((t, i) => {
          if (t.getId() === id) {
            this.dataStore.objects.splice(i, 1);
          }
        });
        this._objects.next(Object.assign({}, this.dataStore).objects);
      },
      error => console.log('Could not delete  ' + this.getLoggingObjectTypeName())
    );
  }

  addObjectInAlphabeticalOrder(object: T, array: Array<T>) {
    if ( object && array ) {
      let i = array.length - 1;
      for (; i >= 0; i--) {
        if ( array[i].getId() === object.getId()) {
          array.splice(i, 0);
        }
      }

      i = 0;
      for (; i < array.length && array[i].getOrderValue() && array[i].getOrderValue() < object.getOrderValue(); i++) {
      }
      array.splice(i, 0, object);
    }
  }

  reset (filter: string = '') {
    this.loadingState = 'INITIAL';
    while (this.dataStore.objects.length > 0 ) {
      this.dataStore.objects.pop();
    }
    // this.dataStore.objects = [];
    this._objects.next(Object.assign({}, this.dataStore).objects);
    this.loadAll();
  }

  // retrieveLocalObject(id: string): T {
  //   for (const object of this.objects) {
  //     if (object.getId() === id) {
  //       return object;
  //     }
  //   }
  //   return null;
  // }
  //
  // refresh(id: string) {
  //   const localObject = this.retrieveLocalObject(id);
  //   this.loadObject(id).then(object => {
  //     if (localObject) {
  //       for (const key in object) {
  //         if (object[key] !== localObject[key]) {
  //           localObject[key] = object[key];
  //         }
  //       }
  //     } else {
  //       this.addObjectInAlphabeticalOrder(object);
  //     }
  //   });
  // }



}
