export class Image {
    public key: string;
    public url: string;
    public thumbnailUrl: string;
    public timestamp: number;

    constructor( obj ) {
      this.key = '';
      this.url = '';
      this.thumbnailUrl = '';
      this.timestamp = 0;
      if ( obj ) {
        for ( const prop of Object.keys(obj) ) {
          this[prop] = obj[prop];
        }
      }
      try {
        if ( this.key && this.key['imageKey'] ) {
          this.key = this.key['imageKey'];
        }
        if ( this.key && this.key.startsWith('datachurchprintondemand')) {
          this.key = this.key.replace('datachurchprintondemand', 'imagechurchprintondemand');
        }
      } catch ( err ) {

      }
    }
}

