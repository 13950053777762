<div>
  <div class="add-title">
    <h2 class="primary" i18n="@@Add Facility Inventory Product">Add Facility Inventory Product</h2>
  </div>
  <div>
    <form [formGroup]="addForm" (change)="formChanged()">
      <mat-grid-list cols="2" [cols]="breakpoint" rowHeight="85px" (window:resize)="onResize($event)">
<!--        <mat-grid-tile>-->
<!--          <div class="text-inside">-->
<!--            <mat-form-field class="full-width">-->
<!--              <mat-select  placeholder="Organization" i18n-placeholder="@@Organization" formControlName="organizationId" (selectionChange)="organizationSet()" required>-->
<!--                <mat-option *ngFor="let organization of organizations" [value]="organization.ID">{{organization.ID}} {{organization.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </mat-grid-tile>-->
        <mat-grid-tile *ngIf="startupService.selectedRole !== 'facility'">
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-label></mat-label>
              <mat-select  placeholder="Facility" i18n-placeholder="@@Facility"  formControlName="printnode_ID" required>
                <mat-option value="1" i18n='@@Phoneix, AZ'>Phoneix, AZ</mat-option>
                <mat-option value="7" i18n='@@Houston, TX' selected='selected'>Houston, TX</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-label></mat-label>
              <input matInput i18n-placeholder="@@BookId" placeholder="BookId" id="bookId" type="number" min="100"  max="1000000" formControlName="bookId">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <button mat-icon-button color="primary" [disabled]="!addForm.controls.bookId.value || addForm.controls.bookId.value < 100" (click)="lookupProductFromBookId()" i18n-title="@@Lookup Product From BookId" title="Lookup Product From BookId"><mat-icon>search</mat-icon></button>
<!--            <button *ngif="addForm.controls.bookId.value && addForm.controls.bookId.value >= 100" mat-icon-button color="primary" [disabled]="!addForm.controls.bookId.value || addForm.controls.bookId.value < 100" (click)="lookupProductFromBookId()" i18n-title="@@Lookup Product From BookId" title="Lookup Product From BookId"><mat-icon>search</mat-icon></button>-->
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-label></mat-label>
              <input matInput i18n-placeholder="@@location" placeholder="Location" id="location" type="text" formControlName="location">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-label></mat-label>
              <input matInput i18n-placeholder="@@Shelf" placeholder="Shelf" id="shelf" type="text" formControlName="shelf">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-label></mat-label>
              <mat-select  placeholder="Inventory Management Type" i18n-placeholder="@@Inventory Management Type" formControlName="inventoryManagementType" required>
                <mat-option value="CUSTOMER" i18n='@@Customer'>Customer</mat-option>
                <mat-option value="LEVEL" i18n='@@Level' selected='selected'>Level</mat-option>
                <mat-option value="ADHOC" i18n='@@Ad Hoc'>Ad Hoc</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-label></mat-label>
              <input matInput i18n-placeholder="@@Reorder Quantity" placeholder="Reorder Quantity" id="reorderQuantity" type="number" min="0"  max="1500" formControlName="reorderQuantity">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-label></mat-label>
              <input matInput i18n-placeholder="@@Reorder Level" placeholder="Reorder Level" id="reorderLevel" type="number" min="0"  max="1500" formControlName="reorderLevel">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-label></mat-label>
              <input matInput i18n-placeholder="@@Case Quantity" placeholder="Case Quantity" id="caseQuantity" type="number" min="0"  max="1500" formControlName="caseQuantity">
            </mat-form-field>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="text-inside">
            <mat-form-field class="full-width">
              <mat-label></mat-label>
              <input matInput i18n-placeholder="@@Initial Quantity" placeholder="Initial Quantity" id="initialQuantity" type="number" min="0"  max="1500" formControlName="initialQuantity">
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <div>
        <p style="color: lightgrey" *ngIf="product"><span i18n="@@Product Title">Product Title</span>: {{product.title}}</p>
        <p style="colorL white" *ngIf="message">{{message}}</p>
        <p style="color: red" *ngIf="errorMessage">{{errorMessage}}</p>
      </div>
      <div class="btn-sec">
        <button mat-raised-button type="submit" color="primary" class="Update-btn" [disabled]="addForm.invalid || !product" (click)="onAdd()" i18n="@@Add Inventory Product">Add Inventory Product</button>
        <button mat-raised-button type="button" class="Discard-btn" mat-dialog-close="" i18n="@@Cancel">Cancel</button>
      </div>
    </form>
  </div>
</div>
