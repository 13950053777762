import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {DataService} from "./data-service";

@Injectable()
export class ScanService extends DataService {

  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
    super(startupService);
  }

  /** PUT: sendScan */
  scan(value: string ): Observable<String> {
    const url = `${environment.apiBaseURL}scan/${value}`;
    return this.http.post(url, undefined, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`scan value:${value}`)),
      catchError(this.handleError<any>(`Error scan value:${value}`))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add('UserService: ' + message);
  }

  reset (filter: string = '') {
  }

}
