import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";
import {CognitoCallback, CognitoUtil, LoggedInCallback} from "./cognito.service";
import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import * as STS from "aws-sdk/clients/sts";
import {StartupService} from "../service-ui/startup.service";

@Injectable()
export class UserLoginService {

  constructor(/* public ddb: DynamoDBService, */ public cognitoUtil: CognitoUtil, public startupService: StartupService) {
  }

  authenticate(username: string, password: string, callback: CognitoCallback) {
    console.log("UserLoginService: starting the authentication");

    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    console.log("UserLoginService: Params set...Authenticating the user");
    const cognitoUser = new CognitoUser(userData);

    console.log("UserLoginService: config is " + AWS.config);
    const self = this;
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        callback.cognitoCallback(`User needs to set password.`, null);
      },
      onSuccess: (result) => {

        console.log("In authenticateUser onSuccess callback");

        const creds = self.cognitoUtil.buildCognitoCreds(result.getIdToken().getJwtToken());

        AWS.config.credentials = creds;

        // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
        // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
        // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
        // security credentials. The identity is then injected directly into the credentials object.
        // If the first SDK call we make wants to use our IdentityID, we have a
        // chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
        // very innocuous API call that forces this behavior.
        const clientParams: any = {};
        if (environment.sts_endpoint) {
          clientParams.endpoint = environment.sts_endpoint;
        }
        const sts = new STS(clientParams);
        sts.getCallerIdentity(function (err, data) {
          console.log("UserLoginService: Successfully set the AWS credentials");
          callback.cognitoCallback(null, result);
        });

        this.startup(cognitoUser, result.getIdToken().getJwtToken());
      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null);
      },
    });
  }

  startup(cognitoUser: CognitoUser, jwtToken: string) {
    const startupServiceLocal = this.startupService;
    cognitoUser.getUserAttributes(function (err, result2) {
      if (err) {
      } else {
        let sub = '';
        let email = '';
        let name = '';
        let phone_number = '';
        let role = '';
        let roleId = '';
        let subscriptionToken = '';
        for (let i = 0; i < result2.length; i++) {
          switch (result2[i].getName()) {
            case 'sub':
              sub = result2[i].getValue();
              break;
            case 'name':
              name = result2[i].getValue();
              break;
            case 'role':
              role = result2[i].getValue();
              break;
            case 'roleId':
              roleId = result2[i].getValue();
              break;
            case 'phone_number':
              phone_number = result2[i].getValue();
              break;
            case 'email':
              email = result2[i].getValue();
              break;
            case 'custom:subscriptionToken':
              subscriptionToken = result2[i].getValue();
          }
          console.log('attribute ' + result2[i].getName() + ' has value ' + result2[i].getValue());
        }
        startupServiceLocal.init(jwtToken, email, name, phone_number, role, roleId, sub, subscriptionToken);
      }
    });
  }

  forgotPassword(username: string, callback: CognitoCallback) {
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function () {

      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null);
      },
      inputVerificationCode() {
        callback.cognitoCallback(null, null);
      }
    });
  }

  confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
    const userData = {
      Username: email,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: function () {
        callback.cognitoCallback(null, null);
      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null);
      }
    });
  }

  logout() {
    console.log("UserLoginService: Logging out");
    // this.ddb.writeLogEntry("logout");
    const currentUser = this.cognitoUtil.getCurrentUser();
    if ( currentUser ) {
      currentUser.signOut();
    }
  }

  isAuthenticated(callback: LoggedInCallback) {
    if (callback == null) {
      throw(new Error("UserLoginService: Callback in isAuthenticated() cannot be null"));
    }

    const cognitoUser = this.cognitoUtil.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
          callback.isLoggedIn(err, false);
        } else {
          console.log("UserLoginService: Session is " + session.isValid());
            callback.isLoggedIn(err, session.isValid());
        }
      });
    } else {
      console.log("UserLoginService: can't retrieve the current user");
      callback.isLoggedIn("Can't retrieve the CurrentUser", false);
    }
  }

}
