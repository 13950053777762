import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {DraftCover} from "../../../../../model/DraftCover";
import {StartupService} from "../../../../service-ui/startup.service";
import {Draft} from "../../../../../model/Draft";


@Component({
  selector: 'app-project-draft-cover',
  templateUrl: './project-draft-cover.component.html',
  styleUrls: ['./project-draft-cover.component.css']
})
export class ProjectDraftCoverComponent implements OnInit {
  @ViewChild('draftCoverImage') draftCoverImage;
  @Input() draft: Draft;
  @Input() draftCover: DraftCover;

  displayScale = 1.0;

  Math = Math;


  constructor (public router: Router, public startupService: StartupService) {
  }

  ngOnInit() {
  }

}


