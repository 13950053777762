<ng-container *ngIf="startupService.project && themeService.fonts && themeService.fonts.length > 0">
  <mat-form-field>
    <mat-label i18n="@@Primary">Primary</mat-label>
    <mat-select i18n-placeholder="@@Primary" placeholder="Primary" [style.fontFamily]="fontSettings.primary" [(ngModel)]="fontSettings.primary" (selectionChange)="changedFont()">
      <mat-option *ngFor="let fontItem of themeService.fonts"  [class.selected]="fontItem.name === fontSettings.primary" [style.fontFamily]="fontItem.name" [value]="fontItem.name">
        {{fontItem.name  | languageString : 'compressSpaces' }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@Secondary">Secondary</mat-label>
    <mat-select i18n-placeholder="@@Secondary" placeholder="Secondary" [style.fontFamily]="fontSettings.secondary" [(ngModel)]="fontSettings.secondary" (selectionChange)="changedFont()">
      <mat-option *ngFor="let fontItem of themeService.fonts"  [class.selected]="fontItem.name === fontSettings.secondary" [style.fontFamily]="fontItem.name" [value]="fontItem.name">
        {{fontItem.name  | languageString : 'compressSpaces' }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@Tertiary">Tertiary</mat-label>
    <mat-select i18n-placeholder="@@Tertiary" placeholder="Tertiary" [style.fontFamily]="fontSettings.tertiary" [(ngModel)]="fontSettings.tertiary" (selectionChange)="changedFont()">
      <mat-option *ngFor="let fontItem of themeService.fonts"  [class.selected]="fontItem.name === fontSettings.tertiary" [style.fontFamily]="fontItem.name" [value]="fontItem.name">
        {{fontItem.name  | languageString : 'compressSpaces' }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n="@@Accent">Accent</mat-label>
    <mat-select i18n-placeholder="@@Accent" placeholder="Accent" [style.fontFamily]="fontSettings.accent" [(ngModel)]="fontSettings.accent" (selectionChange)="changedFont()">
      <mat-option *ngFor="let fontItem of themeService.fonts"  [class.selected]="fontItem.name === fontSettings.accent" [style.fontFamily]="fontItem.name" [value]="fontItem.name">
        {{fontItem.name  | languageString : 'compressSpaces' }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

<!--<div class="fontSettings">-->
  <!--<div class="labeledInput">-->
    <!--<label class="labeledInput">-->
      <!--<span class="labeledInput" i18n="@@Primary">Primary </span>-->
    <!--</label>-->
    <!--<select class="labeledInput" *ngIf="startupService.project && themeService.fonts && themeService.fonts.length > 0" [style.fontFamily]="fontSettings.primary" [(ngModel)]="fontSettings.primary" (change)="changedFont()">-->
      <!--<option  *ngFor="let fontItem of themeService.fonts"  [selected]="fontItem.name === fontSettings.primary" [style.fontFamily]="fontItem.name" [value]="fontItem.name">{{fontItem.name  | languageString : 'compressSpaces' }}</option>-->
    <!--</select>-->
  <!--</div>-->
  <!--<div class="labeledInput">-->
    <!--<label class="labeledInput">-->
      <!--<span class="labeledInput" i18n="@@Secondary">Secondary </span>-->
    <!--</label>-->
    <!--<select class="labeledInput" *ngIf="startupService.project && themeService.fonts && themeService.fonts.length > 0" [style.fontFamily]="fontSettings.secondary" [(ngModel)]="fontSettings.secondary" (change)="changedFont()">-->
      <!--<option  *ngFor="let fontItem of themeService.fonts"  [selected]="fontItem.name === fontSettings.secondary" [style.fontFamily]="fontItem.name" [value]="fontItem.name">{{fontItem.name  | languageString : 'compressSpaces' }}</option>-->
    <!--</select>-->
  <!--</div>-->
  <!--<div class="labeledInput">-->
    <!--<label class="labeledInput">-->
      <!--<span class="labeledInput" i18n="@@Tertiary">Tertiary </span>-->
    <!--</label>-->
    <!--<select class="labeledInput" *ngIf="startupService.project && themeService.fonts && themeService.fonts.length > 0" [style.fontFamily]="fontSettings.tertiary" [(ngModel)]="fontSettings.tertiary" (change)="changedFont()">-->
      <!--<option  *ngFor="let fontItem of themeService.fonts"  [selected]="fontItem.name === fontSettings.tertiary" [style.fontFamily]="fontItem.name" [value]="fontItem.name">{{fontItem.name  | languageString : 'compressSpaces' }}</option>-->
    <!--</select>-->
  <!--</div>-->
  <!--<div class="labeledInput">-->
    <!--<label class="labeledInput">-->
      <!--<span class="labeledInput" i18n="@@Accent">Accent </span>-->
    <!--</label>-->
    <!--<select class="labeledInput" *ngIf="startupService.project && themeService.fonts && themeService.fonts.length > 0" [style.fontFamily]="fontSettings.accent" [(ngModel)]="fontSettings.accent" (change)="changedFont()">-->
      <!--<option  *ngFor="let fontItem of themeService.fonts"  [selected]="fontItem.name === fontSettings.accent" [style.fontFamily]="fontItem.name" [value]="fontItem.name">{{fontItem.name  | languageString : 'compressSpaces' }}</option>-->
    <!--</select>-->
  <!--</div>-->
<!--</div>-->
