import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { FacilityInventoryProductEvent } from '../../model/FacilityInventoryProductEvent';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';import { catchError, map, tap } from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {DataService} from "./data-service";
import {FacilityInventoryProduct} from "../../model/FacilityInventoryProduct";

@Injectable()
export class FacilityInventoryProductEventService extends DataService {
  private objects: FacilityInventoryProductEvent[] = [];
  private objectsObservable = of( this.objects );
  public loadingState = 'INITIAL';


  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
    super(startupService);
    // this.startupService.setFacilityInventoryProductEventService(this);
  }

    reset (filter: string = '') {
    this.loadingState = 'INITIAL';
    while ( this.objects.length > 0) {
      this.objects.pop();
    }
  }

  // public getFacilityInventoryProductEvents(): Observable<FacilityInventoryProductEvent[]> {
  //   if ( this.loadingState === 'INITIAL') {
  //     this.loadingState = 'LOADING';
  //     const url = `${environment.apiBaseURL}facilityinventoryproductevent`;
  //     this.messageService.add('FacilityInventoryProductEventService: fetched facilityInventoryProductEvents');
  //     return this.http.get<FacilityInventoryProductEvent[]>(url, this.startupService.getHttpOptions())
  //       .pipe(
  //         map(facilityInventoryProductEvents => {
  //           while ( this.objects.length > 0) {
  //             this.objects.pop();
  //           }
  //           for ( const facilityInventoryProductEvent of facilityInventoryProductEvents ) {
  //             const o = new FacilityInventoryProductEvent(facilityInventoryProductEvent);
  //             this.objects.push(o);
  //           }
  //           // this.startupService.facilityInventoryProductEvents = ps;
  //           this.loadingState = 'LOADED';
  //           return this.objects;
  //         }), catchError(this.handleError('getFacilityInventoryProductEvents', []))
  //       );
  //   } else {
  //     return this.objectsObservable;
  //   }
  // }
  //
  //
  // getFacilityInventoryProductEvent(id: string): Observable<FacilityInventoryProductEvent> {
  //   const url = `${environment.apiBaseURL}facilityinventoryproductevent/${id}`;
  //   this.messageService.add(`FacilityInventoryProductEventService: fetched facilityInventoryProductEvent id=${id}`);
  //   return this.http.get<FacilityInventoryProductEvent>(url, this.startupService.getHttpOptions())
  //     .pipe(
  //       map(facilityInventoryProductEvent => {
  //         const p = new FacilityInventoryProductEvent(facilityInventoryProductEvent);
  //         return p;
  //       }),
  //       catchError(this.handleError<FacilityInventoryProductEvent>(`getFacilityInventoryProductEvent id=${id}`))
  //     );
  // }

  /** PUT: update the facilityInventoryProductEvent on the server */
  updateFacilityInventoryProductEvent (facilityInventoryProductEvent: FacilityInventoryProductEvent): Observable<FacilityInventoryProductEvent> {
    const url = `${environment.apiBaseURL}facilityinventoryproductevent/${facilityInventoryProductEvent.ID}`;
    this.messageService.add(`FacilityInventoryProductEventService: updated facilityInventoryProductEvent id=${facilityInventoryProductEvent.ID}`);
    return this.http.put(url, facilityInventoryProductEvent, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`updated facilityInventoryProductEvent id=${facilityInventoryProductEvent.ID}`)),
      catchError(this.handleError<any>('updateFacilityInventoryProductEvent'))
    );
  }

  addFacilityInventoryProductEvent (facilityInventoryProductEvent: FacilityInventoryProductEvent, facilityInventoryProduct: FacilityInventoryProduct): Observable<FacilityInventoryProductEvent> {
    // const facilityInventoryProduct = facilityInventoryProductEvent.facilityInventoryProduct;
    const url = `${environment.apiBaseURL}facilityinventoryproductevent`;
    return this.http.post<FacilityInventoryProductEvent>(url, facilityInventoryProductEvent, this.startupService.getHttpOptions()).pipe(
      tap(o => {
        this.log(`added facilityInventoryProductEvent w/ id=${facilityInventoryProductEvent.ID}`);
        this.objects.push(new FacilityInventoryProductEvent(o, facilityInventoryProduct));
      }),
      catchError(this.handleError<FacilityInventoryProductEvent>('addFacilityInventoryProductEvent'))
    );
  }

  /** DELETE: delete the facilityInventoryProductEvent from the server */
  deleteFacilityInventoryProductEvent (facilityInventoryProductEvent: FacilityInventoryProductEvent): Observable<FacilityInventoryProductEvent> {
    const url = `${environment.apiBaseURL}facilityinventoryproductevent/${facilityInventoryProductEvent.ID}`;

    return this.http.delete<FacilityInventoryProductEvent>(url, this.startupService.getHttpOptions()).pipe(
      tap(_ => this.log(`deleted facilityInventoryProductEvent id=${facilityInventoryProductEvent.ID}`)),
      catchError(this.handleError<FacilityInventoryProductEvent>('deleteFacilityInventoryProductEvent'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for facilityInventoryProductEvent consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add('FacilityInventoryProductEventService: ' + message);
  }

}
