import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MessageService } from '../service-ui/message.service';
import { HttpClient } from '@angular/common/http';
import {catchError, map, retry, tap} from 'rxjs/operators';
import { StartupService } from '../service-ui/startup.service';
import {environment} from "../../environments/environment";
import {Order} from "../../model/Order";

export class ValidationAddress {
  title: string;
  firstname: string;
  lastname: string;
  company: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  state: string;
  country: string;
  postalcode: string;

  constructor (obj) {
    this.title = '';
    this.firstname = '';
    this.lastname = '';
    this.company = '';
    this.address1 = '';
    this.address2 = '';
    this.address3 = '';
    this.address4 = '';
    this.city = '';
    this.state = '';
    this.country = 'USA';
    this.postalcode = '';

    if (obj) {
      for (const prop of Object.keys(obj)) {
        this[prop] = obj[prop];
      }
      if ( obj.country && obj.country.shortCode ) {
        this.country = obj.country.shortCode;
      }
    }
    if ( !this.country || this.country === 'US') {
      this.country = 'USA';
    }
  }
}

export class AddressValidationResult {
  public recommendedAddresses: ValidationAddress[];
  public validationResult: string;

  constructor (obj) {
    this.validationResult = '';
    this.recommendedAddresses = [];

    if (obj) {
      for (const prop of Object.keys(obj)) {
        this[prop] = obj[prop];
      }
      this.recommendedAddresses = [];
      if ( obj.recommendedAddresses ) {
        for ( const recommendedAddress of obj.recommendedAddresses ) {
          this.recommendedAddresses.push( new ValidationAddress( recommendedAddress ) );
        }
      }
    }
  }

  // public enum ValidationResult {
  // 	NO_COUNTRY_MATCH, //If we do not have an address validator for the requested country, then this is the validated result.
  // 	EXACT_MATCH, //There is not a single difference between the suggestion and the requested address.
  // 	NO_MEANINGFUL_SUGGESTIONS, //The difference between the suggestion and the requested address may have been because it was not in all caps (like UPS wants it to be for some reason)
  // 	HAVE_SUGGESTIONS, //There is one or more suggestions
  // 	ERROR //There was an error with the request
  // }
}

@Injectable()
export class AddressValidationService {
  private objects: Order[] = [];
  private objectsObservable = of( this.objects );
  public loadingState = 'INITIAL';


  constructor(private http: HttpClient, private messageService: MessageService, private startupService: StartupService) {
  }

  validate (address: any): Observable<AddressValidationResult> {
    const a = new ValidationAddress(address);
    const url = `${environment.apiBaseURL}validateaddress`;
    return this.http.post<AddressValidationResult>(url, address, this.startupService.getHttpOptions())
      .pipe( retry(3),
        map(o => {
          const x = new AddressValidationResult( o );
          return x;
        }),
      catchError(this.handleError<AddressValidationResult>('address validation'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loadingState = 'INITIAL';

      // TODO: send the error to remote logging infrastructure
      console.error(error, operation); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add('OrderService: ' + message);
  }

}
