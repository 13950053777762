import { Component, OnInit, VERSION, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {LanguagePipe} from "../../../pipe/language.pipe";
import {UserService} from "../../../service-data/user.service";
import {Organization} from "../../../../model/Organization";
import {OrganizationService} from "../../../service-data/organization.service";
import {FacilityService} from "../../../service-data/facility.service";

@Component({
  selector: 'app-add-facility',
  templateUrl: './add-facility.component.html',
  styleUrls: ['./add-facility.component.css']
})
export class AddFacilityComponent implements OnInit {
  public breakpoint: number; // Breakpoint observer code
  public org_ID: number;
  public addForm: FormGroup;
  wasFormChanged = false;
  organizations: Organization[];

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog, public userService: UserService, public organizationService: OrganizationService, public facilityService: FacilityService) {
  }

  public ngOnInit(): void {
    this.organizationService.getItems()
      .subscribe(organizations => {
        this.organizations = organizations;
      });

    this.addForm = this.formBuilder.group({
      org_ID: [this.org_ID, [Validators.required, Validators.pattern('[0-9]+([0-9]+)*')]]
      // email: [null, [Validators.required, Validators.email]],
    });
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
  }

  public onAdd(): void {
    this.markAsDirty(this.addForm);
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

}
