<div style="width: 100%">
  <div style="padding-top: 10px; margin-left: auto; margin-right: auto;">
<!--    <mat-grid-list cols="1" >-->
<!--      <mat-grid-tile i18n-class="@@dir">-->
        <mat-card appearance="outlined" class="formCard">
          <h1 i18n="@@Select Language">Select Language</h1>
          <img src="/logo.png" class="intro-logo">
          <mat-card-content>
            <form style="padding-top: 85px; padding-bottom: 85px;">
              <div class="localeButtons" *ngIf="environment.showLanguagesOptions">
                <a mat-raised-button color="primary" i18n-href="@@enURL" href="/" i18n="@@English">English</a>
                <a mat-raised-button color="primary" i18n-href="@@frURL" href="/" i18n="@@French">français</a>
                <a mat-raised-button color="primary" i18n-href="@@esURL" href="/" i18n="@@Spanish">Español</a>
                <a mat-raised-button color="primary" i18n-href="@@deURL" href="/" i18n="@@German">Deutsche</a>
                <a mat-raised-button color="primary" i18n-href="@@arURL" href="/" i18n="@@Arabic" style="text-align:right">عربى</a>
                <a mat-raised-button color="primary"  i18n-href="@@zhURL" href="/" i18n="@@Chinese">中文</a>
                <a mat-raised-button color="primary" i18n-href="@@amURL" href="/" i18n="@@Amharic">አማርኛ</a>
                <a mat-raised-button color="primary" i18n-href="@@koURL" href="/" i18n="@@Korean">한국어</a>
                <a mat-raised-button color="primary" i18n-href="@@roURL" href="/" i18n="@@Romanian">Română</a>
                <a mat-raised-button color="primary" i18n-href="@@ruURL" href="/" i18n="@@Russian">русский</a>
              </div>
            </form>
          </mat-card-content>
          <mat-card-actions class="secondaryAction">
            <button *ngIf="environment.allowUsersToSignThemSelvesUp" mat-button color="primary" [routerLink]="['/home/register']"><ng-container i18n="@@Register">Register</ng-container></button>
            <button mat-button color="primary" [routerLink]="['/home/login']"><ng-container i18n="@@Login">Login</ng-container></button>
          </mat-card-actions>
        </mat-card>
<!--      </mat-grid-tile>-->
<!--    </mat-grid-list>-->
  </div>
</div>
