import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import {MatSidenav} from "@angular/material/sidenav";
import {MediaMatcher} from "@angular/cdk/layout";
import {Draft} from "../../../model/Draft";
import {DraftService} from "../../service-data/draft.service";
import {ResourceService} from "../../service-data/resource.service";
import {Subject} from "rxjs";
import {DataObject} from "../../../model/DataObject";
import {takeUntil} from "rxjs/operators";
import {ProjectService} from "../../service-data/project.service";
import {Project} from "../../../model/Project";

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})

export class ProjectsComponent implements OnInit, OnDestroy, LoggedInCallback {
  @ViewChild('mainSideNav', { static: true }) mainSideNav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  goose: number = 1;
  items: Project[] = [];
  // selectedProject: Project;
  // projects: Project[];
  selectedItem: Project;
  // selectedDraft: Draft;
  // drafts: Draft[];

  role: string;
  showLanguagesMenu = false;
  showFinalized: boolean = true;
  showEditable: boolean =  true;
  showGrid: boolean = true;
  showSplitView: boolean = false;
  editingDetails: string = '';
  displayCover: boolean = true;

  resourceURLS = {};
  draftURLS = {};

  constructor(public router: Router, public startupService: StartupService, private projectService: ProjectService, private draftService: DraftService, public resourceService: ResourceService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    console.log("SecureHomeComponent: constructor");
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    console.log("AppComponent: Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log("Not logged in in Projects");
      this.startupService.logout();
    } else {
      this.resourceURLS = {};
      this.draftURLS = {};
      this.getData();
    }
  }

  // onSelectProject(project: Project): void {
  //   this.selectedDraft = undefined;
  //   this.selectedProject = project;
  //   this.editingDetails = true;
  // }
  // onSelectDraft(draft: Draft): void {
  //   this.selectedProject = undefined;
  //   this.selectedDraft = draft;
  //   this.editingDetails = true;
  // }

  createNewProject  (draft: Draft) {
    console.log('Clicked Create New Project');
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

  addUpdateProject(project: Project) {
    try {
      if ( this.matchProject(project)) {
        let found = false;
        for (const item of this.items ) {
          if ( item.getId() === project.getId() ) {
            found = true;
          }
        }
        if ( !found ) {
          this.items.push(project);
        }
      }
    } catch ( e ) {
      console.log(`Error in ProjectsComponent addUpdate ${project.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
    }
  }

  deleteProject(project: Project) {
  }

  matchProject(project: Project): boolean {
    if ( project ) {
      return true;
    }
    return false;
  }

  // addUpdateDraft(draft: Draft) {
  //   try {
  //     if ( this.matchDraft(draft)) {
  //       let found = false;
  //       for (const item of this.items ) {
  //         if ( item['type'] === 'DRAFT' && item.getId() === draft.getId() ) {
  //           found = true;
  //         }
  //       }
  //       if ( !found ) {
  //         draft['type'] = 'DRAFT';
  //         this.items.push(draft);
  //       }
  //     }
  //   } catch ( e ) {
  //     console.log(`Error in BooksComponent addUpdate ${draft.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
  //   }
  // }
  //
  // deleteDraft(draft: Draft) {
  // }
  //
  // matchDraft(draft: Draft): boolean {
  //   if ( draft ) {
  //     return true;
  //   }
  //   return false;
  // }
  //
  // addUpdateProject(project: Project) {
  //   try {
  //     if ( this.matchProject(project)) {
  //       let found = false;
  //       for (const item of this.items ) {
  //         if ( item['type'] === 'PRODUCT' && item.getId() === project.getId() ) {
  //           found = true;
  //         }
  //       }
  //       if ( !found ) {
  //         project['type'] = 'PRODUCT';
  //         this.items.push(project);
  //       }
  //     }
  //   } catch ( e ) {
  //     console.log(`Error in BooksComponent addUpdate ${project.getId()} ${e.message} ${this.startupService.selectedRole}:${this.startupService.selectedRoleId}`);
  //   }
  // }
  //
  // deleteProject(project: Project) {
  // }
  //
  // matchProject(project: Project): boolean {
  //   if ( project ) {
  //     return true;
  //   }
  //   return false;
  // }

  contains(object: DataObject, array: DataObject[] ): boolean {
    for ( const o of array ) {
      if ( o.getId() === object.getId() ) {
        return true;
      }
    }
    return false;
  }
  getData(reset: boolean = true): void {
    this.goose++;
    this.startupService.touchLastUserActivity();
    this.items = [];

    this.projectService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdateProject(o);
    });
    this.projectService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.addUpdateProject(o);
    });
    this.projectService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
      this.deleteProject(o);
    });
    this.projectService.objects.forEach( o => {
      this.addUpdateProject(o);
    });

    // this.draftService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
    //   this.addUpdateDraft(o);
    // });
    // this.draftService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
    //   this.addUpdateDraft(o);
    // });
    // this.draftService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
    //   this.deleteDraft(o);
    // });
    // this.draftService.objects.forEach( o => {
    //   this.addUpdateDraft(o);
    // });
    //
    // this.projectService.getAddNotifier(false).pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
    //   this.addUpdateProject(o);
    // });
    // this.projectService.getUpdateNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
    //   this.addUpdateProject(o);
    // });
    // this.projectService.getDeleteNotifier().pipe(takeUntil(this.onDestroySubject)).subscribe( o => {
    //   this.deleteProject(o);
    // });
    // this.projectService.objects.forEach( o => {
    //   this.addUpdateProject(o);
    // });
  }

  // public download(resourceId: number, color: string, dpi: number, page: number) {
  //
  //   console.log(`Download Resource Image: ${resourceId}`);
  //   this.resourceService.getImage(resourceId, 'GREY', 360, 1).subscribe( data => {
  //     const downloadFileName = `/${resourceId}/${color}/${dpi}/${page}.png`;
  //     const blob = new Blob([data.toString()], {type: 'text/html'});
  //     const e = document.createEvent('MouseEvents');
  //     const a = document.createElement('a');
  //     a.download = downloadFileName;
  //     a.href = window.URL.createObjectURL(blob);
  //     a.dataset.downloadurl = ['text/html', a.download, a.href].join(':');
  //     e.initEvent('click', true, false);
  //     a.dispatchEvent(e);
  //   });
  // }

  editingDetailsChanged(newEditingDetails: string) {
    this.editingDetails = newEditingDetails;
  }

  selectedItemChanged(project: Project) {
    this.selectedItem = project;
  }
}
