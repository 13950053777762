import {Component, LOCALE_ID, OnInit, Inject, ViewChild, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { Project } from '../../../model/editor/Project';
import { LanguagePipe } from '../../pipe/language.pipe';
import { EditorProjectService } from '../../service-data/editor/editor-project.service';
import {ActivatedRoute, Router} from "@angular/router";
import {StartupService} from "../../service-ui/startup.service";
import {LoggedInCallback} from "../../service-auth/cognito.service";
import {MediaMatcher} from '@angular/cdk/layout';
import {ThemeService} from "../../service-data/theme.service";
import {CoverTheme} from "../../../model/editor/CoverTheme";
import {ColorPalette} from "../../../model/color/ColorPalette";
import {ColorPaletteGroup} from "../../../model/color/ColorPaletteGroup";
import {CoverThemeItemSetting} from "../../../model/editor/CoverThemeItemSetting";
import {Subject} from "rxjs";

declare const chroma: any;

@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.css']
})
export class CoverComponent implements OnDestroy, OnInit, LoggedInCallback {
  // @ViewChild('mainSideNav') mainSideNav: MatSidenav;
  @ViewChild('standard') standard;
  @ViewChild('toc') toc;
  @ViewChild('title') title;
  @ViewChild('copyright') copyright;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  onDestroySubject: Subject<boolean> = new Subject();

  project: Project;
  typeOfChapterToAdd: string = 'standard';
  locale: string;

  activeSideArea: string = '';
  showColorPaletteEditor: boolean = false;
  // activeSideArea: string = 'objects';
  leftWidth: string = 'leftNormalWidth';
  rightWidth: string = 'rightNormalWidth';
  sizeWidth: 0;

  trimSizes: Array<Object> = [
    {value: '5.00inX7.00in', name: '5.00inX7.00in', height: '7.00in', width: '5.00in' },
    {value: '5.50inX8.50in', name: '5.50inX8.50in', height: '8.50in', width: '5.50in' },
    {value: '6.00inX9.00in', name: '6.00inX9.00in', height: '9.00in', width: '6.00in' }
  ];

  toggleAddAudioFiles: boolean = false;

  constructor(public router: Router, public startupService: StartupService, private projectService: EditorProjectService, private themeService: ThemeService, private route: ActivatedRoute, @Inject(LOCALE_ID) localeId: string, public changeDetectorRef: ChangeDetectorRef, public media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.locale = localeId;
  }

  ngOnInit() {
    console.log("Project: Checking if the user is already authenticated");
    this.startupService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/home/login']);
    } else {
      this.getProject();
    }
  }

  getProject() {
    // const projectId = this.route.snapshot.paramMap.get('ID');
    // this.project = this.projectService.retreiveLocalProject(projectId);
    this.project = this.startupService.project;
    if ( this.project ) {
      this.startupService.selectedCover = this.project.cover;
      this.startupService.setProject(this.project);

    } else {
        this.router.navigate(['/home/login']);
    }
  }

  getSideClasses(): string {
    let classes = '';
    if ( this.startupService.showStructure ) {
      classes += this.leftWidth;
    } else {
      classes += 'leftHidden';
    }
    if ( this.startupService.showCoverTools || this.startupService.showInteriorTools) {
      classes += ' ' + this.rightWidth;
    } else {
      classes += ' rightHidden';
    }
    return classes;
  }

  onResize(event: any) {
    console.log("onresize " + event);
    const w = event.target.innerWidth;
    this.sizeWidth = w;
  }

  setTrimSize(trimSize: any) {
    const project: Project = <Project> this.startupService.getProject();
    project.trimSize = trimSize.value;
    project.width = trimSize.width;
    project.height = trimSize.height;
    this.startupService.trimSizeChange++;
    this.saveProject();
  }

  setCoverTheme(coverTheme: CoverTheme) {
    this.startupService.project.cover.clearImages();
    this.startupService.project.cover.coverTheme = new CoverTheme(coverTheme);
    this.startupService.project.cover.copyCoverThemeColorPalette();
    this.startupService.project.cover.copyCoverThemeFontSettings();
    this.startupService.project.cover.verifyImages();
    this.startupService.coverThemeChange++;
    this.saveProject();
  }

  saveProject() {
    this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroySubject.next(true);
    this.onDestroySubject.unsubscribe();
  }

    getOppositeOfLightness(value: number): number {
    let oppositeOfLightness = value - 0.5;
    if ( oppositeOfLightness < 0 ) {
      oppositeOfLightness = value + 0.5;
    }
    return oppositeOfLightness;
  }

  // TODO: use chroma.contrast to create a proper contract for primary and secondary
  generateMonochromatic(colorPalette: ColorPalette) {
    if ( chroma ) {
      colorPalette.name = colorPalette.primary.normal.name + " Complementary"; // + LanguagePipe.transform( 'Split Compementary' );;
      const primaryColor = chroma(colorPalette.primary.normal.color );
      colorPalette.secondary.normal.color = primaryColor.darken(3).hex();
      colorPalette.tertiary.normal.color = primaryColor.brighten(3).hex();
      colorPalette.quaternary.normal.color = primaryColor.darken(2).hex();
      colorPalette.quinary.normal.color = primaryColor.darken(4).hex();
      colorPalette.senary.normal.color = primaryColor.brighten(4).hex();

      this.setDarkerAndLighter(colorPalette);
      this.startupService.colorPaletteChange++;
    }
  }


  generateComplementary(colorPalette: ColorPalette) {
    if ( chroma ) {
      colorPalette.name = colorPalette.primary.normal.name + " Complementary"; // + LanguagePipe.transform( 'Split Compementary' );;
      const primaryColorHSV = chroma(colorPalette.primary.normal.color ).hsv();
      colorPalette.secondary.normal.color = chroma.hsv((primaryColorHSV[0] + 180) % 360, primaryColorHSV[1], primaryColorHSV[2]).hex();
      colorPalette.tertiary.normal.color = chroma.hsv(primaryColorHSV[0], primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.quaternary.normal.color = chroma.hsv((primaryColorHSV[0] + 180) % 360, primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.quinary.normal.color = chroma.hsv(primaryColorHSV[0], primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.senary.normal.color = chroma.hsv((primaryColorHSV[0] + 180) % 360, primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();

      this.setDarkerAndLighter(colorPalette);
      this.startupService.colorPaletteChange++;
    }
  }

  generateSplitComplementary(colorPalette: ColorPalette) {
    if ( chroma ) {
      colorPalette.primary.normal.name = '';
      colorPalette.name = colorPalette.name + " Split Complementary"; // + LanguagePipe.transform( 'Split Compementary' );;
      colorPalette.type = 'Complementary';
      const primaryColorHSV = chroma(colorPalette.primary.normal.color ).hsv();
      colorPalette.secondary.normal.color = chroma.hsv((primaryColorHSV[0] + 170) % 360, primaryColorHSV[1], primaryColorHSV[2]).hex();
      colorPalette.tertiary.normal.color = chroma.hsv((primaryColorHSV[0] + 170) % 360, this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.quaternary.normal.color = chroma.hsv(primaryColorHSV[0], primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.quinary.normal.color = chroma.hsv(primaryColorHSV[0], primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.senary.normal.color = chroma.hsv((primaryColorHSV[0] + 170) % 360, primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();

      this.setDarkerAndLighter(colorPalette);
      this.startupService.colorPaletteChange++;
    }
  }

  generateTriad(colorPalette: ColorPalette) {
    if ( chroma ) {
      colorPalette.primary.normal.name = '';
      colorPalette.name = colorPalette.name + " Triad"; // + LanguagePipe.transform( 'Triad' );
      colorPalette.type = 'Triad';
      const primaryColorHSV = chroma(colorPalette.primary.normal.color ).hsv();
      colorPalette.secondary.normal.color = chroma.hsv((primaryColorHSV[0] + 155) % 360, primaryColorHSV[1], primaryColorHSV[2]).hex();
      colorPalette.tertiary.normal.color = chroma.hsv((primaryColorHSV[0] - 155) % 360, primaryColorHSV[1], primaryColorHSV[2]).hex();
      colorPalette.quaternary.normal.color = chroma.hsv((primaryColorHSV[0] + 180) % 360, primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.quinary.normal.color = chroma.hsv(primaryColorHSV[0], primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.senary.normal.color = chroma.hsv((primaryColorHSV[0] + 155) % 360, primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();

      this.setDarkerAndLighter(colorPalette);
      this.startupService.colorPaletteChange++;
    }
  }

  generateRectangle(colorPalette: ColorPalette) {
    if ( chroma ) {
      colorPalette.primary.normal.name = '';
      colorPalette.name = colorPalette.primary.normal.name + " Rectangle"; // + LanguagePipe.transform( 'Rectangle' );
      colorPalette.type = 'Rectangle';
      const primaryColorHSV = chroma(colorPalette.primary.normal.color ).hsv();
      colorPalette.secondary.normal.color = chroma.hsv((primaryColorHSV[0] + 60) % 360, primaryColorHSV[1], primaryColorHSV[2]).hex();
      colorPalette.tertiary.normal.color = chroma.hsv((primaryColorHSV[0] - 120) % 360, primaryColorHSV[1], primaryColorHSV[2]).hex();
      colorPalette.quaternary.normal.color = chroma.hsv((primaryColorHSV[0] + 120) % 360, primaryColorHSV[1], primaryColorHSV[2]).hex();
      colorPalette.quinary.normal.color = chroma.hsv(primaryColorHSV[0], primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.senary.normal.color = chroma.hsv((primaryColorHSV[0] + 60) % 360, primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.quinary.normal.color = chroma.hsv(primaryColorHSV[0], primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.senary.normal.color = chroma.hsv((primaryColorHSV[0] + 60) % 360, primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();

      this.setDarkerAndLighter(colorPalette);
      this.startupService.colorPaletteChange++;
    }
  }

  generateSquare(colorPalette: ColorPalette) {
    if ( chroma ) {
      colorPalette.name = colorPalette.primary.normal.name + " Square"; // + LanguagePipe.transform( 'Square' );
      colorPalette.type = 'Square';
      const primaryColorHSV = chroma(colorPalette.primary.normal.color ).hsv();
      colorPalette.secondary.normal.color = chroma.hsv((primaryColorHSV[0] + 90) % 360, primaryColorHSV[1], primaryColorHSV[2]).hex();
      colorPalette.tertiary.normal.color = chroma.hsv((primaryColorHSV[0] + 180) % 360, primaryColorHSV[1], primaryColorHSV[2]).hex();
      colorPalette.quaternary.normal.color = chroma.hsv((primaryColorHSV[0] + 270) % 360, primaryColorHSV[1], primaryColorHSV[2]).hex();
      colorPalette.quinary.normal.color = chroma.hsv(primaryColorHSV[0], primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();
      colorPalette.senary.normal.color = chroma.hsv((primaryColorHSV[0] + 180) % 360, primaryColorHSV[1], this.getOppositeOfLightness(primaryColorHSV[2])).hex();

      this.setDarkerAndLighter(colorPalette);
      this.startupService.colorPaletteChange++;
    }
  }

  setDarkerAndLighter(colorPalette: ColorPalette) {
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.primary);
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.secondary);
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.tertiary);
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.quaternary);
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.quinary);
    this.setDarkerAndLighterColorPaletteGroup(colorPalette.senary);
  }

  setDarkerAndLighterColorPaletteGroup(colorPaletteGroup: ColorPaletteGroup) {
    if ( chroma ) {
      colorPaletteGroup.dark.color = chroma( colorPaletteGroup.normal.color ).darken().hex();
      colorPaletteGroup.light.color = chroma( colorPaletteGroup.normal.color ).brighten().hex();
      colorPaletteGroup.normal.name = colorPaletteGroup.normal.color;
      colorPaletteGroup.dark.name = colorPaletteGroup.dark.color;
      colorPaletteGroup.light.name = colorPaletteGroup.light.color;
    }
  }

  moveCoverThemeItemSetting(coverThemeItemSetting: CoverThemeItemSetting, amount: number) {
    const index = this.project.cover.coverTheme.objects.indexOf(coverThemeItemSetting);
    if ( index > -1 ) {
      this.project.cover.coverTheme.objects.splice(index, 1);
      this.project.cover.coverTheme.objects.splice(index + amount, 0, coverThemeItemSetting);
      this.projectService.saveProjectAndCanvas(this.startupService.canvasManager, this.startupService.project, this.startupService.canvasManager.canvas);
      // this.projectService.saveProjectImmediately(this.project).then( proj => {
      //   console.log('Reordered coverThemeItemSetting');
      // });
    }
  }

  coverThemeItemIndex(coverThemeItemSetting): number {
    const index = this.project.cover.coverTheme.objects.indexOf(coverThemeItemSetting);
    return index;
  }
}

