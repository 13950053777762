import {TableFilter} from "./TableFilter";


export class StringRangeTableFilter extends TableFilter {
  public startValue: string = '';
  public endValue: string = '';

  constructor (obj: any) {
    super(obj);
    if ( obj.startValue ) {
      this.startValue = String(obj.startValue);
    }
    if ( obj.endValue ) {
      this.endValue = String(obj.endValue);
    }
  }

  public match(data: any, filter: string): boolean {
    const value = this.getStringValue(data);
    if ( this.startValue <= value && this.endValue >= value ) {
      return true;
    }
    return false;
  }
}
