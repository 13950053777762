import { Project } from '../../model/editor/Project';
import {HttpHeaders} from "@angular/common/http";
import {CognitoUtil, LoggedInCallback} from "../service-auth/cognito.service";
import {environment} from "../../environments/environment";
import {CognitoUserPool} from "amazon-cognito-identity-js";
import {UserLoginService} from "../service-auth/user-login.service";
import {NotificationsService} from "../service-data/notifications.service";
import {Router} from "@angular/router";
import {AlertService} from "./alert.service";
import {CoverTheme} from "../../model/editor/CoverTheme";
import {InteriorTheme} from "../../model/editor/interior-theme/InteriorTheme";
import {FontItem} from "../../model/font/FontItem";
import {Cart} from "../../model/cart/Cart";
import {Cover} from "../../model/editor/Cover";
import {Chapter} from "../../model/editor/Chapter";
import {Subscription} from "../../model/Subscription";
import {FileUploader} from "../file-upload/file-uploader.class";
import {User} from "../../model/User";
import * as JWT from 'jwt-decode';
import {CanvasManager} from "../../canvas-manager/CanvasManager";
import {ColorPalette} from "../../model/color/ColorPalette";
import {Product} from "../../model/Product";
import {Draft} from "../../model/Draft";
import {Book} from "../../model/Book";
import { Injectable } from "@angular/core";
import {OrganizationService} from "../service-data/organization.service";
import {DataService} from "../service-data/data-service";
import {Organization} from "../../model/Organization";
import {Subject} from "rxjs";
import {PackageAndShippingComputationService} from "../service-data/package-and-shipping-computation.service";

@Injectable()
export class StartupService implements LoggedInCallback, RoleChangeNotifier {

  public static colorAliases = {
    'errorBackground': 'darkred',
    'errorAndSelectedBackground': 'darkorange',
    'selectedBackground': 'goldenrod',
    'background1': 'darkred',
    'background2': 'darkgreen',
    'background3': 'darkblue',
    'background4': 'darkgrey',
    'background5': 'darkorange',
    'background6': 'darkgreen',
    'background7': 'blue',
    'background8': 'brown',
    'background9': 'orange',
    'highPriorityColor': 'pink',
    'normalPriorityColor' : 'darkyellow',
    'normalColor' : 'white',
    '' : ''
  };

  onDestroySubject: Subject<boolean> = new Subject();

  public colorAliases = StartupService.colorAliases;
  // Project focus related properties
  selectedChapter: Chapter;
  selectedCover: Cover;
  showStructure: boolean = true;
  showCoverTools: boolean = true;
  showInteriorTools: boolean = true;
  showUpload: boolean = false;
  showUploadCheckout: boolean = false;
  showUpdateRecurring: boolean = false;

  floatingToolStripLeft: boolean = false;
  showInactiveCoverThemeItems: boolean = false;
  admin: boolean = false;
  inventory: boolean = false;

  // productService: ProductService = undefined;
  // bookService: BookService = undefined;
  // draftService: DraftService = undefined;
  // projectService: ProjectService = undefined;
  // subscriptionService: SubscriptionService = undefined;
  uploader: FileUploader;

  colorPaletteChange: number = 0;
  fontSettingsChange: number = 0;
  coverThemeChange: number = 0;
  trimSizeChange: number = 0;
  registrationRetries = 0;
  public coverThemes: CoverTheme[];
  public interiorThemes: InteriorTheme[];
  public fonts: FontItem[];
  public colorPalettes: ColorPalette[];
  public project: Project;
  public product: Product;
  public products: Product[] = [];
  public drafts: Draft[] = [];
  public books: Book[] = [];
  public editorDocument: Document;
  selectedElements: any[];

  public dir: string = 'ltr';

  public canvasManager: CanvasManager = undefined;
  public cart: Cart = undefined;
  private jwtToken: string;
  private email: string;
  private name: string;
  private phone_number: string;
  private role: string;
  private roleId: string;
  private userId: string;
  private user: User;
  private groupRoles: any = {};
  public roleOptions: any = [];
  public selectedRole: string;
  public selectedRoleId: string;
  public selectedRoleName: string;


  public limitToOnlyCSVAndOrderHistory = false;
  public showLanguagesOptions = true;

  private subscription: Subscription;
  private subscriptionToken: string;

  public showAudioCertantity = true;
  public useBookWidthInEditor = true;
  public showCurrentlySelectedElementInteriorStyles = true;

  private lastUserActivityTimestamp = Date.now();
  private sessionRefreshTimstamp = Date.now();

  public ready: string = 'NOT_READY';

  private alertService: AlertService;
  private router: Router;
  private userLoginService: UserLoginService;
  private notificationsService: NotificationsService;
  private packageAndShippingComputationService: PackageAndShippingComputationService;
  private organizationService: OrganizationService;

  private FREQUENCY_TO_CHECK_REFRESH = 3; // Three minutes
  private MINUTES_OF_INACTIVITY_TO_WARN = 10 * this.FREQUENCY_TO_CHECK_REFRESH;
  private MINUTES_OF_INACTIVITY_TO_LOGOUT = this.MINUTES_OF_INACTIVITY_TO_WARN + 10;
  private FREQUENCY_TO_REFRESH_SESSION = 15; // Get a refreshed session about every 15 minutes
  private dataServices: DataService[] = [];
  private roleChangeListeners: RoleChangeListener[] = [];
  private organization: Organization;
  private dataLoadingStatus: {};
  public isDataLoaded: boolean = false;

  constructor() {
    if ( environment.limitToOnlyCSVAndOrderHistory ) {
      this.limitToOnlyCSVAndOrderHistory = true;
    } else {
      // TODO: Put this back
      this.limitToOnlyCSVAndOrderHistory = true;
    }
    this.lastUserActivityTimestamp = Date.now();
    this.sessionRefreshTimstamp = Date.now();
    const refreshIntervalTimerId = setInterval(() => {
      if ( this.notificationsService ) {
        this.notificationsService.sendKeepAlive();
      }
      const minutesFromSessionRefresh = ( Date.now() - this.sessionRefreshTimstamp ) / 60000;
      let minutesFromLastUserActivity = ( Date.now() - this.lastUserActivityTimestamp ) / 60000;
      minutesFromLastUserActivity = 0; // Never time out
      if ( minutesFromLastUserActivity >= this.MINUTES_OF_INACTIVITY_TO_WARN && minutesFromLastUserActivity < this.MINUTES_OF_INACTIVITY_TO_LOGOUT) {
        if ( this.alertService ) {
          this.alertService.warn("You are about to be logged due to inactivity.  Clear this alert to continue.", false);
        }
      }

      if ( minutesFromLastUserActivity >= this.MINUTES_OF_INACTIVITY_TO_LOGOUT ) {
        this.logout();
        // clearInterval(refreshIntervalTimerId);
      }
      if (minutesFromSessionRefresh > this.FREQUENCY_TO_REFRESH_SESSION) {
        if ( minutesFromLastUserActivity < 60 ) {
          this.checkSession(this);
        }
      }
    }, this.FREQUENCY_TO_CHECK_REFRESH * 60000); // Check every five minutes
  }

  addRoleChangeListener(roleChangeListener: RoleChangeListener): void {
    this.roleChangeListeners.push(roleChangeListener);
  }

  logout() {
    this.ready = 'NOT_READY';
    this.admin = false;
    this.inventory = false;
    this.userLoginService.logout();
    this.user = undefined;
    this.subscription = undefined;
    this.coverThemes = undefined;
    this.interiorThemes = undefined;
    this.fonts = undefined;
    this.project = undefined;
    this.editorDocument = undefined;
    this.cart = undefined;
    this.jwtToken = undefined;
    this.email = undefined;
    this.name = undefined;
    this.role = undefined;
    this.roleId = undefined;
    this.userId = undefined;
    this.groupRoles = {};
    this.roleOptions = [];
    this.subscription = undefined;
    this.subscriptionToken = undefined;
    this.registrationRetries = 0;

    this.notificationsService.stopNotification();
    this.alertService.clear();

    try {
      this.onDestroySubject.next(true);
      this.onDestroySubject.unsubscribe();
    } catch ( e ) {
      console.log(`Problem destroying subject in StartupService.logout()`);
    }


    // Added to deal with user being logged in every other time the browser loads
    const currentUser = this.getCurrentUser();
    if ( currentUser ) {
      currentUser.signOut();
    }
    for ( const dataService of this.dataServices ) {
      dataService.reset();
    }

    // This did not work
    // const cognitoIdentityCredentials = new AWS.CognitoIdentityCredentials({});
    // cognitoIdentityCredentials.clearCachedId();
    // AWS.config.credentials = cognitoIdentityCredentials;
    //
    this.selectRole('', '', '');
    if ( this.router ) {
      this.router.navigate(['/home/login']);
    }
  }

  setJwtToken(token: string) {
    this.jwtToken = token;
    const jwtDecoded = JWT(this.jwtToken);
    this.groupRoles = {};
    this.roleOptions = [];
    if ( jwtDecoded && jwtDecoded['cognito:groups']) {
      for ( const group of jwtDecoded['cognito:groups'] ) {
        const parts = group.split('-');
        if ( parts.length === 2 ) {
          this.groupRoles[parts[0]] = parts[1];
          this.roleOptions.push({ role: parts[0], roleId: parts[1]});
        }
      }
      console.log('Setting Admin');
    }
    if ( jwtDecoded && jwtDecoded['custom:role'] &&  jwtDecoded['custom:roleId']) {
      // Check to see if a role is already selected -- if it is see if the role exists in the jwtDecoded data
      if ( this.selectedRole && this.selectedRoleId && jwtDecoded['cognito:groups'] && jwtDecoded['cognito:groups'].findIndex(item => item === this.selectedRole + '-' + this.selectedRoleId) !== -1 ) {
        console.log(`New JWT Session but keeping the same role ${this.selectedRole}:${this.selectedRoleId}`);
      } else {
        console.log(`New JWT Session -- Setting to the default user because the selected role is not in the JWT`);
        this.selectRole(jwtDecoded['custom:role'], jwtDecoded['custom:roleId'], '');
      }
    }
    this.getCurrentOrganization();
    if ( jwtDecoded && jwtDecoded['given_name'] && jwtDecoded['custom:admin'] && jwtDecoded['custom:admin'] === jwtDecoded['given_name'] ) {
      this.admin = true;
    } else {
      this.admin = false;
    }
    if ( jwtDecoded && jwtDecoded['given_name'] && jwtDecoded['custom:inventory'] && jwtDecoded['custom:inventory'] === jwtDecoded['given_name'] ) {
      this.inventory = true;
    } else {
      this.inventory = false;
    }
  }

  doesSelectedRoleNeedNotification(): boolean {
    if ( this.selectedRole && ( this.selectedRole === 'facility' || this.selectedRole === 'network' ) ) {
      return true;
    }
    return false;
  }
  hasRoleOptions(): boolean {
    return this.groupRoles;
  }

  getRoleIdFromRole(role: string): number {
    if ( this.groupRoles[role] ) {
      return this.groupRoles[role];
    }
    return 0;
  }

  selectRole(role: string, roleId: string, roleName: string) {
    if ( role !== this.selectedRole || roleId !== this.selectedRoleId || roleName !== this.selectedRoleName ) {
      this.selectedRole = role;
      this.selectedRoleId = roleId; // this.groupRoles[role];
      this.selectedRoleName = roleName;
      this.dataLoadingStatus = {};
      this.cart = undefined;

      this.onDestroySubject.next(true);
      this.onDestroySubject.unsubscribe();

      this.resetCurrentOrganization();
      for ( const dataService of this.dataServices ) {
        dataService.reset();
      }
      for ( const roleChangeListener of this.roleChangeListeners ) {
        try {
          roleChangeListener.roleChanged(String(this.selectedRole), this.selectedRoleId);
        } catch ( error ) {
          console.log("Error notifying a roleChangeListener " + error);
        }
      }
      if ( this.notificationsService ) {
        this.notificationsService.stopNotification();
        this.notificationsService.startNotification();
      }
      switch (this.selectedRoleName) {
        case 'facility': {
          if ( this.router ) {
            this.router.navigate(['/secure/startup']);
          }
          break;
        }
        case 'network': {
          if ( this.router ) {
            this.router.navigate(['/secure/startup']);
          }
          break;
        }
        case 'organization': {
          if ( this.router ) {
            this.router.navigate(['/secure/startup']);
          }
          break;
        }
        case 'servicedProvider': {
          if ( this.router ) {
            this.router.navigate(['/secure/startup']);
          }
          break;
        }
      }
    }
  }

  setAlertService(alertService: AlertService, router: Router, userLoginService: UserLoginService) {
    this.userLoginService = userLoginService;
    this.alertService = alertService;
    this.router = router;
  }

  setNotificationsService(notificationsService: NotificationsService) {
    this.notificationsService = notificationsService;
    if ( this.notificationsService ) {
      this.notificationsService.startNotification();
    }
  }
  setPackageAndShippingComputationService(packageAndShippingComputationService: PackageAndShippingComputationService) {
    this.packageAndShippingComputationService = packageAndShippingComputationService;
  }

  touchLastUserActivity () {
    this.lastUserActivityTimestamp = Date.now();
  }

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  // load(): Promise<any> {
  load(): any {
    // This will call itself to see if we can get things setup early
    // this.isAuthenticated(this);
  }
  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log("Startup Service confirmed things are ready by calling itself from load()");
    if ( isLoggedIn ) {
      if ( this.notificationsService ) {
        this.notificationsService.startNotification();
      }
    } {
      if ( this.router ) {
        this.router.navigate(['/home/login']);
      }
    }
  }

  init(jwtToken: string, email: string, name: string, phone_number: string, role: string, roleId: string, userId: string, subscriptionToken: string): any {
    this.setJwtToken(jwtToken);
    this.name = name;
    this.phone_number = phone_number;
    this.role = role;
    this.roleId = roleId;
    this.resetCurrentOrganization();
    if ( !this.selectedRoleId ) {
      this.selectedRoleId = this.roleId;
      this.role = this.role;
    }
    // this.groupRoles = {};
    this.email = email;
    this.userId = userId;
    this.subscriptionToken = subscriptionToken;
    // this.initSubscription();
    this.ready = 'READY';
  }

  setOrganizationService(organizationService: OrganizationService) {
    this.organizationService = organizationService;
  }

  getProject(): Project {
    return this.project;
  }

  getJWTToken(): string {
    return this.jwtToken;
  }

  getEmail(): string {
    return this.email;
  }

  getName(): string {
    return this.name;
  }
  getRole(): string {
    return this.role;
  }
  getRoleId(): string {
    return this.roleId;
  }
  getPhoneNumber(): string {
    return this.phone_number;
  }

  // getUser(): User {
  //   if ( !this.user ) {
  //     this.user = new User( undefined );
  //     this.user.init( this.getUserId(), this.getName(), this.getEmail() );
  //   }
  //   return this.user;
  // }

  getUserId(): string {
    return 'cognito-' + this.userId;
  }

  setInteriorThemes(interiorThemes: InteriorTheme[]) {
    this.interiorThemes = interiorThemes;
  }

  setCoverThemes(coverThemes: CoverTheme[]) {
    this.coverThemes = coverThemes;
  }

  setFonts(fonts: FontItem[]) {
    this.fonts = fonts;
  }
  setColorPalettes(colorPalettes: ColorPalette[]) {
    this.colorPalettes = colorPalettes;
  }

  setProduct(product: Product) {
    this.product = product;
  }

  setProject(project: Project) {
    this.project = project;
  }

  setUserId(userId: string) {
    this.userId = userId;
  }

  getHttpOptions() {
    if ( this.selectedRole && this.selectedRoleId ) {
      return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'accesstoken': this.getJWTToken(), 'selectedrole': this.selectedRole + '-' + this.selectedRoleId })};
    } else {
      return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'accesstoken': this.getJWTToken() })};
    }
  }

  isAuthenticated (callback: LoggedInCallback) {
    // this.touchLastUserActivity();
    if (callback == null) {
      throw(new Error("StarupService: Callback in isAuthenticated() cannot be null"));
    }

    if (this.ready === 'READY') {
      callback.isLoggedIn(null, true);
    } else {
      try {
        this.checkSession(callback);
      } catch (e) {
        this.logout();
      }

    }
  }

  checkSession (callback: LoggedInCallback) {
    console.log(`CheckSession [${this.ready}]`);
    if (callback === null) {
      throw(new Error("UserLoginService: Callback in checkSession() cannot be null"));
    }

    if (this.ready === 'READY') {
      callback.isLoggedIn(null, true);
    }  // else {
      const cognitoUser = this.getCurrentUser();

      if (cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
            this.logout();
            callback.isLoggedIn(err, false);
          } else {
            try {

              this.getCurrentUser().refreshSession( session.getRefreshToken(), (error, refreshedSession) => {
                if (error) {
                  console.log("CognitoUtil: Can't refresh the refreshedSession:" + error);
                  this.logout();
                } else {
                  this.setJwtToken(refreshedSession.getIdToken().getJwtToken());

                  this.sessionRefreshTimstamp = Date.now();
                  if (!this.jwtToken || this.jwtToken.length < 20) {
                    callback.isLoggedIn("No valid authentication token", false);
                  }
                  console.log("UserLoginService: Session is " + refreshedSession.isValid());
                  cognitoUser.getUserAttributes((err2, result2) => {
                    if (err2) {
                      callback.isLoggedIn("Could not get user attributes  - " + err2.message, false);
                    } else {
                      for (let i = 0; i < result2.length; i++) {
                        console.log('CognitoUserAttributes: ' + result2[i].getName() + ' ' + result2[i].getValue());
                        switch (result2[i].getName()) {
                          case 'sub':
                            this.userId = result2[i].getValue();
                            // this.initSubscription();
                            break;
                          case 'name':
                            this.name = result2[i].getValue();
                            break;
                          case 'email':
                            this.email = result2[i].getValue();
                            break;
                          case 'custom:role':
                            this.role = result2[i].getValue();
                            break;
                          case 'custom:roleId':
                            this.roleId = result2[i].getValue();
                            this.resetCurrentOrganization();
                            break;
                        }
                        console.log('attribute ' + result2[i].getName() + ' has value ' + result2[i].getValue());
                      }
                      // if ( this.subscriptionService && !this.subscription ) {
                      //   const user = this.getUser();
                      //   if ( this.getUserId() === user.ID ) {
                      //     this.subscriptionService.changeSubscription(user.subscription);
                      //   } else {
                      //     console.log('Trying to set the User and subscription information for the wrong user.');
                      //   }
                      // }
                      this.ready = 'READY';
                      callback.isLoggedIn(null, refreshedSession.isValid());
                    }
                  });
                }
              });
            } catch (e) {
              this.logout();
            }
          }
        });
      } else {
        console.log("UserLoginService: can't retrieve the current user");
        callback.isLoggedIn("Can't retrieve the CurrentUser", false);
      }
    // }
  }

  // Copied from CognitioUtil to remove dependancy
  getUserPool() {
    if (environment.cognito_idp_endpoint) {
      CognitoUtil._POOL_DATA.endpoint = environment.cognito_idp_endpoint;
    }
    return new CognitoUserPool(CognitoUtil._POOL_DATA);
  }

  getSubscription(): Subscription {
    if ( !this.subscription ) {
      this.subscription = new Subscription(this.userId, null);
    }
    return this.subscription;
  }
  resetCurrentOrganization() {
    this.organization = undefined;
    this.getCurrentOrganization();
  }
  getCurrentOrganization(): Organization {
    if ( !this.organization ) {
      if ( this.organizationService ) {
        switch ( this.selectedRole ) {
          case 'organization': {
            this.organizationService.getOrganization(this.selectedRoleId).subscribe( organization => {
              this.organization = organization;
              if ( Number(this.selectedRoleId) === this.organization.ID && this.selectedRole === 'organization') {
                this.selectedRoleName = this.organization.name;
              }
            });
            break;
          }
          case 'serviceProvider': {
            this.organizationService.getServiceProviderOrganization(this.selectedRoleId).subscribe( organization => {
              this.organization = organization;
              if ( Number(this.selectedRoleId) === this.organization.serviceProvider_ID && this.selectedRole === 'serviceProvider') {
                this.selectedRoleName = this.organization.name;
              }
            });
            break;
          }
          case 'umbrella': {
            this.organizationService.getUmbrellaOrganization(this.selectedRoleId).subscribe( organization => {
              this.organization = organization;
              if ( Number(this.selectedRoleId) === this.organization.agent_ID && this.selectedRole === 'umbrella') {
                this.selectedRoleName = this.organization.name;
              }
            });
            break;
          }
          case 'facility': {
            this.organizationService.getOrganization(this.roleId).subscribe( organization => {
              this.organization = organization;
            });
            break;
          }
          case 'network': {
            this.organizationService.getOrganization(this.roleId).subscribe( organization => {
              this.organization = organization;
            });
            break;
          }
          default: {
            this.organizationService.getOrganization(this.selectedRoleId).subscribe( organization => {
              this.organization = organization;
            });
          }
        }
      }
      return undefined;
    }
    return this.organization;
  }
  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  // initSubscription() {
  //   if ( this.userId && this.subscriptionToken ) {
  //     this.subscription = new Subscription(this.userId, this.subscriptionToken);
  //     if ( this.subscriptionService && this.subscription.getSubscriptionLevel() === SubscriptionLevel.INITIAL ) {
  //       this.subscription.setSubscriptionLevel('STARTER');
  //       this.subscriptionService.changeSubscription(this.subscription);
  //     }
  //
  //   }
  // }

  getCart(): Cart {
    if ( this.cart && this.cart.items && this.cart.items.length > 0 ) {
      return this.cart;
    } else {
      this.cart = new Cart( undefined, this.packageAndShippingComputationService, this.organization );
      return this.cart;
    }
  }


  addService (dataService: DataService) {
    this.dataServices.push(dataService);
  }
}

export interface RoleChangeNotifier {
  addRoleChangeListener(roleChangeListener): void;
}

export interface RoleChangeListener {
  roleChanged(role: string, roleId: string): void;
}


