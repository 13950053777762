import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {DraftCover} from "../../../../../model/DraftCover";
import {StartupService} from "../../../../service-ui/startup.service";
import {Draft} from "../../../../../model/Draft";
import {Cover} from "../../../../../model/Cover";
import {Book} from "../../../../../model/Book";


@Component({
  selector: 'app-project-book-cover',
  templateUrl: './project-book-cover.component.html',
  styleUrls: ['./project-book-cover.component.css']
})
export class ProjectBookCoverComponent implements OnInit {
  @ViewChild('coverImage') coverImage;
  @Input() book: Book;
  @Input() cover: Cover;

  displayScale = 1.0;

  Math = Math;


  constructor (public router: Router, public startupService: StartupService) {
  }

  ngOnInit() {
  }

}


