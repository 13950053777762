import {Organization} from "./Organization";
import {ServiceProvider} from "./ServiceProvider";

export class User {
  public ID: string;
  public name: string;
  public email: string;
  public phoneNumber: string;
  public isAdmin: string;
  public properties: { };

  public password_digest: string;
  public active: string;
  public allowInPlant: string;
  public created: Date;
  public focusOrg_ID: number;
  public selectedRole: string | null;
  public language: string;
  public country: string;
  public lastUpdate: Date;
  public facebookUserId: string | null;
  public cognitoUserName: string | null;
  public organization: Organization;


  constructor ( obj ) {
    this.ID = '';
    this.name = '';
    this.email = '';
    if ( obj ) {
      for ( const prop of Object.keys(obj) ) {
        if ( prop === 'organization') {
          this.organization = new Organization(obj[prop]);
        } else {
          if ( prop === 'properties') {
            this.properties = JSON.parse(obj[prop]);
          } else {
            this[prop] = obj[prop];
          }
        }
      }
    }
  }
}
